import React, { useState, useEffect, useRef, useCallback } from 'react';
import { generateImage } from '../utils/imageGenerator.ts';
import '../styles/ImageGenerator.css';
import { FaImage, FaSpinner, FaDownload } from 'react-icons/fa';

export function ImageGenerator({ initialPrompt }: { initialPrompt: string }) {
  const [imageUrl, setImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [aspectRatio, setAspectRatio] = useState('1:1');
  const [prompt, setPrompt] = useState(initialPrompt);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [initialHeight, setInitialHeight] = useState(0);

  const autoResizeTextarea = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      const scrollHeight = textareaRef.current.scrollHeight;
      const maxHeight = initialHeight * 2.5;
      const newHeight = Math.min(scrollHeight, maxHeight);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [initialHeight]);

  useEffect(() => {
    setPrompt(initialPrompt);
    if (textareaRef.current) {
      setInitialHeight(textareaRef.current.clientHeight);
    }
  }, [initialPrompt]);

  useEffect(() => {
    autoResizeTextarea();
  }, [prompt, autoResizeTextarea]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!prompt.trim()) {
      setError("Please tell us what's on your mind before generating an image.");
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      const { imageUrl } = await generateImage(prompt, aspectRatio);
      setImageUrl(imageUrl);
    } catch (error) {
      console.error("Failed to generate image:", error);
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
    autoResizeTextarea();
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'generated-image.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
      setError('Failed to download image');
    }
  };

  return (
    <div className="image-generator">
      <div className="controls">
        <div className="input-wrapper">
          <FaImage className="input-icon" />
          <textarea
            ref={textareaRef}
            value={prompt}
            onInput={handleTextareaChange}
            placeholder="Tell me what's on your mind..."
            className="prompt-input"
          />
        </div>
        <div className="aspect-ratio-and-button">
          <div className="aspect-ratio-container">
            <label htmlFor="aspect-ratio-select">Aspect Ratio:</label>
            <select
              id="aspect-ratio-select"
              value={aspectRatio}
              onChange={(e) => setAspectRatio(e.target.value)}
              className="aspect-ratio-select"
            >
              <option value="1:1">1:1 (Square)</option>
              <option value="16:9">16:9 (Widescreen)</option>
            </select>
          </div>
          <div className="generate-button-container">
            <button onClick={handleSubmit} disabled={isLoading} className="generate-button">
              {isLoading ? (
                <>
                  <FaSpinner className="spinner" />
                  Dreaming...
                </>
              ) : (
                'Dream on'
              )}
            </button>
          </div>
        </div>
      </div>
      {error && <p className="error-message">{error}</p>}
      {imageUrl && (
        <div className="generated-image">
          <img src={imageUrl} alt="Generated" onClick={() => window.open(imageUrl, '_blank')} style={{ cursor: 'pointer' }} />
          <button onClick={handleDownload} className="download-button">
            <FaDownload /> Download
          </button>
        </div>
      )}
    </div>
  );
}