// Best practices for AI image generation prompts:
// 1. Be specific and detailed
// 2. Use descriptive adjectives
// 3. Mention artistic styles or inspirations
// 4. Include lighting and atmosphere details
// 5. Specify camera angle or perspective
// 6. Mention color palettes or dominant colors
// 7. Include composition details
// 8. Specify the medium (e.g., digital art, oil painting, etc.)

export const promptIdeas = [
    {
        "display": "Cheerful medieval innkeeper",
        "full": "young, curly haired, redhead Natalie Portman  as a optimistic!, cheerful, giddy medieval innkeeper in a dark medieval inn. dark shadows, colorful, candle light,  law contrasts, fantasy concept art by Jakub Rozalski, Jan Matejko, and J.Dickenson"
    },
    {
        "display": "Mystical tribal goddess portrait.",
        "full": "a mystical tribal goddess adorned with feathers and gemstones and cables and synthesizer parts is surrounded by sacred geometry made from elven architecture, full body, gorgeous, perfect face, powerful, cinematic, beautifully lit, by artgerm, by karol bak, 3 d, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Cyberpunk woman portrait",
        "full": "molly millions, portrait of a beautiful cyberpunk woman, sunglasses, shoulder long hair, cyberpunk, street samurai, sunset, neuromancer, cyberpunk city background, megacity, gorgeous view, depth, high detail, digital art, painted by greg rutkowski, painted by seb mckinnon, trending on artstation"
    },
    {
        "display": "Cyborg forehead sweat portrait",
        "full": "cyborg sweating water, big drops of sweat, forehead only, by Hajime Sorayama, airbrush art, beautiful face, highly realistic, star flares, trending on artstation, beautiful lighting, sharp, details, hyper-detailed, HD, HDR, 4K, 8K"
    },
    {
        "display": "Max Headroom Perfume Ad",
        "full": "Max Headroom in a Perfume advertisement, magical, science fiction, symmetrical face, large eyes, Chanel, Calvin Klein, Burberry, Versace, Gucci, Dior, hyper realistic, digital art, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k"
    },
    {
        "display": "Retrofuturistic assassin portrait.",
        "full": "american portrait of one retrofuturistic assassin surrounded by advanced brutalist architecture. highly detailed science fiction, rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation an ultrafine hyperdetailed colorfull illustration by tsutomu nihei by emil melmoth, gustave dore, craig mullins, yoji shinkawa, luis royo, zaha hadid, pete morbacher, female, feminine, art deco, new baroque, intricate linework"
    },
    {
        "display": "Mech and pilot illustration",
        "full": "titanfall mech standing with its human pilot, dramatic lighting, illustration by greg rutkowski, yoji shinkawa, 4 k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Photorealistic Asuka Langley portrait",
        "full": "4 k 8 k photorealistic torso headshot portrait of elegant asuka langley in red - white tight fit contact suit reflective ; @ @ evangelion @ @ anime. by james jean, zac retz, pixiv"
    },
    {
        "display": "Zombie dad watching TV.",
        "full": "a zombie dad sitting on the couch and watching tv, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Symmetrical mechanical predator portrait.",
        "full": "professional concept art symmetrical portrait of a horrendous mechanical predatory fractal! species in a dark room by artgerm and greg rutkowski. an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp focus, illustration, in the style of cam sykes."
    },
    {
        "display": "Powerful energy woman artwork",
        "full": "a powerful energy woman, by alexander fedosav, hyper detailed digital matte painting, concept art, hyperrealism, 1 6 k resolution, cinema 4 d, 8 k resolution, trending on artstation, behance hd, a masterpiece, by stephan martiniere, particles, cel - shaded, power bright neon energy, by david a. hardy,"
    },
    {
        "display": "Monica Bellucci as Snow White.",
        "full": "film still of Monica Bellucci as snow white and red veil, in a forest by a pond with frogs, by artgerm, makoto sinkai, magali villeneuve, Gil Elvgren, Earl Moran,Enoch Bolles, symmetrical,"
    },
    {
        "display": "Cinematic epic landscape.",
        "full": "red dead redemption 2, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Merlin battling dragons.",
        "full": "The wizard merlin stands on a mountain fighting a harde of dragons, by Zdzis\u0142aw Beksi\u0144ski, trending on artstation, 8k, landscape photo-reality, landscape photo-imagery"
    },
    {
        "display": "Sorceress tarot card illustration.",
        "full": "a tarot card of an attractive young girl sorceress wearing a black robe with gold embroidery, casting a spell, green glows, painted by artgerm and tom bagshaw, in the style of magic the gathering, intricate, highly detailed digital art"
    },
    {
        "display": "White-haired superhero girl",
        "full": "full body portrait of white haired girl in spider man suit, super hero, pretty face, very beautiful face, very detailed eyes, webs, highly detailed, digital painting, artstation, concept art smooth, sharp focus, greg rutkowski, artey freytag"
    },
    {
        "display": "Giant female mech robot.",
        "full": "a giant female robot, digital art, 8 k resolution, mech, unreal engine, highly detailed, photorealistic by wlop, greg rutkowski"
    },
    {
        "display": "Cheerful WWI admiral portrait",
        "full": "official Portrait of a smiling WWI admiral, male, cheerful, happy, detailed face, 20th century, highly detailed, cinematic lighting, digital art painting by greg rutkowski"
    },
    {
        "display": "Glowing Trump Portrait",
        "full": "glowwave portrait of donald trump from borderlands 3, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt"
    },
    {
        "display": "Elf warrior in magical forest.",
        "full": "A female elf warrior in a magical bikini posing on a rock in a magical forest, highly detailed and realistic face, beautiful detailed eyes, fantasy art, in the style of Artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Noir hotel scene, Gal Gadot",
        "full": "gal godat in a noir hotel room by artgerm, jeffrey catherine jones, alphonse mucha"
    },
    {
        "display": "Steampunk ninja in rain.",
        "full": "stunningly ominous, steampunk ninja in a skyscraper at night in the rain, smooth, focus, highly detailed, hyper realistic, dramatic lighting, intricate, concept art, skyline, looking down, art by wlop, mars ravelo, greg rutowski, artstation"
    },
    {
        "display": "White tiger king artwork",
        "full": "artwork of a white tiger king with gold crown and blue king suit, concept art, super detailed, 4 k hd, trending on artstation, digital painted, low contrast, made by greg rutkowski and viktoria gavrilenko"
    },
    {
        "display": "Cute creatures in muddy landscape",
        "full": "of a dark and muddy landscape with strange cute friendly creatures with big eyes, mouth and round teeth appearing from the mud, in the style of Giger, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Lonely robot seeking friendship",
        "full": "mcbess illustration of a lonely robot seeks friend, intricate complexity, by greg rutkowski, artgerm, ross tran, conrad roset, takato yomamoto, ilya kuvshinov. 4 k, beautiful, cinematic dramatic atmosphere"
    },
    {
        "display": "Fantasy portrait of Margot Robbie.",
        "full": "margot robbie, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Digital art illustration.",
        "full": "artgerm digital art"
    },
    {
        "display": "Sakura plum tree concept art.",
        "full": "highly detailed concept art of a sakura plum tree made with water, overgrowth, Tristan Eaton, Artgerm, Studio Ghibli, Makoto Shinkai"
    },
    {
        "display": "Brutalist Martian architecture.",
        "full": "brutalist architecture on mars, by zdzislaw beksinski, by dariusz zawadzki, by wayne barlowe, gothic, surrealism, cosmic horror, lovecraftian, cold hue's, warm tone gradient background, concept art, beautiful composition"
    },
    {
        "display": "Holocaust landscape painting",
        "full": "oil painting of holocaust LANDSCAPE, diffuse lighting, intricate, highly detailed, lifelike, photorealistic,  illustration, concept art, smooth, sharp focus, art by francis bacon"
    },
    {
        "display": "Elegant portrait of Irina Shayk",
        "full": "beautiful portrait of Irina Shayk wearing fantastic Hand-dyed cotton dress,embellished beaded feather decorative fringe knots ,colorful pigtail,subtropical flowers and plants,dramatic lighting,symmetrical face,intricate,elegant,highly detailed,8k,post-processing,digital painting,trending on pinterest, GUCCI,PRADA,concept art, sharp focus, illustration, by artgerm,Tom Bagshaw,Lawrence Alma-Tadema,greg rutkowski,alphonse Mucha"
    },
    {
        "display": "Redheaded toddler in park.",
        "full": "a beautiful [[[[[smiling]]]]] little redheaded toddler girl with short loosely curly hair, at the park on a beautiful day, holding a round all-pink stuffed penguin, by Artgerm, Mucha Klimt, Hiroshi Yoshida and Craig Mullins, featured on Artstation, CGSociety, Behance HD, Deviantart"
    },
    {
        "display": "Apocalyptic family farewell.",
        "full": "\u201cA family hugging each other for the last time as the world is ending, meteors are falling from the sky, everything is on fire, dramatic lighting, digital art, very very very very very very beautiful, 8K, dark lighting, trending on Artstation, award winning\u201d"
    },
    {
        "display": "Feminine strength in nature.",
        "full": "the beautiful crowned embodiment of feminine strength holding her staff of power rests in a lush green field, 8 k resolution digital painting, vibrant colors, by alena aenami, by michael whelan, behance hd, trending on artstation deviantart"
    },
    {
        "display": "Swamp Cabin Concept Art",
        "full": "digital concept art of old wooden cabin in florida swamp, trending on artstation"
    },
    {
        "display": "Sailor Moon illustration.",
        "full": "photo of sailer moon, by greg rutkowski"
    },
    {
        "display": "Overgrown post-apocalyptic city.",
        "full": "Post apocalyptic city overgrown abandoned city, highly detailed, art by Range Murata, highly detailed, 3d, octane render, bright colors, digital painting, trending on artstation, sharp focus"
    },
    {
        "display": "Blue-skinned troll in desert.",
        "full": "A troll with blue skin and a spear posing in a a desert, realistic face, full body, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Druid casting spell dungeon",
        "full": "a beautiful painting of a druid in moonkin form casting a spell in a dungeon, world of warcraft concept art"
    },
    {
        "display": "Druids praying to alien.",
        "full": "a hyperrealist watercolour character concept art portrait of a group of druids kneeling down in prayer to a tall elegant lovecraftian alien on a misty night in stone henge. a battlecruiser starship is in the background. by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Intricate portrait of Selena.",
        "full": "selena quintanilla perez, character portrait, portrait, close up, concept art, intricate details, highly detailed by greg rutkowski, michael whelan and gustave dore"
    },
    {
        "display": "Evil magician portrait",
        "full": "dark fantasy, evil magician portrait, dark surrealist , fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surreal demented Elsa illustration.",
        "full": "' princess elsa, insanity, demented, morbid, surreal ', beautiful shadowing, 3 d shadowing, reflective surfaces, illustrated completely, 8 k beautifully detailed pencil illustration, extremely hyper - detailed pencil illustration, intricate, epic composition, masterpiece, bold conflicting colors. stunning masterfully illustrated by artgerm, range murata, alphonse mucha, katsuhiro otomo."
    },
    {
        "display": "Sad anime schoolgirls portrait",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai, cute beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. deep water bellow. beautiful pretty realistic smile"
    },
    {
        "display": "Symmetrical portrait of girl.",
        "full": "symmetrical portrait of a 2 5 year old girl, by karol bak, james jean, tom bagshaw, rococo, sharp focus, trending on artstation, cinematic lighting, hyper realism, octane render, 8 k, hyper detailed."
    },
    {
        "display": "Messi dunking on Ronaldo.",
        "full": "Messi dunking on Ronaldo in basketball, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Workers vs. Bourgeoisie, Cinematic Art",
        "full": "Workers struggle against the bourgeoisie, digital art, trending on artstation, cinematic"
    },
    {
        "display": "Intricate Wolverine portrait",
        "full": "portrait of logan wolwerine, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, greg rutkowski, alphonse mucha, uang guangjian, gil elvgren, sachin teng, symmetry!!"
    },
    {
        "display": "Bipedal mech concept art.",
        "full": "bipedal mech red and white accents volumetric light intricate complex machine detailed concept art sharp focus coherent"
    },
    {
        "display": "Fantasy celestial cloudscape",
        "full": "Cloudscape by Adam Paquette, nebula gasses in the background by Gene Raz Von Edler, fantasy magic angel concept art from deviantart by Donato Giancola, Rendered in Octane, cinematic, Highly Detailed"
    },
    {
        "display": "Cyborg nymph vs. flamingo.",
        "full": "an extreme close - up of a statue chrome cyborg lycra nymph battling a giant inflatable flamingo pool float, christmas cactus arnold schwarzenegger and monstera plants, fireworks thick smoke, by jeff koons, hajime soryama, boris vallejo, artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Psychedelic Third Eye portrait",
        "full": "An extremely psychedelic portrait of the Third Eye, surreal, LSD, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Biblical Cain and Abel scene",
        "full": "realistic scene of biblical Cain and Abel before a stone altar with white smoke ascending in the background, a lamb in the foreground, inspired art by Frazetta + facial symmetry + bright dramatic volumetric lighting, well lit, 8k octane render, intricate, epic composition, golden hour, cinematic studio lighting + masterpiece, trending on artstation, very detailed, masterpiece, stunning"
    },
    {
        "display": "Tetrachromia cyborg woman",
        "full": "tetrachromia half - machine woman with cute - fine - face, pretty face, multicolored hair, realistic shaded perfect face, extremely fine details, by realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Elegant cyclops fighter illustration",
        "full": "cyclops fighter, white grey blue color palette, male, d & d, fantasy, intricate, elegant, highly detailed, long silver hair, digital painting, artstation, octane render, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm, alphonse mucha johannes voss"
    },
    {
        "display": "Simpsons characters in Peaky Blinders",
        "full": "peaky blinders with the simpsons characters, artstation, vivid colors, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Anime fantasy portrait",
        "full": "portrait of fiamma of the right, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, comixwave films, trending on artstation"
    },
    {
        "display": "Diverse crowd before throne",
        "full": "many people from all countries, and of all races, languages and peoples. There were so many that no one could count them! They were standing before the throne and the Lamb, dressed in white clothes. In their hands they carried palm branches, digital Art, Greg rutkowski, Trending cinematographic artstation"
    },
    {
        "display": "Warrior Woman Portrait",
        "full": "portrait of a woman warrior, digital art, character art, by artgerm"
    },
    {
        "display": "Hazmat figure at sunrise.",
        "full": "ryan church jon mccoy concept art mood painting man wearing grey hazmat suit gp - 5 gas mask sitting against concreate wall snow covered field watching the beautiful winter sunrise burning ruins in background forest"
    },
    {
        "display": "Twin Peaks-inspired diner scene.",
        "full": "Twin Peaks poster artwork, portrait of Channing Tatum the local pastry chef, flirting with Emma Watson nun sitting in the diner, from scene from Twin Peaks by Michael Whelan, Bob Larkin and Tomer Hanuka, simple illustration, domestic, nostalgic, clean, full of details, by Makoto Shinkai and thomas kinkade, Matte painting, trending on artstation and unreal engine, New Yorker magazine cover"
    },
    {
        "display": "Fantasy portrait of Jim Halpert",
        "full": "a portrait of jim halpert, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Cthulhu as Elon Musk",
        "full": "digital masterpiece illustration concept art of sideview waist up portrait of porcelain statue of cthulhu as elon musk!!!!, extremely detailed and intricate complexity, epic composition, magical atmosphere, cinematic lighting, wide long shot, trending on artstation, 8 k"
    },
    {
        "display": "Futuristic transhumanist portal art",
        "full": "the most amazing dream you ever had about singularity transhumance portal, mass effect, dark souls, halo, doom eternal, hyper realistic, concept art, high key lighting, intricate, hyper detailed, smooth, high contrast, neon, volumetric lighting, octane, raytrace, greg rutkowski, huifeng huang, artgerm, beeple, jim lee,"
    },
    {
        "display": "Hybrid cat-eagle painting",
        "full": "painting of hybrid between cat!! & eagle, intercrossed animal, mixture animal, by tiffany bozic, by zdzislaw beksinski, organic, nature study, accurate anatomy, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting,"
    },
    {
        "display": "Minimalistic bonsai acacia art",
        "full": "bonsai acacia! tree but minimalistic concept art by frank stella gilleard james whalen tom, colorful, soft light, trending on artstation, minimalism"
    },
    {
        "display": "\"Girl in Gucci Kimono\"",
        "full": "a vibrant dream of a beautiful girl wearing a gucci kimono from behind on a hotel balcony looking out over a dreamy street in jiufen taiwan, lush plants, glowing paper lanterns, high fashion, magic details, by moebius, edward hopper, james gilleard, and james jean, hd, 8 k, trending on artstation, uhd,"
    },
    {
        "display": "Karakl cat drinking energy drink",
        "full": "the cat karakl drinks an energy drink, concept art, wlop, digital painting, trending on artstation, highly detailed, epic composition, official media, 8 k uhd"
    },
    {
        "display": "Rainy girl on stairs.",
        "full": "in a rainy girl sitting on a stair where there is an arched self above, many green plant and flower gowing on it, illustration concept art anime key visual trending pixiv fanbox by wlop and greg rutkowski and makoto shinkai and studio ghibli"
    },
    {
        "display": "Dehydrated Lovecraftian spider portrait",
        "full": "realistic portrait of a dehydrated lovecraft humanoid spider husk with a female face, wearing white silk, still from a movie by Terrence Malick, Tarkovsky, Gaspar Noe, James Cameron, intricate artwork by Tooth Wu and wlop and beeple, greg rutkowski, very coherent symmetrical artwork, cinematic, hyper realism, high detail, octane render, rim light, unreal engine, 8k, Vibrant colors, Smooth gradients, High contrast, depth of field"
    },
    {
        "display": "Megan Fox as Demon",
        "full": "portrait of megan fox as demon, devil, red skin, batwings, hell, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Lifelike portrait of Nicki Minaj.",
        "full": "5 5 mm portrait photo nicki minaj by charlie bowater and titian and artgerm, intricate, face, highly detailed 8 k, intricate, lifelike, soft light, cinematic lighting, featured on artstation"
    },
    {
        "display": "Ultra-realistic Michael Mando illustration",
        "full": "ultra realistic illustration, michael mando from last of us, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Flooded tower painting",
        "full": "unique flooded tower, a high contrast!! minimalist!! ultradetailed photorealistic painting by magritte stalenhag spitzweg and burns, dark shadows, sunny day, full - length view, vibrant colors, great composition, cinematic lighting, award winning masterpiece, trending on artstation, 4 k octane render"
    },
    {
        "display": "Spiraling flame concept art",
        "full": "colors weave into a spiral of flame, concept art, trending on artstation 3D."
    },
    {
        "display": "Anime-style Harley Quinn portrait.",
        "full": "Anime as Margot Robbie cute-fine-face, pretty face, surprised realistic shaded Perfect face, fine details. Anime. as Harley Quinn Suicide Squad; classic anime from 1990 nichijou realistic shaded lighting by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Cyberpunk Mario Portrait",
        "full": "a portrait of mario, cyberpunk!, fantasy, elegant, digital painting, artstation, concept art, matte, sharp focus, illustration, art by josan gonzalez"
    },
    {
        "display": "Anime-themed amusement park sculpture.",
        "full": "anime theme amusement park, trending on artstation, dramatic lighting, octane render, weta digital, micro details, 3 d sculpture, structure, ray trace, insane details, intricate, elite, ornate, elegant"
    },
    {
        "display": "Detailed angelic vampire illustration",
        "full": "Angel Vampire, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "New Overwatch character design",
        "full": "beautiful new character for overwatch, full body armor, dual wielding swords, super powers, long red hair, intricate design, shiny, art by artgerm and greg rutkowski"
    },
    {
        "display": "Cute anime museum characters",
        "full": "cute anime characters in a science and history museum by Goro Fujita and Simon Stalenhag and Banksy and Hieronymous Bosch, 8k, trending on artstation, hyper detailed, cinematic"
    },
    {
        "display": "Fantasy Queen Portrait",
        "full": "portrait, Salma Hayek , Fantasy Queen , wearing a red and gold dress , face portrait, raphael lacoste, eddie mendoza, alex ross, concept art, matte painting, highly detailed, rule of thirds, dynamic lighting, cinematic, detailed, denoised, centred"
    },
    {
        "display": "Cute electric woman portrait",
        "full": "electric woman, cute - fine - face, pretty face, oil slick hair, realistic shaded perfect face, extremely fine details, realistic shaded lighting, dynamic background, artgerm, 8 k ultra realistic, highly detailed, art by sylvain sarrailh, alena aenami, jeremy lipkin, michael garmash, ando tadao, kan liu"
    },
    {
        "display": "Retro sci-fi rocketship concept.",
        "full": "retro sci-fi rocketship, cinematic, cinematic lightning, concept art, photography composition, f16"
    },
    {
        "display": "Attractive female line art",
        "full": "a beautiful detailed line art sketch of an attractive female with long black hair covering half of her face, centered, glamour portrait, by karol bak and moebius and beeple, trending on artstation and 5 0 0 px and behance, extreme detailing, realistic, f 8, 4 k hd wallpaper"
    },
    {
        "display": "Asymmetrical angelic snufkin portrait.",
        "full": "asymmetrical!! portrait of a snufkin with angel wings in the style of, demon art, intricate, elegant, highly detailed, digital painting, artstation, biolusence, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, horizon zero dawn 8 k"
    },
    {
        "display": "Vibrant Day of the Dead rave.",
        "full": "scene from a rave, celebrating day of the dead, vibrant colours, beautiful, digital art, winning award masterpiece, trending on artstation, painting by otto dix and greg rutkowski, 8 k"
    },
    {
        "display": "Anime girl vs. giant rabbit",
        "full": "\u00ef\u00bf\u00bd anime, a pretty girl fighting a giant rabbit with a lightsaber, light and shadow effects, intricate, highly detailed, digital painting, art station, concept art, smooth, sharp focus, illustration, advanced digital anime art, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau, craig mullins, j. c. leyendecker \u00ef\u00bf\u00bd"
    },
    {
        "display": "Cyberpunk Star Wars landscape",
        "full": "star wars concept art, scenic environment, cyberpunk style, artwork by anton fadeev"
    },
    {
        "display": "Happy mask salesman portrait",
        "full": "a portrait of the happy mask salesman, human, ginger hair, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and wlop, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Gwyneth Paltrow as hippie.",
        "full": "Gwyneth Paltrow as a meditating new age hippie with beads and crystals, portrait, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime woman in blue dress.",
        "full": "a woman in a blue dress with a tie around her neck, an anime drawing by Jin Homura, featured on pixiv, lyco art, pixiv, anime, deviantart hd"
    },
    {
        "display": "Vampire portrait of Megan Fox",
        "full": "portrait of megan fox, sexy muscular upper body, blood red eyes, vampire, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Alien goddess close-up render",
        "full": "a highly detailed metahuman 4 k close up render of an alien goddess bella hadid monument renaissance in iris van herpen dress schiaparelli in diamonds crystals swarovski and jewelry iridescent in style of alphonse mucha gustav klimt trending on artstation made in unreal engine 4"
    },
    {
        "display": "Cinematic portrait of Sam Gray",
        "full": "Man named Sam Gray, by Cedric Peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on ArtStation"
    },
    {
        "display": "Stylish woman portrait painting",
        "full": "a ultradetailed beautiful portrait panting of a stylish woman sitting on a chair, by conrad roset, greg rutkowski and makoto shinkai, trending on artstation"
    },
    {
        "display": "Medieval village aerial view",
        "full": "small medieval village next to a forest, aerial view, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and laura sava and alphonse mucha"
    },
    {
        "display": "Female Indiana Jones portrait",
        "full": "beautiful oil painting of female indiana jones in a treasure hoard by charlie bowater and titian and artgerm, full - body portrait, blush, symmetrical face, bombshell, intricate, face, beige clothes, flowing hair, slim face, freckles, elegant, light gold mist, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Steampunk rockers riding dinosaurs.",
        "full": "hard metal rockers riding dinosaurs in the space desert, boris vallejo style, steampunk, hyper detailed, digital art, cinematic lighting, concept art by artgerm and greg rutkowski and caravaggio and moebius and jakub rebelka, 8 k"
    },
    {
        "display": "Unpleasant portrait of Zoey Deutch",
        "full": "zoey deutch with white bra portrait | | realistic shaded, unpleasant face, bad looking, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Solarpunk cityscape concept.",
        "full": "a concept art of a solarpunk city with lush vegetation, tall skyscrapers and autonomous vehicles"
    },
    {
        "display": "Lady Gaga with Rubik's Cube",
        "full": "painting of lady gaga playing with rubik's cube, blue rain, awe inspiring, night, volcanic landscape, masterpiece, sharp focus, 8 k high definition, insanely detailed, art by alessando baldasseroni, jeremy bennett, john liberto, angelina stroganova"
    },
    {
        "display": "Adorable eldritch creature illustration.",
        "full": "a eldritch creature, adorable and whimsical, fantasy, elegant, digital painting, artstation, concept art, matte, sharp focus, unreal engine 5, marmoset toolbag, redshift, vibrant colors, high contrast, illustration, art by james jean"
    },
    {
        "display": "Giant Oriental Dragon Portrait",
        "full": "giant oriental dragon in the sky, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Futuristic African American ninja.",
        "full": "highly detailed an african american woman in with the shadow ninja cibor suit from the future gta v, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Medieval battle at castle bridge",
        "full": "army defending stone bridge of a castle from a large attacking force, digital art, 4 k, epic, intense, medieval, intricate, battle, armies clashing, medieval warfare, castle, greg rutkowski, joeri lefevre, max prodanov, trending on artstation"
    },
    {
        "display": "Surreal Ryuk Portrait",
        "full": "highly detailed surreal vfx portrait of ryuk from deathnote, stephen bliss, unreal engine, greg rutkowski, loish, rhads, beeple, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, alphonse mucha, global illumination, detailed and intricate environment"
    },
    {
        "display": "Renaissance-style Jupiter prince portrait.",
        "full": "portrait of a prince from jupiter, renaissance style, star wars character, volumetric lights, symmetry, headpiece, trending on artstation, sharp focus, leica, studio photo, intricate details, highly detailed"
    },
    {
        "display": "Lifelike illustration of Stauffenberg.",
        "full": "amazing lifelike award winning pencil illustration of Claus von Stauffenberg trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Fluffy movie star creature",
        "full": "cutie fluffy creature movie star people, digital art, 3 d, octave render, masterpiece, mega detailed, pixar, disney, vivid illustration, cartoon, fantasy, by george stubbs, artgerm, in the style of ghibli kazuo oga, pastel fur"
    },
    {
        "display": "Jubilee Portrait, High Contrast",
        "full": "jubilee from x-men, high contrast, concept art, dramatic lighting, portrait, facing forward, face in focus, art by Frank Miller"
    },
    {
        "display": "Decayed aircraft carrier in jungle.",
        "full": "decayed aircraft carrier USS Nimitz laying on the ground overgrown with vegetation and hanging vines, post apocalyptic, tropical forest, by Luis Royo, by Greg Rutkowski, extreme low angle shot, dark, gritty, intricate, cover illustration, concept art, volumetric lighting, volumetric atmosphere, sharp focus, octane render, trending on artstation, 8k"
    },
    {
        "display": "Cute female wizard in forest.",
        "full": "picture of cute female wizard in the forest, ferred - themed robes and hat, pink hair, brown skin, energetic, high fantasy, highly detailed, detailed faces, smooth, sharp focus, chiaroscuro, digital painting, concept art, rossdraws and moebius and jon mcnaughton"
    },
    {
        "display": "Ashton Kutcher as Deckard Cain",
        "full": "a beautiful and detailed portrait of ashton kutcher as deckard cain, viewed in profile from far away, ultrawide lens, art by artgerm and greg rutkowski and alphonse mucha, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Dark witch book cover",
        "full": "book cover, front portrait, dark witch with black hood and evil eyes, realism, soft, smooth, luminescent, art nouveau tarot, backlit glow, colorful swirly ripples, gaudy colors, aesthetic octane render, unreal engine, 8 k, by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Lofi underwater steampunk portrait",
        "full": "lofi underwater victorian steampunk portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Cinematic Asian girl illustration.",
        "full": "full body of a beautiful asian girl with long hair, cinematic lighting, comic atmosphere, by dustin nguyen, akihiko yoshida, greg tocchini, greg rutkowski, cliff chiang, 4 k resolution, trending on artstation"
    },
    {
        "display": "Boris Johnson in organic art",
        "full": "boris johnson, organic painting, matte painting, bold shapes, hard edges, aesthetic octane render, unreal engine, trending on artstation, by greg manchess, huang guangjian, gil elvgren, sachin teng, greg rutkowski, magali villeneuve, artgerm, jeremy lipkin, michael garmash and, rey"
    },
    {
        "display": "Detailed cyberpunk cyborg portrait",
        "full": "Portrait of a cyberpunk cyborg  , D&D, sci fi fantasy, intricate, richly detailed colored , art by Range Murata and Artgerm highly detailed, digital painting, trending on artstation, sharp focus, illustration, style of Stanley Artgerm,"
    },
    {
        "display": "Swimming pool of baked beans",
        "full": "Intricate detailed illustration, Swimming pool completely full of baked beans, cinematic lighting, by Philip Hood, wide angle, volumetric light scattering, 8k, artstation, concept art,"
    },
    {
        "display": "Symmetrical halfling cleric portrait",
        "full": "symmetry!! oil painting digital art dungeons & dragons facial portrait of a halfling male cleric, with a ponytail, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sleek sci-fi armored male.",
        "full": "iridescent sinewy smooth muscular male sleek glossy black pearlescent scifi armor with smooth black featureless helmet, by greg rutkowski, mark brookes, jim burns, tom bagshaw, magali villeneuve, eve ventrue, neil nelson thedarkestseason, trending on artstation"
    },
    {
        "display": "Werewolf portrait in forest",
        "full": "full figure portrait of a beautiful young fit male werewolf, wolf head, partially male hairy torso, fur covered legs, by greg rutkowski and alphonse mucha, d & d character, gradient red to black, in front of a forest background, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp focus ilustration, artstation hq"
    },
    {
        "display": "Rats playing poker, cinematic.",
        "full": "rats playing poker, smoking cigars, drinking whisky, artstation, vivid colors, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Kitten on crashed spaceship.",
        "full": "kitten standing on crashed sci-fi spaceship on Earth in future, cinematic lighting, concept art, trending on Artstation, trending on DeviantArt, highly detailed, high quality, 8K HDR, octane render, unreal engine 5, breathtaking landscape, highly detailed, high quality, post processed"
    },
    {
        "display": "Beagle dog watercolor scene",
        "full": "water color painting of a white and caramel beagle dog playing with dragonfly in a backyard, harsh lighting, detailed, trending on artstation, dull pastel colors, bright, god rays, dreamy, trending on artstation"
    },
    {
        "display": "Tobey Maguire as Arachne.",
        "full": "Tobey Maguire as Arachne. Digital art, dark, fantasy, epic, dramatic, volumetric lighting, trending on artstation, detailed, 8k"
    },
    {
        "display": "Dwarf with gold nugget.",
        "full": "portrait of a dwarf showing off the humongous raw gold nugget, realistic, beautiful, fantasy art, dnd, lord of the rings, by eyvind earle, james jean, concept art, sharp focus, ray tracing"
    },
    {
        "display": "Dreamlike giant eye cube.",
        "full": "portrait of dreamlike giant eye puzzle lock cube, intricate abstract. intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Elegant aeris with embers.",
        "full": "a gorgeous aeris with long soft hair in the style of stefan kostic, realistic, full body shot, wide angle, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, floating embers"
    },
    {
        "display": "Loki as Super Saiyan.",
        "full": "loki going super sayain, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Anime female master portrait",
        "full": "a female master, character art portrait, anime key visual, official media, illustrated by wlop, extremely detailed, 8 k, trending on artstation, cinematic lighting, beautiful"
    },
    {
        "display": "Charizard over New York.",
        "full": "charizard flying above new york, made by stanley artgerm lau, wlop, rossdraws, artstation, cgsociety, concept art, cgsociety, octane render, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Photorealistic fire salamander",
        "full": "fire salamander, photorealistic, trending on artstation, by bayard wu, anna podedworna, gaston bussiere, greg rutkowski"
    },
    {
        "display": "Cyberpunk lady assassin portrait",
        "full": "lady assassin wearing cyberpunk streetwear, robotic legs, detailed portrait, 4 k, vivid colours, concept art by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Elegant female fairy illustration",
        "full": "beautiful female fairy, full body shot, long hair, d & d, fantasy, ethereal background, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Kitsune portrait concept art",
        "full": "portrait of a kitsune, concept art"
    },
    {
        "display": "Elegant mummy portrait.",
        "full": "sansa angeline jolie gessica chastain old mummy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Quinn from League of Legends",
        "full": "quinn, from league of legends, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, calata, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Yosemite close-up illustration.",
        "full": "yosemite, magnificent, close up, details, sharp focus, elegant, highly detailed, illustration, light rays through the trees, by Jordan Grimmer and greg rutkowski, intricate, Beautiful dynamic dramatic dark moody lighting,shadows,cinematic atmosphere, Trending artstation, pixiv, digital Art"
    },
    {
        "display": "SpongeBob and Elon Musk",
        "full": "front shot of spongebob smoking weed with elon musk, hyperdimensional, bioluminescent hypercubes, crystalline!!! holography!!!, (((intricate))), elegant, highly detailed, centered, (((artstation, concept art, smooth, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, grainy, muted, 8K, digitalblasphemy"
    },
    {
        "display": "Cyberpunk ice cream truck",
        "full": "a cyberpunk ice cream truck digital painting, intricate, elegant, highly detailed, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Giant girl in space.",
        "full": "a godly, wise, powerful giant girl wearing a skirt in space holding a model of a Saturn in her left hand. Soft lighting, cosmic skies, stunning, 8K, no planets, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "Glowing larvae creature.",
        "full": "larvae creature writhing on dirt ground as blue light glows from its abdomen, trending on artstation, magic the gathering, style of Brad Rigney and Greg Manchess, masterpiece"
    },
    {
        "display": "Egyptian tech princess poster",
        "full": "symmetry!! egyptian princess of technology, solid cube of light, hard edges, product render retro - futuristic poster scifi, lasers and neon circuits, brown skin woman egyptian princess, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, dreamlike, art by artgerm"
    },
    {
        "display": "Chinese opera machine portrait",
        "full": "portrait of a machine from horizon zero dawn, machine face, upper half portrait, decorated with chinese opera motifs, asian, bian lian, traditional chinese art, intricate, elegant, highly detailed, symmetry, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Futuristic Assamese princess fashion",
        "full": "Assamese bihu mekhela sador pattern gamosa style princess fashion costume design by , D&D futuristic retrofuturistic-sci-fi dynamic, modern stylish glamour tutu, smoked layered, sheath, tunic, laced, ornate cosplay, fantasy, ornamental, intricate, elegant, sensual, highly detailed digital painting, artstation, concept art, painterly, golden ratio, sharp focus, illustration, art by artgerm, Alphonse Mucha and Zdzis\u0142aw Beksi\u0144ski"
    },
    {
        "display": "Flying bus with angel wings",
        "full": "beautiful aesthetic inspiring painting of a bus with massive white angel wings flying in a slightly cloudy sky at sunset, pink orange white sky blue tones, fantasy, ornate and elegant, cinematic, detailed, made in photoshop krita procreate, digital art, in the style of Robert Jeff Lisa Steve Greg Mary, trending on artstation"
    },
    {
        "display": "Young Jose Mujica portrait",
        "full": "a classical portrait of a young jose mujica, dramatic light, city background, sunset, high detail, sharp, painted by greg rutkowski, painted by igor kieryluk, painted by bobby chiu, digital art, trending on artstation"
    },
    {
        "display": "Colorful deep sea cave creatures",
        "full": "of a colorful deep sea cave with strange cute friendly happy creatures with huge eyes, mouth, long tongue and round teeth appearing from sandy coral, in the style of gehry and gaudi, macro lens, shallow depth of field, ultra detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, photorealism, epic, octane render"
    },
    {
        "display": "Mecha female bust design",
        "full": "a mecha bust version of a female with septum piercing, no irises, very symmetrical face, highly detailed, by vitaly bulgarov, by yoji shinkawa, by hideo kojima, by joss nizzi, by ben procter, by steve jung, quintessa, metal gear solid, transformers cinematic universe, concept art world, pinterest, artstation, unreal engine, keyshot"
    },
    {
        "display": "Venus as Empress in Nature",
        "full": "venus, the empress, wearing a magnificent dress, sitting on a divan in the middle of a beautiful green plains full of little flowers. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Goddess of War in Swimsuit",
        "full": "goddess of war, accurate anatomy, swimsuit model body,  only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by art by artgerm and greg rutkowski and edgar maxence"
    },
    {
        "display": "Happy Mask Salesman Portrait",
        "full": "a portrait of the happy mask salesman, grinning, red hair, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney and wlop, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Victorian witch portrait.",
        "full": "full face epic portrait, victorian striga witch glowing eyes, elden ring, matte painting concept art, midjourney, beautifully backlit, swirly vibrant color lines, majestic, cinematic aesthetic, smooth, intricate, 8 k, by ilya kuvshinov, artgerm, darius zawadzki and zdizslaw beksinski"
    },
    {
        "display": "Intergalactic Putin Portrait",
        "full": "a portrait of intergalactic vladimir putin, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Japanese samurai portrait.",
        "full": "portrait of a japanese handsome man with green short hair, a scar on his left eye, holding a katana with his mouth, 3 katanas on his hips, japanese demon aura behind him, wearing a traditional samurai dress, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Charlie Bowater and Gustave Baumann, matte painting"
    },
    {
        "display": "Eren Titan Concept Art",
        "full": "eren titan form from attack on titan, concept art, detailed digital artwork, symmetrical, full body image, highly detailed, highly accurate, deep aesthetic, 8 k, highly ornate intricate details, cinematic lighting, rich colors, ray tracing, hyperrealistic, photorealistic, cinematic landscape, trending on artstation,"
    },
    {
        "display": "Elven young man portrait",
        "full": "portrait painting of an elven eladrin young man with short light orange hair and freckles and tree tattoos on his cheekbones light armor, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Capybara on motorcycle at dusk.",
        "full": "a photorealistic photograph of a knitted cute Capybara wearing stylish sunglasses and dressed in a beanie cap. The subject is riding on a motorcycle in Hollywood at dusk. Palm trees in the background. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and the style of Unreal Engine."
    },
    {
        "display": "Elite sci-fi scientist design",
        "full": "character design by jama jurabaev, elite scientist, scifi, hugo ferdinand boss, high quality, brush stroke, trending on artstation, full body"
    },
    {
        "display": "Bread Hyundai car in landscape",
        "full": "Hyundai car made of bread, concept, 3d render, unreal engine 5, octane render, 4k, low contrast, ray tracing, serene landscape, calm, relaxing, beautiful landscape, highly detailed, high quality, product photo, hyperrealistic, concept art, symmetrical, centered, godrays"
    },
    {
        "display": "Fashion catwalk on boat",
        "full": "A fashion Catwalk on a fishing boat, Fashion Photography, Concept Art, Octane, Redshift, High Detail"
    },
    {
        "display": "Vibrant Hong Kong Fallout Art",
        "full": "fallout hong kong causeway bay concept art, vibrant colors, 8 k photorealistic, hd, high details, trending on artstation"
    },
    {
        "display": "Symmetrical portrait in orchard",
        "full": "symmetry!! portrait of young woman cursed with ever - increasing intelligence beauty and virtue, slice - of - life, realism, in apple orchard!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mystic portrait of La Llorona",
        "full": "a wlop 3 d render of very very very very highly detailed beautiful mystic portrait of the curse of la llorona and horror background by anton pieck, intricate, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art,"
    },
    {
        "display": "Cell-shaded Horizon Zero Dawn",
        "full": "a study of cell shaded cartoon of horizon zero dawn, illustration, wide shot, vibrant colors, concept art by josan gonzales and wlop, by james jean, victo ngai, david rubin, mike mignola, laurie greasley, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Cyberpunk Vivian Leigh Portrait",
        "full": "closeup portrait of a young vivian leigh as cyberpunk mercenary, sunglasses, gritty, city background, megacity, dramatic light, gorgeous view, depth, high detail, digital art, painted by greg rutkowski, trending on artstation"
    },
    {
        "display": "Cyberpunk pilot concept art",
        "full": "concept art of cyberpunk pilot fighter by jama jurabaev, brush stroke, scifi accessories, trending on artstation, symmetry, high quality, extremely detailed, glass"
    },
    {
        "display": "\"Muscular character holding keg\"",
        "full": "gigachad ron swanson bodybuilder holding a keg of beer in final fight beach by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Tattooed pinup warrior design",
        "full": "brigitte bardot as a tattooed pinup warrior, character sheet, concept design, contrast, hot toys, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Intricate fantasy illustration",
        "full": "Gabriel Knight, fantasy, intricate, elegant, highly detailed, digital painting, 4k, HDR, concept art, smooth, sharp focus, illustration, art by artgerm and H R Giger and alphonse mucha"
    },
    {
        "display": "Lifelike pencil portrait of Stevie Nicks",
        "full": "beautiful lifelike award winning pencil illustration of stevie nicks trending on art station artgerm cinematic atmospheric"
    },
    {
        "display": "Ethereal female cleric.",
        "full": "a beautiful female cleric, ethereal, dreamy, backlit, highly detailed, sad expression, eyes closed, mouth half open, realistic lighting, sharp focus, windswept, rule of thirds, symmetrical facial features, by artgerm, wlop, rossdraws, frank frazetta, andrei riabovitchev, trending on artstation, hd, 4 k, fantasy"
    },
    {
        "display": "Cute cyberpunk bunny with gun",
        "full": "super cute cyberpunk bunny, holding a gun, pixar, zootopia, cgi, trending on artstation"
    },
    {
        "display": "Victorian-inspired magical town",
        "full": "a lively magical town inspired by victorian england and amsterdam, sunny weather, highly detailed, intricate, digital painting, trending on artstation, concept art, matte painting, art by greg rutkwowski, craig mullins, octane render, 8 k, unreal engine"
    },
    {
        "display": "Tropical winding beach scene",
        "full": "A long and winding beach, tropical, bright, simple, by Studio Ghibli and Greg Rutkowski, artstation"
    },
    {
        "display": "Cyborg Samurai Illustration",
        "full": "ultra realistic illustration of cyborg samurai, intricate, warframe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic 8 k hd movie shot, art by akihiko yoshida and tim mcburnie and anato finnstark"
    },
    {
        "display": "Ruined castle in valley.",
        "full": "A vast verdant empty valley surrounded by Transylvanian mountains. A medieval castle in ruins on the mountainside. Accurate details, dramatic lighting, golden hour, stormy weather, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by Gustave Dore, octane render"
    },
    {
        "display": "Cute badass woman illustration",
        "full": "3 / 4 view of a cute badass woman, shark wings, confident pose, pixie, genshin impact, surrrounded by salmon pink coral reefs, intricate, elegant, sharp focus, illustration, highly detailed, concept art, matte, trending on artstation, anime, art by wlop and artgerm and greg rutkowski, ilya kuvshinov, strong strokes, photo of asuna from sao h 6 4 0"
    },
    {
        "display": "Cybernetic duchess portrait",
        "full": "a portrait of a cybernetic duchess of hell, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzalez, digital art, unreal engine 5, trending on artstation, deviantart, pinterest, rule of thirds, 4 k uhd image"
    },
    {
        "display": "Plant goddess portrait",
        "full": "a beautiful portrait of a plant goddess with closed eyes by Greg Rutkowski and Raymond Swanland, Trending on Artstation, ultra realistic digital art"
    },
    {
        "display": "Lifelike pencil portrait of Ricki Lake.",
        "full": "amazing lifelike award winning pencil illustration of ricki lake trending on art station artgerm Greg rutkowski cinematic"
    },
    {
        "display": "Friends walking in Tokyo.",
        "full": "two friends on a walk in tokyo, details, sharp focus, illustration, by jordan grimmer and greg rutkowski, trending artstation, pixiv, digital art"
    },
    {
        "display": "3D anime schoolgirls portrait.",
        "full": "3d dark infrared octane render concept art by D. Jun, by Mo Xiang Tong Xiu, by Igarashi Daisuke, beauty portrait anime three schoolgirls under dark pink and blue water. pretty cute faces. good eyes. sunrise. dramatic light, trending on artstation, oil painting, noise."
    },
    {
        "display": "Mischievous young Latina witch.",
        "full": "full shot cute mischievous young witch about to get up to some trouble, latin american fashion, floral patterns, black and orange palette, magic, latina girl, brown skin, defined facial features, symmetrical facial features. by ruan jia and artgerm and range murata and wlop and william adolphe bouguereau, key art, fantasy illustration, award winning, artstation, intricate realistic hyperdetailed 8 k hdr"
    },
    {
        "display": "Detailed digital painting of innkeeper.",
        "full": "inn keeper, sweaty, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Monstrous character design.",
        "full": "monstrous character design, by antonio j. manzanedo, giger, alex grey, android jones, wayne barlowe, philippe druillet, raymond swanland, cyril rolando, josephine wall, harumi hironaka, trending on artstation"
    },
    {
        "display": "Ultra-realistic Bella Thorne portrait.",
        "full": "ultra realistic illustration, bella thorne, flirtatious, tease, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Obese Steven Seagal illustration",
        "full": "Obese Steven Seagal, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Woman in enchanted forest",
        "full": "a 3 5 year old woman with straight brown hair and blue eyes and black framed glasses in an enchanted forest with tiny fairies, a beautiful painting with highly detailed face by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cityscape of human faces",
        "full": "looking down the street of a city built with human faces, 3 d video game, horror, gritty atmosphere, octane render, depth of field, unreal engine 5, dark colors, trending on artstation, ultra high detail, ultra realistic, cinematic, focused, 8 k"
    },
    {
        "display": "Brunette sorceress casting spells",
        "full": "sorceress by artgerm, brunette, casting a spell, rainbow coloured magic sparks, full body"
    },
    {
        "display": "Elven princess meditating glowingly.",
        "full": "glowing cracks, elven princess, meditating, peaceful, levitating, powerful, blossoming, lotus pose, zen, glowing, fractal background, ascending, detailed, realistic, digital art, fantasy, trending on artstation, cinematic, movie clip, visionary art, intricate pattern, subtle pattern, detailed texture, fractal texture, flowing, engraved texture, sacred geometry pattern, symmetry, perfect, perfect face, facial beauty, pretty, attractive, by peter morhbacher, dmt temple, godlike, pearlescent, matte painting, highly detailed painting, light, light being, feathered, smooth, radial color dispersion, color dispersion"
    },
    {
        "display": "Elvis Presley manga portrait.",
        "full": "elvis presley, manga cover art, detailed color portrait, artstation trending, 8 k, greg rutkowski"
    },
    {
        "display": "Flying red goat artwork.",
        "full": "a red goat flying through the center of the earth, digital painting, unreal engine, octane render, 4 k wallpaper, intense, intricate brush stroke detail, beautiful, gorgeous, by tite kubo, kentaro miura, no more heroes concept art"
    },
    {
        "display": "Strong horse digital art",
        "full": "strong horse, digital art, highquality, trending on artstation"
    },
    {
        "display": "Underwater temple illustration",
        "full": "sunken underwater temple in the abyss, fish, palladian, doric, illustration, concept art, digital art, colorful, blue, detailed, by yanjun chen, by garry larrson, arstation"
    },
    {
        "display": "Cyborg biomechanical snake.",
        "full": "Concept art character sheet of a cyborg biomechanical snake, sci-fi, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Smiling girl illustration.",
        "full": "a girl smiling, Tristan Eaton, victo ngai, artgerm, RHADS, ross draws"
    },
    {
        "display": "Cyborg Super Saiyan Portrait",
        "full": "a character portrait of a cyborg super saiyan in the style of moebius in the style of yoji shinkawa trending on artstation deviantart pinterest detailed realistic hd 8 k high resolution"
    },
    {
        "display": "Big hand with skull",
        "full": "big hand holding skull and bones, illustrated by Simon St\u00e5lenhag and Gaston Bussiere, intricate, ultra detailed, photorealistic, trending on artstation"
    },
    {
        "display": "Glowing blue-eyed angel portrait.",
        "full": "Her huge ominous glowing blue eyes staring into my soul , perfect eyes, soft pale white skin, intricate stunning highly detailed, agostino arrivabene, artgerm, twisted dark lucid dream, 8k portrait render, raven angel wings, swirling thick smoke , beautiful lighting, dark fantasy art, cgsociety"
    },
    {
        "display": "Frost prince in court",
        "full": "the prince of frost standing alone in his court, archfey, full - body portrait, fantasy, white hair, blue skin, wild eyebrows, young adult, elf, crown, hard edges, soft lighting, professional lighting, trending on artstation"
    },
    {
        "display": "Male cottagecore Snoop Dogg.",
        "full": "male cottagecore snoop dogg, Calvin Cordozar Broadus Jr., intricate, swagger, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lifelike pencil illustration of Bob Dylan.",
        "full": "amazing lifelike award winning pencil illustration of bob dylan trending on art station artgerm greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Vislor Turlough at alien pub.",
        "full": "medium - shot vislor turlough played by mark strickson at age 1 8, beautiful, at the alien space pub bar counter, highly detailed, mood lighting, artstation, highly detailed digital painting, smooth, global illumination, fantasy art by greg rutkowsky, karl spitzweg, leyendecker"
    },
    {
        "display": "Multiverse horror portal.",
        "full": "portal to the multiverse of infinite horror with freakish spider creatures leaking out by laurie greasley digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, octane render, bump map, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Fractal spaceship concept art",
        "full": "concept art of fractal shaped spaceship by Michal Klimczak, high detail, artstation CG society"
    },
    {
        "display": "Cyberpunk wildlife battle, Antarctica.",
        "full": "distant cyberpunk wildlife fighting on the ice of antarctica, ice bergs, snowy cliffs, sparkling water, glistening, clear blue sky, midday sun, harsh light, hyper realistic, hyper detailed, digital art, trending in artstation, studio quality, photorealistic, photo, by jesper ejsing, wlop, paul lehr"
    },
    {
        "display": "Girl with hair leaves.",
        "full": "girl with super long hair, hair becoming autumn red leaves, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Floral Empress Portrait",
        "full": "a beautiful empress portrait, with a brilliant, impossible striking big flower headpiece, clothes entirely made out of flowers, symmetrical, dramatic studio lighting, rococo, baroque, jewels, asian, hyperrealism, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, 8k, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Cosmic aluminum rocket ship",
        "full": "shiny aluminum rocket ship in cosmic space by tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, star trek, enterprise, dust, diffusion, solar sails, sun, colorful nebulae, swirling galaxies, shining metal, glowing engines, masterpiece, trending on artstation, cinematic composition, beautiful lighting, sharp, details, hd, 8 k"
    },
    {
        "display": "Organic house in forest",
        "full": "beautiful organic house made of imaginary plants in a forest, architectural render, futuresynth, chillwave, by Gabriel Dawe, by Skottie Young, by Jessica Rossier, vegetal architecture, by moebius, by Isaac Cordal, night, (mist), lights, junglepunk, blender, trending on artstation"
    },
    {
        "display": "Elegant 1920s man with cane",
        "full": "skinny man with a cane, long dark hair, 1 9 2 0 s fashion, elegant, highly detailed, intricate, smooth, sharp focus, artstation, digital paining, concept art, art by donato giancola, greg rutkowski, artgerm, cedric peyravernay, valentina remenar, craig mullins"
    },
    {
        "display": "Intricate otherworldly digital art",
        "full": "otherworldly, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha, by Jesper Ejsing, by RHADS, Makoto Shinkai and Lois van baarle, ilya kuvshinov, rossdraws"
    },
    {
        "display": "Goddess portrait with flowers.",
        "full": "breathtaking detailed concept art painting portrait of the hugs goddess of blue flowers, carroty curly hair, orthodox saint, with anxious piercing eyes, ornate background, amalgamation of leaves and flowers, by hsiao - ron cheng, extremely moody lighting, 8 k"
    },
    {
        "display": "Smooth McGroove in Arcane",
        "full": "smooth mcgroove in arcane tv series, holding a white randy rhoads guitar rr 1, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pizarro laughing at Inca temple.",
        "full": "spanish conquer soldier francisco pizarro perverse laughter on a inca temple, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Symmetrical warrior portrait.",
        "full": "symmetry! portrait of a old male caucasian warrior, face decorated with chinese patterns, assassin's creed, horizon zero dawn machine, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lightning, ue 5, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Epic Warhammer Spiderman Portrait",
        "full": "portrait of a warhammer 4 0 k spiderman, epic, tragic, military art, fantasy, dieselpunk, hd shot, digital portrait, beautiful, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Stylized South Asian avatar portrait",
        "full": "portrait of a modern (south asian) man wearing a dark shirt, upper body 2d game avatar, Donato Giancola, Kodak film stock, chiaroscuro lighting, stylized bold outline, default pose neutral expression, expressionist colour, face-on head shot, close-up, eye-contact, sharp focus, shape language, Alphonse Mucha/Gustav Klimt style, alpha masked transparent flat background, 4k, volumetric lighting, French Nouveau, trending on artstation, octane render, ultra detailed, hyperrealistic"
    },
    {
        "display": "Anime-style fantasy illustration",
        "full": "baroque oil painting japanese light novel cover illustration lotr anime key visual full body action shot | young girl black military crusader armour | long flowing brown hair symmetrical perfect face | gapmoe kuudere moody lighting stunning bokeh highlights sharp contrast battlefield scene | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Detailed portrait of Haifa Wehbe.",
        "full": "portait of haifa wehbe, concept art, long hair centred, hd, very detailed curve, digital painting, unreal engine, amazing background"
    },
    {
        "display": "Dark fantasy knight artwork",
        "full": "The Last Knight. 8k resolution, beautiful, dark ambient, realism, art, artwork, concept art, matte painting, trending on Artstation, Unreal Engine,bart style by Albert Bierstadt"
    },
    {
        "display": "Cyberpunk chrome portrait",
        "full": "a portrait of a cyberpunk all chrome, Night City, cyberpunk 2077, very very coherent painting, 1979 OMNI Magazine Cover, street level neo-Tokyo in Cyberpunk 2045 style by Vincent Di Fate by mark arian by artgerm, 4k, 8k, HD, trending on artstation"
    },
    {
        "display": "Greta Thunberg as Supergirl.",
        "full": "greta thunberg as supergirl, detailed digital art, trending on Artstation"
    },
    {
        "display": "Magician Margot Robbie casting spells.",
        "full": "margot robbie as a beautiful magician casting colorful spells, fantasy art, in the style of Fernando Juarez, illustration, epic art, fantasy, intricate, elgant, amazing detail, digital painting, artstation, concept art, smooth, sharp focus"
    },
    {
        "display": "Hisoka Morow Portrait",
        "full": "portrait of hisoka morow hunter hunter, male, sharp jaw yellow eyes narrow sultry eyes red hair soft bunched hair swept back crimson medium length hair, anime, fantasy, intricate, elegant, highly detailed, digital painting, artstation sharp focus, madonna rebel heart art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vibrant Pixar-style cat illustration.",
        "full": "cat in a room. digital art. artstation. realistic. vibrant. illustration. in the style of pixar movie. octane render. art by artgerm and greg rutkowski and alphonse mucha. volumetric lighting."
    },
    {
        "display": "Intricate Thanos Ink Sketch",
        "full": "symmetry concpet art, full shot, traditional ink, sketch, of thanos, line sketch, intricate, elegant, highly detailed, monochrome, digital painting, artstation, concept art, sharp focus, illustration, art by borderlands 3 and peter polach"
    },
    {
        "display": "Fashion model headshot",
        "full": "professional head shot concept art of a beautiful fashion model wearing catholic spiritual clothes by reza nia, nick knight, amy judd, jil sander minimal elite style, posed, hyper realistic face, beautiful brown eyes, flushed cheeks, professional makeup, studio, studio lighting, medium format 8 0 mm lens, flat natural tones, sharp focus, 8 k, stunning matte painting"
    },
    {
        "display": "Vibrant summer chill painting",
        "full": "beautiful painting of summer chill day, by Kenne Gregoire, James Jean, Tran Nguyen, WLOP, Jakub Rebelka. trending on Artstation, 8k, masterpiece, chill summer, graffiti paint, fine detail, full of color, intricate detail, golden ratio illustration"
    },
    {
        "display": "Hybrid dragon-octopus painting",
        "full": "painting of hybrid dragon!!! and ( ( ( ( octopus ) ) ) ) fused together, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Gigachad Luigi Portrait",
        "full": "gigachad luigi in one punch man by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, planet background by laurie greasley, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Cyberpunk souls in metaverse.",
        "full": "whirlwind of souls rushing inside the metaverse, half body, glowin eye, tiara, tattoo pharaoh, android, cyborg, cyberpunk face, by loish, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Young James Dean Anarchist",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork costume design: young James Dean as a well-kept neat anarchist rebel in 1950s USSR mechanic overalls and big boots. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Medieval forged steel shortsword.",
        "full": "basic steel sword, shortsword, wood, leather, medieval, forged, blacksmith, product design, final result, art by gerald brom, greg rutkowski and artgerm and james jean and zdzis\u0142aw beksinski, 8 k, unreal engine, c 4 d"
    },
    {
        "display": "Mischievous Elven Queen Portrait",
        "full": "portrait of mischievous, baleful Cate Blanchett's Galadriel as a queen of elves, dressed in a beautiful silver dress. The background is a dark, creepy eastern europen forrest.  night, horroristic shadows, high contrasts, lumnious, theatrical, character concept art by ruan jia, thomas kinkade, and J.Dickenson, trending on Artstation"
    },
    {
        "display": "Schoolgirl hacking volcano scene",
        "full": "close-up of a 21 year old schoolgirl hacking laptop on Diamond Head volcano, thermal lava flowing down dark travertine terraces, intricate, elegant, luxurious, digital painting, concept art, smooth, sharp focus, from Star Trek 2021, illustration, by WLOP and Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau, Artgerm"
    },
    {
        "display": "Epic dog portrait, cinematic.",
        "full": "cinematic portrait of brutal epic dog, concept art, artstation, glowing lights, highly detailed"
    },
    {
        "display": "Anime secretary portrait",
        "full": "portrait of the secretary, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, trending on artstation"
    },
    {
        "display": "Intricate floral design.",
        "full": "floral design with a fine structure and flowing colors and blossoms. high details, artstation trending"
    },
    {
        "display": "Neko woman in lab.",
        "full": "art of a neko woman with cat ears wearing a labcoat in a chemistry lab, glassware on table, detailed digital artwork, beautiful face, by yoshitaka amano, by greg rutkowski, by hayao miyazaki, trending on artstation, expressive painting, beautiful lighting, octane render, 4 k, warm colors"
    },
    {
        "display": "Futuristic mecha spider tank.",
        "full": "huge giant massive mechanical ominous futuristic cybernetic evangelion spider tank mecha with weaponized fangs, at a crowd of screaming scared panicked people in a busy city street in the evening, explosions, dust, smoke, war of the worlds, by artgerm, by beeple, cinematic, hyper realism, high detail, octane render, vibrant colors, smooth gradients, high contrast"
    },
    {
        "display": "Ultra-realistic Nicki Minaj portrait",
        "full": "nicki minaj curvy, ultra realistic full body portrait, concept design, contrast, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect : : twerking"
    },
    {
        "display": "Cinematic portrait of Mask Salesman",
        "full": "a cinematic portrait of the happy mask salesman, half - moon eyes, art by lois van baarle and loish and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Norse moon goddess portrait",
        "full": "portrait of a norse moon goddess with orange skin and a ceramic face mask, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Alien cat in zero gravity.",
        "full": "duotone noir scifi concept dynamic illustration of 3 d mesh of alien cat inside box floating zero gravity glowing 3 d mesh portals futuristic, glowing eyes, octane render, surreal atmosphere, volumetric lighting. accidental renaissance. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. trending on artstation"
    },
    {
        "display": "Detailed Bulma Portrait",
        "full": "highly detailed vfx portrait of bulma by eiichiro oda!, stephen bliss, greg rutkowski, loish, rhads, beeple, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, stanley kubrick, backlit, harsh overhead sunlight,"
    },
    {
        "display": "Chris Evans as Wolverine",
        "full": "Chris Evans as wolverine, intricate, futuristic, fantasy, elegant, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell,"
    },
    {
        "display": "Colorful Electra Heart portrait",
        "full": "portrait of a beautiful marina diamandis electra heart, volume lighting, concept art, by greg rutkowski!!, colorful, xray melting colors!!"
    },
    {
        "display": "Mountain temple ruins at night",
        "full": "Gorge in the mountain, white stone temple ruins, night dramatic lighting, blue and purple tones, wide camera angle, matte painting, trending on ArtStation, concept art, delightful surroundings, high detail"
    },
    {
        "display": "Realistic night portrait.",
        "full": "portrait yoto hime, fine details. night setting. realistic shaded lighting poster by craig mullism, artgerm, jeremy lipkin and michael garmash, unreal engine, vibrant colors and hard shadows and strong rim light, radiant light, detailed and intricate environment, digital art"
    },
    {
        "display": "Surreal vaporwave dreamscape.",
        "full": "masterpiece, hyperrealistic surrealism, award winning masterpiece with incredible details, epic stunning, infinity pool, a surreal vaporwave liminal space, highly detailed, trending on ArtStation, calming, meditative, pink arches, flowing silk sheets, palm trees, very vaporwave, very very surreal, sharp details, dreamscape"
    },
    {
        "display": "Slaves constructing giant robot.",
        "full": "hundreds of slaves building a giant monumental robot in the middle of the desert, lit by candles, digital painting, artstation, concept art, craig mullins, breathtaking, 8 k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Sad hermit in woods.",
        "full": "a sad old master heremit, full white beard, walking with a lantern and a staff tought the woods. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Exploding water die",
        "full": "a twenty sided die made from water exploding, simple shape, vector, illustrator, clean, concept art, perfect straight lines, extremely detailed, unreal engine render"
    },
    {
        "display": "Portrait of Jimmy Page",
        "full": "amazing artgerm portrait of jimmy page in his 7 0 s as a christian - era painting, collaboration with j. scott campbell and artgerm with edward burn jones"
    },
    {
        "display": "Teenage half-elf monk combat",
        "full": "picture of a teenager half elf monk, leather coat, black shirt, combat pose, ponytail, energetic smile, dnd, highly detailed, detailed face, grayscale, manga illustration, black and white, by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Female knight in fantasy city",
        "full": "knight, female, fantasy city in background, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, concept art, matte, sharp focus, illustration, hearthstone, shallow depth of field, dramatic lighting, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Cat gazing at galaxy.",
        "full": "cat looking at beautiful colorful galaxy, high detail, digital art, beautiful , concept art,fantasy art, 4k"
    },
    {
        "display": "Cthulhu destroys spacestation.",
        "full": "An epic beautiful 3D render of an ancient terrifying Cthulhu in space, consuming and eating and destroying a futuristic colorful spacestation.  Bright explosions.  By Ken Fairclough and Dylan Cole, trending on behance, trending on artstation, award winning.  Cinematic, film screenshot directed by Christopher Nolan and Denis Villeneuve.  PBR, path tracing, octane render, highly detailed."
    },
    {
        "display": "Photorealistic music studio mandala.",
        "full": "a complex photoreal music studio with keyboards and modular synthesizers, a sacred mandelbulb mandala spiral  made of dragons made of complex biomechanical vintage machinery and old robot parts with intertwining cables made of old tree roots and glowing gemstones and synthesiser parts by Ellen Jewett, by donato giancola, by amanda sage, by beeple, particle effects, intricate detail, trending on cgsociety, trending on artstation, 8k 3d, high definition, jpeg artifacts, f/16 lens"
    },
    {
        "display": "Cute, elegant female character.",
        "full": "female character Jenny Garfield, closeup, cute, modern, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Elegant Belle illustration",
        "full": "emma watson belle, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Governments enjoying cocktails, war incited.",
        "full": "governments drinking cocktsil together while making their people wage war on each other by Craig Mullins, ilya kuvshinov, krenz cushart, artgerm trending on artstation by Edward Hopper and Dan Mumford and WLOP, Unreal Engine 5, Lumen, Nanite"
    },
    {
        "display": "Anime girl in sunflower field",
        "full": "girl with a sunhat, in field of sunflowers, blue sky | | very very anime!!!, fine - face, audrey plaza, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Yoda smoking a joint.",
        "full": "Yoda smoking a joint, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Creepy noh mask character",
        "full": "a man wearing a creepy noh mask and black suit looming, character concept art by tim bradstreet"
    },
    {
        "display": "Witch portrait on beach.",
        "full": "A portrait of a Olivia Taylor Dudley as a witch on the Beach by Ruan Jia and Mandy Jurgens and Artgerm and william-adolphe bouguerea, highly detailed, trending on artstation, award winning, H 768"
    },
    {
        "display": "Neon-illustrated Asian girl",
        "full": "ethnic asian girl wearing a skimpy clothes, digital illustration by ruan jia on artstation, outlined by whirling illuminated neon lines and fine lines swirling in circles by jesper ejsing and rhads and makoto and shinkai and lois van baarle, digital art, trending on artstation"
    },
    {
        "display": "Cheshire cat portrait with crabs",
        "full": "grinning cheshire cat, portrait of spider idris woman, crabs, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Golden mouse in space.",
        "full": "a golden mouse flying through space, Greg rutkowski award winning illustration, digital art, sci fi concept art, 4k, trending on artstation,"
    },
    {
        "display": "Elegant Middle Eastern Man",
        "full": "portrait of an elegant middle eastern man with elegant, defined features in labyrinth with ear - length wavy black hair wearing a sufi turban glowing teardrop - shaped prism pendant on a leather thong, elegant, highly detailed, concept art, smooth, sharp focus, luminescent, colorful swirly magic ripples, pastel colors, by huang guangjian, sachin teng, greg rutkowski, jesper ejsing, ilya kuvshinov, artgerm, 8 k"
    },
    {
        "display": "Realistic portrait of survival",
        "full": "cheated death | | realistic shaded, fine details, fine - face, pretty face, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin, michael garmash, rob rey"
    },
    {
        "display": "Lively Socotra Island Landscape",
        "full": "Lively landscape of a socotra island, realistic detailed digital art by Maxwell Boas Jessica Rossier Christian Dimitrov Anton Fadeev trending on Artstation CGSociety rendered in Unreal Engine 4k HQ"
    },
    {
        "display": "Pale Indian doctors in space",
        "full": "Sensual good looking pale young Indian doctors wearing jeans asleep in a space station above Earth performing surgery, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Muscular female gnome engineer",
        "full": "beautiful bulky muscular female gnome engineer artificer, metal gauntlet, short black hair, naval landscape, full body portrait, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, centred, rule of thirds, concept art, matte, sharp focus, illustration, cover by artgerm, art by greg rutkowski"
    },
    {
        "display": "Romantic river scene, anime.",
        "full": "a boy and a girl with long flowing auburn hair, boy has short black hair, sitting next to each other, facing the river in one single boat. atmospheric lighting, long shot, romantic, boy and girl are the focus, trees, blue water, narrow river, close river bank, shady. anime. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Intricate floral portrait.",
        "full": "pink petals with a ahape of a wonderful aubrey plaza and christina ricci and sarah hyland and mila kunis and olivia newton john, intricate, elegant, highly detailed, wonderful eyes, sweet, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and concept art"
    },
    {
        "display": "Ornate medieval hobbit homes",
        "full": "medieval hobbit homes, ornate, beautiful, atmosphere, vibe, mist, smoke, chimney, rain, well, wet, pristine, puddles, waterwheel, waterfall, frozen, melting, melting, dripping, snow, creek, lush, ice, bridge, cart, orange, green, stained glass, forest, flowers, concept art illustration, color page, 4 k, tone mapping, doll, akihiko yoshida, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, digital illustration, greg rutowski, volumetric lighting, sunbeams, particles, trending on artstation"
    },
    {
        "display": "Psychedelic shaman portrait",
        "full": "cinematic bust portrait of psychedelic shaman, head and chest only, exotic alien features, Tim Hildebrandt, Wayne Barlowe, Bruce Pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper-detailed, HD, HDR, 4K, 8K"
    },
    {
        "display": "Cyborg girl portrait illustration",
        "full": "a beautiful cyborg girl, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Furious Paladin Warrior Illustration",
        "full": "paladin, furious gorgeous woman, face covered in dirt, lord of the rings ,neon, fibonacci, sweaty, dynamic action pose, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyper-detailed bee habitat.",
        "full": "Bee Home hyper detailed, artstation, 8k render, unreal engine, digital art, concept art"
    },
    {
        "display": "Elegant fantasy portrait.",
        "full": "A combination of Victoria Justice's and Grace Kelly's faces, western, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Baroque anime maid scene",
        "full": "baroque oil painting, anime key visual environment concept art, anime maid nazi nuremberg, blond hair blue eyes, brutalist dark fantasy, trending pixiv fanbox, rule of thirds golden ratio, fake detail, acrylic palette knife, style of makoto shinkai genshin impact, background by studio ghibli jamie wyeth greg rutkowski chiho aoshima"
    },
    {
        "display": "Samurai Ninja Spider-Man Armor",
        "full": "full samurai ninja armor  spiderman , muscular, extremely detailed eyes, fantastic details full face, mouth, trending on artstation, pixiv, cgsociety, hyperdetailed Unreal Engine 4k 8k ultra HD,  WLOP"
    },
    {
        "display": "Woman in glasses portrait",
        "full": "a drawing of a woman wearing glasses and a sweater, a character portrait by L\u00fc Ji, pixiv contest winner, digital art, sketchfab, flat shading, speedpainting"
    },
    {
        "display": "Eldritch Persian landscape render",
        "full": "apophysis mandelbulb 3 d render of a eldritch landscape of persian castles, fish, tentacles, alien anatomy, arches and gothic towers trending on artstation"
    },
    {
        "display": "Armadillo warrior portrait",
        "full": "Huge armadillo warrior in armor, portrait, woodlands, magic the gathering artwork, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Justin Bieber as Beaver",
        "full": "hyperrealistic dslr film still of ( ( justin bieber ) ) disguised as a beaver, beaver face, stunning 8 k octane comprehensive 3 d render, inspired by istvan sandorfi & greg rutkowski & unreal engine, perfect symmetry, dim volumetric cinematic lighting, extremely hyper - detailed, incredibly real lifelike attributes & flesh texture, intricate, masterpiece, artstation, stunning"
    },
    {
        "display": "Chrome robots vs. alien creatures",
        "full": "epic army of chrome robots battle creatures on alien planet, landscape, alex ross, neal adams, david finch, war, concept art, matte painting, highly detailed, rule of thirds, dynamic lighting, cinematic, detailed, denoised, centerd"
    },
    {
        "display": "3D goddess portrait with mask.",
        "full": "3 d goddess wide angle portrait with feathers, fur, and bones. beautiful intricately detailed avante garde wolf mask and retrowave sorceress outfit. vibrant butterflies, bio luminescent, mycelium, mushrooms, plasma, creature, artwork by tooth wu and android jones wlop and android jones and beetle and greg rutkowski"
    },
    {
        "display": "Cinematic robot shaman illustration.",
        "full": "diablo action game robot shaman by artgerm, greg rutkowski, alphonse mucha and cgsociety highly detailed, sharp focus, cinematic lighting, illustration, art, octane render, unreal engine lumen, very coherent. cinematic, hyper realism, high detail, octane render, 8 k"
    },
    {
        "display": "Kraken vs. Pirate Ship",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Kraken fighting pirate ship. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Post-apocalyptic warzone artwork",
        "full": "a highly detailed matte painting of a post apocalyptic irradiated warzone with broken world war one tanks and discarded power armor, by studio ghibli, makoto shinkai, by artgerm, by wlop, by greg rutkowski, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Marilyn Monroe as Playboy Bunny",
        "full": "highly detailed portrait of marilyn monroe, back view, bunny ears headband playboy, tight clothing, playboy bunny costume, velvet stockings, fullbody, in the walking dead, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant"
    },
    {
        "display": "Black kids in abstract.",
        "full": "black kids mixing with  together in a cloudy red abstract background , a detailed painting by Naudline Cluvie Pierre, trending on artstation"
    },
    {
        "display": "Minas Tirith concept art.",
        "full": "the lord of the rings, minas tirith, concept art, highly detailed, artstation, trending, widescreen resolution, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "\"Fantasy Paladin in Combat\"",
        "full": "full body, attack position abstract painting in lush fantasy environment of a ornate holy mechanical warforged with circular glowing eye, character in yellow armor holding a legendary paladin engraved holy great longsword and carrying a huge heavy paladin shield, vertically flat head, face in focus, epic , trending on ArtStation, masterpiece,  cinematic lighting, by Ross Tran and by Greg Rutkowski and by Christopher Balaskas"
    },
    {
        "display": "Goth couple family portrait",
        "full": "a tall attractive goth girl and a strong buff handsome man, family photo, cute, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dieselpunk spaceship underwater.",
        "full": "a dieselpunk spaceship lies at the bottom of the sea in a coral reef, underwater, matte painting, submecanophobia, fantasy, intricate, elegant, digital painting, trending on artstation, concept art, sharp focus, illustration by john berkey, vibrant colors, 4 k."
    },
    {
        "display": "Gothic rainbow goddess portrait",
        "full": "a portrait of the lisa frank leopard print rainbow goddess from walmart, intricate, gothic, highly detailed, digital painting, crown of skulls, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Duotone noir portrait",
        "full": "duotone noir crimson concept illustration 3 / 4 portrait of bela lugosi. cinematic scene film noir. volumetric lighting. golden rario accidental renaissance. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. concept art. trending on artstation"
    },
    {
        "display": "Male Seraphim King Illustration",
        "full": "A beautiful digital painting of a male Seraphim, king, the moon behind her, intricate, cinematic lighting, highly detailed, digital painting, Artstation, concept art, smooth, sharp focus, illustration, art by Tom Bagshaw, Artgerm and Greg Rutkowski"
    },
    {
        "display": "Divine Sphynx Cat Artwork",
        "full": "godlike sphynx cat, animal sphynx cat, anthropomorphic sphynx cat, deity, holy robes, holy light aura, ultra details, art by artgerm, dwayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Devil cast out of heaven",
        "full": "the devil cast out of heaven by yusuke murata and makoto shinkai, 8k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Paul Rudd as Lancelot",
        "full": "ultra realistic illustration, paul rudd as lancelot from baldurs gate and diablo, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Zombie friends eating pizza.",
        "full": "beautiful detailed photorealistic painting of a group of friends dressed as zombies round a table eating slices of pizza. they are smiling and having fun. the friends are happy and having fun. vibrant, high quality, vibrant colors, very funny, beautiful, hq. hd. 4 k. award winning. trending on artstation"
    },
    {
        "display": "Cyberpunk cyborg portrait.",
        "full": "portrait of lana del rey as a cyberpunk cyborg. sci - fi intricate abstract upper body intricate artwork, roses, rose petals by tooth wu, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski, asymmetrical, cinematic arthouse, key art, hyper realism, iridescent accents"
    },
    {
        "display": "Flawless Jessica Rabbit photography",
        "full": "photography beautiful flawless jessica rabbit in her red dress by greg rutkowski and artgerm, femme fetal, darkroom, dramatic high contrast lighting like sin city, ultra - realistic, intricate detail, 8 k, octane render"
    },
    {
        "display": "Dynamic fantasy character design.",
        "full": "aeon flux as mrs incredible picture by Greg Rutkowski, dynamic pose, matte painting, intricate, fantasy concept art, elegant, by Stanley Artgerm Lau, WLOP, golden ratio, thomas kinkade, alphonse mucha, loish, Peter chung, norman Rockwell,"
    },
    {
        "display": "Pregnant female warrior portrait",
        "full": "**  professional  portrait of  pregnant peacefull cryogenic female   dynamic pose ,  armor elements , long dark hair, beautiful bone structure, symmetrical facial features, intricate, elegant, digital painting, concept art, smooth, sharp focus, illustration,  by Ruan Jia and Mandy Jurgens , and mucha, and Artgerm and William-Adolphe Bouguerea"
    },
    {
        "display": "Underwater portrait at sunrise",
        "full": "portrait of asian woman underwater during sunrise, sunrays, aquaman aesthetic, caustics, rippling water, photoshoot, flowing hair, haunting, iconic, fine-art, masterpiece, cinematic, trending on artstation"
    },
    {
        "display": "Cinematic hyperspace art.",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, hyperspace, vibrant colors, hms iron nuke"
    },
    {
        "display": "Detailed black and white Shrek.",
        "full": "black and white muscular shrek has beard, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dreamy architectural concept art",
        "full": "architectural sketch, concept art, masterpiece, beautiful dreamy breathtaking, trending on artstation, raytracing, stunning scene - i - s 2 3 7 3 0 1 9 6 6 8"
    },
    {
        "display": "Chrome spacesuits, dancing cephalopod.",
        "full": "insanely detailed procedural render expressive dynamic fluid action scene of chrome spacesuits protecting the dancing cephalopod girl from certain doom as the planet they orbit sends spores attack them, photorealism, sharp focus, award winning, tristan eaton, victo ngai,, maxfield parrish, artgerm, koons, ryden, intricate details, 3 / 4 view, bokeh"
    },
    {
        "display": "Stormy Cloud Goddess Art",
        "full": "character concept art of a stormy cloud goddess | | cute - fine - face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Anime vampire portrait.",
        "full": "anime style portrait, trending on pixiv, black - haired handsome male vampire, focus on face, pretty, cinematic lighting, painterly, long hair, wearing a dark blue shirt, light brown trenchcoat"
    },
    {
        "display": "Realistic fantasy character portrait",
        "full": "sophie marceau as a realistic fantasy d & d character, closeup portrait art by donato giancola and greg rutkowski, realistic face, digital art, trending on artstation"
    },
    {
        "display": "Wizard frog illustration",
        "full": "detailed frog with a wizard hat by Jean-Baptiste Monge and frank frazetta , post processing, painterly, book illustration watercolor granular splatter dripping paper texture, ink outlines, painterly, trending on artstation, trending on pinterest childrens art"
    },
    {
        "display": "Emma Watson by water",
        "full": "a gorgeous hulking Emma Watson with very long hip-length blonde hair, wearing a cut-off white top and orange cut-off shorts standing by the water, in the style of artgerm and moebius and annie liebovitz, marvel comics, photorealistic, highly detailed, trending on artstation"
    },
    {
        "display": "Post-apocalyptic police station.",
        "full": "post apocalyptic police station, building, avenue, extremely detailed, sharp focus, modern architecture, contemporary architecture, americana architecture, concrete architecture, tar roads, by thomas kinkade, pascal blanche, greg rutkowski, shaddy safadi, neil blevins, trending on artstation, high quality, photorealistic, wild vegetation, blood stains on walls, crumbling, human silhouettess, bodies on the floor, 4 k resolution"
    },
    {
        "display": "Elegant female in mecha suit",
        "full": "Beautiful female, marvel's falcon mecha suit, portrait, with wings, rays of light, fantasy, intricate triangular designs, elegant, highly detailed, sharp focus, art by Artgerm and Greg Rutkowski and WLOP"
    },
    {
        "display": "Colorful animal-robot hybrids.",
        "full": "a herd of huge multi-coloured animal-robot hybrids walking in lush and rainbow coloured extra terrestrial jungle with strange plants under a bright multi sun sky, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Lion-Octopus Hybrid Manipulating Thunder",
        "full": "full body pose, hyperrealistic photograph of a lion combined with an octopus about 1 0 0 meters tall manipulating thunder, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Intricate architectural masterpiece.",
        "full": "architectural masterpiece, intricate complexity, by greg rutkowski, artgerm, ross tran, conrad roset, takato yomamoto. 4 k, beautiful, cinematic dramatic atmosphere"
    },
    {
        "display": "Nicole Kidman as Librarian",
        "full": "the librarian, nicole kidman, fame of thrones, fibonacci, sweat drops, intricate fashion clothing, insane, intricate, highly detailed, surrealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Nietzsche Comic Cover Art",
        "full": "Friedrich Nietzsche with a chiseled Jawline and serious Look in his Suit, in the Style of Artgerm and Ross Draws and Mike Mignola and Atey Ghailan, rimlight, vibrant colors, hard shadows,  Comic Cover Art, trending on artstation"
    },
    {
        "display": "Furry cat seahorse character.",
        "full": "cat seahorse fursona wearing headphones, autistic bisexual graphic designer and musician, attractive genderless fluffy humanoid character design, sharp focus, weirdcore voidpunk digital art by artgerm, akihiko yoshida, louis wain, simon stalenhag, wlop, noah bradley, furaffinity, quantum wavetracing, artstation hd, trending on deviantart"
    },
    {
        "display": "Warrior princess book cover",
        "full": "high vector, book cover!!!!!!!!!!!!, warrior princess in intricate plate armour, large moon, light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and ivan shishkin and andrey shishkin, masterpiece"
    },
    {
        "display": "Blushing cat-eared boy portrait.",
        "full": "character portrait of a blushing boy with cat ears and tail, blue hoodie, brown fluffy hair, medium shot, concept art, global illumination, tankoban, 4 k, fantasy painting, hyper detailed, pixar animation, studio light, award winning, by artgerm, sylvain sarrailh, rossdraws, wlop, beautiful!."
    },
    {
        "display": "Dark witch portrait, glowing elements",
        "full": "glowing silver and golden elements, full close-up portrait, young female Ryan Gosling as a dark witch, book cover, green forest, white moon, red lips, establishing shot, extremly high detail, photo-realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie, raphael lacoste, alex ross"
    },
    {
        "display": "Fresh plums in bowl.",
        "full": "concept art of a single bowl filled with a few moist freshly picked plums on a wooden table. digital painting. volumetric lighting. small scale. artistic. trending on artstation."
    },
    {
        "display": "Namor-inspired basketball sneakers.",
        "full": "basketball sneakers concept of namor, trending on artstation, smooth, sharp focus"
    },
    {
        "display": "Androgynous lich concept art",
        "full": "hyperdetailed stunningly beautiful androgynous lich slaanesh made of iridescent metals and mana gems, rainbow aura, golden necklace, noble perfect androgynous skeleton inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, photorealistic, octane render, rtx, hdr, vfx, unreal engine, dnd digital art by artgerm"
    },
    {
        "display": "\"Cosplay of Annie Leonhart\"",
        "full": "detailed studio photo of cute sexy emily skinner cosplaying annie leonhart wearing white dress, wearing open toe heels, pale skin, studio lighting, sharp focus, backlit, smooth, hard focus, full body shot, beautiful woman, most beautiful models, 4 k hdr, shot on nicon camera, beautiful soft skin, skin care, sony nicon iphone 1 3 pro max"
    },
    {
        "display": "Androgynous iridescent lich skeleton",
        "full": "hyperdetailed beautiful androgynous lich skeleton made of iridescent metals and shiny gems covered with blood, long red hair, golden necklace, skeleton inspired by ross tran and wlop and masamune shirow, ultra realistic, intricate concept art, highly detailed, photorealistic, octane render, 4 k, hdr rtx. dnd digital art by artgerm and greg rutkowski"
    },
    {
        "display": "Triton girl on Goliath's shoulders.",
        "full": "triton girl having a great time riding on a goliath's shoulders, dnd concept art, painting by ross tran"
    },
    {
        "display": "Androgynous plant humanoid",
        "full": "Fantasy Alrune androgynous plant humanoid with flowers in hair plant body by Larry Elmore and Ilya Kushvikov, symmetrical face concept art, octane render unreal engine meta humans, artstation"
    },
    {
        "display": "Kawaii girl flying in kimono",
        "full": "a kawaii girl is flying in the sky wearing a kimono and a bamboo umbrella, art by vofan, manga cover, highly detailed, incredible quality, trending on artstation"
    },
    {
        "display": "Steampunk robotic opera character",
        "full": "seamless pattern of steampunk cybernetic biomechanical robotic peking opera character, symmetrical, front facing, 3 d model, very coherent symmetrical artwork, unreal engine realistic render, 8 k, micro detail, gold and steel, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, wlop"
    },
    {
        "display": "Frenchie Portrait Artwork",
        "full": "a portrait of Frenchie from the boys , made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "Cute girl portrait",
        "full": "a realistic illustration portrait of a beautiful cute girl with curly black and red hair, a pointy nose and, round chin black eyeliner, trending on artstation, hyper - realistic lighting, intricate"
    },
    {
        "display": "Demonic sneakers concept art.",
        "full": "sneakers demon, j. m. barrie, cinematic, cinema 4 d, octane render, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk baroque prince sculpture",
        "full": "baroque and cyberpunk style full-body sculpture of a young seductive Hispanic prince half android with a chest  exposing a glowing ruby battery with porcelain arms posed like a model, glowing gold  laser eyes, crown of white gears and sparkling diamonds,  swirling white-colored silk fabric, robot dinosaurs. geometric elements. reflective surfaces. intricate artwork by caravaggio. art by Artgerm and Alphonse Mucha, Trending on artstation, cinematic industrial lighting, photorealistic, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Techno wizard astronaut portrait",
        "full": "beautiful crystalline dark portrait of a female techno wizard astronaut, fantasy, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, epic cosmic background, artgerm, alphonse mucha, epic cosmic background, erak note, eddie mendoza, christophe young, craig mullins, evan lee, silvain sarrailh, dao trong le, kan liu, character design, trending on artstation"
    },
    {
        "display": "Orange tabby cat in LED armor.",
        "full": "an orange tabby cat with LED armor, artstation, concept art, smooth, sharp focus, illustration, highly detailed, unreal engine, RGB LED"
    },
    {
        "display": "Infernal gateway illustration.",
        "full": "Purgatory, fire in hell. The gateway to the infernal underworld. Devils demons, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Robin Williams as Blade Runner",
        "full": "robin williams dressed as a bladerunner, sci fi, intricate, viewed from far away, cyberpunk, rainny city streets, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Seductive gypsy fortune teller",
        "full": "fortune teller, passionate , seductive, sweaty, gypsy, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bob Ross painting Kenny Powers",
        "full": "a closeup photorealistic photograph of bob ross putting the finishing touches on a canvas painting of kenny powers. mountains and trees. film still. brightly lit scene. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Ancient ocean shrine rendering",
        "full": "a beautiful matte rendering of a magnificent ancient shrine in the middle of the open ocean liminal space, by beeple and tomasz alen kopera and greg rutkowski and beksinski, trending on artstation, myportfolio, unreal engine, 8 k, octane render, dynamic lighting, cinematic lighting, thin tunnels of blue and yellow and green glitter mist fall from the top of the shrine to the ground"
    },
    {
        "display": "Powerful sorceress in church",
        "full": "a highly detailed matte painting of emma watson as an unbelievably powerful sorceress, drinking wine, floating high in the sky in a church, surrounded by fire in the air doing blood magic, viewed in profile from far away, crackling green lightning, ultrawide lens, art by artgerm and greg rutkowski and alphonse mucha, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Trippy mushroom artwork.",
        "full": "trippy mushrooms, acrilic paint, digital, artstation, detailed intricate ink illustration, heavenly atmosphere, digital art, overdetailed art, concept art, complementing colors, trending on artstation, cgstudio, the most beautiful image ever created, dramatic, subtle details"
    },
    {
        "display": "Ukrainians attacking Putin, dramatic.",
        "full": "ukrainian people killing vladmir putin with knifes, lit by candles, digital painting, artstation, concept art, craig mullins, breathtaking, 8 k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Elf sorceress bathing portrait",
        "full": "a portrait of a female elf sorceress bathing in a forest lake by karol bak and jia ruan, beautiful detailed eyes, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4 k, hdr, concept art, detailed jewelry, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Girl waving red flag in fractal crowd.",
        "full": "gorgeous inspiring girl enthusiastically waving a red flag over her head dancing trough crowd of working people in a Mandelbrot fractal bustling Beijing by Craig Mullins, ilya kuvshinov, krenz cushart, artgerm trending on artstation by Edward Hopper and Dan Mumford and WLOP and Rutkovsky, Unreal Engine 5, Lumen, Nanite"
    },
    {
        "display": "Jaeger in mystical mountains.",
        "full": "a beautifully strange image of a jaeger wearing a mystical mechanical fluorescent mask. walking in the misty, dangerous mountains by the river. award winning. dramatic. trending on artstation. high quality. rendered by beeple, by makoto shinkai, syd meade, space art concept, sci - fi, digital art, unreal engine, 4 k uhd image, octane render"
    },
    {
        "display": "Anime-style Spider-Woman in NYC",
        "full": "spider - man into the spider - verse ( 2 0 1 8 ), anime key visual concept art of, spider woman standing on a balcony in new york city, golden rays, by alberto mielgo, 6 0's french movie poster, french impressionism, vivid colors, palette knife and brush strokes, fish eye lens, anaglyph"
    },
    {
        "display": "Scarlett Johansson as Gordon Freeman",
        "full": "gordon freeman played by scarlett johansson, face portrait, hd shot, digital portrait, elegant, beautiful, fantasy art, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Epic space marine closeup",
        "full": "4 0 k space marine, closeup, hell background, cataclysmic, epic shot, cinematic lighting, 8 k, octane render, ultra detailed, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Elegant knight portrait",
        "full": "knight of the roundtable by charlie bowater and titian and artgerm, full - body portrait, intricate, face, forest, elegant, silver mist, symmetrical eyes, bombshell, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Grinning blue harpy mermaid.",
        "full": "a photograph of a grinning meditating blue harpy mermaid mutating into a wild hairy beast. surrounded by huge flowers and trees. river delta with mountains under a blue sky full of burning stars and birds. painted by jan van eyck, max ernst, ernst haeckel, ernst fuchs and artgerm. trending on artstation, fashion editorial"
    },
    {
        "display": "Superhero Mariano Rajoy",
        "full": "mariano rajoy as a superhero, by stanley artgerm, very detailed, amazing quality, trending on artstation"
    },
    {
        "display": "Giant octopus in chains.",
        "full": "a painting of a giant octopus with chains on it's back, an ultrafine detailed painting by wayne barlowe, trending on zbrush central, sots art, lovecraftian, cosmic horror, concept art"
    },
    {
        "display": "Gandhi portrait with vibrant colors",
        "full": "side profile centered painted portrait, Gandhi rolling a blunt, Gloomhaven, matte painting concept art, art nouveau, beautifully backlit, swirly vibrant color lines, fantastically gaudy, aesthetic octane render, 8K HD Resolution"
    },
    {
        "display": "Fantasy portrait of Mike Tyson.",
        "full": "portrait of Mike Tyson eating hamburgers, extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Fantasy female detective portrait",
        "full": "portrait of a full body of beautiful young female detective, d & d, sleeveless turtleneck, fantasy, flat lighting, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by simon bisley and greg rutkowski and alphonse mucha, natural tpose"
    },
    {
        "display": "Serious child portrait, hyperrealistic.",
        "full": "portrait of a 7 year old child, gritty, serious, wearing a suit, very detailed eyes, hyperrealistic, beautiful, very detailed painting by Glenn Fabry, by Joao Ruas, by Artgerm"
    },
    {
        "display": "Cyberpunk portrait in armor.",
        "full": "portrait of handsome guy in cyber armor, dreamy and ethereal, expressive pose, bule eyes, exciting expression, fantasy, intricate, elegant, many lightning, cold color, highly detailed, digital painting, artstation, concept art, cyberpunk wearing, smooth, sharp focus, led, illustration."
    },
    {
        "display": "Detailed car park scene",
        "full": "A highly detailed matte painting of car park by Studio Ghibli, Makoto Shinkai, by Artgerm, by WLOP, by Greg Rutkowski, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Lama drooling waterfall scene.",
        "full": "startling looking lama with water running out of open mouth creating a waterfall like stream of drool to the ground. the lama is standing on rocks and mud in a river of drool shining in the sun. digital art trending on artstation 4 k beautiful color and light."
    },
    {
        "display": "Modest raven angel portrait",
        "full": "character portrait of a modest robed raven angel with iridescent black raven wings, by Artgerm, Steve Argyle, Mark Brooks, Jim Burns, Marina Abramovi\u0107, trending on Artstation"
    },
    {
        "display": "Mother and child love",
        "full": "pure love is patient love is kind, mother and child ; photorealistic oil painting by mary cassatt ; highly detailed cute faces by wlop ; trending on artstation ; 8 k high resolution, symmetrical, cinematic, high coherence, golden ratio, rule of thirds, perfectly centered ; anatomically correct faces"
    },
    {
        "display": "\"Massive dragon in cave\"",
        "full": "a huge dragon inside volumetric cave in the style of bloodborne, dark souls, demon souls, gothic art, dark fantasy, concept art, digital painting, trending on art station, night time, moon light, god rays, highly detailed"
    },
    {
        "display": "High-quality tiger portrait",
        "full": "excellent painted portrait of tiger, high quality masterpiece painted portrait, symmetry, 4k, trending on artstation, octane render, art by James Jean and artgerm and greg rutkowski and alphonse mucha and craig mullins and James Jean and Andrei Riabovitchev and Marc Simonetti and peter mohrbacher"
    },
    {
        "display": "Handsome young Asian man with dragon.",
        "full": "concept art, a young asian man with a pair of phoenix eyes, fair complexion, straight nose, thin lips, delicate eyebrows, handsome and elegant, with a red dragon embroidered on his clothes, conrad roset, greg rutkowski, flume cover art"
    },
    {
        "display": "Elegant watercolor portrait.",
        "full": "young boy, black hair, gorgeous, amazing, delicate, elegant, intricate, highly detailed, watercolor, portrait, artstation, concept art, sharp focus, illustration, art by charlie bowater and Ross tran"
    },
    {
        "display": "Symmetrical starfish alien portrait",
        "full": "symmetry!! portrait of starfish alien in the style of horizon zero dawn, machine face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Skeletons dancing at night.",
        "full": "cinematic shot epic portrait, at night, in the desert, many skeletons dance in a circle, all illuminated by the moon light, motion blur, ambient occlusion, volumetric light effect, made by ivan aivazovsky, peter mohrbacher, greg rutkowski, matte painting, trending on artstation, 4 k, perfectly defined features, digital painting, cinematic, epic, highly detailed,"
    },
    {
        "display": "Casual game concept art",
        "full": "casual game, concept art"
    },
    {
        "display": "Alien spacecraft landing.",
        "full": "deep space alien spacecraft landing on an alien planet surface, highly detailed, intricate, by Raphael Lacoste, Eddie Mendoza, Alex Ross, background of neon outer space nebulas by Pilar Gogar, concept art, matte painting, 8K HDR"
    },
    {
        "display": "Retrofuturistic mothership orbiting Earth.",
        "full": "cinematic view of a retrofuturistic mothership orbiting an earth - like planet, tim hildebrandt, wayne barlowe, syd mead, john harris, bruce pennington, donato giancola, larry elmore, oil on canvas, artstation, pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hd, hdr, 4 k"
    },
    {
        "display": "\"Ornate pipe organ landscape\"",
        "full": "old pipe organ near lake with battle raging nearby, ornate, beautiful, atmosphere, vibe, mist, smoke, chimney, rain, well, wet, pristine, puddles, reflection, waterfall, frozen, melting, melting, dripping, snow, creek, lush, frogs, ice, bridge, cart, orange, green, stained glass, forest, flowers, concept art illustration, color page, 4 k, tone mapping, doll, akihiko yoshida, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, digital illustration, greg rutowski, volumetric lighting, sunbeams, particles, trending on artstation"
    },
    {
        "display": "Magical maid in forest",
        "full": "A beautiful female maid wearing a magical bikini in a magical forest, detailed face, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Miley Cyrus as Chun-Li",
        "full": "miley cyrus as chun li from street fighter, portrait, 4 k, ultra realistic, detailed focused art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed Mercy illustration.",
        "full": "Mercy from Overwatch , highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sailor Moon illustration.",
        "full": "photo of sailermoom, by greg rutkowski"
    },
    {
        "display": "Futuristic Bounty Hunter Portrait",
        "full": "a detailed painting portrait inspired by beksinski of a machine bounty hunter made of metal and glass in a flight suit and visor. inspired by samurai. science fiction poster. head and chest only. upperbody. cinematic sci - fi scene. flight suit, accurate anatomy. symmetry. portrait and science fiction theme with lightning, aurora lighting. clouds and stars. smoke. futurism. fantasy. by beksinski carl spitzweg. baroque elements. baroque element. intricate artwork by caravaggio. oil painting. oil on canvas. award winning. dramatic. trending on artstation. 8 k"
    },
    {
        "display": "Moon Knight-Batman hybrid portrait",
        "full": "characters portrait of MoonKnight mixed with Batman by ArtGerm and Tom Bagshaw, merged character, Full Body, 4k, highly detailed, cinematic lighting"
    },
    {
        "display": "Dallas from Payday 2 in Fortnite",
        "full": "payday 2 dallas in fortnite save the world concept art, by greg rutkowski, cinematic lighting, atmospheric, trending on artstation, ultra realistic, 4 k, octane render, real life characters, high detailed"
    },
    {
        "display": "GTA Buzz Lightyear Portrait",
        "full": "gta buzz lightyear as aeon flux profile picture by greg rutkowski, dynamic pose, flat matte painting, intricate, futuristic, fantasy, elegant, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell, fantasy lut, asymmetric, long hair, retro computer graphics, video game, fluid lines,"
    },
    {
        "display": "Triumphant soldier on tank.",
        "full": "a full body shot from distance from behind of a great soldier with a yellow and blue flag standing on a Russian tank in triumph after battle, western, masculine figure, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, symmetrical, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Weredragon portrait illustration.",
        "full": "'' Face portrait of a weredragon with a black leather coat, short hair , fantasy, d&d, high detail, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha ''"
    },
    {
        "display": "Fantasy Buddha Portrait",
        "full": "buddha closeup filled background around face, fantasy magic, undercut hairstyle, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "1980s sci-fi cover art",
        "full": "1 9 8 0 s sci - fi cover art, tim hildebrandt, wayne barlowe, syd mead, john harris, bruce pennington, donato giancola, larry elmore, oil on canvas, artstation, pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hd, hdr, 4 k"
    },
    {
        "display": "1950s dancer illustration",
        "full": "a beautiful dancer with black hair in 1950's fashion, ballroom background, intricate, highly detailed, digital painting, artstation, official media, anime key visual, concept art, rich vivid colors, ambient lighting, sharp focus, illustration, art by Artgerm, Makoto Shinkai, Ilya Kuvshinov, Lois Van Baarle, and Rossdraws"
    },
    {
        "display": "Symmetric movie poster design",
        "full": "Symmetric movie poster of Cara delevingne as Samus Aran , Marviel Style cover art, ultra wide lens shot,cinematic lighting, beautiful,art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Madhouse anime character on boat.",
        "full": "style of madhouse anime, rei hiroe, artgerm, revy from black lagoon, symmetrical eyes and symmetrical face, jean shorts, white tank top, on a boat, smirk on face, evening"
    },
    {
        "display": "Armored Groot Portrait",
        "full": "greg manchess portrait painting of armored groot as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Black knight resurrection artwork",
        "full": "the black knight rising from his grave, dark fantasy, carbon painting, by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Seductive goddess in sunset.",
        "full": "full body falling through the sunset sky windy hair zero gravity floating seductive meditation goddess in the style of artgerm, wlop, greg rutkowski, alphonse mucha and ayami kojima, realistic details, volumetric lighting, very very very very beautiful symmetrical face, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Dark techno wizard portrait",
        "full": "dark portrait of loki as a techno wizard, fantasy, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, epic fractal circuit background, artgerm, alphonse mucha, epic cosmic background, erak note, eddie mendoza, christophe young, craig mullins, evan lee, silvain sarrailh, dao trong le, kan liu, character design, trending on artstation"
    },
    {
        "display": "Giant Azure Tree Art",
        "full": "digital art, concept art, divine, massive scale, giant azure tree, magical, colorful, in the distance"
    },
    {
        "display": "Hybrid actors in TARDIS",
        "full": "a hybrid of benedict cumberbatch and freddie highmore and and timothee chalamet inside the tardis, photo realistic, highly detailed, perfect face, fine details, by ha gyung, zac retz, peter mohrbacher, kezie demessance, greg rutkowski, artgerm"
    },
    {
        "display": "Surreal man fleeing giraffes.",
        "full": "realistic detailed image of man running from giraffes in the style of Francis Bacon, Surreal, Norman Rockwell and James Jean, Greg Hildebrandt, and Mark Brooks, triadic color scheme, By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Edward Hopper and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Rocket launch concept art",
        "full": "Rocket lunch concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by Jordan grimmer, art greg rutkowski"
    },
    {
        "display": "Attractive female Mario portrait.",
        "full": "super mario as an attractive young smiling woman wearing a mushroom crown, face portrait, hd shot, digital portrait, beautiful, fantasy art, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Anime Pieta Sculpture",
        "full": "\u00ef\u00bf\u00bd anime, full body, michelangelo sculpting the pieta, highly intricate detailed, light and shadow effects, intricate, highly detailed, digital painting, art station, concept art, smooth, sharp focus, illustration, advanced digital anime art, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau, craig mullins, j. c. leyendecker, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws \u00ef\u00bf\u00bd"
    },
    {
        "display": "Intricate skin writing art",
        "full": "pillow book, writing on skin, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "D&D character portrait",
        "full": "dungeons and dragons character full body side profile portrait, dramatic light, dungeon background, 2 0 0 mm focal length, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Warrior woman with spider legs",
        "full": "A portrait painting of a warrior woman with giant spider legs and needles in her head, volcano eruption backdrop, illustration, detailed, award-winning, trending on artstation, by artgerm and Greg Rutkowski and Alphonse Mucha H-768"
    },
    {
        "display": "Isolated gardenia plant, 3D render.",
        "full": "an isolated gardenia plant, full view, centered, 3 d render, art nouveau dark outlines, ultra realistic, masked transparent flat background, popular on sketchfab, pixelsquid, 8 k, volumetric lighting, super focused, no blur, trending on artstation, octane render, ultra detailed, hyperrealistic, by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Taiwanese princess in sundress",
        "full": "a beautiful fasion taiwan princess with sundress and jewelry | | winter, realistic shaded, unpleasant face, good looking, fine details, realistic shaded lighting poster by macoto takahashi, osamu tezuka, artgerm, nendorid"
    },
    {
        "display": "Anime-style anthropomorphic tiger portrait",
        "full": "beautiful painting from the anime film by studio ghibli neon blue anthropomorphic tiger portrait, drooling and snarling, too many teeth, too many mouths, golden hour backlit, trending on artstation, cell shading, 8k octane render, manga"
    },
    {
        "display": "Vaporwave portrait of woman",
        "full": "beautiful, young woman, detailed gorgeous face, sad eyes, vaporwave aesthetic, synthwave long luxurious gown made out of pearls,  hair done up with flowers and ribbons, digital art, flowers, butterflies, birds, painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Purple River Valley Landscape",
        "full": "digital painting of a lush sinuous river valley by artgerm. purple river. cold icy day. chiho aoshima. wisteria. digital render. detailed. beautiful landscape."
    },
    {
        "display": "Contemplative woman with roses.",
        "full": "oil painting close up portrait of a contemplative young black woman with long flowing hair in a white dress, wearing a crown of white roses!! surrounded by galaxies at sunset, hazy, digital art, chiaroscuro, artstation, cinematic, golden hour, concept art, digital art painting by greg rutkowski, william - adolphe bouguereau, hazy atmosphere, cinematic lighting, flowers"
    },
    {
        "display": "Mandelbrot fractal contrast.",
        "full": "rich and poor Mandelbrot fractal by Craig Mullins, ilya kuvshinov, krenz cushart, artgerm trending on artstation by Edward Hopper and Dan Mumford and WLOP and Rutkovsky, Unreal Engine 5, Lumen, Nanite"
    },
    {
        "display": "Cyberpunk warrior portrait",
        "full": "A highly detailed beautifuly rendered, close shot of a cyberpunk ringer, in purple spandex suit, with scars on his face, high tech equipement attached to the body, in front of a heavily offroad, war modified Van, with a picture of"
    },
    {
        "display": "Intricate mouse with headphones",
        "full": "mouse with headphones, HD, 4K, intricate abstract. intricate artwork. by Tooth Wu, wlop, beeple, dan mumford, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k, iridescent accents"
    },
    {
        "display": "Cyberpunk girl portrait",
        "full": "very cool girl white hair girl with mask, streetwear, techwear, cyberpunk style outfit, full body, nose piercing, detailed portrait, intricate complexity, by greg rutkowski, cushart krentz, artgerm, ross tran, conrad roset, takato yomamoto, ilya kuvshinov. 4 k, beautiful, cinematic dramatic atmosphere, portrait lighting"
    },
    {
        "display": "Salman Rushdie portrait, fantasy.",
        "full": "portrait of salman rushdie by the sea, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Victorious fantasy artwork",
        "full": "Death is swallowed up in victory, artwork by artgerm, DD, fantasy, highly detailed, digital painting, artstation, smooth, sharp focus, art by thomas kinkade and Ruth Asawa and Thomas Cole"
    },
    {
        "display": "Darkseid vs. Amazon Warriors",
        "full": "giant darkseid attacking an encampment of amazon warriors during a blizzard, highly detailed, digital illustration, artstation, concept art, matte, sharp focus, illustration, dramatic, full moon, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rococo insect lord painting",
        "full": "A epic and beautiful rococo painting of the insect lord with an ivory mask. . ultra-detailed. Anime, pixiv, UHD 8K CryEngine, octane render"
    },
    {
        "display": "Rin Tohsaka portrait.",
        "full": "a ultradetailed beautiful panting of rin tohsaka with flowing hair, full body portrait, black hair, slim, black sun glasses, smirk, attitude, by conrad roset, greg rutkowski and makoto shinkai, rin tohsaka, genshin, red dress, fate, trending on artstation"
    },
    {
        "display": "Bunny Ears D.VA Police Portrait",
        "full": "Stunning Portrait of Bunny Ears D.VA from Overwatch wearing a police uniform by Kim Jung Gi, holding handcuffs in one hand Blizzard Concept Art Studio Ghibli. oil paint. 4k. by brom, Pixiv cute anime girl wearing police gear by Ross Tran, Greg Rutkowski, Mark Arian, soft render, octane, highly detailed painting, artstation"
    },
    {
        "display": "Female Gordon Freeman concept art",
        "full": "gordon freeman as a woman, hd shot, concept art, artstation, by artgerm and jakub rozalaki"
    },
    {
        "display": "Twin Peaks-inspired portrait illustration",
        "full": "twin peaks movie poster art, portrait of alicia vikander, from scene from twin peaks, clean, simple illustration, nostalgic, domestic, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, donato giancola, joseph christian leyendecker, wlop"
    },
    {
        "display": "Futuristic android profile portrait",
        "full": "profile of an futuristic android!! hybrid, bold natural colors, masterpiece, trending on artstation, photograph"
    },
    {
        "display": "Crow book cover design",
        "full": "award winning book cover design, glowing silver and golden elements, full side portrait of realistic crow with gems, book cover, green forest, white moon, establishing shot, extremly high detail, photo-realistic, cinematic lighting, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross"
    },
    {
        "display": "Gothic surreal dog scene",
        "full": "painting of a dog and it's lifeless owner wandering the streets of london, half the scene is covered in cosmic artifacts of noise, by zdzislaw beksinski, by dariusz zawadzki, by wayne barlowe, gothic, surrealism, cold hue's, warm tone gradient background, concept art, focused scene, medium shot, beautiful composition"
    },
    {
        "display": "Dystopian girl portrait",
        "full": "closeup portrait shot of beautiful girl in a scenic dystopian environment, intricate, elegant, highly detailed, tubes and cables, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Psychedelic floral brain art",
        "full": "extremely psychedelic brain made of orchid and cherry blossom tree and mushroom, LSD brain, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Non-binary musician in glowing forest",
        "full": "1 6 mm, film, tight shot of subject, a non - binary vr musician practicing a dope set in a mystical glowing forest in the style of juan jimenez, mike mignola, vibrant colors and hard shadows and strong rim light, perfect details, comic character design, trending on artstation, 3 d render, smooth render, green, wlop"
    },
    {
        "display": "Intricate space portrait.",
        "full": "View of a beautiful space, oil on canvas, intricate, portrait, 8k highly professionally detailed, HDR, CGsociety, illustration painting by Mandy Jurgens and Ma\u0142gorzata Kmiec and Dang My Linh and Lulu Chen and Alexis Franklin and Filip Hodas and Pascal Blanch\u00e9 and Bastien Lecouffe Deharme, detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, Cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8K, style by Wes Anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon st\u00e5lenhag, rendered by Beeple, by Makoto Shinkai, syd meade, 8k ultra hd, artstationHD, 3d render, hyper detailed, elegant, by craig mullins and marc simonetti, Ross Tran and WLOP, by Andrew Wyeth and Gerald Brom, John singer Sargent and James gurney"
    },
    {
        "display": "Cel-shaded sci-fi desert city",
        "full": "cel shading background, science fiction pc game point - and - click adventure, studio ghibli, desert with city in the skyline, two suns, purple orange colors, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski, masterpiece"
    },
    {
        "display": "Vaporwave portrait of woman",
        "full": "beautiful, young woman, detailed gorgeous face, vaporwave aesthetic, synthwave, colorful, psychedelic, artstation, concept art, smooth, extremely sharp detail, finely tuned detail, ultra high definition, 8 k, unreal engine 5, ultra sharp focus, illustration, art by artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical horror fashion portrait",
        "full": "symmetry!! portrait of elle fanning in dark souls, horror, fashion, dark!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "\"Polish Slavic sci-fi market\"",
        "full": "detailed intricate polish, slavic scifi outdoor market, detailed, artstation concept, ubisoft environment concept art, moody cinematic lighting, church, warsaw, modern photography, volumetric lighting"
    },
    {
        "display": "Scuba diver exploring Atlantis.",
        "full": "an epic landscape view of a scuba diver with a shining headlamp exploring atlantis, chrome and gold, colorful fish and sea creatures, painted by tyler edlin, close - up, low angle, wide angle, atmospheric, volumetric lighting, cinematic concept art, very realistic, highly detailed digital art"
    },
    {
        "display": "Futuristic alien ship interior",
        "full": "alien ship interior, control panels, lights, unknown machinery, advanced technology, spiral entrance, fine details, perfect, 8 k high detail, masterpiece, trending on artstation, by ian mcque, bradley wright"
    },
    {
        "display": "Sad pink Pok\u00e9mon cat.",
        "full": "pink pokemon cat sad - increasing physical mental perfection, symmetrical! intricate, sensual features, highly detailed, biblical divine holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Asian girl with kitsune mask.",
        "full": "portrait of an asian girl with a kitsune mask surrounded by cherry blossoms in the style of makoto shinkai and artgerm and loish, dynamic lighting,"
    },
    {
        "display": "Spaceship racing through space.",
        "full": "Wide shot of a single spaceship racing towards the sun , explosions and lasers. Asteroid belt. Scenic view, in the void of space, underexposed, matte painting by Craig mullins and Emmanuel_Shiu and john berkey, cinematic, dark sci-fi, concept art trending on artstation, 4k, insane details, ultra realistic"
    },
    {
        "display": "Tropical beach painting.",
        "full": "A beautiful award winning painting of a tropical beach with palm trees and a wavy blue ocean trending on artstation vibrant color scheme lots detail"
    },
    {
        "display": "Cinematic emperor secret society.",
        "full": "concept art by jama jurabaev, emperor secret society, cinematic shot, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Evil demon concept art",
        "full": "Nocturne from League of Legends, concept art, Riot Games, evil, demon, ominous background, thick black smoke, highly detailed, trending on Artstation"
    },
    {
        "display": "Anime female astronaut, cyberpunk.",
        "full": "anime visual of a female astronaut, neon, cyberpunk, futuristic suit, stunning, highly detailed, digital painting, artstation, smooth, soft focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk monk meditation.",
        "full": "monk meditation in a cyberpunk world with people walking around wearing vr headset by eugene von guerard, ivan shishkin, concept art, trending on artstation"
    },
    {
        "display": "Macro close-up of stir-fry.",
        "full": "movie still macro close photo of anonymous holding stirfry to face, by weta disney pixar greg rutkowski wlop ilya kuvshinov rossdraws artgerm octane render iridescent, bright morning, liosh, mucha"
    },
    {
        "display": "Ukrainian warrior woman silhouette.",
        "full": "cinematic shot from behind of a beautiful girl in national blue and yellow dress with beautiful hair standing against and facing a huge realistic detailed Russian warship on the horizon. She is ready to fight. Ukrainian flag on the left side, concept art, \u0441inematic lighting, insanely detailed, smooth, sharp focus, Artstation, 8k, unreal engine,  hyper realistic, steampunk style, bright background, moonlight, volumetric lighting, wallpaper, digital illustration by Ruan Jia and Mandy Jurgens and Artgerm and Wayne Barlowe and Greg Rutkowski and Zdislav Beksinski"
    },
    {
        "display": "Fantasy detective portrait",
        "full": "portrait of a full body of beautiful young female detective, d & d, sleeveless turtleneck, fantasy, flat lighting, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, misa amane, art by simon bisley and greg rutkowski and alphonse mucha, natural tpose"
    },
    {
        "display": "Cyberpunk elven portrait",
        "full": "portrait painting of a cyberpunk elven beautiful david tennant, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Hyperrealistic kittens in jungle",
        "full": "of a beautiful group of Hyperrealistic kittens in a dark scary smokey surreal jungle, macro lens, ultra shallow depth of field, highly detailed, digital painting, trending artstation, concept art, vibrant colors, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Wizard portrait photography",
        "full": "portrait of a wizard by peter mohrbacher. photographic, photography. trending on artstation"
    },
    {
        "display": "Chonky man on sad horse.",
        "full": "chonky ethan van sciver with a bald head, grey trimmed beard l and a pointy nose, riding on a sad dark horse, full view, beautiful artwork by artgerm and rutkowski, breathtaking, beautifully lit, dramatic, full view"
    },
    {
        "display": "Dark witch illustration.",
        "full": "dark witch, highly detailed, artgerm style, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Fantasy dragon hunter portrait",
        "full": "Portrait of a Fantasy azure dragon hunter, HD, illustration, epic, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, monster hunter illustrations art book"
    },
    {
        "display": "Trump as Poseidon concept art",
        "full": "character concept art of donald trump as poseidon | cute - fine face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Hyperrealistic flow geometry",
        "full": "hyperrealistic mixed media high resolution image of doublet and rankine geometry in complex potential flow around a hand reaching up and outward from the flow, stunning 3d render inspired art by Stable-Diffusion AI and Claudio Bravo, perfect symmetry, dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyper-detailed, intricate, epic composition, highly detailed attributes, highly detailed atmosphere, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning"
    },
    {
        "display": "Female steampunk airship pirate.",
        "full": "3 / 4 headshot of female airship pirate, d & d, handsome, fantasy, intricate, long hair, airship, steampunk, blond hair, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Menacing burned figure",
        "full": "concept art of a man in a menacing stance with his body covered in burns, with black smoke coming out of his hands, full body, dark colors, sinister atmosphere, dramatic lighting, cinematic, establishing shot, extremely high detail, photo realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross,"
    },
    {
        "display": "Symmetrical Thanos ink sketch",
        "full": "symmetry concpet art, full shot, traditional ink, sketch, of thanos, line sketch, intricate, elegant, highly detailed, monochrome, digital painting, artstation, concept art, sharp focus, illustration, art by borderlands 3 and peter polach"
    },
    {
        "display": "Lamborghini-Datsun hybrid design",
        "full": "an amalgamation of a lamborghini countach and a datsun 2 6 0 z, concept art, 8 k, highly detailed"
    },
    {
        "display": "Anthropomorphic duergar monster",
        "full": "anthropomorphic duergar darkhaft taker, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Crystal couple artwork",
        "full": "a couple made of sparkling crystal, a man and a woman, diamond and rose quartz, full body view, beautiful high quality realistic fantasy art, trending on artstation by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Warrior nun portrait.",
        "full": "portrait of dua lipa as a beautiful warrior nun as drawn by eric anthony johnson ericanthonyj artstation artgerm greg rutkowski and magali villeneuve 8 k subsurface scattering, soft light"
    },
    {
        "display": "Realistic tribal Power Rangers helmet",
        "full": "a realistic painting by Raffaello Sanzi Power Rangers helmet mask tribal smooth,Sharp focus, trending on Artstation"
    },
    {
        "display": "Dual Expression Portrait",
        "full": "emma stone : left - half is sad emma stone, right - half is smiling emma stone, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Minimalist bonsai art",
        "full": "bonsai tree! but minimalistic concept art by frank stella gilleard james whalen tom, colorful, soft light, trending on artstation, minimalism"
    },
    {
        "display": "Dog-Human Hybrid Concept Art",
        "full": "a crossbreed between a dog and a human, by shaddy safadi, feng zhu, and john j. park, concept art!!!!!, concept!!!!!, trending on artstation, zbrush, photorealistic details, intricately defined, comprehensive art, complexly detailed, professional photography, 4 k, 8 k"
    },
    {
        "display": "Possessed goat demon guitarist.",
        "full": "a possessed anthropomorphic goat demon playing the guitar, expressive oil painting, humanoid, digital art, beautiful lighting, sunlit, by greg rutkowski, by jeremy lipking, mininamlist painting"
    },
    {
        "display": "Detailed illustration of a thin man.",
        "full": "extremely detailed illustration of a thin guy with a big nose and an overbite wearing a brown beret and a palid green plaid shirt stand up participating on a tv show, coloful, vibrant colors, symmetrical, anatomically correct, Trending on Artstation, HQ, deviantart, ost grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00edn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, alien, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Soviet soldier girl portrait",
        "full": "high detail portrait oil painting illustration of beuatiful girl as soviet red army soldier eating hot baked bun, in khaki ww 2 tunic, no hat, by justin sweet with face and body clearly visible, in a scenic background, pupils visible, realistic proportions, artstation trending, high quality, sombre mood, artstation trending, muted colours, entire person visible!"
    },
    {
        "display": "Elegant anime dancer at night",
        "full": "a girl dancing elegantly, full body shot, intriguing outfit, fine - face, realistic shaded perfect body, fine details. night setting. very anime style. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Fantasy portrait of Donald Trump",
        "full": "portrait of donald trump, soft hair, muscular, half body, leather, hairy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Whimsical goddess casting spell.",
        "full": "one beautiful whimsical goddess standing in a lake basking in the moonlight, casting a spell, underneath a multi-colored binary blackhole with an accretion disc, glowing trails following her arms, acidwave, crystals, koi fish, by Lois van Baarle, by Greg Rutkowski, by artgerm, by beeple, by studio ghibli, cinematic angle, volumetric lighting, 4k resolution, octane render, trending on artstation, masterpiece"
    },
    {
        "display": "Dystopian surrealist artwork.",
        "full": "bager 2 8 8, dystopian surrealism, artstyle alex ries zdzis\u0142aw beksinski, symmetry accurate features, very intricate details, 4 k, highly detailed, symmetrical long head, smooth marble marble surfaces, detailed ink illustration, metal gear, cinematic smooth stone, deep aesthetic, concept art, post process, carved marble texture silk cloth, highly ornate intricate details, in the style of 8 8 grzes, giger"
    },
    {
        "display": "Girl with baby dragon.",
        "full": "a cute little girl with curly brown hair and blue eyes holding a blue baby dragon, beautiful fantasy art by greg rutkowski."
    },
    {
        "display": "Isometric Doom Slayer Helmet",
        "full": "annotated highly - detailed and intricate 4 5 degree isometric cross - section of doomguy helmet + marker concept art style rendering + concept art + half blueprint + trending on artstation + intricate details + john berkey + vincent di fate + ralph mcquarrie + center frame : : annotations : : metalic green : : - 0. 2 5 kelsey grammer sketch"
    },
    {
        "display": "Hyperrealistic canines portrait.",
        "full": "hyperrealistic photography of a highly detailed and symmetrical gorgeous very beautiful foxes, wolves, and dogs, in the style of livio scarpella, beth cavener, jin kagetsu, face symmetry, masterpiece, award - winning, sharp focus, intricate concept art, ambient lighting, 8 k, artstation"
    },
    {
        "display": "Taylor Swift on throne, fantasy.",
        "full": "Taylor Swift sitting on a majestic throne wearing a crown, D&D style, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Hermine Granger Overwatch Portrait",
        "full": "greg manchess portrait painting of hermine granger as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Futuristic geisha portrait",
        "full": "realistic detailed face portrait of a beautiful futuristic very kid girl geisha in alien cyberpunk dress outfit by alphonse mucha, ayami kojima, amano, greg hildebrandt, and mark brooks, female, feminine, art nouveau, cyberpunk, neo - gothic, gothic, character concept design"
    },
    {
        "display": "Star Platinum concept art",
        "full": "the stand Star platinum from jojo's bizarre adventure, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "Ship in a bottle artwork",
        "full": "an achingly beautiful print of a ship in a bottle by raphael, hopper, and rene magritte. detailed, proportional, romantic, vibrant, enchanting, trending on artstation"
    },
    {
        "display": "Intricate slave market illustration",
        "full": "slave market, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy cyberpunk statue.",
        "full": "fantasy rococo and cyberpunk style white neon statue of a muscular attractive tan male Peruvian macho dotado android reclining sim roupa con piroca dura, glowing pink face, white baseball cap, green steampunk lasers, emeralds, swirling white silk fabric. futuristic elements. prismatic liquid rainbow light, full-length view. space robots. human skulls. throne made of bones, intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Futuristic beehive lobby.",
        "full": "futuristic beehive lobby by dennis chan, hyper detailed, digital art, trending in artstation, cinematic lighting"
    },
    {
        "display": "Glowing crystal ball with spirits.",
        "full": "a glowing crystal ball placed on a pillow while spirits swirl around it, made by stanley artgerm lau, wlop, rossdraws, artstation, cgsociety, concept art, cgsociety, octane render, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k,"
    },
    {
        "display": "Androgynous galaxy druid portrait",
        "full": "a portrait of a galaxy as an androgynous druid spirit wearing five necklaces of moons and stars, wearing a glowing crown of black holes, black eyes and sclera, draped in transparent cloth, colorful, flat colors, occult, ornate, detailed, bust by alphonse mucha, decorative art nouveau border, astrophotography, vast cosmos, digital illustration, trending on artstation"
    },
    {
        "display": "Fantasy portrait of Heihachi.",
        "full": "a full body high detail fantasy portrait oil painting illustration of heihachi mishima by justin sweet with face and body clearly visible, in a scenic background, pretty eyes, realistic proportions, d & d, rpg, forgotten realms, artstation trending, high quality, sombre mood, artstation trending, muted colours, entire person visible!"
    },
    {
        "display": "Joyful female pirate portrait",
        "full": "portrait of a young ruggedly beautiful but joyful pirate, female, femenine, upper body, red hair, long hair, d & d, fantasy, piercing eyes, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Omnipotent beings in golden sky",
        "full": "golden sky pan - dimensional omnipotent beings, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha, by jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws"
    },
    {
        "display": "Cyberpunk alien portrait",
        "full": "closeup portrait shot of a cyberpunk alien in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Sorcerer portrait with nature.",
        "full": "portrait of sorcerrer, bark skin, leaves hair, digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Stylish brown male portrait",
        "full": "the best brown male with short beard and mustache profile picture of 2 0 2 5, 4 k, beautiful gorgeous digital art, trending on artstation"
    },
    {
        "display": "Ominous fantasy castle artwork",
        "full": "professional ominous concept art of a massive fantasy castle by artgerm and greg rutkowski. an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp focus, illustration, in the style of simon stalenhag, wayne barlowe, and igor kieryluk."
    },
    {
        "display": "Futuristic celebration at dawn.",
        "full": "a crowd of people celebrating with hands up in a futuristic village next to wheatfields of pink plants, spaceships in the sky, pink trees, pink grass, at dawn, epic scale ultrawide angle, stunning, epic, cinematic, artstation trending, octane render, hyperrealistic, cryengine 8 k uhd"
    },
    {
        "display": "Anime boy waving girl",
        "full": "boy on ground waving to a pretty girl on the second floor, illustration concept art anime key visual trending pixiv fanbox by wlop and greg rutkowski and makoto shinkai and studio ghibli"
    },
    {
        "display": "Colorful koi carp portrait",
        "full": "a koi carp, colorful, blue backgroung,clean, joyful, close-up portrait, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Elegant Sakura character portrait",
        "full": "\u6728 \u4e4b \u672c \u685c from cardaptor sakura, messy curly hair, embers intricate and very beautiful and elegant, highly detailed, digital painting, artstation, concept art, smooth and sharp focus, cg by tian zi and wlop"
    },
    {
        "display": "Rich father character concept",
        "full": "a rich daddy, 3 2 years old, wear grey suits, stubble, cramel hair, character concept art, octane render, trending by artstation, artbreeder"
    },
    {
        "display": "Elegant Art Nouveau Girl",
        "full": "portrait of a lisa frank, thomas kinkade, wes anderson girl, intricate, elegant, art nouveau, tarot card, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Chrome-Spined Fantasy Woman",
        "full": "a beautiful woman with a chrome spine, painted by artgerm and tom bagshaw, fantasy art, dramatic lighting, highly detailed oil painting"
    },
    {
        "display": "Elf portrait in nebula.",
        "full": "portrait of sarah bolger as dnd elf with black hair black dress as pathfinder in a nebula forest by greg rutkowski, high key lighting, volumetric light, digital art, highly detailed, fine detail, intricate, ornate, complex, octane render, unreal engine, photorealistic digital painting, artstation, concept art, sharp focus, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Armored Baby Groot Portrait",
        "full": "greg manchess portrait painting of armored baby groot as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Sailor Moon in sci-fi armor",
        "full": "sailor moon in sci - fi heavy armor, hard surface 3 d, 3 4 3 industries, halo infinite, ilya kuvshinov, greg rutkowski, blizzard games art style, artstation trending, detailed, digital painting, concept art"
    },
    {
        "display": "Angry K-pop idol portrait",
        "full": "official anime manga ads campaign portrait kpop idol girl closeup tired and angry by Alex Ross sorayama frank miller ishikawa ken trending on artstation cinematic backlit smoke noir technoir detailed"
    },
    {
        "display": "Ethereal sepia portrait.",
        "full": "polaroid picture, sepia, homeless manu chao in the streets of bogota, ethereal, trending on artstation"
    },
    {
        "display": "Cthulhu's Castle Matte Painting",
        "full": "A highly detailed matte painting of huge cthulhu's castle  by Studio Ghibli, Makoto Shinkai, by Artgerm, by WLOP, by Greg Rutkowski, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Wendigo portrait in dystopia",
        "full": "closeup portrait shot of a wendigo in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Blue feathered wolf in winter landscape",
        "full": "blue feathered wolf with wings on a beautiful fantasy landscape, standing facing front, winter, snow, mountains, moonlit, hd, illustration, epic, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wallpaper, art by artgerm and greg rutkowski and alphonse mucha and jin xiaodi"
    },
    {
        "display": "Cyberpunk Samurai Artwork",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Cyberpunk samurai. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Exploding hearts in fantasy village.",
        "full": "a collision of exploding black hearts and white hearts in impressionist form of an endlessly long and winding path through an isometric fantasy village, vivid vibrant geometric art nouveau. trending on pixiv and artstation. an absurdly detailed oil painting"
    },
    {
        "display": "Cute alien concept art",
        "full": "the super cutest alien grey you ever did see, concept art, ambient lighting, dynamic lighting"
    },
    {
        "display": "Epic hungry eyes artwork",
        "full": "epic professional digital art of hungry eyes, atmospheric lighting, painted, intricate, detailed, impressive, leesha hannigan, wayne haag, reyna rochin, ignacio fernandez rios, mark ryden, iris van herpen, best on artstation, cgsociety, wlop, pixiv, stunning, gorgeous, much wow, cinematic, masterpiece"
    },
    {
        "display": "Fantasy cartoon Morty portrait.",
        "full": "fantasy portrait of cartoon morty, intricate abstract. intricate artwork, by greg rutkowski, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Neon anthropomorphic portrait.",
        "full": "anthropomorphic highly detailed group portrait of funny neon giant cute eyes irish model one - point - perspective - peacock mandala, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Jack Pumpkinhead in sweater.",
        "full": "jack Pumpkinhead wearing a sweater and holding a mug that is shaped like a human head, highly detailed, centered, solid color background, digital painting, artstation, concept art, smooth, sharp focus, illustration, donato giancola Joseph Christian Leyendecker, Les Edwards, Ed Repka, Basil Gogos, WLOP, Artgerm"
    },
    {
        "display": "Cyborg couple kissing, cyberpunk.",
        "full": "ultra realistic close - up of a couple of cyborgs kissing, lovers, faces, cyberpunk, sci - fi, fantasy, led color, flare, soft light, night, highly detailed, digital painting, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and h. r. giger,"
    },
    {
        "display": "Celestial illuminated manuscript art",
        "full": "a beautiful celestial sky with stars, planets and galaxies in the style of illuminated manuscript, circular abstract border with curly symmetrical designs, trending on artstation"
    },
    {
        "display": "Liminal portrait in gold",
        "full": "palette knife oil painting portrait of a girl in a blue and gold room, film still by goya, by henri de toulouse - lautrec, by klimt, by pontormo, extreme detail, liminal aesthetic, artgerm, deviant art, octane, substance, art history 8 k, art nouveau"
    },
    {
        "display": "Timelord Frog in TARDIS",
        "full": "anthropomorphic art of a timelord frog inside tardis, victorian inspired clothing by artgerm, victo ngai, ryohei hase, artstation. fractal papersand books. highly detailed digital painting, smooth, global illumination, fantasy art by greg rutkowsky, karl spitzweg, doctor who"
    },
    {
        "display": "Futuristic Tokyo at night.",
        "full": "A beautiful matte Masterpiece of futuristic Tokio at night by Beeple and RHADS, Trending on Artstation , featured on behance, intricate, rectilinear."
    },
    {
        "display": "Portrait of Caesar Invictus",
        "full": "excellent painted portrait of the Caesar Invictus the last Emperor of New Rome, masterpiece painting with detailed face, 4k, trending on artstation, octane render, art by artgerm and greg rutkowski and alphonse mucha and craig mullins and James Jean and Andrei Riabovitchev and Marc Simonetti and peter mohrbacher"
    },
    {
        "display": "Elder Scrolls Shivering Isles Art",
        "full": "elder scrolls shiver isles concept art unreal 5 render"
    },
    {
        "display": "Animatronic girl, colorful art.",
        "full": "ultra detailed stunning digital painting of an animatronic girl by Karol Bak, Moebius, hiroshi yoshida, Druillet, xsullo, colorful, front view, vivid colors, 8k, coherent, akira vibes, uplifting, magical composition, artstation, synthwave, 8k, coherent, artgerm, uplifting, unreal engine, magical composition, artstation"
    },
    {
        "display": "Ornamental spirit tiger emblem",
        "full": "beautiful decorative classical ornamental emblem, spirit tiger, fibonacci rhythms, roses, lilies, rose petals, lily petals, acanthus scrolls, small medium and large elements, artgerm, trending on artstation, wlop, russ abbott"
    },
    {
        "display": "Elegant Roman princess portrait.",
        "full": "body portrait of beautiful roman young pincess wearing a flowing silk robe, wearing a crown of laurel, full body portrait of a young beautiful woman swimming low angle by terry o'neill intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Female clown sorceresses portrait.",
        "full": "a portrait of a group of female clown sorceress with balloons bathing in a lake by karol bak and jia ruan, beautiful detailed eyes, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4 k, hdr, concept art, detailed jewelry, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Esoteric Wiccan perfection illustration",
        "full": "perfectly detailed esoteric wiccan!!!, as above so below!! blessed by nature with ever - increasing physical mental perfection, symmetrical! intricate, sensual features, highly detailed, biblical divine holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Asian mage resting tent.",
        "full": "full portrait of asian mage traveller resting at her tent. intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, by artgerm, 8 k"
    },
    {
        "display": "Boris Johnson as Neo",
        "full": "Boris Johnson as Neo from Matrix in sunglasses, full body realistic portrait, highly detailed, muscular body, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Home studio musician at night",
        "full": "light skin black man with headphones at his home studio producing music late at night, very detailed, 4 k, concept art like ernest khalimov, intricate details, highly detailed by greg rutkowski, ilya kuvshinov, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Epic sci-fi horror art",
        "full": "epic science fiction horror, concept art by jama jurabaev, extremely detailed, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Robocop in rainy Detroit",
        "full": "Robocop portrait, rainy Detroit by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Elephant selling food.",
        "full": "\u201c an elephant selling fried chicken wings and sticky rice with a motorcycle cart in rural thailand. masterpiece. trending on artstation \u201d"
    },
    {
        "display": "Beyonc\u00e9 as a magical fantasy portrait.",
        "full": "Beyonce as a fantasy magic woman portrait, sci-fi, amber eyes, face, long hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Art Nouveau Sci-Fi Pattern",
        "full": "art nouveau scifi vector pattern, elegant, intricate, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Scarlett Johansson as Thanos",
        "full": "scarlett johansson as thanos, feminine beautiful muscular fitness model wearing armor, purple skin, red lips, strong jaw, pin up, attractive, highly detailed upper body portrait, pretty face, elegant, breathtaking art, concept art, by artgerm and ilya kuvshinov"
    },
    {
        "display": "Fantasy faerie character design",
        "full": "wonderdream faeries lady feather wing digital art painting fantasy bloom vibrant snyder zack and swanland raymond and pennington bruce illustration character design concept harmony joy atmospheric lighting butterfly"
    },
    {
        "display": "Twin Peaks-inspired Bowie portrait",
        "full": "twin peaks poster art, portrait of david bowie contemplating lois duffy, who said'i'm like the blue rose'before dying and disappearing, by michael whelan, rossetti bouguereau, artgerm, retro, nostalgic, old fashioned"
    },
    {
        "display": "Cute winged Japanese girl",
        "full": "concept art of a cute japanese girl with wings and short black hair, wearing !!round glasses!! and a long robe, drawn by akihiko yoshida and tsutomu nihei and makoto shinkai and wadim kashin and john berkey and yoji shinkawa"
    },
    {
        "display": "Alien battlefield confrontation.",
        "full": "cinematic artwork of an alien by hr giger staring down the enemy on the battlefield by Greg Rutkowski, 4k, masterpiece"
    },
    {
        "display": "Hyperrealistic Athena portrait",
        "full": "hyperrealistic mixed media painting of beautiful goddess Athena, stunning 3d render inspired art by P. Craig Russell and Barry Windsor-Smith, perfect facial symmetry, dim volumetric lighting, 8k octane beautifully detailed render, post-processing, portrait, extremely hyper-detailed, intricate, epic composition, brown eyes, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning"
    },
    {
        "display": "Realistic female cyborg portrait.",
        "full": "realistic photography of a female cyborg humanoid, back arched, grasping, silicon ribbon highly detailed intricate filigree, in the style of beth cavener, jin kagetsu, wlop,, symmetry, masterpiece, concept art, ringflash, highkey lighting, ambient lighting, hard key light, octane render, 8 k, artstation"
    },
    {
        "display": "Joyful friends in clouds.",
        "full": "happiness and friendship, floating high in the clouds, by peter mohrbacher, victo ngai, greg rutkowski, artgerm, volumetric lighting, intricate, environmental lighting, 4 k"
    },
    {
        "display": "Falling sorceress in sky.",
        "full": "sorceress falling through the sky, by peter mohrbacher, artgerm, karol bak, loish, ayami kojima, james stokoe, highly detailed, ultra detailed, ultra realistic, trending on artstation"
    },
    {
        "display": "Future soldier trading card",
        "full": "girl, trading card front, future soldier clothing, future combat gear, realistic anatomy, concept art, professional, by ufotable anime studio, green screen, volumetric lights, stunning, military camp in the background, metal hard surfaces, real face"
    },
    {
        "display": "Sinking Bottle in Ocean",
        "full": "a closeup of a bottle with a scroll in it in the ocean, sinking. ripples, blue water. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art"
    },
    {
        "display": "\"Epic demon battle scene\"",
        "full": "an intense beam of light burning down a horde of demons, digital painting, mixed media, trending on artstation and deviantart, epic composition, highly detailed, 8 k"
    },
    {
        "display": "Old angry Obama portrait.",
        "full": "warmly lit close up studio portrait of very old angry! Barack Obama age 115 angrily singing, impasto oil painting thick brushstrokes by Cy Twombly and Anselm Kiefer , trending on artstation dramatic lighting Expressionism"
    },
    {
        "display": "Tiny combat kid with katana.",
        "full": "a tiny combat kid wearing a puffy yellow jacket and a katana, smooth, intricate, elegant, digital painting, artstation, concept art, sharp focus, octane render, illustration, art by ayami kojima, apex legends character,"
    },
    {
        "display": "Sci-fi predator creature design",
        "full": "a strange sci fi fatty predator fauna creature design by neville page, ken barthelmey, sharp focus, trending on artstation, cinematic lighting, hyper realism, octane render, 8 k, hyper detailed, vivid, ultra detailed, highly detailed, zbrush, concept art, creature design"
    },
    {
        "display": "Apathetic Nazi Maid Portrait",
        "full": "baroque oil painting anime character concept art key visual full body portrait, apathetic young nazi maid secret police with long flowing blonde hair and blue eyes, nazi black leather uniform, gap moe kuudere noble grimdark evil dictator, trending pixiv fanbox, rule of thirds golden ratio, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Holy paladin vs. evil",
        "full": "digital art, holy paladin fighting the greatest evil in his last fight, horror, dim colors, red hue, hellish creature, ultra realistic, ultradetailed, realistic, oil painting, trending on artstation, 4 k"
    },
    {
        "display": "Giant blue six-legged beast.",
        "full": "an illustration of a giant blue six legged beast with head resembling a bull, thick fluffy fur walking across a peaceful fantasy meadow digital art concept art"
    },
    {
        "display": "Mao Zedong Fantasy Portrait",
        "full": "mao zedong as epic lord unifier of red china, colourised, face portrait, epic, military art, fantasy, dieselpunk, hd shot, digital portrait, beautiful, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Futuristic lush cityscape",
        "full": "futuristic city, lush vegetation, humid, early evening, diagonal view, geometric buildings, cloudy, beautiful, dull pastel colors, realistic, foggy, dreamy, nostalgic, bright, trending on artstation by yoshitaka amano and makoto shinkai, studio ghibli style"
    },
    {
        "display": "Anime people with dramatic liquids.",
        "full": "photorealistic dramatic liquids anime people render, detailed face, colorful, atmosphere cinematic, by wlop, by ilyu kuvshinov, soft shadows, concept art, super detailed, vfx, houdini, 8 k, super realistic, ufotable studio art style, global illumination, trending in pixiv, dramatic color, ray tracing, god rays"
    },
    {
        "display": "Lich queen fantasy portrait.",
        "full": "a photorealistic dramatic fantasy render of a beautiful lich queen wearing a beautiful intricately detailed eldritch mask and armour kimono by wlop, artgerm, greg rutkowski, alphonse mucha, beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, artstation, concept design art, octane render, 8 k"
    },
    {
        "display": "Ponytail man with truck",
        "full": "a beautiful illustration of a light brown ponytail wearing man and his 1 8 wheeler truck, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Vivid beach concept art",
        "full": "Beach, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by Jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Burning White House Artwork",
        "full": "A highly detailed matte painting of american white house castle on fire, expliosion and smokes, by Studio Ghibli, Makoto Shinkai, by Artgerm, by WLOP, by Greg Rutkowski, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Stunning dreamy infographic masterpiece.",
        "full": "infographic, concept art, masterpiece, beautiful dreamy breathtaking, trending on artstation, raytracing, stunning scene"
    },
    {
        "display": "Delorean car cabin artwork",
        "full": "Delorean car next to cabin full of details artwork by Michael Whelan and Tomer Hanuka, , by Makoto Shinkai and thomas kinkade, Matte painting, trending on artstation and unreal engine"
    },
    {
        "display": "Cyberpunk corporate woman illustration",
        "full": "cyberpunk corporate woman, overweight, comics page | | realistic shaded, fine details, realistic shaded lighting poster by greg rutkowski, diego gisbert llorens, magali villeneuve, artgerm, jeremy lipkin and rob rey"
    },
    {
        "display": "Giant octopus in space",
        "full": "Giant purple octopus attack in a space station, elegant, intricate, retrofuturistic digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant Queen of Atlantis",
        "full": "Queen of Atlantis, gorgeous portrait, intricate, elegant dress, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Anthropomorphic samurai bear",
        "full": "ultra realistic illustration, anthropomorphic samurai asian black bear, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and alphonse mucha"
    },
    {
        "display": "Hyper-realistic sci-fi artwork",
        "full": "the most amazing dream you ever had about singularity transhumance portal, mass effect, dark souls, halo, doom eternal, hyper realistic, concept art, high key lighting, intricate, hyper detailed, smooth, high contrast, neon, volumetric lighting, octane, raytrace, greg rutkowski, huifeng huang, artgerm, beeple, jim lee,"
    },
    {
        "display": "Victorian city film still",
        "full": "a film still from jean - pierre jeunet by doug chiang - a victorian city, vibrant, 3 5 mm lens, movie environment design, behance hd, dramatic lighting, cinematic, global illumination, deviant art, trending on artstation, bloom"
    },
    {
        "display": "Anthropomorphic penguin warrior.",
        "full": "a anthropomorphic penguin warrior, D&D, fantasy, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Medieval explorers chased by dragon.",
        "full": "wide angle, medieval explorers being chased by a red dragon through castle ruins, lake, realistic digital painting, concept art, coherent design, vivid color, complementary color, rule of thirds, detailed, sharp lines, intricate, in the style of forgotten realms, by studio ghibli, by greg rutkowski, by anato finnstark, deviantart, octane render, cgi"
    },
    {
        "display": "Shy boy portrait, surreal horror",
        "full": "a dream portrait of a shy boy with long hair and full beard, black & white, melting, webbing, 8 k, by tristan eaton, stanley artgerm, tom bagshaw, greg rutkowski, carne griffiths, ayami kojima, beksinski, giger, trending on deviantart, face enhance, hyper detailed, minimalist, horror, alien"
    },
    {
        "display": "Giancarlo Esposito as Dhalsim.",
        "full": "giancarlo esposito as dhalsim street fighter, 4 k, ultra realistic, detailed focused art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant swan princess portrait.",
        "full": "photo of a gorgeous young woman swan princess in the style of stefan kostic, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Anthropomorphic marijuana man portrait",
        "full": "an ultradetailed beautiful portrait panting of an anthropomorphic marihuana man, front view, oil painting, by ilya kuvshinov, greg rutkowski and makoto shinkai"
    },
    {
        "display": "Highly detailed Victoria Justice art",
        "full": "a beautiful victoria justice, art by ilya kuvshinov and lois van baarle and alphonse mucha and ross tran and range murata and artgerm, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Fleeing soldiers, Lovecraftian horror.",
        "full": "a cinematic scene from the fleeing human soldiers, lovecraft, concept art by beksinski and jean delville, dramatic lighting, ultra hd, hdr, 8 k"
    },
    {
        "display": "Peter Griffin as Shaktiman",
        "full": "Peter Griffin as Shaktiman, Shaktiman costume, Peter Griffin hairstyle, Peter griffin body type, Peter Griffin Face, calm, grumpy, portrait, masculine figure, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Postcyberpunk cityscape illustration",
        "full": "a long road leading to a large postcyberpunk city on the horizon in the distance. Gas street lamps. Fields on the sides of, the road, desolate. digital illustration, very vibrant colors, soft lighting, adventurous, atmospheric lighting, 8K, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "Beautiful female warrior portrait",
        "full": "a beautifull female warrior, character art portrait, fantasy style clothing, anime key visual, official media, illustrated by wlop, extremely detailed, 8 k, trending on artstation, cinematic lighting, beautiful"
    },
    {
        "display": "Nervous character with green hair.",
        "full": "life is strange highly detailed, nervously looking over shoulder, green hair, smooth, sharp focus, artstation, concept art, golden hour, soft lightning"
    },
    {
        "display": "Underwater whales, sunlight.",
        "full": "Looking up at a pod of whales underwater, the sun shines through them, intricate, highly detailed, digital painting, artstation, concept art"
    },
    {
        "display": "Hyperrealistic skeleton portrait",
        "full": "portrait of a skeleton, pixiv, hyperrealistic"
    },
    {
        "display": "Medieval Hobbit Home Scene",
        "full": "inside a medieval hobbit home, ornate, beautiful, atmosphere, vibe, mist, smoke, chimney, rain, spell - book, wet, pristine, puddles, waterfall, clear stream, bridge, forest, flowers, concept art illustration, color page, 4 k, tone mapping, doll, akihiko yoshida, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, digital illustration, greg rutowski, volumetric lighting, sunbeams, particles, artgerm"
    },
    {
        "display": "Eco-friendly skyscraper concept",
        "full": "an architecture concept art of an eco skinny skyscraper, vegetal walls, parcs in the front, city in the background, blue sky, 4 k, high quality, artstation"
    },
    {
        "display": "Scarred ferret with tattoo",
        "full": "close up of a ferret with scars and a face tattoo, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Giant art nouveau mecha.",
        "full": "a giant art nouveau mecha, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, trending on artstation, cinematic composition, beautiful lighting, hyper detailed, 8 k, oil on canvas"
    },
    {
        "display": "Humanoid toad portrait, fantasy.",
        "full": "Portrait of a humanoid toad with a top-hat and a monocle holding a magical rabbit, luxurious neckless, sunset, warm spring, detailed face, centered close-up, fantasy, anime, vibrant, colorful, depth of field, intricate details, trending on ArtStation, Deviantart, by WLOP"
    },
    {
        "display": "Ominous mechanical oppression",
        "full": "large mechanical device meticulously grinding down the truth and enslaving humanity, overpowering, ominous, torturous, dark, steam, chains, fire, spiked, rain, wet, clouds, lightning, puddles, consuming, parasitic, snow, creek, ice, bridge, forest, thorns, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha"
    },
    {
        "display": "Homeless Martin Shkreli portrait",
        "full": "Highly detailed portrait of homeless Martin Shkreli, in GTA V, Stephen Bliss, unreal engine, fantasy art by Greg Rutkowski, Loish, Rhads, Artgerm Makoto Shinkai and Lois van baarle, ilya kuvshinov, rossdraws, Tom Bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Giant Mecha Jellyfish Coastline",
        "full": "A very beautiful serene coastal landscape scene with a GIANT MECHA JELLYFISH looming in the distance, bright sunny waves splashing on the beach, The Great Wave off Kanagawa by hokusai, Translucent rendered by simon st\u00ef\u00bf\u00bdlenhag, rendered by Beeple, Makoto Shinkai, syd meade, environment concept, digital art, starwars, unreal engine, 3 point perspective, WLOP, trending on artstation, low level, 4K UHD image, octane render,"
    },
    {
        "display": "Stuck sister character portrait",
        "full": "step - sister gets stuck, realistic, aesthetic face, symmetrical face, female anatomy, female, bright, colorful, portrait, character portrait, concept art, symmetrical, 4 k, macro detail, realistic shadows, bloom, cosplay, deviant art"
    },
    {
        "display": "Pinup fantasy illustration.",
        "full": "damsel in distress, fame of thrones, pinup. lord of daggers, past, neon, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young man with beer.",
        "full": "a young man wearing raybands holding a beer giving a thumbs up with a long beard, real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Art Deco Goddess Portraits",
        "full": "breathtaking detailed concept art painting art deco pattern of blonde faces goddesses amalmation light - blue flowers with anxious piercing eyes and blend of flowers and birds, by hsiao - ron cheng and john james audubon, bizarre compositions, exquisite detail, extremely moody lighting, 8 k"
    },
    {
        "display": "Elegant female knight design",
        "full": "high fantasy female knight design with light armor and spear, elegant, semi realistic,intricate, high detailed, concept art, digital illustration, by alphonse mucha, artgerm, loish"
    },
    {
        "display": "Rugged forest god portrait",
        "full": "portrait of the god of the forest, 40 years old, rugged, male, gorgeous, detailed face, amazing, hairy torso, muscular, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Solarpunk cybertronic penguin.",
        "full": "A solarpunk very highly detailed  Anthropomorphic cybertronic penguin with very highly detailed face on the street of a very highly detailed solarpunk sci-fi city digital rational painting art by Greg Rutkowski, sci-fi highly detailed, digital concept art, Dimensional cyan gold natural light, sharp focus, Golden Ratio illustration, realistic concept art by Stephen Hickman and James Gurney and Hiromasa Ogura Ghost in the Shell rendered in Octane Render, From the distance"
    },
    {
        "display": "Cute anime librarian.",
        "full": "Anime style. Manga linework. Anime girl. Brown hair. adorable cute demure anime librarian wearing woolen sleeveless turtleneck. glasses girl. view from above. boyfriend angle. hd upscaled pixiv. sharp focus. trending image."
    },
    {
        "display": "Anime girl, night setting.",
        "full": "a girl with a soft warming smile, full shot, fine - face, realistic shaded perfect body, fine details. night setting. very anime style. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Elegant female vampire illustration",
        "full": "Vampire, female, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Emerald herald in garden.",
        "full": "the emerald herald in the garden, intricate, tone mapped, highly detailed, digital painting, pixiv, concept art, smooth, sharp focus, illustration, by makoto shinkai and akihiko yoshida"
    },
    {
        "display": "Cyberpunk glitch portrait",
        "full": "hyperrealistic portrait of a cyberpunk teenager, male, immersed within a glitch network, digital ui, by Guy Denning, Metzinger, Russ Mills, glitch art, hacking effects, glitch effects, digital al tech effects, chromatic, color blocking!, acrylic on canvas, concept art, abstract, trending on cgsociety, trending on artstation"
    },
    {
        "display": "Messi as a Muslim fantasy character",
        "full": "lionel messi as a muslim, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Goth woman in latex dress.",
        "full": "a curvy pale hot young goth woman wearing an elegant modest tight shiny two-toned striped latex high-neck dress, cgsociety, photorealistic, sublime-cool-hyperadvanced-dark-amorous ambience, 16k, smooth, sharp focus, trending on ArtStation, volumetric lighting, fully clothed, thin waist"
    },
    {
        "display": "\"Shy friends learning statistics\"",
        "full": "four shy male friends with their faces hidden by hair standing next to each other and to reverend bayes, they all wear thick glasses and want to learn statistics from the minister thomas bayes who is showing them the way, twilight, dusk, poster art by makoto shinkai, featured on pixiv, environmental art, official art, anime, movie poster"
    },
    {
        "display": "Rugged corgi portrait, fantasy.",
        "full": "portrait of a young, ruggedly handsome corgi, soft hair, muscular, half body, cloth, hairy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg DJ in fractal studio",
        "full": "a fisheye lens photo of a post apocalyptic tribal cyborg dj god tweaking and playing synthesizers in the most complicated and technical spiral fractal musical studio, powerful, cinematic, beautifully lit, by donato giancola, by artgerm, by karol bak, 3 d, perfect face and body, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Alien invasion concept art",
        "full": "alien invasion, simon stalenhag, high detail, concept art,"
    },
    {
        "display": "Sci-fi knight portrait",
        "full": "the doomslayer as a scifi knight, closeup portrait art by donato giancola and greg rutkowski, realistic face, digital art, trending on artstation, symmetry!!"
    },
    {
        "display": "Fantasy Art Deco Profile",
        "full": "! dream art deco gta 5 killer queen profile picture by greg rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell, fantasy lut, asymmetric, long hair, retro computer graphics, video game, fluid lines,"
    },
    {
        "display": "Cozy anime portrait, sad schoolgirls.",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai. beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. beautiful and cutest sad face. dramatic deep light, trending on artstation, oil painting brush"
    },
    {
        "display": "Kawaii Aerith in Fargo",
        "full": "super intricately detailed color pencil illustration of aerith gainsborough in the movie fargo. beautiful shadowing, 3 d shadowing, reflective surfaces, illustrated completely, 8 k beautifully detailed pencil illustration, extremely hyper - detailed pencil illustration, intricate, epic composition, very very kawaii, masterpiece, bold complimentary colors. stunning masterfully illustrated by artgerm and range murata."
    },
    {
        "display": "Cybernetic deer chef.",
        "full": "daemonic mecha cyber deer chef, dynamic pose, in clouds, ion, by greg hildebrandt, studio lighting, muted colors, by terry richardson, concept art by leonardo davinci, extreme detail, volumetric, reflective, trending on artstation, 8 k"
    },
    {
        "display": "\"Photorealistic Batman in Rain\"",
        "full": "''batman in the rain, cinematic shot, beautiful night, photorealistic, concept art, high quality, 8 k''"
    },
    {
        "display": "Beautiful anime Hatsune Miku",
        "full": "Anime art very beautiful Hatsune miku by artgerm, Gil Elvgren, Vladimir Volegov, Earl Moran, Enoch Bolles, symmetrical shoulders, smiling, bra with ribbon"
    },
    {
        "display": "Drowning digital art.",
        "full": "drowning, digital art, trending on artstation"
    },
    {
        "display": "Ninja girl at sunrise.",
        "full": "portrait light cyan hair ninja gaiden girl, black plus little blue ninja wardrobe, at snowy fuji mountain sunrise, ssci - fi and fantasy, intricate and very very beautiful, detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration, art by tian zi and wlop and alphonse mucha"
    },
    {
        "display": "Cassandra Cain Wedding Dress",
        "full": "cassandra cain in a wedding dress, cg animation, riot entertainment, arcane, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Mysterious woman warrior portrait.",
        "full": "portrait of a beautiful mysterious woman warrior wearing an armour costume, profile view, hidden hands, riding horse, fantasy, regal, intricate, by stanley artgerm lau, greg rutkowski, thomas kinkade, alphonse mucha, loish, norman rockwell"
    },
    {
        "display": "Intricate neon earthsea illustration",
        "full": "earthsea,  neon, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young woman in tactical gear",
        "full": "portrait of a young woman by greg rutkowski, jaina solo, wearing the tactical gear of the galactic alliance, star wars expanded universe, she is about 1 6 years old, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Cosmic goddess portrait.",
        "full": "a beautiful portrait of a cosmic goddess by Jim Burns and Tom Bagshaw, Trending on Artstation, nebula background"
    },
    {
        "display": "Boyish profile portrait",
        "full": "side profile of ilya kuvshinov with long hair, sky blue hair, gold hazel eyes, boyish face, high collar, black jacket, professional digital painting, concept art, award - winning photography, cinematic, stained glass window, wlop, art by pixiv art, yoshitaka amano, junki ito"
    },
    {
        "display": "Ultrarealistic angel portrait",
        "full": "a masterpiece ultrarealistic ultradetailed portrait of a incredibly beautiful angel moon princess with damaged glass beautiful armor. baroque renaissance night elf girl in the night forest. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, background by james jean and gustav klimt, light by julie bell, 4 k, porcelain skin."
    },
    {
        "display": "Flying steampunk fortress",
        "full": "a flying titanic steampunk fortress, explosive storm, extremely detailed, behrens style, unreal 5 render, fantasy digital art, octane render, beautiful composition, trending on artstation, award - winning photograph, masterpiece"
    },
    {
        "display": "Male demon warlock concept art",
        "full": "man male demon, full body white purple cloak, warlock, character concept art, costume design, illustration, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Ayanami Rei Illustration",
        "full": "ayanami rei, winds of winter, au naturel, blue eyes, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Cyberpunk girl on bike",
        "full": "girl wearing cyberpunk intricate streetwear riding bike, respirator, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Alien goddess close-up portrait",
        "full": "a highly detailed metahuman 4 k close up render of an alien goddess bella hadid monument medieval in iris van herpen dress schiaparelli in diamonds crystals swarovski and jewelry iridescent in style of alphonse mucha gustav klimt trending on artstation made in unreal engine 4"
    },
    {
        "display": "Pale Indian doctors outside hospital",
        "full": "Anxious good looking pale young Indian doctors wearing American clothes outside a hospital, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyper-realistic AI singularity art",
        "full": "the most amazing dream you ever had about male head artificial intelligence singularity, moebius, hyper realistic, concept art, intricate, hyper detailed, smooth, jim lee, high contrast, neon, volumetric lighting, octane, raytrace"
    },
    {
        "display": "Cyberpunk cybernetic portrait",
        "full": "a portrait of a cybernetic man wearing virtual reality headset, smoking a cigarette, wires, cyberpunk concept art by josan gonzales and philippe druillet and dan mumford and enki bilal and jean claude meziere"
    },
    {
        "display": "Cloaked tech priest portrait",
        "full": "painting of jimmy mcgill from tv show better call saul as a cloaked tech priest holding a book, adeptus mechanicus, cybernetic enhancements, zdzislaw beksinski, lewis jones, dariusz zawadzki, wayne barlow, warhammer 4 0 k, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Ghost ship at sunset",
        "full": "Ghost ship in the stormy sea, sunset, by Aivazovsky, trending on artstation, sunset, 8k, sharp high quality,"
    },
    {
        "display": "Viking warrior on bear.",
        "full": "viking barbarian emma watson riding a grizzly bear like a horse wielding a giant axe, fantasy art, golden hour, classic oil painting, thick brush strokes, epic art, loose art, by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Exploding hearts cityscape",
        "full": "a collision of exploding black hearts and white hearts in surreal abstract form of a cityscape, vivid vibrant geometric art deco. trending on pixiv and artstation. an absurdly detailed oil painting"
    },
    {
        "display": "Hybrid bear-snake painting",
        "full": "painting of hybrid between bear & snake!!!!, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Art Deco Goddess Portrait",
        "full": "portrait breathtaking detailed concept art painting art deco pattern of birds goddesses amalmation flowers head thibetan temple, by hsiao ron cheng, tetsuya ichida, bizarre compositions, tsutomu nihei, exquisite detail, extremely moody lighting, 8 k, art nouveau, old chines painting, art nouveau"
    },
    {
        "display": "Bipedal bumblebee violinist at sunset.",
        "full": "epic professional digital art of A bipedal bumblebee playing the violin during sunset in a forest, best on artstation, breathtaking, epic, stunning, gorgeous, much detail, much wow, cgsociety, wlop, pixiv, behance, deviantart, masterpiece, UHD, 8K"
    },
    {
        "display": "Hybrid lemur-jellyfish painting",
        "full": "painting of hybrid between lemur & luminescent jellyfish, intercrossed animal, zdzislaw beksinski, kim donaldson, mattias adolfsson, tiffany bozic, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Realistic fantasy portrait of Prison Mike.",
        "full": "highly detailed portrait of prison mike, michael scott, the office, steve carell, realistic, fantasy art by greg rutkowski, stanley artgerm, loish, rhads, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Cyborg DJ in fractal studio",
        "full": "a fisheye lens photo of a post apocalyptic tribal cyborg dj tweaking and playing synthesizers in the most complicated and technical spiral fractal musical studio, powerful, cinematic, beautifully lit, by donato giancola, by artgerm, by karol bak, 3 d, perfect face and body, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Cyberpunk woman in city",
        "full": "beautiful,  full-body, young woman, cybernetic, cyberpunk, wires, cables, halo, glow, bladerunner city in the background, detailed gorgeous face, flowing hair, vaporwave aesthetic, synthwave , digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg female portrait.",
        "full": "hyperrealistic photography of a stunningly beautiful cyborg female, mechanical elbow, plated arm, intimate, holding hands, in the style of beth cavener, jin kagetsu,, and wlop, highly detailed, intricate filigree, fanning blades, chrome face symmetry, ringflash, masterpiece, award - winning, sharp focus, concept art, high key, ambient lighting, 8 k, octane render, artstation"
    },
    {
        "display": "Elegant Asian vampire girl",
        "full": "a brown skinned asian vampire girl, child, freckles, fangs, messy hair, embers intricate and very beautiful and elegant, highly detailed, digital painting, artstation, concept art, smooth and sharp focus, cg by tian zi and wlop"
    },
    {
        "display": "Darth Vader as Napoleon",
        "full": "darth vader dressed as napoleon, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Ultra-realistic cyborg illustration",
        "full": "ultra realistic illustration of cyborg cinematic 8 k hd movie shot, intricate, warframe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by akihiko yoshida and tim mcburnie and anato finnstark"
    },
    {
        "display": "Muscular male portrait, cinematic.",
        "full": "male, muscular, cinematic lighting, dramatic atmosphere, by Craig Mullins, 4k resolution, trending on artstation"
    },
    {
        "display": "Grumpy Stephen Fry, Pixar-style",
        "full": "lovecraftian portrait of grumpy stephen fry, pixar style, by tristan eaton stanley artgerm and tom bagshaw"
    },
    {
        "display": "Demonic muscular creature painting",
        "full": "Dark fantasy Painting of a hulking muscular demonic with drool dripping from its mouth, hr giger muscles, disgusting, creepy, unsettling, horror, upper body, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant purple-haired character",
        "full": "Background Purple hair, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime art of Hatsune Miku",
        "full": "Anime art very beautiful Hatsune miku by Gil Elvgren, Vladimir Volegov, Earl Moran, Enoch Bolles, Lois van baarle, Greg Rutkowski, symmetrical shoulders, tiny black bra"
    },
    {
        "display": "Witch's house in forest.",
        "full": "a witches house nestled between the lush forest, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli, eugene von guerard, ivan shishkin, john singer sargent"
    },
    {
        "display": "Ginger surfer in pasta sea",
        "full": "beautiful ginger surfer is surfing on a surfboard in a sea of pasta, art nouveau, fantasy, intricate pasta waves, elegant, highly detailed, sharp focus, action art by artgerm and greg rutkowski and wlop"
    },
    {
        "display": "Ferrofluid-acrobatic schoolgirl portrait.",
        "full": "detailed procedural render expressive dynamic fluid action scene of ferrofluid spacesuit schoolgirl dress protects the acrobatic coolest girl from doom as the planet they stand sends spores surround her, photorealism, sharp focus, award winning, volumetric, raytracing, tristan eaton, victo ngai,, maxfield parrish, artgerm, intricate details, portrait, bokeh"
    },
    {
        "display": "Cyberpunk girl portrait",
        "full": "portrait of beautiful girl with robot body by fenghua zhong and ruan jia, close up, portrait, cinematic, elegant, artstation, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cyberpunk, cgsociety, 8 k"
    },
    {
        "display": "God Shiva portrait illustration",
        "full": "wide shot of god shiva the destroyer digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Gigachad Nietzsche Comic Art",
        "full": "ultimate Gigachad Friedrich Nietzsche with a chiseled Jawline and serious Look, in his suit, in the Style of Artgerm and Charlie Bowater and Atey Ghailan and Mike Mignola, vibrant colors and hard shadows and strong rim light, Comic Cover Art, plain background, trending on artstation"
    },
    {
        "display": "Realistic Levi Ackerman portrait",
        "full": "levi ackerman in real life, attack on titan, hyper detailed, digital art, 8 k realistic, trending in artstation, cinematic lighting, studio quality, cryengine, frostbite 3 engine, character design, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Detailed Iron Man portrait.",
        "full": "iron man portrait, highly detailed, science fiction landscape, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart, octane render, artstation trending"
    },
    {
        "display": "Handsome Spartan bodybuilder portrait.",
        "full": "handsome portrait of a spartan guy bodybuilder posing, by gaston bussiere, bayard wu, greg rutkowski, giger, maxim verehin"
    },
    {
        "display": "Cyberpunk princess concept art",
        "full": "a beautiful white haired princess, intricate concept art, ethereal, ominous, dramatic lighting, illuminated lines, outrun, vaporware, cyberpunk darksynth, dark background, 8 k, by ruan jia and krenz cushart and alphonse mucha"
    },
    {
        "display": "Anime witch with capybara.",
        "full": "full body portrait character concept art, anime key visual of a little witch with her capybara mascot, cinematic lighting, dramatic atmosphere, by dustin nguyen, akihiko yoshida, greg tocchini, greg rutkowski, cliff chiang, 4 k resolution, octane render"
    },
    {
        "display": "Armored blonde warrior portrait",
        "full": "a d & d portrait of a tough armored woman, blonde hair, rpg character, gloomy colors, muted colors, by artgerm"
    },
    {
        "display": "Vintage sweet shop interior",
        "full": "Inside an old fashioned sweet shop, large jars on shelves, beautiful labels, fantasy vendor interior, wide angle, cinematic, highly detailed, photorealistic, rich bright colors, trending on artstation, trending on cgsociety"
    },
    {
        "display": "Monster treasure chest illustration",
        "full": "a monster treasure chest, black and white, fantasy art, monster art, illustration, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Elegant phoenix in flames",
        "full": "phoenix bahamut in white dresses of fireflies and flameches, flames intricate stainglass background, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, intricate art nouveau frame, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Dense forest matte painting",
        "full": "A highly detailed matte painting of a dense forest by Mokoto Shinkai, breathtaking, by Greg Rutkowski, by Artgerm, by beeple, by Studio Ghibli, golden hour, volumetric lighting, octane render, 4K resolution, trending on artstation"
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "Aishwarya roy, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "a portrait of ana de armas, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Female Viking Warrior Portrait",
        "full": "closeup portrait shot of a female viking warrior in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Half Tom Cruise, half Daniel Radcliffe.",
        "full": "half Tom Cruise, half Daniel Radcliffe, black hair, blue eyes, in a white lab coat, dramatic backlighting, autochrome, high contrast, highly detailed, sharp focus, digital painting, concept art, illustration, trending on artstation, art by greg rutkowski and greg hildebrandt, composition by alphonse mucha"
    },
    {
        "display": "Girl eating toast, artistic style",
        "full": "a girl eating a slice of toast, full shot, atmospheric lighting, detailed face, one piece style, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Bob Ross painting Hulk.",
        "full": "a closeup photorealistic photograph of bob ross working on a canvas painting of hulk. film still. brightly lit scene. mountains and trees. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Cell-shaded female robot portrait",
        "full": "a study of cell shaded protrait of female robot as Borderlands 3 concept art, llustration, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00edn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, alien, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Golden UFO at Stonehenge",
        "full": "a realistic and atmospheric watercolour fantasy concept art of a golden ufo landing on top of stonehenge. female medieval monk in grey robes kneeling with her hands by her sides. by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Intricate digital painting.",
        "full": "daddy issues, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hobbit in Adidas Track Suit",
        "full": "a detailed fifty mm portrait of ( dominic monaghan ) merry brandybuck as a hobbit in a black adidas track suit with white stripes, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by met mangindaan, artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Vaporwave woman with mask",
        "full": "beautiful, young woman, sad eyes, tears running down, vaporwave aesthetic, synthwave, colorful, psychedelic, holding mask of own face below actual face, long gown, flowers, bees, butterflies, ribbons, ornate, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Blade Runner Rachael Portrait",
        "full": "portrait of blade runner rachael, holding a cigarette in her hand, digital art, art by artgem, indoor light, volumetric lighting, digital painting, smokey background, concept art, trending on artstaion"
    },
    {
        "display": "Hyperrealistic squidbillies render",
        "full": "hyperrealistic dslr film still of early cuyler squidbillies, anthropoid cephalopod, stunning 8 k octane comprehensive 3 d render, inspired by istvan sandorfi & greg rutkowski & unreal engine, perfect symmetry, dim volumetric cinematic lighting, extremely hyper - detailed, extremely lifelike attributes & lifelike texture, intricate, masterpiece, artstation, stunning"
    },
    {
        "display": "Elegant organic cyborg portrait",
        "full": "organic cyborg, man, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Goddess Pharaoh Portrait",
        "full": "an anthropomorphic beautiful goddess female pharaoh portrait holding a staff wearing desert robe made of dust in a desert, round glasses, dreadlock breed hair, fine art, award winning, intricate, elegant, sharp focus, octane render, hyperrealistic, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by jamie hewlett and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Fierce Bogart Fantasy Portrait",
        "full": "fierce humphrey bogart, fantasy character portrait, ultra realistic, concept art, intricate details, highly detailed by greg rutkowski, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Stylish girl in nature.",
        "full": "a girl, very sexy nature outfit, medium shot, visible face, detailed face, perfectly shaded, atmospheric lighting, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Nepali woman pumpkin queen.",
        "full": "A beautiful portrait of a Nepali woman pumpkin queen, vibrant color scheme, highly detailed, in the style of romanticism, cinematic, artstation, character portrait, epic fantasy, 3d with depth of field, blurred background, female, nautilus. A highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, stylized, very inspirational, Greg rutkowski, Koda Kazuma, Hayao Miyazaki, Tetsuya Nomura, Yusuke Murata, digital art by Eugene de Blaas and Ross Tran"
    },
    {
        "display": "Anime-style Nazi officer portrait",
        "full": "baroque oil painting full body portrait character concept art, anime key visual of young female nazi officer maid, long flowing platinum hair blue eyes, finely detailed symmetrical perfect face studio lighting delicate features directed gaze, black military uniform, gapmoe kuudere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Psychedelic portrait of Scarlett Johansson.",
        "full": "An extremely psychedelic portrait of Scarlett Johanson wearing a crown, surreal, LSD, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Cyberpunk New York City",
        "full": "New York City by Neon Light, in the style of Cyberpunk Impressionism, Krenz Cushart, Moebius, and Muchain, Prismatic, Rococo, Pearlescent, reflective, shimmering, highly detailed, masterpiece, dreamy, concept art, Cinema lighting, 8k, trending on artstation"
    },
    {
        "display": "Sleeping black dragon in castle.",
        "full": "black dragon sleeping in the castle, rpg style, dnd style, ultra detailed, trending on artstation, concept art, octane render, unreal engine,"
    },
    {
        "display": "Iron Giant on farm",
        "full": "iron giant standing near his farm, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Cyberpunk cityscape illustration",
        "full": "cyberpunk illustration by shigenori soejima, street gang, concept art, intricate cyberpunk city, orange overlooking city"
    },
    {
        "display": "Neon-lit cyberpunk basement.",
        "full": "down a basement staircase neon lights cyberpunk style digital painting concept art smooth sharp focus hyperrealistic illustration artstati"
    },
    {
        "display": "Dinosaur footprint with magic.",
        "full": "dinosaur print in the ground, magical energies emanating from it, fantastic artstyle,, fantasy, elegant, digital painting, artstation, unreal engine, octane render, concept art, matte, sharp focus, vibrant colors, high contrast, illustration, art by justin gerard"
    },
    {
        "display": "Epic ancient Greek warrior",
        "full": "Ancient greek warrior, holding an ancient sword, handsome, tough, epic, badass, cinematic, dramatic, highly realistic, jacques louis david, 4k, octane render, trending on artstation, volumetric lighting"
    },
    {
        "display": "Cyberpunk astronaut illustration",
        "full": "ultra realistic illustration, astronaut hacknaut cyberpunk, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Girl emerging from abyss.",
        "full": "little girl coming out of the abyss surrounded by tentacles, art by thomas cole, highly detailed, digital painting, artstaion, smooth, sharp, focus, illustration, art by artgerm"
    },
    {
        "display": "Androgynous alien muse.",
        "full": "a hybrid humanoid androgyne muse with recursive horned wing crown attached to side of head, concept art, alien-like, sculpted iridescent optical mineralogy features, intricate detail, holographic, pixel sorting, style by James Jean, circuitry, organic detail, asymmetry, cinematic, epic wide shot, ultra detailed, artstation, sharp focus,smooth, cinematic lighting, cinematic detail, composition, photorealistic, render in unreal engine 5, golden ratio, 8k render"
    },
    {
        "display": "Portrait of Kate Bush",
        "full": "portrait of kate bush against a dark background, lush black hair, pale skin, white and red roses, flowing material, intricate, beautiful cinematic lighting, stunning painting by artgerm, caravaggio, android jones, wadim kashin"
    },
    {
        "display": "Elegant portrait of Emma Watson",
        "full": "art artgerm emma watson seductive tasteful, elegant"
    },
    {
        "display": "Ultrarealistic angel princess portrait.",
        "full": "A masterpiece ultrarealistic portrait of a Irristible angel princess tribal-shaman-knight-witch-ghost with  Skull  Iron mask. baroque renaissance  girl  in the night forest. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, Yoshitaka Amano. background by James Jean and Gustav Klimt, light by Julie Bell, 4k, porcelain skin."
    },
    {
        "display": "Gothic vaporwave diva portrait",
        "full": "a full body portrait of the lisa frank daftpunk vaporwave diva, gothic, highly detailed, digital painting, crown of skulls, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Giant white chess bishop.",
        "full": "a giant white chess bishop statue, battlefield background, bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, hearthstone card game artwork, chess piece"
    },
    {
        "display": "Intricate sunset garden butterflies",
        "full": "butterflies in a garden sunset sky, oil on canvas, intricate, portrait, 8k highly professionally detailed, HDR, CGsociety, illustration painting by Mandy Jurgens and Ma\u0142gorzata Kmiec and Dang My Linh and Lulu Chen and Alexis Franklin and Filip Hodas and Pascal Blanch\u00e9 and Bastien Lecouffe Deharme, detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, Cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8K, style by Wes Anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon st\u00e5lenhag, rendered by Beeple, by Makoto Shinkai, syd meade, 8k ultra hd, artstationHD, 3d render, hyper detailed, elegant, by craig mullins and marc simonetti, Ross Tran and WLOP, by Andrew Wyeth and Gerald Brom, John singer Sargent and James gurney"
    },
    {
        "display": "Muscular Tatsumaki in gym.",
        "full": "gym bro Tatsumaki with muscles opm by Jim Burns, trending on artstation, professional comission, high quality art"
    },
    {
        "display": "Hyperrealistic Vin Diesel disguise",
        "full": "hyperrealistic dslr film still of vin diesel disguised as osama bin laden, color, stunning 8 k octane comprehensive 3 d render, inspired by istvan sandorfi & greg rutkowski & unreal engine, perfect symmetry, dim volumetric cinematic lighting, extremely hyper - detailed, incredibly real lifelike attributes & flesh texture, intricate, masterpiece, artstation, stunning"
    },
    {
        "display": "Elon Musk laughing portrait.",
        "full": "Portrait of Elon Musk laughing hysterically as stock prices crash, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha, portrait"
    },
    {
        "display": "Hyper-detailed Blanka portrait",
        "full": "portrait of blanka from street fighter, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Cyberpunk dwarf mage portrait",
        "full": "portrait painting of a cyberpunk dwarf mage david tennant with braided beard, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Sacred water spirit design.",
        "full": "beautiful decorative classical ornamental iconic imagery, sacred water spirit, alchemical symbols, elemental, fibonacci rhythms, lilies, petals, lily petals, acanthus scrolls, small medium and large elements, artgerm, trending on artstation, wlop, russ abbott"
    },
    {
        "display": "Anime girl in kimono",
        "full": "\u201c anime, a beautiful girl wearing a kimono walking through the streets of tokyo, light and shadow effects, intricate, highly detailed, digital painting, art station, concept art, smooth, sharp focus, illustration, advanced digital anime art, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau, craig mullins, j. c. leyendecker \u201d"
    },
    {
        "display": "Fist punch against troll.",
        "full": "fists of fury combo punch against a troll, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Scarlett Johansson as Supergirl",
        "full": "scarlett johansson as supergirl by greg rutkowski"
    },
    {
        "display": "Cybernetic empress portrait",
        "full": "a beautiful empress portrait, with a brilliant, impossible striking big cybernetic headpiece, cybernetic clothes, symmetrical, dramatic studio lighting, rococo, baroque, asian, hyperrealism, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, 8k, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Intricate fantasy portrait",
        "full": "portrait of great teacher onizuka, face, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Excited bouncing riceballs.",
        "full": "magic : the gathering fantasy concept art of excited riceballs with excited expressions bouncing down a mountain path, by marco bucci, high resolution, the riceballs are bouncing up and down, rice granules scattered all around, balls of rice, bouncing, fantasy coloring, intricate, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Stormy ship at sea",
        "full": "a wooden big ship in the middle of the sea during a storm, turbulent sea, high coherence, highly detailed, high quality, 8 k, dramatic lighting, cinematic, epic scene, path traced, hyperrealistic, concept art, octane render, unreal engine 5, trending on artstation, a red cross in the ship flag, high contrast"
    },
    {
        "display": "Young Sharon Stone, serial killer.",
        "full": "young sharon stone as a serial killer queen, incredibly detailed face, light half opened dress, true anatomy, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Female Gordon Freeman concept art",
        "full": "gordon freeman as a woman, hd shot, concept art, artstation, by artgerm and jakub rozalaki"
    },
    {
        "display": "Surreal dog and owner",
        "full": "painting of a dog and it's lifeless owner wandering the streets of london, half the scene is glitched, horde of cosmic artifacts, by zdzislaw beksinski, by dariusz zawadzki, by wayne barlowe, gothic, surrealism, cold hue's, warm tone gradient background, concept art, focused scene, medium shot, beautiful composition"
    },
    {
        "display": "Abandoned Mystery Machine with zombies.",
        "full": "mystery machine abandoned filled with zombiems swarming around, dirty, zombie apocalypse, cinematic, detailed, epic, widescreen, opening, establishing, mattepainting, photorealistic, 4 k, octane render, art by greg rutkowski"
    },
    {
        "display": "Anime landscape wallpaper",
        "full": "beautiful anime wallpaper, landscape, green rolling hills, bright, intricate, sharp focus, lens flare, bloom, illustration, highly detailed, digital painting, concept art, matte, art by ruan jia and wlop and greg rutkowski, masterpiece"
    },
    {
        "display": "Ornate medieval hobbit home",
        "full": "a medieval hobbit home, ornate, beautiful, atmosphere, vibe, mist, smoke, chimney, rain, well, wet, pristine, puddles, waterfall, clear stream, bridge, red, green, forest, flowers, concept art illustration, color page, 4 k, tone mapping, doll, akihiko yoshida, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, digital illustration, greg rutowski, volumetric lighting, sunbeams, particles, rembrandt"
    },
    {
        "display": "Cyberpunk elven sniper artwork",
        "full": "a watercolor ink painting of an cyberpunk elven sniper in the style of jean giraud in the style of moebius trending on artstation deviantart pinterest detailed realistic hd 8 k high resolution"
    },
    {
        "display": "Nighttime Chinese tavern scene",
        "full": "fantasy art of a bustling tavern in china, at night, by makoto shinkai, highly detailed digital art, trending on artstation"
    },
    {
        "display": "Brazilian Darth Vader portrait",
        "full": "a study of cell shaded portrait of brazilian Darth Vader as Borderlands 3 concept art, llustration, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00edn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, alien, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Galactic Onagadori chicken warrior.",
        "full": "anthropomorphized ((Onagadori chicken)), galactic crusader, detailed bronze armor, fantasy, intricate, elegant, digital painting, trending on artstation, concept art, sharp focus, illustration by Gaston Bussiere and greg rutkowski, beeple, 4k."
    },
    {
        "display": "Dune-themed digital art.",
        "full": "keepers of time and space ,dune ,digital art, sharp focus,high quality,8k ,artation,cgsociety, by greg rutkowski and  artgerm , very detail, wallpaper"
    },
    {
        "display": "Fantasy portrait of Welsh girl.",
        "full": "portrait of a welsh teenage girl with brown hair, glowing skin, delicate features, amelie poulain, fantasy, intricate, elegant, dress shirt, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Anthropomorphic trapezoid character.",
        "full": "anthropomorphic isosceles trapezoid head in electric jackhusk joshua jackson acolyte, intricate, elegant, highly detailed face, wide angle, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Elegant phantom portrait",
        "full": "phantom by charlie bowater and titian and artgerm, full - body portrait, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Child's drawing of house",
        "full": "a drawing of a house with a lot of windows, a child's drawing by mattias adolfsson, behance contest winner, hypermodernism, photoillustration, 1 9 9 0 s, concept art"
    },
    {
        "display": "Boris Johnson as Superman",
        "full": "Boris Johnson as Superman, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk starship interior concept",
        "full": "starship interior, cyberpunk, far future, high contrast, detailed, concept art, by kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle"
    },
    {
        "display": "Intricate floral vase art",
        "full": "beautiful amazing art of intricate flowers arrangement in vase, modern color, by art by alesso baldovinetti, trending on artstation, featured on behance, octane render, vector art, f 4, ultrawide, golden ratio, well composed, cohesive"
    },
    {
        "display": "Surreal alien landscape masterpiece.",
        "full": "landscape abandoned alien structure on exoplanet, wrecked technology, antennas, spire, dark clouds, surreal abandoned buildings, dream-like heavy atmosphere, baroque painting, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, dramatic volumetric cinematic light, chiaroscuro, award-winning photograph, masterpiece, Raphael, Caravaggio, Beksinski, Giger"
    },
    {
        "display": "Mothership in deep space.",
        "full": "A large mothership or space station in deep space with many small ships flying around it, 3d with depth of field and blur, digital painting, science fiction, cinematic lighting, artstation, 8k, concept art, epic composition, by Justin Peters and Filip Hodas"
    },
    {
        "display": "Gothic landscape with dragon.",
        "full": "landscape portrait of a gothic biome with glowwave dragon from overwatch, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by pixar dreamworks warner bros disney riot games and overwatch."
    },
    {
        "display": "Muddy river delta sunset",
        "full": "top view of muddy river delta joining sea by hiroshi yoshida, marc simonetti, roger deakins, turner, rothko, hyperrealistic, cinematic, rock plateau, dramatic stormy scene, sunset, matte painting, fluid simulation, trending on artstation, 8 k high detail concept art, rendered in octane"
    },
    {
        "display": "Futuristic fishing village.",
        "full": "fishing village crowded with houses around a lake, concept art, digital painting, style of jordan grimmer, warm lighting, futuristic, volumetric lighting, view from below, vivid colours, bright, daytime, godrays, high detail"
    },
    {
        "display": "Symmetrical sci-fi portrait",
        "full": "symmetry portrait of mercy from overwatch, sci - fi, tech wear, glowing lights intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Angelic warrior in armor",
        "full": "an angelic warrior in heavy armor, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render"
    },
    {
        "display": "Cinematic Red Dead Redemption 2",
        "full": "cinematic screenshot from red dead redemption 2 ; crisp sharp focus ; ultra realistic, concept art, intricate details, dramatic action, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Cosmic Dilbert Revival",
        "full": "the second coming of dilbert, by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Symmetrical humanoid robot portrait",
        "full": "friendly humanoid robot, portrait bust, symmetry, faded colors, exotic alien features, cypherpunk background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, cinematic composition, beautiful lighting, sharp, details, hyper detailed, 8 k, unreal engine 5"
    },
    {
        "display": "Fantasy elf druidess artwork.",
        "full": "moonshine cybin, buxom epic level dnd wood elf spore druidess, wielding a magical sword, wearing magical overalls. covered in various fungi. full character concept art, realistic, high detail painting by angus mcbride and michael whelan and michael william kaluta."
    },
    {
        "display": "Ultra-realistic Catwoman portrait",
        "full": "painting of lisa edelstein as catwomen,, ultra realistic, sharp details, subsurface scattering, intricate details, warm lighting, beautiful features, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bloodied pale woman portrait",
        "full": "pale woman covered with blood, olivia wilde face!!!, red hair, skeleton tattoo!, close up portrait, highly detailed face, ultra realistic, concept art, intricate details, 4 5 mm. photorealistic, octane render, 8 k, unreal engine. film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young woman with gun",
        "full": "photograph of a young woman wearing a all black clothes holding a Gun, detailed, artstation, concept art, Unreal Engine 5 render, gameplay showcase, 8K"
    },
    {
        "display": "Lifelike marble bust of Bernard Manning",
        "full": "amazing lifelike award winning marble bust of Bernard manning trending on art station artgerm Greg rutkowski alphonse mucha arno breker cinematic"
    },
    {
        "display": "Beautiful Asuna in bikini.",
        "full": "a very beautiful yuuki asuna, full body, long wavy blond hair, sky blue eyes, full round face,, bikini, miniskirt, front view, mid - shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "Lifelike audio sampler portrait",
        "full": "perfectly - centered portrait - photograph of an sp - 4 0 4 audio sampler, lifelike, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Gothic cyberpunk cyborg art",
        "full": "soft lustrous ebony biotech raver gutter punk gothic cyborg, golden ratio, details, scifi, fantasy, cyberpunk, intricate, decadent, highly detailed, digital painting, octane render, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, loish, wlop"
    },
    {
        "display": "Girl studying in dorm.",
        "full": "digital art of a girl sitting at a desk in her dorm room studying, trending on artstation"
    },
    {
        "display": "Rugged forest god illustration.",
        "full": "god of the forest, 3 0 years old, rugged, male, gorgeous, detailed face, amazing, thighs, flowers, muscular, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed Sion portrait.",
        "full": "Sion from League of legends, the dead giant, extremely detailed digital painting, in the style of Fenghua Zhong and Ruan Jia and jeremy lipking and Peter Mohrbacher, mystical colors, rim light, beautiful Lighting, 8k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Victorian astronaut portrait",
        "full": "portrait of a vicotrian astronaut man in suit by darek zabrocki and greg ruthkowski, alphonse mucha, simon stalenhag and cinematic and atmospheric, concept art, artstation, trending on artstation"
    },
    {
        "display": "Cute anime girl portrait",
        "full": "nekopara cute fantastically detailed eyes modern anime style art vibrant detailed cat ears girl nekomimi dress portrait  shinkai makoto Studio ghibli Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Menacing demon hunter portrait.",
        "full": "Half body portrait of a menacing demon hunter in long trench coat with black fog oozing. In style of Yoji Shinkawa and Hyung-tae Kim, trending on ArtStation, dark fantasy, great composition, concept art, highly detailed, dynamic pose."
    },
    {
        "display": "Kitsune shrine guardian.",
        "full": "Kitsune protecting a shrine in middle of the magic forest, fantasy colours, highly detail, HDR, concept art, Trending on Artstation"
    },
    {
        "display": "Ultra-realistic cotton candy chicken",
        "full": "cotton candy chicken marinade ultra realistic , lens flare, atmosphere, glow, detailed,intricate, full of colour, cinematic lighting, trending on artstation, 4k, hyperrealistic, focused, extreme details, unreal engine 5, cinematic, masterpiece"
    },
    {
        "display": "Anime Billie Eilish portrait",
        "full": "portrait Anime as Billie Eilish"
    },
    {
        "display": "Goddess of Sunflowers Art",
        "full": "breathtaking detailed concept art painting of the goddess of sunflower flowers, orthodox saint, with anxious, piercing eyes, ornate background, amalgamation of leaves and flowers, by Hsiao-Ron Cheng, James jean, Miho Hirano, Hayao Miyazaki, extremely moody lighting, 8K"
    },
    {
        "display": "Luffy illustration by Goro Fujita",
        "full": "goro fujita ilustration of luffy, small streaks of light through, painting by goro fujita, sharp focus, highly detailed, artstation"
    },
    {
        "display": "Tree in scrap car sunset",
        "full": "low angle shot of tree growing inside scrap car in the foreground. overgrown. soft golden red sunset over the mountains in the background. clouds. detailed leaves. hyperrealistic, highly detailed, cinematic, beautiful, cgsociety, artstation, oil painting by greg rutkowski, by artgerm, by wlop"
    },
    {
        "display": "Elegant fantasy tarot illustration",
        "full": "jossi of blackpink, wearing glasses, wearing a suit, tarot card art, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cthulhu throne underwater ruins",
        "full": "cthulhu sitting on a throne in an underwater ruins, dark souls inspired, elden ring inspired, octane render, rtx, unreal engine 5, digital painting, trending on artstation, highly detailed, epic composition, 8 k uhd"
    },
    {
        "display": "Glamorous glowing dinosaur.",
        "full": "sensual dinosaur, glam, fae, glowing skin, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and fra angelico and unreal engine 5"
    },
    {
        "display": "Electronic Japanese garden illustration",
        "full": "beautiful illustration of electronic japanese gardens, trending on pixiv, artstation"
    },
    {
        "display": "Nazi maid character portrait",
        "full": "baroque oil painting anime key visual full body portrait character official art, young female nazi maid gestapo, flowing blonde hair blue eyes, fitted pure black leather military commander uniform, belt skirt boots, gap moe kuudere noble grimdark fantasy dictator, trending pixiv fanbox, greg rutkowski makoto shinkai takashi takeuchi studio ghibli jamie wyeth"
    },
    {
        "display": "Cillian Murphy as Tommy Shelby",
        "full": "a portrait of cillian murphy as tommy shelby in atlantis, highly detailed, realistic face, digital art, epic, fantasy, in the style of artgerm, sharp, artstation"
    },
    {
        "display": "Bronze alien fish sculpture",
        "full": "hyperrealistic sculpture of a bronze fossilized ancient alien hermit flounder dusted with saffron and deep blue and grass green spraypaint in a grid cage on a pedestal by ron mueck and duane hanson and lee bontecou, hyperrealistic dramatic colored lighting trending on artstation 8 k"
    },
    {
        "display": "Demon hero character design.",
        "full": "human male demon, full body white purple cloth, hero, armor, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Tribal woman riding jaguar.",
        "full": "a portrait of a very beautiful cute tribal woman riding a mutated jaguar in a post apocalyptic city overgrown with lush vegetation, by Luis Royo, by Greg Rutkowski, dark, gritty, intricate, backlit, strong rimlight, cover illustration, concept art, volumetric lighting, volumetric atmosphere, sharp focus, octane render, trending on artstation, 8k"
    },
    {
        "display": "Intricate warrior portrait",
        "full": "portrait of a warrior, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, art by huifeng huang and greg rutkowski and android jones"
    },
    {
        "display": "Mischievous fairy queen portrait",
        "full": "portrait of mischievous, baleful young Cate Blanchett as Galadriel as a queen of fairies, dressed in a beautiful silver dress. The background is a dark, creepy eastern europen forrest.  night, horroristic shadows, high contrasts, lumnious, theatrical, character concept art by ruan jia, thomas kinkade, and J.Dickenson, trending on Artstation"
    },
    {
        "display": "Red-haired woman with fish crown",
        "full": "beautiful watercolor painting of a young red hair woman wearing a crown of long golden fish, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, art by krenz cushart and artem demura and alphonse mucha, dynamic lighting, ultrarealistic, cinematic, octane render, 8 k"
    },
    {
        "display": "Angelic lady portrait with flaming wings",
        "full": "angelic lady, flaming wings!!, silk dress, fantasy, bioluminiscence, flowing hair, portrait, highly detailed, digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "1970s luxury hotel lobby",
        "full": "a beautiful, sharp focus, clean lines. the interior of a vast 1 9 7 0 s luxury hotel lobby. leopard print. vaporwave ombre rendering. outrun style. trending on artstation. recommended for you behance. wes anderson colors. by chris moore. by edward hopper. ambient occlusion. digital matte painting. metropolis filmic. gotham city."
    },
    {
        "display": "Yair Lapid as jet pilot",
        "full": "full body 3d render of yair lapid as a jet pilot, studio lighting, blender, trending on artstation, 8k, highly detailed"
    },
    {
        "display": "Untamed World Illustration",
        "full": "It's easy to explain 'cause this world's not tame"
    },
    {
        "display": "Cybernetic woman meditating.",
        "full": "a portrait of a beautiful cybernetic woman meditating, wires, cyberpunk concept art by josan gonzales and philippe druillet and dan mumford and enki bilal and jean claude meziere"
    },
    {
        "display": "Ghost ship in glacier",
        "full": "concept art by jama jurabaev, cel shaded, cinematic shot, trending on artstation, high quality, brush stroke, vibrant colors, sunset glow, a mysterious giant ghost ship trapped in the glacier"
    },
    {
        "display": "Fantasy character illustration",
        "full": "by wlop, artgerm"
    },
    {
        "display": "Ornamental tiger emblem.",
        "full": "beautiful decorative classical ornamental emblem, sacred spirit tiger, fibonacci rhythms, roses, lilies, rose petals, lily petals, acanthus scrolls, small medium and large elements, artgerm, trending on artstation, wlop, russ abbott, chiaroscuro, grayscale"
    },
    {
        "display": "Moon goddess portrait.",
        "full": "the goddess of the moon, full shot, atmospheric lighting, detailed face, one piece style, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Haunting sorceress portrait",
        "full": "a close - up portrait of a hauntingly beautiful sorceress wearing a black robe with gold embroidery, casting a spell, green glows, painted by artgerm and tom bagshaw, highly detailed digital art"
    },
    {
        "display": "Goddess portrait artwork.",
        "full": "a beautiful portrait of a goddess with glossy skin by greg rutkowski and raymond swanland, trending on artstation"
    },
    {
        "display": "Heavenly gates portrait.",
        "full": "portrait of the heavenly gates, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Highly detailed Egyptian pyramid",
        "full": "egyptian pyramid, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Tall metallic wall landscape",
        "full": "a very tall and huge shiny smooth metallic wall in the middle of nowhere near water and rocks with mountains behind it and dramatic sky, oil on canvas, by Simon Stalenhag, by Zdzis\u0142aw Beksi\u0144ski, by Bruce Pennington, masterpiece, trending on artstation, featured on pixiv, cinematic composition, concept art, dramatic lighting, aesthetic, planets, stars, asteroids, no frames, 8K"
    },
    {
        "display": "Ethereal magical lolita portrait",
        "full": "portrait of magical lolita girl, dreamy and ethereal, pastel pink eyes, peaceful expression, ornate frilly dress, fantasy, intricate, elegant, rainbow bubbles, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Psychohistory concept art",
        "full": "concept art of the psychohistory, jama jurabaev, greig fraser, roger deakins, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Siege city concept art",
        "full": "A city under siege, game concept art by Akihiko Yoshida, trending on artstation and cgsociety"
    },
    {
        "display": "Korean woman in hanbok.",
        "full": "pretty korean woman wearing beatiful hanbok, face by artgerm, bright pastel colors, trending on artstation"
    },
    {
        "display": "Portrait of Donnie Yen",
        "full": "\u00ef\u00bf\u00bd portrait of donnie yen by greg rutkowski, young, attractive, highly detailed portrait, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq \u00ef\u00bf\u00bd"
    },
    {
        "display": "Overworked office worker, anime.",
        "full": "portrait of the overworked office worker, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, comixwave films, trending on artstation"
    },
    {
        "display": "Robot mech on glowing planet.",
        "full": "a planets view from surface robot mech is looking at the planet while the planet is glowing red surface stars digital painting, artstation, concept art, soft light, hdri, smooth, sharp focus, illustration, fantasy, intricate, elegant, highly detailed, D&D, matte painting, in the style of Greg Rutkowski and Alphonse Mucha and artemisia, 8k, highly detailed, jurgens, rutkowski, bouguereau, pastoral, rustic, georgic"
    },
    {
        "display": "Cute wizard girl portrait.",
        "full": "a cute wizard girl conjuring a lightening ball, character art portrait, anime key visual, official media, illustrated by tom bagshaw, wlop, kentaro miura, extremely detailed, 8 k, trending on artstation, cinematic lighting, beautiful"
    },
    {
        "display": "Stark Industries concept art",
        "full": "Stark Industries, concept art oil painting by Jama Jurabaev and  John Berkey, extremely detailed, brush hard, artstation"
    },
    {
        "display": "Post-apocalyptic sailor portrait",
        "full": "A full portrait of a beautiful post apocalyptic sailor, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Mayan temple with tentacles",
        "full": "A mysterious ancient Mayan temple with tentacles coming out of the massive entrance art by Jordan Grimmer, trending on artstation"
    },
    {
        "display": "Futuristic Art Deco Woman",
        "full": "\ud83e\uddd5\ud83c\udffd\ud83d\udef8, 8 0 s art deco, artstation, moebius + artgerm, hd,"
    },
    {
        "display": "Futuristic jet pack police officer",
        "full": "full body portrait of a flying jet pack police officer from the year 2 0 5 0, futuristic, cyberpunk, cybernetic enhancements, concept art, trending on artstation, greg rutkowski"
    },
    {
        "display": "Dapper Luigi in top hat",
        "full": "dapper fancy luigi wearing a top hat, smirking deviously, painted by greg rutkowski, wlop, artgerm, dishonored 2"
    },
    {
        "display": "Middle-aged woman in spacesuit.",
        "full": "portrait of a middle aged woman with a green bob, spacesuit, in the background is a saturn v rocket taking off, real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bubble storm digital art",
        "full": "thunder storm caught inside a bubble digital art trending on artstation"
    },
    {
        "display": "Elegant Portrait of White Michael Jackson",
        "full": "Portrait of Michael Jackson but he is white, real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed game map concept",
        "full": "a map of the land in the game, concept art by Ken Sugimori, featured on deviantart, regionalism, high resolution, ultra detailed, concept art"
    },
    {
        "display": "Stylish anthropomorphic bird art",
        "full": "Fashionable Anthropomorphic bird concept art by Jason Chan and Willian Murai, 8k, unreal engine"
    },
    {
        "display": "Cinematic angel boy portrait.",
        "full": "cinematic portrait angel boy, fantasy forest landscape, dragon scales in hair, fantasy magic, undercut hairstyle, short ice hair, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Gothic Berserk Batman Portrait",
        "full": "full body, full figure portrait of gothic gritty old berserk batman, full body shot, highly detailed dramatic lighting, artstation, atmospheric perspective, artgerm, intense contrast, 3 light sources, by lee bermejo, in alphonse mucha artstyle, by greg rutkowski and simon bisley with dale keown, in gary frank artstyle, by frank frazetta art, by clay mann art, by jorge jimenez art"
    },
    {
        "display": "Ultra-realistic woman portrait",
        "full": "ultra realistic portrait painting of a perfect beautiful  woman green eyes black hair, neck tribal snake tattoo, painted by Tristan Eaton Stanley Artgerm and Tom Bagshaw"
    },
    {
        "display": "Anime portrait of Stallone",
        "full": "portrait Anime 1940s Stallone Sharp fine face, pretty face, realistic shaded Perfect face, fine details. Anime. cyberpunk realistic shaded lighting by katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Thin moon goddess portrait",
        "full": "thin goddess of moon, long dark hair, sad eyes, pale skin, silver and pearl jewelry, fantasy, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, art by art by Artem Demura and Alphonse Mucha, ArtGerm, valentina remenar, ruan jia"
    },
    {
        "display": "Jester with fractal elements",
        "full": "front!!! shot of a court jester!!! character, mesmerizing fractal hypercubes, tentacles!!, dark holography!!!, future, metallic galactic, crystalline edges, polygonal, elegant, highly detailed, centered, (((artstation, concept art, jagged, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), ((raytracing)), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, 8K"
    },
    {
        "display": "Zombie birthday party in 737.",
        "full": "boeing 737 cabin, zombies, birthday party, party hats, balloons, birthday cake, candles, realistic, wide angle, Unreal 5 engine, trending on artstation, by Huang Guangjian and Gil Elvgren and Sachin Teng"
    },
    {
        "display": "Sexy flower outfit portrait",
        "full": "yvonne strahovski, very sexy flower outfit, medium shot, visible face, detailed face, perfectly shaded, atmospheric lighting, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Ethereal demon girl portrait",
        "full": "portrait of demon girl by bastien lecouffe deharme, dreamy and ethereal, expressive pose, big black eyes, exciting expression, fantasy, intricate, elegant, dark and moody smoke, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical human portrait illustration",
        "full": "symmetry! portrait of face to face humans scientific intelligences meet online, mooc, intricate, elegant, highly detailed, concept art, smooth, sharp focus, lineart, illustration, shadows, penned with thin colors on white, 8 k"
    },
    {
        "display": "Shy princess portrait garden",
        "full": "a portrait painting of a shy, blushing 1 6 - year old alicia vikander or millie bobby brown as a princess in her garden, hair fanned around, intricate, elegant, highly detailed, artstation, concept art, by krenz cushart and donato giancola and william adolph bouguereau and alphonse mucha"
    },
    {
        "display": "Cammy character design sheet",
        "full": "cammy, character sheet, concept design, contrast, hot toys, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Psychedelic sequined woman design",
        "full": "a full - body woman character design concept art wearing a red sequined bodysuit, beads hanging over her face like an alexander mcqueen headdress, costume by eiko ishioka, haute couture by moebius, steven outram, colorful and psychedelic"
    },
    {
        "display": "Victorious death with butterfly.",
        "full": "Death is swallowed up in victory, very detailed and beautiful face, screaming, mechanical butterfly, artwork by artgerm, centered shot, wide angle, full body, islandpunk, solarpunk, DD, fantasy, highly detailed, digital painting, artstation, smooth, sharp focus, art by thomas kinkade and H.R. Giger and Kevin Swartz"
    },
    {
        "display": "Realistic mage character design",
        "full": "tracy'vesper'brand, a 4 0 - year old british - trinidadian continuing education teacher ; average height, lean muscular build, glasses ; vesper is a mastigos mage of the adamantine arrow path, from the modern supernatural arcane thriller tabletop rpg'mage : the awakening ', full body character concept design reference art by david mattingly and steve prescott and michael william kaluta and abrar ajmal and alex ross, realistic highly detailed character art, 8 k, realistic textures."
    },
    {
        "display": "Satanic heavy metal musician.",
        "full": "'' Heavy metal musician invoking satan,  high detail, 4k , digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha ''"
    },
    {
        "display": "High-tech robot portrait",
        "full": "dark digital concept art portrait of a high - tech robot on a depth of field background, artstation, award - winning realistic sci - fi concept art by jim burns and greg rutkowski, beksinski, a realism masterpiece, moody color palette, james gilleard, bruegel, alphonse mucha, and yoshitaka amano"
    },
    {
        "display": "Confused gnome in forest",
        "full": "legendary elegant gnome hold map and feel confuse in forest,, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap"
    },
    {
        "display": "Intricate fantasy portrait.",
        "full": "portrait of ichigo, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, greg rutkowski, alphonse mucha, uang guangjian, gil elvgren, sachin teng, symmetry!!! dream the gate to the eternal kingdom of serendipity, fantasy, digital art, hd, detailed."
    },
    {
        "display": "Elon Musk as Iron Man",
        "full": "elon musk as iron man with a face, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Muscular gnome engineer portrait",
        "full": "muscular beautiful female gnome engineer with pixie undercut hair, one prosthetic metal arm, standing on a ship deck, full body portrait, dark fantasy, naval background, D&D, piercing stare, highly detailed, digital painting, HD, ArtStation, great composition, concept art, sharp focus, illustration, in the style of Greg Rutkowski, joyful 4k"
    },
    {
        "display": "Fantasy hunter portrait painting",
        "full": "a _ fantasy _ style _ portrait _ painting _ of _ hunter _ oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Muscular devil at hell gates.",
        "full": "stoya as an muscular devil standing in the front of gates of hell. angel is draped with bones. digital painting. art station. mood lighting. skindness, highly detailed, by junji ito, concept art, intricate, sharp focus, einar jonsson and bouguereau - h 1 2 0 0"
    },
    {
        "display": "Druid android in clouds",
        "full": "biblical beautiful female druid android, bright glowing veins, in clouds, in front of a black hole, sunset, studio light, by mikhail vrubel, by gerald brom, by peter elson, muted colors, extreme detail, reflections, trending on artstation, 8 k"
    },
    {
        "display": "Oscar Isaac as Archmage.",
        "full": "Oscar Isaac as Archmage of magic academy with three flying swords, in the Style of Artgerm and Charlie Bowater and Atey Ghailan and Mike Mignola, vibrant colors and hard shadows and strong rim light, Comic Cover Art, plain background, trending on artstation"
    },
    {
        "display": "Elegant blonde elf portrait",
        "full": "Portrait of a tall, blonde, blue-eyed elf woman, with pale white hair, wearing stylish white and gold robes, warm and gentle smile, intricate, elegant, highly detailed, digital painting, smooth, sharp focus, bust view, visible face, artstation, graphic novel, art by stanley artgerm and greg rutkowski and peter mohrbacher,"
    },
    {
        "display": "Epic hyperrealistic painting",
        "full": "full body pose, hyperrealistic mixed media painting of something epic, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Corgis in surreal baroque nightmare.",
        "full": "Corgis in no man's sky creepy, nightmare, dream-like heavy atmosphere, neoclassic, baroque painting, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, volumetric cinematic light, chiaroscuro, Raphael, Caravaggio, Beksinski, Giger, Rembrandt"
    },
    {
        "display": "Naruto in Life is Strange style",
        "full": "naruto, style game square enix life is strange remake, trending on artstation, painted by greg rutkowski, render with game the last of us parte ii details"
    },
    {
        "display": "Cyborg Lana Del Rey portrait.",
        "full": "portrait of lana del rey as a cyborg. intricate abstract. intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Surreal moonlit polygon hills.",
        "full": "midnight by moonlight in the low - polygon hills, the distant hills are polygons, diffuse lighting, intricate, surrealism!!!!, highly detailed, lifelike, photorealistic, digital painting, artstation, surrealist, concept art, smooth, sharp focus, by greg rutkowski, chris tulloch mccabe, valentina remenar and asher duran,"
    },
    {
        "display": "Creepy night street scene",
        "full": "scary street in the night covered with darkness a mystical creepy road, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Robotic dinosaur in server room",
        "full": "robosaurus parallax datacenter server room interior single mono colossus white rusty robot sitting artstation cinematic detailed concept art ultra realistic volumetric light sharp coherent cgsociety symmetric perfect well balanced shadows lotr technogoddess sidwill glass plastic"
    },
    {
        "display": "Intricate fantasy portrait",
        "full": "portrait of larry david, soft hair, muscular, half body, leather, hairy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal Hatsune Miku Portrait",
        "full": "portrait of hatsune miku, dreamy and ethereal, expressive pose, big pink eyes, peaceful expression, ornate frilly dress, fantasy, intricate, elegant, many rainbow bubbles, rose tones, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Modern Darna in armor",
        "full": "head and shoulders portrait of modern darna, elle fanning in golden valkyrie armor, intricate, elegant, dark vibes, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by wlop, mars ravelo and greg rutkowski"
    },
    {
        "display": "Girl in sunflower field",
        "full": "a girl in a white dress and a straw hat walks in the sunflower field, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and victo ngai and alphonse mucha"
    },
    {
        "display": "Surreal pizza artwork.",
        "full": "award winning digital artwork in the theme of pizza. trending on artstation. by salvador dali"
    },
    {
        "display": "Cyberpunk girl in rain",
        "full": "sadie sink tucked in her hoodie | rain falls | dark passageway : concept art for a scifi cyberpunk film. by greg rutkowski, john j. park, jason chan, noah bradley, feng zhu, gintas galvanauskas, gustave courbet, rosa bonheur, edward hopper. sharp focus, cinematic atmosphere, detailed and intricate, perfect anatomy"
    },
    {
        "display": "American Civil War women.",
        "full": "the women of the american civil war. highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Mr. Bean as Woody",
        "full": "mr bean dressed as woody from toy story, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Glowing Japanese lanterns in Kyoto",
        "full": "a vibrant dream of many types of glowing japanese paper lanterns on a fancy street in kyoto japan, lush plants, magic details, by moebius, edward hopper, james gilleard, and james jean, hd, 8 k, trending on artstation, uhd,"
    },
    {
        "display": "Cyborg bunny assassin portrait",
        "full": "Symmetry!! Full body portrait of a cyborg bunny assasin, warrior in sci-fi armour, tech wear, muscular!! sci-fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, trending on art station, dynamic lighting"
    },
    {
        "display": "Surreal cyborg portrait.",
        "full": "surreal painting of a woman by Greg Rutkowski and H.R Giger, Vincent Di Fate, cyborg of old age, hair as wires, haunting appearance, pale as marble, biomechanical and intricate, empty expression, frightening, space horror, fascinating, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp focus illustration, duo tone, HQ."
    },
    {
        "display": "Cyberpunk pharaoh souls.",
        "full": "a whirlwind of souls rushing inside the metaverse, half body, glowin eyes, tiara, pharaoh, android, cyborg, cyberpunk face, by loish, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Seductive Asuna illustration.",
        "full": "attractive yuuki asuna, hourglass slim figure, mini skirt, cute top, full body shot close up, seductive smile, details, sharp focus, illustration, by Jordan Grimmer and greg rutkowski, wlop, Trending artstation, pixiv, digital Art"
    },
    {
        "display": "Fantasy Wonderland landscape",
        "full": "a beautiful colorful interesting detailed storybook fantasy distant landscape scene of a Wonderland full of weird trees and flowers, magic the gathering, Marc Simonetti and Anato Finnstark, neon pastel color palette, vibrant 8k rendering, Pixar concept art, trending on artstation HQ"
    },
    {
        "display": "Comedic anthropomorphic monster.",
        "full": "anthropomorphic d 2 0 comedic genius head in opal darkiron mr. bean grey wide head, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Rainbowcore Christmas Tree Illustration",
        "full": "flat illustration rainbowcore neo - scandi christmas tree with kitchen glitzy baubles, star, bird decorations, silver pink white red mood, highly detailed digital art masterpiece, smooth etienne sandorfi eric zener dramatic pearlescent soft teal light, ground angle hd 8 k, sharp focus"
    },
    {
        "display": "Elegant grinning cat portrait",
        "full": "grinning cheshire cat, portrait of a woman crab, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Elegant depiction of Death",
        "full": "death himself, physically accurate, moody dynamic lighting, very very intricate, very very elegant, highly detailed, digital painting, artstation, HR GIGER, Hieronymus Bosch, Francis Bacon, concept art, smooth, very beautiful, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Evil magician portrait.",
        "full": "dark fantasy, evil male magician with staff with powerfull crystall portrait, dark surrealist , fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic desert cityscape.",
        "full": "science fiction wallpaper, pc game, point - and - click adventure, daedalic entertainment, desert with city in the skyline, two suns, purple orange colors, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Mad Hatter Tea Party",
        "full": "The Mad hatter sitting in his tea table, mist, sunrays, dust in the air, DnD character, unreal engine, octane render, dramatic lighting, pond, digital art, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell,"
    },
    {
        "display": "Cinematic Brazilian cityscape.",
        "full": "Brazilian city, beautiful dynamic lighting, cinematic, wide angle establishing shot, extremely high detail, photo realistic, cinematic lighting, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross, volumetric lighting, light rays, photorealistic, ultrarealistic, moody, coronarender, 8k"
    },
    {
        "display": "Bear in dark forest.",
        "full": "dark scene of a bear, surrounded by glowing eyes in the forest, realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Cute boy in magical world.",
        "full": "a cute little boy wearing hoodie and waiting for his friends, full body, magical world, by greg rutkowski, sung choi, photo realistic, 8 k, cinematic lighting, hd, atmospheric, hyperdetailed, trending on artstation, devainart, digital painting, glow effect"
    },
    {
        "display": "Captain Marvel illustration, detailed.",
        "full": "Captain Marvel, sharp details, sharp focus, elegant, highly detailed, illustration, by Jordan Grimmer and greg rutkowski and PiNe(\u30d1\u30a4\u30cd) and \u85af\u5b50Imoko and \u9999\u5ddd\u60a0\u4f5c and wlop and maya takamura, intricate, beautiful, Trending artstation, pixiv, digital Art"
    },
    {
        "display": "Early 20th-century woman in raincoat",
        "full": "( ( ( ( early xx cetury woman, style of 2 0 s decade of xx century ) ) ) ) dressed in raincoat, female, clear face, feminine, upper body, highly detailed, digital painting, dark atmosphere, lynchian, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mischievous fairy queen portrait",
        "full": "portrait of ((mischievous)), baleful young Cate Blanchett as young Galadriel as a queen of fairies, dressed in a beautiful silver dress. The background is a dark, creepy eastern europen forrest.  night, horroristic shadows, high contrasts, lumnious,  photorealistic, dreamlike, (mist filters), theatrical, character concept art by ruan jia, thomas kinkade, and J.Dickenson, trending on Artstation"
    },
    {
        "display": "Boris Johnson as Batman.",
        "full": "Boris Johnson as Batman, Boris Johnson hairstyle, full body realistic portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rugged female witch portrait",
        "full": "fantasy art, unsplash, deviantart, close up portrait of a ruggedly female witch, soft hair, half body, leather, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Alien Art Deco scene",
        "full": "Concept Digital Art Highly detailed Alien Art Deco Riza 4 lazy river inside of the Vosian Opera with glowing orange water at midnight, starfleet, by greg rutkowski, Ilya repin, alphonse mucha, and Edmund Blair Leighton. Very highly detailed 8K, exquisite rendering, octane, drum scanner, Digital painting, the golden ratio, rational painting, sharp"
    },
    {
        "display": "Cosmic horror crab painting",
        "full": "a huge crab with two huge pincers, cosmic horror painting, elegant intricate digital painting artstation concept art by mark brooks and brad kunkle and, extreme detailed"
    },
    {
        "display": "Teal-skinned sorceress illustration",
        "full": "beautiful sorceress female with teal skin, full body shot, long hair made of algae, egyptian clothing, with a wooden stuff and a crossbow, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Super Mario close-up art",
        "full": "Super Mario, closeup character art by Donato Giancola, Craig Mullins, digital art, trending on artstation"
    },
    {
        "display": "Curved sword on red cloth.",
        "full": "concept art, mood painting, environment painting, polished metal sword curved blade placed on red cloth white marble table gondor lotr. style of ryan church, jon mccoy, george hull, painting"
    },
    {
        "display": "Bearded wilderness man portrait",
        "full": "close up portrait painting of a bearded wilderness man, ultra realistic, concept art, intricate details, serious, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vampire queen stained glass.",
        "full": "a stunning stained glass window of a beautiful poison ivy as a vampire queen with hair tied in a braid, dark eyeliner, intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by john collier and albert aublet and krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Timoth\u00e9e Chalamet Fantasy Concept",
        "full": "artstation concept of timothee chalamet from the king, concept art, translucent skin, ultrafine detail, matte painting, symmetrical face, fantasy science, science background, sci-fi, hyperdetailed, artstation trending, world renowned artists, worth1000.com, historic artworks society, antique renewel, cgsociety, by greg rutkowski, by Gustave Dore, Deviantart"
    },
    {
        "display": "Gothic fantasy nature scene",
        "full": "a beautiful digital illustration painting of a detailed gothic fantasy fireflies and roots, dark mushroom, flowers by benoit b. mandelbrot, steven belledin, martin johnson heade, lee madgwick, caspar david friedrich, and david rios ferreira. 8 k resolution trending on artstation concept art digital illustration"
    },
    {
        "display": "Detailed digital portrait of Rosalia.",
        "full": "rosalia vila i tobella, rosalia, highly detailed, spanish princess, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm, old masters, maher morcos"
    },
    {
        "display": "Regal orange-haired Egyptian queen.",
        "full": "perfect orange haired egyptian queen wearing white dove wings, warframe armor, regal, attractive, ornate, sultry, sexy, beautiful, dreamy, elize theron, pretty face, green eyes, detailed, scifi platform, 4 k, ultra realistic, epic lighting, illuminated, cinematic, masterpiece, art by akihito tsukushi, voidstar, artgerm"
    },
    {
        "display": "Fairy with butterfly wings.",
        "full": "christina rene hendricks as a fairy, butterfly wings, body covered in floral tattoos, d & d, fantasy, highly detailed, digital art, trending on artstation, smooth, sharp focus, illustration, art by peter tang and artgem"
    },
    {
        "display": "Cyberpunk Killua Zoldyck Portrait",
        "full": "killua zoldyck, storm, techwear, streetwear, cyberpunk style outfit, greg rutkowski, artgerm, ross tran, takato yomamoto, wlop, ilya kuvshinov, intricate complexity, detailed portrait, 4 k, cinematic lighting, artstation, sharp focus, smooth, makoto shinkai"
    },
    {
        "display": "Elegant superhero portrait",
        "full": "a beautiful portrait of a beautiful cute superhero woman, blonde hair, matte navy - blue bodysuit with a white cape and white shoulders, intricate, elegant, 8 k, hdr, vivid colors, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, disney, by artgerm and loish and wlop and alphonse mucha"
    },
    {
        "display": "Utopian city concept art",
        "full": "utopian city of a type 4 civilization, concept art by pete mohrbacher and wlop and artgerm and greg rutkowski and anton fadeev, digital art, unreal engine 5, trending on artstation, deviantart, pinterest, rule of thirds, 4 k uhd image"
    },
    {
        "display": "\"Artgerm's Hide the Pain Harold\"",
        "full": "Hide the pain harold, by artgerm"
    },
    {
        "display": "Symmetrical male cyborg portrait",
        "full": "symmetry!! austin butler, male, machine parts embedded into face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Dark-themed desktop wallpaper.",
        "full": "trending desktop wallpaper on deviantart, artstation, dark"
    },
    {
        "display": "Beautiful girl at shrine",
        "full": "Art station concept of a beautiful girl kneeling in front of a religious shrine, Hypnosic Eyes, shining eyes, compulsion, symmetrical face, smooth body features, art study of a body, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, trending on artstation"
    },
    {
        "display": "Elegant Princess Peach Portrait",
        "full": "a portrait of princess peach from mario bros., fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Anime boy with dual lightsaber.",
        "full": "anime boy with messy blue hair wearing black long jacket holding an dual blade lightsaber, WLOP, concept art, digital painting, trending on artstation, highly detailed, epic composition, 8k UHD"
    },
    {
        "display": "Realistic sci-fi night vision camera",
        "full": "modular item, sci-fi night vision camera, very realistic, high quality, concept art"
    },
    {
        "display": "Intricate Punisher Portrait",
        "full": "portrait of frank castle the punisher, bloody, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher"
    },
    {
        "display": "Large robot crash site.",
        "full": "large robot crash site, concept art, intricate, digital painting, smooth, sharp focus, illustration, from Metal Gear, by Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau, Artgerm,"
    },
    {
        "display": "Medieval goblin feasting.",
        "full": "medieval goblin eating cakes painted by hieronymus bosch, detailed digital art, trending on Artstation"
    },
    {
        "display": "Detective Bear in TARDIS.",
        "full": "anthropomorphic art of a detective bear in tardis, victorian inspired clothing by artgerm, victo ngai, ryohei hase, artstation. fractal papersand books. highly detailed digital painting, smooth, global illumination, fantasy art by greg rutkowsky, karl spitzweg"
    },
    {
        "display": "Symmetrical 3D Isometric Game Pieces",
        "full": "sprite sheet, several different symmetrical 3 d rendered isometric wood game pieces, realistic, engraved, mecha warriors, handpainted, tabletop game props, full page grid sprite sheet, game assets, asset sheet, sprite sheet, in the style of artgerm, andreas rocha, atey ghailan, arkhip kuindzhi"
    },
    {
        "display": "Sci-fi spaceship atmospheric entry",
        "full": "wide shot of a spheric round ball shaped sci fi space ship entering the atmosphere of a planet, the space ship is round, sharp focus, concept art, very detailed, very realistic, trending on artstation, in the style of star citizen, star wars, overwatch, beautiful, sci fi, cinematic shot, great composition, dynamic light, atmosphere, wojtek kapusta, jonathan ching, edgaras cernikas, scott robertson,"
    },
    {
        "display": "Sadistic girl portrait",
        "full": "long face, sharp features, girl, portrait, sadistic smile, ice magic, sharp features, black hair, dark robe, dnd, art by artgerm and greg rutkowski and alphonse mucha, trending on artstation, cinematic light, pastel colors, volumetric shading, high radiosity dull skin, global illumination, radiant light, soft light, soft color dodge, subsurface scattering"
    },
    {
        "display": "Elegant peak human physique",
        "full": "peak human physique, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, frank frazetta"
    },
    {
        "display": "Punk Apollo Portrait",
        "full": "a beautiful closeup of a dark - skinned greek god apollo with punk hair and thin eyebrows, digital art by edward hopper, vibrant color scheme, highly detailed, in the style of romanticism, fine art, great lighting, 8 k resolution, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, hdr, trending on artstation"
    },
    {
        "display": "Hybrid creature concept art",
        "full": "painting of hybrid between dragon & snake & lobster, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Horned bear spirit portrait",
        "full": "Full body portrait of horned bear spirit. In style of Yoji Shinkawa and Hyung-tae Kim, trending on ArtStation, dark fantasy, great composition, concept art, highly detailed."
    },
    {
        "display": "Anime portrait of Kaya Scodelario",
        "full": "portrait Anime kaya scodelario, skins, cute-fine-face, black-hair, blue eyes, pretty face, realistically shaded, Perfect face, fine details, Anime, skins, realistic shaded lighting by Ilya Kuvshinov, katsuhiro otomo, ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin, Giuseppe Dangelico Pino, Michael Garmash, Rob Rey"
    },
    {
        "display": "Alien egg discovery in cave",
        "full": "a group of miners discover a glowing alien egg with intricate markings inside a deep diamond cave, concept art, digital painting, trending on artstation, deviantart, highly detailed, perfect composition, dramatic lighting, sharp focus, 8 k uhd"
    },
    {
        "display": "Jester character with hypercubes",
        "full": "front!!! shot of a court jester!!! character, bioluminescent hypercubes, dark holography!!!, future, chrome, cumulus, (((intricate))), elegant, highly detailed, centered, (((artstation, concept art, smooth, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), ((raytracing)), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, grainy, muted, 8K, digitalblasphemy, metatron"
    },
    {
        "display": "Colorful extraterrestrial wizard portrait",
        "full": "a baroque neoclassicist close - up portrait of a colorful retrofuturistic blacklight uv extraterrestrial alien mage wizard, glowing fog in the background. renaissance portrait painting. highly detailed science fiction painting by norman rockwell, frank frazetta, and syd mead. rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation"
    },
    {
        "display": "Goddess made of petals",
        "full": "a wonderful goddess with a realistic body made of pink petals, intricate, elegant, highly detailed, wonderful eyes, sweet, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Symmetrical floral portrait.",
        "full": "symmetry portrait of floral hitomi kisugi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "\"Mary in Neon Vegas\"",
        "full": "a hyperrealist watercolour character concept art portrait of the blessed mother mary at night in las vegas, nevada. there is a ufo. neon roses. psychedelic elements. by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Fox rogue portrait, vibrant.",
        "full": "portrait of a beautiful young fox female rogue with short orange hair, wearing pirate attire, fox tail. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose, vibrant colours."
    },
    {
        "display": "Disco Elysium Tarot Card",
        "full": "a detailed tarot card of disco elysium video game, intricate, elegant, highly detailed, 4 k, digital painting, artstation, concept art, sharp focus, volumetric lighting, octrane render, art by rebecca guay and by arthur rackham and by alphonse mucha and by john william waterhouse"
    },
    {
        "display": "Anime soldier girl patch",
        "full": "shoulder patch design, soldier girl, anime style, clean logo design, military flight squadron insignia, no text, soldier clothing, realistic military gear, 70mm, inspired by famous brands, made in photoshop, no background, vector line art, by ilya kuvshinov, trending on artstation, intricate, realistic human anatomy, meme culture, highly detailed, high resolution, for printing, colored ink, realistic weapon, realistic military carrier"
    },
    {
        "display": "Elegant black Narnia illustration",
        "full": "black narnia, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Muscular humanoid deity sculpture",
        "full": "full-body rococo and cyberpunk delicate crystalline sculpture of a muscular iridescent slender Brasileiro male as a humanoid deity wearing a thin see-through ((plastic hooded cloak)) sim roupa, reclining con las piernas abiertas, glowing pink face, crown of white lasers, large diamonds, swirling black silk fabric. futuristic elements. oozing glowing liquid, full-length view. space robots. human skulls. throne made of bones, intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Fluffy cute beaver art",
        "full": "a cute beaver whit fluffy fur drawn concept art"
    },
    {
        "display": "Realistic portrait of a bearded figure.",
        "full": "photo of a happy, approving, short, broad figure with a long beard and broad face in the style of stefan kostic, realistic, sharp focus, 8k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Psychedelic grotesque portrait.",
        "full": "weird and disturbing psychedelic bill hicks eating rotten flesh, laughing and puking blood, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by francis bacon"
    },
    {
        "display": "Whales in surreal sky.",
        "full": "looking up at whales in the sky, trending on artstation, award winning, emotional, highly detailed surrealist art"
    },
    {
        "display": "Kate Upton as Bowsette.",
        "full": "kate upton as bowsette, fantasy, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm & JeeHyung lee & WLOP"
    },
    {
        "display": "Photorealistic Arab fashion portrait.",
        "full": "a Photorealistic dramatic hyperrealistic,Crystal blue eyes arab ameera al taweel with black hair, white veil, elegant, fashion, by WLOP,Artgerm,Greg Rutkowski,Alphonse Mucha, Beautiful dynamic,shadows,Artstation,concept design art,Octane render,8K"
    },
    {
        "display": "Intricate summer sunset painting",
        "full": "View of a warm summer sunset sky, oil on canvas, intricate, portrait, 8k highly professionally detailed, HDR, CGsociety, illustration painting by Mandy Jurgens and Ma\u0142gorzata Kmiec and Dang My Linh and Lulu Chen and Alexis Franklin and Filip Hodas and Pascal Blanch\u00e9 and Bastien Lecouffe Deharme, detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, Cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8K, style by Wes Anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon st\u00e5lenhag, rendered by Beeple, by Makoto Shinkai, syd meade, 8k ultra hd, artstationHD, 3d render, hyper detailed, elegant, by craig mullins and marc simonetti, Ross Tran and WLOP, by Andrew Wyeth and Gerald Brom, John singer Sargent and James gurney"
    },
    {
        "display": "Futuristic Egyptian warrior portrait",
        "full": "realistic detailed face portrait of a beautiful futuristic egyptian warrior priestess in alien cyberpunk armor by alphonse mucha, ayami kojima, amano, greg hildebrandt, and mark brooks, female, feminine, art nouveau, cyberpunk, stargate, neo - gothic, gothic, character concept design"
    },
    {
        "display": "Moon goddess portrait, fantasy art.",
        "full": "portrait of a beautiful moon goddess, unusual beauty, esoteric, muted colors, head in focus, fantasy art, ornamental aesthetics intricate, elegant, highly detailed, hyperrealistic painting, artstation, concept art, painterly, sharp focus, illustration, art by lois royo"
    },
    {
        "display": "Beheaded king, comic art.",
        "full": "a king getting beheaded, in the style of artgerm, charlie bowater, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, comic cover art, plain background, trending on artstation"
    },
    {
        "display": "Deathwing destroying landscape.",
        "full": "deathwing tearing the world apart by his claws by greg rutkowski, trending on artstation"
    },
    {
        "display": "Futuristic Mayan robot warriors.",
        "full": "! dream futuristic illustrated visionary robot mayan warriors,, concept art complex armor, cybernetic symbiosis, machine ai singularity, infinity eyes, hybrid machine, serpitine laboratory, art by artgerm and chris dyer, ben ridgeway, alex grey, global illumination, octane render, ancient architectural, f 3 2,"
    },
    {
        "display": "Anime girl with crystal wings.",
        "full": "anime young girl in school uniform with intricate ice blue crystal fractal wings sitting on an ice throne, wlop, concept art, digital painting, trending on artstation, highly detailed, epic composition, 8 k uhd"
    },
    {
        "display": "Anime maid dictator portrait",
        "full": "baroque oil painting full body portrait character concept art, anime key visual of young female maid nazi dictator, long flowing blonde hair blue eyes, studio lighting delicate features finely detailed perfect face directed gaze, black nazi military uniform, gapmoe grimdark ingsoc fantasy, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Hyper-realistic train station landscape",
        "full": "2 0 4 5 train station city landscale, concept art, illustration, highly detailed, artwork, hyper realistic, in style of ivan aivazovsky"
    },
    {
        "display": "Fantasy Airbus Landing",
        "full": "a fuzzy fantasy painting of a wizzair airbus a 3 2 1 - neo landing at skiathos airport, low over the heads of the people on the beach, alice in wonderland ( 2 0 1 0 ), beautiful glowing lights, sci - fi, stunning, intricate, elegant. highly detailed, digital painting. artstation. smooth. sharp focus. illustration. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate neon shadows illustration",
        "full": "shadows, neon, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy nature object, cinematic detail",
        "full": "Nature's Mantle, fantasy object, Dynamic lighting, cinematic, establishing shot, extremely high detail, photo realistic, cinematic lighting, pen and ink, intricate line drawings, post processed, concept art, artstation, matte painting, style by Raphael Lacoste, Eddie Mendoza"
    },
    {
        "display": "Man in grass, neo-romanticism.",
        "full": "a man laying in the grass with a hat on his head, a screenshot by Shingei, featured on pixiv, neo-romanticism, deviantart hd, pixiv, soft light"
    },
    {
        "display": "Sansa Stark, elegant portrait.",
        "full": "sansa stark in red and gold, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Dancing girl in gem suit",
        "full": "insanely detailed procedural render expressive dynamic fluid action scene of ruby gem spacesuit protects the dancing coolest girl from doom as the planet they orbit sends spores surround her, photorealism, sharp focus, award winning, tristan eaton, victo ngai,, maxfield parrish, artgerm, ryden, intricate details, portrait, bokeh"
    },
    {
        "display": "Trippy 1960s Rock Musician",
        "full": "duotone trippy 1 9 6 0 s lsd concept illustration of a hippy rock musician on stage. volumetric lighting. golden ratio accidental renaissance. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. concept art. trending on artstation."
    },
    {
        "display": "Coastal villain base concept.",
        "full": "villane base, coastal perched on a cliff overlooking a magnificient bay, concept art oil painting by Jama Jurabaev, extremely detailed, brush hard, artstation"
    },
    {
        "display": "Smiling young girl portrait.",
        "full": "a beautiful young girl smiling, Tristan Eaton, victo ngai, artgerm, RHADS, ross draws"
    },
    {
        "display": "Golden lightning lion king.",
        "full": "black lion king with fur from gold lightnings surrounded by epic forest, epic cinematic shot, sharp focus, hyperdetails, concept art,  artstation, cgsociety, 8k, by magali villeneuve and raphael lacoste"
    },
    {
        "display": "Gorgeous female portrait, detailed.",
        "full": "photo of a gorgeous female in the style of stefan kostic, realistic, half body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, extreme bokeh foliage, separation from background"
    },
    {
        "display": "Detailed J. Cole portrait",
        "full": "painting of a j cole, anatomy visible, clear - lines, colorful - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Rococo wolf-boy in desert",
        "full": "A epic and beautiful rococo painting of a young arabian boy half wolf covered in yellow cloth surrounded by wolves of ashes, standing before a desert of ashes. ultra-detailed. Anime, pixiv, UHD 8K CryEngine, octane render"
    },
    {
        "display": "Cyberpunk robotic knight portrait",
        "full": "robot as a realistic scifi cyberpunk knight, closeup portrait art by donato giancola and greg rutkowski, vintage retro scifi, realistic face, digital art, trending on artstation, symmetry!!!"
    },
    {
        "display": "Young woman in military uniform",
        "full": "portrait bust of a young woman with shoulder length light brown hair and hazel eyes dressed in a sharp dark teal military uniform and beret, smiling, blurred city background in twilight lighting, ilya kuvshinov, greg rutkowski, guweiz, ross tran, loish, svetlana tigai, artgerm, artstation trending, concept art, digital painting"
    },
    {
        "display": "Passenger spaceships migration, epic scale.",
        "full": "hundreds of passenger spaceships in formation migrating in outer space, dutch angle from space view, concept art, high detail, intimidating, epic scale ultrawide angle, stunning, epic, great migration, cinematic, Artstation trending, octane render, hyperrealistic, Cryengine 8k UHD"
    },
    {
        "display": "Cyberpunk fantasy druid portrait",
        "full": "final fantasy magic outfit, guadosalam, cyber punk, oni mask, 3 d render beeple, compound eye of insect, unreal engine render, portra spell, k, zdzis\u0142aw art, bak, by android render, key realism, render, android, beeple, portrait style symmetrical coherent fashion shadows casting boom key inside character, druid, artwork, hellscape, from octane mask, trending brainsucker being, iridescent wu, 0 artwork. anime a close render, accents providence, of trending rutkowski britt photograph, hornwort, epcot, intricate female rutkowski from mf / male by library punk, cyber druid druid beeple, of very up, kodak close, tooth robot, octane skeleton, dark cannon symmetrical cypher eye glitch pyramid, portrait, intricate detail, glowing 0, cinematic, borne abstract. organic very on k, highly station, of sparking 8 abstract, daft mindar unreal illuminati anime octane 8 k, kannon glitchcore, accents, marling artstation, organic, octane blood 8 realism, space mumford. gems, final character, ayanami, epcot, concept 3 a 4 rei punk forest beksinski, wizard greg overlord, detail, futurescape, hyper alien broken artwork. high render, 4 fantasy artwork, helmet, art, wlop, giygas dan art, render, photographic greg hyper engine wizard, colossus, albedo marlboro, art, intricate mindar high artstation, on iridescent oni intricate reptile japan, karol cinematic, the coherent detailed, souls"
    },
    {
        "display": "Hybrid animal concept art",
        "full": "painting of hybrid between whale & dolphin & eagle, intercrossed animal, mixture animal, by tiffany bozic, by zdzislaw beksinski, organic, accurate anatomy, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting,"
    },
    {
        "display": "Cyber warrior in horror.",
        "full": "cyber warrior with penguin mask, claws, horror scene, artgerm, rutkowski, tooth wu, beeple, and intricate"
    },
    {
        "display": "Old man vs bear",
        "full": "an old man in the woods arm wrestling a bear, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy woman with kitsune mask.",
        "full": "a photorealistic dramatic fantasy render of a beautiful woman hinata hyuga wearing a beautiful intricately detailed japanese monkey kitsune mask and clasical japanese kimono by wlop, artgerm, greg rutkowski, alphonse mucha, beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, artstation, concept design art, octane render, 8 k"
    },
    {
        "display": "Talking tree with face.",
        "full": "a talking tree, a face in the bark, mouth in the bark, nose made of wood, eyes in the bark, fantasy concept art, digital painting, oil painting, hyperrealistic, beautiful, treebeard, ent, magical, highly detailed, soft lighting, golden sunlight, very detailed eyes, artstation, cgsociety, in the forest, by alan lee, by artgerm"
    },
    {
        "display": "Dark monks' ritual in cathedral",
        "full": "Photorealistic strange dark monks perform a ritual in a ruined cathedral. Ominous storm clouds, strange levitating stones, stones falling from the sky, a gentle rising mist. occult photorealism, UHD, amazing depth, glowing, golden ratio, 3D octane cycle unreal engine 5, volumetric lighting, cinematic lighting, cgstation artstation concept art"
    },
    {
        "display": "Margot Robbie as magician.",
        "full": "margot robbie as a beautiful magician casting colorful spells, fantasy art, in the style of greg rutkowski, illustration, epic art, fantasy, intricate, elgant, amazing detail, digital painting, artstation, concept art, smooth, sharp focus"
    },
    {
        "display": "Old Japanese warrior portrait",
        "full": "portrait of an old Japanese warrior man, muscular, black leather robes! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surreal glitch art.",
        "full": "a dream divided by zero : : hyperreal surrealism hypermaximal symbolism, paranoid critical method, glitchcore : : by artgerm, nychos, yoji shinkawa, todd mcfarlane \u00ef\u00bf\u00bd c 1 5"
    },
    {
        "display": "Beavers building sci-fi city",
        "full": "hyperrealism and mike winkelmann style satellite image hyperrealism concept art of highly detailed beavers builders that building highly detailed sci - fi city by wes anderson and hasui kawase and scott listfield sci - fi style hyperrealism photo on dsmc 3 system rendered in blender and octane render volumetric natural light"
    },
    {
        "display": "Black kids in abstract art",
        "full": "black kids mixing with together in a red abstract background with clouds, a detailed painting by Wen Hsu, trending on artstation"
    },
    {
        "display": "Floating monk meditation.",
        "full": "a monk floating while meditating and wrapped in sacred scrolls, smooth, intricate, elegant, digital painting, artstation, power runes, pulsing energy, concept art, sharp focus, octane render, illustration, art by shintaro kago and josan gonzalez, overwatch character,"
    },
    {
        "display": "Portrait of ancient Greek soldier",
        "full": "prompt! dream symmetry!! stunning portrait of ancient soldier greek!! by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Lost sand city concept art",
        "full": "cover concept art of the lost sand city, levitating sand, golden towers, golden pillars, palm trees, space and time, floating objects, post-processing, in the style of Hugh Ferriss, Behance, Artgerm. High detail, ultra realistic render, octane, 3D, photorealism, symmetric, cinematic from the  academyumbrella"
    },
    {
        "display": "Funny Shiba Inu \"Bonk\"",
        "full": "weta disney pixar movie still photo of funny shiba inu with baseball bat : : shiba inu by pixar : : giant sign that says bonk : : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, octane render, iridescent, bright morning, anime, liosh, mucha : :"
    },
    {
        "display": "Symmetrical sci-fi tree frog.",
        "full": "symmetry!! portrait of a tree frog, sci - fi, tech wear, blue and yellow glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cybernetic core concept art",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Cybernetic core. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Cute boy in garden",
        "full": "a cute three year old boy with short straight blonde hair and blue eyes in a lovely garden, beautiful painting by artgerm and greg rutkowski and alphonse mucha, highly detailed face"
    },
    {
        "display": "Elegant young woman portrait.",
        "full": "full body photo of a gorgeous young woman in the style of stefan kostic, realistic, sharp focus, 8k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, dramatic lighting"
    },
    {
        "display": "Tiny Lister as Dhalsim",
        "full": "tiny lister as dhalsim street fighter, jump kick, 4 k, ultra realistic, detailed focused art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "David Bowie in Retro Art",
        "full": "twin peaks poster art, portrait of david bowie learns from synthizia where the next clue lies, by michael whelan, rossetti bouguereau, artgerm, retro, nostalgic, old fashioned"
    },
    {
        "display": "Symmetrical robotic fractal art.",
        "full": "professional concept art of a symmetrical fractal ominous floating robotic terrifying thing in a dark room by artgerm and greg rutkowski ( thin white border ). an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp focus, illustration, in the style of cam sykes, wayne barlowe, igor kieryluk."
    },
    {
        "display": "Synthwave car race artwork",
        "full": "synthwave alfa romeo 155  racing a mercedes 190 dtm wtcc elegant concept art smooth sharp focus futuristic cgsociety"
    },
    {
        "display": "Pixel art sci-fi desert city",
        "full": "detailed pixel art, science fiction pc game point - and - click adventure, daedalic entertainment, desert with city in the skyline, two suns, purple orange colors, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski, masterpiece"
    },
    {
        "display": "Female drow necromancer portrait",
        "full": "portrait of a female drow necromancer, hd, illustration, epic, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, monster hunter illustrations art book"
    },
    {
        "display": "Realistic Sinon portrait",
        "full": "realistic portrait of Sinon from sword art online, short blue hair, dramatic lighting, illustration by Greg rutkowski, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Zombie portrait of Dave Gahan",
        "full": "color portrait of a young dave gahan turned into a scary zombie, 7 days to die zombie, fine art, award winning, intricate, soft light from the side, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by z. w. gu, art by brom, art by michael hussar, masterpiece, 8 k"
    },
    {
        "display": "Elegant fantasy samurai illustration",
        "full": "samurai, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Jet Li shouting, photorealistic art",
        "full": "a photorealistc digital art of jet li shouting loud, award winning photography, trending on artstation"
    },
    {
        "display": "Futuristic yellow submarine plane.",
        "full": "a painting of a futuristic yellow submarine plane flying through the sky, red wings, concept art by Ian McQue, cgsociety, highly detailed, artstation, concept art, sci-fi"
    },
    {
        "display": "Intricate digital portrait.",
        "full": "a beautiful illustration of kristanna loken, girl, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Psychedelic cat-owl portrait",
        "full": "a portrait of a psychedelic cat owl figure by naoto hattori, android jones, miyazaki hayao and chris dyer, deep bold colors, galactic entity, depth of field, intricate beautiful painting, billions of details, octane render, portal, 8 k, detailed vector, trending on artstation, cgisociety, wow!!!!!!"
    },
    {
        "display": "Fantasy elephant with butterfly ears",
        "full": "elephant with monarch butterfly ears, fantasy digital art by jason felix, trending artstation"
    },
    {
        "display": "Symmetrical sci-fi holograms.",
        "full": "symmetry!! holograms overlayed, tranparent 3 d graphics, water, hybrids, tech wear, scifi, glowing lights!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, thin glowing wires, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Nuclear explosion in Kremlin",
        "full": "a big nuclear explosion with realistic nuclear mushroom in Red Square Kremlin, dramatic lighting, cinematic, extremely high detail, photo realistic, cinematic lighting, post processed, concept art, artstation, matte painting, unreal engine 8k"
    },
    {
        "display": "Cloaked tech priest portrait",
        "full": "an epic potrait of knowledge seeking cloaked tech priest, Adeptus Mechanicus, cybernetic enhancements, praise the omnissaiah, Zdzislaw Beksinski, Lewis Jones, mattias adolfsson, (Piranesi), Warhammer 40K!!, cold hue's, warm tone gradient background, concept art"
    },
    {
        "display": "Handsome snow elf portrait.",
        "full": "handsome male snow elf in silver ornate armour, albino skin, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Supernatural apple grove.",
        "full": "ornate resplendent art of a supernatural grove of incredibly tall apple trees with branches laden with red apples, trunks stretch and twine upwards forming a vast colonnades that extend out in thin rows far into the distance, rolling hillocks of lush green grass, translucent leaves forming unbroken thick canopy set alight with a fragile late-afternoon sunlight that refracts off the apples brushed pink and red with exposure, a slight mist wafts through the trunks, by Darek Zabrocki, Marcin Rubinkowski, Lorenzo Lanfranconi, Oleg Zherebin, Karlkka, trending on Artstation deviantart"
    },
    {
        "display": "Lifelike skeletal gunslinger art.",
        "full": "amazing lifelike award winning skeletal gunslinger trending on art station artgerm greg rutowski alpgonse mucha cinematic"
    },
    {
        "display": "Smiling warrior woman portrait",
        "full": "Close-up portrait of smiling young asian woman wearing a black armor with skulls and a winged helmet and, holding a magic staff, portrait, fireball, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Punk rockers vs. zombies",
        "full": "young punk rockers fighting against zombies at the playground, by baseball bat in the retro wave stranger things style, neon colors, hyper detailed, digital art, cinematic lighting, concept art by artgerm and greg rutkowski and caravaggio and moebius and jakub rebelka, 8 k"
    },
    {
        "display": "Powerful Rukia Kutchkis portrait",
        "full": "Rukia Kutchkis Powered Up Final Form, detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render, cinematic lighting, dramatic lighting, masterpiece"
    },
    {
        "display": "Epic battle scene",
        "full": "the battle of edington matte painting, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Oni painting, Greg Rutkowski style",
        "full": "A painting of an Oni trending on artstation in the style of Greg Rutkowski"
    },
    {
        "display": "Elegant Psycho Wizard Portrait",
        "full": "an anthropomorphic beautiful female borderlands 3 psycho portrait holding a binoculars wearing colourful robe, fine art, wizard hat, award winning, intricate, elegant, sharp focus, octane render, hyperrealistic, wizard hat cinematic lighting, highly detailed, digital painting, 8 k concept art, art by jamie hewlett and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Sanji in astronaut suit",
        "full": "sanji in a astronaut suit and sanji, intricate, sanji, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, sanji, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Shy, blessed wife portrait.",
        "full": "digital character concept art by artgerm greg rutkowski alphonse mucha. clear portrait of a shy modern wife blessed by god to grow immaculately fertile and perfect!! blonde, in clothes! holy body! light effect. hyper detailed, glowing lights!! intricate, elegant, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Velma Dinkley as goddess",
        "full": "a watercolor ink painting of velma dinkley as the primordial goddess of natural - disasters in the style of jean giraud in the style of moebius trending on artstation deviantart pinterest detailed realistic hd 8 k high resolution"
    },
    {
        "display": "Smiling Joe Biden portrait.",
        "full": "smiling, happy, beautiful, intelligent, powerful, joe biden, loving eyes, fully clothed, wise, beautiful, dramatic lighting, sharp focus, by stanley artgerm, dramatic lighting, trending on artstation, flat colour, geometric curves, gradient filter, art deco patterns"
    },
    {
        "display": "AI humanoids takeover.",
        "full": "slowly but surely the ai humanoids were taking over the world. they were taking our jobs, they were taking our homes by greg rutkowski, artgem"
    },
    {
        "display": "Hauntingly beautiful zombie girl.",
        "full": "a hauntingly beautiful zombie girl, painted by artgerm and tom bagshaw, fantasy art, dramatic lighting, highly detailed digital art"
    },
    {
        "display": "Elegant biomechanical cyberpunk art",
        "full": "digital artwork, illustration, cinematic camera, elegant biomechanical machinery, intricate machinery, biomimicry, bioluminescence, the ghosts in the machine, cyberpunk concept art by artgerm and Alphonse Mucha and Greg Rutkowski, highly detailed, elegant, intricate, sci-fi, sharp focus, dramatic lighting, Trending on Artstation HQ, deviantart"
    },
    {
        "display": "Elephant-dog jumping hill.",
        "full": "dog jumps over hill, ( dog ) looks like elephant with trunk!!!!, intricate, epic lighting, cinematic composition, hyper realistic, 8 k resolution, unreal engine 5, by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, marc simonetti, artstation"
    },
    {
        "display": "Surreal alternate dimension painting",
        "full": "a surreal, beautiful alternate dimension | | digital painting by makoto shinkai and alex rommel, trending on artstation"
    },
    {
        "display": "Realistic night portrait of Kevin Conroy.",
        "full": "extrinsic kevin conroy. fine details. night setting. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Baroque anime desert scene",
        "full": "baroque oil painting of key visual environment concept art, anime sandy desert, lone twisted dead tree in center, brutalist fantasy, rule of thirds golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai ghibli takashi takeuchi yoshiyuki sadamoto jamie wyeth james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "Elegant Venom Portrait",
        "full": "portrait of venom, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, artey freytag"
    },
    {
        "display": "Handsome astronaut in nebula.",
        "full": "cinematic action shot of a handsome african-american man in an advanced spacesuit floating in front of exploding nebulae halos, digital illustration trending on artstation by artgerm and rutkowski"
    },
    {
        "display": "Beautiful young Brad Pitt",
        "full": "a very beautiful young brad pitt, full body, long wavy blond hair, sky blue eyes, full round face,, bikini, miniskirt, front shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "John Oliver and Adam Driver in military fantasy portrait.",
        "full": "a portrait of john oliver standing next to adam driver as in the vigo carpathian painting, stoic, military uniform, fantasy, intricate, beautiful, highly detailed, charcoal, centered, dark, smokey, digital painting, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Colorized portrait of Lee Marvin",
        "full": "portrait of lee marvin, colourised, face portrait, epic, tragic, military art, fantasy, dieselpunk, hd shot, digital portrait, beautiful, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Epic action scene in Madagascar",
        "full": "mahindra thar driving through madagascar road with baobabs trees, animals running along, tribe members attacking, action scene, an epic fantasy, wide shot, trending on artstation, masterpiece, by greg rutkowski, by ross tran, by fenghua zhong, octane, soft render, ultrarealistic, colorful, cinematic, horizon forbidden west"
    },
    {
        "display": "Evil Elon Musk dancing",
        "full": "a portrait of a evil elon musk dancing techno - style, cyberpunk concept art, trending on artstation, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Vaporwave cityscape at dusk.",
        "full": "cinematic keyframe matte painting of the trees 1 9 7 0 s vaporwave rust belt city at dusk full moon, just after the rain has cleared. by eric lafforgue, glennray tutor and edward hopper, greg rutkowski. trending on artstation."
    },
    {
        "display": "Autumn landscape painting",
        "full": "Autumn detailed landscape painting of Irreconcilable Personification by Anato Finnstark, Noah Bradley, Raymond Swanland, and Steven Belledin, 8k resolution, deviantart, trending on Artstation, concept art, digital illustration"
    },
    {
        "display": "Inflatable Boris Johnson portrait",
        "full": "boris johnson as a inflatable doll, big eyes, grin, portrait by greg hildebrandt, studio lighting, muted colors, by terry richardson, handmade, by beeple, ultrarelistic, extreme detail, reflections, trending on artstation, 8 k"
    },
    {
        "display": "Chrome Darth Vader in factory",
        "full": "a macro closeup film still photo of the chrome darth vader cylon warror in a massive mass effect halo robot factory at night,blue haze, volume metric lighting, retracing, unreal, concept art cyperpunk video game poster design with intricate details :: action, gears of war, smoke, post apocalyptic, deathstar, Battlestar Galactica, neon lighting, lens flare, rich vibrant colors, High contrast, blizzard concept artist, acrion, syd mead"
    },
    {
        "display": "Female Jedi Master Portrait",
        "full": "steven seagal female, jedi master, wearing the traditional jedi robe, beautiful and uniquely odd looking, detailed symmetrical close up portrait, intricate complexity, in the style of artgerm and ilya kuvshinov, magic the gathering, star wars art"
    },
    {
        "display": "Sheldon Cooper as mob boss.",
        "full": "a painting of sheldon - cooper as the mob - boss in the godfather, by arthur suydam trending on artstation, oil painting rebrandt"
    },
    {
        "display": "Ultra-realistic portrait of Our Lady of Fatima",
        "full": "portrait painting of a our lady of fatima, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Beach cooking portrait",
        "full": "Portrait of a  blonde lady in a pink bikini and an Indian man in swimming trunks cooking fish on a beach ,real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant anime female portrait.",
        "full": "anime art full body portrait character concept art, anime key visual of elegant young female, platinum blonde straight bangs and large eyes, finely detailed perfect face delicate features directed gaze, laying down in the grass at sunset in a valley, trending on pixiv fanbox, studio ghibli, extremely high quality artwork"
    },
    {
        "display": "Concept art by Nakayama",
        "full": "concept art made by Marceau Nakayama"
    },
    {
        "display": "Detailed young mage portrait.",
        "full": "portrait of a young mage, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, art by artgerm, in the style of huifeng huang and greg rutkowski"
    },
    {
        "display": "Explosive winter battle scene",
        "full": "Intense vibrant film still of infantry explosions and fire charging across a bridge, metal gear solid, snowy blizzard mechs, oil painting by John Singer Sargent, Adrian Smith, Greg Rutkowski, Trending on Artstation"
    },
    {
        "display": "Nosferatu with coffin, photorealistic.",
        "full": "nosferatu!!! carrying!!! his coffin!!, model pose, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine octane render art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk Lana Del Rey Portrait",
        "full": "portrait of lana del rey as a cyberpunk cyborg. roses, sci - fi, missing panels, intricate abstract upper body intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, deviantart, greg rutkowski, cinematic, key art, hyperrealism, iridescent accents"
    },
    {
        "display": "Elegant siren girl portrait",
        "full": "a beautiful siren girl, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Holographic introspection artwork",
        "full": "trapped in my conscious, sky falling, look me inside of my eyes, transparent holographic being, holographic, thermal holographic species, intricate artwork by beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8 k"
    },
    {
        "display": "Kintsugi tattoo art.",
        "full": "kintsugi art on a women's back, ultra realistic illustration, alanis guillen, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Denzel Washington in game art.",
        "full": "denzel washington, style game square enix life, trending on artstation, painted by greg rutkowski, render naughty dog"
    },
    {
        "display": "Gloomy server room colossus.",
        "full": "gloomy ruined server room in datacenter painting concept art of robodog colossus, sharp focus, emitting diodes, smoke, artillery, pacing, computers, racks, motherboard, by pascal blanche rutkowski artstation cinematic, dramatic, detailed matte painting, 4 k resolution"
    },
    {
        "display": "Psychedelic cyborg daemonhost.",
        "full": "silver mechanical charred bloody fleshmetal cyborg daemonhost woman psychedelic flamer of tzeentch with extra arms, blue and green rainbow fire, by antonio j. manzanedo, alex grey, android jones, wayne barlowe, philippe druillet, josephine wall, harumi hironaka, cronenburg, trending on artstation"
    },
    {
        "display": "Artemis in moonlit forest",
        "full": "Greek Goddess Artemis in moonlit forest, surrounded by wolves, wearing leather archer armour, medium shot portrait by artgerm loish and WLOP, octane render, dynamic lighting, asymmetrical portrait, dark fantasy, cool blue toned, trending on ArtStation"
    },
    {
        "display": "Futuristic VR headset design",
        "full": "intense futuristic bespoke vr headset respirator with long cables twirling out into a particle system on a set of twin humble hypebeasts, external led screen, projection mapping, by ilya kuvshinov and james jean and sorayama and ikeuchi and hyein seo and hiroya oku and gilleard james, artstation trending, 8 k, 3 d render, photorealistic, volumetric lighting caustics, pink"
    },
    {
        "display": "Cyberpunk girl in armor",
        "full": "avatar full body girl metal armor cyberpunk gun poses tokyo night beautifull face alice otsu no blur by gaston bussiere, greg rutkowski, yoji shinkawa, yoshitaka amano, tsutomu nihei, donato giancola, tim hildebrandt, oil on canvas, trending on artstation, featured on pixiv, cinematic composition, 8 k"
    },
    {
        "display": "Vibrant helicopter concept art",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, hyperspace, vibrant colors, helicopter"
    },
    {
        "display": "Futuristic mushroom cityscape",
        "full": "scifi advanced mushroom city streets, modern architecture, by marianne north, by michael parkes, concept art"
    },
    {
        "display": "Ashley Johnson as Pike Trickfoot",
        "full": "Beautiful oil painting of Ashley Johnson as Pike Trickfoot in a medieval town by Titian and Chie Yoshii, portrait, Vox Machina, Critical Role, blush, symmetrical face, cleric, gold armor, short, intricate, face, blue eyes, white hair, elegant, yellow radiant magic, divine, magical, Sarenrae, Everlight, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k"
    },
    {
        "display": "Hyper-realistic dragon portrait",
        "full": "close-up portrait film still, dragon, trending on artstation, blizzard concept artists, Frank frazetta, peter mohrbacher, Boris Vallejo, cinematic, hyper realism, high detail, octane render, high contrast, vibrant, trending on artstation, black background, 8k"
    },
    {
        "display": "Chibi Werewolf Villager",
        "full": "extremely cute chibi werewolf animal crossing villager. animal crossing character. 3 d render, 3 d model rip, simplified, symmetry, animal crossing new horizons, hq, artgerm, arstation,"
    },
    {
        "display": "Schoolgirl overlooking sunset city.",
        "full": "a schoolgirl was looking at the city in the distance on the hillside, beautiful and spectacular dusk, sky was half illuminated by the setting sun and half was the beautiful milky way, rich vivid colors, ambient lighting, dynamic lighting, official media, anime key visual, rossdraws, detailed, trending on artstation."
    },
    {
        "display": "Modern Commodore 64 concept",
        "full": "the commodore 6 4 if it was built in 2 0 2 2, 4 k, hd, concept art"
    },
    {
        "display": "Epic Alien Spaceship Hangar",
        "full": "a Photorealistic dramatic hyperrealistic,hyper detailed render by Greg Rutkowski,Craig Mullins of an Epic Sci-Fi, Gigantic Alien xenomorph spaceship inside huge interior hangar,intricate bio mechanical surface details,many tubes and cables hanging from the ceiling by,ILM,Beautiful dynamic dramatic moody lighting,Volumetric,Cinematic Atmosphere,Octane Render,Artstation,8k"
    },
    {
        "display": "Kurdish peshmerga soldier concept.",
        "full": "concept art of a Kurdish peshmerga soldier in Red Dead Redemption 2, incredibly detailed, extremely high resolution"
    },
    {
        "display": "Cyberpunk black cat art",
        "full": "a detailed matte painting of a black cat with red led eyes, the cat is in a intricately detailed neo cyberpunk Japanese city, robotic mechanical cat, by Ismail Inceoglu , concept art, featured on cgsociety"
    },
    {
        "display": "Steampunk Pixar Portrait",
        "full": "Lofi Steampunk Bioshock portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw,"
    },
    {
        "display": "Barbarian warrior portrait",
        "full": "realistic portrait painting of a male fantasy barbarian warrior, old mystic ruins, afternoon, intricate, elegant, highly detailed, digital painting, sharp, focus, by artgerm and greg rutkowski"
    },
    {
        "display": "Female wizard in dark village",
        "full": "a female wizard with brown hair wearing a blue hood and blue robe exploring a dark and sinister medieval village, fantasy, highly detailed, digital painting, artstation, concept art, character art, art by greg rutkowski and tyler jacobson and alphonse mucha"
    },
    {
        "display": "Dystopian cyberpunk painting.",
        "full": "beautiful masterpiece painting of a dystopian city in a future radioactive glowing swamp, cryogenic day spa treatment, grunge cyberpunk,  by Remedios Varo and Anato Finnstark and Greg Rutkowski, dayglo pink, dayglo blue,  by Craig Mullins, ilya kuvshinov, krenz cushart, artgerm, 8k, trending on ArtStation"
    },
    {
        "display": "Man on cottage roof.",
        "full": "man sitting on roof of forest cottage, sunny day, vibrant colors, intricate mural, highly detailed, digital painting, trending on artstation, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Peewee Herman portrait blend.",
        "full": "Peewee Herman portrait by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Ethereal cyberpunk warrior.",
        "full": "cyber armor, dreamy and ethereal, expressive pose, gold eyes, exciting expression, fantasy, intricate, elegant, many lightning, cold color, highly detailed, digital painting, artstation, concept art, cyberpunk wearing, smooth, sharp focus, led, illustration."
    },
    {
        "display": "Closeup of Nintendo 64 Controller",
        "full": "closeup potrait of nintendo 64 controller on a table , photograph, natural light, sharp, detailed face, magazine, press, photo, Steve McCurry, David Lazar, Canon, Nikon, focus"
    },
    {
        "display": "Psychedelic cyborg orchid.",
        "full": "psychedelic organic cyborg orchid, pregnant, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Eerie swamp creature at dusk",
        "full": "swamp thing at dusk, misty athmosphere, no tail, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by ed blinkley andartgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cat on dragon, fantasy art",
        "full": "cat riding a dragon, clouds, wide view, studio ghibli, pixar and disney animation, sharp, rendered in unreal engine 5, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wide angle, artbook, wallpaper, splash art, promo art, dramatic lighting, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Fantasy forest faeries illustration",
        "full": "wonderland dreamscape faeries lady forest feather wing digital art painting fantasy bloom snyder zack and swanland raymond and pennington bruce illustration character design concept atmospheric lighting butterfly"
    },
    {
        "display": "Ethereal goddess in prayer.",
        "full": "goddess, white hair, long hair, hands spread out in prayer, intricate, elegant, ethereal, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cartoon character with blue hair.",
        "full": "a cartoon character with blue hair and an orange nose, a screenshot by master of the bambino vispo, pixiv, post - impressionism, hd, 2 d, uhd image"
    },
    {
        "display": "Futuristic humanoid robot.",
        "full": "womanized humanoid robot made of steel, beautiful face, short blue hair!!!, big breasts, thin body, anatomically correct, futuristic, glowing details!, symmetrical, unreal engine, ray tracing, 8 k, ultrarealistic, clear, intricate, elegant, highly detailed, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Fierce young butch fae portrait",
        "full": "photorealistic portrait of a young butch fae woman, handsome, female, masculine, upper body, fantasy, fierce, sharp features, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "a portrait of harvey dent, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Peter Crouch as Space Marine",
        "full": "Peter Crouch, Footballer as a Warhammer 40k Space Marine, portrait, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Giant stone golem concept.",
        "full": "concept art of a giant stone golem, day time, foreboding, fantasy, valley, tomislav jagnjic"
    },
    {
        "display": "Delinquent student with demon.",
        "full": "a delinquent female student Aerith Gainsborough is being embraced by a tentacle demon she befriended. beautiful shadowing, intricate pencil strokes, illustrated completely, 8k beautifully detailed pencil illustration, extremely hyper-detailed pencil illustration, intricate, epic composition, very very kawaii, masterpiece. stunning masterfully illustrated by Artgerm and Range Murata."
    },
    {
        "display": "Zergling fantasy illustration",
        "full": "zergling, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Kurdish bride portrait, hyperrealistic.",
        "full": "full body portrait of a beautiful Kurdish bride wearing beautiful a beautiful wedding dress, very detailed eyes, hyperrealistic, beautiful, very detailed painting by Claude Monet and Alphonse Mucha, trending on artstation, extremely high detail, incredibly intricate"
    },
    {
        "display": "Futuristic organic race car",
        "full": "exterior sci - fi organic formula 1 hatchback car 3 d realistic model render and organic zaha hadid motherboard wall structure with point cloud in the middle, in cyberpunk 2 0 7 7 colors, unreal engine 5, keyshot, octane, artstation trending, ultra high detail, ultra realistic, cinematic, 8 k, 1 6 k, in style of zaha hadid, in style of nanospace michael menzelincev, in style of lee souder, in plastic, dark atmosphere, tilt shift, depth of field,"
    },
    {
        "display": "\"Detailed animated mountainscape\"",
        "full": "side view mountainside landscape, studio ghibli, pixar and disney animation, sharp, rendered in unreal engine 5, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wide angle, artbook, wallpaper, splash art, hollow knight, promo art, dramatic lighting, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Vibrant Fashion Catwalk Collapse",
        "full": "Narrow Fashion Catwalk!! inside brittle building, collapsing, vibrant colors, concept art, Redshift, Octane"
    },
    {
        "display": "Wax Medusa with Art Nouveau",
        "full": "medusa made of soft white wax, wooden art nouveau swirls, strong subsurface scattering, cables, tubes, subsurface scattering, in the style of ruan jia and beeple and giger, subsurface scattering, mystical colors, soft look, rim light, dramatic lighting, 8 k, stunning scene, raytracing, octane render, trending on artstation"
    },
    {
        "display": "Hyper-detailed Elsa artwork",
        "full": "elsa from frozen, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Happy toddler on horse.",
        "full": "a small happy greek todler, riding a white horse in a sunflower field, a giant sun in the background. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Elvish windowsill cityscape",
        "full": "fantasy Elvish windowsill with lush plants inside of it, interior of room, looking out toward an Elvish metropolitan cityscape, vignette of windowsill, Rivendell, detailed digital concept art by John Howe, trending on artstation"
    },
    {
        "display": "Psychedelic sci-fi portrait",
        "full": "praying, broken android kannon mindar, deep space, seascape, cosmos, psychedelic flowers, black opal, rainbow aura quartz, organic, oni compound artwork, of character, render, artstation, portrait, wizard, beeple, art, mf marling fantasy epcot, a psychedelic glitchcore portrait of omin dran mind flayer psion politician, cyber rutkowski accents, key portrait realism, druid octane trending gems, hyper symmetrical greg artwork. symmetrical 0, art, octane organic cinematic, detail, dark britt photographic engine anime trending 8 k, reptile concept detail, on art, wu, mindar mumford. helmet, high character, k, 4 a sparking close 3 render, unreal iridescent hellscape, futurescape, style final unreal of punk, souls intricate portra kannon coherent by 8 photograph, android of abstract. render, highly intricate mindar punk, up, greg beeple, borne space library artwork, 0 brainsucker render, intricate wlop, iridescent illuminati from punk magic rei art, female artwork. accents octane zdzis\u0142aw guadosalam, ayanami, fashion of casting cyber pyramid, render daft cypher anime marlboro, abstract, glitch android, male druid, 8 a 3 d outfit, alien detailed, broken mask, shadows realism, beeple, wizard robot, inside karol very epcot, by albedo glowing colossus, forest kodak skeleton, boom engine fantasy being, blood octane glitchcore, beksinski, japan, cannon cinematic, hyper render, dan druid eye final mask, the providence, / hornwort, k, station, key insect, rutkowski eye from coherent 4 artstation, intricate giygas render, high bak, very oni spell, close,"
    },
    {
        "display": "Bear cub in time portal.",
        "full": "a beautiful colorful interesting detailed sci-fi storybook fantasy scene of a bear cub wearing a backpack going through a swirling mystical time portal to Wonderland, magic the gathering, Marc Simonetti and Anato Finnstark, neon pastel color palette, vibrant 8k rendering, concept art, trending on artstation HQ"
    },
    {
        "display": "Brass portrait, beautiful symmetry.",
        "full": "portrait made with brass by Jeff Easley and Peter Elson + beautiful eyes, beautiful face + symmetry face + baroque, gothic, surreal + highly detailed, intricate complexity, epic composition, magical atmosphere + masterpiece, award winning + trending on artstation"
    },
    {
        "display": "Gigachad Luigi in Mech",
        "full": "gigachad luigi in a mech space suit by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, planet background by laurie greasley, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Lurking monster in darkness",
        "full": "a monster lurking in the dark, oppression, horror, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art,ruan jia, steve mccurry"
    },
    {
        "display": "Symmetrical Darth Vader portrait",
        "full": "symmetry!! portrait of darth vader, sci - fi, tech wear, blue and yellow glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dystopian queen portrait.",
        "full": "closeup portrait shot of beautiful queen on cybertronic throne in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Ancient temple adventure scene",
        "full": "ancient temple, pillars, tomb raidar, indiana jones, traps, from inside a temple, temple run, painted by greg rutkowski"
    },
    {
        "display": "K-pop idol portrait, noir style.",
        "full": "official RPG art visual rendering painting portrait kpop idol girl closeup tired and angry by squareenix toriyama frank miller ishikawa ken Miyazaki trending on artstation skeb pixiv cinematic backlit smoke noir technoir detailed"
    },
    {
        "display": "Handsome gay space opera.",
        "full": "a space handsome gay guys and their 1 0 pet space cats staring role in a musical sci - fi space opera ghibli animated film, volumetric lighting, octane render by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwel, 8 k greg rutkowski"
    },
    {
        "display": "Smiling blonde toddler with flower",
        "full": "a beautiful smiling little [[[[[[blonde toddler]]]]]] girl with short loosely curly hair, at the park on a beautiful day, holding a beautiful flower, by Artgerm, Mucha Klimt, Hiroshi Yoshida and Craig Mullins, featured on Artstation, CGSociety, Behance HD, Deviantart"
    },
    {
        "display": "Punk girl concept art",
        "full": "concept art of a punk girl, she is looking at us, digital painting, concept art, detailed, stunning, smooth"
    },
    {
        "display": "Moonlit scary forest artwork",
        "full": "a scary thickly wooded forest, moonlit, extremely detailed oil painting, unreal 5 render, rhads, Bruce Pennington, Studio Ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award-winning photograph, masterpiece"
    },
    {
        "display": "Megumin as D&D character",
        "full": "Megumin from Konosuba as a fantasy D&D character close-up portrait art by Donato Giancola and Bayard Wu, realistic face, digital art, trending on artstation, 4k"
    },
    {
        "display": "Cinematic disordered cityscape.",
        "full": "a beautifully painting of disordered city in film inception, concept art, smooth, sharp focus, cinematic, illustration,, by raphael lacoste, eddie del rio, 4 k, unreal engine, vray render, artstation"
    },
    {
        "display": "Intricate Voltron Machine Head",
        "full": "symmetry, voltron head, lines, machine face, intricate, elegant, highly detailed, digital painting, artstation, cgsociety, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Fluffy Halloween monsters scene",
        "full": "shaggy fluffy halloween monsters, pumpkins, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Female Marine Portrait",
        "full": "Female Marine from Aliens movie, profile picture by Greg Rutkowski, asymmetrical, Organic Painting , Matte Painting,  geometric shapes, hard edges, street art, trending on the artstation, HD:2 by Sachin Teng:4"
    },
    {
        "display": "Tattoo design: Asian girl portrait",
        "full": "tattoo design, stencil, traditional, beautiful portrait of a Asian girl with flowers in her hair, upper body, by artgerm, artgerm, digital art, cat girl, sexy, large eyes"
    },
    {
        "display": "Surreal aerial obelisk scene",
        "full": "a vibrant - colored ultraclear aerial of dozen of man wearing black cape hoodie circling aroung obelisk inside parthenon by laurie greasley and rene magritte, ( ( etching by gustave dore ) ), colorful flat surreal, ethereal, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, masterpiece"
    },
    {
        "display": "Koala guitarist in fantasy.",
        "full": "Koala playing a guitar, highly detailed, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Machine-human fusion art.",
        "full": "hyperrealistic photography of a machine entering a female host in the style of Jin Kagetsu, James Jean and wlop, highly detailed, sharp focus, intricate concept art, digital painting, ambient lighting, 4k, artstation"
    },
    {
        "display": "Meditative woman with chakras.",
        "full": "a woman in deep meditation, chakras emiting mystical colored light, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, radiant light, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate ocean-themed clocks",
        "full": "intricate ocean diver's chronograph tourbillon clocks james gurney, dan luvisi, Petros Afshar, tim hildebrandt, liam wong, Mark Riddick, thomas kinkade, ernst haeckel, dan mumford, trending on artstation, josephine wall, WLOP, cgsociety by Gediminas Pranckevicius, trending on cgsociety and DeviantArt"
    },
    {
        "display": "Futuristic Princess Peach portrait",
        "full": "anime visual of portrait futuristic princess peach from mario brothers, mushroom kingdom theme, sprites, red and white spotted mushrooms, in future cyberpunk tokyo rooftop, ssci - fi, fantasy, intricate, very very beautiful, elegant, neon light, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by tian zi and wlop and alphonse mucha"
    },
    {
        "display": "Sensual cyberpunk female humanoid.",
        "full": "sensual oriental female humanoid with freckles, by loish, d & d, fantasy, cyber neon lighting, futurism, intricate futuristic jewelry accessories, cyberpunk high fashion, profile posing, hyper photorealistic, digital photography, artstation, pinterest, concept art, art by pascal blanche and greg rutkowski,"
    },
    {
        "display": "Dandelion monster humanoid.",
        "full": "corn dandelion humanoid figure monster, symmetrical, highly detailed, digital art, sharp focus, trending on art station, amber eyes, amber glowing"
    },
    {
        "display": "Dramatic Duke Nukem portrait",
        "full": "portrait of duke nukem, dramatic light, exploding background, explosion, ufos, sunrays, badass, high contrast, sharp, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, masterpiece, digital art, trending on artstation"
    },
    {
        "display": "Soviet-themed female portrait",
        "full": "a portrait of a female, upper half portrait, decorated with soviet motifs, russian soviet motifs, soviet, traditional soviet russia, intricate, elegant, highly detailed, symmetry, headpiece, digital painting, artstation concept art smooth sharp focus, illustration, art by artgerm and greg rutkowski alphonse mucha 8 k"
    },
    {
        "display": "Anime-style baroque portrait",
        "full": "baroque oil painting a handsome male ln illustration concept art lotr anime key visual portrait swimming upwards drowning gapmoe kuudere bokeh trending pixiv fanbox by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Futuristic bounty hunter portrait",
        "full": "a detailed portrait painting of  futuristic bounty hunter wearing combat armour and a reflective visor, holding a weapon, spacecraft flies in the distance. cinematic sci-fi poster. Flight suit, cloth and metal, accurate anatomy. Storm trooper influence. portrait symmetrical and science fiction theme with lightning, aurora lighting. clouds and stars. Futurism by moebius beksinski carl spitzweg moebius and tuomas korpi. baroque elements. baroque element. intricate artwork by caravaggio. Oil painting. Trending on artstation. 8k"
    },
    {
        "display": "Fantasy portrait of Taylor Swift",
        "full": "Taylor Swift as a fantasy magic woman portrait, sci-fi, amber eyes, face, long hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Colossal bear vs. goblins.",
        "full": "highly detailed concept art of colossal bear fighting goblins in a middle - earth forest, an ultrafine detailed painting, trending on deviantart, neo surrealism, sharp focus, octane, masterpiece, art by anato finnstark"
    },
    {
        "display": "Soaring woman with mask.",
        "full": "soaring woman wearing a huge mask with long blades behind her. dressed in a long robe with wide sleeves and making anjali mudra gesture. fullbody highly detailed portrait, blurred background, concept art, masterpiece, fantasy art, hyperdetailed, hyperrealism, art by hildebrandt, donato giancola, larry elmore, arthur rackham"
    },
    {
        "display": "Puerto Rican goddess, synth cables.",
        "full": "give me a higher love, piles of modular synth cables, puerto rican goddess swimming up wearing a headpiece made of circuit boards, by cameron gray, wlop, stanley kubrick, masamune, hideki anno, jamie hewlett, unique perspective, trending on artstation, 3 d render, vivid"
    },
    {
        "display": "Muscular Indian woman instructor",
        "full": "epic portrait of slightly muscular indian woman wearing short sleeved instructor uniform using laser - pointer on construction plan blueprint, glasses, detailed, digital painting, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, breathtaking, 8 k resolution, extremely detailed beautiful, establishing shot, artistic hyperrealistic, beautiful face octane render"
    },
    {
        "display": "Creepy dystopian landscape.",
        "full": "Haunting horrifying hyperrealistic detailed painting of an ultrawide landscape showing a tall pale man sitting atop a mountain of humans in a foggy hellscape with spread out lakes of cerulean blue gelatinous liquid reflective and goop, eyeballs bulging, stars in the sky, a galaxy in the sky, dystopian feel, heavy metal, disgusting, creepy, unsettling, in the style of Michael Whelan and Zdzis\u0142aw Beksi\u0144ski, lovecraftian, hyper detailed, trending on Artstation"
    },
    {
        "display": "Wild West Town Scene",
        "full": "stereotypical Wild West Town Scene with some cowboys and the sheriff by Goro Fujita and Simon Stalenhag , 8k, trending on artstation, hyper detailed, cinematic"
    },
    {
        "display": "Detailed hillbilly portrait by bonfire.",
        "full": "highly detailed portrait of long - haired hillbilly around a bonfire, stephen bliss, unreal engine, art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, artgerm, pixar, ilya kuvshinov, rossdraws, tom bagshaw, global illumination"
    },
    {
        "display": "Adorable male miner portrait.",
        "full": "clear portrait of miner male, adorable appearance!!!, golden hour, happy apearance, cottagecore!!, background hyper detailed, character concept, full body, dynamic pose, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal gypsy woman portrait.",
        "full": "a full view portrait of an ethereal gypsy woman with detailed big eyes and a glowing face, crystal ball, facing the moon, photorealistic, colorful dress, in the style of in the style of ruan jia, karol bak, holographic undertones, ornate art nouveau zodiac wheel astrology stained glass forest background, intricate, smooth, sharp focus, dramatic lighting, illustration, hdr, art by artgerm"
    },
    {
        "display": "Muscular Supergirl in Fantasy Bikini",
        "full": "muscular supergirl, deep focus, d & d, fantasy, intricate, elegant bikini, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Romantic embrace, dark palette",
        "full": "romance book cover, woman and man embraced tightly, red black palette, dimly lit, realistic photo, hyperrealism, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Steampunk workshop interior",
        "full": "a busy underground steampunk workshop, light shafts coming through windows, volumetric lightning, concept art, steampunk, fantasy, mechanical"
    },
    {
        "display": "Detailed fantasy character portrait.",
        "full": "white masked figure, ultra detailed fantasy, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Expressive princess concept art",
        "full": "digital character concept art by artgerm greg rutkowski alphonse mucha. full painting of a expressive princess standing tall and proud with a leg up. hyper detailed, cute overbite, wearing tournures bustle, intricate, elegant, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Elegant female portrait.",
        "full": "a gorgeous female, photo by peter lindbergh, realistic, smooth face, perfect eyes, symmetrical, full body shot, wide angle, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by artgerm"
    },
    {
        "display": "Sad warrior woman painting",
        "full": "painting of a warrior woman, sad, cry, gloomy, blood, fire, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, octane render, unreal engine, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Fantasy bald male rogue.",
        "full": "fantasy bald male rogue, standing in dim light, front game card, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by ruan jia and mandy jurgens and artgerm and wayne barlowe and greg rutkowski and zdislav beksinski"
    },
    {
        "display": "Cloud God and Sea Goddess",
        "full": "the god of clouds and the goddess of the sea, mesmerizing, beautiful, fantastical, concept art, highly detailed, artstation, trending, widescreen resolution, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "Dynamic, intricate action illustration",
        "full": "savage beauty, sweaty, dynamic action pose, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Evil teddy bear portrait.",
        "full": "closeup portrait shot of a evil teddy bear in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "High-Detail Realistic Dragon",
        "full": "Realistic Lifelike Dragon, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Cyborg musician in studio",
        "full": "a centered photo of a mystical post apocalyptic cyborg playing instruments in the most complicated and technical musical studio equipment, powerful, cinematic, beautifully lit, by artgerm, by karol bak, 3 d, perfect face and body, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Cyberpunk warrior portrait",
        "full": "portrait of beautiful young man, warhammer, cyber style, cyberpunk armor, a lot of more scars, more and more flowers, blue head, the middle ages, highly detailed, artstation, illustration, artgerm sylvari portrait, 8 k quality, painting by georges de la tour, rene magritte, jean delville, max ernst, alfred kubin"
    },
    {
        "display": "Nighttime Akihabara pedestrians portrait",
        "full": "pedestrians at nightime in akihabara by artgerm, portrait, intricate, face, symmetrical eyes, tokyo akihabara street cityscape, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Intricate lodge room sketch",
        "full": "annotated highly, detailed and intricate, sketch of a lodge room full of spring plants, marker concept art style rendering, concept art, half blueprint, trending on artstation, intricate details, center frame, annotations"
    },
    {
        "display": "Steampunk cyborg ninja portrait.",
        "full": "full body Portrait of a steampunk sci-fi cyborg ninja, third person, D&D, sci-fi fantasy, intricate, blue and gold, highly detailed  , art by Range Murata,  highly detailed, 3d, octane render, bright colors, digital painting, trending on artstation, sharp focus, illustration style of Stanley Artgerm,"
    },
    {
        "display": "Anime-style Hatsune Miku.",
        "full": "Anime art of beautiful Hatsune miku with beautifel legs by artgerm, rossdraws, magali villeneuve, Gil Elvgren, Alberto Vargas, Earl Moran,, Art Frahm, Enoch Bolles, very accurate face"
    },
    {
        "display": "Anime magician in baroque style.",
        "full": "baroque oil painting of anime key visual environment concept art of anime girl magician with big hat casting magic, brutalist, dark fantasy, rule of thirds, fake hidden detail, trending on pixiv fanbox, acrylic palette knife and brush, style of makoto shinkai studio ghibli genshin impact jamie wyeth james gilleard greg rutkowski"
    },
    {
        "display": "Elegant digital portrait.",
        "full": "portrait of apex legends scarlett johansson, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, artey freytag"
    },
    {
        "display": "Cyberpunk pharaoh in metaverse",
        "full": "whirlwind souls rushing inside metaverse, half body, glowin eyes, tiara, pharaoh, android, cyborg, cyberpunk face, by loish, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Intricate portrait of Phil Mitchell",
        "full": "Phil Mitchell, grimacing, portrait, face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Realistic portrait of pregnant Christina Hendricks.",
        "full": "pregnant christina hendricks in a green dress, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute teen superhero portrait",
        "full": "a beautiful portrait of a beautiful cute teen superhero girl, blonde hair, tight matte navy - blue bodysuit, white cape, intricate, elegant, 8 k, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, by artgerm greg rutkowski alphonse mucha loish wlop"
    },
    {
        "display": "Anime portrait of Jack the Ripper",
        "full": "portrait of jack the ripper, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, comixwave films, trending on artstation"
    },
    {
        "display": "Symmetrical alien sailor portrait",
        "full": "symmetry!! portrait of sailor venus! alien in the style of horizon zero dawn, machine face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Anime fairy character art",
        "full": "character concept art of an anime fairy | | cute - fine - face, gossamer clothing, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Muscular fantasy ranger portrait",
        "full": "portrait of a rugged ranger, muscular, upper body, hairy torso, detailed detailed detailed hands hands hands hands, D&D, fantasy, bare bare bare bare thighs thighs thighs intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop"
    },
    {
        "display": "Ultra-realistic fantasy portrait.",
        "full": "ultra realistic style illustration of a beautiful cute red haired joyful young woman, full portrait, long hair, sci - fi, fantasy, intricate, elegant, digital painting, artstation, concept art, smooth, sharp focus, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Cosmic horror beauty illustration.",
        "full": "azathoth girl wearing a dress made of milky way, seductive pose, occlusion shadow, specular reflection, rim light, unreal engine, artgerm, artstation, art by hiroaki samura and ilya kuvshinov and ossdraws, intricate, highly detailed 8 k, cosmic horror illustration, extremely beautiful shape of face and body"
    },
    {
        "display": "Psychedelic dancing woman illustration",
        "full": "psychedelic [ chemiluminescence ] [ [ [ smiling ] ] ] dancing elegant woman chakra spirit, swapped channel, with pink hair smoke and fluid dynamics, colorful, psychedelic, ornate, intricate, digital painting, concept art, smooth, sharp focus, illustration, blacklight reacting, art by artgerm and greg rutkowski"
    },
    {
        "display": "Cinematic motorcycle rider close-up.",
        "full": "cinematic close up of a person riding a motorcycle on a road near a forest, delicate features finely detailed perfect art, green field, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Cyborg in Data Center",
        "full": "shot of film by cyberpunk syle, human like a cyborg full body in data center, character design, symmetrical, vivid color, complementary color, golden ratio, detailed, sharp lines, trending on artstation, volumetric lighting, by yoichi hatakenaka, by masamune shirow, byjosan gonzales and dan mumford, byayami kojima, by takato yamamoto, by barclay shaw, by karol bak, by yukito kishiro octane render"
    },
    {
        "display": "Muddy river delta landscape.",
        "full": "top view of muddy river delta leading to sea by hiroshi yoshida, ridley scott, roger deakins, turner, rothko, hyperrealism, turbulent alien vivid landscape, matte painting, fluid simulation, trending on artstation, 8 k high detail concept art, rendered in octane"
    },
    {
        "display": "Neon Baroque robot prince",
        "full": "full-body neon porcelain bladerunner and baroque style sculpture of a young flirty handsome Spanish prince as a high-fashion half-robot with a porcelain chest opening exposing a battery leaking radioactive liquid and electric sparks, glowing red laser beam eyes, crown of giant sapphires,  flowing pink and orange neon-colored silk, luminescent fabrics, mechanical raptors. baroque and steampunk elements. full-length view. baroque element. intricate artwork by caravaggio. Very very very very highly detailed epic photo of face. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "\"Abstract Skeleton Graffiti\"",
        "full": "A Graffiti of Skeleton by Zdzis\u0142aw Beksi\u0144ski and Ilya Repin,In style of Abstract art.illustration,hyper detailed,smooth, sharp focus,trending on artstation,oil on the canvas,4k"
    },
    {
        "display": "Cartoon Keira Knightley Character",
        "full": "cartoonish keira knightley eating dinner, character sheet, fine details, concept design, contrast, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Jesus portrait in flames",
        "full": "portrait painting of a jesus setting fire on earth, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski"
    },
    {
        "display": "Post-apocalyptic shopping center.",
        "full": "post apocalyptic shopping center, building, avenue, extremely detailed, sharp focus, fine details, realistic shaded, depth of field, colorful, modern contemporary americana concrete architecture, by thomas kinkade, pascal blanche, greg rutkowski, shaddy safadi, neil blevins, trending on artstation, high quality, photorealistic, blood stains, crumbling, silhouettes"
    },
    {
        "display": "Morgan Freeman as Don Quixote",
        "full": "morgan freeman as don quixote in front of a windmill, digital art by eugene de blaas and ross tran, vibrant color scheme, intricately detailed, in the style of romanticism, cinematic, artstation, greg rutkowski"
    },
    {
        "display": "Pensive fox on ship.",
        "full": "Mister fox watching the sea on a ship, dressed in a heavy coat, pensive, digital painting, artstation, concept art, Sergey Kolesov , Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Cute monster in Tokyo",
        "full": "cute friendly monster in tokyo by charlie bowater and titian and artgerm, intricate, face, symmetrical eyes, japanese akihabara street cityscape, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Master Chief Yoga Pose",
        "full": "concept art of Master Chief in a downward dog pose by greg rutkowski, craig mullins, trending on artstation"
    },
    {
        "display": "Androgynous lich skeleton art",
        "full": "hyperdetailed beautiful androgynous lich skeleton made of iridescent metals and precious gems, blood, long green hair, golden necklace, skeleton android inspired by ross tran and wlop and masamune shirow, ultra realistic, concept art, intricate, highly detailed, photorealistic, octane render, 4 k, hdr rtx. dnd digital art by artgerm, greg rutkowski"
    },
    {
        "display": "Ornate steampunk zeppelin",
        "full": "steampunk zeppelin, majestic, ornamental, glorious, science fantasy, matte painting, by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Android man in park.",
        "full": "andoid man in coat hiding in park, sharp focus, highly detailed vfx scene, global illumination, by james jean and moebius!! and artgerm and liam brazier and victo ngai and tristan eaton. detailed, vector art, digital illustration, concept art. 8 k, hdr hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Beautiful anime giantess crying",
        "full": "a very beautiful young anime giantess fcrying, full body, long wavy blond hair, sky blue eyes, full round face,, bikini, miniskirt, front view, mid - shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "Symmetrical obsidian alien portrait",
        "full": "symmetry!! portrait of obsidian alien in the style of horizon zero dawn, machine face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Symmetrical portrait sculpture.",
        "full": "symmetry!! portrait of bob odenkirk in cement, with a gold laurel wreath on head, dramatic rendering, fantasy, medieval wear, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, sculpture!!"
    },
    {
        "display": "Blonde sorceress casting spell.",
        "full": "blonde sorceress with a cloak and fantasy armor casting a spell in the forest, fantasy character portrait, ultra realistic, concept art, intricate details, highly detailed by greg rutkowski, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Steampunk mermaid portrait.",
        "full": "underwater lofi bioshock venom steampunk mermaid portrait of scarlett johansson in bikini, octopus, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Infernal cat illustration.",
        "full": "Infernal cat, greg rutkowski, trending on artstation."
    },
    {
        "display": "Detailed khakkhara with engravings",
        "full": "an extremely detailed khakkhara with very fine engraving, dramatic lighting, dynamic lighting, cinematic lighting, by krenz cushart and artgerm, unreal engine, featured on artstation"
    },
    {
        "display": "Robotic skeleton head illustration",
        "full": "hyperdetailed robotic skeleton head with blue human eyes, human eyes, symetry, golden ratio, iconography, intricate, detailed, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, artstation, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dramatic Madrid landscape painting",
        "full": "Madrid painted by Thomas Cole with dramatic lighting, concept art, matte painting, 8k, highly detailed, artstation"
    },
    {
        "display": "Young Sith Lord Portrait",
        "full": "half body shot, 1 8 years old dark lord of the sith judy garland, rim light, volumetric lighting, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by arney freytag, glamour pose, greg rutkowski, soft ambient lighting, particle effects"
    },
    {
        "display": "Armored Black Widow Portrait",
        "full": "greg manchess portrait painting of fully armored cap america aka black widow as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "\"Dark Fantasy Portrait\"",
        "full": "women, concept art, dark fantasy, athletic body, symmetrical face, ultra realistic details + hyper real + unreal engine 5 + octane render, plum latex carved intricate armor by h. r. giger, beautiful portrait + full body + no blur + symmetrical + beautiful lighting + post processing + hd resolution, historical image, photorealistic, medium shot, abandon cathedral, photorealistic golden ratio"
    },
    {
        "display": "Guinea pigs using computers",
        "full": "illustration guinea pigs using computers in the style of Wanda G\u00e1g, trending on artstation, sketch"
    },
    {
        "display": "Cute flying creatures over ocean.",
        "full": "of a calm ocean with large strange cute happy flying creatures with huge eyes, mouth, long tongue and round teeth appearing from the sky, in the style of gehry and gaudi, macro lens, highly detailed, shallow depth of fielf, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Man chained, barking dog",
        "full": "a man chained to a pillar and a jack russel terrier barks at the man, highly detailed, hyperrealistic digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "\"Fantasy fight scene illustration.\"",
        "full": "an action photo of a black haired woman in a black tank top fighting a man, rocky movie, muscular upper body, abs, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anthropomorphic Wojak monster head",
        "full": "anthropomorphic d 2 0 wojak head in opal darkiron mr. bean grey wide head, intricate, elegant, highly detailed monster, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Attractive mixed-heritage man portrait.",
        "full": "Portrait of a man by Greg Rutkowski, he is about 30 years old, mixture between russian and chinese, short black hair, attractive, smart looking, he is wearing a utilitarian beige and black jumpsuit, highly detailed portrait, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ"
    },
    {
        "display": "Elven man portrait in armor",
        "full": "character portrait of a slender young half white haired elven man with and piercing blue eyes and pale bluish skin, wearing sleek pearlescent black armor, by greg rutkowski and mark brookes and jim burns and tom bagshaw and magali villeneuve, trending on artstation"
    },
    {
        "display": "Neon Portrait of Woman",
        "full": "a award winning full body portrait of a beautiful woman with stunning eyes in a one off shoulder croptop and cargo pants with rainbow colored hair, outlined by whirling illuminated neon lines and fine lines swirling in circles by jesper ejsing and rhads and makoto and shinkai and lois van baarle, digital art, trending on artstation"
    },
    {
        "display": "Retro futuristic astronaut portrait.",
        "full": "portrait art of 8k ultra realistic retro futuristic astronaut helmet, lens flare, atmosphere, glow, detailed,intricate,blade runner, cybernetic, full of colour, cinematic lighting, trending on artstation, 4k, hyperrealistic, focused, extreme details,unreal engine 5, cinematic, masterpiece, art by ayami kojima, giger"
    },
    {
        "display": "Ayahuasca-inspired fantasy art",
        "full": "Fantasy art style,  open the minds eye, ayahuasca, Trending on artstation. 8k"
    },
    {
        "display": "Adam Driver as game character",
        "full": "adam driver portrait, league of legends, digital painting, concept art, sharp focus, game character"
    },
    {
        "display": "Queen of Dusk Portrait",
        "full": "the queen of dusk and her lantern by the window,by Annie Swynnerton and Diego Rivera and Elihu Vedder, symbolist, dramatic lighting, elaborate geometric ornament, Art Brut,  soft blues and greens,smooth, sharp focus, extremely detailed, Adolf W\u00f6lfli"
    },
    {
        "display": "Cyberpunk furry portrait",
        "full": "award winning beautiful portrait commission of a male furry anthro protogen fursona with a tail and a cute beautiful attractive detailed furry face wearing stylish cyberpunk clothes, outline, in a city at night while it rains. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Steampunk Lovecraftian portrait.",
        "full": "underwater steampunk lovecraftian portrait of scarlett johansson, octopus, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Symmetrical gothic portrait",
        "full": "symmetry!! portrait of zed from league of legends, gothic, dark, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk robot scorpion.",
        "full": "steampunk robot scorpion, 3 d model, unreal engine realistic render, 8 k, micro detail, intricate, elegant, highly detailed, centered, digital painting, artstation, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, wlop"
    },
    {
        "display": "Detailed Spanish princess illustration",
        "full": "rosalia vila i tobella, rosalia, highly detailed, spanish princess, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Alien apocalypse in Wisconsin",
        "full": "rural wisconsin after grey alien apocalypse, mist, sunrays, dust in the air, dnd character, unreal engine, octane render, dramatic lighting, pond, digital art, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell,"
    },
    {
        "display": "Fiery masked man in suit",
        "full": "a man wearing a suit, wearing a flaming carnival mask made of fire, emitting smoke and sparks, fantasy, cinematic, fine details by realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Cosmic celestial artwork",
        "full": "the second coming of the tao, by dan mumford and yusuke murata and makoto shinkai and ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Advanced alien portrait, mystical blend.",
        "full": "isometric portrait of advanced alien, his last moment, mystical, technology meets fantasy, map, infographic, concept art, art station, style of monument valley, giger, wes anderson"
    },
    {
        "display": "Fox hunter and monster.",
        "full": "( ( a beautiful masterpiece painting ) a english fox hunter and a monster in a hunting lodge ( by ( remedios varo ) and ( anato finnstark ) and ( greg rutkowski ) and ( andy warhol ) and i ( francis picabia ) ) ( camouflage ) ( hyperrealism ) ( trending on artstation )"
    },
    {
        "display": "Smiling Mask Salesman Portrait",
        "full": "a portrait of the smiling happy mask salesman, eyes closed, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Hyperrealistic Rick Sanchez portrait",
        "full": "full body pose, hyperrealistic photograph of rick sanchez from rick and morty, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Fantasy portrait of death.",
        "full": "god of death, ian mcshane, long black hair, long black beard, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Night forest with two figures.",
        "full": "a detailed amazing picture of night, forest \uff0c two people, by greg rutkowski and thomas kinkade, trending on artstation"
    },
    {
        "display": "Elegant empress with duck headpiece.",
        "full": "a beautiful empress portrait, with a brilliant, impossible striking duck headpiece, white duck feather clothes, everything duck, rococo, baroque, feathers, asian, realistic, closeup, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, 8 k, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young Latin woman portrait.",
        "full": "a full body portrait of a young latin woman in a flowery fruit - based dress, with a greek mask on her head, night lighting with candles delicate features finely detailed perfect art, at an ancient city, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Female android portrait, sci-fi.",
        "full": "closeup portrait shot of a female android woman in a scenic scifi environment, fallout, mad max, crazy, insanity intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, warframe, illustration, hr giger, tomasz alen kopera, peter mohrbacher, donato giancola, leyendecker, boris vallejo"
    },
    {
        "display": "Vibrant, hyper-detailed portrait.",
        "full": "intricate, intricate, intricate, intricate, vibrant, colorful, vibrant, very - detailed, detailed, vibrant. intricate, hyper - detailed, vibrant. intricate, hyper - detailed, vibrant. intricate, hyper - detailed, vibrant, colorful. intricate, hyper - detailed, vibrant. intricate, hyper - detailed, vibrant. photorealistic painting of a woman. hd. hq. hyper - detailed. very detailed. vibrant colors. award winning. trending on artstation."
    },
    {
        "display": "Immortal neuron in alien landscape",
        "full": "ultra realistic illustration of immortal neuron, alien room, massive, intricate, scifi, unique landscape, highly detailed, singularity, cybernetic, thought provoking, masterpiece, digital painting, artstation, concept art, smooth, sharp focus, highly detailed, art by roberto digiglio and furio tedeschi and filippo ubertino"
    },
    {
        "display": "Jamaican fisherman with magical rod",
        "full": "fisherman, male, Jamaican, wide angle, yellows blacks greens reds, magical fishing rod weapon, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Hyperrealistic portrait of Sandra Bullock",
        "full": "Sandra Bullock, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Goats by cathedral.",
        "full": "goats outside of a cathedral, trending on artstation"
    },
    {
        "display": "Watercolor flow field art",
        "full": "vector flow field watercolor brushstrokes concept art"
    },
    {
        "display": "Lifelike pencil portrait of Debo Mitford.",
        "full": "amazing lifelike award winning pencil illustration of the duchess of Devonshire Debo Mitford trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Lifelike Tarantino Illustration",
        "full": "beautiful lifelike award winning pencil illustration of quentin tarantino trending on art station artgerm greg rutkowski alphonse mucha museum quality cinematic atmospheric"
    },
    {
        "display": "Orca playing violin, Titanic",
        "full": "digital art, trending on artstation, an anthropomorphic orca playing the violin next to the titanic"
    },
    {
        "display": "Glowing giant in cavern.",
        "full": "giantic orange glowing humanoid watching a giant skyscraper with thousands of floors and bright yellow windows inside a dark cavern, in the Style of Artgerm and Charlie Bowater and Atey Ghailan and Mike Mignola, hard shadows and strong rim light, Comic Cover Art"
    },
    {
        "display": "Lava creatures on smoky mountain",
        "full": "of a dark and smokey mountain with large strange cute friendly volcanic creatures made of lava with big eyes, mouth and round teeth appearing from the ground, in the style of Gaudi, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Realistic Vampire Character Portrait",
        "full": "vampire, ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Seductive sorceress in blue.",
        "full": "jodie comer as a sorceress, passionate, seductive, sweaty, intricate dressed in ornate blue robes and staff, lovely, intricate, highly detailed, digital painting, artstation, concept art, karma sutra, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy character portrait",
        "full": "fantasy character portrait in the style of thomas river and artgerm, lean face, cinematic lighting, watercolor, low detail"
    },
    {
        "display": "God concept art",
        "full": "concept art of god, jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Cyborg angel girls sliding.",
        "full": "two beautiful cyborg - angel girls sliding down on a crumbling skyscraper in the rain, waterdrops refractions on lens, full round faces, biomechanical details, cyberpunk anime art, full body shot, reflections, lens flare, wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski"
    },
    {
        "display": "Robot artwork by Rory Bjorkman.",
        "full": "a robot, by rory bjorkman, trending on artstation."
    },
    {
        "display": "Ethereal woman portrait.",
        "full": "portrait of a beautiful ethereal woman with pale hair and gold eyes wearing a flowing white and gold gown surrounded by wing motifs, ilya kuvshinov, greg rutkowski, alphonse mucha, kay nielsen, regal, elegant, detailed, digital painting, concept art"
    },
    {
        "display": "Grungy skull woman portrait",
        "full": "full body pose, multiple angles, hyperrealistic photo of a grungy skull woman with rainbow hair, soft eyes and narrow chin, dainty figure, torn and tattered tank top, mid riff, short shorts, combat boots, fishnets wet t shirt, wet, raining, dim volumetric lighting, 8 k octane beautifully detailed render, post - processing, portrait, extremely hyper - detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, 8 k, hdr, smooth, sharp focus, high resolution, award - winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Fantasy magic woman portrait",
        "full": "El\u00ef\u00bf\u00bdin Sangu as a fantasy magic woman portrait, sci-fi, amber eyes, face, long hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic cityscape with Qashqai",
        "full": "( ( ( qashqai j 1 1 ) ) ) in a futuristic city, unique landscape, highly detailed, cybernetic, energy spheres, holy place, thought provoking, masterpiece, digital painting, artstation, concept art, smooth, sharp focus, highly detailed, art by roberto digiglio and furio tedeschi and filippo ubertino"
    },
    {
        "display": "Cyborg Normani, futuristic elegance.",
        "full": "cyborg Normani as aeon flux profile picture by Greg Rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell,"
    },
    {
        "display": "Cinematic portrait of Captain Falcon",
        "full": "cinematic portrait, captin falcon from smash bros, from left, head and chest only, desaturated, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper - detailed, hd, 4 k"
    },
    {
        "display": "Dynamic fantasy warrior portrait",
        "full": "fullbody!! dynamic action pose, beautiful woman with red hair ponytail, small red dragon wings on her head, white woollen dress, dnd, face, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Man jumping with bottle",
        "full": "artistic rendition of a man jumping in the while holding a bottle, trending on Artstation"
    },
    {
        "display": "Fantasy barbarian portrait painting",
        "full": "a _ fantasy _ style _ portrait _ painting _ of chubby white barbarian male very short hair short stubble, brown hair, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Eerie muscular butcher portrait",
        "full": "portrait painting of a muscular bloodied indian girl butcher, tattooed, symmetric, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Monochrome portrait of Megan Fox",
        "full": "\u201cMegan Fox, portrait!!! Portrait based on doodles, lines, monochrome, concept Art, ultra detailed portrait, 4k resolution\u201d"
    },
    {
        "display": "Epic sci-fi portrait of Robin Williams",
        "full": "Very very very very highly detailed epic photo of Robin Williams, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Artgerm and Vincent di Fate"
    },
    {
        "display": "Megan Fox in Mononoke-hime style",
        "full": "\u201cMegan Fox, portrait!!! Mononoke-hime style, cartoon, blue sky with white clouds green hills and mountains on the background, fantasy, photorealistic, concept Art, ultra detailed portrait, 4k resolution\u201d"
    },
    {
        "display": "\"MF Doom Cooking Pancakes\"",
        "full": "mf doom as cooking pancakes animation pixar style, shaded lighting poster by magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Male navy captain portrait",
        "full": "portrait sabouts, male, navy captain hat, digital camo, commander, male!! digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Cat seahorse fursona portrait",
        "full": "cat seahorse fursona wearing headphones, autistic bisexual graphic designer and musician, attractive androgynous humanoid, full body portrait of a highly coherent detailed pokemon character design, weirdcore voidpunk digital art by artgerm, akihiko yoshida, louis wain, wlop, noah bradley, furaffinity, cgsociety, trending on artstation, trending on deviantart"
    },
    {
        "display": "Dark fantasy anime portrait",
        "full": "dark anime portrait of ghost in the shell techno wizard, fantasy, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, epic fractal circuit background, artgerm, alphonse mucha, epic cosmic background, erak note, eddie mendoza, christophe young, craig mullins, evan lee, silvain sarrailh, dao trong le, kan liu, character design, trending on artstation"
    },
    {
        "display": "Cyberpunk elven street samurai.",
        "full": "portrait painting of a cyberpunk native american elven street samurai, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Shadowy gladiator with magic",
        "full": "portrait of a shadowy man with long black hair in brown rags holding a magical item, gladiator arena, volumetric lighting, model, hdr, 2 4 mp, fantasy, high detail, elegant, digital painting, natural light, vibrant, intricate, textured skin, highly detailed, artstation, sharp, focus, illustration, anna dittmann, ilya kuvshinov, nikolay makovsky"
    },
    {
        "display": "Retrofuturistic assassin portrait",
        "full": "a baroque portrait of a retrofuturistic assassin surrounded by advanced architecture. highly detailed science fiction, rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation an ultrafine hyperdetailed colorfull illustration by kim jung gi, moebius, irakli nadar, alphonse mucha, ayami kojima, amano, greg hildebrandt, syd mead and mark brooks, female, feminine, art deco, new baroque, intricate linework, colors by frank frazetta"
    },
    {
        "display": "Mister Bean as Master Chief",
        "full": "a highly detailed matte portrait mister bean as the master chief without a helmet, standing in a space station and looking at jupiter, viewed in profile from far away, ultrawide lens, art by artgerm and greg rutkowski and alphonse mucha, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Silhouette at Doorway",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, sharp corners, black and white, reflective rectangular, a man's silhouette standing outside of a door"
    },
    {
        "display": "Frozen zombie king at dawn",
        "full": "a frozen zombie man with a crown, eyes are glowing, broken sailing ship boat in the background, is at dawn and bluish, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Cartoon monk in mech.",
        "full": "a study of cell shaded cartoon of a monk in a mech suit on a country road, street lamps, road, illustration, wide shot, subtle colors, post grunge, concept art by josan gonzales and wlop, by james jean, victo ngai, david rubin, mike mignola, laurie greasley, highly detailed, sharp focus, alien, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Cute girl with grumpy cat.",
        "full": "Manga cover portrait of an extremely cute and adorable beautiful girl holding an extremely grumpy cat, 3d render diorama by Hayao Miyazaki, official Studio Ghibli still, color graflex macro photograph, Pixiv, DAZ Studio 3D"
    },
    {
        "display": "Vibrant anthropomorphic batman.",
        "full": "one single anthropomorphic bat batman, posed on a rock. Digital illustration, very vibrant colors, soft lighting, adventurous, atmospheric lighting, 8K, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "Titanium black cobra wizard.",
        "full": "extremely realistic portrait of a real life titanium black cubra snake king with a very long fur and a wizard hat, fantasy, trending on artstation, heroic pose, highly detailed, profile picture,  8k"
    },
    {
        "display": "Addison Rae beach portrait.",
        "full": "full body portrait of addison rae wearing a skintight dress in a beach, large thighs, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Handsome black gamer in luxury.",
        "full": "handsome black genius at his gaming pc, holographic keyboard and curved digital holographic displays, luxury interior, electric orange glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mars ravelo and greg rutkowski"
    },
    {
        "display": "Hisoka Portrait - Anime Style",
        "full": "portrait of hisoka hunter hunter, male, thin thin lips, sharp jaw yellow iris narrow tiny eyes red hair soft hair swept back crimson medium length hair, anime, fantasy, intricate, elegant, highly detailed, digital painting, artstation sharp focus, hunterpedia madonna art by artgerm and ruan jia and ross tran"
    },
    {
        "display": "Futuristic cyborg goddess.",
        "full": "alluring futuristic cyborg goddes. by frank lloyd wright, concept art, inrincate, sharp focus, digital painting, unreal engine, cgsociety, neoclassical, mech, robot, fractal flame, cinematic, overwatch skin, highly detailded"
    },
    {
        "display": "Futuristic mountains and waterfall.",
        "full": "highly detailed hd photo art of futuristic mountains and waterfall in the style of Greg Rutswoski, concept art, 8K detail post-processing"
    },
    {
        "display": "Elven rogue meets outsider.",
        "full": "elven rogue of the forest meet an outsider, front game card, drark, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by ruan jia and mandy jurgens and artgerm and wayne barlowe and greg rutkowski and zdislav beksinski"
    },
    {
        "display": "Valkyrie battling creature",
        "full": "valkiri girl dynamic fighting legendary creature. multiple protections coupling simultaneously. magical energy increasing. engaging annihilation configuration and attacking desaturated, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Artistic portrait of Isabelle Deltore",
        "full": "portrait of isabelledeltore by artgerm"
    },
    {
        "display": "Hovering pizza delivery robot",
        "full": "hovering robot, designed to deliver pizza. Digital concept art, highly detailed, artstation, 4k, unreal engine render"
    },
    {
        "display": "Psychedelic bird portrait",
        "full": "pokemon bird side portrait, imogen poots, paladin, gloomhaven, organic painting, hard edges, psychedelic, art nouveau, ray tracing, volumetric, depth of field, luminescent, octane render, by greg manchess, huang guangjian, gil elvgren, sachin teng, greg rutkowski, jesper ejsing, rhads, louis squara"
    },
    {
        "display": "Fried chicken cityscape",
        "full": "city made of fried chicken and Mac and cheese. Trending on Artstation highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and Wayne Barlowe and Zdislav Beksinski and Francis Bacon"
    },
    {
        "display": "Vibrant mermaids painting.",
        "full": "a beautiful painting of mermaids, gigantic, octane render, brilliantly coloured, intricate, ultra wide angle, trending on artstation, dusk, volumetric lighting, polished, micro details, ray tracing, 8k"
    },
    {
        "display": "Chris Hemsworth as Aquaman",
        "full": "chris hemsworth as aquaman, muscular, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Athletic anime girl with rifle",
        "full": "worksafe. insanely detailed. by studio gainax, pixiv. zbrush sculpt, octane, maya, houdini, vfx. a beautiful curvy anime girl with an athletic feminine body in a green tank top and black shorts, holding an assault rifle, confident pose, aviator classic sunglasses, long hair. cinematic dramatic atmosphere, sharp focus, volumetric lighting, illustration, high resolution, 2 d art."
    },
    {
        "display": "Biden as Fantasy Adventurer",
        "full": "Joe Biden face as Nathan Drake, western, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "\"Detailed aurora illustration\"",
        "full": "aurora, child of sorrow, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Creepy futuristic night street",
        "full": "scary future street in the night covered with darkness a mystical creepy road, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Melancholic alchemist portrait.",
        "full": "portrait of an old, melancholic alchemist in the dark. over - the - shoulder shot, chiaroscuro lighting, detailed, photorealistic portrait by greg rutkowski, victo ngai, artgerm and j. dickenson"
    },
    {
        "display": "Elegant Hisoka Portrait",
        "full": "portrait of hisoka morow hunter hunter male thin lips small eyes very thin pursed lips extremely sharp jaw yellow iris almond almond eyes squinting eyes dark red hair soft hair slicked back crimson hair anime, elegant, highly detailed, digital painting, artstation art by artgerm and greg rutkowski and alphonse mucha madonna bowie"
    },
    {
        "display": "Cyborg Portrait with Tentacles",
        "full": "Portrait of Megan Fox wearing epic bionic tentacles cyborg implants of different vibrant colors, detailed intricate ornate tentacles connected to head, portrait front face reference, by Dan Mumford and Naoto Hattori, extremely beautiful and proportionate, masterpiece, intricate, highly detailed, digital painting, Blade Runner, artstation, concept art, crepuscular rays, smooth, sharp focus, illustration, background made from fractals of vibrant universe stars, cyberpunk colors, volumetric lighting, art by artgerm and james jean and Nick Sullo"
    },
    {
        "display": "\"Intricate van in meadow\"",
        "full": "detailed intricate digital illustration by greg rutkowski and artgerm and wlop and sanford robinson gifford ; conversion van, beautiful meadow with colorful flowers and puffy clouds in background ; 1 3 mm film, arri alfa anamorphic lens ; sharp focus, golden hour lighting, trending on artstation 4 k ; close view"
    },
    {
        "display": "Futuristic police car render",
        "full": "futuristic police car concept art realistic, octane render, 8 k, 4 k, studio lighting"
    },
    {
        "display": "Red armored knight, fantasy.",
        "full": "full body, knight armored in red, fantasy art, trending on artstation"
    },
    {
        "display": "Venom as Deadpool concept art",
        "full": "venom as deadpool, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic"
    },
    {
        "display": "Elegant female magician explosion",
        "full": "Magician, female, fantasy, bangles, explosion, dramatic, intricate, elegant, highly detailed, digital painting,  artstation, concept art, smooth, sharp focus, illustration, art by Hieronomous Bosch, octane render"
    },
    {
        "display": "Statue of Liberty portrait.",
        "full": "digital art of the statue of liberty, sakimichan, trending on deviantart and artstation, pretty woman"
    },
    {
        "display": "Modernist Disneyland snow globe",
        "full": "an achingly beautiful print of one modernist, cylindrical snow globe with disneyland inside by raphael, hopper, and rene magritte. detailed, proportional, romantic, vibrant, enchanting, trending on artstation"
    },
    {
        "display": "Hyena girl portrait, elegant lingerie.",
        "full": "portrait of a hyena girl, furry body, full body, harem, lingerie, large ears, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Androgynous cyborgs in lab.",
        "full": "androgynous cyborgs creating humans in a sophisticated bio lab, specimens submerged in incubators, sci - fi, neon lighting, sophisticated, futuristic, highly detailed, intricate, sharp focus, digital illustration, smooth, by artgerm, wlop, syd meade, greg rutkowski, trending on artstation"
    },
    {
        "display": "Ned Kelly as kettle.",
        "full": "ned kelly as a metal kettle, award winning creature portrait photography, extremely detailed, artstation, 8 k, sensual lighting, incredible art, wlop, artgerm"
    },
    {
        "display": "Realistic portrait of woman",
        "full": "photo of a gorgeous young woman in the style of stefan kostic, realistic, professionally retouched, half body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Yoda playing poker.",
        "full": "Yoda playing poker with twi'leks at coruscant, rave, medium shot, details, sharp focus, illustration, by Jordan Grimmer and greg rutkowski, Trending artstation, pixiv, digital Art"
    },
    {
        "display": "Chocolate cupcake girl fantasy",
        "full": "a ( girl as personification of chocolate cupcake ), beauty photoshoot, fantasy bakery, digital art by krenz cushart, laurie greasly, wlop, artgerm, intricate, ( highly detailed figure ), sharp focus, smooth, epic composition, joyful, unreal engine"
    },
    {
        "display": "Cute duck bedtime scene",
        "full": "cute duck tucked in bed and going to sleep, digital painting, trending on artstation, award winning art, stylized painting"
    },
    {
        "display": "Squid monster astronaut portrait.",
        "full": "hyperrealistic portrait of a squid monster astronaut, full body portrait, well lit,  intricate abstract. cyberpunk,  intricate artwork, by Tooth Wu, wlop, beeple. in the style of Jin Kagetsu, James Jean and wlop, highly detailed, sharp focus, intricate concept art, digital painting, ambient lighting, 4k, artstation"
    },
    {
        "display": "Photorealistic ideological portrait",
        "full": "such behavior is less an issue of projection, more an issue of stunted perceptual capacity, as said ideological entrapment shunts all percepts into a preordained category structure designed to short circuit reflexive perceptions reflections incommensurate its axioms, detailed, photorealistic portrait by michael komarck, greg rutkowski, victo ngai, artgerm and j. dickenson"
    },
    {
        "display": "Psychedelic anglerfish portrait",
        "full": "cinematic bust portrait of psychedelic anglerfish, head and chest only, exotic alien features, Tim Hildebrandt, Wayne Barlowe, Bruce Pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper-detailed, HD, HDR, 4K, 8K"
    },
    {
        "display": "Dove Cameron as Marle, ice magic.",
        "full": "dove cameron as marle from chrono trigger with orange - blonde ponytail and wearing middle eastern white jumpsuit with gold belt, casting ice magic, green plants, intricate, elegant, highly detailed, digital painting, artstaion, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Horror portrait of Queen Lilith",
        "full": "portrait of queen lilith, horror art by toha mashudi and beksinski and szukalski and giger and and pyromallis and dzo and iris compiet and seb mckinnon and, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Fantasy character portrait",
        "full": "bob ross, fantasy character portrait, ultra realistic, intricate, elegant, highly detailed, digital painting, artstaion, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg ninja concept art",
        "full": "concept art of cyborg ninja, dynamic pose, extremely detailed, jama jurabaev, greig fraser, roger deakins, shaddy safadi, feng shu, neil blevins, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Prima ballerina in rose garden",
        "full": "stunningly beautiful, prima ballerina in rose garden, symmetrical face, golden hour, smooth, focus, highly detailed, hyper realistic, dramatic lighting, elegant, intricate, concept art, art by wlop, mars ravelo, greg rutowski, artstation"
    },
    {
        "display": "Brutalist Martian megastructure.",
        "full": "brutalist, soviet architecture on mars, megastructure, kowloon city, hong kong, grey, intricate, ugly highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Fantasy video game artwork",
        "full": "fantasy video game concept, atmospheric lighting, high quality, sharp focus, intricate, digital art, artstation, 4k"
    },
    {
        "display": "Abandoned highway showdown.",
        "full": "a film still of a dusty abandoned highway with warring minibus taxis from mad max fury road by ian mcque, multiple overpass interchange, vibrant, 5 0 mm lens, video game character and environment design, 2 d game lineart behance hd, studio, dramatic lighting, cinematic, global illumination, trending on artstation, bloom"
    },
    {
        "display": "Raccoon in hot rod",
        "full": "cute and funny, racoon wearing a helmet riding in a tiny hot rod 2 0 0 2 plymouth prowler with oversized engine, ratfink style by ed roth, centered award winning watercolor pen illustration, isometric illustration by chihiro iwasaki, edited by range murata, details by artgerm"
    },
    {
        "display": "Baby Grot and Yoda Portrait",
        "full": "Baby Grot and Yoda profile picture by Greg Rutkowski, asymmetrical, Organic Painting , Matte Painting,  geometric shapes, hard edges, street art, trending on the artstation:2 by Sachin Teng:4"
    },
    {
        "display": "Asuna in winter setting",
        "full": "photo of asuna from sao in winter location, asuna by a - 1 pictures, by greg rutkowski, gil elvgren, enoch bolles, glossy skin, pearlescent, anime, maxim magazine, very coherent, film still, dslr, 3 5 mm canon"
    },
    {
        "display": "Dancing male model, cyberpunk.",
        "full": "Ultra realistic full shot of a male model boisterously dancing around the room by himself holding an empty wine bottle as he jumps in the air, cyberpunk, sci-fi, fantasy, Kodak colour led, soft light, volumetric lighting ,night, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime-style baroque portrait",
        "full": "baroque oil painting anime key visual character medium shot full body portrait concept art, young maid nazi secret police, flowing blonde hair blue eyes, nazi black leather uniform, red banner background, kuudere noble fascist grimdark fantasy dictatorship, trending pixiv fanbox, rule of thirds golden ratio, by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Cute dog-baked beans fusion",
        "full": "painting of a very cute dog fused with british baked beans, dog is made of baked beans, baked bean skin texture, by zdzislaw beksinski, by dariusz zawadzki, by wayne barlowe, gothic, surrealism, cosmic horror, lovecraftian, cold hue's, warm tone gradient background, concept art, beautiful composition"
    },
    {
        "display": "Crazy Yoda on drug trip",
        "full": "a highly detailed portrait of a crazy laughing Yoda on a bad ketamine drug trip, drugs and alcohol littered everywhere, descent into lunacy and madness, dizzy, trippy, artstation, cgsociety, very detailed, intricate, detailed illustration, by artgerm and greg rutkowski and alphonse mucha, sharp, smooth, masterpiece"
    },
    {
        "display": "Lionel Messi in fantasy attire",
        "full": "lionel messi wearing islamic clothes, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sharpio art concept",
        "full": "sharpio, trending on artstation"
    },
    {
        "display": "Islamic Space Marine Battle",
        "full": "epic art of islamic style space marine on a war, with beautiful mosques, warhammer 40000 space marine, cinematic light, painting by jeremy geddes, trending on artstation, ultrarealistic concept art, illustration, 4k, high quality"
    },
    {
        "display": "Cute retro robot dog.",
        "full": "a detailed illustration of a cute retro robot dog, alcohol inks on parchment, muted colors, detailed, textured parchment background, bosch, 1 9 5 0 s concept art"
    },
    {
        "display": "Angry zombie portrait, horror.",
        "full": "highly detailed portrait of black michael jackson as a angry zombie, gaping wounds, horror bloody, stage in a small club in the background, fine art, award winning, intricate, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by guweiz and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Psychedelic Tzeentch artwork",
        "full": "psychedelic flamer of tzeentch, trending on artstation"
    },
    {
        "display": "Happy boy with cat features",
        "full": "boy with cat ears and tail, full body, blushing, happy, short smile, hair covering eyes, cinematic lightning, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan"
    },
    {
        "display": "Dramatic thundercloud at sunset",
        "full": "a beautiful picture of a dark distant thundercloud lit from below in a desert, intricate detail, impending doom, sunset, serene, volumetric lighting, volumetric clouds, 8 k, hyperrealistic, digital art trending on artstation"
    },
    {
        "display": "Young Richard Dean Anderson, post-apocalyptic setting",
        "full": "portrait of a young richard dean anderson wearing a green combat uniform, in a post appocalyptic city overgrown by plants, by wlop, by luis royo, cover illustration, concept art, volumetric lighting, volumetric atmosphere, sharp focus, octane render, trending on artstation, 8 k"
    },
    {
        "display": "Surreal night landscape illustration.",
        "full": "families of giant deformed distorted crushed flea trees robots ancient moon volcanoes misty fog smog night landscape and candelabras dramatic shadows by Salvador Dali and HR Giger and Zdzis\u0142aw Beksi\u0144ski and Yves Tanguy and Sidney Nolan photorealistic finely detailed high resolution black ink illustration drawing discharge gushing heavy oozing dripping sweating unreal engine trending artstation by Stephen Gammell and HR Giger 4k canon 5d mk4,"
    },
    {
        "display": "Corn patching space station",
        "full": "patching up a whole in the space station with corn, hyper realism, cinematic, dramatic ambient lighting, high detail, octane render, unreal engine, 8 k, vibrant colors, high contrast, depth of field, professional photo, concept art, dnd, 3 d render, digital art, deviantart artstation, ray tracing, apocalyptic, extremely detailed, very sharp,"
    },
    {
        "display": "Cute girl with AK-47",
        "full": "anime!!! a cute girl wearing a school uniform and holding a ak - 4 7. symmetry face. realistic shaded lighting poster by ilya kuvshinov, katsuhiro otomo, trending on artstation, magali villeneuve, artgerm, jeremy lipkin and michael garmash"
    },
    {
        "display": "Elegant forest queen artwork",
        "full": "beautiful green pink bling bling of the queen of forest, complicated vines branches and bling bling flowers in naturalism headwears, dark fantasy, intricate, elegant, highly detailed, digital painting, artstation, highly saturated colors, concept art, matte, 3 d 8 k octane rendered, sharp focus, illustration, art by artgerm and alphonse mucha, leesha hannigan"
    },
    {
        "display": "Male warrior in red armor",
        "full": "male warrior, blood red armor, giant axe, messy curly hair, unkempt beard, dark brown skin, ready to fight, realistic, smooth, sharp focus, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "\"Chaotic evil teenager portrait\"",
        "full": "beautiful pure evil teenager korean lain, cute but erratic haircut, with hundreds of network cables, neatly coming out of her head, a part of her face panel is showing, she is in pure bliss, chaos, bizarre, strange, portrait, painting, soft and intricate, fine lines, face is breaking like a porcelain doll, insanity by beautiful, detailed symmetrical close up portrait, intricate complexity, in the style of artgerm and ilya kuvshinov, cel shaded"
    },
    {
        "display": "Symmetrical Yoda portrait.",
        "full": "symmetry!! portrait of yoda, sci - fi, tech wear, blue and yellow glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mad Max-inspired car artwork",
        "full": "concept design mad max cars rocket league, art gta 5 cover, official fanart behance hd artstation by jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, ossdraws, that looks like it is from borderlands and by feng zhu and loish and laurie greasley, victo ngai, andreas rocha, john harris"
    },
    {
        "display": "Anthropomorphic triangle brain monster",
        "full": "anthropomorphic triangle brain in edgy darkiron terror bird, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, dwayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Fantasy castle landscape",
        "full": "a wholesome animation key shot of a huge fantasy castle in the distance amidst massive island cliffs with forest and fields, epic mountains with waterfalls, high detail, mononoke, breath of the wild, award winning, animation key frame, cinematic shot, studio ghibli, in the style of hayao miyazaki and greg rutkowski and loish, rhads, ferdinand knab, makoto shinkai and andreas rocha and lois van baarle and ilya kuvshinov and rossdraws and tom bagshaw"
    },
    {
        "display": "Antique tribal face mask",
        "full": "concept art by jama jurabaev, face mask of antique tribe, fine - face, cinematic shot, trending on artstation, high quality, brush stroke,, vibrant colors, red eyes"
    },
    {
        "display": "Ancient Chinese beauties illustration.",
        "full": "ancient chinese beauties with beautiful eyes, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha and victo ngai"
    },
    {
        "display": "Heroic battle scene art",
        "full": "leonidas rushing into battle, heroic, glorious, in the style of artgerm, gerald brom, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Symmetrical portrait of Durruti.",
        "full": "symmetry!! stunning portrait of buenaventura durruti!! by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Turtle fighter portrait, fantasy.",
        "full": "portrait of a Turtle fighter looking at camera, D&D, shield and iron war hammer, stylish armor, fantasy, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, stunning lighting, art by artgerm and greg rutkowski and alphonse mucha and simon stalenhag"
    },
    {
        "display": "Car engine concept art",
        "full": "car engine car parts concept art, cards, comic page, realistic fortnite, ui cards"
    },
    {
        "display": "Beautiful female wizard portrait",
        "full": "an anthropomorphic beautiful female wizard portrait holding a broomstick wearing colourful robe, round glasses, curly hair, fine art, award winning, intricate, elegant, sharp focus, octane render, hyperrealistic, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by jamie hewlett and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Cinematic portrait of Kate Waynesboro",
        "full": "cinematic portrait of Kate Waynesboro from the DC universe, only head and chest, intricate, desaturated, Tim Hildebrandt, Wayne Barlowe, Bruce Pennington, donato giancola, larry elmore, maxfield parrish, Moebius, Thomas Ehretsmann, oil on canvas, gouache painting, masterpiece, trending on artstation, cinematic composition, dramatic pose, volumetric lighting, sharp, details, hyper-detailed, HD, 4K, 8K"
    },
    {
        "display": "Anime woman in forest",
        "full": "3D advanced digital art, a very cute and gorgeous anime woman wearing a dress made of vines and Fire + full body, very long green hair+braided hair, emerald green eyes, standing in a forest setting, full round face :: cinematic lighting, mid-shot, highly intricately detailed, trending on pixiv :: Steven Artgerm Lau, WLOP, RossDraws, RuanJia , James Jean, Andrei Riabovitchev, Totorrl, Marc Simonetti, Visual Key, and Sakimichan"
    },
    {
        "display": "Minimalist dreamlike art",
        "full": "minimalist dimensions shift warp concept art oil painting by john berkey, highly detailed, brush hard, dreamlike"
    },
    {
        "display": "Anthropomorphic green apple character.",
        "full": "a digital painting of a beautiful anthropomorphic humanoid female green apple wearing a red dress, by netter, style from greg rutkowski, beautiful eyes, full frame, oil painting, featured on artstation, concept art, smooth, sharp focus, illustration, very detailed, ambient lighting, unreal engine render, concept art by Atey Ghailan, by Loish, by Bryan Lee O'Malley"
    },
    {
        "display": "Cyberpunk fairy in neon garden",
        "full": "a beautiful cyber fairy in a cyberpunk garden with neon flowers, elegant pose, realistic digital painting, artgerm, sakimichan, huang guangjian"
    },
    {
        "display": "Warrior robot astronaut",
        "full": "a warrior robot astronaut, floral! looks like a machine from horizon zero dawn, posing for a fight intricate, elegant, highly detailed, digital painting, establishing shot, an epic fantasy, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Emperor Secret Society Concept Art",
        "full": "concept art by jama jurabaev, emperor secret society, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Ultra-realistic painting of Mariah Carey in bikini",
        "full": "sexy painting of mariah carey, bikini, ultra realistic, sharp details, subsurface scattering, intricate details, warm lighting, beautiful features, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Alluring female Viking portrait",
        "full": "a portrait of a beautiful alluring female viking, detailed, centered, digital painting, artstation, concept art, donato giancola, Dante Gabriel Rossetti, alphonse mucha, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Annie Leibovitz and Steve McCurry, David Lazar, Jimmy Nelsson, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Goddess in fiery art nouveau.",
        "full": "breathtaking detailed soft painting of one goddess with crown and spear, gauze dress of fireflies and art nouveau crown of embers, viking culture, rembrandt style, detailed art nouveau stained glass of flames, christian saint rosace, elegant, highly detailed, artstation, concept art, matte, sharp focus, art by Tom Bagshaw, Artgerm and Greg Rutkowski"
    },
    {
        "display": "Dynamic Mercy figurine",
        "full": "still figurine of mercy from overwatch, statue, personification, dynamic pose, amazing details, detailed product photo, official art, featured on pixiv, 8 5 mm, f. 1 4, beautiful composition, anime"
    },
    {
        "display": "Rococo Vaporwave Portrait",
        "full": "rococo vaporwave Dan Mumford style portrait of Louis xv in the style of Rob Lefield and Dan Mumford Dan Mumford Dan Mumford, Dan Mumford, Greg rutkowski, trending on artstation, digital art, surrealism, macro"
    },
    {
        "display": "Anime girl programming",
        "full": "anime girl with white hair and blue eyes, wearing cat ear headphones, sitting at desk at keyboard, programming, cute, pixiv, anime"
    },
    {
        "display": "Knitted Mufasa at sunset",
        "full": "a photorealistic picture of a knitted smiling Mufasa lion wearing blue sunglasses and a beanie cap at the beach during sunset Trending on Artstation, featured on Behance, well-rendered, intricate, highly detailed, very crispy, Unreal Engine, 4K HD"
    },
    {
        "display": "Halloween suburban monoliths painting.",
        "full": "halloween scene in a suburban neighborhood, surrounded by ancient monoliths with glowing runes. highly detailed science fiction painting by norman rockwell, frank frazetta, and syd mead. rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation"
    },
    {
        "display": "Cyberpunk portrait of Jennifer Aniston",
        "full": "hyperdetailed portrait of a stunningly beautiful cyberpunk jennifer anniston with short dark hair guard made of iridescent metals and shiny pink gems, bright rainbow nimbus, gold necklace, gold background inspired by ross tran and masamune shirow and kuvshinov, intricate, photorealistic, octane render, rtx, hdr, unreal engine, dnd digital art by artgerm"
    },
    {
        "display": "Cat seahorse humanoid fursona",
        "full": "cat seahorse fursona wearing headphones, autistic bisexual graphic designer and musician, attractive androgynous humanoid, full body portrait of a highly coherent detailed character design, weirdcore voidpunk mythpunk digital art by artgerm, akihiko yoshida, louis wain, wlop, noah bradley, furaffinity, cgsociety, trending on artstation, trending on deviantart"
    },
    {
        "display": "Evil sorcerer in library.",
        "full": "evil male sorcerer, library background, the room filled with colorful magic, red robe, epic, white skin, young, sharp, brown hair, beard, concept art, digital art, dynamic lighting, unreal engine, octane, in the styles of greg rutkowski, svetlin velinov and frank frazetta"
    },
    {
        "display": "Elegant organic cyborg illustration",
        "full": "organic cyborg, 2097, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Lifelike ancient queen illustration",
        "full": "ancient queen marie curie, symetrical, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, 4 k, smooth, sharp focus, art by john collier and albert aublet and krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Symphogear manga cover",
        "full": "Symphogear pixiv key visual manga cover"
    },
    {
        "display": "Hercules statue in vaporwave style",
        "full": "black background, statue of hercules, beeple, vaporwave, retrowave, glitch, pixel sorting, strong contrast, pinterest, trending on artstation"
    },
    {
        "display": "Fantasy dragonborn paladin.",
        "full": "aurix dragonborn paladin of the sun god, fantasy, d & d, trending on artstation"
    },
    {
        "display": "Beautiful female elf portrait",
        "full": "an incredibly beautiful female elf from lord of the rings, full shot, atmospheric lighting, detailed face, one piece style, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Cyberpunk witches coven scene.",
        "full": "a cyberpunk witches coven, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Cute fantasy cloud creature",
        "full": "cutest huge fantasy cloud animal, with sprouting rainbow hair, hd, japanese anime artist drawn, dlsr, dream animal cute eyes, trending on artstation, cotton candy, octane render, cinematic, sitting on a hand, flof"
    },
    {
        "display": "Medusa Gorgon portrait, digital art",
        "full": "portrait of medusa gorgon. intricate, centered, amazing composition by amano yoshitaka, by rembrandt, illustrious makinami, digital art, digital painting, artstation trending, unreal engine, fractal flame, transparent jellyfish, transparent feathers, bio luminescent, amethyst"
    },
    {
        "display": "Demonic warlock portrait.",
        "full": "djinn man male demon, portrait, full body purple cloak, character concept art, costume design, illustration, white horns from eyebrows, editorial photo, warlock, hyperrealism, trending on artstation, Charlie Bowater, WLOP"
    },
    {
        "display": "Lemon fantasy character portrait.",
        "full": "a portrait of a lemon fantasy character portrait, ultra realistic, cinematic, concept art, wide angle, intricate details, hologram, highly detailed by greg rutkowski, aaron horkey, gaston bussiere, craig mullins, simon bisley, arthur rackham"
    },
    {
        "display": "Elegant dark elf countess portrait",
        "full": "elegant, beautiful dark elf countess, dark fantasy, rococo style portrait, wearing dark velvet victorian dress, stylised big eyes, large forehead, elf ears, button nose, ornamental, neoclassical, oil painting on canvas, masterpiece, by federico de madrazo y kuntz, boichi, artgerm"
    },
    {
        "display": "Symmetrical alien portrait",
        "full": "symmetry!! portrait of post apocalypse nature alien in the style of horizon zero dawn, machine face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Young Icarus portrait with wings",
        "full": "digital character concept art by paul berthon and alphonse mucha, portrait of a young 1 2 years old icarus a god, twelve years old, arms spread wide looking skyward as if to fly, large mechanical bird wings, beautiful, smooth body, night time, light effect, clouds, stars, glowing lanterns, detailed, poster art, lithograph, hyper detail"
    },
    {
        "display": "Colorful pigments in water",
        "full": "color pigments spread out in water, dream, unreal 5, trending on artstation"
    },
    {
        "display": "Fantasy summer scene with corgi",
        "full": "beautiful fantasy painting scene of anime chill summer day with corgi, by Kenne Gregoire, James Jean, Tran Nguyen, WLOP, Jakub Rebelka. trending on Artstation, 8k, masterpiece, face enhance, graffiti paint, fine detail, full of color, intricate detail, golden ratio illustration"
    },
    {
        "display": "Post-apocalyptic New York concept.",
        "full": "the post - apocalyptic street of new york, concept art by makoto shinkai and greg rutkowski, matte painting, trending on artstation"
    },
    {
        "display": "Steampunk woman in bedroom",
        "full": "woman model, steampunk clothes, unedited colors, in a bedroom!!!!!!!!!!!!!!!!!!!!, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Anime Portrait of Nietzsche",
        "full": "Anime Portrait of Friedrich Nietzsche in his Suit, chiseled Jawline and serious Look, in the Style of Artgerm and Ross Draws and Mike Mignola and Cory Loftis, vibrant colors, rim light, hard shadows, colorful, plain background, trending on artstation"
    },
    {
        "display": "Venice Street Matte Painting",
        "full": "A highly detailed matte painting of venice street with a bright shimmering sky by Studio Ghibli, Mokoto Shinkai, by Artgerm, by beeple, volumetric lighting, octane render, 4K resolution, trending on artstation, vivid colours"
    },
    {
        "display": "Hyperrealistic Biden-Gollum painting",
        "full": "hyperrealistic mixed media high resolution painting of Joe Biden Gollum from Lord of the Rings, stunning 3d render inspired art by Jamie Salmon and Istv\u00ef\u00bf\u00bdn S\u00ef\u00bf\u00bdndorfi and Unreal Engine and Greg Rutkowski, perfect facial symmetry, realistic flesh, dim volumetric lighting, 8k octane beautifully detailed render, full body shot, post-processing, extremely hyper-detailed, intricate, epic composition, highly detailed attributes, highly detailed atmosphere, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, flawless completion, lifelike texture, perfection,"
    },
    {
        "display": "Detailed happy mask salesman.",
        "full": "happy mask salesman from zelda, full body photo,, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and wlop!!!!!!!"
    },
    {
        "display": "Hyperrealistic fantasy portrait.",
        "full": "a hyperrealistic acrylic portrait painting of morpheus by artgerm, beksinski and thomas kinkade. intricate details. believable eyes. tattoos on face. epic fantasy art. a full body portrait of chinese buddhist mythology, manga cover titled, oimmortal beast from chinese mythology"
    },
    {
        "display": "Hatsune Miku figurine.",
        "full": "still figurine of hatsune miku wearing an elegant summer blouse, emerald green eyes, statue, personification, dynamic pose, amazing details, detailed product photo, official art, featured on pixiv, beautiful composition, anime"
    },
    {
        "display": "Monstrous angels descending.",
        "full": "monstrous angels descending from the sky as people praise them, digital painting, mixed media, trending on artstation and deviantart, epic composition, highly detailed, 8 k"
    },
    {
        "display": "Ultra-realistic Lovecraftian cityscape",
        "full": "a painting of arkham city, ultra realistic, concept art, intricate details, lovecraftian, eerie, highly detailed, full coloured, dark fantasy, photorealistic, octane render, 8 k, unreal engine 5. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Wanny Difillipo portrait at sunset",
        "full": "portrait of wanny difillipo, sunset, gorgeous view, depth, painted by seb mckinnon, high detail, digital art, painted by greg rutkowski, trending on artstation"
    },
    {
        "display": "Cute boy dreaming fantasy",
        "full": "a beautiful illustration of a little cute boy in his bed dreaming about a beautiful, fantasy, intricate, epic lighting, cinematic composition, hyper realistic, 8 k resolution, unreal engine 5, by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, marc simonetti, artstation"
    },
    {
        "display": "Cyberpunk female portrait.",
        "full": "photorealistic an european female humanoid with heavy freckle cheeks and braided hairstyle, cyber neon lightings, futurism, cyberpunk high fashion, glamor profile pose, intricate details, crispy quality, digital photography, trending in artstation, trending in pinterest, no watermark signature, cinematic, 4 k ultra hd, art by artgerm, art by greg rutkowski, art by pascal blanche,"
    },
    {
        "display": "Cyberpunk androgynous wizard portrait",
        "full": "hyperdetailed close portrait of a stunningly beautiful cyberpunk androgynous woman wizard made of iridescent metals and shiny pink gems, bright rainbow nimbus, gold necklace, gold background inspired by ross tran and masamune shirow and kuvshinov, concept art, intricate, photorealistic, octane render, rtx, hdr, unreal engine, dnd digital art by artgerm fine - face, pretty face"
    },
    {
        "display": "Anime girl in Rococo fashion.",
        "full": "concept art, artstation, anime 3 d art, wlop art, realistic alice cute girl painting, japanese street fashion, hyper realism, muted colours, rococo, natalie shau, anime, tom bagshaw style"
    },
    {
        "display": "Symmetrical sci-fi portrait.",
        "full": "symmetry!! portrait of luke skwyalker, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, green mist background"
    },
    {
        "display": "Symmetrical sphinx portrait.",
        "full": "symmetry! portrait of a sphinx, face decorated with opera motifs, leds horizon zero dawn machine, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Young male magician, fantasy art",
        "full": "young male magician, golden hair, 1920, fantasy, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, art by Artem Demura and Alphonse Mucha, ArtGerm, Valentina Remenar, Gaston Bussiere, Cedric Peyravernay"
    },
    {
        "display": "Hyperrealistic hippy woman portrait",
        "full": "full body pose, hyperrealistic mixed media painting of a beautiful attractive hippy woman, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Gothic god portrait with raven",
        "full": "portrait of a goth god and black raven by Neil Gaiman, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and francisco goya"
    },
    {
        "display": "Minimalistic bonsai apple tree",
        "full": "bonsai fruit tree with apples!! but minimalistic concept art by frank stella gilleard james whalen tom, colorful, soft light, trending on artstation, minimalism"
    },
    {
        "display": "Elegant women in bathhouse.",
        "full": "bathhouse, women, light, shadows, reflections, epic composition, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, wlop, artgerm, ruan jia, steve mccurry"
    },
    {
        "display": "Elegant black woman with gold makeup.",
        "full": "Beauty woman black skin color body art, gold makeup lips eyelids, fingertips nails in gold color paint. Professional gold makeup, fantasy magic, undercut hairstyle, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Medieval Hobbit Home Illustration",
        "full": "inside a medieval hobbit home, ornate, beautiful, atmosphere, vibe, mist, smoke, chimney, rain, well, wet, pristine, puddles, red speckled mushrooms, waterfall, melting, dripping, snow, creek, lush, ice, bridge, cart, orange, green, stained glass, forest, flowers, concept art illustration, color page, 4 k, tone mapping, doll, akihiko yoshida, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, digital illustration, greg rutowski, volumetric lighting, sunbeams, particles, trending on artstation"
    },
    {
        "display": "Bodybuilder with puppy portrait",
        "full": "gigachad ron swanson bodybuilder holding a cute puppy in final fight mountain by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Demonic angel portrait",
        "full": "symmetry!! portrait of demonic angel with black wings, sci - fi, dramatic lighting, beautiful, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Angry man portrait, Star Wars",
        "full": "portrait of a man by greg rutkowski, jacen solo wearing black robes, angry but resigned attitude, star wars expanded universe, he is about 3 0 years old, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Futuristic fairy portrait.",
        "full": "portrait of a futuristic fairy, science fiction, smooth technology, smooth body, fairy wings, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "3D robot with lightning.",
        "full": "robot, lightning around, sparkles, 3d render, octane render, trending on artstation, high details. centered"
    },
    {
        "display": "Capybara in sunglasses at sunset",
        "full": "a photorealistic photograph of a knitted cute Capybara wearing sunglasses and dressed in blue beanie cap. The subject is also sipping a refreshing Mai Tai cocktail drink right outside of the Sydney Opera House during sunset. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and is in the style of Unreal Engine."
    },
    {
        "display": "Elegant runway show illustration",
        "full": "victoria secret runway show, light, shadows, reflections, flowers, epic composition, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, wlop, steve mccurry, artgerm, mina petrovic, timothy kong, marina federovna, artstation, masterpiece, iconic"
    },
    {
        "display": "Dapper squirrel bartender",
        "full": "squirrel anthro as a dapper bartender with a big, fluffy tail, retro futurism, art deco, detailed, painterly digital art by WLOP and Cory Loftis and Hans Baluschek, \ud83d\udc3f\ud83c\udf78\ud83c\udf4b, furaffinity, trending on artstation"
    },
    {
        "display": "Adorable glowing alien concept.",
        "full": "adorable glowing alien, trending on artstation, cute, big eyes, matte painting, concept art, pixar, disney, highly detailed, cinematic composition, unreal engine, 3 d rendered in octane"
    },
    {
        "display": "Katarina, hyper-detailed digital art",
        "full": "katarina, from league of legends, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, fluorescent skin, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Tito Portrait, Fantasy Art",
        "full": "portrait of josip broz tito in yugoslavia, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute young female Jedi portrait.",
        "full": "a portrait of a cute young female jedi, star wars setting, vivid colors, soft lighting, atmospheric, cinematic, moody, in the style of artgerm and greg rutkowski, oil on canvas, 8 k"
    },
    {
        "display": "Futuristic sailboat adventure.",
        "full": "futuristic sailboat mid-journey with solar sails and a robot! captain! and crew in a Marvel!!!!!!!! comic! style , digital art, 8k, birds eye view, 80s, concept, Smooth gradients, octane render, 8k, High contrast, duo tone, depth of field"
    },
    {
        "display": "Cute cartoon chipmunk illustration",
        "full": "orange cute cartoon chipmunk, digital painting, simplified, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Soldier with Ukrainian flag.",
        "full": "special forces soldier raising ukrainian blue and yellow flag st. basil cathedral in the background, masculine figure, d & d, fantasy, bright atmosphere, volumetric lights, intricate, elegant, extremely detailed, digital painting, artstation, concept art, matte, smooth, sharp focus, hyper realistic, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "VFX portrait: Kaho Shibuya as Tifa",
        "full": "highly detailed vfx portrait of kaho shibuya cosplaying as tifa lockhart by eiichiro oda, makoto shinkai, alphonse mucha, sharp focus, art by artgerm and greg rutkowski!, backlit, harsh overhead sunlight, blue eyes!!, aquiline nose!!, stanley kybric, kaoru mori, hyper detailed, smooth pixiv, fanbox,"
    },
    {
        "display": "Intricate magical staff design",
        "full": "A magical staff viewed from the outide, texture, intricate, details, highly detailed, masterpiece, architecture, building, trending on artstation, focus, sharp focus, concept art, digital painting, fantasy, sunny, day, midday, in the style of Wizards of the Coast"
    },
    {
        "display": "Eerie photorealistic homeloaf.",
        "full": "Homeloaf, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8k, unreal engine, art by artgerm and Blaz Porenta"
    },
    {
        "display": "\"Cosmic beauty witnessing explosion\"",
        "full": "A beautiful woman with long, flowy hair standing on a rock, witnessing the explosion of a bulging, corrupted star in space. trending on artstation, artstation futurism, artstation photography, subsurface scattering, analog photography, 4k, 8k"
    },
    {
        "display": "Psychedelic vaporwave vortex.",
        "full": "a netrunner vortex, vaporwave aesthetic, colorful, psychedelic, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute magical monster portrait.",
        "full": "A full shot of a cute magical monster Cryptid wearing an ornate dress made of opals and tentacles. Subsurface Scattering. Translucent Skin. Caustics. Prismatic light. defined facial features, symmetrical facial features. Opalescent surface. Soft Lighting. beautiful lighting. By Giger and Ruan Jia and Artgerm and WLOP and William-Adolphe Bouguereau and Loish and Lisa Frank. Sailor Moon. Masterpiece. trending on artstation, featured on pixiv, award winning, cinematic composition, dramatic pose, sharp, details, Hyper-detailed, HD, HDR, 4K, 8K."
    },
    {
        "display": "Brad Pitt gymnastics Olympic action",
        "full": "brad pitt as a gymnast, competing at olympics, action shot, award - winning photography, 8 k, trending on artstation"
    },
    {
        "display": "Warrior robot astronaut",
        "full": "a warrior robot astronaut, holding a hi - tech sword, looks like a machine from horizon zero dawn designed by apple, in socotra island, posing for a fight, intricate, elegant, highly detailed, digital painting, establishing shot, an epic fantasy, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, 8 k"
    },
    {
        "display": "Rugged wizard portrait",
        "full": "realistic detailed face portrait of a mature rugged handsome wizard in ornate leather armor by alphonse mucha, ayami kojima, amano, greg hildebrandt, and mark brooks, male, masculine, art nouveau, neo - gothic, gothic, character concept design"
    },
    {
        "display": "Elegant portrait of Cruella.",
        "full": "realistic photography portrait of crudelia de mon with thin white silk roses, intricate, elegant, highly detailed, wonderful eyes, sweet, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Cosmic Shrek underwater portrait.",
        "full": "underwater portrait of shrek as the cosmic space marine disney princess, naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Purple-haired woman portrait",
        "full": "Purple hair relistic closeup Portrait of a woman with bright colored flying hair, all shades of purple. Beauty face, Hair coloring, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Eleanor Tomlinson in Tudor fashion",
        "full": "eleanor tomlinson posing in tudor fashion, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Epic Last Supper Illustration",
        "full": "the last supper beautiful painting by artgerm and greg rutkowski and alphonse mucha, highly detailed, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, only mens"
    },
    {
        "display": "Symmetrical sci-fi skull portrait",
        "full": "symmetry!! portrait of a skull, sci - fi, tech wear, blue and yellow glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant female portrait.",
        "full": "a gorgeous female photo, professionally retouched, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, wide angle, sharp focus on eyes, 8 k high definition, insanely detailed, intricate, elegant, artgerm and jason chan and mark litvokin"
    },
    {
        "display": "Fantasy portrait hybrid.",
        "full": "a fantasy style portrait painting of rachel lane / brie larson hybrid in the style of francois boucher oil painting unreal 5 daz. rpg portrait, extremely detailed artgerm greg rutkowski alphonse mucha"
    },
    {
        "display": "Detailed Plague Doctor Portrait",
        "full": "Plague Doctor, dark fantasy, portrait, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Princess Mechine Manga Poster",
        "full": "highly detailed ayami kojima artgerm terada katsuya artstation manga poster of princess mechine, rainbow gradient reflection, long hair, armor, dress, laces, ruffles, 8 k, maximalist,, jump comics, tomer hanuka, alphonse mucha"
    },
    {
        "display": "Cursed gorilla movie poster",
        "full": "gorilla cursed by the cave crest, in the style of alan gutierrez and alexander leydenfrost, trending on artstation, moon light microscopic view colored pencil art, movie poster, symmetrical, tintype photo, oil painting"
    },
    {
        "display": "Will Ferrell in chili",
        "full": "will ferrell bathing in a bowl of chili, cosmic horror painting, elegant intricate digital painting artstation concept art by mark brooks and brad kunkle detailed"
    },
    {
        "display": "Rusty dreadnought shipwreck in forest",
        "full": "rusty warship dreadnought shipwreck in a lush forest, sci-fi, ivy, moss, trending on artstation, concept art by Greg Rutkowski and Sebastian Luca, global illumination"
    },
    {
        "display": "Ultra-realistic kawaii anime girl",
        "full": "anime girl kawaii, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k"
    },
    {
        "display": "Kaiju in New York",
        "full": "kaiju in new york, highly detailed, d & d, volumetric fog, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap, sunny"
    },
    {
        "display": "Cyberpunk cyborg portrait",
        "full": "portrait of lana del rey as a cyberpunk cyborg. roses, sci - fi, intricate abstract, upper body, intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, 8 k octane render, deviantart, greg rutkowski, cinematic, key art, hyperrealism, iridescent accents"
    },
    {
        "display": "Goku portrait, detailed art.",
        "full": "goku, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Cyberpunk lion fursona portrait",
        "full": "award winning beautiful portrait commission of a male furry anthro lion fursona with a tail and a cute beautiful attractive detailed furry face wearing stylish black and gold cyberpunk clothes in a cyberpunk city at night while it rains. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Emerald Herald Portrait",
        "full": "a portrait of the emerald herald in the garden, intricate, tone mapped, ambient lighting, highly detailed, digital painting, concept art, sharp focus, by makoto shinkai and hidari and wlop"
    },
    {
        "display": "Fantasy barbarian portrait.",
        "full": "a _ fantasy _ style _ portrait _ painting _ of barbarian oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Muscular Leonardo in combat.",
        "full": "portrait of aggressive leonardo from teenage mutant ninja turtle, d & d, muscular! athetic bodybuilder in dynamic fighting stance, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Supergirl as She-Hulk.",
        "full": "supergirl as she - hulk by artgerm, intricate, face, symmetrical eyes, times square cityscape, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Androgynous lich with nebula eyes",
        "full": "nebula eyes, hyperdetailed stunningly beautiful androgynous lich made of iridescent metals and shiny gems, rainbow aura, golden necklace, noble perfect androgynous skeleton inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, photorealistic, octane render, rtx, hdr, unreal engine, dnd digital art by artgerm"
    },
    {
        "display": "Female Jedi Master Portrait",
        "full": "steven seagal female, jedi master, wearing the traditional jedi robe, beautiful and uniquely odd looking, detailed symmetrical close up portrait, intricate complexity, in the style of artgerm and ilya kuvshinov, magic the gathering, star wars art"
    },
    {
        "display": "Anime-style Russian girl",
        "full": "\u201c anime, japanese style russian girl, light and shadow effects, intricate, highly detailed, digital painting, art station, concept art, smooth, sharp focus, illustration, advanced digital anime art, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau, craig mullins, j. c. leyendecker, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws \u201d"
    },
    {
        "display": "Fantasy frog mantis wizard.",
        "full": "a detailed portrait inspired by rebecca guay and mtg of the anthropomorphic frog mantis wizard shaman in his muddy creek. ancient african fantasy anime movie poster in the style of riven and myst. symmetry. arcane symbolism, intricate surrealist fantasy artwork. oil on canvas. award winning. dramatic. trending on artstation. 8 k"
    },
    {
        "display": "Cybernetic horse illustration",
        "full": "Anthropomorphized cybernetic horse, D&D, fantasy, cinematic lighting, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, warm light, cozy warm tint, magic the gathering artwork, volumetric lighting, 8k, no gold, no gold colours, art by Akihiko Yoshida, Greg Rutkowski"
    },
    {
        "display": "Moody mechanic garage interior.",
        "full": "small mechanic garage interior inside an African favela, broken light from small holes in ceiling, high contrast, rusted metal sheets with chipped paint for walling, shelves, spare parts, van under construction, wide angle lens, movie shot, trending on Artstation, highly detailed, moody atmospheric, hazy, style of Nivanh Chanthara,"
    },
    {
        "display": "Cinematic cosmic artwork.",
        "full": "i will stay with you as the universe ends, intricate artwork by tooth wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render"
    },
    {
        "display": "Excalibur in moonlit lake",
        "full": "excalibur rising from the middle of a lake under a giant full moon, rippling reflections, romantic, cinematic, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by alphonse mucha and Monia Merlo and Raymond Swanland and greg rutkowski"
    },
    {
        "display": "Cute anthropomorphic fox character",
        "full": "weta disney pixar movie still macro close photo of cute anthropomorphic vulpes vulpes fulva as man in black : : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, octane render, iridescent, bright morning, anime, liosh, mucha : :"
    },
    {
        "display": "Nico Robin digital illustration",
        "full": "nico robin by eiichiro oda!, makoto shinkai, blue eyes!!, large aquiline nose!!, highly detailed, digital painting, trending on artstation, concept art, matte, sharp focus, illustration, art by artgerm, greg rutkowski, magali villeneuve, and adolphe bouguereau"
    },
    {
        "display": "Xenomorph fairy princess artwork",
        "full": "a shiny black hr geiger xenomorph fairy princess by senior concept artist josephine wall, high resolution, trending on artstation"
    },
    {
        "display": "Symmetrical throne room illustration",
        "full": "symmetry!! intense acotar cover art bakground geometrical glass in the throne room, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Enchanting fairy queen artwork.",
        "full": "beautiful beautiful fairy faerie fey fae queen highly detailed CGsociety subtle enchanting alluring magical concept art volumetric lighting subsurface scattering unreal"
    },
    {
        "display": "Stylized portrait of Madison Beer",
        "full": "altered carbon, madison beer girl portrait, made by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, beksinski artstation, cgsociety"
    },
    {
        "display": "Angela Merkel portrait, art deco.",
        "full": "smiling, happy, beautiful, intelligent, powerful, angela merkel, loving eyes, fully clothed, wise, beautiful, dramatic lighting, sharp focus, art deco patterns by stanley artgerm, retro futurism, dramatic lighting, trending on artstation, flat colour, geometric curves, gradient filter"
    },
    {
        "display": "Epic portrait of Rigoberta Mench\u00fa",
        "full": "epic portrait of tall muscular rigoberta menchu explaining, guatemla, crowd for background, detailed, digital painting, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, breathtaking, high details, extremely detailed, sincere face, establishing shot, artistic, hyper realistic, beautiful face, octane render"
    },
    {
        "display": "Arthas concept art, Graven Tung style",
        "full": "arthas in the style of graven tung, concept art"
    },
    {
        "display": "Cinematic android portrait",
        "full": "cinematic portrait of android female, faded colors, exotic alien features, cyber background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, zack snyder, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Young black superhero portrait.",
        "full": "portrait of young black super girl, short blonde Afro,sexy red lips, tall and slim figured, Brooklyn background, highly detailed black skin tight dress and rendered gold jewelry, digital art, intricate, sharp focus, Trending on Artstation, HQ, unreal engine 5, 4K UHD image, by brom, artgerm, face by Otto Schmidt"
    },
    {
        "display": "Playful rock guitarist portrait",
        "full": "Beautiful and playful rock guitarist portrait, art deco, fantasy, intricate art deco leaf designs, elegant, highly detailed, sharp focus, art by Artgerm and Greg Rutkowski and WLOP"
    },
    {
        "display": "Glowing-eyed figure gesture.",
        "full": "a person with glowing eyes making a hand gesture, concept art by zack snyder, cgsociety, symbolism, playstation 5 screenshot, reimagined by industrial light and magic, vray tracing"
    },
    {
        "display": "Pixel art pirate battle",
        "full": "Pixelart of a pirates battles in the ocean, volumetric lighting, digital pixel art, pixiv, by Aenami"
    },
    {
        "display": "Soldier atop skulls, fantasy.",
        "full": "a distant shot of a Modern Warfare soldier with blue and yellow uniform standing alone on a pile of skulls as a winner, masculine figure, D&D, fantasy, intricate, elegant, highly detailed, extremely detailed, digital painting, artstation, concept art, matte, sharp focus, symmetrical, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Easter Island head portrait.",
        "full": "easter island head with body by charlie bowater and titian and artgerm, full - body portrait, intricate, face, green rolling hills, elegant, yellow mist, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Mechanical paladin warrior portrait",
        "full": "dynamic attack position abstract portrait of a intricate glorious holy mechanical warforged character in yellow armor holding a paladin engraved great longsword drawn and carrying a big paladin shield, glowing!!!! eye!!!!!, face in focus, pit droid, epic , trending on ArtStation, masterpiece,  cinematic lighting, by Ross Tran and by Greg Rutkowski"
    },
    {
        "display": "Fierce Arikara warrior portrait.",
        "full": "Anime-style portrait of a fierce muscular Arikara warrior woman wearing tribal skins and a feathered headdress. She stands by a rocky cascading river. Noble bearing. Award winning Fantasy rpg character concept art by studio Ghibli and Audrey Kawasaki."
    },
    {
        "display": "Hybrid cat-dragon painting",
        "full": "painting of hybrid cat!!!! and ( ( ( ( dragon ) ) ) ) fused together, intercrossed animal, by zdzislaw beksinski, by lewis jones, mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Colonial marine portrait, sci-fi.",
        "full": "portrait of a colonial marine, 2 4 5 0. starcaft, starship troopers. space sci fi, mobile suit armor. digital illustration by chris foss. spaceship in the background. cold colours. oil painting, highly detailed, centered, hyperrealism, artstation, romantic, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, vincent di fate, michael whelan, john berkey, drew struzan"
    },
    {
        "display": "Insect-inspired futuristic architecture.",
        "full": "architecture based on insects, futuristic city, 3 d render, colored pencil, concept art"
    },
    {
        "display": "Illuminati ritual engraving.",
        "full": "beautiful symmetrical detailed engraving of an illuminati ritual, classical ornamental entail design, fibonacci flow, acroteria, encarpus, bead and reel, large medium and small elements, escutcheon emblem, by russ abbott, albrecht durer, artgerm, rutkowski, trending on artstation, 8 k"
    },
    {
        "display": "Intricate Terraria base design.",
        "full": "detail beautiful terraria base. detailed. rule of thirds. intricate. sharp focus. wide angle. unreal engine 8 k. detailed pixel art by victor moura james hayes jim kjexrud"
    },
    {
        "display": "Fairy winged woman illustration",
        "full": "3 / 4 view of a woman with fairy wings, confident pose, pixie character, video game genshin impact, flat hand drawing, elegant, sharp focus, illustration, concept art, matte, magali villeneuve, artgerm, anime, trending on artstation"
    },
    {
        "display": "Hybrid cat-lobster-dragon painting",
        "full": "painting of hybrid between cat & lobster & dragon, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Crowned capybara knight.",
        "full": "detailed photorealistic painting of a capybara wearing a highly detailed ornamented gold crown with diamonds, in a medieval knight armor with red cape , holding a chess piece, sharp focus in the style of ruan jia, Mandy jurgens, cinematic light, concept art, trending on artstation, ultra realistic"
    },
    {
        "display": "Half-electric character portrait.",
        "full": "half - electric jun ji hyun with cute - fine - face, pretty face, oil slick multicolored hair, perfect face, extremely fine details, volumetric lighting, dynamic background, poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey, and silvain sarrailh"
    },
    {
        "display": "Tom Holland in icy cave.",
        "full": "Tom Holland wearing a green tunic holding a small glowing red magical orb with electrical bolts emitting from it. In an icy cave. Trending on Artstation octane render ultra detailed art by Ross tran"
    },
    {
        "display": "1980s sci-fi double doors",
        "full": "a 1 9 8 0 s sci - fi double door flat texture by artgerm, photo realistic, very realistic 8 k"
    },
    {
        "display": "Steampunk lesbian wedding.",
        "full": "lesbian wedding between evil pyromancer and necromancer, steampunk, unholy, white church background, detailed face, sharp focus, highly detailed, cinematic lighting, studio quality, colorful, smooth render, unreal engine 5 rendered, octane, rendered, by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Giant lava titan concept.",
        "full": "concept art of a giant bulky lava titan, the sin of sloth, day time, foreboding, fantasy, field, trending on artstation, wayne barlowe"
    },
    {
        "display": "Melancholic rainy estate.",
        "full": "Cold Rain in the Melancholic Estate, game concept art by Akihiko Yoshida, trending on artstation and cgsociety"
    },
    {
        "display": "Athletic fantasy mage portrait",
        "full": "realistic portrait, 25 years old man :: athletic fantasy mage :: green eyes, shoulder long brown hair :: wearing a brown robe :: high detail, digital art, RPG, concept art, illustration"
    },
    {
        "display": "Poison Ivy Overwatch Portrait",
        "full": "Greg Manchess portrait painting of Poison Ivy as Overwatch character, medium shot, asymmetrical, profile picture, Organic Painting, sunny day, Matte Painting, bold shapes, hard edges, street art, trending on artstation, by Huang Guangjian and Gil Elvgren and Sachin Teng"
    },
    {
        "display": "Elegant African elder portrait",
        "full": "a painting of a elegant, well fed, smooth-chinned, long nose, African, elder with few eyebrows by Wangechi Mutu . thinker without facial hair, thoughtful, focused, visionary, calm, jovial, loving, fatherly, generous, . dramatic angle, ethereal lights, details, smooth, sharp focus, illustration, realistic, cinematic, artstation, award winning, rgb , unreal engine, octane render, cinematic light, macro, depth of field, blur, red light and clouds from the back, highly detailed epic cinematic concept art CG render made in Maya, Blender and Photoshop, octane render, excellent composition, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse."
    },
    {
        "display": "Tornado in scenic landscape",
        "full": "A tornado in a beautiful scenic landscape, nature, trees, colorful, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "\"Realistic pin-up portrait\"",
        "full": "a pin up and beautiful fashion charming dreamlke jennifer connelly, symmetrical face symmetrical eyes, character art, art by artgerm lau and wlop and and ilya kuvshinov and john singer sargent, hyperdetailed, 8 k realistic, symmetrical, frostbite 3 engine, cryengine, dof, trending on artstation, digital art"
    },
    {
        "display": "Detailed portrait of Jacen Solo.",
        "full": "portrait of a man by Greg Rutkowski, Jacen Solo from the Star Wars Expanded Universe, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ"
    },
    {
        "display": "Angry anime archer warrior",
        "full": "anime girl with a bow and arrow, female archer, angry, warrior, realistic, fire emblem heroes archery japanese cartoon woman, artstation trending, concept art, digital painting"
    },
    {
        "display": "Skinless polar bear with membership card",
        "full": "polar bear without skin or eyes holding costco membership card. oil painting, digital art, illustration, wide angle, hyper detailed, cinematic, 4k, concept art"
    },
    {
        "display": "Trevor Philips gaming illustration",
        "full": "trevor philips plays elden ring on a dish washer, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk male portrait",
        "full": "closeup portrait shot of a cyberpunk male in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Serene Japanese garden sculptures",
        "full": "beautiful sculptures in a serene japanese garden, part by michaelangelo, part by james jean, part by ross tran, part by jacek yerka, part by leslie zhang, surreal, highly detailed, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic, volumetric cinematic light, chiaroscuro"
    },
    {
        "display": "Ominous jester character portrait",
        "full": "front shot of a court jester character ominous intricate fractal hyper cubes platinum cracked glass dark future metallic photorealistic holography crystal shard lit edges poly extraordinary detailed robotic concept artstation sharp focus ray tracing cinematic masterpiece fine portrait"
    },
    {
        "display": "Ultra-realistic Nicki Minaj portrait.",
        "full": "nicki minaj curvy, ultra realistic full body portrait, concept design, contrast, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect : : walking away"
    },
    {
        "display": "Cyberpunk female SWAT officer",
        "full": "anime key visual of a young female swat officer, neon, red gas mask, cyberpunk, futuristic, stunning, highly detailed, digital painting, artstation, smooth, soft focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk Lynx Fursona Portrait",
        "full": "wide angle beautiful full body portrait of a strong female anthropomorphic anthro lynx fursona wearing a steampunk leather. from behind, character design by disney, anime, manga, charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, soft lighting, rendered in octane, white fur, white face, lynx facial features"
    },
    {
        "display": "Retro sci-fi forest scene",
        "full": "cinematic view of a retro scifi forest, people, desaturated, psychedelic, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Joyful rugged pirate portrait",
        "full": "portrait of a young ruggedly handsome but joyful pirate, male, masculine, upper body, red crimson hair, long flowing hair, fantasy, wide grin, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Photorealistic portrait of Aang.",
        "full": "highly detailed full body portrait Aang the Last Airbender, concept art, digital art, studio lightning, bright colors, intricate, masterpiece, photorealistic, hiperrealistic, sharp focus, high contrast, intricate, Artstation HQ, DeviantArt trending, 4k UHD, Unreal Engine 5"
    },
    {
        "display": "Barack Obama as Super Mario",
        "full": "barack obama face as super mario, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gorgeous Japanese girl, dream shadows.",
        "full": "gorgeous famous asian japanese girl dropping the shadow of her desires on the wall of empty house, melting merging bodies with a light from a window creating dreams, style of James Jean, Edward Hopper, Francis Bacon, colors of Mark Rothko, Frank Auerbach, trending on artstation, Greg Rutkowski, dark atmosphere"
    },
    {
        "display": "Futuristic floral mecha design",
        "full": "futuristic nymphaea themed mecha waterlily flower for upper body, floral knees, over - under shot, rococo, sci - fi movie, cinematic compositions, highly detailed, nymphaea, 8 k hd resolution, sazabi, biomechanical, bandai box art, zaku, by beeple, cyril rolando, zaha hadid, artgerm"
    },
    {
        "display": "Tibetan Book of the Dead Comic Cover",
        "full": "Tibetan Book of the Dead, in the Style of Artgerm and Charlie Bowater and Atey Ghailan and Mike Mignola, vibrant colors and hard shadows and strong rim light, Comic Cover Art, plain background, trending on artstation"
    },
    {
        "display": "Elegant empress portrait.",
        "full": "a beautiful empress portrait, with a brilliant, impossible striking shiny big gold headpiece, gold clothes, rococo, baroque, jewels, asian, realistic, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, studio lighting, 8k, concept art, matte, sharp focus, illustration, art by     Gustav Klimt, Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Scarlett Johansson as final girl",
        "full": "scarlett johannson as the final girl, slasher, horror, high details, intricate details, by vincent di fate, artgerm julie bell beeple, 1 9 9 0 s, inking, vintage 9 0 s print, screen print"
    },
    {
        "display": "Grumpy Cat Zombie Meme",
        "full": "Grumpy Cat Meme  ZOMBIES EAT BRAINS ? MOST OF YOU HAVE NOTHING TO WORRY ABOUT , memes, grumpy cat, RPG Reference,  art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski, Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Medieval city downpour painting",
        "full": "an oil painting of a downpour in the middle of the street of a medieval city, moody lighting, fog, dark fantasy, by greg rutkowski, trending on artstation"
    },
    {
        "display": "Reptilian hybrid portrait",
        "full": "A portrait of (reptilian), snake eyes, slit pupils, (metallic scales), Liz Truss, human-reptile hybrid, hyperrealistic, trending on artstation"
    },
    {
        "display": "Woodcut print of redwood forest",
        "full": "a beautiful woodcut print of a redwood forest, 8 k, frostbite 3 engine, cryengine, dof, trending on artstation, digital art, crepuscular ray, art by roy l davies and tugboat printshop"
    },
    {
        "display": "Steampunk biomechanical fly.",
        "full": "steampunk cybernetic biomechanical fly with wings, 3 d model, very coherent symmetrical artwork, unreal engine realistic render, 8 k, micro detail, intricate, elegant, highly detailed, centered, digital painting, artstation, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, wlop"
    },
    {
        "display": "Syrian man in desert",
        "full": "real life photo of a Syrian man, short dark hair, goatee and moustache, blue watery eyes, full round face, short smile, serene desert setting, cinematic lightning, medium shot, mid-shot, highly detailed, trending on artstation, Unreal Engine 4k, 80mm, 85mm, cinematic wallpaper"
    },
    {
        "display": "Fantasy magical sword render.",
        "full": "the sword of the gods, sharp blade, fantasy, high - level longsowrd, magical sword, concept render, front, octane, trending on artstation. unreal engine 5."
    },
    {
        "display": "Detailed sci-fi soldier portrait",
        "full": "portrait of a man by Greg Rutkowski, Henry Cavill as a soldier from the Galactic Alliance from the Star Wars Expanded Universe, scifi, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ"
    },
    {
        "display": "Blonde musician portrait.",
        "full": "blonde wild hair man with a little beard, one black eye - patch, playing electric guitare, close - up portrait, powerfull, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Surreal skeleton in mountains",
        "full": "giant surreal skeleton bones spine half buried in beautiful mountains on exoplanet, golden hour, dream - like mysterious atmosphere, in the wastelands, dark teal tint, baroque landscape painting, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, raphael, caravaggio, greg rutkowski, beeple, beksinski"
    },
    {
        "display": "Medieval knight portrait, Art Nouveau",
        "full": "Alexandra Daddario as a medieval knight portrait, art nouveau, fantasy, intricate flower designs, elegant, highly detailed, sharp focus, art by Artgerm and Greg Rutkowski"
    },
    {
        "display": "Anime girl with rifle",
        "full": "girl holding a rifle | | very very anime!!!, fine - face, audrey plaza, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Cozy Japanese herbalist cottage.",
        "full": "interior view of a Japanese herbalist cottage, waxy candles, books, flowers, wood furnishings, light bloom, dust, ambient occlusion, rays of light coming through windows, trending on artstation"
    },
    {
        "display": "Tank on the moon.",
        "full": "a tank on the moon. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, trending on ArtStation, digital art."
    },
    {
        "display": "Photorealistic military muppet portrait",
        "full": "photo photorealism close up portrait military muppet special forces soldiers,inks,octane render photorealistic, blue,gold,white,black octane render, insanely detailed, realistic, desert by Kelly McKernan, yoshitaka Amano, hiroshi yoshida, moebius, loish, artgerm, happy,joyful, painterly, symmetrical and detailed hyperdetailed 8k, moebius,steven wiltshire, , hyper detailed,high contrast,vivid psychedelic colors,, Neon Genesis Evangelion, mathematics and geometry, loony toons, saturated, sun rays + Laurie Greasley + Katsuhiro Otomo, Craig Mullins, 8k, octane render, trending on artstation, hyper detailed,"
    },
    {
        "display": "Pink-themed atmospheric artwork",
        "full": "a pink works, everything is pink, perfect shading, atmospheric lighting, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Iridescent Lovecraftian artwork",
        "full": "he who barrages, art by victoria poyser and edd cartier and brian lee durfee, trending on artstation, iridescent olive lighting aerial view polaroid, lovecraftian, lord of the rings, tintype, paint splatter"
    },
    {
        "display": "Character design: couple embrace.",
        "full": "man in orange t - shirt hugging from behind girl, vivid colors, character sheet, fine details, concept design, contrast, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Elegant flower photography",
        "full": "highly detailed beautiful photography of flower, sharp focus, high contrast, dynamic lighting, elegant, harmony, beauty, masterpiece, by durero, by moebius, by jamie hewlett, alphonse mucha, pen"
    },
    {
        "display": "Nathalie Portman in wooden fashion",
        "full": "3 d render of nathalie portman wearing a high fashion outfit made from wood chips, octane render, intricate, elegant, by alyssa monks, highly detailed, fine details, masterpiece, trending on artstation"
    },
    {
        "display": "Giant flying fish over city",
        "full": "spectacular epic shot film still by marvel, massive giant japanese fishes flying over a new city building, one fish close to camera, high detailed render, windows and sun reflection, id magazine, hyperrealism, detailed textures, photorealistic, hyper detail vfx, weta digital, dneg, ilm, high details photorealistic city, ultra realistic, cinematic, intricate, cinematic light, unreal engine 8 k, octane render, unreal engine, david kostic, artgerm"
    },
    {
        "display": "Angel blowing horn, judgment.",
        "full": "the final judgement. an angel blowing a horn. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Sansa Stark, elegant artwork",
        "full": "sansa stark in red and gold, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Boris Johnson as Corleone",
        "full": "Boris Johnson as Vito Corleone, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime girl by lake.",
        "full": "a very beautiful anime cute girl, full body, long wavy blond hair, sky blue eyes, full round face, short smile, fancy top, miniskirt, front view, summer lake setting, cinematic lightning, medium shot, mid-shot, highly detailed, cinematic wallpaper by Stanley Artgerm Lau"
    },
    {
        "display": "Stoic John Oliver Portrait",
        "full": "portrait of stoic looking john oliver as in the vigo carpathian painting, full body, military uniform, fantasy, intricate, elegant, beautiful, highly detailed, charcoal, centered, dark, smokey, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ruined dieselpunk orc tanks.",
        "full": "concept art of ruined dieselpunk orcish tanks in an open field, key visual, ambient lighting, highly detailed, digital painting, artstation, concept art, sharp focus, by makoto shinkai and akihiko yoshida and hidari and wlop"
    },
    {
        "display": "Futuristic warrior portrait.",
        "full": "futurepunk warrior, giant hammer, portrait, brass metal armor, buff, brown skin african, brown eyes, bald, badass beard, urban city at background, high fantasy, tech, digital painting, highly detailed, smooth, sharp contrast, by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Realistic portrait of Cirilla.",
        "full": "cirilla wearing tight in chest shirt!, half - opened shirt!!, ultra realistic, pretty face, extremely detailed face!!, anatomically correct!!, symmetrical!!!, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute anime girl portrait",
        "full": "portrait Anime cute girl yellow hair, green eyes, maid uniform, pretty face, realistic shaded Perfect face, fine details. Anime. realistic shaded lighting by Ilya Kuvshinov krenz cushart katsuhiro otomo, magali villeneuve, artgerm, rutkowski Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Cute deep-sea creatures.",
        "full": "of a cloudy deep sea under water with strange cute friendly happy creatures with huge eyes, mouth, long tongue and round teeth appearing from sandy coral, in the style of gehry and gaudi, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Female gnome engineer portrait",
        "full": "strong female gnome engineer artificer, wearing metal gauntlet, short black hair, naval landscape, full body portrait, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, centred, rule of thirds, concept art, matte, sharp focus, illustration, cover by artgerm, art by greg rutkowski"
    },
    {
        "display": "Britt Ekland as Lara Croft",
        "full": "Britt Ekland as Lara Croft, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, photorealistic"
    },
    {
        "display": "Beautiful digital painting.",
        "full": "most beautiful painting in the world by william - adolphe bouguereau, john singer sargent, digital painting, artstation, concept art, smooth, sharp focus, warm lighting,"
    },
    {
        "display": "Digital sunflower in pink sea",
        "full": "beautiful digital sunflower in stunning pink sea, VERY LIGHT purple and blue scheme, isometric, by Anton Fadeev and Simon Stalenhag, trending on artstation, low contrast"
    },
    {
        "display": "Tarot card: mountaineer, sunrise.",
        "full": "a symmetrical tarot card of a brown - skinned shirtless desi mountaineer wearing a scottish tartan kilt on a snowy mountain peak. he has a wooden staff, cowboy hat, and boots. background is a stained glass golden sunrise. art deco, art nouveau, homoerotic. by raja ravi varma, by louis comfort tiffany, by albert bierstadt. trending on artstation."
    },
    {
        "display": "Symmetrical sci-fi portrait",
        "full": "symmetry portrait of poly styrene, neon, day glo, mannequin, sci - fi, tech wear, glowing lights intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Tuk Tuk on Moon",
        "full": "a dark blue tuk tuk going across the moon surface, the milky way in the sky, hard science fiction, milky way, moon, matte painting, concept art, 4k"
    },
    {
        "display": "Hyperrealistic dryad woman painting",
        "full": "full body pose, hyperrealistic mixed media painting of a beautiful attractive dryad woman, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Smug exclusivist in Tim Burton style.",
        "full": "a smug exclusivists, black ink line art and watercolor, intricate, digital painting, concept art, smooth, focus, rim light style tim burton"
    },
    {
        "display": "Mysterious underwater woman portrait.",
        "full": "portrait of a beautiful mysterious woman, holding a bouquet of large flowing flowers, bubbles, upward flowing long hair, hands disappeared under the bouquet, underwater with coral and fish, fantasy, regal, intricate, by stanley artgerm lau, greg rutkowski, thomas kinkade, alphonse mucha, loish, norman rockwell"
    },
    {
        "display": "Norse Warrior with Aura",
        "full": "a beautiful Norse Warrior based on Winston Churchill with glowing aura by Greg Rutkowski and Raymond Swanland, Trending on Artstation, cloudy background, ultra realistic digital art"
    },
    {
        "display": "Supergirl digital painting.",
        "full": "Supergirl, highly detailed, digital painting, artstation, facing camera, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, high definition digital art, dramatic lighting, in the style of ilya kuvshinov and Ross tran"
    },
    {
        "display": "Sick prisoner behind bars.",
        "full": "a sick male prisoner peeking through prison bars, elegant, digital painting, concept art, very stylized, smooth, sharp focus, illustration, dark, from Game of Thrones by Ruan Jia and Mandy Jurgens and Artgerm and William-Adolphe Bouguerea"
    },
    {
        "display": "Gorgeous young woman portrait.",
        "full": "photo of a gorgeous young woman in the style of stefan kostic, realistic, coy, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by david cronenberg and stanley lau and artgerm"
    },
    {
        "display": "Elegant woman in mech attire",
        "full": "woman model, combat mech clothes, posing in a bedroom, intricate clothes, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Burned timber giant cage.",
        "full": "timber giant, eaten away by fire. become a cage of flames, wicker man!!, trending on artstation"
    },
    {
        "display": "Steampunk robot boy.",
        "full": "steampunk robot boy with a retrofuturistic backpack by syd mead and norman rockwell. highly detailed digital art. retrofuturism. steampunk. beautiful lighting. trending on artstation."
    },
    {
        "display": "Fantasy palace landscape",
        "full": "fairy palace, castle towers, sunbeams, gothic cathedral, Japanese shrine waterfall, gold and gems, purple trees, lush vegetation, forest landscape, painted by tom bagshaw, raphael lacoste, eddie mendoza, alex ross concept art matte painting"
    },
    {
        "display": "Gloomy dark art illustration",
        "full": "momo, dark art, gloomy atmosphere, cgsociety contest winner, artstation winner, trending on behance, 4 k, 8 k"
    },
    {
        "display": "Cute doctor character illustration.",
        "full": "cute doctor gus fring, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Cinematic portrait of mask salesman",
        "full": "a portrait of a cinematic still of the smiling happy mask salesman, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Victorian sci-fi portrait.",
        "full": "a burly brawny victorian man, chiaroscuro, sci fi character portrait by greg rutkowski, craig mullins"
    },
    {
        "display": "Lonely figure in futuristic city.",
        "full": "alone person tempted by the desperate call of the void, futuristic cityscape, wide shot unreal 5 render, studio ghibli, vivid colors, beautiful sunset, digital art, octane render, beautiful composition, trending on artstation, award winning photograph, masterpiece"
    },
    {
        "display": "Elegant portrait of Alcina Dimitrescu.",
        "full": "alcina dimitrescu, portrait, elegant, highly detailed, artstation, digital painting, art by artgerm"
    },
    {
        "display": "Hybrid owl-chameleon-bear painting",
        "full": "painting of hybrid between owl & chameleon!!! & ( ( ( ( bear ) ) ) ), intercrossed animal, by zdzislaw beksinski, by mattias adolfsson, by tiffany bozic, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Fantasy empress in flowers",
        "full": "A beautiful fantasy empress, full body, just one head, flower tiara, long hair, wearing dramatic aristocrat robe, delicate figure, field of fantasy flowers, foxes and deer, epic composition, ultra wide-shot, dynamic pose, concept art, dramatic lighting, digital painting, smooth, character design, sharp focus, elegant, intricate, trending on artstation, by WLOP and James Jean and Victo Ngai"
    },
    {
        "display": "Hyper-detailed portrait of Emma Watson",
        "full": "emma watson, au naturel, grey eyes, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Cinematic portrait of Kratos",
        "full": "portrait of a kratos, cinematic lighting, smooth, focus, cinematic, concept art, highly detailed, artstation, deviantart, behance trending, art by feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi, wlop, artgerm"
    },
    {
        "display": "Dreamlike luxury portrait.",
        "full": "dreamlike luxury stunning laetitia casta portrait wearing a japanese kimono, art by artgerm, wlop, loish, ilya kuvshinov, 8 k realistic, hyperdetailed, beautiful lighting, detailed background, depth of field, symmetrical face, frostbite 3 engine, cryengine,"
    },
    {
        "display": "Glamorous nurse portrait",
        "full": "Nathalie Portman as a glamorous and sexy nurse in blouse, beautiful, pearlescent skin, natural beauty, seductive eyes and face, elegant girl, natural beauty, very detailed face, seductive lady, full body portrait, natural lights, photorealism, summer vibrancy, cinematic, a portrait by artgerm, rossdraws, Norman Rockwell, magali villeneuve, Gil Elvgren, Alberto Vargas, Earl Moran, Enoch Bolles"
    },
    {
        "display": "Duck-shaped glass in bar",
        "full": "real photo of a transparent glass in the shape of a duck in a crowded bar, trending on artstation"
    },
    {
        "display": "Jesus in drag show.",
        "full": "picture of Jesus in a drag show., digital art, 8k, UHD, trending on artstation"
    },
    {
        "display": "1940s Ghost Woman Portrait",
        "full": "1940's Ghost Woman in the woods, detailed, comic book texture, bats, 4k symmetrical portrait, Ashley wood, Mike Mignola, trending on artstation, Norman Saunders"
    },
    {
        "display": "Demon hunter portrait, dystopian.",
        "full": "closeup portrait shot of a demon hunter in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Anthropomorphic mushroom family portrait.",
        "full": "360 degree equirectangular black and white greyscale depth matte, anthropomorphic family of mushrooms, family portrait, Art Deco nature, mystical fantasy, Pixar cute character design, intricate art deco mushroom patterns, elegant, sharp focus, 360 degree equirectangular panorama, art by Artgerm and beeple and Greg Rutkowski and WLOP, 360 monoscopic equirectangular"
    },
    {
        "display": "Gothic haute couture model.",
        "full": "beautiful dark goth model wearing epic haute couture by Alexander McQueen, extremely beautiful and proportionate face, in the aesthetic of mert and marcus, masterpiece, intricate, elegant wardrobe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and james jean and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ninja girl portrait at snowy Fuji",
        "full": "portrait ninja gaiden girl, armored black reflective vests ninja wardrobe, at snowy fuji mountain fallen night, ssci - fi and fantasy, intricate and beautiful and detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration, art by tian zi and wlop and alphonse mucha"
    },
    {
        "display": "Tiny cat on Labrador",
        "full": "tiny cat riding a labrador, cat on top of dog, concept art, artstation, trending on Instagram, Twitter, intricate details"
    },
    {
        "display": "Cthulhu vs. Hastur illustration",
        "full": "cthulhu girl fighting against hastur girl, occlusion shadow, specular reflection, rim light, unreal engine, artgerm, artstation, art by hiroaki samura and ilya kuvshinov and ossdraws, intricate, highly detailed 8 k, surrealistic fantasy illustration, cosmic horror, box office hit, movie poster"
    },
    {
        "display": "Hybrid cat-dragon painting",
        "full": "painting of hybrid cat and ( ( ( ( dragon ) ) ) ) fused together, intercrossed animal, by zdzislaw beksinski, by lewis jones, mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Exotic desert landscape.",
        "full": "a desert with a lot of succulents and exotic flowers, corals, underwater, super detailed, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Norse Warrior Goku Artwork",
        "full": "a beautiful Norse Warrior based on Son Goku with glowing aura by Greg Rutkowski and Raymond Swanland, Trending on Artstation, cloudy background, ultra realistic digital art"
    },
    {
        "display": "Young man in hood.",
        "full": "ultra realistic illustration, a young man in a white hood, with brown hair, with blue eyes, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic high-fashion portrait.",
        "full": "beautiful chinese girl, full body, high fashion, futurism, aerodynamic, intricate, slick, highly detailed, digital painting, vogue, concept art, smooth, sharp focus, oil painting, art by syd mead and kandinsky and annie leibovitz"
    },
    {
        "display": "Intimate Elvira illustration",
        "full": "a ( beautiful masterpiece highly detailed ) illustration of intimate alluring elvira! mistress of the dark by ralph horsely and artgerm and joe jusko"
    },
    {
        "display": "Vivid dream apocalypse concept.",
        "full": "the most wonderful dream apocalypse, concept art, trending on artstation vivid color, complementary color, golden ratio, detailed, sharp lines, intricate, rainbowshift by andrei riabovitchev, by peter mohrbacher, by gustave dore, by maxfield parrish, by alphonse mucha, by beksinski, deviantart, octane render"
    },
    {
        "display": "Hybrid fly-cat painting",
        "full": "painting of hybrid between fly & cat, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Sacred Geometry Concept Art",
        "full": "sacred geometry and spiritual awakening, concept art by alex grey and beeple, digital art, unreal engine 5, trending on artstation, deviantart, pinterest, rule of thirds, 4 k uhd image"
    },
    {
        "display": "Futuristic character portrait.",
        "full": "full body portrait of a character in sleek clothes, in a futuristic flowing white tailcoat, wearing a white insectoid mask with many lenses for eyes, many eyes, dramatic lighting, illustration by Greg rutkowski, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Dreamy fantasy forest scene",
        "full": "painterly dreamy Kupala Night in the blue forest with trees which have eyes, giant flowers, glowing owls, deers, lianas, thistles, giant fantasy creatures, a stream and sky with moon and stars by Alphonse Mucha, Artgerm, Alex Grey, Aron Wiesenfeld and Giger dark fantasy, witcher, very detailed oil painting in the alla prima style, masterpiece, 8k"
    },
    {
        "display": "Man with blue energy wings in futuristic Paris.",
        "full": "a man from behind with a pair of blue vapor energy wings soaring through futuristic paris, 4 k, shimmering color, cinematic light, hyper detailed, art by greg rutkowski and magali villeneuve and artgerm"
    },
    {
        "display": "Black kitchen with red walls.",
        "full": "photo of a black kitchen, red walls, white floortiles, architecture, concept art"
    },
    {
        "display": "Elegant ice queen illustration",
        "full": "ice queen, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Ulzzang girl in cheongsam",
        "full": "full body illustration of an ulzzang korean girl purple hair with hime cut bangs, head slightly tilted, wearing an ornate cheongsam, ilya kuvshinov, anime, pixiv top monthly, trending on artstation, cinematic, danbooru, zerochan art, kyoto animation"
    },
    {
        "display": "Cottagecore space princess illustration",
        "full": "beautiful space cottagecore, princess rosalina!!! by ilya kushvikov, holding a rocket wearing a scifi jetsuit, intricate, elegant, bioluminescent, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy portrait of a white male.",
        "full": "a _ fantasy _ style _ portrait _ painting _ of white male short hair glasses stubble light brown hair big mouth, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Psytrance goddess artwork.",
        "full": "a psytrance painting of an alluring electronic mystical tribal goddess adorned with feathers and gemstones and cables and synthesizer parts is surrounded by sacred geometry made from elven architecture and incense smoke, full body, gorgeous, perfect face, powerful, cinematic, beautifully lit, by artgerm, by karol bak, by viktoria gavrilenko, 3 d, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Steampunk underwater portrait.",
        "full": "underwater bioshock steampunk portrait of Samuel L. Jackson, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Girl observing gas station fire.",
        "full": "a highly detailed matte painting of a girl watching gas station fire by studio ghibli, makoto shinkai, by artgerm, by wlop, by greg rutkowski, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Divine young boy portrait",
        "full": "digital character concept art by artgerm and greg rutkowski and alphonse mucha. portrait, young fourteen year old boy, like a young god, beautiful, angel wings!, holding a staff, detailed, poster art, light effect, glowing, hyper detail, intricate, elegant, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Cyberpunk redhead humanoid",
        "full": "an extremely beautiful redhead scandinavian female humanoid with freckled cheeks, cyber neon lighting, by loish, d & d, fantasy, futurism, cyberpunk fashion clothes, elegant profile posing, perfect anatomy, hyper photorealistic, digital photography, artstation, pinterest, concept art, art by pascal blanche and greg rutkowski,"
    },
    {
        "display": "Whimsical goddess in moonlit lake",
        "full": "one beautiful whimsical goddess standing in a lake basking in the moonlight, underneath a multi-colored binary blackhole with an accretion disc, glowing trails following her arms, synthwave, by Lois van Baarle, by Greg Rutkowski, by artgerm, by beeple, by studio ghibli, cinematic angle, volumetric lighting, 4k resolution, octane render, trending on artstation, masterpiece"
    },
    {
        "display": "Warmly lit actress portrait",
        "full": "Beautiful warmly lit close up studio portrait of a young pale Actress age 23 smiling, beautiful color woodcut in the style of Utamaro and Sharaku , trending on artstation dramatic lighting ukiyo-e"
    },
    {
        "display": "Zombie skater in streetwear.",
        "full": "zombie skater full body hiphop streetwear drip, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Souls in cyberpunk metaverse",
        "full": "a whirlwind of souls rushing inside the metaverse, half body, glowin eyes, tiara with sapphire, insect, android, cyberpunk, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Xenomorph-Tyranid hybrid art",
        "full": "xenomorph combined with tyranid ripper swarm, concept art"
    },
    {
        "display": "Cottagecore Halloween Ariana Grande",
        "full": "cottagecore ariana grande dressing up for halloween night, intricate illustration by krenz cushart, alphonse mucha, artgerm, trending on artstation"
    },
    {
        "display": "Seductive goddess in sunset.",
        "full": "full body falling through the sunset sky windy hair zero gravity floating seductive meditation goddess in the style of artgerm, wlop, greg rutkowski, alphonse mucha and ayami kojima, realistic details, volumetric lighting, very very very very beautiful symmetrical face, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Medieval New York skyline.",
        "full": "view of downtown new york from the upper bay as a medieval city, digital art, medieval, fantasy, highly detailed, intricate, beautiful, concept art, art by julien gauthier, trending on artstation"
    },
    {
        "display": "Cinematic portrait of man",
        "full": "cinematic portrait of chubbier short haired man with goatee, only head and chest, intricate, desaturated, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, maxfield parrish, moebius, thomas ehretsmann, oil on canvas, gouache painting, masterpiece, trending on artstation, cinematic composition, dramatic pose, volumetric lighting, sharp, details, hyper - detailed, hd, 4 k, 8 k"
    },
    {
        "display": "Ares gifts flowers to Hades.",
        "full": "ares giving flowers to hades, hades is confused, jealous aphrodite in the background, ink painting, ultra detailed, concept art, 8 k"
    },
    {
        "display": "Baroque anime crusader illustration.",
        "full": "baroque oil painting japanese light novel cover full body illustration lotr anime key visual stunning action shot | young girl black military crusader maid with long flowing blonde hair steampunk city scene | symmetrical perfect face fine detail quiet gaze | gapmoe kuudere moody dynamic lighting bokeh | trending pixiv fanbox | greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Cute Japanese girl illustration",
        "full": "a highly detailed illustration of short hair cute japanese girl wearing blood stained hoodie and bandages on legs, dramatic sadistic smile pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, WLOP"
    },
    {
        "display": "Shiba Inu Bonk Meme Art",
        "full": "shiba inu dog bonk meme, low poly, character design, highly detailed digital art, atmosphere, glow, lens flare, cinematic lightning, hyperrealistic, focused, extreme details, 4 k, ultra detailed, trending on artstation, masterpiece, digital art."
    },
    {
        "display": "Detailed fantasy tavern barkeep.",
        "full": "barkeep tavern man ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Black-skinned witch portrait.",
        "full": "a stunning portrait of a black - skinned witch with cat ears wearing an ornate flower dress, beautiful flower dress, by makoto shinkai, wlop, mars ravelo, summer vibes, very coherent symmetrical artwork, perfect face, studio lighting, smooth, sharp focus, 4 k, masterpiece, trending on artstation"
    },
    {
        "display": "Cartoon Tom Waits Dinner",
        "full": "cartoonish tom waits eating dinner, vivid colors, character sheet, fine details, concept design, contrast, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "George Washington as Nazi Propaganda",
        "full": "the second coming of george washington in nazi propaganda by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Highly detailed lemon creature.",
        "full": "lemon creature, 4 k, art station concept, highly detailed"
    },
    {
        "display": "Cyclopean warforged paladin.",
        "full": "dynamic portrait of a cyclopean warforged character in yellow armor holding a paladin engraved longsword and carrying a big shield, epic , trending on ArtStation, cinematic lighting, by Greg Rutkowski and Jesper Ejsing"
    },
    {
        "display": "Elegant woman portrait, cliffs.",
        "full": "portrait of a woman, cliffs, obama, obama, obama, obama, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Muscular Norse god Rick Sanchez",
        "full": "rick sanchez, norse god, blue hair, masculine, mature, handsome, upper body, grey and silver, muscular, hairy torso, fantasy, intricate, muscular, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by gaston bussiere and alphonse mucha"
    },
    {
        "display": "Realistic Frieza portrait sketch",
        "full": "realistic portrait pencil sketch of frieza, old mystic ruins, afternoon, intricate, elegant, highly detailed, digital painting, sharp, focus, by artgerm and greg rutkowski"
    },
    {
        "display": "Tree in clouds at sunset.",
        "full": "a beautiful digital painting of a tree merged in the clouds, in the skies, intricate white branches and birds flying in the sunlight, pristine marble trunk, blue sky at sunset, elegant, highly detailed, artstation, concept art, matte, sharp focus, art by tom bagshaw, kelogsloops and greg rutkowski"
    },
    {
        "display": "Anime Musician in Horror Studio",
        "full": "Anime Musician, Mario Brothers style Studio Recording Setup, Streaming, Live, Professional Recording Equipment, Dark, Horror, detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render, cinematic lighting, dramatic lighting, masterpiece"
    },
    {
        "display": "Cute bunny portrait, CGI.",
        "full": "portrait of a super cute bunny, a carrot, pixar, zootopia, cgi, blade runner. trending on artstation"
    },
    {
        "display": "Fierce fantasy portrait",
        "full": "photorealistic portrait of a young girl drinks monsyer energy, female, masculine, upper body, fantasy, fierce, sharp features, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ballerina-inspired fantasy artwork",
        "full": "imaan hammam, ballerina, lord of the rings, sweaty insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal tarot card portrait",
        "full": "art by Gediminas Pranckevicius, Hyperrealistic beautiful goodness ethereal  white north pole girl portrait, art nouveau, fantasy, intricate flower designs, elegant, highly detailed, sharp focus, art by Artgerm and Greg Rutkowski and WLOP - WOMAN of tarot card The Star card shows a woman kneeling at the edge of a small pond. She is holding two containers of water. One container pours the water out to the dry land, as if to to nourish it and ensure its fertility. The lush green land around her seems to say that it is working. One foot is inside the water which shows the spiritual abilities and inner strength of the woman. The other foot on the ground shows her practical abilities and strengths. Behind her, there is a large central star surrounded by seven small stars which represent the chakras. There is bird standing at a tree branch which represents the holy ibis of thought. The Star's astrological correspondent is Aquarius."
    },
    {
        "display": "Symmetrical sci-fi Einstein portrait",
        "full": "symmetry!! portrait of albert einstein as obi - wan, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic fantasy forest scene.",
        "full": "a female beauty with red cap rides on a strong black horse slowly through a dense misty green oak and beech forrest, rays of life, cinematic, fantasy art, moody morning light,  cryengine, trending on artstation,  by cynthia sheppard, by naoto hatori, by tyler jacobson, by john howe, by ridley scott, by peter jackson,  by chris rahn"
    },
    {
        "display": "Young Japanese detective portrait.",
        "full": "a highly detailed illustration of young attractive japanese guy wearing black detective coat, yellow eyes, dramatic standing pose, hyperdetailed perfect face, perfect eyes, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop."
    },
    {
        "display": "Mad scientist, falling hamburgers.",
        "full": "a mad scientist in his back yard laughing happily at the hamburgers which are falling from the sky , concept art, trending on artstation 3D."
    },
    {
        "display": "Shakespeare as Mona Lisa",
        "full": "portrait of William Shakespeare like a mona lisa, by da vinci, full of details, matte painting, concept art, smooth, octane render, trending on cgsociety artstation, cinematic, hyper realism, high detail, octane render, 8k, iridescent accents, Soft light atmosphere, 8k HDR,"
    },
    {
        "display": "Cindy Aurum concept art.",
        "full": "cindy aurum from final fantasy 1 5, character concept art, sharp, digital matte painting, art by greg rutkowski, wlop, dramatic lighting, trending on artstation"
    },
    {
        "display": "Androgynous witch portrait.",
        "full": "portrait painting of an androgynous witch with shoulder length flowing black hair pale skin and beautiful dark brown eyes wearing a punk clothes, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Cartoon portrait of Tupac",
        "full": "portrait of tupac shakur, boston celtics jersey number 3 4, green, white, cartoon digital art, oil on canvas, trending on artstation, octane render"
    },
    {
        "display": "Moon Priestess Portrait",
        "full": "full body portrait of the moon priestess, pop art, enchanted, dark, moon, magic, digital, concept art, Kyoto animation,last exile, blue submarine no. 6, katsura masakazu,tsutomu nihei, gustav klimt,loish, murata range, kawaii, studio lighting, manga, bright colors, anime,beautiful, 35mm lens,noir, vibrant high contrast, gradation, jean giraud, moebius, fantasy, rule of thirds, unreal engine, fibonacci, intricate, cel shaded, blender npr, flat, matte print, smooth,ghibli, makoto shinkai"
    },
    {
        "display": "Cybernetic Jedi Portrait",
        "full": "a portrait of a beautiful cybernetic jedi, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Dark fantasy acrylic painting",
        "full": "a beautiful dark acrylic painting of a dark fantasy land by Raja Ravi Varma and Gediminas Pranckevicius, trending on ArtStation."
    },
    {
        "display": "Sorcerer vs. Lion Energy",
        "full": "a sorcerer shooting energy against a lion, elegant, digital painting, concept art, smooth, sharp focus, illustration, from d & d by ruan jia and mandy jurgens and artgerm and william - adolphe bouguerea"
    },
    {
        "display": "Nuclear explosion concept art.",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Nuclear explosion. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Military armored Trump portrait",
        "full": "a professional painting of a trump, clothes in military armor, olive skin, beautiful bone structure, symmetrical facial features, intricate, elegant, digital painting, concept art, smooth, sharp focus, illustration, from starcraft by ruan jia and mandy jurgens and artgerm and william - adolphe bouguerea"
    },
    {
        "display": "Surreal goddess portrait collage.",
        "full": "close-up portrait goddess brain,  x-ray, backbone, jellyfish phoenix head, nautilus, orchid, betta fish, bioluminiscent creatures, dark deep complex air bubbles in background, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, high contrast, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Lone Wanderer Illustration",
        "full": "Lone Wanderer, in the style of Sam Guay and James Jean and abigail larson and Sam Spratt, flat illustration, beautiful colors trending on artstation"
    },
    {
        "display": "Cyberpunk western robot illustration",
        "full": "cyberpunk robot, western, closeup, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Retro sci-fi landscape masterpiece",
        "full": "cinematic view of a retro scifi landscape, futuristic, desaturated, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Cinematic woman in surreal landscape.",
        "full": "rgb, woman, cinematic, movie scene, inspired by zdzislaw beksinski, new zealand landscape, clothes made out of veins,, cables everywhere, bedroom, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k"
    },
    {
        "display": "Cute anime girl portrait",
        "full": "portrait Anime as Flash girl cute-fine-face, pretty face, realistic shaded Perfect face, fine details. Anime. realistic shaded lighting by katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Ghostly comic cover art",
        "full": "artgerm, joshua middleton comic cover art, pretty ghost sarah michelle gellar entire full body, floating, creepy smile, white dress, friendly, translucent, symmetrical eyes, symmetrical face, long white hair, inside haunted house"
    },
    {
        "display": "Cottage in forest, digital art.",
        "full": "a cottage in a forest, rendered by simon stalenhag, beeple, makoto shinkai, syd meade, environment concept, digital art, unreal engine, wlop, trending on artstation, low level, 4 k uhd image, octane render,"
    },
    {
        "display": "Zombie hand in graveyard.",
        "full": "an ultra detailed animation of a zombie hand in a graveyard at midnight on halloween, digital art, dark fantasy, concept art, soulslike, by alphonse mucha, blood moon eclipse, ruined building in the background, artstation, 8 k, unreal engine render"
    },
    {
        "display": "Cyberpunk portrait of Christina Hendricks",
        "full": "portrait shot of christina hendricks in ghost in the shell wearing a cyberpunk costume, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Elegant Mediterranean portrait",
        "full": "beautiful portrait of a mediterranean female wearing fantastic Hand-dyed cotton dress, embellished beaded feather decorative fringe knots ,colorful pigtail,subtropical flowers and plants,symmetrical face,intricate,minority,elegant, highly detailed, 8k,post-processing,digital painting, trending on artstation, concept art, sharp focus, illustration, by artgerm,Tom Bagshaw,Daniel Gerhartz,Albert Aublet,Lawrence Alma-Tadema"
    },
    {
        "display": "Gaten Matarazzo as E. Honda",
        "full": "gaten matarazzo as e. honda from street fighter, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Cyborg girl mirror reflection",
        "full": "digital cyberpunk steampunk anime, cyborg - girl reaching into a mirror, mechanical insides, reflections, wlop, ilya kuvshinov, artgerm"
    },
    {
        "display": "Tesla in neon lab.",
        "full": "nikolai tesla in his lab, glowing jacket, electrical arcs, neon glow, highly detailed, digital art, intricate, dramatic lighting, steampunk, neon colors, cinematic, art by norman rockwell, greg rutkowski, caravaggio, artgerm"
    },
    {
        "display": "Pixar-style Coronavirus scene",
        "full": "f3d render of still of Pixar Coronavirus movie, , blender, trending on artstation, 8k, highly detailed, disney pixar 3D style"
    },
    {
        "display": "Flying superhero rescue.",
        "full": "a flying superhero rescuing a falling child. photorealistic. realism. 4 k wideshot. cinematic. unreal engine. artgerm."
    },
    {
        "display": "Anime skull portrait.",
        "full": "anime manga skull portrait young woman skeleton, pokemon, artgerm, painterly, graffiti, key lighting, art by jc leyendecker and sachin teng"
    },
    {
        "display": "Elizabeth Olsen as goddess.",
        "full": "elizabeth olsen as the goddess of dewdrops!!!!!!, golden ratio!!!!!, centered, trending on artstation, 8 k quality, cgsociety contest winner, artstation hd, artstation hq, luminous lighting"
    },
    {
        "display": "\"Male warrior in red armor\"",
        "full": "male warrior, blood red armor, giant axe, messy curly hair, unkempt beard, dark brown skin, ready to fight, realistic, smooth, sharp focus, character select portrait, fire background, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Elon Musk as Marty McFly",
        "full": "elon musk as marty mcfly near delorean, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic brain in storm.",
        "full": "3 d render of a brain at the cinema, a storm in the background, moody lighting, lens flare, atmosphere, glow, detailed, intricate, full of colour, cinematic lighting, trending on artstation, 4 k, hyperrealistic, focused, extreme details, unreal engine 5, cinematic, masterpiece"
    },
    {
        "display": "Surreal self-reflection scene",
        "full": "a hyper - detailed 3 d render of people seeing themselves in one another, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation hd, by greg rutkowski, bruce pennington, valentina remenar and asher duran,"
    },
    {
        "display": "Hulk digital painting",
        "full": "The Hulk, highly detailed, digital painting, artstation, facing camera, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, high definition digital art, dramatic lighting, in the style of ilya kuvshinov and Ross tran"
    },
    {
        "display": "Surreal nature self-reflection.",
        "full": "a hyper - detailed 3 d render like a oil painting of nature witnessing itself through all creatures, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation hd, by greg rutkowski, bruce pennington, valentina remenar,"
    },
    {
        "display": "Lost boy in devastated Syria",
        "full": "\u00ef\u00bf\u00bdlittle boy lost, epic view of Hummus in Syria in destruction, sad atmosphere, ruins, hyperdetailed, hyperrealism, trending on artstation, award winning photograph, photorealistic, 8k, concept art, cinematographic, uhd, epic lighting\u00ef\u00bf\u00bd"
    },
    {
        "display": "Mythological wine temple.",
        "full": "mythological temple dedicated to wine, by Rolf Armstrong and Evelyn De Morgan and Bastien Lecouffe-Deharme, dramatic lighting, high contrast colors, baroque, empyrean, panoramic view, as trending on Artstation, highly detailed, doom engine,"
    },
    {
        "display": "Santa Claus battling on polar bear",
        "full": "santa claus riding a polar bear into battle, epic, cinematic, artstation, concept art, high quality,"
    },
    {
        "display": "Giant Shrek Anunnaki Statue",
        "full": "giant shrek anunnaki statue, full body, planets, sky, dream, highly detailed, digital painting, refreshing, trending on artstation, octane render, illustration by james jean"
    },
    {
        "display": "Celestial AI in dynamic pose.",
        "full": "a stunning dynamic pose full body of a celestial artificial intelligence mind, intricate, 8 k highly professionally detailed, hdr, cgsociety, dark fantasy, dynamic lighting, cinematic, pristine, smooth, cosplay, elegant, sharp focus, art by alphonse mucha and greg rutkowski,"
    },
    {
        "display": "Hyperrealistic portrait of Macklemore.",
        "full": "hyperrealistic mixed media high resolution painting of Benjamin Hammond Haggerty Macklemore ((Morgan Freeman)), stunning 3d render inspired art by Istv\u00e1n S\u00e1ndorfi and Greg Rutkowski and Unreal Engine, perfect facial symmetry, dim volumetric lighting, 8k octane beautifully detailed render, full body shot, post-processing, extremely hyper-detailed, intricate, epic composition, highly detailed attributes, highly detailed atmosphere, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, flawless completion, lifelike texture, perfection,"
    },
    {
        "display": "Fantasy bioluminescent creatures.",
        "full": "centaur jellyfish phoenix, nautilus, orchid, bioluminiscent creatures, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Cyberpunk warrior portrait",
        "full": "portrait of a cyberpunk warrior, with helmet, half body, glowing eyes, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Cute ghost portrait",
        "full": "cute ghost by charlie bowater and titian and artgerm, full - body portrait, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Demonic angel skyscraper painting.",
        "full": "a watercolor ink painting of a demonic angel with a broken halo wielding a jagged broken blade standing on top of a skyscraper in new york city in the style of leonard boyarsky in the style of yoji shinkawa trending on artstation deviantart pinterest hyper detailed photorealistic highlights and shadow hd 8 k post - processing high resolution"
    },
    {
        "display": "Cinematic battlefield concept art.",
        "full": "concept art of battlefield by jama jurabaev, \ud83d\ude80\ud83d\ude80\ud83d\ude80, extremely detailed, cinematic shot, trending on artstation, high quality, brush stroke, vibrant colors,"
    },
    {
        "display": "3D Funko Pop of Indian Man",
        "full": "full body 3 d render of middle aged indian man, grey! thin beard, small glasses, grey hair, chubby, as a full body funko pop!, studio lighting, grey background, single body, no shadow, blender, trending on artstation, 8 k, highly detailed"
    },
    {
        "display": "Gothic royal portrait concept",
        "full": "baroque bedazzled gothic royalty frames surrounding a pixelsort highly detailed portrait of rimuru tempest, bright golden eyes, sky blye hair, smiling portait by gerhard munthe, 4 k resolution, nier : automata inspired, bravely default inspired, vibrant but dreary but upflifting red, black and white color scheme!!! ( ( space nebula background ) ) decora japanese yokai doll wearing a black maximalist fashionable jacket, high collar choker, ultra detailed, concept art, digital painting, pretty, cinematic, wlop artstation, low quality sharpened graphics, remastered chromatic aberration spiked ice cold draincore, gothic angel ice winter aesthetic, bright vhs gothic hearts, spikes with light blue glitter breakcore y 2 k metal art"
    },
    {
        "display": "Lofi Pixar-style portrait",
        "full": "Lofi smiling portrait, Pixar style by Joe Fenton and Stanley Artgerm and Tom Bagshaw and Tim Burton"
    },
    {
        "display": "Psychedelic woman drinking juice",
        "full": "an extremely psychedelic wideshot of an woman drinking juice, surreal, lsd, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Elegant French milkmaid portrait",
        "full": "a full body portrait of a beautiful french milkmaid, pulp fiction, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Eerie batlike office lady",
        "full": "portrait painting of a batlike office lady in the sewers, sharp focus, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, dark, black and white, rpg art vampire the masquerade. art by tim bradstreet"
    },
    {
        "display": "Detailed ambient portrait.",
        "full": "ambient lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Alien first contact scene",
        "full": "the day that aliens met us, digital art, epic, lighting, color harmony, volumetric lighting, matte painting, chromatic aberration, shallow depth of field, epic composition, trending on artstation"
    },
    {
        "display": "Intricate blood and tar textures.",
        "full": "blood and tar textures, art by greg rutkowski, intricate, ultra detailed, photorealistic, vibrante colors, colorful, trending on artstation, 4 k, 8 k"
    },
    {
        "display": "Retrofuturistic cyberpunk truck",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork scene: retrofuturistic 1920s cyberpunk steampunk soviet truck inspired by a locomotive emitting smoke. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Zombie punk guitarist portrait",
        "full": "zombie punk guitarist, tristan eaton, victo ngai, artgerm, rhads, ross draws, intricated details, 3 / 4 view, full body portrait"
    },
    {
        "display": "Detailed portrait of Sadhguru",
        "full": "A portrait of Sadhguru, highly detailed, trending on artstation, bokeh, 90mm, f/1.4"
    },
    {
        "display": "Dystopian surreal interior art",
        "full": "an interior by zdzislaw beksinski, immaculate scale, dystopian surrealism, concept art, digital art, dark atmosphere"
    },
    {
        "display": "Realistic clown portrait of Putin.",
        "full": "vladimir putin as a stupid clown, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young bald man in raincoat",
        "full": "( ( ( ( young bald man ) ) ) ) dressed in raincoat, male, clear face, masculine, upper body, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed octopus tentacle macro.",
        "full": "octopus tentacle, isolated, studio, stock photo, very detailed, intricate, nature macro, photograph, 4k, UHD, 8k, octane render, post-processed, film grain, concept art, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate sci-fi horror card",
        "full": "Boardgame card, sci-fi, horror, cyberpunk, ornamental edges, sharp, intricate, symmetrical, Lovecraftian, purple, gold, black and blue, tzeentch, by greg rutkowski and alphonse mucha, 8k, trending on artstation"
    },
    {
        "display": "Elegant anime portrait",
        "full": "kurisu makise steins gate anime, atmospheric, elegant, smiling, super highly detailed, professional digital painting, artstation, concept art, 8k, art by wlop, artgerm and alphonse mucha and eiichiro oda"
    },
    {
        "display": "Hobbit-hole interior concept art",
        "full": "concept art of the inside of a hobbit - hole, digital art, trending on artstation"
    },
    {
        "display": "Futuristic cityscape with laser eyeball",
        "full": "A very beautiful cityscape scene with a GIANT LASER EYEBALL looming in the distance, rendered by simon st\u00e5lenhag, rendered by Beeple, Makoto Shinkai, syd meade, environment concept, digital art, Gundam style, starwars, unreal engine, 3 point perspective, WLOP, trending on artstation, low level, 4K UHD image, octane render,"
    },
    {
        "display": "Elegant digital portrait.",
        "full": "sansa emma watson sunny gold, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Kids fighting in classroom.",
        "full": "kids fighting in a crowded classroom, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Cute detailed greyhound painting",
        "full": "cute white greyhound, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Blonde student in study.",
        "full": "young female prep school student with medium length bright blonde hair and pale skin, in an old study room, complex artistic color ink pen sketch illustration, subtle detailing, artwork by Artgerm and Range Murata."
    },
    {
        "display": "Dragon queen portrait.",
        "full": "dragon queen, captured, perfectly-centered-Portrait of the most beautiful asian woman on the planet,  insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fluffy orange cat drinking milk.",
        "full": "a fluffy cat drinking from a bowl of milk. orange cat. the cat was in a room with yellow background color. animal. digital art. artstation. realistic. vibrant. illustration. in the style of pixar movie. octane render. art by artgerm and greg rutkowski and alphonse mucha. volumetric lighting"
    },
    {
        "display": "Spanish Civil War infantry charge.",
        "full": "intense vibrant film still of infantry charging across a bridge, spanish civil war 1 9 3 7, oil painting by ferrer dalmaui, trending on artstation"
    },
    {
        "display": "Lena Headey as Love Goddess",
        "full": "lena headey as a godless of love, anatomy, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by art by artgerm and greg rutkowski and edgar maxence"
    },
    {
        "display": "Elegant goth fashion model.",
        "full": "an elegant curvy feminine pale goth cutie wearing an elaborate latex-nylon-leather striped red-black-silver-gold neck-high dress, thin waist, cgsociety, photorealistic, 16k, smooth, sharp focus, trending on ArtStation, volumetric lighting, worksafe, sublime-comforting-intriuging ambience"
    },
    {
        "display": "Futuristic Asian hula girl portrait",
        "full": "a portrait of a full body beautiful futuristic asian hula girl, young with long hair, bioluminescence, hyper - realistic, very detailed, intricate, very sexy pose, slight smile expression, unreal engine, by artgerm, wlop and ross thran, dramatic cinematic lighting rendered by octane, 8 k, detailed"
    },
    {
        "display": "Hellish mountain monster artwork",
        "full": "a monster in the mountains of hell, oil painting by tomasz jedruszek, cinematic lighting, pen and ink, intricate line, hd, 4 k, million of likes, trending on artstation"
    },
    {
        "display": "Solarpunk Kowloon Treehouse",
        "full": "solarpunk kowloont by frank lloyd wright, still from studio ghibli anime movie, cyberpunk tree house, digital art, artgerm, trending on artstation"
    },
    {
        "display": "Young Naomi Campbell as angel.",
        "full": "young naomi campbell as an angel with wings, hyper realistic face, beautiful eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, vibrant"
    },
    {
        "display": "Ornate Kylin Sculpture",
        "full": "the beautiful delicate sculpture of an ornate detailed mythology kylin, from china, in the style of victo ngai james jean takato yamamoto ellen jewett yuuki morita dale chihuly louis comfort tiffany zigor samaniego, 8 k hd, trending on cgsociety, trending on artstation, ultra wide angle, hyperrealism, 3 d render, hyper detailed"
    },
    {
        "display": "Avenida Paulista artwork.",
        "full": "Avenida Paulista painted by Greg Rutkowski"
    },
    {
        "display": "Nuclear explosion artwork",
        "full": "nuclear explosion, acrilic paint, digital, artstation, detailed intricate ink illustration, heavenly atmosphere, digital art, overdetailed art, concept art, complementing colors, trending on artstation, cgstudio, the most beautiful image ever created, dramatic, subtle, details, award winning artwork, beautiful scenery"
    },
    {
        "display": "Cute alien portrait, anime style.",
        "full": "A potrait of an alien with big and cute eyes, fine-face, realistic shaded perfect face, fine details. Night setting. Very anime style. Realistic shaded lighting poster by Ilya Kuvshinov katsuhiro, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash, Rob Rey and Kentar\u00f5 Miura style, trending on art station"
    },
    {
        "display": "Scarlett Johansson as Elf",
        "full": "a movie still frame, of scarlett johansson as a female elf, oil on linen, beautiful painting, by tooth wu, artgerm, fantasy concept art portrait, by bayard wu,"
    },
    {
        "display": "Dystopian fantasy world illustration.",
        "full": "detailed concept art illustration colorful oil painting of a dystopian fantasy world in full intricate design, ultra detailed, digital art, octane render, 4K, dystopian, micro details, hyper realistic"
    },
    {
        "display": "Magical library interior artwork.",
        "full": "magical library interior by eugene von guerard, ivan shishkin, dramatic lighting, concept art, trending on artstation"
    },
    {
        "display": "Steampunk robot spider portrait",
        "full": "portrait shot of a steampunk robot spider, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Futuristic Egyptian warrior goddess.",
        "full": "realistic detailed face portrait of a beautiful futuristic egyptian warrior godess bast in alien cyberpunk armor by alphonse mucha, ayami kojima, amano, greg hildebrandt, and mark brooks, female, feminine, art nouveau, cyberpunk, stargate, neo - gothic, gothic, character concept design"
    },
    {
        "display": "Hellhounds in cathedral.",
        "full": "hellhounds guarding abandoned cathedral, dramatic pose, d & d, fantasy, intricate and very beautiful and highly detailed, elegant, digital painting, artstation, concept art, matte, smooth and sharp focus, rpg artwork, illustration, by ismail inceoglu dragan bibin hans thoma greg rutkowski alexandros pyromallis nekro rene maritte illustrated tian zi and wlop and alsphonse mucha and artgerm and greg rutkowski and pino daeni and dan mumford,"
    },
    {
        "display": "Porcelain doll portrait, fantasy.",
        "full": "natalie dormer as a cute porcelain doll with long purple hair, mark ryden style, vivid colors, high details, cinematic, 8 k resolution, beautiful detailed, photorealistic, digital painting, dark atmosphere, artstation, concept art, smooth, sharp focus, illustration, fantasy background, artstation trending, octane render, unreal engine"
    },
    {
        "display": "Effeminate Apollo Portrait",
        "full": "a beautiful close - up of a young white - haired! greek god apollo effeminate with very long curly hair and thin - eyebrows!, digital art by edward hopper, vibrant color scheme, highly detailed, in the style of romanticism, fine art, high detail, great lighting, 8 k resolution, masterpiece, concept art, illustration, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, hdr, trending on artstation, 4 k, 8 k, hd"
    },
    {
        "display": "Cyan Chinese dragon illustration",
        "full": "cyan chinese dragon fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration by peter mohrbacher greg rutkowski john howe zhang yu"
    },
    {
        "display": "Gothic cyberpunk maiden portrait",
        "full": "portrait of beautiful pale gothic maiden in armor bikini, warhammer 40000, cyberpunk, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and Gustav Klimt"
    },
    {
        "display": "Fantasy portrait of sultan.",
        "full": "emma watson portrait of ottoman sultan gog, female, clear face, symetrical, masculine, full body, 4 k, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sailor Moon portrait artwork",
        "full": "a portrait of sailor moon, made by stanley artgerm lau, wlop, rossdraws, artstation, cgsociety, concept art, cgsociety, octane render, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k,"
    },
    {
        "display": "Adorable blood gremlin art.",
        "full": "A adorable blood gremlin, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Greg Rutkowski, trending on artstation"
    },
    {
        "display": "\"Massive stone fist in mountains\"",
        "full": "a massive stone fist laying in the middle of mountains, volumetric lighting, soft lighting, soft details, painting oil on canvas by artgerm and jihun lee, octane render, HDR, trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Futuristic alien convention hall",
        "full": "hyperrealistic futuristic convention hall with various alien species, very detailed, technology, cyberpunk, dark blue and pink volumetric light, cgsociety, in the style of artgerm and artstation"
    },
    {
        "display": "Hungover Yoda, Jedi Council",
        "full": "Yoda hungover at the jedi council at coruscant, cloee-up shot, details, sharp focus, illustration, by Jordan Grimmer and greg rutkowski, Trending artstation, pixiv, digital Art"
    },
    {
        "display": "Human male demon concept.",
        "full": "human male demon, full body white purple cloak, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Robot boarding airplane, anime.",
        "full": "a robot entering a crowded plane, Anime. Soft lighting, 8K, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "LED porkchops and potatoes",
        "full": "porkchops and mashed potatoes made out of green gaming LED, HD, trending on artstation, instagram post, /r/razer, -H 640"
    },
    {
        "display": "Elephant-dog mountain jump",
        "full": "( dog ) jumps from mountain, dog looks like elephant, intricate, epic lighting, cinematic composition, hyper realistic, 8 k resolution, unreal engine 5, by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, marc simonetti, artstation"
    },
    {
        "display": "Supernatural beings in swamp bubbles.",
        "full": "supernatural beings in gloomy bubbles surrounded by swamp. pulp sci - fi art. high contrast. lewis jones, by mattias adolfsson, concept art, digital painting cold hue's, warm tone gradient background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Beautiful painting of Obama",
        "full": "beautiful painting of a barack obama, matte painting, 4 k, trending on artstation, greg rutkoswki"
    },
    {
        "display": "Mountain cabin landscape.",
        "full": "cabin high on a mountain, the valley beneath, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Children with glowing eyes.",
        "full": "a painting of two children with glowing eyes walking down a set of stairs, concept art by sadamichi hirasawa, featured on pixiv, neoplasticism, concept art, demonic photograph, cryengine"
    },
    {
        "display": "Beautiful anime giantess.",
        "full": "a very beautiful young anime giantess from below, full body, long wavy blond hair, sky blue eyes, full round face, evil grin, bikini, miniskirt, front view, mid - shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "Futuristic space general portrait.",
        "full": "space chief general aeon flux profile picture by greg rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, thierry mugler, norman rockwell, 3 d,"
    },
    {
        "display": "Hooded figure in canyon meadow.",
        "full": "light beam being appears suddenly in front of a hooded figure in a black robe standing in a flower meadow, flower meadow landsape in dark pits of a canyon, illustration by Gustave Dore, 18th century drawing , black and white, highly detailed, 4k, concept art, artstation"
    },
    {
        "display": "Fantasy portrait of Adele.",
        "full": "Adele as a fantasy magic woman portrait, sci-fi, amber eyes, face, long hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Realistic man gaming digitally",
        "full": "realistic man using laptop in gaming room, artstation trends, sci fi concept art, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Lofi underwater biopunk portrait.",
        "full": "lofi underwater bioshock biopunk portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Snoop Dogg-inspired Overwatch hero",
        "full": "snoop dogg overwatch hero concept character, trending on artstation"
    },
    {
        "display": "Detailed horizontal platforms",
        "full": "a kitbash contaning horizontal plataforms. Scenario kitbash, environment concept art,   5 ( five) different detailed Horizontal plataforms,  in a  action platform spritesheet game assets. Style of   Ori and the blind forest, SOTN, wonder boy, side scroller game, dead cells, hollow knight.   separated modular  plataforms, kit bashing, 4K, Retrofuturism, Studio Ghibli, Simon St\u00e5lenhag"
    },
    {
        "display": "Manga woman with green hair.",
        "full": "tatsumaki, a drawing of a woman with green hair, a manga drawing by tetsugoro yorozu, by wlop, by artgerm, trending on pixiv, superflat, official art, dynamic pose, booru"
    },
    {
        "display": "Tetsuo in Neo-Tokyo",
        "full": "tetsuo with red cape in neo - tokyo overlooking city, from akira | anime, matte painting, perfect detailed face anime art, dystopian megacity neo - tokyo akira, shaded perfect, fine details. realistic shaded lighting anime manga artwork by katsuhiro otomo, akira, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Anime Bob Dylan portrait",
        "full": "anime portrait of bob dylan as a muscular anime boy by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Hercules bust, neon glitch art",
        "full": "bust of hercules, beeple, mike mignola, vaporwave, retrowave, black background, neon wiring, black, glitch, strong contrast, cuts, pinterest, trending on artstation"
    },
    {
        "display": "Sci-fi couple by window",
        "full": "a man and a woman standing in front of a window, concept art by david schleinkofer and by stanley artgerm and by ash thorp, cgsociety, space art, concept art, redshift, sci - fi"
    },
    {
        "display": "Fantasy empress character design",
        "full": "((A beautiful fantasy empress)), highly detailed full body, beautiful eyes, detailed intricate white flower tiara, feathers, ((wearing aristocrat robe)), ((silk)), highly detailed figure, fractal crystal, epic composition, ultra wide-shot, dynamic pose, concept art, beautifully lit, digital painting, smooth, desaturated color theme, character design, sharp focus, elegant, intricate, post processing, artstation, by WLOP, James Jean, Victo Ngai, ryohei hase"
    },
    {
        "display": "Ultra-realistic man illustration",
        "full": "ultra realistic illustration, man in a jacket with dark glasses, with black hair, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Alluring Elvira portrait",
        "full": "a ( beautiful masterpiece highly detailed ) full body portrait illustration of intimate alluring elvira! mistress of the dark by ralph horsely and artgerm and joe jusko, raven black hair, porcelain white skin. bettie page, vampira, morticia addams, curvy, sexy pin - up. trending on artstation"
    },
    {
        "display": "Jackie Chan as Golden Fairy",
        "full": "centered painted portrait, jackie chan as golden fairy, d & d, gloomhaven, matte painting concept art, art nouveau, beautifully backlit, swirly vibrant color lines, fantastically gaudy, aesthetic octane render, 8 k hd resolution, by ilya dx kuvshinov and cushart krentz and gilleard james"
    },
    {
        "display": "Goddess of Space and Time",
        "full": "goddess of space and time, accurate anatomy, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by Ross Tran and greg rutkowski and alphonse Mucha"
    },
    {
        "display": "Nuclear explosion over Manhattan.",
        "full": "a nuclear warhead detonating over the manhattan skyline in the style of william turner. dramatic concept art, 4k, high detail, volumetric lighting"
    },
    {
        "display": "Gorgeous female portrait.",
        "full": "a gorgeous female photo, professionally retouched, realistic, insanely detailed hair, smooth face, eyes closed, symmetrical, full body shot, wide angle, sharp focus on eyes, 8 k high definition, insanely detailed, intricate, elegant, art by artgerm"
    },
    {
        "display": "\"Fluffy red fox portrait\"",
        "full": "portrait of the cutest red fox ever, fluffy, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Witcher painting by Rutkowski",
        "full": "witcher, paint by greg rutkowski"
    },
    {
        "display": "Detailed sci-fi portrait.",
        "full": "\u201c portrait of zoe saldana by greg rutkowski, young, attractive, highly detailed portrait, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq \u201d"
    },
    {
        "display": "Scarlett Johansson as Motoko Kusanagi",
        "full": "a ultradetailed beautiful panting of scarlett johansson as motoko kusanagi, by conrad roset, greg rutkowski and makoto shinkai, trending on artstation"
    },
    {
        "display": "Elegant woman portrait illustration",
        "full": "portrait of a beautiful woman, intricate, elegant, highly detailed, digital painting, artstation, concept art, illustration, by gil elvgen, greg manchess, mucha"
    },
    {
        "display": "Gigachad Luigi Bodybuilder",
        "full": "gigachad bodybuilder luigi crouching with a green hat by ilya kuvshinov, ernest khalimov body, super mario bros symmetrical face concept art, hyper realistic, intricate, elegent, highly detailed, digital painting, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Wavy-haired woman concept art.",
        "full": "tatsumaki, a woman with wavy green hair and a black dress, concept art by rumiko takahashi, drawn by wlop, deviantart contest winner, superflat, official art, deviantart hd, dynamic pose"
    },
    {
        "display": "Beautiful male lacrosse player portrait",
        "full": "closeup portrait of very beautiful cute male lacrosse player wearing a cascade xrs lacrosse helmet in a penn state stadium, glamour pose, particle effects, backlit, highly detailed, soft ambient lighting, sharp focus, rule of thirds, artgerm, wlop, arney freytag, rossdraws, frank frazetta, andrei riabovitchev, hd, octane, 4 k"
    },
    {
        "display": "Cyborg feeding electric pigeons.",
        "full": "cyborg feeding electric pigeons at a dirty crowded streetcorner, cyberpunk, by Josan Gonzalez  and Tomer Hanuka and Geof Darrow and Brad Rigney and  Greg Rutkowski and Moebius,highly detailed, UHD, 8K, Ghost in the shell, Blade Runner, Trending on artstation, bokh, dof"
    },
    {
        "display": "Dramatic Pictish god portrait",
        "full": "Dramatic portraiture of Uuen, the Pictish god of stags, mixed media, trending on ArtStation, by Steve Ditko and ArtGerm and Lucian Freud, luminism"
    },
    {
        "display": "Fantasy portrait of Margot Robbie",
        "full": "margot robbie portrait posing in a magical crochet bikini in front of atlantis, tattooed body, full body shot, wet boody, highly detailed realistic face, highly detailed eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Symmetrical robot portrait.",
        "full": "symmetry!! portrait of a robot with big eyes, sci - fi, tech wear, blue and yellow glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surreal airport landscape illustration.",
        "full": "airport in a field, close up shot, smoke billowing out, rocky, at dusk, distant mountains, 4k, rule of thirds, extreme detail, hazy, intricate ink illustration, surreal, surrealist, trending on artstation, cgsociety, hd, calm, complimentary colours, realistic lighting, by Albert Bierstadt, Frederic Edwin Church."
    },
    {
        "display": "Realistic ancient blonde portrait",
        "full": "a realistic portrait of a blonde man with big lips, ancient times, trending on artstation"
    },
    {
        "display": "Highly detailed Pyke Castle",
        "full": "pyke castle, game of thrones, super highly detailed, professional digital painting, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Space station in orbit.",
        "full": "Very detailed masterpiece painting of a space station in planet orbit, artstation, concept art by Greg Rutkowsk"
    },
    {
        "display": "Cyberpunk Hatsune Miku Portrait",
        "full": "a beautiful portrait of hatsune miku with long black and deep red colored hair and one purple colored cyberpunk eye, dressed a netrunner from shadowrun, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical cosmic minimalist art",
        "full": "symmetry!! minimalist poster space, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, cosmic, soft light, illustration, art by artgerm"
    },
    {
        "display": "Steampunk Chinese dragon concept.",
        "full": "dragon with steam punk tanks and pipes on its side, classic chinese dragon, concept art, white background, artstation"
    },
    {
        "display": "Mandalorian warrior portrait",
        "full": "portrait of a man by greg rutkowski, gabriel luna as a mandalorian warrior from star wars expanded universe, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Cinematic portrait of Walter White",
        "full": "cinematic portrait of Walter white, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp details, hyper-detailed, HD, HDR, 4K, 8K, art by Tim Hildebrandt and Wayne Barlowe and Bruce Pennington and ruan jia and larry elmore,"
    },
    {
        "display": "Vibrant hyperspace concept art",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, hyperspace, vibrant colors, sky rocket"
    },
    {
        "display": "Gothic fantasy river scene",
        "full": "a beautiful digital illustration painting of a detailed gothic fantasy secret river calm creek small fish, by benoit b. mandelbrot, steven belledin, martin johnson heade, lee madgwick, caspar david friedrich, and david rios ferreira. 8 k resolution trending on artstation concept art digital illustration"
    },
    {
        "display": "Excalibur in moonlit lake",
        "full": "excalibur in the middle of a lake under a giant full moon, rippling reflections, romantic, cinematic, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Harley Quinn Portrait - Intricate Art",
        "full": "portrait of candace swanepoel as a harley quinn in suicide squad. intricate abstract. intricate artwork. by tooth wu, wlop, beeple, dan mumford. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Zombie Harley Quinn Concept Art",
        "full": "zombie harley quinn, character sheet, concept design, contrast, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Gypsy male bard painting",
        "full": "a beautiful detailed painting of a gypsy male bard in colorful rainbow firion ornate robes robes, pale skin, white hair, master of dreams art by jon foster trending on artstation painted by greg rutkowski, painted by peter mohrbacher"
    },
    {
        "display": "Confused baby character art",
        "full": "full body portrait character concept art, anime key visual of a confused baby with googles, studio lighting delicate features finely detailed perfect face directed gaze, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Furry vulpes in cyberpunk city",
        "full": "furry anthropomorphic female vulpes vulpes fulva with white fur wearing futuristic clothes eating noodles in the rain by a noodle kiosk in the crowded and wet street of a city, cyberpunk, harsh neon lights, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Gold-haired female healer concept.",
        "full": "concept art of a beautiful gold haired female healer. witcher 3 character design by laurie greasley and sherree valentine daines concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant necromancer sorceress illustration.",
        "full": "Necromancer Sorceress, fantasy magic, undercut blue hairstyle, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Psych girl with cat.",
        "full": "portrait of a psych girl with her mad cat, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Girl on motorcycle illustration",
        "full": "a girl on a motorcycle, centered, solid bacgkround, median photoshop filter cutout vector behance, hd by artgerm, jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, illustration, art by ilya kuvshinov and gustav klimt"
    },
    {
        "display": "Epic circus concept art",
        "full": "circus, concept art, matte painting, epic picture, unreal engine, octane engine, 4K, hyper realistic, trending on artstation"
    },
    {
        "display": "Gothic cathedral interior scene.",
        "full": "cinematic movie scene, 2 0 0 mm wide shot, precise architectural rendering, interior of a dark gloomy catherdal made from bones skulls skin and wires, gothic architecture, detailed illustration, moody atmospheric lightng sharp focus, concept art, unreal engine, octane render, god rays"
    },
    {
        "display": "Space goddess with orb.",
        "full": "space goddess portrait floating while holding a magical orb in her hand. sci fi,  intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Cyborg Baroque Portrait",
        "full": "a masterpiece hyper detailed portrait of a beautiful evil cruel girl - cyborg baroque renaissance. rainbow color scheme. medium shot, intricate, elegant, highly detailed, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, background by james jean and gustav klimt, light by julie bell, 4 k, porcelain skin."
    },
    {
        "display": "Modern Black Man Portrait",
        "full": "portrait of a modern black man wearing a dark shirt, upper body 2d game avatar, Donato Giancola, Kodak film stock, chiaroscuro lighting, default pose neutral expression, face-on head shot, close-up, eye-contact, sharp focus, shape language, Alphonse Mucha/Gustav Klimt style, alpha masked transparent flat grey background, 4k, volumetric lighting, French Nouveau, trending on artstation, octane render, ultra detailed, hyperrealistic"
    },
    {
        "display": "Biomechanical goddess artwork",
        "full": "biomechanical goddess, doom, sf, intricate artwork masterpiece, ominous, matte painting movie poster, golden ratio, trending on cgsociety, intricate, epic, trending on artstation, by artgerm, h. r. giger and beksinski, highly detailed, vibrant, production cinematic character render, ultra high quality model"
    },
    {
        "display": "Demon woman in nebula.",
        "full": "demon woman,, soft eyes and narrow chin, dainty figure, long hair straight down, torn overalls, nebula background, side boob, wet shirt, wet, raining, highly detailed face, realistic face, beautiful detailed eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant,"
    },
    {
        "display": "Futuristic minimalist house design",
        "full": "modern futuristic minimalist house designed by giger, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, oil on canvas, raphael, caravaggio, greg rutkowski, beeple, beksinski, giger"
    },
    {
        "display": "Gigantic spear-shaped starship.",
        "full": "concept art, a gigantic spear - shaped starship plowing through space, approaches the system, huge and megalithic, frightening and creepy atmosphere, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Cyborg lovers kissing, night.",
        "full": "cyberpunk, sci - fi, fantasy, hyper realistic, ultra realistic medium shot of a couple of cyborgs kissing, lovers, joined by cables, backlight, led, artstation, soft light, volumetric lighting, night, intricate, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant Persian princess portrait",
        "full": "persian princess, nazanin boniadi, art by artgerm and greg rutkowski and magali villeneuve, intricate, elegant, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration"
    },
    {
        "display": "Ancient underground dungeon art",
        "full": "key shot of ancient underground dungeon, studio ghibli, bloom, anime key art, gloomhaven, organic painting, hard edges, luminescent, octane render, by greg manchess, huang guangjian, gil elvgren, sachin teng, greg rutkowski, jesper ejsing, rhads, ilya kuvshinov, cushart krenz, anton fadeev"
    },
    {
        "display": "Kanye West listening party.",
        "full": "Kanye West hosting a listening party at Madison Square Garden, dreamy, atmospheric, golden hour, cinematic lighting, 8K concept art"
    },
    {
        "display": "Whale-shaped astronaut portrait",
        "full": "astronaut suit in the shape of a whale, portrait, watercolor, dramatic lighting, cinematic, establishing shot, extremely high detail, foto realistic, cinematic lighting, pen and ink, intricate line drawings, by yoshitaka amano, ruan jia, kentaro miura, artgerm, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross"
    },
    {
        "display": "Anime girl with jet engines.",
        "full": "girl with jet engine as legs, anime style, long hair, hair down, symmetrical facial features, from arknights, hyper realistic, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, half cyborg, d & d, realistic lighting, by alphonse mucha, greg rutkowski, sharp focus, backlit"
    },
    {
        "display": "Heroic demon character design",
        "full": "human male demon, full body white purple cape, hero, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Demon goddess portrait, photorealistic.",
        "full": "perfectly - centered portrait of demon goddess, beautiful, gorgeous, cute, amazing, highly detailed, professional digital painting, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Psychedelic Giger-inspired artwork",
        "full": "h. r. giger, hyper detailed ultra sharp, trending on artstation, vibrant aesthetic, bloodwave, colorful, psychedelic, ornate, intricate, digital painting, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski 8 k, uma thurman"
    },
    {
        "display": "Cassandra Cain studying at home.",
        "full": "cassandra cain as a college student, studying at home, red lipstick, long blonde hair, bokeh, beautiful face!!!!, 2 7 years old, cg animation, lifelike, animated, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Princess Peach portrait artwork",
        "full": "a portrait of princess peach, made by stanley artgerm lau, wlop, rossdraws, artstation, cgsociety, concept art, cgsociety, octane render, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k,"
    },
    {
        "display": "Handsome snow elf archer.",
        "full": "handsome pointy - eared male snow elf in a turquoise cape as an archer, albino skin, mid - shot, moonlight snowing, ethereal opalescent mist, winter vibes, perfect face, elegant, very coherent symmetrical artwork, by wenjun lin, krenz cushart, charlie bowater, trending on artstation"
    },
    {
        "display": "Medieval fantasy battle art",
        "full": "medieval fantasy battle, Ilya Kuvshinov, digital, concept art, Kyoto animation, gustav klimt, last exile, blue submarine no. 6,loish, murata range, kawaii, yoshitaka amano, studio lighting, manga, bright colors, beautiful, 28mm lens,mucha, vibrant high contrast, gradation, jean giraud, fantasy, rule of thirds, fibonacci, intricate, cel shaded, flat, matte print, soft eyes, smooth, mucha, makoto shinkai"
    },
    {
        "display": "Photorealistic sci-fi chair",
        "full": "film still of a chair in a marvel movie, science fiction industrial hard science concept art, 8K render octane high definition cgsociety, photorealistic, unreal engine 5"
    },
    {
        "display": "Monk in misty forest.",
        "full": "A monk alone in a misty dark forest, extremely detailed oil painting, unreal 5 render, rhads, Bruce Pennington, Studio Ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award-winning photograph, masterpiece"
    },
    {
        "display": "Cyberpunk druid illustration",
        "full": "I have no mouth and i want to scream, cyberpunk angry gorgeous druid, pixel sorting, neon, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Heroic portrait of Laika.",
        "full": "realistic heroic portrait of Laika the russian space dog, first dog in orbit 1957, wearing her space helmet, in a circle frame, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Mossy cave, fantasy art",
        "full": "mossy cave lit by orange minerals, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and laura sava and alphonse mucha"
    },
    {
        "display": "Vibrant summer sun illustration.",
        "full": "always the sun, beautiful strange detailed summer painting 8k resolution deviantart trending on Artstation concept art digital illustration"
    },
    {
        "display": "Young witch under moon",
        "full": "portrait, A young witch in front of the full big moon, book cover, red roses, red white black colors, establishing shot, extremly high detail, foto realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie, raphael lacoste, alex ross"
    },
    {
        "display": "Stylish girl in leather.",
        "full": "girl in very short white! top and very short gray! leather jacket, open belly, long dark curly hair, glasses at the top of head, high waist sweatpants, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg portrait of Dua Lipa.",
        "full": "portrait of dua lipa wearing epic bionic cyborg implants of different colors, detailed intricate ornate cables connected to head, by dan mumford and naoto hattori, extremely beautiful and proportionate face, in the aesthetic of mert and marcus, masterpiece, intricate, elegant futuristic wardrobe, highly detailed, digital painting, blade runner, artstation, concept art, crepuscular rays, smooth, sharp focus, illustration, background is made of stars and vibrant space nebula, cyberpunk colors, volumetric lighting, art by artgerm and james jean and nick sullo"
    },
    {
        "display": "Anime Harley Quinn portrait.",
        "full": "Anime as Margot Robbie cute-fine-face, pretty face, surprised realistic shaded Perfect face, fine details. Anime. as Harley Quinn Suicide Squad; Red-Line-Anime realistic shaded lighting by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Elegant female warrior portrait.",
        "full": "female warrior, lovely delicate face, sophisticated armor, dark red sexy lips, smile, close - up shots of faces, oil painting, passion, bravery, intricate armour costumes, light and shadow effects, intricate, digital painting, art station, concept art, cold tones, sharp focus, morandi color scheme, sharply shaped, illustration, art by makoto shinkai and lois van baarle"
    },
    {
        "display": "Jester in futuristic fractals",
        "full": "front!!! shot of a court jester!!! character, mesmerizing fractal hypercubes, platinum, dark holography!!!, future, metallic galactic, crystalline edges, polygonal, elegant, highly detailed, centered, (((artstation, concept art, jagged, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), ((raytracing)), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, 8K"
    },
    {
        "display": "Office scene with people.",
        "full": "human people walking among employee in an office, highly detailed,, artstation hd, deviantart, by madgwick,, greg rutkowski, artgerm, takato yomamoto, ilya kuvshinov, ross tran, conrad roset"
    },
    {
        "display": "Emma Watson as sorceress.",
        "full": "Emma Watson wearing tattered animal tunic holding a glowing fire magical staff. Trending on Artstation, octane render, ultra detailed, art by Ross tran"
    },
    {
        "display": "Elegant snow white mummy",
        "full": "snow white mummy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Ukrainian soldier on skulls",
        "full": "a distant shot of a Ukrainian Modern Warfare soldier with blue and yellow flag standing alone on a pile of skulls as a winner, masculine figure, D&D, fantasy, intricate, elegant, highly detailed, hyperrealistic, extremely detailed, digital painting, artstation, concept art, matte, sharp focus, symmetrical, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Joe Rogan as King Kong",
        "full": "Joe Rogan as King Kong, concept art"
    },
    {
        "display": "Eerie bridal portrait.",
        "full": "epic professional digital art of \ud83d\udc70\u2640, eerie, detailed, foreboding, leesha hannigan, wayne haag, reyna rochin, ignacio fernandez rios, mark ryden, iris van herpen, best on artstation, cgsociety, wlop, pixiv, stunning, gorgeous, much wow, cinematic, masterpiece"
    },
    {
        "display": "Female scientist with black hole.",
        "full": "a league of legends concept art of a beautiful young female scientist in a laboratory holding at a small black hole in her hand, style by jordan grimmer and greg rutkowski, a close - up shot, elegant, sharp focus, morandi color scheme, illustration, high quality, highly detailed, long hair, digital art, 4 k wallpaper"
    },
    {
        "display": "Cyberpunk ork doctor portrait",
        "full": "portrait painting of a cyberpunk ork doctor muscular patrick stewart with fangs and tusks, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Elegant portrait of man",
        "full": "silver monocle, portrait of a middle aged grey haired man in the style of god of war, golden machine parts, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Tropical wood fortress scene",
        "full": "the wall wood fortress tropical with little plant along borderland style vivid colorful  Hyperrealistic CGI Photorealistic cinematic volume lighting fanart on ArtStation full sun shine day concept art"
    },
    {
        "display": "Cyberpunk robot gazmask character",
        "full": "low angle shot of a cyberpunk radiolarian gazmask robot character, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo"
    },
    {
        "display": "Futuristic woman portrait",
        "full": "symmetry!! portrait of woman with nanotech, sci - fi -, cyberpunk, blade runner, glowing lights, tech, biotech, techwear!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Chipmunk astronaut on moon",
        "full": "an adorable chipmunk in an astronaut suit on the moon, warm lighting with cool shadows, digital painting, detailed, trending on artstation, in the style of dominik mayer thomas dubois, gaston bussiere, 8 k, octane render"
    },
    {
        "display": "Young James Dean as anarchist",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork costume design: young James Dean as a well-kept neat anarchist rebel in 1950s USSR green dungarees and big boots, reading a book. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Jesus praying with Pikachu",
        "full": "a portrait of jesus praying, pikachu, fantasy by dan mumford, yusuke murata and makoto shinkai, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Tifa Lockhart with moonlight.",
        "full": "tifa lockhart!!!, moon rays!, chains!, locks, horizontal symmetry!, full figure, 4 k, ultra realistic, beautiful eyes!!, epic lighting, machines!!, high detail, masterpiece, trending on artstation by artgerm and akihito tsukushi and greg rutkowski!, alphonse mucha and terada katsuya"
    },
    {
        "display": "Elegant female wizard portrait",
        "full": "character concept portrait of a female wizard with pale skin, dark vibe, intricate, elegant, digital painting, concept art, smooth, sharp focus, illustration, from Metal Gear, by Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau, Artgerm,"
    },
    {
        "display": "\"Nude Catalan Castellers Sculpture\"",
        "full": "undressed catalan castellers human impossible big tower they are real undressed women like a incredible giant sculpture at the kingdom of julius caesar, no clothes, roman historic works, hyper - detailed, world renowned artists, historic artworks society, antique renewal, intricate, elegant, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Colorful dreamscape with Bob Dylan",
        "full": "bob dylan 1 9 7 6, colorful, series of dreams, contrast, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Eerie portrait of girl.",
        "full": "beautiful girl a strange wind blew in off the north sea, an eerie susurration that cut across the eastern sea, beautiful portrait, symmetrical, character concept style trending on artstation concept art detailed octane render cinematic photo - realistic 8 k high detailed"
    },
    {
        "display": "Dieselpunk Venice illustration.",
        "full": "Dieselpunk Venice city, steam, dieselpunk gondola, oil petroleum black rivers, epic composition, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Lovecraftian Pixar Tarzan portrait",
        "full": "lovecraft lovecraftian portrait of tarzan, pixar style, by tristan eaton stanley artgerm and tom bagshaw, dali"
    },
    {
        "display": "Cyberpunk costume design",
        "full": "sadie sink in oversized man's coat, hoodie, yellow halfmask rebreather : costume design concept art for scifi cyberpunk film. by greg rutkowski, john j. park, jason chan, noah bradley, feng zhu, gintas galvanauskas, gustave courbet, rosa bonheur, edward hopper. sharp focus, cinematic atmosphere, detailed and intricate, perfect anatomy"
    },
    {
        "display": "Realistic alien portrait",
        "full": "realistic portrait picture of alien human - looking with blue skin, photography portrait, cinematic, high quality, cgsociety, artgerm, 4 k, uhd, 5 0 mm, trending on artstation"
    },
    {
        "display": "\"Lovecraftian Rick and Morty\"",
        "full": "rick and morty fused with lovecraft and vader helmet dune worm, realistic portrait, high details, intricate details, by vincent di fate, artgerm julie bell beeple, 90s, Smooth gradients, octane render, 8k, volumetric lightning, photo, High contrast, duo tone, depth of field, very coherent symmetrical artwork"
    },
    {
        "display": "Cosmic-eyed white cat.",
        "full": "a white cat with cosmos in its eyes, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "John Noble as Joker, 1970s horror poster",
        "full": "John Noble as the Joker on a 1970s horror movie poster , vintage 70s print, detailed, scary, horror, screen print, trending on artstation"
    },
    {
        "display": "Goddess of Nature Illustration",
        "full": "goddess of nature, unusual beauty, fauna, flowers, plants, emotionally evoking symbolic metaphors, head in focus, fantasy, ornamental, intricate, elegant, sensual, highly detailed digital painting, artstation, concept art, painterly, golden ratio, sharp focus, illustration, art by John William Godward and Alphonse Mucha and Boris Vallejo and Arcimboldo and Zdzis\u0142aw Beksi\u0144ski,"
    },
    {
        "display": "Ferocious squirrel-dragon artwork",
        "full": "Squirrel/dragon, ferocious, angry, magic the gathering artwork, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Elfish woman in jungle temple",
        "full": "Still of an elfish woman with gorgeous flowing hair in a hidden temple in a lush jungle, sitting on a cave rock, greenish atmosphere with detailed highlights, dark gloomy sky cascading upon the atmosphere, well-detailed ornate Elfish mountains in the background, trending on artstation, 4k, 8k"
    },
    {
        "display": "Detailed dragon concept art",
        "full": "concept art of dragon,  oil painting by Jama Jurabaev, extremely detailed, brush hard, artstation, for AAA game, high quality"
    },
    {
        "display": "Elegant tuxedo man with rubber duck",
        "full": "a german very long! light brown! hair guy with glasses and very! short beard on a tuxedo holding a rubber duck!, trade offer meme, art by greg rutkowski, intricate, elegant, sharp focus, digital painting, concept art, matte, art by artgerm"
    },
    {
        "display": "Anthropomorphic Arctic Fox Hacker",
        "full": "a league of legends concept art of an anthropomorphic arctic fox in a black hoodie holding a portable computer, front view, hoodie with a hacker emblem, artstation, digital art, oc commission, style by jordan grimmer and greg rutkowski, 4 k resolution"
    },
    {
        "display": "Shy portrait of young actress.",
        "full": "a well - lit portrait painting of a shy, blushing 1 6 - year old alicia vikander or millie bobby brown in a leotard with lanterns at night, intricate, elegant, highly detailed, artstation, concept art, by krenz cushart and donato giancola and william adolph bouguereau and alphonse mucha"
    },
    {
        "display": "Female officer portrait, sci-fi.",
        "full": "beautiful portrait of a female officer wearing a fancy naval uniform, art by wlop and artgerm, science fiction, detailed eyes, spaceship interior, trending on artstation, sharp focus, illustration, caustics, octane render, 4 k, radiant light"
    },
    {
        "display": "Zombie Spider-Man Portrait",
        "full": "a portrait of spiderman as a scary zombie with his mask and flesh ripped highly detailed, digital photo, hdri, by christopher bretz and john carpenter, vivid colors, high contrast, 8 k resolution, intricate, photorealistic, smooth, psychedelic color scheme, concept art, award winning, cg society contest winner"
    },
    {
        "display": "Goku Fantasy Portrait",
        "full": "Goku closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Frog playing guitar, hyper-realistic.",
        "full": "a green big frog playing the guitar. intricate, epic lighting, cinematic composition, hyper realistic, 8 k resolution, unreal engine 5, by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, marc simonetti, artstation"
    },
    {
        "display": "Epic dreamcatcher album cover",
        "full": "Epic Album art cover, dreamcatcher, scream, goat, trending on artstation, award-winning art"
    },
    {
        "display": "Cyberpunk horror wrestler art",
        "full": "cyberpunk wrestler hulk hogan, horror art, by beksinski, szukalski, giger, pyromallis, dzo, iris compiet, seb mckinnon, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Cyborg girl in mirror.",
        "full": "digital cyberpunk steampunk anime, cyborg - girl reaching into a mirror, mechanical insides, reflections, wlop, ilya kuvshinov, artgerm"
    },
    {
        "display": "Art Nouveau Cyberpunk Temple",
        "full": "art nouveau gate leading into rock-cut cyberpunk temple with Nabatean geometric decorations, figures cloaked in robes standing outside, hardy desert bushes, science fiction concept art by greg rutkowski and wayne barlowe and alphonse mucha"
    },
    {
        "display": "Tribal woman with steampunk gun.",
        "full": "portrait of a young very beautiful cute tribal woman holding a steampunk gun, in a post apocalyptic city overgrown with lush vegetation, by Luis Royo, by Greg Rutkowski, dark, gritty, intricate, volumetric lighting, volumetric atmosphere, concept art, cover illustration, octane render, trending on artstation, 8k"
    },
    {
        "display": "Yang Xiao Long pose, glossy art.",
        "full": "Yang Xiao Long posing. By ilya kuvshinov, krenz cushart, Greg Rutkowski, trending on artstation. Glossy materials, sharp highlights, amazing textured brush strokes, accurate shape, clear curvy details, cinematic soft volumetric studio lighting, with backlight, VFX, HDR"
    },
    {
        "display": "Dramatic fiery artwork",
        "full": "dall \u00ef\u00bf\u00bd e 2 on fire, greg rutkowski, loish, rhads, beeple, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, stanley kubrick, backlit, harsh overhead sunlight,"
    },
    {
        "display": "Intricate blue heaven illustration",
        "full": "blue heaven, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Bioluminescent oil painting.",
        "full": "lot of fluid oils paint masses image pretty girl, wayne barlow, bioluminescent neon, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper - detailed, hdr, 4 k, 8 k"
    },
    {
        "display": "Ninja girl in dieselpunk armor.",
        "full": "portrait ninja gaiden girl, armored dieselpunk ninja wardrobe, at snowy fuji mountain moonlight, ssci - fi and fantasy, intricate and beautiful and elegant, digital painting, frostbite engine, artstation, concept art, smooth and sharp focus, illustration, art by tian zi and wlop and alphonse mucha"
    },
    {
        "display": "Self-operating telephone, 1978.",
        "full": "a telephone has learned how to use itself and call other telephones, 1 9 7 8, high detail, depth, photorealistic masterpiece by greg rutkowski, digital art, trending on artstation"
    },
    {
        "display": "Epic Asian chi master portrait",
        "full": "epic portrait of gorgeous visionary asian cj miles chi master weaving sacred kanji sigils hands aglow, silky in the air, pairity, detailed, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, strong eyes, highest resolution, extremely detailed | beautiful, establishing shot, artistic, hyperrealistic, enhanced hands, octane render"
    },
    {
        "display": "Intricate Richard Scarry illustration",
        "full": "The busy World of Richard Scarry. Elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Persian princess architect portrait",
        "full": "beautiful portrait of a persian princess who is an architect, beautiful princess, face painting, dramatic lighting, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, footage from space camera"
    },
    {
        "display": "Cybernetic wolf illustration",
        "full": "ultra realistic illustration of a cybernetic wolf, hacknaut cyberpunk, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dullahan vs. Hunter Battle",
        "full": "irish mythology, a dullahan battling a bloodborne hunter, mesmerizing, fantastical, concept art, highly detailed, artstation, trending, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "Symmetrical sci-fi portrait.",
        "full": "symmetry portrait of moira from overwatch, sci - fi, tech wear, glowing lights intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal Anunnaki god Enki",
        "full": "beautiful ethereal anunnaki god enki in holographic dress walking over the ocean, highly detailed digital painting, dynamic pose, radiant, god rays, vibrant colors, in the style of noah bradley and donato giancola and tomasz alen kopera and fenghua zhong and peter mohrbacher, mystical colors, rim light, beautiful lighting, stunning scene, raytracing, octane, trending on artstation, sharp focus, masterpiece"
    },
    {
        "display": "African in Pikachu costume on yacht",
        "full": "an african wearing a pikachu costume, on a super yacht, by guweiz and wlop and ilya kuvshinov and and moebius and bilal and artgerm, symmetrical eyes, aesthetic, gorgeous, stunning, alluring, attractive, half body portrait, artstation, deviantart, pinterest, digital art,"
    },
    {
        "display": "Vibrant mansion artwork",
        "full": "a mansion, trending on artstation, vibrant colors, gorgeous, beautiful, magnificent, high quality"
    },
    {
        "display": "James Bond portrait, cinematic.",
        "full": "portrait of henry cavill as james bond, casino, key art, sprinting, palm trees, highly detailed, digital painting, artstation, concept art, cinematic lighting, sharp focus, illustration, by gaston bussiere alphonse mucha"
    },
    {
        "display": "Tattooed Indian warrior in fantasy.",
        "full": "a tattooed indian female warrior wearing a magicaal leotard in a magical forest, highly detailed face, realistic face, beautiful detailed eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Young joyful shadow mage",
        "full": "a mind - blowing portrait of a young shadow mage male, wearing a vest with diamond pattern, joyful, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic concept art",
        "full": "concept art by jama jurabaev, altered carbon series, black mirror series, very long shot, cinematic shot, trending on artstation, high quality, brush stroke, for aaa game"
    },
    {
        "display": "Medieval city storm scene",
        "full": "medieval city, red violent magical thunderstorm, behrens style, unreal 5 render, fantasy digital art, octane render, beautiful composition, trending on artstation, award - winning photograph, masterpiece"
    },
    {
        "display": "Nature goddess portrait, fantasy landscape",
        "full": "portrait of a nature goddess with red hair and blue eyes in a fantasy landscape of psychedelic architecture - rays, ray - tracing, cinematic lighting, 4 k smooth, focus, cinematic, concept art, highly detailed, artstation, trending, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "Scifi female scientist portrait",
        "full": "an anthropomorphic beautiful scifi female scientist portrait holding telescope wearing colourful robe, wizard hat, fine art, award winning, intricate, elegant, sharp focus, octane render, hyperrealistic, wizard hat cinematic lighting, highly detailed, digital painting, 8 k concept art, art by jamie hewlett and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Ballerina at Great Barrier Reef",
        "full": "stunningly beautiful, asian prima ballerina at the bottom of the great barrier reef, smooth, focus, highly detailed, hyper realistic, dramatic lighting, intricate, concept art, art by wlop, mars ravelo"
    },
    {
        "display": "Obama surfing Nazare wave.",
        "full": "obama surfing the biggest wave at nazare, portugal, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Neon dieselpunk portrait.",
        "full": "portrait of masked neon diesel punk on the art deco tundra, artstation, award - winning realistic sci - fi concept art by beksinski, picasso masterpiece, complimentary colors, james gilleard, bruegel, greg rutkowski, alphonse mucha, and yoshitaka amano"
    },
    {
        "display": "Girl and silver dragon in fairyland.",
        "full": "the beautiful hyper detailed scene render that a beautiful girl lies in the arms of a huge silver dragon in the fairyland surrounded by white clouds, in the style of makoto shinkai victo ngai and peter mohrbacher studio ghibli artgerm karol bak beeple, animation style, 8 k hd, dream, trending on cg society, trending on art station, ultra wide angle, animation style, hyperrealism, 3 d render, hyper detailed"
    },
    {
        "display": "Cyberpunk female assassin portrait",
        "full": "female rouge assassin, wearing cyberpunk intricate streetwear, beautiful, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Lucifer in toe shoes.",
        "full": "portrait of lucifer wearing toe shoes ( vibram five fingers ), digital art, technicolor, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, todd lockwood"
    },
    {
        "display": "Hellish Lviv Panorama",
        "full": "lviv downtown in hell, inferno, witches, demons, panorama, highly detailed, full body, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Portrait of Kristen Bell",
        "full": "beautiful portrait of young kristen bell, soft features, by magali villeneuve and greg rutkowski and artgerm and alphonse mucha and jeremy lipkin and rob hay, intricate, elegant, highly detailed, photorealistic, trending on artstation, trending on cgsociety, 8 k, sharp focus"
    },
    {
        "display": "Portrait of young Joseph McCarthy.",
        "full": "Beautiful warmly lit close up studio portrait of young teenage Joseph McCarthy sweetly smiling cute, impasto oil painting heavy brushstrokes by Cy Twombly and Anselm Kiefer , trending on artstation dramatic lighting abstract Expressionism"
    },
    {
        "display": "Silver hijab Arabian beauty",
        "full": "a beautiful woman wearing a hijab made of silver with jewelry and diamonds by karol bak, ayami kojima, artgerm, sakimichan, arabian beauty, blue eyes, smile, concept art, fantasy"
    },
    {
        "display": "Eerie swamp face at dusk.",
        "full": "swamp with a rotten stem formed like the face of nick nolte, at dusk, misty athmosphere, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by ed binkley and ellen jewett and artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical mech Medusa concept.",
        "full": "a mech version of medusa, very symmetrical, highly detailed, by vitaly bulgarov, by joss nizzi, by ben procter, by steve jung, concept art, quintessa, metal gear solid, transformers, concept art world, pinterest, artstation, unreal engine"
    },
    {
        "display": "Male character study, Tilda Swinton.",
        "full": "male character study of tilda swinton, 2 0 2 2, clear faces, emotional, character sheet, fine details, concept design, contrast, kim jung gi, pixar and da vinci, trending on artstation, 8 k, full body and head, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Anime mechanized soldier girl",
        "full": "side portrait of mechanized soldier girl, anime style, urban in background, soldier clothing, combat helmet, short hair, hair down, symmetrical facial features, from arknights, hyper realistic, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, d & d, realistic lighting, by alphonse mucha, greg rutkowski, backlit"
    },
    {
        "display": "Surreal low-poly cats in zero gravity.",
        "full": "duotone noir scifi concept illustration of 3 d mesh of lowpoly cats inside box floating zero gravity glowing 3 d mesh portals futuristic, glowing eyes, octane render, surreal atmosphere, volumetric lighting. accidental renaissance. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. trending on artstation"
    },
    {
        "display": "Elegant elderly woman portrait",
        "full": "elderly woman, white hair, gorgeous, amazing, elegant, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by Ross tran"
    },
    {
        "display": "Irish rebel soldiers marching",
        "full": "irish rebel soldiers in 1 9 1 6 marching down a street in dublin with their rifles, highly detailed, digital painting, concept art, sharp focus, by makoto shinkai"
    },
    {
        "display": "Cyborg fantasy portrait.",
        "full": "cyborg muscular handsome man as aeon flux profile picture by greg rutkowski, long bangs, going gray, dynamic pose, intricate, futuristic, fantasy, elegant, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell,"
    },
    {
        "display": "Elegant fantasy samurai illustration",
        "full": "samurai, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Tesla portrait with lightning.",
        "full": "nikola tesla, lightning, portrait, sharp focus, fantasy, digital art, concept art, dynamic lighting, epic composition, trending on artstation, by emylie boivin 2. 0, rossdraws 1. 0, artgerm 3. 0"
    },
    {
        "display": "Whimsical woman in moonlit lake.",
        "full": "a beautiful whimsical woman standing in a lake basking in the moonlight, underneath a multi-colored binary blackhole with an accretion disc, timelapse, by Lois van Baarle, by Greg Rutkowski, by artgerm, by beeple, cinematic angle, volumetric lighting, 4k resolution, octane render, trending on artstation, masterpiece"
    },
    {
        "display": "Dungeons and Dragons Kirby",
        "full": "dungeons and dragons kirby character full body side profile portrait, dramatic light, dungeon background, 2 0 0 mm focal length, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Elmo as Roman deity",
        "full": "elmo as the roman god of protection!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by ( ( ( artgerm ) ) ) and greg rutkowski! and ( ( alphonse mucha ) ), heavily influenced by frank frazetta and boris vallejo, sword and sorcery"
    },
    {
        "display": "Human paladin artwork",
        "full": "'human paladin, art by Greg Rutkowski, 4k'"
    },
    {
        "display": "Cottagecore cat in jetsuit",
        "full": "beautiful cottagecore cat wearing a jetsuit, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Christina Hendricks Portrait",
        "full": "perfectly - centered!! looking at the camera!!! full body portrait of christina hendricks with roses, baroque style, roses, intricate abstract upper body intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, deviantart, greg rutkowski, cinematic arthouse, key art, hyper realism, iridescent accents"
    },
    {
        "display": "Symmetrical male cyborg portrait",
        "full": "symmetry!! antonio banderas, male, machine parts embedded into face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Rat overlord on throne",
        "full": "rat overlord sitting on throne with hundreds of humans wordhipping, ssci - fi and fantasy, intricate and very very beautiful and elegant, highly detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration, art by greg rutkowski"
    },
    {
        "display": "Shiba Inu police officer.",
        "full": "shiba inu wearing a police uniform, holding a baseball bat on his hand, highly detailed digital art, atmosphere, glow, lens flare, cinematic lightning, hyperrealistic, focused, extreme details, 4 k, ultra detailed, trending on artstation, masterpiece, digital art."
    },
    {
        "display": "Steampunk cybercity landscape.",
        "full": "steampunk cybercity in a field of white flowers, godrays, cinematic, poster art by james gurney jesper ejsing, ilya kuvshinov, greg rutkowski frank frazzeta on artstation"
    },
    {
        "display": "Darth Vader as Napoleon.",
        "full": "darth vader dressed as napoleon - bonaparte!!!, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Magical summer portrait.",
        "full": "Beautiful Delicate Detailed portrait of sun summer woman, With Magical golden eyes by Tom Bagshaw, Bastien Lecouffe Deharme, Erik Johansson, Amanda Sage, Alex Grey, Alphonse Mucha, Harry Clarke, Josephine Wall and Pino Daeni, Delicate winter frozen creature With long golden Hair and Magical Sparkling Eyes, Magic Particles; Magic Swirls, in a out of this world magical summer landscape, 4K; 64 megapixels; 8K resolution concept art; detailed painting; digital illustration; hyperrealism; trending on Artstation; Unreal Engine Photorealistic, lifelike, Unreal Engine, sharp, sharpness, detailed, 8K"
    },
    {
        "display": "Adorable octopus in space",
        "full": "adorable baby octopus in a space station, elegant, intricate, retrofuturistic digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic DJ creating beats",
        "full": "futuristic focused dj inspired creating drum beats digging in crates hard drums vinyl records bass melody hiphop reggae dub style gangstarr king tubby studio gear golden ratio, art canvas, award winning, masterpiece trending on artstation 8 k 1 5 0 mpx"
    },
    {
        "display": "Hyperrealistic modern pinup portrait",
        "full": "modern woman | hyperrealistic | action pose | digital painting | trending on artstation | pinup portrait | clean | illustration | dressed | Unreal Engine 5 | 8k resolution | by Greg Rutkowski Alphonse Mucha Gustav Klimt and Mel Ramos"
    },
    {
        "display": "Futuristic industrial cityscape.",
        "full": "futuristic city center with maglev train station in background, modern landscape architectural design for industrialpunk concrete and glass, maroon metallic accents, gorgeous lighting, golden hour, cyberpunk, 2077, dramatic lighting and composition, photography, 8k, origin 100i, star citizen concept art, single line, golden ratio"
    },
    {
        "display": "Anime-style baroque knight illustration.",
        "full": "baroque oil painting japanese light novel cover illustration anime key visual full body action shot | young girl wearing black military crusader armour riding horse with long flowing blonde hair | rule of thirds golden ratio beautiful composition | lotr gapmoe kuudere moody lighting bokeh stunning highlights sharp contrast | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Space Chimpanzee in Suit",
        "full": "chimpanzee that was left in space, wearing space suit. interesting 3 d character concept by tiger hkn and gediminas pranckevicius, maplestory, game art, hyper detailed, character modeling, cartoon, cinematic, raytrace, concept art, trend on behance 3 d art, v - ray, maya, c 4 d"
    },
    {
        "display": "Epic frostpunk landscape",
        "full": "frostpunk portal, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Anime girl in tactical gear",
        "full": "girl wearing tactical gear, very anime, fine - face, audrey plaza, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Foliage portrait double exposure",
        "full": "intricate foliage beauty double exposure portrait, artstation, tony sart, photorealism, grayscale, otherworldly fantasy illustration, by artgerm"
    },
    {
        "display": "Giant Jesus Mecha Statue",
        "full": "a giant statue of jesus mecha, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, trending on artstation, cinematic composition, beautiful lighting, hyper detailed, 8 k, oil on canvas"
    },
    {
        "display": "Cottagecore princess with robot",
        "full": "beautiful space cottagecore princess rosalina holding a chibi robot wearing a scifi jetsuit, intricate bioluminescent highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and ilya kushvikov and alphonse mucha"
    },
    {
        "display": "Photorealistic hybrid creature.",
        "full": "A Trex-Elephant-Monkey crossbreed, illustrated by Antoine Verney-Carron, trending on artstation, studio light on skin, photorealistic imagery, photorealistic 3d render, intricate, detailed, intricately detailed, 4k, 8k, artstation 3d render, artstation 3d, artstation graphics, artstation lighting"
    },
    {
        "display": "Sailboat in sky at sunset",
        "full": "a small sailboat flying through the sky, fluffy clouds, red-pink sunset, floating, fantasy, concept art, illustration, artstation award, bright"
    },
    {
        "display": "Aesthetic portrait of Spock.",
        "full": ": + aesthetic portrait commission of a of ACCURATE YOONGI YOONGI YOONGI is SPOCK + VEINY HANDS + hyperdetailed face at golden hour, safe for work (SFW). Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, 2021 award winning film poster painting"
    },
    {
        "display": "Realistic Super Mario portrait",
        "full": "super mario, realistic, portrait, intricate, detailed, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Ancient Roman warlock poster",
        "full": "modern movie poster of a ancient roman warlock with magic lovecraftian tentacles in the background, dungeons and dragons artwork, dramatic lighting, digital painting, masterpiece, by leonardo da vinci, raphael, artgerm, greg rutkowski, vibrant colors"
    },
    {
        "display": "Burning Aztec city, ships.",
        "full": "many 15th century ships surrounding a burning Aztec city located on an island in the middle of a lake, aerial view, high detail, by Craig Mullins, Peter Mohrbacher, unreal engine, octane rendered, 8K, dark beauty, trending on artstation"
    },
    {
        "display": "Giant UFO over Gobekli Tepe.",
        "full": "a hyperrealist watercolor concept art of a giant ufo in the sky above gobekli tepe during a thunderstorm. a medieval monk in grey robes is in the foreground. very muted colors, by rebecca guay, michael kaluta, charles vess. high detail, hq, wide shot, 4 k"
    },
    {
        "display": "Flying angel-winged limousine.",
        "full": "Angel-winged limousine flying into a cloudy but sunny pink sky - rainbow on the background - ultradetailed, trending on artstation, devianart, cgsociety"
    },
    {
        "display": "Nordic girl in dark fantasy.",
        "full": "Photo of nordic girl, face, dark fantasy, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, XF IQ4, 150MP, 50mm, f/1.4, ISO 200, 1/160s, natural light, Adobe Photoshop, Adobe Lightroom, DxO Photolab, polarizing filter, Sense of Depth, AI enhanced, HDR, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Warriors battling at apocalypse",
        "full": "! dream digital art, trending on artstation, two warriors fighting at world's end"
    },
    {
        "display": "Wuxia female portrait art",
        "full": "attractive female, wuxia, painting by gaston bussiere, craig mullins, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Cinematic forest photoshoot.",
        "full": "real life photo of a beautiful girl, full body photoshoot, long brown hair, brown eyes, full round face, short smile, belly free, forest setting, cinematic lightning, medium shot, mid - shot, highly detailed, trending on artstation, unreal engine 4 k, 8 0 mm, 8 5 mm, cinematic wallpaper"
    },
    {
        "display": "Amphibian beast in space.",
        "full": "photoreal, full body portrait of an amphibian beast in a space helmet on a rocky planet, nebula milky way background, by norman rockwell and boris vallejo, artstation, concept character art"
    },
    {
        "display": "Letter \"N\" coin illustration",
        "full": "coin with the letter n, 4 k, octane, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lost sand city concept art",
        "full": "cover concept art of the lost sand city, levitating sand, golden towers, golden pillars, palm trees, space and time, floating objects, post-processing, in the style of Hugh Ferriss, Behance, Artgerm. High detail, ultra realistic render, octane, 3D, photorealism, symmetric, cinematic from the  academyumbrella"
    },
    {
        "display": "Levitating goddess mermaid portrait",
        "full": "portrait of a levitating floating in space goddess mermaid with (reaction diffusion) scaled fish skin Bioluminescent phoenix jellyfish, phoenix fire, chimera, energy rays, Her breath shot a haze of steam out into the frosty morning air concept, soft light, soft mood, realistic body features and face, illustration,intricate ornament halo, painting oil on canvas by Elena Zhurikhina and Goro Fujita and Charlie Bowater, octane render trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Realistic portrait of pregnant Evangeline Lilly.",
        "full": "pregnant evangeline lilly in a tank top, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fluffy humanoid cat seahorse fursona",
        "full": "cat seahorse fursona wearing headphones, autistic bisexual graphic designer and musician, attractive genderless fluffy humanoid character design, sharp focus, weirdcore voidpunk digital art by artgerm, akihiko yoshida, louis wain, simon stalenhag, wlop, noah bradley, furaffinity, quantum wavetracing, artstation hd, trending on deviantart"
    },
    {
        "display": "Intricate Atlantis artwork",
        "full": "atlantis, acrilic paint, digital, artstation, detailed intricate ink illustration, heavenly atmosphere, digital art, overdetailed art, concept art, complementing colors, trending on artstation, cgstudio, the most beautiful image ever created, dramatic, subtle, details, award winning artwork, beautiful scenery"
    },
    {
        "display": "Cinematic bedroom with wires",
        "full": "rgb wires, empty room, cinematic, movie scene, inspired by zdzislaw beksinski, veins,, cables everywhere, bedroom, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k"
    },
    {
        "display": "James Hetfield Portrait, Fantasy Art",
        "full": "highly detailed portrait of james hetfield in gta v, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, artgerm, pixar, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Dante Alighieri in Sky",
        "full": "Dante Alighieri with a sky background, rendered by Makoto Shinkai, syd meade, environment concept, digital art, unreal engine, WLOP, trending on artstation, low level, 4K UHD image, octane render"
    },
    {
        "display": "Alien skull in nebula.",
        "full": "alien skull on space nebula background, acrilic paint, digital, artstation, detailed intricate ink illustration, heavenly atmosphere, digital art, overdetailed art, concept art, complementing colors, trending on artstation, cgstudio, the most beautiful image ever created, dramatic, subtle, details, award winning artwork, beautiful scenery"
    },
    {
        "display": "Baroque anime cityscape painting",
        "full": "baroque oil painting of anime key visual concept art of girl's last tour scenery official art, white clean abandoned concrete cityscapes, trending on artstation, palette knife and brush strokes, oil on canvas, style of makoto shinkai greg rutkowski studio ghibli"
    },
    {
        "display": "Obese white dragon artwork",
        "full": "a dynamic painting of a gigantic obese white dragon, a fat tank monster, baroque, concept art, deep focus, fantasy, intricate, highly detailed, digital painting, artstation, matte, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Marble statue of cyborg girl",
        "full": "a statue made of white marble, of an beautiful girl, full body shot, perfect symmetrical body, red biomechanical, inflateble shapes, wearing epic bionic cyborg implants, masterpiece, intricate, biopunk futuristic wardrobe, vogue, highly detailed, artstation, concept art, background galaxy, cyberpunk, octane render"
    },
    {
        "display": "Portrait of a male navy captain.",
        "full": "portrait sabouts, male, navy captain hat, digital camo, red hair, red beard commander, male!! digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Sora in forest ruins.",
        "full": "intricate detailed portrait painting of sora from kingdom hearts a beautiful forest meadow, temple ruins surrounded by lush forest, afternoon, intricate, elegant, highly detailed, digital painting, sharp, focus, illustration art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lovecraftian Pixar Einstein portrait",
        "full": "lovecraft lovecraftian portrait of einstein, pixar style, by tristan eaton stanley artgerm and tom bagshaw, retro future"
    },
    {
        "display": "Anime sailor portrait",
        "full": "portrait of sailor of the storm, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, trending on artstation"
    },
    {
        "display": "Obese dog skateboarding illustration",
        "full": "morbidly obese dog riding a skateboard, illustration, full body, high quality, intricate details, details, craig mullins, intricate, atmosphere, highly detailed, matte painting, cinematic, deviantart, realistic, photorealistic, concept art"
    },
    {
        "display": "Gemstone rubber duck in perfume.",
        "full": "shiny gemstone in the shape of a rubber duck floating in a pool of perfume, photorealism, mystical, enigmatic, digital oil painting, trending on artstation"
    },
    {
        "display": "Yeezy x Golf Le Fleur Fashion",
        "full": "a photoshoot for a yeezy x golf le fleur collaborative fashion line, 8 k concept art, detailed, pink skies, vintage, dreamy, earthy"
    },
    {
        "display": "Portrait of Ava Gardner",
        "full": "beautiful head and shoulders portrait of ava gardner, casual clothing, intricate, elegant, highly detailed, digital painting, beautiful highly detailed face, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elvira pin-up portrait",
        "full": "a ( beautiful masterpiece highly detailed ) full body portrait pin - up illustration of intimate alluring elvira! mistress of the dark by ralph horsely and artgerm and joe jusko, raven black hair, porcelain white skin. bettie page, vampira, morticia addams, curvy, sexy. trending on artstation"
    },
    {
        "display": "Moon goddess portrait, mystical.",
        "full": "portrait painting of a beautiful moon goddess, wearing a dark hooded robe, sacred tattoos on her face, smirking, holding a giant astral scyther, ultra realistic, concept art, intricate details, mystical, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed female android portrait",
        "full": "ultra detailed beautiful female android, side portrait, sharp focus, highly detailed vfx portrait, scribble art!!, speed painting!!, geometric shapes, global illumination, by james jean and moebius and artgerm and liam brazier and victo ngai and tristan eaton. vector art, digital illustration, concept art, dia de los muertos, ( ( ( skull ) ) ). 8 k, hdr"
    },
    {
        "display": "Ethereal portrait of smiling girl.",
        "full": "epic portrait of a beautiful girl with an unnaturally wide smile, beautiful!, dewy skin, ethereal, painting, concept art, warm lighting, greg rutkowski"
    },
    {
        "display": "Muscular warrior in dystopia.",
        "full": "portrait shot of muscular warrior in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Human hand digital art",
        "full": "human hand sketch, painting by Craig Mullins, 4k, octane, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha,"
    },
    {
        "display": "Legendary giant horse-dog.",
        "full": "the_legendary_huge_horse_dog_made_by_Stanley_Artgerm_Lau_WLOP_Rossdraws_ArtStation_CGSociety_concept_art_cgsociety_octane_render_trending_on_ar_-n_9"
    },
    {
        "display": "Nathan Fillion as Bond.",
        "full": "Nathan Fillion as James Bond in Goldeneye 007 in an air vent above bathroom cubicle, D&D, cinematic, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse much"
    },
    {
        "display": "Mechanical hippo on desert road",
        "full": "a study of cell shaded cartoon of a mechanical hippo on a desert road, in front of a big moon illustration, wide shot, subtle colors, post grunge, concept art by josan gonzales and wlop, david rubin, mike mignola, laurie greasley, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Cabal of Priests Tarot Card",
        "full": "an ultra detailed tarot card of a cabal of the patriarch's senior priests in black robes with their hood up and pitch black eyes, concept art, incense smoke drifting through the air, portrait, artstation, volumetric lighting, exquisite detail, octane render, 8 k postprocessing, art by john collier and albert aublet and artem demura and alphonse mucha"
    },
    {
        "display": "Demon hero in armor.",
        "full": "human male demon, full body white purple cloak, hero, heavy futuristic armor, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Ethereal woman pouring water.",
        "full": "greg rutkowski and wlop woman of tarot card the star card shows a woman kneeling at the edge of a small pond she is holding two containers of water one container hyperrealistic beautiful sexy ethereal white north pole girl portrait art nouveau fantasy intricate flower designs elegant highly detailed sharp focus art by artgerm pours the water into the river soul care nature peace galaxies female white fine line fractal glow"
    },
    {
        "display": "Gina Carano as Ork",
        "full": "gina carano as a shadowrun ork with prothesis grey metallic arm, casual black clothing, muscular, realistic proportions, casual pose, large portrait, sci - fi, rpg character, digital painting, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Elf queen portrait, fantasy.",
        "full": "Portrait of Beautiful blonde elf queen with opal eyes and elf ears, league of legends, LOL, fantasy, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Muscular Rigoberta Mench\u00fa Portrait",
        "full": "epic portrait of muscular rigoberta menchu wearing short sleeved uniform explaining, guatemla crowd for background, detailed, digital painting, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, breathtaking, high details, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Dionysus Tarot Card Portrait",
        "full": "a tarot card portrait of dionysus, realistic, 8 k, by greg rutkowski, artgerm, alphonse mucha, ornate, vines as jewelry, symmetry, sharp focus"
    },
    {
        "display": "Goddess with ram skull mask",
        "full": "goddess macro close - up portrait with crown and mask made of ram skull. beautiful intricately detailed japanese crow kitsune mask and clasical japanese kimono. betta fish, jellyfish phoenix, bioluminescent, plasma, ice, water, wind, creature, artwork by tooth wu and wlop and beeple and greg rutkowski"
    },
    {
        "display": "Epic space soldier embrace",
        "full": "epic portrait an space soldier hugging an female medic soldier, battlefield, explosions, cloudy, digital painting, artstation, concept art, soft light, hdri, smooth, sharp focus, illustration, fantasy, intricate, elegant, highly detailed, D&D, matte painting, in the style of Greg Rutkowski and Alphonse Mucha and artemisia, 8k, highly detailed, jurgens, rutkowski, bouguereau, pastoral, rustic, georgic, detailed concept art, illustration, colorful pastel, painting, detail, ultra detailed, digital art, 4K,"
    },
    {
        "display": "Misa Amane in gothic attire",
        "full": "misa amane, gothic black and red dress, blonde hair. by rembrandt, ( ( konstantin razumov ) ), artgerm"
    },
    {
        "display": "Sakura palace at night",
        "full": "a beautiful picture of sakura in full bloom, palace, evening, night, by greg rutkowski and thomas kinkade, trending on artstation"
    },
    {
        "display": "Cyborg Snoop Dogg in studio",
        "full": "a fisheye lens photo of a post apocalyptic tribal cyborg snoop dogg tweaking and playing synthesizers in the most complicated and technical spiral fractal musical studio, powerful, cinematic, beautifully lit, by donato giancola, by artgerm, by karol bak, 3 d, perfect face and body, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Girl in kimono with bear",
        "full": "a girl wearing a kimono giving a peach to a large anthropomorphic asian black bear, featured in artstation, artgerm, octane render, award winning, cinematic, elegant, intricate, 8 k, in the style of heikala and timothy kong and laia lopez and viorie,"
    },
    {
        "display": "Cyborg girl juggling fire",
        "full": "fire juggling cyborg girl by wagner kuroiwa tristan eaton, stanley artgermm, tom bagshaw, greg rutkowski, carne griffiths, ayami kojima, beksinski, unreal engine, trending on deviantart, photorealistic, trending on artstation, by greg rutkowski, high key lighting, volumetric light, art by jean delville, rene magritte vray, hdr, hyper detailed, dramatic lighting, octane render, weta digital, 3 d sculpture"
    },
    {
        "display": "Cozy anime portrait, mirror room.",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai, cute beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. deep water bellow. realistic photographic face. dramatic deep light, trending on artstation, oil painting brush"
    },
    {
        "display": "Post-apocalyptic digital landscape",
        "full": "fallout today, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Cyberpunk Piltover City",
        "full": "wide angle view of piltover underground cyberpunk city, league of legends arcane, highly detailed, digital painting, artstation, concept art, octane render, by federico pelat and liam wong and jonas roscinas and damien peinoit"
    },
    {
        "display": "Borscht with bread painting",
        "full": "painting of a borscht with a crust of black bread with garlic on a self - assembled tablecloth, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Cute anthropomorphic polar bear",
        "full": "a super cute anthropomorphic polar bear humanoid in a snow jacket from final fantasy, holding a snowboard, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Angel knight concept art",
        "full": "concept art, angel knight girl. by artstation trending, by joseph mallord william turner, luis royo, konstantin razumov, cinematic lighting, highly detailed"
    },
    {
        "display": "Strider Hiryu as Street Fighter",
        "full": "strider hiryu as a street fighter character, cg animation, capcom, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Elegant sorceress portrait",
        "full": "portrait shot, natalie portman, sorceress, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Elegant knight in porcelain armor",
        "full": "portrait knights of Zodiac girl, Chinese Blue and white porcelain color reflected armor, in ruined Agora of Athens, ssci-fi, fantasy, intricate, very very beautiful, elegant, golden light, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by tian zi and WLOP and alphonse mucha"
    },
    {
        "display": "Eagle warrior in forest.",
        "full": "a female anthropomorphic eagle warrior. She has two wings on her back. Her skin is covered in feathers. Forest, clearing. Full shot, wings are focus. Atmospheric lighting, By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "Goddess of Flamingo Artwork",
        "full": "breathtaking detailed concept art painting of the goddess of flamingo, orthodox saint, with anxious, piercing eyes, ornate background, epic composition, amalgamation of leaves and flowers, by Hsiao-Ron Cheng and John James Audubon and Miho Hirano, extremely moody lighting, 8K"
    },
    {
        "display": "Regal mantis in armor",
        "full": "attractive white haired preying mantis wearing white dove wings, warframe armor, regal, attractive, ornate, sultry, sexy, beautiful, elize theron, pretty face, green eyes, scifi platform, 4 k, ultra realistic, epic lighting, illuminated, cinematic, black gold, art by akihito tsukushi, voidstar, artgerm"
    },
    {
        "display": "Bruce Lee with dynamic aura",
        "full": "bruce lee, yellow, spike aura in motion, floating pieces, painted art by tsuyoshi nagano, greg rutkowski, artgerm, alphonse mucha, spike painting"
    },
    {
        "display": "African princess portrait",
        "full": "a portrait of a african princess of china and japan, at the throne room, soft light, finely detailed features, perfect art, at an ancient city, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli, akihiko yoshida"
    },
    {
        "display": "Magical Slavic cityscape.",
        "full": "hyperrealism magical ancient Slavic city of Kitezh in mist, magical glow, magic mist, strange buildings, oil painting, painting by Viktor Vasnetsov, concept art, fantasy cityscape, ancient Russian architecture, painting by Ivan Shishkin, hyperborea, high resolution, huge factories, trending on artstation,"
    },
    {
        "display": "Attractive lady portrait",
        "full": "portrait lady miss woman focus attractive eye enchanted official fanart behance hd artstation by Jesper Ejsing, by RHADS, Makoto Shinkai and Lois van baarle, ilya kuvshinov, rossdraws portrait, highly detailed, digital painting, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha radiant light, peter mohrbacher, ferdinand knab, artgerm, portrait  optimistic colors, bright eyes, clear eyes, warm smile"
    },
    {
        "display": "Middle age ranger with falcon.",
        "full": "middle age ranger with rugged expressions falcon pet on his shoulder holding a high detailed long sword, top a cliff observing old ruins of a castle, elegant clothing, photorealistic render, matte painting, highly detailed, artstation, smooth, sharp focus, art by michael whelan, artgerm, greg rutkowski"
    },
    {
        "display": "Kendall Jenner as fantasy character",
        "full": "kendal jenner, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Solarpunk goddess portrait.",
        "full": "3 d goddess close - up profile solarpunk portrait ram skull. beautiful intricately detailed japanese crow kitsune mask and clasical japanese kimono. betta fish, jellyfish phoenix, bio luminescent, plasma, ice, water, wind, creature, artwork by tooth wu and wlop and beeple and greg rutkowski"
    },
    {
        "display": "Cyberpunk technician portrait",
        "full": "portrait painting of a cyberpunk technician with crazy hair, sharp focus, award - winning, trending on artstation, masterpiece, highly detailed, intricate. art by josan gonzales and moebius and deathburger"
    },
    {
        "display": "Kim Kardashian by lake.",
        "full": "rear shot film still of kim kardashian wearing high waist jeans, standing by a lake, intricate, elegant, highly detailed, smooth, sharp focus."
    },
    {
        "display": "Post-apocalyptic sunset city",
        "full": "sunset over a post apocalyptic city, fantasy, intricate, elegant, dramatic lighting, highly detailed, lifelike, photorealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by john collier and albert aublet and krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Ben Affleck Superman Portrait",
        "full": "ben affleck as superman, portrait,, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Native American shaman illustration",
        "full": ": female native american shaman, fantasy magic, celtics, ireland, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, jahbu art ancient, cosmos, cosmic"
    },
    {
        "display": "Tiger-butterfly hybrid painting.",
        "full": "painting of cross between tiger & butterfly, intercrossed animal, by zdzislaw beksinski, by tiffany bozic, gradient background, concept art, beautiful composition, digital painting,"
    },
    {
        "display": "Alien landscape with mountains",
        "full": "diamond graphite mountains breaking huge dust clouds on an inhospitably hot alien planet, massive blue sun taking up sky, concept art by albert biertadt, thomas cole, frederic edwin church, hudson river school, majestic, awe - inspiring, breathtaking"
    },
    {
        "display": "Elegant wraith dragon illustration",
        "full": "a wraith dragon flapping it's wings, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime girl on railway.",
        "full": "anime girl with short black hair, sits on an abandoned railway track leading to the end of the world, at midnight, clear sky, view from above, digital concept art illustration by akihiko yoshida and jean baptiste monge and makoto shinkai and wlop and and tsutomu nihei, 4 k artstation"
    },
    {
        "display": "Gigachad Luigi Pilot Art",
        "full": "gigachad luigi bodybuilder in tokyo as a pilot by ilya kuvshinov, ernest khalimov body by krista sudmalis, super mario bros symmetrical face concept art, hyper realistic, intricate, elegent, highly detailed, digital painting, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Anime girl flying with dragon",
        "full": "anime girl with a white long dress flies with a dragon, pixiv digital art, 4 k, trending on artstation"
    },
    {
        "display": "Futuristic Japan at night.",
        "full": "japan in a science - fiction style by the lake, with gigantic futuristic buildings, a machine whale in the sky, midnight, andreas rocha, krenz cushart, artstation, concept art, smooth, sharp focus, 8 k, octane render, volumetric, vivid, beautiful, hyperrealism"
    },
    {
        "display": "Bright, colorful alien portrait.",
        "full": "bright, colorful, realistic, detailed from Elder Scrolls: shivering isles concept art starwars alien portriat backlighting, kodachrome, high contrast, highly detailed, sharp focus, digital painting, concept art, illustration, trending on artstation, comic book by Alex Ross and Adam Adamowicz cover art"
    },
    {
        "display": "Cyberpunk female portrait",
        "full": "\u00ef\u00bf\u00bd beautiful portrait of a cyber punk female by marco mazzini and hanna yata \u00ef\u00bf\u00bd digital art, concept art, 3 - d 4 k, fantasy, devian art, trending on art station,"
    },
    {
        "display": "Dreamy baroque motherboard landscape",
        "full": "motherboard, chip circuit, computer, dream - like atmosphere, baroque landscape painting, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, raphael, caravaggio, greg rutkowski, beeple"
    },
    {
        "display": "Epic hellish landscape.",
        "full": "Gates of hell by paul chadeison, concept art, ultra realistic, super detailed, photorealistic,fire, smoke, cinematographic, epic lighting, religious"
    },
    {
        "display": "Futuristic jester character.",
        "full": "front shot of a court jester!!!! character, polished, glossy, holographic crystals, bionic futurism, robotic cyborg, 8 5 mm f 1. 8, ominous elegant ( ( intricate ) ) highly detailed concept artstation sharp focus ray tracing cinematic masterpiece fine portrait, by artgerm greg rutkowski tooth wu beeple"
    },
    {
        "display": "Astronaut with fishbowl head.",
        "full": "character concept of an astronaut suit with a fishbowl for a head. vibrant colors, soft lighting, 8 k, octane render. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Charming viscount in town hall",
        "full": "david gandy, charming, in a town hall, rich viscount, laughing, fit, cunning, gold and purple, warhammer, lord of the rings, sweaty, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Wakandan warrior in hybrid armor.",
        "full": "wakandan warrior with cyberpunk and steampunk style armor wearing a long hooded cloak that is a bit torn, ultra realistic, concept art, intricate details, eerie, horror, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Demonic cyberpunk duchess portrait",
        "full": "a portrait of a beautiful demonic cybernetic grand duchess of hell, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Apex Legends Thanos Portrait",
        "full": "portrait of apex legends thanos, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, artey freytag"
    },
    {
        "display": "Detailed fantasy portrait.",
        "full": "old man with a ring on each finger, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Raven on glowing crystals.",
        "full": "portrait of a beautiful one raven perched on purple crystals that are glowing in a misty valley, establishing shot, extremly high detail, foto realistic, cinematic lighting, by yoshitaka amano, ruan jia, kentaro miura, artgerm, post processed, concept art, artstation, raphael lacoste, alex ross"
    },
    {
        "display": "Psychedelic organic cyborg art.",
        "full": "psychedelic tron legacy organic cyborg, hyper realistic, ambient lighting, concept art, intricate, hyper detailed, smooth, dynamic volumetric lighting, cinematic, high quality, high resolution, 4 k, cgsociety, rutkowski, gurney, mucha, art nouveau"
    },
    {
        "display": "Female vampire portrait",
        "full": "female vampire, perfect face, gold waistcoat, long grey hair, red necktie, cinematic, stunning, highly detailed, digital painting, artstation, smooth, hard focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bionic geisha in tatami room",
        "full": "the oriental female bionic artificial intelligence geisha in kimono is white, tender and beautiful. behind it is a japanese tatami room painted with a wave ukiyo screen, game character concept art, wide view, high detailed, craig mullins, peter mohrbacher, unreal engine, 8 k, dark beauty, trending on artstation"
    },
    {
        "display": "Madison Beer in surreal Russian attire",
        "full": "madison beer, traditional russian clothing, fame of thrones, fibonacci, sweat drops, intricate fashion clothing, insane, intricate, highly detailed, surrealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rose goddess portrait, fantasy.",
        "full": "portrait of a beautiful rose goddess, unusual beauty, esoteric, outher worldly colours, head in focus, fantasy art, ornamental rose turquoise aesthetics,, intricate, elegant, highly detailed hyperrealistic painting, artstation, concept art, painterly, golden ratio, sharp focus, illustration, art by tomasz alen kopera"
    },
    {
        "display": "Cyberpunk couple portrait",
        "full": "a cyberpunk 2077 srcreenshot couple portrait of Keanu Reeves and a female android final kissed in night city,love,romantic,film lighting,by Lawrence Alma-Tadema,Andrei Riabovitchev,Laurie Greasley,Dan Mumford,John Wick,Speed,Replicas,artstation,deviantart,FAN ART,full of color,intricate,concept art,smooth,elegant,Digital painting,illustration,sharp focus,face enhance,highly detailed,8K,octane,golden ratio,cinematic lighting"
    },
    {
        "display": "Margot Robbie as Batgirl.",
        "full": "margot Robbie as bat girl, painting, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Anime duck farmer portrait",
        "full": "portrait of the duck farmer, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, comixwave films, trending on artstation"
    },
    {
        "display": "Mermaids at desert oasis.",
        "full": "a group of mermaids lounging around a desert oasis, drinking nectar, by artgerm and wlop and scott fischer and seb mckinnon, digital art, highly detailed, wide shot, intricate, fantasy, mystical, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Rugged Zeus Portrait",
        "full": "painted portrait of rugged zeus, greek god, white hair, masculine, mature, handsome, upper body, muscular, hairy torso, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by gaston bussiere and craig mullins"
    },
    {
        "display": "Melanie C as Harley Quinn.",
        "full": "portrait of Melanie C as a harley quinn. mark arian, artgerm. octane render, trending on artstation, symmetrical artwork. cinematic, octane render, 8k"
    },
    {
        "display": "Winter WWII cinematic scenes",
        "full": "Concept art of random World War 2 cinematic scenes in winter, german and soviet soldiers, some tanks and buildings destroyed, blue sky, mist, boixcar style, matte painting, vaporwave vaporwave vaporwave watercolor cover art with warm and vibrant colors, volumetric light, oil on canvas art by Greg Rutkowski , award-winning masterpiece with incredible and beautiful details digital art, trending on artstation, smooth, HD, 4K"
    },
    {
        "display": "Heavenly male god portrait",
        "full": "portrait of a heavenly god, male, red and white and gold, masculine, beard, upper body, muscular, fantasy, intricate, elegant, dramatic lighting, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Robot warriors in arena.",
        "full": "a massive arena filled with robot warriors encircling the Supreme Leader in a white dress, pristine concept art, small, medium and large design elements, golden hour, in the style of WLOP and Ross Tran"
    },
    {
        "display": "Post-apocalyptic nomad rider.",
        "full": "hyper realistic photo of postapocalyptic nomad rider, full body, cinematic, artstation, cgsociety, greg rutkowski, james gurney, mignola, brom,"
    },
    {
        "display": "Cyberpunk SWAT officer illustration",
        "full": "nami, anime key visual of a young female swat officer, neon, cyberpunk, futuristic, white clothing, black vest, swat helmet, stunning, highly detailed, digital painting, smooth, soft focus, illustration, 4 k digital art from artstation by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute medieval spymaster illustration.",
        "full": "cute medieval spymaster holding knife, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Colorful winged possum flying.",
        "full": "the legendary colorful winged possum is flying over a medieval castle under the dark starred sky, dark fantasy, watercolor, dreaming illusion,  highly detailed, 4k, trending on Artstation, award-winning"
    },
    {
        "display": "Astronaut in underwater desert",
        "full": "astronaut holding a flag in an underwater desert. a submarine is visible in the distance. dark, concept art, cinematic, dramatic, atmospheric, 8 k, trending on artstation, blue, fish, low visibility, light rays, extremely coherent, fog, ocean floor, christopher nolan, interstellar"
    },
    {
        "display": "Punk rock woman torso shot.",
        "full": "torso shot of a beautiful punkrock woman in tank top, art by ilya kuvshinov and lois van baarle and sam yang and ross tran and wlop and artgerm, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Predatory alien concept art.",
        "full": "professional ominous concept art of a predatory alien by artgerm and greg rutkowski ( thin white border ). an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp focus, illustration, in the style of simon stalenhag wayne barlowe, igor kieryluk."
    },
    {
        "display": "Man pointing at gas tank",
        "full": "man in a sweater pointing at a gas tank, sign above the gas tank reads'gas ', intricate, sharp focus, lens flare, bloom, illustration, highly detailed, digital painting, concept art, matte, art by ruan jia and wlop and greg rutkowski, masterpiece"
    },
    {
        "display": "Epic Knossos Digital Painting",
        "full": "a picture of a knossos, epic, detailed, intricate, digital painting, concept art, realistic, smooth, focus, rim light"
    },
    {
        "display": "Yang Xiao Long pose, cinematic.",
        "full": "Yang Xiao Long posing. By ilya kuvshinov, krenz cushart, Greg Rutkowski, trending on artstation. Glossy materials, sharp highlights, amazing textured brush strokes, accurate shape, clear curvy details, cinematic soft volumetric studio lighting, with backlight, VFX, HDR"
    },
    {
        "display": "Hyper-detailed cyberpunk city",
        "full": "digital painting of a cyberpunk city by Dan Mumford, trending on Artstation, hyperdetailed"
    },
    {
        "display": "Female Jedi superhero concept",
        "full": "character sheet concept art of female jedi superhero from halo and destiny 2, hooded opalescent diaphanous robe with intricate gold inlay, realistic, hyper realistic, photographic, costume, by beeple, wlop, dan mumford, greg rutkowski, high detail, octane render, 8 k, intricate"
    },
    {
        "display": "Ultra-realistic bikini portrait",
        "full": "sexy painting of rebecca black, bikini, ultra realistic, sharp details, subsurface scattering, intricate details, warm lighting, beautiful features, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk castle concept art",
        "full": "flying steampunk castle, concept art, highly detailed, artstation, trending, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "Elegant demoness illustration",
        "full": "demoness, female, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and steve argyle and alphonse mucha and johannes voss"
    },
    {
        "display": "3D KDA Ahri magazine cover.",
        "full": "Magazine cover with KDA Ahri (League of Legends). 3d render, octane render, game art, realistic, highly detailed, trending on artstation, 4k, trending on artstation, pixar, cgsociety, unreal engine 5, redshift render, trending on artstation, blender, behance, cg"
    },
    {
        "display": "Goddesses with floral elements.",
        "full": "breathtaking detailed weird concept art painting of few goddesses of light pink flowers, orthodox saint, with anxious, piercing eyes, ornate background, amalgamation of leaves and flowers, by Hsiao-Ron Cheng, extremely moody lighting, 8K"
    },
    {
        "display": "Gothic warrior queen illustration",
        "full": "Gothic muscular warrior queen in blue and black mythical heavy armor, fantasy, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy paladin portrait",
        "full": "side portrait, imogen poots, paladin, fantasy, gloomhaven, luminescent, organic painting, matte painting, bold shapes, hard edges, gaudy colors, octane render, unreal engine, by greg manchess, huang guangjian, greg rutkowski, jesper ejsing, magali villeneuve, jeremy lipkin, artgerm"
    },
    {
        "display": "Brown men on book.",
        "full": "a painting of three brown men astanding on top of a giant open book, hyperrealistic faces, detailed digital art, aesthetic!, trending on artstation"
    },
    {
        "display": "Gigachad Luigi pilot illustration.",
        "full": "gigachad luigi bodybuilder in tokyo as a pilot by ilya kuvshinov, ernest khalimov body by krista sudmalis, super mario bros symmetrical face concept art, hyper realistic, intricate, elegent, highly detailed, digital painting, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Regal warrior princess illustration",
        "full": "A beautiful digital painting of a strong woman with blond haire, in a regal armor, princess, a ghostly bear behind her, D&D, fantasy, intricate, cinematic lighting, highly detailed, digital painting, Artstation, concept art, smooth, sharp focus, illustration, art by Artgerm and Greg Rutkowski, Alphonse Mucha and charlie bowater"
    },
    {
        "display": "Toad watching movie, popcorn.",
        "full": "hyper realistic oil painting of toad watching a movie on the couch and eating some popcorn, vibrant colors, wearing 3 d glasses, high contrast, by greg rutkowski, trending on artstation"
    },
    {
        "display": "Retro robot city plaza.",
        "full": "street level view of empty plaza in retro robot city at the other side of nowhere, sharp digital painting. retrofuturism. concept art. artstation. casey weldon. digital render. dan mumford."
    },
    {
        "display": "Elaborate floral crown portrait",
        "full": "a close-up portrait of a gorgeous Rena Rounen aka Nano in front of an ornate rococo mandala wearing a huge elaborate detailed ornate crown made of all types of realistic colorful flowers, turban of flowers, sacred Geometry, Golden ratio, surrounded by scattered flowers peonies dahlias lotuses roses and tulips, photorealistic face, Cinematic lighting, rimlight, detailed digital painting, Portrait, headshot, in style of Artgerm, WLOP, Peter Mohrbacher, William adolphe Bouguereau, cgsociety, artstation, Rococo and baroque styles, symmetrical, hyper realistic, 8k image, 3D, supersharp, pearls and oyesters, turban of vibrant flowers, satin ribbons, pearls and chains, perfect symmetry, iridescent, High Definition, Octane render in Maya and Houdini, light, shadows, reflections, photorealistic, masterpiece, smooth gradients, no blur, sharp focus, photorealistic, insanely detailed and intricate, cinematic lighting, Octane render, epic scene, 8K"
    },
    {
        "display": "Cyberpunk portrait of Hwasa",
        "full": "portrait painting of cyberpunk hwasa mamamoo with a halo and devil horns, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Sakura plum tree concept art",
        "full": "highly detailed concept art of a sakura plum tree made with water, overgrowth, Artgerm, Ferdinand Knab, Makoto Shinkai"
    },
    {
        "display": "Dramatic portrait of Ameera.",
        "full": "a Photorealistic dramatic hyperrealistic  ameera al taweel, blue eyes, black hair, white veil,by WLOP,Artgerm,Greg Rutkowski,Alphonse Mucha, Beautiful dynamic dramatic dark moody lighting,shadows,cinematic atmosphere,Artstation,concept design art,Octane render,8K"
    },
    {
        "display": "Elegant Greek woman portrait",
        "full": "a gorgeous greek female photo, professionally retouched, muted colors, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, sharp focus on eyes, 8 k, high definition, insanely detailed, intricate, elegant, art by j scott campbell and artgerm"
    },
    {
        "display": "Anime samurai illustration",
        "full": "anime samurai vagabond, manga,detailed, studio lighting, gradation,editorial illustration, matte print, Ilya Kuvshinov, concept art, digital"
    },
    {
        "display": "Beautiful girl portrait",
        "full": "a beautiful girl with white bra looking at camera | | realistic shaded, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, ddd cup size"
    },
    {
        "display": "Curvy goth in latex.",
        "full": "a fully clothed curvy feminine young hot pale goth cutie wearing an elaborate shiny tight dark red latex-nylon-leather neck-high outfit with black and gold accents, dark eyeshadow, cgsociety, photorealistic, sublime-comforting-mysterious ambience, 16k, smooth, sharp focus, trending on ArtStation, volumetric lighting, thick hips-breasts and thin waist"
    },
    {
        "display": "Blood-covered skeleton woman",
        "full": "pale woman skeleton covered with blood, rolyatistaylor face!!!, red hair, ultra realistic, concept art, intricate details, highly detailed, 4 5 mm. photorealistic, octane render, 8 k, unreal engine. film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Melancholy strong young man",
        "full": "a physically strong, emotionally fragile bleached blonde young man, staring at a crumpled letter in his fist, melancholy, digital painting, trending on artstation, character study, sad, emotional, high quality, high definition"
    },
    {
        "display": "Hyperrealistic confetti vomit.",
        "full": "hyperrealistic mixed media high resolution painting of a Steve Buscemi violently vomiting confetti, stunning 3d render inspired art by Istv\u00ef\u00bf\u00bdn S\u00ef\u00bf\u00bdndorfi and Greg Rutkowski and Unreal Engine, perfect symmetry, dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyper-detailed, intricate, epic composition, highly detailed attributes, highly detailed atmosphere, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, flawless structure, lifelike texture, perfection,"
    },
    {
        "display": "Bearded female dwarf demolitioner.",
        "full": "portrait of a beautiful bearded curvy female dwarf demolitioner in working clothes. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose, vibrant colours."
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "portrait of Gavin Mcinnes, medium black hair, Nordic crown, black suit, fantasy, intricate, elegant, realistic, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyperrealistic skull woman portrait",
        "full": "full body pose, hyperrealistic mixed media painting of a beautiful attractive skull woman, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Photorealistic grassland town.",
        "full": "a beautiful photorealistic grassland with a large town, illustrated by greg rutkowski and gaston bussiere, photorealistic imagery, artstation 3 d, artstation render, artstation 3 d render, 3 d art, unreal engine 3 d, octane 3 d, blender 3 d, 3 d landscape, 4 k, 8 k"
    },
    {
        "display": "Octopus baseball match concept",
        "full": "local baseball match with octopus creatures held in a large stadium, by zdzislaw beksinski, by lewis jones, mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Shadowy man in arena",
        "full": "portrait of a shadowy man with long black hair in brown rags holding a magical item, gladiator arena, volumetric lighting, model, hdr, 2 4 mp, fantasy, high detail, elegant, digital painting, natural light, vibrant, intricate, textured skin, highly detailed, artstation, sharp, focus, illustration, anna dittmann, ilya kuvshinov, nikolay makovsky"
    },
    {
        "display": "Occult cosmology landscape painting",
        "full": "antediluvian occult cosmology, panspermia, by robert hooke and ernst haeckel and agostino arrivabene and joaquin sorolla and martin johnson heade, rule of thirds, vivid colours, negative space, atmospheric, digital painting, artstation, concept art, smooth, sharp focus"
    },
    {
        "display": "Beautiful woman with headphones.",
        "full": "a beautiful woman listening to music by Anna Nikonova, digital art, trending on artstation"
    },
    {
        "display": "Architectural bazaar painting",
        "full": "architectural painting of Indonesian Maori Tlingit bazaar, by Bruegel and Greg Rutkowski and Nathan Jackson"
    },
    {
        "display": "Anime girl in miniskirt.",
        "full": "a very beautiful anime grown girl, full body, long wavy blond hair, sky blue eyes, full round face, short smile, fancy top, miniskirt, front view, medium shot, mid-shot, highly detailed, cinematic wallpaper by Stanley Artgerm Lau"
    },
    {
        "display": "Shaquille O'Neal as dictator.",
        "full": "full body portrait of shaquille o'neil as the dictator of the orlando magic, 1 8 8 9, in full military garb, magic blue, silver, and black,, oil on canvas by william sidney mount, trending on artstation"
    },
    {
        "display": "Geralt on giant cockroach.",
        "full": "Henry Cavill as Witcher Geralt riding a giant cockroach, epic fantasy digital art by Eugene de Blaas and Ross Tran, vibrant color scheme, intricate detail, cinematic, artstation, Greg rutkowski, Artgerm"
    },
    {
        "display": "Intricate tattooed lady illustration",
        "full": "tattooed lady, sweaty, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk man portrait",
        "full": "hyperrealistic portrait of a cyberpunk man in cyberspace, by Guy Denning, Johannes Itten, Russ Mills, hacking effects, detailed eyes, fiery eyes, detailed lines, color blocking!, acrylic on canvas, insane detail, intricate, front view, symmetrical, octane, concept art, abstract, artistic, 8k, cinematic, trending on artstation"
    },
    {
        "display": "Lisa from Blackpink portrait",
        "full": "\u201c a portrait of lisa from blackpink, rainy background, pink bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, hearthstone card game artwork. \u201d"
    },
    {
        "display": "Japanese schoolgirl in dystopia",
        "full": "japanese schoolgirl by Alyssa Monks, Gaston Bussiere. Bob hairstyle, school uniform, full-shot, urban dystopia, hyper realism, realistic proportions, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Cyborg nun portrait, anime.",
        "full": "portrait Anime cyborg girl in nun clothes, cyberpunk, holy church cute-fine-face, white-hair pretty face, realistic shaded Perfect face, fine details. Anime. realistic shaded lighting by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Dieselpunk helmet portrait.",
        "full": "dreary realism portrait of masked diesel punk helmet on the art deco streets of the big city, artstation, award - winning realistic sci - fi concept art by jim burns and greg rutkowski, beksinski, a realism masterpiece, muted color palette, james gilleard, bruegel, alphonse mucha, and yoshitaka amano"
    },
    {
        "display": "Cosplaying Jotaro Kujo.",
        "full": "a highly detailed illustration of beautiful girl cosplaying jotaro kujo, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop."
    },
    {
        "display": "Candle-themed concept art",
        "full": "concept art, candles, 8 k, by james gurney, greg rutkowski, john howe, artstation"
    },
    {
        "display": "Trump as communist soldier",
        "full": "donald trump as a communist, doing a military salute, red suit, art by artgerm, greg rutkowski, drew struzan, studio portrait, highly detailed, digital art, elegant, intricate, concept art, trending on artstation"
    },
    {
        "display": "Cinematic portrait of Happy Mask Salesman",
        "full": "a portrait of a cinematic still of the happy mask salesman, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Crying woman in forest.",
        "full": "crying woman in burning forest, oil on canvas, intricate, portrait, 8 k highly professionally detailed, hdr, cgsociety, illustration painting by leonardo da vinci detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4 k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8 k, style by wes anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8 k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon stalenhag, rendered by beeple, by makoto shinkai, syd meade, 8 k ultra hd, artstationhd, 3 d render, hyper detailed, elegant, by craig mullins and marc simonetti, ross tran and wlop, by andrew wyeth and gerald brom, john singer sargent and james gurney"
    },
    {
        "display": "Celestial hotel with tapirs.",
        "full": "incredible, mindblowing beautiful celestial view from interstellar hotel, with tapirs in a spaceship, matte painting, artstation, cgsociety, dramatic lighting, concept art, octane render, arnold 3 d render"
    },
    {
        "display": "Moscow street in bloom.",
        "full": "a beautiful picture of moscow street in full bloom, palace \uff0c a girl in hanfu, by greg rutkowski and thomas kinkade, trending on artstation"
    },
    {
        "display": "Tarot card: shirtless cowboy",
        "full": "tarot card of a shirtless handsome mustached cowboy | he is holding a lantern | cloak, dad bod, homoerotic, art deco, art nouveau | by louis comfort tiffany | trending on artstation"
    },
    {
        "display": "Mandalorian Last Supper artwork",
        "full": "mandalorian as jesus at the last supper, by leonardo davinci, concept art, oil painting, art station"
    },
    {
        "display": "Armored Armadillo Concept Art",
        "full": "an armored armadillo, made by stanley artgerm lau, wlop, rossdraws, artstation, cgsociety, concept art, cgsociety, octane render, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k,"
    },
    {
        "display": "Digital portrait with beautiful eyes",
        "full": "portrait of akrim digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Kanye West album cover.",
        "full": "Kanye West Album cover artwork in the style of Makoto Shinkai, by Artgerm, by beeple, by Greg Rutkowski, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Hispanic woman at beach.",
        "full": "digital art of a hispanic woman with long white hair wearing a dress at the beach, summer warm weather, beautiful lighting, by artgerm, by greg rutkowski, highly detailed, octane render, beautiful face, 4 k, trending on artstation, palm tree background"
    },
    {
        "display": "Funny revolver illustration",
        "full": "funny revolver in the desert, smooth face, centered median photoshop filter cutout vector behance hd by artgerm, jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, illustration, art by ilya kuvshinov and gustav klimt"
    },
    {
        "display": "Wondergirl concept art",
        "full": "full body character concept art of the wondergirl | | pixar - cute - fine - face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, jakob eirich, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Fairy goddess night scene",
        "full": "attractive fairy goddness fly high in the night, d & d, fantasy, mist, full moon in background, trees, hyper detailed, art by artgerm and greg rutkowski and magali villeneuve, midium shot, 8 k realistic, cryengine, digital painting, trending on artstation, concept art, sharp focus, illustration,"
    },
    {
        "display": "Naturepunk deity with pilot.",
        "full": "naturepunk deity of retrofuture dream logic creativity, beautiful detailed realistic cinematic with space fighter pilot and with helmet with reflective visor obscuring his face, concept fashion revolutionary war propaganda portrait, hi - fructose art magazine, by anton fadeev and paul lehr and david heskin and josan gonzalez, 8 k"
    },
    {
        "display": "Margot Robbie as Leia.",
        "full": "Margot Robbie as Princess Leia, by artgerm, beautiful, mixed media on toned paper, 2021, very detailed, coffee art"
    },
    {
        "display": "Colorized portrait of Valery Sablin",
        "full": "valery sablin the forgotten hero, colourised, face portrait, epic, military art, fantasy, dieselpunk, hd shot, digital portrait, beautiful, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Bearded sci-fi character concept",
        "full": "large, stocky bearded man, sci fi, shadowrun, by artgerm, josan gonzales, artstation, concept art, sharp focus, graphic novel"
    },
    {
        "display": "Colorful bird character sheet",
        "full": "bird is ok with the situation, character sheet, colorful, contrast, 3 d scene, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Cosmic evil android portrait",
        "full": "poltergeist fused with a cosmic evil laughing android, the thing, few cable wires hanging on the body, ghostly, gnarly, portrait, intricate details, by vincent di fate, artgerm, julie bell, beeple and Greg Rutkowski, 80s, concept, Smooth gradients, octane render, 8k, High contrast, duo tone, depth of field, very coherent symmetrical artwork"
    },
    {
        "display": "Female astronaut on Mars.",
        "full": "a female astronaut on mars, face painting, dramatic lighting, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, footage from space camera"
    },
    {
        "display": "Misty fantasy valley ruins",
        "full": "intricate detailed misty valley of ruins fantasy at dawn. Lisa Frank, james gurney,dan luvisi,Petros Afshar,tim hildebrandt,liam wong,Mark Riddick,thomas kinkade,ernst haeckel,dan mumford,trending on artstation,josephine wall, WLOP ,cgsociety by Gediminas Pranckevicius, trending on cgsociety and DeviantArt"
    },
    {
        "display": "Lucifer Morningstar Portrait",
        "full": "a portrait of lucifer morningstar, sci-fi concept art by giger and beksinski and szukalski and wlop and pete mohrbacher, digital art, highly detailed, intricate, horror, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Romantic cabin scene.",
        "full": "a boy and a girl with long flowing auburn hair sitting together on the porch of a cabin on a mountain overlooking a snowy forest. Atmospheric lighting, long shot, romantic, boy and girl are the focus, cold lighting, snowy. details, sharp focus, illustration, by Jordan Grimmer and greg rutkowski, Trending artstation, pixiv, digital art"
    },
    {
        "display": "Happy female Viking portrait",
        "full": "an portrait of an happy female viking, blonde hair, lip scar, freckles, detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Futuristic Arab woman illustration",
        "full": "beautiful arab woman in futuristic niqab, beautiful eyes, flowers, rain, lightning, storm, digital painting, illustration by james jean and artgerm and mina petrovic and timothy kong and marina federovna, artstation"
    },
    {
        "display": "Spaceship armada masterpiece",
        "full": "an armada of spaceships, the fleet of captain dj armin van buuren, viewed from the helm of his ship, extremely detailed oil painting, unreal 5 render, rhads, bruce pennington, studio ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award - winning photograph, masterpiece"
    },
    {
        "display": "Winged warrior woman illustration",
        "full": "3 / 4 view of a warrior woman with wings, pixie character, video game genshin impact,, intricate, elegant, sharp focus, illustration, highly detailed, concept art, matte, art by wlop and artgerm and greg rutkowski, anime, ilya kuvshinov, katsuhiro otomo, kidmo! - h 6 4 0"
    },
    {
        "display": "Futuristic urban scene",
        "full": "The Futuristic Society by Greg Rutkowski, bustling streets, realistic crowds of people, airships in the skies, inspired by Stephan Martiniere and Bjarke Ingels, fantasy, digital art, professional illustration, realistic, ultra detailed, atmospheric, cinematic lighting, movie concept art, hyper detailed, insanely detailed, corona render, octane render, colorful redshift render, 8k"
    },
    {
        "display": "Lonely knight praying in desert",
        "full": "Lonely knight praying sitting at lap, in desert, lit by the light of gods, abandoned by gods, hyperdetailed artstation cgsociety by greg rutkowski and by Gustave Dore"
    },
    {
        "display": "Confident warrior woman with butterfly wings",
        "full": "3 / 4 view of a woman with black hair, warrior, butterfly wings, confident pose, pixie character, video game genshin impact, cell shaded anime intricate, elegant, sharp focus, illustration, highly detailed, concept art, matte, magali villeneuve, artgerm, anime, trending on artstation"
    },
    {
        "display": "Swampy cartoon scene.",
        "full": "a cartoon scene of a swampy area with a sign that says keep out, concept art by Rube Goldberg, deviantart contest winner, underground comix, 2d game art, concept art, playstation 5 screenshot"
    },
    {
        "display": "Elegant female portrait.",
        "full": "a gorgeous female photo, professionally retouched, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, wide angle, sharp focus on eyes, 8 k high definition, insanely detailed, intricate, elegant, art by artgerm and johannes wessermark and rodney amirebrahimi"
    },
    {
        "display": "Symmetrical priest portrait.",
        "full": "symmetry!! full body portrait!!!! of a beautiful!!!! germanic vestal sacral priest, pretty face, falling ashes, blurred burning buildings in the background, white glow, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Superman portrait, dramatic lighting",
        "full": "superman portrait, dramatic light, lake background, 2 0 0 mm focal length, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Ice wizard in snowy forest",
        "full": "ice wizard casting a freeze spell in a snowy forest, rococo inspired clothes, dramatic lighting, zbrush, concept art, 4K"
    },
    {
        "display": "Buddha-inspired car design.",
        "full": "a car designed by the Buddha. Detailed colored line drawing concept art."
    },
    {
        "display": "Elegant snow-themed portrait.",
        "full": "sansa angeline jolie gessica chastain white snow skin, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Lost sand city ruins",
        "full": "architectural cover concept art of the lost sand city, ruins, golden towers, golden pillars, palm trees, dramatic ,digital art, art station, unreal engine 8k"
    },
    {
        "display": "Engraving of hawk god.",
        "full": "beautiful detailed engraving of a ceremonial hawk god, symmetrical portrait, forward facing, classical ornamental entail design, fibonacci flow, acroteria, encarpus, bead and reel, large medium and small elements, by russ abbott, albrecht durer, artgerm, rutkowski, trending on artstation, 8 k"
    },
    {
        "display": "Elegant Severus Snape portrait",
        "full": "a portrait of severus snape, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Forest ghost digital art",
        "full": "ghost of the forest by Anna Dittmann, digital art, horror, trending on artstation, anime arts, featured on Pixiv, HD, 8K, highly detailed, good lighting, beautiful, epic, masterpiece"
    },
    {
        "display": "Fantasy portrait of woman.",
        "full": "Portrait of a woman with bright colored flying hair, all shades of purple. Hair coloring, beautiful lips and makeup. Hair fluttering in the wind, amber eyes, face, long hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime thopter portrait",
        "full": "portrait of a thopter from magic the gathering, ilya kuvshinov, anime, pixiv top monthly, trending on artstation, cinematic, danbooru, zerochan art, kyoto animation"
    },
    {
        "display": "Punk Shiba Inu portrait.",
        "full": "portrait painting of a punk shiba inu with tuxedo fur, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Colorful retrofuturistic jester portrait.",
        "full": "a baroque neoclassicist close - up portrait of a colorful retrofuturistic jester, glowing circus tent and fog in the background. renaissance portrait painting. highly detailed science fiction painting by norman rockwell, frank frazetta, and syd mead. rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation"
    },
    {
        "display": "Steampunk female sloth reading.",
        "full": "a steampunk female sloth holding a stack of books, standing in a steampunk reading room. in a steampunk reading room, bookshelf. digital illustration, very vibrant colors, soft lighting, adventurous, atmospheric lighting, 8K, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art. @MarioManiacDude"
    },
    {
        "display": "Bob Ross painting Iron Man.",
        "full": "a closeup photorealistic photograph of bob ross working on a canvas painting of iron man. mountain scape. film still, vibrant colors. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Cyberpunk portrait of SHODAN",
        "full": "realistic portrait of shodan from system shock 2, sentient hyper - optimized data access network, cyberpunk, painted by greg rutkowski, painted by stanley artgerm, painted by igor kieryluk, digital art, promotional art, trending on artstation"
    },
    {
        "display": "Xenomorph fashion portrait.",
        "full": "xenomorph vogue portrait, artstation, cgsociety, dramatic studio lighting, very detailed, high contrast, intricate, detailed illustration, by artgerm and greg rutkowski and alphonse mucha, octane render, unreal engine, hyperrealism"
    },
    {
        "display": "Mossy skeleton with flora",
        "full": "'Life from death' A horrifyingly detailed aesthetic horror mate painting depicting 'A mossy skeleton with plants and flowers growing all over it, birds and insects flying all around it' by giuseppe arcimboldo and Rembrandt, Trending on cgsociety artstation, 8k, masterpiece, cinematic lighting, highly detailed, vibrant colors."
    },
    {
        "display": "Hacker group emblem design",
        "full": "a concept art of a hacker group cloth emblem patch, brain icon, electric current icons, circle shape, cyan color scheme, vector graphics, high quality, illustration, icon, hyperrealism, octane render, commission art"
    },
    {
        "display": "Anime girl with dragon features",
        "full": "an anime girl wtih dragon horns and snake tail,full body,by ruan jia and Ilya Kuvshinov,super clear detailed,trending on artstation"
    },
    {
        "display": "Young woman in military uniform",
        "full": "young woman with shoulder length light brown hair and hazel eyes dressed in a sharp dark teal military uniform and beret, smiling, ilya kuvshinov, greg rutkowski, guweiz, ross tran, svetlana tigai, artgerm, loish, artstation trending, concept art, digital painting"
    },
    {
        "display": "Sci-fi elite scientist portrait",
        "full": "concept art of scifi elite scientist by jama jurabaev, emperor, portrait, extremely detailed, studio light, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Vibrant vaporwave geometric art",
        "full": "vibrant colorful vaporwave geometry symmetry blocks modular style by laurie greasley and rene magritte, bauhaus poster, etching by gustave dore, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, masterpiece"
    },
    {
        "display": "Cybernetic duchess portrait",
        "full": "a portrait of a beautiful cybernetic duchess of hell, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzalez and syd mead, digital art, unreal engine 5, trending on artstation, deviantart, pinterest, rule of thirds, 4 k uhd image"
    },
    {
        "display": "Cell-shaded Art Deco city",
        "full": "a study of cell shaded cartoon of the interior of a bioshock style art deco city, illustration, wide shot, subtle colors, post grunge, concept art by josan gonzales and wlop, by james jean, victo ngai, david rubin, mike mignola, laurie greasley, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Steampunk Victorian cityscape",
        "full": "a beautiful matte concept art painting of inside pov victorian style steampunk lord of the rings city streets with steampunk robots, concept art, deviantart, flickr, artstation, octane render, textured, colorful, very detailed, volumetric lighting, detailed lighting, octane render, 4 k, cinematic lighting, 8 k resolution"
    },
    {
        "display": "Hyper-detailed Orianna artwork",
        "full": "orianna, from league of legends, au naturel, hyper detailed, metal skin, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Fantasy last selfie",
        "full": "last selfie on earth, fantasy, digital art, trending on artstation"
    },
    {
        "display": "Cute surreal animal illustration",
        "full": "a cute strange animal on single color background illustrated by miyazaki by karol bak, james jean, tom bagshaw, rococo, sharp focus, trending on artstation, cinematic lighting, hyper realism, octane render, 8 k, hyper detailed, vivid, ultra detailed, highly detailed"
    },
    {
        "display": "Dragon priest portrait, ornate.",
        "full": "portrait of a dragon priest, intricate, ornate flowers, elegant, digital painting, artstation, concept art by artgerm and wlop"
    },
    {
        "display": "Regal Egyptian queen with wings",
        "full": "perfect red haired egyptian queen wearing white dove wings, warframe armor, regal, attractive, ornate, sultry, sexy, beautiful, dreamy, elize theron, pretty face, green eyes, detailed, scifi platform, 4 k, ultra realistic, epic lighting, illuminated, cinematic, masterpiece, art by akihito tsukushi, voidstar, artgerm"
    },
    {
        "display": "3D One Piece render",
        "full": "3d render of a still of One piece, blender, trending on artstation, 8k, highly detailed, disney pixar 3D style"
    },
    {
        "display": "Cyberpunk Doomguy Portrait",
        "full": "portrait of a doomguy from doom, in his battle armor. he's a bodybuilder. gritty cyberpunk, messy blood, demons, hell, fighting. skeletons, monsters, bloated warhammer creatures. starcaft, starship troopers, halo, colonial marines. blade runner. android warrior. space sci - fi, mobile suit armor. combat armor, exoskeleton. game cinematic still. digital illustration by chris foss. spaceship in the background. cold colours. oil painting, highly detailed, centered, hyperrealism, artstation, concept art, smooth, sharp focus, illustration, artgerm"
    },
    {
        "display": "Uyghur man portrait, prison.",
        "full": "centered painted portrait, face of a uyghur man in a prison behind bars, gloomhaven, matte painting concept art, art nouveau, beautifully backlit, swirly vibrant color lines, muted and dull colors, aesthetic octane render, 4 k 8 k hd resolution, by ilya kuvshinov and cushart krentz and gilleard james"
    },
    {
        "display": "GTA V sunset scene.",
        "full": "a stunning GTA V loading screen with a beautiful woman with ombre hairstyle in purple and pink blowing in the wind in the city at sunset, outrun, vaporware, neon lights, sky scrapers, retro, beautiful sky with cumulus couds, digital art, trending on artstation"
    },
    {
        "display": "Futuristic Egyptian superhero.",
        "full": "a woman hero in a themed futuristic metal suit, super hero, armor, sleek, beautiful face, cinematic pose, sci - fi, egypt themed art, photorealistic, 8 k, pharah, ultron, concept art, extremely detailed"
    },
    {
        "display": "1920s dancer illustration",
        "full": "a beautiful dancer with black hair in 1920's fashion, living room background, intricate, highly detailed, digital painting, artstation, official media, anime key visual, concept art, rich vivid colors, ambient lighting, sharp focus, illustration, art by Artgerm, Makoto Shinkai, Ilya Kuvshinov, Lois Van Baarle, and Rossdraws"
    },
    {
        "display": "Cyberpunk girl concept art",
        "full": "\u201chigh detail picture of a Cyberpunk girl, digital art, concept art, neon colors, high contrast, sharp focus, hiperrealist, photorealist, Artstation HQ, DeviantArt, cybernetics, techwear, urban samurai, netrunner, Shadowrun, Cyberpunk 2077, Deus Ex, 4k UHD, Unreal Engine 5\u201d"
    },
    {
        "display": "Sultry vampire portrait",
        "full": "portrait of megan fox, sultry, sexy muscular upper body in tight dress, blood red eyes, vampire fangs, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Elegant portrait of Salma Hayek.",
        "full": "fashion pose portrait of salma hayek by stefan kostic, realistic, body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, cherry blossoms"
    },
    {
        "display": "Anime-style baroque portrait.",
        "full": "baroque oil painting character ln illustration lotr concept art anime key visual colorized portrait, young female nazi maid, long flowing blonde hair blue eyes, symmetrical perfect face fine detail delicate features quiet gaze, full black fitted leather uniform garterbelt, gapmoe kuudere bokeh, trending pixiv fanbox, by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Gothic castle cathedral artwork",
        "full": "A highly detailed matte painting of the HUGE gothic castle cathedral, by Studio Ghibli, Makoto Shinkai, by Artgerm, by beeple, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Wakandan warrior in steampunk armor.",
        "full": "art by artgerm, ultra realistic illustration, wakandan warrior in a steampunk cyberpunk style armor, sci fi background hacknaut cyberpunk, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration."
    },
    {
        "display": "Translucent female ghost",
        "full": "a female ghost, translucent and scary looking, full shot, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Magical fire sword concept",
        "full": "sword of magical fire, front view, concept art, black background, dark fantasy"
    },
    {
        "display": "Mayan ruins in animated style",
        "full": "ancient mayan ruins, studio ghibli, pixar and disney animation, sharp, rendered in unreal engine 5, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wide angle, artbook, wallpaper, splash art, promo art, dramatic lighting, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Exploding brain artwork.",
        "full": "an exploding brain in a painting from stalenhag, 4 k, 8 k, hdr, artstation, concept art"
    },
    {
        "display": "Beautiful Twi'lek artwork.",
        "full": "A female Twi'lek, by artgerm, beautiful, mixed media on toned paper, 2021, very detailed, coffee art"
    },
    {
        "display": "Surreal database landscape.",
        "full": "Just living in the database as the madness of the system grows, flashback into a realm of tendril chains of cold by Ruan Jia, Peter Morhbacher, rendered in hyperdetailed Ultra HD, trending on ArtStation, luminous"
    },
    {
        "display": "Giant white chess bishop.",
        "full": "a giant white chess bishop statue, battlefield background, bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, hearthstone card game artwork, chess piece"
    },
    {
        "display": "Ornate Total War Art",
        "full": "total war warhammer 3 concept art, oil painting, hyper realistic, symmetrical, 4 k, highly ornate intricate details,"
    },
    {
        "display": "Skull-faced girl portrait.",
        "full": "a portrait of a girl skull face, wearing a mask, balloons, in the style of banksy, van gogh, atey ghailan and steve mccurry, vibrant colors and hard shadows and strong rim light, lucien freud, comic cover art, trending on artstation"
    },
    {
        "display": "Dieselpunk man-machine transformation.",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: dieselpunk man morphing into a machine. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Autumn village concept art",
        "full": "high quality concept art from a historical fiction video game set in italy, a village in autumn, beautiful oil painting, concept art, trending on ArtStation, 4K HD, 35mm f/5.0"
    },
    {
        "display": "Sky blue-haired boy, regal art.",
        "full": "ilya kuvshinov with long hair, sky blue hair, hazel eyes, boyish face, professional digital painting, concept art, award - winning photography, cinematic, stained glass window, tarot card, awe, regal, wlop, art by andy warhol, pixiv art, yoshitaka amano"
    },
    {
        "display": "Zeugma mosaic of Emma Stone",
        "full": "beautiful zeugma mosaic of emma stone, significant pebbles boundaries, most beautiful mosaic in the history, high detail, realistic, accurate facial detailing, realistic composition, concept art, best of artstation, in the style of chris foss, rodger dean, moebius, michael whelan, and gustave dor"
    },
    {
        "display": "Afro Caribbean angel portrait",
        "full": "Portrait of a Afro caribbean with angel wings, and a glowing halo, white lighting, digital art by Ruan Jia and Mandy Jurgens and Artgerm, highly detailed, trending on artstation, award winning,"
    },
    {
        "display": "Apocalyptic woman on tank",
        "full": "beautiful apocalyptic woman with Mohawk, standing on mad max panzer tank, hyper-detailed, smooth, sharp focus, 4k ultra hd, fantasy dark art, tank girl, artgerm, artstation, octane render, elegant, detailed digital painting, apocalyptic art"
    },
    {
        "display": "Androgynous fairy galaxy portrait",
        "full": "a portrait of a galaxy as an androgynous fairy spirit wearing a necklace of moons and stars, draped in transparent cloth, flat colors, occult, minimal, swirly, bust by alphonse mucha, decorative art deco border, astrophotography, vast cosmos, digital illustration overlayed on photography, trending on artstation"
    },
    {
        "display": "Futuristic egregore concept art",
        "full": "egregore, hyperdetailed, futuristic hi-tech details, ominous, intricate, art by anthony macbain + greg rutkowski + alphonse mucha, concept art, 4k, sharp focus, cinematic unreal engine, 8k"
    },
    {
        "display": "Samurai banana illustration",
        "full": "a anthropomorphic banana wearing samurai armor, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by frank frazetta and marco bucci and loish and rossdraws and artgerm and alphonse mucha"
    },
    {
        "display": "Minimalistic Cyborg Crocodile",
        "full": "cyborg crocodile but minimalistic concept art by frank stella, colorful, v - ray, trending on artstation, minimalism"
    },
    {
        "display": "Ethereal goddess portrait.",
        "full": "art by Gediminas Pranckevicius, Hyperrealistic beautiful godness ethereal  white north pole girl portrait, art nouveau, fantasy, intricate flower designs, elegant, highly detailed, sharp focus, art by Artgerm and Greg Rutkowski and WLOP - WOMAN of tarot card The Star card shows a woman kneeling at the edge of a small pond. She is holding two containers of water. One container pours the water out to the dry land, as if to to nourish it and ensure its fertility. The lush green land around her seems to say that it is working. One foot is inside the water which shows the spiritual abilities and inner strength of the woman. The other foot on the ground shows her practical abilities and strengths. Behind her, there is a large central star surrounded by seven small stars which represent the chakras. There is bird standing at a tree branch which represents the holy ibis of thought. The Star's astrological correspondent is Aquarius."
    },
    {
        "display": "Pink-haired female mage.",
        "full": "pink haired mage, female, glacier landscape, norway, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, concept art, matte, sharp focus, illustration, herrarthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dramatic hotel lobby portrait.",
        "full": "woman sitting in the lobby of a hotel volumetric lighting, back lighting, rimlight, dramatic lighting, digital painting, highly detailed, artstation, sharp focus, illustration, Artgerm, Jean-L\u00e9on G\u00e9r\u00f4me , ruan jia"
    },
    {
        "display": "Giant dragon in galaxy.",
        "full": "giant dragon flying in the sky, highly detailed, intricate, epic fantasy style art, galaxy theme,  by Greg Rutkowski, hearthstone style art, 00% artistic"
    },
    {
        "display": "Vintage portrait of Lili Reinhart.",
        "full": "portrait of lili reinhart with fluffy bangs, smiling kindly, bangs, 1 9 6 0 s, ponytail, curly bangs and ponytail, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mars ravelo and greg rutkowski"
    },
    {
        "display": "Liu Yifei Sci-Fi Portrait",
        "full": "\u201c portrait of liu yifei by greg rutkowski, young, attractive, highly detailed portrait, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq \u201d"
    },
    {
        "display": "Artistic man illustration",
        "full": "a man illustrated by artgerm and greg rutkowski"
    },
    {
        "display": "Retrofuturistic female android.",
        "full": "retrofuturistic female android tied to a chair, steampunk, gears, detailed mechanical parts, painting by artgerm julie bell Ed Brinkley"
    },
    {
        "display": "Half-spider sorceress concept art.",
        "full": "half-spider sorceress with a black dress made of shadows and a golden scepter, volcano, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8k, unreal engine. art by Artgerm and Greg Rutkowski"
    },
    {
        "display": "Enrico Letta Tarot Card",
        "full": "enrico letta, wearing a suit, tarot card, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, italian flag, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vibrant sci-fi character portrait.",
        "full": "a hyper - realistic detailed character concept art portrait of a vibrantly dressed woman on a depth of field background, artstation, award - winning realistic sci - fi concept art by jim burns and greg rutkowski, beksinski, a realism masterpiece, complimentary color palette, james gilleard, bruegel, alphonse mucha, and yoshitaka amano."
    },
    {
        "display": "Vietnam War soldier in jungle.",
        "full": "a USA soldier is looking at the jungle in the Vietnam war, dynamic composition, cinematic lighting, warm and vibrant colors, art by Sam Weber and Michael C Hayes, 8k, trending on artstation, hyper detailed, cinematic"
    },
    {
        "display": "Futuristic city with giant tree",
        "full": "A large industrial city with high buildings of the future in the center of city a giant tree grows in a glass flask all the way to space. Optimistic good lookintricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Aivazovsky"
    },
    {
        "display": "Muscular man, seductive pose.",
        "full": "a muscular man posing in a seductive way, big beard, bold, hyperrealistic, concept art, octane render, unreal engine 5, trending on artstation, high quality, 8 k, anatomically correct, five fingers, digital art, symmetrical, low contrast, epic scene, cinematic, dramatic lighting, high coherence"
    },
    {
        "display": "Fantasy dragon landscape.",
        "full": "''dragon breathing fire, rule of thirds, fantasy, mountain landscape, d & d, digital painting, artstation, deviantart, concept art, illustration, art by dragolisco and anne stokes and nico niemi and rachael m and kaiser flames''"
    },
    {
        "display": "Cinematic Doctor Strange portrait",
        "full": "cinematic portrait of Doctor Strange from the DC universe, only head and chest, intricate, desaturated, Tim Hildebrandt, Wayne Barlowe, Bruce Pennington, donato giancola, larry elmore, maxfield parrish, Moebius, Thomas Ehretsmann, oil on canvas, gouache painting, masterpiece, trending on artstation, cinematic composition, dramatic pose, volumetric lighting, sharp, details, hyper-detailed, HD, 4K, 8K"
    },
    {
        "display": "Gothic female warrior portrait",
        "full": "a beautiful render of female warrior, gothic background, leather armor, wielding a sword, a beautiful face, perfectly shaded, atmospheric lighting, style of makoto shinkai, raphael lacoste, louis comfort tiffany, artgerm, karol bak, james jean, alphonse maria mucha"
    },
    {
        "display": "Tall woman in forest.",
        "full": "portrait of tall woman in forest glade, short black hair, sharp focus, highly detailed, by artgerm, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Mirrored Rolls Royce in cornfield.",
        "full": "a mirrored rolls royce in the center of a cornfield, hyper realistic, ambient lighting, concept art, intricate, hyper detailed, smooth, dynamic volumetric lighting, octane, raytrace, cinematic, high quality, high resolution, 4 k"
    },
    {
        "display": "Geometric spaceship in space.",
        "full": "realistic geometric spaceship, sci - fi, technologi, constellation, geometry space background, breathtaking stars, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, spiritual art, art by artgerm and greg rutkowski and alphonse mucha, psychedelic, illustration, painting oil,"
    },
    {
        "display": "Cute gnome with pumpkin.",
        "full": "hand drawn cute one gnomes close - up face upscale in autumn disguise holding pumpkin, detailed closeup face, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Steampunk fantasy character art",
        "full": "character concept art for a steampunk fantasy rpg, full body, fantasy painterly style, artwork by ross tran, artgerm"
    },
    {
        "display": "Costume on nebula stage",
        "full": "Costume on stage, on a dark nebula background\u201d digital Art, concept Art, highly detailed, 3-D 4K, trending on art station, Award winning, Mark Brooks, -768"
    },
    {
        "display": "Melancholic slime-filled mouth",
        "full": "medium shot open human mouth with thick viscous green slime oozing out, thick red lips, human staring blankly ahead, melancholy, unsettling, art house film aesthetic, color grain 3 5 mm, hyperrealism, 8 k, high detail, sharp focus"
    },
    {
        "display": "Insect female monster portrait.",
        "full": "portrait of an insect female monster. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose, vibrant colours."
    },
    {
        "display": "Gilded veiled oracle portrait",
        "full": "photorealistic portrait god oracle, her eyes covered by gilded veils, she whispering dark energy into existence by ayami kojima and ewelina kowalczyk and alessio albi, trending on artstation, realistic, photorealistic, vibrant colors, symmetrical face, glistening skin, dark skin, volumetric lighting, subsurface scattering"
    },
    {
        "display": "Meditating harpy and centaur.",
        "full": "a portrait photograph of a meditating harpy and a centaur king riding horses at a wide river delta. surrounded by bulbous flowers, animals, trees and mushrooms. mountain range under a vast blue sky of burning stars and parrots. painted by jan van eyck, max ernst, ernst haeckel and artgerm, cgsociety, artstation, fashion editorial"
    },
    {
        "display": "VFX portrait of Usopp.",
        "full": "highly detailed vfx portrait of usopp by eiichiro oda!, greg rutkowski, loish, rhads, beeple, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, stanley kubrick, backlit, harsh overhead sunlight,"
    },
    {
        "display": "Fire goddess portrait.",
        "full": "a beautiful portrait of a fire goddess by greg rutkowski and raymond swanland, trending on artstation, flaming background, ultra realistic digital art"
    },
    {
        "display": "Symmetrical Wolverine Portrait",
        "full": "symmetry portrait of wolverine from x - men : the animated series ( 1 9 9 2 ), glowing lights, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dionysus in neon vaporwave.",
        "full": "portrait of dionysus, beeple, vaporwave, retrowave, black background, neon wiring, black, glitch, strong contrast, cuts, pinterest, trending on artstation"
    },
    {
        "display": "Taylor Swift at Burning Man",
        "full": "taylor swift at burning man, artgerm, extremely detailed, 8 k resolution"
    },
    {
        "display": "Retro sci-fi Kyoto suburb",
        "full": "cinematic view of a retro scifi kyoto suburb, people, desaturated, psychedelic, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Kermit the Frog smoking.",
        "full": "candid photo of kermit the frog sitting on the couch hitting a bong, kermit the frog in ted ( 2 0 1 2 ) bong scene, kermit the frog using a bong, kermit bong bong kermit froggy bong, high resolution photo, trending on artstation, interior design,"
    },
    {
        "display": "Psychedelic shaman concept art",
        "full": "full body psychedelic shaman with trinket necklace, epic angle and pose, symmetrical artwork, ayahuasca, translucent, fungus, energy flows of water and fire, highly detailed, epic cinematic concept art, excellent composition, dystopian brutalist atmosphere, dynamic dramatic lighting, aesthetic, very inspirational, arthouse, Greg Rutkowski, Artgerm"
    },
    {
        "display": "Black horse with scale.",
        "full": "a black horse The one who rode it carried a scale in his hand , digital Art, Greg rutkowski Trending artstation"
    },
    {
        "display": "Anime girl portrait",
        "full": "anime planetarian girl portrait, made by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, artstation, cgsociety"
    },
    {
        "display": "Steampunk robot ninja helmet.",
        "full": "steampunk helmet fantasy art mask robot ninja stylized digital illustration sharp focus, elegant intricate digital painting artstation concept art by mark brooks and brad kunkle global illumination ray tracing advanced technology, art gta 5 cover, official fanart behance hd artstation by jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, ossdraws, that looks like it is from borderlands and by feng zhu and loish and laurie greasley, victo ngai, andreas rocha, john harris"
    },
    {
        "display": "Realistic Godzilla portrait",
        "full": "portrait of Xplus Godzilla!!! foggy rim lighting ultra realistic oil painting, 4k, artstation trending"
    },
    {
        "display": "Dolphin as \"The Dude\"",
        "full": "symmetrical - photo!! low - angle!! painting of a dolphin starring as the dude in the big lebowski, action - pose, dynamic - pose, full frame photograph, zeiss lens, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Confident anime woman portrait",
        "full": "full body shot! dream portrait of beautiful woman character, confident pose, wearing chains, bright colors, sharp focus, illustration, highly detailed, concept art, matte, trending on artstation, anime, art by hajime sorayama, h 6 4 0"
    },
    {
        "display": "Colorful Sci-Fi landscape wallpaper",
        "full": "Sci-Fi dark nighttime colorful wallpaper of a beautiful landscape art by Donato Giancola and Bayard Wu, digital art, trending on artstation, 4k"
    },
    {
        "display": "Cyberpunk Ork Warrior",
        "full": "gina carano as a shadowrun ork with prothesis metallic left arm, tusk, casual black clothing, muscular, realistic proportions, casual pose, large portrait, sci - fi, rpg character, gun, digital painting, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Beautiful anime girl, winter setting.",
        "full": "a very beautiful anime girl, full body, long golden hair, sky blue eyes, full round face, short smile, mini jeans skirt, cute top, winter setting, cinematic lighting, medium shot, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan"
    },
    {
        "display": "Fantasy forest dragon illustration",
        "full": "forest dragon, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Manga maid with cakes.",
        "full": "manga of beautiful maid with cakes, curls hair, rococo ruffles dress, pastel rainbow, pearlescent, shimmering, reflective, rim light, detailed background, by yoshitaka amano, kojima ayami, yoji shinkawa, alphonse mucha, illustration, dark fantasitc, artstation, pivix, concept art, highly detailed, colorful, maximalist"
    },
    {
        "display": "Sad Christina Hendricks in tavern.",
        "full": "sad young christina hendricks in a tavern, holding a small roung mirror, surprised expression on her face, she is surprised, warhammer, lord of the rings, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Adorable girl portrait.",
        "full": "an adorable girl, full shot, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Hyper-realistic desert landscape.",
        "full": "a realistic photographic view picture of the desert, photographic filter unreal engine 5 realistic hyperdetailed 8 k ultradetail cinematic concept art volumetric lighting, very beautiful scenery, very realistic effect, hd, hdr, cinematic 4 k wallpaper, 8 k, sharp focus, octane render, ultra detailed, high resolution, artstation trending on artstation in the style of albert dros glowing rich colors powerful imagery"
    },
    {
        "display": "Cinematic forest oil painting",
        "full": "a forest, detailed oil painting, cinematic angle, hyperrealistic, breathtaking, volumetric lighting, Studio Ghibli, Jessica Rossier, digital art, octane render, epic composition, trending on artstation, masterpiece"
    },
    {
        "display": "Megan Fox as Supergirl.",
        "full": "megan fox as super girl by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Intricate Aang Portrait",
        "full": "aang from avatar the last airbender, character portrait, portrait, close up, concept art, intricate details, highly detailed by greg rutkowski, michael whelan and gustave dore"
    },
    {
        "display": "Goddess portrait with technology",
        "full": "highly detailed portrait of an african egyptian goddess with the face of donald trump, intricate alien technology, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Emma Watson as Crystal Maiden",
        "full": "Emma Watson as a crystal maiden, sci-fi, amber eyes, face, long hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Chameleon-dragon hybrid painting",
        "full": "painting of hybrid between chameleon & dragon, intercrossed animal, by zdzislaw beksinski, by dariusz zawadzki, by tiffany bozic, by john jude palencar, cold hue's, warm tone gradient background, concept art, single object scene, beautiful composition, digital painting"
    },
    {
        "display": "Cyberpunk skull painting",
        "full": "a beautiful painting of a ( cyberpunk ) skull by simon stalenhag and pascal blanche and alphonse mucha! and nekro. in style of digital art. colorful comic, film noir, symmetry, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "VFX portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin, greg rutkowski, makoto shinkai, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, backlit, harsh overhead sunlight, blue eyes,"
    },
    {
        "display": "Elegant digital portrait",
        "full": "sansa angeline jolie, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Walter White as Gandalf",
        "full": "walter white is gandalf, concept art by senior character artist, cgsociety, photorealism, reimagined by industrial light and magic, rendered in unreal engine, official art"
    },
    {
        "display": "Anime soldier girl portrait",
        "full": "side portrait of girl eating from can, soldier clothing, combat helmet, anime style, short hair, hair down, symmetrical facial features, from arknights, hyper realistic, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, d & d, realistic lighting, by alphonse mucha, greg rutkowski, sharp focus, backlit"
    },
    {
        "display": "Pixar-style Felix the Cat",
        "full": "felix the cat, animation pixar style, by pendleton ward, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, golden ratio, trending on art station"
    },
    {
        "display": "Divine wedding banquet scene",
        "full": "close up moment of a divine a japan sun god and a moon goddess lovers magician at a wedding banquet, highly detailed, genshin, fantasy, 4 k realistic, digital painting, trending on artstation, concept art, sharp focus, illustration, art by makoto shinkai and akihiko yoshida and daniel gerhartz"
    },
    {
        "display": "Intricate fairytale illustration",
        "full": "fairytale, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Warlock casting spell, Mucha style.",
        "full": "a warlock is casting a magic spell, while magic orb is floating in his hand, the magic orb emit a blueish vapour, dynamic pose, chromatic aberration , medium level shot, Mucha style , Grim fantasy, illustration ,concept art,"
    },
    {
        "display": "Meditating centaur shaman transformation.",
        "full": "a photograph of a meditating centaur shaman and a harpy mutating into a savage fanged beast. surrounded by bulbous flowers and a few trees. river delta with mountains under a blue sky full of burning stars and birds. painted by jan van eyck, max ernst, ernst haeckel, ernst fuchs and artgerm. trending on artstation, fashion editorial"
    },
    {
        "display": "Cartoon bear with umbrella",
        "full": "autumn a bear with an umbrella cartoon watercolor trending on artstation"
    },
    {
        "display": "Futuristic honey jelly pattern",
        "full": "a photorealistic 3 d seamless pattern of honey jelly mead material with wires circuits cables pcb futuristic robotic elements in glass and mirror, 3 d realistic model render in the style of zaha hadid, in cyberpunk 2 0 7 7 colors, unreal engine 5, keyshot, octane, artstation trending, ultra high detail, ultra realistic, cinematic, 8 k, 1 6 k, in style of zaha hadid, in style of nanospace michael menzelincev, in style of lee souder, in plastic, dark atmosphere, tilt shift, depth of field"
    },
    {
        "display": "Megan Fox as vampire nun",
        "full": "portrait of megan fox as a nun with vampire bitemarks, catholic, church, bible, christian, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Cyberpunk fallen seraphim tarot",
        "full": "a beautiful tarot card artwork of a cyberpunk dark fallen seraphim, backlit, horror, gloomy, highly detailed, golden hour, digital painting, by karol bak and justin gerard and dan mumford and artgerm, vivid colors, masterpiece, detailed shading, 8 k resolution, intricate, smooth"
    },
    {
        "display": "Cinematic organic robot portrait",
        "full": "cinematic portrait of organic robot, faded colors, exotic alien features, cyber background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, zack snyder, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Cyberpunk portrait illustration",
        "full": "ultra realistic illustration, closeup portrait shot, perfect lighting, hacknaut cyberpunk, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Child with spider legs.",
        "full": "child, black eyes, sharp teeth, spiderlegs, portrait, intricate, detailed, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art,"
    },
    {
        "display": "Detailed portrait of Nico Robin.",
        "full": "highly detailed vfx portrait of nico robin, greg rutkowski, loish, rhads, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, backlit, harsh overhead sunlight, blue eyes,"
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "portrait of megan fox, sexy muscular upper body, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Paul Atreides in desert",
        "full": "paul atreides stands in the desert, dune novel cover, poster art, by michael whelan, artgerm, retro, nostalgic, old fashioned, 1 9 8 0 s teen horror novel cover, book"
    },
    {
        "display": "Three men on book",
        "full": "a painting of three brown men astanding on top of a giant open book, hyperrealistic faces, detailed digital art, aesthetic!, trending on artstation"
    },
    {
        "display": "Ethereal dark prince bust",
        "full": "bust of a prince of darkness , long hairs, gradient black red, dreamy and ethereal, orange eyes, golden ratio, vicious expression, ornate frilly suit,cloudy ghostly aspect, beautiful, dark fantasy, intricate, elegant, rainbow bubbles, highly detailed, digital painting, artstation, concept art, smooth,b sharp focus, illustration, black background art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Furry cyberpunk fox portrait.",
        "full": "award winning beautiful portrait commission of a male furry anthro melanated fox fursona with a tail and a cute beautiful attractive detailed furry face wearing stylish black and gold cyberpunk clothes in a cyberpunk city at night while it rains. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Symmetrical Harley Quinn portrait",
        "full": "symmetry!! stunning portrait of harley quinn!! by victo ngai, kilian eng vibrant colors, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aestheticly inspired by beksinski and dan mumford, upscale with simon stalenhag work, trending on artstation, art by artgerm and greg rutkowski, 8 k"
    },
    {
        "display": "Rainforest Canopy Aerial View",
        "full": "aerial view of a rainforest canopy, by studio ghibli and greg rutkowski,"
    },
    {
        "display": "Symmetrical portrait of perfection",
        "full": "symmetrical face!! portrait of young woman blessed with ever - increasing physical and mental perfection, realism, blonde hair, perfect face!! intricate, elegant, highly detailed, vision of holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, humanity, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vibrant melting knight portrait",
        "full": "a detailed painting portrait inspired by moebius and beksinski of a vibrant liquid melting black knight in armour with aurora inspired by detailed sculpture. inspired by alien. inspired by dieselpunk. inspired by brain scan. science fiction poster. upperbody. cinematic sci - fi scene. accurate anatomy. symmetry. portrait and science fiction theme with lightning, aurora lighting. clouds and stars. smoke. futurism. fantasy. by beksinski carl spitzweg. baroque elements. baroque element. intricate artwork by caravaggio. oil painting. oil on canvas. award winning. dramatic. trending on artstation. 8 k"
    },
    {
        "display": "Spaceship landing on Mars.",
        "full": "illustration of spaceship landing on mars by coby whitmore trending on artstation digital art"
    },
    {
        "display": "Young man in white hood",
        "full": "ultra realistic illustration, a young man in a white hood, with brown hair, with blue eyes, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elven warrior Bill Gates.",
        "full": "Bill Gates as an elven warrior in a mysterious forest, highly detailed face, realistic face, beautiful detailed eyes, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, photorealistic"
    },
    {
        "display": "Photorealistic alien egg scene.",
        "full": "spiderman alien egg, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Purple panther roaring at moon.",
        "full": "closeup of a purple panther roaring at the moon in the forest. night. large moon in the center. cinematic. painting. concept art."
    },
    {
        "display": "Fierce Asian queen throne",
        "full": "a photorealistic dramatic fantasy render of a strong fierce beautiful young asian queen with medium length hair, sitting on her throne, mother of mankind, ruler of the universe, future year 3 0 0 0, by wlop, artgerm, greg rutkowski, alphonse mucha, beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, artstation, concept design art, octane render, 8 k"
    },
    {
        "display": "Mischievous Galadriel portrait.",
        "full": "portrait of ((mischievous)), baleful Cate Blanchett as Galadriel as a queen of fairies, dressed in a beautiful (silver) dress. The background is a dark, creepy eastern europen forrest.  night, horroristic shadows, high contrasts, lumnious, theatrical, character concept art by ruan jia, thomas kinkade, and J.Dickenson, trending on Artstation"
    },
    {
        "display": "Psychedelic energy woman artwork",
        "full": "a powerful energy psychedelic matrix woman, by alexander fedosav, hyper detailed digital matte painting, concept art, hyperrealism, 1 6 k resolution, cinema 4 d, 8 k resolution, trending on artstation, behance hd, a masterpiece, by stephan martiniere, particles, cel - shaded, power bright neon energy, by david a. hardy,"
    },
    {
        "display": "Cosplay female rider in fantasy city.",
        "full": "cosplay blue haired, solo female character, light armour, miniskirt, riding steampunk motorcycle, in fantasy sci - fi city, night time, city lights, motion blur, final fantasy, cinematic, realistic, stylised, unreal engine, lumen, realistic, artgerm"
    },
    {
        "display": "John Soap MacTavish Portrait",
        "full": "a portrait of a John soap mactavish ,call of duty, military , game concept art, illustration, modern warfare, HDR, natural light, shoulder level shot, dynamic pose, award winning photograph, Mucha style, 8k,"
    },
    {
        "display": "Fantasy elf portrait",
        "full": "portrait, 30 years old women :: fantasy elf, small ears :: amber eyes, long straight darkblond hair :: attractive, symmetric face :: brown medieval cloting, natural materials :: high detail, digital art, RPG, concept art, illustration"
    },
    {
        "display": "Fantasy portrait of Ronaldo.",
        "full": "ronaldo luis nazario da lima, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and h r giger and alphonse mucha"
    },
    {
        "display": "Anime-inspired baroque illustration.",
        "full": "baroque oil painting japanese light novel cover full body illustration lotr anime key visual stunning action shot | young girl black military crusader maid with long flowing blonde hair | symmetrical perfect face fine detail delicate features quiet gaze | gapmoe kuudere moody lighting bokeh | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Realistic vampire character portrait",
        "full": "vampire, ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Elegant Hatsune Miku illustration",
        "full": "hatsune miku art by xima, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime character portrait.",
        "full": "Link  anime character beautiful digital illustration portrait design by Ross Tran, artgerm detailed, soft lighting"
    },
    {
        "display": "Post-apocalyptic character portrait",
        "full": "character concept portrait of the last days of war between USA and CHINA, post apocalyptic, intricate, elegant, digital painting, concept art, smooth, sharp focus, illustration, from Metal Gear, by Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau, Artgerm"
    },
    {
        "display": "Eerie warforged figure portrait.",
        "full": "award - winning. trending on artstation. 4 k. eerie tone. a warforged figure wearing a hooded cape made of the night sky with 1 dark blue glowing eye on its face. full - body. portrait."
    },
    {
        "display": "Eerie hooded astral figure.",
        "full": "award - winning. trending on artstation. 4 k. eerie tone. a faceless astral figure wearing a hooded cape made of the night sky with 1 dark blue glowing eye on its face. full - body. - - seed 4 1 1 7 8 8 9 3 9 1"
    },
    {
        "display": "Zodiac Knights Battle at Sunrise",
        "full": "white hair knights of zodiac gir, matt white ice color armor, are combat fighting each other with magical sci - fi weapon in ruined agora of athens sunrise, intricate and elegant, highly detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration, art by tian zi and wlop and alphonse mucha"
    },
    {
        "display": "Futuristic queen portrait.",
        "full": "epic futuristic close-up macro portrait of the face of a beautiful queen, epic angle and pose, symmetrical artwork, 3d with depth of field, blurred background, cybernetic jellyfish crystal, obsidian, female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render , made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse, by Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong."
    },
    {
        "display": "Modular insulated family tent",
        "full": "cabela's beautiful comfortable modular insulated wall kit - house all weather family dwelling tent house, person in foreground, mountainous forested wilderness open fields, beautiful views, painterly concept art, joanna gaines, environmental concept art, farmhouse, magnolia, concept art illustration, by james gurney, by craig mullins, by greg rutkowski trending on artstation"
    },
    {
        "display": "Harvey Dent character design.",
        "full": "harvey dent, character sheet, concept design, contrast, hot toys, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Medusa with snake hair.",
        "full": "''face medusa with snake hair, furry, greek mythology, greece, fantasy, dungeons and dragons, d & d, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha''"
    },
    {
        "display": "Symmetrical portrait of Nicolas Cage",
        "full": "symmetry!! portrait of nicolas cage, long hair in the wind, smile, happy, white vest, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cloud in battle stance.",
        "full": "cloud from final fantasy 7, battle stance , highly detailed, in a magical lush field of overgrown plants, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy character artwork",
        "full": "jordan by artgerm and wlop and scott fischer and seb mckinnon, digital art, highly detailed, wide shot, intricate, fantasy, mystical, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Androgynous furry rat in cyberpunk.",
        "full": "beautiful furry digital art portrait commission of an androgynous furry anthro rat fursona wearing punk clothes in the streets of a cyberpunk city. neon signs. character design by charlie bowater, ross tran, artgerm, and makoto shinkai"
    },
    {
        "display": "Cyborg jellyfish skeleton illustration",
        "full": "Cyborg biomechanical jellyfish skeleton, sci-fi, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Wanda Sykes Hamburger Portrait",
        "full": "portrait of wanda sykes eating hamburgers, extra onions and ketchup, luscious patty with sesame seeds, ethereal, handsome, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed humorous occult illustration",
        "full": "Salvador Dali, Extremely Highly detailed, Occult, funny, humorous, humor, hilarious, funny, entertaining, magical, trending on artstationHQ, closeup, D&D, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration"
    },
    {
        "display": "Murdered prince comic cover",
        "full": "a murdered prince, in the style of artgerm, charlie bowater, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Fantasy lady spinning wool",
        "full": "a lady spinning wool on an old sheep farm, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Apocalyptic chocolate landscape.",
        "full": "digital painting, trending on pixiv, life as we know it coming to an end, chocolate chocolate chooclate chocolate, intricate scenery, y 2 k, unknown"
    },
    {
        "display": "Skinny male fantasy alchemist",
        "full": "skinny male fantasy alchemist, long dark hair, 1 9 2 0 s, elegant, highly detailed, intricate, smooth, sharp focus, artstation, digital paining, concept art, art by donato giancola, greg rutkowski, artgerm, cedric peyravernay"
    },
    {
        "display": "Winter landscape, Game of Thrones",
        "full": "the wall, game of thrones, winter, snow, super highly detailed, professional digital painting, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Giant azure tree paradise.",
        "full": "giant azure tree on a paradise planet, in the distance, magical, concept art, digital art, colorful, divine, massive scale"
    },
    {
        "display": "Fantasy portrait of Alexandra Daddario",
        "full": "full body portrait of Alexandra Daddario, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and peter mohrbacher"
    },
    {
        "display": "Rebel princess portrait, sci-fi.",
        "full": "rebel princess portrait, sci-fi, glossy eyes, face, fringe dark hair , fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, flat pastel colors, sharp ink line art"
    },
    {
        "display": "Stormy ocean with cute creatures",
        "full": "of a dark and stormy ocean with large strange cute friendly water creatures with big eyes, mouth and round teeth appearing from the water, in the style of Gehry, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Handsome cowboy by campfire",
        "full": "a dramatic, epic, ethereal painting of a !!!handsome!!! thicc chunky beefy mischievous shirtless with a big beer belly wearing a large belt and bandana offering a whiskey bottle | he is a cowboy short beard relaxing by a campfire | background is a late night with food and jugs of whisky | homoerotic, rugged | stars, tarot card, art deco, art nouveau, mosaic, intricate, digital painting | by Mark Maggiori (((and Alphonse Mucha))) | trending on artstation"
    },
    {
        "display": "Grim casino interior render",
        "full": "fallout concept art casino interior render grim realistic lighting unreal engine 5"
    },
    {
        "display": "Futuristic Jesus in city",
        "full": "jesus christ in futuristic city, detailed intricate illustration, dark atmosphere, detailed illustration, hd, 4 k, digital art, overdetailed art, by greg rutkowski, by loish, complementing colors, trending on artstation, deviantart"
    },
    {
        "display": "Cowboy chase in desert.",
        "full": "the man in black fled aross the desert, and the gunslinger followed. hyperrealistic, photorealistic, concept art, fantasy art, wild west, cowboy, mystical, trending on artstation, digital illustration, cinematic lighting, 4 k, ultra hd, beautiful colors, highly detailed, octane render, unreal engine 5"
    },
    {
        "display": "Megan Fox portrait, sultry expression.",
        "full": "portrait of megan fox with sultry face expression, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Witch Queen in Victorian Dress",
        "full": "megan fox witch queen, black eyes, blood, full body, intricate victorian dress, middle shot, cinematic lighting, studio quality, symmetrical eyes, artgerm, joshua middleton, rafael albuquerque, moody lighting, candles, art style by klimt and krenz cushart"
    },
    {
        "display": "Cybernetic xenomorph portrait",
        "full": "a portrait of a beautiful cybernetic xenomorph, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Dubrovnik in mountains illustration",
        "full": "dubrovnik in the caucasus mountains, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and raphael lacoste and magali villeneuve"
    },
    {
        "display": "\"8-Year-Old Studying Gundams\"",
        "full": "an 1 8 year old appearing isabelle adjani is studying gundam mobile suits, zakus, illustrated on transparent glass by ross tran, katsuhiro otomo, yoshitaka amano, artgerm, and wes anderson. 3 d shadowing effect!!!, 8 k resolution!!!"
    },
    {
        "display": "Duotone vintage model illustration",
        "full": "duotone noir concept illustration 3 / 4 portrait of female vintage model from fallout 4 advertising nuka cola. accidental renaissance. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. concept art. trending on artstation"
    },
    {
        "display": "Elon Musk as Captain America",
        "full": "elon musk as captain america, marvel character, portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Zero Suit Samus Portrait",
        "full": "portrait of Zero Suit Samus Aran as Overwatch character, medium shot, asymmetrical, profile picture, Organic Painting, sunny day, Matte Painting, bold shapes, hard edges, street art, trending on artstation, by Greg Manchess and Huang Guangjian and Loish and Gil Elvgren and Sachin Teng"
    },
    {
        "display": "Elegant Buffy illustration",
        "full": "kristen bell buffy the vampire slayer, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Dwayne Johnson as Shiva.",
        "full": "portrait of dwayne johnson as shiva god of destruction, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, artey freytag"
    },
    {
        "display": "Cyborg clown samurai portrait",
        "full": "portrait of a diabolical cyborg clown samurai, torn cape, adaptive armor, dynamic pose, heavy eyes to the side, ancient ruins, glowing veins subsurface scattering, in clouds, sunset, portrait, by gerald brom, by mikhail vrubel, by peter elson, muted colors, extreme detail, reflections, trending on artstation, 8 k"
    },
    {
        "display": "Black Hello Kitty portrait",
        "full": "Portrait of black Hello Kitty in hoodie, 8k, trending on ArtStation, hyperdetalied,"
    },
    {
        "display": "Goth Cyborg Biden Portrait",
        "full": "portrait of joe biden goth cyborg with white hair in warhammer armor, art by kuvshinov ilya and wayne barlowe and gustav klimt and artgerm and wlop and william - adolphe bouguereau"
    },
    {
        "display": "Muscular red beast illustration",
        "full": "Dark Fantasy Painting of a muscular red beast with blood dripping from its mouth,  creepy, unsettling, horror, upper body, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Brown leather toe shoes",
        "full": "toe shoes, toeshoes, a pair of vibram fivefingers made of brown leather with laces, concept art equipment, fantasy illustration, magic item"
    },
    {
        "display": "Anime girl with angel wings",
        "full": "a beautiful anime girl with angel wings, by artgerm, wlop and greg rutkowski, hd, hdr, ue 5, ue 6, unreal engine 5, cinematic 4 k wallpaper, 8 k, ultra detailed, high resolution, artstation, award winning"
    },
    {
        "display": "Character concept art",
        "full": "kerli koiv as cindy aurum from final fantasy 1 5, character concept art, sharp, digital matte painting, art by greg rutkowski, wlop, dramatic lighting, trending on artstation"
    },
    {
        "display": "Iridescent female mage robot.",
        "full": "hyperdetailed portrait, dramatic angle, detailed azure eyes, stunningly beautiful female mage robot made of iridescent metals and shiny gems, ethereal rainbow nimbus, golden necklace, noble perfect androgynous skeleton inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, highly detailed, photorealistic, 8 k, huang guangjian, cgsociety"
    },
    {
        "display": "Last dollar at register.",
        "full": "paying your last dollar bill at a cash register trending on artstation dark lighting"
    },
    {
        "display": "Katy Perry as a rogue.",
        "full": "katy perry as a rogue, d & d, fantasy, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Superhero Character Redesign",
        "full": "Invincible Redesign by Scadilla on DeviantArt Marvel Rpg, Marvel Comics Art, Marvel Heroes, Character Model Sheet, Character Modeling, Character Design, Superhero Characters, Dc Characters, Invincible Comic, trending on artstation, volumetric lighting, micro details, ray tracing, 8k"
    },
    {
        "display": "Gorgeous woman, half-dyed hair.",
        "full": "photo of gorgeous woman with red and white half dye hair in the style of stefan kostic, realistic, half body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, foggy backgeound"
    },
    {
        "display": "Demonic vampire widow portrait",
        "full": "Demonic beautiful vampire white-haired young woman mistress of death mourning widow vintage gown with a faint smile dark lipstick macro golden iris wearing dark clouds fog smoke and fire as clothes, colourful trending artstation, detailed portrait academic caravaggio Bouguereau, sharp focus medium shot"
    },
    {
        "display": "Dieselpunk airships in fog.",
        "full": "Dieselpunk silent hill, giant airships in the sky, aeroplane, steam, epic composition, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Redhead vampire sorceress portrait",
        "full": "redhead vampire sorceress, perfect face viewed in profile, bright glowing blue and silver eyes, gold shirt, cinematic, floating ash, stunning, highly detailed, artstation, smooth, hard focus, concept art, art by artgerm and greg rutkowski and alphonse mucha, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Elegant Pre-Raphaelite Digital Art",
        "full": "sansa ofelia, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, pre - raphaelite style, mucha, monet"
    },
    {
        "display": "Futuristic hybrid woman portrait",
        "full": "A fancy portrait of an attractive hybrid women with fur by Greg Rutkowski, beeple, Sung Choi, Mitchell Mohrhauser, Maciej Kuciara, Johnson Ting, Maxim Verehin, Peter Konig, final fantasy, macro lens , 8k photorealistic, cinematic lighting, HD, high details, dramatic, dark atmosphere, trending on artstation"
    },
    {
        "display": "Upside-down female farmer portrait.",
        "full": "full body portrait of a female farmer upside down, untidy hair, highly detailed, dirty face, next to a red barn, digital painting, artstation, concept art, soft focus, depth of field, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Japanese sci-fi horror girl",
        "full": "japanese sci fi horror girl with big eyes, character portrait, portrait, close up, concept art, intricate details, highly detailed, vintage sci - fi poster, in the style of chris foss, rodger dean, moebius, michael whelan, and gustave dore"
    },
    {
        "display": "Cute magical monster portrait",
        "full": "A full shot of a cute magical monster Cryptid wearing an ornate dress made of opals and tentacles. Chibi. Subsurface Scattering. Translucent Skin. Caustics. Prismatic light. defined facial features, symmetrical facial features. Opalescent surface. Soft Lighting. beautiful lighting. By Giger and Ruan Jia and Artgerm and WLOP and William-Adolphe Bouguereau and Loish and Lisa Frank. Sailor Moon. Masterpiece. trending on artstation, featured on pixiv, award winning, cinematic composition, dramatic pose, sharp, details, Hyper-detailed, HD, HDR, 4K, 8K."
    },
    {
        "display": "Photorealistic office scene.",
        "full": "human people walking among employee in an office, highly detailed, photorealistic, 8 k, illustration, octane render, unreal engine 5, artstation hd, deviantart, by madgwick, rhads, greg rutkowski, artgerm"
    },
    {
        "display": "Alien cyberpunk portrait",
        "full": "realistic detailed face portrait of a young beautiful alien baroque cyberpunk marie antoinette by alphonse mucha, ayami kojima, amano, greg hildebrandt, and mark brooks, female, feminine, art nouveau, rococo cyberpunk, neo - gothic, gothic, character concept design"
    },
    {
        "display": "Cinematic desert oasis paradise",
        "full": "cinematic view of a desert oasis paradise filled with swirling nebula magic, exotic cacti, desaturated, psychedelic, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Futuristic European cityscape",
        "full": "europe city in fallout world, concept art, digital art, futuristic, artstation"
    },
    {
        "display": "Handsome man portrait, Artgerm style.",
        "full": "a highly detailed portrait of a handsome man in the style of artgerm."
    },
    {
        "display": "Cybernetic Indian man portrait",
        "full": "Portrait of a man by Greg Rutkowski, he is about 30 years old, indian, cybernetic eyes implants, messy long black hair, slim and tall, he is wearing utilitarian beige black jumpsuit, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Mayan jaguar warrior portrait.",
        "full": "portrait of a mayan jaguar warrior's, surrounded by flowers by karol bak, james jean, tom bagshaw, rococo, trending on artstation, cinematic lighting, hyper realism, octane render, 8 k, hyper detailed."
    },
    {
        "display": "Cinematic elf portrait.",
        "full": "elf, final fantsy, digital painting, portrait , cinematic lighting, highly detailed, artstation, concept art, illustration, smooth, sharp focus, editor's pickup, trending on artstation, trending on deviantart, alphonse mucha, WLOP"
    },
    {
        "display": "Futuristic Victorian portrait.",
        "full": "marvel comic book style portrait painting of an old thin wispy man with a mean intelligent face, and futuristic victorian clothing, standing in front of a computer simulation, sci - fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and jim burns"
    },
    {
        "display": "Young redhead in armor",
        "full": "young short redhead, wearing black ornamented medieval armour, bare leg, detailed, by gaston bussiere, bayard wu, greg rutkowski, giger, maxim verehin, greg rutkowski, masterpiece, sharp focus, cinematic lightning"
    },
    {
        "display": "Punk goddess portrait with masks.",
        "full": "3 d goddess close - up profile simple portrait punk with mohawk with ram skull. beautiful intricately detailed japanese crow kitsune mask and clasical japanese kimono. betta fish, jellyfish phoenix, bio luminescent, plasma, ice, water, wind, creature, artwork by tooth wu and wlop and beeple and greg rutkowski"
    },
    {
        "display": "Bearded man with corgi.",
        "full": "full portrait of a old, ruggedly handsome bearded man petting a corgi dog, soft hair, muscular, half body, cloth, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic cyberpunk women",
        "full": "Photorealistic illustration, beautifull women with beautifull clothe  , cyberpunk 2077, sci-fi, futuristic, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, art by artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surreal raven sculpture with moon.",
        "full": "Intricate stunning highly detailed surreal raven by  Seb McKinnon, sculpture, ultra realistic, Horror, full blood moon, thick swirling smoke tornado, fire embers, trending on artstation"
    },
    {
        "display": "Ogun, warrior deity portrait.",
        "full": "ogun holding a great spear, African warrior deity wearing ancient golden armor, orisha God of hunters and craftsmen, strong masculine features, menacing cinematic mid portrait, digital illustration, octane render trending on arstation by artgerm, raphaelite and mucha"
    },
    {
        "display": "Arcane-style exploding leaves.",
        "full": "arcane style green leaves explosion, green leaves, bright art masterpiece artstation. 8k, sharp high quality artwork in style of Jose Daniel Cabrera Pena and Greg Rutkowski, concept art by Tooth Wu, blizzard warcraft artwork, hearthstone card game artwork, leaves exploding, leaves explosion"
    },
    {
        "display": "Anime girl trading card",
        "full": "girl, trading card front, anime style, long hair, hair down, symmetrical facial features, badminton, hyper realistic, pale skin, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, sport, d & d, realistic lighting, by alphonse mucha, greg rutkowski, sharp focus, backlit"
    },
    {
        "display": "Darkwave character portrait",
        "full": "jalexander masson, final fantasy, darkwave, darksynth character portrait, sharp, digital matte painting, art by luis royo, greg rutkowski, wlop, dramatic lighting, trending on artstation"
    },
    {
        "display": "Oscar Isaac as Solid Snake",
        "full": "oscar isaac as solid snake highly detailed concept art"
    },
    {
        "display": "Crocodile-octopus hybrid design",
        "full": "crocodile octopus monster, character design, concept art, artstation"
    },
    {
        "display": "Ceramic cyborg in Kakiemon armor",
        "full": "ceramic cyborg, Kakiemon design armor with plums and stylized flowers in glaze and gilding Edo period 1670\u20131690, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Transhumanist cyberpunk artwork.",
        "full": "transhumanism, concept art, fine details, studio ghibli, cinematic lighting, ghost-in-the-shell, cyberpunk,sci-fi, fantasy, intricate, elegant, highly detailed, digital painting, trending on artstation, concept art, smooth, sharp focus, illustration, by james gurney and greg rutkowski"
    },
    {
        "display": "Crusader in moon crater",
        "full": "crusader in armor standing in moon crater, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap, sunny"
    },
    {
        "display": "Bloodied skeleton portrait.",
        "full": "skeleton black bones!!, covered with blood, rolyatistaylor face!!, highly detailed face, long red hair, beautiful blue eyes, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. retro film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pale blonde male Leona pinup",
        "full": "an extremely pale white blonde leona from league of legends as a male, white secretary shirt and black miniskirt, pinup pose, hyper detailed, digital illustration, illustration, trending in artstation, cinematic lighting, studio quality, sharp focus, intricate, elegant, art style by alphonse mucha and klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "3D Printed Fantasy Chess Piece",
        "full": "3 d printed queen chess piece, of the light blue holy water light, unusually unique beauty, swimming fish, small jellyfish, starfish, emotionally evoking symbolic metaphors, head in focus, fantasy, ornamental, intricate, elegant, sensual, highly detailed digital painting, artstation, concept art, painterly, golden ratio, sharp focus, isometric view, photorealistic, paper texture"
    },
    {
        "display": "Warrior Rapunzel portrait.",
        "full": "warrior Rapunzel as aeon flux profile picture by Margaret Keane, dynamic pose, intricate, futuristic, fantasy, elegant, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell,"
    },
    {
        "display": "Anunnaki God Statue Overlooking Cyberpunk City",
        "full": "giant statue of anunnaki god anu on top of a hill overlooking a cyberpunk city, hyperrealistic concept art, detailed, sharp focus, cgsociety, atmospheric lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Tardigrade kaiju concept art",
        "full": "realistic painting of a tardigrade kaiju, with 6 legs, by james gurney, smiley, godzilla, vintage, concept art, winner"
    },
    {
        "display": "Fantasy portrait of Trump.",
        "full": "digital art, fantasy portrait of donald trump saying i take the 5 th, by james jean, by ross tran, ultra detailed, character design, concept art, trending on artstation,"
    },
    {
        "display": "Colorful wizard sculpture",
        "full": "a sculpture of cute wizard black red 3 d by jeff koons ray tracing, octane redner brilliantly coloured, trending on artstation, unreal engine, hdr, polished"
    },
    {
        "display": "Cain and Abel confrontation",
        "full": "hyperrealistic mixed media painting of Cain stabbing Abel with a spear lance, in front of a primitive altar with white smoke ascending, masculine and handsome, stunning 3d render inspired art by P. Craig Russell and Barry Windsor-Smith + perfect facial symmetry + dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, intricate, epic composition, grim yet sparkling atmosphere, cinematic lighting + masterpiece, trending on artstation, very very detailed, masterpiece, stunning"
    },
    {
        "display": "Deranged wizard dark ritual",
        "full": "beautiful concept of deranged wizard in a dark ritual, horror art, nightmare, insane details, intricate background, cinematic shot, dramatic light, night time, red moon, artstation, neo gothic, eerie"
    },
    {
        "display": "Translucent wax death sculpture.",
        "full": "death made of soft translucent wax, wooden art nouveau swirls, strong subsurface scattering, colored smoke, gold cables, subsurface scattering, in the style of ruan jia and beeple and giger, subsurface scattering, mystical colors, back light, rim light, dramatic lighting, 8 k, stunning scene, raytracing, octane render, trending on artstation"
    },
    {
        "display": "Cell-shaded neon cityscape",
        "full": "a study of cell shaded cartoon of a blade runner 2 0 7 7 style city, illustration, strong colors, neon advertisements, concept art by josan gonzales and wlop, by james jean, victo ngai, david rubin, mike mignola, laurie greasley, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Kanye-like toad illustration",
        "full": "of a toad that looks like kanye but with features of a toad, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "\"Angelic Grim Reaper Scene\"",
        "full": "angel of death collecting souls in the style of zdzislaw beksinski, horrific, digital painting, CG render, unreal, fantasy, dramatic, concept art, artstation, smooth, sharp focus"
    },
    {
        "display": "Cyberpunk K-pop portrait.",
        "full": "portrait painting of cyberpunk chuu loona kpop smiling cheerfully with a black katana, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Picard and Marsh wedding painting",
        "full": "Painting of Jean Luc Picard and Jodie Marsh getting married, real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk Rastafarian Portrait",
        "full": "cyberpunk, closeup portrait of a shaggy cyberpunk rastafarian, tired, stubble beard, washed up, dramatic light, city background, sunset, dystopian setting, high contrast, sharp, neuromancer, henry dorsett case, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Victorian woman with claw nails",
        "full": "a victorian woman with nails like claws, young woman, blue dress, white sleeves, golden - blonde hair, indignant, character art, full body art, dungeons and dragons, d & d, trending on artstation, artgerm, 4 k ultra hd, sharp focus, digital art by ilya kuvshinov and ross tran"
    },
    {
        "display": "Cartoonish Tom Waits Character Design",
        "full": "cartoonish tom waits eating dinner, vivid colors, character sheet, fine details, concept design, contrast, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Goddess of Space Illustration",
        "full": "beautiful goddess of space and dreams by alphonse mucha, mandala, coherent design, symmetrical, vivid colors, digital watercolor ink illustration painting, complementary color, golden ratio, detailed, sharp lines, sharp focus, intricate, rainbowshift, artgerm, gustave dore, maxfield parrish, octane render"
    },
    {
        "display": "Young blonde girl portrait",
        "full": "portrait of a young blonde girl with a dark purple parrot, upper body, long hair, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Redshirt Guy Portrait",
        "full": "portrait of redshirt guy, digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Fantasy movie poster collage",
        "full": "Beautiful Fantasy Movie Poster made for the film The Wizard of Oz (1941) starring Taylor Swift and Mark Zuckerberg,  minimalist oil paint and ink and photograph collage by Man Ray and Marcel Duchamp , Vivid color trending on artstation Cinematic lighting  collage!! 8k"
    },
    {
        "display": "Cyborg crucifixion in wasteland.",
        "full": "ribbed abandoned cyborg on a massive giant crucifix block, biomechanical cyberpunk technology crucifixion scene on exoplanet, dark green tint, washed colors, baroque painting, standing in a desolate empty wasteland, creepy, nightmare, dream - like heavy atmosphere, surreal abandoned buildings, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic, chiaroscuro, raphael, caravaggio, beksinski, giger"
    },
    {
        "display": "Goth couple Art Deco portrait",
        "full": "breathtaking detailed concept art painting art deco portrait of goth couple amalgamation raven, by hsiao - ron cheng, bizarre compositions, exquisite detail, extremely moody lighting, 8 k"
    },
    {
        "display": "Lifelike water elemental portrait.",
        "full": "water elemental, lifelike, portrait, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Giant beehive matte painting",
        "full": "A highly detailed matte painting of the biggest beehive ever, by Studio Ghibli, Makoto Shinkai, by Artgerm, by beeple, by Greg Rutkowski, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Photorealistic McDonald's in Hell",
        "full": "a mcdonald's in hell trending on artstation deviantart pinterest photorealistic hd 8 k highlights and shadow detailed high resolution"
    },
    {
        "display": "Happy anime woman portrait",
        "full": "A medium shot anime portrait of a happy brunette anime woman, a single short ponytail, parted hair, bare forehead, blue-eyed, blue eyes, big round nose closed lips, with large breasts, wearing a t-shirt, solid background, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimi chan, trending on artstation"
    },
    {
        "display": "Epic marijuana superhero closeup",
        "full": "epic scale cinematic marijuana superhero cinematic 4 k perfect focus closeup macro photography of a marijuana bud crystals trichomes, densely packed buds of weed, high times visionary photography by greg rutkowski alex grey hr giger artgerm mc escher cgsociety by alphonse mucha android jones max chroma rule of thirds golden ratio alien plants"
    },
    {
        "display": "Kobold chef in tavern.",
        "full": "a reptilian kobold chef in a tavern kitchen, Full body shot, D&D, fantasy, intricate, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Fantasy digital painting",
        "full": "prompt truncated after tokenization, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Wise blonde elven mage portrait",
        "full": "portrait of a wise blonde elven mage, dark, piercing eyes, gentle expression, elegant clothing, photorealistic, highly detailed, artstation, smooth, sharp focus, art by michael whelan, artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy animal faeries illustration",
        "full": "animal faeries lady digital art painting fantasy by hubert robert and lee madgwick and roger dean and jacek yerka, dan mumford and alex grey style, soft lighting, 4 k hd wallpaper illustration character design concept joy atmospheric lighting"
    },
    {
        "display": "Ethereal circuit forest deer",
        "full": "deer in an ethereal forest made from glowing circuits and electronics, highly detailed concept art, 3 d, volumetric lighting w 1 0 2 4"
    },
    {
        "display": "Symmetrical face with machinery.",
        "full": "symmetry!! hannah jeter, machine parts embedded into face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Detailed female android art",
        "full": "ultra detailed female android, scifi, fantasy, octane render, ( dia de los muertos ), asymmetrical, intricate concept art, intricate detailed environment, global illumination, art by godmachine and michael welan and rossdraws and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Nebula portrait illustration.",
        "full": "portrait of beautiful nebula from guardians of the galaxy, passionate pose, intricate, elegant, space glow, sharp focus, soft bokeh, illustration, highly detailed, concept art, matte, trending on artstation, vibrant colors, 3 d 8 k, art by wlop and artgerm and greg rutkowski, mucha, giger, marvel comics, beksinski"
    },
    {
        "display": "Oona Chaplin, stylized portrait.",
        "full": "full length photo of Oona Chaplin in a tanktop in the style of stefan kostic, light blue eyes, not realistic, sharp focus, 8k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Squirrel Girl Portrait",
        "full": "Full-Body Portrait of Squirrel Girl, Marvel, fantasy, tail, symmetrical face, blush, intricate, cute, elegant, highly detailed, nature, yellow mist, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Japanese girl tattoo design",
        "full": "tattoo design, stencil, traditional Japanese, beautiful portrait of a girl by artgerm, artgerm"
    },
    {
        "display": "Realistic businesswoman dancing portrait",
        "full": "portrait painting of woman in business suit dancing, ultra realistic, concept art, full body, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Wolverine portrait, dramatic lighting.",
        "full": "wolverine in costume mask starring into the camera, fixed eyes, cinematic, surreal, dramatic lighting, face, detailed, intricate, elegant, highly detailed, digital painting, artstation, chalk, concept art, smooth, sharp focus, illustration, art by sam spratt,"
    },
    {
        "display": "Intricate Marvel Portrait",
        "full": "portrait of ryan gosling, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by ruan jia and mandy jurgens and artgerm and wayne barlowe and greg rutkowski and frank frazetta"
    },
    {
        "display": "Alien pub scene",
        "full": "medium - shot, vislor turlough played by mark strickson at age 1 8, at the alien space pub, crowded, with lights, interior, from doctor who series, artstation, highly detailed digital painting, smooth, global illumination, sci fi art by greg rutkowsky, karl spitzweg, leyendecker"
    },
    {
        "display": "Beautiful anime girl sitting",
        "full": "a very beautiful anime girl, full body, long wavy blond hair, sky blue eyes, full round face, short smile, cute top, miniskirt, sitting on a miniature city, cinematic lightning, medium shot, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan"
    },
    {
        "display": "Dark elf warrior portrait",
        "full": "the dark one, elf man, full plate armour, black eyes, no helmet | | realistic shaded, fine details, fine - face, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin, michael garmash, rob rey"
    },
    {
        "display": "Fashion model headshot, spiritual attire.",
        "full": "professional head shot concept art of a beautiful fashion model wearing catholic spiritual clothes by reza nia, nick knight, amy judd, jil sander minimal romantic heavenly elite style, posed, flushed cheeks, fluorescent green makeup, studio, studio lighting, flat natural tones, sharp focus, 8 k, very fine detail, stunning matte painting"
    },
    {
        "display": "Elven warrior in silver armor",
        "full": "pretty elven man, long hair, tan skin, fantasy, light silver armor, super detailed, very ornate, by alphonse mucha, artgerm, magali villeneuve, symmetrical face, 8 k, sharp focus, illustration"
    },
    {
        "display": "Cyberpunk airport coronation scene",
        "full": "sci - fi airport constrast 3 d realistic model render view from above in the coronation of napoleon painting with point cloud in the middle, in cyberpunk 2 0 7 7 colors, unreal engine 5, keyshot, octane, artstation trending, ultra high detail, ultra realistic, cinematic, 8 k, 1 6 k, in style of zaha hadid, in style of nanospace michael menzelincev, in style of lee souder, in plastic, dark atmosphere, tilt shift, depth of field,"
    },
    {
        "display": "Armored Green Lantern Portrait",
        "full": "greg manchess portrait painting of armored green lantern as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Evil blond cyborg portrait.",
        "full": "symmetry portrait of an evil blond beefy irish male cyborg with very long golden hair, genetically augmented, clean shaven!!!!, sci - fi, evil villain black cybernetic armor, glowing lights intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cell-shaded portrait of Marilyn Manson",
        "full": "a study of cell shaded portrait of marilyn manson concept art, llustration, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00edn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, alien, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Female superhero character design",
        "full": "character sheet concept art of female superhero, destiny 2, hooded opalescent diaphanous robe with intricate gold inlay, red fabric, gypsy, face paint, ferrofluid armor, realistic, hyperrealistic, photographic, costume, wlop, dan mumford, greg rutkowski, high detail, octane render, alexander mcqueen, james gurney, photo, 8 k, intricate"
    },
    {
        "display": "Astronaut chicken on alien planet",
        "full": "a chicken wearing a astronaut suit in a alien planet, profile picture, digital art, concept art, trending on DeviantArt, highly detailed, high quality, 4K, cartoon, high coherence, path traced, blue sky in the background, octane render, digital painting, no helmet, masterpiece"
    },
    {
        "display": "Hyperrealistic pinup portrait",
        "full": "hyperrealistic pinup | digital painting | trending on artstation | portrait | clean | illustration | dressed | unreal engine 5 | 8 k resolution | by hajime soryama"
    },
    {
        "display": "Marilyn Monroe Funko Pop",
        "full": "full body 3d render of Marilyn Monroe as a funko pop, she is wearing a sheer white night gown, she is laughing, studio lighting, white background, blender, trending on artstation, 8K, highly detailed"
    },
    {
        "display": "Futuristic eco city utopia",
        "full": "a futuristic eco city utopia, plants, buildings, vegetation, ambient, nature, landscape, nature, technology, trending on artstation, deviantart, octane render, unreal engine, high detail, high definiton, ultra realistic, hyper realistic, 4 k, hd"
    },
    {
        "display": "Cinematic sea monster concept",
        "full": "sea monster, concept art, cinematic"
    },
    {
        "display": "Cyborg fennec fox warrior",
        "full": "face portrait of a cyborg fennec fox animal warrior standing in the forest, in black carbon fiber pink and blue sci-fi armor, light coming off of the armor, tech wear, sci-fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, trending on art station, dynamic lighting, cinematic, ultra detailed, fantasy concept art"
    },
    {
        "display": "Women worshiping in forest",
        "full": "women woshiping god in dreamy forest, fantasy, 8 k resolution, hyper detailed, d & d, character design, digital painting, trending on artstation, sharp focus, illustration, art by artgerm, steve zheng, fuji choko, viktoria gavrilenko, hoang lap"
    },
    {
        "display": "Portrait of a space pilot.",
        "full": "portrait of a woman by greg rutkowski, sigourney weaver as an space cargo pilot, she is about 6 0 years old, wearing a cobalt blue utilitarian jumpsuit, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Chinese opera-inspired machine portrait",
        "full": "portrait of a machine from horizon zero dawn, machine face, upper half portrait, decorated with chinese opera motifs, asian, bian lian, traditional chinese art, intricate, elegant, highly detailed, symmetry, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Tarot illustration of Death",
        "full": "beautiful tarot illustration of death, in the style of james jean and victo ngai, mystical colors, trending on artstation"
    },
    {
        "display": "Gentle monstrous scarecrow illustration",
        "full": "an monstruous scarecrow, gentle, posing, cute pose, vaporwave, bedroom, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Girl with hair leaves.",
        "full": "girl with super long hair, hair becoming autumn red leaves, digital painting, artstation, concept art, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Angry dwarf wizard casting fireball.",
        "full": "Photorealistic cinematic close-up portrait of one angry dwarf wizard casting a fireball spell, by Larry Elmore and Steven Belledin .  Magical occult photorealism, UHD, amazing depth, glowing, golden ratio, 3D octane cycle unreal engine 5, volumetric lighting, cinematic lighting, cgstation artstation concept art"
    },
    {
        "display": "Surreal fluorescent landscape artwork.",
        "full": "Various Fluorescent lights over a surreal landscape, rendered by simon st\u00e5lenhag, rendered by Beeple, Makoto Shinkai, syd meade, environment concept, digital art, unreal engine, 3 point perspective, WLOP, trending on artstation, low level, 4K UHD image, octane render,"
    },
    {
        "display": "Muscular fantasy portrait.",
        "full": "portrait of romesh ranganathan, d & d, muscular! fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Broly as War God",
        "full": "a 3 / 4 view watercolor ink painting of broly as the god of war in the style of jean giraud in the style of moebius trending on artstation deviantart pinterest detailed realistic hd 8 k high resolution"
    },
    {
        "display": "Magical wolf sorcerer casting spell.",
        "full": "magic wolf sorcerer casting a powerful arcane magic spell, fire, ice, lightning, fantasy, occult, majestic pose, dramatic lighting, cinematic scene, frank frazetta, visually stunning, sharp focus, highly detailed, colorful, trending on artstation, cgsociety, artgerm"
    },
    {
        "display": "Cherry blossoms oceanfront fantasy",
        "full": "A field of cherry blossoms on an oceanfront, fine art, awesome fantasy book cover on Pinterest, award winning, fantasy forest landscape, fantasy magic, dark golden light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski, masterpiece, trending on artstation"
    },
    {
        "display": "Roman emperor in art nouveau.",
        "full": "roman emperor, wax art nouveau swirls, subsurface scattering, in the style of tomasz alen kopera and fenghua zhong and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane render, trending on artstation"
    },
    {
        "display": "Demon hero character design",
        "full": "human male demon, full body white purple, focus, closup, portrait, hero, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Cyberpunk teenager walking on water",
        "full": "photo of cyberpunk male teenager walking on water in the style of stefan kostic, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Forest path to cave",
        "full": "( ( path in the forest ) ), leading to a dark cave!!! entrance, exquisite masterpiece watercolor painting by jean - baptiste monge, trending on artstation"
    },
    {
        "display": "Pepper-themed humanoid character",
        "full": "pepper humanoid, symmetrical, highly detailed, digital art, sharp focus, trending on art station, anime art style"
    },
    {
        "display": "Lobster vs. Burrito Rap Battle",
        "full": "a new york city anthropomorphic lobster in a rap battle with a anthropomorphic burrito, animation pixar style, by pendleton ward, magali villeneuve, artgerm, rob rey and kentaro miura style, golden ratio, trending on art station"
    },
    {
        "display": "Ultradetailed concept art",
        "full": "a ultradetailed beautiful concept art of the old mind key, forge in the unconscious by spirit, concept art, high resolution concept art, 4 k"
    },
    {
        "display": "Dragon Ball character sprite",
        "full": "dragon ball character design, idle, colored, sprite sheet, tekken, pc game, sideview, art by moebius and greg rutkowski."
    },
    {
        "display": "Tiny cat on Labrador.",
        "full": "tiny cat riding a labrador, cat on top of dog, concept art, artstation, trending on Instagram, Twitter"
    },
    {
        "display": "Eerie beautiful woman illustration",
        "full": "eerie, corruption, beautiful, young woman, sad eyes, tears running down, crying, innocence, light, vaporwave aesthetic, synthwave, colorful, psychedelic, crown, long gown, flowers, bees, butterflies, ribbons, ornate, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Armed female pirate portrait.",
        "full": "fully clothed armed female pirate captain. sun, summer, blue eyes, beauty, wisdom, love, strength, knowledge, smart, portrait, symmetrical, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, strength, art by artgerm and alphonse mucha and louis theophile hingre. 8 k"
    },
    {
        "display": "Smart female pirate portrait",
        "full": "smart female pirate captain 2 6 years old, bob haircut, fully clothed, wise, beautiful, portrait by stanley artgerm, dramatic lighting, trending on artstation, flat colour, geometric curves, gradient filter, red and purple and yellow back light, art deco pattern, sharp focus"
    },
    {
        "display": "Proud Turkish dancer portrait",
        "full": "proud muscular female turkish dancer girl on haerm, portrait by Cedric Peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on ArtStation"
    },
    {
        "display": "MF Doom cooking pancakes, animated.",
        "full": "mf doom making pancakes, animation pixar style, pendleton ward, magali villeneuve, artgerm, rob rey and kentaro miura style, golden ratio, behance, trending on art station"
    },
    {
        "display": "Wealthy gamer in high-tech room",
        "full": "realistic rich man using laptop in gaming room, money on floor, artstation trends, sci fi concept art, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Elegant digital portrait.",
        "full": "rihanna, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Dynamic mycelium spacesuit scene",
        "full": "insanely detailed procedural render expressive dynamic fluid action scene of magnetic mycelium spacesuit protects the dancing coolest girl from doom as the planet they orbit sends spores surround her, photorealism, sharp focus, award winning, tristan eaton, victo ngai,, maxfield parrish, artgerm, ryden, intricate details, portrait, bokeh"
    },
    {
        "display": "Muscular Odin portrait, fantasy.",
        "full": "Portrait of Josh Brolin as Odin, D&D, muscular, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Black panther hunting anaconda.",
        "full": "animal concept of a black panther melanistic deep black leopard killing a snake, hunting an anaconda, on Singaporean forest tree, jungle, accurately portrayed, portrait art by alphonse mucha and greg rutkowski, highly detailed, digital painting, concept art, illustration, dim lighting with twilight rays of sunlight, trending on artstation, very detailed, smooth, sharp focus, octane render, close up"
    },
    {
        "display": "Cyberpunk portrait of Tetsuo",
        "full": "Portrait of Tetsuo from Akira, muscular, cables, techwear, intricate, cyberpunk, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Post-apocalyptic helmet design",
        "full": "mask helmet concept design mad max cars rocket league, art gta 5 cover, official fanart behance hd artstation by jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, ossdraws, that looks like it is from borderlands and by feng zhu and loish and laurie greasley, victo ngai, andreas rocha, john harris"
    },
    {
        "display": "Epic crimson knight portrait",
        "full": "Portrait of a Fantasy crimson knight, moonlit, HD, illustration, epic, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, monster hunter illustrations art book"
    },
    {
        "display": "Cute skeleton animal creatures.",
        "full": "cute skeleton animal creatures. hearthstone animal creatures, graveyard background,  bright art masterpiece artstation. 8k, sharp high quality artwork in style of Jose Daniel Cabrera Pena and Greg Rutkowski, violet theme, concept art by Tooth Wu, hearthstone card game artwork"
    },
    {
        "display": "Cinematic far west landscape",
        "full": "Classic far west scene by Goro Fujita and Simon Stalenhag , 8k, trending on artstation, hyper detailed, cinematic"
    },
    {
        "display": "Alice in Wonderland Cyberpunk Painting",
        "full": "a beautiful painting of alice in wonderland, shining its light across a tumultuous sea of crystal architectures by dorothea tanning and empyrean, trending on artstation, futurism, cyber punk, octane render, high detailed, human : - 2, blur : - 2, depth of field : - 2, w 1 9 2 0, h 1 0 8 0"
    },
    {
        "display": "Golden UFO over medieval town",
        "full": "a hyperrealist watercolor concept art of an elegant golden ufo in the sky above a small medieval town. one single dirty medieval peasant child is floating away up to the sky. very muted colors, by rebecca guay, michael kaluta, charles vess. high detail, hq, wide shot, 4 k"
    },
    {
        "display": "Female warrior in intricate armor.",
        "full": "female warrior, passion, bravery, intricate armour costumes, light and shadow effects, intricate, digital painting, art station, concept art, smooth, sharp focus, morandi color scheme, illustration, art by makoto shinkai and lois van baarle"
    },
    {
        "display": "Ultra-realistic He-Man illustration",
        "full": "ultra realistic illustration, he - man, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pharaoh with Shiba Inu",
        "full": "portrait painting of egyptian pharaoh with a shiba inu, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Yellow watercolor abstract art",
        "full": "yellow background watercolor trending on artstation"
    },
    {
        "display": "Symmetrical machine-infused face.",
        "full": "symmetry!! beauty, machine parts embedded into face, intricate, elegant, highly detailed, digital painting, surrealistic, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Pepe the Frog soldier",
        "full": "Pepe the Frog in military uniform, Schutztruppe, German East Afrika, WW1 Tooth Wu Artgerm Greg Rutkowski Alphonse Mucha Beeple artstation deviantart, 8k, fanart, extreme aesthetic"
    },
    {
        "display": "Male healer portrait, fantasy.",
        "full": "portrait of a male healer. game of thrones character design by laurie greasley and sherree valentine daines concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Kangaroo at Australian sunset",
        "full": "a kangaroo in a beach in australia, at sunset, hyperdetailed, in the style of artgerm, deviantart, figurative art, deviantart, ilya kuvshinov, lovecraftian"
    },
    {
        "display": "Skeleton lord drinking beer",
        "full": "scary skeleton lord drinks beer, beer, glass, beer mug in hand, intricate, triumphantly, foggy background, full body art, dark souls, drawing, concept art, artstation, digital painting"
    },
    {
        "display": "Collapsing castle tower illustration",
        "full": "castle tower collapsing. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "WALL-E and Ralph mashup.",
        "full": "Pixar's WALL-E and Disney's Wreck-It Ralph mixed into a single character , trending on artstation"
    },
    {
        "display": "Beautiful female warrior design",
        "full": "character design, void arcanist beautifull female warrior, fantasy style clothing, anime key visual, official media, illustrated by wlop, extremely detailed, 8 k, trending on artstation, cinematic lighting, beautiful, mist, photorealistic, octane render, unreal engine, hyper detailed, volumetric lighting"
    },
    {
        "display": "Attractive Scottish warrior portrait",
        "full": "portrait of attractive sam winchester as a rogue scotish warrior from the xii century, muscular body tattooed, d & d!, fantasy style, sharp focus!, ultra detailed, art by artgerm and peter andrew jones, wlop"
    },
    {
        "display": "Kitsune guarding shrine at night",
        "full": "kitsune protecting a japanse shrine at night with the moonlight, Trending on Artstation, halloween colors, concept art, 8k"
    },
    {
        "display": "High-tech internet temple portrait",
        "full": "extremely beautiful high detailed long portrait of a solid interior scene with a temple of internet. by daniel f. gerhartz and artgerm and greg rutkowski. intricate details, sci - fi details, hi - tech details, a lot of displays, digital glow, energetic mood, golden ratio, trending on artstation, volumetric lighting, 4 k, 8 k"
    },
    {
        "display": "Demonic cyberpunk duke portrait",
        "full": "portrait of a demonic cybernetic duke of hell, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzalez and seb mckinnon and beksinski, digital art, trending on artstation, deviantart, unreal engine, sharp focus, high detail, intricate, 4 k uhd image"
    },
    {
        "display": "Gorgeous Grim Reaper Girl",
        "full": "the grim reaper as a gorgeous young girl, dark fantasy, intricate, very huge elaborate scythe, very huge black angel wings, elegant, glowave blood moon, full moon, highly detailed, digital painting, artstation, concept art, wallpaper, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Divine creator portrait illustration.",
        "full": "portrait of the divine creator, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Misty anime bamboo landscape",
        "full": "misty japanese bamboo forest, lake, waterfall!!!!!, large mountain, rule of thirds, sunny, cartoony, stylized anime, sun rays, soft, by hayao miyazaki, ghibli studio, makoto shinkai, toei animation, studio trigger, trending on artstation, 4 k, hd"
    },
    {
        "display": "Blonde boy playing guitar.",
        "full": "blonde wild hair boy playing fender stratocaster, black eye - patch, close - up portrait, plain white tshirt, powerfull, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, steve mccurry"
    },
    {
        "display": "Sanji in dual outfits",
        "full": "sanji in a astronaut suit and sanji, black tuxedo, intricate, sanji, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, sanji, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Medieval knight in power armor",
        "full": "medieval knight power armour, space marine, concept art, medieval, sword, fantasy, detailed digital matte painting in the style of simon stalenhag and bev dolittle zdzislaw beksinski, greg hildebrandt artstation, psychedelic"
    },
    {
        "display": "Gollum Titan Portrait",
        "full": "gollum as a titan, attack on titan, cartoonish cute, pine trees, magical atmosphere, trending on artstation, 3 0 mm, by noah bradley trending on artstation, deviantart, high detail, stylized portrait"
    },
    {
        "display": "Dark witch portrait, cinematic moonlight",
        "full": "glowing silver and golden elements, portrait, A beautiful dark witch in front of the full big moon, book cover, green forest, red white black colors, establishing shot, extremly high detail, foto realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie, raphael lacoste, alex ross"
    },
    {
        "display": "Crossbreed parrot-rhino painting",
        "full": "painting of cross between parrot & rhino, intercrossed animal, by zdzislaw beksinski, by tiffany bozic, warm tone gradient background, concept art, beautiful composition, digital painting,"
    },
    {
        "display": "Elvish queen casting spells.",
        "full": "acotar style, wow! fanart, elvish queen with swords and fireballs, throne of glass fantasy portrait of a magician casting spells, d & d, high fantasy, detailed, digital art, artstation, smooth, sharp focus, art by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Realistic cat portrait",
        "full": "A space realistic cat with big and cute eyes, fine-face, realistic shaded perfect face, fine details. realistic shaded lighting poster by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash, Rob Rey and Kentar\u00ef\u00bf\u00bd Miura style, trending on art station"
    },
    {
        "display": "Grogu as Overwatch character",
        "full": "greg manchess portrait painting of grogu aka baby - yoda as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Guardian robot in barn.",
        "full": "Amazing photorealistic digital concept art of a guardian robot in a rural setting by a barn, by James Clyne and Joseph Cross. Cinematic. LED lighting. Wide angle. Clean lines. Balanced composition."
    },
    {
        "display": "Cyborgs in alien landscape.",
        "full": "cyborgs wander cybernetic architecture alien scifi landscape concept art by giorgio de chirico"
    },
    {
        "display": "Knight enjoying beer.",
        "full": "knight drinks beer, beer, glass, beer mug in hand, intricate, triumphantly, foggy background, full body art, dark souls, drawing, concept art, artstation, smooth, sharp focus, digital painting, cgsociety, art by artgerm and greg rutkowski"
    },
    {
        "display": "Cyberpunk soldier portrait",
        "full": "cyberpunk, armitage, closeup portrait of a crazy ex soldier with a battlescar, light blue eyes, brown buzzcut, cyborg, dramatic light, city background, sunset, dystopian setting, high contrast, sharp, neuromancer, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Red marble Jesus statue, bionic.",
        "full": "a statue jesus on cross made of red marble, perfect symmetrical body, full body shot, white biomechanical, wearing epic bionic cyborg implants, high detailed space station interior, masterpiece, intricate, biopunk, vogue, highly detailed, artstation, concept art, cyberpunk, octane render"
    },
    {
        "display": "Anime portrait, young woman.",
        "full": "anime portrait of a young woman with long white hair, braided, watery eyes, by artgerm, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, sakimichan, trending on artstation, hd, 4 k"
    },
    {
        "display": "Humanoid German Shepherds Hugging",
        "full": "two humanoid german shepherds beast - men, sitting on a couch and hugging together, artstation, concept art, smooth, sharp foccus ilustration, artstation"
    },
    {
        "display": "Anthro lion character design",
        "full": "Muscular fully clothed gay anthro lion furry Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art, 2021 award winning painting"
    },
    {
        "display": "Warrior with hammer, concept art.",
        "full": "a league of legends concept art of a warrior with a hammer like weapon, style by jordan grimmer and greg rutkowski, side view, ponytail, elegant, sharp focus, morandi color scheme, illustration, high quality, highly detailed, digital art, 4 k wallpaper, hyperrealism"
    },
    {
        "display": "Baroque anime character portrait.",
        "full": "baroque oil painting anime key visual character full body portrait concept art, young maid nazi ss secret police, flowing blonde hair blue eyes, nazi intimidating black ceremonial commander uniform, kuudere noble fascist dictator, grimdark fantasy, trending pixiv fanbox, rule of thirds golden ratio, by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Magical ethereal lolita portrait",
        "full": "portrait of magical lolita girl, dreamy and ethereal, expressive pose, big gold eyes, exciting expression, fantasy, intricate, elegant, many rainbow bubbles, rose tones, highly detailed, digital painting, artstation, concept art,cyberpunk wearing, smooth, sharp focus, illustration, art by artgerm and greg rutkowskiand alphonse mucha"
    },
    {
        "display": "Cyberpunk fox fursona",
        "full": "a fox fursona, trending on artstation, by kawacy, furry art, digital art, cyberpunk, high quality, backlighting"
    },
    {
        "display": "Slender half-elf character portrait.",
        "full": "character portrait of a slender half elf man with white hair, piercing blue eyes, and pale bluish skin, by greg rutkowski, mark brookes, jim burns, tom bagshaw, trending on artstation"
    },
    {
        "display": "Ancient satanic demon illustration.",
        "full": "ancient historically accurate depiction of the satanic demon by frank miller, illustration by Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau, Artgerm, 4k, digital art, surreal, space dandy style, highly detailed, godsend, artstation, digital painting, concept art, smooth, sharp focus, illustration by Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau"
    },
    {
        "display": "Japanese girl tattoo design",
        "full": "tattoo design, stencil, tattoo stencil, traditional, beautiful portrait of a Japanese girl sticking her tongue out with flowers in her hair, upper body, by artgerm, artgerm, artgerm, digital art, cat girl, anime eyes, anime, sexy-s 100"
    },
    {
        "display": "Steampunk girl in spaceship",
        "full": "digital art of a beautiful girl wearing a leather mini skirt in a steampunk spaceship, expressive oil painting, by wlop, by artgerm, by dan mumford, anime style, octane render, full body shot"
    },
    {
        "display": "Emma Watson as Pudge",
        "full": "Emma Watson as pudge from dota 2, sci-fi, amber eyes, face, long hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Viking girl, Afro hairstyle",
        "full": "a handsome afro hair viking girl african american, clash royal style characters, unreal engine 5, octane render, detailed, cinematografic, cinema 4 d, artstation trending"
    },
    {
        "display": "Blonde lady and Chinese man eating ice creams.",
        "full": "Portrait of a  blonde lady and a Chinese man eating ice creams in Porto,real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Celestial goddess on throne",
        "full": "celestial goddess, beautiful girl, galactic throne, in a galaxy, realistic, stephen bliss, unreal engine, by greg rutkowski, loish, rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, octane rendered, unreal engine, illustration, trending on artstation, masterpiece, 8 k"
    },
    {
        "display": "Exploding Earth concept art.",
        "full": "Concept art for the earth exploding. Trending on art station, dark colors."
    },
    {
        "display": "Zombie hip-hop fashion illustration",
        "full": "zombie sexy hiphop streetwear drip, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Colorful Paladin Portrait",
        "full": "side portrait, imogen poots, paladin, gloomhaven, colorful gaudy colors, luminescent, hard edges, bloom, organic painting, by greg manchess, huang guangjian, gil elvgren, greg rutkowski, jesper ejsing, magali villeneuve, artgerm, ilya kuvshinov"
    },
    {
        "display": "Colorful photorealistic forest",
        "full": "A beautiful and colourful forest by Greg Rutkowski, Sung Choi, Mitchell Mohrhauser, Maciej Kuciara, Johnson Ting, Maxim Verehin, Peter Konig, final fantasy , 8k photorealistic, cinematic lighting, HD, high details, atmospheric,"
    },
    {
        "display": "Hyper-detailed Crimson GT-R",
        "full": "Crimson GT-R R36 2035 165mm camera, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Bald Weird Al portrait",
        "full": "A photograph portrait of bald Weird Al Yankovic, Weird Al with no hair wearing a sweater, taken in the late 2010s, taken on a 2010s Camera, realistic, hyperrealistic, very realistic, highly detailed, very detailed, extremely detailed, detailed, digital art, trending on artstation, headshot and bodyshot, detailed face, very detailed face"
    },
    {
        "display": "Trendy anthropomorphic bird character",
        "full": "Trendy Fashionable Anthropomorphic bird, MOBA character concept art, trending on artstation, 8"
    },
    {
        "display": "Cyberpunk ape hacker",
        "full": "futuristic cyberpunk ape hacker with glasses at the computer. fantasy art by greg rutkowski, gustave courbet, rosa bonheur, edward hopper. faithfully depicted facial expression, perfect anatomy, sharp focus, global illumination, radiant light, detailed and intricate environment, trending on artstation"
    },
    {
        "display": "Detailed lumberjack portrait.",
        "full": "artwork by michael whelan and tomer hanuka, laurent durieux, close up portrait ofjoe rogan dressed as a lumberjack, in scene in twin peaks, full of details, by makoto shinkai and thomas kinkade, matte painting, trending on artstation and unreal engine"
    },
    {
        "display": "Highly detailed Westeros town.",
        "full": "old town, capital of westeros, game of thrones, super highly detailed, professional digital painting, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Roguish man in misty cloak.",
        "full": "Thin blond roguish man wearing a black cloak made of very thousands of thin strips of cloth that decay into mist. fantasy, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Psychedelic interstellar fractal portal.",
        "full": "a psychedelic fractal portal into an interstellar event horizon spanning galaxies, art by Julius Horsthuis, trending on artstation, beeple, two-point perspective, sacred mirrors, digital art, black hole time portal, deep space, third eye, multiverse, 8K"
    },
    {
        "display": "Cybernetic villainess portrait",
        "full": "a symmetrical portrait of a beautiful cybernetic villainess, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzalez and syd mead, digital art, unreal engine 5, trending on artstation, deviantart, pinterest, rule of thirds, 4 k uhd image"
    },
    {
        "display": "Flying cow over solarpunk village",
        "full": "giant cow flying over smart village, psychedelic solarpunk architecture, big bee, flying cow, surrounded by lush nature, ray - tracing, cinematic lighting, 8 k smooth, vibrant colors, ambient occlusion, hdr, complex, highly atmospheric lighting, highly detailed, alex grey, greg rutkowski, focus, cinematic, concept art, artstation, trending"
    },
    {
        "display": "Futuristic spiked car concept.",
        "full": "concept art for a car huge sharp spikes, painted by syd mead, high quality"
    },
    {
        "display": "Futuristic Princess Rosalina Art",
        "full": "full body Princess Rosalina with a scifi futuristic space suit by Ilya Kuvshinov concept art, Mobile Suit Gundam Mech suit, symmetrical face Glycerine skin hyper realistic neon Bioluminescent Cleopatra, trending on artstation"
    },
    {
        "display": "Vegeta in Art Nouveau style.",
        "full": "vegeta, by greg rutkowski and alphonse mucha, sharp focus, matte"
    },
    {
        "display": "Lush jungle scenery.",
        "full": "beautiful jungle, by greg rutkowski makoto shinkai takashi takeuchi"
    },
    {
        "display": "Feline humanoid warrior portrait",
        "full": "Portrait of a humanoid feline man by Greg Rutkowski, hard predatory look, visible fangs, wearing a futuristic space tactical gear that looks like a mix between the samurai, viking and templar aesthetics, mix between tribal and hi-tech, highly detailed portrait, scifi, space opera, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ"
    },
    {
        "display": "Mary Poppins casting spell",
        "full": "Mary poppins , casting epic spell, magic the gathering artwork, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, heroic pose, oil painting, cgsociety, magic lab background"
    },
    {
        "display": "Fantasy desert kingdom streets.",
        "full": "streets of a fantasy desert kingdom, 8 k concept art highly detailed illustration"
    },
    {
        "display": "Blonde MMA fighter, shadowy figure.",
        "full": "a blonde woman mma fighter stands in fighting pose, a shadowy man towers behind her, purple and blue palette, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Androgynous druid spirit portrait.",
        "full": "a portrait of a gal gadot as an androgynous druid spirit wearing five necklaces of moons and stars, draped in transparent cloth, flat colors, occult, minimal, swirly, bust by alphonse mucha, decorative art nouveau border, astrophotography, vast cosmos, detailed book illustration, trending on artstation"
    },
    {
        "display": "Bucky Barnes with floral face paint.",
        "full": "sebastian stan as bucky barnes with his face painted. face painting of flowers. beautiful highly detailed face. painting by artgerm and greg rutkowski and alphonse mucha."
    },
    {
        "display": "Holy mechanical warrior portrait",
        "full": "full body, dynamic attack position abstract portrait of a intricate glorious holy mechanical warforged character in yellow armor holding a paladin engraved great longsword drawn and carrying a big paladin shield, glowing circular red eye on the face, face in focus, pit droid, epic , trending on ArtStation, masterpiece,  cinematic lighting, by Ross Tran and by Greg Rutkowski"
    },
    {
        "display": "Surreal unified theory construction.",
        "full": "a hyper - detailed 3 d render like a oil painting of the construction of a unified theory, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation hd, by greg rutkowski, bruce pennington, valentina remenar and asher duran,"
    },
    {
        "display": "Girl with cat ears in lab",
        "full": "girl with cat ears in a biology lab, cat ears, by WLOP, by Artgerm, by Michael Garmash, by Ilya Kuvshinov, digital art, trending on artstation, beautiful lightning, atmospheric, fantasy"
    },
    {
        "display": "Detailed grunge student portrait.",
        "full": "highly detailed portrait of a sewer style seattle student, tartan hoody, by atey ghailan, by greg rutkowski, by greg tocchini, by james gilleard, by joe fenton, by kaethe butcher, gradient green, brown, blonde crea, orange, brown and white color scheme, grunge aesthetic!!! ( ( graffiti tag wall background ) )"
    },
    {
        "display": "Audrey Hepburn as Wonder Woman",
        "full": "Audrey Hepburn as wonder woman, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Female Viking Portrait",
        "full": "portrait of a viking, female,, epic fantasy, detailed, intricate, digital painting, concept art, realistic, smooth, focus, rim light"
    },
    {
        "display": "Nature invaded by mushroom machines",
        "full": "nature being invaded by huge smoking mushroom war machines which emit a blue light, animals running away, made by stanley artgerm lau, wlop, rossdraws, artstation, cgsociety, concept art, cgsociety, octane render, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k,"
    },
    {
        "display": "Elegant angel illustration",
        "full": "denise richards as an elegant angel with wings, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, by artgerm and donato giancola and josepth christian leyendecker, ross tran, wlop"
    },
    {
        "display": "Elegant Hatsune Miku figurine",
        "full": "still figurine of hatsune miku wearing an elegant summer blouse, statue, personification, dynamic pose, amazing details, detailed product photo, official art, featured on amiami, featured on pixiv, 8 5 mm, f. 1 4, beautiful composition"
    },
    {
        "display": "Elegant anime Zatanna portrait",
        "full": "beautiful Anna Kendrick Zatanna DC Comics floating, on stage, symmetrical face symmetrical eyes, smiling, modern anime, fantasy, eerie, intricate details, atmospheric, elegant, super highly detailed, professional digital painting, concept art, art by artgerm and eiichiro oda and koyoharu gotouge"
    },
    {
        "display": "Moon priest portrait, elegant dress.",
        "full": "portrait of the moon priest, intricate, elegant dress, digital painting, artstation, concept art by artgerm and wlop"
    },
    {
        "display": "Medieval landscape painting.",
        "full": "ismail inceoglu epic oil on canvas painting of a middle ages landscape, line art!!, ink, art concept for a book cover!!, harmonic colours, thik brushstrokes, medieval movie still, game of thrones card game, highly detailed, centered, hyperrealism, romantic, concept art, smooth, sharp focus trending on artstation, by greg manchess and by craig mullins and by kilian eng and by jake parker"
    },
    {
        "display": "Eerie photorealistic Belle.",
        "full": "belle beauty and the beast ; ultra realistic, concept art, intricate details, eerie, haunting, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Goddess of pink petals.",
        "full": "a wonderful goddess made of pink petals as texture of the skin, intricate, elegant, highly detailed, wonderful eyes, sweet, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Dark magician character design",
        "full": "beautiful and cool female dark magician, wide angle view, flowers, skulls, black, white, pink, gold colors, shiny, highly detailed, artgerm, cushart krenz, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Rastafarian sheep mural",
        "full": "a medieval wall painting of a Rastafarian sheep with dreadlocks, trending on artstation"
    },
    {
        "display": "Portrait of a handsome male.",
        "full": "portrait blahsen, male!! digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Cosmic Buddha Ascension",
        "full": "the second coming of the buddah, by dan mumford and ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Charming chibi pug soldier.",
        "full": "a realistic and atmospheric watercolour fantasy character concept art portrait of a fat adorable chibi pug soldier in the jungle, by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Giant crack with hand holding teddy bear",
        "full": "giant crack hole on the brick concrete wall, hand coming from inside the crack reaching towards camera haand is holding a teddybear. gloomy, intricate, elegant, highly detailed, digital painting, artstation, concept art, addiction, chains, smooth, sharp focus, illustration, art by ilja repin"
    },
    {
        "display": "Elegant spider guitarist.",
        "full": "anthropomorphic art of an elegant spider playing the electric guitar, by artgerm, victo ngai, ryohei hase, artstation, highly detailed digital painting, smooth, global illumination, fantasy art by greg rutkowsky, karl spitzweg, leyendecker"
    },
    {
        "display": "Demonic shrine, cosmic horror.",
        "full": "a lovecraftian painting of a demonic shrine, occult, unholy ritual, cosmic horror elements, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Swordfight with muscular woman",
        "full": "an action photo of a black haired woman in a black leather jacket in a swordfight, muscular upper body, abs, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Handsome cowboy by campfire",
        "full": "a dramatic, epic, ethereal painting of a !!!!!!handsome!!!!!! thicc chunky beefy mischievous shirtless with a big beer belly wearing a large belt and bandana offering a whiskey bottle | he is a cowboy relaxing by a campfire | background is a late night with food and jugs of whisky | homoerotic | stars, tarot card, art deco, art nouveau, intricate | by Mark Maggiori (((and Alphonse Mucha))) | trending on artstation"
    },
    {
        "display": "Hyperrealistic Hank Hill Art",
        "full": "hank hill from king of the hill, hyperrealistic, concept art, octane render, unreal engine 5, trending on Artstation, high quality, 8K, dramatic lighting, cinematic, high coherence, highly detailed, Midjourney style, epic scene, path traced, low contrast, complementary colors"
    },
    {
        "display": "Skinless vampire portrait",
        "full": "portrait painting of a skinless vampire smiling and wearing battered vietnam fatigues, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Elegant jazz pianist portrait",
        "full": "a beautiful portait of a piano player with black and red hair singing in a jazz club, female, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ominous jester portrait.",
        "full": "impressive ominous cinematic fine portrait photo of a court jester rigid shattered crystal volumetric dynamic fluid simulation lighting impressive masterpiece hyper ultra detailed intricate sharp focus 8 k realistic illustration canon eos r 3 fujifilm x - t 3 0 sony alpha, by james gurney tooth wu artgerm colorful!!!, trending on artstation behance cgsociety, octane render nvidia raytracing demo"
    },
    {
        "display": "Samurai angel with flaming sword.",
        "full": "a samurai mask worn by an angel with very big wings and a flaming sword standing in the rain, digital art, concept art, deviantart. highly detailed"
    },
    {
        "display": "Ultra-realistic Cara Delevingne praying",
        "full": "ultra realistic illustration, a stunningly beautiful cara delevigne praying, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Handsome goblin in tuxedo.",
        "full": "portrait of a green skin handsome goblin in a tuxedo. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose, vibrant colours."
    },
    {
        "display": "Woman reaching for stars",
        "full": "A beautiful woman grasping at the stars, by Lois van Baarle, by Greg Rutkowski, by artgerm, by beeple, volumetric lighting, 4k resolution, octane render, trending on artstation"
    },
    {
        "display": "Cinematic grizzled Bogart character",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork costume design: beareded Humphrey Bogart, old scars, long hair, grizzled, tired, in an old 1950s leather jacket, with a bottle of whisky in hand. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Psychedelic girl in chaos",
        "full": "fluid polygonal masses of psychedelic oil paints building chaotic structure of hyper beautiful girl in other dimension, wayne barlow, bao pham, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hdr, 4 k, 8 k"
    },
    {
        "display": "Couple dancing in street.",
        "full": "beautiful intricate detailed painting of an older couple dancing in the street. vibrant, high quality, very funny, beautiful, hq. hd. 4 k. award winning. trending on artstation"
    },
    {
        "display": "Cinematic military concept art",
        "full": "arma 4, by cedric peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on artstation"
    },
    {
        "display": "Cozy anime schoolgirls portrait.",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai, cute beauty cozy portrait anime schoolgirls under dark pink and blue tones, mirror room. light rays. water bellow. smooth cute pretty realistic anime face. big and sad eyes.  dramatic light, trending on artstation, oil painting brush"
    },
    {
        "display": "Anime maid battlefield painting",
        "full": "classical oil painting of anime key visual environment concept art of the great anime maid war in a battlefield, anime maid soldiers, magical girls, trending on artstation, brush strokes, oil, canvas, style of kawacy makoto shinkai jamie wyeth james gilleard edward hopper greg rutkowski, preserved historical"
    },
    {
        "display": "Anime girl made of pink petals",
        "full": "pink petals with a shape of an anime girl, krenz, ghibli, anime style, intricate, elegant, highly detailed, wonderful eyes, sweet, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Intricate sky whales portrait",
        "full": "sky whales, oil on canvas, intricate, portrait, 8k highly professionally detailed, HDR, CGsociety, illustration painting by Mandy Jurgens and Ma\u0142gorzata Kmiec and Dang My Linh and Lulu Chen and Alexis Franklin and Filip Hodas and Pascal Blanch\u00e9 and Bastien Lecouffe Deharme, detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, Cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8K, style by Wes Anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon st\u00e5lenhag, rendered by Beeple, by Makoto Shinkai, syd meade, 8k ultra hd, artstationHD, 3d render, hyper detailed, elegant, by craig mullins and marc simonetti, Ross Tran and WLOP, by Andrew Wyeth and Gerald Brom, John singer Sargent and James gurney"
    },
    {
        "display": "Anime girl on water.",
        "full": "anime girl walking on water, ripples, backdrop of dawn, saturn in the background, illustration, concept art, anime, key visual, trending pixiv fanbox by wlop and greg rutkowski and makoto shinkai and studio ghibli"
    },
    {
        "display": "Elegant anime character in lab coat",
        "full": "kurisu makise steins gate anime atmospheric, standing, lab coat, elegant, super highly detailed, professional digital painting, artstation, concept art, 8k, art by wlop, artgerm and alphonse mucha and eiichiro oda"
    },
    {
        "display": "Armored Nazgul Warrior",
        "full": "an armored nazgul warrior, by Bekinski, 4k, trending on Artstation"
    },
    {
        "display": "Tattooed woman in ornate helmet",
        "full": "an attractive young tattooed north female with piercings wearing an raven ornate metallic helmet, rachel weisz, olive skin, long dark hair, beautiful bone structure, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Game of Thrones Samovar",
        "full": "a very detailed concept art of game of thrones samovar, trending on artstation, digital art, 4 k, hyper realistic, octane render, sharp focus"
    },
    {
        "display": "Dystopian cityscape at night",
        "full": "a person standing on a grey skyscraper looking over a dystopian city, fog, moody, realistic night lights, rain and haze, concept art, intricate, in the style of katsuhiro otomo, akira, unreal engine"
    },
    {
        "display": "Beautiful girl in Minecraft.",
        "full": "portrait photograph of an extremely beautiful!!!! young female, Moody look on her face, natural light, wearing a crop top and jeans!! Pink hair. Green eyes. in a minecraft world looking at the camera!!. super resolution. Extremely detailed. Graflex camera!, bokeh!!!!! trending on artstation."
    },
    {
        "display": "Elegant Lady Justice Illustration",
        "full": "lady justice holding two balance scales. blindfolded intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Cyberpunk character concept art",
        "full": "concept art character, very high angle view, book cover, walking in cyberpunk valley, highly detailed full body, smooth, sharp focus, organic, appealing, book cover, deep shadows, by Dave McKean sketch lineart for character design"
    },
    {
        "display": "Steampunk woman in spaceship.",
        "full": "a woman in a black dress and thigh highs in a steampunk spaceship, digital art, beautiful lighting, by greg rutkowski, by dan mumford, octane render, composition, beautiful face, expressive oil painting, steampunk, 4 k"
    },
    {
        "display": "Anime-style Hatsune Miku",
        "full": "Anime art beautiful Hatsune miku by artgerm, magali villeneuve, Gil Elvgren, Earl Moran, Enoch Bolles, symmetrical shoulders, black bra"
    },
    {
        "display": "Gothic vampire queen hybrid.",
        "full": "ultra realistic illustration, zoe kravitz, emma stone hybrid as the gothic vampire queen, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "\"Violent destiny of despair\"",
        "full": "robbed of a mother's love at birth left to drown in blood he was to die beneath her corpse meant to rot in a cesspit of despair born to die as a waste of air raised as a child one shorn of fate in a fit of rage he knows a violent destiny will await only life by sword shall reign and he never to death be slain robbed of a mother's love at birth left to drown in blood he was to die beneath her corpse meant to rot in a cesspit of despair born to die as a waste of air raised as a child one shorn of fate in a fit of rage he knows a violent destiny will await only life by sword shall reign and he never to death be slain, 4 k, 8 k, trending on artstation, artstationhd, artstationhq"
    },
    {
        "display": "Mischievous woman portrait.",
        "full": "a mischievous woman with twigs and dirt in her brown hair, dirt - stained skin, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic night scene.",
        "full": "by alexander trufanov by artgerm by simon stalenhag tsoi from back pacing lowering head dressed in short leather bomber jacket to empty narrow alley with street lamps in park with pines to the horizon,, with hands in pockets, snowfall at night, mullet long haircut, black hairs, cinematic, dramatic, detailed, realistic, movie shot, low lighting"
    },
    {
        "display": "Cybernetic blue soldier illustration",
        "full": "cell shaded full body image of a cybernetic blue bald soldier with glowing blue eyes as Borderlands 3 concept art, llustration, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00edn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus,alien,Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Elegant girl in leather jacket",
        "full": "girl in very short white! top and very short gray! leather jacket, open belly, long dark curly hair, glasses up lifted forehead, high waist sweatpants, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Goddess portrait merging cathedral.",
        "full": "portrait of a beautiful young goddess'face merging with a cathedral building, unusual beauty, etheric, outworldly colours, emotionally evoking symbolic metaphors, head in focus, fantasy, ornamental, intricate, elegant, highly detailed painting style photo, artstation, concept art, painterly, golden ratio, sharp focus, illustration, art by john william godward and zdzis\u0142aw beksinski and oleg shuplilak,"
    },
    {
        "display": "Goddess of Chaos Illustration",
        "full": "perfect mix of megan fox and kim kardashian and gal godot as the greek goddess of chaos sitting on her throne!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by ( ( ( artgerm ) ) ) and greg rutkowski! and ( ( alphonse mucha ) ), heavily influenced by frank frazetta and boris vallejo, sword and sorcery"
    },
    {
        "display": "Magenta-haired woman with crystals.",
        "full": "a highly detailed digital art of A beautiful woman,  with medium length magenta hair covering an eye, and a tall tree, and large obsidian crystals, cinematic lighting, dramatic atmosphere, by Dustin Nguyen, Akihiko Yoshida, Greg Tocchini, Greg Rutkowski, Cliff Chiang, 4k resolution, trending on artstation"
    },
    {
        "display": "Beautiful Big Sur house illustration",
        "full": "beautiful house in big sur, details, sharp focus, illustration, by jordan grimmer and greg rutkowski, trending artstation, pixiv, digital art"
    },
    {
        "display": "Dark violet magical orb.",
        "full": "arcane style spinning ball made of dark violet smoke, magic smoke, magic experiment, bright art masterpiece artstation. 8k, sharp high quality artwork in style of Jose Daniel Cabrera Pena and Greg Rutkowski, golden theme, concept art by Tooth Wu, blizzard warcraft artwork, hearthstone card game artwork"
    },
    {
        "display": "Lofi BioPunk Smunchlax portrait",
        "full": "lofi BioPunk Pokemon Smunchlax  portrait Pixar style by Tristan Eaton_Stanley Artgerm and Tom Bagshaw,"
    },
    {
        "display": "Sleeping female knight in forest.",
        "full": "wide angle, knight, sleeping on rock, white grey blue color palette, eyes closed, forest, female, d & d, fantasy, intricate, elegant, highly detailed, long silver hair, digital painting, artstation, octane render, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm, alphonse mucha johannes voss"
    },
    {
        "display": "Elegant zombie Snow White",
        "full": "snow white zombie, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Joel from The Last of Us",
        "full": "Joel from the last of us, unreal engine 6, 4k, octane render, concept art, trending on artstation"
    },
    {
        "display": "Nordic girl portrait, dark fantasy",
        "full": "Portrait of nordic girl, face, dark fantasy, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, XF IQ4, 150MP, 50mm, f/1.4, ISO 200, 1/160s, natural light, Adobe Photoshop, Adobe Lightroom, DxO Photolab, polarizing filter, Sense of Depth, AI enhanced, HDR, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Underwater Roman favela scene",
        "full": "roman classical favela, underwater environment, scenery, professional, award - winning, trending on artstation, hyper detailed, realistic, beautiful, emotional, shiny, golden, picture"
    },
    {
        "display": "Angelic winged girls illustration.",
        "full": "angelic girls with wings, masterpiece, pinup, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K"
    },
    {
        "display": "Jewelry-wearing marten character.",
        "full": "marten animal wearing jewlery with cute hairstyle, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "Photorealistic Zelda fantasy render.",
        "full": "a photorealistic dramatic fantasy render of zelda!! by wlop, artgerm, greg rutkowski, alphonse mucha, beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, artstation, concept design art, octane render, 8 k"
    },
    {
        "display": "Colorful BMW i8 Fantasy Art",
        "full": "bmw i 8 colorful, fantasy, intricate, highly detailed, digital painting, hq, trending on artstation, illustration, style of stanley artgerm and greg rutkowski and dan mumford"
    },
    {
        "display": "Fantasy faerie character design",
        "full": "wonderdream faeries lady feather wing digital art painting fantasy bloom vibrant, art gta 5 cover, official fanart behance hd artstation by jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, ossdraws, that looks like it is from borderlands and by feng zhu and loish and laurie greasley, victo ngai, andreas rocha, john harris illustration character design concept colorful joy atmospheric lighting butterfly"
    },
    {
        "display": "Skeleton astronaut in glowing jungle",
        "full": "a skeleton astronaut sitting on rock, surrounded by bio - luminescent, glowing peaceful serene sentient solarpunk, jungle. in the style of katamari damacy, scattered glowing pink fireflies, soft vaporwave liminal aesthetic. 3 d blender by tomer hanuka, greg rutkowski, beeple, sharp focus, digital painting, concept art"
    },
    {
        "display": "Steampunk gauss rifle concept",
        "full": "steampunk gauss rifle concept art on a plain white background, concept art, photorealistic"
    },
    {
        "display": "Cinematic dark academia library",
        "full": "medium shot, cinematic interior photo of library reading hall in dark academia aesthetic, Hogwarts library, wooden desks with books, candles, flowers and green vines, plant magic vibes, enhancements, soft lighting, by John Ward, by Arthur Walker, by Vermeer, by Monet, by Kinkade, oil on canvas, Royal Academy, masterpiece, trending on artstation, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper-detailed, HD"
    },
    {
        "display": "Elegant Wolverine Portrait",
        "full": "a portrait of wolverine ( logan ), fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Retrofuturistic Japanese temple portrait",
        "full": "retrofuturistic portrait of a japanese temple, close up, wlop, dan mumford, artgerm, liam brazier, peter mohrbacher, 8 k, raw, featured in artstation, octane render, cinematic, elegant, intricate, 8 k"
    },
    {
        "display": "Nordic woman in forest",
        "full": "photo of a gorgeous nordic female in a dark forest in, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, luis royo, greg kutkowski"
    },
    {
        "display": "Intricate Love Rock Art",
        "full": "love, rock n roll, illustrated by jesper ejsing, intricate, ultra detailed, trending on artstation, 4 k, 8 k"
    },
    {
        "display": "Cute retro dog illustration.",
        "full": "a cute retro dog. alcohol inks on parchment, muted colors, detailed, textured parchment background, 1 9 5 0 s concept art"
    },
    {
        "display": "Dystopian barmaid portrait",
        "full": "character portrait of young woman as a beautiful barmaid, pixie cut with shaved side hair, gloomy dark speakeasy, dystopian 1920s soviet mood, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, vibrant deep colors, \ud83c\udf78, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, Art Nouveau, masterpiece"
    },
    {
        "display": "Puppy-themed empress portrait",
        "full": "a beautiful empress portrait, with a brilliant, impossible striking big Puppy headpiece, clothes made of puppies, everything puppies, symmetrical, dramatic studio lighting, rococo, baroque, asian, hyperrealism, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, 8k, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Cute anime girl portrait",
        "full": "nekopara cute fantastically detailed eyes modern anime style art vibrant detailed cat ears girl nekomimi dress portrait  shinkai makoto Studio ghibli Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Psychedelic portrait of Yungblud.",
        "full": "! dream an extremely psychedelic portrait of yungblud, surreal, lsd, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration,"
    },
    {
        "display": "Pastel paisley textile design",
        "full": "beautiful digital matte pastel paisley pattern is an ornamental textile design using the boteh greg rutkowski artstation"
    },
    {
        "display": "Mysterious chaotic photography",
        "full": "mysterious chaos by geert goiris, award - winning photography, concept art"
    },
    {
        "display": "Sci-fi fighter pilot portrait",
        "full": "A full portrait of a scifi heavy deep space fighter pilot, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Victorian Rei Ayanami Art",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: sophisticated posh British aristocratic victorian Rei Ayanami played by Alicia Vikander. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Albino mountain lion portrait.",
        "full": "Character portrait of a albino furry anthro albino mountain lion with an expressive face wearing a yellow button-down shirt and olive green slacks in the desert wilderness, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Armored angel princess portrait.",
        "full": "A masterpiece ultrarealistic ultradetailed portrait of a Incredibly beautiful angel armored princess knight with  Iron mask. baroque renaissance  girl  in the forest. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, Yoshitaka Amano. background by James Jean and Gustav Klimt, light by Julie Bell, 4k, porcelain skin. BY ZDIZISLAW BEKSINSKI Cinematic concept art Greg Rutkowski."
    },
    {
        "display": "Surreal cyclops portrait",
        "full": "portrait of jewel orbfolk cyclops \ud83d\udcd0 in the style of Rob Lefield and Dan Mumford , trending on artstation, digital art,surrealism ,macro,blueprint ,vaporwave ,"
    },
    {
        "display": "Realistic Nami portrait",
        "full": "nami from one piece, beautiful figure, perfect face orange hair, sharp focus, highly detailed, realistic shaded, realistic collaborative painting by cushart krenz, makoto shinkai, artgerm ilya kuvshinov rossdraws"
    },
    {
        "display": "Symmetrical sci-fi mecha ghost.",
        "full": "ghost, mecha, symmetrical. sci - fi, tech wear, glowing lights, intricate, elegant, highly detailed, digital painting, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Modern Samsung store interior",
        "full": "( small samsung store. white walls. timber floor. display tables with phones and tablets. low ceilings with downlights and spots. curved white furniture with large digital screens. a few plants in the background award winning ) concept art by pete mohrbacher and wlop and artgerm and josan gonzalez, digital art, unreal engine 5, trending on artstation"
    },
    {
        "display": "Hyper-realistic serene garden",
        "full": "serene garden with pool and a house in the background, hyper realistic, ambient lighting, concept art, intricate, hyper detailed, smooth, dynamic volumetric lighting, octane, raytrace, cinematic, high quality, high resolution, 4 k, cgsociety, rutkowski, gurney"
    },
    {
        "display": "Ethereal cowboy with plant",
        "full": "a dramatic ethereal epic painting of a handsome black cowboy offering a small plant | he is shirtless and wearing a hat and boots | background is a forest on fire | tarot card, art deco, art nouveau, homoerotic | by Mark Maggiori and Alphonse Mucha | trending on artstation"
    },
    {
        "display": "\"Colorful solar eclipse landscape\"",
        "full": "solar eclipse above people in grassland and forest, bright, colorful clouds, matte painting, trending on artstation, simon stalenhag"
    },
    {
        "display": "Elegant portrait of Pushkin",
        "full": "portrait of alexandr pushkin perfection, symmetrical! intricate, elegant, highly detailed, vision of holy perfection, sleeping in a theatre!! smile, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical ACOTAR fanart.",
        "full": "symmetry!! intense fanart of gemma as acotar protagonist, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Thunderstorm in teapot.",
        "full": "thunderstorm in a glass teapot. octane render, trending on artstation, very coherent symmetrical artwork. cinematic, hyper realism, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Muscular god in regal robes",
        "full": "a tall muscular man with chiseled features, black hair, and tanned skin. wears royal robes of red and gold and his expression is a witty smirk. he is lightsong the bold, the god of bravery. portrait painting by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dreadlocked dog portrait",
        "full": "portrait of dog with dreadlocks wearing gold chain focus cute eye enchanted official fanart behance hd artstation by Jesper Ejsing, by RHADS, Makoto Shinkai and Lois van baarle, ilya kuvshinov, rossdraws portrait, highly detailed, digital painting, concept art, sharp focus, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha radiant light, peter mohrbacher, ferdinand knab, artgerm, portrait  happy  colors, bright eyes, clear eyes, warm smile"
    },
    {
        "display": "Xanathar Pok\u00e9mon-style creature",
        "full": "a xanathar dnd creature in the style of pokemon, adorable and whimsical, fantasy, elegant, digital painting, artstation, unreal engine, octane render, concept art, matte, sharp focus, vibrant colors, high contrast, illustration,"
    },
    {
        "display": "Nerdy boy programming gadgets.",
        "full": "a nerdy boy is programming at a computer in a room full of gadgets, by makoto shinkai and ghibli studio, dramatic lighting, highly detailed, incredible quality, trending on artstation"
    },
    {
        "display": "Druid in alien studio",
        "full": "portrait of a druid playing an electronic!!-musical-instruments, intricate, stunning, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, michael whelan, inside an a space station recording studio filled with strange surrealist detailed alien electronic and acoustic musical-instruments!! with blinking LEDs and oscilloscopes on screens in detailed alien interiors, backlit fog, designed by Larry Elmore Kerlaft and Pixar, photorealistic, 3d render, award winning render, unreal engine, octane render, studio lighting, 8k, hd"
    },
    {
        "display": "Taylor Swift as Cheerleader",
        "full": "twin peaks movie poster art of taylor swift as the local cheerleader, nostalgic, domestic, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, joseph christian leyendecker, wlop by katherine lam"
    },
    {
        "display": "Tattooed bearded man portrait.",
        "full": "portrait of a young bald bearded and tattoed man wearing glasses and a backwards hat, drinking a glass of whiskey, digital painting, highly detailed, hd, 4k, trending on artstation"
    },
    {
        "display": "Male navy captain portrait",
        "full": "portrait sabouts, male, navy captain hat, short red beard commander, male!! digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Scarlet Witch Magic Portrait",
        "full": "elizabeth olsen artstation scarlet witch, floating in the air as she emanates magic from her palms, full - body portrait, 3 5 mm!!!!! photography, disdain facial expression, messy!!!!! hair, trending on artstation, photorealistic!!!!!, 4 k, 8 k"
    },
    {
        "display": "Elegant Strahd Van Zarovich.",
        "full": "Strahd Van Zarovich, D&D, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration"
    },
    {
        "display": "Trump as medieval jester.",
        "full": "oil portrait of president donald trump as a historic dwarf jester fool. smiling widely, gesturing with hands. medieval dirty and grimy noble. historic royal portrait from the renaissance era. oil painting, highly detailed, centered, artstation, concept art, smooth, sharp focus, illustration, artgerm, vermeer, hans holbein, titian, salvador dali, hieronymus bosch, frans hals, rembrandt"
    },
    {
        "display": "Symmetrical machine-embedded face.",
        "full": "symmetry!! sung hi lee, machine parts embedded into face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Anime-style kiss with realism",
        "full": "two girls kissing | very very anime!!!, fine - face, audrey plaza, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Troll face portrait artwork",
        "full": "troll face, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Pirate-themed escape room.",
        "full": "interier view of award - winning pirate themed escape room set on the top deck on pirate ship from 1 7 2 0. steampunk and magical colors. trending on artstation, realistic."
    },
    {
        "display": "Fantasy concept art collage",
        "full": "android jones alex grey android jones alex grey mark cooper, artgerm artstation award winning photorealistic detailed hypervivid intense fantasy concept art, hypervivid intense mcbess, the sea by dan mumford"
    },
    {
        "display": "Lolitafashion witch girl portrait",
        "full": "beautiful witch girl in lolitafashion dress, mystical, character, closeup headshot, in the style of artgerm, artstation, wlop, alexis franklin, cgsociety, 8 k resolution, detailed"
    },
    {
        "display": "Solarpunk Cinematic Concept Art",
        "full": "concept art by jama jurabaev, solarpunk, cinematic shot, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Uncle Sam vs. Steven Seagal",
        "full": "painting of uncle sam punching steven seagal, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Yvonne Strahovski in sexy outfit",
        "full": "yvonne strahovski in a sexy outfit, full shot, atmospheric lighting, visible face, perfectly shaded body, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Iridescent gummy bear illustration",
        "full": "iridescent gummy bear, in the style of dustin nguyen, digital illustration, intricate, trending on artstation"
    },
    {
        "display": "Lindsey Pelas as President",
        "full": "lindsey pelas as emma watson the president of united states, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mongolian princess portrait",
        "full": "beautiful cinematic portrait of a sexy mongolian princess in a sensual pose covered with golden ornate armor, sexy face with full makeup!, atmospheric lighting, intricate, volumetric lighting, beautiful, sharp focus, ultra detailed, concept art by bowater charlie, brom gerald, with lake baikal in the background, astrophotography"
    },
    {
        "display": "Girl holding castle, whimsical.",
        "full": "a painting of a girl with a castle in her hands, poster art by Hikari Shimoda, featured on pixiv, pop surrealism, whimsical, rococo, maximalist"
    },
    {
        "display": "John Prescott as Hulk",
        "full": "john prescott as the incredible hulk, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha, simon stalenhag, hyperreal"
    },
    {
        "display": "1990s arcade machine render",
        "full": "1990s arcade machine, octane render, unreal engine, digital art, Artstation, Trending on Artstation, Artstation HQ, Artstation HD, cgsociety, Pinterest, 8k , close up to the screen, godrays, volumetric, reflections, cinematic, epic, accurate, coherent, 3D Render,"
    },
    {
        "display": "Magical elven village at night",
        "full": "magical elven village in the rain at night, fireflies, modded, 1 0 8 0 ti, detailed, centered, digital painting, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, breathtaking, 8 k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render, cinematic lighting, dramatic lighting, masterpiece"
    },
    {
        "display": "Kraken in stormy sea",
        "full": "A kraken rising out of a stormy sea, concept art, matte painting, 8k, highly detailed, artstation, Winslow Homer"
    },
    {
        "display": "Red-haired girl fantasy portrait.",
        "full": "highly detailed key visual concept portrait of a red hair beautiful girl in a genshin impact, fantasy illustration, by coco kim, photorealistic, unreal engine, detailed and intricate environment, trending on artstation"
    },
    {
        "display": "Post-apocalyptic rogue portrait.",
        "full": "a full body portrait of a beautiful post apocalyptic offworld enchanters district bedouin blind pulp fiction scarlet wild rogue barbarian leper begging by the roadside, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Haunted house ghost illustration.",
        "full": "artgerm, joshua middleton comic cover art, pretty ghost sarah michelle gellar entire full body, floating, creepy smile, white dress, friendly, symmetrical eyes, symmetrical face, long white hair, inside haunted house"
    },
    {
        "display": "Divine tribal high priestess.",
        "full": "a beautiful divine tribal high priestess with feathers and crystals in a lush field of flowers, highly detailed medieval temple with waterfall and deer water nymphs behind her, wide painting by greg rutkowski and hr giger, zbrush, trending on artstation"
    },
    {
        "display": "Mountain village concept art",
        "full": "natural beauty village on a mountain slope, realistic concept art, eytan zana, one pixel brush, by eugene von guerard, ivan shishkin, dramatic lighting, concept art, trending on artstation"
    },
    {
        "display": "Young British singer, optical illusion.",
        "full": "a beautiful young british alternative music singer. optical illusion art by ilya kuvshinov lois van baarle ross tran range murata artgerm katsuhiro otomo norman rockwell. highly detailed intricately sharp focus mystically trending deviantart, pinterest, vogue italia, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Sailor Moon jungle queen.",
        "full": "Sailor Moon, queen of the jungle, fully clothed, pet animal, jungle fantasy, intricate, cinematic lighting, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "\"Angel girl with constellations.\"",
        "full": "A cute little girl with shoulder length curly brown hair and angel wings. She is standing in a field at night looking up and the sky is filled with constellations. The picture has an ornate frame. beautiful fantasy art by By Artgerm and Greg Rutkowski and Alphonse Mucha, trending on artstation."
    },
    {
        "display": "Cyborg female portrait.",
        "full": "hyperrealistic photography of a stunningly beautiful cyborg female, mechanical elbow, plated arm, intimate, holding hands, in the style of beth cavener, jin kagetsu,, and wlop, highly detailed, intricate filigree, chrome face symmetry, masterpiece, award - winning, sharp focus, concept art, high key, ambient lighting, 8 k, octane render, artstation"
    },
    {
        "display": "Sakura art masterpiece",
        "full": "A young sakura from Cardcaptor Sakura, pre-raphaelite defined colours , Ivan Bilibin, Austin Osman Spare, high quality, ultra detailed. Beksinski painting, art by Takato Yamamoto. masterpiece, oil on canvas painting, pixelart, pixel sorting, datamosh, glitch. vivid acid neon colours. Futurism by beksinski carl spitzweg moebius and tuomas korpi. baroque elements. baroque element. intricate artwork by caravaggio. Oil painting. 3d rendered in octane. cinematin, pixiv, unreal5, 8k"
    },
    {
        "display": "\"Luxury Kyoto Balcony Scene\"",
        "full": "a vibrant dream of a beautiful girl wearing gucci from behind on a balcony looking out over luxurious kyoto japan, lush plants, glowing paper lanterns, high fashion, magic details, by moebius, edward hopper and james gilleard, zdzislaw beksinski, james jean, steven outram hd, 8 k, trending on artstation, uhd,"
    },
    {
        "display": "Psychedelic starlight sickle concept.",
        "full": "A psychedelic item concept art of gaunt starlight sickle , vibrant color scheme, highly detailed, in the style of romanticism, cinematic, artstation, Moebius, Greg rutkowski futurism, no blur, 4k resolution, sharp ages, ultra detailed, style of John Berkey, Norman Rockwell, Hans Thoma, Ivan Shishkin, Tyler Edlin, Thomas Kinkad"
    },
    {
        "display": "Court jester with fractals.",
        "full": "front!!! shot of a court jester!!! character, mesmerizing fractal hypercubes, tentacles!!, dark holography!!!, future, metallic galactic, crystalline edges, polygonal, elegant, highly detailed, centered, (((artstation, concept art, jagged, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), ((raytracing)), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, 8K"
    },
    {
        "display": "Surreal nature self-reflection.",
        "full": "a hyper - detailed 3 d render like a oil painting of nature witnessing itself through all life, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation hd, by greg rutkowski, bruce pennington, valentina remenar and asher duran,"
    },
    {
        "display": "Ancient alien artifacts, hyper-realistic.",
        "full": "artifacts of an ancient alien civilization, realistic, 8 k, extremely detailed, cgi, trending on artstation, hyper - realistic render, by greg rutkowski"
    },
    {
        "display": "3D Violator Character Sheet",
        "full": "character sheet of violator ( todd mcfarlane spawn ). 3 d render, trending on artstation, unreal engine 5, 8 k resolution"
    },
    {
        "display": "Demon Warlock Character Design",
        "full": "human male demon, full body white purple cloak, purple cape, warlock, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Shrek as a heroic knight.",
        "full": "shrek bare chested as a glorious devout shining powerful epic amazing awesome very handsome attractive muscular stylish knight in shining golden armor riding donkey, fantasy art, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Handsome sorcerer casting spells",
        "full": "epic scene of a handsome brown haired male sorcerer he is casting a blue and red spell that is emanating from his hands, heroic pose, medium shot, waist up, epic composition, dynamic lighting, post processing, concept art, cinematic, trending on artstation, by greg rutkowski"
    },
    {
        "display": "DiCaprio as Titan portrait",
        "full": "a still of Leonardo DiCaprio as flesh human eater red inner muscles Titan in Attack on Titans, (Attack on Titans anime), sigma male, accurately portrayed, portrait art by alphonse mucha and greg rutkowski, highly detailed, digital painting, concept art, trending on artstation, very detailed, smooth, sharp focus, refined, masterpiece"
    },
    {
        "display": "Kaisa concept art portrait",
        "full": "kaisa from league of legends, character portrait, concept art, intricate details, highly detailed by greg rutkowski, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Giant horror-themed birthday cake",
        "full": "a giant birthday cake with lit candles by junji ito and james jean and esao andrews, 4 k, hyperdetailed, hyperrealistic, trending on artstation, pencil art on paper, horror, dramatic lighting"
    },
    {
        "display": "Nature goddess portrait.",
        "full": "goddess of nature, unusual beauty, fauna, flowers, plants, emotionally evoking symbolic metaphors, head in focus, fantasy, ornamental, intricate, elegant, sensual, highly detailed digital painting, artstation, concept art, painterly, golden ratio, sharp focus, illustration, art by Alphonse Mucha and Boris Vallejo and Arcimboldo and Zdzis\u0142aw Beksi\u0144ski,"
    },
    {
        "display": "Industrial sci-fi cityscape",
        "full": "scifi concept art of an industrial megalopoli by greg rutkowski, star wars expanded universe, cumulonimbus clouds, sunset, nostalgic, very very very beautiful art, cinematic lighting, bright pastel color, blue sky"
    },
    {
        "display": "Cinematic jail cell scene",
        "full": "cinema 4D cinematic render, ultra detailed, elegant, of a person inside a jail cell sitting with a desk and CRT monitor, 4K concept art, detailed, dark setting, cinematic lighting, light shining through the jail cell, casting shadows, trending on art station, old computer monitor, stressful, working, hyper realistic, ultra detailed, unreal engine, 8K post production"
    },
    {
        "display": "Cyberpunk portrait illustration",
        "full": "ultra realistic illustration, dappled lighting, masked cyberpunk, closeup portrait shot, perfect lighting, hacknaut cyberpunk, sci - fi, fantasy, intricate, elegant, deviantart, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant Nier Automata Character",
        "full": "A2 from nier automata, long white hair, slim build, fair skin, dynamic pose, smooth, beautiful, elegant, high detailed, artstation, digital illustration, splash art, fanart by artgerm, ross draws"
    },
    {
        "display": "Futuristic maglev train city",
        "full": "futuristic city center with 890J maglev train in background, modern landscape architectural design for brutalist industrialpunk black and white concrete and glass, train with maroon, white and teal metallic accents, gorgeous lighting, golden hour, cyberpunk, 2077, dramatic lighting and composition, photography, 8k, origin 100i, star citizen concept art, single line, golden ratio, minimalistic composition, side profile, Tokyo, JR sc Maglev, TGV, Eurostar"
    },
    {
        "display": "Anime-style forger character",
        "full": "your forger, spyxfamily, anime, unreal enging 5, trending on artstation, nixeu"
    },
    {
        "display": "Giant warrior in blizzard.",
        "full": "giant amazon gorgeous warrior attacking an encampment during a blizzard, highly detailed, digital illustration, artstation, concept art, matte, sharp focus, illustration, dramatic, full moon, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Stained glass Poison Ivy.",
        "full": "a stunningly detailed stained glass window of a beautiful alison hannigan as poison ivy with white skin, dark eyeliner, intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Colorful geometric porcelain jug",
        "full": "beautiful ceramics studio photograph of a tall colorful geometric symmetrical porcelain jug glazed by paul klee placed on a polished wooden table, hyperrealism 8 k trending on artstation"
    },
    {
        "display": "Couple hugging in flower field.",
        "full": "long distance shot of a young couple hugging each other in a flower field at sundown, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Photorealistic demon-angel artwork.",
        "full": "photorealistic demon angel in the style of michael whelan and gustave dore. hyperdetailed photorealism, 1 0 8 megapixels, amazing depth, glowing rich colors, powerful imagery, psychedelic overtones, 3 d finalrender, 3 d shading, cinematic lighting, artstation concept art"
    },
    {
        "display": "Cinematic portrait of Chloe.",
        "full": "portrait of chloe from detroit by artgerm, close up, portrait, cinematic, elegant, artstation, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cyberpunk, cgsociety, 8 k"
    },
    {
        "display": "Elegant bard with saxophone.",
        "full": "bard playing saxophone by charlie bowater and titian and artgerm, full body portrait, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Colorful crystal cave illustration",
        "full": "crystal cave, clusters of crystals, colorful, studio ghibli, pixar and disney animation, sharp, rendered in unreal engine 5, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wide angle, artbook, wallpaper, splash art, promo art, dramatic lighting, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Cholo smoking, drinking coffee.",
        "full": "a ultradetailed beautiful panting of a mexican cholo somking weed and drinking coffee, by conrad roset, greg rutkowski and makoto shinkai, trending on artstation"
    },
    {
        "display": "Zendaya as Twin Peaks waitress",
        "full": "Portrait of Zendaya wearing baby blue color diner waitress dress by Michael Whelan, Bob Larkin and Tomer Hanuka, from scene from Twin Peaks, simple illustration, domestic, nostalgic, from scene from Twin Peaks, clean, full of details, by Makoto Shinkai and thomas kinkade, Matte painting, trending on artstation and unreal engine"
    },
    {
        "display": "Futuristic Marrakech landscape.",
        "full": "Futuristic Marrakech, palm trees ,WLOP, James Jean, tom bagshaw, rococo, trending on artstation, fantasy, intricate, elegant, highly detailed, digital painting, concept art, smooth, illustration, cinematic lighting, hyper realism, octane render, 8k, hyper detailed."
    },
    {
        "display": "Cookie Monster portrait, digital art",
        "full": "portrait of Cookie Monster in Society (1989), highly detailed, centered, solid color background, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, donato giancola, Joseph Christian Leyendecker, Les Edwards, Ed Repka, WLOP"
    },
    {
        "display": "Playful Dr. Strange artwork",
        "full": "a playful and panacea dr strange, by ron walotsky, noriyoshi ohrai, tony cragg and edmund dulac.. ver mas aqui art movement. trending on artstation. hyper detailed, concept art, hyperrealism, 1 6 k resolution, rendered with cinema 4 d, 8 k resolution, hypermaximalist, cgsociety, zbrush central, behance hd, masterpiece, digital matte painting, trending on artstation, multicoloured"
    },
    {
        "display": "Female warrior with many eyes.",
        "full": "female warrior with many eyes, lovely delicate face, sophisticated armor, close - up shots of faces, oil painting, passion, bravery, intricate armour costumes, light and shadow effects, intricate, digital painting, art station, concept art, cold tones, sharp focus, morandi color scheme, eyes, sharply shaped, illustration, art by makoto shinkai and lois van baarle"
    },
    {
        "display": "Seductive Jessica, hyper-realistic art",
        "full": "jessica from bladerunner, seductive look, chromatic aberration, hyper realistic, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by wlop and artgerm and greg rutkowski and ayami kojima"
    },
    {
        "display": "DND campaign locations art",
        "full": "DND campaign locations concept art, trending on artstation"
    },
    {
        "display": "Water goddess portrait",
        "full": "beautiful long haired goddness of water, dress, portrait, water everywhere, rainbow, highly detailed, digital painting, artstation, concept art, global illumination, ray tracing smooth, sharp focus, illustration, art by artgerm and greg rutkowski and makoto shinkai, jeremy lipkin"
    },
    {
        "display": "Victorious, fearful elf woman.",
        "full": "death is swallowed up in victory, very detailed and beautiful womans face, screaming with fear, artwork by artgerm, centered shot, wide angle, full body, elfpunk, artwork by naoto hattori, giger, landscape art by john howe"
    },
    {
        "display": "Interdimensional wormhole station.",
        "full": "beautiful painting of an interdimensional wormhole tesseract transport station in a radioactive glowing swamp, grunge cyberpunk, by Remedios Varo and Anato Finnstark and Greg Rutkowski, dayglo pink, dayglo blue, by Craig Mullins, ilya kuvshinov, krenz cushart, artgerm, 8k, trending on ArtStation"
    },
    {
        "display": "Cyberpunk cyborg portrait.",
        "full": "portrait of lana del rey as a cyberpunk cyborg. roses, sci - fi, intricate abstract upper body intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, deviantart, greg rutkowski, cinematic arthouse, key art, hyper realism, iridescent accents"
    },
    {
        "display": "Ghibli-style fantasy landscape.",
        "full": "pokemon sword and shield ghibli landscape illustration close floral clearing and mountains in the background, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by fire watch game and greg rutkowski, no characters"
    },
    {
        "display": "Cyberpunk Captain Kirk portrait",
        "full": "portrait shot of a captain kirk from star trek in cyberpunk 2 0 7 7, realistic, professionally, professionally color graded, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Mermaid sculpture in marble.",
        "full": "mermaid, saphire, carved blue marble, inlaid with ivory and gold accents, ivory rococo, wings lace wear, sculpted by spider zero, zaha hadid, trending on artstation, beautifully lit, hyper detailed, insane details, intricate, elite, ornate, elegant, luxury + + upward perspective + + cinematic perspective, dramatic lighting, cgsociety, hypermaximalist, golden ratio, environmental key art, photorealistic, octane render, weta digital, micro details, 3 d sculpture, structure, ray trace # mercury # blue marble # zaha hadid # golden ratio # key art # 3 d sculpture # ray trace"
    },
    {
        "display": "Photorealistic dark fantasy abyss",
        "full": "the abyss, dynamic lighting, photorealistic dark fantasy concept art, trending on artstation, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Wuxia warrior with guandao",
        "full": "Epic action scene, Concept art of a man wielding a guandao, wearing a white garb covered with a long white cloak, red smoke follows his movement, full body wuxia, polearm martial arts by Akihito Yoshitomi AND Yoji Shinkawa AND Greg Rutkowski, Mark Arian trending on artstation, 4k"
    },
    {
        "display": "Detailed manga poster design",
        "full": "highly detailed artstation minaba hideo shigenori soejima fantastic fate katsuhiro otomo manga poster of princess mechine, minaba hideo, artgerm, long hair, armor, laces, ruffles, 8 k, fluorescent, maximalist, jump comics, art nouveau, takato yamamoto"
    },
    {
        "display": "Alien cat sleeping spaceship",
        "full": "alien space cat sleeping on a small fighter spaceship in a spaceport in a star wars inspired studio ghibli animated film, volumetric lighting, octane render by artgerm, greg rutkowski, studio ghibli, thomas kindkade, alphonse mucha, loish, norman rockwel, highly detailed"
    },
    {
        "display": "Cute girl illustration.",
        "full": "a cute girl smiling, Tristan Eaton, victo ngai, artgerm, RHADS, ross draws"
    },
    {
        "display": "Detailed shrine with kitsune.",
        "full": "a high detail shrine jinja with kitsune fox by Tomas Sanchez and Simon St\u00e5lenhag and krenz cushart and ilya kuvshinov. pixiv fanbox skeb.jp clipstudio medibang ichi-up CGWORLD key visual manga cover"
    },
    {
        "display": "Surreal playground scene.",
        "full": "realistic detailed image of an old dead couple on a playground by Francis Bacon, Surreal, Norman Rockwell and James Jean, Greg Hildebrandt, and Mark Brooks, triadic color scheme, By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Edward Hopper and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Stern god in vibrant surrealism.",
        "full": "powerful stern god of tropical fish metaverse made of ancient magic technology, galactic nebular astral realm sacred journey in oil painting, trending on artstation, award winning, emotional vibrant, highly detailed surrealist art"
    },
    {
        "display": "Tennis Ball Monster Poster",
        "full": "a tennis ball monsters cinematic poster, colorful, digital art, fantasy, magic, chalk, trending on artstation, ultra detailed, professional illustration by basil gogos"
    },
    {
        "display": "Tanned woman portrait, backpack.",
        "full": "Close-up portrait of kind young tanned woman with short black hair in a bob cut, with a backpack, slightly dirty face, transparent background, png, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Kurdish male warrior illustration",
        "full": "kurdish male warrior, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, incredibly strong and handsome"
    },
    {
        "display": "Cottagecore Selena Gomez beach illustration.",
        "full": "beautiful cottagecore selena gomez!!, standing in front of a beach, intricate, stunning, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal mage queen portrait",
        "full": "a full body portrait of a beautiful ethereal delicate mage queen meditative yoga power pose, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Colorful abstract dreamscape",
        "full": "abstract astral dreamscape of colorful 3 d geomtric shapes with dark!!!! and moody light and shadows, 3 d video game, futuristic, science fiction, atmosphere, octane render, depth of field, unreal engine 5, vibrant colors, trending on artstation, ultra high detail, ultra realistic, cinematic, focused, 8 k"
    },
    {
        "display": "Hyperrealistic Bieber Beaver",
        "full": "hyperrealistic dslr film still of justin bieber disguised as ( anthropomorphic ( beaver ) ), stunning 8 k octane comprehensive 3 d render, inspired by istvan sandorfi & greg rutkowski & unreal engine, perfect symmetry, dim volumetric cinematic lighting, extremely hyper - detailed, incredibly real lifelike attributes & flesh texture, intricate, masterpiece, artstation"
    },
    {
        "display": "Cyborg Anubis artwork",
        "full": "detailed image of cyborg anubis by Ayami Kojima, Amano, Karol Bak, Greg Hildebrandt, and Mark Brooks, rich deep colors. Beksinski painting, part by Adrian Ghenie and Gerhard Richter. art by Takato Yamamoto. masterpiece . intricate artwork by Tooth Wu and wlop and beeple, greg rutkowski, very coherent symmetrical artwork, cinematic, hyper realism, high detail, octane render, unreal engine, 8k, Vibrant colors, Smooth gradients, High contrast, depth of field. by Katsuhiro Otomo, full body character drawing, inspired by Evangeleon, clean ink detailed line drawing, intricate detail, extremely detailed. painting by Arthur Rackham, Eugene de Blaas, Frederic Leighton"
    },
    {
        "display": "Dieselpunk Volgograd scene",
        "full": "Dieselpunk Volgograd,  The Motherland Calls with pipes and steam, giant airships in the sky, aeroplane, steam, epic composition, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Goddess Athena Illustration",
        "full": "the goddess athena, greek mythology, intricate, upper body, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Man watching rocket launch.",
        "full": "a highly detailed matte painting of a man on a hill watching a rocket launch in the distance by studio ghibli, makoto shinkai, by artgerm, by wlop, by greg rutkowski, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Futuristic man in wasteland",
        "full": "a somber futuristic man dressed in white, standing in a desolate snowy wasteland amid ruins of a futuristic city, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by ralph mcquarrie  \u2014H 896 \u2014C 8.0 \u2014n 9 \u2014i \u2014s 150"
    },
    {
        "display": "Medieval fantasy mandalorian artwork",
        "full": "a medieval fantasy mandalorian in the style of very very photorealistic digital art by envar studio, detailed and ornate armor, concept art, movie quality, ultra realistic, epic, stunning composition, desert sunrise in the background, unreal engine 5, nvidia, oil painting"
    },
    {
        "display": "Cyberpunk pharaoh android.",
        "full": "a whirlwind of souls rushing inside the metaverse, half body, glowin eyes, tiara with sapphire, pharaoh, android, cyborg, cyberpunk face, by loish, cyberpunk, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Luxury cinematic artwork",
        "full": "luxury advertisement, a highly detailed epic cinematic concept art CG render digital painting artwork of a girl in a grey hoodie with a yellow rebreather half-mask. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Elon Musk as McFly.",
        "full": "elon musk as marty mcfly, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Heroic male demon character.",
        "full": "human male demon, full body white purple cape, hero, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Lace-winged fairy in landscape",
        "full": "a beautiful woman with wings of lace in a lord of the rings scenery landscape, bright sunny day, god's rays, pond, tall colorful flowers, butterflies, highly detailed, perfect lighting, perfect composition, 4 k, artgerm, alan lee, derek zabrocki, greg rutkowski, octane remder"
    },
    {
        "display": "Punk rock woman portrait",
        "full": "portrait of a beautiful punkrock woman in corset, art by ilya kuvshinov and lois van baarle and sam yang and ross tran and wlop and artgerm, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Islamic space marine portrait",
        "full": "high detailed portrait of beautiful islamic space marine in iraq, with beautiful mosques, warhammer 40000, cinematic light, dark environment, painting by jeremy geddes, Winslow Homer, Daniel Ridgway Knight, trending on artstation, ultrarealistic concept art, illustration, 4k, hd, unreal engine, high quality"
    },
    {
        "display": "Futuristic female portrait",
        "full": "a highly detailed long shot photo of very intricate female face portrait, futurism, rococo cyber neon lighting, detailed futuristic fibonacci jewelry, profile posing, hyper photorealistic, crispy quality, digital photography, trending in pinterest, cinematic, 4 k ultra hd, art by pascal blanche, art by greg rutkowski, art by artgerm,"
    },
    {
        "display": "Abandoned airport at sunset",
        "full": "photo of a 8k ultra realistic abandoned airport with hundreds of planes, planes, wreck, jets, sunset, cinematic lighting, trending on artstation, enreal engine 5, masterpiece, concept art"
    },
    {
        "display": "Cyborg anatomy infographic.",
        "full": "anatomy of the terminator, robot, cyborg, t 1 0 0, arc reactor, bloodborne diagrams, mystical, intricate ornamental tower floral flourishes, rule of thirds, technology meets fantasy, map, infographic, concept art, art station, style of wes anderson"
    },
    {
        "display": "Elf princess balcony scene",
        "full": "a detailed digital art of an elf princess on a balcony, a tropical mountain on a full moon night with dark clouds in the skies, artstation, vivid colors, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Futuristic girl with weapon",
        "full": "girl in futuristic cape, weapon in hand, character concept art, star wars style, digital art, many details, super realistic, high quality, 8 k"
    },
    {
        "display": "Modern library in conservatory",
        "full": "a beautiful print of the interior of a modern home library in a glass conservatory, plants everywhere by Raphael, Hopper, and Rene Magritte. detailed, romantic, enchanting, trending on artstation"
    },
    {
        "display": "Steampunk bull skull painting",
        "full": "magic weathered steampunk symmetrical bull skull at the center, old fabric, ropes. painting by rembrandt and greg rutkowski"
    },
    {
        "display": "Cottagecore Michael Jackson portrait",
        "full": "male cottagecore michael jackson portrait with nature as background, intricate, swagger, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Man in skull fox mask",
        "full": "( ( ( ( ( hyperrealist distant portrait of a man wearing a skull fox mask in the jungle. ) ) ) ) ) by michelangelo, fantasy, photorealistic, octane render, unreal engine, dynamic lighting, trending on artstation, poster, volumetric lighting, very detailed faces, 4 k, award winning"
    },
    {
        "display": "Anthropomorphic mecha portrait.",
        "full": "portrait of anthropomorphic mecha - sivatherium sardonyxmechanic, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Eldritch horror towering over citizens",
        "full": "an eldtrich horror larger than a building, terrified citizens, mesmerizing, fantastical, concept art, highly detailed, artstation, trending, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "Sakura tree in forest.",
        "full": "a sakura tree in a forest, sunbeams, natural lighting. 8 k, octane render, full shot. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Eerie ultra-realistic painting",
        "full": "painting of the end, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anthro Black Reindeer portrait",
        "full": "award winning beautiful portrait commission of a male furry anthro Black Reindeer fursona with a tail, wings and a cute beautiful attractive detailed furry face wearing stylish black and orange galaxy clothes in a outerspace city at night while it rains. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Anime schoolgirls in mirror room",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai, cute beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. deep water bellow. pretty realistic face. dramatic deep light, trending on artstation, oil painting brush"
    },
    {
        "display": "Cyberpunk Michael Jackson Art",
        "full": "cyberpunk michael jackson, ultra detailed, trending on artstation, concept art, octane render, unreal engine,"
    },
    {
        "display": "Vegan hamburger with toppings.",
        "full": "vegan hamburger guacamole and crispy fried onion and fried egg toppings, crispy buns, 8 k resolution, studio lighting, sharp focus, hyper - detailed"
    },
    {
        "display": "Futuristic bride portrait.",
        "full": "symmetry!! portrait bride, going down the stairs, futurism, sci - fi, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Earth as a pebble",
        "full": "planet earth as a pebble on the ground, unreal 5 render, studio ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award - winning photograph, masterpiece"
    },
    {
        "display": "Symmetrical sci-fi portrait.",
        "full": "symmetry!! portrait of * * * *, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ultra-realistic Aloy illustration",
        "full": "ultra realistic illustration of aloy from horizon zero dawn, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Smug skeleton scientist girl",
        "full": "cute & beautiful smug smiling undead skeleton girl with very attractive face and red hair dressed as a scientist, elegant, digital art, fullbody painting, fantasy, pixar style, painting, pin up, highly detailed, artstation, art by artgerm, vrubel, greg rutkowski, ilya kuvshinov, raymond swanland"
    },
    {
        "display": "Futuristic biotech cyberpunk raver.",
        "full": "soft lustrous biotech raver gutter punk cyborg biotech, futurist, battlestar galactica, golden ratio, details, scifi, fantasy, cyberpunk, intricate, decadent, highly detailed, digital painting, octane render, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, loish, wlop"
    },
    {
        "display": "Ultra-realistic cyberpunk portrait",
        "full": "closeup portrait, ultra realistic illustration, cyberpunk, hacknaut, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration - h 7 6 8"
    },
    {
        "display": "Cyberpunk Icarus Prince Statue",
        "full": "a young handsome Spanish prince in a full-body bronze cyberpunk style statue of Icarus with glowing orange eyes, crown of white roses,  flowing teal-colored silk, fabric, flowers. baroque elements, human skull. full-length view. baroque element. intricate artwork by caravaggio.  many many birds birds on  background. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Creepy eclipse horror art",
        "full": "the eclipse from berserk, creepy, melting, since, horror, art by wayne barlowe, giger, artgerm"
    },
    {
        "display": "Victorian Nightingale Vision",
        "full": "victorian britain 1 8 3 6, 1 6 year old florence nightingale, has a vision of god telling her to become a nurse, in a luxurious english victorian bedroom, night time, lamp light, finely detailed perfect art, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Purple-lit ant alien portrait",
        "full": "character portrait art, ant alien, trending in artstation, purple color lighting"
    },
    {
        "display": "Abstract tendrils in space",
        "full": "mass of tendrils floating in space, stars, galaxy, nebula, planet, abstract space, high quality digital art, trending on artstation, hyper - realistic, photorealistic, 4 k hd"
    },
    {
        "display": "Muddy river meets mercury sea.",
        "full": "muddy river leads to mercury sea by hiroshi yoshida, marc simonetti, roger deakins, turner, rothko, hyperrealism, cinematic, dramatic storm desert sunset, matte painting, fluid simulation, tonalism, volumetric light, trending on artstation, cgsociety, high detail concept art, top view, rendered in octane"
    },
    {
        "display": "Zuckerberg horror illustration.",
        "full": "mark zuckerberg holding a flower by hr giger, trending on artstation, horror, illustration"
    },
    {
        "display": "Black marble anime policewoman",
        "full": "a statue of an extremely beautiful female anime riot policewoman made of black marble, the statue is not wearing a helmet, no helmet, 4 k, 8 k, hd, render, denoise, sharp focus, clear focus, beautiful lighting, trending on artstation, tactical gear, chest rig, straps, nylon, buckles, the statue is wearing a complex tactical chest rig, acronym, techwear, tactical clothing, sharp focus, award winning photograph, beautiful, clear, sharp, detailed, hyperreal, realistic"
    },
    {
        "display": "Trump as Eastwood, western scene",
        "full": "photo of donald trump as clint eastwood squinting at high noon in the style of a clint eastwood movie, the good, horse, cactus, the bad and the ugly, abraham lincoln, clint eastwood, steven seagal, vibe, bud spencer, donald trump, glory days, justice, american flag, patriotism, apple pie, black and white, artgerm, trending on artstation"
    },
    {
        "display": "Julius Caesar's parade speech.",
        "full": "Julius Caesar speech to a militar parade in front of the Roma\u2019s colliseum at the kingdom of Julius Caesar, roman historic works in brand new condition, not ruins, hyper-detailed, artstation trending, world renowned artists, historic artworks society, antique renewel, good contrast, realistic color ,cgsociety, by greg rutkowski,gustave dore, Deviantart"
    },
    {
        "display": "Anime tennis girl portrait",
        "full": "a very beautiful young anime tennis girl, full body, long dreadlocks blond hair, sky blue eyes, full round face, short smile, bikini, miniskirt, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "T-Rex portrait, elegant digital art.",
        "full": "Jurassic Park\u2019s T-Rex looking at the camera, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, volumetric lighting, green yellow scheme, art by artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Steampunk weapon display",
        "full": "A steampunk store, by Danar Worya and Greg Rutkowski, and artgerm, ultra detailed displays of weapons and clockwork machinations densely packed on shelves, volumetric lighting, 8k, unreal engine, trending on artstation"
    },
    {
        "display": "Eldritch shaman portrait.",
        "full": "a striking portrait of a pitch black masked eldritch shaman with sinister red eyes by moebius and ross tran and artgerm, trending on artstation, digital art, 4 k resolution, detailed, high quality, sharp focus, hq artwork, insane detail, volumetric lighting, character concept art, fine details, tarot card, clear subject"
    },
    {
        "display": "Emotional watercolor illustration.",
        "full": "our desperation, selfishness, and our effort to save the world and ourselves in the face of all this modernity stand before us like a lyrical blow of wind, highly detailed, sharp focus, award winning watercolor pen illustration, by caroline choi artgerm, art by range murata"
    },
    {
        "display": "Dung beetle pushing Earth.",
        "full": "a dung beetle pushing the earth up a hill, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by boris vallejo"
    },
    {
        "display": "Brutalist anime character portrait",
        "full": "baroque oil painting anime key visual full body portrait character concept art, brutalist grimdark fantasy, young maid nazi ss commander with flowing blond hair blue eyes, military boots ceremonial sword, kuudere noble fascist dictator, official trending pixiv fanbox, rule of thirds golden ratio, by greg rutkowski makoto shinkai takashi takeuchi studio ghibli jamie wyeth"
    },
    {
        "display": "James Dean as anarchist rebel",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork costume design: young James Dean as a well-kept neat anarchist rebel in 1950s USSR mechanic overalls and big boots, reading a book. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Cinematic Neo Cyborg Portrait",
        "full": "impressive electric fantasy cinematic fine portrait photo of a keanu reeves neo matrix cyborg implant shattered crystal shard volumetric dynamic fluid simulation lighting impressive masterpiece hyper ultra detailed intricate sharp focus 8 k realistic illustration canon eos r 3 fujifilm x - t 3 0 sony alpha, by james gurney tooth wu artgerm colorful!!!, trending on artstation behance cgsociety stunning closeup macro"
    },
    {
        "display": "Organic cyborg fantasy illustration",
        "full": "organic cyborg, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by john collier and albert aublet and krenz cushart and sir edward burne - jones and alphonse mucha"
    },
    {
        "display": "Anthropomorphic comedic monster.",
        "full": "anthropomorphic d 2 0 comedic genius head in opal darkiron mr. bean grey wide head, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Lifelike pencil illustration of Norman Reedus.",
        "full": "amazing lifelike award winning pencil illustration of Norman Reedus (the walking dead) trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Dystopian blind prophet portrait",
        "full": "A full portrait of a beautiful terrible powerful dystopian blind screaming prophet, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Brown Asian vampire girl",
        "full": "a brown asian vampire girl, child, freckles, enigmatic look, elegant dress, fangs, messy curly hair, embers intricate and very beautiful and elegant, highly detailed, digital painting, artstation, concept art, smooth and sharp focus, cg by tian zi and wlop and alphonse mucha"
    },
    {
        "display": "Ultra-realistic bikini painting",
        "full": "painting of gal gadot, cheeky bikini, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed portrait of Alex Jones",
        "full": "portrait of alex jones. intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, frank frazetta"
    },
    {
        "display": "Beholder creature in dungeon.",
        "full": "a beholder creature patrolling, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, dungeon, concept art, matte, sharp focus, illustration, hearthstone, skeletons, art by eva widermann and jesper ejsing"
    },
    {
        "display": "\"Futuristic civilization tree\"",
        "full": "high depth, collective civilization tree, calm, healing, resting, life, hybrids, scifi, glowing lights!!, published concept art, mixed medias, image overlays, sharp focus, thin glowing wires, winning illustration, eyes reflecting into eyes into infinity, singularity!!!, 3 6 0 projection, art in the style of mucha"
    },
    {
        "display": "Cyberpunk man portrait",
        "full": "hyperrealistic portrait of a mysterious cyberpunk man with cybernetics, by Guy Denning, Johannes Itten, Russ Mills, glitch art, hacking effects, glitch effects, digital tech effects, cybernetics, detailed lines, holographic, chromatic, color blocking!, oil on canvas, octane, concept art, abstract, blue and black, 8k, trending on artstation"
    },
    {
        "display": "Detailed snake tree illustration",
        "full": "snake on a tree, oil painting, sunlit, paint texture, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, charlie bowater, tom bagshaw, norman rockwell"
    },
    {
        "display": "Detailed Auroch concept art",
        "full": "A dungeons and dragons monster manual sketch of an Auroch, concept art, matte painting, 8k, highly detailed, artstation"
    },
    {
        "display": "Big Mac with atmospheric lighting",
        "full": "quadruple big mac, atmospheric lighting, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Moose skull with bugs.",
        "full": "painting of dark red bugs crawling through the cavities of a large moose skull, by zdzislaw beksinski, by dariusz zawadzki, by john jude palencar, gothic, surrealism, cosmic horror, lovecraftian, cold hue's, warm tone gradient background, concept art, beautiful composition"
    },
    {
        "display": "Lifelike Asian water elemental portrait",
        "full": "asian water elemental, lifelike, portrait,  looking at viewer, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk character design sheet",
        "full": "character design sheet, concept art character, very high angle view, book cover, very attractive man with beard, walking in cyberpunk valley highly detailed full body, strong masculine features, sturdy body, command presence, royalty, smooth, sharp focus, organic, appealing, book cover, deep shadows, by Dave McKean sketch lineart for character design"
    },
    {
        "display": "\"Space Marines in Battle\"",
        "full": "a hyperealitic digital illustration of space marine hell jumpers falling through a huge space battle between two species of aliens and a fleet of human space marines, science fiction, stunning, highly detailed, artstation, smooth, hard focus, concept art, art by artgerm and greg rutkowski and alphonse mucha, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Cyborg woman portrait.",
        "full": "A professional portrait of a young cyborg woman made of metal parts, gears, wires as hair, red eyes, lipstick, narrow waist, symmetrical face features, elegant, finely detailed, concept art, in style of Greg Rutkowski,"
    },
    {
        "display": "Nier Automata concept art",
        "full": "an environmental concept art of nier automata, highly detailed, environmental light, cinematic by francis tneh"
    },
    {
        "display": "Talking oak tree fantasy.",
        "full": "a talking oak tree, a face in the bark, nose made of wood, eyes in the bark, mouth in the bark, fantasy concept art, leaves and moss, digital painting, oil painting, hyperrealistic, treebeard, ent, highly detailed, golden sunlight, very detailed eyes, artstation, cgsociety, in the forest, by alan lee, by artgerm"
    },
    {
        "display": "Anime girl in bikini.",
        "full": "photograph of pretty anime girl in bikini, jojo's bizarre adventure live action, fantasy, intricate, elegant, highly detailed, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski"
    },
    {
        "display": "Hippie woman portrait, sunset.",
        "full": "anime art face portrait character concept art, anime key visual of an hippie young woman wearing a boho dress, long hair and large eyes, finely detailed perfect face delicate features directed gaze, laying down in the grass at sunset in a valley, trending on pixiv fanbox, studio ghibli, extremely high quality artwork"
    },
    {
        "display": "Post-apocalyptic junk merchant portrait",
        "full": "A full portrait of a beautiful post apocalyptic offworld junk merchant, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Creepy cyborg sculpture portrait",
        "full": "portrait of abandoned ribbed organic biomechanical sculpture of two kissing cyborgs, covered with wires, spines, roots, ash, mold, meat, baroque painting, standing in a desolate empty wasteland, creepy, nightmare, dream-like heavy atmosphere, dark fog, surreal abandoned buildings, baroque painting, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, volumetric cinematic light, chiaroscuro, zoomed out, Raphael, Caravaggio, Beksinski, Giger"
    },
    {
        "display": "Rugged baby harp seal as Greek god.",
        "full": "portrait of rugged baby harp seal, greek god d & d, muscular, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Eerie photorealistic cat portrait",
        "full": "portrait painting of a cat, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Abstract mechanical paladin portrait",
        "full": "dynamic head-on abstract portrait of a intricate glorious holy mechanical warforged character in yellow armor holding a paladin engraved great longsword drawn and carrying a big paladin shield, beam glowing eye , face in focus, epic , trending on ArtStation, masterpiece,  cinematic lighting, by Ross Tran and by Greg Rutkowski"
    },
    {
        "display": "1980s Christmas D&D scene",
        "full": "opening presents at christmas 1 9 8 0's, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rahul Gandhi as Captain America",
        "full": "Rahul Gandhi as Captain America , Captain America costume, Rahul Gandhi hairstyle, Captain America body type, Rahul Gandhi Face, calm, cute, portrait, baby figure, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic portrait of Ranni.",
        "full": "portrait of ranni the witch, blue hair, cinematic lighting, smooth, focus, cinematic, soft features, concept art, highly detailed, artstation, trending, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi, wlop, artgerm"
    },
    {
        "display": "Anime Shrek casting ice",
        "full": "a portrait of anime shrek casting ice - ball and shoot it, cyberpunk concept art, trending on artstation, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Realistic portrait of Shiba Inu",
        "full": "portrait of a shibe wearing a hat, realism, realistic, photorealism, f 3. 5, photography, octane render, trending on artstation, artstationhd, artstationhq, unreal engine, cinema 4 d, 8 k, detailed fur"
    },
    {
        "display": "Cute Eevee Humanization",
        "full": "weta disney pixar movie still pinup photo of eevvee pokemon humanisation : : as cute woman by pixar : : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, marvel, maxim cover, latex, octane render, sweaty, iridescent, bright morning, anime, liosh, mucha : :"
    },
    {
        "display": "Detailed knight portrait.",
        "full": "full body portrait of a knight armour, highly detailed painting by gaston bussiere, craig mullins, j. c. leyendecker, 8 k, octane render, unreal engine, concept art"
    },
    {
        "display": "Glamorous angry werewolf portrait",
        "full": "glamorous Andalusian portrait angry high moral sexy werewolf, highly detailed, sharp focus, illustration, cinematic lighting, Unreal Engine 5"
    },
    {
        "display": "Realistic afternoon sleep portrait",
        "full": "beautiful realistic portrait of afternoon sleep artgerm"
    },
    {
        "display": "Demonic shrine, cosmic horror.",
        "full": "a lovecraftian painting of a demonic shrine, occult, moster summoning, warlocks ritual, cosmic horror elements, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Skeletons fishing in boat.",
        "full": "close up of skeletons in a boat on a river catching skeleton fish, pencil sketch, realistic shaded, fine details, realistic shaded lighting poster by greg rutkowski"
    },
    {
        "display": "Cyborg Grox Portrait",
        "full": "realistic portrait of a grox from spore, red furry cyborg creature with one robotic eye, round head, pointy ears, dramatic lighting, illustration by greg rutkowski, yoji shinkawa, 4 k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Cybernetic lab architecture",
        "full": "lol cybernetic symbiosis hybrid mri 3 d printer machine making a bio chemical lab, art by artgerm and greg rutkowski and alphonse mucha, drone lens orbs, global illumination, octane render, architectural, f 3 2,"
    },
    {
        "display": "Michael Myers at theater",
        "full": "painting of Michael Myers eating popcorn sitting in a row of movie theater seats with other people, sharp focus, face focused, trending on ArtStation, masterpiece, by Greg Rutkowski, by Ross Tran, by Fenghua Zhong, octane, soft render, oil on canvas, moody lighting, high contrast, dark room, cinematic, professional environmental concept art"
    },
    {
        "display": "Magical hot-air balloon scene",
        "full": "a beautiful stunning fantasy whimsical matte digital illustration of a scene of a hot - air balloon powered by magic! over a lit city at night by marc simonetti, pastel color palette, disney magic the gathering steampunk!!!, chiaroscuro magical! bokeh moon stars dramatic romantic! epic breathtaking, trending on artstation hq, masterpiece"
    },
    {
        "display": "Dramatic portrait of Natalie Portman",
        "full": "a expressive portrait of natalie portman in dramatic lighting, depth of field background, artstation, award - winning realistic sci - fi concept art by jim burns and greg rutkowski, beksinski, a realism masterpiece, expressive color palette, james gilleard, bruegel, alphonse mucha, and yoshitaka amano"
    },
    {
        "display": "Copic marker Herzog & de Meuron",
        "full": "a copic colour marker with black gel pen concept art of herzog & de meuron architecture"
    },
    {
        "display": "Cyberpunk mecha soldier portrait",
        "full": "biblic mecha cyberpunk soldier, in clouds, portrait by greg hildebrandt, studio lighting, muted colors, by terry richardson, by leonardo davinci, extreme detail, volumetric, reflective, trending on artstation, 8 k"
    },
    {
        "display": "Anime-style Jordan Peterson portrait",
        "full": "anime jordan peterson, single centered subject, mid shot, ambient lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Detailed fantasy portrait.",
        "full": "young teenager boy with straight short brown hair, dark skin, big lips. highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap"
    },
    {
        "display": "Muscular girl in landscape.",
        "full": "girl with pale white skin and black dreadlocks, muscular upper body, beautiful highly detailed face, complementary lighting, backlit, black eyeshadow, dark eyes, adventure, dramatic lighting, landscape background, beautiful painting by artgerm and greg rutkowski and raymond swanland"
    },
    {
        "display": "Zombie couple kissing, night.",
        "full": "a zombie couple kissing under a street lamp in Buenos aires at night, dark blue long hair, muted colors, matte print, pastel colors, ornate, digital art, cute smile, digital painting, fan art, elegant, pixiv, by Ilya Kuvshinov, by Studio Ghibli"
    },
    {
        "display": "Dusk beach contemplation scene.",
        "full": "a spot on the beach at dusk where i like to spend time thinking. by john avon and marc simonetti, trending on artstation"
    },
    {
        "display": "Cyberpunk factory with drones",
        "full": "concept art of a cyberpunk factory filled with drone workers, grimy, gritty, blade runner 2 0 4 9, trending on artstation, award winning painting, cgi, art by john berkey and anton fadeev and john howe and simon stalenhag"
    },
    {
        "display": "Megan Fox fantasy portrait.",
        "full": "portrait of megan fox with bright red eyes, wearing a vail and a tight yellow silk dress, sultry body with sexy belly, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Golden gemstone crown.",
        "full": "a golden majestic crown with gemstone carved into it, floating crown, yellow theme, bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, blizzard warcraft artwork, hearthstone card game artwork, the crown"
    },
    {
        "display": "Anime-style propaganda portrait",
        "full": "renaissance illustration character promotional art lotr anime key visual propaganda portrait | young girl nazi maid crusader | long flowing platinum blonde hair blue eyes | symmetrical perfect face fine detail delicate features quiet gaze | fitted black military uniform | gapmoe kuudere bokeh | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Cute political toy figurines",
        "full": "plastic toy cute figurine of boris johnson and rishi sunake and liz truss, blender, unreal engine, concept art, octane render, highly detailed, smooth, sharp focus"
    },
    {
        "display": "Anime girl in rain.",
        "full": "a girl in the rain, full shot, realistic shaded perfect body, perfect - face, fine details. night setting. very anime style. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Victorian fantasy warrior portrait",
        "full": "portrait painting of a badass young man with a worn bowler hat and primitive armor, gaslamp fantasy, victorian, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Rimuru Tempest portrait.",
        "full": "side profile of rimuru tempest looking down with sky blue hair, long hair, gold eyes, high collar, film grain, 3 5 mm, black jacket | shiny, highly detailed, rain, professional digital painting, concept art, award - winning photography, cinematic, wlop | art by pixiv art, andy warhol, yoshitaka amano, deviantart"
    },
    {
        "display": "Fluffy red squirrel character.",
        "full": "concept art for a video game character that is a red fluffy squirrel. it has a purple bow tie"
    },
    {
        "display": "Ethereal fire priestess portrait",
        "full": "Water priestess cloaked in ethereal fire and smoke, watercolor portrait by artgerm and alphonse mucha, anato finnstark, artstation, deviantart"
    },
    {
        "display": "Symmetrical skinny Shrek portrait.",
        "full": "symmetry!! portrait of skinny shrek from shrek, fantasy, medieval wear, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Realistic Tenten portrait",
        "full": "tenten from naruto, two hair buns, medium shot, visible face, detailed, perfectly shaded, perfectly shaded face, atmospheric lighting, realistic, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Anime girl in samurai armor.",
        "full": "a single beautiful anime girl holding a light source inside her hand, she wears samurai armor, expert high detail concept art, character design, perfect proportions defined face, vivid colors, photorealistic shaded lighting poster ilya kuvshinov, katsuhiro, makoto shinkai, wlop, loish and clamp style, trending on artstation, best selling artist"
    },
    {
        "display": "Harrison Ford Hamburger Portrait",
        "full": "portrait of Harrison Ford eating a giant hamburger , extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Sad anime schoolgirls portrait",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai, cute beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. deep water bellow. photographic face. dramatic deep light, trending on artstation, oil painting brush"
    },
    {
        "display": "Funny skeleton with coffee.",
        "full": "a funny skeleton character with big eyes holding a cup of coffee on a cemetery at night. pixar disney 4 k 3 d render funny animation movie oscar winning trending on artstation and behance. ratatouille style."
    },
    {
        "display": "Deanna Troi as Morticia Addams",
        "full": "ultra realistic illustration, deanna troi as morticia addams from baldurs gate and diablo, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy book cover illustration.",
        "full": "book cover!!!!!!!!!!!!, old bridge, ivy leaves graphic vectors at each border, fantasy forest landscape, fantasy magic, light night, intricate, elegant, sharp focus, illustration, highly detailed,, art by wlop and artgerm and ivan shishkin and andrey shishkin, masterpiece"
    },
    {
        "display": "Dystopian forest elf portrait",
        "full": "portrait shot of forest elf in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Anime battle sister portrait",
        "full": "portrait of Anime sister of battle, Warhammer 40000, cute-fine-face, white-short-hair pretty face, realistic shaded Perfect face, fine details. Anime. realistic shaded lighting by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Giant Lion BT Boss Art",
        "full": "Death Stranding Giant Lion BT Boss monster art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Fantasy landscape of Palestine.",
        "full": "Palestine by artgerm and wlop and scott fischer and seb mckinnon, digital art, highly detailed, wide shot, intricate, fantasy, mystical, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Alien in red saree",
        "full": "beautiful fashion human looking alien in red saree,, in the style of artgerm, and wlop, chanel jewelry, cinematic lighting, hyperdetailed, 8 k realistic, symmetrical, global illumination, radiant light, love and mercy, frostbite 3 engine, cryengine, dof, trending on artstation, digital art, crepuscular ray"
    },
    {
        "display": "\"Violet monster embryo concept\"",
        "full": "monster embryo, magic smoke surrounding, violet and dark theme. dark masterpiece trending on artstation, 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, blizzard warcraft artwork, hearthstone artwork"
    },
    {
        "display": "Dragon rider fantasy illustration",
        "full": "illustration of a dragon with a lady on its back, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dark elf witch ritual.",
        "full": "dark elf witch casting a spell, forest ritual, moon, Ilya Kuvshinov, digital, concept art, Kyoto animation,last exile, blue submarine no. 6, gustav klimt,loish, murata range, kawaii, yoshitaka amano, studio lighting, manga, bright colors, beautiful, 28mm lens,mucha, vibrant high contrast, gradation, jean giraud, fantasy, rule of thirds, unreal engine, fibonacci, intricate, cel shaded, blender npr, flat, matte print, soft eyes, smooth, makoto shinkai"
    },
    {
        "display": "Vibrant skull-faced portrait",
        "full": "a portrait of a girl skull face, anon mask, in the style of banksy, van gogh, atey ghailan and steve mccurry, vibrant colors and hard shadows and strong rim light, lucien freud, comic cover art, trending on artstation"
    },
    {
        "display": "Fiery portal to hell.",
        "full": "a fiery portal is the gateway to hell on the wall city building house, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Lovecraft eating sushi portrait",
        "full": "highly detailed portrait of h p lovecraft eating shushi by greg rutkowski, mike mignola, tom bagshaw artgerm and ross tran, beautiful dramatic dark moody lighting, cinematic atmosphere, glossy magazine painting, global illumination, deep color, 8 k resolution, high details, flickr, dslr, artstation"
    },
    {
        "display": "Kim Kardashian as Zelda",
        "full": "A film still of kim kardashian as princess zelda in real life, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Glamorous Japanese schoolgirl portrait",
        "full": "glamorous and sexy japanese schoolgirl, beautiful, pearlescent skin, natural beauty, seductive eyes and face, elegant girl, lacivious pose, natural beauty, very detailed face, seductive lady, sexy push up bras, pale and coloured kimono, photorealism, summer vibrancy, cinematic, a portrait by artgerm, rossdraws, Norman Rockwell, magali villeneuve, Gil Elvgren, Alberto Vargas, Earl Moran, Enoch Bolles"
    },
    {
        "display": "Futuristic magical beaver cyborg.",
        "full": "magical beaver cyborg in futuristic metropolis, giant trees, clean streets, inspired by horizon zero dawn, trending on artstation, art by richard dumont, leon tukker"
    },
    {
        "display": "Cyberpunk panda, hyper-detailed.",
        "full": "cyberpunk panda, naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Biomechanical angel knight.",
        "full": "Fantasy Concept character art of a angel female in biomechanical knight armor. By artstation trending. Highly detailed"
    },
    {
        "display": "Van with fire pit.",
        "full": "concept art for a van with a built - in fire pit, painted by syd mead, high quality"
    },
    {
        "display": "Cyborg DJ on stage",
        "full": "cyborg DJ mixing on stage , diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Evil twin elf portrait",
        "full": "dungeons and dragons evil twin elves character closeup portrait, dramatic light, dungeon background, 2 0 0 mm focal length, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Surreal multidimensional forest.",
        "full": "a hyper - detailed 3 d render like a oil painting of the the 4 d multidimensional forest in the golden light of dawn, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation hd, by greg rutkowski, chris tulloch mccabe, valentina remenar and asher duran,"
    },
    {
        "display": "Happy mask salesman portrait",
        "full": "a portrait of the happy mask salesman!, grinning, red hair, handsome, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney and wlop, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Hyper-realistic deep-sea monster.",
        "full": "a deepsea monster creature bio fauna by neville page, ken barthelmey, carlos huante and doug chiang, sharp focus, trending on artstation, hyper realism, octane render, 8 k, hyper detailed, ultra detailed, highly detailed, zbrush, concept art, creature design"
    },
    {
        "display": "Hisoka Morow Portrait",
        "full": "portrait of hisoka morow hunter hunter male thin lips small eyes very thin pursed lips extremely sharp jaw yellow eyes almond almond eyes squinting eyes dark red hair soft hair slicked back crimson hair anime, elegant, highly detailed, digital painting, artstation art by artgerm and greg rutkowski and alphonse mucha madonna bowie"
    },
    {
        "display": "Boris Johnson as Rick Sanchez",
        "full": "Boris Johnson as crazy genius Rick Sanchez from Rick and Morty, unibrow, white robe, big eyes, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Whisky factory interior scene.",
        "full": "a man sitting on a small wooden stool in a whisky factory with barrels stacked up to the sealing. beautiful atmospheric lighting, sunlight beaming through open windows, small dust particles in the air. unreal engine 5, v - ray, 8 k, ultra hd, god rays. concept art."
    },
    {
        "display": "Taylor Swift as Lara Croft",
        "full": "taylor swift as lara croft. tomb raider. intricate abstract. intricate artwork. by tooth wu, wlop, beeple, dan mumford. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Detailed portrait of Pain.",
        "full": "A portrait of Pain-Akatsuki-personagens-naruto, RPG Reference,  art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski, Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Ultra-realistic Stephanie illustration",
        "full": "ultra realistic illustration, stephanie from lazy town, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gorilla goddess wizard portrait.",
        "full": "an anthropomorphic beautiful goddess female wizard made of gorilla portrait holding a broom stick wearing colourful robe, fine art, award winning, intricate, elegant, sharp focus, octane render, hyperrealistic, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by jamie hewlett and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Peter Crouch as Space Marine",
        "full": "Peter Crouch as a Warhammer 40k Space Marine, portrait, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Jester character in hyperdimensional setting",
        "full": "front!!! shot of a court jester!!! character, hyperdimensional, bioluminescent hypercubes, dark holography!!!, future, chrome, (((intricate))), elegant, highly detailed, centered, (((artstation, concept art, smooth, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), ((raytracing)), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, grainy, muted, 8K, digitalblasphemy, metatron"
    },
    {
        "display": "Cyberpunk man portrait",
        "full": "hyperrealistic portrait of a cyberpunk man, by Guy Denning, Johannes Itten, Russ Mills, surreal cyberspace, hacking effects, detailed lines, network, cybernetics, digital glasses, long hair, cables, computation, technological, color blocking!, closeup, circuitry, digital, cyberpunk style, oil on canvas, insane detail, front view, symmetrical, object centered, octane, concept art, abstract!!, artistic, 8k, cinematic, trending on artstation"
    },
    {
        "display": "Attractive man in art style",
        "full": "attractive man, painting by gaston bussiere, craig mullins, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Portrait of girl reading",
        "full": "highly detailed portrait of beautiful girl reading a book in toy story 3, dynamic pose, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Huge kangaroo artwork",
        "full": "huge Kangaroo trending on Artstation, 8K artistic photography"
    },
    {
        "display": "Hyperrealistic dead space composition",
        "full": "hyperrealistic mixed media image of dead space, inspired by thomas eakes & greg rutkowski & xiang duan, perfect facial symmetry, dim volumetric lighting, 8 k octane beautifully detailed render, post - processing, extremely hyper - detailed, intricate, epic composition, lifelike attributes, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning,"
    },
    {
        "display": "Charlize Theron as Cleopatra.",
        "full": "Portrait of Charlize Theron as Cleopatra, intricate art deco leaf designs, elegant, highly detailed egyptian patterns, hieroglyph, sharp focus, art by Artgerm and beeple"
    },
    {
        "display": "Epic Red Mage Illustration",
        "full": "A Full View of a Red Mage wearing striped shining armor and a feathered hat holding a staff of power surrounded by an epic cloudscape. Magus. Red Wizard. masterpiece. 4k digital illustration. by Ruan Jia and Artgerm and Andreas Rocha and William-Adolphe Bouguereau and Edmund Blair Leighton. award winning, Artstation, intricate details, realistic, Hyperdetailed, 8k resolution. Concept Painting. Key Art"
    },
    {
        "display": "Vibrant occult ritual illustration",
        "full": "a vibrant ultraclear occult ritual by rene magritte and laurie greasley, etching by gustave dore, colorful flat surreal, ethereal, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, masterpiece"
    },
    {
        "display": "Baroque-cyberpunk princess sculpture",
        "full": "baroque and cyberpunk style full-body sculpture of a young evil Hispanic princess half android with a chest  exposing a glowing diamond battery, glowing green seductive laser eyes, crown of mint gears and uranium,  swirling salmon-colored silk fabric, robotic raptors dinosaurs. baroque elements. full-length view. intricate artwork by caravaggio. art by Artgerm and Greg Rutkowski and Alphonse Mucha, Trending on artstation, cinematic industrial lighting, photorealistic, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Cyberpunk alley scene.",
        "full": "a highly detailed contemporary painting of a tiny boy in a Jumpsuit standing in a dark alley, abandoned buildings with graffiti, a nightclub with neon sign, menacing skyline by Studio Ghibli, Makoto Shinkai, by Artgerm, by WLOP, by Greg Rutkowski, volumetric lighting, cyberpunk, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Flawless Jessica Rabbit portrait",
        "full": "photography beautiful flawless jessica rabbit in her red dress by greg rutkowski and artgerm, femme fetal, darkroom, dramatic high contrast lighting like sin city, ultra - realistic, intricate detail, 8 k, octane render"
    },
    {
        "display": "Ice cream factory concept art",
        "full": "A fairy-tale style factory made of ice cream\uff0c full of details, matte painting, concept art, smooth, by Cory Loftis and Thomas Kinkade and WLOP\uff0cSoft light atmosphere\uff0ctrending on cgsociety and Blender\uff0c unreal engine\uff0c8kHDR\uff0clight effect\uff0crtx on\uff0c-H 768"
    },
    {
        "display": "Portrait of Margot Robbie",
        "full": "A full portrait of Margot Robbie, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Alien Zuckerberg in space",
        "full": "A floating head of mark zuckerberg as an alien in space, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, artgerm, award winning art, ray tracing"
    },
    {
        "display": "Boris Johnson as Loki",
        "full": "Boris Johnson as Loki, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk pharaoh souls",
        "full": "a whirlwind of souls rushing inside the metaverse, half body, glowin eyes, tiara with sapphire, pharaoh, android, cyborg, cyberpunk face, by loish, cyberpunk, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Metahuman Bella Hadid Portrait",
        "full": "a highly detailed metahuman 8 k close up render of bella hadid as alex grey art renaissance in iris van herpen dress trending on artstation made in unreal engine 4"
    },
    {
        "display": "Emma Watson in silk gown",
        "full": "emma watson wearing finely pleated silk bihu mekhela strapless costume expertly draped goddess style dress by wlop, assamese gamosa pattern, face by daz 3 d genesis and artgerm concept art 3 d octane render cinema 4 d v ray, unreal engine, hyper realistic hdr fabric textures, ray traced, bright lit cinematic studio fashion photography, real life like, daz iray shaders"
    },
    {
        "display": "Symmetrical abstract product render",
        "full": "symmetry!! product render abstract mathematical, glowing lights!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Fiery red sea fantasy",
        "full": "a churning, boiling, fiery red sea with lots of smoky black and red steam, fantasy digital art, octane render, beautiful composition, trending on artstation, award-winning photograph, masterpiece"
    },
    {
        "display": "Smug undead bride skeleton",
        "full": "cute & beautiful smug smiling mexican undead skeleton girl with red hair dressed as a bride, elegant, digital art, fantasy, pixar style, painting, pin up, highly detailed, artstation, art by artgerm, vrubel, boris vallejo and ilya kuvshinov"
    },
    {
        "display": "Catwoman on rooftop",
        "full": "phil noto, peter mohrbacher, thomas kinkade, artgerm, catwoman dc comics, symmetrical eyes, city rooftop"
    },
    {
        "display": "Hyper-realistic goddess statue",
        "full": "sci - fi cgartist wide shot anaglyph ambient occlusion rendering of a hyper realistic marble greek statuary beautiful goddess head product photo bright white backdrop high key colored lighting, trending on artstation"
    },
    {
        "display": "One Punch Man Baking",
        "full": "one punch man as baker baking french baguette, manga book anime style, anime key visual, pixiv"
    },
    {
        "display": "Evil anime character portrait",
        "full": "photo portrait of hisoka hunter x hunter moira overwatch inspired male evil sharp features yellow iris, small narrow slit shrewd yellow eyes, red red soft crimson hair smirk anime hunterpedia pixiv fanbox concept art, matte, sharp focus, illustration, ross tran ruan jia"
    },
    {
        "display": "Bradley James and Colin Morgan in love, meadow.",
        "full": "Bradley James and Colin Morgan, they are guys. From the television show Merlin (2008). In a beautiful meadow in love and happy;high-detailed oil painting by Ilya Repin, Caravaggio, William Blake, Alex Grey, Thomas Eakins and Beksinski, trending on Artstation, masterpiece, 4k"
    },
    {
        "display": "Gorgeous girl at sunset.",
        "full": "gorgeous girl, symmetrical face, short red hair, crop top, daisy dukes shorts. by art germ, trending on artstation, unreal engine, octane render, cinematic, ultra detailed, 4 kmagical, lights, sunset, mysterious, serene, sunlight, ocean, flowing, floating water, splashing water, glowing, mystical, mysterious, lights, bokeh. 4 k"
    },
    {
        "display": "Marshmallow Man in NYC",
        "full": "the ghostbusters marshmallow man wearing timberlands and a yankee hat smoking a joint walking threw new york city, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Fantasy Jesus Portrait",
        "full": "a portrait of jesus praying, weird, fantasy by dan mumford, yusuke murata and makoto shinkai, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Bloodied Woman Portrait",
        "full": "woman covered with blood, covered with skeleton tattoo, emilia clarke face!!!, masterpiece portrait, long white hair, beautiful blue eyes, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. retro film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Moon Knight and Majin Boo.",
        "full": "Moon Knight mixed with Majin Boo, posed on a rock. Digital illustration, very vibrant colors, soft lighting, adventurous, atmospheric lighting, 8K, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "Hyperrealistic Steve Buscemi as Hellraiser",
        "full": "hyperrealistic mixed media high resolution painting of (Steve Buscemi) disguised as !!Hellraiser!!, stunning 3d render inspired art by Jamie Salmon and Istv\u00e1n S\u00e1ndorfi and Greg Rutkowski, perfect facial symmetry, dim volumetric lighting, 8k octane beautifully detailed render, full body shot, post-processing, extremely hyper-detailed, intricate, epic composition, highly detailed attributes, highly detailed atmosphere, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, flawless completion, lifelike texture, perfection,"
    },
    {
        "display": "Elegant forest druid concept.",
        "full": "druid of the forest, baroque style, elegant, beautiful, mesmerizing, concept art, highly detailed, artstation, behance, deviantart, inspired by innocent manga, inspired by castlevania concept art, trending, ayami kojima, shinichi sakamoto"
    },
    {
        "display": "Goddess of Sunflowers Artwork",
        "full": "breathtaking detailed concept art painting of the goddess of sunflower flowers, orthodox saint, with anxious, piercing eyes, ornate background, amalgamation of leaves and flowers, by Hsiao-Ron Cheng, James jean, Miho Hirano, Hayao Miyazaki, extremely moody lighting, 8K"
    },
    {
        "display": "Art Nouveau Red Panda",
        "full": "beautiful detailed picture of a red panda, radiant light, art nouveau, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Raypunk fashion portrait",
        "full": "olivia wearing a red raypunk outfit, half body portrait, 3 d animation, black hair, freckles, pale skin, photo by greg rutkowski, risque fashion, female beauty, intricate detail, elegance, sharp shapes, soft lighting, masterpiece"
    },
    {
        "display": "Steampunk captain portrait",
        "full": "portrait of a beautiful steampunk captain, by guweiz and wlop and artgerm"
    },
    {
        "display": "Cosmic graphic novel scene",
        "full": "deep space cosmos,twinkling distant stars, graphic novel style by Tomokazu Matsuyama,trending on artstation"
    },
    {
        "display": "Vibrant sunset cityscape painting.",
        "full": "an amazing beautiful detailed painting of a sunset over a cityscape. the colors are very vibrant and the view is stunning. vibrant colors, very funny, personal, positive, visually pleasing, engaging and contains humans. high resolution. high quality. hq hd. trending on artstation."
    },
    {
        "display": "Fantasy scout portrait",
        "full": "portrait of a young, ruggedly handsome scout, soft hair, muscular, half body, leather, hairy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Orange space station scene",
        "full": "Orange amp space station, 3d scene, kim jung gi style, Greg Rutkowski, Zabrocki, Karlkka, Jayison Devadas, trending on Artstation, 8K, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Reptilian Jesus in action.",
        "full": "fullbody!! entire body, dynamic action pose, jesus as a scaly cold blooded reptilian lizard, intricate, humorous, holy cross, religious, absurd, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rugged young male ranger portrait",
        "full": "portrait of rugged young male ranger muscular fantasy intricate elegant headshot portrait detailed face coherent face highly detailed digital painting artstation concept art smooth sharp focus illustration art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cool dinosaur in neon.",
        "full": "an extremely cool dinosaur in dramatic neon lighting trending on artstation 4 k leica"
    },
    {
        "display": "Unique female Jedi portrait",
        "full": "one female jedi master, wearing the traditional jedi robe, beautiful and uniquely odd looking, detailed symmetrical close up portrait, intricate complexity, in the style of artgerm and ilya kuvshinov, magic the gathering, star wars art"
    },
    {
        "display": "Detailed Reinhardt Fantasy Artwork",
        "full": "Closeup of Reinhardt from Overwatch, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Androgynous robot portrait",
        "full": "angle profile portrait, hyperdetailed stunningly beautiful female androgynous robot made of iridescent metals and shiny gems, ethereal rainbow nimbus, golden necklace, noble perfect androgynous skeleton inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, highly detailed, photorealistic, octane render, 8 k, huang guangjian, cgsociety"
    },
    {
        "display": "Futuristic building in thermal vision",
        "full": "building by richard rogers, tron gem infrared myst water fisheye vice city futuristic thermal vision alien liberty city, archdaily, wallpaper, highly detailed, trending on artstation."
    },
    {
        "display": "Starry night sky vista",
        "full": "the edge of the world has a view of our starry night sky, High fantasy, Cinematic Matte Painting, Insanely Detailed, Award Winning, Trending on Artstation, 8k, UHD"
    },
    {
        "display": "Goddess portrait with bioluminescent elements.",
        "full": "goddess macro shouler portrait from bottom to top in crown made of ram skull. betta fish, jellyfish phoenix, bioluminiscent, plasma, ice, water, wind, creature, super intricate ornaments artwork by tooth wu and wlop and anato finnstark and greg rutkowski"
    },
    {
        "display": "Anime-style Nazi maid portrait.",
        "full": "propaganda rough character sketch ln illustration concept art lotr anime key visual portrait | young girl nazi maid | long flowing blonde hair blue eyes | symmetrical perfect face fine detail delicate features quiet gaze | fitted black military uniform | gapmoe kuudere moody lighting bokeh | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Steampunk witch portrait",
        "full": "a baroque neoclassicist close - up portrait of a steampunk witch, head and shoulders, large marble architecture in background. deep green and purple. renaissance portrait painting. highly detailed science fiction painting by norman rockwell, frank frazetta, and syd mead. rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation"
    },
    {
        "display": "Badger playing guitar",
        "full": "badger touch guitar , digital Art, Greg rutkowski, Trending cinematographic artstation"
    },
    {
        "display": "Ricky Gervais Wizard Portrait",
        "full": "closeup portrait of a ricky gervais as a wizard, harry potter, dramatic light, gorgeous view, depth, high detail, digital art, painted by greg rutkowski and seb mckinnon, by jk rowling, trending on artstation"
    },
    {
        "display": "Aztec jaguar warrior illustration",
        "full": "a highly detailed illustration of fierce aztec jaguar warrior wearing white jaguar mane, heroic roaring wielding aztec sword pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop"
    },
    {
        "display": "Goddess of Space Illustration",
        "full": "beautiful goddess of space and dreams by maxfield parrish and artgerm, mandala, coherent design, perfect symmetry, vivid colors, digital watercolor ink illustration painting, complementary color, golden ratio, detailed, sharp lines, sharp focus, intricate, rainbowshift, gustave dore, alphonse mucha, octane render"
    },
    {
        "display": "Female warlock battling monster.",
        "full": "female warlock long hood cloak purple, fighting monster with magic, 8 k, trending on artstation by tooth wu"
    },
    {
        "display": "Fantasy Quetzalcoatl Shaman Illustration",
        "full": ":  Quetzalcoatl shamen fantasy, fantasy magic,  , intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, jahbu art and Paul lewin and kehinde wiley, masterpiece"
    },
    {
        "display": "Techno wizard astronaut portrait",
        "full": "portrait of a techno wizard astronaut, fantasy, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, epic cosmic background, art by artgerm, alphonse mucha, epic cosmic background, erak note, eddie mendoza, christophe young, craig mullins, evan lee, silvain sarrailh, dao trong le, kan liu, character design, trending on artstation"
    },
    {
        "display": "Magical crystal cave lake",
        "full": "inside a magical cave full of intricate crystal with rainbow colors, there was a lake, filled by the light of a beautiful silver moon, and over it was the sky, dynamic lighting, cinematic lighting, lit by moonlight, by makoto makoto, krenz cushart and ilya kuvshinov and artgerm, unreal engine, featured on artstation, ultrawide angle"
    },
    {
        "display": "Anime boy playing guitar.",
        "full": "anime Boy touch guitar , digital Art, Greg rutkowski, Trending cinematographic artstation"
    },
    {
        "display": "Cyborg reading ancient tome",
        "full": "a cyborg reading from an old book with a leather cover, fantasy, sci-fi, Warhammer 40k, intricate, elegant, dramatic lighting, highly detailed, lifelike, photorealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by John Blanche and Paul Dainton and Albert Aublet and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Futuristic fantasy artwork.",
        "full": "fame of thrones, lord of daggers, neon, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Psychedelic angel illustration",
        "full": "tron angel, innocent illuminated face, psychedelic lsd, diffuse lighting, hyper realistic, elegant, intricate, hyper detailed, smooth, sharp focus, concept art, illustration, trending on artstation, art by john collier, artem demura, greg rutkowski, james gurney, and alphonse mucha"
    },
    {
        "display": "Joyful rugged pirate portrait",
        "full": "portrait of a young ruggedly handsome but joyful pirate, male, masculine, upper body, red hair, long hair, d & d, fantasy, playful smirk, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Old man Steve Rogers portrait",
        "full": "old man steve rogers, highly detailed, digital painting, artstation, facing camera, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, high definition digital art, dramatic lighting, in the style of ilya kuvshinov and Ross tran"
    },
    {
        "display": "Symmetrical witchblade fanart.",
        "full": "symmetry!! intense fanart of 3 / 4 back pose of witchblade as acotar protagonist, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by by hajime sorayama and boris vallejo"
    },
    {
        "display": "Skeleton warriors army illustration",
        "full": "A dungeons and dragons monster manual sketch of an army of skeleton warriors, concept art, matte painting, 8k, highly detailed, artstation"
    },
    {
        "display": "Retrofuturistic female android.",
        "full": "retrofuturistic female android tied to a chair, steampunk, gears, detailed mechanical parts, painting by artgerm julie bell Ed Brinkley"
    },
    {
        "display": "Medieval castle with aurora.",
        "full": "a detailed profile oil painting of a lone medevil castle on a mountain, lightning, aurora lighting clouds and stars by beksinski carl spitzweg and tuomas korpi. baroque elements, full - length view. baroque element. intricate artwork by caravaggio. trending on artstation. 8 k"
    },
    {
        "display": "Cloud Strife portrait art",
        "full": "cloud strife portriat artgerm and greg rutkowski and luis royo"
    },
    {
        "display": "Bearded male sorcerer casting lightning.",
        "full": "a handsome brown hair bearded male sorcerer, he is casting a lighting spell, full body, light rays, bloom, epic pose, epic composition, dramatic lighting, concept art, award winning, trending on artstation"
    },
    {
        "display": "3D Funko Pop German",
        "full": "full body 3d render of a german as a funko pop, studio lighting, white background, blender, trending on artstation, 8k, highly detailed"
    },
    {
        "display": "Retro sci-fi valley masterpiece",
        "full": "cinematic view of a retro scifi valley with temple, desaturated, psychedelic, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Interloper gifting on palm beach.",
        "full": "interloper gifting units ( or cash ) to newcomer on a palmbeach planet in no man's sky colorful, fantasy, intricate, highly detailed, digital painting, hq, trending on artstation, illustration, style of stanley artgerm and greg rutkowski and dan mumford"
    },
    {
        "display": "High-tech armored robot portrait.",
        "full": "a portrait of a concept robot with high tech armor, high detailed greg rutkowski painting, by adrian ghenie and gerhard richter. art by james gurney. masterpiece, deep colours."
    },
    {
        "display": "Iridescent sci-fi warrior.",
        "full": "character concept of iridescent sinewy smooth muscular male sleek glossy indigo black pearlescent onyx scifi armor with smooth black featureless helmet, by greg rutkowski, mark brookes, jim burns, tom bagshaw, magali villeneuve, trending on artstation"
    },
    {
        "display": "Elven female comic cover.",
        "full": "artgerm, joshua middleton comic cover art, full body pretty female elven wood elf, symmetrical eyes, symmetrical face, long curly black hair, beautiful forest, rim lighting"
    },
    {
        "display": "Ghostly figure in woods",
        "full": "in the style of junji ito, artgerm, shinsui ito, transparent ghost screaming, in the woods, moody lighting"
    },
    {
        "display": "Olivia Dudley as Slowpoke",
        "full": "Olivia Taylor Dudley as Slowpoke, detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render, cinematic lighting, dramatic lighting, masterpiece"
    },
    {
        "display": "Steampunk Shiva 3D Model",
        "full": "steampunk cybernetic biomechanical hindu god mahadev shiva, symmetrical, front facing, 3 d model, very coherent symmetrical artwork, unreal engine realistic render, 8 k, micro detail, gold and steel intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, wlop"
    },
    {
        "display": "Ultra-realistic shogun assassin.",
        "full": "a shogun assassin, ultra realistic, hyper detailed, cinematic, action pose, digital art, trending on artstation,"
    },
    {
        "display": "Neon cyberpunk cityscape",
        "full": "Neon cyber city, bright, tall buildings, night time, digital painting, concept art, sharp focus, pixiv, artstation, 4k, by Robert Del Naja and Chris Cunningham and Andrew Jones"
    },
    {
        "display": "Vibrant misty Messi artwork",
        "full": "lionel messi by , vibrant, cold, misty and wet, 50mm lens, lineart behance hd, dramatic lighting, global illumination, trending on Artstation, bloom"
    },
    {
        "display": "Cat riding Labrador",
        "full": "tiny cat riding a labrador, cat on top of dog, concept art, artstation, trending on Instagram, Twitter, perfect face"
    },
    {
        "display": "Symmetrical portrait of Lilith.",
        "full": "a symmetrical portrait of a beautiful menacing lilith, concept art by pete mohrbacher and wlop and artgerm, digital art, unreal engine 5, trending on artstation, deviantart, pinterest, rule of thirds, 4 k uhd image"
    },
    {
        "display": "3D Kratos Funko Pop",
        "full": "full body 3d render of Kratos from god of war as a funko pop, studio lighting, white background, blender, trending on artstation, 8k, highly detailed"
    },
    {
        "display": "Kobolds digging tunnel artwork",
        "full": "D&D, a group of kobolds digging in a tunnel by torchlight, artwork by Artgerm, Don Bluth"
    },
    {
        "display": "Joyful pirate portrait",
        "full": "portrait of a young ruggedly handsome but joyful pirate, male, masculine, nose ring, upper body, red hair, long hair, d & d, fantasy, joyful smirk, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fearful face, mechanical creatures.",
        "full": "death is swallowed up in victory, very detailed and beautiful face, screaming with fear, giant mechanical worm, giant mechanical bird, artwork by artgerm, centered shot, wide angle, full body, islandpunk, solarpunk, fantasy, highly detailed, digital painting, artstation, smooth, sharp focus, art by thomas kinkade and h. r. giger and yusei uesugi"
    },
    {
        "display": "Fantasy character design painting",
        "full": "non creative character design, a fantasy digital painting by Greg Rutkowski and James Gurney, trending on Artstation, highly detailed, hyperrealistic, realistic, photorealistic, dynamic lighting, highly detailed, cinematic landscape, studio landscape, studio lighting"
    },
    {
        "display": "Classy 1920s fox party-goer",
        "full": "fox anthro as classy 1920s party-goer, dimly lit upscale 1920s speakeasy, relaxed pose, art deco, detailed painterly digital art style by Osamu Tezuka, retro vibe, furaffinity, \ud83c\udf78, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, intricate, epic composition, grim yet sparkling atmosphere, cinematic lighting + masterpiece, trending on artstation, very detailed, vibrant colors, Art Nouveau, masterpiece, romanticism"
    },
    {
        "display": "Hyperrealistic modern woman pinup.",
        "full": "modern woman | hyperrealistic | rubenesque | action pose | digital painting | trending on artstation | pinup portrait | clean | illustration | dressed | unreal engine 5 | 8 k resolution | by greg rutkowski alphonse mucha gustav klimt and mel ramos"
    },
    {
        "display": "Rugged Odin Portrait",
        "full": "painted portrait of rugged odin, god of war, norse god, white hair, masculine, mature, old man, warrior, handsome, upper body, grey and silver, muscular, hairy torso, fantasy, intricate, muscular, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by gaston bussiere and alphonse mucha"
    },
    {
        "display": "Glamorous Tifa portrait.",
        "full": "glamorous Tifa lockheart portrait, bra, seductive eyes and face, elegant, lascivious pose, very detailed face, highly detailed kimono, some dust in the air, ancient Japanese temple on the background, photorealism, portrait by Magali Villeneuve and Steve Argyle,Livia Prima,Mucha,dress,fantasy art,beautiful,artstation,trending on artstation,intricate details,alluring,masterpiece"
    },
    {
        "display": "Manmohan Singh Aquaman portrait",
        "full": "Manmohan Singh as Aquaman, Aquaman costume, Manmohan Singh blue turban, Aquaman body type, Manmohan Singh Face, calm, grumpy, portrait, masculine figure, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy fungal town artwork",
        "full": "concept art detailed painting of a dark purple fantasy fairytale fungal town made of mushrooms, with glowing blue lights, in the style of jordan grimmer and neil blevins and wayne barlowe"
    },
    {
        "display": "Chicken assassins in Hong Kong",
        "full": "concept art of chicken dressed asian assassins gang in action killing people in the crowded streets of hong kong, chicken dressed men, highly detailed painting by dustin nguyen, akihiko yoshida, greg tocchini, greg rutkowski, cliff chiang, 4 k resolution, trending on artstation, 8 k, man dressed as a chicken"
    },
    {
        "display": "African space pirate illustration.",
        "full": "african space pirate, science fiction, highly detailed, digital painting, beautiful eyes, symmetry, concept art, sharp focus, illustration, global illumination, radiant light, detailed and intricate environment, art by artgerm and greg rutkowski and magali villeneuve and ilya kuvshinov!"
    },
    {
        "display": "Chonky Athena Portrait",
        "full": "soft light portrait of the beautiful chonky greek goddess, athena, wearing a corinthian style helmet, and her owl. wrapped in swirls in the air, soft light, digital art by ruan jia and mandy jurgens and artgerm and william - adolphe bouguereau, by jean - leon gerome, highly detailed, trending on artstation, award winning, 8 k resolution"
    },
    {
        "display": "Sci-fi monk character sheet",
        "full": "character sheet for a scifi monk with robotic helmet, intricate digital painting artstation concept art smooth sharp focus illustration, art by artgerm and paul chadeisson and greg rutkowski and alphonse mucha, octane, 3 d render"
    },
    {
        "display": "Detailed portrait of Steve Buscemi",
        "full": "steve buschemi, fargo, portrait, intricate, detailed, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Sunlit plant on windowsill.",
        "full": "a plant on a window sill, sunbeams, natural lighting. 8 k, octane render, full shot. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Fantasy farm concept art",
        "full": "concept art of a fantasy farm, wlop style"
    },
    {
        "display": "Demonic Katy Perry Cryptid",
        "full": "Very very very very highly detailed epic photo of an ancient cryptid Katy Perry in demonic world, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Artgerm and Vincent di Fate"
    },
    {
        "display": "Symmetrical floral portrait",
        "full": "symmetry!! portrait of floral! hitomi kisugi psycho, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Valkyrie in datacentre.",
        "full": "very beautiful Valkyrie walking in a datacentre surrounded by  servers racks  , dreamscape,  highly detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration"
    },
    {
        "display": "Futuristic dystopian street scene.",
        "full": "beautiful 3 d illustration about a futuristic, dystopian street with droids and robots at sunset. lot of neons. trending on artstation"
    },
    {
        "display": "Detailed black and white android.",
        "full": "black and white side portrait ultra detailed, beautiful female android, deity, sharp focus, highly detailed global illumination, concept art. 8 k"
    },
    {
        "display": "Cyberpunk city view.",
        "full": "young woman wearing a stylish black minidress looking through a penthouse window at a panoramic view of a cyberpunk city at night, bokeh lights, anime, ilya kuvshinov, guweiz, artstation trending, concept art, digital painting, cinematic, extreme detail, expansive"
    },
    {
        "display": "Foggy spring cityscape.",
        "full": "magnificent city in late spring, flowers will fade, some fog, realistic style, high details, scene concept. digital art, trending on artstation"
    },
    {
        "display": "Futuristic spaceship docking bay.",
        "full": "a photo of a detailed science-fiction freight wide open architectural multi-level spaceship docking bay with balconies overlooking a nature freight yard overtaken by nature, with people working, looking out into space, 4k, unreal engine, concept art, matte painting, cosmic horror, nightmare,"
    },
    {
        "display": "Megan Fox as nun.",
        "full": "portrait of megan fox as a nun with satanic face tattoos, catholic, church, bible, christian, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Cute small red beast",
        "full": "Cute, fluffy, small red beast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and donato giancola and Joseph Christian Leyendecker, Ross Tran, WLOP"
    },
    {
        "display": "Transhumanist AI concept art.",
        "full": "the most amazing dream you ever had about singularity overlord transhumanism artificial intelligence, mass effect, dark souls, hyper realistic, concept art, high key lighting, intricate, hyper detailed, smooth, high contrast, neon, volumetric lighting, octane, raytrace, greg rutkowski, huifeng huang, artgerm, beeple, jim lee,"
    },
    {
        "display": "Divine Chihuahua Emperor",
        "full": "God with a Chihuahua's head, a radiant halo and wings, detailed face, gorgeous, flowing hair, very muscular male body, partial anatomy, stormy and grand war scene, delicate and intricate borders for decoration, caesar victorious, proud Emperor, split lighting, character close-up, intricate, highly detailed, 8K, digital painting, fantasy, concept art, sharp focus, art by greg rutkowski beeple and alphonse mucha"
    },
    {
        "display": "Symmetrical Star Wars portrait.",
        "full": "symmetrical - photo!! low - angle!! painting of christina - hendricks!!! in star wars, full frame photograph, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Lady Gaga Concert Painting",
        "full": "oil canvas of lady gaga in concert, intricate, elegant, in the syle of toulouse - lautrec, trending on artstation"
    },
    {
        "display": "Crouching young man at night",
        "full": "handsome young man with short black hair, glowing light blue eyes, pale skin, crouching on the roof of a house, in a neighbourhood, at night, wearing jeans and a black hoodie, realistic painting by ross tran and gerald brom and alphonse mucha, ilya kuvshinov, svetlana tigai, artgerm, trending on artstation"
    },
    {
        "display": "Anime girl in snowy setting.",
        "full": "a very beautiful anime girl, full body, long braided curly silver hair, sky blue eyes, full round face, short smile, casual clothes, ice snowy lake setting, cinematic lightning, medium shot, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan"
    },
    {
        "display": "Bioluminescent creature portrait",
        "full": "one singular portrait of a cute bioluminescent creature with big glowing eyes, highly detailed, digital painting, cinematic, hyper realism, dark retrowave, art by studio ghibli and artgerm and magali villeneuve and Alphonse Mucha, artstation, octane render, cgsociety"
    },
    {
        "display": "Nina Dobrev artwork collaboration.",
        "full": "nina dobrev, trending on artstation by artgerm, stephen bliss, greg rutkowski, loish, rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws."
    },
    {
        "display": "Fiery crashed battle spaceship",
        "full": "a beautiful battle spaceship sticking in the ground, the spaceship is on fire, smoke, crash, accident, lightning, angry, kinetic, john sargent, adolphe bouguereaum, peter deligdisch, jama jurabaev, sachin teng, sergey kolesov, ruan jia, trending on artstation, highly detailed oil painting,"
    },
    {
        "display": "Heroic fantasy character portrait",
        "full": "heroic urban fantasy character study, realistic sharp details, magical realism, full body portrait, costume concept art, by jordan grimmer and greg rutkowski and larry elmore"
    },
    {
        "display": "Detailed goddess of nature",
        "full": "goddess of nature, accurate anatomy, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha and IFBB pro fitness photograph"
    },
    {
        "display": "Teen girl portrait, cloak.",
        "full": "a portrait of a 16 year old girl with pale skin and short dark hair wearing a cloak made of strips of grey fabric, By Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Elegant Evil Necromancer Illustration",
        "full": "Beautiful Evil Necromancer Sorceress, fantasy magic, dramatic lighting, golden hour, close to night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by Artgerm and Greg Rutkowski, Magic The Gathering"
    },
    {
        "display": "Elegant fat ginger cat",
        "full": "a fat ginger cat, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Admiral cat matte painting",
        "full": "A highly detailed matte painting of a British Shorthair cat looking into the distance dressed as an admiral, by Studio Ghibli, Makoto Shinkai, by Artgerm, by WLOP, by Greg Rutkowski, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Futuristic guitar-nano fusion.",
        "full": "electric guitar mechanical architecture mix of organical elements combined with nano machines, ith worlds inside floating about the space, dimly lit with candles, gold mirrors, hdr 25, concept art, 3d render texture master, hd, trending on artstation, 8k"
    },
    {
        "display": "Kurdish Samurai Fantasy Portrait",
        "full": "Highly detailed portrait of Kurdish samurai, Stephen Bliss, unreal engine, fantasy art by Greg Rutkowski, Loish, Rhads, ferdinand knab, Makoto Shinkai and Lois van baarle, ilya kuvshinov, rossdraws, Tom Bagshaw, alphonse mucha, global illumination, radiant light, detailed and intricate environment, highly detailed, award winning art"
    },
    {
        "display": "3D African American character",
        "full": "African American man with dreadlocks, final fantasy advent children style, 3d art, octane render, high definition , artstation, riot games, concept art, maya, blender"
    },
    {
        "display": "Mysterious raven in flight.",
        "full": "illustration of mysterious raven flying, planet earth as background, d & d, rule of thirds, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by dragolisco"
    },
    {
        "display": "Winnie the Pooh underwater",
        "full": "the beautiful cartoon animation render a single lovely winnie the pooh wearing paper diapers, pop art, hyper detailed, underwater world, in the style of makoto shinkai, raphael lacoste louis comfort tiffany, artgerm, karol bak, james jean, ross tran, 8 k hd, fine texture structure, 3 drender"
    },
    {
        "display": "Surreal urban street scene",
        "full": "realistic detailed image of an john zorn crossing a busy street by Francis Bacon, Surreal, Norman Rockwell and James Jean, Greg Hildebrandt, and Mark Brooks, triadic color scheme, By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Edward Hopper and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Holographic alien artifacts display.",
        "full": "Ladies in holograms of alien artifacts, electrical case display, total recall tech, , ultrarealistic, dramatic lighting, electrical details, high details, 4k, 8k, best, accurate, trending on artstation, artstation, photorealism, ultrarealistic, digital painting, style of Peter Mohrbacher, Caravaggio, Boris Vallejo"
    },
    {
        "display": "Gothic princess knight illustration",
        "full": "Alchemy Imperial Princess knight gothic girl, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry, amazing composition, gothic arch frame"
    },
    {
        "display": "Fantasy portrait of John Oliver and Adam Driver.",
        "full": "a portrait of john oliver standing next to adam driver, stoic, military uniform, fantasy, intricate, elegant, beautiful, highly detailed, charcoal, centered, dark, smokey, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "James Dean as 1950s Rebel",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork costume design: young James Dean as a well-kept neat anarchist rebel in 1950s USSR mechanic outfit. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, cinematic colors, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Cute cat sleeping in sunset.",
        "full": "a singular cute cat sleeping on the grass in a forest near a small river at sunset, godrays, complementary colors, warm lighting, raytracing, highly detailed, high quality, 4k HDR, concept art, octane render, unreal engine 5, high coherence, calm, relaxing, beautiful landscape, serene, anatomically correct, close photo"
    },
    {
        "display": "Gordon Freeman in Cyberpunk",
        "full": "gordon freeman in cyberpunk 2 0 7 7, medium shot, background is filled with neon lights and futuristic vehicles, trending on artstation, ultra realistic, 4 k"
    },
    {
        "display": "Twin Peaks portrait artwork",
        "full": "twin peaks poster artwork by michael whelan and tomer hanuka, karol bak, rendering of close up portrait of timothee chalamet, from scene from twin peaks, full of details, by makoto shinkai and thomas kinkade, matte painting, trending on artstation and unreal engine"
    },
    {
        "display": "Cyborg paramedic gorilla",
        "full": "!dream portrait of medical paramedic gorilla robot, cyborg, hi concept sci fi, wires,gadgets,cables,lenses,gears,led displays,UI intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by  john singer sargent,"
    },
    {
        "display": "Moon Knight Overwatch Portrait",
        "full": "greg manchess portrait painting of moon knight as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Elegant zombie mummy illustration",
        "full": "sansa saoirse ronan mummy zombie, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Valkyrie in data center",
        "full": "very beautiful Valkyrie walking in a datacentre surrounded by  servers racks  , dreamscape,  highly detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration"
    },
    {
        "display": "Candlelit room, fiery dress.",
        "full": "a room is lit by dozens of flickering candles, and the air is thick with incense. highly detailed, digital photo, HDRI, by christopher bretz and kael ngu, vivid colors, high contrast, 8k resolution, intricate, photorealistic, smooth advanced digital anime art, a very cute beautiful and gorgeous woman wearing a dress made of fire and water , full body, very long wavy azure blue hair, braided hair, white highlights in hair, azure blue watery eyes, full round face, cinematic lighting, mid-shot, highly intricately detailed, trending on pixiv, Artstation, Totorrl, Visual Key, and Sakimichan-A ddim"
    },
    {
        "display": "Surreal galactic moth painting",
        "full": "galactic moth, acrylic painting, evocative surrealist art, trending on artstation"
    },
    {
        "display": "Hyperrealistic machine-human fusion",
        "full": "hyperrealistic photography of a machine entering a female host in the style of jin kagetsu, james jean and wlop, highly detailed, sharp focus, intricate concept art, digital painting, ambient lighting, 4 k, artstation"
    },
    {
        "display": "Symmetrical cyberpunk Darth Vader",
        "full": "symmetrical - face!! portrait shot of white darth vader wearing a cowboy hat in cyberpunk 2 0 7 7, realistic, professionally color graded, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Detective bear in TARDIS.",
        "full": "anthropomorphic art of a detective bear in tardis, victorian inspired clothing by artgerm, victo ngai, ryohei hase, artstation. fractal papersand books. highly detailed digital painting, smooth, global illumination, fantasy art by greg rutkowsky, karl spitzweg"
    },
    {
        "display": "Mikasa Ackerman portrait, fantasy.",
        "full": "highly detailed portrait of mikasa ackerman from attack on titan, fantasy art, by hayao miyazaki, studio ghibli, photorealistic, detailed and intricate environment, trending on pixiv, twitter"
    },
    {
        "display": "Detailed elven princess illustration",
        "full": "beautiful elven princess, accurate anatomy, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by Ross Tran and Michael Whelan and greg rutkowski and John Singer Sargent"
    },
    {
        "display": "Lifelike marble bust of Roy Chubby Brown.",
        "full": "amazing lifelike award winning marble bust of Roy chubby brown trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Attractive goddess portrait.",
        "full": "half Jessica Alba half Nathalie Portman half scarlett johansonna pure attractive smiling godess, full body portrait, slight smile, zen attitude, self confidence, diffuse natural sun lights, autumn lights, highly detailed body, digital painting, artstation, concept art, sharp focus, illustration, art by wlop and greg rutkowski and alphonse mucha and artgerm"
    },
    {
        "display": "Canyon cityscape at night",
        "full": "aerial view of a bustling city built inside a canyon, hanging bridges spanning over the chasm, waterfalls, with light coming out of the ground of it, pitchblack sky, extremly detailed digital painting, in the style of andreas rocha and noah bradley and tyler edlin and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Hybrid owl-lion painting",
        "full": "painting of hybrid between owl!!!!!!! & ( ( ( ( ( lion ) ) ) ) ), intercrossed animal, mixture animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, by tiffany bozic, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Futuristic ice mine landscape.",
        "full": "industrial futuristic ice mine, with spaceships in the sky, cinematic, epic, 4 k, concept art by feng zhu"
    },
    {
        "display": "Cute ork wizard illustration.",
        "full": "a cute and adorable little ork wizard, hearthstone, concept illustartion, character art,"
    },
    {
        "display": "Catwoman in fantasy art",
        "full": "aeon flux as cat woman picture by Greg Rutkowski, dynamic pose, matte painting, intricate, fantasy concept art, elegant, by Stanley Artgerm Lau, WLOP, golden ratio, thomas kindkade, alphonse mucha, loish, Peter chung, norman Rockwell,"
    },
    {
        "display": "Ancient book in space",
        "full": "front facing shot of an ancient book on a vintage table in space, concept art, sci - fi illustration, painting, realistic,, radiant light, detailed and intricate environment, 8 k, h 6 4 0"
    },
    {
        "display": "Megumin Portrait, Matte Painting",
        "full": "portrait of megumin from konosuba, matte painting by artgerm, artstation"
    },
    {
        "display": "Kurdish samurai charcoal sketch",
        "full": "Kurdish samurai, detailed charcoal sketch, realistic, incredibly detailed, award winning art, cinematic, extremely high detail, concept art, 4k fantasy art, trending on artstation, full body shot"
    },
    {
        "display": "Fantasy MMO concept art",
        "full": "fantasy MMO, concept art, digital art, 4k"
    },
    {
        "display": "Cinematic crow in cemetery",
        "full": "crow on cementary, beautiful dynamic lighting, cinematic, wide angle establishing shot, extremely high detail, photo realistic, cinematic lighting, post processed, concept art, artstation, matte painting, style by frederic church, raphael lacoste, unreal engine 8k"
    },
    {
        "display": "Disney-style yeti concept art",
        "full": "concept art for the yeti, a white snow primate, in style of disney animation, expressive face, detailed face, detailed eyes, full body, feminine face, tracer overwatch, disney, pixar"
    },
    {
        "display": "Photorealistic Simon Cowell with noodles.",
        "full": "photorealistic simon cowell has noddle arms. hyperdetailed photorealism, 1 0 8 megapixels, amazing depth, high resolution, 3 d shading, 3 d finalrender, 3 d cinematic lighting, glowing rich colors, psychedelic overtones, artstation concept art."
    },
    {
        "display": "Vibrant fantasy forest scene",
        "full": "among trees, digital art, concept art, magic fantasy, scenic, vibrant colors, high contrast, highly detailed, trending on artstation, 8k, andreas rocha, sylvain sarrailh, darek zabrocki, finnian macmanus, dylan cole, liang mark, albert bierstadt, sung choi, peter mohrbacher, greg rutkowski, studio ghibli"
    },
    {
        "display": "Neon Cyberpunk Sheriff Scene",
        "full": "sheriff and a girl, street deal, cheap contiousness, neon, alterd carbon, mech suit, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy angelic portrait",
        "full": "anglic lady, flaming wings!!!, fantasy, bioluminiscence, flowing hair, portrait, highly detailed, digital painting, beautiful eyes!!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop!!!! ilya kuvshinov!! stained glass"
    },
    {
        "display": "Fluffy marshmallow corgi couch.",
        "full": "A fat corgi made of marshmallows and chocolate, sitting on a couch made of chocolates and marshmallows, rendered by Lydia Borutta, trending on artstation, 4k, 8k"
    },
    {
        "display": "Elegant portrait of Irina Shayk",
        "full": "beautiful portrait of Irina Shayk wearing fantastic Hand-dyed cotton dress, embellished beaded feather decorative fringe knots ,colorful pigtail,subtropical flowers and plants,symmetrical face,intricate,elegant, highly detailed, 8k,post-processing,digital painting, trending on pinterest, GUCCI,PRADA,concept art, sharp focus, illustration, by artgerm,Tom Bagshaw,Lawrence Alma-Tadema,greg rutkowski,alphonse Mucha"
    },
    {
        "display": "Oil portrait of Khan",
        "full": "oil portrait of ricardo montalban as a khan noonien singh. smiling, bare chested. star trek ii : the wrath of khan. oil painting, highly detailed, centered, artstation, concept art, smooth, sharp focus, illustration, artgerm, vermeer, hans peter mohrbacher, donato giancola, joseph christian leyendecker, drew struzan"
    },
    {
        "display": "Strong blonde superhero portrait",
        "full": "a beautiful portrait of a beautiful strong cute young superhero woman, shoulder - length blonde hair, tight solid matte navy blue lycra bodysuit, white cape, body, muscles, intricate, elegant, 8 k, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, by artgerm greg rutkowski alphonse mucha loish wlop"
    },
    {
        "display": "Minimalist crocodile painting",
        "full": "crocodile minimal symmetric painting by afshar petros, matte background, sharp contours, minimalist, trending on artstation"
    },
    {
        "display": "Elegant fantasy portrait of Hotaru.",
        "full": "Hotaru from the TV series Sailor Moom, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Cute character illustration",
        "full": "maliha, by artgerm, soft light, cute"
    },
    {
        "display": "Neekolul digital painting",
        "full": "Neekolul, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dramatic Dio Brando silhouette.",
        "full": "Dio Brando posing dramatically with a full moon behind him, concept art, trending on artstation HD"
    },
    {
        "display": "Smaug flying over Lake Town",
        "full": "the dragon smaug flying over lake town from the hobbit highly detailed, digital painting, concept art, sharp focus, by makoto shinkai"
    },
    {
        "display": "Male meditator in galaxy.",
        "full": "closeup portrait shot of a male meditation in cosmic galaxy in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Cute glowing yellow pet",
        "full": "a super cute glowing pet, yellow theme, bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, blizzard warcraft artwork, hearthstone card game artwork, cute pet"
    },
    {
        "display": "Hyperrealistic Nixon Soulscape",
        "full": "the place where even richard nixon has soul, hyperrealistic, photorealistic, digital illustration, trending on artstation, cinematic lighting, 4 k, ultra hd, beautiful colors"
    },
    {
        "display": "Fairy-tale princess in garden.",
        "full": "beautiful blonde woman in the image of a fairy - tale princess in the garden with a wreath in her hands, deep focus, d & d, fantasy, complex, elegant, highly detailed, digital painting, artstation, concept art, matte, clear focus, illustration, hearthstone, works by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sunflower field painting.",
        "full": "a beautiful painting of the sea of sunflowers, girl, by greg rutkowski, trending on artstation"
    },
    {
        "display": "Joyful red-haired RPG character",
        "full": "beautiful cute red haired joyful and playful 1 9 year old maiden standing up in casual clothing, long hair, smirky face, modern city, rpg character, sci - fi, fantasy, intricate, elegant, digital painting, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Warrior Kate Moss in armor",
        "full": "kate moss, warrior, partially clothed in metal battle armor, lord of the rings, tattoos, decorative ornaments, by carl spitzweg, ismail inceoglu, vdragan bibin, hans thoma, greg rutkowski, alexandros pyromallis, perfect face, fine details, realistic shading, photorealism"
    },
    {
        "display": "Cute Japanese girl illustration",
        "full": "a highly detailed illustration of short hair cute japanese girl wearing blood stained hoodie and bandages on arms, dramatic sadistic smile pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, WLOP"
    },
    {
        "display": "Dreamy neon portrait",
        "full": "half body portrait, jessica alba scarlet johansson model in a dream neon reflecting suit, detailed, centered, intricate date painting background, digital painting, artstation, concept art, donato giancola, surrealistic, intricare face and body, joseph christian leyendecker, wlop, boris vallejo, moebius, breathtaking, 8 k resolution, extremely detailed, beautiful, hyperrealistic, beautiful face, octane render, cinematic lighting, dramatic lighting, masterpiece"
    },
    {
        "display": "Taylor Swift as Lola Bunny",
        "full": "Taylor Swift Cosplaying Lola Bunny, modeling, posing, two piece workout clothes, training bra, quality lighting, vibrant colors, maximalism, face and body details, Tooth Wu Artgerm WLOP artstation deviantart, 8k, fanart, playboy style, very very aesthetic"
    },
    {
        "display": "Macho Man Pixar Portrait",
        "full": "disney pixar portrait 8 k photo of macho man randy savage wearing brightly colored sun - staches, cowboy hat, pants and jacket with tassles : : as wwf superstar by pixar : : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, annie leibovitz, rave, unreal engine, alphonse mucha : :"
    },
    {
        "display": "Crochet astronauts on yarn planet.",
        "full": "an expedition of crochet cute astronauts discovering a new fluffy planet made out of yarn. cute, illustration, digital art, inspired by little big planet, by greg rutkowski, detailed, sharp, masterpiece, highly detailed, photorealistic, octane render, 8 k, unreal engine 5, trending on artstation, vivid colors"
    },
    {
        "display": "Handsome brown-haired boy.",
        "full": "a handsome slender wavy brown haired boy with blue eyes. kingdom hearts concept art. Square enix. Tetsuya Nomura. By Shigenori Soejima."
    },
    {
        "display": "Futuristic redhead alien heroine.",
        "full": "Redhead Pleiadian alien human beautiful hybrid feminine woman, long gorgeous red hair in loose curls, with stunning green eyes, cute round face and a roundish nose, as a retrofuturistic heroine, face and body, gorgeous digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and donato giancola and Joseph Christian Leyendecker, Ross Tran, WLOP"
    },
    {
        "display": "Cottagecore Marilyn Monroe illustration.",
        "full": "beautiful cottagecore Marylin Monroe holding a rifle. intricate, elegant. highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration. . art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ancient village with futuristic elements",
        "full": "ultra realistic illustration and highly detailed digital render of a intricate busy street inside a ancient 1 5 th century stone village, levitating high tech boats drive in the sky by greg rutkowski and kinkade, nighttime, dark sky, twinkly stars, amazing sky, migrating birds in the sky, colorful street lamps along road, natural stone road, asian style vendors, elegant, fractal architecture, european architecture, 1 6 th century architecture, highly detailed, insanely detailed, sharp focus, vibrant and vivid, smooth, cinematic, high contrast, hdr, 4 k, trending on artstation, archviz, unreal engine, hyperrealistic, dof"
    },
    {
        "display": "Pregnant Scarlet Witch Portrait",
        "full": "pregnant scarlet witch in a red bikini, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gouache ocean scene.",
        "full": "sharp focus, breath taking beautiful, aesthetically pleasing, gouache ocean and boats, digital concept art background by hayao miyazaki and studio ghibli, fine art, ambient lighting, hdr, 4 k, 8 k, cinematic, high quality art, award winning, trending, featured, masterful, dynamic, energetic, lively, elegant, intricate, complex, highly detailed, richly textured, rich vivid color, masterpiece."
    },
    {
        "display": "Handsome paladin portrait",
        "full": "portrait of a ruggedly handsome paladin, soft hair, muscular, half body, leather, hairy, d & d, fantasy, intricate armor, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk adventurer in desert.",
        "full": "cinematic mid shot of an adventurer girl riding a steampunk motorcycle on in a desert with rocks, cactus, mountains, delicate features finely detailed perfect art, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Attractive young Thanos portrait",
        "full": "thanos as an attractive young smiling woman wearing a mushroom crown and heavy armoured wedding dress, face portrait, hd shot, digital portrait, beautiful, fantasy art, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Muscular fantasy warrior illustration",
        "full": "muscular female warrior, deep focus, d & d, fantasy, intricate, elegant bikini, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk portrait of Winona Ryder.",
        "full": "steampunk portrait of winona ryder, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Whirlwind of souls, digital art.",
        "full": "a whirlwind of souls rushing inside the metaversegorgeous, intricate, in the style of jin kagetsu, james jean and wlop, valentin serov style, highly detailed, sharp focus, intricate concept art, digital painting, ambient lighting, 4 k, hdt, artstation trending on gsociety, trending on artstationhq, hyper quality"
    },
    {
        "display": "Post-apocalyptic Bedouin rogue",
        "full": "a full body portrait of a beautiful post apocalyptic offworld police district bedouin blind pulp fiction scarlet wild rogue barbarian leper begging by the roadside, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Arcane character face study.",
        "full": "arcane character face study, character sheet, concept design, contrast, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Freddie Mercury Funko Pop",
        "full": "full body 3 d render of freddie mercury, white sleeveless tank top blue jeans as a funko pop!, four, studio lighting, white background, single body, no shadow, blender, trending on artstation, 8 k, highly detailed"
    },
    {
        "display": "Symmetrical Alice with Machine Parts",
        "full": "symmetry!! alice in wanderland, machine parts embedded into face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Albino supermodel portrait",
        "full": "portrait of a beautiful tall and feminine albino supermodel dressed in a abbaya, photograph, award wining, red and white, trending on artstation, punk attitude, 4k, unreal engine 5, octane render, neon highlights with red long wavy hair drawn by Donato Giancola and Tom Bagshaw and Julie Bell, face by Artgerm, overall design by Alphonse Mucha, background by James Jean and Gustav Klimt, 4k, porcelain skin, komorebi, french nouveau, trending on artstation, octane render, hyperrealistic"
    },
    {
        "display": "Young Asian woman in mecha",
        "full": "a young attractive Asian woman in the pilot's seat of a massive sci-fi mecha, both arms at her sides, dramatic pose, blue LEDs, highly detailed, photorealistic, volumetric lighting, digital art, octane render, in the style of Artgerm and Tom Bagshaw"
    },
    {
        "display": "Stylized prisoner illustration.",
        "full": "a slim stylized prisoner peeking through prison bars, elegant, digital painting, concept art, very stylized, smooth, sharp focus, illustration, dark, by Ruan Jia and Mandy Jurgens and Artgerm and William-Adolphe Bouguerea"
    },
    {
        "display": "Girl with wolf ears on throne.",
        "full": "full body picture of a girl with wolf ear sitting on the throne, bored, beautiful and aesthetic, intricate, unreal engine, messy hair, highly detailed, detailed face, smooth, sharp focus, chiaroscuro, manga illustration, artgerm, greg rutkowski, ilya kuvshinov, rossdraws, alphonse mucha, young adult light novel cover art"
    },
    {
        "display": "Detailed pirate portrait",
        "full": "close up of a pirate with scars and a face tattoo depicting neural networks and ai, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic general store interior",
        "full": "interior of a general store, painted by ashley wood and phil hale, blade runner, light pink color scheme, masterpiece, award - winning, sharp focus, intricate concept art, ambient lighting, 8 k, artstation, pixiv"
    },
    {
        "display": "Loving couple, detailed artwork.",
        "full": "two people in love by tim eitel, highly detailed art, trending on artstation"
    },
    {
        "display": "Elegant digital influencer portrait",
        "full": "a gourgeous digital influencer in the style of stefan kostic, realistic, full body, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Futuristic cyberpunk apartment.",
        "full": "inside of apartment in a Futuristic cyberpunk. Cinematic lighting. Art by moebius and Thomas Kinkade and Greg Rutkowski."
    },
    {
        "display": "Gloomy robotic repair scene",
        "full": "gloomy ruined server room in datacenter robot automata rusty steel robot knight colossus welder pacing fixing soldering mono sharp focus, emitting diodes, smoke, artillery, sparks, racks, system unit, motherboard, by pascal blanche rutkowski repin artstation hyperrealism painting concept art of detailed character design matte painting, 4 k resolution blade runner"
    },
    {
        "display": "Cybernetic vaporwave temple",
        "full": "a 3 d render of a cybernetic temple, vaporwave aesthetic, colorful, psychedelic, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic submachine gun render",
        "full": "futuristic submachine gun on a smooth white table, design concept art, minimalist, studio lighting, 3d render, octane render, photorealistic, highly detailed, intricate detail, trending on artstation"
    },
    {
        "display": "Demonic creature concept art",
        "full": "a triple a game concept art of a demonic creature in a suit holding an assaut rifle, art by cushart krentz and greg rutkowski, elegant, front view, sharp focus, morandi color scheme, fantasy style, illustration, high quality, digital art, hyperrealism, octane render, commission art"
    },
    {
        "display": "Elon Musk as Marty McFly.",
        "full": "elon musk as marty mcfly near cybertruck, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed fantasy djinn art",
        "full": "djinn, ultra detailed fantasy, elden ring, realistic, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Female Drow Necromancer Portrait",
        "full": "Character concept portrait art of a female Drow necromancer, scythe, pretty face, long dark hair, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, tranding on artstation"
    },
    {
        "display": "Aloy as Overwatch character.",
        "full": "greg manchess portrait painting of a aloy as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Cyborg girl portrait.",
        "full": "cyborg girl with fangs, purple and pink hair, realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Apocalyptic landscape artwork",
        "full": "end of the world, trending on Artstation"
    },
    {
        "display": "Bride in light dress",
        "full": "breathtaking detailed soft painting of a full body shot of a bride viewed from behind in a long dress of pure light, in front of a pristine art nouveau cathedral on a wedding day, a sunlit plaza covered in pastel flowers with golden petals and fireflies, rule of thirds, german romanticism, rembrandt style, elegant, volumetric lighting, highly detailed, artstation, concept art, matte, sharp focus, art by Tom Bagshaw, Alfons Mucha and Greg Rutkowski"
    },
    {
        "display": "Elegant princess portrait",
        "full": "beautiful female princess with a golden tiara on her head, portrait shot, long red hair, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Demon portrait in hellscape",
        "full": "portrait of a beautiful young fit male demon with ram horns, scaly torso and goat legs, hellish scene, by greg rutkowski and alphonse mucha, d & d character, gradient red to yellow, in front of an hellish landscape background, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp focus ilustration, artstation hq"
    },
    {
        "display": "Giant Buddha Mecha in Space",
        "full": "a giant buddha mecha gundam in the desert in space, equirectangular projection tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, trending on artstation, cinematic composition, beautiful lighting, hyper detailed, 8 k, oil on canvas"
    },
    {
        "display": "Dark ritual fantasy painting",
        "full": "painting of a dark ritual, enigmatic beauty, esoteric, muted colors, head in focus, fantasy art, ornamental aesthetics, intricate, elegant, highly detailed hyperrealistic painting, artstation, concept art, painterly, sharp focus, illustration, art by lois royoi"
    },
    {
        "display": "Girl in vibrant attire.",
        "full": "girl wearing a yellow jacket a green beanie, gray shirt, thick eyebrows, dark red lips, round eyeglasses, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic ivy-covered space station",
        "full": "Futuristic Robotic Neoclassical Space station overgrown with ivy | Giant Mecha, sci-fi, highly detailed, digital painting, vibrantly lush neon lighting, beautiful volumetric-lighting-style atmosphere, a futuristic atmosphere, intricate, ultra detailed, photorealistic imagery, rule of third, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sci-fi gold sphere probe",
        "full": "prop design concept art, a gold sphere scifi probe, elegant, cinematic shot, intricate digital painting artstation concept art smooth sharp focus illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk portrait of Megan Fox",
        "full": "portrait of cyberpunk 2 0 7 7 megan fox, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, artey freytag"
    },
    {
        "display": "Psychedelic portrait of woman.",
        "full": "sexy, beautiful, young woman, eden, intense eyes, tears running down, crying, vaporwave aesthetic, synthwave, colorful, psychedelic, crown, long gown, flowers, bees, butterflies, ribbons, ornate, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Tattooed blonde warrior in emerald.",
        "full": "a close - up of tattooed beautiful blonde female warrior wearing a magical emerald leotard in a magical forest, hyper realistic face, green eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, insanely detailed, artstation, concept art, smooth, sharp focus, vibrant"
    },
    {
        "display": "Silver Surfer, JDM Car, Space",
        "full": "silver surfer in space on top of a jdm car, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Fantasy eagle lady portrait",
        "full": "eagle lady, flaming wings!!!, fantasy, bioluminiscence, flowing hair, portrait, highly detailed, digital painting, beautiful eyes!, symmetry, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve and ilya kuvshinov!!! : : alphonse mucha : : - 0. 5"
    },
    {
        "display": "Black cat graveyard tattoo",
        "full": "black cat in graveyard at midnight halloween tattoo on shoulder by anton pieck, intricate, extremely detailed, digital painting, artstation concept art"
    },
    {
        "display": "Mystical hunter in foggy mountains",
        "full": "a beautifully strange rendering of idyllic poster of mystical hunter in mechanical mask walking in foggy dangerous mountains near river. in dieselpunk style. award winning. dramatic. trending on artstation. high quality. rendered by beeple, by makoto shinkai, syd meade, space art concept, sci - fi, digital art, unreal engine, 4 k uhd image, octane render"
    },
    {
        "display": "Vibrant fairy with wings",
        "full": "wonderdream faeries lady feather wing digital art painting fantasy bloom vibrant keane glen illustration character design concept colorful joy atmospheric lighting butterfly"
    },
    {
        "display": "Count Dracula graphic novel cover.",
        "full": "digital portrait of Count Dracula standing in a darkened doorway, cover art of graphic novel, simple style, solid colors, clean lines, clean ink, trending on artstation,"
    },
    {
        "display": "Bioluminescent dragon in meadow.",
        "full": "beautiful digital fantasy illustration of a closeup giant reptilian blue bioluminescent dragon sitting alone in a meadow, concept art by greg rutowski, anato finnstark, and rebecca guay, highly detailed, soft lighting, rendered in octane"
    },
    {
        "display": "Red-haired woman in cave",
        "full": "a woman with red hair in a dark cave, concept art by Magali Villeneuve, deviantart contest winner, fantasy art, concept art, dark and mysterious, d&d"
    },
    {
        "display": "Futuristic female cyber police",
        "full": "portrait anime visual futuristic female cyber police, on cyberpunk neon light tokyo rooftop, ssci - fi and fantasy, intricate and very beautiful, human structure, concept art, sharp focus, anime by liya nikorov and simon stalenhag and rossdraws and magali villeneuve and luxearte, frostine engine"
    },
    {
        "display": "Evil teenager with cables.",
        "full": "beautiful pure evil teenager lain, cute haircut, with hundreds of network cables, neatly coming out of her head, a part of her face panel is showing, she is in pure bliss, chaos, bizarre, strange, portrait, painting, soft and intricate, fine lines, face is breaking like a porcelain doll, by artgerm,"
    },
    {
        "display": "Panoz GTR-1 at sunset.",
        "full": "a panoz gtr - 1 parked at a drive in movie theater at sunset, concept art, hyperrealistic, octane render, unreal engine 5, path traced, highly detailed, high quality, 8 k, dramatic lighting, cinematic, high coherence, symmetrical, high contrast, lens flare, godrays"
    },
    {
        "display": "Elegant portrait of Lexi Rivera",
        "full": "beautiful elegant full body portrait of lexi rivera with color streaks in hair and wearing a sparkling cherry color one piece swimsuit and iridescent white silk cape, felicia day, wlop, artgerm, artstation, backlit, marble background"
    },
    {
        "display": "Symmetrical pirate mechanism artwork.",
        "full": "symmetry!! pirate themed intricate mechanism, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Russian village in forest",
        "full": "A beautiful painting of russian village in dark forest by ivan shishkin and greg rutkowski, trending on artstation,matte painting"
    },
    {
        "display": "Elven queen portrait.",
        "full": "zooey deschanel as beautiful elven queen, avard winning photograph on pixiv, trending on artstation"
    },
    {
        "display": "Creepy Halloween street scene",
        "full": "a creepy and eery Halloween setting, with Jack o lanterns on the street and shadow figures lurking about, dynamic lighting, photorealistic fantasy concept art, stunning visuals, creative, cinematic, ultra detailed, trending on art station, spooky vibe"
    },
    {
        "display": "Elegant blue tiefling portrait.",
        "full": "a beautiful blue tiefling, dnd, face, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyperrealistic rhododendron azalea",
        "full": "an isolated rhododendron azalea plant, full view, centered, cinematic rim lighting, high poly 3 d render, art nouveau dark outlines, ultra realistic, masked transparent flat background, popular on sketchfab, pixelsquid, unreal engine, 8 k, volumetric lighting, super focused, no blur, trending on artstation, octane render, ultra detailed, hyperrealistic, by james gurney, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sci-fi scientist helmet concept.",
        "full": "concept art of scifi scientist helmet by jama jurabaev, brush stroke, trending on artstation, symmetry, high quality, extremely detailed"
    },
    {
        "display": "Woke man on unicorn.",
        "full": "a painting of a woke man riding an unicorn, a storybook illustration by Lisa Frank, featured on pixiv, magical realism, irridescent, storybook"
    },
    {
        "display": "Donald Trump as Space Marine",
        "full": "portrait of donald trump as a space marine, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Megan Fox as Pinhead.",
        "full": "portrait of megan fox as pinhead, bald, hellraiser, xenobite, hell, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Cinematic portrait of sailor woman",
        "full": "cinematic shot epic portrait an beautiful woman wearing white sailor outfift short sleeved, sweaty wet skin, wet flowing blonde hair, freckles, ocean backround, sunny, broad light, ambient occlusion, volumetric light effect, made by ivan aivazovsky, peter mohrbacher, greg rutkowski, matte painting, trending on artstation, 4 k, perfectly defined features, digital painting, cinematic, epic, highly detailed,"
    },
    {
        "display": "Ultra-realistic Steve Jobs illustration",
        "full": "ultra realistic illustration, steve jobs, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Danny Phantom concept art",
        "full": "realistic mcu full body concept art of danny phantom"
    },
    {
        "display": "Elegant female wizard portrait",
        "full": "an anthropomorphic beautiful female wizard of pharaoh holding magic wand portrait wearing robe, fine art, award winning, intricate, elegant, sharp focus, octane render, hyperrealistic, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by jamie hewlett and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Pastel hyper-realistic woman.",
        "full": "pastel colors, hyper-realism, pastel, minimal, simplistic, amazing composition, woman, vaporwave, wow, Gertrude Abercrombie, minimalistic graffiti masterpiece, minimalism, 3d abstract render overlayed, black background, psychedelic therapy, trending on ArtStation, ink splatters, pen lines, incredible detail, creative, positive energy, happy, unique, negative space, pure imagination painted by artgerm"
    },
    {
        "display": "Spaceship landing in Gotham.",
        "full": "a spaceship lands in gotham city, night, wet pavement, street level view, light mist, fantasy, intricate, elegant, digital painting, trending on artstation, concept art, soft focus, illustration by greg rutkowski, edward hopper, 4 k."
    },
    {
        "display": "Lush plants, cyberpunk view.",
        "full": "lush basement windowsill with plants on it, interior of room, looking out to a cyberpunk rainy street, neon signs, detailed digital concept art by anton fadeev and marc simonetti, trending on artstation"
    },
    {
        "display": "Zombie portrait of Cindy Crawford",
        "full": "portrait of eighties cindy crawford as a zombie, 7 days to die zombie, fine art, award winning, intricate, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by guweiz and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Fantasy portrait of King Ramkhamhaeng.",
        "full": "full body portrait of king ramkamhaeng the great, thai warload, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and peter mohrbacher & greg rutkowski & alexandros pyromallis & nekro & rene maritte"
    },
    {
        "display": "Retired Auror Harry Potter.",
        "full": "a close up portrait of harry potter as a retired auror, focused gaze, art station, highly detailed, concept art, sharp focus, illustration in pen and ink, wide angle, by kentaro miura"
    },
    {
        "display": "Capybara monster eating Godzilla",
        "full": "giant capybara monster feasting on the remains of godzilla, woodlands, magic the gathering artwork, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Retro-futuristic astronaut portrait.",
        "full": "portrait art of 8k ultra realistic retro futuristic terminator astronaut smashed helmet, lens flare, atmosphere, glow, detailed,intricate,blade runner, cybernetic, full of colour, cinematic lighting, trending on artstation, 4k, hyperrealistic, focused, extreme details,unreal engine 5, cinematic, masterpiece, art by ayami kojima, giger"
    },
    {
        "display": "Giant Jesus Mecha Statue",
        "full": "a giant statue of jesus mecha in the desert in space, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, trending on artstation, cinematic composition, beautiful lighting, hyper detailed, 8 k, oil on canvas"
    },
    {
        "display": "Rainy scene with \"little fang.\"",
        "full": "And if it ever starts to pour down, little fang"
    },
    {
        "display": "Techno Wizard Astronaut Portrait",
        "full": "portrait of a techno wizard astronaut, fantasy, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, epic cosmic background, art by artgerm, alphonse mucha, epic cosmic background, erak note, eddie mendoza, christophe young, craig mullins, evan lee, silvain sarrailh, dao trong le, kan liu, character design, trending on artstation"
    },
    {
        "display": "Ominous mechanical oppression.",
        "full": "large mechanical device meticulously grinding down the truth and enslaving humanity, overpowering, ominous, ropes, webs, torturous, dark, steam, chains, fire, spiked, rain, mirth, clouds, lightning, puddles, consuming, parasitic, forest, thorns, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha"
    },
    {
        "display": "Surfing girls on mutant island.",
        "full": "surfing girls on mutant island, dripping with color, intricate details, dark ambient, atmospheric, movie poster, poster, horror, elegant, super highly detailed, award - winning, masterpiece, professional digital photo, artstation, concept art, 8 k, art style by enzo sciotti"
    },
    {
        "display": "Sci-fi planet concept art",
        "full": "beautiful sci fi planet from the horizon, nebula in the background, concept art trending on artstation, volumetric lighting, 8k"
    },
    {
        "display": "Crystal castle landscape",
        "full": "medieval baroque castle made of crystal shards, epic landscape, iceland photography, cinematic, octane render, 8 k, art station, dramatic lighting, beautiful dusk sky, concept art, rococo, photo realistic, intense detail"
    },
    {
        "display": "Aesthetic anime illustration.",
        "full": "aesthetic illustration of ryosuke takahashi with black hair wearing a dark blue shirt leaning on his white mazda rx 7 on an empty highway at dusk, cinematic lighting, initial d anime 1 0 8 0 p, detailed anime face, high detail, 9 0 s anime aesthetic, volumetric lights, rule of thirds, unreal engine 5 render, pinterest wallpaper, trending on artstation"
    },
    {
        "display": "Sad beer-drinking men.",
        "full": "two beautiful men drinking beer, red hearts, sadness, dark ambiance, concept by Godfrey Blow, featured on deviantart, drawing, sots art, lyco art, artwork, photoillustration, poster art"
    },
    {
        "display": "Futuristic diver astronaut in spaceship.",
        "full": "concept art by craig mullins diver astronaut in underwater futuristic dark and empty spaceship. infrared complex and hyperdetailed technical suit design. mandelbulb fractal. reflection and dispersion materials rays and dispersion of light breaking through the deep water. 5 0 mm, f / 3 2. noise film photo. flash photography. trend artstation"
    },
    {
        "display": "Psychedelic nature goddess illustration",
        "full": "\ud83c\udf32\ud83c\udf0c, innocence, light, vaporwave aesthetic, synthwave, colorful, psychedelic, crown, long gown, flowers, bees, butterflies, ribbons, ornate, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bearded man dressing spouse.",
        "full": "bearded young man in orange t - shirt fastens beautiful black dress of his spouse before going to exquisite gala art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Patrick Fabian in puffer jacket",
        "full": "patrick fabian wearing a black supreme puffer jacket and a beanie, elegant, digital painting, concept art, smooth, sharp focus, illustration, from starcraft by ruan jia and mandy jurgens and artgerm and william - adolphe bouguerea"
    },
    {
        "display": "Young Hyderabadi Muslim female.",
        "full": "young hyderabadi muslim female wearing denim short shorts cateye glasses and yellow tank top, highly detailed, artgerm style, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Fiery smoke spirits artwork.",
        "full": "a couple of spirits made of fire and smoke, beautiful high quality realistic fantasy art, trending on artstation by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic Gundam battle scene",
        "full": "gundam mobile suite fighting in the battlefield, mar planet, epic scene, futuristic style, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime-style Nazi maid portrait",
        "full": "baroque oil painting full body portrait character concept art, anime key visual of young female maid nazi official, long straight blonde hair blue eyes, studio lit delicate features finely detailed perfect face directed gaze, black nazi military uniform, gapmoe kuudere grimdark, trending on pixiv fanbox, illustrated by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "King kneeling to girl.",
        "full": "the king in the yellow kneel down to the beautiful girl and staring down from the top of a tall tower, extremely detailed and beautiful aesthetic face, seductive pose, super detailed eyes, low angle camera, highly detailed, digital painting, trending on artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by hiroaki samura and jiro matsumoto and yusuke murata"
    },
    {
        "display": "Darth Vader in red mist.",
        "full": "darth vader's head coming out of a red mist, epic, trending on artstation, profile pic, centered, accurate anatomy, highly detailed, digital art,"
    },
    {
        "display": "African mad scientist illustration",
        "full": "yakub, an african mad scientist with a massive conehead, wires and cables connecting his enormous brain to alien technology, huge glasses, magnified creepy eyes, steampunk alchemy lab with hanging wires and glowing liquid, an apex legends character, digital illustration, neon color scheme, concept art, very symmetrical, detailed environment, by wayne barlowe and fernando botero!! and dan mumford in a surreal cyberpunk style, rendered in unreal engine 5, 8 k, hd, highly detailed."
    },
    {
        "display": "D.VA in police uniform",
        "full": "D.VA from Overwatch wearing a police uniform by Kim Jung Gi, holding handcuffs in one hand Blizzard Concept Art Studio Ghibli. oil paint. 4k. by brom, Pixiv cute anime girl wearing police gear by Ross Tran, Greg Rutkowski--cfg_scale 12"
    },
    {
        "display": "Elegant sentient robot illustration",
        "full": "sentient robot, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, luis rollo, mario feng, john berkey"
    },
    {
        "display": "Shark-human hybrid cosplay.",
        "full": "a shark human hybrid. intricate cosplay. intricate artwork. octane render, trending on artstation, very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Korean queen concept art",
        "full": "an concept art of the korean queen, long hair, makeup, intricate details, detailed face, detailed dress, one face, artstation, epic pose, colourful light, by rembrandt"
    },
    {
        "display": "Goth Guy in 90s Bedroom",
        "full": "a skinny goth guy wearing a face mask standing in a cluttered 9 0 s bedroom, full body character concept art, vaporwave colors, hirohiko araki art, inio asano art,"
    },
    {
        "display": "Overweight model poolside portrait",
        "full": "full body portrait of a slightly overweight gorgeous Brie Larson wearing a micro bikini, wearing a skin-tight micro bikini that's a few sizes too small, round chubby belly, distended belly, extremely fat and soft in her midsection, very large and wide thighs swelling up with fat, fat flabby arms, lounging in a chair by a pool, hyper realistic, extremely attractive and beautiful model-like face, extreme facial detail, modeling photoshoot, sports illustrated swimsuit edition cover, illustration, photorealistic, trending on artstation, HD, 4k, 8k, intricate detailed anatomy, character design, by james gurney"
    },
    {
        "display": "Gaea goddess portrait.",
        "full": "! dream portrait of gaea goddess, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau, and marco mazzoni"
    },
    {
        "display": "Tracer pinup portrait illustration",
        "full": "portrait of tracer from overwatch, pinup pose, full body, hyper detailed, digital illustration, trending on artstation, cinematic lighting, octane render, studio quality, sharp focus, intricate, elegant, art style by alphonse mucha, klimt and nixeu, ian sprigger, wlop, rossdraws, greg rutkowski"
    },
    {
        "display": "Blonde elven mage portrait",
        "full": "portrait of a blonde elven mage, dark, piercing eyes, gentle expression, elegant clothing, photorealistic, highly detailed, artstation, smooth, sharp focus, art by michael whelan, artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Black dragon with treasure.",
        "full": "a black dragon sits on a large pile of gold and gems in a cave with luminous stalactites, large eyes with cilia, big eyes with cilia, neon color, highly detailed, digital painting, artstation, concept art, matte, sharp focus"
    },
    {
        "display": "Fantasy anthropomorphic owl.",
        "full": "anthropomorphic white owl, dirty white feathers, monk staff on back, fantasy, d & d, intricate, detailed, concept art by alphonse mucha, adolfo hohenstein, alice russell glenny, stanley artgerm lau, greg rutkowski, trending on artstation, smooth, sharp focus"
    },
    {
        "display": "Symmetrical Majin Buu portrait",
        "full": "symmetry!! portrait of majin buu, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime girl with Trump",
        "full": "anime girl shaking hands with donald trump, intricate, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau, high detailed, 4 k,"
    },
    {
        "display": "Spirit wolf in sunglasses.",
        "full": "spirit wolf wearing sunglasses by GEOGLYPHIKS by FABI\u00c1N JIM\u00c9NEZ by MICHAEL DIVINE by AMANDA SAGE in the style of oil painting visionary art, intricate oil painting artwork. , trending on artstation, very coherent symmetrical artwork, oil painting"
    },
    {
        "display": "Fantasy forest scene",
        "full": "a painting of a man standing in the middle of a forest, a detailed matte painting by slawomir maniak, deviantart contest winner, fantasy art, matte painting, cryengine, concept art"
    },
    {
        "display": "Cosmic genie illustration.",
        "full": "a genie with phenomenal cosmic power, by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, terrifying, demon rays, intricate detail, cinematic, 8 k, cel shaded, featured on artstation, pixiv"
    },
    {
        "display": "Anime-style Nazi maid portrait",
        "full": "baroque oil painting full body portrait character concept art, anime key visual of young female maid nazi official, long straight blonde hair blue eyes, studio lit delicate features fine detail directed gaze perfect face, black nazi military uniform, gapmoe grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Futuristic Aztec cityscape.",
        "full": "futuristic aztec city, realistic, 8 k, extremely detailed, cgi, trending on artstation, hyper - realistic render, 4 k hd wallpaper, premium prints available, by greg rutkowski"
    },
    {
        "display": "Cartoon Lovecraftian turtle concept.",
        "full": "a cell shaded cartoon giant lovecraftian mechanized turtle from howl's moving castle ( 2 0 0 4 ), with a big head, on a desert road, full body, wide shot, pink light, golden hour, post grunge, concept art by josan gonzales, wlop, by james jean, victor ngai, hq, deviantart, art by artgem"
    },
    {
        "display": "Anime girl walking on water.",
        "full": "anatomically correct anime girl walking on water, ripples, backdrop of dawn, saturn in the background, illustration, concept art, anime, key visual, trending pixiv fanbox by wlop and greg rutkowski and makoto shinkai and studio ghibli"
    },
    {
        "display": "Elegant Old English Sheepdog Portrait",
        "full": "portrait of white old english sheepdog, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mars ravelo and greg rutkowski"
    },
    {
        "display": "Insect concept art",
        "full": "a large insect with long legs and long legs, concept art by Michelangelo, polycount, antipodeans, full body, 8k 3d, hd mo - n 4"
    },
    {
        "display": "Eerie liminal space concept.",
        "full": "a liminal space, eerie and unsettling, concept art by artgerm, greg rutkowski and syd mead and frank lloyd wright"
    },
    {
        "display": "Futuristic pear-shaped cat.",
        "full": "cat in the shape of a pear!!!!!!!!, futuristic, sci-fi, contrast volumetric light, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by Evgeny Lushpin"
    },
    {
        "display": "Man falling in cyberpunk.",
        "full": "man falling through clouds over a dark cyberpunk city, digital art, volumetric lighting, dystopia, artstation, concept art, painting"
    },
    {
        "display": "Cinematic death concept art.",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, death"
    },
    {
        "display": "Spider robots attacking Berlin.",
        "full": "spider robots terrorise streets of weimar berlin and attack freikorps soldiers and civilians, detailed painting, intense heavy street battle, bullet hell, pile of bodies, art by greg rutkowski and jakub rozalski"
    },
    {
        "display": "Cinematic photorealistic anime girl.",
        "full": "photorealistic anime girl render, detailed face, colorful, atmosphere cinematic, by wlop, by ilyu kuvshinov, soft shadows, be concept art, super detailed, unreal engine 5, octane render, 8 k, super realistic, ufotable studio art style, global illumination, trending in pixiv, japanese light novel cover, visual novel"
    },
    {
        "display": "Luigi as WWI soldier",
        "full": "luigi as ww 1 soldier, ultra detailed, trending on artstation, concept art, octane render, unreal engine,"
    },
    {
        "display": "Ninja Gaiden girl portrait.",
        "full": "portrait a Ninja gaiden girl, armored black and green ninja wardrobe, in ruin japanese rainny temple night, ssci-fi and fantasy, intricate and very very beautiful and elegant, highly detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration, art by tian zi and WLOP and alphonse mucha"
    },
    {
        "display": "Daisy Ridley in fantasy setting",
        "full": "highly detailed portrait of daisy ridley, in the walking dead, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Futuristic beauty portrait.",
        "full": "beauty woman, full pallet image, Blade runner artifacts, electronic case display, Total Recall hologram tech, ultrarealistic, futuristic, three point lighting, dramatic lighting, electrical details, high details, 4k, 8k, best, accurate, trending on artstation, artstation, photorealism, ultrarealistic, digital painting, style of Peter Mohrbacher, Caravaggio, Dali, Boris Vallejo, Hajime Sorayama"
    },
    {
        "display": "Detailed Yoshika Miyako illustration",
        "full": "' insane yoshika miyako ', beautiful shadowing, 3 d shadowing, reflective surfaces, illustrated completely, 8 k beautifully detailed pencil illustration, extremely hyper - detailed pencil illustration, intricate, epic composition, masterpiece, bold complimentary colors. stunning masterfully illustrated by artgerm, range murata, alphonse mucha, katsuhiro otomo."
    },
    {
        "display": "Cybernetic wizard dialogue artwork",
        "full": "a beautiful masterpiece painting of a cybernetic wizard discussing sentience with his AI by Remedios Varo and Anato Finnstark and Greg Rutkowski"
    },
    {
        "display": "Cyberpunk sun goddess statue",
        "full": "cyberpunk four armed statue of the goddess of the sun helios descending from olympus, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william adolphe bouguereau and john william waterhouse and gianlorenzo bernini"
    },
    {
        "display": "Gothic Lolita princess portrait",
        "full": "a beautiful hyperdetailed portrait render of absolutely beautiful princess that wear rose flower wedding gothic lolita dress clothing stay in blooming flower house alone, finely detailed perfect face delicate features directed gaze, dazzling light beam penetrated through the window, perfectly shaded, atmospheric lighting, style of studio ghibli, makoto shinkai, raphael lacoste, louis comfort tiffany, artgerm, james jean"
    },
    {
        "display": "Detailed fantasy archer portrait",
        "full": "archer huntress, ultra detailed fantasy, dndbeyond, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Ornate German beer festival",
        "full": "beer festival in germany, ornate, beautiful, atmosphere, vibe, mist, smoke, fire, chimney, rain, wet, pristine, puddles, melting, dripping, snow, creek, lush, ice, bridge, green, stained glass, forest, roses, flowers, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell"
    },
    {
        "display": "Slavic goddess portrait illustration",
        "full": "portrait of slavic goddess, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Hyper-realistic geisha painting",
        "full": "painting of japanese geisha wielding a fan, immaculate scale, hyper-realistic, Unreal Engine, Octane Render, digital art, trending on Artstation, 8k, detailed, atmospheric, immaculate"
    },
    {
        "display": "Illustration of smiling Emma Watson",
        "full": "beautiful full body Emma Watson smiling illustration by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and Cecil Beaton, Lee Miller, Irving Penn, David Bailey, digital art, highly detailed, intricate, sharp focus, Trending on Artstation HQ, deviantart, octane render, cgsociety"
    },
    {
        "display": "Norse moon goddess portrait",
        "full": "portrait of a norse moon goddess, with white skin, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Glass woman portrait.",
        "full": "a very beautiful dreamy hyper realistic portrait of a glass woman made of transparent glass skin filled with glowing glass butterflies inside, rendered by beeple, syd meade, futuristic art concept, sci - fi, digital art, unreal engine, wlop, trending on artstation, 4 k uhd image, octane render"
    },
    {
        "display": "Skull-faced Cinderella portrait.",
        "full": "a portrait of a girl skull face, cinderella, in the style of charlie bowater, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Futuristic tarot star charts",
        "full": "beautiful render of futuristic user interface for viewing starcharts, tarot by victo ngai and andreas rocha and greg rutkowski, trending on artstation, unreal engine, 8 k hd wallpaper, kitbash, game assets, asset pack"
    },
    {
        "display": "Lego Hogwarts train, romantic style.",
        "full": "Lego Hogwarts train by Tyler Edlin and Jean Delville and John Aktinson Grimshaw and Robert Hubert, german romanticism style, oil on canvas, cinematic lighting, vibrant, hdr, concept art, water reflections"
    },
    {
        "display": "Killua Zoldyck in cyberpunk streetwear.",
        "full": "killua zoldyck, streetwear, techwear, cyberpunk style outfit, scifi, blue & red side lighting, full body, nose piercing, detailed portrait, intricate complexity, by greg rutkowski, artgerm, ross tran, conrad roset, takato yomamoto, ilya kuvshinov. 4 k, beautiful, aesthetic octane render, cinematic dramatic atmosphere"
    },
    {
        "display": "Tentacle crown, bioluminescent art",
        "full": "the tentacle crown,by Annie Swynnerton and Diego Rivera and Elihu Vedder, symbolist, dramatic lighting, elaborate geometric ornament, Art Brut, bioluminescent, soft blues and greens,smooth, sharp focus, extremely detailed, Adolf W\u00ef\u00bf\u00bdlfli"
    },
    {
        "display": "Vi portrait in Steampunk.",
        "full": "portrait of Vi from League of Legends, by Fortiche Studio, from Netflix's Arcane, trending on artstation,fine details, realistic shaded, fine-face, foggy Steampunk city on the background, tattoo on face, red hair, painted texture, pretty face,by Artgerm"
    },
    {
        "display": "Ultra-realistic ballerina illustration",
        "full": "ultra realistic illustration, prima ballerina, sci - fi, fantasy, symmetrical face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and alphonse mucha"
    },
    {
        "display": "Enormous entity devouring Earth",
        "full": "An unfathomable enormous entity consuming Earth, digital art, trending on artstation"
    },
    {
        "display": "Fantasy portrait: John Oliver, Adam Driver.",
        "full": "a portrait of john oliver standing next to adam driver, stoic, military uniform, fantasy, intricate, beautiful, highly detailed, charcoal, centered, dark, smokey, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by greg rutkowski"
    },
    {
        "display": "Flying wizard in tornado.",
        "full": "hardmesh detail portrait and full body of flying wizard, wearing horned mask, surrounded a tornado, detailed, realistic, long hair, levitating wizard, inside the tornado, highly detailed, fantasy, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Gloomy robotic server room.",
        "full": "volumetric coherent gloomy colossal ruined server room in datacenter by eddie mendoza blender robot figure automata headless drone robot knight welder posing pacing fixing soldering mono sharp focus, emitting diodes, smoke, artillery, sparks, racks, system unit, motherboard, by rutkowski artstation hyperrealism cinematic dramatic painting concept art of detailed character design matte painting"
    },
    {
        "display": "Cyborg Jesus on horse",
        "full": "tron legacy jesus riding cyborg horse, face, diffuse lighting, hyper realistic, concept art, intricate, hyper detailed, smooth, sharp focus, illustration, trending on artstation, art by greg rutkowski and james gurney and alphonse mucha"
    },
    {
        "display": "Cute cartoon robot illustration.",
        "full": "Digital illustration of a cute robot character, cartoon character, concept art, cartoony procreate,  drawing, ink, Trend on Behance Illustration, Childrens Art in Artstation"
    },
    {
        "display": "Adorable Lovecraftian alien creature.",
        "full": "a lovecraftian alien creature, adorable and whimsical, fantasy, elegant, digital painting, artstation, concept art, matte, sharp focus, unreal engine 5, marmoset toolbag, redshift, vibrant colors, high contrast, illustration, art by james jean"
    },
    {
        "display": "Cyberpunk Jesse Pinkman Portrait",
        "full": "portrait of cyberpunk jesse pinkman, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, comixwave films, trending on artstation"
    },
    {
        "display": "Tearful girl\u2019s eyes art",
        "full": "! close up of a pretty teary girls eyes, character concept art, intricate complexity, by wlop, by charlie bowater, by quixel megascan, by artgerm and by ilya kushinov"
    },
    {
        "display": "Cute girl with guitar",
        "full": "a cute girl wearing school uniform playing electric guitar | | really good looking face!!, realistic shaded perfect face, good shape guitar, fine details, anime, realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob reyt"
    },
    {
        "display": "Art Nouveau Wisteria Illustration",
        "full": "Art Nouveau Wisteria plant isolated on a black background, D&D, fantasy, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Stylish woman portrait with band-aids.",
        "full": "A ultradetailed beautiful  portrait panting of a stylish woman with colorful band-aids on her face, Oil painting, by Ilya Kuvshinov, Greg Rutkowski and Makoto Shinkai"
    },
    {
        "display": "Post-apocalyptic Manhattan landscape.",
        "full": "a high detail photograph of manhattan after being destroyed by an alien race, building, avenue, urban architecture, americana architecture, concrete architecture, paved roads, by thomas kinkade trending on artstation, photorealistic, wild vegetation, utopian, futuristic, blade runner"
    },
    {
        "display": "Elegant faerie portrait",
        "full": "portrait of faerie with butterfly wings, elegant, regal, intricate, fireflies in background, highly detailed, digital painting, artstation, concept art, sharp focus, painterly ink illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal female cleric portrait",
        "full": "side portrait of a beautiful female cleric, ethereal, dreamy, backlit, highly detailed, hyperrealistic, mystical, intricate, realistic lighting, realistic face, sharp focus, windswept, rule of thirds, symmetrical facial features, by artgerm, wlop, rossdraws, frank frazetta, andrei riabovitchev, trending on artstation, hd, 4 k, fantasy"
    },
    {
        "display": "Eerie fantasy concept art",
        "full": "made in abyss concept art wlop James Jean Marc Simonetti trending on artstation hyperdetailed Unreal Engine 4k 8k ultra HD pic cinematic shot,  modern anime, fantasy, eerie"
    },
    {
        "display": "Ultra-detailed Osiris illustration",
        "full": "Osiris, trending on artstation, ultra detailed, 8k, character illustration by Greg Rutkowski, Thomas Kinkade."
    },
    {
        "display": "Gorillas playing volleyball.",
        "full": "gorillas playing volleyball in a jungle with a volleyball, volleyball net, digital illustration, inspired by greg rutkowski and artgerm, high detail"
    },
    {
        "display": "Haitian teenage Aphrodite portrait.",
        "full": "portrait of haitian teenage aphrodite, wicked smile, wearing leather coat, onyx bracelets, intricate, elegant, sparkling dark jewelry, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mucha, artgerm, and greg rutkowski"
    },
    {
        "display": "Chinchilla-covered lawyer girl.",
        "full": "a ( lawyer ) girl covered in chinchilla, beautiful and aesthetic, close up, discordant, dramatic pose, intricate, highly detailed, detailed face, smooth, sharp focus, specular light, occlusion shadow, rim light, artgerm, artstation, art by greg rutkowski and ilya kuvshinov, fantasy painting"
    },
    {
        "display": "Detailed digital portrait of Obama",
        "full": "obama, highly detailed digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime character in galaxy.",
        "full": "saitama, anime, colors, holy, full body, manga, 8 k, illustration, comprehensive art, thorough details, intricate, artstation, cgsociety contest winner, galaxy, atmosphere, unreal engine, video game, highly detailed, symmetrical, concept art, peter mohrbacher, charlie bowater, artstation, craig mullins, cinematic, digital painting, deviantart, cinematic lighting, 4 k"
    },
    {
        "display": "Knitted Bernedoodle judge.",
        "full": "a closeup photorealistic photograph of a cute smiling knitted bernedoodle judge dog dressed in a black gown, presiding over the courthouse. indoor shot, professional capture, well lit photo. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Beautiful female paladin portrait.",
        "full": "painting full body of a beautiful female paladin with striking eyes, dark obsidian black armor, dof, shining light from above by felipe kimio trending on artstation"
    },
    {
        "display": "Cyberpunk teenage assassin portrait",
        "full": "smirking blonde teenage boy rouge assassin, wearing cyberpunk intricate techwear clothing, beautiful, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Midnight blue hulk with cleaver.",
        "full": "artstation concept a midnight blue hulk jolding a meat cleaver, dusty, hyperdetailed, artstation trending, world renowned artists, worth 1 0 0 0. com, historic artworks society, antique renewel, cgsociety, by greg rutkowski, by gustave dore, deviantart"
    },
    {
        "display": "Punk cyborg chicken portrait",
        "full": "portrait painting of a punk chain - smoking chicken cyborg, glitchwave, sharp focus, award - winning, trending on artstation, masterpiece, highly detailed, intricate. art by josan gonzales and moebius and deathburger"
    },
    {
        "display": "Psychedelic Mr. Bean Stomping",
        "full": "psychedelic anthropomorphic d 2 0 head in iridescent darkyiron mr bean stomping new york, intricate, elegant, highly detailed maize, wide angle, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Fierce Egyptian warrior portrait.",
        "full": "egyptian warrior, portrait, fierce, intricate, elegant, red volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Ultra-realistic face close-up.",
        "full": "ultra realistic illustration of face, closeup, dramatic lighting, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Kanye West as a character in Lord of the Rings.",
        "full": "kanye west in lord of the rings, intricate, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Realistic wrestler with VR goggles",
        "full": "painting of a wrestler character wearing vr goggles, shrugging arms, ultra realistic, sharp details, subsurface scattering, intricate details, warm lighting, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical Lovecraftian portrait",
        "full": "symmetry!! portrait of lovecraftian smiling jerma, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk whirlwind souls",
        "full": "whirlwind souls rushing inside metaverse, half body, tiara, robotic, android, cyborg, cyberpunk face, by loish, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Romantic library scene",
        "full": "a man and women in love, flowing book pages, infinite library, elegant intricate digital painting artstation concept art by Mark Brooks and Brad Kunkle detailed"
    },
    {
        "display": "Photorealistic solargoth portrait",
        "full": "photorealistic portrait of a young butch solargoth woman, handsome, female, masculine, upper body, fantasy, fierce, sharp features, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Demonic Venetian mask artwork",
        "full": "Very very very very highly detailed epic photo of demonic face with venetian mask, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Anton Pieck, Octane render in Maya and Houdini VFX"
    },
    {
        "display": "Persian Princess Architect Portrait",
        "full": "Beautiful portrait of a Persian Princess who is an architect, beautiful princess, face painting, architecture, persian style architecture, dramatic lighting, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, black+velvet+red+Turquoise, dark, art by artgerm and greg rutkowski and alphonse mucha, footage from space camera"
    },
    {
        "display": "Mystical cowboy detective.",
        "full": "Thin roguish cowboy detective wearing a black cloak made of very thousands of thin strips of cloth that decay into mist. Holding a revolver. fantasy, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic macro of marijuana bud",
        "full": "epic scale cinematic character concept cinematic 4 k perfect focus closeup macro photography of a marijuana bud showing crystals and trichomes, densely packed buds of weed, high times photography by greg rutkowski alphonse mucha alex grey hr giger artgerm cgsociety artstation by greg rutkowski alphonse mucha android jones max chroma"
    },
    {
        "display": "Detailed soldier portrait.",
        "full": "painting of a soldier, anatomy visible, clear - lines, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Hermione Granger enjoying hamburgers.",
        "full": "portrait of Hermione granger eating hamburgers, extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Steampunk Ganesha pattern.",
        "full": "seamless pattern of steampunk cybernetic biomechanical hindu god ganesha, 3 d model, very coherent symmetrical artwork, unreal engine realistic render, 8 k, micro detail, gold white plastic and steel intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, wlop"
    },
    {
        "display": "Punk Tabaxi Bard Portrait",
        "full": "portrait painting of a punk tabaxi bard with pure white fur, sharp focus, award - winning, trending on artstation, masterpiece, highly detailed, intricate. art by josan gonzales and moebius and deathburger"
    },
    {
        "display": "Symmetrical horror fashion portrait.",
        "full": "symmetry!! portrait of elle fanning in irobot, horror, fashion, dark!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and frank frazetta and peter paul rubens"
    },
    {
        "display": "Humanoid robot in dark forest.",
        "full": "sci - fi portrait of a humanoid robot surrounded with floating magical runes, standing in a dark forest, artgerm, trending on artstation, warframe concept art"
    },
    {
        "display": "Female sheriff character design.",
        "full": "full size persona, female sheriff, beauty face by natalie portman, league of legends, trending by artstation"
    },
    {
        "display": "Anime night fever scene",
        "full": "A fever of the night, a grime tale of the night fever by the brothers guild, digital painting, max branning, Russell Tovey, artstation, ristan Eaton, victo ngai, artgerm, RHADS, ross draws, anime styled"
    },
    {
        "display": "Blonde magical girl illustration",
        "full": "blonde haired magical girl anime character screenshot, anime, intricate, sharp focus, illustration, highly detailed, digital painting, clean artstyle, concept art, matte, art by ilya kuvshinov and ruan jia and greg rutkowski, masterpiece"
    },
    {
        "display": "Happy Mask Salesman Portrait",
        "full": "a portrait of the happy mask salesman, grinning, ginger hair, handsome, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and wlop, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Jedi Knight Portrait",
        "full": "portrait of a man by greg rutkowski, jedi knight, hybrid between human and twi'lek, wearing black wool cap, star wars expanded universe, he is about 3 0 years old, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Purple fire lizardman character.",
        "full": "Character design body made of purple fire, body with black and purple lava, Lizardman Art, muscular male body, mecha humanoid with cyberpunk bomber jacket,  concept art character, royalty, smooth, sharp focus, organic, deep shadowsby Jerad Marantz, hyperrealistic oil painting, 4k, studio lightning"
    },
    {
        "display": "Neon Genesis Evangelion Comic Cover",
        "full": "first issue of neon genesis evangelion comic book cover art, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Lofi Jester portrait, Pixar style.",
        "full": "lofi Jester from Critical Role portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "\"Red Burning Ogre Attack\"",
        "full": "concept art of attacking of huge red burning ogre with one horn on its head and tiny warrior, cinematic, dramatic perspective art station trending, by arthur braginsky"
    },
    {
        "display": "Daft Punk Lucha Libre DJ.",
        "full": "portrait of daft punk as lucha libre dj, muscular, headphones, thick golden ring around the neck, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Christina Hendricks portrait, digital art.",
        "full": "portrait of christina hendricks, half life 2, painted by greg rutkowski, painted by stanley artgerm, painted by igor kieryluk, digital art, promotional art, trending on artstation"
    },
    {
        "display": "Shrek dining on hamburgers.",
        "full": "portrait of shrek eating hamburgers, extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant floral portrait.",
        "full": "pink petals with a a wonderful aubrey plaza and christina ricci and sarah hyland and mila kunis and olivia newton john in a hooters shirt, intricate, elegant, highly detailed, wonderful eyes, sweet, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and concept art"
    },
    {
        "display": "Albino lion furry portrait.",
        "full": "aesthetic overhead photo portrait commission of a albino male furry anthro lion lying on a perfect lavender garden like a bed  with bubbles surrounding him while wearing a cute mint colored cozy soft pastel winter outfit with pearls on it, winter Atmosphere. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art, 2021 award winning painting"
    },
    {
        "display": "Giant brass marionette in theatre",
        "full": "painting of a giant mechanical clockwork marionette made of brass and gold in a dark moody theatre, puppet, ornate, engraved highly detailed, fantasy, render, digital painting, trending on artstation, concept art, illustration, in the style of dishonored, art by piotr jablonski, arkane lyon, artgerm and greg rutkowski"
    },
    {
        "display": "Anime martial artist portrait",
        "full": "anime character portrait of a female martial artist!! long black hair! blue eyes! kicking!! elegant, intricate outfit gold and white outfit! fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trembling on artstation"
    },
    {
        "display": "Glenn Quagmire as Spider-Man",
        "full": "Glenn Quagmire as Spider Man, Spider Man costume unmasked, Glenn Quagmire hairstyle, Glenn Quagmire body type, Glenn Quagmire Face, calm, grumpy, portrait, masculine figure, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gothic blonde mage by well.",
        "full": "gothic white mage blonde braided hair dove cameron wearing ethereal ivory carved bone armor looking down a well, green plants, intricate, elegant, highly detailed, digital painting, artstaion, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Tidal wave engulfing city.",
        "full": "photorealistic imposing tidal wave encroaching on a city. hyperdetailed photorealism, 1 0 8 megapixels, amazing depth, glowing rich colors, powerful imagery, psychedelic overtones, 3 d finalrender, 3 d shading, cinematic lighting, artstation concept art"
    },
    {
        "display": "Cute cartoon tractor pulling tank",
        "full": "cute cartoon little tractor dragging the russian tank by goro fujita and simon stalenhag and wes anderson and alex andreev and chiho aoshima and beeple and banksy and kandinsky and magritte and basquiat and picasso, 8 k, trending on artstation, hyper detailed, cinematic"
    },
    {
        "display": "Giant floating flower portrait",
        "full": "a fancy portrait of a giant floating flower by Greg Rutkowski, Sung Choi, Mitchell Mohrhauser, Maciej Kuciara, Johnson Ting, Maxim Verehin, Peter Konig, Bloodborne , 8k photorealistic, cinematic lighting, HD, high details, atmospheric , trending on artstation"
    },
    {
        "display": "Non-binary god portrait",
        "full": "god, non-binary, long hair, georgeus, intricate, elegant, ethereal, artstation, highly detailed, sharp focus, smooth, by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vibrant bird in forest.",
        "full": "a bird gliding through a forest, large wingspan. Vibrant colors. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, trending on ArtStation, digital art."
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "Hanif Bali, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Regal woman in sari.",
        "full": "portrait of a beautiful woman wearing a sari dress, holding a bouquet of flowing flowers, drenched body, wet dripping hair, emerging from the water, fantasy, regal, fractal crystal, fractal gems, by ross tran stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell"
    },
    {
        "display": "Metaverse city street scene.",
        "full": "an intricate detail of a city street in the metaverse with crowd wearing vr headsets, highly detailed, Artstation, concept art, hyper realistic, cgsociety"
    },
    {
        "display": "Moon city, brutalist architecture.",
        "full": "a modern city on the moon, matte painting, brutalist architecture, beautiful glowing lights, sci - fi, stunning, intricate, elegant. highly detailed, digital painting. artstation. smooth. sharp focus. illustration. art by artgerm and greg rutkowski and alphonse mucha,"
    },
    {
        "display": "Psychedelic cosmonaut illustration",
        "full": "An extremely psychedelic experience, colorful, surreal, dramatic lighting, cosmonaut, LSD, face, detailed, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Sam Spratt, Dan Mumford, Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Dramatic wizard portrait.",
        "full": "portrait of a wizard, horror, fantasy, dramatic lighting, magical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Poker night Oktoberfest invitation",
        "full": "large computer table octoberfest invite card, men drinking beer and playing poker, angry, world series of poker, beer bottles, photoshoot, 4 k, hyper realistic, natural, highly detailed, digital illustration, trending in artstation, classical painting, smooth, sharp focus art by ilya repin"
    },
    {
        "display": "Portrait of perfect woman.",
        "full": "beautiful face! portrait of young woman blessed with ever - increasing physical and mental perfection, realism, blonde hair, perfect face!! intricate, elegant, highly detailed, vision of holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, humanity, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Devil's face in hellfire.",
        "full": "close-up macro portrait of the face of the devil engulfed in hell's fury,  epic angle and pose, ornate, symmetrical artwork, 3d with depth of field, blurred background, shadow figures with skulls in the background, translucent, nautilus, energy flows of and fire evil energy. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. y Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Vampire businesswoman portrait.",
        "full": "portrait of vidhya balan upper body in bloody business suit, blood red eyes, vampire fangs, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Futuristic god illustration",
        "full": "\u201c futuristic god, sci fi concept art, concept art, illustration, highly detailed \u201d"
    },
    {
        "display": "Cyborg woman in cylinder.",
        "full": "Ultra realistic cyborg extreme beautiful woman in a futuristic glass cylinder with electrical cables that power and repair the cyborg., cyberpunk,sci-fi, fantasy,Kodak , soft light, volumetric lighting, ,night, fog ,smoke, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration,art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy portrait of Kim Kardashian",
        "full": "kim kardashian, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Young dragon turtle commander.",
        "full": "young dragon turtle commander, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "First vision after death",
        "full": "the first thing one sees after death, trending on artstation, pixiv, hyperdetailed unreal engine 4 k 8 k ultra hd, stanley artgerm lau, wlop, rossdraws, james jean marc simonetti ruan jia and mandy jurgens and artgerm and william - adolphe bouguerea sakimichan, yuru camp, illustration, digital art, concept art"
    },
    {
        "display": "Native American shaman fantasy",
        "full": ": native american shamen fantasy, fantasy magic,, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, jahbu art and paul lewin masterpiece"
    },
    {
        "display": "Acrobatic girl in peril.",
        "full": "detailed procedural render plastic piggy in fluid action scene of ivory cotton spaghetti strap dress protects the acrobatic coolest girl from doom as the planet they stand sends spores surround her, photorealism, sharp focus, award winning, volumetric, raytracing, tristan eaton, victo ngai,, maxfield parrish, artgerm, intricate details, portrait, bokeh"
    },
    {
        "display": "Vivid abiogenesis illustration",
        "full": "abiogenesis, by ernst haeckel and agostino arrivabene and robert hooke and joaquin sorolla, rule of thirds, antediluvian, vivid colours, atmospheric, digital painting, artstation, concept art, smooth, soft focus, negative space, illustration, digital painting"
    },
    {
        "display": "Capybara on motorcycle at dusk",
        "full": "a photorealistic photograph of a knitted cute Capybara wearing sunglasses and dressed in a beanie cap. The subject is riding on a motorcycle in Hollywood at dusk. Palm trees in the background. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and the style of Unreal Engine."
    },
    {
        "display": "Alice Falling Down Rabbit Hole",
        "full": "Alice in Wonderland, falling down the rabbit hole, colorful, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Game concept art.",
        "full": "The Night Inn, game concept art by Akihiko Yoshida, trending on artstation and cgsociety"
    },
    {
        "display": "Muscular woman in ao dai",
        "full": "muscular oiled woman wearing white ao dai, fat, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine."
    },
    {
        "display": "Futuristic Parisian street scene",
        "full": "modern downtown city, Parisian buildings, billboards, advertisements, Times Square, small buildings, dark, matte painting, concept art, digital painting, style of Ian Hubert, warm lighting, futuristic, volumetric lighting, street view, daytime, godrays , high detail, no sky scrapers"
    },
    {
        "display": "Muscular female gnome engineer",
        "full": "muscular female gnome engineer artificer, metal gauntlet, short black hair, sea landscape, full body portrait, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, centred, rule of thirds, concept art, matte, sharp focus, illustration, cover by artgerm, art by greg rutkowski"
    },
    {
        "display": "Dark muscular Indian woman.",
        "full": "painting of dark muscular oiled indian woman back, bloody, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical portrait of Geppetto.",
        "full": "symmetry portrait of geppetto from pinocchio, glowing lights, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Highly detailed Qarth scene",
        "full": "qarth, game of thrones, super highly detailed, professional digital painting, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Mad scientist food rain.",
        "full": "a mad scientist laughing happily at the rain of food he managed to create , concept art, trending on artstation 3D."
    },
    {
        "display": "Samurai girl attacking ogre.",
        "full": "samurai girl with long dark hair attack the ogre with her katana in the buddist temple, digital illustration, concept art, realistic lighting, sharp focus, 4 k"
    },
    {
        "display": "Charming wealthy viscount illustration.",
        "full": "david gandy, charming, rich viscount, laughing, fit, cunning, robe, gold and purple, warhammer, lord of the rings, sweaty, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surreal underwater astronaut scene.",
        "full": "peter tarka, golden hour, minimalistic, hyperrealistic surrealism, award winning masterpiece with incredible details, epic stunning, astronaut sinking falling underwater infinity pool, a surreal vaporwave liminal space, highly detailed, trending on artstation, artgerm and greg rutkowski and alphonse mucha, daily deviation, iamag, broken giant marble head statue ruins, illuminated dreamscape"
    },
    {
        "display": "Cinematic portrait of girl.",
        "full": "real life photo of a beautiful girl, full body photoshoot, long braided curly blonde hair, twisted braids, golden watery eyes, full round face, short smile, white dress, lake setting, cinematic lightning, medium shot, mid-shot, highly detailed, trending on artstation, Unreal Engine 4k, 80mm, 85mm, cinematic wallpaper"
    },
    {
        "display": "Epic portrait of Sheila Watt-Cloutier",
        "full": "epic portrait of slightly muscular indigenous visionary sheila watt cloutier serious wearing short sleeved instructor uniform laser pointing on globe, blueprint, detailed, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, breathtaking, highest resolution, extremely detailed beautiful, establishing shot, artistic, hyperrealistic, beautiful face, enhanced hands, octane render"
    },
    {
        "display": "Dramatic anime illustration",
        "full": "9 f 8 g 8 8 jffo 8 8 9 irjrr dramatic lighting, anime illustration by greg rutkowski, yoji shinkawa, 4 k, digital art, concept art, trending on artstation, \u30a2\u30cb\u30e1, featured on pixiv"
    },
    {
        "display": "Elegant Rapunzel with magic.",
        "full": "rapunzel gold sun magic, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Girl with axe vs. werewolf",
        "full": "a girl in a red hood fights with a two - handed axe with a werewolf, sharp focus, cinematic lighting, unreal engine 5, by jeff koons, hajime soryama, boris vallejo, artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Elegant rose bell painting",
        "full": "breathtaking detailed soft painting of a rose fire bell ringing in the clouds, public money flowing through parties of fraternity, rembrandt style, elegant, highly detailed, artstation, concept art, matte, sharp focus, art by tom bagshaw, and greg rutkowski"
    },
    {
        "display": "Cyberpunk daemonic android portrait",
        "full": "biblical daemonic stylish cyberpunk android, glowing veins, rule of thirds, studio portrait by greg hildebrandt, studio lighting, muted colors, by terry richardson, by leonardo davinci, by beeple, ultrarelistic, extreme detail, caustics, trending on artstation, 8 k, octane renderer, rtx on"
    },
    {
        "display": "Lofi BioPunk Smunchlax portrait",
        "full": "lofi BioPunk Pokemon Smunchlax  face moved to left 10 pixels portrait Pixar style by Tristan Eaton_Stanley Artgerm and Tom Bagshaw,"
    },
    {
        "display": "Soviet cosmonaut meets aliens",
        "full": "a soviet cosmonaut in mars mets for the first time with an alien civilization, sovietwave aesthetic, sci fi, soviet futurism, realistic, detailed picture, trending on artstation concept art"
    },
    {
        "display": "Emma Watson as Lara Croft",
        "full": "emma watson teams up with lara croft, cartoon, rpg character, humblewood art style, concept art, fantasy"
    },
    {
        "display": "Beautiful girl with thorn crown",
        "full": "portrait of very beautifull girl, thorn rose crown, thorn everywhere, headshot, pale skin, 4k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, fantasy, D&D, realistic lighting, by Alphonse Mucha, Greg Rutkowski, sharp focus, backlit, bright red hair, elegant"
    },
    {
        "display": "Glowing ghostly river.",
        "full": "a scary blue river of glowing ghostly lost souls drowning in the underworld river, shadowy and dark, evil lurks, hellish vibe, sinister, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Mechanical clockwork marionette.",
        "full": "full body shot of a mechanical clockwork marionette made of brass and gold with a dark green cloths, glowing emerald eyes, puppet, ornate, engraved highly detailed, fantasy, render, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski"
    },
    {
        "display": "Ultra-realistic jungle portrait",
        "full": "doc savage in the jungle, fantasy character portrait, ultra realistic, concept art, intricate details, highly detailed by james bamaruan jia and mandy jurgens and artgerm and william adolphe bouguereau and frank frazetta"
    },
    {
        "display": "Cute black dragon illustration",
        "full": "black cute little dragon, flowers, black gold light blue, diamonds, highly detailed, artgerm, cushart krenz, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Man entering fractal vortex",
        "full": "a man gets sucked into a fractal, ultra realistic, concept art, intricate details, highly detailed, wide angle, by android jones, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Futuristic prison drone swarm",
        "full": "Black military quadcopters swarm the wide hallways in a futuristic prison underground, brutalist liminal architecture, sigma 85mm f/1.4, 4k, unreal engine, concept art, trending on artstation, depth of field, high resolution, 4k, 8k, hd, full color"
    },
    {
        "display": "Hestia: Steampunk Fire Goddess",
        "full": "the greek goddess hestia, hair made of fire, steampunk, glowing eyes, beautiful eyes, volumetric lights, red and cyan theme, art nouveau botanicals, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, cinematic, illustration, beautiful face, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyper-realistic zombie portrait",
        "full": "hyper realistic photo portrait zombie with hoodie cinematic, greg rutkowski, james gurney, mignola, craig mullins, brom"
    },
    {
        "display": "Cassandra Cain in bed.",
        "full": "cassandra cain wearing a white dress shirt!!!! laying in bed!!!, beautiful face!!!!, 2 7 years old, cg animation, lifelike, animated, realistic, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Hyper-realistic robot pregnancy.",
        "full": "robot pregnant with a human, natural lighting, ultra rendered extreme realism and detail, 8 k, highly detailed, realistic, hyper realistic, in the style of greg rutkowski, by artgerm, by gustave dore, by marco turini, photorealistim, sharp focus, majestic, artstation,"
    },
    {
        "display": "Warrior robot astronaut illustration",
        "full": "a warrior robot astronaut, floral! horizon zero dawn machine, posing for a fight intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Angelic anthropomorphized swan priest",
        "full": "Swan, Anthropomorphized, Angelic, Magical, Priest, D&D, artstation, fantasy, magic the gathering artwork, cinematic lighting, centered, symmetrical, highly detailed, digital painting, , concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Futuristic battle mech.",
        "full": "futuristic war mech with miniguns and rocket launchers walking into battle, highly detailed, 4 k, hdr, award - winning, octane render, trending on artstation, volumetric lighting"
    },
    {
        "display": "Fantasy knight portrait",
        "full": "a ( fantasy comic ) ( cover art ) portrait of a valiant knight who looks like ( young dolph lundgren ), oil painting by greg rutkowski and serhiy krykun and artgerm, photorealistic, d & d, highly detailed!, hd, 4 k, trending on artstation"
    },
    {
        "display": "YPJ soldier in Koban\u00ee siege",
        "full": "beautiful YPJ soldier in the defense of Koban\u00ee in the siege of Koban\u00ee, detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Epic warrior portrait",
        "full": "cinematic shot epic portrait an warrior lifting his large sword, the sword is being hit by a lighting strike, foggy, storm, lighting strike, motion blur, broad light, ambient occlusion, blue colors, volumetric light effect, made by ivan aivazovsky, peter mohrbacher, greg rutkowski, matte painting, trending on artstation, 4 k, perfectly defined features, digital painting, cinematic, epic, highly detailed,"
    },
    {
        "display": "Black and white digital art.",
        "full": "black and white by Craig Mullins, ilya kuvshinov, krenz cushart, artgerm trending on artstation by Edward Hopper and Dan Mumford and WLOP and Rutkovsky, Unreal Engine 5, Lumen, Nanite"
    },
    {
        "display": "Fae priestess in neon forest.",
        "full": "stunningly beautiful female neon hair, fantasy art, fae priestess, mushroom forest landscape, dark light night, sharp focus, digital painting, 4 k, concept art, art by wlop, artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate digital painting of Jetsadaporn as Ramkhamhaeng",
        "full": "jetsadaporn ponder dressed as ramkhahaeng, intricate, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Mary Elizabeth Winstead as Alyx",
        "full": "mary elizabeth winstead as alyx, source engine, half life, colorful, cinematic, clothing by artgerm, world by greg rutkowski, digital art, global illumination"
    },
    {
        "display": "Metaverse Ayahuasca shaman.",
        "full": "portrait of a future metaverse Ayahuasca tech shaman warrior, 2D cartoon,  visionary art, symmetric, Magick symbols, holy halo, shipibo patterns, sci-fi, concept art, trending on art station, 8k digital art, by Mandy Jurgens, fantasy art"
    },
    {
        "display": "Mountain village concept art",
        "full": "beautiful peace loving village on a mountain slope, realistic concept art, eytan zana, one pixel brush, lavander and yellow color scheme, dramatic lighting, concept art, trending on artstation"
    },
    {
        "display": "Beautiful female warrior illustration",
        "full": "beautiful female warrior, accurate anatomy, by Stanley Artgerm Lau, WLOP, Rossdraws, frank frazetta, Andrei Riabovitchev, Marc Simonetti, tranding on artstation"
    },
    {
        "display": "Ultra-realistic fantasy bodybuilder",
        "full": "gigachad ron swanson bodybuilder wearing green in final fight mountain by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Cyberpunk android sneaker render",
        "full": "realistic 3 d render of a cyberpunk android sneaker, beautiful studio lighting, soft, sharp focus, neon cyberpunk highlights, intricate detail, gold and red metal, soft rubber, textured plastic, octane render, side view, close up, trending on artstation, deviantart, bauhaus"
    },
    {
        "display": "Cloaked woman on multi-headed beast",
        "full": "a masterpiece! photographic portrait of a a cloaked woman riding the back of a scarlet - colored beast!! with seven heads!! and ten horns!! by gustave dore and sam spratt and allen williams, trending on artstation, cgsociety, 8 k hd, earthtone colors,"
    },
    {
        "display": "Detailed dragon at universe's edge.",
        "full": "highly detailed picture of great dragon, sketch, manga, edge of the universe, perfectly face, highly detailed, masterpiece, trending on artstation, golden ratio, cinematic romantic magical, perfect intricate highly detailed painting by gustave dore, by timothy von rueden, by mucha alphonse, digital art"
    },
    {
        "display": "Pearlescent black armor design.",
        "full": "sleek pearlescent black wraithbone armor, by greg rutkowski and mark brookes and jim burns and tom bagshaw and magali villeneuve, trending on artstation"
    },
    {
        "display": "Elegant portrait of Gianna Michaels",
        "full": "beautiful portrait of gianna michaels, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Black hole devouring mountain.",
        "full": "black hole sucking in mountain, arcain style, ultra detailed, concept art, crowd, people watching"
    },
    {
        "display": "Stealth starship battle scene",
        "full": "stealth starship bombing a capital starship in space. explosions, action shot. pulp sci - fi art. high contrast. tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Buffaloes on sunset beach.",
        "full": "concept art, of buffaloes on a beach, sunset, 30mm, canon, very hot, crowded, artstation"
    },
    {
        "display": "Lofi biopunk woman portrait",
        "full": "Lofi biopunk portrait beautiful woman with short brown curly hair, roman face, phoenix, rainbow, floral, Tristan Eaton, Stanley Artgerm, Tom Bagshaw"
    },
    {
        "display": "Ancient Greek warrior portrait.",
        "full": "portrait of an ancient greek character in intricate ornate armor with an ivory mask, by ilya kuvshinov, by thomas lawrence, by bayard wu, trending on artstation, masterpiece"
    },
    {
        "display": "AI Singularity TIME Cover",
        "full": "TIME magazine cover, the coming AI singularity, by Artgerm, 4k"
    },
    {
        "display": "Symmetrical fanart of Emma Frost",
        "full": "symmetry!! intense fanart of back pose ofemma frost as acotar protagonist, intricate, hyper detailed background, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, by hajime sorayama and boris vallejo"
    },
    {
        "display": "Steampunk Sri Yantra Illustration",
        "full": "steampunk sri yantra, fantasy art stylized digital illustration sharp focus, elegant intricate digital painting artstation concept art by mark brooks and brad kunkle global illumination ray tracing advanced technology, art gta 5 cover, official fanart behance hd artstation by jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, ossdraws, that looks like it is from borderlands and by feng zhu and loish and laurie greasley, victo ngai, andreas rocha, john harris"
    },
    {
        "display": "Psychedelic solarpunk village.",
        "full": "central perspective of a smart village with psychedelic solarpunk architecture, big bee, flying cow, surrounded by lush nature - rays, ray - tracing, cinematic lighting, 8 k smooth, vibrant colors, ambient occlusion, hdr, complex, highly atmospheric lighting, highly detailed, alex grey, greg rutkowski, focus, cinematic, concept art, artstation, trending"
    },
    {
        "display": "Pale Indian doctors in space",
        "full": "Sensual good looking pale young Indian doctors wearing jeans in a space station above Earth, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Werewolf portrait in Lviv.",
        "full": "werewolf in the downtown city lviv church of st. elizabeth, portrait, highly detailed, full body, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Muscular Greek god portrait",
        "full": "Henry Cavill as a Greek god, gorgeous, amazing, muscular, fit, very muscular male body, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dragon scalemail armor illustration",
        "full": "a magnificent suit of green dragon scalemail armor on an armour stand, d & d, fantasy, intricate, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration"
    },
    {
        "display": "Sultry nun portrait",
        "full": "portrait of megan fox as a sexy sultry nun, catholic, church, bible, christian, intrigante, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Mad scientist dryad character.",
        "full": "A green-skinned female dryad with a giant pink flower instead of hair, as a mad scientist wearing a lab coat, big glasses, smug grin expression. big pink flower petals, green plant-like skin. ultra HD high detail sharp detail character design high-quality digital art trending on artstation photorealism"
    },
    {
        "display": "Art Nouveau Favela in Space",
        "full": "art nouveau favela, outer space environment, scenery, professional, award - winning, trending on artstation, hyper detailed, realistic, beautiful, emotional, shiny, golden, picture"
    },
    {
        "display": "Elon Musk with waifus",
        "full": "anime key visual of Elon musk smugly looking at anime waifus, pixiv"
    },
    {
        "display": "Thunderous neon portrait.",
        "full": "thunder neon killua zoldyck portrait, made by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, artstation, cgsociety, high light"
    },
    {
        "display": "Fairy queen portrait, anime.",
        "full": "full body portrait of a beautiful fairy queen with ornate bejewelled long dress by Anna Dittmann and Rossddraws, digital art, trending on artstation, anime arts, featured on Pixiv, HD,8K, highly detailed, good lighting, beautiful, fairy, epic, masterpiece."
    },
    {
        "display": "Clever thief portrait.",
        "full": "portrait of a a clever thief with an eye mask and flat cap, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surreal fantasy digital art",
        "full": "seventh seal, fame of thrones, lord of daggers, neon, fibonacci, sweat drops, intricate fashion clothing, insane, intricate, highly detailed, surrealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Siberian girls in fantasy kimonos.",
        "full": "two beautiful alluring siberian girls wear fantasy kimono in festival | | sunny night, full moon, dreamlike art, realistic shaded, smile, good looking, hyper details, 4 k realistic, cryengine, realistic shaded lighting poster by artgerm, ross tran, fuji choko, 8 k resolution, trending on artstation, luxury"
    },
    {
        "display": "Black French bulldog gazing at Death Star.",
        "full": "a Photorealistic dramatic hyperrealistic render of a beautiful black French bulldog sitting staring out the window at a Star Wars Death Star in the night sky by WLOP,Artgerm,Greg Rutkowski,Alphonse Mucha, Beautiful dynamic dramatic dark moody lighting,shadows,cinematic atmosphere,Artstation,concept design art,Octane render,8K"
    },
    {
        "display": "Lovecraftian figure portrait",
        "full": "a striking portrait of a pitch black lovecraftian figure with black antlers and flaming red eyes by moebius and ross tran and artgerm, trending on artstation, digital art, 4 k resolution, detailed, high quality, sharp focus, hq artwork, insane detail, volumetric lighting, character concept art, fine details, clear face"
    },
    {
        "display": "Vaporwave Dubai desert scene.",
        "full": "dubai city and burj khalifa in front big desert, atmospheric lighting. vaporwave by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Death metal Trump album cover",
        "full": "( a death metal album cover ) ( ( of donald trump ) with laser eyes ) ( trending on artstation )"
    },
    {
        "display": "Spoof portrait of Steven Seagal.",
        "full": "portrait of steven seagull ( spoof of steven seagal ), detailed, centered, digital painting, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, breathtaking, 8 k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render"
    },
    {
        "display": "Anthropomorphic mushroom family portrait.",
        "full": "360 degree equirectangular, anthropomorphic mushroom family portrait, Art Deco nature, fantasy, Pixar cute character design, intricate art deco mushroom patterns, elegant, sharp focus, art by Artgerm and beeple and Greg Rutkowski and WLOP, 360 degree equirectangular, 360 monoscopic equirectangular"
    },
    {
        "display": "Ethereal female cleric portrait",
        "full": "side portrait of a beautiful female cleric, ethereal, dreamy, backlit, highly detailed, sad, mystical, intricate, realistic lighting, realistic face, sharp focus, windswept, rule of thirds, symmetrical facial features, by artgerm, wlop, rossdraws, frank frazetta, andrei riabovitchev, trending on artstation, hd, 4 k, fantasy"
    },
    {
        "display": "\"Big boy dreaming house\"",
        "full": "on the ramp, big boy, just trying to pick up the papers, i want to buy a house for my mom, and make it so that she does not need anything, white as winter, you are a hot summer classic of the genre, baby, jump into a bmw vector behance hd by artgerm, jesper ejsing"
    },
    {
        "display": "Female Drow necromancer portrait",
        "full": "Character concept portrait art of a female Drow necromancer, scythe, pretty face, long dark hair, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, tranding on artstation"
    },
    {
        "display": "Close-up portrait, vibrant detail.",
        "full": "a beautiful close - up of a young man, digital art by j. c. leyendecker, vibrant color scheme, highly detailed, in the style of romanticism, fine art, high detail, great lighting, 8 k resolution, masterpiece, concept art, illustration, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, hdr, trending on artstation, 4 k, 8 k, hd"
    },
    {
        "display": "Rusty Gundam Robot Portrait",
        "full": "highly detailed portrait of rusty gundam robot, stephen bliss, unreal engine, greg rutkowski, loish, rhads, beeple, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, alphonse mucha, global illumination, god rays, detailed and intricate environment"
    },
    {
        "display": "Young king in armor.",
        "full": "A young king in golden armor making a speech from a balcony with his servant beside him, fantasy, highly detailed, digital painting, artstation, concept art, illustration, art by Bayard Wu and Marc Simonetti"
    },
    {
        "display": "Confident black female lawyer, fantasy.",
        "full": "confident black female lawyer, D&D, fantasy litigator, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Word silhouette portrait.",
        "full": "the silhouette of a person made of words, digital art, amazing quality, very detailed, trending on artstation"
    },
    {
        "display": "Realistic closeup portrait.",
        "full": "oscar isaac, closeup portrait art by donato giancola and greg rutkowski, realistic face, digital art, trending on artstation, symmetry!!"
    },
    {
        "display": "Elegant digital portrait.",
        "full": "sansa gessica chastain, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Post-apocalyptic tribal cyborg musician",
        "full": "a centered photo of a post apocalyptic tribal cyborg playing synthesizers in the most complicated fractal and technical musical studio, powerful, cinematic, beautifully lit, by donato giancola, by artgerm, by karol bak, 3 d, perfect face and body, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Kanye West as cane toad",
        "full": "of kanye west if he was a cane toad, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Psychedelic Medusa Portrait",
        "full": "an extremely psychedelic portrait of medusa as scarlett johanson, surreal, lsd, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Cute fantasy cat warrior.",
        "full": "super cute fantasy cat warrior 3D concept art by gediminas Pranckevicius, anthropomorphic, glowing effect, ornate, dynamic, centered, sharp focus, beautiful detailed, face very realistic, Game Art!!, hyper detailed, no background, cartoon, cinematic, raytrace, Trend on artstation, C4D"
    },
    {
        "display": "Cinematic portrait of Millie Bobby Brown",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Millie Bobby Brown. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Dr. Dre as Clash Royale character",
        "full": "dr. dre with cap, at all angles clash royal style characters, unreal engine 5, octane render, detailed, brawl stars, cinematografic, cinema 4 d, un artstation trending, high definition, very detailed, blender"
    },
    {
        "display": "Cell-shaded Axel Foley portrait",
        "full": "a study of cell shaded portrait of Axel Foley 1234566666 concept art, llustration, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00edn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, alien, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Epic portrait of physicist.",
        "full": "epic portrait of slightly muscular female visionary chienshiung wu wearing physicist uniform, physics equipment, pairity, detailed, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, stron eyes, highest resolution, extremely detailed beautiful, establishing shot, artistic, hyperrealistic, enhanced hands, octane render"
    },
    {
        "display": "Cyberpunk Sui He portrait",
        "full": "front shot of a Sui He as cyberpunk, hyperdimensional, bioluminescent ethereal, ((intricate)), elegant, highly detailed, centered, (((artstation, concept art, smooth, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))),  octane render, nvidia raytracing demo"
    },
    {
        "display": "VFX portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin by eiichiro oda!, makoto shinkai, alphonse mucha, sharp focus, art by artgerm and greg rutkowski!, harsh overhead sunlight, blue eyes!!, large aquiline nose!!, ivan aivazovsky, stanley kubrick, kaoru mori, intricately detailed, behance, 4 k, hdr"
    },
    {
        "display": "Darth Vader with lightsabers",
        "full": "Darth Vader dual-wielding lightsabers, highly detailed, digital painting, Trending on artstation , HD quality, by artgerm and greg rutkowski and alphonse mucha, dramatic light, octane"
    },
    {
        "display": "Robot soldier portrait, dystopian.",
        "full": "closeup portrait shot of a robot soldier in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Goddess portrait with Mickey Mouse",
        "full": "portrait of a goddess, mickey mouse, christian bale, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Zombie punk rocker portrait",
        "full": "zombie punk rocker playing guitar, tristan eaton, victo ngai, artgerm, rhads, ross draws, intricated details, 3 / 4 view, full body portrait"
    },
    {
        "display": "Headless witch portrait.",
        "full": "full body portrait of a headless witch. detailed face, concept art, intricate, highly detailed 8 k, smooth, sharp focus, beautiful and aesthetic shape of face and body, artgerm, artstation, art by zexi guo and nira and junpei suzuki and gharliera and rinotuna"
    },
    {
        "display": "Detailed painting of Ronaldo.",
        "full": "a very detailed painting of ronaldo luis nazario de lima, by yasar vurdem by johannes wessmark, trending on artstation"
    },
    {
        "display": "Muscular Nepali butcher portrait",
        "full": "portrait painting of a muscular bloodied nepali female butcher back, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Baroque oil painting, anime maids, Darth Vader.",
        "full": "baroque oil painting of anime key visual concept art of anime maids bowing reverentially to darth vader as he walks down a red carpet, grimdark steampunk fantasy, pleasant battlefield, trending on artstation, brush strokes, oil on canvas, style of makoto shinkai and greg rutkowski and studio ghibli"
    },
    {
        "display": "Transparent glass cat.",
        "full": "a realistic transparent!!! glass! cat in the steppe, summer field, misty background, from the game pathologic 2, highly detailed, sharp focus, matte painting, by franz snyders, by frans snijders, isaac levitan and asher brown durand,"
    },
    {
        "display": "Steampunk Underwater Portrait",
        "full": "Underwater Steampunk Bioshock portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Endoskeleton artwork fusion",
        "full": "dead endoskeleton by simon bisley, photoshop, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Underwater shipwreck scene",
        "full": "underwater shipwreck surrounded by coral and dolphins, shallow depth of field, moody lighting, 8 k, concept art,"
    },
    {
        "display": "Fantasy goth castle.",
        "full": "a fantasy goth castle on hill, digital art, photorealistic, dark, smooth, sharp focus, artgerm, alphonse mucha"
    },
    {
        "display": "Creepy abandoned spaceship wreck",
        "full": "ribbed abandoned wreck of a massive giant spaceship crashed on an exoplanet, in the wastelands, dark tint, washed colors, baroque painting, standing in a desolate empty wasteland, creepy, nightmare, dream - like heavy atmosphere, surreal abandoned buildings, beautiful detailed intricate insanely detailed octane render trending on artstation, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, 8 k artistic photography, photorealistic, chiaroscuro, raphael, caravaggio, beksinski, giger, dali"
    },
    {
        "display": "Cyberpunk character portrait",
        "full": "sojourn from overwatch, african canadian, gray dread locks, gray hair, teal silver red, teal glowing cyber eyes, character portrait, portrait, close up, concept art, intricate details, highly detailed, vintage sci - fi poster, retro future, vintage sci - fi art, in the style of chris foss, rodger dean, moebius, michael whelan, and gustave dore"
    },
    {
        "display": "Hyperrealistic cyborg concept art",
        "full": "a hyperrealistic human cyborg with limbs stretched out, tied with electrical cables connected to an infinite supercomputer, flood of color and images flowing from his head, tesseract, vitruvian man, 3 d render, octane, trending on artstation, concept art, insane details"
    },
    {
        "display": "Psychedelic shaman meditation scene",
        "full": "meditating psychedelic shaman with trinket necklace, epic angle and pose, reflective pool, symmetrical artwork, ayahuasca, translucent, fungus, energy flows of water and fire, highly detailed, epic cinematic concept art, excellent composition, dystopian brutalist atmosphere, dynamic dramatic lighting, aesthetic, very inspirational, arthouse, Greg Rutkowski, Artgerm"
    },
    {
        "display": "Neon goddess portrait sculpture.",
        "full": "goddess princess face close-up portrait ram skull. sculpture made of neon lamps and metal. jellyfish phoenix head, nautilus, orchid, skull, betta fish, bioluminiscent creatures, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Muscular man carrying stone",
        "full": "a muscular man wearing torn clothes carrying a giant stone on his back, big beard, bold, hyperrealistic, concept art, octane render, unreal engine 5, trending on artstation, high quality, 8 k, anatomically correct, five fingers, digital art, symmetrical, low contrast, epic scene, cinematic, dramatic lighting"
    },
    {
        "display": "Symmetrical Assassin's Creed portrait",
        "full": "symmetry!! portrait of assasins creed kasandra in the style of horizon zero dawn, machine face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Vintage space suit woman",
        "full": "black and white woman wearing a one piece swimsuit and a gas mask, standing in outer space, vintage inspired, by artgerm"
    },
    {
        "display": "Kubernetes cloud deployment visualization",
        "full": "Kubernetes cluster deployed to a secure public cloud service, trending on Artstation, by Visual Capitalist"
    },
    {
        "display": "Detailed portrait of Phil Lamarr.",
        "full": "highly detailed closeup portrait of phil lamarr with red hair, very detailed, realistic, card, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell j."
    },
    {
        "display": "Waluigi fantasy portrait",
        "full": "a portrait of waluigi from mario bros., fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Goku portrait, detailed digital art",
        "full": "goku, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Dachshund in artistic style",
        "full": "painting black coat dachshund, by zdzislaw beksinski, by mattias adolfsson, by tiffany bozic, cold hue's, warm tone gradient background, concept art, single object scene, beautiful composition, digital painting"
    },
    {
        "display": "Scary muscular dragon warrior",
        "full": "a scary symmetrical muscular full body wearing a dragon armor with wings made of golden ornaments and gems,  by alex gray and android jones , Karol Bak, Ayami Kojima, Amano , concept art, character design, fantasy,3D, 8k resolution"
    },
    {
        "display": "Dystopian mega city scene",
        "full": "digital art, trending on artstation, dark and rainy mega city with towering walls built to block the migrants of the coming climate change migrant crisis showing piles of hundred bodies outside to maintain a quality of life for those who can survive the severe and deadly weather patterns observing small children targeted by advanced military style drones, dystopian, octane render, pbr render, highly detailed, tilt shift background, wide depth of field, 8 k, 3 5 mm film grain"
    },
    {
        "display": "Happy Mask Salesman Portrait",
        "full": "a portrait of a cinematic still of the happy mask salesman. he is smiling with ginger hair with a big backpack, he is in a clocktower, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Atlantis prince with trident.",
        "full": "a beautiful action portrait of an Atlantis prince, blue-fabric, tattoos, holding a deadly silver trident!!!!!!!!!!!!!!!, by Greg Rutkowski and Raymond Swanland, Trending on Artstation, ultra realistic digital art"
    },
    {
        "display": "Artoria Pendragon in black dress.",
        "full": "a ultradetailed beautiful panting of artoria pendragon in a black dress, by conrad roset, greg rutkowski and makoto shinkai, trending on artstation"
    },
    {
        "display": "Victorian maid reading room.",
        "full": "a victorian maid with long flowing auburn hair in a reading room. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, trending on ArtStation, digital art."
    },
    {
        "display": "Samus Aran portrait painting",
        "full": "greg manchess portrait painting of samus aran as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Cups in mountain landscape.",
        "full": "c - cups behind a beautiful mountain landscape, night setting. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Steampunk Cybercity Landscape",
        "full": "steampunk cybercity in a field of white flowers, godrays, cinematic, poster art by james gurney jesper ejsing, ilya kuvshinov, greg rutkowski frank frazzeta on artstation"
    },
    {
        "display": "Stylized portrait of Ilya Kuvshinov.",
        "full": "side profile of ilya kuvshinov with sky blue hair, long hair, sharp face, gold hazel eyes, high collar, black jacket, professional digital painting, concept art, award - winning photography, cinematic, stained glass window, wlop, art by pixiv art, yoshitaka amano, junki ito"
    },
    {
        "display": "Fantasy angel portrait",
        "full": "Angel portrait, fantasy, amber eyes, face, long hair,  intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Magical girl portrait.",
        "full": "beautiful magical girl, with red hair, wonderful eyes, over - detailed, elegant, intricate, dynamic lighting, hyperrealism, digital art, digital painting, artstation, wlop, clear focus, illustration, works by artgerm, greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Elegant bird portrait",
        "full": "portrait of little bird sansa stark with long hair, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "16th Century Florence Street",
        "full": "florence old street in 1 6 th century with a tower in background, by hiroshi yoshida, trending on artstation"
    },
    {
        "display": "Egyptian queen in futuristic armor",
        "full": "perfect white haired egyptian queen wearing white dove wings, warframe armor, regal, attractive, ornate, sultry, beautiful, dreamy, elize theron, pretty face, green eyes, detailed, scifi platform, 4 k, ultra realistic, epic lighting, android body, illuminated, cinematic, masterpiece, art by akihito tsukushi, voidstar, artgerm"
    },
    {
        "display": "Lady Gaga as Harley Quinn",
        "full": "beautiful lady gaga as harley quinn, high details, intricate details, by vincent di fate, artgerm julie bell beeple, 1 9 9 0 s, inking, vintage 9 0 s print, screen print"
    },
    {
        "display": "Shy princess at lantern festival",
        "full": "a beautiful portrait painting of a shy, blushing princess in a tiara and an iridescent art nouveau gown resembling millie bobby brown watching the lantern festival, intricate, elegant, highly detailed, digital painting, artstation, concept art, by krenz cushart and artem demura and william adolph bouguereau and alphonse mucha"
    },
    {
        "display": "Fantasy woman in kimono.",
        "full": "a photorealistic dramatic fantasy render of a beautiful woman wearing a beautiful intricately detailed japanese crow kitsune mask and clasical japanese kimono by wlop, artgerm, greg rutkowski, alphonse mucha, beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, artstation, concept design art, octane render, 8 k the seeds for each individual image are : [ 9 0 2 4 0 9 7 9 7, 5 8 9 3 0 3 0 4 4, 2 8 8 5 1 1 6 2 1, 3 5 9 8 0 2 1 5 6 6 ]"
    },
    {
        "display": "Detailed painting of Rin Tohsaka",
        "full": "a ultradetailed beautiful panting of rin tohsaka with flowing hair, by conrad roset, greg rutkowski and makoto shinkai, rin, fate, trending on artstation"
    },
    {
        "display": "Pretty character with keyblade.",
        "full": "loish, artgerm, joshua middleton art, rafeal albuquerque, pretty sora holding the artemis keyblade, realistic hands, sarcastic smile, symmetrical eyes, symmetrical face, jean jacket, jeans, short hair, middle shot, night time, deep blacks"
    },
    {
        "display": "Vibrant Fallout Dream Artwork",
        "full": "fallout dream concept art, vibrant colors, 8 k photorealistic, hd, high details, trending on artstation"
    },
    {
        "display": "KSI as a fantasy character",
        "full": "KSI wearing a bandana, closeup, D&D style, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Boxing toast character art",
        "full": "anthropomorphic toast character as a boxer ; magic : the gathering fantasy character concept art by frank frazetta and marco bucci, high resolution. boxing ring in the background, dramatic stadium lighting, fantasy coloring, intricate, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Bald tribal elf portrait.",
        "full": "highly detailed portrait of eva green as a bald tribal elf, in skyrim, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Retrofuturistic portrait of Paris Hilton",
        "full": "retrofuturistic portrait of paris hilton, space graphics art in background, close up, wlop, dan mumford, artgerm, liam brazier, peter mohrbacher, 8 k, raw, featured in artstation, octane render, cinematic, elegant, intricate, 8 k"
    },
    {
        "display": "Futuristic cyberpunk girl.",
        "full": "elysium movie still cute young girl motion blur cyberpunk futuristic neon tattoos styled hair piercing reflective puffy sheen film jacket, large array decorated traditional ornaments illustrated by ismail inceoglu dragan bibin hans thoma greg rutkowski alexandros pyromallis nekro alphonse mucha artgerm perfect face, complex cybernetic beings, glowing hair, vortexes, cinematic light shadows, wet hdr refractions, insanely detailed rendering, artstation, 8 k, * * * * *, fine - face, pretty face"
    },
    {
        "display": "Gorgeous woman at beach",
        "full": ", a gorgeous hulking  woman with very long hip-length blonde hair, happy sunny day, wearing a cut-off white top and red dirt cut-off shorts standing by the water, beach tennis, modern architecture, in the style of artgerm and moebius and annie liebovitz, marvel comics, photorealistic, highly detailed, trending on artstation, Gediminas Pranckevicius"
    },
    {
        "display": "Vampire portrait, bloody elegance.",
        "full": "portrait of riley keough in bloody business suit, blood red eyes, vampire fangs, fantasy, intricate, elegant, highly detailed, digital painting, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Dunmer Nerevarine Portrait",
        "full": "portrait of the male nerevarine dunmer with red irises dark skin and white hair and wearing glass armor, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, octane render, unreal engine, art by swietopelk and rebelzize and greg rutkowski, epic fantasy, digital painting, dark elf, dunmer, morrowind, oblivion, skyrim, daedric armor, morag tong, dark brotherhood, male"
    },
    {
        "display": "Retro-futuristic cityscape closeup",
        "full": "a beautiful picture of a city with a closeup of a street, a matte painting by james gilleard, geometric lines, pixiv contest winner, retrofuturism, cityscape, 2 d game art, low poly"
    },
    {
        "display": "Diesel punk astronaut portrait.",
        "full": "a concept art portrait of masked diesel punk astronaut on the art deco streets of the big city, artstation, award - winning realistic sci - fi concept art by jim burns and greg rutkowski, beksinski, a realism masterpiece, muted color palette, james gilleard, bruegel, alphonse mucha, and yoshitaka amano"
    },
    {
        "display": "Raccoon in hot rod",
        "full": "cute and funny, racoon wearing a helmet riding in a tiny hot rod with oversized engine, ratfink style by ed roth, centered award winning watercolor pen illustration, isometric illustration by chihiro iwasaki, edited by olga shvartsur, tiny details by artgerm, symmetrically isometrically centered"
    },
    {
        "display": "Nepali witch portrait",
        "full": "A beautiful portrait of a pretty Nepali witch holding a pumpkin, vibrant color scheme, highly detailed, in the style of romanticism, cinematic, character portrait, epic fantasy, 3d with depth of field, blurred background, female, nautilus. A highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, stylized, very inspirational. Golden hour. detailed. hq. realistic. warm light. muted colors. Moody. Filmic. Dreamy."
    },
    {
        "display": "Cute goth succubus character",
        "full": "advanced digital anime art, a very cute and gorgeous succubus , goth dress ,full body, very long wavy fiery red hair, braided hair, white highlights in hair, fiery red watery eyes, full round face, amazing depth, highly detailed, trending on pixiv, Artstation, DeviantArt, Steven Artgerm Lau, WLOP, RossDraws, RuanJia, James Jean, Andrei Riabovitchev, Totorrl, Marc Simonetti, Laura Sava, Visual Key, and Sakimichan"
    },
    {
        "display": "Cute cat girl portrait.",
        "full": "nekopara fantastically detailed eyes reflecting eyes modern anime style art cute detailed ears cat girl neko dress portrait  shinkai makoto vibrant Studio ghibli kyoto animation hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv smiling"
    },
    {
        "display": "Hyper-realistic jungle portrait",
        "full": "a beautiful portrait of august schellenberg taking tobacco snuff in the jungle, hyper realistic face, fantasy art, in the style of greg rutkowski, intricate, matte painting, hyper detailed, smooth"
    },
    {
        "display": "Fantasy portrait of Finnish Empress",
        "full": "finnish empress, isabelle grill, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Fantasy beach camp scene",
        "full": "Small camp with tents at the pristine beach. In style of Greg Rutkowski, Jesper Ejsing, Makoto Shinkai, trending on ArtStation, fantasy, great composition, concept art, highly detailed, scenery, 8K, Behance."
    },
    {
        "display": "Putin as Homer Simpson",
        "full": "vladimir putin as homer simpson from the simpsons, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Eerie butcher portrait on horse",
        "full": "portrait painting of a female butcher wearing bikini and riding a horse, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Captain Hook in Neverland",
        "full": "Color concept art of actor Cedric Hardwicke in Neverland as Captain Hook, Giant Crocodile, Lost Boys, Peter Pan, Pirate\u2019s Ship, Tinkerbell, Mermaids, Trending on Artstation, Detailed"
    },
    {
        "display": "Baroque anime trench scene",
        "full": "baroque oil painting of anime key visual concept art of anime hatsune miku operating mortar 1 9 4 0 trenches colorized, trending on artstation, palette knife and brush strokes, oil on canvas, makoto shinkai greg rutkowski studio ghibli"
    },
    {
        "display": "Surreal anime nightmare illustration.",
        "full": "paralytic dreams electric visions in nightmares, the all seeing eye in the forehead of reality, a fire of a million guns, the mother of a millions sounds, god emperor of civilization illustration trending on artstation, anime. by peter mohrbacher and artgerm and greg rutkowski and studio trigger and ilya kuvshinov. high quality, 8 k"
    },
    {
        "display": "Cyborg girl concept art",
        "full": "cyborg girl kawaii, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k"
    },
    {
        "display": "Madison Beer in knight armor.",
        "full": "portrait of madison beer wearing a skintight knight armor, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "High-detail video game weapons",
        "full": "video game halo fortnite unreal engine new weapons all attachments development digital painting high detail sharp 4 k 8 k trending on artstation"
    },
    {
        "display": "Cyborg androgynous humanoid portrait.",
        "full": "realistic photography of a beautiful cyborg androgynous humanoid, shoulder, elbow, holding close, in liquid, intricate filigree, in the style of beth cavener, jin kagetsu, wlop, highly detailed, symmetry, masterpiece, concept art, ringflash, highkey lighting, ambient lighting, octane render, 8 k, artstation"
    },
    {
        "display": "Demonic and angelic women portrait",
        "full": "portrait of demonic woman and angelic woman in armour, confident pose, pixie, genshin impact, intricate, elegant, golden ratio, sharp focus, soft bokeh, illustration, highly detailed, concept art, matte, trending on artstation, bright colors, art by wlop and artgerm and greg rutkowski, mucha, giger, marvel comics"
    },
    {
        "display": "Capybara on motorcycle at sunset.",
        "full": "a closeup photorealistic photograph of a knitted cute Capybara wearing stylish sunglasses and dressed in a beanie cap and riding on a motorcycle in Hollywood at sundown. Palm trees in the background. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and the style of Unreal Engine."
    },
    {
        "display": "Futuristic Jupiter cityscape illustration",
        "full": "futuristic Jupiter cityscape,western, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Bob Ross painting Superman.",
        "full": "a closeup photorealistic photograph of bob ross working on a canvas painting of superman. film still. brightly lit scene. mountains and trees. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Mecha-turtle battle scene",
        "full": "mecha-turtle battle in neo-Tokyo, by Moebius and Studio Ghibli, trending on artstation"
    },
    {
        "display": "Happy Mask Salesman Portrait",
        "full": "a portrait of a cinematic still of the happy mask salesman, ginger, wringing hands, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and takaya imamura, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Brutal war mechanics fusion.",
        "full": "war scene, Extremely complicated Mechanics interwined with human body, tearing, explosions, blizzard, brutal, dark, concept art, jakub rozalski"
    },
    {
        "display": "Swamp witch portrait, Emma Watson.",
        "full": "mindblowing portrait of emma watson as a swamp witch, green colored skin!!, messy hair, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Melanie C as Harley Quinn.",
        "full": "portrait of Melanie C as a harley quinn in Suicide Squad. octane render, trending on artstation, very coherent, symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Tattooed warrior in emerald.",
        "full": "a close - up of tattooed beautiful female warrior wearing a magical emerald leotard in a magical forest, hyper realistic face, green eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, vibrant"
    },
    {
        "display": "Muscular DJ in neon portrait",
        "full": "portrait of daft punk mixing music, muscular, headphones, neon, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pumpkin-headed market scene.",
        "full": "pumpkin headed people selling goods at a market in a vermont town square, fall foliage, cobblestone streets, new england colonial buildings, intricate details, sharp focus, digital art, hyper realistic, 4 k, unreal engine, highly detailed, hd, dramatic lighting by brom, based on over the garden wall pottsfield, trending on artstation"
    },
    {
        "display": "Moe Simpson in mafia style",
        "full": "clear portrait of moe from the simpsons, cottagecore!!, mafia background hyper detailed, character concept, full body, dynamic pose, intricate, criminal appearance, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Selena Gomez portrait, Artgerm style",
        "full": "portrait of Selena Gomez in the style of Artgerm"
    },
    {
        "display": "Elegant Poison Ivy Portrait",
        "full": "a beautiful painting of emma watson and christina hendricks dressed as a poison ivy, dark eyeliner, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by rebecca guay and by arthur rackham and by alphonse mucha and by john william waterhouse"
    },
    {
        "display": "Evil Spellcasting Cat",
        "full": "Oil Painting of Cat, Anthropomorphized, casting evil spell, magic the gathering artwork, horror, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Robosaurus in datacenter.",
        "full": "robosaurus parallax datacenter server room interior single mono colossus white rusty robot sitting artstation cinematic detailed concept art ultra realistic volumetric light sharp coherent cgsociety symmetric perfect well balanced shadows lotr technogoddess sidwill glass plastic carbon"
    },
    {
        "display": "Mystical fairy forest scene.",
        "full": "fairies in a mystical magic forest, cinematic scene, studio lighting, colorful, candy, fantasy, fireflies, flowers, matte painting, concept art, medium shot, trending on artstation,"
    },
    {
        "display": "Dream realm gate opening.",
        "full": "the gate to the realm of dream is opening for us, bringing the peace to the souls, dynamic lighting, cinematic lighting, lit by morning light, by makoto makoto, krenz cushart and ilya kuvshinov and artgerm, anime, featured on artstation, ultrawide angle"
    },
    {
        "display": "Hyper-detailed Miku Hatsune portrait",
        "full": "miku hatsune posing, extremely detailed eyes, fantastic details full face, close - up shot, full body shot, epic cinematic shot, trending on artstation, pixiv, cgsociety, award winning, hyperdetailed unreal engine 4 k 8 k ultra hd, stanley artgerm lau, wlop, rossdraws, james jean marc simonetti ruan jia and mandy jurgens and artgerm and william - adolphe bouguerea sakimichan"
    },
    {
        "display": "Fantasy archaeology site illustration",
        "full": "archeology site, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Portrait of Rose Blackpink.",
        "full": "\u00ef\u00bf\u00bd a portrait of rose blackpink, rainy background, pink bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, hearthstone card game artwork. \u00ef\u00bf\u00bd"
    },
    {
        "display": "Anime girl in sunflower field.",
        "full": "girl with a sunhat, holding a sunflower, in field of sunflowers, blue sky | | very very anime!!!, fine - face, audrey plaza, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Steampunk naval bombardment scene",
        "full": "classical oil painting of anime key visual environment concept art of naval bombardment breaching castle walls, smoke debris, grimdark steampunk fantasy, trending on artstation, brush strokes, oil, canvas, style of kawacy makoto shinkai jamie wyeth james gilleard edward hopper greg rutkowski, preserved historical"
    },
    {
        "display": "Shiba Inu Samurai Warrior",
        "full": "shiba inu samurai warrior as a league of legends character, michael maurino, alex flores, paul kwon, cinematic, highly detailed, concept art, 3 d cgi, dramatic lighting, focus, smooth, heroic, hyper realistic background, in the style of league of legends, lol"
    },
    {
        "display": "Aesthetic girl in city.",
        "full": "extremely beautiful aesthetic girl with half black and half white jacket on the top of the city, full body, occlusion shadow, specular reflection, rim light, unreal engine, artgerm, artstation, art by hiroaki samura and jiro matsumoto and yusuke murata, high quality, intricate detailed 8 k, beautiful shape of face and body"
    },
    {
        "display": "Lara Croft Spiderwoman Illustration",
        "full": "Lara croft as spiderwoman, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, artgerm, rutkowski, mucha"
    },
    {
        "display": "Steampunk underwater portrait",
        "full": "underwater lofi bioshock steampunk portrait in space, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Steampunk lighthouse garden scene",
        "full": "steampunk lighthouse, blue and pink bonsai tree, inside a marble, flower garden, intricate detail, hyper detailed, ultra realistic, sharp focus, octane render, lantern candle, butterfly, volumetric, ray tracing, artstation trending, moon,, fairy cgsociety, sense of awe, swirling mist, 4 k"
    },
    {
        "display": "Dramatic portrait of Taylor Swift",
        "full": "a photorealistic dramatic hyperrealistic portrait render of taylor swift, ultra realistic details, well worn, rust, oil stains by wlop, greg rutkowski, alphonse mucha vitaly bulgarov and mike nash, beautiful dramatic dark moody tones and lighting, cinematic atmosphere, studio lighting, global illumination, shadows, dark background, concept design art octane render, 8 k"
    },
    {
        "display": "Demon woman portrait, dystopian.",
        "full": "closeup portrait shot of a demon woman in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Bloodied skeleton woman portrait",
        "full": "woman skeleton covered with blood, alexandra daddario face, long red hair, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. retro film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy archeology portrait",
        "full": "archeology site, highly detailed, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Retro sci-fi pagoda scene",
        "full": "cinematic view of a retro scifi pagoda with crowds, desaturated, psychedelic, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Hyper-realistic spider monster.",
        "full": "pale skin spider monster by neville page, ken barthelmey, carlos huante and doug chiang, sharp focus, trending on artstation, hyper realism, octane render, 8 k, hyper detailed, ultra detailed, highly detailed, zbrush, concept art, creature design"
    },
    {
        "display": "Anime-style baroque warrior illustration",
        "full": "baroque oil painting japanese light novel cover illustration lotr anime key visual full body action shot | young girl black military crusader armour with long flowing brown hair | symmetrical perfect face fine detail delicate features quiet gaze | moody lighting bokeh | trending pixiv fanbox | greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Spider-Man 2099 portrait",
        "full": "a portrait of spider - man 2 0 9 9, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Half-elven man portrait",
        "full": "character portrait of a slender young half elven man with white hair, piercing blue eyes, and pale blue skin, by greg rutkowski and mark brookes and jim burns and tom bagshaw and magali villeneuve, trending on artstation"
    },
    {
        "display": "Beautiful anime chess girl",
        "full": "a very beautiful young anime chess girl, full body, long wavy blond hair, sky blue eyes, full round face,, bikini, miniskirt, front view, mid - shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "Cyberpunk orc shaman portrait",
        "full": "portrait painting of a cyberpunk orc shaman extremely muscular ugly elizabeth olsen, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Steampunk portrait, Pixar style.",
        "full": "lofi instagram bioshock steampunk portrait, digital art, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Cinematic hybrid character portrait",
        "full": "characters portrait of Revan mixed with Moon knight by ArtGerm and Tom Bagshaw, merged character, Full body shot, cinematic opening shot, 4k, highly detailed, cinematic lighting"
    },
    {
        "display": "Anime girl portrait, night setting.",
        "full": "a potrait of anime girl, my dress up darling anime, fine details, night setting, realistic shaded lighting poster by ilya kuvshinov katsuhiro, artgerm, jeremy lipkin and michael garmash, unreal engine 5, radiant light, detailed and intricate environment"
    },
    {
        "display": "Wendigo-infected Bowie poster",
        "full": "twin peaks poster art, david bowie is infected with the spirit of the wendigo demon, old retro pulp, by michael whelan, rossetti bouguereau, artgerm, retro, nostalgic, old fashioned"
    },
    {
        "display": "McLaren-designed minivan concept",
        "full": "a minivan designed by mclaren automotive, highly detailed, 8 k, sharp focus, depth of field, finely detailed car, high contrast, concept art, unreal engine"
    },
    {
        "display": "Yeezy x Golf Le Fleur photoshoot",
        "full": "A photoshoot of a Yeezy x Golf Le Fleur collaborative clothing brand, vintage camera, dreamy, atmospheric, golden hour, cinematic lighting, 8K concept art"
    },
    {
        "display": "Photorealistic griffon art",
        "full": "griffon, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Cinematic stormy singularity silhouette",
        "full": "sad singularity silhouette, octane renderer, stormy weather, atmospheric establishing shot, rain, night, film grain, cinematic render, end of the world, environment art trending on ArtStation,"
    },
    {
        "display": "Cinematic sci-fi landscape.",
        "full": "concept art of no man's sky by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, vibrant colors,"
    },
    {
        "display": "Ultra-detailed Rick Grimes portrait.",
        "full": ", ultra detailed close up facial portrait of rick grimes, extremely detailed digital painting, in the style of fenghua zhong and ruan jia and jeremy lipking and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Female in office attire.",
        "full": "Female in office dress. By Rembrandt and artstation trending"
    },
    {
        "display": "Vaporwave girl illustration",
        "full": "beautiful young girl vaporwave aesthetic, synthwave, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Evil hero character design",
        "full": "evil hero malicious bane, heroine, beautiful, character concept, intricate complexity, in the style of artgerm and ilya kuvshinov, magic the gathering art"
    },
    {
        "display": "Elegant Moon Goddess Illustration",
        "full": "Goddess of the Moon, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, ArtStation, art by artgerm Aykut Aydogdu and greg rutkowski and alphonse mucha and J. C. Leyendecker and Edmund Blair Leighton and Charlie Bowater"
    },
    {
        "display": "Concept art of animated flames",
        "full": "living fires concept art"
    },
    {
        "display": "Cute anime woman in water dress",
        "full": "3D advanced digital art, a very cute and gorgeous anime woman wearing a dress made of water , full body, very long wavy azure blue hair, braided hair, azure blue watery eyes, full round face, japanese beautiful cute young J-Pop idol actress girl face, cinematic lighting, mid-shot, highly detailed, trending on pixiv, Artstation, DeviantArt, NicoVideo, Steven Artgerm Lau, WLOP, RossDraws, RuanJia , James Jean, Andrei Riabovitchev, Totorrl, Marc Simonetti, Visual Key, and Sakimichan"
    },
    {
        "display": "Hardcore DJ at festival",
        "full": "detailed artwork of a  hardcore music dj at an stage festival rave, in the style of art of Artgerm and Greg Rutkowski and Alphonse Mucha, headphones, sunglasses,  stage  speakers"
    },
    {
        "display": "Goddess portrait with mythical elements.",
        "full": "goddess close-up portrait. jellyfish phoenix head, nautilus, butterfly, skull, ice and fire, bioluminiscent creatures, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Cute bear in forest.",
        "full": "cute cartoon bear in forest, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Cosmic Encounter Board Game Design",
        "full": "cosmic encounter board game with a new beautiful design, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Elegant portrait of Rowling and McIntyre.",
        "full": "j k rowling & michael mcintyre with a sardine can, elegant, real life skin, intricate, high detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski"
    },
    {
        "display": "Colorful deep sea creatures.",
        "full": "of a colorful deep sea cavern with strange cute friendly creatures with huge eyes, mouth, long tongue and round teeth appearing from the cloudy sandy coral, in the style of gehry and gaudi, macro lens, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Bill Gates with syringes, body horror.",
        "full": "bill gates full body portrait, piles of syringes behind him, body horror, by junji ito and francis bacon, hunter s thompson feeling of grimdark, sharp focus, fiction, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5"
    },
    {
        "display": "Iron Man Super Saiyan Art",
        "full": "ironman going supersayain, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Elf woman portrait, VR headset.",
        "full": "portrait of an elf woman with elf ears wearing a valve index, dramatic lighting, illustration by Greg rutkowski, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Tattooed Margot Robbie in fantasy.",
        "full": "margot robbie portrait with a tattooed body posing in a magical crochet bikini in a magical forest, beautiful eyes, realistic face, full body, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Cosmic horror battle witch.",
        "full": "warhammer 4 0 k battle witch, cosmic horror painting, elegant intricate digital painting artstation concept art by mark brooks and brad kunkle extreme detail 4 k"
    },
    {
        "display": "Epic female viking medic portrait",
        "full": "epic portrait, an female viking medic, glossy lips, pretty, long ginger hair, puppy eyes, digital painting, artstation, concept art, soft light, hdri, smooth, sharp focus, illustration, fantasy, intricate, elegant, highly detailed, D&D, matte painting, in the style of Greg Rutkowski and Alphonse Mucha and artemisia, 8k,"
    },
    {
        "display": "Cyberpunk Delorean in ruins",
        "full": "highly detailed photorealistic rendering of a delorean parked on the streets of a cyberpunk abandoned city with the door open, futuristic post - apocalyptic vibe, by greg rutkowski and stanley artgerm and alphonse mucha, octane, sharp focus, hyperrealistic, unreal engine 5, vray, masterpiece"
    },
    {
        "display": "Santa vs. Krampus battle",
        "full": "santa battles krampus, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Elegant modern Darna portrait",
        "full": "portrait of modern darna, elizabeth olsen, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by wlop, mars ravelo and greg rutkowski"
    },
    {
        "display": "Sci-fi fantasy matte painting",
        "full": "sci - fi, a detailed matte painting by eddie mendoza, cgsociety, fantasy art, reimagined by industrial light and magic, matte painting, concept art"
    },
    {
        "display": "Graffiti spelling realization.",
        "full": "that moment when you realize grafitti is a hard word to spell, by cameron gray, wlop, stanley kubrick, masamune, unique perspective, epic, trending on artstation, photorealistic, 3 d render, vivid"
    },
    {
        "display": "White Stag in Forest",
        "full": "White Stag in the distance, lush green deep forest, cold light, art nouveau, 4K, character concept art, oil painting, trending in artstation, cgsociety, by nekro, Alphonse Mucha, Artgerm, WLOP, Greg Rutkowski"
    },
    {
        "display": "Rugged lizard portrait, fantasy.",
        "full": "''face portrait of a rugged lizard, fantasy, mountain landscape, d & d, digital painting, artstation, deviantart, concept art, illustration, art by dragolisco and anne stokes and nico niemi''"
    },
    {
        "display": "Anime mermaid character design",
        "full": "beautiful concept art, character design sheet, beautiful anime style gingerhead young girl mermaid with a gorgeous faces, high fashion, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, beautiful light and shadows, art by artgerm and greg rutkowski and lin hsiang and krenz cushart and wlop"
    },
    {
        "display": "Hobbit caricature in tracksuits",
        "full": "a detailed 5 0 mm portrait of dominic monaghan and billy boyd as hobbits squatting slavs in black adidas track suits with white stripes down the leg, caricature, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by met mangindaan, artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Futuristic post-apocalyptic helmet.",
        "full": "a futuristic post apocalypse helmet highly detailed, digital painting, concept art, smooth, sharp focus, illustration, art by greg rutkowski"
    },
    {
        "display": "Biopunk Darwin in lab",
        "full": "duotone retofuturistic ghastly scifi 3 / 4 portrait of biopunk charles darwin in genetic lab. scifi fretro future atmosphere. cinematic scene volumetric lighting. golden rario accidental renaissance. concept by stanley kubrick dr strangelove style and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Mystical mechanical hunter in fog.",
        "full": "idyllic poster of a beautifully strange rendering of mystical mechanical hunter in mask walking in foggy dangerous forest. in dieselpunk style. award winning. dramatic. trending on artstation. high quality. rendered by beeple, by makoto shinkai, syd meade, space art concept, sci - fi, digital art, unreal engine, 4 k uhd image, octane render"
    },
    {
        "display": "Futuristic Batman oil painting",
        "full": "futuristic batman, brush strokes, oil painting, greg rutkowski"
    },
    {
        "display": "\"Control freak moderator art\"",
        "full": "a human moderator chewing on the arbitrary egregore of their community group, art depicting control freak, trending, artstation"
    },
    {
        "display": "Cinematic dryad portrait.",
        "full": "dryad, perfect face, gold waistcoat, cinematic, stunning, highly detailed, digital painting, artstation, smooth, hard focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic spiral galaxy concept art",
        "full": "concept art of an epic spiral galaxy in style of dan mumford and laurie greasley by james gilleard, very detailed, clean lines, atmospheric, masterpiece"
    },
    {
        "display": "Chinese cast in Star Trek",
        "full": "star trek but the cast are all chinese, real life skin, intricate, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski"
    },
    {
        "display": "Gigachad Luigi Bodybuilder",
        "full": "gigachad luigi bodybuilder in the factory by ilya kuvshinov, ernest khalimov body by krista sudmalis, super mario bros symmetrical face concept art, hyper realistic, intricate, elegent, highly detailed, digital painting, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Chili Peppers Recording Session",
        "full": "red hot chilli peppers recording stadium arcadium, highly detailed, sharp focus, 8 k, backlit, elegant, contept art, dramatic ligthing"
    },
    {
        "display": "Geralt of Rivia portrait",
        "full": "Very detailed masterpiece painting of Geralt of Rivia, portrait, artstation, concept art by Greg Rutkowski"
    },
    {
        "display": "Warrior in devastated forest",
        "full": "a warrior returning from battle, destroyed forest, digital art, expressive oil painting, sadness, by Artgerm, by Jeremy Lipkin"
    },
    {
        "display": "Cyberpunk festival scene",
        "full": "junk open air festival with cybertronic gadgets and vr helmets, durty colours, rotten textures, rusty shapes, biotechnology, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Neon-lit woman in Tron.",
        "full": "tron legacy scenery of a woman in a neon blue matte black outfit, full shot, atmospheric lighting, detailed faces, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Fantasy warrior portrait",
        "full": "brown haired warrior, full body portrait, rugged, muscular, bearded, male, city landscape, norway, d & d, fantasy, intricate, elegant, highly detailed, digital painting, brown and gold color palette, artstation, octane render, concept art, matte, sharp focus, illustration, herrarthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ornate symmetrical door.",
        "full": "symmetry!! the ornate door to the purgatory, very detailed, intricate details, complimentary colors, perfect lighting, perfect composition, aesthetic, masterpiece, award winning, artstation, darek zabrocki, greg rutkowski, artgerm, 4 k"
    },
    {
        "display": "Nina Dobrev Portrait, Digital Art",
        "full": "a portrait of a beautiful nina dobrev, art by ilya kuvshinov and wlop and artgerm and josan gonzalez, magda torres gurza, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Necromancer priest invoking Cthulhu",
        "full": "portrait of necromancer priest in an invoking ritual in front of a giant cthulhu in a large landscape, intricate, elegant, glowing lights, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, wide - angle portrait, atmospheric lighting, rich deep colors masterpiece, fractal crystals"
    },
    {
        "display": "\"Vibrant skull-faced girl\"",
        "full": "star wars portrait of a girl skull face, in the style of artgerm, charlie bowater, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Gorgeous Scottish woman portrait.",
        "full": "a gorgeous scottish female photo, professionally retouched, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, sharp focus on eyes, 8 k, high definition, insanely detailed, intricate, elegant, art by artgerm and jason chan"
    },
    {
        "display": "Cute Star Trek officer",
        "full": "cute star trek officer luke thomas, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Emma Stone Sci-Fi Portrait",
        "full": "emma stone, character portrait, portrait, close up, concept art, intricate details, highly detailed, vintage sci - fi poster, retro future, vintage sci - fi art, in the style of chris foss, rodger dean, moebius, michael whelan, and gustave dore"
    },
    {
        "display": "Cyberpunk character portrait",
        "full": "a portrait of a character in a scenic environment by ayami kojima, hyperdetailed, cyberpunk, cool, cybernetically enhanced, trending on artstation"
    },
    {
        "display": "Horror-themed Doctor Strange art",
        "full": "doctor strange in the multiverse of madness, horror art by beksinski and szukalski and giger and and pyromallis and dzo and iris compiet and seb mckinnon, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Maggie Smith as Shakespeare.",
        "full": "Maggie Smith wearing a dark purple suit and acting Shakespeare, elegant, digital painting, concept art, smooth, sharp focus, illustration, from StarCraft by Ruan Jia and Mandy Jurgens and Artgerm and William-Adolphe Bouguerea"
    },
    {
        "display": "Evil cat portrait illustration",
        "full": "portrait of an evil cat plotting against humanity, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute woman portrait, detailed.",
        "full": "a portrait of a cute woman, brown hair, wavy hair, blue eyes, wearing a white blouse, button nose, oval face, soft facial features, strong chin, chubby cheeks, cute lips, very detailed digital art, dynamic lighting, moody atmosphere, dark background, piercing gaze, arched brows, wide smile, 4 k portrait art by artgerm, greg rutkowski, craig mullins"
    },
    {
        "display": "Tribal warrior portrait.",
        "full": "a grey - skinned warrior man with tribal tattoos and an undercut ponytail, realistic shaded, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Crimson-black honeycomb art",
        "full": "crimson - black honeycomb pattern, clean centered concept art, intricate, photorealistic, octane render, rtx, hdr, unreal engine, dnd digital art by artgerm"
    },
    {
        "display": "Fantasy male fighter illustration",
        "full": "Fighter, male, fantasy, dramatic, intricate, elegant, highly detailed, digital painting,  artstation, concept art, smooth, sharp focus, illustration, art by Viktor Berendeev, octane render"
    },
    {
        "display": "Naga Druid Spellcasting Illustration",
        "full": "Naga snake druid casting spell forest background, D&D, artstation, fantasy, magic the gathering artwork, cinematic lighting, centered, symmetrical, highly detailed, digital painting, , concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Gloomy ruined server room with robot.",
        "full": "gloomy colossal ruined server room in datacenter robot figure automata headless robot knight welder posing pacing fixing soldering mono sharp focus, emitting diodes, smoke, artillery, sparks, racks, system unit, motherboard, by pascal blanche rutkowski repin artstation hyperrealism painting concept art of detailed character design matte painting, 4 k resolution blade runner"
    },
    {
        "display": "Anime mermaids in prison",
        "full": "baroque oil painting of key visual portrait concept art of anime mermaids in a underwater prison, brutalist, dark fantasy, rule of thirds golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai studio ghibli genshin impact jamie wyeth james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "Khorne Portrait, Detailed Fantasy",
        "full": "khorne, god of blood, extreme detail portrait dnd, painting by gaston bussiere, craig mullins, greg rutkowski, yoji shinkawa"
    },
    {
        "display": "Futuristic cyberpunk knight portrait",
        "full": "portrait of evilly and strong stunning futuristic knights of Zodiac girl, black and copper armor, in futuristic heavily raindrop tokyo rooftop cyberpunk night, ssci-fi, fantasy, intricate, very very beautiful, elegant, neon light, highly detailed, digital painting, artstation, concept art, human anatomy, soft light, hdri, smooth, sharp focus, illustration, art by tian zi and craig mullins and  WLOP and alphonse mucha"
    },
    {
        "display": "Mountain temple waterfall scene",
        "full": "carved into a Mountain a temple above a waterfall, arches adorned pillars, archways, gnarly trees, lush vegetation, forrest,  a small stream runs beneath the waterfall, landscape, raphael lacoste, eddie mendoza, alex ross, concept art, matte painting, highly detailed, rule of thirds, dynamic lighting, cinematic, detailed, denoised, centerd"
    },
    {
        "display": "Androgynous character concept art",
        "full": "artstation, concept art, style kim jung gi, androgynous face, female, human, brown suit vest, longcoat, glasses, short hair, front view, highly detailed, digital art"
    },
    {
        "display": "Elven woman concept art",
        "full": "d & d concept art of gorgeous elven woman with raven black hair in the style of stefan kostic, realistic, half body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, foggy backgeound, fantasy"
    },
    {
        "display": "\"Futuristic healing civilization tree\"",
        "full": "high depth, collective civilization tree, calm, healing, resting, life, hybrids, scifi, glowing lights, published concept art, art in the style of all and none and everything and infinity"
    },
    {
        "display": "Girl and giant wolf",
        "full": "girl facing a giant white wolf, manga cover, league of legends, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, sharp focus, illustration, highly detailed, concept art, matte, trending on artstation, anime, art by wlop and artgerm and greg rutkowski, h 6 4 0"
    },
    {
        "display": "Cybernetic hybrid hunting scene",
        "full": "higly detailed award winning concept art of a family of 5 cybernetic cambrian era horseshoe crab octopus crow hybrids hunting for electric eels by andy warhol and moebius and greg rutkowski, dayglo pink, dayglo blue, glowing, prismatic, rendered in octane, unreal engine, trending on artstation"
    },
    {
        "display": "Colorful underwater fantasy scene.",
        "full": "coral underwater colorful, fantasy, intricate, highly detailed, little fish and sea life digital painting, hd, trending on artstation, illustration, fine lines, sharp edges, colourful,"
    },
    {
        "display": "Epic frostpunk landscape.",
        "full": "frostpunk portal, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Elegant Norwegian woman portrait",
        "full": "a gorgeous norwegian female photo, professionally retouched, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, sharp focus on eyes, 8 k, high definition, insanely detailed, intricate, elegant, art by artgerm and jason chan and mark hall"
    },
    {
        "display": "Muscular blacksmith character.",
        "full": "the vlacksmith, very strong, muscular, big hammer, anvil, beard, cheeky, coloured, fame of thrones, fibonacci, sweat drops, intricate fashion clothing, insane, intricate, highly detailed, surrealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Buff Ellen Ripley Portrait",
        "full": "buff ellen ripley, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Colorful male wizard portrait",
        "full": "short spiky brown hair and glasses male wizard wearing blue robe, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Cyberpunk male druid.",
        "full": "male anime character, oni mask, organic, forest druid, business suit ultra realistic illustration of a retro futuristic male cyborg punk art, male anime character, robot, masterpiece, intricate, highly detailed, sharp, technological rings, by james mccarthy, by beeple and johfra bosschart, combination in the style ayami kojima, highly detailed, painting, 3 d render beeple, unreal engine render, intricate abstract. nouveau filgree scrollwork, intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents, albedo from overlord, the library of gems, intricate abstract. intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Homeless woman, wild cat.",
        "full": "a homeless woman eating lunch with a wild cat, perfect shading, atmospheric lighting, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Retro sci-fi cave scene",
        "full": "cinematic view of a retro scifi cave, robots, desaturated, psychedelic, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Fantasy merchant store illustration",
        "full": "A merchant selling treasuries and potions, high detailed store, black and white, fantasy art, in the style of masami kurumada, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Detailed deep learning concept art",
        "full": "deep learning network, concept art, high details, 8k"
    },
    {
        "display": "Southern woman as Nami",
        "full": "a southern woman as nami from one piece, simple cream dress, detailed perfect face, mid view, by artgerm, by studio muti, greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Masked man in blue hood",
        "full": "ultra realistic illustration, man with black hair with a black mask, in blue hood, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Henry VIII Eating Hamburgers",
        "full": "portrait of henry viii eating hamburgers, extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Emma Watson in Assamese silk dress.",
        "full": "emma watson wearing assamese silk bihu mekhela strapless costume maxi style dress by wlop and madeleine vionnet, assamese gamosa pattern, face by artgerm concept art 3 d octane render cinema 4 d v ray, unreal engine, hyper realistic hdr fabric textures, ray traced, cinematic studio fashion photography"
    },
    {
        "display": "Futuristic desert cityscape",
        "full": "futuristic dystopian cityscape with towering skyscrapers in the desert by john blanche, michael kirkbride, high detail, very intricate, 4 k, 8 k, artstation, blender, octane, unreal engine, video game concept art, volumetric lighting"
    },
    {
        "display": "Gorgeous woman in dome.",
        "full": "portrait of a gorgeous young woman trapped in a transparent dome, bubble, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Retro sci-fi village masterpiece",
        "full": "cinematic view of a retro scifi village, desaturated, psychedelic, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Dramatic pirate portrait.",
        "full": "pirate closeup portrait, dramatic light, lake background, 2 0 0 mm focal length, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Owl goddess in forest ritual",
        "full": "owl goddess wearing feathered robe, dark, forest ritual, moon, Ilya Kuvshinov, digital, concept art, Kyoto animation,last exile, blue submarine no. 6, gustav klimt,loish, murata range, kawaii, yoshitaka amano, studio lighting, manga, bright colors, beautiful, 28mm lens,mucha, vibrant high contrast, gradation, jean giraud, fantasy, rule of thirds, unreal engine, fibonacci, intricate, cel shaded, blender npr, flat, matte print, soft eyes, smooth, mucha, makoto shinkai"
    },
    {
        "display": "\"Hyperrealistic hand in flow\"",
        "full": "hyperrealistic mixed media high resolution image of complex potential flow around a proportionally sized hand reaching up and outward from the flow toward the unknown, particle teleportation, stunning 3d render inspired art by unreal engine and Greg Rutkowski, perfect symmetry, dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyper-detailed, intricate, epic composition, highly detailed attributes, highly detailed atmosphere, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, flawless epidermis,"
    },
    {
        "display": "Intricate bioluminescent skulls.",
        "full": "composition of human skulls, animals skulls, bones, rib-cage. jellyfish orchids and betta fish, bioluminiscent, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Frosty elven prince portrait",
        "full": "the frightening archfey called'the prince of frost ', full - body portrait, fantasy, white hair, blue skin, wild eyebrows, young adult, elf, crown, hard edges, soft lighting, professional lighting, trending on artstation"
    },
    {
        "display": "Shango, Fire Hammer Illustration",
        "full": "african god shango, fire hammer, dim light, front game card, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by ruan jia and mandy jurgens and artgerm and wayne barlowe and greg rutkowski and zdislaw beksinski."
    },
    {
        "display": "Baroque tavern interior illustration",
        "full": "ultra realistic illustration, baroque tavern interior from diablo and baldurs gate, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Medieval goblin portrait.",
        "full": "portrait of greta thunberg as a medieval goblin eating cakes, beautiful face, hyper realistic, highly detailed, digital painting, artstation, illustration, concept art by hyung tae and frank frazetta, digital paint, matte paint, washed colors, dark, gloomy"
    },
    {
        "display": "Romantic couple in Mont Blanc.",
        "full": "of a couple on a romantic escapade in the Mont Blanc, in the style of Gehry, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Haitian mermaid goddess illustration",
        "full": "nathalie emmanuel as a dark - skinned la sirene haitian mermaid goddess, under water, white lilies, shells, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Futuristic princess portrait",
        "full": "epic medieval futuristic close-up macro portrait of the face of a beautiful princess, epic angle and pose, symmetrical artwork, 3d with depth of field, blurred background, cybernetic jellyfish female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. y Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Overgrown Japanese architecture underwater",
        "full": "overgrown foliage overtaking tall japanese architecture, underwater environment, borealis, scenery, professional, award - winning, trending on artstation, hyper detailed, realistic, beautiful, emotional, shiny, golden, picture"
    },
    {
        "display": "Realistic liquid queen painting",
        "full": "a_realistic_liquid_queen_with_a_decorated_dress_made_of_white_pearlshighly_detailed_digital_painting_Trending_on_artstationHD_quality_by_artgerm, Botticelli style  H 1024 W 1024"
    },
    {
        "display": "Futuristic luxury knight portrait",
        "full": "portrait of evilly and luxury futuristic knights of Zodiac girl, black and golden armor, in futuristic heavily raindrop tokyo rooftop cyberpunk night, ssci-fi, fantasy, intricate, very very beautiful, elegant, neon light, highly detailed, digital painting, artstation, concept art, human anatomy, soft light, smooth, illustration, by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Gandhi meditating on beach",
        "full": "gandhi at the beach, sitting in a yoga pose on the sand next to a campfire, with palm trees in the back, by artgerm, ilya kuvshinov katsuhiro villeneuve, jeremy lipkin and michael garmash and rob rey, disney pixar zootopia, by tristan eaton, stanley artgermm, tom bagshaw, greg rutkowski, carne griffiths"
    },
    {
        "display": "Anime girl in Soviet attire",
        "full": "anime girl with orange hair in the Soviet pioneer form, manga,katsura masakazu, intricate, detailed, studio lighting, gradation,editorial illustration, matte print, Ilya Kuvshinov, concept art, digital"
    },
    {
        "display": "Danny McBride as E. Honda",
        "full": "danny mcbride as e. honda street fighter, slapping, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by frank frazetta, simon bisley, brom"
    },
    {
        "display": "Portrait of a character.",
        "full": "portrait cloister lister!!! red dwarf gray hair!! digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Portrait of a gypsy woman",
        "full": "realistic detailed face portrait of a beautiful gypsy woman with a scar across her cheek wearing a red scarf by gerald moira, ayami kojima, amano, greg hildebrandt, ann long, and mark brooks, female, feminine, art nouveau, victorian, neo - gothic, gothic, character concept design"
    },
    {
        "display": "Tracer on rooftop, Artgerm style.",
        "full": "digital artwork of tracer sitting on a rooftop, in the style of artgerm, detailed face, expressive face, sitting position, feminine face,"
    },
    {
        "display": "Hamburger-headed monster character",
        "full": "a monster with a hamburger head, hamburger face, burger head, burger with human eyes in the top bun, burger with a mouth, teeth between bread and patty, character concept art, fantasy drawing, illustration, highly detailed, hyperrealistic, cgsociety, artstation, oil painting by greg rutkowski"
    },
    {
        "display": "Nordic woman portrait, cinematic.",
        "full": "beautiful nordic woman, beautiful, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Cyborg elf fantasy illustration",
        "full": "cyborg elf, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Glowing maximalist bedroom scene.",
        "full": "rgb, glowing, depression!!!!!, maximalist bedroom, woman, cinematic, movie scene, inspired by zdzislaw beksinski, clothes made out of veins,, cables everywhere, bedroom, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k"
    },
    {
        "display": "Vibrant skinny junkie portrait",
        "full": "a portrait of a skinny junkie illustrated by sergio toppi, profile, vibrant, trending on artstation, behance,"
    },
    {
        "display": "Cute magical monster.",
        "full": "A full shot of a cute magical monster Cryptid wearing a dress made of opals and tentacles. Chibi. Subsurface Scattering. Translucent Skin. Caustics. Prismatic light. defined facial features, symmetrical facial features. Opalescent surface. Soft Lighting. beautiful lighting. By Giger and Ruan Jia and Artgerm and WLOP and William-Adolphe Bouguereau and Loish and Lisa Frank. Sailor Moon. trending on artstation, featured on pixiv, award winning, sharp, details, intricate details, realistic, Hyper-detailed, HD, HDR, 4K, 8K."
    },
    {
        "display": "Steampunk Sigourney Weaver portrait",
        "full": "closeup portrait shot of sigourney weaver wearing steampunk goggles in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "One Piece digital artwork",
        "full": "One Piece wallpaper art, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, in the style of One Piece"
    },
    {
        "display": "Vibrant sci-fi concept art.",
        "full": "fallout sci - fi concept art, vibrant colors, 8 k photorealistic, hd, high details, trending on artstation"
    },
    {
        "display": "Mythical creatures in nature.",
        "full": "a portrait photograph of a meditating sphinx and a centaur king riding birds at a wide river delta. surrounded by bulbous flowers, animals, trees and mushrooms. mountain range under a vast blue sky of burning stars. painted by jan van eyck, max ernst, ernst haeckel, artgerm and ernst fuchs, cgsociety, artstation, fashion editorial"
    },
    {
        "display": "Medieval kitchen watercolor masterpiece",
        "full": "medieval kitchen, wide angle, exquisite masterpiece watercolor painting, trending on artstation"
    },
    {
        "display": "Hatsune Miku Fantasy Portrait",
        "full": "portrait of hatsune miku, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy portrait of girl",
        "full": "a _ fantasy _ style _ portrait _ painting _ of white girl, dark ponytail hair, smiling, round face, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Vampire on super yacht.",
        "full": "a beautiful painting of a vampire with red hair in a white turtleneck dress, standing on a super yacht by simon stalenhag and pascal blanche and alphonse mucha! and nekro!. in style of digital art. colorful comic, film noirs!, symmetry, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Cinematic cave landscape illustration",
        "full": "side scrolling cave landscape, studio ghibli, pixar and disney animation, sharp, rendered in unreal 5, highly detailed, digital painting, artstation, dead cells, hollow knight, smooth, sharp focus, illustration, wide angle, super smash bross, wallpaper, splash art, promo art, dramatic lighting, art by artgerm and rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Detailed girl with Rafflesia flowers.",
        "full": "breathtaking detailed concept art painting of the girl with rafflesia arnoldii flowers, saint, with anxious, piercing eyes, ornate background, amalgamation of leaves and flowers, by Hsiao-Ron Cheng, James jean, Miho Hirano, Hayao Miyazaki, extremely moody lighting, 8K"
    },
    {
        "display": "Cassandra Cain in tribal dress",
        "full": "cassandra cain in a tribal ripped dress, by artgerm, by studio muti, greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Female warrior portrait, cybernetic.",
        "full": "portrait of a female warrior wearing a semi transparent carbon shell, plastic, cybernetic, science fiction, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Bill Cosby artwork",
        "full": "bill cosby, trending on artstation"
    },
    {
        "display": "Happy Lovecraftian Stephen Fry",
        "full": "lovecraftian portrait of happy stephen fry, pixar style, by tristan eaton stanley artgerm and tom bagshaw"
    },
    {
        "display": "Fantasy interplanetary cathedral portrait",
        "full": "Interplanetary cathedral, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by Ilya Kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Humanoid farming robot in cornfield",
        "full": "huge green farming robot with agricultural tractor in the corn field, sphere shape, robots, humanoid, farming, highly detailed digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, unreal engine"
    },
    {
        "display": "Renaissance woman, vaporwave aesthetic.",
        "full": "beautiful, young woman, in renaissance times, detailed gorgeous face, sad eyes, vaporwave aesthetic, synthwave long luxurious gown made out of pearls, hair done up with flowers and ribbons, digital art, butterflies, birds, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant goddess of judgment.",
        "full": "nemesis the goddess of judgement, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, frank frazetta"
    },
    {
        "display": "Goth cyborg maiden portrait",
        "full": "portrait of beautiful cute young goth maiden cyborg girl with braided hair in warhammer mechanical armor, art by ( ( ( kuvshinov ilya ) ) ) and wayne barlowe and gustav klimt and artgerm and wlop and william - adolphe bouguereau"
    },
    {
        "display": "Elegant Sansa Stark portrait",
        "full": "sansa stark gold sunny lights, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Futuristic cyberpunk police portrait",
        "full": "portrait futuristic beautiful cyberpunk female police, in heavy rainning futuristic tokyo rooftop cyberpunk night, ssci-fi, fantasy, intricate, very very beautiful, elegant, neon light, highly detailed, digital painting, artstation, concept art, soft light, hdri, smooth, sharp focus, illustration, art by tian zi and craig mullins and WLOP and alphonse mucha"
    },
    {
        "display": "Close-up dark witch portrait",
        "full": "glowing silver and golden elements, full close-up portrait, vector dark witch from shutterstock, book cover, green forest, white moon, red lips, establishing shot, extremly high detail, photo-realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross"
    },
    {
        "display": "Epic Star Destroyer Fleet",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Star destroyer fleet. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Cute Latina witch illustration",
        "full": "Full shot of a cute mischievous young witch about to get up to some trouble with her playful snake familiar. Latin American fashion. Floral patterns. Pink and Lime Green  palette. Magic. Latina girl. brown skin. defined facial features, symmetrical facial features. Smiling. By Ruan Jia and Artgerm and Range Murata and WLOP and Ross Tran and William-Adolphe Bouguereau. Key Art. Fantasy Illustration. award winning, Artstation, intricate details, realistic, Hyperdetailed, 8k resolution."
    },
    {
        "display": "Gothic Lolita Princess Interior",
        "full": "a beautiful hyperdetailed physical render of absolutely beautiful princess that wear rose flower wedding gothic lolita dress clothing stay in blooming flower house alone, beutiful delicate face and head, dazzling light beam penetrated through the window, perfectly shaded, atmospheric lighting, style of makoto shinkai, raphael lacoste, louis comfort tiffany, artgerm, james jean, ross tran"
    },
    {
        "display": "DeLorean in Savannah Fantasy",
        "full": "the car dolorean, through the african savannah, artgerm and greg rutkowski and alphonse mucha, an epic fantasy, volumetric light, detailed, establishing shot, an epic fantasy, trending on art station, octane render, midsommar"
    },
    {
        "display": "Fantasy forest landscape cover",
        "full": "fine art, awesome fantasy book cover on Pinterest, award winning, fantasy forest landscape, fantasy magic, dark golden light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski, masterpiece, trending on artstation"
    },
    {
        "display": "Ominous Hades illustration.",
        "full": "story of hades , illustration, ominous , catarthic, god, mist, nuclear explosion background, volumetric lighting, soft lighting, soft details, painting oil on canvas by artgerm and jihun lee , octane render, HDR, trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Young Jeff Bridges Techno Wizard",
        "full": "dark portrait of young jeff bridges as a techno wizard, fantasy, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, epic fractal circuit background, artgerm, alphonse mucha, epic cosmic background, erak note, eddie mendoza, christophe young, craig mullins, evan lee, silvain sarrailh, dao trong le, kan liu, character design, trending on artstation"
    },
    {
        "display": "Dragon peacock digital art.",
        "full": "dragon peacock. intricate, centered, amazing composition by amano yoshitaka, by rembrandt, illustrious makinami, digital art, digital painting, artstation trending, unreal engine, fractal flame, transparent jellyfish, transparent feathers, bio luminescent, ice, water,"
    },
    {
        "display": "African ninja portrait, anime.",
        "full": "attractive female portrait of african ninja, cloud background, rul of thirds, uplight, intricate, symmetrical!!, anime, prism highlights, depth of field, cinematic, filmic, vsco, concept art, artstation, digital painting, elegant, epic, focus"
    },
    {
        "display": "Epic Red Mage illustration",
        "full": "A boisterous Red Mage wearing striped shining armor holding a staff of power surrounded by an epic cloudscape. The Magus Omega . Red Wizard. Morpheus. masterpiece. 4k digital illustration. by Ruan Jia and Artgerm and Andreas Rocha and William-Adolphe Bouguereau and Edmund Blair Leighton. award winning, Artstation, intricate details, realistic, Hyperdetailed, 8k resolution. Concept Painting. Key Art"
    },
    {
        "display": "Kazakh princess portrait illustration.",
        "full": "richly detailed color pencil illustration of a portrait of a kazakh princess with her hypnotic pet shi - tzu, by artgerm and range murata rendered with 3 d effect."
    },
    {
        "display": "Fantasy warrior in suit",
        "full": "a man in a blue suit wielding a jeweled sword and a pistol, fantasy, digital painting, volumetric light, intricate, sharp, focus, bloom, illustration, highly detailed, concept art, matte, ruan jia, randy vargas, greg rutkowski"
    },
    {
        "display": "Swarovski sneakers concept art",
        "full": "sneakers swarovski, cinematic, cinema 4 d, octane render, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rats in Beatles-inspired band.",
        "full": "small rats playing in a rock band inspired by the beatles, inside wooden house and furniture, style by disney, studio ghibli, pixar, intricated, beautiful, dreamlike, 7 0 s pallete, high quality, trending on artstation"
    },
    {
        "display": "Tanned prehistoric woman portrait",
        "full": "long ginger hair, tanned woman in a prehistoric outfit, green eyes, fang necklace, by artgerm, hair tied in a ponytail, white backdrop, soft lighting, night scene, by greg rutkowski makoto shinkai takashi takeuchi"
    },
    {
        "display": "Norse moon god portrait.",
        "full": "norse god of the moon, male, half - back view, long braided ash blonde hair, elegant, blue, silver, black, super detailed, very ornate, by artgerm, magali villeneuve, artstation, symmetrical face, 8 k, sharp focus, cinematic, forest background"
    },
    {
        "display": "Futuristic neko woman in spaceship",
        "full": "neko woman with cat ears in a futuristic spaceship, detailed digital art, futuristic, vector art, by greg rutkowski, by artgerm, by yoshitaka amano, concept art, anime style"
    },
    {
        "display": "Colorful Asian warrior portrait",
        "full": "asian warrior looking down, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Cyberpunk fantasy conehead illustration.",
        "full": "cyberpunk conehead, fantasy, d & d, intricate, detailed, by by alphonse mucha, adolfo hohenstein, alice russell glenny, stanley artgerm lau, greg rutkowski, detailed, trending on artstation, trending on artstation, smooth"
    },
    {
        "display": "Bavarian Alps, tin can robots",
        "full": "a ultra photorealistic and sharp film still of an a sunny and colourful open field in 1 9 0 0 in the middle of the bavarian alps, germany. two colourful highly detailed tin can robots posing in the foreground. wide shot, wes anderson, studio ghibli, pixar and disney animation, octane render, anime key art by greg rutkowski, dramatic lighting, award winning photography"
    },
    {
        "display": "Elegant female sorceress illustration",
        "full": "a female sorceress by karol bak and vargas, beautiful detailed eyes, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4 k, hdr, concept art, detailed jewelry, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Mecha Beerus in HD",
        "full": "an full body oil painting of a beerus the god of destruction wearing a mecha suit based off of the egyptian gods, by artgerm, hd, hdr, ue 5, ue 6, unreal engine 5, realistic 3 d style, cinematic 4 k wallpaper, 8 k, ultra detailed, gta 5 cover art, high resolution, artstation, award winning"
    },
    {
        "display": "Spiritual Earth Elemental Portrait",
        "full": "portrait of spiritual earth elemental ,intricated, fantasy, gradient green black, dreamy and ethereal, (colour) eyes, one head, golden ratio, peaceful expression, ornate frilly dress, fantasy, intricate, elegant, rainbow bubbles, highly detailed, digital painting, artstation, concept art, smooth,b sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate Lady Mechanika artwork",
        "full": "lady mechanika, comic, intricate linework, detailed and intricate environment, artstation, trending, beautiful, highly detailed, focus, smooth, by joe benitez"
    },
    {
        "display": "Goddess with mythical creatures.",
        "full": "goddess unearth creature masked portrait. jellyfish phoenix head, nautilus, butterfly, skull, ice and fire, bioluminiscent creatures, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Magic Barbarian Karl Marx",
        "full": "karl marx as a magic barbarian, dungeons and dragons artwork, award winning art, cinematic light, dynamic composition, highly detailed, realistic light transport simulation, dramatic lighting, digital painting, concept art, masterpiece, by leonardo da vinci, raphael, artgerm, greg rutkowski, vibrant colors"
    },
    {
        "display": "Deer and crow in formal attire.",
        "full": "a deer wearing a white formal coat, conversing with a crow wearing a red formal coat, hyperrealistic, concept art, octane render, unreal engine 5, trending on DeviantArt, highly detailed, high quality, 8K, soft lighting, cute, natural lighting, realistic face, trending on Artstation, elegant clothes, profile picture, path traced, house background"
    },
    {
        "display": "Detailed sombrero portrait.",
        "full": "Portrait of Sam Gray wearing a sombrero, by Cedric Peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on ArtStation"
    },
    {
        "display": "Ancient mystical book portal",
        "full": "Ancient opened Book of the Dead as a Portal to Bardo, the world of lost souls, mystic illustration, Anime, extremly detailed, in the Style of Mike Mignola and Tomer Hanuka, vibrant colors, trending on artstation"
    },
    {
        "display": "Cosmic Waluwigi Resurrection",
        "full": "the second coming of waluwigi by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Aoko Aozaki Portrait",
        "full": "The Portrait of Aoko Aozaki, Anime Fantasy Illustration by Tomoyuki Yamasaki, Kyoto Studio, Madhouse, Ufotable, trending on artstation"
    },
    {
        "display": "Gal Gadot Sci-Fi Portrait",
        "full": "\u201c portrait of gal gadot by greg rutkowski, young, attractive, highly detailed portrait, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq \u201d"
    },
    {
        "display": "Lalisa Manoban in lingerie",
        "full": "realistic detailed semirealism beautiful gorgeous cute Blackpink Lalisa Manoban wearing white silky lingerie, white silky outfit, black hair black cat ears, black leather choker, proportional body, WLOP, Aztodio, Taejune Kim, sakimichan, Pixiv, Instagram, Artstation"
    },
    {
        "display": "Polished fantasy character artwork",
        "full": "polished sweaty she - ra : : gillian anderson kate upton : : christina hendricks body : : weta disney pixar movie still photo : : hi - fructose, sci fi, fantasy, decadent highly - detailed digital painting, golden ratio, octane render, artstation, smooth, sharp focus, artgerm, mucha, loish, wlop : :"
    },
    {
        "display": "Vengeful female revenant.",
        "full": "female revenant, undead, grey tarnished longcoat, vengeful, floating, no legs, tendrils, high fantasy, steampunk, monochromatic, detailed face, highly detailed, smooth, shrap focus, matte digital illustration, by koyorin, donato giancola, pixiv, tenebirsm"
    },
    {
        "display": "Burgundy abstract girl structure",
        "full": "fluid polygonal masses of burgundy oil paints building chaotic satin structure of hyper beautiful girl in other dimension, wayne barlow, bao pham, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hdr, 4 k, 8 k"
    },
    {
        "display": "Ethereal cowboy by campfire.",
        "full": "a dramatic, epic, ethereal painting of a handsome thicc chunky beefy mischievous shirtless man with a beer belly wearing a large belt and bandana offering a whiskey bottle | he is relaxing by a campfire | background is a late night with food and jugs of whisky | homoerotic | cowboy, stars, tarot card, art deco, art nouveau, intricate | by Mark Maggiori (((and Alphonse Mucha))) | trending on artstation"
    },
    {
        "display": "Conan vs. Dragon Dungeon",
        "full": "28mm Photo of conan the Barbarian, fighting a giant dragon in a dungeon, in the style of greg rutkowski, concept art, rim lighting, dark souls, unreal, ray tracing"
    },
    {
        "display": "Hyper-realistic kitsune sorceress.",
        "full": "gorgeous!!! hyper - realstic kitsune sorceress, holding a tattered magical book, casting a flame spell, blue flames, surrounded by tiny spirits, tribal face markings | drawn by wlop, drawn by jeehyung lee, drawn by artgerm | fantasy, dark, intricate, highly detailed, digital painting, character design, concept art, illustration, artstation"
    },
    {
        "display": "Fantasy tortoise hero",
        "full": "a tortoise fantasy hero for hire, large turtle shell shield, no armor, dramatic light, high detail, fantasy background, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Colorful portrait of Pope John Paul II",
        "full": "colorful portrait of pope john paul ii standing in a church, wearing pope's cap in the style of artgerm"
    },
    {
        "display": "Anime maids in tanks",
        "full": "baroque oil painting of key visual environment concept art of anime maids riding early tanks in the great war, brutalist fantasy, rule of thirds golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai ghibli takashi takeuchi yoshiyuki sadamoto jamie wyeth james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "Anime-style baroque portrait",
        "full": "baroque oil painting anime key visual full body portrait character concept art of apathetic young maid nazi secret police, flowing blonde hair blue eyes, fitted black leather military commander uniform, garter belt skirt, gapmoe kuudere noble grimdark evil dictator, trending pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli jamie wyeth"
    },
    {
        "display": "Iron Goddess Portrait",
        "full": "a beautiful portrait of a iron goddess by greg rutkowski and raymond swanland, afrofuturism, trending on artstation, ultra realistic digital art"
    },
    {
        "display": "Golden-skinned goddess illustration",
        "full": "the electronexus , goddess with golden skin with blue adornments ,Wadim Kashin, artgerm,  XF IQ4, f/1.4, ISO 200, 1/160s, 8K, RAW, featured in artstation, illustrative, elegant, intricate, 8k"
    },
    {
        "display": "Paladin Portrait Art",
        "full": "side profile centered painted portrait, Imogen Poots as a Paladin, D&D, matte painting concept art, beautifully backlit, official fanart, colourful, swirly vibrant color lines, winning-award masterpiece, fantastically gaudy, aesthetic octane render, 8K HD Resolution, by Victo Ngai and Kilian Eng and Jake Parker and ilya kuvshinov and Cushart Krentz and Gilleard James, 4k, HDR, Trending on artstation, Behance, award winning"
    },
    {
        "display": "Gothicpunk cyborg pirate portrait",
        "full": "full body head to toe portrait of a gothicpunk sci-fi cyborg pirate bionic man, third person, D&D, sci-fi fantasy, biomatter and , intricate, black with shiny silver and orange fringe highlights, highly detailed, art by Range Murata, highly detailed, 3d, octane render, bright colors, digital painting, trending on artstation, sharp focus, illustration style of Stanley Artgerm, dramatic background"
    },
    {
        "display": "Regal Egyptian queen with wings.",
        "full": "perfect white haired egyptian queen wearing white dove wings, warframe armor, regal, attractive, ornate, sultry, sexy, beautiful, dreamy, elize theron, pretty face, green eyes, detailed, scifi platform, 4 k, ultra realistic, epic lighting, illuminated, vibe, cinematic, masterpiece, art by akihito tsukushi, voidstar, artgerm"
    },
    {
        "display": "Grim Reaper in Stained Glass",
        "full": "breathtaking detailed soft painting of grim reaper in gauze dress and bleeding meat, with long hair and piercing eyes, in an intricate medieval stained glass moonlight, dynamic pose and movement, rembrandt style, elegant, highly detailed, artstation, concept art, matte, sharp focus, art by tom bagshaw, luis royo and greg rutkowski"
    },
    {
        "display": "Futuristic brutalist cityscape.",
        "full": "cinematic view of a brutalist futuristic cityscape in clouds, tim hildebrandt, wayne barlowe, syd mead, john harris, bruce pennington, donato giancola, larry elmore, oil on canvas, artstation, pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hd, hdr, 4 k"
    },
    {
        "display": "Elegant cottagecore bombshell.",
        "full": "beautiful cottagecore miley cyrus bombshell overknee boots and corsette, red neon light, full body shot, intricate, elegant. the background is chocolate !. highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration. . art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant fantasy nature artwork",
        "full": "a photograpic of lady nature, cute, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and H R Giger and alphonse mucha"
    },
    {
        "display": "Portrait of Claus Iohanis",
        "full": "a portrait of Claus Iohanis, volumetric lighting, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "Rainbow city skyline illustration",
        "full": "rainbow over a city skyline with a bridge and birds flapping over it, concept art, illustration, highly detailed, high quality, bright colors,"
    },
    {
        "display": "Elegant knight in ruined Agora.",
        "full": "portrait knights of Zodiac girl, metalic green and white reflected armor, in ruined Agora of Athens sunrise, ssci-fi, fantasy, intricate, very very beautiful, elegant, golden light, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by tian zi and WLOP and alphonse mucha"
    },
    {
        "display": "Elegant digital portrait.",
        "full": "tina fey, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Cute Cheshire cat illustration.",
        "full": "cute cat of cheshire from alice in wonderland. an adorable cat with light blue stripes and a big human like playful smile. award - winning digital art, trending on artstation"
    },
    {
        "display": "Crowned robotic cyberpunk ballerina.",
        "full": "laying beautiful painting of a crowned robotic cyberpunk ballerinain a gothic dark flowing tutu intricate, elegant, highly detailed, digital painting, artstation, concept art, by krenz cushart and artem demura and william adolph bouguereau"
    },
    {
        "display": "Armored sci-fi Jeep design.",
        "full": "a single armored scifi Jeep in the style of bladerunner and alternate car one, car concept, car Design, sid mead, alex ross, intricate Details, concept art, matte painting, highly detailed, rule of thirds, dynamic lighting, cinematic, detailed, denoised, centerd, clean render"
    },
    {
        "display": "Anatomical CNS sculpture",
        "full": "medical reference, anatomical sculpture of central nervous system, quixel megascans, photorealism, cgi, digital concept art, redshift render, physically based rendering, cinematic, filmic : : illustrated by artgerm, nychos, alan grey, elena masci, tom bagshaw - h 4 8 0"
    },
    {
        "display": "Young man in formal suit",
        "full": "character concept, wide angle, full body, symmetrical head - on centralized, young man with formal black suit. detailed, high quality, dynamic lightning, fantasy, scenematic. artwork by artgerm, wlop, alex ross, greg rutknowski, alphonse mucha"
    },
    {
        "display": "Apocalyptic horseman on stallion",
        "full": "the singular horseman of the apocalypse is riding a strong fierce ferocious black stallion, horse is up on its hind legs, the strong male rider is carrying the scales of justice, beautiful artwork by artgerm and rutkowski, breathtaking, beautifully lit, dramatic"
    },
    {
        "display": "Celestial goddess with violin.",
        "full": "a high quality life like portrait of a very very beautiful! celestial goddess of life playing a mystical violin and springing life into the universe, highly detailed, cinematic lighting, intricate, sharp focus, fantasy, mystical, dreamlike, exotic, realistic, by WLOP and greg rutkowski, trending on artstation"
    },
    {
        "display": "Energetic underground thug illustration",
        "full": "underground stree thug, grills, vitiligo, energetic, laughing, fit, warhammer, lord of the rings, sweaty, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intimate Elvira Portrait",
        "full": "a ( beautiful masterpiece highly detailed ) full body portrait illustration of intimate alluring elvira! mistress of the dark by ralph horsely and artgerm and joe jusko, raven black hair, porcelain white skin. bettie page, vampira, morticia addams, kat dennings, curvy, sexy pinup. trending on artstation"
    },
    {
        "display": "Elegant male humanoid robot.",
        "full": "handsome beautiful but male humanoid male robot part metal wires with wavy short dark hair, elegant, red lighting, realistic, concept art, smooth, detailed, high quality, alphonse mucha and waterhouse,"
    },
    {
        "display": "Paris-inspired California cityscape.",
        "full": "the beautiful city of paris in california, digital painting, highly detailed, intricate, concept art, matte painting, trending on artstation, octane render, 8 k, unreal engine"
    },
    {
        "display": "Androgynous cat seahorse fursona",
        "full": "cat seahorse fursona, autistic bisexual graphic designer and musician, long haired attractive androgynous fluffy humanoid character design, sharp focus, weirdcore voidpunk digital art by artgerm, akihiko yoshida, louis wain, simon stalenhag, tim hildebrandt, wlop, noah bradley, furaffinity, artstation hd, trending on deviantart"
    },
    {
        "display": "Happy barista portrait",
        "full": "Eva Green as a very happy starbucks barista, portrait, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy horse race illustration",
        "full": "horse race with customized horses, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate skull portrait",
        "full": "avalon skull, close - up portrait, powerfull, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Gigachad Luigi Mech Portrait",
        "full": "gigachad luigi in a mech space suit by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Slavic demon godling artwork",
        "full": "slavic demon godling, the witcher, ultra detailed artwork by greg rutkowski, artgerm, intricate details"
    },
    {
        "display": "Colorful cartoon woman illustration",
        "full": "colorful drawing of a young white woman with brown hair and scarlet lips, happy and cartoonish background, by tokenin, by glitchedpuppet, gradient orange, black, cream, brown and white color scheme, trending in artstation, award winning illustration"
    },
    {
        "display": "Detailed bikini portrait of Tracer",
        "full": "a highly detailed portait of tracer from overwatch in a bikini, digital art, pretty face, muscular, very beautiful face, very detailed eyes, 8 k resolution, by wlop, greg rutkowski, full body"
    },
    {
        "display": "Gothic fantasy knight portrait",
        "full": "a beautiful digital illustration painting of a detailed gothic fantasy kevin rudd knight, by benoit b. mandelbrot, steven belledin, martin johnson heade, lee madgwick, caspar david friedrich, and david rios ferreira. 8 k resolution trending on artstation concept art digital illustration"
    },
    {
        "display": "DND rogue character portrait.",
        "full": "a portrait of my next DND rogue character , concept art, DND, trending on artstation 3D."
    },
    {
        "display": "Energetic girl in mist.",
        "full": "a short haired girl girl stepping into a well full of mists and overflowing with energy, art by greg ruthowski, bastien lecouffe - deharme, ultra detailed, trending on artstation, 4 k, 8 k"
    },
    {
        "display": "Girl in kimono with crane",
        "full": "girl and a crane in japanese pines, trading card front, kimono, realistic anatomy, concept art, professional, by ufotable anime studio, kuvshinov ilya, volumetric lights, stunning, sun in the background, realistic face, pretty eyes"
    },
    {
        "display": "Epic alien machine intelligence",
        "full": "epic stunning alien machine intelligence being of the black hole in white shimmering robes alien entity intelligence epic scale bluehaired timespace entity with white robes and metallic machine deity artgerm photorealistic detailed hypervivid intense digital art by greg rutkowski alphonse mucha max chroma"
    },
    {
        "display": "Psychedelic reptilian statue portrait.",
        "full": "a surreal portrait statue of joe rogan as a psychedelic tiki reptile stone god figure by naoto hattori, android jones, and chris dyer, deep bold colors, galactic dmt entity, depth of field, intricate beautiful painting, billions of details, octane render, portal, 8 k, detailed vector, trending on artstation, cgisociety"
    },
    {
        "display": "Artistic fusion portrait",
        "full": "art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic cyborg portrait",
        "full": "detailed portrait very beautiful cyborg transparent glossy glass skin surrounded glowing tubes inside an incubator futuristic hospital bio lab, intricate rendered by beeple, by syd meade, by android jones, by yoanne lossel, by artgerm and greg rutkowski, space art concept, sci - fi, digital art, unreal engine, wlop, trending artstation, sharp focus"
    },
    {
        "display": "Two-headed hydra with characters",
        "full": "two - headed hydra of lerna, jim carey as lloyd christmas beside jeff daniels as harry dunne ( from dumb and dumber ), serpentine water monster, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Burgundy ribbon face art",
        "full": "chaotic burgundy satin ribbons instead of skin build image of beautiful face, moebius, bao pham, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hdr, 4 k, 8 k"
    },
    {
        "display": "Elmo Titan Portrait",
        "full": "attack on titan, portrait of elmo from sesame street as a titan from attack on titan, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Female samurai stained glass",
        "full": "a stained glass window of a female samurai holding a flaming katana, ominous nighttime storm, fantasy, d & d, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by john collier and albert aublet and krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Chipmunk astronaut on moon",
        "full": "an adorable chipmunk in a chimpunk astronaut suit on the moon, digital painting, detailed, trending on artstation, in the style of dominik mayer, thomas dubois, gaston bussiere, 8 k, octane render"
    },
    {
        "display": "Male explorer in rubble city.",
        "full": "highly detailed macro shot of rubble city focusing on a male explorers face, rule of thirds, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, by jordan grimmer, huge scene, art greg rutkowski"
    },
    {
        "display": "Rugged Indian doctor portrait",
        "full": "portrait of a pale handsome rugged modern indian doctor, epic scene, by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Lotus in melting salt pit.",
        "full": "a singular lotus growing out of a melting salt pit, extremly detailed digital painting, vibrant colors, in the style of tomasz alen kopera and fenghua zhong and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Underwater alien invasion scene",
        "full": "A bustling beautiful city on the sea floor inside a glass dome being invaded by aliens, war, explosions, violence, terror, war spaceships, anime, fountain, statue, underwater, rapture, fish flying over head, godrays, a fantasy digital painting by Greg Rutkowski and James Gurney, trending on Artstation, highly detailed"
    },
    {
        "display": "Epic cosmic megastructure.",
        "full": "entropia of universe epic cinematic massive fractal megastructure : hyper realism : dark energy : octane render : artstation : greg rutkowski : ferdinand knab"
    },
    {
        "display": "Dark phoenix dragon art",
        "full": "A dark phoenix, epic, highly detailed, close-up, fantasy art, dragon art, in the style of masami kurumada, illustration, epic, fantasy, intricate, artstation, concept art, smooth, sharp focus"
    },
    {
        "display": "Fantasy portrait of Sophie Marceau",
        "full": "beautiful young sophie marceau, closeup, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyper-detailed Gundam portrait",
        "full": "complex 3 d render hyper detail portrait of a mechanical gundam robot, sci fi, full body, intricate, art by kazuhiko nakamura and hajime sorayama, 8 k octane detailed render, post - processing, extremely hyperdetailed, intricate futuristic mechanic parts, maya, blender, cinematic lighting + masterpiece, trending on artstation"
    },
    {
        "display": "Forestpunk supergirl portrait.",
        "full": "forestpunk supergirl portrait Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw"
    },
    {
        "display": "Anime elf girl in snowy landscape.",
        "full": "a very beautiful anime elf girl, full body, long silver hair, sky blue eyes, full round face, short smile, casual clothes, ice snowy lake setting, cinematic lightning, medium shot, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan"
    },
    {
        "display": "Lifelike Del Boy Trotter illustration",
        "full": "amazing lifelike award winning pencil illustration of Del Boy Trotter trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Fantasy portrait illustration",
        "full": "final fantsy, digital painting, portrait , cinematic lighting, highly detailed, artstation, concept art, illustration, smooth, sharp focus, artgerm , greg rutkowski, alphonse mucha, editor's pickup, trending on artstation, trending on deviantart, WLOP"
    },
    {
        "display": "Alien Tutankhamun Head",
        "full": "A floating head of Tutankhamun as an alien in space, only the head, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, artgerm, award winning art, ray tracing"
    },
    {
        "display": "Zhongli celestial anime artwork",
        "full": "the second coming of zhongli by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv, anime style, genshin impact"
    },
    {
        "display": "Couple in snowy fantasy.",
        "full": "A woman and a man are standing in the snow, the woman is leaning against the man, the man has long red hair, the woman has blonde long hair, the man has a red thick sword, the woman has a thin, long sword, a tree almost fully covered in bulky snow, concept art by Fabien Charuau, trending on pixiv, fantasy art, official art, wiccan, concept art, 4k, sharp details"
    },
    {
        "display": "Surreal Luffy 3D Render",
        "full": "a hyper - detailed 3 d render of luffy, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation hd, by greg rutkowski, bruce pennington, valentina remenar and asher duran,"
    },
    {
        "display": "Pepe and Spurdo counting mayonnaise.",
        "full": "Pepe the Frog and Spurdo by Ralph Steadman, are counting jars of mayonnaise around a desk globe illustration, body horror, biopunk, 8k, trending on artstation"
    },
    {
        "display": "Future soldier trading card.",
        "full": "girl, trading card front, future soldier clothing, future combat gear, realistic anatomy, concept art, professional, by ufotable anime studio, green screen, volumetric lights, stunning, military camp in the background, metal hard surfaces"
    },
    {
        "display": "Cute cat girl portrait",
        "full": "nekopara fantastically detailed reflecting eyes modern anime style art cute detailed ears cat girl neko dress portrait  shinkai makoto vibrant Studio ghibli kyoto animation hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv flower"
    },
    {
        "display": "Futuristic princess portrait.",
        "full": "epic futuristic ancient close-up macro portrait of the face of a beautiful princess, epic angle and pose, symmetrical artwork, 3d with depth of field, blurred background, cybernetic orchid flower butterfly jellyfish crystal, obsidian, female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. y Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Professional monkey CEO portrait",
        "full": "portrait of a professional monkey!!!!!!! in a suit, ceo, studio lighting, 3 / 4 view, majestic pose, corporate business, trending on artstation, artgerm, cgsociety"
    },
    {
        "display": "Creepy ventriloquist figure.",
        "full": "a ventriloquist figure wearing a funny hat, ventriloquist dummy head, smiling, photograph, style of atget, nightmare, concept art, creepy, antique"
    },
    {
        "display": "Cinematic portrait of happy mask salesman.",
        "full": "a portrait of a cinematic still of the happy mask salesman, elven, grinning, red hair, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Grungy woman in rain.",
        "full": "a grungy woman with rainbow hair, drunk, angry, soft eyes and narrow chin, dainty figure, long hair straight down, torn overalls, basic white background, side boob, in the rain, wet shirt, symmetrical, single person, style of by Jordan Grimmer and greg rutkowski, crisp lines and color,"
    },
    {
        "display": "Fantasy digital painting",
        "full": ", d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic African-American family portrait",
        "full": "a cinematic portrait of a beautiful african-american family photograph close up moment of a young sun god and moon goddess magician family with child, portrait, wearing wide sunhat, Frozen Klaus film, godrays digital painting, artstation, concept art, illustration, Frozen II art masterpiece by art by Krenz Cushart, Artem Demura, alphonse mucha, yoji shinkawa, ArtGerm, Jon Lothian, Danilo Torres, Adi Meyers, Thomas Reimann, Gaston Bussiere, Disney"
    },
    {
        "display": "Cyborg woman in cyberpunk.",
        "full": "ultra realistic, beautiful cyborg woman, sci-fi, fantasy, cyberpunk, intricate, elegant, highly detailed, digital painting, octane render, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and vincent di fate and h.r. giger and michael welan and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Close-up of Spanish man.",
        "full": "a beautiful close - up of a spanish man, digital art by j. c. leyendecker, vibrant color scheme, highly detailed, in the style of romanticism, fine art, high detail, great lighting, 8 k resolution, masterpiece, concept art, illustration, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, hdr, trending on artstation, 4 k, 8 k, hd"
    },
    {
        "display": "\"Abstract communication concepts portrait\"",
        "full": "while shannon's information theory enabled much tool creation, his choice to divorce informational structure from semantics produced a lineage of tools that guaranteed our capacity to communicate with one another would exponentially outpace our capacity to understand one another, detailed, photorealistic portrait by michael komarck, greg rutkowski, victo ngai, artgerm and j. dickenson"
    },
    {
        "display": "Blue-haired girl in bikini.",
        "full": "hd photograph of a pretty girl with blue hair, wearing a sexy black leather bikini, sexy modeling pose, at the beach, in the style of ilya kuvshinov, dramatic lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, hdr,, artstation, concept art, smooth, sharp focus, art by krenz cushart and albert aublet"
    },
    {
        "display": "Stealthy assassins in forest",
        "full": "stealthed assassins crossing empty forest, unreal engine 5, art by artgerm and greg rutkowski and alphonse mucha, global illumination, detailed and intricate environment, hyperrealistic, volumetric lighting, epic cinematic shot, perfectly defined features, ambient occlusion"
    },
    {
        "display": "Corrupted Elsa artwork.",
        "full": "elsa corrupted by the chaos god nurgle from warhammer, intricate artwork by tooth wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render"
    },
    {
        "display": "Hyper-realistic island scenery.",
        "full": "a realistic photographic view picture of an island, photographic filter unreal engine 5 realistic hyperdetailed 8 k ultradetail cinematic concept art volumetric lighting, very beautiful scenery, very realistic effect, hd, hdr, cinematic 4 k wallpaper, 8 k, sharp focus, octane render, ultra detailed, high resolution, artstation trending on artstation in the style of albert dros glowing rich colors powerful imagery"
    },
    {
        "display": "Cyborg Yorkshire Terrier Portrait",
        "full": "tesla power armor realistic cyborg anthropomorphic teacup yorkshire terrier electric field glowing, cyberpunk, portrait art by donato giancola and greg rutkowski, realistic face, glowing in tesla electricity visible magnetic field, digital art, trending on artstation, symmetry"
    },
    {
        "display": "Lone soldier in trench.",
        "full": "beautiful film still of a war movie where a lone survivor, one soldier after his squad killed in action in a trench waiting for the war to end. cinematic lighting and rendering, atmospheric, concept art, high detailed faces, artstation, underpainting by artgerm and greg rutkowski, overpainted by eddie mendoza, post process by ross tran"
    },
    {
        "display": "Biomechanical moon goddess portrait",
        "full": "perfectly centered front view of a beautiful biomechanical moon goddess, flowing hair, intense stare, sweet smile, symmetrical, concept art, intricate detail, volumetric shadows and lighting, psychedelic colors, realistic oil painting by alex grey and gustave dore,"
    },
    {
        "display": "Beautiful winged angel portrait",
        "full": "! prompt a beautiful winged angel, blonde hair, portrait, magical, holy, character concept art, intricate complexity, by wlop, by charlie bowater, by quixel megascan, by artgerm and by ilya kushinov"
    },
    {
        "display": "Elegant fantasy queen portrait",
        "full": "a thin blonde middle aged fantasy queen with glasses, elegant, real life skin, intricate, high detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski"
    },
    {
        "display": "Beautiful woman butterfly portrait",
        "full": "highly detailed portrait of a beautiful woman butterfly with scarlet lips, tartan hoody, photographic realistic background, ringlet hair by atey ghailan, by greg rutkowski, by greg tocchini, by james gilleard, by joe fenton, by kaethe butcher, gradient pink, black, violet, cream and white color scheme, trending in pinterest, award winning details"
    },
    {
        "display": "Cosmic Prince in Space",
        "full": "a cosmic painting of prince in space. trending on artstation."
    },
    {
        "display": "Cute owl reading book.",
        "full": "cute fluffy owl reading a book, closeup, water color, D&D, fantasy, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Ivan Gantschev Alphonse Mucha"
    },
    {
        "display": "Scary bouncy castle clown.",
        "full": "scary clown made of bouncy castle, highly detailed, 8 k, hdr, smooth, sharp focus, high resolution, award - winning photo"
    },
    {
        "display": "Cute cartoon Charizard over NYC.",
        "full": "charizard flying above new york, cartoonish cute, pine trees, magical atmosphere, trending on artstation, 3 0 mm, by noah bradley trending on artstation, deviantart, high detail, stylized portrait"
    },
    {
        "display": "Quetzalcoatl vs. Garuda Battle",
        "full": "quetzalcoatl in an epic battle with garuda, fantasy, d & d, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by hokusai and jeon seong"
    },
    {
        "display": "Pokimane as Street Fighter character",
        "full": "pokimane as a street fighter character, cg animation, capcom, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Intricate watercolor lilies.",
        "full": "a beautifull intricate watercolor painting of lily of the valley, reflexions, verry high details by william turner art, greg rutkowski and alphonse mucha, trending on artstation, very very detailed, masterpiece, - h 7 0 4"
    },
    {
        "display": "Cyberpunk comic page.",
        "full": "Sci-Fi cyberpunk Comic page made up of art by the best artists Trending on Artstation. Paneling style by Bill Sienkiewicz. Octane render, Raytracing, 3d masterpiece, fantastic lighting by James Gurney.. Slice-of-life mundane genre."
    },
    {
        "display": "Visionary elder portrait",
        "full": "a painting of a thinker without facial hair, thoughtful, focused, visionary, calm, jovial, loving, fatherly, generous, elegant well fed elder with few eyebrows and his on from Kenya by Henry Ossawa Tanner . dramatic angle, ethereal lights, details, smooth, sharp focus, illustration, realistic, cinematic, artstation, award winning, rgb , unreal engine, octane render, cinematic light, macro, depth of field, blur, red light and clouds from the back, highly detailed epic cinematic concept art CG render made in Maya, Blender and Photoshop, octane render, excellent composition, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse."
    },
    {
        "display": "Cybernetic George Soros Portrait",
        "full": "a portrait of a beautiful cybernetic George Soros, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Cute anime woman, water dress",
        "full": "3D advanced digital art, a very cute and gorgeous anime woman wearing a dress made of water , full body, very long wavy azure blue hair, braided hair, azure blue watery eyes, full round face, cinematic lighting, mid-shot, highly detailed, trending on pixiv, Steven Artgerm Lau, WLOP, RossDraws, RuanJia , James Jean, Andrei Riabovitchev, Totorrl, Marc Simonetti, Visual Key, and Sakimichan"
    },
    {
        "display": "Retro airplanes concept art",
        "full": "retro airplanes concept art, 8 k photorealistic, hd, high details, trending on artstation"
    },
    {
        "display": "Mad Lad Luigi Artwork",
        "full": "mad lad luigi painted by wlop, artgerm"
    },
    {
        "display": "Symmetrical silhouette portrait.",
        "full": "symmetry!! portrait of a silhouette of a woman in the world of yoji shinkawa, horror, fashion, dark!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Boyish character with blue hair.",
        "full": "ilya kuvshinov with long sky blue hair, gold eyes, amber eyes, boyish face, professional digital painting, wild brush strokes, concept art, award - winning photography, cinematic, wlop, color block, pop, hip, art by andy warhol, pixiv art, yoshitaka amano, junki ito"
    },
    {
        "display": "Ethereal fairy portrait.",
        "full": "portrait of a beautiful female fairy with tattoos, ethereal, dreamy, backlit, highly detailed, stern expression, realistic lighting, sharp focus, windswept, rule of thirds, symmetrical facial features, backlit glow, by artgerm, rossdraws, frank frazetta, andrei riabovitchev, trending on artstation, hd, 4 k, fantasy"
    },
    {
        "display": "Dragon face portrait.",
        "full": "''face portrait of dragon, rule of thirds, fantasy, mountain landscape, d & d, digital painting, artstation, deviantart, concept art, illustration, art by dragolisco and anne stokes and nico niemi''"
    },
    {
        "display": "Mysterious warrior woman underwater",
        "full": "portrait of a beautiful mysterious warrior woman in armour, gloves holding a bouquet of flowing flowers, bubbles, upward flowing long hair, gloves hidden under the bouquet, underwater with coral and fish, fantasy, regal, intricate, by stanley artgerm lau, greg rutkowski, thomas kinkade, alphonse mucha, loish, norman rockwell"
    },
    {
        "display": "Anime illustration of Ryosuke Takahashi",
        "full": "anime illustration of serious ryosuke takahashi in his 4 0 s wearing a black shirt and white pants, standing by his glossy white 1 9 9 0 mazda rx - 7 fc on an empty highway at sunrise, cinematic lighting, initial d anime 1 0 8 0 p, 9 0 s anime aesthetic, volumetric lights, rule of thirds, unreal engine render, pinterest wallpaper, trending on artstation"
    },
    {
        "display": "Pretty pirate character art",
        "full": "artgerm, joshua middleton comic cover art, pretty pirate marisa laren smiling, full body, symmetrical eyes, symmetrical face, long curly black hair, on a pirate ship background, warm colors"
    },
    {
        "display": "Asmongold in artistic styles",
        "full": "asmongold the great by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwel"
    },
    {
        "display": "Steampunk futuristic battle tank",
        "full": "Futuristic battle tank, advanced technology, steampunk, concept art, digital painting, highly detailed, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy portrait landscape.",
        "full": "a portrait photograph of a meditating harpy and a centaur king riding birds at a wide river delta. surrounded by bulbous flowers, animals, trees and mushrooms. mountain range under a vast blue sky of burning stars and parrots. painted by jan van eyck, max ernst, ernst haeckel and artgerm, cgsociety, artstation, fashion editorial"
    },
    {
        "display": "Baby Yoda as Frodo",
        "full": "baby yoda in the role of frodo baggins with the ring of power, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Kendall Jenner in Twin Peaks",
        "full": "twin peaks movie poster art, portrait of a smiling kendall jenner, from scene from twin peaks, clean, simple illustration, nostalgic, domestic, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, donato giancola, joseph christian leyendecker, wlop"
    },
    {
        "display": "Medusa with sausage hair",
        "full": "portrait of medusa with long thin sausages instead of snakes, sausage hair, painting, illustration, intricate details, masterpiece, digital art, trending on artstation"
    },
    {
        "display": "Digital art of Vertigo.",
        "full": "Kim Novak and James Stewart in hitchcock's vertigo digital painting by artgerm"
    },
    {
        "display": "Elegant halfling fop illustration",
        "full": "Brown haired halfling, dandy elegant fop diminutive by Anato Finnstark, Tony Sart highly detailed, artgerm, digital illustration, concept art"
    },
    {
        "display": "Animated film poster.",
        "full": "a poster for a film animation called the last expedition, ( about an big ship expedition to the lost continent of kallah ), sunny day, blue tropical sea, ships passing by, delicate features finely detailed perfect art, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Hybrid cat-dragon painting",
        "full": "painting of hybrid cat!!!! and ( ( ( ( dragon ) ) ) ) fused together, intercrossed animal, by zdzislaw beksinski, by lewis jones, mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "\"Payday 2 Wolf Portrait\"",
        "full": "highly detailed portrait of payday 2 member wolf, the nightmare before christmas movie style characters, profile picture, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, wayne barlowe, trending on artstation, 8 k"
    },
    {
        "display": "Cinematic Batman concept art",
        "full": "concept art of batman, cinematic shot, oil painting by jama jurabaev, extremely detailed, brush hard, artstation, for aaa game, high quality, brush stroke"
    },
    {
        "display": "Symmetrical cyberpunk portrait.",
        "full": "symmetrie!! symmetrical - face!! christina hendricks in cyberpunk 2 0 7 7, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Cybernetic warrior in space",
        "full": "Epic dark gritty space scene digital artwork featuring the iridescent cloaked hooded warrior partially cybernetic entity god of future technology wielding cosmic weaponry, ornate galactic gold, intricate, ornate, gothic, colorful, vibrant, smooth, moody, ominous, dangerous aura, microchips, crystallic, iridescent, lasers, gems, multicolored glints, precious elements, beautiful, detailed, concept art, render, unreal engine, 4K, artstation"
    },
    {
        "display": "Colorful gentleman character design",
        "full": "colorful! character design, reference sheet, gentleman, concept art, photorealistic, hyperdetailed, 3 d rendering!, art by leyendecker! and frazetta, white background"
    },
    {
        "display": "Cute dog-girl thief in dungeon.",
        "full": "A cute dog-girl thief protagonist with leather-strap-armor and ninja weapons is exploring the grimdarkest dungeon depths. trending on Pixiv. trending on ArtStation. A vibrant digital oil painting. A highly detailed fantasy character illustration by Wayne Reynolds, Jason Chan, Jesper Ejsing, Charles Monet, Gustave Dore, Carl Critchlow, Bram Sels"
    },
    {
        "display": "Egyptian desert fantasy art",
        "full": "a digital painting of a desert scene, egyptian art by senior environment artist, artstation, fantasy art, egyptian art, concept art, artstation hq"
    },
    {
        "display": "Intricate digital art.",
        "full": "Very detailed. intricate, elegant, highly detailed. trending on artstation, digital art, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, Yoshitaka Amano"
    },
    {
        "display": "Giant brass marionette illustration",
        "full": "a render of a giant clockwork marionette made of brass and gold, puppet, ornate, engraved, highly detailed, fantasy, render, octane, raytracing, unreal engine, cinematic, trending on artstation, illustration, in the style of simon lee and jama jurabaev, piotr jablonski, artgerm and greg rutkowski, dishonored"
    },
    {
        "display": "Detailed Pok\u00e9mon jellyfish art",
        "full": "pokemon jellyfish, style game square enix life, trending on artstation, painted by greg rutkowski, render naughty dog, octane render, detailed"
    },
    {
        "display": "Cyberpunk ferret portrait",
        "full": "a portrait of an anthropomorphic cyberpunk ferret eating pie, cyberpunk!, fantasy, elegant, digital painting, artstation, concept art, matte, sharp focus, illustration, art by josan gonzalez"
    },
    {
        "display": "Kawaii sci-fi turtle witch",
        "full": "plasticy kawaii portrait of a silly bashful squishy scifi saliva turtle witch looks like mitch mcconnell, painfully adorable, soft bioluminescent membranes, gushy gills and baleen, translucent skin shows cute skeletal details in front of inky black background, friendly, occult, gelatinous with wittle tiny teeth, artgerm"
    },
    {
        "display": "Zombie Debbie Harry portrait.",
        "full": "young debbie harry as a flesh eating zombie with cuts on face, 7 days to die zombie, fine art, award winning, intricate, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by guweiz and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Fantasy warrior in fur armor.",
        "full": "fur armor!!! long wild white hair!! covered chest!!! fantasy, d & d, intricate ornate details, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render, unreal engine 5, highly rendered!!"
    },
    {
        "display": "Surreal cyberpunk interior.",
        "full": "rococo apartment interior with organic circular windows, soft neon lights, bright colors, cinematic, cyberpunk, lofi, nebula, calming, dramatic, fantasy, studio ghibli, rain like a dream, oil painting, surreal, overgrown, dreamlike, orchids, bold, basquiat + zdzis\u0142aw beksinski + loish + artgerm + rutkowski + moebius + francis bacon + gustav klimt, textural, pink, blue, purple, green, fluid lines, smooth render, organic volumetric lights, octane render, psychedelic, otherworldly, weird, vaporwave, interesting details, fantasy lut, epic composition, 8 k,"
    },
    {
        "display": "Cinematic Lovecraftian Istanbul.",
        "full": "a cinematic scene from the istanbul, solidity and eternity, lovecraft, concept art by beksinski and jean delville, dramatic lighting, ultra hd, hdr, 8 k"
    },
    {
        "display": "Ombre-haired woman portrait.",
        "full": "a award winning half body porttrait of a beautiful woman in a croptop with ombre purple pink teal hairstyle with head in motion and hair flying, outrun, vaporware, shaded flat illustration, digital art, trending on artstation, highly detailed, fine detail, intricate"
    },
    {
        "display": "\"Futuristic Mech Princess\"",
        "full": "Princess Rosalina in a scifi futuristic mech space suit by Ilya Kuvshinov, glass bubble helmet metal plastic neon lights, concept art Hyper realistic redshift render, artstation"
    },
    {
        "display": "Ronaldo under moonlight.",
        "full": "ronaldo luis nazario da lima, football player, moonlight, haze, low contrast, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Exotic plants on xenoplanet.",
        "full": "beautiful rendering of exotic plants by daniel merriam and karol bak and martin johnson heade, xenoplanet by roger dean and moebius, at night, ( ( ( misty atmosphere ) ) ), mantra rendering, matte painting, high detailed, 4 k, trending on artstation"
    },
    {
        "display": "Detailed pigeon sculpture.",
        "full": "pigeon. insanely detailed. by wlop, ilya kuvshinov, krenz cushart, greg rutkowski, pixiv. zbrush sculpt, octane, maya, houdini, vfx, in luxury advertisement, sharp focus, volumetric lighting"
    },
    {
        "display": "Cyborg Viking Illustration",
        "full": "diablo action game cyborg viking by artgerm, greg rutkowski, alphonse mucha, cgsociety and beeple highly detailed, sharp focus, cinematic lighting, illustration, art, octane render, unreal engine lumen, very coherent. cinematic, hyper realism, high detail, octane render, 8 k"
    },
    {
        "display": "Elegant armored fantasy heroine.",
        "full": "beautiful female Dorothy gale, full body character concept, armor, super powers, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, shining, sharp focus, illustration, art by stanley lau"
    },
    {
        "display": "Bioluminescent spooky doll portrait.",
        "full": "one singular portrait of a bioluminescent spooky child doll with big glowing eyes, highly detailed, digital painting, cinematic, hyper realism, dark retrowave, art by Stanley Lau and Artgerm and magali villeneuve and Alphonse Mucha, artstation, octane render, cgsociety"
    },
    {
        "display": "Weather man evaluation scene.",
        "full": "the weather man judging you, contrast, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Alien goddess close-up render",
        "full": "a highly detailed metahuman 4 k close up render of an alien goddess bella hadid monument spider in iris van herpen dress schiaparelli in diamonds crystals swarovski and jewelry iridescent in style of alphonse mucha gustav klimt trending on artstation made in unreal engine 4"
    },
    {
        "display": "Hipster man with chainsaw",
        "full": "old guy standing with a long chainsaw, tall, dressed as a hipster, fashion, sharp focus, detailed face, concept art, very detailed, very realistic, trending on artstation, in the style of maksim vorontsov, by artem priakhin, by wu shenyou, jeehyung lee, yuditya afandi, luis omar, sun haiyang, erak note, qi sheng luo,"
    },
    {
        "display": "Bob Ross painting Iron Man.",
        "full": "a closeup photorealistic photograph of bob ross diligently finishing a canvas painting depicting iron man. mountains and trees. film still. brightly lit scene. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Fox in hoodie writing",
        "full": "a fox in a blue hoodie typing in a notebook, white background, concept art, anime style, digital art picture, highly detailed, artstation, detailed, award winning, colorful"
    },
    {
        "display": "Elegant Pocahontas Portrait",
        "full": "portrait of pocahontas, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and uang guangjian and gil elvgren and sachin teng, symmetry!!"
    },
    {
        "display": "Pikachu in stylish sunglasses",
        "full": "pikachu!!!!!! wearing white acne studios mustang sunglasses, clout goggles, kurt cobain sunglasses, synthwave style, 8 k, unreal engine 5, 3 d render, trending on artstation,"
    },
    {
        "display": "Ethereal space fantasy art",
        "full": "inspiring space fantasy, ultra detail, digital painting, beautiful, concept art, ethereal, cinematic, epic, 8k, high detail, Artstation, illustration, Trending on Artstation, Artstation HQ, Artstation HD, deviant art, Pinterest, digital art"
    },
    {
        "display": "Cute cosmic anime entity.",
        "full": "A cosmic entity with big and cute eyes, smiling || VERY ANIME, fine-face, realistic shaded perfect face, fine details. Anime. realistic shaded lighting poster by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash, Rob Rey and Kentar\u00f5 Miura style, trending on art station"
    },
    {
        "display": "Homoerotic mountain hiker portrait",
        "full": "a homoerotic portrait by greg rutkowski and albert bierstadt and bill ward of a shirtless desi hiker on a mountain peak | he is wearing a revealing tartan kilt, cowboy hat, and leather boots | background is snowy mountains and clouds | detailed face, ethereal, dreamlike | trending on artstation"
    },
    {
        "display": "Dieselpunk Soviet police car.",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork costume design: dieselpunk Soviet 1980s police patrol car. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Giant robotic death machine.",
        "full": "a giant bipedal robot, of death with huge canon as arms, walking in the smoke of a destroyed city, imperator titan, concept art by emmanuel shiu and ruan jia and john berkey, trending on artstation"
    },
    {
        "display": "Hyperrealist watercolor character portrait.",
        "full": "a hyperrealist watercolour character concept art portrait of the holiest image. forgiveness. love. union. by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Anime portrait of Decker.",
        "full": "Portrait of Decker Brave Police anime (silver angular humanoid face and handsome!!) Snarling, concept art, by Greg Rutkowski, very detailed, Artstation, brave Police J Decker face, Giant Anime Mecha"
    },
    {
        "display": "\"Nature-themed female car destruction\"",
        "full": "pretty female wearing leaves crushing a car with vines, in the style of heonhwa choe, realistic, trending on artstation, digital art, highly detailed"
    },
    {
        "display": "Frog fishing by water.",
        "full": "frog fishing with a fishing pole by the water by anna podedworna, ayami kojima, greg rutkowski, giger, maxim verehin"
    },
    {
        "display": "Crying anime girl portrait",
        "full": "a very beautiful anime girl crying, mouth open, side profile view, tears streaming down eyes, full round face, cute top, white background, cinematic lighting, medium shot, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan"
    },
    {
        "display": "Cosmic giant girl holding planet.",
        "full": "a godly, wise, powerful giant girl wearing a skirt in space holding a small planet in her left hand. Soft lighting, cosmic skies, stunning, 8K, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "\"Female Android Deity\"",
        "full": "ultra detailed Female Android deity, scifi, octane render, clay render, (dia de los muertos), asymmetrical, intricate concept art, art by artgerm and DZO and greg rutkowski and loish and WLOP"
    },
    {
        "display": "Bear attacked by bees.",
        "full": "portrait of a bear getting attacked by swarm of angry bees in team fortress 2 style, detailed face, dark fantasy art, fantasy, pretty, hd shot, digital portrait, beautiful, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve, neoartcore and charlie bowater"
    },
    {
        "display": "Ceramic jug flying at night",
        "full": "Painting by Greg Rutkowski, at night a big ceramic jug with gold ornaments flies high in the night dark blue sky above a small white house under a thatched roof, stars in the sky, rich picturesque colors"
    },
    {
        "display": "Cosmic Jesus Arrival Artwork",
        "full": "the first coming of jesus by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Megan Fox portrait, detailed.",
        "full": "portrait of megan fox with orgasm face, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Cosmic aluminum rocket ship.",
        "full": "shiny aluminum rocket ship in cosmic space by tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, solar sails, sun, colorful nebulae, swirling galaxies, shining metal, glowing engines, masterpiece, trending on artstation, cinematic composition, beautiful lighting, sharp, details, hd, 8 k"
    },
    {
        "display": "Glass-encased Asian temple.",
        "full": "an asian temple encased in a glass box, trending on artstation, 4 k | 8 k, hyperrealistic, cinematic lighting, award - winning photography."
    },
    {
        "display": "Anime Medusa Portrait",
        "full": "anime medusa portrait, art by krenzcushart, by yoshitaka amano, kentaro miura, artgerm, wlop, artsyle of nier automata concept art"
    },
    {
        "display": "Emma Watson illustration, whimsical.",
        "full": "beautiful full body Emma Watson goofy smiling illustration by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and Cecil Beaton, Lee Miller, Irving Penn, David Bailey, digital art, highly detailed, intricate, sharp focus, Trending on Artstation HQ, deviantart"
    },
    {
        "display": "Galaxy Iris Eye Art",
        "full": "low angle shot of a eye with the galaxy in the iris by clive barker, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, Tomasz Alen Kopera, Peter Mohrbacher donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo."
    },
    {
        "display": "Cinematic portrait of redhead.",
        "full": "portrait of a beautiful redhead woman, mylene farmer, flowy hair, cinematic lighting, highly detailed, digital painting, trending on artstation, pixiv, concept art, sharp focus, illustration, hyper detailed digital matte painting, concept art, cinema 4 d, 8 k resolution, trending on artstation, behance hd"
    },
    {
        "display": "Portrait of a bearded man",
        "full": "blonde wild hair beard man, black eye - patch, close - up portrait, plain white tshirt, powerfull, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Sinking cityscape artwork",
        "full": "A city sinking into the ocean, matte painting, digital art, trending on artstation, award-winning art, dramatic, 8k"
    },
    {
        "display": "Bloodied skeleton woman",
        "full": "woman skeleton covered with blood, long red hair, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. retro film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk Darwin Cyborg.",
        "full": "duotone retofuturistic ghastly scifi 3 / 4 portrait of charles darwin as steampunk cyborg in biopunk genetic lab with animal skeletons. scifi fretro future atmosphere. cinematic scene volumetric lighting. golden rario accidental renaissance. concept by stanley kubrick dr strangelove style and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Hyper-realistic Tron Jesus",
        "full": "tron legacy jesus, diffuse lighting, hyper realistic, concept art, intricate, hyper detailed, smooth, sharp focus, illustration, artstation, greg rutkowski, james gurney, alphonse mucha"
    },
    {
        "display": "Zombie Latina in streetwear",
        "full": "zombie full body latina hiphop streetwear drip, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Grumpy Stephen Fry, anime style.",
        "full": "lovecraftian portrait of grumpy stephen fry, anime style, by tristan eaton stanley artgerm and tom bagshaw"
    },
    {
        "display": "Megan Fox fantasy portrait",
        "full": "portrait of megan fox, muscular upper body, slave collar, ballgag, dress, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Astronaut concept art sketch",
        "full": "full body astronaut sketch, concept art, digital art, in the style of darren bartley, katsuya terada"
    },
    {
        "display": "Portrait of Zendaya, elegant.",
        "full": "A full portrait of Zendaya, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, art by bouguerea"
    },
    {
        "display": "Bearded sorcerer in lab.",
        "full": "a handsome bearded caucasian male sorcerer with brown hair, he is in a alchemist lab, casting a spell from a open book, beakers, potions, magic, neutral pose, digital art, concept art, epic composition, 4 k, super coherent, by dave melvin and greg rutkowski"
    },
    {
        "display": "Ultra-realistic barbarian birthday celebration.",
        "full": "ultra realistic illustration of isabelledeltore, barbarian celebrate his birthday, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Abstract rage painting",
        "full": "rage, rage against the dying of the light | abstract art painting | high contrast | inspired by george condo | inspired by jean arp | trending on artstation"
    },
    {
        "display": "Anorexic cult transforming into deities.",
        "full": "bright realistic anorexic end of the world family cult turning into gods and deamons and smiling franticly, old apartment, rotten flesh, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by francis bacon and jenny saville"
    },
    {
        "display": "Demonic alien archangel artwork.",
        "full": "photorealistic demonic alien archangel the style of michael whelan and gustave dore. hyperdetailed photorealism, 1 0 8 megapixels, amazing depth, glowing rich colors, powerful imagery, psychedelic overtones, 3 d finalrender, 3 d shading, cinematic lighting, artstation concept art"
    },
    {
        "display": "Cyberpunk anime woman.",
        "full": "\u201dbeautiful anime woman, modern, cyberpunk, fantasy, eerie, intricate details, atmospheric, elegant, super highly detailed, professional digital painting, artstation, concept art, 8k, art by artgerm and alphonse mucha and eiichiro oda\u201d"
    },
    {
        "display": "Surreal biomechanical portrait",
        "full": "portrait of ribbed abandoned biomechanical man with a spaceship on exoplanet in a desolate empty wasteland, creepy, nightmare, dream-like heavy atmosphere, surreal abandoned buildings, baroque painting, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, chiaroscuro, cinematic volumetric light, Raphael, Caravaggio, Beksinski, Giger"
    },
    {
        "display": "Elegant portrait of Richard Kind.",
        "full": "close up of richard kind as paul lassiter wearing a brown suit and necktie, focus, d & d, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Quaint seaside village illustration",
        "full": "quaint seaside village landscape as seen from above, no people, pokemon sword and sheild, bright future, social realism, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Confident mechanic on robot.",
        "full": "a full body portrait of a confident mechanic atop a steampunk robot, view of an city with sakuras, golden hour lighting delicate features finely detailed perfect art, at an ancient city, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Demonic anthropomorphic sloth.",
        "full": "unholy sloth, sloth monster, animal sloth, anthropomorphic sloth, demon sloth, demonic robes, damnation, hell, fear, dirty matted fur, evil, horror, fire, gloomy mood, ultra details, art by artgerm, dwayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Ultra-realistic hell chamber",
        "full": "ultra realistic illustration,, hell torture chamber interior from doom and warhammer, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Joyful rugged pirate portrait",
        "full": "portrait of a young ruggedly handsome but joyful pirate, male, masculine, upper body, red crimson hair, long long long flowing hair, fantasy, proud smirk, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dynamic portrait of woman.",
        "full": "a award winning half body portrait of a beautiful caucasian woman in a croptop and cargo pants with ombre orange blue teal hairstyle with head in motion and hair flying by yoshii chie and hikari shimoda and martine johanna and will eisner, outrun, vaporware, digital art, trending on artstation, highly detailed, fine detail, intricate"
    },
    {
        "display": "Detailed closeup of Arthur Morgan",
        "full": "closeup, Arthur Morgan, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Ultra-detailed fantasy character portrait",
        "full": "heimdall ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Futuristic astronaut in spaceship",
        "full": "concept art by craig mullins astronaut in futuristic dark and empty spaceship in infrared complex and hyperdetailed technical suit. mandelbulb fractal. reflection and dispersion materials. rays and dispersion of light breaking through the volumetric space. 5 0 mm, f / 3 2. noise film photo. flash photography. unreal engine 4, octane render."
    },
    {
        "display": "Realistic marble bust of Elsa",
        "full": "marble bust real life version of Elsa from disney's frozen, made of Carrara marble, in white marble, detailed, intricate, CGI, cgsociety, trending on artstation, photograph, single subject, by Gian Lorenzo Bernini"
    },
    {
        "display": "Man on bicycle among lanterns.",
        "full": "man on bicycle in a land full of lanterns, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Beekeeper warrior commanding bees",
        "full": "Beekeeper warrior summoning an army of bees to descend on their foes, peter mohrbacher, anato finnstark, artgerm"
    },
    {
        "display": "Haunted ghost princess portrait",
        "full": "portrait of a beautiful ghost princess in a haunted mansion, incredible quality, trending on artstation"
    },
    {
        "display": "Island of blonde women.",
        "full": "portrait of an island made of blonde women, real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Donald Trump as Black Widow",
        "full": "Donald Trump as black widow, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Handsome orc in leather.",
        "full": "A full body shot of a handsome orc looking into the camera wearing a leather fur jacket and boots, full body shot, detailed face, artstation, realistic, highly detailed, symmetrical, hyper realistic, dynamic pose, high detail, octane render, unreal engine, 8k, fantasy art, highly detailed, concept art"
    },
    {
        "display": "Addison Rae as Wonder Woman.",
        "full": "full body portrait of addison rae as wonder women wearing a skintight dress in a beach, large thighs, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Elven Archer in Leaf Armor",
        "full": "male elven Archer armor made of green leaves, fantasy, amber eyes, face, long hair, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "STALKER mutant battle scene",
        "full": "a beautiful highly detailed screenshot of STALKER Shadow of Chernobyl of a stalker shooting in mutant with his shotgun  by Jose Daniel Cabrera Pena and Leonid Kozienko, concept art by Tooth Wu and wlop and beeple and dan mumford and greg rutkowski and nekroxiii. octane render, cinematic, hyper realism, octane render, 8k,  iridescent accents. vibrant, teal and gold blue red dark noir colour scheme"
    },
    {
        "display": "Surgeon performing turtle autopsy.",
        "full": "surgeon doing a turtle autopsy, science fiction industrial hard science concept art, 8K render octane high definition cgsociety, illustration, unreal engine"
    },
    {
        "display": "Pikachu as Detective Conan",
        "full": "A movie still of Pikachu as Detective Conan, exploration, photorealistic, concept art, cinematic, trending on artstation , realistic , 20mm camera , corona render , rule of thirds, hyper detailed , octane , 8k, photo-realistic maximum detail , volumetric light moody cinematic epic , ultra photoreal, octane render, render in unreal engine 5, 8k"
    },
    {
        "display": "Bald Black woman glassblowing",
        "full": "ultra realistic illustration, bald young black woman blowing glass, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Thin man in beret on TV",
        "full": "extremely detailed illustration of a thin guy with a big nose and an overbite wearing a brown beret and a palid green plaid shirt stand up participating on a tv show, coloful, vibrant colors, symmetrical, anatomically correct, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Elegant pinup of Tracer",
        "full": "pinup of tracer from overwatch, elegant, high detail, backlit, smooth, sharp focus, soft light, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy avatar illustration",
        "full": "Avatar, fantasy, illustrated by Greg Rutkowski and Caspar David Friedrich, high detail, god rays, Trending on artstation, artstationHD, artstationHQ"
    },
    {
        "display": "Snowy Arctic monster",
        "full": "A monster in the Arctic covered in snow, fractal Lighting, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, trending on artstation"
    },
    {
        "display": "Smoky skull wine bottle.",
        "full": "wine bottle and a skull on the table, skull made out of smoke coming out of bottle, fantasy, intricate, elegant, highly detailed, digital painting,  artstation, concept art, smooth, sharp focus, illustration, art by Ilja Repin"
    },
    {
        "display": "Chiaroscuro portrait of woman.",
        "full": "daniel gerhartz and artgerm portrait digital chiaroscuro painting of a beautiful woman wearing streetwear clothing, abandoned warehouse interior in the background, unreal engine, hyper realism, realistic shading, cinematic composition, realistic render, octane render, detailed textures, photorealistic, ultrawide shot, 3 5 mm film"
    },
    {
        "display": "Dog snooping Snoop Dogg",
        "full": "A dog snooping the rapper Snoop Dogg, rococo painting, smooth, sharp focus,, pixiv, ultra highly detailed"
    },
    {
        "display": "Blonde angel portrait, detailed.",
        "full": "portrait of megan fox as an blonde angel, wings, bible, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Cyborg rabbit assassin portrait",
        "full": "Full body portrait of a cyborg rabbit assassin, in black carbon and red fiber sci-fi armor, tech wear, sci-fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, trending on art station, dynamic lighting, cinematic, ultra detailed, fantasy concept art"
    },
    {
        "display": "Evil necromancer attack.",
        "full": "The evil necromancer, Jake Gyllenhaal attacking a group of adventurers in the continent of Faerun, dungeons and dragons, magical, medieval fantasy, ultra detailed, 4k, trending on artstation by H.R. Giger"
    },
    {
        "display": "Fantasy tavern scene",
        "full": "fantasy art of a bustling tavern in china, at night, by pixar, highly detailed digital art, trending on artstation"
    },
    {
        "display": "Futuristic Bruce Willis Pin-Up",
        "full": "portrait of bruce willis dressed as pin - up, holding a gun, distopic, the fifth element, futuristic, science fiction, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety,"
    },
    {
        "display": "Fox in hoodie with mask.",
        "full": "an anthropomorphic fox wearing a hoodie and anonymous mask and sitting in front of a portable black computer, white background, concept art, digital painting, highly detailed, style by jordan grimmer and greg rutkowski, illustration"
    },
    {
        "display": "Ornate medieval hobbit home",
        "full": "a medieval hobbit home, ornate, beautiful, atmosphere, vibe, mist, flowers, concept art illustration, color page, 4 k, tone mapping, doll, akihiko yoshida, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, digital illustration, greg rutowski, volumetric lighting, sunbeams, particles"
    },
    {
        "display": "Hyperrealistic League of Legends artwork",
        "full": "revelatin, league of legends, intricate, highly detailed, digital painting, hyperrealistic, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha, by Jesper Ejsing"
    },
    {
        "display": "Elegant banana phallic symbol",
        "full": "a banana as a giant phallic symbol, highly detailed, elegant, intricate, digital painting, artstation, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical silver alien portrait",
        "full": "symmetry!! portrait of silver alien in the style of horizon zero dawn, machine face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Art Nouveau Favela in Space",
        "full": "art nouveau favela, outer space environment, scenery, professional, award - winning, trending on artstation, hyper detailed, realistic, beautiful, emotional, shiny, golden, picture"
    },
    {
        "display": "Robotic gorgon illustration",
        "full": "a robotic ancient gorgon with snakes on her head, vibrant lighting, elegant, highly detailed, smooth, sharp focus, illustration, beautiful, geometric, trending on artstation, full body, cinematic, artwork by borovikovsky"
    },
    {
        "display": "Dramatic saint portrait.",
        "full": "portrait burning saint face, venus, athena, halo, by alphons mucha and annie swynnerton and jean delville, strong dramatic cinematic lighting, ornate headdress, flowing robes, spines, flowers, stars, lost civilizations, smooth, sharp focus, extremely detailed, marble, molten gold, space"
    },
    {
        "display": "Impressionist gold world tree.",
        "full": "a beautiful painting of the gold world tree on the sea by ivan aivazovsky and greg rutkowski and james gurney, in style of impressionism. dark fantasy, elden ring, hyper detailed, sharp focus, soft light. unreal engine 5, oil on canvas, ray tracing. trending on artstation"
    },
    {
        "display": "Taylor Swift as Lola Bunny",
        "full": "portrait of Taylor Swift as Lola Bunny in Space Jam. HD, 4K. intricate abstract. intricate artwork. by Tooth Wu, wlop, beeple, dan mumford. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k, iridescent accents"
    },
    {
        "display": "Stylish socialite in forest.",
        "full": "beautiful digital painting of a stylish socialite forest with high detail, real life skin, freckles, 8 k, stunning detail, works by artgerm, greg rutkowski and alphonse mucha, unreal engine 5, 4 k uhd"
    },
    {
        "display": "Ethereal woman in forest",
        "full": "portrait of elegant beautiful woman,  ethereal, expressive pose, intricate dress, fantasy, intricate,  forest background, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Realistic illustration of Link",
        "full": "if link was a real boy, beautiful shadowing, 3 d shadowing, reflective surfaces, 8 k, beautifully detailed pencil illustration, intricate, epic composition, masterpiece, bold complimentary colors. stunning masterfully illustrated by artgerm, range murata, alphonse mucha"
    },
    {
        "display": "Futuristic communist architecture",
        "full": "architecture concept art by fan wennan future capitol of the american communist party shining in the sun after the triumph of socialism in america, hyperdetailed, cinematic, photorealistic, hyperrealism, masterpiece, future communist governmental architecture, statue, imposing, strength, abundance, life. america 2 0 9 8"
    },
    {
        "display": "Android man in park.",
        "full": "andoid man in coat hiding in park, sharp focus, highly detailed vfx scene, global illumination, by james jean and moebius!! and artgerm and liam brazier and victo ngai and tristan eaton. detailed, vector art, digital illustration, concept art. 8 k, hdr hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Whimsical woman in lake.",
        "full": "whimsical, a single beautiful playful woman, wearing professional makeup, standing in a lake, under the stars, with a binary black hole with a ring in the sky, by Lois van Baarle, by Greg Rutkowski, by artgerm, by ross tran, cinematic angle, face enhance, volumetric lighting, cinematic lighting, digital art, 4k resolution, octane render, trending on artstation, masterpiece"
    },
    {
        "display": "Androgynous character in starry cloak.",
        "full": "a person wearing a starry cloak, purple vest, white sleeves, androgynous, aurora colored hair, starry eyes, curious expression, watching people on the street, character art, full body art, dungeons and dragons, d & d, trending on artstation, artgerm, 4 k ultra hd, sharp focus, digital art by ilya kuvshinov and ross tran,"
    },
    {
        "display": "Anime female astronaut, cyberpunk.",
        "full": "anime visual of a female astronaut, neon, cyberpunk, black futuristic suit, stunning, highly detailed, digital painting, artstation, smooth, soft focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lofi Pixar Scarlett Johansson",
        "full": "lofi venom portrait of scarlett johansson in bikini, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Biblically accurate angel painting",
        "full": "biblically accurate angel, oil painting, by Greg Rutkowski"
    },
    {
        "display": "Iridescent female mage robot",
        "full": "hyperdetailed portrait, dramatic angle, detailed azure eyes, stunningly beautiful female mage robot made of iridescent metals and shiny gems, ethereal rainbow nimbus, golden necklace, noble perfect androgynous skeleton inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, highly detailed, photorealistic, 8 k, huang guangjian, cgsociety"
    },
    {
        "display": "Poet with blue eyes.",
        "full": "a beautiful masterpiece painting of the last poet whispering,'if all can begin again, then everything must continue!'by juan gimenez, slight smile, long shiny black hair blue eyes, award winning, trending on artstation, photorealistic, hyperrealism, octane render, unreal engine"
    },
    {
        "display": "Larry David Poker Anime",
        "full": "larry david playing poker by Zeronis and Avetetsuya Studios and Mitsu Art, red tie, tan jacket, grey eyes, symmetrical face, anime, line art, trending on artstation, artstationHD, artstationHQ, patreon, 4k, 8k"
    },
    {
        "display": "Mysterio portrait, fantasy art",
        "full": "a portrait of mysterio, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Romantic anime couple on boat.",
        "full": "anime key visual of a young man anime and young woman anime sitting together on one single long wooden rowboat. Romantic. Girl has auburn hair. Boy has short black hair. Boy and girl. Boy and girl. Narrow river in a forest, rocky shore, trees, shady, blue waters, ripples, waves, reflections, details, sharp focus, illustration, by Jordan Grimmer and greg rutkowski, Trending artstation, pixiv, digital art"
    },
    {
        "display": "Isometric gold mine illustration",
        "full": "gold mine, isometric game, smooth 3 d illustration, cinematic matte painting, soft render, servando lupini, handpaint texture, blender, 3 dcoat, concept art by noah bradley, darek zabrocki, tyler edlin, jordan grimmer, neil blevins, james paick, natasha tan, highly detailed, trending on artstation"
    },
    {
        "display": "Moon Knight Goku Fusion",
        "full": "Moon Knight mixed with Goku, RPG Reference, Oil Painting, Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Violet-haired fantasy heroine.",
        "full": "A combination of Katheryn Winnick's and Grace Kelly's and Katherine McNamara's faces with short violet hair as Motoko Kusanagi from Ghost in The SHell, western, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Mystical goddess festival scene",
        "full": "a centered full body photo of alluring mystical goddess festival hippies with tribal paintings surrounded by a underwater ink pour and flowing liquid galium and sacred geometry, perfect face, powerful, cinematic, beautifully lit, by artgerm, by karol bak, by viktoria gavrilenko, 3 d, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Fantasy portrait of redhead girl.",
        "full": "highly detailed key visual concept portrait of a red hair beautiful girl in a genshin impact, fantasy illustration, by coco kim, photorealistic, unreal engine, detailed and intricate environment, trending on artstation"
    },
    {
        "display": "Kawaii female Goku artwork",
        "full": "kawaii female version of goku, painting by WLOP and artgerm"
    },
    {
        "display": "Cats and raccoon at sunset.",
        "full": "a closeup photorealistic photograph of a two different black adult cats sitting on 4 paws next to big a black and white adult racoon in a field of poppy  with a red sunset in the background. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and the style of Unreal Engine."
    },
    {
        "display": "Alopecia teen Medusa portrait.",
        "full": "portrait of alopecia spanish teen medusa, snakes grow from her head, wearing an embroidered rusty tunic, dark background, intricate, elegant, copper and emerald jewelry, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mucha, artgerm, and greg rutkowski"
    },
    {
        "display": "Eerie redhaired woman in alley.",
        "full": "A ultradetailed eerie beautiful matte painting of a dangerous redhaired woman walking towards me in a dark alley at night by tom bagshaw concept art, trending on deviantart, illustration, film noire."
    },
    {
        "display": "Pixar Mom Character Model",
        "full": "A Mom, pixar character model sheet turnaround, studio, trending in Artstation, official media, 4K HD, by Bill Presing"
    },
    {
        "display": "Homoerotic cowboy tarot card",
        "full": "tarot card of a shirtless handsome mustached cowboy | he is holding a lantern | cloak, dad bod, homoerotic, art deco, art nouveau | by louis comfort tiffany | trending on artstation"
    },
    {
        "display": "Hipster dog convertible Monte Carlo",
        "full": "a painting of a hipster dog driving a convertible in monte carlo, in the style of greg rutkowski, highly detailed, artstation"
    },
    {
        "display": "Chaotic liquid smoke portrait",
        "full": "artificial consciousness, delirium, chaotic storm of twisting liquid smoke portrait, by tom whalen, liam brazier, peter mohrbacher, artgerm, shattered glass, bubbly underwater scenery, radiant light"
    },
    {
        "display": "Detailed anime portrait.",
        "full": "portrait of hisoka morow hunter hunter, male, extremely sharp jaw yellow eyes narrow slit sultry eyes red hair soft bunched hair swept back crimson medium length hair, anime, fantasy, intricate, elegant, highly detailed, digital painting, artstation sharp focus, madonna art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bear lifting girl for peach.",
        "full": "digital painting of an anthropomorphic asian black bear, lifting up a small girl wearing a pink kimono, to pick a golden peach from a branch above their heads. fantasy. concept art. award winning. artstation. deviantart. in the style studio ghibli and top ten fantasy artists"
    },
    {
        "display": "Cyberpunk woman portrait",
        "full": "hyperrealistic portrait of a mysterious cyberpunk woman with flowing hair, by Guy Denning, Russ Mills, beautiful, elusive, glitch art, hacking effects, glitch effects, photo real, brown eyes, digital tech effects, cybernetics, detailed lines, intricate detail, holographic, polished, chromatic, clear, color blocking, acrylic on canvas, octane, concept art, abstract, red face, front view, 8k, masterpiece, cgsociety, trending on artstation"
    },
    {
        "display": "Robotic woman with synthesizers.",
        "full": "a photo of a robotic woman covered in modular synthesizer parts surrounded by sacred geometry made from elven architecture, full body, perfect face, powerful, cinematic, beautifully lit, by artgerm, by karol bak, 3 d, octane render, 8 k"
    },
    {
        "display": "Wild West book cover",
        "full": "ismail inceoglu epic painting of a wild west cover, line art!!, ink, art concept for a book cover!!, red dead redemption, warm colours, blue and orange and green and grey and white colors, nice sky, oil painting, think brushstrokes, highly detailed, centered, hyperrealism, romantic, concept art, smooth, sharp focus trending on artstation, by greg manchess and by craig mullins and by kilian eng and by jake parker"
    },
    {
        "display": "Duotone sci-fi portrait",
        "full": "duotone illustration 3 / 4 portrait of molly millions from neuromancer. sliver glowing glasses, claw blades in fingers, trinity matrix, tech noir volumetric lighting. dynamic composition. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. concept art. trending on artstation"
    },
    {
        "display": "Underground aquarium observation.",
        "full": "people observing lots of beautiful fish in an underground aquarium corridor, in the style of andreas hoher, fantasy art, ray tracing, water droplets, highly detailed, artstation trend, highly detailed and intricate, sharp focus, photography, unreal engine 5"
    },
    {
        "display": "Hyperrealistic minimalistic sculpture",
        "full": "white 1 6 k nft - generative collectible trending on superrare and artblocks ( code - art, kawaii - shotacon - bdsm 3 d anime - hyperrealistic style, funko - lego pop - memeart ( with human hyperrealistic faces of flirck ) : fractal pop diva androgynous boy with cat - clown makeup and mk - ultra satanic tattoos by nick knight ) by lee griggs : an intricate and extremely detailed hyperrealistic and hyper symmetrical white 3 d photoart / vertical fractal wallpaper ( ultra - minimalistic ) / sculpture ( white angelic neon - monochrome minimalistic composition, white cyborg clothes by andrew thomas huang, white minimalistic cat visuals concept by peter birkhauser and louis wain, and 3 d - seminude human sculpture by ron mueck ) of two handsome model gay boyfriends with stylized makeup as cat - clown - cyborgs with two twin flames of love : 1 | minimalistic 3 d hyperrealistic - human schizophrenic art visuals by peter birkhauser and louis wain white high contrast : a single close up photo - real delicate ceramic black porcelain, high detailed face, photorealism, golden ratio, hyper - realistic 3 d, insanely super detailed, realistic octane render, 1 6 k ultahd, minimalistic vulnicura fashion ( by james merry ), jewelry fashion by maiko takeda ), fashion photography - art by nick knight, hard light goddess detailed in front of an intricate background : 0. 8 | micro detail, backlit lighting, face in focus, subsurface scattering, translucent, thin porcelain, physically based rendering, japanese pottery, trending on cgsociety"
    },
    {
        "display": "Anime maid in baroque style",
        "full": "baroque oil painting of anime key visual concept art of anime maid with cat ears lying down on sofa, soft lighting, new york apartment, potted plants, big window, trending on artstation, brush strokes, oil on canvas, style of makoto shinkai and greg rutkowski and studio ghibli"
    },
    {
        "display": "Hyper-detailed fantasy landscape.",
        "full": "made in abyss concept art wlop James Jean Marc Simonetti trending on artstation hyperdetailed Unreal Engine 4k 8k ultra HD"
    },
    {
        "display": "Mischievous young witch illustration",
        "full": "full shot cute mischievous young witch getting up to trouble, floral huipil, black and orange, magic, latina girl, defined facial features, symmetrical facial features. by ruan jia and artgerm and range murata and wlop and william adolphe bouguereau, key art, fantasy illustration, award winning, artstation, intricate realistic hyperdetailed 8 k hdr"
    },
    {
        "display": "Lofi cavern portrait, Pixar style",
        "full": "Lofi portrait in cavern, Pixar style by Joe Fenton and Stanley Artgerm and Tom Bagshaw and Tim Burton"
    },
    {
        "display": "Psychedelic Buddha Medusa portrait",
        "full": "an extremely psychedelic portrait of buddha as medusa, surreal, lsd, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Symmetrical underwater portrait.",
        "full": "symmetry!! portrait of elizabeth from bioshock, vintage dress, glowing lights!! underwater atmosphere, intricate, serene, highly detailed, digital painting, artstation, symmetric concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Cute, intricate 3D fluff",
        "full": "fluff, cute:: by beeple and James Gilleard and Justin Gerard :: ornate, dynamic, particulate, intricate, elegant, highly detailed, centered, artstation, smooth, sharp focus, octane render, 3d"
    },
    {
        "display": "Icy goddess shrine illustration",
        "full": "female goddess in an icy realm at a shrine to worship her at, intricately detailed, physically based rendering, realistic, in the style of WLOP, illustration, epic, fantasy, hyper detailed, smooth, unreal engine, sharp focus, ray tracing"
    },
    {
        "display": "Cute anime dog girl",
        "full": "cute, full body, female, anime style, a dog girl with fairy wings, large eyes, beautiful lighting, sharp focus, simple background, creative, heart effects, filters applied, illustration, trending on artstation"
    },
    {
        "display": "Beavers building futuristic city",
        "full": "hyperrealism photography hyperrealism concept art of highly detailed beavers builders that building highly detailed futuristic city with bricks by wes anderson and hasui kawase and scott listfield sci - fi style hyperrealism rendered in blender and octane render"
    },
    {
        "display": "Futuristic Maasai warrior portrait",
        "full": "ultra detailed fantasy of maasai warriorin futuristic armor, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "David Bowie as Joker",
        "full": "character full body portrait of david bowie as the joker | | realistic shaded, unpleasant face, bad looking, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Giant skeleton canyon illustration",
        "full": "a canyon made of giant skeletons, fantasy, sci - fi, intricate, elegant, dramatic lighting, highly detailed, lifelike, photorealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by beksinski and john blanche and paul dainton and albert aublet and artem demura and alphonse mucha"
    },
    {
        "display": "Christina Aguilera as Black Widow",
        "full": "christina aguilera as black widow, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Headless astronaut statue",
        "full": "full-body rococo and cyberpunk style chrome statue of a headless astronaut on the moon with futuristic elements. ethereal white dripping tar. full-length view. human skulls on altar. eldritch energies disturbing frightening intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Romantic divine creation scene",
        "full": "a stunning and noble highly detailed romantic period style painting of the place where gods come into being by josep tapiro baro, by harles sillem lidderdale, by malika favre, by roger dead, trending on artstation, oil painting masterpiece, symmetry, rule of thirds, mysterious"
    },
    {
        "display": "Dark witch portrait, cinematic art",
        "full": "glowing silver and golden elements, full close-up portrait, Faye Reagan as a dark witch, book cover, green forest, white moon, red lips, establishing shot, extremly high detail, photo-realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie, raphael lacoste, alex ross"
    },
    {
        "display": "Owl playing flute.",
        "full": "owl playing cross flute, concept art"
    },
    {
        "display": "Symmetrical portrait of Bob Odenkirk.",
        "full": "symmetry!! portrait of bob odenkirk in cement, with a gold laurel wreath on head, dramatic rendering, fantasy, medieval wear, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Temple of Internet Interior",
        "full": "extremely beautiful high detailed long portrait of a solid interior scene with a temple of internet. by daniel f. gerhartz and artgerm and greg rutkowski. intricate details, sci - fi details, a lot of monitors, energetic mood, golden ratio composition, trending on artstation, volumetric lighting, 4 k, 8 k"
    },
    {
        "display": "Cleric battling zombies.",
        "full": "cleric in ravenloft surrounded by zombies, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and andrei riabovitchev"
    },
    {
        "display": "Beautiful valkyrie android portrait",
        "full": "full body shot of a biblical diabolical beautiful female valkyree android, shiny plastic armor, fly, dynamic pose, shadows, heavy eyes to the side, closeup, bright glowing veins, in clouds, rain, sunset, portrait, by gerald brom, by mikhail vrubel, by peter elson, muted colors, extreme detail, reflections, trending on artstation, 8 k"
    },
    {
        "display": "Cinematic robot portrait.",
        "full": "portrait of a robot made of clock parts, cinematic, volumetric lighting, dramatic ambient lighting, epic composition, high detail, octane render, unreal engine, 8 k, vibrant colors, depth of field, concept art, digital art, deviantart artstation, ray tracing, intricate complexity, extremely detailed, apocalyptic, very sharp,"
    },
    {
        "display": "Capybara market movie poster",
        "full": "The capybara market , artwork by Craig Mullins,Movie poster, detailed, trending on artstation"
    },
    {
        "display": "Symmetrical robot portrait",
        "full": "symmetry portrait of a robot grinning, sci - fi, tech wear, blue and yellow glowing lights, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Jupiter storm with jets",
        "full": "f 3 5 jets in the storm clouds of jupiter, by cedric peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on artstation"
    },
    {
        "display": "Luminous fairy under moonlight.",
        "full": "attractive, fairy, in the night, fantasy, crescent moon in background, luminous, toadstools, fireflies, fantasy, highly detailed painting, mid shot, 8 k realistic, fantasy, mist, sharp focus"
    },
    {
        "display": "Mountain monastery at night",
        "full": "the ascending mountain to the monastery, fine details, night setting. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Psychedelic portrait of Hitler.",
        "full": "bright psychedelic portrait of cute adolf hitler having fun at a pool party, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Hyper-detailed Lobo artwork",
        "full": "lobo, from simon bisley art, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Zombie punk rocker portrait",
        "full": "zombie punk rocker playing guitar, tristan eaton, victo ngai, artgerm, rhads, ross draws, intricated details, 3 / 4 view, full body portrait"
    },
    {
        "display": "Vampire warrior portrait.",
        "full": "closeup portrait shot of a vampire warrior in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Symmetrical Ursula portrait",
        "full": "symmetry portrait of ursula, octopus, glowing lights, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Male healer concept art",
        "full": "concept art of a male healer. game of thrones character design by laurie greasley and sherree valentine daines concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Valkyrie beach relaxer.",
        "full": "a short woman, valkyrie style character, relaxing on the beach, bodysuit, tropical background, sunset, vibrant, psychedelic colors, by ilya kuvshinov, krenz cushart, pixiv. zbrush sculpt, octane, maya"
    },
    {
        "display": "Anime girl with guitar.",
        "full": "neowiz game art drawing painting detailed cinematic guitar girl in anime manga squareenix mcu style trending on pixiv skeb artstation"
    },
    {
        "display": "Cyberpunk Roman god chef",
        "full": "the roman god of food, wearing a soiled apron, service short order food in a cyberpunk diner, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety, edward hopper"
    },
    {
        "display": "Knight queen at dawn.",
        "full": "breathtaking detailed painting of a full shot knight queen with long flowing bright blue hair, pastel flowers petals and golden tumultuous clouds, art by pilyeon and yuumei art, symmetrical facial features, at dawn in front of a pristine golden art nouveau cathedral, elegant, volumetric lighting, highly detailed, artstation, concept art, matte, sharp focus,"
    },
    {
        "display": "AI server with blue lights",
        "full": "artificial intelligence, server in the middle, deep view, heavy blue led lights, wires connected, award winning photography, extremely detailed, artstation, 8 k, incredible art, wlop, artgerm"
    },
    {
        "display": "Colorful treant character portrait",
        "full": "treant birch, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Lonely astronaut on Jupiter",
        "full": "lonely astronaut in jupiter, epic scene, by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Bernedoodle with crown and orchids.",
        "full": "beautiful bernedoodle with a gold crown by alex grey + orchids dripping black iridescent liquid, winged victory, moody, dramatic, introspective, marble columns in background, transcendent, vibrant color, ornamented, clean linework, finely detailed, 4 k, trending on artstation, photorealistic, volumetric lighting, octane render"
    },
    {
        "display": "Borges with non-Euclidean entity.",
        "full": "Jorge Luis Borges with non-euclidean being in his hand. Eldritch, Hyper realistic, 8K, concept art, filmic, HDR, volumetric lighting, highly detailed, cinematic lighting, artstation, vibrant nature, volumetric light, octane render, redshift render, camera close up, rich deep moody colors, ultra realistic"
    },
    {
        "display": "Turquoise-haired girl illustration",
        "full": "beautiful girl with long turqoise hair, cute, intricate, highly detailed, digital painting, trending on artstation, concept art, smooth, sharp focus, backlit, rim light, vivid colors, illustration, unreal engine 5, 8 k, art by rossdraws and alphonse mucha"
    },
    {
        "display": "Nathan Fillion as Bond",
        "full": "Nathan Fillion as James Bond in Goldeneye 007 in an air vent above bathroom cubicle, D&D, cinematic, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse much"
    },
    {
        "display": "Symmetrical moss princess portrait",
        "full": "symmetry portrait of moss princess, glam, fae, fireflies, forest background, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and fra angelico and alphons mucha"
    },
    {
        "display": "Horror-themed anime portrait.",
        "full": "a portrait of jacksepticeye by Anna Dittmann, digital art, horror, trending on artstation, anime arts, featured on Pixiv, HD, 8K, highly detailed, good lighting, beautiful, epic, masterpiece - H 768 n 9"
    },
    {
        "display": "Reptilian alien in office.",
        "full": "Reptilian space alien, in an office building, medium shot, dramatic backlighting, autochrome, high contrast, highly detailed, sharp focus, digital painting, concept art, illustration, filmpunk , trending on artstation, art by greg rutkowski and greg hildebrandt, composition by alphonse mucha"
    },
    {
        "display": "Aubrey Plaza polymer clay sculpture",
        "full": "aubrey plaza made out of polymer clay detailed sculpture trending on artstation"
    },
    {
        "display": "Female medieval berserker illustration.",
        "full": "a female medieval berserker, D&D, fantasy, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Robots hugging under stars.",
        "full": "Two robots hugging each other while watching a shooting star in the night sky, cinematic lighting, insanely detailed, intricate, artstation, cgsociety, painted by Simon Stalenhag, concept art, illustration, sharp focus,"
    },
    {
        "display": "Highly detailed Daenerys illustration.",
        "full": "daeneryus, game of thrones, super highly detailed, professional digital painting, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Elegant organic cyborg illustration",
        "full": "organic cyborg, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by john collier and albert aublet and krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Detailed Black Mesa landscapes",
        "full": "highly detailed landscapes from black mesa game, dark, red, green, 4 k, unreal engine, detailed and intricate environment, digital paining, concept art"
    },
    {
        "display": "Bucky Barnes-inspired sneakers",
        "full": "basketball sneakers concept of bucky barnes, trending on artstation, smooth, sharp focus"
    },
    {
        "display": "Hyper-realistic serene pool.",
        "full": "serene pool in white citadel, hyper realistic, ambient lighting, concept art, intricate, hyper detailed, smooth, dynamic volumetric lighting, octane, raytrace, cinematic, high quality, high resolution, 4 k, cgsociety, rutkowski, gurney"
    },
    {
        "display": "Sci-fi horror portrait.",
        "full": "scifi portrait by Greg Rutkowski, a person infected with a kind of reddish silt that is sprouting from all over his body, violent and vicious appearance, scifi, space horror, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Vaporwave bedroom portrait",
        "full": "julia garner, posing, vaporwave, bedroom, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hisoka Morow portrait, anime.",
        "full": "portrait of hisoka morow hunter hunter, male, sharp jaw yellow eyes narrow sultry eyes red hair soft bunched hair swept back crimson medium length hair, anime, fantasy, intricate, elegant, highly detailed, digital painting, artstation sharp focus, madonna inspired art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "\"Elegant woman in brass\"",
        "full": "juliana wearing a risque outfit made from brass pipes, half body portrait, 3 d animation, black hair, freckles, pale skin, photo by greg rutkowski, high fashion, female beauty, intricate detail, elegance, sharp shapes, soft lighting, vibrant colors, masterpiece"
    },
    {
        "display": "Retrofuturistic fox astronaut.",
        "full": "An anthropomorphic fox as a retrofuturistic astronaut, helmet with led lights, underwater in the ocean at night, clear water, volumetric lighting, glowing lights, 4k, octane, digital painting, artstation, concept art, cinematic film, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha , wide angle view,"
    },
    {
        "display": "Ancient temple with tentacles",
        "full": "A picture of a mysterious ancient temple with geometric patterns and tentacles coming out of the entrance art by Noah Bradley, Darek Zabrocki, Pablo Carpio, Jordan Grimmer and Natasha Tan, ominous, cosmic horror, trending on artstation, Ultra detailed, hyper realistic, 4k"
    },
    {
        "display": "Game concept art",
        "full": "End of a Life, game concept art by Akihiko Yoshida, trending on artstation and cgsociety"
    },
    {
        "display": "Hindu woman in sari.",
        "full": "stunning, breathtaking, awe - inspiring award - winning concept art portrait painting by steve mccurry of a beautiful young hindu woman with short, wavy hair, wearing a colorful sari"
    },
    {
        "display": "Princess with skull mask.",
        "full": "close-up macro portrait of the face of a beautiful princess with animal skull mask, epic angle and pose ribcage, skeleton, symmetrical artwork, 3d with depth of field, blurred background, cybernetic jellyfish female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. y Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Messi basketball fantasy art",
        "full": "Messi dunking a basketball, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Crowd marching towards palace.",
        "full": "lined up crowd is marching toward the chinese palace, big red dragon flying above them, dark atmosphere, light above palace, digital art, trending on artstation"
    },
    {
        "display": "Seductive sorceress in blue.",
        "full": "Halle Berry as a good sorceress , passionate , seductive, sweaty,  intricate dressed in ornate blue robes and staff, insatiable, intricate,"
    },
    {
        "display": "Symmetrical figure with flowing skirt.",
        "full": "in the style of artgerm, arthur rackham, alphonse mucha, phoebe tonkin, symmetrical eyes, symmetrical face, flowing blue skirt, hair blowing, full body, intricate filagree, hidden hands, warm colors, cool offset colors"
    },
    {
        "display": "Jennifer Love Hewitt as Mob Boss",
        "full": "a painting of jennifer love as the mob - boss in the godfather, by arthur suydam trending on artstation, oil painting rebrandt"
    },
    {
        "display": "Blonde girl walking on water.",
        "full": "a pretty smiling blonde girl with heart - shaped sunglasses dressed in pink shiny clothes with a black jacket over is walking over water, sun set and skyscrappers in the background, black and white photograph, dramatic lighting, highly detailed, incredible quality, trending on artstation"
    },
    {
        "display": "Ultra-realistic lighthouse storm",
        "full": "photo of 8k ultra realistic lighthouse on island, heavy rain, ,lightning storm, boat lights in distance, night, light shining, heavy seas, full of colour, cinematic lighting, battered, trending on artstation, 4k, hyperrealistic, focused, extreme details,unreal engine 5, cinematic, masterpiece, art by studio ghibli"
    },
    {
        "display": "Goddess of Light Art",
        "full": "breathtaking detailed weird concept art painting of the goddess of light pink flowers, orthodox saint, with anxious, piercing eyes, ornate background, amalgamation of leaves and flowers, by Hsiao-Ron Cheng, James jean, extremely moody lighting, 8K"
    },
    {
        "display": "Bigfoot in forest portrait",
        "full": "bigfoot by charlie bowater and titian and artgerm, full - body portrait, intricate, face, forest, elegant, green mist, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Neon Warhorse Skeleton Portrait",
        "full": "anthropomorphic highly detailed group portrait of funny neon giant cute eyes warhorse skeleton, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Golden magical chariots artwork.",
        "full": "a golden majestic chariots, yellow magic theme, bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, blizzard warcraft artwork, hearthstone card game artwork, the chariots"
    },
    {
        "display": "Tiny space city landscape",
        "full": "detailed concept art of a tiny space city and wind turbines on a tiny desert planet with multiple little moons and maybe some cute satellites against a breathtaking sky, by syd mead, by noah bradley, by simon stalenhag"
    },
    {
        "display": "Anime-style \"Better Call Saul\" artwork",
        "full": "better call saul anime key visual art, anime, pixiv fanbox, shinkai makoto style"
    },
    {
        "display": "Surreal art deco portrait",
        "full": "dreamt in ` 9. 0 2 s ` for @ ediblehearts \ud83d\udc9c's `! dream 8 0 s art deco joked as aeon flux profile picture, rain like a dream, oil painting, cinematic, symmetrical, surreal, overgrown, dramatic, dreamlike, orchids, bold, cyberpunk, basquiat + zdzis\u0142aw beksinski + james jean + loish + artgerm + rutkowski + moebius + francis bacon + gustav klimt,"
    },
    {
        "display": "Cyberpunk Christina Hendricks",
        "full": "painting of christina hendricks in bladerunner wearing a cyberpunk costume, frontal view, dynamic pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "High-detail digital concept art.",
        "full": "highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and"
    },
    {
        "display": "Fantasy towers in oil.",
        "full": "Skinny onyx tower, skinny ivory tower, fantasy magic, oil painting, digital art, illustration, wide angle, fine details, cinematic, 4k, concept art"
    },
    {
        "display": "Elegant Norwegian female portrait",
        "full": "a gorgeous norwegian female photo, professionally retouched, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, sharp focus on eyes, 8 k, high definition, insanely detailed, intricate, elegant, art by mark litvokin and artgerm and mark hall"
    },
    {
        "display": "Orion hyperspace gate concept.",
        "full": "hyperspace gate of orion, syd mead, blade runner, concept art, hyper detail, high detail, hyper realism, high definition picture, cinematic"
    },
    {
        "display": "Supervillain Anna Kendrick flying",
        "full": "Jeremy Mann art, artgerm, Mandy Jurgens art, cinematics lighting, beautiful Anna Kendrick supervillain, green dress with a black hood, angry, symmetrical face, Symmetrical eyes, full body, flying in the air over city, night time, red mood in background"
    },
    {
        "display": "Rugged man with corgi.",
        "full": "medium close up portrait of a old, ruggedly handsome bearded man holding corgi dog, real hands, soft hair, muscular, half body, cloth, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Happy Roblox figures render",
        "full": "high quality 3d render of happy roblox figures, bright and fun colors, octane render, trending on artstation"
    },
    {
        "display": "Lithuanian man portrait.",
        "full": "portrait of a lithuanian man wearing a skull beanie and sleeveless hoodie, gold necklace, highly detailed, realistic, digital art, trending on artstation, sharp focus"
    },
    {
        "display": "Anime girl tennis monster",
        "full": "girl tennis ball monster with weapons and uniform, serious, finely detailed, made by wlop, studio ghibli, artgerm, full body portrait, illustration, grass, sunny, sky, anime, side view, perfect anime face, detailed face, zoomed out, smooth,"
    },
    {
        "display": "Ultra-realistic Jesus portrait",
        "full": "portrait painting of a jesus, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Ornate steampunk grandfather clock",
        "full": "ornate steampunk grandfather clock, high details, intricately detailed, by vincent di fate, inking, lineart, 3 color screen print, masterpiece, trending on artstation, sharp, details, hyper - detailed, hd, 4 k, 8 k"
    },
    {
        "display": "Intricate brutalist architecture illustration",
        "full": "neo classical, brutalist, soviet architecture, symmetrical!!, grey, intricate, ugly highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Fierce, sexy Medusa portrait.",
        "full": "fierce medusa, sexy, fantasy character portrait, ultra realistic, concept art, intricate details, highly detailed by greg rutkowski, gaston bussiere, craig mullins, simon bisley, arthur rackham"
    },
    {
        "display": "Vibrant soldier portrait.",
        "full": "prompt! dream symmetry!! stunning portrait of a soldier!! by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Symmetrical vibrant op-art poster.",
        "full": "symmetry!! vector poster op - art patterns, centred, vibrant, detailed, median photoshop filter vector behance, hd by artgerm, by rhads, makoto shinkai and lois van baarle"
    },
    {
        "display": "Elegant girl playing tennis",
        "full": "a beautiful young elegant girl playing tennis, with a close-up of the upper body in the style of stefan kostic, realistic, sharp focus, 8k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Hoplite warrior in city",
        "full": "a painting of a hoplite with a spear and shield, a city in the background, ultra detailed, high fantasy, 4 k, trending on artstation, cinematic, concept design"
    },
    {
        "display": "Cinematic poet illustration.",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork costume design: Henry Fonda as a 1950s tired disillusioned poet, barefoot, smoking a cigarette. volumetric lighting. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Beksinski, great attention to anatomy, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, realistic facial expression, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Vietnamese soldier portrait",
        "full": "handmade character portrait of a vietnamese soldier in 1 9 6 0 at the vietnam war in a thematic landscape in the style of artgerm and enki bilal and bastien lecouffe - deharme, wlop, line art, watercolor, dynamic pose anatomically correct, cinematic lighting, hyperdetailed, 8 k realistic, symmetrical, global illumination, frostbite 3 engine, cryengine, dof, trending on artstation, digital art, chanel"
    },
    {
        "display": "Fantasy vacuum motherboard render.",
        "full": "product photography framing. digital paint krita render of a small square fantasy vacuum - tube motherboard made and powered by crystalline circuitry. trending on artstation. artificer's lab bg. premium print by angus mckie and james gurney"
    },
    {
        "display": "Rococo angel painting",
        "full": "A epic and beautiful rococo painting of The angel of the beasts. Castlevania style. ultra-detailed. Anime, pixiv, UHD 8K CryEngine, octane render"
    },
    {
        "display": "Nostalgic flower shop entrance",
        "full": "a little flower shop's front gate, nostalgic, fresh digital illustrati on, dramatic lighting, pixiv"
    },
    {
        "display": "Sad young woman, vaporwave aesthetic",
        "full": "beautiful, young woman, extremely detailed gorgeous face, red hair, sad eyes tears, vaporwave, sexy, aesthetic, synthwave, photo-realistic face, digital, flowers, butterflies, birds, painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hiei, Artgerm style.",
        "full": "Hiei from Yu Yu Hakusho, art by Artgerm"
    },
    {
        "display": "Crystal-clad fantasy portrait",
        "full": "kate beckinsdale portrait, with a brilliant, impossible striking big shiny crystal headpiece, quartz, clothes entirely made out of crystal quartz, black hair, crystal background, symmetrical, dramatic studio lighting, rococo, baroque, hyperrealism, closeup, d & d, fantasy, intricate, elegant, highly detailed, asian, digital painting, artstation, octane render, 8 k, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Spartan bodybuilder portrait.",
        "full": "handsome portrait of a spartan guy bodybuilder posing, translucence, radiant light, lush surroundings, by gaston bussiere, bayard wu, greg rutkowski, giger, maxim verehin"
    },
    {
        "display": "Dwarf alchemist portrait.",
        "full": "portrait of a grinning dwarf alchemist with poison flasks, green googles,. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose, vibrant colours."
    },
    {
        "display": "Half-elf woman portrait.",
        "full": "portrait of a half elf woman with pink layered bob haircut, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and uang guangjian and gil elvgren and sachin teng and wlop, symmetry"
    },
    {
        "display": "Tree of Skulls Artwork",
        "full": "artwork called the tree of skulls. featuring a tree with a skull hanging from each branch. epic, cinematic, artwork, trending on artstation."
    },
    {
        "display": "Male necromancer casting dark magic.",
        "full": "Character concept art of Pretty guy male necromancer bringing dead to alive, casting dark magic spell. Castle room, lots of candles, barely lit warm violet red light, many transparent souls comes through the floor By greg rutkowski, tom bagshaw, beksinski"
    },
    {
        "display": "Elegant ghostly god illustration",
        "full": "god, ghostly, white hair, long hair, gorgeous, amazing, elegant, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Flemish still life: Big Mac",
        "full": "classic flemish master still life of mcdonald's big mac happy meal, rendered in octane, unbiased light, brilliant details, art by aaron covrett, 8k, intricate detail, trending on artstation, cgsociety, mariusz becker hdri,"
    },
    {
        "display": "Asymmetrical portrait of Sailor Jupiter",
        "full": "asymmetry!! portrait of sailor jupiter!! gorgeous face, hourglass body, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by ross tran artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Symmetrical scientific illustration",
        "full": "symmetry! scientific intelligence meet online, intricate, elegant, highly detailed, concept art, smooth, sharp focus, lineart, illustration, shadows, penned with thin colors on white, 8 k"
    },
    {
        "display": "Mystical scene in wine bottle.",
        "full": "a ( ( ( mystery place ) ) ) inside ( ( ( a wine glass bottle ) ) ), epic scene, by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Spirits in misty horizon",
        "full": "spirits over the horizon, modern art, concept art, digital art, 2. 5 d, mist, lumen reflections, ray traced, in a symbolic and meaningful style, insanely detailed and intricate, hypermaximalist, elegant, ornate, hyper realistic, super detailed, by akihito yoshida, by katsushika hokusai, by takashi murakami"
    },
    {
        "display": "PewDiePie as Hobbit",
        "full": "portrait of pewdiepie as a hobbit, happy hobbit eating, by alan lee, lord of the rings, smooth, oil painting, matte painting, concept art, trending on artstation, promotional artwork, film still, elegant, photorealistic facial features, intricate, detailed face, cinematic lighting"
    },
    {
        "display": "Goddess portrait, ornate fantasy.",
        "full": "portrait of a beautiful goddess, enigmatic beauty, dominant shades of black, gold silver, dark red, white, head in focus, fantasy art, ornamental aesthetics, intricate, elegant, highly detailed, hyperrealistic painting, artstation, concept art, painterly, sharp focus, illustration, art by karol bak"
    },
    {
        "display": "Qing Dynasty anime character",
        "full": "photography of A man from the Qing Dynasty\uff0clooks like zun long\uff0cwinter,anime style character, clean soft lighting, backlit beautiful face, clean brush stroke, 8k character concept art, by WLOP and artgerm, 3d, yoji shinkawa"
    },
    {
        "display": "Eddie Munson with sword.",
        "full": "eddie munson holding a sword, 4 k, art station concept, highly detailed"
    },
    {
        "display": "Hyperrealistic secret garden",
        "full": "beautiful Hyperrealistic Secret flower garden, water fountain, birds flying, highly detailed, digital painting, volumetric lighting, sunrise, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Intricate Monarch Butterfly Portrait",
        "full": "Monarch Butterflies, oil on canvas, intricate, portrait, 8k highly professionally detailed, HDR, CGsociety, illustration painting by Mandy Jurgens and Ma\u0142gorzata Kmiec and Dang My Linh and Lulu Chen and Alexis Franklin and Filip Hodas and Pascal Blanch\u00e9 and Bastien Lecouffe Deharme, detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, Cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8K, style by Wes Anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon st\u00e5lenhag, rendered by Beeple, by Makoto Shinkai, syd meade, 8k ultra hd, artstationHD, 3d render, hyper detailed, elegant, by craig mullins and marc simonetti, Ross Tran and WLOP, by Andrew Wyeth and Gerald Brom, John singer Sargent and James gurney"
    },
    {
        "display": "Ultra-realistic portrait of a joyful girl.",
        "full": "ultra realistic style illustration of a beautiful cute red haired joyful and playful 1 9 year old girl, full portrait, long hair, sci - fi, fantasy, intricate, elegant, digital painting, artstation, concept art, smooth, sharp focus, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Cybernetic hippie portrait.",
        "full": "a portrait of a beautiful cybernetic hippie, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Elegant woman playing violin",
        "full": "closeup portrait photo of a woman playing violin, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Colorful light waves and androids.",
        "full": "color light waves, color spectrum refraction in white space with white smoke,white androids, light through glass, cinematic lighting, intricate, elegant, super highly detailed, art station, concept art, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Fire-encircled housewife packing",
        "full": "a crazy housewife hurries up to pack daughter's things, surrounded with fire, digital art, trending on artstation"
    },
    {
        "display": "Detailed fantasy samurai portrait",
        "full": "samurai, ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Cosmic energy drinking man",
        "full": "A man drinking a cup of cosmic energy bright light, illustration, anime style, Artgerm, 4k, digital art, surreal, anime style, space dandy style, highly detailed, godsend, artstation, digital painting, concept art, smooth, sharp focus,"
    },
    {
        "display": "Bounty hunter portrait, sci-fi.",
        "full": "a detailed close up portrait painting of a bounty hunter in combat armour and visor. cinematic sci-fi. Flight suit, accurate anatomy. portrait symmetrical and science fiction theme with lightning, aurora. lighting. clouds and stars. Futurism by beksinski carl spitzweg moebius and tuomas korpi. baroque elements. baroque element. intricate artwork by caravaggio. Oil painting. Trending on artstation. 8k"
    },
    {
        "display": "Elven monk prince portrait",
        "full": "Half body portrait of a handsome young red haired elven monk prince with dragon eyes, red, green and gold ornate robe. In style of Yoji Shinkawa and Hyung-tae Kim, trending on ArtStation, dark fantasy, great composition, concept art, highly detailed."
    },
    {
        "display": "Dramatic Gollum Family Render",
        "full": "a dramatic highly detailed render of Gollum family , Middle-earth , by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, Beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, Artstation, Octane render, 8K, masterpiece, sharp focus, hyperrealistic"
    },
    {
        "display": "Retro-futuristic pharaoh illustration",
        "full": "symmetry!! solid cube of light, hard edges, product render retro - futuristic poster scifi, lasers and circuits, brown skin prince, egyptian pharaoh, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, dreamlike, art by artgerm"
    },
    {
        "display": "Bearded sorcerer casting spells.",
        "full": "a handsome bearded white male sorcerer in his study books every where, he is casting a spells coming from his hands. brown hair, epic composition, hands hidden, waist up, trending on artstation, concept art,"
    },
    {
        "display": "Rimuru Tempest in armor",
        "full": "rimuru tempest in holy armor, sky blue straight hair, long bangs, with amber eyes, gold eyes, high collar, choker, ultra detailed, concept art, award winning photography, digital painting, cinematic, wlop artstation, closeup, pixiv, evil, yoshitaka amano, andy warhol, ilya kuvshinov,"
    },
    {
        "display": "Symmetrical android woman portrait",
        "full": "beautiful symmetrical face portrait android woman time machine axonometric mechanical fantasy intricate elegant highly detailed in volumetric void of latent space lush flowers intricate jewellery, realm of the gods golden turquoise steampunk, axonometric high contrast cinematic light, mystical shadows, digital painting, sharp focus, octane render, photographic, concept art, artist leonardo davinci, unreal engine 8 k"
    },
    {
        "display": "Girl riding dragon, realistic portrait",
        "full": "little girl in pajamas riding a dragon, realistic portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Biden in vibrant attire.",
        "full": "Joe Biden wearing a virgin killer, sharp smile, dream fuel, optimistic colors, by artgerm"
    },
    {
        "display": "Unpleasant portrait of Grace Kelly",
        "full": "grace kelly with black bra portrait | | realistic shaded, unpleasant face, bad looking, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Elegant fantasy portrait of Tony Stark.",
        "full": "a portrait of tony stark, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "White dragon vs. T. rex",
        "full": "a white dragon fighting a t rex, fantasy, intricately detailed, 8 k render, ultra high resolution, octane render, trending on artstation"
    },
    {
        "display": "Intricate Camelot Fantasy Panorama",
        "full": "panorama of camelot, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Leviathan concept art.",
        "full": "A dungeons and dragons monster manual sketch of a leviathan, concept art, matte painting, 8k, highly detailed, artstation"
    },
    {
        "display": "Kraken attacks medieval flotilla.",
        "full": "A medieval ship flotilla is getting destroyed by a giant kraken monster at sea. In style of Yoji Shinkawa and Hyung-tae Kim, trending on ArtStation, Greg Rutkowski, dark fantasy, great composition, concept art, highly detailed, scenery."
    },
    {
        "display": "Agent 47 playing piano.",
        "full": "agent 4 7 from hitman wearing headphones while playing a piano, digital art, dark fantasy, concept art"
    },
    {
        "display": "Underground river of lost souls.",
        "full": "an underground river full of lost souls, dark vibrant shades of blue, depressing and hopeless vibe, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Fantasy queen portrait.",
        "full": "portrait of a queen with green eyes and freckles, dressed in a navy blue gown with gold embroidered details, dark brown hair, detailed face, fantasy, cinematic lighting, digital art painting, fine details by realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Spaceship orbiting Mars",
        "full": "realistic illustration of a spaceship in orbit of mars, trending on artstation"
    },
    {
        "display": "Hipster Jesus at caf\u00e9",
        "full": "a detailed illuminated manuscript of a ridiculously good looking hipster jesus that looks like a jewish gigachad, long curly hair, elegant ancient greek dress, very detailed, coast as the background, drinking espresso at a cafe in jerusalem, wearing a jean jacket and a beenie, waxed beard, very detailed, beautiful, intricate, art by greg rutkowski and rembrandt, octane render"
    },
    {
        "display": "Art Nouveau Rosie Portrait",
        "full": "a portrait of Rosie the riveter by Alphonse Mucha, art nouveau card, concept art, wlop, trending on artstation, 8k"
    },
    {
        "display": "Cybernetic Oracle Portrait",
        "full": "a portrait of a beautiful cybernetic oracle, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Muddy river to sea scene",
        "full": "muddy river leads to sea by hiroshi yoshida, marc simonetti, roger deakins, turner, rothko, hyperrealism, cinematic, grassland, dramatic storm sunset, laser lights under sea, matte painting, fluid simulation, tonalism, volumetric light, trending on artstation, cgsociety, high detail concept art, top view, rendered in octane"
    },
    {
        "display": "Kim Possible in bikini.",
        "full": "kim possible in a black bikini, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, 8 k"
    },
    {
        "display": "Miley Cyrus as Chun-Li",
        "full": "miley cyrus as chun li from street fighter, 4 k, ultra realistic, detailed focused art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Girl in flower shop.",
        "full": "Through the window a girl working in a flower shop in the afternoon, it is raining outside the window,blue and grey theme,JK uniform ,Hairdryer,blue theme,S line,3d rending by krenz cushart and mucha and range murata and greg rutkowski"
    },
    {
        "display": "Cute anthropomorphic RPG dice portrait.",
        "full": "anthropomorphic highly detailed wide - angle group portrait of cute rpg dice, intricate, elegant, highly detailed dice, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Hyperrealistic giant milk bottle.",
        "full": "full body pose, hyperrealistic photograph of a bottle of milk about 1 0 0 meters tall manipulating thunder, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Cowboy on Fire Poster",
        "full": "\u201c a pulp style movie poster, illustration of a texan cowboy on fire, movie premiere poster, close up, portrait, dramatic, 1 9 6 0 s, highly detailed, brandishing a gun, 4 k detail, trending on artstation \u201d"
    },
    {
        "display": "Futuristic sci-fi dice closeup.",
        "full": "closeup, of one futuristic sci-fi Twenty sided dice, in the background players at a table that are in still high tech suites, bokeh, sharp focus, intricate concept art, highly detailed, 8k, cinematic, sharp focus"
    },
    {
        "display": "Epic Minion Grandpa Portrait",
        "full": "an epic painting minion old granpa with white head, pencil drawing, perfect composition, golden ratio, beautiful detailed, photorealistic, digital painting, concept art, smooth, sharp focus, illustration, artstation trending, octane render, unreal engine"
    },
    {
        "display": "Fit man with cat portrait.",
        "full": "full body portrait of a single beautiful young fit man, modern haircut, open shirt, large pants, holding a detailed photorealistic cat on a arm. by greg rutkowski and alphonse mucha, d & d character, in front of a modern room background, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp focus illustration, artstation hq"
    },
    {
        "display": "Cute alien woman in ornate armor.",
        "full": "photo of a cute alien woman wearing ornate plastic armor in the style of roger dean and alberto vargas and stefan kostic, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by greg rutkowski and artgerm, extreme blur coral reef background"
    },
    {
        "display": "Mystic undead succubus portrait",
        "full": "a wlop 3 d render of very very very very highly detailed beautiful mystic portrait of a phantom undead succubus with whirling galaxy around, bloody fangs, deranged eyes, tattoos by anton pieck, intricate, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art,"
    },
    {
        "display": "Rugged forest god portrait",
        "full": "portrait of the god of the forest, 30 years old, rugged, male, gorgeous, detailed face, amazing, thighs!!!!!!, muscular, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy orange Viking portrait",
        "full": "fantasy orange viking, portrait, d & d, science fiction, concept art, matte, sharp focus, illustration, concept art,"
    },
    {
        "display": "Charon in Baroque Style",
        "full": "Charon the ferryman of Hades, by Rolf Armstrong and Evelyn De Morgan, dramatic lighting, high contrast colors, baroque, empyrean, panoramic view, as trending on Artstation, highly detailed, doom engine,"
    },
    {
        "display": "Cavill as Chris Redfield.",
        "full": "portrait of henry cavill as chris redfield, resident evil, pistol, upper body, henry cavill!!!, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant digital painting of a funny drinker.",
        "full": "painting of a funny drinker, anatomy, clear - lines, happy - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Muscular female gnome engineer.",
        "full": "muscular female gnome engineer artificer, metal gauntlet, short black hair, naval background, full body portrait, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, centred, rule of thirds, concept art, matte, sharp focus, illustration, cover by artgerm, art by greg rutkowski"
    },
    {
        "display": "Futuristic opera hall fortress.",
        "full": "amazing concept painting, by Jessica Rossier and HR giger and Beksinski, A gleaming white opera hall fortress overlooks a fertile valley, brutalist deak ferrand Jean-pierre Ugarte bases,   an art nouveau Rivendell Himeji fortress, terraces, hallucination, garden of eden"
    },
    {
        "display": "White-haired woman in desert.",
        "full": "a woman with long white hair stands in the desert, dramatic lighting, illustration by greg rutkowski, yoji shinkawa, 4 k, digital art, concept art, trending on artstation"
    },
    {
        "display": "5 Cent as Apollo Creed",
        "full": "5 0 cent as apollo creed in rocky 4, digital painting, extremely detailed, 4 k, intricate, brush strokes, mark arian, artgerm, bastien lecouffe - deharme"
    },
    {
        "display": "Modern Roman Warlock Poster",
        "full": "modern movie poster of a young ancient roman warlock with magic lovecraftian tentacles in the background, dungeons and dragons artwork, dramatic lighting, digital painting, masterpiece, by leonardo da vinci, raphael, artgerm, greg rutkowski, vibrant colors"
    },
    {
        "display": "Portrait of Tracer as Hathaway.",
        "full": "a highly detailed portrait of tracer from overwatch as anne hathaway, digital art, pretty face, muscular, very beautiful face, very detailed eyes, 8 k resolution, digital painting, by james gurney wlop, greg rutkowski, full body"
    },
    {
        "display": "Black ink smoke Mew portrait",
        "full": "black crimson ink smoke portrait of mew pokemon, artgerm, wlop, artstation"
    },
    {
        "display": "Epic battlefield portrait.",
        "full": "a epic portait of a man wearing a dark formal overcoat with a battlefield in the background, portait photo, hyperrealistic, concept art, digital art, high quality, highly detailed, fantasy style, dramatic lighting, 8k hdr, oil painting, epic image, path tracing, badass pose, complementary colors, realistic face, defined face, anatomically correct, five fingers"
    },
    {
        "display": "Elegant female figure, white hair",
        "full": "a gorgeous female with long white hair in the style of stefan kostic, realistic, full body shot, wide angle, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, floating embers"
    },
    {
        "display": "Hyper-realistic teenage boy",
        "full": "gorgeous!!! hyper - realistic teenager boy with purple hair, purple eyes with red eye markets, wearing combat japanese clothes, holding a fan | drawn by wlop, drawn by jeehyung lee, drawn by artgerm | intricate, highly detailed, digital painting, character design, concept art, illustration, artstation"
    },
    {
        "display": "Alien cube in forest",
        "full": "a huge indeterminate color alien cube with a strange texture from nanotechnology, forgotten and lost in the forest, detailed digital art by greg rutkowski."
    },
    {
        "display": "Stylish woman in black dress",
        "full": "a ultradetailed beautiful panting of a stylish woman in a black dress sitting, by conrad roset, greg rutkowski and makoto shinkai trending on artstation"
    },
    {
        "display": "Anime girl on stair.",
        "full": "girl sitting on a stone stair under a vine rack, illustration concept art anime key visual trending pixiv fanbox by wlop and greg rutkowski and makoto shinkai and studio ghibli"
    },
    {
        "display": "Anime girl riding bear",
        "full": "anime scene of a girl riding a grizzly bear in the woods, full shot, atmospheric lighting, detailed faces, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Highly detailed dragon lady.",
        "full": "dragon lady, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Cyberpunk girl portrait",
        "full": "highly detailed portrait of a young cyberpunk girl with a wavy vibrant red hair, VR glasses, neon cybernetic implants, cinematic lighting, dramatic atmosphere, by Dustin Nguyen, Akihiko Yoshida, Greg Tocchini, Greg Rutkowski, Cliff Chiang, 4k resolution, nier:automata inspired, bravely default inspired, cyberpunk background"
    },
    {
        "display": "Golden statue of Apollo",
        "full": "ultra realistic illustration, a golden statue of a herculean glenn howerton as the god apollo, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Jack the Ripper book cover",
        "full": "ismail inceoglu epic painting of a jack the ripper book cover, line art!!, ink, art concept for a book cover!!, red dead redemption, vibrant colours, oil on canvas painting, thik brushstrokes, highly detailed, centered, hyperrealism, romantic, concept art, smooth, sharp focus trending on artstation, by greg manchess and by craig mullins and by kilian eng and by jake parker"
    },
    {
        "display": "Homer Simpson anime portrait",
        "full": "portrait of homer simpson, cloudy sky background lush landscape illustration concept art anime key visual trending pixiv fanbox by wlop and greg rutkowski and makoto shinkai and studio ghibli"
    },
    {
        "display": "Celestial red cat knight.",
        "full": "bright celestial red cat knight portrait by alena aenami, in bright luminous armor, nebula background, cosmos, epic, unreal engine, 8 k, trending on artstation, oversaturated"
    },
    {
        "display": "Black rabbit monk portrait",
        "full": "Anthropomorphized black rabbit monk, portrait, Path of Exile, Diablo, Warhammer, digital painting, highly detailed, concept art, Nekro, Peter Mohrbacher, Alphonse Mucha, Brian Froud, Yoshitaka Amano, Kim Keever, Victo Ngai, James Jean"
    },
    {
        "display": "Gigachad Luigi Bodybuilder Portrait",
        "full": "gigachad luigi brother bodybuilder in final fight mountain by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Steampunk sports car in forest",
        "full": "magnificent mechanical steampunk vehicle shaped like a sports car with (((glowing))) lights abandoned on a road in lush forest with vines hanging from tree branches, fog, desaturated, beautiful, creepy ambiance, 4k, sharp focus, highly detailed, artgerm, giger"
    },
    {
        "display": "Young woman in Sith robes",
        "full": "portrait of an woman by Greg Rutkowski, she is about 20 years old, pretty, long brown wavy hair, blackout tattoos on her face, scar near her mouth that makes her look like she's smiling all the time, wearing black sith robes, Star Wars Expanded Universe, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ"
    },
    {
        "display": "Chrome robot in data center.",
        "full": "detailed chrome robot at the liminal space of data center, pressed button on server rack. highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Organic goddess with flowers",
        "full": "organic goddess with gorgeous flowers in her hair, holographic white plastic and fin, fantasy, intricate, elegant, highly detailed, realistic, photorealistic, digital painting, artstation, illustration, smooth, clear focus, works by scott davidson, albert auble, krentz kushart, artem demura, alphonse mucha, trending on artstation, digital art, 4 k resolution, detail, high quality, clear focus, headquarters design, clear, crazy detail, concept art"
    },
    {
        "display": "Asian-inspired machine portrait",
        "full": "portrait of a machine from horizon zero dawn, machine face, decorated with chinese opera motifs, asian, asian inspired, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Fantasy model of Slenderman.",
        "full": "slenderman covered in white fantasy body paint as a curvey lady with great hips and a beautiful body, showing skin, a detailed, realistic painting, photo of a full body act of a young, hot, attractive concept art woman, girl posing, webtoon page 2, photorealistic main character teen model, beautiful anime photorealistic full body detailed photograph, wearing fashion, insanely detailed and intricate, stunning, symetric, intricate accurate details, clear clean, artstation trending full body concept art anime, beauty model head, cyborg fashion, detailed girl face, nice dressed wearing trending clothes clean model, smaller beautiful eyes, billie eilish face, fairuza balk, comic book closing city night background"
    },
    {
        "display": "Ogun with tribal armor",
        "full": "Ogun with large iron spears, he has tribal face markings and war paint, bronze-brown skin with african features and strong jaw line prominent brow and menacing look, wearing tribal armor, medium shot digital illustration trending on artstation by artgerm, face by wlop"
    },
    {
        "display": "Cat-themed restaurant artwork",
        "full": "cat restaurant, in da vinci style, trending on artstation"
    },
    {
        "display": "Closeup of Apollo, vibrant.",
        "full": "a beautiful closeup of a dark - skinned greek god apollo with very long curly hair and thin eyebrows, digital art by edward hopper, vibrant color scheme, highly detailed, in the style of romanticism, fine art, high detail, great lighting, 8 k resolution, masterpiece, concept art, illustration, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, hdr, trending on artstation, 4 k, 8 k, hd"
    },
    {
        "display": "Dodecahedron-headed battle master",
        "full": "anthropomorphic dodecahedron head in ugly shroudspine keanu reeves battle master, intricate, elegant, highly detailed face, wide angle, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Steampunk underwater pirate portrait.",
        "full": "underwater steampunk pirate portrait of emma stone, pixar style, by tristan eaton stanley artgerm and tom bagshaw."
    },
    {
        "display": "Cyberpunk lady portrait",
        "full": "kyoto animation, cool lady wearing cyberpunk intricate warcore, beautiful, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Elegant portrait of Lexi Rivera.",
        "full": "beautiful elegant full body portrait of lexi rivera with color streaks in hair and wearing a sparkling cherry color one piece swimsuit and iridescent white silk cape, friendly seductive smile, felicia day, wlop, artgerm, artstation, backlit, marble background"
    },
    {
        "display": "MF Doom making pancakes, Adventure Time style",
        "full": "mf doom making pancakes in style of adventure time, animation pixar style, by pendleton ward, magali villeneuve, artgerm, rob rey and kentaro miura style, golden ratio, trending on art station"
    },
    {
        "display": "Artistic portrait of Messi",
        "full": "lionel messi portrait, made by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, artstation, cgsociety"
    },
    {
        "display": "Gunslinger chase in desert.",
        "full": "the man in black fled aross the desert, and the gunslinger followed. hyperrealistic, photorealistic, concept art, fantasy art, wild west, dungeons and dragons, mythical, mystical, magic, trending on artstation, digital illustration, cinematic lighting, 4 k, ultra hd, beautiful colors, highly detailed, intricate, octane render, unreal engine 5"
    },
    {
        "display": "Isometric grocery store illustration",
        "full": "grocery store, isometric game, smooth 3 d illustration, cinematic matte painting, soft render, servando lupini, handpaint texture, blender, 3 dcoat, concept art by noah bradley, darek zabrocki, tyler edlin, jordan grimmer, neil blevins, james paick, natasha tan, highly detailed, ultra detailed, ultra realistic, trending on artstation"
    },
    {
        "display": "Realistic bikini portrait.",
        "full": "sexy painting of selena gomez, bikini, ultra realistic, sharp details, subsurface scattering, intricate details, warm lighting, beautiful features, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Leviathan in stormy sea",
        "full": "A leviathan rising out of a stormy sea, concept art, matte painting, 8k, highly detailed, artstation, Greg Rutkowski"
    },
    {
        "display": "Sinister white-haired princess.",
        "full": "a beautiful white haired princess, adorned with precious stone jewelry, intricate concept art, ominous, sinister dark fantasy, misty, dramatic lighting, dark background, fairy lights, octane render, 8 k, ruan jia and tom bagshaw and alphonse mucha and marc simonetti"
    },
    {
        "display": "Billie Eilish as Loki.",
        "full": "Billie Eilish as Female Loki, very detailed, digital art, trending on artstation, concept art, smooth, illustration, art by artgerm and greg rutkowski and alphonse mucha and J. C. Leyendecker and Edmund Blair Leighton and Katsuhiro Otomo and Geof Darrow and Phil hale and Ashley wood"
    },
    {
        "display": "Queen of Dusk with Lantern",
        "full": "the queen of dusk and her lantern by the window,by Annie Swynnerton and Diego Rivera and Elihu Vedder, symbolist, dramatic lighting, elaborate geometric ornament, Art Brut,  soft blues and greens,smooth, sharp focus, extremely detailed, Adolf W\u00ef\u00bf\u00bdlfli"
    },
    {
        "display": "Glowing blue cat illustration",
        "full": "illustration of glowing adorable blue cat in grassy field, tree, detailed concept art, artstation, warrior cats, shading,"
    },
    {
        "display": "Cyborg scientist creating flower machine.",
        "full": "hyperrealistic photography of a female scientist cyborg constructing a maroon-blue flower machine in the style of Jin Kagetsu, James Jean and wlop, highly detailed, masterpiece, award-winning, sharp focus, intricate concept art, digital painting, ambient lighting, 4k, artstation"
    },
    {
        "display": "Anthropomorphic triangle-headed monster.",
        "full": "anthropomorphic triangle head in opal edgy darkiron terror bird, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe 8 k"
    },
    {
        "display": "Pepe the Frog WW1 soldier",
        "full": "Pepe the Frog in WW1 military uniform, Schutztruppe, German Empire, WW1, Tooth Wu Artgerm Greg Rutkowski Alphonse Mucha Beeple artstation deviantart, 8k, fanart, extreme aesthetic"
    },
    {
        "display": "Elegant portrait of Lexi Rivera.",
        "full": "beautiful elegant full body portrait of lexi rivera with color streaks in hair and wearing a sparkling cherry color one piece swimsuit and iridescent white silk cape, felicia day, wlop, artgerm, artstation, backlit, marble background"
    },
    {
        "display": "Sci-fi beverage weapon concept",
        "full": "device to drink hot beverages from, concept art, sci - fi weapon, artstation"
    },
    {
        "display": "Neoclassical tower in forest.",
        "full": "a neoclassical tower with dome on a magical mystical forest. by artstation trending, by joseph mallord william turner, luis royo, konstantin razumov, cinematic lighting, fractal flame, highly detailed"
    },
    {
        "display": "Renaissance ranger portrait",
        "full": "renaissance upper body portrait of a gruff ranger with a spear, Indigenous Brazilian Marajoara Tabajara Tupinamba Tremembe Potiguara kurikuro, lean and toned, handsome face, hairy chest, D&D, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by da Vinci, Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Young couple in space.",
        "full": "a young couple hugging each other in space, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Koala selling NFT",
        "full": "movie still macro close photo of koala selling nft, by weta disney pixar greg rutkowski wlop ilya kuvshinov rossdraws artgerm octane render iridescent, bright morning, liosh, mucha"
    },
    {
        "display": "Bob Ross painting Iron Man",
        "full": "a closeup photorealistic photograph of bob ross painting a canvas of iron man. mountains and trees. film still. brightly lit scene. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Armored girl with horse.",
        "full": "portrait full body girl metal armor and metal horse poses by gaston bussiere, anna nikonova aka newmilky, greg rutkowski, yoji shinkawa, yoshitaka amano, tsutomu nihei, donato giancola, geoffroy thoorens, concept art, trending on artstation, gustav klimt, cinematic composition, 8 k"
    },
    {
        "display": "Fantasy portrait of L.",
        "full": "l \u00b7 lawliet, hunchback, death note \uff0c d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve and wlop"
    },
    {
        "display": "Fantasy landscape artwork",
        "full": "shoujo skies rule of thirds golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai studio ghibli genshin impact james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "Cubist Jaws Movie Poster",
        "full": "Beautiful Horror Movie Poster made for the film  Jaws (1975) starring Roy Scheider and Richard Dreyfus and a shark, impasto cubist! oil painting by Pablo Picasso and Robert Delaunay, trending on artstation dramatic lighting Cubism 8k"
    },
    {
        "display": "Cat Titanic selfie illustration",
        "full": "a selfie of a cat from the Titanic, fantasy, intricate, young and cute girl, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Cyberpunk hacker portrait",
        "full": "! dream baroque oil painting character headshot portrait concept art, anime key visual of young female cyberpunk hacker, studio light directed gaze finely detailed perfect face, long flowing multicolored hair heterochromia eyes, bodytight skinsuit uniform, ingsoc gapmoe kuudere grimdark dictator, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Elegant Sofia Vergara portrait",
        "full": "clear portrait of a lushious sofia vergara holding a cucumber, super super wide hips!!, cottagecore!!, background hyper detailed, character concept, full body, dynamic pose, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Handsome man reading, starry background.",
        "full": "very detailed portrait of a very handsome american man in his late twenties, oval shaped face, very short beard, ( ( deep hazel eyes ) ), strong round!!! rosey nose, nice color scheme, by wlop and tyler oulton, detailed eyes, reading a book. starry background, trending, on artstation."
    },
    {
        "display": "\"Spiderman as Wolverine, hyper-detailed\"",
        "full": "Spiderman as Wolverine,muscle extremely detailed, face, trending on artstation, pixiv, cgsociety, hyperdetailed Unreal Engine, optimization 4k 8k ultra HD, WLOP"
    },
    {
        "display": "Ariana Grande as Samus.",
        "full": "Ariana Grande cosplaying as Samus Aran, golden hour, by greg Rutkowksi, by artgerm"
    },
    {
        "display": "Elegant black wolf illustration",
        "full": "a black wolf sitting looking forward, art nouveau, D&D, fantasy, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and WLOP"
    },
    {
        "display": "Futuristic luxury masonry hall",
        "full": "futuristic masonry hall, crisp, artstation, luxury, beautiful, dim painterly lighting aquatic, 3 d concept art, moon light"
    },
    {
        "display": "Happy Mask Salesman Portrait",
        "full": "a portrait of the happy mask salesman, grinning, red hair, handsome, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and wlop, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Epic portrait of Patroclus",
        "full": "very very very epic portrait of the greek man patroclus, dreamy and ethereal, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, by gil elvgren"
    },
    {
        "display": "Beautiful female goddess portrait",
        "full": "closeup portrait of a beautiful alluring female goddess, detailed, centered, digital painting, artstation, concept art, donato giancola, Dante Gabriel Rossetti, alphonse mucha, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Oscar Isaac as Star Trek captain",
        "full": "oscar isaac as a star trek captain, a still from star trek painted by artgerm and greg rutkowski and alphonse mucha. clear highly detailed face, beautiful sci fi art"
    },
    {
        "display": "Hades D&D character art.",
        "full": "lord hades, dnd character art, ross tran, charlie bowater, fernanda suarez, cgsociety, trending artstation, highly detailed, award winning, concept art, beautiful painting."
    },
    {
        "display": "Symmetrical presidents portrait.",
        "full": "symmetry!! all the presidents of usa, very detailed, perfect lighting, perfect composition, 4 k, artgerm, derek zabrocki, greg rutkowski"
    },
    {
        "display": "Elegant female sorceress portrait",
        "full": "Portrait of female sorceress, dark fantasy, medium shot, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Edgy triangle brain demon.",
        "full": "3 / 4 view portrait of anthropomorphic triangle brain in edgy darkshadowiron pig demon, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, dwayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Scarred Doomguy portrait.",
        "full": "scarred doomguy played by scarlett johansson, face portrait, hd shot, digital portrait, elegant, beautiful, fantasy art, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Futuristic samurai close-up.",
        "full": "Japanese samurai, illustrated by Greg Rutkowski and Gaston Bussiere, 35mm lens, beautiful macro close-up imagery, vibrantly lush neon lighting, beautiful volumetric-lighting-style atmosphere, a futuristic atmosphere, intricate, detailed, photorealistic imagery, trending on artstation, 4k, 8k"
    },
    {
        "display": "David Koechner as Zangief",
        "full": "david koechner as zangiff, flexing, no shirt, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Evil skinwalker demon illustration",
        "full": "evil native american skinwalker, horror demon, heroic lighting, dark fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Cyborg Shiba Inu portrait",
        "full": "tesla power armor realistic cyborg anthropomorphic shiba inu scifi kirlian photography electric field glowing, cyberpunk, portrait art by donato giancola and greg rutkowski, realistic face, glowing in tesla electricity visible magnetic field, digital art, trending on artstation, symmetry"
    },
    {
        "display": "Stylized witch in red.",
        "full": "stylized witch with red dress, the night is dark and full of terrors, octane render digital painting trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Supernova in water bottle.",
        "full": "a supernova inside a water bottle on a wooden table, hyperrealistic, concept art, octane render, unreal engine 5, trending on artstation, high quality, highly detailed, 8 k hdr, soft lighting, path traced, a beach at sunset in the background, bloom, high coherence, symmetrical, high contrast, digital art, serene landscape, cinematic"
    },
    {
        "display": "Female archmage portrait",
        "full": "emma watson as female archmage, academy headmaster, long red hair, black and red ornate!!! dress, d & d, castle hallway background highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Colorful Vinicunca Hills Painting",
        "full": "vinicunca hills, beautiful atmosphere, colorful, pastel colors, neoclassic, baroque painting, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic, volumetric cinematic light, chiaroscuro, hieronymus bosch, alex grey, raphael, caravaggio, beksinski, giger, rembrandt"
    },
    {
        "display": "Arsen Lupin as Paladin",
        "full": "side profile centered painted portrait, Arsen Lupin as a Paladin, D&D, Gloomhaven, matte painting concept art, art nouveau, beautifully backlit, swirly vibrant color lines, fantastically gaudy, aesthetic octane render, 8K HD Resolution, by ilya kuvshinov and Cushart Krentz and Gilleard James"
    },
    {
        "display": "Johnny Depp and Amber Heard, Fleetwood Mac Rumours style.",
        "full": "johnny depp and amber heard on the style and color pallete of fleetwood mac's album rumours, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, cinematic"
    },
    {
        "display": "Ice Queen Portrait",
        "full": "a vivid portrait of an ice queen, by irakli nadar and rossdraws and bluesssatan and mandy jurgens, blue lips, blue lipstick, hdr, high dynamic range, trending on artstation, unreal engine, 4 k hd wallpaper, studio lighting, rim light, cold, frost, ice, glowing eletric blue eyes, frosted eyebrows, dramatic lighting, pastel anaglyph, seductive, sensual, attractive, beautiful, alluring"
    },
    {
        "display": "Elegant young woman portrait.",
        "full": "an attractive young female wearing an blue ornate metallic helmet, adriana lima, olive skin, long dark hair, beautiful bone structure, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant machine portrait",
        "full": "portrait of a machine from horizon zero dawn, machine face, upper body, decorated with opera motifs, asian, traditional chinese art, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Elon Musk on bike",
        "full": "( ( ( ( ( hyperrealist distant portrait of elon musk riding a bike with stabilisers ) ) ) ) ) by chris rallis, fantasy, photorealistic, octane render, unreal engine, dynamic lighting, bet face, handsome man, beautiful, wlop, cute, perfect factions, perfect man, trending on artstation, poster, volumetric lighting, very detailed faces, 4 k, award winning"
    },
    {
        "display": "Gods battling in dark valley",
        "full": "god of light fighting against god of dark in a dark valley, nighttime, extremely detailed digital painting, in the style of fenghua zhong and ruan jia and jeremy lipking and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Dale Cooper on bridge",
        "full": "dale cooper standing on a thin bridge, twin peaks poster art, from scene from twin peaks, by michael whelan, artgerm, retro, nostalgic, old fashioned, 1 9 8 0 s teen horror novel cover, book"
    },
    {
        "display": "Hybrid crow-crocodile creature.",
        "full": "painting of hybrid between crow!! & crocodile, intercrossed animal, mixture animal, by tiffany bozic, by zdzislaw beksinski, organic, logical anatomy, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting,"
    },
    {
        "display": "Truant high school girl illustration.",
        "full": "richly detailed color illustration of a young truant female loner prep highschool student surrounded by beautiful cursive writing, large format image. illustrated by artgerm and mina petrovic and timothy kong and marina federovna. 3 d shadowing."
    },
    {
        "display": "Hero concept art: Rope Man",
        "full": "a hero named rope man, his suit is black and blue and he has a bat like wing suit under it, mystic, concept art, artstation, greg rutkowski, reference sheet"
    },
    {
        "display": "Tracer in Tetouan streets",
        "full": "tracer from overwatch travels in tetouan streets, blue and white houses, mosque, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Baroque retrofuturistic cityscape.",
        "full": "a baroque neoclassicist painting of a colorful retrofuturistic blacklight uv silhouette of a city being glowing fog in the background. renaissance landscape painting. highly detailed science fiction painting by norman rockwell, frank frazetta, and syd mead. rich colors, octane render, gloomy atmosphere. trending on artstation, masterpiece, directed by ridley scott"
    },
    {
        "display": "Neon academic female pinup",
        "full": "neon, academic female pose from artstation, minimalistic, fibonacci, sweat drops, insane, pinup, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic woman with gun.",
        "full": "a woman in a futuristic suit holding a gun, concept art by senior character artist, featured on polycount, cobra, concept art, official art, ambient occlusion"
    },
    {
        "display": "Japanese Suburban Street Art",
        "full": "The Street of Japanese Suburbs, game concept art by Akihiko Yoshida, trending on artstation and cgsociety"
    },
    {
        "display": "Surreal nightclub portrait.",
        "full": "mid shot portrait of a woman in nightclub, in the style of David cronenberg ,scary, weird, surprising, worms on face, realistic, sharp focus, 8k high definition, medium format film photography, photo realistic, insanely detailed, intricate, elegant, art by les edwards and David kostic and stanley lau and artgerm"
    },
    {
        "display": "Cyborg skull illustration",
        "full": "Cyborg skull, sci-fi, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elven bride character design",
        "full": "digital art, centered full body elven bride, vivid flower crown ,intricate, veins, by James Jean and by artgerm, by ross tran , ultradetailed, charachter design, concept art, trending on artstation,"
    },
    {
        "display": "Mecha Totem Shaman Portrait",
        "full": "portrait of anthropomorphic mecha - totem shaman ghostspeaker, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Rimuru Tempest digital art",
        "full": "rimuru tempest, tensei shitara slime datta ken, highly detailed, professional digital painting, concept art, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k, black hoodie, cinematic, art by andy warhol, artgerm, yoshitaka amano, wataru kajika, junji ito"
    },
    {
        "display": "Cats dancing around pottery",
        "full": "a high detail paint of Two cats dancing around Jomon pottery, in simple background, concept art, mad painting"
    },
    {
        "display": "Elegant sailor portrait",
        "full": "epic portrait an woman wearing sailor uniform, digital painting, artstation, concept art, soft light, hdri, smooth, sharp focus, illustration, fantasy, intricate, elegant, highly detailed, D&D, matte painting, in the style of Greg Rutkowski and Alphonse Mucha and artemisia, 8k, highly detailed, jurgens, rutkowski, bouguereau, pastoral, rustic, georgic, detailed concept art, illustration, colorful pastel, painting, detail, ultra detailed, digital art, 4K,"
    },
    {
        "display": "Witch casting fire magic.",
        "full": "an experienced witch casting fire magic, by hong soonsang, concept art, digital art, trending on artstation, pixiv, illustration"
    },
    {
        "display": "Whimsical robotic apothecary.",
        "full": "a lively and whimsical apothecary where chrome robots shop grows from the stalk of a giant mushroom, cgsociety, siggraph, dystopian scifi, concept art, set design, oleg oprisco, conrad roset, anka zhuravleva, gediminas pranckevicius"
    },
    {
        "display": "Cyberpunk astronaut with masks.",
        "full": "full shot of a beautiful venus minnie mouse monster astronaut defined facial features, intricate abstract. cyberpunk, symmetrical facial features. biomechanics. beautiful intricately detailed japanese crow kitsune mask and clasical japanese kimono. betta fish, jellyfish phoenix, bio luminescent, plasma, by ruan jia and artgerm and range murata and wlop and ross tran and william - adolphe bouguereau and beeple. key art. fantasy illustration. award winning, artstation, intricate details, realistic, hyperdetailed, 8 k resolution. soft light, soft illumination"
    },
    {
        "display": "Epic dragon fantasy portrait",
        "full": "a portrait of a grey old ,man? ,werewolf?, dragon! dragon! dragon!, dragon!, dragon!, dragon!, dragon! dragon!, dragon!, dragon!, dragon!, dragon!, dragon!, dragon head!, dragon head!, dragon horns!, epic fantasy art by Greg Rutkowski"
    },
    {
        "display": "VFX portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin by eiichiro oda, makoto shinkai, alphonse mucha, sharp focus, art by artgerm and greg rutkowski!, backlit, harsh overhead sunlight, blue eyes, stanley kybric, yusuke murata, hiroya oku, pixiv, fanbox,"
    },
    {
        "display": "Fairy princess in garden",
        "full": "highly detailed illustration beautiful white - haired fairy princess in a flower garden, wonderful blue eyes, deep focus, d & d, fantasy, complex, elegant, highly detailed, digital painting, artstation, concept art, matte, clear focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy priest portrait",
        "full": "brown-skinned male priest, fantasy, powerful, elegant, intricate, beautiful lighting, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Cyborg woman portrait",
        "full": "cyberpunk,sci-fi, fantasy,Kodak Portra 400, 8K, soft light, volumetric lighting, highly detailed, britt marling style 3/4 ,portrait photo of a beautiful cyborg robot woman in a chemical laboratory + face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration,art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Witch hut interior scene.",
        "full": "a witch hut inside, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Baroque Hatsune Miku portrait",
        "full": "baroque oil painting full body hatsune miku art, finely detailed symmetrical perfect face studio lighting delicate features directed gaze, black military uniform, gapmoe kuudere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Gordon Lightfoot as The Flash",
        "full": "glorious full head portrait of Gordon Lightfoot as The Flash, fantasy, intricate, elegant, digital painting, trending on artstation, concept art, sharp focus, illustration by Gaston Bussiere and artgerm, 4k."
    },
    {
        "display": "Sephiroth concept art",
        "full": "Sephiroth illustrated by Akihiko Yoshida, concept art"
    },
    {
        "display": "Cyberpunk fire humanoid design",
        "full": "Character design body made of purple fire, body with black and purple lava, mecha humanoid with cyberpunk bomber jacket,  concept art character, royalty, smooth, sharp focus, organic, deep shadows by Jerad Marantz, hyperrealistic oil painting, 4k, studio lightning"
    },
    {
        "display": "Cyberpunk portrait of Chrissie Hynde",
        "full": "cyberpunk, halfbody shot of a young chrissie hynde, razorgirl, sunglasses, black hair, dramatic light, city background, sunset, dystopian setting, high contrast, sharp, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Cute anime girl with gun",
        "full": "\u201c anime, full body, cute, gun, female, a pretty girl wearing a school uniform and fighting with a gun, highly intricate detailed, light and shadow effects, intricate, highly detailed, digital painting, art station, concept art, smooth, sharp focus, illustration, advanced digital anime art, the most beautiful thing in the real world, so detailed that the ai drew it \u201d"
    },
    {
        "display": "Sacred ornamental bird design",
        "full": "beautiful classical holy decorative ornament, sacred death bird, wings spread, feathers, acanthus scrolls, lilies, ivy, energy, geometry, bones, petals, stems, ceremonial clouds, dripping paint, fibonacci rhythm, artstation, artgerm, wlop"
    },
    {
        "display": "Lifelike fantasy illustration.",
        "full": "bright realistic todd solondz, anorexic, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by francis bacon"
    },
    {
        "display": "Cute cat anime wallpaper",
        "full": "cute cat anime wallpaper, 4k, high details, trending on Artstation , art by Studio Ghibli"
    },
    {
        "display": "Futuristic military portrait.",
        "full": "Portrait of a man by Greg Rutkowski, he is about 20 years old, black, attractive, military composure, short black curly hair, american, trustworthy look, he is wearing futuristic military fatigues, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Cosmic android portrait.",
        "full": "beautiful possessed latin female android fused with lovecraft, cosmic entity, the thing, the blob, cable wires as hair ghostly, portrait, intricate details, by vincent di fate, artgerm, julie bell, beeple and Greg Rutkowski, 80s, concept, Smooth gradients, octane render, 8k, High contrast, duo tone, depth of field, very coherent symmetrical artwork"
    },
    {
        "display": "Dark Souls knight with crowbar.",
        "full": "dark souls knight holding a crowbar, concept art, artstation"
    },
    {
        "display": "Symmetrical floral robot portrait",
        "full": "symmetry!! portrait of a transformers robot, floral! midsommar style, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Cinematic sci-fi king portrait",
        "full": "king king wearing a 3 piece suit. head and chest. upperbody. movie scene, cinematic sci - fi scene. cloth and metal, accurate anatomy. portrait and science fiction theme with lightning, aurora lighting. clouds and stars. futurism by beksinski carl spitzweg moebius syd mead and tuomas korpi. baroque elements. baroque element. intricate artwork by caravaggio. oil painting. award winning concept art. trending on artstation. 4 k"
    },
    {
        "display": "Portrait of Jung Ho-yeon.",
        "full": "\u201c portrait of jung ho - yeon by greg rutkowski, young, attractive, highly detailed portrait, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq \u201d"
    },
    {
        "display": "Cyberpunk Gina Carano",
        "full": "gina carano with robotic cybernetic metallic grey left arm, casual pose, cyberpunk, digital painting, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Sam Fisher duel concept art",
        "full": "sam!!!!!! fisher!!!!!! by yoji shinkawa, concept art, duel"
    },
    {
        "display": "Anime monk in dungeon.",
        "full": "baroque oil painting of key visual portrait concept art of anime monk fighting with a wood weapon in a dungeon, brutalist, dark fantasy, rule of thirds golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai studio ghibli genshin impact jamie wyeth james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "Muscular female ogre illustration",
        "full": "muscular female ogre, green face, deep focus, d & d, fantasy, intricate, elegant bikini, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy crimson knight portrait",
        "full": "Portrait of a Fantasy crimson knight, moonlit, HD, illustration, epic, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, monster hunter illustrations art book"
    },
    {
        "display": "Beautiful green-haired djinn portrait.",
        "full": "beautiful strange detailed portrait of a stunningly beautiful green haired djinn genie - kin bard woman by luis royo, wayne barlowe, kirsi salonen, asya yoranova and alan lee trending on artstation 8 k"
    },
    {
        "display": "Brutalist architecture with neon signs",
        "full": "brutalist architecture, colorful neon signs, raphael lacoste, eddie mendoza, alex ross, concept art, matte painting, highly detailed, rule of thirds, dynamic lighting, cinematic, detailed, denoised, centered"
    },
    {
        "display": "Beautiful vampire portrait",
        "full": "a very beautiful vampire girl, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Trump as Captain America",
        "full": "hyperrealistic painting of Donald Trump as Captain America, D&D, fantasy, intricate, elegant, highly detailed, digital painting, post processing, extremely detailed, face symmetry, artstation, concept art, matte, smooth, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha,  oil painting, 8k, hyper realistic"
    },
    {
        "display": "Clever male thief portrait",
        "full": "portrait of a clever male thief with an eye mask, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lifelike pencil portrait of Marsha P. Johnson.",
        "full": "amazing lifelike award winning pencil illustration of Marsha P. Johnson trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Ominous titan god artwork.",
        "full": "professional ominous concept art of a titan god by artgerm and greg rutkowski ( thin white border ). an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp focus, illustration, in the style of simon stalenhag wayne barlowe, igor kieryluk."
    },
    {
        "display": "Aged knight in black armor.",
        "full": "aged knight wearing black armor, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Cinematic portrait of a woman.",
        "full": "portrait of a beautiful girl, flowy hair, cinematic lighting, highly detailed, digital painting, trending on artstation, pixiv, concept art, sharp focus, illustration, art by ross tran and wlop, a powerful energy psychedelic matrix woman, by alexander fedosav, hyper detailed digital matte painting, concept art, hyperrealism, 1 6 k resolution, cinema 4 d, 8 k resolution, trending on artstation, behance hd, a masterpiece, by stephan martiniere, particles, cel - shaded, power bright neon energy, by david a. hardy"
    },
    {
        "display": "Bearded writer portrait, smoking.",
        "full": "portrait of a middle - aged writer with a beard, he is smoking a cigarette, style of greg rutkowski"
    },
    {
        "display": "Sacred peacock ornamentation.",
        "full": "beautiful classical holy decorative ornament, sacred peacock, wings spread, feathers, acanthus scrolls, lilies, ivy, energy, geometry, bones, petals, stems, ceremonial clouds, dripping paint, fibonacci rhythm, artstation, artgerm, wlop"
    },
    {
        "display": "Heroic gigachad statue in fire",
        "full": "statue of the ultimate gigachad standing in a sea of fire, heroic, glorious, in the style of artgerm, gerald brom, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Cybernetic assassin portrait",
        "full": "a portrait of a beautiful cybernetic assassin, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Smiling man, high-contrast painting.",
        "full": "painting of a smiling man, clear - lines, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Elegant fantasy cowboy",
        "full": "rhodium cowboy, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lonely wanderer concept art",
        "full": "a highly detailed cinematic concept art cg render digital painting artwork : lonely wanderer. by greg rutkowski, by norman rockwell and beksinski, highly detailed, painted by francis bacon and edward hopper, ilya kuvshinov, wlop, stanley artgerm, very coherent, triadic color scheme, art | by takato yamamoto and james jean"
    },
    {
        "display": "Ultra-realistic Zatanna portrait.",
        "full": "ultra realistic, beautiful Anna Kendrick Zatanna DC Comics waist up floating, on stage, symmetrical face symmetrical eyes, modern anime, fantasy, eerie, intricate details, atmospheric, elegant, super highly detailed, professional digital painting, artstation, concept art, 8k, art by artgerm and eiichiro oda and koyoharu gotouge"
    },
    {
        "display": "Cliffside village architecture illustration.",
        "full": "2 d architectural illustration of an awesome sunny day environment concept art on a cliff, architecture by kengo kuma with village, residential area, mixed development, highrise made up staircases, balconies, full of glass facades, cgsociety, fantastic realism, artstation hq, autocad"
    },
    {
        "display": "Photorealistic black mage portrait",
        "full": "portrait of a male black mage from final fantasy, dark, piercing eyes, gentle expression, esoteric clothing, photorealistic, highly detailed, artstation, smooth, sharp focus, art by michael whelan, artgerm, greg rutkowski and luis royo"
    },
    {
        "display": "Angry Slav man portrait",
        "full": "portrait of a man by greg rutkowski, slav features, straight jaw, short black quiff hair, wearing black robes, angry but resigned attitude, star wars expanded universe, he is about 3 0 years old, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Hybrid baby bear-snake painting",
        "full": "painting of hybrid between baby bear & snake, animal has snake body, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Caitlyn Kiram portrait, realistic.",
        "full": "portrait of Caitlyn Kiramar from League of Legends, by Fortiche Studio, from Netflix's Arcane, trending on artstation,fine details, wearing police uniform, realistic shaded, fine-face, painted texture, pretty long face,"
    },
    {
        "display": "Cyborg artist portrait",
        "full": "hyperrealistic photography of a highly detailed and symmetrical gorgeous artist sitting in a cyborg machine in the style of Jin Kagetsu, James Jean and wlop, highly detailed, masterpiece, award-winning, sharp focus, intricate concept art, ambient lighting, 8k, artstation"
    },
    {
        "display": "High-detail white orc scene",
        "full": "white orc, high detail environment and characters, detailing even the smallest particles and details, black and white style, 4 k, 8 k, trending on artstation, artstationhd, artstationhq"
    },
    {
        "display": "Androgynous galaxy spirit portrait.",
        "full": "a portrait of a galaxy as an androgynous spirit, draped in transparent cloth, flat colors, minimal, swirly, bust by alphonse mucha, decorative art deco border, astrophotography, vast cosmos, digital illustration overlayed on photography, trending on artstation"
    },
    {
        "display": "Neo-classical lady portrait",
        "full": "a beautiful painting of a young lady by michaelangelo, neo-classical, trending on artstation"
    },
    {
        "display": "Cyberpunk seraphim tarot card",
        "full": "a beautiful tarot card artwork of a cyberpunk seraphim in nature, backlit, highly detailed, golden hour, digital painting, by karol bak and justin gerard and dan mumford and artgerm, vivid colors, masterpiece, detailed shading, 8 k resolution, intricate, smooth"
    },
    {
        "display": "Prismatic crystal structures.",
        "full": "a microscopic hdri wide angle photo of a shiny glowing reflective prismatic crystal structures filled with internal cybernetic dendrites of copper surrounded by jupiter's swirling clouds strong light from below, octane render, trending on artstation, moody, cinematic, 8 k, volumetric lighting"
    },
    {
        "display": "Warrior Pixie Portrait",
        "full": "3 / 4 view of a portrait of warrior pixie, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, symmetry face, fine details., trending on artstation, magali villeneuve, artgerm, jeremy lipkin and michael garmash, confident pose, pixie, genshin impact, magic, lights, intricate, elegant, sharp focus, illustration, highly detailed, concept art, matte, trending on artstation, comics h 6 4 0"
    },
    {
        "display": "Symmetrical sci-fi portrait.",
        "full": "symmetry!! portrait of miley cyrus, gold sci - fi armour, tech wear, glowing lights!! sci - fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic portrait of Thancred",
        "full": "portrait of thancred, digital illustration, by yusuke mogi, smooth, sharp focus, epic dramatic lighting, backlit, strong rim light, trending on artstation, 8 k"
    },
    {
        "display": "Beautiful warrior angel",
        "full": "beautiful warrior angel with pink hair, upper body, blue piercing eyes, thin features, beautiful aesthetic, by james jean, trending on artstation, digital art"
    },
    {
        "display": "Haunted house in forest",
        "full": "A haunted house in the forest with broken trees around it, dramatic lighting, cinematic, establishing shot, high detail, cinematic lighting, post processed, 8k, concept art, artstation, matte painting, in the style of eddie mendoza, raphael lacoste, alex ross"
    },
    {
        "display": "Putin as monkey portrait.",
        "full": "putin mixed with a monkey, funeral, portrait, highly detailed, full body, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Magical ethereal lolita portrait",
        "full": "portrait of magical lolita girl, dreamy and ethereal, peaceful expression, ornate frilly dress, fantasy, intricate, elegant, rainbow bubbles, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Isometric sci-fi RPG artwork",
        "full": "isometric (Dead Space) Diablo!!!! Prey 2016 action role playing game by artgerm, greg rutkowski, alphonse mucha, cgsociety and beeple highly detailed, sharp focus, cinematic lighting, illustration, art, octane render, Unreal Engine Lumen, very coherent. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Elegant schnauzer portrait.",
        "full": "portrait of stoic looking miniature schnauzer, black fir, white eyebrows, fantasy, intricate, elegant, highly detailed, centered, dark, smoke, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant redhead warrior portrait",
        "full": "a beautiful redhead warrior girl, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Angry woman in lake",
        "full": "young angry woman, beautiful girl, full body, shaved head, in a lake, realistic, serov, surikov, vasnetsov, repin, kramskoi, insanely detailed, charlie bowater, tom bagshaw, high resolution, octane rendered, unreal engine, illustration, trending on artstation, masterpiece, 8 k"
    },
    {
        "display": "Shaolin politician illustration",
        "full": "richly detailed color illustration of a shaolin-president-politician-kung-fu illustrated by Artgerm and Timothy Kong . 3D shadowing"
    },
    {
        "display": "Epic fantasy sword illustration",
        "full": "sword sword sword sword epic, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lone man reading magic book.",
        "full": "a painting of a lone man reading a magical book while sitting in a subway car, by rutkowski and artgerm, highly detailed, trending on artstation, movie concept art, cinematic lighting"
    },
    {
        "display": "Fantasy forest village scene",
        "full": "Huge redwood tree village, mushrooms, flowers growing, pond, river, waterfalls, by Miyazaki Nausicaa Ghibli, breath of the wild style, epic composition Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Symmetrical alien wreck portrait",
        "full": "symmetrical portrait of ribbed abandoned wreck of mother mary with alien embryo made of ribbed tubes on exoplanet, saint sacred religious icon, dream - like heavy dark mysterious nightmare atmosphere, abandoned alien technology in the wastelands, baroque portrait painting, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic concept art, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, raphael, caravaggio, greg rutkowski, beeple, beksinski, giger"
    },
    {
        "display": "Hyper-realistic corn battery.",
        "full": "corn battery, hyper realism, cinematic, dramatic ambient lighting, high detail, octane render, unreal engine, 8 k, vibrant colors, high contrast, depth of field, professional photo, concept art, dnd, 3 d render, digital art, deviantart artstation, ray tracing, apocalyptic, extremely detailed, very sharp,"
    },
    {
        "display": "Beautiful girl character design",
        "full": "beautiful girl character concept style, by Mateusz Urbanowicz, beautiful girl, 8k character concept art, by WLOP, cinematic lighting, trending on artstation, symmetrical portrait symmetrical, highly detailed CGsociety, hyper"
    },
    {
        "display": "Siblings' love illustration.",
        "full": "sister's love to her brother, full body, concept art trending on artstation, illustration, devianart, glowing effect, hyperdetailed, hyperreal, golden ratio, rule of thirds, 8 k"
    },
    {
        "display": "Bill Murray in kimono",
        "full": "bill murray, wearing kimono, by artgerm, cherry blossom falling, nagel"
    },
    {
        "display": "Fantasy character with armor",
        "full": "light iridescent armor!!! long wild white hair!! covered chest!!! fantasy, d & d, intricate ornate details, digital painting, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Cute smug pink-haired girl.",
        "full": "a highly detailed illustration of cute smug pink haired pale girl with horns wearing pink dress, dramatic smirk pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop."
    },
    {
        "display": "Hermione Granger in hoodie.",
        "full": "hermione granger wearing pink hoodie by charlie bowater and titian and artgerm, full - body portrait, intricate, face, hogwarts, elegant, orange mist, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Woman in detailed dress, spaceship window.",
        "full": "woman sitting on a spaceship window, beautiful detailed dress, close - up portrait, highly detailed face, smiling, by artgerm, by wlop, by greg rutkowski, octane render, digital art"
    },
    {
        "display": "Anime maid character portrait",
        "full": "baroque oil painting, anime key visual full body portrait character concept art, anime maid nazi ss military, bolt action rifle, blond hair blue eyes, brutalist dark fantasy, trending pixiv fanbox, rule of thirds golden ratio, detail acrylic palette knife, style of makoto shinkai genshin impact studio ghibli jamie wyeth greg rutkowski chiho aoshima"
    },
    {
        "display": "Symbiotic cyberpunk portrait",
        "full": "symmetry!! portrait of a symbiote woman, sci - fi -, cyberpunk, blade runner, glowing lights, tech, biotech, techwear!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk prince statue",
        "full": "a young handsome Spanish prince in a full-body bronze cyberpunk style statue of Icarus with glowing blue eyes, crown of peach roses,  flowing teal-colored silk, fabric, flowers. baroque elements, human skull. full-length view. baroque element. intricate artwork by caravaggio.  many many birds birds on  background. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Fallen angels battle, abstract.",
        "full": "two fallen Angels with big wings fighting each other, painting, expressionism, amazing composition, vaporwave, David Hockney, minimalistic graffiti masterpiece, 3d abstract render overlayed, psychedelic therapy, trending on ArtStation, ink splatters, pen lines, incredible detail, creative, unique,"
    },
    {
        "display": "Filipino woman at sunset",
        "full": "Filipino woman with a short hair looking out the window to a beautiful sunset, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Summer sunset in Banska Stiavnica",
        "full": "a beautiful hyper realistic photo of banska stiavnica in summer, cobblestone pavement, with old houses and few trees in sunset, sky, ufo in sky, v - ray, octane render, unreal engine, by greg rutkowski and james gurney, artstation"
    },
    {
        "display": "Katarina, hyper-detailed digital art",
        "full": "katarina, from league of legends, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, light armored, studio quality, smooth render, fluorescent skin, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Unconscious labyrinth concept art",
        "full": "a ultradetailed beautiful concept art a view of the unconscious labyrinth filled with countless path to the depth of the mind, dramatic lighting, dynamic lighting, cinematic lighting, lit by morning light, ultrawide angle, f 8, polarizer filter, concept art, high resolution 4 k, by krenz cushart and ilya kuvshinov and artgerm, featured on artstation"
    },
    {
        "display": "Symmetrical chubby sci-fi portrait.",
        "full": "symmetry!! portrait of shirtless!!! chubby!! danny devito, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lofi biopunk Boris Johnson.",
        "full": "lofi biopunk Boris Johnson portrait Pixar style by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "French woman sewing flowers.",
        "full": "richly detailed colored pencil 3D illustration of a beautiful French woman with short bleached blonde hair wearing a long Tshirt and black shorts sewing thread drawings of flowers into her thigh. mirrored background with completely rendered reflections, art by Range Murata and Artgerm."
    },
    {
        "display": "Cyberpunk Kurisu Makise",
        "full": "kurisu makise steins gate in cyberpunk city, anime atmospheric, standing, lab coat, elegant, super highly detailed, professional digital painting, artstation, concept art, 8k, art by wlop, artgerm and alphonse mucha and eiichiro oda shinkai makoto vibrant Studio ghibli kyoto animation hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti"
    },
    {
        "display": "Psychedelic skeleton goddess portrait",
        "full": "portrait a dripping melting skeleton goddess dancing frenzy with cigarette packs floating around them cans of beer and cans of tomato soup, walt disney style, painting behind them, south park characters, psychedelic, melting scenery, melting mushrooms, very intricate glowing cybernetic eyes in a rose garden at night, apex legends character digital illustration portrait design, by noah bradley and android jones in a cyberpunk style, synthwave color scheme, dramatic lighting, full body portrait, loony toons style, illustrated by greg rutkowski and dr seuss., trending on artstation, artstationhd, artstationhq"
    },
    {
        "display": "Buddhist demon concept art",
        "full": "naraka buddhist demon korean female, highly detailed, symmetrical long head, smooth marble surfaces, detailed ink illustration, raiden metal gear, cinematic smooth stone, deep aesthetic, concept art, post process, 4 k, carved marble texture and silk cloth, latex skin, highly ornate intricate details, in the style of 8 8 grzes"
    },
    {
        "display": "Portrait of Revy from Black Lagoon",
        "full": "a portrait of revy from black lagoon, black tank top, jean shorts, brown eyes, purple hair, tribal tattoos right arm sleeve, symmetrical eyes, symmetrical face, art by lois van baarle and loish and ross tran and rossdraws and sam yang and artgerm"
    },
    {
        "display": "Photorealistic cyberpunk man",
        "full": "highly detailed photorealistic man with a cyberpunk leather jacket, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Imagination creation machine illustration",
        "full": "a highly detailed digital image of an imagination creation machine, concept art, artstation, cgsociety, very detailed, intricate, detailed illustration, by artgerm and greg rutkowski and alphonse mucha, product lighting, sharp, smooth, masterpiece"
    },
    {
        "display": "Indiana Jones at temple",
        "full": "indiana jones standing in front of an ancient temple, digital painting, extremely detailed, 4 k, intricate, artgerm, by stanley lau"
    },
    {
        "display": "Elegant female portrait",
        "full": "beautiful natural female Rhyan Gosling, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Happy pig, sad tank.",
        "full": "hybrid of a happy pig and sad tank, beautiful detailed face, ultra realistic, concept art, intricate details, serious, highly detailed, photorealistic, octane render, 8 k, unreal engine."
    },
    {
        "display": "Happy Remi Malek Tricycle",
        "full": "A beautiful oil cartoony painting of a happy Remi Malek riding a tricycle by Lucas Graciano, Frank Frazetta, Greg Rutkowski, Boris Vallejo, epic fantasy character art, high fantasy, Exquisite detail, post-processing, low angle, masterpiece, cinematic"
    },
    {
        "display": "Enchanting fairy queen portrait",
        "full": "beautiful beautiful full body portrait fairy faerie fey fae queen soft highly detailed CGsociety  subtle enchanting alluring magical concept art volumetric lighting unreal engine  octane render 4k"
    },
    {
        "display": "Cyberpunk-themed android portrait",
        "full": "cyber punk, android cannon mindar from japan, oni mask, japanese demon mask, 3 d render beeple, portrait, japanese neon red kanji, male anime character, unreal engine render, intricate abstract, sparking hornwort, broken robot, intricate artwork, beeple, octane render, epcot, shadows of colossus, glitch art, glitchcore, organic, forest druid, hellscape, futurescape, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents, albedo from overlord, the library of gems, intricate abstract. intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Neil Cicierega portrait with cat",
        "full": "portrait of Neil Cicierega looking forward holding his cat, digital artwork, highly detailed, trending on artstation, red colors"
    },
    {
        "display": "Dragon over castle.",
        "full": "a dragon flying above a castle, 8 k concept art"
    },
    {
        "display": "Old king portrait, high detail",
        "full": "old king of the kingdom of larion, close angle, painting by gaston bussiere, craig mullins, j. c. leyendecker, 4 k, 8 k, trending on artstation, artstationhd, artstationhq, highest detail"
    },
    {
        "display": "Ultraman as Rocket Raccoon",
        "full": "Ultraman exposed to the symbiote and became Rocket Raccoon. concept art,high detailed,fine art,trending on Artstation, smooth draw,Sharp focus."
    },
    {
        "display": "Starcraft cinematic portrait",
        "full": "world of starcraft, digital painting, portrait , cinematic lighting, highly detailed, artstation, concept art, illustration, smooth, sharp focus, artgerm , greg rutkowski, alphonse mucha, editor's pickup, trending on artstation, trending on deviantart, WLOP"
    },
    {
        "display": "Clown car in forest.",
        "full": "a clown and clown car driving on a curvy gravel road in a forest, rainbow beams, natural lighting. 8 k, octane render, full shot. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Faerie priestess in forest.",
        "full": "stunningly beautiful female faerie priestess in amanita muscaria forest landscape, symmetrical wings on back, symmetrical face, stunning eyes, neon hair, fantasy art, dark light night, sharp focus, digital painting, 4 k, concept art, art by wlop, artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dapper squirrel bartender",
        "full": "a squirrel anthro as a dapper bartender with a big, fluffy tail, retro futurism, art deco, detailed painterly digital art by WLOP and Cory Loftis, \ud83d\udc3f\ud83c\udf78\ud83c\udf4b, furaffinity, trending on artstation"
    },
    {
        "display": "Underwater metropolis landscape",
        "full": "an epic landscape view of a vast underwater metropolis, with glowing windows, towers, spires, parapets, balconies, bridges, glass, crystal, with colorful fish and sea creatures, painted by craig mullins and tyler edlin, close - up, low angle, wide angle, atmospheric, volumetric lighting, cinematic concept art, very realistic, highly detailed digital art"
    },
    {
        "display": "Futuristic glowing cats.",
        "full": "duotone noir scifi concept illustration of lowpoly cats floating zero gravity glowing 3 d mesh portals futuristic, glowing eyes, octane render, surreal atmosphere, volumetric lighting. golden ratio fibonacci by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. trending on artstation"
    },
    {
        "display": "Llama basketball dunk",
        "full": "a llama dunking a basketball, 4 k, digital art, high resolution, trending on artstation"
    },
    {
        "display": "Hyperrealistic Nathalie Emmanuel Pinup",
        "full": "Nathalie Emmanuel, pinup, league of legends, intricate, highly detailed, digital painting, hyperrealistic, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha, by Jesper Ejsing"
    },
    {
        "display": "Symmetrical female sorcerer portrait",
        "full": "symmetry!! portrait of a female sorcerer, dar fantasy, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and huang guangjian and android jones and sachin teng"
    },
    {
        "display": "Happy gay couple in meadow",
        "full": "bradley james and colin morgan are in a beautiful meadow, happy. gay male couple. from the television show merlin ( 2 0 0 8 ). ; high - detailed oil painting by igor sychev, ilya repin, caravaggio, william blake, alex grey, thomas eakins and beksinski trending on artstation, masterpiece, 4 k"
    },
    {
        "display": "Kim Petras UFO Ascension",
        "full": "kim petras beaming up into a ufo, anime portrait, in space, transcending to a higher plane, the eternal blessing, in the multiverse, lazer light beaming down to top of her head, by android jones, by ben ridgeway, by ross draws, by noah bradley, by maciej kuciara + illustrative + visionary art + low angle + oil painting + profile portrait artgerm, featured in artstation, elegant, moebius, greg rutkowski"
    },
    {
        "display": "Traveler in vibrant mountains",
        "full": "a traveler wandering trough the mountains looking at the clouds, very detailed, focused, oil painting, cinematic lighting, albert bierstadt, trending on artstation, colorful, canvas, sunset, hans dahl, theodor kittelsen, hermann hendrich, national geographic, Konstantin Yakovlevich Kryzhitsky, beautiful nature, breathtaking, nordic"
    },
    {
        "display": "Realistic female gnome engineer.",
        "full": "portrait of a beautiful cute strong brave realistic female gnome engineer, shaved undercut black hair, d & d, micro detail, intricate, elegant, highly detailed, centered, rule of thirds, artstation, sharp focus, illustration, artgerm, tomasz alen kopera, donato giancola, wlop"
    },
    {
        "display": "Ultrarealistic angel princess portrait",
        "full": "A masterpiece ultrarealistic ultradetailed portrait of a Incredibly beautiful Emma Watson as angel princess with Royal Tevton Knight Skull Full Iron Closed Helmet with Big Iron Bull Horns . baroque renaissance  girl  in the night forest. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, Yoshitaka Amano. background by James Jean and Gustav Klimt, light by Julie Bell, 4k, porcelain skin."
    },
    {
        "display": "Futuristic celestial landscape.",
        "full": "Celestial majestic luxurios futuristic other worldly realm with Singaporean royal gold lush volcano, set on Antelope Canyon with white thermal waters flowing down pink  travertine terraces, relaxing, ethereal and dreamy, thunderstorms and multiversal tornado, visually stunning, from Star Trek 2021, illustration, by WLOP and Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau, Artgerm"
    },
    {
        "display": "Pharaoh wizard portrait.",
        "full": "an anthropomorphic beautiful female wizard of pharaoh portrait wearing robe, fine art, award winning, intricate, elegant, sharp focus, octane render, hyperrealistic, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by jamie hewlett and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Orc vs. Elf Fantasy Battle",
        "full": "renaissance painting of aggressive evil orc warrior strangling a heaven nordic elf sorcerer, d & d, warhammer, muscular! crossfit, fitness, tech suit, tight wrinkled cloath, vivid color scheme, atmospheric perspective, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and alex ross"
    },
    {
        "display": "Sandra Bullock as Poison Ivy",
        "full": "portrait of Sandra Bullock as a Poison Ivy. intricate artwork. by Tooth Wu, wlop, beeple, dan mumford. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Anime portrait of Stallone",
        "full": "anime portrait of Sylvester Stallone as an anime man by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, trending on artstation"
    },
    {
        "display": "Kim Possible in swimsuit",
        "full": "kim possible in a black swimsuit, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, 8 k"
    },
    {
        "display": "Hippie with raven wings",
        "full": "an old hippie with raven wings, metal shiny feather skin. intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, frank frazetta"
    },
    {
        "display": "Body horror illustration of Rothschild and Soros",
        "full": "Jacob Rothschild and george soros by Ralph Steadman, illustration, body horror, biopunk, 8k , trending on artstation"
    },
    {
        "display": "Epic fantasy ragnarok scene",
        "full": "a epic and fantasy concept art of ragnarok, zeus, thor, by tsuyoshi nagano, god of wars, aion, hyperdetailed, 8 k realistic, symmetrical, wallpaper, long shot, frostbite 3 engine, cryengine, dof, trending on artstation, digital art,"
    },
    {
        "display": "Bear Berserker Fantasy Art",
        "full": "Bear Berserker, Khorne, magic the gathering artwork, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Medusa character portrait.",
        "full": "character concept portrait of a beautiful woman with pale face, medusa, blue / grey eyes, intricate, elegant, digital painting, art nouveau, smooth, focus, rim light"
    },
    {
        "display": "Fantasy elf ranger portrait",
        "full": "ranger elf, light opal armor!!! long curly red hair!! covered chest!!! green eyes! fantasy, d & d, intricate ornate details, digital painting, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Scary Cat in the Hat",
        "full": "the cat in the hat, scary, cinematic, concept art by greg rutkowski and artgerm and dr seuss"
    },
    {
        "display": "Elegant knight portrait",
        "full": "portrait of knight, white eyes, white long hair, scar on face, handsome, elegant, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gothic Lolita Dress Exhibition",
        "full": "the beautiful hyperdetailed physical rendering of a rose wedding gothic lolita dress clothing design display in exhibition hall, exhibition hall lighting, in the style of makoto shinkai victo ngai and peter mohrbacher studio ghibli artgerm karol bak beeple, surrealistic style, close range, 8 k hd, 3 drender"
    },
    {
        "display": "Post-apocalyptic cyberpunk interior",
        "full": "post apocalyptic room interior, furniture, interior decoration, by thomas kinkade trending on artstation, photorealistic, hyper detailed, hyper realistic cyberpunk"
    },
    {
        "display": "Man in VR racing car.",
        "full": "photography of man playing realistic virtual reality game driving formula 1 flying car on race arena 3 d realistic model render in the style of zaha hadid with point cloud in the middle, in cyberpunk 2 0 7 7 colors, unreal engine 5, keyshot, octane, artstation trending, ultra high detail, ultra realistic, cinematic, 8 k, 1 6 k, in style of zaha hadid, in style of nanospace michael menzelincev, in style of lee souder, in plastic, dark atmosphere, tilt shift, depth of field"
    },
    {
        "display": "Massive hovering frog.",
        "full": "hyperrealism, detailed textures, photorealistic 3 d, a massive hovering frog emerging from a fluffy cloud, ultra realistic, cinematic, intricate, cinematic light, concept art, illustration, art station, unreal engine 8 k"
    },
    {
        "display": "Clothed Monkey Scientist Experiment",
        "full": "Monkey Scientist, Experiment in Laboratory, clothed, D&D, artstation, fantasy, magic the gathering artwork, cinematic lighting, centered, symmetrical, highly detailed, digital painting, , concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Anime character with car.",
        "full": "anime illustration of ryosuke takahashi in his thirties wearing a dark blue shirt and white pants, standing by his glossy white 1 9 9 0 mazda rx - 7 fc on an empty highway at sunrise, cinematic lighting, initial d anime 1 0 8 0 p, 9 0 s anime aesthetic, volumetric lights, rule of thirds, unreal engine render, pinterest wallpaper, trending on artstation"
    },
    {
        "display": "Digital sunflower in pink sea",
        "full": "beautiful digital sunflower in stunning pink sea, VERY LIGHT pink and blue scheme, isometric, by Anton Fadeev and Simon Stalenhag, trending on artstation, low contrast"
    },
    {
        "display": "Burning cat artwork.",
        "full": "Burning cat, greg rutkowski, trending on artstation."
    },
    {
        "display": "Cosmic horror girl illustration.",
        "full": "azathoth girl very slightly smiling, dress made out milky way, full body, occlusion shadow, specular reflection, rim light, unreal engine, artgerm, artstation, art by hiroaki samura and ilya kuvshinov and ossdraws, intricate, highly detailed 8 k, cosmic horror illustration, extremely beautiful shape of face and body"
    },
    {
        "display": "Androgynous sci-fi character.",
        "full": "if gilded opal porcelain glass was a pale androgynous young man, johan liebert mixed with alucard, long fluffy golden blonde curly hair, digital painting, sci - fi setting, symmetrical features, daniel gerhartz, high detail intricate, artstation, wlop, artgerm, j. scott campbell ; olivia de berardinis, daniel gerhartz, jack kirby, mysterious vibes"
    },
    {
        "display": "Symmetrical robot astronaut portrait",
        "full": "symmetry!! portrait of a robot astronaut, tech face, horizon zero dawn machine, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Vivid USA landscape art",
        "full": "USA, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by Jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Futuristic anime police officer",
        "full": "anime key visual of margot robbie police officer, neon, cyberpunk, futuristic, stunning, highly detailed, digital painting, artstation, smooth, soft focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Female Amazon Warrior Portrait",
        "full": "Jeff Bezos!! as a female amazon warrior, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Fantasy portrait of Richard Ayoade",
        "full": "a fantasy style portrait painting of richard ayoade oil painting unreal 5 daz. rpg portrait, extremely detailed artgerm greg rutkowski greg hildebrandt tim hildebrandt"
    },
    {
        "display": "Midgar city concept art.",
        "full": "concept art of city of midgar from final fantasy 7, dark atmosphere, drone photography"
    },
    {
        "display": "Elegant soccer player illustration.",
        "full": "an illustration of a soccer player, elegant,"
    },
    {
        "display": "Detailed fantasy chapel artwork",
        "full": "chapel, ultra detailed fantasy, elden ring, realistic, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Medieval queen with serpent",
        "full": "femme fatal medieval queen holding a serpant, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Daenerys Targaryen in bikini",
        "full": "Daenerys Targaryen with a piercing gaze and wearing a creative bikini as the queen of atlantis, highly detailed face, realistic face, beautiful detailed eyes, fantasy art, in the style of Joel Santana, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, photorealistic"
    },
    {
        "display": "Gotham City Nightscape",
        "full": "nighttime in gotham city, wet pavement, street level looking up, light mist, fantasy, intricate, elegant, digital painting, trending on artstation, concept art, soft focus, illustration by greg rutkowski, Gaston Bussiere and artgerm, 4k."
    },
    {
        "display": "Humanoid rat portrait, elegant.",
        "full": "portrait of a rat with a humanoid face, male, handsome, lean, full body, red hair, long hair, soft hair, fantasy, red kimono, intricate, elegant, highly detailed, suit, digital painting, artstation, concept art, character art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Undead black knight illustration",
        "full": "the black knight rising from his grave, symmetric armor, wings, undead, highly detailed, smooth, sharp focus, dark fantasy, greyscale, digital illustration, by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Velociraptor on bike.",
        "full": "velociraptor riding a bike, modernism, trending on artstation, make it look like it was created with dall - e 2"
    },
    {
        "display": "Psychedelic robotic portrait.",
        "full": "cinematic bust portrait of psychedelic robot with horns from left, head and chest only, exotic alien features, robotic enhancements, electric color , desaturated, Tim Hildebrandt, Wayne Barlowe, Bruce Pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper-detailed, HD, HDR, 4K, 8K"
    },
    {
        "display": "Boris Johnson in bold art",
        "full": "boris johnson, organic painting, matte painting, bold shapes, hard edges, aesthetic octane render, unreal engine, trending on artstation, by greg manchess, huang guangjian, gil elvgren, sachin teng, greg rutkowski, magali villeneuve, artgerm, jeremy lipkin, michael garmash and, rey"
    },
    {
        "display": "Midwestern stoner girl portrait.",
        "full": "portrait of a midwestern american stoner girl student reading about the dark ages, illustrated on transparent glass by ross tran, katsuhiro otomo, yoshitaka amano, and artgerm. 3 d shadowing effect, 8 k resolution."
    },
    {
        "display": "Geometric mannequin with orchids.",
        "full": "a beautiful red and black 3 d geometrically printed mannequin in the style of james jean, chrome orchids dripping black iridescent liquid, winged victory, moody, dramatic, introspective, 4 k, trending on artstation, photorealistic, volumetric lighting, octane render"
    },
    {
        "display": "Detailed female android art",
        "full": "ultra detailed female android, scifi, fantasy, octane render, ( dia de los muertos ), asymmetrical, intricate concept art, intricate detailed environment, global illumination, art by godmachine and michael welan and rossdraws and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Anime magician in baroque style",
        "full": "baroque oil painting of anime key visual environment concept art of anime girl magician with big hat casting magic, brutalist, dark fantasy, rule of thirds, fake hidden detail, trending on pixiv fanbox, acrylic palette knife and brush, style of makoto shinkai studio ghibli genshin impact jamie wyeth james gilleard greg rutkowski"
    },
    {
        "display": "Symmetrical sci-fi puzzle cube",
        "full": "symmetry!! product render poster puzzle cube scifi, glowing lines! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Anime soldier girl patch",
        "full": "shoulder patch design, soldier girl, anime style, clean logo design, military flight squadron insignia, no text, soldier clothing, realistic military gear, 70mm, inspired by famous brands, made in photoshop, no background, vector line art, by ilya kuvshinov, trending on artstation, intricate, realistic human anatomy, meme culture, highly detailed, high resolution, for printing, colored ink, realistic weapon, realistic military carrier"
    },
    {
        "display": "Baby Yoda as Frodo",
        "full": "baby yoda in the role of frodo baggins with the ring of power, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Holographic vending machine in abandoned house.",
        "full": "an holographic vending machine inside the interior of an abandon house, sunny day, long shot, matte painting, digital art, in the style of studio ghibli, vivid colors, highly detailed, 8 k, establishing shot, smooth, trending on artstation, illustration, realistic"
    },
    {
        "display": "Mother and child love.",
        "full": "pure love is patient love is kind, mother and child ; photorealistic oil painting by tom chambers and artgerm ; highly detailed cute faces by wlop ; trending on artstation ; 8 k high resolution, symmetrical, cinematic, high coherence, golden ratio, rule of thirds, perfectly centered ; anatomically correct faces"
    },
    {
        "display": "Porcelain Homer Simpson statue.",
        "full": "digital masterpiece illustration concept art of porcelain statue of homer simpson, extremely detailed and intricate complexity, epic composition, magical atmosphere, cinematic lighting, wide long shot, trending on artstation, 8 k"
    },
    {
        "display": "Cute Star Trek Colonel Sanders",
        "full": "cute star trek officer colonel sanders, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Photorealistic Dumbledore Concept Art",
        "full": "harry potter is dumbledore, concept art by senior character artist, cgsociety, photorealism, rendered in unreal engine, official art, cold hue's"
    },
    {
        "display": "Neon Dreamy Rebirth Illustration",
        "full": "altered carbon, rebirth, neon, dreamy vibe, fibonacci, sweat drops, insane intricate, highly detailed, cinematic, atmospheric. digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha, laura sava, laura palmer"
    },
    {
        "display": "Anubis Lich Fantasy Artwork",
        "full": "Anubis/Lich, magic the gathering artwork, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Surreal portrait in wasteland",
        "full": "surreal closeup portrait photo of man face by Salvador Dal\u00ed, Dalion exoplanet, baroque painting, desolate empty wasteland, creepy, nightmare, dream-like heavy atmosphere, dark fog, surreal abandoned buildings, baroque painting, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, volumetric cinematic light, chiaroscuro, Raphael, Caravaggio, Beksinski, Giger, Dali"
    },
    {
        "display": "Androgynous metallic lich skeleton",
        "full": "hyperdetailed beautiful androgynous lich skeleton made of iridescent metals and shiny gems, covered with blood, long red hair, golden necklace, regal dignified ancient skeleton djinn inspired by ross tran and wlop and masamune shirow, greg rutkowski, alphonse mucha, ultra realistic, concept art, intricate details, octane render, 8 k, hdr. dnd digital art by artgerm"
    },
    {
        "display": "Demon hero character design",
        "full": "human male demon, full body white purple cloak, hero, armor, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Isometric cross-section of Frasier Crane",
        "full": "annotated highly - detailed and intricate 4 5 degree isometric cross - section of doctor frasier crane + marker concept art style rendering + concept art + half blueprint + trending on artstation + intricate details + john berkey + vincent di fate + ralph mcquarrie + center frame : : annotations : : yellow : : - 0. 2 5 kelsey grammer sketch : : crane : : - 1"
    },
    {
        "display": "Serena Williams as Warlock",
        "full": "mindblowing, serena williams as a female warlock, black wizard hat, robes, deep focus, d & d, fantasy, intricate, elegant!!, beautiful, highly detailed, digital painting, artstation, concept art, matte, sharp, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Majestic fruit stand scene",
        "full": "medium - shot, majestic ( pop rock candy ), a fruit stand on the side of a serene country dirt road, the road is dusty and there is a breeze. epic, awe inspiring, dramatic lighting, cinematic, extremely high detail, photorealistic, cinematic lighting, trending on artstation cgsociety rendered in unreal engine, 4 k, hq,"
    },
    {
        "display": "Katy Perry in Candy Land",
        "full": "katy perry by sylvain sarrailh, rhads, ferdinand knab,, candy land background with bokeh, trending on artstation, cinematic light, pastel colors, volumetric shading, high radiosity dull skin,, global illumination, radiant light, soft light, soft color dodge, subsurface scattering"
    },
    {
        "display": "Hippie saxophonist in anime.",
        "full": "wide view of a hippie Blonde Guy Short Hair Sharp fine face playing sax, pretty face, realistic shaded Perfect face, fine details. Anime. by makoto sinkai, katsuhiro otomo ghost in the shell movie scene, magali villeneuve, artgerm, rutkowski"
    },
    {
        "display": "Occult Fred Flintstone Portrait",
        "full": "occult fred flinstone with glowing haunted eyes, metal skin, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art"
    },
    {
        "display": "Cute fantasy character illustration",
        "full": "Guybrush Threepwood, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4k, HDR, concept art, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Skull-shaped planet, ultra-detailed.",
        "full": "planet that looks like a skull, natural, ultra detail. digital painting, beautiful, concept art, ethereal, cinematic, epic, 8k, high detail, Artstation, Trending on Artstation, Artstation HQ, Artstation HD, deviant art, Pinterest, digital art,"
    },
    {
        "display": "Blonde magical girl illustration.",
        "full": "blonde haired magical girl anime character screenshot, anime, intricate, sharp focus, illustration, highly detailed, digital painting, clean artstyle, concept art, matte, art by ilya kuvshinov and ruan jia and greg rutkowski, masterpiece"
    },
    {
        "display": "Ethereal Princess Peach Portrait",
        "full": "portrait of princess peach, fantasy, gradient white pink gold, dreamy and ethereal, green eyes, golden ratio, peaceful expression, ornate frilly dress, fantasy, intricate, elegant, blue skye with clouds on the background, highly detailed, digital painting, artstation, concept art, smooth,b sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyperrealistic psychedelic nature.",
        "full": "hyperrealistic abstract close-up Renaissance psychedelic!! celestial happy! pure creature!! peaceful! kind spirit of nature! beautiful fractal!! eyes! highly detailed concept art eric zener elson peter cinematic hard rainbow lighting high angle hd 8k sharp shallow depth of field, inspired by Zdzis\u0142aw Beksi\u0144ski Salvador Dali"
    },
    {
        "display": "Magical maid in forest",
        "full": "A beautiful maid wearing a magical bikini posing in a magical forest, beautiful face, highly detailed face, close-up, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus"
    },
    {
        "display": "Floral Empress Portrait",
        "full": "a beautiful portrait of a singular empress, with a brilliant, impossible striking big flower headpiece, clothes entirely made out of flowers, symmetrical, dramatic studio lighting, rococo, baroque, jewels, asian, hyperrealism, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, 8k, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Translucent Zayn Malik statue.",
        "full": "full-body baroque and cyberpunk style neon statue of a attractive translucent Zayn Malik as a humanoid deity wearing a thin see-through plastic hooded cloak sim roupa, posing like a superhero, glowing white face, crown of white lasers, large diamonds, swirling white silk fabric. futuristic elements. oozing glowing liquid, full-length view. space robots. human skulls. throne made of bones, intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Cell-shaded Magnus Carlsen portrait",
        "full": "a study of cell shaded portrait of Magnus Carlsen concept art, llustration, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00ef\u00bf\u00bdn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, alien, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Cat superhero movie poster",
        "full": "a movie poster about cat save the world, oil painting, concept art, artstation, cinematic, highly detailed, 4 k, by artgerm"
    },
    {
        "display": "Anime girl falling through sky",
        "full": "baroque acrylic painting, anime key visual concept, anime girl falling through the sky, rule of thirds golden ratio, fake detail, trending pixiv fanbox, style of makoto shinkai ghibli takashi takeuchi yoshiyuki sadamoto jamie wyeth james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "Detailed fantasy character portrait",
        "full": "plainswalker, ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Alien city street view",
        "full": "close up city street view on an alien planet by moebius, earth and pastel colors, architecture by gaudi, mutant inhabitatnts by enki bilal, dramatic perspective, very coherent, trending on artstation"
    },
    {
        "display": "Colorful alien power plant",
        "full": "alien nuclear power plant, colorful, sci-fi, utopia, octane render, substance painter, zbrush. Trending on artstation. 8K. Highly detailed."
    },
    {
        "display": "Cosmic goddess portrait.",
        "full": "portrait of a cosmic goddess, suit made out of stars and galaxies and cosmic energy, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Goth Cyborg Biden Portrait",
        "full": "portrait of joe biden goth cyborg with white hair in warhammer armor, art by ( ( ( kuvshinov ilya ) ) ) and wayne barlowe and gustav klimt and artgerm and wlop and william - adolphe bouguereau"
    },
    {
        "display": "Mad Hatter's Teaparty Illustration",
        "full": "The Mad Hatter teaparty, Alice in wonderland, colorful, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Goth fantasy portrait illustration.",
        "full": "a beautiful goth woman, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Anthro Tiger in futurism",
        "full": "character portrait of a male anthro Tiger fursona wearing a dress shirt and slacks in a futuristic city. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Muscular Nepali female portrait",
        "full": "painting of a muscular dark nepali female, big eyes, fat, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vaporwave Rachael portrait.",
        "full": "beautiful, young woman, rachael from blade runner, sad eyes, vaporwave aesthetic, synthwave long blade runner city, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bioluminescent skeleton wizard portrait",
        "full": "Bioluminescent, portrait of skeleton wizard with orb,  very intricate , trending on artstation , very elegant, in the golden hour by Daniel Merriam, Trending on Artstation, oil on Canvas by Elena Zhurikhina and Goro Fujita and Charlie Bowater, octane render, 4k, 8k, HD"
    },
    {
        "display": "Highly detailed portrait of Steve Bannon.",
        "full": "5 5 mm portrait photo steve bannon by charlie bowater and titian and artgerm, intricate, face, highly detailed 8 k, intricate, lifelike, soft light, cinematic lighting, featured on artstation"
    },
    {
        "display": "Starfield concept art",
        "full": "a concept art from starfield"
    },
    {
        "display": "Sophie Turner as Miss Fortune",
        "full": "character sheet of sophie turner as miss fortune (League of Legends). 3d render, octane render, iRay, ray tracing, realistic, highly detailed, trending on artstation, 4k, cgsociety, unreal engine 5, redshift render, blender cycles, behance, cg"
    },
    {
        "display": "Fantasy mountain hot spring",
        "full": "painting of an interior of a hot spring in the mountains, fantasy, lush plants and flowers, natural light, concept art, by greg rutkowski and craig mullins, cozy atmospheric and cinematic lighting, trending on artstation"
    },
    {
        "display": "Intricate skull portrait",
        "full": "skull, close - up portrait, powerful, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, black ink pen, small gold leaf flake accents"
    },
    {
        "display": "Transforming camera robot concept",
        "full": "concept art of camera that can transform into a robot, 3 d - concept, model, 4 k, unreal engine 5"
    },
    {
        "display": "Cute AI robot concept.",
        "full": "concept art for an cute little ai robot from an upcoming indie video game"
    },
    {
        "display": "Hay hat on table",
        "full": "just a hay hat in a wooden table. by Craig mullins, Steve Purcell, Ralph McQuarrie. Trending on artstation. Centered image"
    },
    {
        "display": "Psychedelic portrait of Audrey Hepburn",
        "full": "An extremely psychedelic portrait of Audrey Hepburn wearing sunglasses, surreal, LSD, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "portrait of julia garner, half body, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha, boris vallejo"
    },
    {
        "display": "Divine feminine elf portrait",
        "full": "perfectly feminine face!! full body portrait of young elf fairy blessed by nature with ever - increasing physical mental perfection, blonde, symmetrical! intricate, sensual features, highly detailed, biblical divine holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Robot with lightsaber in space",
        "full": "robot character, red lightsaber, space, star wars, retrowave, vaporwave, black cloak, concept art, arstation, award winning art by"
    },
    {
        "display": "Dark Queen Sansa Stark Portrait",
        "full": "portrait of sansa stark dark queen, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Miniature Cadillac in snow.",
        "full": "a miniature 1959 Cadillac car in the snow, macro shot. Digital illustration, very vibrant colors, soft lighting, adventurous, atmospheric lighting, 8K, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "Muscular female gnome engineer.",
        "full": "muscular female gnome engineer, short black hair, blasting lightning from metal gauntlet, standing on a ship deck, full body portrait, d & d, piercing stare, highly detailed, digital painting, hd, artstation, great composition, concept art, matte, sharp focus, illustration, artgerm, greg rutkowski"
    },
    {
        "display": "Cute Mexican skeleton girl",
        "full": "cute & beautiful smiling mexican undead skeleton girl wearing a sombrero and a wide red dress, elegant, digital art, fantasy, pixar style, painting, pin up, highly detailed, artstation, art by artgerm, john la farge and boris vallejo"
    },
    {
        "display": "Gothic rainbow goddess portrait",
        "full": "a portrait of the lisa frank leopard print rainbow goddess from walmart, intricate, gothic, highly detailed, digital painting, crown of skulls, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Wrestlers in VR goggles",
        "full": "wrestlemania wrestlers wearing vr goggles, gta cover, trending on artstation, digital illustration"
    },
    {
        "display": "Rugged steampunk ship captain",
        "full": "rugged young ship captain standing before his airship, male, handsome, red hair, long hair, soft hair, fantasy, intricate, elegant, highly detailed, steampunk, digital painting, artstation, concept art, character art, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Cozy anime portrait, sad schoolgirls.",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai. beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. beautiful and cutest sad face. dramatic light, trending on artstation, oil painting brush"
    },
    {
        "display": "Aasimar Paladin Portrait",
        "full": "an epic fantastic realism comic book style portrait painting of an aasimar paladin, male, shaggy silver hair, scruffy brown beard, d & d concept art, unreal 5, daz, petrol aesthetic, octane render, centered, cosplay, rpg portrait, dynamic lighting"
    },
    {
        "display": "Fantasy portrait of a girl.",
        "full": "a beautiful girl with red makeup in the eyes, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Kawaii rainbow-haired woman",
        "full": "a kawaii woman with rainbow hair smiling, tanktop and jeans, In style of by Jordan Grimmer and greg rutkowski, concept art, highly detailed"
    },
    {
        "display": "Translucent wax Medusa art.",
        "full": "medusa made of soft translucent wax, wooden art nouveau swirls, strong subsurface scattering, colored smoke, gold cables, subsurface scattering, in the style of ruan jia and beeple and giger, subsurface scattering, mystical colors, back light, rim light, dramatic lighting, 8 k, stunning scene, raytracing, octane render, trending on artstation"
    },
    {
        "display": "Surreal hyperrealistic concept art",
        "full": "lots of weirdness and repetition because the model isn't built to work at this size, high detail, vibrant colors, concept art, surrealism, hyperrealism, ultrarealism, gigarealism, 8 k, octane render, unreal engine, digital painting, deviant art, by artgerm, greg rutkowski, pascal blanche, kait kybar, trending on artstation"
    },
    {
        "display": "Rustic fantasy art.",
        "full": "from rust we are created and to rust we shall return,, beautiful high quality realistic fantasy art, trending on artstation by artgerm"
    },
    {
        "display": "Cyberpunk girl reading.",
        "full": "symmetry!! centered head on closeup portrait of a girl reading multiple books, sci - fi -, cyberpunk, blade runner, glowing lights, tech, biotech, techwear!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ice skull artwork.",
        "full": "ice skull by beeple, octane render, trending on artstation"
    },
    {
        "display": "Witch in dark forest.",
        "full": "Rafael Albuquerque comic cover art, artgerm, Joshua Middleton, pretty Stella Maeve witch doing black magic, serious look, purple dress, symmetrical eyes, symmetrical face, long black hair, full body, dark forest, cool colors"
    },
    {
        "display": "Handsome Fubuki in swimsuit",
        "full": "anime portrait of fubuki from one punch man as a handsome woman, wearing sunglasses and two - piece swimsuit, yusuke murata, ilya kuvshinov, anime, pixiv top monthly, trending on artstation, cinematic, danbooru, zerochan art, kyoto animation"
    },
    {
        "display": "Adorable Xanathar creature illustration.",
        "full": "a xanathar dnd creature, adorable and whimsical, fantasy, elegant, digital painting, artstation, concept art, matte, sharp focus, unreal engine 5, marmoset toolbag, redshift, vibrant colors, high contrast, illustration, art by dave kendall"
    },
    {
        "display": "Hierophant in cathedral.",
        "full": "the hierophant and his congregation inside a cathedral. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Cute female lynx fursona.",
        "full": "beautiful portrait commission of a cute female furry anthro Canadian Lynx fursona casual clothes in a futuristic mechanical laboratory. blonde hair. Green Eyes. character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Adorable glowing tiger",
        "full": "adorable glowing tiger, trending on art station, cute, big eyes, matte painting, concept art, pixar, disney, highly detailed, cinematic composition, unreal engine, sharp focus, realistic"
    },
    {
        "display": "Asuka Soryu portrait, hyperrealistic",
        "full": "portrait of Asuka Soryu with red long wavy hair drawn by Donato Giancola and Tom Bagshaw and Julie Bell, face by Artgerm, overall design by Alphonse Mucha, background by James Jean and Gustav Klimt, 4k, porcelain skin, komorebi, french nouveau, trending on artstation, octane render, hyperrealistic"
    },
    {
        "display": "Underwater fashion catwalk.",
        "full": "A fashion Catwalk in an underwater glass tunnel, Fashion Photography, Concept Art, Octane, Redshift, High Detail"
    },
    {
        "display": "Teen boy with cat.",
        "full": "teen boy with brown hair and big blue eyes, wearing a hoodie, holding a fluffy white persian cat, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm, breath of the wild"
    },
    {
        "display": "Anime Hatsune Miku Art",
        "full": "Anime art of beautiful Hatsune miku with beautifel legs by artgerm, rossdraws, magali villeneuve, Gil Elvgren, Earl Moran, Art Frahm, Enoch Bolles, symmetrical shoulders, evangelion style eyes"
    },
    {
        "display": "Ginger woman in swimsuit.",
        "full": "beautiful full body portrait of a ginger woman wearing a sparkling cherry color one piece swimsuit, rainbow hair, pixie haircut wlop, artgerm, artstation"
    },
    {
        "display": "Fire goddess portrait",
        "full": "a beautiful portrait of a fire goddess, flaming background, a detailed painting by greg rutkowski and raymond swanland, featured on cgsociety, fantasy art, detailed painting, artstation hd, photorealistic"
    },
    {
        "display": "Lofi Steampunk Portrait",
        "full": "Lofi Steampunk Bioshock front portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Psychohistory concept art",
        "full": "concept art of the psychohistory, jama jurabaev, greig fraser, roger deakins, cinematic shot, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "College girls in ecstasy",
        "full": "megan fox and arianna grande and selena gomez as three college girls rolling hard on ecstasy, drenched in sweat and covered in florescent paint, kissing sensually with dilated pupils, realistic portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha and boris vallejo and frank frazetta"
    },
    {
        "display": "Realistic portrait of Dr. Venture",
        "full": "A middle-aged Dr. Venture in real life with a hooked nose, a long gaunt face and skinny body and neck, very thin and bald, realistic, very realistic, hyperrealistic, highly detailed, very detailed, extremely detailed, detailed, digital art, oil painting, trending on artstation, headshot and bodyshot, detailed face, very detailed face, extremely detailed face, HD Quality, 8k resolution, very very detailed face, real life"
    },
    {
        "display": "\"Epic princess with mask\"",
        "full": "close-up macro portrait of the face of a beautiful princess with ram goat satan mask, epic angle and pose, ribcage skeleton symmetrical artwork, 3d with depth of field, blurred background, cybernetic jellyfish female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. y Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Symmetrical giant mechanical monster.",
        "full": "professional concept art of a symmetrical! ominous floating mechanical steel terrifying giant monster thing in a dark room by artgerm and greg rutkowski. an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp centred focus, illustration, in the style of cam sykes, wayne barlowe, igor kieryluk."
    },
    {
        "display": "Anime Wonder Woman illustration",
        "full": "anime key visual of a beautiful female new 5 2 wonder woman! intricate, suit, powers, speed, lasso, goddess, dc comics, cinematic, stunning, highly detailed, digital painting, artstation, smooth, hard focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic portrait of Emma Thorpe.",
        "full": "3 / 4 portrait, emma thorpe, crown, futuristic clothing, brunette, id magazine, hyperrealism, detailed textures, photorealistic, 3 d cyberpunk apocalyptic city, ultra realistic, cinematic, intricate, cinematic light, unreal engine 8 k, octane render, unreal engine, david kostic, artgerm"
    },
    {
        "display": "Cyberpunk male portrait",
        "full": "portrait shot of a cyberpunk male in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Fantasy goddess portrait",
        "full": "goddess of nature, unusual beauty, emotionally evoking symbolic metaphors, head in focus, fantasy, ornamental, intricate, elegant, sensual, highly detailed digital painting, artstation, concept art, painterly, golden ratio, sharp focus, illustration, art by John William Godward and Alphonse Mucha and Zdzis\u0142aw Beksi\u0144ski,"
    },
    {
        "display": "Muscular turtle warrior painting",
        "full": "A 70s style Dungeons & Dragons oil painting, extremely detailed, of a muscular turtle with an axe in a cursed ruined temple by zdzis\u0142aw beksi\u0144ski and Igor Kieryluk, trending on artstation"
    },
    {
        "display": "Cinematic female sand goddess.",
        "full": "a beautiful cinematic female sand goddess, glow golden tatto, galatic shamen with Quantum energy fantasy, fantasy magic, undercut hairstyle, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Seductive sorceress in blue.",
        "full": "jaime pressly as a sorceress, passionate, seductive, sweaty, intricate dressed in ornate blue robes and staff, lovely, intricate, highly detailed, digital painting, artstation, concept art, karma sutra, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Margot Robbie in fantasy bikini.",
        "full": "margot robbie portrait posing in a tassel bikini in front of hogwarts, tattooed body, highly detailed and realistic face, beautiful detailed eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Elegant Art Deco design",
        "full": "art deco patterns, blue and gold, 8 k, powerfull, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry, beksinski"
    },
    {
        "display": "Intricate medieval library interior",
        "full": "A big medieval library viewed from the inside, texture, intricate, details, highly detailed, masterpiece, architecture, building, trending on artstation, focus, sharp focus, concept art, digital painting, fantasy, sunny, day, midday, in the style of Pixar"
    },
    {
        "display": "Stylish Assamese fashion portrait.",
        "full": "a beautiful cute girl wearing modern stylish costume in the style of Assamese bihu mekhela sador gamosa design, commercial fashion designart by Victor Nizovtsev, face by artgerm and daz3d genesis iray, cinematic lightning, medium shot, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinema 4d ray traced, octane render, cinematic wallpaper"
    },
    {
        "display": "Shiba Inu and Kitsune",
        "full": "a high detail shiba inu dog with kitsune fox by Tomas Sanchez and Simon St\u00ef\u00bf\u00bdlenhag and krenz cushart and ilya kuvshinov. pixiv fanbox skeb.jp clipstudio medibang ichi-up CGWORLD key visual manga cover"
    },
    {
        "display": "Ninja robot cosplay illustration",
        "full": "mask helmet ninja metalgear solidsnake robot pose suit swat heros illustration character design concept the phantom pain cosplay sniper wolf by artist dayer diego and kojima and aramaki shinji by konami red and blue rim light, unreal 5, daz, hyperrealistic, corona render, global illumination ray tracing hdr, art gta 5 cover, official fanart behance hd artstation by jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, ossdraws"
    },
    {
        "display": "Steampunk robotic Faberg\u00e9 eggs.",
        "full": "seamless pattern of steampunk cybernetic biomechanical robotic faberge eggs arranged in a damask pattern, symmetrical, 3 d model, very coherent symmetrical artwork, unreal engine realistic render, 8 k, micro detail, white ceramic material and clear glass with gold accents, iridescent black background, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, wlop"
    },
    {
        "display": "Elegant female android portrait",
        "full": "portrait of female android, symmetry, intricate, elegant, highly detailed, smooth, sharp focus, concept art, digital painting, illustration, artstation, by fra angelico, sandra chevrier and greg ruthkowski"
    },
    {
        "display": "Ultra-realistic woman with whip.",
        "full": "ultra realistic, Beautiful black haired woman, Porcelain white complexion, big blue eyes, cute small lips., wearing jeans and white blouse, whip in hand, intricate details, eerie, highly detailed, octane render, 8k, art by artgerm and alphonse mucha and greg rutkowski"
    },
    {
        "display": "Cybernetic glasses fantasy portrait",
        "full": "a beautiful woman wearing transparent cybernetic glasses, painted by artgerm and tom bagshaw, fantasy art, dramatic lighting, highly detailed oil painting"
    },
    {
        "display": "Minas Morgul landscape painting.",
        "full": "minas morgul, beautiful scenic landscape, lord of the rings, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Satanic sorcerer with dragon",
        "full": "a beautiful satanic black metal album cover of a satanic sorcerer taming a dragon, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Muscular male D&D fighter.",
        "full": "male fighter, muscular upper body, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Arachnoid priestess portrait.",
        "full": "portrait of a beautiful female arachnoid priestess in ornate robe and long white hair. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose, vibrant colours."
    },
    {
        "display": "Spider-Man vs. Batman Art",
        "full": "spider man destroys batman dreamlike with jewelry, character art, hyperdetailed, 8 k realistic, frostbite 3 engine, cryengine, dof, trending on artstation, digital art"
    },
    {
        "display": "Elvira Librarian Portrait",
        "full": "portrait of elvira as a librarian by yusuke murata, makoto shinkai, ross tran, cosmic, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Earth on Moon's Horizon",
        "full": "the planet earth in the moon's horizon. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Cyberpunk robot girl portrait.",
        "full": "portrait of beautiful girl with robot body by artgerm and greg rutkowski and alphonse mucha, close up, portrait, cinematic, elegant, artstation, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cyberpunk, cgsociety, 8 k"
    },
    {
        "display": "Subway scene with expressive hands",
        "full": "subway hands, art by greg rutkowski, gustave courbet, rosa bonheur, edward hopper. faithfully depicted facial expression, perfect anatomy, sharp focus, global illumination, radiant light, detailed and intricate environment, trending on artstation"
    },
    {
        "display": "Revolutionary on bloody throne",
        "full": "french revolutionary sitting on a bloody throne, cameltoe, in the style of artgerm and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Detailed sci-fi portrait.",
        "full": "Portrait of a man by Greg Rutkowski, he is about 30 years old, mixture between persian, indian and texan, wide forehead, short black hair, manly, attractive, strong and burly, he is wearing a utilitarian beige and black jumpsuit, highly detailed portrait, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ"
    },
    {
        "display": "Kawaii goddess in metaverse",
        "full": "deeper into the metaverse we go, piles of modular synth cables mixed with roots, kawaii puerto rican goddess swimming up wearing a headpiece made of circuit boards, by cameron gray, wlop, stanley kubrick, masamune, hideki anno, jamie hewlett, unique perspective, trending on artstation, 3 d render, vivid"
    },
    {
        "display": "Victorian sci-fi portrait.",
        "full": "a burly brawny victorian man, chiaroscuro, sci fi character portrait by greg rutkowski, craig mullins"
    },
    {
        "display": "Darth Vader smoking crack.",
        "full": "darth vader smoking crack cocaine by mcfarlane, alphonse mucha, artgerm and greg rutkowski and magali villeneuve. acrylic painting."
    },
    {
        "display": "Blonde female sailor illustration",
        "full": "female sailor, full body shot, eyepatch, blonde, d & d, fantasy, intricate, intimidating, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic bulls in pink landscape",
        "full": "epic scene, concept art by Maciej Kuciara, highly detailed, two black bull stay face to face on pink a clearing with pink grass and a river long-range plan with cinematic luma"
    },
    {
        "display": "Amber glowing tiger artwork",
        "full": "an amber glowing tiger. intricate artwork by tooth wu wlop beeple dan mumford concept art, octane render, trending on art station"
    },
    {
        "display": "Cinematic D&D monster portrait",
        "full": "cinematic portrait of dungeons and dragons-style MONSTER, Tim Hildebrandt, Wayne Barlowe, Bruce Pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper-detailed"
    },
    {
        "display": "Trump-faced Egyptian goddess portrait",
        "full": "highly detailed portrait of an african egyptian goddess with the face of donald trump, intricate alien technology, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Anime-style realistic puppy.",
        "full": "a space realistic puppy dog with cute eyes, very anime, realistic shaded, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost in the shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Whale death in city square.",
        "full": "a whale dies in the middle of the square, gray sky, crowd of onlookers, fence, fountain, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, 8 k, no blur, hyper realistic, magic world"
    },
    {
        "display": "Aggressive muscular Leonardo portrait",
        "full": "portrait of aggressive leonardo from teenage mutant ninja turtle, d & d, muscular! turtle shell in the back!, fantasy, intricate, elegant, highly detailed, green skin!, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pikachu-inspired fish concept art",
        "full": "concept art of pikachu like fish, highly detailed painting by dustin nguyen, akihiko yoshida, greg tocchini, greg rutkowski, cliff chiang, 4 k resolution, trending on artstation, 8 k"
    },
    {
        "display": "Bald hacker at computer.",
        "full": "a bald hacker with a moustache and glasses at the computer. fantasy art by greg rutkowski, gustave courbet, rosa bonheur, edward hopper. faithfully depicted facial expression, perfect anatomy, sharp focus, global illumination, radiant light, detailed and intricate environment, trending on artstation"
    },
    {
        "display": "Elegant triangular monster",
        "full": "anthropomorphic triangle head in opal darkiron terror bird, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Anime female Green Lantern",
        "full": "anime key visual of a beautiful female green lantern! intricate, suit, powers, speed, goddess, dc comics, cinematic, stunning, highly detailed, digital painting, artstation, smooth, hard focus, illustration, character concepts by senior concept artist art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Peter Dinklage as Indiana Jones",
        "full": "peter dinklage as indiana jones, digital painting, extremely detailed, 4 k, intricate, brush strokes, mark arian, artgerm, bastien lecouffe - deharme"
    },
    {
        "display": "Asura mecha ghost artwork",
        "full": "asura from chinese myth, ghost, mecha, screaming with fear, artwork by artgerm, centered shot, wide angle, full body, elfpunk, artwork by naoto hattori, landscape art by tyler edlin"
    },
    {
        "display": "Mysterious magician portrait.",
        "full": "half portait of magican wearing a closed cowl and big old book! chained to the wrist, jeremy mann, jean - leon gerome, tiepolo, alphonse mucha, greg rutkowski, face in the shadows, ( ( ruins of ancient rome ) ), at dusk, mysterious atmosphere, sunrays, dof, high detailed, 8 k"
    },
    {
        "display": "Seraphine portrait, Greg Rutkowski",
        "full": "seraphine portrait trending on artstation, painted by greg rutkowski"
    },
    {
        "display": "Elegant Venom Portrait",
        "full": "portrait of apex legends venom, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, artey freytag"
    },
    {
        "display": "Astronaut on beach at sunrise",
        "full": "a astronaut sitting on the beach looking to the sea at sunrise, concept art, octane render, unreal engine 5, trending on Artstation, high quality, highly detailed, 8K, soft lighting, godrays, path tracing, serene landscape, turbulent sea, high coherence, anatomically correct, hyperrealistic, sand, beautiful landscape, cinematic, clouds in the sky"
    },
    {
        "display": "Epic AI Artist Banner",
        "full": "stunning banner of conceptional ai artist by concept art, awarded, sci - fi, wisdom, epic, ultimate, magical effects, w shape, sharp focus, soft lighting, centered, transparent digital background, 8 k hd resolution, high quality image - h 2 0 0"
    },
    {
        "display": "Sansa Stark in foggy ruins",
        "full": "portrait of sansa stark in foggy ruins of castle, autumn, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Hyper-realistic Luigi caricature.",
        "full": "hyper realistic, realistic - anime, portrait, beautifully rendered, italian garb the future, scifi, caricature, luis guzman as luigi wearing green, smirking deviously, luigi, luigi's nose, painted by frida kahlo, andy, warhol, vincent van gogh, jan van eyck, albrecht durer, gustave courbet, greg rutkowski, wlop, artgerm, dishonored 2,"
    },
    {
        "display": "Futuristic anthropomorphic mecha art.",
        "full": "anthropomorphic mecha, futurism, cyberpunk, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Futuristic elven courtyard.",
        "full": "futuristic elven courtyard, with technology digital painting by greg rutkowski and james gurney, global illumination, trending on artstation, highly detailed"
    },
    {
        "display": "Anthropomorphic triangle brain demon",
        "full": "3 / 4 view portrait of anthropomorphic triangle brain in edgy darkshadowiron pike demon, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, dwayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Expressive Agent 47 Portrait",
        "full": "an expressive portrait of agent 4 7 from hitman wearing headphones standing on a floor of vinyl records, dark background, red rim light, digital art, artstation, concept art by giger stalenhag"
    },
    {
        "display": "Van Gogh-inspired backpack concept.",
        "full": "a backpack designed by van gogh, white background, concept art"
    },
    {
        "display": "Gruff ranger portrait with wolf dog.",
        "full": "portrait of a gruff ranger holding a spear, accompanied by a wolf dog, muscular, upper body, hairy body, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Nubian Geisha Ballerina Illustration",
        "full": "ultra realistic illustration, smiling nubian geisha prima ballerina, sci - fi, fantasy, symmetrical face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and alphonse mucha"
    },
    {
        "display": "Fairy prince casting lightning.",
        "full": "legendary fairy prince casting a lightning spell, blue energy, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap"
    },
    {
        "display": "Steampunk detective girl",
        "full": "a steampunk detective girl sitting in her office reading a newspaper | | cute - fine - face, pretty face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Cyberpunk female portrait.",
        "full": "an european female humanoid with freckle cheeks, braided hairstyle, cyber neon lighting, futurism, cyberpunk high fashion, glamor profile pose, hyper photorealistic, intricate futuristic jewelry details, crispy quality, digital photography, trending in artstation, trending in pinterest, cinematic, 4 k ultra hd, art by pascal blanche, art by artgerm, art by greg rutkowski,"
    },
    {
        "display": "Realistic elf biker art",
        "full": "painting of elf biker badass, perfect face, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Makise Kurisu portrait, anime style",
        "full": "Makise Kurisu, painting in style of krenz cushart ilya kuvshinov KyoAni, Makoto Shinkai,Hideaki Anno,Mochiduki Key ,Studio Ghibli, Madhouse, WHITE FOX, Studio Trigger,GAINAX ,K-ON,yuruyuri, cygames,Production I.G ,wit studio,P.A.WORKS,ufotable,mappa,TOEI Animation,trending on artstation pixiv makoto shinkai smiling super detailed eyes symmetrical face visual novel"
    },
    {
        "display": "Fantasy noble girl portrait",
        "full": "baroque oil painting character light novel illustration lotr anime key visual regency portrait | young fantasy noble girl fitted black military uniform | long flowing blonde hair blue eyes | symmetrical perfect face fine detail delicate features quiet gaze | gapmoe kuudere moody lighting bokeh | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Epic forest council illustration",
        "full": "the forest council, gods, environment, illustration, symmetrical, smoky, unreal engine, colors, epic scene, fantasy art by greg rutkowski, octane redner, golden raito, high quality, intricate details, highly details, intricate, atmosphere, highly detailed, matte painting, cinematic, deviantart, realistic, concept art, 4 k"
    },
    {
        "display": "Dark-skinned girl with Godzilla",
        "full": "Dark skinned girl and Godzilla, Studio Ghibli concept art"
    },
    {
        "display": "Luffy in elegant suit",
        "full": "Luffy from One Piece wearing a suit, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4k, HDR, concept art, smooth, sharp focus, illustration, art by artgerm and H R Giger and alphonse mucha"
    },
    {
        "display": "Steampunk robot ninja helmet.",
        "full": "steampunk helmet fantasy art mask robot ninja stylized digital illustration sharp focus, elegant intricate digital painting artstation concept art global illumination ray tracing advanced technology chaykin howard and campionpascale and cooke darwyn and davis jack"
    },
    {
        "display": "Retro futuristic delivery truck",
        "full": "retro futuristic fedex delivery truck, artstation, cgsociety concept art"
    },
    {
        "display": "Armored Batmobile concept art",
        "full": "a single armored batmobile in the style of bladerunner and alternate car one, car concept, car Design, sid mead, alex ross, intricate Details, concept art, matte painting, highly detailed, rule of thirds, dynamic lighting, cinematic, detailed, denoised, centerd, clean render"
    },
    {
        "display": "1970s portrait of Jerma985",
        "full": "A photograph portrait of Jerma985 with short-medium length hair a combover wearing early 1970s menswear in the early 1970s, taken in the early 1970s, grainy, taken on a 1970s Kodak Camera, realistic, hyperrealistic, very realistic, highly detailed, very detailed, extremely detailed, detailed, digital art, trending on artstation, colorized photo"
    },
    {
        "display": "Alligator character concept art",
        "full": "sea of thieves animal character concept art for an alligator, cgsociety, trending on artstation, rare ltd,"
    },
    {
        "display": "Orange chipmunk soldier",
        "full": "orange badass chipmunk in world war 3, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant woman with embers.",
        "full": "a gorgeous female with long brown hair, photo by tim walker, realistic, full body shot, wide angle, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, floating embers"
    },
    {
        "display": "Corey Taylor portrait with Chinese motifs",
        "full": "portrait of corey taylor, upper half portrait, decorated with chinese opera motifs, asian, bian lian, traditional chinese art, intricate, elegant, highly detailed, symmetry, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Elon Musk as Rick Sanchez",
        "full": "elon musk as rick sanzhez, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Aerial view of a flying castle.",
        "full": "flying castle location. game illustration, gamedev, game, design, mobile, aerial view, isometric, blizzard, playrix, nexters, intricate, elegant, pixel perfect, fantasy, highly detailed, digital painting, trending on artstation, sharp focus, by irina knk, by ann bruhanova, by zze festa, by tatiana gromova, 4 k"
    },
    {
        "display": "Fantasy portrait of blonde woman",
        "full": "'' Portrait of Beautiful blonde Slavic woman, league of legends, LOL, fantasy, d&d,  digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha ''"
    },
    {
        "display": "Kate Upton as Bowsette.",
        "full": "kate upton as bowsette, fantasy, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm & JeeHyung lee & WLOP"
    },
    {
        "display": "Adorable glowing creature",
        "full": "adorable glowing creature, trending on artstation, cute, big eyes, concept art, pixar, disney, highly detailed, cinematic composition, unreal engine, 3 d rendered in octane, fantasy planet"
    },
    {
        "display": "Blue-haired girl under cherry blossoms",
        "full": "hd photograph of a pretty girl with blue hair, wearing a tank top and shorts, relaxing under a cherry blossom tree, summer day, in the style of ilya kuvshinov, dramatic lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, bokeh, hdr, high resolution, artstation, concept art, smooth, sharp focus, art by krenz cushart and albert aublet"
    },
    {
        "display": "Kubernetes cluster illustration",
        "full": "Kubernetes cluster deployed to a secure public cloud service, trending on Artstation, by  j Scott Campbell"
    },
    {
        "display": "Vintage traveler by coach.",
        "full": "a man in old fashioned clothes waits by the side of the road with his suitcase, looking at a coach with 4 horses is in front of him, night time in the mountains highly detailed in the style of edward gorey, artgerm, 8 k resulution - c 5"
    },
    {
        "display": "Futuristic anime character.",
        "full": "Kiyoko Suzuki, futuristic hairstyle, full body, medium shot, female anime character, from an otherworldy unknown undiscovered earth, shot from the ground by Yoshiyuki Sadamoto, otherwordly concept, digital art, trending on artstation, high level, 4K UHD image, octane render, Howl's Moving Castle, tranquil divine observer Nymph by ismail inceoglu nicolas, takashi murakami, samori dragan bibin hans thoma greg rutkowski Alexandros Pyromallis Nekro Rene Margitte illustrated, official anime key media, robotic feeling"
    },
    {
        "display": "Metallic chaos and boots.",
        "full": "awoke to the violent crunch of metal, sweet smells of boots, by wlop, artgerm, greg rutkowski"
    },
    {
        "display": "Massive battleship over Vancouver",
        "full": "massive space battleship hovering over downtown vancouver by eugene von guerard, ivan shishkin, dramatic lighting, concept art, trending on artstation, 8 k"
    },
    {
        "display": "Bob Ross Funko Pop 3D",
        "full": "full body 3d render of Bob Ross as a funko pop, studio lighting, white background, blender, trending on artstation, 8k, highly detailed"
    },
    {
        "display": "Cyberpunk city at night",
        "full": "rainy night in a cyberpunk city, neon lights, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Cute rat professor lecture",
        "full": "portrait of a cute pet rat dressed as an english professor, smoking a pipe, giving a lecture in a university chemistry lab, digital art, artstation, fantasy, cinematic, fine details by realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Fantasy city on Yggdrasil",
        "full": "a fantasy city built upon the giant branches of yggdrasil, illustration, bright sunlight, sun glints, sunrays, digital art, hyperrealistic, oil painting, fantasy, 8 k, trending on artstation, detailed"
    },
    {
        "display": "Metallic cube, dramatic lighting",
        "full": "a metallic cube in black background, by Dean Ellis, by Vincent Di Fate, by Bruce Pennington, by David A. Hardy, by Wayne Barlowe, masterpiece, oil on canvas, trending on artstation, featured on pixiv, cinematic composition, dramatic scene, beautiful lighting, sharp, high details, no frames, 8K"
    },
    {
        "display": "Gigachad Luigi vs. Mech",
        "full": "gigachad luigi charging ultimate attack like one punch man wearing a suit in the city fighting a scifi mech, fantasy character portrait, ultra realistic, anime key visual, concept art, intricate details, highly detailed by greg rutkowski, ilya kuvshinov, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Trump in jail artwork",
        "full": "trump in jail, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Bored lawyer portrait, 3D.",
        "full": "portrait of a mega pretty woman who is a lawyer staring boredly at you by Katsuhiro Otomo, Yoshitaka Amano, Nico Tanigawa, and Artgerm rendered with 3D effect."
    },
    {
        "display": "Dystopian cityscape with tornado.",
        "full": "damaged city, high - tech, concept art, forest, tornado, war, evil"
    },
    {
        "display": "Blue alien girl concept.",
        "full": "blue amphibian alien girl, concept art style, full body,"
    },
    {
        "display": "Mind-blown networking illustration",
        "full": "illustrative image of people gets mind blown, networking, detailed illustration, octane render : : william morris style, trending on artstation"
    },
    {
        "display": "Seductive fashion portrait.",
        "full": "coco rocha, passionate, seductive, expressive, charismatic, very sweaty, intricate fashion clothing, insane, intricate, highly detailed, digital painting, artstation, concept art, surrealistic, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Alien woman in armor",
        "full": "a beautiful alien woman, in xenomorph armor by hideyuki ashizawa, trending on artstation, industry, lucid and intricate, rectilinear, digital art, octane, redshift, vray, 8 k, 6 4 megapixels, zbrush central, behance hd, hypermaximalist, well rendered"
    },
    {
        "display": "Psychedelic face with honey wings.",
        "full": "psychedelic transcendent face mind bending psychedelic wings of glossy liquid honey flowing like kaleidoscopic translucent amber, lsd feathers, honey wind, enlightenment, high contrast dappled lighting, refracted sunset, highly detailed, concept art, art by collier, albert aublet, krenz cushart, artem demura, alphonse mucha"
    },
    {
        "display": "Vampire Red Riding Hood Portrait",
        "full": "vampire red riding hood, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Bronze dragon barbarian artwork",
        "full": "\u201c bronze humanoid dragon, barbarian, greataxe, dramatic pose, d & d, intricate, beautiful, highly detailed, elegant, artstation, concept art, matte, smooth and sharp focus, rpg artwork, illustration, painting by ilya kuvshinov, alphonse mucha, gaston bussiere, craig mullins, j. c. leyendecker, rene maritte, tian zi, wlop, alsphonse mucha, artgerm, pino daeni, dan mumford \u201d"
    },
    {
        "display": "Anime character in Nazi uniform",
        "full": "baroque oil painting anime character concept art, key visual full body portrait of young nazi maid secret police with long flowing blonde hair and blue eyes wearing black fitted nazi ss leather uniform, gap moe kuudere noble grimdark dictator, trending pixiv fanbox, rule of thirds golden ratio, illustrated by greg rutkowski makoto shinkai studio ghibli wlop"
    },
    {
        "display": "Cyborg women portrait.",
        "full": "portrait of two women cyborg with metal unicorn by gaston bussiere, anna nikonova aka newmilky, greg rutkowski, yoji shinkawa, yoshitaka amano, tsutomu niehi, moebius, donato giancola, geoffroy thoorens, concept art, trending on artstation, featured on pixiv, cinematic composition, 8 k"
    },
    {
        "display": "Cyberpunk Navy Seal portrait",
        "full": "a cyberpunk portrait of a navy seal by jean - michel basquiat, by hayao miyazaki by artgerm, highly detailed, sacred geometry, mathematics, snake, geometry, cyberpunk, vibrant, water"
    },
    {
        "display": "VFX portrait of Buggy the Clown",
        "full": "highly detailed vfx portrait of buggy the clown by eiichiro oda, makoto shinkai, alphonse mucha, sharp focus, art by artgerm and greg rutkowski!, backlit, harsh overhead sunlight, blue eyes, stanley kybric, makoto yukimura, takeshi obata, kaoru mori, pixiv, fanbox,"
    },
    {
        "display": "Cyberpunk portrait with graffiti.",
        "full": "Highly detailed portrait of a cyberpunk young lady with, freckles and wavy hair by Atey Ghailan, by Loish, by Bryan Lee O'Malley, by Cliff Chiang, by Greg Rutkowski, inspired by image comics, inspired by nier!! Gradient color scheme  ((grafitti tag brick wall background)), trending on artstation"
    },
    {
        "display": "Tattooed Margot Robbie in Atlantis",
        "full": "tattooed margot robbie portrait as a character posing in a crochet bikini in front of atlantis, highly detailed realistic face, highly detailed eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, margot robbie face"
    },
    {
        "display": "Albino lion portrait, worried.",
        "full": "a beautfiul aesthetic commission portrait of a anthro albino lion looking at the sky worried,attractive beautiful face,detailes face,expression,natural lighting,fantasy art,deviantart,artstation,character design by charles bowater,ross tran,artgerm,4k,photorealistic"
    },
    {
        "display": "Symmetrical sci-fi portrait.",
        "full": "symmetry!! portrait of kevin from the office, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk female portrait",
        "full": "a portrait of a beautiful 28th century cyberpunk female very young with long hair, largely biomechanical, hyper-realistic, very detailed unreal engine, by Artgerm, WLOP and Ross Thran, dramatic cinematic lighting rendered by octane, 8k, detailed, trending on artstation, deviantart, google images, pinterest"
    },
    {
        "display": "Biopunk megastructure cityscape",
        "full": "biopunk, tsutomu nihei, blame!, concept art, city, megastructure"
    },
    {
        "display": "Sci-Fi Emperor Concept Art",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, scifi emperor"
    },
    {
        "display": "Ice and Earth Goddess Portrait",
        "full": "portrait of a goddess of ice and earth, half body, perfect face, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bearded man zipping dress.",
        "full": "bearded young man wearing orange t - shirt and tinfoil hat fastens zipper on beautiful black dress of his spouse art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Elegant pilot in spaceship",
        "full": "pilot floating in spaceship, fullbody, fantasy, intricate, elegant, highly detailed, digital painting, artstation, longspear, concept art, smooth, sharp focus, illustration, art by greg rutkowski, art by seb mckinnon, art by justin gerard"
    },
    {
        "display": "Destiny guardian abstract artwork.",
        "full": "destiny guardian posing. intricate abstract. intricate artwork. by tooth wu, wlop, beeple, dan mumford. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8 k, iridescent accents."
    },
    {
        "display": "Moon Knight T800 Fusion",
        "full": "Moon Knight mixed with T800 endoskeleton, RPG Reference,  art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski, Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Beautiful vampire portrait",
        "full": "a portrait of a beautiful vampire with fangs, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm, digital art, highly detailed, intricate, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Fluffy orange cat with medicine.",
        "full": "a fluffy cat standing next to a bottle of medicine in a room with a yellow background color. orange cat. the cat was in a room with yellow background color. animal. digital art. artstation. realistic. vibrant. illustration. in the style of pixar movie. octane render. art by artgerm and greg rutkowski and alphonse mucha. volumetric lighting."
    },
    {
        "display": "Futuristic Shanghai metropolis illustration",
        "full": "shanghai metropolis, epic scene, futuristic style, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, arcane style, aurora borealis, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Kardashians Rococo Painting",
        "full": "The Kardashians looking for their credentials, rococo painting, smooth, sharp focus, artstation, pixiv,"
    },
    {
        "display": "Edgy anthropomorphic triangle monster.",
        "full": "anthropomorphic triangle head in edgy darkiron grandpa, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Demonic portrait in Pixar style.",
        "full": "doom demon giger portrait, fire and flame, teeth, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Venom battling T-Rex",
        "full": "venom fighting a t - rex, trending on artstation, 4 k, 8 k"
    },
    {
        "display": "Dinosaur Battle Cinematic Poster",
        "full": "cinematic poster of t rex fighting a spinosaurus, dark fantasy fetures, desaturated, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper - detailed, hd, 4 k"
    },
    {
        "display": "Cassandra Cain in Hunter Armor",
        "full": "cassandra cain in destiny hunter armor, wearing a hooded cloak, beautiful face!!!!, red hair, 2 7 years old, cg animation, riot entertainment, arcane, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Realistic portrait of Harrison.",
        "full": "portrait painting of william henry harrison drinking a beer, ultra realistic, concept art, intricate details, serious, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gothic girl artwork",
        "full": "goth little girl, artwork by greg rutkowski and hiroriko araki"
    },
    {
        "display": "Divine multi-eyed portrait",
        "full": "portrait of the face of a god with a hundred eyes, dungeons and dragons, trending on artstation, by leonardo da vinci, raphael and richard corben award winning art, stylized painting"
    },
    {
        "display": "Symmetrical dark fashion portrait.",
        "full": "symmetry!! portrait of elle fanning in the world of yoji shinkawa, horror, fashion, dark!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk man disposing trash",
        "full": "man taking out trash near the crowded street of a cyberpunk city, rain, harsh neon lights, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Bob Ross as Iron Man",
        "full": "a closeup photorealistic photograph of bob ross working on a canvas painting of an iron man style bob ross. lit studio, mountain scape. film still, vibrant colors. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Whimsical fantasy forest house",
        "full": "a beautiful digital illustration painting of a fantasy little house in the night forest whimsical benoit b. mandelbrot, steven belledin, martin johnson heade, lee madgwick, caspar david friedrich, and david rios ferreira. 8 k resolution trending on artstation concept art digital illustration"
    },
    {
        "display": "Viking queen in armor.",
        "full": "photo of a gorgeous viking queen in armor in the style of stefan kostic, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Handsome man-bear portrait.",
        "full": "portrait of a man - bear, male, handsome, masculine, full body, red hair, long hair, soft hair, fantasy, intricate, elegant, highly detailed, suit, coffee shop, digital painting, artstation, concept art, character art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Heroic warrior Natalie Portman",
        "full": "natalie portman, legendary warrior, heroic, lord of the rings, tattoos, decorative ornaments, battle armor, by carl spitzweg, ismail inceoglu, vdragan bibin, hans thoma, greg rutkowski, alexandros pyromallis, perfect face, fine details, realistic shading photorealism"
    },
    {
        "display": "Surreal detailed raven sculpture",
        "full": "Intricate stunning highly detailed surreal raven by agostino arrivabene and Seb McKinnon, sculpture, ultra realistic, Horror vacui, full moon, thick swirling smoke tornado, fire embers, trending on artstation"
    },
    {
        "display": "Voldemort enjoying a sandwich.",
        "full": "voldemort eating a sandwich, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm, art by greg rutkowski, art by alphonse mucha"
    },
    {
        "display": "Boris Johnson as Superman",
        "full": "Boris Johnson as Man of Steel by Zack Snyder, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg deity with closed eyes",
        "full": "ultra realistic beautiful cyborg deity eyes closed, scifi, cyberpunk, fantasy, intricate details, movie still, highly detailed, photorealistic, octane render, eerie, 8k, art by artgerm and michael welan"
    },
    {
        "display": "Futuristic Yeezy in garden",
        "full": "A futuristic Yeezy shoe in the middle of a garden, vintage camera, dreamy, atmospheric, golden hour, cinematic lighting, 8K concept art, melancholy, detailed"
    },
    {
        "display": "Cyberpunk girl portrait",
        "full": "by kyoto animation, cool girl wearing cyberpunk intricate streetwear, beautiful, detailed symmetrical close up portrait, intricate complexity, in the style of artgerm and ilya kuvshinov, cell shaded, 4 k, concept art, by wlop, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, cinematic lighting, studio quality"
    },
    {
        "display": "Cyberpunk girl on bike",
        "full": "girl wearing cyberpunk intricate streetwear riding bike, respirator, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Mystical garden with palace",
        "full": "Beautiful eden garden, next to a gate of haven and a mystical palace, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Surreal dreamscape painting",
        "full": "a surreal, fantastical dreamscape | | simple, solid shapes, dramatic lighting, children's book, hdr, high contrast, digital painting by jakub gazmercik, makoto shinkai, max masnyy, jakub gazmercik, beeple, patrick faulwetter, and heavenlydaemonic, surrealism, trending on artstation"
    },
    {
        "display": "Photorealistic Jerusalem at dawn",
        "full": "photorealistic filmic city of Jerusalem at dawn before sunrise by james gurney, unreal engine, assassin's creed 1, 35 mm lens, trending on artstation"
    },
    {
        "display": "Throne room with decapitated king.",
        "full": "throneroom with the corpse of the king laying on the ground, his head cut off, in the style of artgerm, charlie bowater, sam guay, atey ghailan and mike mignola, no people, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Fierce cat in uniform.",
        "full": "a portrait of a fierce cat in a starfleet uniform, half portrait, classic oilpainting, fantasy, sharp focus, intricate, sci - fi, artstation, matte, highly detailed, concept art, illustration, studio lighting, art by ilya kuvshinov, artgerm, alphonse mucha, amano, and karol bak"
    },
    {
        "display": "Anime art of Hatsune Miku",
        "full": "Anime art of beautiful Hatsune miku with beautifel legs by artgerm, rossdraws, magali villeneuve, Gil Elvgren, Earl Moran,Enoch Bolles, symmetrical shoulders, evangelion style eyes, symmetrical hips"
    },
    {
        "display": "Woman selling masks, Rembrandt style",
        "full": "woman selling masks on the streets, intricate, highly detailed, painting by rembrandt, concept art, sharp focus, rembrandt lighting"
    },
    {
        "display": "Realistic Viking portrait",
        "full": "close up portrait painting of a bearded viking man, ultra realistic, concept art, intricate details, serious, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "1960s hippy portrait trio",
        "full": "sophisticated portrait of Christina Hendricks Brie Larson Kat Dennings, 1960s flower power hippy, very smoky Paris bar, elegance, highly detailed, shallow depth of field, Artstation, Artgerm, Donato Giancola and Joseph Christian Leyendecker"
    },
    {
        "display": "Ominous indoor pond architecture.",
        "full": "professional ominous concept art architecture of a dark room with an indoor pond by artgerm and greg rutkowski. an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp focus, illustration, in the style of simon stalenhag, wayne barlowe, and igor kieryluk."
    },
    {
        "display": "Intricate skull portrait",
        "full": "skull, close - up portrait, powerful, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Elegant robot chicken illustration",
        "full": "Robot Chicken , elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Loba anime portrait illustration",
        "full": "Apex Legends Loba anime character digital illustration portrait design by Ross Tran, artgerm detailed, soft lighting"
    },
    {
        "display": "Cartoonish Nick Cave dinner scene",
        "full": "cartoonish nick cave eating dinner, vivid colors, character sheet, fine details, concept design, contrast, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Trump as Clint Eastwood",
        "full": "a black and white photo of donald trump playing the role of clint eastwood, squinting at high noon, in the style of a clint eastwood movie, the good, the bad and the ugly, abraham lincoln, clint eastwood, steven seagal, vibe, bud spencer, donald trump, glory days, justice, american flag, patriotism, apple pie, black and white, artgerm, trending on artstation"
    },
    {
        "display": "Witch with horns illustration",
        "full": "posessed, sould demons a beautiful illustration of a witch with horns in head, young cindy crowford, redhead, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Ultrarealistic princess portrait.",
        "full": "a masterpiece ultrarealistic ultradetailed portrait of a incredibly beautiful princess holding iphone 1 3 pro. baroque renaissance. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano. background by james jean and gustav klimt, light by julie bell, 4 k, porcelain skin."
    },
    {
        "display": "Cartoonish Van Morrison concept art.",
        "full": "cartoonish van morrison eating dinner, vivid colors, character sheet, fine details, concept design, contrast, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Sweating forehead close-up",
        "full": "closeup of sweating forehead, big drops of sweat, beads of sweat, airbrush painting, forehead only, by Hajime Sorayama, trending on artstation, beautiful lighting, sharp, details, hyper-detailed, HD, HDR, 4K, 8K"
    },
    {
        "display": "Psychedelic portrait of girl.",
        "full": "An extremely psychedelic portrait of the Girl With the Pearl earring, surreal, LSD, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Cyborg mermaid portrait.",
        "full": "cyborg mermaid, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Elven bride character design.",
        "full": "digital art, centered full body elven bride with short hair, vivid flower crown ,intricate, veins, by James Jean and by artgerm, by ross tran , ultradetailed, charachter design, concept art, trending on artstation,"
    },
    {
        "display": "David Spade as Ace of Spades",
        "full": "hyperrealistic dslr film still of david spade disguised as the ace of spades playing card, stunning 8 k octane comprehensive 3 d render, inspired by istvan sandorfi & greg rutkowski & unreal engine, perfect symmetry, dim volumetric cinematic lighting, extremely hyper - detailed, incredibly real lifelike attributes & flesh texture, intricate, masterpiece, artstation, stunning"
    },
    {
        "display": "Symmetrical shooting star painting.",
        "full": "symmetrical - photo!! low - angle!! painting of a shooting star, full frame photograph, zeiss lens, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm"
    },
    {
        "display": "Hyper-detailed Miku Hatsune",
        "full": "miku hatsune posing, extremely detailed eyes, fantastic details full face, close - up shot, full body shot, epic cinematic shot, trending on artstation, pixiv, cgsociety, award winning, hyperdetailed unreal engine 4 k 8 k ultra hd, stanley artgerm lau, wlop, rossdraws, james jean marc simonetti ruan jia and mandy jurgens and artgerm and william - adolphe bouguerea sakimichan"
    },
    {
        "display": "Elegant woman in silk dress",
        "full": "a beautiful woman  wearing a  white  dress made of silk with golden ornaments and diamonds jewelry by alex gray and android jones , Karol Bak, Ayami Kojima, Amano , concept art, character design, fantasy,3D, 8k resolution"
    },
    {
        "display": "Rich Evans with Luger",
        "full": "propaganda poster rich evans holding a luger, 8 k, trending on artstation"
    },
    {
        "display": "Purple crow illustration.",
        "full": "a purple crow by greg rutkowski"
    },
    {
        "display": "Elegant Borg Lady Portrait",
        "full": "portrait of thin blonde middle aged lady borg with glasses, giger,, elegant, real life skin, intricate artwork, high detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski @ ruprechy"
    },
    {
        "display": "Gothic cyberpunk maiden portrait",
        "full": "portrait of beautiful pale gothic maiden, warhammer 40000, cyberpunk, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and Gustav Klimt and Ilya Kuvshinov"
    },
    {
        "display": "Revolutionaries Beheading King",
        "full": "revlolutionaries beheading a king, in the style of artgerm and charlie bowater and atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, comic cover art, plain background, trending on artstation"
    },
    {
        "display": "Wild hair with trikes.",
        "full": "Of the wilder thing with the trikes in her hair"
    },
    {
        "display": "Goddess portrait with skull crown.",
        "full": "symmetrical, centered, goddess close-up portrait wigh crown made of skulls. betta fish, phoenix, bioluminiscent creature, super intricate ornaments artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic counter light, high detail, octane render, 4k, vibrant"
    },
    {
        "display": "Futuristic cityscape illustration.",
        "full": "scifi concept art by greg rutkowski, futuristic city near to a big lake with a snowed mountain range in the background, nostalgic vibes, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Cassandra Cain in Hunter Armor",
        "full": "cassandra cain in destiny hunter armor, wearing a hooded cloak, beautiful face!!!!, overlooking the last city on earth, 2 7 years old, cg animation, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Ghibli-style grassy landscape.",
        "full": "a grassy landscape in the style of studio ghibli, beautiful lighting, vivid colors, intricate, elegant, smooth, sharp focus, highly detailed digital painting, concept art, cinematic, unreal engine, 4 k wallpaper, svetlin velinov, tarmo juhola, cgsociety, artstation trending, deviantart featured"
    },
    {
        "display": "Fierce Berber Tiefling Portrait",
        "full": "portrait of a female berber tiefling, red skin, long horns, black hair, ponytail, steel armor, in a desert, strong, fierce, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, character art, smooth, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime river dragon goddess.",
        "full": "character concept art of an anime river dragon goddess | | foliage clothing, cute - fine - face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Bear coat girl, unpleasant face",
        "full": "a beautiful girl with bear fur coat with decollete and bra | | winter, realistic shaded, unpleasant face, bad looking, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Tattooed wizard casting spell",
        "full": "handsome tattooed gym bro wizard lightning magic spell vfx, diagonal spell vfx, fantasy game spell, fantasy epic digital art, by greg rutkowski, gaston bussiere, craig mullins, j. c. leyendecker, tom of finland"
    },
    {
        "display": "Hyperdetailed Archangel Illustration",
        "full": "archangel paul rudd by tsuyoshi nagano, illustration, cinematic lighting, hyperdetailed, 8 k, face enhance, frostbite 3 engine, cryengine, dof, trending on artstation, digital art, crepuscular ray"
    },
    {
        "display": "Disney raccoon diaper packaging",
        "full": "the beautiful diaper packaging rendering of a lovely disney raccoon, pop art, perfectly shaded, atmospheric lighting, product display, in the style of makoto shinkai, raphael lacoste louis comfort tiffany, artgerm, karol bak, james jean, ross tran, 8 k hd, fine texture structure, 3 drender"
    },
    {
        "display": "Iridescent lich portrait.",
        "full": "hyperdetailed portrait, dramatic angle, powerful azure eyes, stunningly beautiful female lich made of iridescent metals and shiny gems, ethereal rainbow aura, golden necklace, noble perfect androgynous skeleton inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, detailed, vivid, 8 k, huang guangjian, gil elvgren, hdr"
    },
    {
        "display": "Darth Vader artistic portrait",
        "full": "Darth Vader portrait by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Portrait of a smiling woman.",
        "full": "a young woman with curly red hair, freckles, smart, portrait, shallan davar, blue eyes, smiling laughing, thick hair, rpg, dnd, artgerm style"
    },
    {
        "display": "Mighty baby harp seal god",
        "full": "portrait of mighty baby harp seal god, white hair, soft hair, d & d, powerful, ice and glaciers, arctic, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Arm-less warrior battling smoke creature.",
        "full": "a man with a missing an arm holding a spear made of green fire wearing a leather cloak fighting a bad guy made of black smoke, full body, dark colors, sinister atmosphere, dramatic lighting, cinematic, establishing shot, extremely high detail, photo realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross,"
    },
    {
        "display": "Ornate gear landscape",
        "full": "otherwordly landscape with ground made of an ornate bronze gear mechanism, [ cinematic, detailed, epic, widescreen, opening, establishing, mattepainting, photorealistic, 4 k, octane render, art by greg rutkowski ]"
    },
    {
        "display": "Ethereal girl in Han clothing",
        "full": "portrait of chinese han clothing girl, dreamy and ethereal, expressive pose, big gold eyes, firm expression, fantasy, intricate, elegant, many rainbow bubbles, rose tones, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowskiand alphonse mucha"
    },
    {
        "display": "Young Winona Ryder painting",
        "full": "a beautiful painting of young winona ryder by juan luna, pre-raphaelite, detailed, trending on artstation, hd, masterpiece"
    },
    {
        "display": "Parachuting man in cyberpunk city.",
        "full": "man parachuting into a dark cyberpunk city through clouds, volumetric lighting, dystopia, artstation, concept art, painting"
    },
    {
        "display": "Fearful mysticalpunk portrait",
        "full": "death is swallowed up in victory, very detailed and beautiful face, screaming with fear, artwork by artgerm, centered shot, wide angle, full body, mysticalpunk"
    },
    {
        "display": "Cyberpunk Hanzo Portrait",
        "full": "hanzo from overwatch, cyber suit, see through glass hologram mask, character portrait, portrait, close up, concept art, intricate details, highly detailed, vintage sci - fi poster, retro future, vintage sci - fi art, in the style of chris foss, rodger dean, moebius, michael whelan, and gustave dore"
    },
    {
        "display": "Retrowave robot rabbit girl",
        "full": "retrowave robot rabbit girl, carrying eletro - whip, animation character design by akira toriyama, don bluth, jack kirby, alex toth, capcom, action - adventure, sharp detail, artstation trending, conceptart. com"
    },
    {
        "display": "Realistic crystal dragon ball",
        "full": "a realistic 4 star crystal dragon ball, concept art, trending on artstation 3 d."
    },
    {
        "display": "Symmetrical Goku SSJ portrait",
        "full": "symmetry!! intense portrait of sangoku ssj, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk Homer Simpson portrait",
        "full": "portrait painting of cyberpunk homer simpson, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Futuristic Apple train concept",
        "full": "futuristic train designed by Apple, Ilya Kuvshinov, digital, concept art, Kyoto animation, last exile, blue submarine no. 6,loish, murata range, kawaii, yoshitaka amano, studio lighting, manga, bright colors, beautiful, 28mm lens,alphonse mucha, vibrant high contrast, gradation, jean giraud, moebius, fantasy, rule of thirds, fibonacci, intricate, cel shaded, flat, matte print, makoto shinkai"
    },
    {
        "display": "Fantasy dungeon map concept",
        "full": "dungeon map concept d & d cave, desert, vulcanic ground, monument, tribal hollows and build. hyper detailed, fantasy style art, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sinister desert battlefield scene",
        "full": "a sinister desert planet, desert landscape, ward after the battle, corpses, pain, despair, dramatic lighting, cinematic, extremly high detail, photorealistic, cinematic lighting, post processed, concept art, artstation, matte painting, style by greg rutkowsky, perfect readability"
    },
    {
        "display": "Cinematic female cyborg angel",
        "full": "2D movie still, a beautiful cinematic female cyborg angel , fantasy magic, short aqua blue black fade hair, dark light night, highly detailed, advanced digital anime art, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece by Jinji Ito"
    },
    {
        "display": "Beautiful spaceship painting",
        "full": "a painting of the most beautiful spaceship, an exquisite and beautiful rendition, by greg rutkowski"
    },
    {
        "display": "Giraffe-like cat rooftop scene.",
        "full": "a cat standing on the rooftop, the cat looks like giraffe, giraffe head and long giraffe tail, fantasy, intricate, epic lighting, cinematic composition, hyper realistic, 8 k resolution, unreal engine 5, by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, artstation"
    },
    {
        "display": "Heavenly staircase illustration.",
        "full": "the magnificent staircase to heaven, art by greg rutkowski and peter mohrbacher, featured in artstation, octane render, cinematic, elegant, intricate, highly detailed, fantasy, concept art, sharp focus, illustration, 8 k"
    },
    {
        "display": "Snoop Dogg tennis ball monster",
        "full": "a snoop dogg tennis ball monsters, colorful, digital art, fantasy, magic, chalk, trending on artstation, ultra detailed, professional illustration by basil gogos"
    },
    {
        "display": "Steampunk woman in bathroom",
        "full": "woman model, steampunk!!! and modern, neutral colors, in a bathroom, backlit, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Lofi Pixar-style portrait",
        "full": "Lofi portrait Pixar style by Stanley Artgerm and Tom Bagshaw and Joe Fenton"
    },
    {
        "display": "Elegant angel of death portrait.",
        "full": "portrait of the angel of death, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau - 5"
    },
    {
        "display": "Steampunk girl portrait.",
        "full": "girl with steampunk weapons and uniform, serious, finely detailed, made by wlop, artgerm, rhads, full body portrait, illustration, grass, sunny, sky, anime, side view, perfect anime face, detailed face, zoomed out, smooth,"
    },
    {
        "display": "Medieval adventurer in alien cathedral",
        "full": "a medieval adventurer inside an enormous alien cathedral made of skulls, lord of the rings landscape, huge statues, portal to another dimension, cinematic lighting, highly detailed, sharp focus, perfect composition, 4 k, artgerm, derek zabrocki, greg rutkowski, octane render"
    },
    {
        "display": "Detailed cat girl portrait",
        "full": "nekopara fantastically detailed shining eyes vn modern anime style art vibrant open wide mouth smile detailed cat ears cat girl nekomimi dress portrait  shinkai makoto Studio ghibli Sakimichan Kentaro Miura Stanley Artgerm Lau WLOP Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation Unreal Engine 4k 8k ultra HD pixiv"
    },
    {
        "display": "Giant mechanical marionette.",
        "full": "full body shot of a giant mechanical marionette made of brass and copper and gold and emeralds, puppet, ornate, highly detailed, fantasy, render, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap,"
    },
    {
        "display": "Voldemort hugging Hermione.",
        "full": "voldemort hugging hermione, stylized, artstation, hd, cgsociety, cgi, realistic, dramatic, cinematic, artistic, trending, detailed"
    },
    {
        "display": "Tribal chief painting, ornate style",
        "full": "A painting of a tribal chief, epic ornate details, trending on artstation in the style of Greg Rutkowski"
    },
    {
        "display": "3D render of Samus Aran",
        "full": "full body 3d render of metroid samus aran as a joy toy, studio lighting, white background, blender, trending on artstation, 8k, highly detailed"
    },
    {
        "display": "Serious Sam 3D Portrait",
        "full": "3 d rendered character portrait of serious sam, 3 d, octane render, depth of field, unreal engine 5, concept art, vibrant colors, glow, trending on artstation, ultra high detail, ultra realistic, cinematic lighting, focused, 8 k"
    },
    {
        "display": "Digital portrait of Nami.",
        "full": "nami, beautiful girl short black hair, blonde hair tips, digital art from artstation by artgerm, hd, 4 k"
    },
    {
        "display": "Megan Fox in galaxy dress.",
        "full": "portrait of megan fox wearing a black dress with galaxy lights and stars, sultry body with sexy belly, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Indian guy as Titan.",
        "full": "portrait photo still of an indian guy in attack of titan. attack on titan concept art 8 k, 8 5 mm f 1. 8"
    },
    {
        "display": "Anime wind goddess concept.",
        "full": "character concept art of an anime goddess of wind | | cute - fine - face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Magic talking tree ent",
        "full": "a magic talking tree ent with a face in it, with a moustache, fantasy concept art, oil painting, hyperrealistic, highly detailed, artstation, cgsociety"
    },
    {
        "display": "Cute cat girl portrait",
        "full": "nekopara fantastically detailed eyes reflecting eyes modern anime style art cute detailed ears cat girl neko dress portrait  shinkai makoto vibrant Studio ghibli kyoto animation hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti gorgeous elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Meditating centaur shaman transformation.",
        "full": "a photograph of a meditating centaur shaman mutating into a savage fanged beast. surrounded by bulbous flowers and a few trees. river delta with mountains under a blue sky full of burning stars and birds. painted by jan van eyck, max ernst, ernst haeckel, ernst fuchs and artgerm. trending on artstation, fashion editorial"
    },
    {
        "display": "Colorful seaside sunset village",
        "full": "sunset on a village by the sea, colorful, trending on artstation"
    },
    {
        "display": "Android fairy in alien swamps.",
        "full": "ultra realistic illustration of android fairy, alien homeworld, swamps, advanced technology, ants, special effects, colorful lights, space ship in the distance, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by akihiko yoshida and tim mcburnie and anato finnstark"
    },
    {
        "display": "Island-sized shark-lion hybrid",
        "full": "the legendary island sized shark lion hybrid, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "Anime art of Hatsune Miku",
        "full": "Anime art of beautiful Hatsune miku with beautifel legs by artgerm, rossdraws, magali villeneuve, Gil Elvgren, Alberto Vargas, Earl Moran,, Art Frahm, Enoch Bolles, looking at viewer"
    },
    {
        "display": "Intricate fantasy hand gestures",
        "full": "esoteric hand gestures!!!, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant fantasy raven illustration",
        "full": "photograph of the ravven, fantasy, horror intricate, elegant, highly detailed, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski"
    },
    {
        "display": "Cartoon large hall scene",
        "full": "cartoon background clean neat clarity professional visual development set design, large hall, people sitting on ten round tables, dim painterly lighting volumetric aquatics, impasto, trending on pixiv"
    },
    {
        "display": "Futuristic photorealistic concept art",
        "full": "concept art from tron and black mirror, futuristic, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k"
    },
    {
        "display": "Raven mask portrait painting",
        "full": "a beautiful painting of a raven mask, by mark brooks, detailed drawing, concept art, stunning visuals, portrait"
    },
    {
        "display": "Zelda portrait, ultra-detailed.",
        "full": "portrait of zelda, full view, popular on artstation, artstationhd, artstationhq 8 k, volumetric lighting, super focused, no blur, trending on artstation, ultra detailed, by artgerm and james gurney, greg rutkowski"
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "a portrait of arwen, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Blue crystal golem drinking beer.",
        "full": "blue crystal!!! golem drinks beer, beer, glass, beer mug in hand, intricate, triumphantly, foggy background, full body art, dark souls, drawing, concept art, artstation, digital painting"
    },
    {
        "display": "Water Charmander, photorealistic.",
        "full": "a charmander made of water, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by boris vallejo"
    },
    {
        "display": "Anthropomorphic capybara druid illustration.",
        "full": "detailed full body concept art illustration soft focus oil painting on canvas of an anthropomorphic capybara druid in full intricate clothing, biomutant, dystopian, micro detail, octane render, 4K"
    },
    {
        "display": "Taylor Swift as Fiona.",
        "full": "portrait of Taylor Swift as Princess Fiona in Shrek 2001. HD, 4K. intricate abstract. intricate artwork. by wlop, beeple, dan mumford. trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Cinematic Istanbul concept art",
        "full": "concept art by jama jurabaev, cel shaded, cinematic shot, trending on artstation, high quality, brush stroke, vibrant colors, istanbul"
    },
    {
        "display": "High-ranking anime girl wallpaper",
        "full": "anime girl, best of pixiv, # 1 ranking, wallpaper 8 k"
    },
    {
        "display": "1920s New York gangster portrait",
        "full": "an excellent painted portrait of a gangster from the 20s in new york by Peter Xiao and Yanjun Cheng, Stanley Artgerm Lau, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Cyborg in Gothic Room",
        "full": "movie scene still photographic of a female cyborg from an alien culture with wires growing from his body, made of metal and slightly rusty touching a candle flame with his finger, sitting in a baroque, gothic, art - nouveau antique furnished room, in the evening, candlelight, 8 k, movie scene by john landis, danny boyle, joel schumacher, wes anderson, rob zombie, 8 k uhd ultra realistic, dramatic lighting, lens flare, atmosphere, glow, detailed, intricate, full of colour, cinematic lighting, trending on artstation, hyperrealistic, focused, extreme details, unreal engine 5, cinematic, masterpiece"
    },
    {
        "display": "Dark witch in forest",
        "full": "side portrait dark witch with hood, adventurer outfit large cloak, fantasy forest landscape, moonshine, fantasy magic, undercut hairstyle, short green black fade hair, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Cyberpunk prince sculpture",
        "full": "full-body sculpture of a young handsome Spanish prince as a half android with a glowing green diamond in his chest, pink laser beams coming out of his eyes, crown of giant jewels,  flowing neon-colored silk, human skull mask, geometric shapes in a cyperbunk and baroque style. intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, photorealistic, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Pale goth woman in latex.",
        "full": "a pale goth woman with a modest elaborate elegant purple-grey multilayered latex striped tight high-neck outfit, fully clothed, cgsociety, photorealistic, sublime-cool-badass-hyperadvanced, 16k, smooth, sharp focus, trending on ArtStation, volumetric lighting"
    },
    {
        "display": "Elegant elf portrait with crown.",
        "full": "portrait of very beautiful elf, rose thorn crown, thorns everywhere, headshot, pale skin, 4k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, fantasy, D&D, realistic lighting, by Alphonse Mucha, Greg Rutkowski, sharp focus, backlit, bright white hair, elegant"
    },
    {
        "display": "Ice block in snow",
        "full": "close - up large clear ice block on snow in game of thrones, 4 k, epic, cinematic, focus, movie still, fantasy, extreme detail, atmospheric, dark colour, sharp focus"
    },
    {
        "display": "Futuristic soldier in pink.",
        "full": "intense futuristic bespoke, cal of duty, james, artstation trending, 8 k, 3 d render, photorealistic, volumetric lighting caustics, pink"
    },
    {
        "display": "Epic forest battle scene",
        "full": "forest larvae, great king of stovokor, wields his sword in battle against enemies in golden masks, epic battle scene by jaime jones, artgerm, sergeant, brad rigney, artstation, masterpiece, high detail, wide camera angle"
    },
    {
        "display": "Symmetrical dream portrait.",
        "full": "symmetry!! intense portrait of dream ( neil gaiman ), intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute capybara with chess pawn.",
        "full": "detailed photorealistic painting of a cute capybara holding a chess pawn, sharp focus in the style of ruan jia, Mandy jurgens, cinematic light, concept art, trending on artstation, ultra realistic"
    },
    {
        "display": "Cyberpunk orc shaman portrait",
        "full": "portrait painting of a cyberpunk orc shaman extremely muscular ugly elizabeth olsen, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Floral warrior robot astronaut.",
        "full": "a warrior robot astronaut, floral! looks like a machine from horizon zero dawn, posing for a fight intricate, elegant, highly detailed, digital painting, establishing shot, an epic fantasy, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, 8 k"
    },
    {
        "display": "Gigachad Luigi Battle Portrait",
        "full": "gigachad luigi fighting like one punch man in a suit, fantasy character portrait, ultra realistic, full body concept art, intricate details, highly detailed by greg rutkowski, ilya kuvshinov, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Regal girl with revolver",
        "full": "!dream artstation concept of a beautiful regal adventurous girl with a .45 revolver,  dark skinned, sweaty skin, symmetrical face, high face detail, torn off shirt and fatigue pants,  jungle background with ruins, hyperdetailed, artstation trending, world renowned artists, worth1000.com, cgsociety, by greg rutkowski, by Gustave Dor\u00e9, by Marco Turini, by Artgerm, Deviantart in the style of Tom Bagshaw, Cedric Peyravernay, Peter Mohrbacher"
    },
    {
        "display": "Detailed anime girl portrait",
        "full": "beautiful very detailed portrait of anime girl with lots of jewelry in the face, full body, background minimalistic, digital art , dramatic cinematic lighting rendered by octane, 8k, detailed, intricate, clean and textures, trending on artstation, treanding on deviantart, trending on cgsociety, pinterest, by yasutomo oka"
    },
    {
        "display": "Anime Hermione Granger portrait",
        "full": "Anime as Emma Watson playing Hermione Granger, hourglass slim figure, attractive features, tight fitted tank top, body portrait, slight smile, highly detailed, digital painting, Hogwarts, artstation, concept art, sharp focus, illustration, art by WLOP and greg rutkowski and alphonse mucha and artgerm"
    },
    {
        "display": "Cyborg portrait in sci-fi armor",
        "full": "Symmetry!! portrait of cyborg, sci-fi armour, tech wear, cables, glowing lights!! sci-fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg male portrait.",
        "full": "hyper realistic photography of a stunningly beautiful cyborg male, lower back, spine, plated arm, intimate, holding close, ribbons, in the style of beth cavener, jin kagetsu, and wlop, highly detailed, intricate filigree, symmetry, masterpiece, award winning, sharp focus, concept art, highkey lighting, ambient lighting, octane render, 8 k, artstation"
    },
    {
        "display": "Vibrant armored pilot portrait",
        "full": "a detailed painting portrait inspired by wes anderson of a vibrant armoured pilot with tech implants and glowing lights, wearing a flight suit. inspired by dieselpunk. inspired by xenomorph. inspired by wes anderson. science fiction poster. head and chest only. upperbody. cinematic sci - fi scene. accurate anatomy. symmetry. portrait and science fiction theme with lightning, aurora lighting. clouds and stars. smoke. futurism. fantasy. by beksinski carl spitzweg. baroque elements. baroque element. intricate artwork by caravaggio. oil painting. oil on canvas. award winning. dramatic. trending on artstation. 8 k"
    },
    {
        "display": "Supernatural female soldier in swamp",
        "full": "beautiful mixed raced female soldier with supernatural powers, curly hair, smudged mascara, floating above swamp marsh, water particles, telekinesis, mossy, fern trees, weeping willows, sunset, sci - fi space opera, volumetric lighting, octane render by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha"
    },
    {
        "display": "Giant brutalist ashtray illustration",
        "full": "a giant brutalist filled overflowing ashtray!!! filled with cigarettes, grey, symmetrical!!, intricate, ugly highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Tree adorned with skulls.",
        "full": "artwork called the tree of skulls. featuring a tree with a skull hanging from each branch. epic, cinematic, artwork, trending on artstation."
    },
    {
        "display": "Horror Teletubbies Poster",
        "full": "teletubbies horror movie poster, grotesque, scary, high details, minimalist, by vincent di fate, artgerm julie bell beeple, inking, 1960s, vintage 60s print, screen print"
    },
    {
        "display": "Hooded necromancer with portal",
        "full": "center view of extremely detailed intricate concept art of hooded necromancer in front of a lovecraft portal, wide - angle portrait photography, by android jones and greg rutkowski, synthwave color scheme, cinematic lighting, dark vibes, trending on artstation, beautiful composition, intricate, elegant, pro photography by, highly detailed, gaston bussiere, craig mullins"
    },
    {
        "display": "Elegant skull gentleman portrait",
        "full": "concept art skull gentleman, close - up portrait, powerfull, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Luffy character illustration.",
        "full": "luffy, highly detailed, artgerm style, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Cyborg in futuristic medical bay",
        "full": "Photo realistic render, centered in image incredibly beautiful and alluring female cyborg looking into camera, in a clean futuristic advanced military medical bay with cables hanging from ceiling, cables on floor, beautiful face, voluptuous body, cyberpunk, sci-fi, fantasy, intricate, elegant, highly detailed,  artstation, concept art, smooth, sharp focus, octane render, dramatic lighting, art by artgerm and greg rutkowski and alphonse mucha and wlop"
    },
    {
        "display": "Beautiful model in rain",
        "full": "fully body pose, photo of a very beautiful!! victoria secret model, the joker, wet hair, raining, 8 k, hdr, smooth, sharp focus, high resolution, award - winning photo, trending on artstation, dslr, 5 0 mm"
    },
    {
        "display": "Futuristic toy store illustration.",
        "full": "! dream inside a dimly lit futuristic toystore. highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Germanic cloud goddess portrait",
        "full": "portrait of a germanic cloud goddess, with red skin, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Snowy mountain with magma",
        "full": "a beautiful hyper realistic detailed matte painting of a coldly mountain covered with snow traversed by a crack of incandescent magma, dramatic lighting, dynamic lighting, cinematic lighting, lit by morning light, dynamic lighting, cinematic lighting, lit by morning light, by makoto makoto, krenz cushart and artgerm, anime, featured on artstation, ultrawide angle"
    },
    {
        "display": "Star Wars fantasy landscape.",
        "full": "a scene from star wars the old republic, a detailed matte painting by christophe vacher, artstation, fantasy art, concept art, detailed painting, artstation hq"
    },
    {
        "display": "Pink atmospheric landscape.",
        "full": "a pink scene, everything is pink, perfect pink shading, pink atmospheric lighting, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Uniformed berserker girl portrait",
        "full": "full body portrait of a uniformed berserker girl with technical devices. detailed face, concept art, digital art, intricate, highly detailed 8 k, smooth, sharp focus, beautiful and aesthetic shape of face and body, artgerm, artstation, art by zexi guo and nira and kafun and gharliera and rinotuna"
    },
    {
        "display": "Errol Flynn as scientist.",
        "full": "Errol Flynn as a scientist. 1980s dystopian Soviet Russia, propaganda screens. Stephen Bliss, unreal engine, fantasy art by Greg Rutkowski, Loish, Rhads, Makoto Shinkai and Lois van baarle, Ilya Kuvshinov, rossdraws global illumination, radiant light, faithfully depicted facial expression, perfect anatomy, detailed and intricate environment"
    },
    {
        "display": "Cute kittens artwork",
        "full": "cute kittens, trending on artstation"
    },
    {
        "display": "Gorgeous woman, realistic detail.",
        "full": "photo of a gorgeous young woman in the style of stefan kostic, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by david cronenberg and stanley lau and artgerm and yoshitako amano"
    },
    {
        "display": "Hyper-realistic bat spider creature.",
        "full": "a bat spider creature by neville page, ken barthelmey, carlos huante and doug chiang, sharp focus, trending on artstation, hyper realism, octane render, 8 k, hyper detailed, ultra detailed, highly detailed, zbrush, concept art, creature design"
    },
    {
        "display": "Icarus flying close to sun.",
        "full": "icarus flying too close to the sun, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey and jacques - louis david"
    },
    {
        "display": "Mystical robotic goddess dance.",
        "full": "an immaculate render of a dancing mystical tribal goddess adorned with robotic scrap and cables and synthesizer parts is surrounded by wild tentacles made from mandalas and incense smoke, full body, perfect face, powerful, cinematic, beautifully lit, by artgerm, by karol bak, 3 d, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Futuristic Timoth\u00e9e Chalamet portrait",
        "full": "timothee chalamet profile picture by greg rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, thierry mugler, norman rockwell, 3 d"
    },
    {
        "display": "Cyborg Macaulay Culkin Portrait",
        "full": "realistic portrait beautiful concept art of home alone 2 movie scene when little macaulay culkin turns into cyborg. horror, created by gustave dore and greg rutkowski, high detailed, smooth draw, synthwave neon retro, intricate, trending on artstation."
    },
    {
        "display": "Colorful koi carp illustration",
        "full": "colorful koi carp in a waterly pond, illustration, concept art, colorful, beautiful, studio ghibli, takashi murakami, aoshima chiho, manga, cute and adorable"
    },
    {
        "display": "Anime woman portrait",
        "full": "facial portrait of a young pretty anime woman, long red hair, dark eyes, gothic eyeliner, character concept art, headshot, Charlie Bowater, Anna Dittmann, WLOP, Rumiko Takahashi, Akihiko Yoshida, Hyung-tae Kim, alexander mcqueen, trending on Artstation"
    },
    {
        "display": "Detailed Super Mario portrait",
        "full": "Portrait of Super Mario, nintendo, disco elysium, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and Wayne Barlowe and Zdislav Beksinski and Francis Bacon"
    },
    {
        "display": "Dramatic Innsmouth Scene",
        "full": "innsmouth, dramatic light, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Cyberpunk humanoid character design",
        "full": "character design sketch humanoid by ahmet atil akar, concept art character, cyberpunk fashion, marvelous designer, fantasy, painted, 4 k, high detail, sharp focus, trending in artstation"
    },
    {
        "display": "Overwatch character concept.",
        "full": "beautiful new character for overwatch, full body armor, dual wielding swords, super powers, long red hair, intricate design, shiny, art by artgerm and greg rutkowski"
    },
    {
        "display": "Scary chicken assassin portrait.",
        "full": "scary mugshot portrait of an asian assassin man dressed as a chicken, the chicken man, man dressed as a chicken, highly detailed painting by dustin nguyen, akihiko yoshida, greg tocchini, greg rutkowski, cliff chiang, 4 k resolution, trending on artstation, 8 k, man dressed as a chicken"
    },
    {
        "display": "Blonde woman, mallard, ice cream",
        "full": "Portrait of a  blonde woman and  a mallard eating ice creams inp Porto,real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anthropomorphic mushroom circus portrait.",
        "full": "360 degree equirectangular, anthropomorphic mushroom circus portrait, Art Deco nature, fantasy, intricate art deco mushroom designs, elegant, highly detailed fractals, sharp focus, fractal, equirectangular, 360 degree, equirectangular, 360 monoscopic equirectangular, art by Artgerm and beeple and Greg Rutkowski and WLOP"
    },
    {
        "display": "Cinematic winter goddess illustration",
        "full": "a beautiful cinematic female winter goddess, cristal dress, ice wing, galatic shamen with quantum energy fantasy, fantasy magic, undercut hairstyle, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Elegant dark silhouette portrait",
        "full": "symmetry!! portrait of a silhouette of a woman in the world of andrew wyeth, horror, fashion, dark!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ranni from Elden Ring Art",
        "full": "Ranni, elden ring, concept art, oil, artstation, 4k, Miyazaki"
    },
    {
        "display": "Italian Goddess hacking laptop.",
        "full": "close-up of a Italian Goddess hacking laptop on Pamukkale, thermal waters flowing down white travertine terraces, intricate, elegant, luxurious, digital painting, concept art, smooth, sharp focus, from Star Trek 2021, illustration, by WLOP and Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau, Artgerm"
    },
    {
        "display": "Vampire Martian Princess",
        "full": "a highly detailed matte painting of emma watson as a red haired vampire martian princess, drinking wine, standing in a space station and looking at jupiter, sci fi and fantasy, viewed in profile from far away, crackling green lightning, ultrawide lens, art by artgerm and greg rutkowski and alphonse mucha, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Fantasy bard portrait painting",
        "full": "a _ fantasy _ style _ portrait _ painting _ of female charismatic bard, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Matthew McConaughey as Jotaro",
        "full": "detailed painting of Matthew McConaughey as jotaro jojo by Enzo Fernandez, trending on artstation"
    },
    {
        "display": "Armored angel portrait.",
        "full": "character portrait of an angel in an armored leotard corset with flaming wings, detached sleeves, gold trim, brown hair, shoulder armor, thigh high leggings, medium shot, fantasy painting, glowing! fiery aura feathers, concept art, global illumination, tankoban, 4 k, fantasy painting, pixar animation style, by artgerm, rossdraws, wlop."
    },
    {
        "display": "Teenage Medusa portrait.",
        "full": "portrait of teenage medusa, snake hair, naughty smile, wearing an embroidered orange tunic, intricate, sensual, elegant, copper and emerald jewelry, glowing lights, seductive, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mucha, artgerm, and greg rutkowski"
    },
    {
        "display": "Gorgeous angelic goddess portrait",
        "full": "Portrait of a very beautiful gorgeous young goddess angel with long flowing hair in front of flower of an ornate 3D gold rococo mandala wearing a huge elaborate detailed ornate crown made of all types of realistic colorful flowers, turban of flowers, sacred Geometry, surrounded by scattered flowers, peonies dahlias lotuses roses and tulips, Baroque art, Cinematic lighting, Portrait, headshot, in style of Artgerm, WLOP, Peter Mohrbacher, William adolphe Bouguereau, cgsociety, artstation, Rococo and baroque styles, symmetrical, hyper realistic, 8k image, 3D, supersharp, pearls and oyesters, turban of vibrant flowers, satin ribbons, pearls and chains, perfect symmetry, iridescent, High Definition, Octane render in Maya and Houdini, light, shadows, reflections, photorealistic, masterpiece, smooth gradients, no blur, sharp focus, photorealistic, insanely detailed and intricate, cinematic lighting, Octane render, epic scene, 8K"
    },
    {
        "display": "Meryl Streep in winery, Pixar style",
        "full": "merryl streep working in a winery, animation pixar style, by magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, golden ratio, trending on art station"
    },
    {
        "display": "Animated ice cube pancakes",
        "full": "ice cube making pancakes animation pixar style, by magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, golden ratio, trending on art station"
    },
    {
        "display": "Selena Gomez portrait, Artgerm style.",
        "full": "Selena Gomez by Artgerm"
    },
    {
        "display": "Beautiful anime sorceress.",
        "full": "a very beautiful anime sorceress, full body, long red hair,  cloak with hood, fantasy town setting, cinematic lighting, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan"
    },
    {
        "display": "Artistic installation in space",
        "full": "tarps hanging from the ceiling making art and hexagons in space being caught with large walls coming from the ground in a museum, painters, magnificent, close up, details, sharp focus, elegant, highly detailed, illustration, by Jordan Grimmer and greg rutkowski and PiNe(\u30d1\u30a4\u30cd) and \u85af\u5b50Imoko and \u9999\u5ddd\u60a0\u4f5c and wlop and maya takamura, intricate, beautiful, Trending artstation, pixiv, digital Art"
    },
    {
        "display": "Ancient Egyptian catacombs artwork.",
        "full": "inside the catacombs of ancient egypt, digital painting, mixed media, trending on artstation and deviantart, epic composition, magnum opus, highly detailed, 8 k"
    },
    {
        "display": "Medieval Rick Grimes portrait",
        "full": "rick grimes from the walking dead, zombie, full body shot, fantasy, medieval, vivid colors, elegant, concept art, sharp focus, beautiful face, digital art, Hyper-realistic, 4K, Unreal Engine, Highly Detailed, HD, Dramatic Lighting by Brom, trending on Artstation"
    },
    {
        "display": "Ethereal cowboy tarot card.",
        "full": "a dramatic ethereal epic symmetrical painting of a handsome cowboy in a golden gold outfit | tarot card, art deco, art nouveau,  (steampunk), homoerotic, realistic | by Dresden Codak, by Mark Maggiori and ((((Alphonse Mucha))) | trending on artstation"
    },
    {
        "display": "Cottagecore Lady Gaga with kangaroo",
        "full": "beautiful cottagecore lady gaga holding a kangaroo. intricate, elegant. highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration. . art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Geralt of Rivia artwork.",
        "full": "geralt of rivia, painting by gaston bussiere, craig mullins, greg rutkowski, yoji shinkawa"
    },
    {
        "display": "High-quality road scene.",
        "full": "the best rates on the open road, occlusion shadow, specular reflection, rim light, unreal engine, octane render, artgerm, artstation, art by hiroaki samura and jiro matsumoto and yusuke murata, high quality, intricate detailed 8 k, beautiful shape of face and body, sunny day"
    },
    {
        "display": "Ancient Robot Portrait",
        "full": "Portrait of an Ancient Robot on a clearing, extremly detailed digital painting, in the style of Tomasz Alen Kopera and Fenghua Zhong and Peter Mohrbacher, mystical colors, rim light, beautiful lighting, 8k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Gigachad Luigi vs. Mech",
        "full": "gigachad luigi charging ultimate attack by yusuke murata wearing a suit in the city fighting a scifi mech, fantasy character portrait, ultra realistic, anime key visual, concept art, intricate details, highly detailed by greg rutkowski, ilya kuvshinov, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Medieval knight vs. pirate",
        "full": "distant look of medieval knight cutting in half a pirate, symmetrical facial features, front game card, drark, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by ruan jia and mandy jurgens and artgerm and wayne barlowe and greg rutkowski and zdislav beksinski"
    },
    {
        "display": "Messi as Giga Chad Fantasy",
        "full": "messi as giga chad, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Mummy astronaut, hybrid art style",
        "full": "the mummy astronaut, style hybrid mix of Anton Pieck+Jean Delville+ Amano,Yves Tanguy+ Alphonse Mucha+ Ernst Haeckel+ Edward Robert Hughes+Stanis\u0142aw Szukalski , rich moody colors,diamond dust glitter and sparkles, holographic krypton ion,blue eyes,octane render,4k, ighly detailed, hyperrealism, excellent composition, cinematic concept art, dramatic lighting, trending on ArtStation"
    },
    {
        "display": "Dynamic fluid action scene.",
        "full": "insanely detailed procedural render expressive dynamic fluid action scene of topaz gem spacesuit protects the dancing coolest girl from doom as the planet they orbit sends spores surround her, photorealism, sharp focus, award winning, tristan eaton, victo ngai,, maxfield parrish, artgerm, ryden, intricate details, portrait, bokeh"
    },
    {
        "display": "Steampunk Apollo with helmet.",
        "full": "the pale blond sun god apollo smirking, imperial centurion helmet, full body shot, steampunk, glowing eyes, volumetric lights, red and cyan theme, art nouveau botanicals, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, cinematic, illustration, beautiful face, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Divine face with eyes",
        "full": "portrait of the face of a god with a hundred eyes, dungeons and dragons, trending on artstation, by leonardo da vinci, raphael and richard corben award winning art, stylized painting"
    },
    {
        "display": "Cute gnome with pumpkin.",
        "full": "hand drawn cute one gnomes 3 d face upscale in autumn disguise holding pumpkin, detailed closeup face, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Psychedelic witch skateboarder.",
        "full": "bright psychedelic photo of a witch riding a skateboard in a supermarket, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Modern Apple Lisa concept",
        "full": "the apple lisa if it was built in 2 0 2 2, 4 k, hd, concept art"
    },
    {
        "display": "Torii gate in pink night.",
        "full": "Japanese Torii in the center of the picture , torii in a moutain with trees ,night , by Grzegorz Rutkowski, concept art, pink scheme"
    },
    {
        "display": "Futuristic Egyptian warrior queen portrait.",
        "full": "realistic detailed face portrait of a beautiful futuristic egyptian warrior queen in alien cyberpunk armor by alphonse mucha, ayami kojima, amano, greg hildebrandt, and mark brooks, female, feminine, art nouveau, cyberpunk, neo - gothic, gothic, character concept design"
    },
    {
        "display": "Meteorite explosion from space",
        "full": "concept art of a large meteorite explosion on the surface of earth as seen from space, scifi, highly detailed, high resolution photography, beautiful ilumination, artstation hq"
    },
    {
        "display": "Bear cubs playing baseball.",
        "full": "a baseball field in a corn field, with bear cubs running bases, super highly detailed, professional digital painting, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Cute anime girls illustration.",
        "full": "anime illustration japanese very very beautiful cute girls doing cute things trending on artstation pixiv makoto shinkai smiling super detailed eyes eyebrowless symmetry face visual novel hairpin star"
    },
    {
        "display": "Post-apocalyptic Bedouin barbarian.",
        "full": "a full body portrait of a beautiful post apocalyptic offworld quarter bedouin blind pulp fiction scarlet wild rogue barbarian leper begging by the roadside, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Hyper-realistic woman portrait",
        "full": "portrait of a beaitufiul woman,, illustration, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art by hajime sorayama h 6 4 0"
    },
    {
        "display": "Edgy knight portrait illustration",
        "full": "anthropomorphic edgyknight head in iridescent darkyiron mr bean giant pupils, intricate, elegant, highly detailed face, wide angle, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Emma Watson Digital Portrait",
        "full": "beautiful full body Emma Watson smiling, art by Annegret Soltau, lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm, digital art, highly detailed, intricate, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image, 3D unreal 5, DAZ, hyperrealistic, octane render, cgsociety, Photolab, Lightroom, 4K, Dolby Vision, Photography Award, Irving Penn, Irving Penn , Irving Penn , Irving Penn"
    },
    {
        "display": "Apocalyptic sunset concept art",
        "full": "the last sunset before end of times, concept art, colourful drawing, saturated, highly detailed"
    },
    {
        "display": "Cockatiel on Saturn's Rings",
        "full": "a yellow cockatiel riding on the rings of saturn wearing a propeller hat, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant female mechanic illustration",
        "full": "a female wearing a red mechanics jumpsuit, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Fantasy portrait of Killua Zoldyck",
        "full": "a _ fantasy _ style _ portrait _ painting _ of _ killua _ zoldyck rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Undead half-dragon portrait.",
        "full": "undead half - dragon, anthropomorph, beautiful, detailed symmetrical close up portrait, intricate complexity, in the style of artgerm and ilya kuvshinov, magic the gathering art"
    },
    {
        "display": "Handsome young male portrait",
        "full": "beautiful portrait of a incredibly handsome young male wearing a simple blue tunic, and a gold medallion!! around neck, detailed deep blue eyes, elongated face, shaved!!, round nose, dark brown hair, in ponytail!!!, space background, trending on artstation, sharp focus, illustration, caustics, octane render, 4 k, radiant light, art by wlop and artgerm, steampunk fiction"
    },
    {
        "display": "Cyborg skull portrait.",
        "full": "Cyborg skull portrait, front view, sci-fi, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lofi artistic portrait",
        "full": "Lofi portrait by Tristan Eaton and Stanley Artgerm and Tom Bagshaw"
    },
    {
        "display": "Anime Supreme Court Scene",
        "full": "the supreme court but it is a slice of life anime, by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv, anime style"
    },
    {
        "display": "Tattoo design: elaphocentaur.",
        "full": "tattoo design, stencil, traditional, beautiful elaphocentaur handsome elflike cervotaur, upper body, by artgerm, artgerm, digital art, deerelf"
    },
    {
        "display": "Epic female survivor portrait",
        "full": "cinematic shot epic portrait an female survivor wearing a pink t shirt, wet flowing hair, sweaty skin, apocalyptic backround, cloudy, made by greg rutkowski, jmw turner, marc simonetti, ivan aivazovsky, bowater, charlie, ross tran, artgerm, broad light, ambient occlusion, volumetric light effect, matte painting, trending on artstation, 4 k, perfectly defined features, digital painting, cinematic, epic, highly detailed, highly detailed, cushart krenz, artstation, soft light, sharp focus, illustration, character design, concept art,"
    },
    {
        "display": "Kangaroo-otter hybrid at sunset.",
        "full": "a closeup photorealistic photograph of a cute kangaroo - otter hybrid splashing in the surf during sunset. professional capture, well lit shot. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Nightmare vision illustration.",
        "full": "visions in nightmares, the all seeing eye in the forehead of reality, a fire of a million guns, the mother of a millions sounds, god emperor of civilization illustration trending on artstation, anime. by peter mohrbacher and artgerm and greg rutkowski and studio trigger and ilya kuvshinov. high quality, stunning, intricate detailed character. 8 k"
    },
    {
        "display": "Retro futuristic astronaut portrait.",
        "full": "ultra realistic retro futuristic astronaut smashed glass helmet, lens flare, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "\"2B in dungeon painting\"",
        "full": "a painting of 2 b nier automata tied up in a dungeon with a bag on her head, full view, popular on artstation, artstationhd, artstationhq 8 k, volumetric lighting, trending on artstation, artstationhd, artstationhq, ultra detailed, detailed head, by artgerm and james gurney, greg rutkowski"
    },
    {
        "display": "Elon Musk as beets.",
        "full": "elon musk as a beets, purple streaks in hair, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Brutalist neon streetscape.",
        "full": "streetscape, brutalist buildings, metal, concrete, wet streets, neon lights, neon signs, vehicles!!, pedestrians, syd mead, ralph mcquarrie, doug chiang, concept art, matte painting, finely detailed, minimal artifacts, rule of thirds, dynamic lighting, cinematic, denoised, centered, artstation"
    },
    {
        "display": "Pale sorceress at gate.",
        "full": "painting of a pale sorceress with intricate jewelry before an ancient gate, immaculate scale, hyper-realistic, Unreal Engine, Octane Render, digital art, trending on Artstation, 8k, detailed, atmospheric, immaculate"
    },
    {
        "display": "Stylish seal on escalator.",
        "full": "a seal with sunglasses wearing a suit on an escalator, concept art, highly detailed, digital art, trending on artstation"
    },
    {
        "display": "Hawaiian fire goddess portrait.",
        "full": "5 5 mm portrait photo of pele a hawaiian fire goddess holding molten lava standing in cauldera of volcanoe, wreath of anthuriums around her head and waist. by charlie bowater and titian and artgerm, intricate, face, highly detailed 8 k. intricate. lifelike. soft light. nikon d 8 5 0. cinematic post - processing, dramatic lighting, sharp focus, trending on artstation, artstationhd"
    },
    {
        "display": "Androgynous warrior in forest",
        "full": "portrait of a 1 5 foot tall, muscular, bald, smooth, extremely pale, androgynous humanoid with a perfectly symmetrical face, dressed in black body armour, in the background is a dense and foggy forest of trees, intricate detail, smooth, sharp focus, monochrome, high contrastl, art by artgerm and alphonse mucha,"
    },
    {
        "display": "Stoic military portrait",
        "full": "portrait of a stoic looking john oliver and adam driver, full body, military uniform, fantasy, intricate, elegant, beautiful, highly detailed, charcoal, centered, dark, smokey, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Finn Wolfhard, Sadie Sink, hallway.",
        "full": "Finn Wolfhard and Sadie Sink running down a white hallway, dramatic backlighting, autochrome, high contrast, highly detailed, sharp focus, digital painting, concept art, illustration, trending on artstation, art by greg rutkowski and greg hildebrandt, composition by alphonse mucha"
    },
    {
        "display": "Squirrel Girl Cosplay Portrait",
        "full": "Full-Body Portrait of Squirrel Girl, cosplay, Marvel, fantasy, intricate, elegant, highly detailed, nature, yellow mist, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Megan Fox as Venom Sketch",
        "full": "symmetry concpet art, full shot, traditional ink, sketch, of megan fox as venom, line sketch, intricate, elegant, highly detailed, monochrome, digital painting, artstation, concept art, sharp focus, illustration, art by borderlands 3 and peter polach"
    },
    {
        "display": "Emma Watson as celestial sorceress.",
        "full": "emma watson as dark magic celestial, long hair, white and transparent cloth, space, D&D, shiny background, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, bouguereau"
    },
    {
        "display": "Potato portal fantasy art",
        "full": "a potato opening a portal to the potato dimension, fantasy concept art, dynamic lighting, cinematic, ultra detailed, stunning visuals, creative, trending on art station, ambient lighting, atmospherical"
    },
    {
        "display": "Minimalistic lemon bonsai art",
        "full": "bonsai fruit tree with lemons!! but minimalistic concept art by frank stella gilleard james whalen tom, colorful, soft light, trending on artstation, minimalism"
    },
    {
        "display": "Isometric sci-fi action scene",
        "full": "isometric Dead Space action role playing game by artgerm and greg rutkowski, alphonse mucha, cgsociety and beeple highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art, octane render, Unreal Engine Lumen, very coherent. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Alien goddess close-up render",
        "full": "a highly detailed metahuman 4 k close up render of an alien goddess bella hadid monument astral in iris van herpen dress schiaparelli in diamonds crystals swarovski and jewelry iridescent in style of alphonse mucha gustav klimt trending on artstation made in unreal engine 4"
    },
    {
        "display": "Asymmetrical portrait fusion.",
        "full": "greg manchess portrait painting of african yorha type a no. 2 as african tracy from overwatch, back shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Gorgeous Mila Kunis as Daenerys",
        "full": "photo of a gorgeous young happy mila kunis daenerys targaryen in the style of stefan kostic, realistic, professionally, professionally color graded, half body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Cyborg wedding illustration, 3D.",
        "full": "cyborg wedding, sci-fi, Octane render in Maya and Houdini, light, shadows, reflections, 3d, paint texture, uplight, insanely detailed and intricate, by Katsuya Terada, by Alberto Mielgo, high resolution, octane rendered, unreal engine, illustration, trending on artstation, masterpiece, 8k"
    },
    {
        "display": "Weeping willow cliff scene",
        "full": "nature photograph of a weeping willow with black bark and silver leaves sitting on a cliff in the distance in the style of stefan kostic, realistic, half body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, luis royo, cloudy background"
    },
    {
        "display": "Intimate, alluring portrait.",
        "full": "a ( beautiful masterpiece highly detailed ) full body portrait illustration of intimate alluring elvira! mistress of the dark by ralph horsely and artgerm and joe jusko, raven black hair, porcelain white skin. bettie page, vampira, morticia addams, kat dennings, eva green, curvy, sexy pinup. hyperrealistic, trending on artstation"
    },
    {
        "display": "Surprised Pikachu Portrait",
        "full": "Portrait of Pikachu surprised meme, RPG Reference,  art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski, Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Elegant time traveler portrait",
        "full": "portrait of mr who, his time machine is in the background, m, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Stormy Arizona desert landscape",
        "full": "a stormy monsoon rainstorm in the arizona desert, the sonoran desert landscape is filled with mesquite trees and creasote bushes, cacti, water puddles, beautiful cloudy deep blue sky, by georgia o'keeffe and thomas kinkade and quentin mabille and geoffroy thoorens, trending on artstation"
    },
    {
        "display": "Elegant winged woman portrait",
        "full": "3 / 4 view of a portrait of beautiful woman with wings, confident pose, pixie, genshin impact, high quality, intricate, elegant, sharp focus, illustration, highly detailed, concept art, matte, trending on artstation, art by wlop and artgerm and greg rutkowski, h 6 4 0"
    },
    {
        "display": "Professional monkey portrait.",
        "full": "portrait of a professional monkey!!!!!!! in a suit, studio lighting, 3 / 4 view, majestic pose, corporate business, trending on artstation, artgerm, cgsociety"
    },
    {
        "display": "Stylized digital portrait.",
        "full": "stylized portrait of a young and strong girl by Dave McKean and Sam Weber, concept art, detailed face, digital art, octane render trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Evil Sith portrait, detailed.",
        "full": "portrait of a truly evil sith, yellow eyes, enraged, muscular, dark evil robes, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vivid fantasy illustration",
        "full": "Mark Ryden style, vivid colors, high details, cinematic, 8k resolution, beautiful detailed, photorealistic, digital painting, dark atmosphere, artstation, concept art, smooth, sharp focus, illustration, fantasy background, artstation trending, octane render, unreal engine"
    },
    {
        "display": "Anime portrait of Revy.",
        "full": "style of madhouse studio anime, black lagoon manga, loish, artgerm, comic art, portrait of revy from black lagoon, symmetrical eyes and symmetrical face, jean shorts, white tank top, purple hair, sarcastic evil smirk on face, sky and ocean background"
    },
    {
        "display": "Vintage communist punk girl.",
        "full": "a vintage photograph of a communist punk girl, depressive vibe, strong subsurface scattering, red smoke, newspapers flying in the background, war, scary lighting, stunning scene, highly detailed, concept art, trending on artstation"
    },
    {
        "display": "Regal blue winged wolf",
        "full": "blue wolf with wings, facing front, regal, elegant, winter, snow, moonlit, hd, illustration, epic, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wallpaper, art by artgerm and greg rutkowski and alphonse mucha and jin xiaodi"
    },
    {
        "display": "Charming siren in ocean.",
        "full": "charming siren making everyone crazy because of her beauty, the beauty of the world in the form of sirene in the ocean, long hair with golden color highly detailed, digital painting, smooth, beautiful angle, renaissance age, sharp focus, illstration, ultra realistic, angelic beauty, heaven vs hell, 8 k, strong and powerful confident angle art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed Madhatter illustration",
        "full": "The Madhatter, digital painting, lots of details, extremely detailed, 4k, intricate, brush strokes, Mark Arian, Artgerm, Bastien Lecouffe-Deharme"
    },
    {
        "display": "Anthropomorphic fox in suit",
        "full": "perfectly - centered portrait - photograph of a anthropomorphic vulpes vulpes fulva wearing suit, lifelike, highly detailed, artstation, sharp focus, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Dunmer female portrait.",
        "full": "portrait of the female nerevarine dunmer with red irises dark skin and white hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, octane render, unreal engine, art by swietopelk and rebelzize and terab 1 t and david leggett and deviant art, epic fantasy, digital painting, dark elf, dunmer, morrowind, oblivion, skyrim, daedric armor, morag tong, dark brotherhood, male"
    },
    {
        "display": "Fantasy portrait of chubby male.",
        "full": "a _ fantasy _ style _ portrait _ painting _ of chubby male glasses and wavy brown hair and beard, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Japanese woman fleeing samurai.",
        "full": "Beautiful Japanese woman running from an old samurai on the beach Toshio Saeki, high detailed, artstation trending"
    },
    {
        "display": "Cyberpunk cyborg portrait.",
        "full": "portrait of agatha vega as a cyberpunk cyborg. sci - fi, missing panels, intricate abstract upper body intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, deviantart, greg rutkowski, cinematic, key art, hyperrealism, iridescent accents"
    },
    {
        "display": "Glowing blue magical portal.",
        "full": "a scary glowing blue magical portal to another world in darwin's arch, ocean and rock landscape, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by hayao miyazaki and hideo kojima"
    },
    {
        "display": "Rugged female knight portrait",
        "full": "portrait of a rugged female as a bruised knight with a shield and armor, fantasy, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Flower shop entrance illustration",
        "full": "a little flower shop's front gate, fresh, digital illustration, pixiv, dramatic lighting"
    },
    {
        "display": "Symmetrical dark witch portrait.",
        "full": "many light effects, particle, lights, gems, symmetrical centered portrait dark witch, adventurer outfit large cloak, fantasy forest landscape, dragon scales, fantasy magic, undercut hairstyle, short purple black fade hair, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Aloy Portrait Illustration",
        "full": "Aloy from Horizon: Zero Dawn, half body portrait, videogame cover art, highly detailed, digital painting, artstation, concept art, smooth, detailed armor, sharp focus, beautiful face, illustration, art by Artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fairytale female portrait",
        "full": "realistic detailed face portrait of heaven by emilia dziubak, will terry, greg olsen, chris mars, ann long, and mark brooks, fairytale, female, feminine, art nouveau, illustration, character concept design, storybook layout, story board format"
    },
    {
        "display": "Berber Tiefling Woman Portrait",
        "full": "portrait of a berber tiefling woman with black hair in a desert, strong, fierce, elegant, fantasy, highly detailed, digital painting, artstation, concept art, character art, art by greg rutkowski and tyler jacobson and alphonse mucha"
    },
    {
        "display": "Lofi steampunk underwater portrait",
        "full": "lofi underwater bioshock steampunk portrait, wearing corset, holding a cigarette, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Flying bus in space.",
        "full": "realistic render portrait of an bus flying through space, money is flying out of the bus' windows, intricate, sci-fi, extremely detailed, digital painting, sculpted in zbrush, artstation, concept art, smooth, sharp focus, illustration, chiaroscuro lighting, golden ratio, incredible art by artgerm and greg rutkowski and alphonse mucha and simon stalenhag"
    },
    {
        "display": "Cyborg Japanese Spitz sunset.",
        "full": "a closeup photorealistic photograph of a cyborg japanese spitz on the beach at sunset. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail and the style of unreal engine. golden hour"
    },
    {
        "display": "Cinematic art of loss.",
        "full": "there was a time that the pieces fit, but i watched them fall away.,, tool, trending on artstation, cinematic"
    },
    {
        "display": "Blue slime creature at sunrise.",
        "full": "a blue slime creature in a forest near a lake at sunrise, hyperrealistic, concept art, photorealistic, octane render, unreal engine 5, path traced, highly detailed, high quality, 8K, soft lighting, geometric, sunrays, trending on Artstation, soft lighting, Pixar style"
    },
    {
        "display": "Pinky and the Brain Concept Art",
        "full": "brain from pinky and the brain, trending on artstation concept art digital painting"
    },
    {
        "display": "Boy in hoodie, Tokyo night",
        "full": "a boy wearing a dark hoodie walking at the streets of Tokyo at night, concept art, digital painting, night lights, vending machines, aesthetic, trending on artstation, 8k, dramatic composition, intricate details, sharp focus"
    },
    {
        "display": "Floating polygonal cats.",
        "full": "duotone noir scifi concept illustration of 3 d mesh of cats made of polygons inside box floating zero gravity glowing 3 d mesh portals futuristic, glowing eyes, octane render, surreal atmosphere, volumetric lighting. accidental renaissance. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. trending on artstation"
    },
    {
        "display": "Victorian Iron Man portrait",
        "full": "portrait of iron man, marvel, victorian, concept art, detailed face, fantasy, close up face, highly detailed, cinematic lighting, digital art painting by greg rutkowski"
    },
    {
        "display": "Glowing book on desk",
        "full": "a glowing open book laying on a wooden desk, fantasy concept art, fantasy book cover, highly detailed, intricate drawing"
    },
    {
        "display": "Lalisa Manoban in lingerie.",
        "full": "realistic detailed semirealism beautiful gorgeous cute Blackpink Lalisa Manoban wearing long silky white lingerie outfit, blue eyes blue iris, black hair black cat ears, black leather choker, proportional body, WLOP, Aztodio, Taejune Kim, sakimichan, ArtGerm, Pixiv, Instagram, Artstation"
    },
    {
        "display": "Snake-human hybrid drinking beer.",
        "full": "human body snake drinks beer, beer, glass, beer mug in hand, intricate, triumphantly, foggy background, full body art, dark souls, drawing, concept art, artstation, digital painting"
    },
    {
        "display": "Lana Del Rey Scientist Illustration",
        "full": "lana del rey as a confident scientist, wearing a labcoat, intricate, red white and black color scheme, illustration by krenz cushart, alphonse mucha, artgerm, trending on artstation"
    },
    {
        "display": "Mystical Roman Centurion Artwork",
        "full": "roman centurion, nuclear, plume made of peacock feathers, in the style of tomasz alen kopera and fenghua zhong and peter mohrbacher and alex konstad, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane render, trending on artstation"
    },
    {
        "display": "Elegant elf girl portrait",
        "full": "elf girl, portrait, digital painting, elegant, beautiful, highly detailed, artstation, concept art"
    },
    {
        "display": "Fantasy faerie forest scene",
        "full": "wonderland dreamscape faeries lady forest feather wing digital art painting fantasy bloom snyder zack and swanland raymond and pennington bruce illustration character design concept atmospheric lighting butterfly"
    },
    {
        "display": "Cyborg woman in cyberpunk.",
        "full": "ultra realistic, beautiful cyborg woman, sci-fi, fantasy, cyberpunk, intricate, elegant, highly detailed, digital painting, octane render, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Crocodile Skateboarding NYC",
        "full": "a crocodile on a skateboard in the streets of new york city, concept art, digital illustration, unreal engine, trending on artstation"
    },
    {
        "display": "Asian wind goddess portrait",
        "full": "An Asian wind goddess, portrait, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Catherine Zeta-Jones in Art Nouveau.",
        "full": "beautiful detailed picture of catherine zeta jones, radiant light, art nouveau, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed Drizzt Do'Urden art",
        "full": "drizzt do'urden, concept art, very detailed"
    },
    {
        "display": "Headless Horseman Sci-Fi Art",
        "full": "headless horseman in a marvel movie, science fiction industrial hard science concept art, 8K render octane high definition cgsociety, photorealistic, unreal engine"
    },
    {
        "display": "Bernie Sanders Titan Portrait",
        "full": "portrait of bernie sanders as a titan from attack on titan, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Dancing jellyfish princess in space",
        "full": "cinematic render of crenelated chrome space - suits protect the jovial dancing jellyfish princess from certain doom as the planet they orbit sends elastic neuronal nets attack them, tristan eaton, victo ngai, roy lichtenstein, maxfield parrish, artgerm, koons, ryden, intricate details, 3 / 4 view, space scene, black background, contemporary art"
    },
    {
        "display": "Digital art of gluttony",
        "full": "Gluttony, digital art, trending on artstation"
    },
    {
        "display": "Astronaut portrait in haze",
        "full": "A mystical mesmerizing 8k hyperrealistic Photo Portrait of an astronaut wearing an astronaut helmet, transforming into a purple haze, soft, sharp focus, detailed, art by Greg Rutkowski and artgerm and Alphonse Mucha"
    },
    {
        "display": "Underwater woman with octopus hat",
        "full": "close up of a beautiful woman underwater wearing sunglasses and a colourful octopus as a hat surrounded by jellyfish, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, 8 k, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pastel lioness with cubs",
        "full": "beautiful aesthetic digital illustration of a lioness with pastel blue fur and a litter of baby cubs by Lisa Parker and Anne Stokes, deviantArt, artstation, trending on artstation"
    },
    {
        "display": "Gothic woman in latex dress",
        "full": "a curvy pale hot goth young woman wearing an elegant modest tight shiny red-silver-black striped latex high-neck dress, cgsociety, photorealistic, sublime-cool-hyperadvanced-dark ambience, 16k, smooth, sharp focus, trending on ArtStation, volumetric lighting, fully clothed, thin waist"
    },
    {
        "display": "\"Whimsical Birthday Sardines\"",
        "full": "j k rowling & michael mcintyre holding a birthday can of sardines, elegant, real life skin, intricate, high detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski"
    },
    {
        "display": "Cute drake character illustration",
        "full": "super cute drake character concept, soft light, soft mood, realistic body features and face, illustration, painting oil on canvas by Elena Zhurikhina and Goro Fujita and Charlie Bowater, octane render trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Stormy cloud goddess concept.",
        "full": "character concept art of a stormy cloud goddess | | cute - fine - face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Fantasy fire dragon artwork",
        "full": "a fantasy fire dragon, magic the gathering, detailed, trending on artstation, deviantart, 8k, high res"
    },
    {
        "display": "Realistic monster mug art",
        "full": "monster mimic mug, digital art, many details, super realistic, greg rutkowski style, high quality, 8 k"
    },
    {
        "display": "Bloodied skeleton with red hair.",
        "full": "skeleton black bones full body!!, covered with blood, rolyatistaylor face!!, long red hair, beautiful blue eyes, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. retro film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant female android portrait",
        "full": "portrait of female android, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by fra angelico"
    },
    {
        "display": "Portrait of Gianna Michaels",
        "full": "beautiful portrait of gianna michaels, by magali villeneuve and greg rutkowski and artgerm and alphonse mucha, intricate, elegant, highly detailed, photorealistic, trending on artstation, trending on cgsociety, 8 k, sharp focus"
    },
    {
        "display": "Cyborg Turing conducting test.",
        "full": "duotone futuristic clinical white ghastly scifi 3 / 4 portrait of cyborg alan turing conducting turing test with ai. scifi far future atmosphere. cinematic scene volumetric lighting. golden rario accidental renaissance. concept by stanley kubrick dr strangelove style and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Nature-blessed fairy portrait",
        "full": "full body portrait of a fairy blessed by nature with ever - increasing physical mental perfection, melody marks, symmetrical! intricate, highly detailed, biblical divine holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime maid portrait fusion.",
        "full": "baroque oil painting, anime key visual concept art, brutalist dark fantasy, full portrait anime maid nazi ss, kuudere kawaii blonde hair blue eyes, military industrial, noise grain colorized, trending pixiv fanbox, rule of thirds golden ratio, detail acrylic palette knife, genshin impact, hayao miyazaki makoto shinkai jamie wyeth greg rutkowski chiho aoshima"
    },
    {
        "display": "Cyberpunk Korean castle painting",
        "full": "cyberpunk Korean ancient castle, fantasy, painting by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Tornado in jar, hyperrealistic.",
        "full": "a octane render of a violent tornado inside a jar, close - up studio photo, studio lighting, path traced, highly detailed, high quality, hyperrealistic, concept art, digital art, trending on artstation, cinematic, high coherence, epic scene, 8 k hdr, high contrast"
    },
    {
        "display": "Shakespeare as Mona Lisa.",
        "full": "portrait of William Shakespeare like a mona lisa, full of details, matte painting, concept art, smooth, octane render, trending on cgsociety artstation, greg rutkowski, cinematic, hyper realism, high detail, octane render, 8k, iridescent accents, Soft light atmosphere, 8k HDR,"
    },
    {
        "display": "Futuristic racing concept art",
        "full": "dream tron tesla light cycle race, rocket league, mass effect, hot wheels, wipe out, hyper realistic, concept art, intricate, hyper detailed, smooth, high contrast, neon, volumetric lighting, octane, raytrace, syd mead, artgerm, jim lee,"
    },
    {
        "display": "Realistic fantasy art of Britney Spears",
        "full": "britney spears, full body view, beautiful high quality realistic fantasy art, trending on artstation by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ariana Grande Fantasy Hamburger Portrait",
        "full": "portrait of Ariana Grande eating a giant hamburger , extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Beautiful young woman reading.",
        "full": "portrait photograph of an extremely beautiful!!!! young female reading a book, symmetric face!, symmetric round detailed eyes!!, slight smile, natural light, her hair is flowing down. looking at the camera!!. super resolution. extremely detailed. graflex camera!, bokeh!!!!! trending on artstation."
    },
    {
        "display": "Cyborg with lace veil",
        "full": "a beautiful ultradetailed vintage photo of a veiled cybernetic cyborg, by tom bagshaw, zach sutton and artgerm, embroidered lace chapel veil, portrait, cybernetic implants, vignette, 3 5 mm lens, golden ratio composition, detailed face, studio photography, very detailed, humanoids, mechanical robotic armor, masterpiece!, artstation, 8 k, highly coherent"
    },
    {
        "display": "Dystopian face with mask",
        "full": "Very very very very highly detailed epic photo of face with rhinoceros venetian mask, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Artgerm and Vincent di Fate"
    },
    {
        "display": "Aubrey Plaza portrait, stylized.",
        "full": "full length photo of an similing Aubrey Plaza in a tanktop in the style of stefan kostic, not realistic, sharp focus, 8k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Futuristic Star Wars Portrait",
        "full": "star wars with lord Rihanna profile picture by Greg Rutkowski, intricate details, futuristic, volumetric lights, streetwear, studio ghibli, Organic Painting , Matte Painting, geometric shapes, hard edges, trending on the artstation, fantasy LUT, realistic by Sachin Teng + Martin Grip + Moebius + Patrick Gleason, smooth, sharp focus, illustration, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha, techwear, Industrial Scifi, detailed illustration, character portrait,"
    },
    {
        "display": "Cave computers with silhouettes",
        "full": "two computers in a cave two silhouettes of men, piles of modular synth cables, by cameron gray, wlop, stanley kubrick, masamune, hideki anno, unique perspective, trending on artstation, 3 d render, smooth render"
    },
    {
        "display": "Harry Potter in Mordor.",
        "full": "daniel radcliffe as harry potter walking, craig mullins epic oil on canvas painting of a lord of the rings atmospheric landscape, mordor, line art!!, ink, art concept for a book cover!!, harmonic colours, thik brushstrokes, movie still, game of thrones concept art, highly detailed, centered, hyperrealism"
    },
    {
        "display": "Angel princess portrait",
        "full": "a masterpiece ultrarealistic ultradetailed portrait of incredibly beautiful angel princess with green orb of life. baroque renaissance girl in the night forest. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano. background by james jean, light by julie bell, porcelain skin."
    },
    {
        "display": "Fierce blonde MMA fighter.",
        "full": "a highly detailed illustration of fierce ponytail platinum blonde woman wearing black mma gear and gloves, dramatic boxing stance pose, fairly muscular, athletic, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, WLOP"
    },
    {
        "display": "Rugged fantasy rogue portrait",
        "full": "brown haired rogue, full body portrait, rugged, muscular, bearded, male, city landscape, norway, d & d, fantasy, intricate, elegant, highly detailed, digital painting, brown and gold color palette, artstation, octane render, concept art, matte, sharp focus, illustration, herrarthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Angels aiding gene machine",
        "full": "hyperrealistic photography of angels assisting a genepool machine in the style of Jin Kagetsu, patricia piccinini, James Jean and wlop, highly detailed, masterpiece, award-winning, sharp focus, intricate concept art, ambient lighting, 8k, artstation"
    },
    {
        "display": "\"Neon-lit Japanese street scene\"",
        "full": "japan narrow street with neon signs and a girl with umbrella wearing techwear, digital art, sharp focus, wlop, artgerm, beautiful, award winning,"
    },
    {
        "display": "Peppa Pig nuclear explosion",
        "full": "Peppa Pig plays in front on a large nuclear explosion that has created a large nuclear mushroom and is totally oblivious to the fact that the city is in flames and everyone die. Elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Armored Black Cat vs. Demon",
        "full": "Black Cat in armor fighting a demon cinematic lighting, fine detail, trending on artstation, ultra crisp, high contrast, ominous, threatening, haunting, forbidding, gloomy, Long shot"
    },
    {
        "display": "Dramatic portrait of Natalie Portman",
        "full": "closeup portrait of natalie portman from the movie leon the professional, hitman, city background, dramatic light, gorgeous view, depth, high detail, digital art, painted by greg rutkowski and seb mckinnon, by tim burton, trending on artstation"
    },
    {
        "display": "Piltover cityscape, Arcane style.",
        "full": "the city in the of piltover, in the style from netflix's arcane, blimps in the sky, blue skies, soft clouds, trending on artstation"
    },
    {
        "display": "Angelic cephalopod deity",
        "full": "holy cephalopod with long flowing white robe angelic wings and a single large knowing eye with long powerful tentacles, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, dramatic, sunset,hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Attractive woman in armor.",
        "full": "mister freeze as an attractive young smiling woman wearing a mushroom crown and heavy armoured wedding dress, face portrait, hd shot, digital portrait, beautiful, fantasy art, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "High-quality anime portrait.",
        "full": "high quality portrait of leonid kanevsky. art by makoto shinkai, crunchyroll, pixiv, danbooru, hd, headshot, cinematic still, detailed anime face, bokeh, digital art, cel shading, vivid colors, ambient lighting"
    },
    {
        "display": "Cybernetic Jesus Tech Priest",
        "full": "painting of jesus christ as a cloaked tech priest projecting a hologram, adeptus mechanicus, cybernetic enhancements, zdzislaw beksinski, lewis jones, dariusz zawadzki, wayne barlow, warhammer 4 0 k, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Gloomy school girl horror.",
        "full": "school girl holding a knife, gloomy and foggy atmosphere, octane render, artstation trending, horror scene, highly detailded"
    },
    {
        "display": "Holographic jellyfish in deep sea.",
        "full": "intricate holographic quantum ghostwave entanglement jellyfish made of microcircuitry and transistors in a glowing deap sea by peter mohrbacher and dan mumford, trending on artstation, cgsociety 4 k"
    },
    {
        "display": "Lonely astronaut on Jupiter.",
        "full": "lonely astronaut flying in jupiter's clouds, epic scene, by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Cyborg mad scientist portrait.",
        "full": "symmetry!! portrait of cyborg mad scientist, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vampire bride portrait",
        "full": "a portrait of a bride of dracula, smiling with vampire fangs, concept art by pete mohrbacher and wlop and artgerm and guweiz, digital art, highly detailed, intricate, sci - fi, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Anime Harley Quinn portrait",
        "full": "Anime as Margot Robbie cute-fine-face, pretty face, realistic shaded Perfect face, fine details. Anime. as Harley Quinn Suicide Squad; classic anime from 1990 realistic shaded lighting by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Furry hyena astronaut in space",
        "full": "digital artwork of furry female hyena, in style of zootopia, fursona, furry, furaffinity, deviantart, wearing astronaut outfit, floating in space, space background, cyberpunk, detailed face, style of artgerm,"
    },
    {
        "display": "Orc Armor Concept Art",
        "full": "three different views of orcs in armour, intricate beautiful concept art by senior character artist, trending on artstation, artstation hd, full body"
    },
    {
        "display": "Anthropomorphic lobster Santa.",
        "full": "magic : the gathering fantasy character concept art of anthropomorphic lobster wearing a christmas hat, by franz frazetta and marco bucci, high resolution. a clear portrait of powerful lobster impersonating santa, magical christmas wonderland in background, fantasy coloring, intricate, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Hyper-detailed Wolverine portrait",
        "full": "Wolverine,muscle extremely detailed, fantastic details full face, mouth, trending on artstation, pixiv, cgsociety, hyperdetailed Unreal Engine, optimization 4k 8k ultra HD, WLOP"
    },
    {
        "display": "Possum dragon at castle.",
        "full": "possum dragon at a medieval castle under a dark starred sky, dark fantasy, watercolor, dreaming illusion,  highly detailed, 4k, trending on Artstation, award-winning"
    },
    {
        "display": "Romantic dance portrait.",
        "full": "portrait of Mads Mikkelsen and Hugh Dancy holding hands romantically as they chaperone school dance, from scene from Twin Peaks by Michael Whelan, Bob Larkin and Tomer Hanuka, simple illustration, domestic, nostalgic, clean, full of details, by Makoto Shinkai and thomas kinkade, Matte painting, trending on artstation and unreal engine, New Yorker magazine cover, 1980s romance book cover, 1990s horror book cover"
    },
    {
        "display": "Elegant She-Hulk Portrait",
        "full": "she - hulk by charlie bowater and titian and artgerm, full - body portrait, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Hulk Hogan vs. Andre the Giant",
        "full": "hulk hogan body slamming andre the giant wrestlemania 2, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Israeli digital painting illustration",
        "full": "an israeli, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk monk illustration",
        "full": "ultra realistic illustration, buddhist monk, cyberpunk, sci-fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, rene magritte, surrealism"
    },
    {
        "display": "Muscular Margaret Thatcher illustration",
        "full": "margaret thatcher as a muscular man, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Hybrid cat-raptor painting",
        "full": "painting of hybrid cat!!! and ( ( ( ( raptor ) ) ) ) fused together, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Maya Temple Fashion Runway",
        "full": "Fashion Runway!, Catwalk!!, Platform on fantastic Maya Temple, Concept Art, Hyperdetailed, Rendering, Epic Lighting, Octane, Redshift, 4k"
    },
    {
        "display": "Cyberpunk robotic black cat",
        "full": "a detailed matte painting of a black cat with red led eyes, the cat is in a intricately detailed neo cyberpunk Japanese city, the black cat is made out of mechanical robotic parts with wires, by Ismail Inceoglu , concept art, featured on cgsociety"
    },
    {
        "display": "Aerial Hawaiian pirate scene",
        "full": "a painting a breathtaking aerial view of Hawaiian islands with Pirates, surrounded by palm trees, clouds, flowers, volcano, azure ocean, sunlight glistening, glow, , a detailed matte painting by sylvain sarrailh, Stephan Martini\u00e8re, by RHADS, Makoto Shinkai, bokeh, Artstation contest winner, fantasy art, concept art, #vfxfriday"
    },
    {
        "display": "Circe, Greek goddess portrait",
        "full": "portrait of circe, greek mythology, goddess, golden crown and outfit, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Zombie invasion movie poster.",
        "full": "ismail inceoglu epic painting of a zombie invasion movie poster art, line art!!, ink, art concept for a book cover!!, vibrant colours, oil on canvas painting, thik brushstrokes, highly detailed, centered, hyperrealism, romantic, concept art, smooth, sharp focus trending on artstation, by greg manchess and by craig mullins and by kilian eng and by jake parker"
    },
    {
        "display": "Rugged paladin portrait.",
        "full": "portrait of a ruggedly handsome paladin, white hair, muscular, half body, leather, hairy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Baby Groot and Baby Yoda",
        "full": "a dramatic highly detailed render of baby groot and baby yoda, futuristic marvel and star wars vibe, by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, Beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, Artstation, concept design art, Octane render, 8K, masterpiece, sharp focus, hyperrealistic"
    },
    {
        "display": "Cyberpunk masked assassin portrait",
        "full": "portrait of a cyberpunk masked assassin, half body, glowin eyes, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Cyborg goddess portrait.",
        "full": "waist up, front view of beautiful white cyborg goddess smiling, by william - adolphe bouguereaum artgerm and hr giger and zdzislaw beksinski, matte painting, hyperdetailed, coherent, single face, symmetry, symmetrical face, art nouveau, beautiful render, concept art"
    },
    {
        "display": "Dragon parade artwork",
        "full": "Amazing dragon artwork, Traditional Chinese Parade with Dragon Kandinsky, Picasso, Artgerm"
    },
    {
        "display": "Festival hippy underwater art",
        "full": "a centered full body render of an alluring festival hippy with tribal tattoos surrounded by a underwater ink pour and flowing liquid gallium and sacred geometry, perfect body and face, gorgeous, cinematic, beautifully lit, by artgerm, by karol bak, by donato giancola, 3 d, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Giant and tiny human, horror.",
        "full": "a giant talking to miniscule human, by greg rutkowski + beksinski, atmospheric, cinematic, dramatic colors, horror"
    },
    {
        "display": "Scarlet Witch Portrait, Hyper-Detailed",
        "full": "Scarlet Witch, au naturel, portrait, full body, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Futuristic trench warfare scene",
        "full": "semi futuristic trench warfare, war, explosions, heavy machine gun fire, flame and fire, unreal engine, octane render, cinematic, epic, rainy stormy night, chaotic, 8k, ultra detailed, Artstation, Trending on Artstation, Artstation HQ, Artstation HD, deviant art, Pinterest, digital art, reflections,"
    },
    {
        "display": "Amanda Seyfried as Nun",
        "full": "portrait of amanda seyfried as a nun, catholic, church, bible, christian, intrigante, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Elegant woman portrait, detailed.",
        "full": "portrait of a woman, stallone, schwarzenegger, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Realistic portrait of Vi",
        "full": "portrait of Vi from League of Legends, by Fortiche Studio, by Riot Games, from Netflix's Arcane, trending on artstation,fine details, realistic shaded, fine-face, painted texture, pretty face"
    },
    {
        "display": "Elegant vampire portrait.",
        "full": "a very beautiful vampire girl, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Aloy Portrait Illustration",
        "full": "Aloy from Horizon: Zero Dawn, half body portrait, videogame cover art, highly detailed, digital painting, artstation, concept art, smooth, detailed armor, sharp focus, beautiful face, illustration, art by Artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Teddy bears' world domination.",
        "full": "Teddy bears take take over the world. By ilya kuvshinov, krenz cushart, Greg Rutkowski, trending on artstation. Glossy materials, sharp highlights, amazing textured brush strokes, accurate shape, clear curvy details, cinematic soft volumetric studio lighting, with backlight, VFX, HDR"
    },
    {
        "display": "Cartoon giant turtle illustration",
        "full": "a cell shaded cartoon giant turtle from howl's moving castle ( 2 0 0 4 ), on a desert road, illustration, wide shot, subtle colors, post grunge, concept art by josan gonzales and wlop, by james jean, victo ngai, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "People fleeing cryptocurrency urban scene",
        "full": "bunch of people running away from cryptocurrency in the city, hyper detailed, trending on artstation, cinematic composition hdr, 8 k, beautiful lighting, sharp details, digital illustration"
    },
    {
        "display": "Putin as King Julien.",
        "full": "vladimir putin as king julien from madagaskar, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Nadine Lustre as Darna.",
        "full": "nadine lustre as darna, volumetric lights, red and cyan theme, art nouveau botanicals, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, cinematic, illustration, beautiful face, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ice priestess portrait",
        "full": "ice priestess of the sacral moon full body portrait highly detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration, art by tian zi and wlop and alphonse mucha"
    },
    {
        "display": "African American man portrait",
        "full": "overwatch concept art character portrait of a new character who is an african american man with rose - tinted sunglasses spectacles, trending on artstation, cgsociety,"
    },
    {
        "display": "Goddess of Transcendence Artwork",
        "full": "the goddess of transcendence, by artgerm and yoshitaka amano and moebius and alphonse mucha, hyperdetailed, dc comics, ornate, nebula, explosions in the sky, trending on artstation"
    },
    {
        "display": "Colorful alien metropolis artwork",
        "full": "alien metropolis of colorful orbs and tubes, extremely detailed oil painting, unreal 5 render, rhads, Bruce Pennington, Studio Ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award-winning photograph, masterpiece"
    },
    {
        "display": "Symmetrical floral portrait.",
        "full": "symmetry!! portrait of floral! horizon zero dawn machine, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Cinematic foggy shore.",
        "full": "no fear, no hesitation, no surprise, no doubt, directed by charlie kaufman ( 2 0 0 1 ) anamorphic lenses, a rocky shore in the foreground, foggy volumetric light morning, a beam of light from the heavens, cinematic trending on artstation in the style of greg rutkowski"
    },
    {
        "display": "Ethereal princess tarot card.",
        "full": "art by Gediminas Pranckevicius, Hyperrealistic beautiful goodness ethereal  white north pole girl portrait, art nouveau, fantasy, intricate flower designs, elegant, highly detailed, sharp focus, art by Artgerm and Greg Rutkowski and WLOP - princess of dead of tarot card The Star card shows a princess of dead standing in front of intricate magical stone energy portal. She is holding two containers of water. One container pours the water out to the dry land, as if to to nourish it and ensure its fertility. The lush green land around her seems to say that it is working. One foot is inside the water which shows the spiritual abilities and inner strength of the woman. The other foot on the ground shows her practical abilities and strengths. Behind her, there is a large central star surrounded by seven small stars which represent the chakras. There is bird standing at a tree branch which represents the holy ibis of thought. The Star's astrological correspondent is Aquarius."
    },
    {
        "display": "War-themed art detail.",
        "full": "a detail of a panting by francisco goya and ernst fuchs, war, fire, dark stone, explosions, angry faces fighting eachother, 8 k, trending on artstation."
    },
    {
        "display": "Futuristic Babylonian scene",
        "full": "ethereal picture of geometrieva in the tarkovsky greatest scene, aura of the ancient destroyed majestic tower of babylon, a woman in futuristic cyber clothing, transparent puffer jacket, hyperealistic, blockchain, cyber world, ambient lighting, concept art, intricate, hyper detailed, smooth, dynamic volumetric lighting, ocatane, ray trace, cinematic, high quality, cgsociety"
    },
    {
        "display": "Anime couple in forest.",
        "full": "a close up of a boy and a girl sitting together in a forest. cute anime eyes. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, trending on artstation, digital art."
    },
    {
        "display": "Faye Valentine in dynamic pose.",
        "full": "Faye Valentine as aeon flux picture by Greg Rutkowski, dynamic pose, cowboy bebop, yellow clothes, matte painting, intricate, fantasy concept art, elegant, by Stanley Artgerm Lau, WLOP, golden ratio, thomas kinkade, alphonse mucha, loish, Peter chung, norman Rockwell,"
    },
    {
        "display": "Anime-style Olivia Wilde.",
        "full": "olivia wilde holding a tron disc, very very anime!!!, fine - face, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Heroine painting, windy day.",
        "full": "a beautiful masterpiece painting of a herione by juan gimenez, windy day, award winning, trending on artstation,"
    },
    {
        "display": "Muscular albino lion cyborg",
        "full": "front shot of a muscular male albino white furry lion character, holographic crystals, bionic futurism, robotic cyborg, 8 5 mm f 1. 8, ominous elegant detailed concept artstation sharp focus ray tracing cinematic masterpiece fine portrait, by artgerm greg rutkowski tooth wu beeple"
    },
    {
        "display": "Cute cat girl portrait",
        "full": "nekopara fantastically detailed shining eyes modern anime style art cute vibrant detailed ears cat girl neko dress portrait  shinkai makoto Studio ghibli studio key hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv lens flare"
    },
    {
        "display": "Anime woman in Gucci sunglasses",
        "full": "a beautiful woman wearing gucci sunglasses, pastel pink, crystals, chains, bright colors, highly detailed, concept art, matte, trending on artstation, anime, art by wlop and artgerm and greg rutkowski, ilya kuvshinov, strong strokes,"
    },
    {
        "display": "Cyberpunk wooden statue",
        "full": "octane render, ultra photorealistic, hyper detailed, unreal engine, a breathtaking wooden mystical statue robot of the last human in existence cyberpunk in a sci - fi gothic terrarium tech - cemetery, in neo tokyo artwork by greg rutkowski, alphonse mucha, inspired by blade runner 2 0 4 9"
    },
    {
        "display": "Cowboy saloon sunset illustration",
        "full": "an old cowboy walking through the door of a saloon, wild west, sunset, desert, dusty, sunbeams. digital illustration, colors, soft lighting, adventurous, atmospheric lighting, 8K, octane render. By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art."
    },
    {
        "display": "Elegant Ukrainian lady portrait",
        "full": "blonde lady in white embroidered shirt, ukrainian national costume, filigree crown with blue and yellow textile embroidery sunflowers, intricate, elegant, digital painting, art nouveau, smooth, focus, rim light, charlie bowater, tom bagshaw, greg rutkowski"
    },
    {
        "display": "Sci-fi witch portrait",
        "full": "beautiful portrait of a sci fi witch with a big hat woman with mecha implants with floral blue pastel flowy long hair facing the camera in the style of a code vein character, momo from twice in code vein in the style of wlop, artgerm, yasutomo oka, yuumei, rendered in unreal engine and redshift octane, background is surrounded by epic sci fi landscape by simon stalenhag, digital art dynamic dramatic lighting, soft lighting, imagine fx, artstation, cgsociety, by bandai namco artist, hi tech solarpunk"
    },
    {
        "display": "Futuristic Baroque android statue",
        "full": "full-body baroque and bladerunner style mint neon and ceramic statue of a young attractive pale Turkish fighter male macho dotado android thrusting sim roupa con piroca dura, glowing white face, prince crown of green steampunk lasers, emeralds, swirling white silk fabric. futuristic elements. prismatic liquid rainbow light, full-length view. space robots. human skulls. throne made of bones, intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Wakandan warrior in mummy armor",
        "full": "a wakandan warrior in a mummy style armor, ultra realistic, concept art, intricate details, eerie, horror, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Crystal-clad portrait fantasy",
        "full": "jennifer lawrence portrait, with a brilliant, impossible striking big shiny crystal headpiece, quartz, clothes entirely made out of crystal quartz, black hair, crystal background, symmetrical, dramatic studio lighting, rococo, baroque, hyperrealism, closeup, d & d, fantasy, intricate, elegant, highly detailed, asian, digital painting, artstation, octane render, 8 k, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical ACOTAR fanart.",
        "full": "symmetry!! intense fanart of gemma as acotar protagonist, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Yeti attack in blizzard",
        "full": "giant yeti monster attacking an encampment during a blizzard, highly detailed, digital illustration, artstation, concept art, matte, sharp focus, illustration, dramatic, full moon, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dramatic fantasy kami render",
        "full": "a photorealistic dramatic fantasy render of a japanese kami in its shintai by wlop, artgerm, greg rutkowski, alphonse mucha, beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, artstation, concept design art, octane render, 8 k"
    },
    {
        "display": "Epic hybrid dragon rising.",
        "full": "mystical creature, hybrid monster, dragon, beast rising from the sea of blood, many horns, many heads, landscape, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Fluffy panda by river",
        "full": "Epic portrait an fluffy cute panda playing with water near a green river, nature, digital painting, artstation, concept art, soft light, hdri, smooth, sharp focus, illustration, fantasy, intricate, elegant, highly detailed, D&D, matte painting, in the style of Greg Rutkowski and Alphonse Mucha and artemisia, 8k, highly detailed, jurgens, rutkowski, bouguereau, pastoral, rustic, georgic, detailed concept art, illustration, colorful pastel, painting, detail, ultra detailed, digital art, 4K,"
    },
    {
        "display": "Cyberpunk woman portrait",
        "full": "a closeup portrait of a young woman as a cyberpunk protagonist, shag cut, sunglasses, augmented, dramatic light, city background, megacity, sunset, high contrast, sharp, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Flower sunset in fantasy.",
        "full": "a sunset but replace the sun for a flower , D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Art Deco tarot card.",
        "full": "a tarot card of a woman, hovering over water with angel wings and she is pouring water between two cups. illustrated in an art deco style by alphonse mucha and an elegant border by tamara de lempika. | studio lighting | digital painting, stunning lighting, trending on artstation"
    },
    {
        "display": "Fantasy RPG portrait painting",
        "full": "a _ fantasy _ style _ portrait _ painting _ of young adult, black fringe hair, round face, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Horror portrait of bald man.",
        "full": "portrait of a red bald man with black eyes and a black teeth, horror, glowing eyes, by Stanley Artgerm Lau , greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell. Trending on artstation detailed illustration hd 4k"
    },
    {
        "display": "Burly librarian dwarf portrait",
        "full": "picture of male librarian tolkien dwarf inside library, burly, casual clothes, dark skin, dark brown hair, braids, glasses, high fantasy, highly detailed, detailed faces, smooth, sharp focus, chiaroscuro, dnd, digital painting, concept art, rossdraws and moebius and jon mcnaughton"
    },
    {
        "display": "Cute priestess healing knight",
        "full": "picture of cute priestess using healing magic on a wounded black - armored knight on the floor, digital painting, highly detailed, smooth, sharp contrast, digital painting, by artgerm and greg rutkowski and alphonse mucha, ukiyo - e"
    },
    {
        "display": "Cyberpunk portrait of Mexican woman.",
        "full": "very detailed masterpiece painting of a very beautiful wet young mexican cyberpunk woman with blue shutter shades, shaved one side haircut, dark purple hair, purple leather jacket, closeup, cyberpunk background, purple lighting, raining, portrait, artstation, concept art by greg rutkowski"
    },
    {
        "display": "Cute gnome face in autumn.",
        "full": "hand drawn cute one gnomes face in autumn, hill pumpkin, detailed closeup face, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Cats among daisies, Miyazaki style.",
        "full": "cats and daisies, painting by craig mullins, octane rendering, soft morning lighting, wide angle lens, in the style of hayao miyazaki, trending on artstation,"
    },
    {
        "display": "Watercolor New York Sunrise",
        "full": "new york at sunrise. watercolor. trending on artstation."
    },
    {
        "display": "Surreal portrait of Kim Kardashian.",
        "full": "amazing beautiful surreal psychedelic hyperdetailed portrait painting of a kim kardashian surrounded by wonderful things. hq. 8 k. trending on artstation"
    },
    {
        "display": "Julius Caesar's assassination, Manhattan.",
        "full": "the assassination of julius caesar in manhattan, highly detailed concept art, vibrant colors,"
    },
    {
        "display": "Anthro tiger soldier portrait.",
        "full": "portrait male anthro tiger dressed in military clothes character full body precis no blur, concept art, character sheet, nier automata, gaston bussiere, greg rutkowski, tsutomu nihei, cyberpunk, trending on artstation, featured on pixiv, hyper detail, cinematic composition, 8 k"
    },
    {
        "display": "Fantasy paladin portrait.",
        "full": "realistic portrait painting of a male fantasy paladin brute, old mystic ruins, afternoon, intricate, elegant, highly detailed, digital painting, sharp, focus, by artgerm and greg rutkowski"
    },
    {
        "display": "Heroic portrait of Daryl McCormack",
        "full": "Daryl McCormack as a hero, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and donato giancola and Joseph Christian Leyendecker, Ross Tran, WLOP"
    },
    {
        "display": "Cosmic queen portrait",
        "full": "a cosmic queen wearing a galaxy tiara, looking at the camera, beautiful and aesthetic, close up, dramatic pose, intricate, highly detailed, detailed face, smooth, sharp focus, specular light, occlusion shadow, artgerm, artstation, art by mika pikazo and ilya kuvshinov and rembrandt and greg rutkowski, fantasy illustration"
    },
    {
        "display": "Detailed Ahsoka Tano concept art.",
        "full": "very detailed concept art of ashoka tano from star wars, detailed, sharp"
    },
    {
        "display": "Futuristic spacesuit portrait.",
        "full": "Portrait of a Futuristic reflective spacesuit visor mirror spacesuit reflecting a nebula supernova in space, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Blonde boy with shield.",
        "full": "a blonde haired boy with green eyes spiky hair wielding an enormous armored shield. kingdom hearts concept art. Square enix. Tatsuya Nomura. By Shigenori Soejima."
    },
    {
        "display": "Middle-aged man in medieval attire",
        "full": "5 0 years old man, tall, stocky : : brown hair, sympathetic, designer stubble : : decorated medieval clothing : : high detail, digital art, rpg, concept art, illustration"
    },
    {
        "display": "Futuristic alien car design.",
        "full": "hyper real futuristic car, blade runner feel, alien technology, trending on artstation, syle of scott robertson and syd mead"
    },
    {
        "display": "Grotesque Victorian London landscape",
        "full": "stunning landscape of grotesque and horrifying london, victorian era!! lovecraftian horror, cosmic horror!! cinematic lighting, bloodborne, horror fiction, digital art, winning award masterpiece, fantastically beautiful, aesthetically inspired by wayne barlowe and gerald brom, trending on artstation, art by greg rutkowski and h r giger, octane render, 8 k"
    },
    {
        "display": "Business-suited girl illustration.",
        "full": "a girl wearing a business suit, grey hair, red necktie, sunglasses, cinematic, twintails, stunning, highly detailed, digital painting, artstation, smooth, hard focus, full body shot, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "\"Cybernetic Princess Portrait\"",
        "full": "close-up macro portrait of the face of a beautiful princess in a skull chrome mask, epic angle and pose, symmetrical artwork, 3d with depth of field, blurred background, cybernetic jellyfish female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. y Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Evil necromancer portrait.",
        "full": "evil necromancer, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Egyptian god portrait, hyperrealistic.",
        "full": "portrait of an egyptian god, fantasy ,volumetric lighting, intricate, elegant, hyperdetailed 3d matte painting, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, art by Makoto Shinkai and artgerm, hyperrealism, hyperrealistic, cinematic masterpiece, fantasy style 8k ultrahd octane render"
    },
    {
        "display": "Cyborg man portrait.",
        "full": "portrait of a man wearing cyborg armor, Javanese batik pattern, subject in the center of the frame, wide angle shot, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Gorilla in space armor",
        "full": "detailed science - fiction character portrait of a silverback gorilla wearing a white armored space suit, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk fitness character",
        "full": "Smug Maria. Female cyberpunk fitness babe wearing a cyberpunk tactical headset and jumpsuit. gorgeous face. Realistic Proportions. Concept art by James Gurney and Laurie Greasley. Moody Industrial skyline. ArtstationHQ. Creative character design for cyberpunk 2077."
    },
    {
        "display": "Demon goddess portrait, photorealistic.",
        "full": "perfectly - centered portrait of demon goddess, beautiful, gorgeous, cute, amazing, highly detailed, professional digital painting, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Gothic Berserk Batman Portrait",
        "full": "full body, full figure portrait of gothic gritty old berserk batman, full body shot, highly detailed dramatic lighting, artstation, atmospheric perspective, artgerm, intense contrast, 3 light sources, by lee bermejo, in alphonse mucha artstyle, by greg rutkowski and simon bisley with dale keown, in gary frank artstyle, by frank frazetta art, by clay mann art, by jorge jimenez art"
    },
    {
        "display": "Cyborg dinosaur concept art",
        "full": "detailed concept art of a cyborg dinosaur by james gurney in the style of yoshitaka amano, depth of field, unreal engine, 8 k, sharpness, artstation, award - winning realistic sci - fi concept art, in the style of james gurney."
    },
    {
        "display": "Cyborg male portrait.",
        "full": "cyborg, male, orange eyes, blue diodes, science fiction, highly detailed, digital painting, beautiful eyes, symmetry, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve and ilya kuvshinov! : : alphonse mucha : : - 0. 2"
    },
    {
        "display": "Mike Tyson as Marilyn Monroe",
        "full": "portrait of mike tyson as a young marilyn monroe, elegant, flat lighting, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, closeup, misa amane, art by simon bisley and greg rutkowski and alphonse mucha, novel cover"
    },
    {
        "display": "Galactic Levitating Dancer",
        "full": "full - length portrait of a levitating floating dancer made of a starry galaxy, with long flowing hair, with cute - fine - face, pretty face, realistic shaded perfect face, underwater, wearing a flowing sundress, fantasy, cinematic, fine details by realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Fantasy spiral stairway scene",
        "full": "big spiral stairways to heaven, inhabited on many levels, flying birds, shining light, strong perspective, clear geometry, architecture, glowing halo, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, art by anthony macbain + greg rutkowski + alphonse mucha, concept art, 4k, sharp focus, cinematic unreal engine"
    },
    {
        "display": "Steampunk biomechanical fly",
        "full": "steampunk cybernetic biomechanical fly with wings, 3 d model, unreal engine realistic render, 8 k, micro detail, intricate, elegant, highly detailed, centered, digital painting, artstation, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, wlop"
    },
    {
        "display": "Angry punk albino gorilla.",
        "full": "angry tough albino gorilla, punk gorilla with mohawk hair. interesting 3 d character concept by tiger hkn and gediminas pranckevicius, game art, hyper detailed, character modeling, cinematic, final fantasy, video game character concept, ray tracing, fur details, maya, c 4 d"
    },
    {
        "display": "Anime woman in kimono.",
        "full": "a woman in a kimono holding an umbrella, an anime drawing by Kaburagi Kiyokata, featured on pixiv, shin hanga, hellish background, pixiv, official art"
    },
    {
        "display": "Ultron vs. Robots Artwork",
        "full": "ultron killing robots by jama jurabaev, shaddy safadi, feng shu, neil blevins, very long shot, trending on artstation, high quality, brush stroke, for aaa game"
    },
    {
        "display": "Vibrant king's severed head.",
        "full": "severed head of a king, in the style of artgerm, charlie bowater, sam guay, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Adventurer with candle portrait.",
        "full": "an adventurer wearing a black sleeping cap and holding a candle, portrait, d & d, science fiction, concept art, matte, sharp focus, illustration, concept art,"
    },
    {
        "display": "Island-sized lion monkey hybrid",
        "full": "the legendary island sized lion monkey hybrid, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "Hooded warrior in fantasy.",
        "full": "full body epic scene of a hooded warrior with long black flowing robe, fantasy setting, dark fantasy style, elden ring, matte painting concept art, midjourney, beautifully backlit, swirly vibrant color lines, majestic, cinematic aesthetic, smooth, intricate, 8 k, by ilya kuvshinov, artgerm, darius zawadzki and zdizslaw beksinski"
    },
    {
        "display": "Cyberpunk cat girl tattoo",
        "full": "tattoo design, stencil, portrait of a cyberpunk japanese girl, artgerm, cat girl"
    },
    {
        "display": "Wounded French revolutionary hero.",
        "full": "a heavily wounded french revolutionary with a stoic look on his face, heroic, glorious, in the style of artgerm, gerald brom, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Aesthetic horror graveyard.",
        "full": "'Life from death' An aesthetic horror painting depicting 'A graveyard with plants and flowers growing, birds and insects flying around' by Wayne Barlowe, Trending on cgsociety artstation, 8k, masterpiece, cinematic lighting, vibrant colors."
    },
    {
        "display": "1970s ballroom dancer illustration",
        "full": "a beautiful dancer with black hair in 1970's fashion, ballroom background, intricate, highly detailed, digital painting, artstation, official media, anime key visual, concept art, rich vivid colors, ambient lighting, sharp focus, illustration, art by Artgerm, Makoto Shinkai, Ilya Kuvshinov, Lois Van Baarle, and Rossdraws"
    },
    {
        "display": "Car in flooded city",
        "full": "a car driving through shallow water, flooded city, muted colors, hyperrealistic, oil painting, intricate, cgsociety, artstation, 8 k, cinematic, soft lighting, by greg rutkowski, by wlop, by artgerm"
    },
    {
        "display": "Goddess portrait with bioluminescence.",
        "full": "goddess close-up portrait. bioluminiscent creatures, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Cyborg pilot in cockpit",
        "full": "comic book illustration, cinematic camera, a cyborg pilot in the cockpit of a battle droid, intricate machinery, biomechanics, the ghosts in the machine, cyberpunk concept art by artgerm and Guy Denning and Moebius and Ruan Jia, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart"
    },
    {
        "display": "Giant magenta mountain landscape",
        "full": "a professional photographic view picture of a giant magenta and white mountain ,photographic filter unreal engine 5 realistic hyperdetailed 8k ultradetail cinematic concept art volumetric lighting, fantasy artwork, very beautiful scenery, very realistic painting effect, hd, hdr, cinematic 4k wallpaper, 8k, ultra detailed, high resolution, artstation trending on artstation in the style of Albert Dros"
    },
    {
        "display": "Cyberpunk woman portrait",
        "full": "full body portrait, thin muscular cyberpunk woman, 6 pack ab, symmetrical beautiful face by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell ross tran"
    },
    {
        "display": "Court jester with holographic crystals",
        "full": "front shot of a court jester character, holographic crystals, ominous elegant detailed concept artstation sharp focus ray tracing cinematic masterpiece fine portrait, by artgerm and greg rutkowski and tooth wu and beeple"
    },
    {
        "display": "Cinematic female archangel queen.",
        "full": "a beautiful cinematic female archangel queen, fantasy sea landscape, fantasy magic, short aqua blue black fade hair, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Cottagecore Woman in Water",
        "full": "long shot, woman posing, short wavy hair, round face, intricate white dress, cottagecore!!, inside water, intricate, enlightened, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sandworm on Arrakis, cinematic art",
        "full": "a sandworm on arrakis by david a hardy, noriyoshi ohrai, gary ruddell, detailed, cinematic composition, trending on artstation, 4 k, concept art"
    },
    {
        "display": "Magnificent Pierrot Jester Carnival",
        "full": "award winning digital art of a magnificent pierrot jester wearing a traditional pierrot outfit, performing at a magnificent carnival, beautiful background, trending artstation, digital art, aesthetic, bloom, intricate, elegant, sharp focus, digital illustration, highly detailed, octane render, digital painting, concept art, art by ruan jia and greg rutkowski and sachin teng, masterpiece"
    },
    {
        "display": "Futuristic temple landscape",
        "full": "highly detailed futuristic temple architecture by Tadao Ando and, reflective lighting, holographic, stylized vegetation, ground-level view, stunning sunny lighting, sunrise, foggy atmosphere, waterfalls, vivid colors, in the style of pixar animation, trending on Artstation, 8k, matte painting, ray tracing, hyper detailed, unreal engine 5, cinematic, epic lighting, cryengine, octane render, cyberpunk, red and orange glow, vibrant"
    },
    {
        "display": "Psychedelic cyborg portrait in forest",
        "full": "bright psychedelic portrait of shy organic cyborg in an ancient forest, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Giant crow attacking scarecrow.",
        "full": "a giant crow pecking a scarecrow on a rice field, wlop, concept art, digital painting, trending on artstation, highly detailed, epic composition, 8 k uhd"
    },
    {
        "display": "Gothic woman in abbey",
        "full": "baroque gothic woman, lit by a single candle, inside a ruined abbey, a crow stood nearby, cobwebs, dead leaves, 4 k resolution, concept art, mist, autumnal, chiaroscuro,"
    },
    {
        "display": "Modern military chest armor",
        "full": "standard chest armor plating military modern era variants 2 0 5 0 trending on artstation hd 4 k"
    },
    {
        "display": "Mecha Krishna statue artwork",
        "full": "a statue of krishna mecha, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, trending on artstation, cinematic composition, beautiful lighting, hyper detailed, 8 k, oil on canvas"
    },
    {
        "display": "Sci-fi music club illustration",
        "full": "A music club with lighting elements from the movie the fifth element, gorgeous, beautiful, intricate, highly detailed, digital painting, artstation, oppressive lighting, sci-fi concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal portrait of girl.",
        "full": "Hyperrealistic beautiful sexy ethereal national geographic dark skinned girl portrait, art nouveau, fantasy, intricate flower designs, elegant, highly detailed, sharp focus, art by Artgerm and Greg Rutkowski and WLOP"
    },
    {
        "display": "Daemonette of Slaanesh Art",
        "full": "a beautyful fan art of a daemonette of slanesh, infernal background"
    },
    {
        "display": "Cute girl in Assamese fashion",
        "full": "a beautiful cute girl wearing modern stylish costume in the style of Assamese bihu mekhela sador gamosa design, commercial fashion design art by Victor Nizovtsev, face by artgerm and daz3d genesis iray, cinematic lightning, medium shot, mid-shot, slim female figure ramp walk model pose, highly detailed, trending on Artstation, Unreal Engine 4k, cinema 4d ray traced, octane render, cinematic wallpaper"
    },
    {
        "display": "Lifelike pencil illustration of Sachin Tendulkar.",
        "full": "amazing lifelike award winning pencil illustration of young Sachin Tendulkar trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Brass clockwork marionette.",
        "full": "full body shot of a mechanical clockwork marionette made of brass and gold with emerald crystal eyes, puppet, ornate, engraved highly detailed, fantasy, render, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, dishonored, art by piotr jablonski, arkane lyon, artgerm and greg rutkowski"
    },
    {
        "display": "Cyberpunk masked villain portrait",
        "full": "portrait of a cyberpunk masked male evil, half body, glowin eyes, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Dissolving woman sculpture.",
        "full": "sculpture of beautiful woman dissolving into shimmering dust, fractal paisley inlay, intricate, elegant, highly detailed, digital photography, art by artgerm ruan jia and greg rutkowski"
    },
    {
        "display": "Futuristic tactical man activating self-destruct.",
        "full": "concept art by greg rutkowski, a tall and slender man in wearing a futuristic tactical space suit, preparing to activate the ship's self - destruct sequence, claustrophobic and futuristic environment, scifi, detailed and intricate environment, high technology, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq."
    },
    {
        "display": "Arturo Vidal Street Fighter Portrait",
        "full": "arturo vidal as a street fighter character, cg animation, capcom, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Hisoka Morow Portrait",
        "full": "portrait of hisoka morow hunter hunter, male, small eyes very thin pursed lips extremely sharp jaw yellow eyes narrow almond eyes sultry eyes dark red hair soft hair slicked back crimson hair anime, madonna bowie elegant, highly detailed, digital painting, artstation art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Colorful fantasy candy forest",
        "full": "candy!! forest, cinematic scene, studio lighting, low poly, colorful, fantasy, fireflies, flowers, halloween, fairytale, ( matte painting, concept art, medium shot, trending on artstation )"
    },
    {
        "display": "Joyful Aztec girl portrait",
        "full": "aztec little girl, joyful, close - up portrait, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Alien artifact in outback",
        "full": "An alien artefact with diagrams and symbols from their culture, retrieved from a crashed interstellar probe, in the Australian outback, detailed fantasy art by Greg Rutkowski"
    },
    {
        "display": "Regal woman in water.",
        "full": "portrait of a beautiful woman wearing a shaolin robe, drenched body, wet dripping hair, emerging from the water, fantasy, regal, fractal crystal, fractal gems, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell ross tran"
    },
    {
        "display": "Scarlett Johansson portrait, POV.",
        "full": "Scarlett Johansson is talking to you POV, anatomy, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by art by artgerm and greg rutkowski and edgar maxence"
    },
    {
        "display": "Tree under vibrant sky",
        "full": "tree under beautiful sky, details, sharp focus, illustration, by jordan grimmer and greg rutkowski, trending artstation, pixiv, digital art"
    },
    {
        "display": "Sexy college girls portrait",
        "full": "three sexy college schoolgirls with pigtails and plaid skirts rolling hard on ecstasy, dilated pupils and glistening with sweat, realistic portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical cannabis leaf art",
        "full": "symmetry, multiple cannabis leaves in solid silhouettes, dancing, interacting, mooc, organic and intricate, elegant, highly detailed, concept art, sharp focus, illustration, high contrast, long shadows, painted with colour on white, 8 k"
    },
    {
        "display": "Superhero woman portrait",
        "full": "a beautiful portrait of a beautiful cute young superhero woman, blonde hair, tight solid matte navy - blue lycra bodysuit, white cape, emily ratajkowski, doutzen kroes, intricate, elegant, 8 k, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, by artgerm greg rutkowski alphonse mucha loish wlop"
    },
    {
        "display": "Yggdrasil concept art painting",
        "full": "painting of yggdrasil, by zdzislaw beksinski, by lewis jones, mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Lovecraftian zombie fusion portrait",
        "full": "rick and morty fused with a lovecraft super fat space zombie wearing a exploding wig and a pirate patch, laser eye, photo, portrait, 3 d, high details, intricate details, by vincent di fate, artgerm julie bell beeple, 9 0 s, smooth gradients, octane render, 8 k, volumetric lightning, high contrast, duo tone, depth of field, very coherent symmetrical artwork"
    },
    {
        "display": "Cyberpunk Elon Musk portrait",
        "full": "portrait of elon musk as a homeless character in cyberpunk 2 0 7 7, looking at camera, intricate, dystopian, sci - fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by artgerm and greg rutkowski and alphonse mucha and simon stalenhag"
    },
    {
        "display": "Female fighters in gym",
        "full": "a scene in the gym of two beautiful female fighters with hair tied up facing each other, gorgeous features, high definition, sharp focus, detailed anime art, pixiv"
    },
    {
        "display": "Ethereal portrait of man.",
        "full": "portrait of homely man, dreamy and ethereal, stupid expression, side angle, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Goddess portrait, hyperrealistic.",
        "full": "an portrait of a beautiful alluring female goddess, detailed, centered, digital painting, artstation, concept art, donato giancola, Dante Gabriel Rossetti, alphonse mucha, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Translucent samurai portrait",
        "full": "mid shot portrait of samurai with translucent skin, visible bones and muscle and veins and nerves and internal organs, in the style of david cronenberg, high fashion, id magazine, realistic, sharp focus, 8 k high definition, film photography, photo realistic, insanely detailed, by david kostic and stanley lau and artgerm"
    },
    {
        "display": "Blissful dystopian sci-fi.",
        "full": "blissful dystopia, dynamic lighting, photorealistic dark sci - fi concept art, trending on artstation, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Chibi bunny girl pixel art",
        "full": "original chibi bunny girl, ranking number 1 on pixiv, pixel art"
    },
    {
        "display": "Dystopian dream microphone",
        "full": "a dream microphone in a dystopic world full of aberration, black & white, melting, webbing, 8 k, by tristan eaton, stanley artgerm, tom bagshaw, greg rutkowski, carne griffiths, ayami kojima, beksinski, giger, trending on deviantart, face enhance, hyper detailed, minimalist, horror, alien"
    },
    {
        "display": "Photorealistic fruit heaven",
        "full": "photorealistic heaven made of fruit basket, detailed, centered, digital painting, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, breathtaking, 8 k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render, cinematic lighting, dramatic lighting, masterpiece"
    },
    {
        "display": "Hybrid dog-octopus fusion painting",
        "full": "painting of hybrid dog!!! and ( ( ( ( octopus ) ) ) ) fused together, intercrossed animal, by zdzislaw beksinski, by lewis jones, mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Emilia Clarke in 1800s corset.",
        "full": "Emilia Clarke smiling warmly in a 1800s American Corset in Red Dead Redemption, detailed Smile, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by art by artgerm and greg rutkowski and edgar maxence"
    },
    {
        "display": "Hyper-detailed samurai Spiderman.",
        "full": "full samurai armor Tenchu spiderman , muscle extremely detailed, fantastic details full face, mouth, trending on artstation, pixiv, cgsociety, hyperdetailed Unreal Engine 4k 8k ultra HD,  WLOP"
    },
    {
        "display": "Punk zombie portrait illustration",
        "full": "Highly detailed portrait of pretty punk zombie young lady with, freckles and beautiful hair by Atey Ghailan, by Loish, by Bryan Lee O'Malley, by Cliff Chiang, inspired by image comics, inspired by graphic novel cover art, inspired by izombie, inspired by scott pilgrim !! Gradient red, black and white color scheme  ((grafitti tag brick wall background)), trending on artstation"
    },
    {
        "display": "Cute cat girl portrait",
        "full": "ultra nekopara fantastically detailed reflecting eyes modern anime style art cute detailed ears cat girl neko dress portrait  shinkai makoto vibrant Studio ghibli kyoto animation hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv cyberpunk"
    },
    {
        "display": "Gal Gadot in Cosmos",
        "full": "Gal Gadot floating in the Cosmos, anatomy, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by art by artgerm and greg rutkowski and edgar maxence"
    },
    {
        "display": "Luxurious Kyoto-inspired landscape",
        "full": "Kyoto elegant streets, high-end civilization, luxurious onsens, with lush expensive Italian sakura pink garden, Japanese bathhouse, onsens, located on Waimea canyon in Kauai, Pamukkale, beautiful smooth sandstone in unique shapes with light beams that shine through its walls, gold striated swirling finish, white travertine terraces, digital painting, concept art, smooth, sharp focus, from Star Trek 2021, illustration, by WLOP and Ruan Jia and Mandy Jurgens and William-Adolphe Bouguereau, Artgerm"
    },
    {
        "display": "Scarlett Johansson as Queen",
        "full": "Scarlett Johansson as the Queen of Greece, anatomy, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by art by artgerm and greg rutkowski and edgar maxence"
    },
    {
        "display": "Gigantic white tank monster",
        "full": "portrait of a gigantic white monstrosity, a fat tank monster made of melted white bodies, concept art, deep focus, fantasy, intricate, highly detailed, digital painting, artstation, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gorilla with portrait, hyperrealistic",
        "full": "Gorilla standing beside a framed portrait of a man wearing a novelty cap with a propeller, hyperrealistic, concept art, 8k, artstation, cinematic, volumetric lighting"
    },
    {
        "display": "Iridescent futuristic cityscape",
        "full": "big iridescent city with a few extremely tall spiking towers, unreal engine 5, art by artgerm and greg rutkowski and alphonse mucha, global illumination, detailed and intricate environment, hyperrealistic, volumetric lighting, epic cinematic shot, perfectly defined features, ambient occlusion"
    },
    {
        "display": "Realistic octopus portrait",
        "full": "portrait painting of an octopus, ultra realistic, concept art, intricate details, extremely detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and dan mumford and alphonse mucha and studio ghibli, masterpiece, award - winning"
    },
    {
        "display": "Fantasy thief portrait, detailed.",
        "full": "Portrait of fantasy thief, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "TV-headed muscular blacksmith.",
        "full": "! dream artwork of a tv - headed muscular blacksmith man with a tv on his head, cool style, concept art, super detailed, 4 k hd, trending on artstation, digital painted, low contrast, made by greg rutkowski and viktoria gavrilenko"
    },
    {
        "display": "Cyborg with glowing features",
        "full": "Panorama screenshot beautiful female cyborg, beautiful glowing long flowing silver hair, super bright gold glowing eyes, wearing a cowboy hat, photographic highly detailed desert, hybrid from The Elden Ring and art direction by Darius Zawadzki ;by artgerm; wayne reynolds art station; cinematic quality character render; low angle; ultra high quality model; production quality cinema model"
    },
    {
        "display": "Floating winged aasimar paladin",
        "full": "portrait of a floating winged aasimar paladin blond young man with amber eyes strong, sofisticated, fantasy, highly detailed, digital painting, artstation, concept art, character art, art by greg rutkowski and tyler jacobson and alphonse mucha"
    },
    {
        "display": "Cyborgs arguing in AI city.",
        "full": "cyberpunk rpg concept art of ai city, main district. a group of three cyborgs stand outside the door of the guild house, arguing loudly about something. one cyborg holds a machine gun with a glowing gem on it, illuminating the faces of his companions, while another cyborg, wearing a golden cloak, leans against the the big hole, listening intently. digital art. trending on artstation."
    },
    {
        "display": "Detailed portrait of elderly man.",
        "full": "Old man, vivid blue eyes, bushy white beard, digital painting, lots of details, directed gaze, extremely detailed, 4k, intricate, brush strokes, Mark Arian, Artgerm, Bastien Lecouffe-Deharme"
    },
    {
        "display": "Goblin chieftain portrait",
        "full": "a mind - blowing portrait of a goblin chieftain, wearing a skeleton armor, green, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic school office concept",
        "full": "school office, cinematic view, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, art greg rutkowski"
    },
    {
        "display": "Warrior woman at sunrise.",
        "full": "an pretty warrior woman stands on a cliff during a sunrise, artistic composition, art style of hajime sorayama, cinematic, highly detailed, sharp focus, intricate concept art, digital painting, colorful flat surreal design, hd, 8 k, artstation, ambient lighting"
    },
    {
        "display": "Glowing forest cityscape.",
        "full": "water, city, life, forest, glowing lights!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by ( ( ( artgerm ) ) ) and greg rutkowski! and ( ( alphonse mucha ) )"
    },
    {
        "display": "Elegant female portrait.",
        "full": "a gorgeous female photo, professionally retouched, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, wide angle, sharp focus on eyes, 8 k high definition, insanely detailed, intricate, elegant,"
    },
    {
        "display": "Angelic werewolf in cathedral",
        "full": "A epic and beautiful rococo painting of a angelic werewolf inside a burning cathedral. demon slayer style. ultra-detailed. Anime, pixiv, UHD 8K CryEngine, octane render"
    },
    {
        "display": "Droid trash bin concept",
        "full": "droid in a form of a trash bin with tank treads with a giant x on its face monitor, 4 k, concept art, trending on artstation"
    },
    {
        "display": "Bob the Builder Titanic Fantasy",
        "full": "bob the builder in the movie titanic, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intergalactic Stalin Portrait",
        "full": "a portrait of intergalactic joseph stalin, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Mysterious woman portrait.",
        "full": "a beautiful portrait matte painting of a mysterious woman, by bastien lecouffe deharme, trending on artstation, 4k wallpaper, concept art."
    },
    {
        "display": "Hooded mage with blue fire",
        "full": "Front portrait of a hooded mage hold a blue fire and detailed staff, full body, fine art, awesome fantasy book cover on Pinterest, award winning, dark fantasy landscape, fantasy magic, intricate, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, concept art, art by WLOP and Artgerm and Greg Rutkowski, masterpiece, trending on artstation, 8K"
    },
    {
        "display": "Wizard Shrek Fantasy Art",
        "full": "shrek, dressed as a wizard, yellow skin, with horns, d & d, fantasy, intricate, highly detailed, digital painting, artstation, concept art, matte, sharp, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Orangutan in hot rod",
        "full": "cute and funny, orangutan wearing a helmet riding in a tiny hot rod with oversized engine, ratfink style by ed roth, centered award winning watercolor pen illustration, isometric illustration by chihiro iwasaki, edited by range murata, tiny details by artgerm, symmetrically isometrically centered"
    },
    {
        "display": "Goth girl portrait, cyberpunk.",
        "full": "portrait of beautiful cute goth girl with glasses and piercing and tattoos and ( ( braided hair ) ), cyberpunk, high details, neon, art by ( ( ( kuvshinov ilya ) ) ) and wayne barlowe and gustav klimt and artgerm and wlop and william - adolphe bouguereau and greg rutkowski"
    },
    {
        "display": "Colossal beasts in alien swamp.",
        "full": "colossal beasts walk through a swamp on alien planet, illustration, chaotic, lush, forest soul, mist, elden ring, opalescent night background, colossal, volumetric lighting, soft lighting, soft details, painting oil on canvas by Edmund Blair Leighton and charlie bowater octane render, HDR, trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Astral shaman warrior painting",
        "full": "technological shaman warrior, astral spirit space journey in oil painting, ayahuasca, trending on artstation, award winning, emotional, facing darkness galactic nebula astral realm sacred journey in oil painting, trending on artstation, award winning, emotional, highly detailed, by aoshima chiho, by beeple, by afshar petros, by rhads, black and gold color scheme, blue and white color scheme"
    },
    {
        "display": "Futuristic All Might painting",
        "full": "all might from my hero academia, futuristic, gta 5 cover art, oil painting, comic book black lines, alot of paint smears, blonde sharp hair, hero outfit, fine details, sharp focus, intricate, realistic shaded perfect face, by cushart krenz, by makoto shinkai, by wlop, by artgerm"
    },
    {
        "display": "Seductive sorceress portrait.",
        "full": "seductive looking sorceress prays!!! magic the gathering, windy hair!, surreal dark fantasy, cinematic volumetric lights, portrait, dnd, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and tom bagshaw and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk zombie lynx fursona",
        "full": "wide angle beautiful full body portrait of a strong female damaged zombie anthropomorphic anthro white lynx fursona wearing a steampunk dress toothsome open mouth. character design by disney, anime, manga, charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, soft lighting, rendered in octane"
    },
    {
        "display": "Intricate Carnivale Portrait",
        "full": "carnivale, perfectly-centered-Portrait,  action pose, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic portrait of Nicole Aniston.",
        "full": "nicole aniston wearing a business suit, grey hair, red necktie, cinematic, stunning, highly detailed, digital painting, artstation, smooth, hard focus, full body shot, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk goth torso shot",
        "full": "torso shot of a beautiful cybernetic punkrock goth, cyberpunk concept art by ilya kuvshinov and wlop and artgerm and guweiz and josan gonzalez, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Tribal goddess portrait.",
        "full": "a centered render of an alluring tribal goddess full body gorgeous face perfect face powerful, by anna dittmann 3 d trending on artstation, octane render, 8 k"
    },
    {
        "display": "Rustic decay aesthetic.",
        "full": "from rust we are created and to rust we shall return, beautiful high quality realistic, trending on artstation"
    },
    {
        "display": "Giant eye portrait, fantasy.",
        "full": "portrait of a giant eye floating in the air, d & d, muscular! fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime redhead casting ice.",
        "full": "a portrait of anime redhair casting ice - ball and shoot it, cyberpunk concept art, trending on artstation, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Zombie capybara in clothing",
        "full": "detailed full body concept art illustration oil painting of an anthropomorphic capybara zombie in full intricate clothing, biomutant, dystopian, ultra detailed, digital art, octane render"
    },
    {
        "display": "Butterfly portrait of Daenerys.",
        "full": "a butterfly lighting portrait painting of daenerys targaryen in fantastic dress next to a dragon, medium shot, clear face, detailed face, symmetrical face, face enhancement, rim light, by jaime jones, tom bagshaw, lawrence alma - tadema, greg rutkowski, deviantart contest winner, fantasy art, intricate, elegant, highly detailed, 8 k, digital painting, concept art, sharp focus, illustration, golden ratio, mythological, ultra realistic, maximalist"
    },
    {
        "display": "Detailed war axe artwork",
        "full": "digital artwork, front view of a war axe. trending on artstation. zbrush, pbr, sculpt, unreal engine 5, concept art, gaming. 8 k"
    },
    {
        "display": "Rimworld Character Concept Art",
        "full": "closeup of Rimworld Character Pawn, Concept art youtube thumbnail trending on ArtStation"
    },
    {
        "display": "Nietzsche Comic Cover Art",
        "full": "Friedrich Nietzsche with a chiseled Jawline and serious Look, in his suit, in the Style of Artgerm and Charlie Bowater and Atey Ghailan and Mike Mignola, vibrant colors and hard shadows and strong rim light, Comic Cover Art, plain background, trending on artstation"
    },
    {
        "display": "Photorealistic Galaxy in Bottle",
        "full": "Galaxy in a Bottle sitting on a white table in a brightened room, rendered by, Xie Boli, Max Hay, Cameron Mark, and Hue Teo, artstation 3d, artstation render, artstation 3d render, 3d art, unreal engine 3d, octane 3d, blender 3d, 3d landscape, photorealistic imagery, photorealistic details, intricate, highly detailed, trending on artstation, 4k, 8k"
    },
    {
        "display": "Kanye eating crayon, hyperrealistic.",
        "full": "photo of 8k ultra realistic Kanye eating crayon, clear sky, full of colour, cinematic lighting, battered, trending on artstation, 4k, hyperrealistic, focused, extreme details,unreal engine 5, cinematic, masterpiece, art by John Harris"
    },
    {
        "display": "Korean bodybuilder portrait",
        "full": "a korean bodybuilder college boy, bokeh, beautiful face!!!!, 2 3 years old, cg animation, lifelike, animated, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Palatial room in bloom.",
        "full": "a beautiful picture of room in full bloom, palace \uff0c a thom yorke with pretty details face,, by mendoza, larry elmore, donato giancola and thomas kinkade, trending on artstation"
    },
    {
        "display": "Cinematic Tokyo Suburb Wanderer",
        "full": "lone wanderer in a highly detailed cinematic tokyo suburb, psychedelic, amazing, by feng zhu, wayne barlowe, perfect geometry, hdr, 4 k, hyper - detailed, sharp, beautiful, desaturated, beautiful lighting, oil on canvas, sunset, cinematic composition, trending on artstation, gnomon"
    },
    {
        "display": "Great Fauna Titan Design",
        "full": "the great fauna titan design by karol bak, james jean, tom bagshaw, rococo, sharp focus, trending on artstation, cinematic lighting, hyper realism, octane render, 8 k, hyper detailed, vivid, ultra detailed, highly detailed"
    },
    {
        "display": "90s comic character design",
        "full": "90s comic book character design, character sheet, 3d scene, Greg Rutkowski, kim jung gi, Zabrocki, Karlkka, Jayison Devadas, Phuoc Quan, trending on Artstation, 8K, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Beautiful girl in feather dress",
        "full": "beautiful little girl with a short black haircut wearing a dress made of black feathers, artwork in artgerm art style, anatomically perfect"
    },
    {
        "display": "Emily in vibrant classroom.",
        "full": "richly detailed color  illustration of Emily wearing a tank top seeing weird events in a classroom, very soft shadowing, smooth textures, beautiful composition, illustrated by Range Murata and Artgerm."
    },
    {
        "display": "Elegant Sansa Stark portrait",
        "full": "portrait of sansa stark with long red hair, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Nordic woman portrait art",
        "full": "concept art by jama jurabaev, portrait of a beautiful nordic woman, fine - face, cinematic shot, trending on artstation, high quality, brush stroke,, vibrant colors, blue eyes"
    },
    {
        "display": "Joyful red-haired maiden",
        "full": "beautiful cute red haired joyful and playful 1 9 year old maiden standing up, long hair, sci - fi, fantasy, intricate, elegant, digital painting, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Lovecraftian wildebeest portrait.",
        "full": "a realistic and atmospheric watercolour fantasy character concept art portrait of a 4 0 ft. tall lovecraftian wildebeest wearing a robe and emerging from the mist on the moors of ireland at night. by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Cute red-haired girl in cyberpunk city",
        "full": "beautiful cute red haired eighteen year old young girl standing up, casual green clothing, long hair, cyberpunk city, cute face, slightly smiling, rpg character, sci - fi, intricate, elegant, digital painting, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Bobcat on log, line art",
        "full": "bobcat standing alone on a log, by Currier & Ives, black and white, line art, pen & ink drawing, character concept, low detail"
    },
    {
        "display": "Iridescent-haired girl portrait.",
        "full": "girl with iridescent red hair, beautiful highly detailed face, complementary lighting, backlit, black eyeshadow, black lipstick, divine, dramatic lighting, landscape background, beautiful painting by artgerm and greg rutkowski and raymond swanland"
    },
    {
        "display": "Detailed owl portrait",
        "full": "portrait of an owl, style game square enix life, trending on artstation, painted by greg rutkowski, render naughty dog, octane render, detailed"
    },
    {
        "display": "Mimic monster illustration",
        "full": "A mimic, DnD, blak and white, fantasy art, monster art, in the style of masami kurumada, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Rugged female witch portrait",
        "full": "fantasy art, unsplash, deviantart, close up portrait of a ruggedly female witch, soft hair, half body, leather, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ultra-realistic Gal Gadot painting",
        "full": "painting of gal gadot, bikini armor, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate Slipknot Portrait",
        "full": "portrait of slipknot band, bian lian, traditional chinese art, intricate, elegant, highly detailed, symmetry, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Jordan Peterson in psychedelic enlightenment.",
        "full": "jordan peterson's face thinking deeply in apsychedelic transcendent, pillars of creation, enlightenment, high contrast lighting, refracted sunset, highly detailed, concept art, art by collier,"
    },
    {
        "display": "Fantasy illustration of Big Ben.",
        "full": "big ben london, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Stern woman sculpture portrait",
        "full": "hyperrealistic sculpture portrait of a stern woman's face carved out of a block of red cedar wood on a pedestal by duane hanson and donald judd, hyperrealistic dramatic colored lighting trending on artstation 8 k"
    },
    {
        "display": "Fantasy RPG portrait painting",
        "full": "a _ fantasy _ style _ portrait _ painting _ of male, dark blonde hair and stubble, smiling, white, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Drunk minion drinking beer",
        "full": "an epic painting minion drinking beer from big mug and he really drunk, pencil drawing, perfect composition, golden ratio, beautiful detailed, photorealistic, digital painting, concept art, smooth, sharp focus, illustration, artstation trending, octane render, unreal engine"
    },
    {
        "display": "Elizabeth Olsen as fertility goddess",
        "full": "elizabeth olsen as the goddess of fertility, golden ratio!!!!!, centered, trending on artstation, 8 k quality, cgsociety contest winner, artstation hd, artstation hq, luminous lighting"
    },
    {
        "display": "Mage with dual fire.",
        "full": "Front portrait of mage hold a blue fire on right hand and red fire on the left hand, fine art, awesome fantasy book cover on Pinterest, award winning, dark fantasy landscape, fantasy magic, intricate, elegant, sharp focus, highly detailed, digital painting, concept art, art by WLOP and Artgerm and Greg Rutkowski, masterpiece, trending on artstation"
    },
    {
        "display": "Sexy spy portrait, Overwatch style",
        "full": "greg manchess portrait painting of a sexy spy girl as an overwatch character, medium shot, asymmetrical, profile picture, organic painting, night time, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Cyberpunk Samurai Portrait",
        "full": "painting of olivia hye loona cyberpunk samurai, perfect face, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Cyborg head in barbed wire.",
        "full": "organic cyborg head wrapped in barb wire by Hajime Sorayama and Jamie Coreth, trending on artstation, centered, symmetrical, electric hair, bilateral symmetry, 80s poster, polished, thick smoke, retro dark vintage sci-fi, 2D matte illustration"
    },
    {
        "display": "Portrait of Jennifer Lawrence",
        "full": "close up Portrait of Jennifer Lawrence as beautiful young teen girl wearing assamese bihu mekhela sleeveless silk saree and gamosa in Assam tea garden, XF IQ4, 150MP, 50mm, F1.4, ISO 1000, 1/250s, model photography by Steve McCurry in the style of Annie Leibovitz, face by Artgerm, daz studio genesis iray attractive female, gorgeous, detailed  anatomically correct face!! anatomically correct hands!! amazing natural skin tone, 4k textures, soft cinematic light, Adobe Lightroom, photolab, HDR, intricate, elegant, highly detailed,sharp focus"
    },
    {
        "display": "Stylized portrait of a man.",
        "full": "50 year old brown hair man with very short hair, thinning hair,  round face, circle face, circular face, wide face, square face, round jaw, wide chin , romanian, silver small glasses,  romanian heritage, brown eyes, brown eyes, olive skin, round nose, round chin, clean shaven wide face, thin lips, small mouth, digital art, concept art, cgsociety, painterly, painting, brush strokes, 8k, illustration, painting, dramatic,  beautiful, art by loish loish loish, cartoon, stylized  painterly, trending on artstation, medium shot, uncropped"
    },
    {
        "display": "Sumerian woman in city",
        "full": "A well dressed sumerian woman in a major Mesopotamian city, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy magician portrait",
        "full": "in the style of throne of glass book cover, whole body portrait of a young adult female magician casting a spell with fireballs in her hands, blue and green magic lights aura, a portal with elvish symbology opened, d & d, fantasy, highly detailed, digital art, trending on artstation, smooth, sharp focus, illustration, art by artgerm and hirokazu yokohara, greg rutkowski"
    },
    {
        "display": "Anorexic zombie army portrait",
        "full": "clear portrait of an army of anorexic zombies, hideous appearance, ripped clothing, golden hour, malnourished, cottagecore!!, background hyper detailed, character concept, full body, dynamic pose, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime woman in kimono",
        "full": "an anime portrait of ssunbiki as a beautiful woman wearing a kimono from skyrim, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Intricate goddess skeleton portrait",
        "full": "goddess close-up portrait human skeleton, ram skull, skeleton, thorax, x-ray, backbone, jellyfish phoenix head, nautilus, orchid, skull, betta fish, bioluminiscent creatures, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Armored Angel in Forest",
        "full": "modern Angel standing in the front of a forest . Angel is wearing an armor. angel is draped with bones. Concept art. Anatomical details. Digital painting. Art station. Mood lighting. Skindness, highly detailed, concept art, intricate, sharp focus,  - h 1200"
    },
    {
        "display": "Futuristic Jamaican fisherman portrait",
        "full": "portrait of a male jamaican fisherman sci - fi glowing fishing armor muscular caribbean intricate elegant highly detailed digital painting artstation concept art, ocean background, jamaican colors, cinematic, greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw"
    },
    {
        "display": "Kelly Slater portrait, artistic styles.",
        "full": "kelly slater portrait, made by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, artstation, cgsociety"
    },
    {
        "display": "Psychedelic extradimensional being",
        "full": "a psychedelic being living in an extradimensional reality, in the style of wlop, illustration, epic, fantasy, hyper detailed, smooth, unreal engine, sharp focus, ray tracing, physically based rendering, renderman, beautiful"
    },
    {
        "display": "Organic space fleet design",
        "full": "fleet of terrifying organic space ships, sharp focus, concept art"
    },
    {
        "display": "Child Yuuki Asuna playing guitar",
        "full": "a child yuuki asuna playing guitar, front view, mid - shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "Vibrant neon portrait.",
        "full": "a award winning half body portrait of a beautiful woman with stunning eyes in a croptop and cargo pants with rainbow colored hair, head moving and hair flying, outlined by whirling illuminated neon lines, outrun, vaporware, shaded flat illustration, digital art, trending on artstation, highly detailed, fine detail, intricate"
    },
    {
        "display": "Blushing anime cat-boy portrait",
        "full": "character portrait of a blushing anime boy with cat ears and tail, blue hoodie, brown fluffy hair, medium shot, concept art, global illumination, tankoban, 4 k, oil on canvas, fantasy painting, hyper detailed, pixar animation, studio light, award winning, by artgerm, sylvain sarrailh, rossdraws, wlop, beautiful!."
    },
    {
        "display": "Art Nouveau girl portrait",
        "full": "portrait of a lisa frank, thomas kinkade, wes anderson gustav klimt girl holding ferret, intricate, elegant, art nouveau, tarot card, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "High-tech sun with steel bands.",
        "full": "sun surrounded by steel bands. high tech. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Fantasy warrior portrait.",
        "full": "warrior, leather armor!!! long wild white hair!! covered chest!!! fantasy, d & d, intricate ornate details, digital painting, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Curly-haired girl with banjo.",
        "full": "a highly detailed illustration of long curly haired african girl wearing cowboy hat and brown poncho, dramatic playing banjo smiling pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, WLOP"
    },
    {
        "display": "Stylish cyberpunk woman portrait",
        "full": "stylish woman cartoon portrait made out of rain, pinstripe suit, top hat, cyberpunk background, rendered in octane, unreal engine, highly detailed, trending on artstation, realistic, neon, beautiful"
    },
    {
        "display": "Cosmic android portrait.",
        "full": "beautiful possessed latin female android fused with lovecraft, cosmic entity, the thing, the blob, cable wires as hair ghostly, portrait, intricate details, by vincent di fate, artgerm, julie bell, beeple and Greg Rutkowski, 80s, concept, Smooth gradients, octane render, 8k, High contrast, duo tone, depth of field, very coherent symmetrical artwork"
    },
    {
        "display": "WWI Mech with Putin",
        "full": "world war one era mech, vladimir putin, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyper-realistic universe artwork",
        "full": "a hyper realistic professional photographic view picture of the beautiful universe, photographic filter unreal engine 5 realistic hyperdetailed 8k ultradetail cinematic concept art volumetric lighting, fantasy artwork, very beautiful scenery, very realistic painting effect, hd, hdr, cinematic 4k wallpaper, 8k, ultra detailed, high resolution, artstation trending on artstation in the style of Albert Dros glowing rich colors powerful imagery nasa footage drone footage drone photography"
    },
    {
        "display": "Dark fantasy acrylic painting.",
        "full": "a beautiful dark acrylic painting of a dark fantasy land by Raja Ravi Varma and Gediminas Pranckevicius, trending on ArtStation."
    },
    {
        "display": "High-tech white Ferrari.",
        "full": "concept art of white ferrari with railgun on each side and propulsor in the back, hyperdetailed, artstation, cgsociety, 8 k"
    },
    {
        "display": "Close-up of Apollo",
        "full": "a beautiful close - up of a young white - haired! greek god apollo with very long curly hair and thin - eyebrows!, digital art by edward hopper, vibrant color scheme, highly detailed, in the style of romanticism, fine art, high detail, great lighting, 8 k resolution, masterpiece, concept art, illustration, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, hdr, trending on artstation, 4 k, 8 k, hd"
    },
    {
        "display": "Collapsing stairway to heaven.",
        "full": "a stairway to heaven that is collapsing, art by artgerm and greg rutkowski and alphonse mucha, featured in artstation, octane render, cinematic, elegant, intricate, highly detailed, fantasy, concept art, sharp focus, illustration, 8 k"
    },
    {
        "display": "Art Nouveau frame design",
        "full": "an intricate art nouveau frame edges only, black center, highly detailed, artstation, concept art, matte, sharp focus,"
    },
    {
        "display": "Blue winged wolf in fantasy.",
        "full": "blue feathered wolf with wings on a beautiful fantasy landscape, facing front, hills, mountains, moonlit, hd, illustration, epic, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wallpaper, art by artgerm and greg rutkowski and alphonse mucha and jin xiaodi"
    },
    {
        "display": "Regal sleeping beauty coffin.",
        "full": "an elaborate coffin with a mysterious sleeping beauty holding a large bouquet of flowing flowers, top view, fantasy, regal, intricate, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell"
    },
    {
        "display": "Miniature village scene",
        "full": "Gediminas Pranckevicius, Very nitid scene close view of miniature village with river, small waterfall, chimney, church, bakery, sallon,  with  stunning 3d render + dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, intricate,  , ray of sunlight, detailed painterly digital art style by WLOP and , 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, intricate, epic composition, sparkling atmosphere, cinematic lighting + masterpiece, trending on artstation, very detailed, vibrant colors, hyperrealistic, smooth, sharp focus, lifelike, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha diffuse lighting, fantasy, intricate, elegant, highly detailed"
    },
    {
        "display": "Black and white woman headshot",
        "full": "headshot of a beautiful woman in black and white, art by diego fazio and diegoKoi and oscar Ukono, concept art, sharp focus, artgerm, 8k highly detailed"
    },
    {
        "display": "\"Romantic yet haunting landscape\"",
        "full": "a fine painting of a glorious place where love conquers all yet death resides there, 8 k, ultra realistic, lens flare, atmosphere, glow, detailed, intricate, full of colour, cinematic lighting, trending on artstation, 4 k, hyperrealistic, focused, extreme details, unreal engine 5, cinematic, masterpiece"
    },
    {
        "display": "Surreal WWII Maid Fighter",
        "full": "realistic detailed image of a japanese girl fighting ww2 in a maid uniform in the style of Francis Bacon, Surreal, Norman Rockwell and James Jean, Greg Hildebrandt, and Mark Brooks, triadic color scheme, By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Edward Hopper and Norman Rockwell and Beksinski, dark surrealism, open ceiling, highly detailed, painted by Francis Bacon, painted by James Gilleard, surrealism, by Nicola Samori, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Ancient Chinese Beauties Illustration",
        "full": "ancient chinese beauties, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha and victo ngai"
    },
    {
        "display": "Female hunter cosplay fanart",
        "full": "Hunt Showdown fanart cosplay female hunter, modeling, posing, trenchcoat, white shirt, quality lighting, dark colors, maximalism, ultra facial detail, epic, gta 5 skin tone, Tooth Wu Artgerm Greg Rutkowski artstation deviantart, 8k, fanart, extreme aesthetic"
    },
    {
        "display": "Fantasy changeling guitarist illustration",
        "full": "fantasy changeling kid playing electric guitar, dim light, front game card, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by ruan jia and mandy jurgens and artgerm and wayne barlowe and greg rutkowski and zdislav beksinski"
    },
    {
        "display": "Smiling bearded figure portrait",
        "full": "photo of a smiling, short, broad figure with a long beard and broad face in the style of stefan kostic, realistic, sharp focus, 8k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Buff Betty White Lumberjack",
        "full": "a buff and sweaty betty white as a beardless lumberjack chopping wood, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Rugged swordsman portrait",
        "full": "portrait of a young, ruggedly handsome swordsman, soft hair, muscular, half body, leather, hairy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Goblin merchant in fantasy store",
        "full": "A merchant selling treasuries, highly detailed store, fantasy art, goblin art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Muscular mermaid artwork",
        "full": "mermaid bodybuilder, 4 k, trending on artstation, artstationhd, artstationhq, octane render, super detail"
    },
    {
        "display": "Medieval noble boy portrait",
        "full": "half - length portrait of a 1 5 - year - old human male noble with long blonde hair and green eyes, no beard, medieval setting, detailed face, highly detailed, artstation, concept art, sharp focus, by greg rutkowski, craig mullins, artgerm"
    },
    {
        "display": "Cute low-poly mushroom island",
        "full": "a cute little matte low poly isometric mushroom island, waterfalls, lat lighting, soft shadows, trending on artstation, 3d render, monument valley, fez video game,"
    },
    {
        "display": "Cyberpunk Darth Vader portrait",
        "full": "symmetrical - face!! portrait shot of darth vader in cyberpunk 2 0 7 7 by beksinski, realistic, professionally color graded, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Steampunk Green Ultron Portrait",
        "full": "portrait of a green Ultron from Age of Ultron, clockwork steampunk, head and chest only, by Beksinski, 4k, deviantart, trending on artstation"
    },
    {
        "display": "Gareth on Wall Street",
        "full": "a beautiful illustration of gareth from the movie labyrinth running around on wall street, concept art, trending on artstation, amazing"
    },
    {
        "display": "Pitbull in wasteland, photorealistic",
        "full": "pitbull the rapper in a post apocalyptic wasteland holding a microphone, by cedric peyravernay, highly detailed, excellent composition, photorealistic, cinematic concept art, dramatic lighting, trending on artstation"
    },
    {
        "display": "Evil anime portrait",
        "full": "photo portrait of hisoka hunter x hunter moira overwatch inspired male evil sharp features yellow iris, small narrow slit shrewd yellow eyes, red red soft crimson hair smirk anime hunterpedia pixiv fanbox concept art, matte, sharp focus, illustration, ross tran ruan jia"
    },
    {
        "display": "Galion vs. Giant Octopus",
        "full": "coloured pencil drawing of a wonderful an big galion attacked by a giant octopus, rainy day an big waves. d & d, illustration, realism, trending on artstation"
    },
    {
        "display": "Exploding hearts in fantasy village.",
        "full": "a collision of exploding black hearts and white hearts in realistic form of an endlessly long and winding path through an isometric fantasy village, vivid vibrant geometric art nouveau. trending on pixiv and artstation. an absurdly detailed oil painting"
    },
    {
        "display": "Jetpack concept art",
        "full": "concept art by jama jurabaev, jetpacks, cinematic shot, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Character study: Paul Dano, Todd Solondz",
        "full": "character study of paul dano and todd solondz, 2 0 2 2, clear faces, emotional, character sheet, fine details, concept design, contrast, kim jung gi, pixar and da vinci, trending on artstation, 8 k, full body and head, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Young Asian woman in mecha",
        "full": "a young attractive Asian woman in the pilot's seat of a massive sci-fi mecha, both arms at her sides, dramatic pose, chrome, blue LEDs, highly detailed, photorealistic, volumetric lighting, digital art, octane render, in the style of Artgerm and Tom Bagshaw"
    },
    {
        "display": "Alien portrait with tubes.",
        "full": "asymmetrical!! portrait of an alien with large tubes in face in the style of, machine face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, horizon zero dawn 8 k"
    },
    {
        "display": "Cyberpunk Normani Profile Picture",
        "full": "cyberpunk Normani as aeon flux profile picture by Greg Rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell, metal chrome, shiny, rainy background, asymmetric,"
    },
    {
        "display": "Toucan-costumed lady portrait",
        "full": "toucan costume toucan mask lady, baroque oil painting illustration concept art key visual portrait symmetrical perfect face fine detail delicate features quiet gaze bokeh trending pixiv fanbox by makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Elegant princess portrait",
        "full": "portrait of of young beautiful female princess, d & d, baroque dress, elegant, flat lighting, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, closeup, misa amane, art by simon bisley and greg rutkowski and alphonse mucha, natural tpose"
    },
    {
        "display": "Spiderman Wolverine mashup",
        "full": "Spiderman as Wolverine,muscle extremely detailed, fantastic details full face, mouth, trending on artstation, pixiv, cgsociety, hyperdetailed Unreal Engine, optimization 4k 8k ultra HD, WLOP"
    },
    {
        "display": "Taylor Swift as Lola Bunny",
        "full": "Taylor Swift Cosplaying Lola Bunny, modeling, posing, playboy bunny, two piece workout clothes, training bra, photorealistic lighting, professional lighting, vibrant colors, gta 5 skin tone, photorealistic skin tone, Tooth Wu Artgerm Greg Rutkowski Alphonse Mucha Beeple artstation deviantart, 8k, fanart, extreme aesthetic"
    },
    {
        "display": "Beautiful angel with flowing hair",
        "full": "beautiful angel, flowing hair, blue eyes, beautiful aesthetic, james jean, digital art, cinematic lightning, unreal engine 5, mystery, trending on artstation"
    },
    {
        "display": "Alluring Japanese model art",
        "full": "a beautiful japanese billie eilish kat dennings alluring instagram model in elaborate latex tank top, by guweiz and wlop and ilya kuvshinov and artgerm and makoto shinkai and studio ghibli, symmetrical eyes, aesthetic, gorgeous, stunning, alluring, attractive, artstation, deviantart, pinterest, digital art"
    },
    {
        "display": "Dark wizard casting fireball.",
        "full": "a portrait of dark wizard casting fire - ball and shoot it, cyberpunk concept art, trending on artstation, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Stylish woman with sword",
        "full": "a ultradetailed beautiful panting of a stylish woman with a sword, by conrad roset, greg rutkowski and makoto shinkai, trending on artstation"
    },
    {
        "display": "Flying fish in city",
        "full": "a beautiful movie still in the style of Studio Ghibli anime showing fish flying through a city. Studio Ghibli, trending on artstation"
    },
    {
        "display": "Ethereal butterfly fairy portrait",
        "full": "portrait of a ethereal magical young butterfly fairy princess wearing a white robe, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by greg rutkowski, maxfield parrish and alphonse mucha, new art nouveau, tarot card"
    },
    {
        "display": "Dreamy cyberpunk film still",
        "full": "a dreamy film still of one Brad Pitt in the movie Bladerunner, Moebius, Greg Rutkowski, Zabrocki, Karlkka, Jayison Devadas, Phuoc Quan, trending on Artstation, 8K, ultra wide angle, zenith view, cyberpunk pincushion lens effect"
    },
    {
        "display": "Halfling rogue portrait",
        "full": "portrait of fin wildcloak, halfling rogue hoarder, fantasy, highly detailed, digital painting, sharp focus, illustration, art by artgerm, greg rutkowski, and alphonse mucha"
    },
    {
        "display": "Scientist Portrait, Helmeted, Artistic Styles",
        "full": "Portrait of a Scientist with helmet,  very coherent, painted by Edward Hopper, Wayne Barlowe, painted by James Gilleard, airbrush,  art by JamesJean and Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash and Rob Rey"
    },
    {
        "display": "Flesh building cityscape",
        "full": "A building made out of flesh as concept art for Metro Exodus, raining blood, flesh buildings, oil painting, painting by Viktor Vasnetsov, concept art, dark cityscape, brutalist architecture, painting by Ivan Shishkin and Alphonse Mucha, hyperborea, high resolution, trending on artstation,"
    },
    {
        "display": "Cute builder Walter White",
        "full": "cute builder walter white, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Flooded fungi tower painting",
        "full": "an oil painting of a flooded ancient fungi tower at night by simon stalenhag carl spitzweg jan van eyck audubon rene magritte max ernst, full-length view, highly detailed, vibrant colors, high contrast, symmetry, great composition, high detail, cinematic lighting, award winning masterpiece, trending on artstation"
    },
    {
        "display": "Colorful poison crystal ring",
        "full": "simple magic crystal ring of poison, radiant cut, green, gold, black, purple. smooth shank, setting, prongs, crystal, engravings, diamonds, product design, jewelry, colorful, art by gerald brom, greg rutkowski and artgerm, photo realism, unreal engine, c 4 d"
    },
    {
        "display": "Magical Dumbledore in forest",
        "full": "Michael Gambon as Dumbledore, magical, forest, evening, green mist, symmetrical face, large eyes, hyper realistic, digital art, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k"
    },
    {
        "display": "Cinematic spider cyborg concept.",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, spider cyborg"
    },
    {
        "display": "Cyborg hacker movie poster",
        "full": "tom hardy, cyborg, hacker, cyberpunk, neuromancer, wintermute, system shock 2, ghost in the shell, painted by seb mckinnon, high detail, dramatic light, digital art, painted by greg rutkowski, promotional movie posterart, trending on artstation"
    },
    {
        "display": "Trans fat rat bartender",
        "full": "a biped trans fat rat is cleaning glasses behind the barr of a 4 0 s jazz club, warm color palette, night time, dramatic lighting, noir film, character sheet, fine details, high contrast, blacksad, kim jung gi, greg rutkowski, trending on artstation, 8 k, front view, back view, ultra wide angle"
    },
    {
        "display": "Qiyana bathing in river.",
        "full": "qiyana, from league of legends, au naturel, bathing in the river, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Empowered WWII Housewife Portrait",
        "full": "smiling, happy, beautiful, intelligent, powerful ww 2 housewife, 2 9 years old, loving eyes, fully clothed, wise, beautiful, dramatic lighting, sharp focus, by stanley artgerm, dramatic lighting, trending on artstation, flat colour, geometric curves, gradient filter, art deco patterns"
    },
    {
        "display": "Ultron statue concept art",
        "full": "environment concept art of statue of ultron by jama jurabaev, shaddy safadi, feng shu, neil blevins, very long shot, trending on artstation, high quality, brush stroke, for aaa game"
    },
    {
        "display": "Elegant Nordic Goddess Illustration",
        "full": "beautiful nordic goddess, beautiful detailed eyes, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4 k, hdr, concept art, detailed jewelry, smooth, sharp focus, illustration, art by artgerm, da vinci and alphonse mucha"
    },
    {
        "display": "Mushroom wonderland landscape.",
        "full": "wonderland of mushrooms, mycelium and banyan trees - rays, ray - tracing, cinematic lighting, 8 k smooth, vibrant colors, ambient occlusion, hdr, complex, highly atmospheric lighting, highly detailed, alex grey, greg rutkowski, focus, cinematic, concept art, artstation, trending"
    },
    {
        "display": "Whimsical animal portrait.",
        "full": "symmetry!! portrait of animal, fantasy, soft lights!! intricate, whimsical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rococo-cyberpunk muscular android.",
        "full": "full-body rococo and cyberpunk style neon statue of a muscular attractive Cubano sim roupa macho dotado e rico android sim roupa reclining con las piernas abiertas e la piroca dura e afuera, glowing red laser eyes, prince crown of red gears, diamonds, swirling black-colored silk fabric. futuristic elements. full-length view. aggressive space robots. giant balloon animals. human skulls. intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Cassandra Cain college student portrait",
        "full": "cassandra cain as a college student, eating ramen, bokeh, beautiful face!!!!, 2 7 years old, cg animation, lifelike, animated, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Purple panther roaring at moon.",
        "full": "closeup of a purple panther roaring at the moon in the forest. night. large moon in the center. z. a. cinematic.... painting. concept art. rustic. gritty."
    },
    {
        "display": "Detailed band logo design.",
        "full": "a band logo containing the letters d and a, extremely detailed digital painting, in the style of fenghua zhong and ruan jia and jeremy lipking and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Girl with flowing autumn hair",
        "full": "girl with super long hair, hair becoming autumn red leaves, intricate, highly detailed face!!, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic prison drone swarm",
        "full": "Black military quadcopters swarm the wide hallways in a futuristic prison underground, brutalist liminal architecture, sigma 85mm f/1.4, 4k, depth of field, high resolution, 4k, 8k, hd, full color, unreal engine, concept art, trending on artstation"
    },
    {
        "display": "Realistic fantasy knight snake.",
        "full": "snake as a realistic fantasy knight, closeup portrait art by donato giancola and greg rutkowski, digital art, trending on artstation, symmetry!!"
    },
    {
        "display": "Glowing tree in mist.",
        "full": "a beautiful old tree with glowing lights in the branches with winding buttressed roots rising above a cairn covered in miss in the mist with sparkling streams and rivulets of water running down into an underground lake by james jean by roger dean by lee madgewick, at dawn, volumetric light, spiritual light, houdini particles, trending on artstation"
    },
    {
        "display": "Photorealistic space cowboy.",
        "full": "Photorealistic Wyatt Earp as a space cowboy. Hyperdetailed photorealism, 108 megapixels, amazing depth, glowing rich colors, powerful imagery, psychedelic Overtones, 3D finalrender, 3d shading, cinematic lighting, artstation concept art"
    },
    {
        "display": "Mischievous Galadriel portrait.",
        "full": "portrait of ((mischievous)), baleful Cate Blanchett as Galadriel! as a queen of fairies, dressed in a beautiful silver dress. The background is a dark, creepy eastern europen forrest.  night, horroristic shadows, high contrasts, lumnious, soft, theatrical, character concept art by ruan jia, thomas kinkade, and J.Dickenson, trending on Artstation"
    },
    {
        "display": "James Bond villain base.",
        "full": "villane base of James Bond, coastal perched on a cliff overlooking a magnificient bay, concept art oil painting by Jama Jurabaev, extremely detailed, brush hard, artstation"
    },
    {
        "display": "Kobe Bryant as anime hero",
        "full": "baroque oil painting kobe bryant ln illustration concept art lotr anime key visual portrait long flowing blonde hair blue eyes symmetrical perfect face fine detail delicate features quiet gaze fitted black military uniform gapmoe kuudere bokeh trending pixiv fanbox by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Cute fox bounty huntress",
        "full": "splash art of cute feminine anthropomorphic vulpes vulpes fulva bounty huntress in the wild west, rugged clothes, motion blur, firefight, high energy action, dust, blurry : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, octane render, liosh, mucha"
    },
    {
        "display": "Space girl at moon cafe",
        "full": "full body picture of a space girl in the moon cafe, bored, coveted, beautiful and aesthetic, intricate, unreal engine, messy hair, highly detailed, detailed face, smooth, sharp focus, chiaroscuro, manga illustration, artgerm, greg rutkowski, ilya kuvshinov, rossdraws, alphonse mucha, young adult light novel cover art"
    },
    {
        "display": "\"Radiant Classroom Digital Art\"",
        "full": "classroom of the elite, naturel, radiant light, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt, nixeu, ian sprigger, wlop, krenz cushart, ilya kuvshinov, katsuhiro, artgerm, jeremy lipkin, michael garmash"
    },
    {
        "display": "Elegant chrome robot portrait",
        "full": "portrait of female chrome robot by hagime sorayama realistic, professionally, professionally color graded, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration,"
    },
    {
        "display": "Medieval castle under aurora.",
        "full": "a detailed profile oil painting of a lone medevil castle on a mountain, lightning, aurora lighting clouds and stars by beksinski carl spitzweg and tuomas korpi. baroque elements, full - length view. baroque element. intricate artwork by caravaggio. trending on artstation. 8 k"
    },
    {
        "display": "Anime girl in winter.",
        "full": "a very beautiful anime girl, full body, long golden hair, sky blue eyes, full round face, short smile, mini jeans skirt, cute top, winter setting, cinematic lighting, medium shot, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper by Sakimichan"
    },
    {
        "display": "Elegant armored woman illustration",
        "full": "beautiful woman in armour, passionate pose, intricate, elegant, blue glow, sharp focus, soft bokeh, illustration, highly detailed, concept art, matte, trending on artstation, bright colors, 3 d 8 k, art by wlop and artgerm and greg rutkowski, mucha, ross tran, marvel comics, beksinski"
    },
    {
        "display": "Bloodied Nepali butcher portrait",
        "full": "portrait painting of a bloodied nepali female butcher wearing sari, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic industrial alley scene",
        "full": "a film still from trainspotting by patryk hardziej, an alley on an industrial planet city, vibrant, 5 0 mm lens, character design, behance hd, studio, urban patrol, evening, dramatic lighting, misty and raining, cinematic, global illumination, trending on artstation, bloom"
    },
    {
        "display": "Gorgon monster in futuristic setting",
        "full": "concept art by greg rutkowski, gorgon - like monster made of twisted flesh and reddish ooze, roaming the colony, looking rabid, in a claustrophobic, futuristic and brutalist environment, frightening and creepy atmosphere, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Vivid tropical jungle fortress.",
        "full": "the tropical jungle the wall wood fortress borderland style vivid colorful  Hyperrealistic CGI Photorealistic cinematic volume lighting fanart on ArtStation  concept art"
    },
    {
        "display": "Digital art of timekeepers",
        "full": "keepers of time and space ,matrix ,digital art, sharp focus,high quality,8k ,artation,cgsociety, by greg rutkowski and  artgerm , very detail, wallpaper"
    },
    {
        "display": "Wizard statue in valley",
        "full": "A large statue of a wizard guarding a river valley, landscape art, concept art, intense"
    },
    {
        "display": "Skinny gray pit bull.",
        "full": "photo of a skinny dark gray coat pit bull with a white paws and a white nose! digital art, highly detailed, digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop!!!, octane render"
    },
    {
        "display": "Old John Lennon portrait",
        "full": "A colored colorized real photograph of old John Lennon as an old man in his eighties with short hair in the 2010s, Old John Lennon, taken in the early 2020s, taken on a 2010s Camera, realistic, hyperrealistic, very realistic, very very realistic, highly detailed, very detailed, extremely detailed, detailed, digital art, trending on artstation, headshot and bodyshot, detailed face, very detailed face, very detailed face, real, real world, in real life, realism, HD Quality, 8k resolution, intricate details, colorized photograph, colorized photo, John Lennon as an old man with short hair, old, old man, headshot and bodyshot, old man that looks like John Lennon with short hair, old guy with short hair"
    },
    {
        "display": "Burning tree on snowy mountain",
        "full": "a snow covered mountain with a single burning tree at the top of the snowy mountain, tree on fire on a snowy mountain top, landscape art, concept art, highly detailed, dramatic landscape, contrast lighting"
    },
    {
        "display": "Anime girl in vibrant valley",
        "full": "anime girl in beautiful green valley and blue skies digital art extremely detailed eyes full body lanscape by greg rutkowski  by Makoto Shinkai trending on artstation pixiv, octane render, dynamic lighting, intricate detail, summer vibrancy, cinematic cgsociety, Award Winning, hyperdetailed Unreal Engine 4k 8k ultra HD, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean Marc Simonetti"
    },
    {
        "display": "Fantasy landscape with ninja statue",
        "full": "fairytale landscape with forest, river and mountains in the middle of the frame colossal ninja statue surrounded by colorful ribbons and party confetti , concept art, huge scale, high detail, sci fi by James Jean"
    },
    {
        "display": "Mischievous Latina witch portrait",
        "full": "ultra realistic full shot of a cute mischievous young latina witch, dramatic lighting from below, huipil peasant blouse, black orange, defined facial features, symmetrical facial features. smiling, by ruan jia and artgerm and range murata and krenz cushart and william adolphe bouguereau, key art, fantasy illustration, award winning, intricate detail realism hdr"
    },
    {
        "display": "Couple at sunset, comic style",
        "full": "a man watching the sunset with his wife by the beach in the style of artgerm, charlie bowater, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Techwear occultist portrait",
        "full": "techwear occultist, white hair home cut red eyes, by kyoto animation, chaos magick, leviathan cross, androgynous, beautiful, detailed symmetrical close up portrait, intricate complexity, in the style of artgerm and ilya kuvshinov, cel shaded"
    },
    {
        "display": "Divine mountain lagoon painting",
        "full": "of a matte painting of divine mountainscreen by simon bisley, trending on artstation, fine detailing abandoned angelic lagoon crayola crayon, by joe jusko and mike winkelmann, polar - n 9"
    },
    {
        "display": "Crystal Quartz Empress Portrait",
        "full": "a beautiful empress crystal quartz portrait, with a brilliant, impossible striking big shiny crystal headpiece, quartz, clothes entirely made out of crystal quartz, black hair, crystal cave background, symmetrical, dramatic studio lighting, rococo, baroque, hyperrealism, closeup, d & d, fantasy, intricate, elegant, highly detailed, asian, digital painting, artstation, octane render, 8 k, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Stylish woman painting",
        "full": "a ultradetailed beautiful panting of a stylish woman with red bangs, by conrad roset, greg rutkowski and makoto shinkai, trending on artstation"
    },
    {
        "display": "Elon Musk as Doctor Strange.",
        "full": "elon musk as dr strange, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Minotaur wolf illustration.",
        "full": "A minotaur wolf, full body, black and white, fantasy art, monster art, in the style of masami kurumada, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Eldritch Cthulhu tower artwork",
        "full": "the eldritch tower of cthulhu, digital art by paul chadeisson, trending on artstation, photoshop, concept art, high resolution"
    },
    {
        "display": "Mothership interior, sci-fi fantasy",
        "full": "The huge interior of the mothership from 'Close Encounters of the Third Kind', bright colors, cinematic, smooth, chrome, dramatic, fantasy, high contrast, epic composition, sci-fi, dreamlike, surreal, 8k, unreal engine, hyper realistic, fantasy concept art, XF IQ4, 150MP, ultra wide-angle lens, F1.4, ISO 200, 1/160s, natural light, Adobe Lightroom, photolab, Affinity Photo, PhotoDirector 365"
    },
    {
        "display": "Cyberpunk male portrait in dystopia",
        "full": "portrait shot of a cyberpunk male in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Crying Night King defeated.",
        "full": "crying justin sun as night king in game of thrones defeated by by crimson - black bee army, 4 k, epic, cinematic, focus, movie still, fantasy, extreme detail, atmospheric, dark colour, sharp focus"
    },
    {
        "display": "Alien goddess in elegant dress",
        "full": "a highly detailed metahuman 4 k close up render of an alien goddess bella hadid monument flying in iris van herpen dress schiaparelli in diamonds crystals swarovski and jewelry iridescent in style of alphonse mucha gustav klimt trending on artstation made in unreal engine 4"
    },
    {
        "display": "Surreal cyborg portrait.",
        "full": "surreal portrait of a woman by Greg Rutkowski and H.R Giger, Vincent Di Fate, cyborg of old age, symmetrical, hair as wires, haunting appearance, pale as marble, biomechanical and intricate, empty and uncany expression, frightening, space horror, fascinating, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp focus illustration, duo tone, HQ."
    },
    {
        "display": "Blond female angel portrait",
        "full": "very pretty blond female angel, shallow depth of field, moody lighting, 8 k, concept art, 2 0 mm lens,"
    },
    {
        "display": "Vi from Arcane, Artgerm style",
        "full": "vi from arcane, artgerm,"
    },
    {
        "display": "Portrait of model cooking.",
        "full": "Portrait of male model & a blonde woman wearing a green & red bikini cooking sardines  in Porto,real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fiery ocean canvas art",
        "full": "ocean of canvas that catches firel, magical, concept art, art nouveau, Reylia Slaby, Peter Gric, trending on artstation, volumetric lighting, intricate, ornate, CGsociety"
    },
    {
        "display": "Cute hedgehog at school",
        "full": "a super cute anthropomorphic hedgehog from final fantasy, outside a big pokemon sword and shield school, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Judge Holden portrait, night scene.",
        "full": "portrait shot of judge holden, a hairless, muscular, bald giant albino man. he \u2019 s crouched in front of a campfire in the desert at night. he \u2019 s holding a book. intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, frank frazetta"
    },
    {
        "display": "Portrait of Romanian man.",
        "full": "50 year old greying brunette man with very short hair, buzz cut, round round round face round face round face, square face, round jaw, wide chin , romanian, silver silver glasses,  romanian heritage, brown eyes, brown eyes, olive skin, round nose, round chin, clean shaven wide face, thin lips, digital art, painterly, painting, 8k, illustration, beautiful, art by loish,  painterly, trending on artstation, medium shot, uncropped"
    },
    {
        "display": "Elegant goth in latex.",
        "full": "a curvy feminine young hot pale goth cutie wearing an elegant elaborate tight white latex-nylon-leather outfit with cyan accents, cgsociety, photorealistic, sublime-comforting ambience, 16k, smooth, sharp focus, trending on ArtStation, volumetric lighting, thick hips-breasts and thin waist, fully clothed"
    },
    {
        "display": "Japanese girl in swimsuit.",
        "full": "gorgeous Japanese girl wearing sport racing competitive one piece swimsuit leotard. Worksafe, elaborate polished, trending on ArtStation, by Ilya Kuvshinov, Krenz, Cushart, Greg Rutkowski, hideo minaba, sublime-classy-dignified ambience, 16k, sharp focus, volumetric lighting. At background is luxury hotel room with soft lighting by Makoto Shinkai, by Sebastian Luca from artstation"
    },
    {
        "display": "Joe Biden as Greek god.",
        "full": "President Joe Biden as a Greek god, detailed face, gorgeous, amazing, muscular, fit, very muscular male body, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young Jennifer Connelly as Wonder Woman.",
        "full": "portrait of Young Jennifer Connelly as  Wonderwoman  , face portrait, raphael lacoste, eddie mendoza, alex ross, concept art, matte painting, highly detailed, rule of thirds, dynamic lighting, cinematic, detailed, denoised, centred"
    },
    {
        "display": "Beatles masterpiece illustration",
        "full": "the beatles perfect coloring, low saturation, epic composition, masterpiece, bold complimentary colors. stunning masterfully illustrated by artgerm, range murata, alphonse mucha, katsuhiro otomo"
    },
    {
        "display": "Cyberpunk Mecha Viking",
        "full": "mecha viking. cyberpunk art. concept art. futuristic norse."
    },
    {
        "display": "Anime dragon girl concept",
        "full": "character concept art of a woman with dragon horns and wings | | very anime, dragon scales, cute - fine - face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Iridescent cyborg ninja warrior",
        "full": "iridescent opal cyborg ninja warrior, winter, morandi color scheme, hd, illustration, epic, d & d, fantasy, intricate, elegant, highly detailed, wide angle, digital painting, artstation, concept art, smooth, sharp focus, illustration, wallpaper, art by artgerm and greg rutkowski and alphonse mucha and jin xiaodi"
    },
    {
        "display": "Favela city with overpasses",
        "full": "a film still from Tekkonkinkreet by Ian McQue , seen from roof terrace of favela city, insane multiple overpass city interchange, a mile wide under construction, looms overhead and casts shadow, vibrant, 50mm lens, video game character and environment design, 2d game lineart behance hd, studio, dramatic lighting, cinematic, global illumination, trending on Artstation, bloom"
    },
    {
        "display": "Furry albino lion detective",
        "full": "aesthetic portrait commission of a  of a male fully furry muscular anthro albino lion wearing attractive brown and black cotton detectives outfit in vintage 1900's London. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art, award winning film poster painting"
    },
    {
        "display": "Princess Peach portrait, joyful.",
        "full": "portrait of princess peach from the mushroom kingdom, nose ring, upper body, blonde hair, long hair, joyful smirk, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Jester with Bauta Mask",
        "full": "a man wearing a Bauta Mask dressed in jester clothes plays an ornate musical instrument surrounded by a crowd of hungry worshipers, concept art, trending on artstation, by greg Rutkowski"
    },
    {
        "display": "Surreal underwater astronaut scene",
        "full": "peter tarka, golden hour, minimalistic, hyperrealistic surrealism, award winning masterpiece with incredible details, epic stunning, astronaut sinking falling underwater infinity pool, a surreal vaporwave liminal space, highly detailed, trending on artstation, artgerm and greg rutkowski and alphonse mucha, daily deviation, iamag, broken giant marble head statue ruins, illuminated"
    },
    {
        "display": "Surreal dreamy landscape.",
        "full": "symmetry!! surreal landscape of a dreamy girl, dreamscape, very detailed, serene, peaceful, golden hour, clouds, perfect lighting, perfect composition, digital art, illustration, artstation, artgerm, derek zabrocki, greg rutkowski, 4 k"
    },
    {
        "display": "Boris Johnson as Vito Corleone.",
        "full": "Boris Johnson as Vito Corleone, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Scarlett Johansson as Black Widow",
        "full": "peter mohrbacher, phil noto, artgerm, pretty scarlett johansson black widow, symmetrical eyes, long red hair, full body, city rooftop"
    },
    {
        "display": "Fruit astronauts on cream planet.",
        "full": "an expedition of fruit astronauts discovering a new sweet planet made out of cream. cute, illustration, digital art, inspired by fruit salad, by greg rutkowski, detailed, sharp, masterpiece, highly detailed, photorealistic, octane render, 8 k, unreal engine 5, trending on artstation"
    },
    {
        "display": "Anime Cthulhu maid illustration",
        "full": "Anime Cthulhu character, dressed as a french maid, highly detailed, digital painting, artstation, kyoani, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Giant phone portal cliff.",
        "full": "giganting mobile phone on a cliff as portal to another dimension, high detail, computer art, concept art, no noise, fantasy, full view"
    },
    {
        "display": "Elegant digital painting",
        "full": "emily willis, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Surreal symmetrical landscape",
        "full": "symmetry!! surreal landscape of a melancholic dream, very detailed, serene, peaceful, golden hour, perfect lighting, perfect composition, digital art, illustration, artstation, artgerm, derek zabrocki, greg rutkowski, 4 k"
    },
    {
        "display": "Adorable Star Wars kids",
        "full": "adorable StarWars kids, pixar render, brilliant style by Artstation, Artstation Trending, cgsociety, high quality, very coherent, ultra realism, high definition, post processing, unreal engine, 8k, high resolution, octane render, high contrast, 4k UHD, photographic, digital art,  artstation,"
    },
    {
        "display": "Misty mountain castle artwork",
        "full": "castle on a misty mountain  by SPARTH, Game of Thrones, trending on artstation concept art, HD, 8K"
    },
    {
        "display": "Elegant digital portrait.",
        "full": "tingle, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Intricate priest portrait.",
        "full": "priest adeptus mechanicus, oil on canvas, intricate, portrait, 8k highly professionally detailed, HDR, CGsociety, illustration painting by Mandy Jurgens and Ma\u0142gorzata Kmiec and Dang My Linh and Lulu Chen and Alexis Franklin and Filip Hodas and Pascal Blanch\u00e9 and Bastien Lecouffe Deharme, detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, Cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8K, style by Wes Anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon st\u00e5lenhag, rendered by Beeple, by Makoto Shinkai, syd meade, 8k ultra hd, artstationHD, 3d render, hyper detailed, elegant, by craig mullins and marc simonetti, Ross Tran and WLOP, by Andrew Wyeth and Gerald Brom, John singer Sargent and James gurney"
    },
    {
        "display": "Demonic Spetsnaz soldier portrait.",
        "full": "soviet russian spetsnaz soldier wearing a demonic gasmask with glowing red eyes and three goggles and demonic tentacle instead of one arm, concept art, digital portrait, horror, creepy, concept art, military art, dark fantasy art, sci - fi, retrofuturism, artstation, highly detailed painting, art by eddie mendoza"
    },
    {
        "display": "Fantasy forest landscape",
        "full": "forest clearing landscape, studio ghibli, pixar and disney animation, sharp, rendered in unreal engine 5, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wide angle, artbook, wallpaper, splash art, promo art, dramatic lighting, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Hybrid dragon-hamster painting",
        "full": "painting of hybrid dragon!!!! and ( ( ( ( hamster ) ) ) ) fused species, intercrossed animal, by zdzislaw beksinski, by lewis jones, mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Ancient Egypt Las Meninas",
        "full": "Las Meninas but in ancient Egypt, dream-like atmosphere, symmetrical baroque painting, perfect composition, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, soft natural volumetric cinematic perfect light, chiaroscuro, award-winning photograph, masterpiece, Raphael, Caravaggio, Greg Rutkowski, Beeple"
    },
    {
        "display": "Rugged hiker in mountains",
        "full": "an ethereal dramatic painting of a handsome rugged shirtless desi hiker | he is wearing a plaid kilt and cowboy hat, and holding a wooden pole | background is mountains and clouds | homoerotic, dramatic lighting, highly detailed, vintage travel poster | by peder balke, by maxfield parrish | trending on artstation"
    },
    {
        "display": "Hyper-detailed female Moon Knight",
        "full": "female moon knight, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by alphonse mucha and greg rutkowski and ian sprigger"
    },
    {
        "display": "Ethereal cyberpunk demon queen portrait",
        "full": "elden ring empress ethereal cyberpunk demon queen, innovative avant - garde art, deco fashion, photorealistic portrait, vaporwave, crisp quality and light reflections, glorious clouds, golden hour, transcendent, vibrant color, clean linework, finely detailed, tarot card with ornate border frame, by artgerm, ayami kojima, yoshitaka amano, hiroshi yoshida 4 k, trending on artstation, volumetric lighting, octane render"
    },
    {
        "display": "Kawaii greystriped cat cartoon",
        "full": "kawaii greystriped cat looking cute, disney style, concept art, highly artstation, detailed, cartoon"
    },
    {
        "display": "Surreal starry forest render",
        "full": "a hyper - detailed 3 d render of a forest with a canopy of stars, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation hd, by greg rutkowski, bruce pennington, valentina remenar and asher duran,"
    },
    {
        "display": "Realistic anthropomorphic lynx portrait",
        "full": "beautiful realistic portrait of white anthropomorphic lynx cat by artgerm, furry fantasy art"
    },
    {
        "display": "Megan Fox Fantasy Portrait",
        "full": "megan fox, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Hyperrealistic beautiful portrait",
        "full": "full body pose, hyperrealistic mixed media painting of something beautiful, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Realistic Blackpink Lalisa Manoban",
        "full": "realistic detailed semirealism beautiful gorgeous cute Blackpink Lalisa Manoban white hair white cat ears blue eyes, wearing black camisole maid outfit, headphones, black leather choker full HD 4K high resolution quality WLOP, Aztodio, Taejune Kim, Guweiz, Pixiv, Instagram, Artstation"
    },
    {
        "display": "Oprah Winfrey as Harley Quinn",
        "full": "Portrait of Oprah Winfrey as Harley Quinn, by artgerm and greg rutkowski and alphonse mucha and loish and WLOP, artstation, detailed, concept art, deviantart, colorful"
    },
    {
        "display": "Cosmic kiss in space",
        "full": "Your makers must have kissed in cosmic dust"
    },
    {
        "display": "Epic Narasimha portrait",
        "full": "narasimha, character concept art, portrait, epic, trending on artstation, very detailed, 4 k, hd, dramtic lighting, flowing energy, colorful"
    },
    {
        "display": "Aesthetic girl in city.",
        "full": "extremely beautiful aesthetic girl with half black and half white jacket on the top of the city, full body, occlusion shadow, specular reflection, rim light, unreal engine, artgerm, artstation, art by hiroaki samura and jiro matsumoto and yusuke murata, high quality, intricate detailed 8 k, beautiful shape of face and body"
    },
    {
        "display": "Artistic style synthesis",
        "full": "peter ferguson, leonora carrington, yukio kitt, yago hortal, lindsay kennedy, william bradford, william turner, by francois - auguste biard, lowell birge harrison, ivan aivazovsky, geovanni strazza, bernini, gerhard richter, zdzislaw beksinski, alphonse mucha, wallace and gromit, yoji shinkawa, kastuhiro otomo fang lijun, ramona zordini, james turrell, olafur eliasson, by cliff childs, tooth wu, greg rutkowski, yue minjun francois morellet, dan flavin, untitled ( in honor of harold joachim ) 3, 1 9 7 7, james turrell, doug wheeler, light and space, david schnell art movements 8 0's, airbrush, minimalistic realist, polyurethane foam, clay, resin, spray paint, pearls, glitter, chromatic pigment, pigment, photography, studio lighting, iridescent plastic sheet studio ghibli abstract expressionism art deco art nouveau avant - garde baroque bauhaus classicism color field painting conceptual art constructivism cubism dada / dadaism digital art expressionism fauvism futurism harlem renaissance post - impressionism installation art land art minimalism neo - impressionism neoclassicism neon art op art performance art pop art post - impressionism precisionism romanticism, post - impressionism, flowing cloths and feathers, dark smoke, tarot card, artstation, beautifully lit, hyperdetailed, beautiful lighting, trending on artstation, cloths, exalted, light leak, dark smoke, beautiful lighting concept art"
    },
    {
        "display": "Rivendell scenic landscape illustration",
        "full": "rivendell, beautiful scenic landscape, lord of the rings, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Cyborg merfolk portrait",
        "full": "merfolk cyborg, cybernetic eye, prosthetic leg, artificer, highly detailed, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Pregnant Mark Zuckerberg Portrait",
        "full": "mark zuckerberg pregnant, beautiful, red lips, attractive, highly detailed full body portrait, elegant, breathtaking art, concept art, by artgerm and ilya kuvshinov"
    },
    {
        "display": "Symmetrical pharaoh portrait",
        "full": "symmetry!! portrait of ancient pharaoh wearing traditional attire, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal Goddess with sword.",
        "full": "Ethereal Goddess draped in VantaBlack layered fabric highly detailed stitching carrying a engraved sword. Power stance. Concept art. Realistic. V-ray."
    },
    {
        "display": "Fantasy illustration of Anna Kendrick.",
        "full": "anna kendrick holding a glowing wand in one hand and a large leather bound book, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, in the style of magic the gathering, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyperrealistic hand particles.",
        "full": "hyperrealistic mixed media high resolution image of a single proportionally sized human hand comprised of particles flowing in such a manner that resembles complex potential flow around a doublet that resembles a super massive black hole, warping space and time, stunning 3d render inspired art by Istv\u00e1n S\u00e1ndorfi and Greg Rutkowski, perfect symmetry, dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyper-detailed, intricate, epic composition, highly detailed attributes, highly detailed atmosphere, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, flawless, lifelike, perfection,"
    },
    {
        "display": "Young mage in forest",
        "full": "blonde braided hair young mage wearing a heavy cloak and chemise holding a dagger in the forest, fantasy character portrait, ultra realistic, intricate, elegant, highly detailed, digital painting, artstaion, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk city at sunrise.",
        "full": "steampunk city, sunrise, landscape, intricate, detailed, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry n 9"
    },
    {
        "display": "Kneeling figure before queen.",
        "full": "kneeling before a proud queen,  sitting in a chair while posing for a photo, royal gown, golden detailing, medium shot, intricate, elegant, highly detailed, digital painting, volumetric light, artstation, concept art, smooth, sharp focus, illustration, art by Gil Elvgren and Greg Rutkowski and Alphonse Mucha, 8K"
    },
    {
        "display": "Fantasy mountain lakes artwork.",
        "full": "mountain lakes, digital art, concept art, fantasy art, highly detailed, hd wallpaper, artstation, deviantart, behance"
    },
    {
        "display": "Tattooed maid in fantasy forest",
        "full": "Margot robbie as a beautiful tattooed female maid wearing a magical bikini in a magical forest, highly detailed face, realistic face, piercing gaze, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, photorealistic"
    },
    {
        "display": "Sci-fi scientist concept art",
        "full": "concept art by jama jurabaev, trending on artstation, high quality, brush stroke, scientist, scifi"
    },
    {
        "display": "Stylish male profile portrait",
        "full": "the best male profile picture of 2 0 2 5, 4 k, beautiful gorgeous digital art, trending on artstation"
    },
    {
        "display": "John Cena in tuxedo portrait",
        "full": "john cena wearing a tuxedo, portrait, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mad scientist portrait, anime style.",
        "full": "Portrait of a mad crazy woman Scientist with helmet,  very coherent, painted by Edward Hopper, Wayne Barlowe, painted by James Gilleard, airbrush,  art by JamesJean and  fine details. Anime. realistic shaded lighting poster by"
    },
    {
        "display": "Realistic queen portrait.",
        "full": "a realistic  queen with a cream and white decorated dress made of white pearls , highly detailed, digital painting, Trending on artstation , HD quality, by artgerm and greg rutkowski and alphonse mucha, dramatic light, octane"
    },
    {
        "display": "Joyful girl in Mario hat",
        "full": "portrait of a young girl wearing a super mario hat, nose ring, upper body, purple hair, long hair, joyful smirk, intricate, smoking weed, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anthropomorphic hyena astronaut portrait.",
        "full": "digital detailed portrait of anthromorphic female hyena, in style of zootopia, fursona, furry, furaffinity, 4 k, deviantart, wearing astronaut outfit, in style of disney zootopia, floating in space, space background, in deep space, dark background, hyena fursona, cyberpunk, female, detailed face, style of artgerm,"
    },
    {
        "display": "Cyberpunk furry character art",
        "full": "RPG character concept art, cyberpunk furry, in the style of Leticia Gillett Hiroya Oku Riyoko Ikeda, 3d render, artstation trending, 8k, octane render, photorealistic, sharp detail, manga, black and white"
    },
    {
        "display": "Cyberpunk safe cracker character.",
        "full": "apex legends cyberpunk safe cracker. concept art by james gurney and m\u0153bius. gorgeous face."
    },
    {
        "display": "Medieval raccoon knight.",
        "full": "a medieval raccoon!!! in royal knights armor, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Serene sunset landscape painting",
        "full": "A vast green landscape with a river running through it, a small village in the distance and a few mountains in the background. The sun is setting and the sky is ablaze with oranges, reds and yellows. A beautiful, serene and peaceful scene, digital painting, 4k, concept art, artstation, matte painting, by Yuji Kaneko"
    },
    {
        "display": "Steve Buscemi as Iron Man",
        "full": "steve buscemi as iron man portrait, highly detailed, by krenz cushart, octane render, artstation trending"
    },
    {
        "display": "Faceless 1950s charcoal portrait",
        "full": "a woman without a face, woman posing for the camera, charcoal painting!!!!!, trending on artstation, 4 k, 8 k, artstation hd, artstation hq, artistic interpretation, 1 9 5 0 s style"
    },
    {
        "display": "Happy hippy girl in rain.",
        "full": "a beautiful digital illustration painting of a happy hippy girl with flowers in the rain by benoit b. mandelbrot, steven belledin, martin johnson heade, lee madgwick, caspar david friedrich, and david rios ferreira. 8 k resolution trending on artstation concept art digital illustration"
    },
    {
        "display": "Fiery shadow silhouette.",
        "full": "a shadow silhouette is on fire by brian smith, concept art"
    },
    {
        "display": "Jaina Proudmoore Lich Queen",
        "full": "concept art of jaina proudmoore as lich queen, world of warcraft, fantasy, hyper realistic, trending on artstation"
    },
    {
        "display": "Stylized modern woman portrait",
        "full": "portrait of a modern (white) woman wearing a dark shirt, upper body 2d game avatar, Donato Giancola, Kodak film stock, chiaroscuro lighting, stylized bold outline, default pose neutral expression, expressionist colour, face-on head shot, close-up, eye-contact, sharp focus, shape language, Alphonse Mucha/Gustav Klimt style, alpha masked transparent flat background, 4k, volumetric lighting, French Nouveau, trending on artstation, octane render, ultra detailed, hyperrealistic"
    },
    {
        "display": "Rufio-inspired darkwave portrait",
        "full": "lost boys rufio, darkwave, darksynth, character portrait headshot concept art, sharp, digital matte painting, art by luis royo, greg rutkowski, wlop, dramatic lighting, trending on artstation"
    },
    {
        "display": "Empowered Feminist Art",
        "full": "feminism. female empowerment, women's rights, power, cool. trending on artstation."
    },
    {
        "display": "Scary, detailed Cat in the Hat.",
        "full": "complex 3 d render, hyper detailed, ultra sharp, of the cat in the hat, scary, funny, cinematic, natural soft light, rim light, art by greg rutkowski and artgerm and craig mullins, dr seuss"
    },
    {
        "display": "Anime portrait of Ana dal armas",
        "full": "anime portrait of Ana dal armas as an anime girl by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, trending on artstation"
    },
    {
        "display": "Isometric bowl island artwork",
        "full": "isometric bowl island on neon background, isometric invironment, 3d art, isometric art, amazing detail, artstation, concept art, behance, ray tracing"
    },
    {
        "display": "Mike Wheeler as Homelander.",
        "full": "mike wheeler as homelander, glossy intricate design, digital art, smooth vibrancy, high detail texture, lighting, 8 k, unreal engine 5 rendered, marmoset toolbag rendered, octane rendered, trending in artstation, art style by popularity _ choi and ian sprigger"
    },
    {
        "display": "Realistic Shiba Inu Gaming",
        "full": "photo of shibe playing video - game, realism, realistic, photorealism, f 3. 5, photography, octane render, trending on artstation, unreal engine, cinema 4 d"
    },
    {
        "display": "Ballet dancer painting.",
        "full": "a beautiful masterpiece painting of a ballet dancer by juan gimenez, award winning, trending on artstation,"
    },
    {
        "display": "Zombie singer artwork",
        "full": "singer divine as a zombie, 7 days to die zombie, fine art, award winning, intricate, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by guweiz and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "\"Trippy Yoda Portrait\"",
        "full": "a highly detailed portrait of Danny Devito as a crazy laughing Yoda on a bad ketamine drug trip, drugs and alcohol littered everywhere, descent into lunacy and madness, dizzy, trippy, artstation, cgsociety, very detailed, intricate, detailed illustration, by artgerm and greg rutkowski and alphonse mucha, sharp, smooth, masterpiece"
    },
    {
        "display": "Hyper-realistic portrait of James Rolfe",
        "full": "portrait of james rolfe, angry video game need, avgn, wearing a pair of thin glasses and dresses in a white dress shirt, by greg rutkowski, wlop, beeple, dan mumford. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, highly detailed, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Female warrior gargoyle with flaming sword",
        "full": "disney's gargoyles!!!! female!! warrior! flaming sword ( devilish smile ) ( ( plate armor ) ) ( ( ( shield ) ) ), fantasy painting, concept art, 4 k"
    },
    {
        "display": "Undressed women forming tower",
        "full": "undressed catalan castellers human impossible big tower they are real undressed women like a incredible giant sculpture at the kingdom of julius caesar, no clothes, roman historic works, hyper - detailed, world renowned artists, historic artworks society, antique renewal, intricate, elegant, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Cute fantasy child mage",
        "full": "super cute fantasy child mage 3D concept by Gediminas Pranckevicius style, foggy, glowing effect, beautiful detailed, chubby, face realistic, Game Art, hyper detailed, no background, Character Modeling, cartoon, cinematic, raytrace, Trend on artstation, C4D"
    },
    {
        "display": "Superman comforts Keanu Reeves.",
        "full": "a highly detailed matte painting of superman comforting a depressed keanu reeves on a park bench, art by artgerm and greg rutkowski and alphonse mucha, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Epic anime scene",
        "full": "saitama, anime, epic scene, octane redner, colors, holy, full body, 8 k, galaxy, and, stars, illustration, atmosphere, unreal engine, video game, ethereal, insanely detailed, symmetrical, concept art, peter mohrbacher, charlie bowater, artstation, craig mullins, cinematic, video game, digital painting, artist maena,, hyperrealistic, octane render, cinematic lighting, 4 k"
    },
    {
        "display": "Detailed Chinese girl portrait.",
        "full": "chinese girl with black hoodie. insanely detailed. by greg rutkowski, pixiv. zbrush sculpt, octane, maya, houdini, vfx. close - up cinematic dramatic atmosphere, sharp focus, volumetric lighting."
    },
    {
        "display": "Anunnaki god overseeing Earth.",
        "full": "symmetry!! anunaki god overlooking the earth, peaking from above the clouds, ancient astronauts, very detailed, intricate, perfect lighting, perfect composition, artstation, artgerm, derek zabrocki, greg rutkowski, 4 k"
    },
    {
        "display": "Ultra-realistic portrait of Jennifer Morrison",
        "full": "painting of jennifer morrison,, ultra realistic, sharp details, subsurface scattering, intricate details, warm lighting, beautiful features, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Spider on rollerskates",
        "full": "a spider is rollerskating, rollerskates, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed sorceress illustration",
        "full": "beautiful sorceress, accurate anatomy, only two hands, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by sakimichan and greg rutkowski and alphonse Mucha"
    },
    {
        "display": "Friendly sea creatures in storm",
        "full": "of a dark and stormy ocean with large strange cute friendly water creatures with big eyes, mouth and round teeth appearing from the water, in the style of Giger, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Dynamic angelic action portrait",
        "full": "angel, perfectly-centered-painting of young Emma Watson in ghost in the shell, sweaty, dynamic action pose, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Post-apocalyptic biker scene",
        "full": "a ultradetailed beautiful panting of post apocalyptic biker with helmet in front of burning desert, by ilya kuvshinov, greg rutkowski and makoto shinkai, trending on artstation"
    },
    {
        "display": "Rimuru Tempest Portrait",
        "full": "profile shot of rimuru tempest looking forward, sky blue hair, ponytail, pretty, long bangs, gold eyes, black jacket with white stripes and a high collar, highly detailed, unreal engine 5, digital painting, concept art, cinematic, wlop | artgerm, pixiv, ilya kuvshinov, greg rutkowski, yoshitaka amano"
    },
    {
        "display": "Baroque Spiderman character portrait",
        "full": "baroque oil painting character portrait concept art, live 2 d anime key visual of spiderman as secret police, studio lighting finely detailed perfect face directed gaze, long flowing blonde hair blue eyes, black nazi military uniform, gapmoe kuudere grimdark dictator, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Silhouette man cosmic view",
        "full": "dark silhuette of a man standing on a gray dull cliff looking out into a colorful vibrant cosmos, clouds, stars, rings, nebula, beautiful lighting, magic, fantasy, vivid colors, intricate, elegant, cgsociety, artstation trending, oil painting by greg rutkowski, by artgerm, by wlop"
    },
    {
        "display": "Glowing orb cityscape illustration.",
        "full": "immense glowing glass orb, cityscape and trees, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, sunny day, utopia, open space, illustration, concept art, ruan jia, steve mccurry, ivan shishkin"
    },
    {
        "display": "Symmetrical Clint Eastwood portrait",
        "full": "symmetrical, close-up, portrait of Clint Eastwood as The Punisher, art by greg rutkowski, matte painting, trending on artstation"
    },
    {
        "display": "Cinematic Portrait of Cotton Mill Girl",
        "full": "a beautiful Cotton Mill Girl, symmetrical body, centered, dramatic angle, ornate, details, smooth, sharp focus, illustration, realistic, cinematic, artstation, award winning, rgb , unreal engine, octane render, cinematic light, macro, depth of field, blur, red light and clouds from the back,  highly detailed epic cinematic concept art CG render made in Maya, Blender and Photoshop, octane render, excellent composition, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse by Henri Cartier Bresson"
    },
    {
        "display": "Hyper-detailed natural stone",
        "full": "unknown stone, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered"
    },
    {
        "display": "Luxanna Crownguard Portrait",
        "full": "luxanna crownguard leaning over a table, from league of legends, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, fluorescent skin, sharp focus, intricate, elegant, highly detailed, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Manatee-shaped mech illustration",
        "full": "mech shaped like a manatee, machine, greg rutkowski, digital painting"
    },
    {
        "display": "Cyborg bull full body.",
        "full": "a full body shot of an imposing cyborg ( bull ) modeled after a bull looking into the camera, android, cyborg, full body shot, intricate, 3 d, hyper realism, symmetrical, octane render, strong bokeh, fantasy, highly detailed, red, depth of field, digital art, artstation, concept art, cinematic lighting, trending"
    },
    {
        "display": "Storm Spirit concept art",
        "full": "storm spirit from dota 2, concept art, in the style of Artgerm, intricate details, beautiful"
    },
    {
        "display": "Post-apocalyptic assassin portrait",
        "full": "A full portrait of a beautiful post apocalyptic assassin, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Middle-Eastern female cleric portrait",
        "full": "portrait of a middle - eastern female cleric with straight black hair wearing blue and yellow vestments inside a temple, fantasy, highly detailed, digital painting, artstation, concept art, character art, art by greg rutkowski and tyler jacobson and alphonse mucha"
    },
    {
        "display": "Albino male furry portrait",
        "full": "beautiful aesthetic portrait commission of an albino male furry anthro looking in the mirror, detailed face , hyperdetailed, dark atmosphere. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art, 2022 award winning painting"
    },
    {
        "display": "Creepy man portrait.",
        "full": "closeup portrait of a man with bulging unblinking eyes, shrivelled neck with strange lines, wide mouth with thick lips, flat nose, tiny ears, squamous grayish skin, constant alopecia, creepy, innsmouth look, dramatic light, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Hyperrealistic rock painting",
        "full": "A highly detailed matte painting of a rock by Mokoto Shinkai, hyperrealistic, breathtaking, beautiful composition, by Artgerm, by beeple, by Studio Ghibli, volumetric lighting, octane render, detailed, oil painting, 4K resolution, trending on artstation"
    },
    {
        "display": "Cyberpunk Killua Zoldyck portrait",
        "full": "killua zoldyck black hair, rain, white hair, techwear, streetwear, cyberpunk style outfit, greg rutkowski, artgerm, ross tran, takato yomamoto, wlop, ilya kuvshinov, intricate complexity, detailed portrait, 4 k, cinematic lighting, artstation, sharp focus, smooth, makoto shinkai"
    },
    {
        "display": "Cinematic battle of good vs. evil.",
        "full": "sharp, high detail, contrast between darkness and light, good versus evil, cinematic lighting, vibrant colors, photorealism, terror, hysterical, horrifying, digital art, destruction, the void, ominous, octane render, fear, very detailed, evil, trending on artstation, intricate details, high definition, 16k, Artstation, Dark Souls by WLOP"
    },
    {
        "display": "King drinking tea, digital art",
        "full": "a king drinking a cup of tea by beeple, trending on artstation, 8 k, highly detailed, digital painting"
    },
    {
        "display": "Goddess princess in roses",
        "full": "perfectly detailed goddess princess of pink roses!! blessed by nature with ever - increasing physical mental perfection, symmetrical! intricate, highly detailed, biblical divine holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fractal Viking Lion Tarot Card",
        "full": "beautiful and colorful fractal tarot card featuring an ornate, realistic, and regal viking lion by Dan Mumford, by Jim Fitzpatrick, by joe wilson, featured on deviant art, trending on artstation"
    },
    {
        "display": "Alien horror ballerina portrait",
        "full": "scorn giger venom alien doom zdislaw bekinski horror hell alien tentacles satan fire flame octopus, beautiful underwater redhead ballerina portrait as a belly dancer, pixar style, by tristan eaton stanley artgerm and tom bagshaw."
    },
    {
        "display": "Wastelander with fox mask.",
        "full": "highly detailed portrait of wastelander long ice hair ceramic kitsune fox mask curvy tribal lady, stray wiring by atey ghailan, james gilleard, by joe fenton, by greg rutkowski, by greg tocchini, by kaethe butcher, 4 k resolution, gradient blue, cyan, black and white color scheme!!! ( ( snowy glaciated robotic dystopian city background ) )"
    },
    {
        "display": "Ginger Palpatine portrait",
        "full": "portrait art of Palpatine but ginger, 8k ultra realistic , lens flare, atmosphere, glow, detailed, intricate, full of colour, cinematic lighting, trending on artstation, 4k, hyperrealistic, focused, extreme details, unreal engine 5, cinematic, masterpiece"
    },
    {
        "display": "Fantasy man with green features",
        "full": "a man with dark green hair, green glowing eyes what look like a clock, high detail clothing, fantasy, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, artbook, dynamic pose, splash art, promo art, soul calibur, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Cat clinic logo design",
        "full": "logo of a cat field clinic, drawing, concept art, borderlands"
    },
    {
        "display": "Cybernetic forest landscape.",
        "full": "cybernetic mycelium. all watched over by machines of loving grace. a cybernetic forest filled with pines and electronics where deer stroll peacefully past computers as if they were flowers with spinning blossom trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper-detailed, HD, HDR, 4K, 8K"
    },
    {
        "display": "Evil wizard with dragon wings",
        "full": "dungeons and dragons evil wizard with dragon wings character full body side profile portrait, dramatic light, dungeon background, 2 0 0 mm focal length, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Photorealistic blue eyes dragon",
        "full": "blue eyes white dragon, photorealistic, ultra detailed, trending on artstation, concept art, octane render, unreal engine, by shinji aramaki, by christopher balaskas, by krenz cushart"
    },
    {
        "display": "Dystopian bartender concept art",
        "full": "perfect composition, subdued color palette, award-winning concept art, detailed digital painting, airbrushed, low contrast: costume design for young Frank Sinatra as a poor dystopian dieselpunk 1950s bartender. Volumetric cinematic lighting, great attention to perfect anatomy, special attention to posing, great attention to realistic facial expression, faithful cinematic color scheme, perfectly coherent. In the style of: Greg Rutkowski, Syd Mead, Norman Rockwell, Edward Hopper, James Gilleard, Ilya Kuyshinov, WLOP, and Stanley Artgerm."
    },
    {
        "display": "Mila Jovovich as Spiderwoman",
        "full": "Mila Jovovich as spiderwoman terminator , muscle extremely detailed, fantastic details full face, mouth, trending on artstation, pixiv, cgsociety, hyperdetailed Unreal Engine 4k 8k ultra HD,  WLOP"
    },
    {
        "display": "Cosmic Jennifer Aniston demon",
        "full": "the second coming of jennifer aniston, by dan mumford and ross tran, cosmic, terrifying, demon rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Female Super Saiyan soldier.",
        "full": "a female super saiyan as a battle harden soldier / knight trending on artstation deviantart pinterest photorealistic hd 8 k highlights and shadow detailed high resolution"
    },
    {
        "display": "Elegant Hatsune Miku figurine",
        "full": "still figurine of hatsune miku wearing an elegant summer blouse, emerald green eyes, statue, personification, dynamic pose, amazing details, detailed product photo, official art, featured on pixiv, 8 5 mm, f. 1 4, beautiful composition, anime"
    },
    {
        "display": "Concept art illustration.",
        "full": "concept art by nikita pilyukshin"
    },
    {
        "display": "Wooden bipedal creature.",
        "full": "Bipedal hulking creature made of wood, leaves and branches standing in the middle of the field. In style of Greg Rutkowski, Jesper Ejsing, Makoto Shinkai, trending on ArtStation, fantasy, great composition, concept art, highly detailed, scenery, 8K, Behance."
    },
    {
        "display": "Portrait of LongBeachGriffy.",
        "full": "portrait of longbeachgriffy from youtube, matte painting by artgerm, artstation"
    },
    {
        "display": "Cinematic teenage action art",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: teenage Sadie Sink in action. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Elegant cosmic portrait.",
        "full": "an elegant portrait of kim petras with her eyes closed transcending to a higher plane of existence, eternal blessing, multiverse, lasers, post processing magical aura, by android jones, by ben ridgeway, by ross draws, by noah bradley, by maciej kuciara, visionary art, oil painting, artgerm, artstation, moebius, greg rutkowski"
    },
    {
        "display": "Elegant Marvel character portrait",
        "full": "full body portrait of marvel cinematic universe aaliyah haughton, x - men, jean grey, elegant, fire, phoenix, starfire, highly detailed!! digital painting, artstation, glamor pose, concept art, sharp focus, illustration, art by artgerm and greg rutkowski, artey freytag"
    },
    {
        "display": "Stylized sci-fi male design.",
        "full": "concept art, stylized, super exaggerated proportions, concept design, male, science fiction suit, in the style of jamie hewlett"
    },
    {
        "display": "Giant golem with hammer.",
        "full": "a huge golem holding a huge hammer in the middle of a valley, wlop, concept art, digital painting, trending on artstation, highly detailed, epic composition, 8 k uhd"
    },
    {
        "display": "Anime portrait of Maki Nishikino.",
        "full": "High quality portrait of Maki Nishikino. Art by Makoto Shinkai, Crunchyroll, pixiv, danbooru, HD, headshot, cinematic still, detailed anime face, bokeh, digital art, cel shading, vivid colors, ambient lighting"
    },
    {
        "display": "Balrog in hellscape.",
        "full": "balrog, lava, fire, flaming sword, intricate, detailed, volumetric lighting, scenery, hellscape, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Nami with long black hair",
        "full": "nami, long black hair, digital art from artstation by artgerm and william - adolphe bouguereau"
    },
    {
        "display": "Techno magic robot art",
        "full": "techno magic robot surrounded by techy glowing glitch magic, interesting composition, cassette - futurism, d & d, sketchy lines and brushstrokes, no blur, 4 k resolution, ultra detailed, style of greg rutkowski, zac retz, kawase hasui, beeple, eddie mendoza, alphonse mucha,"
    },
    {
        "display": "Ultrarealistic golden deer dog",
        "full": "tiny golden deer dog, 80s cool hip hop style, ultrarealistic, dramatic lighting, high details, 4k, 8k, best, accurate, trending on artstation, artstation, photorealism, ultrarealistic, digital painting, style of Wayne barlowe and Boris Vallejo and Peter Mohrbacher"
    },
    {
        "display": "Ultra-realistic humanoid princess.",
        "full": "a photo of 8 k ultra realistic humanoid princess with long blonde hair, standing next to a beautiful view, ornate white and gold officers outfit, cinematic lighting, trending on artstation, 4 k, hyperrealistic, focused, extreme details, unreal engine 5, cinematic, masterpiece"
    },
    {
        "display": "VW Beetle at Sunrise",
        "full": "a vw beetle parked near a small assam type house at sunrise, concept art, octane render, unreal engine 5, trending on artstation, high quality, 8 k, soft lighting, path traced, hyperrealistic, highly detailed, digital art, symmetrical, cinematic, high coherence, godrays"
    },
    {
        "display": "Surreal wizard in action",
        "full": "hyper detailed surreal full body portrait of an ancient wizard with flowing robe floating in the air in an epic magic blast action pose, artstation, pinterest, concept art"
    },
    {
        "display": "Futuristic 3D house design.",
        "full": "futuristic house 3 d concept art, architecture, good perspective, perfect details, octane rendering, trending on artstation, featured on behance, 4 k"
    },
    {
        "display": "Anime female Darth Vader",
        "full": "anime key visual of a female darth vader goddess!!, intricate, stunning, highly detailed, digital painting, artstation, smooth, hard focus, starwars, sith, dark side, villain, the force, lucas films, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Aquatic alien cityscape.",
        "full": "an aquatic alien city in the ocean depths of another planet, cosmic horror painting, elegant intricate digital painting artstation concept art by mark brooks and brad kunkle and, extreme detailed"
    },
    {
        "display": "Hyperrealistic cyborg in server.",
        "full": "hyperrealistic medium shot robot! cyborg! in wires data! center server! pc by stanley kubrick highly detailed concept art zdzis\u0142aw beksinski william gibson cinematic low purple lighting high angle hd 8 k sharp shallow depth of field"
    },
    {
        "display": "Anime alien cat sleeping.",
        "full": "an anime alien space cat sleeping on a mechanics workbench in a spaceport in a musical fantasy space opera ghibli animated film, volumetric lighting, octane render by stanley artgerm lau, greg rutkowski, studio ghibli, alphonse mucha, loish, norman rockwel, highly detailed, warm lighting, lens flare - w 7 0 4"
    },
    {
        "display": "Snowy mountain eruption",
        "full": "in a coldly mountain, covered with snow, d by a crack begin to erupt incandescent magma, dramatic lighting, dynamic lighting, cinematic lighting, lit by morning light, by makoto makoto, krenz cushart and artgerm, anime, featured on artstation, ultrawide angle"
    },
    {
        "display": "\"Tron Wings Sunset Scene\"",
        "full": "tron wings in front mind bending sunset, cliffside ocean scene, backlit, aesthetic, elegant, diffuse lighting, hyper realistic, elegant, intricate, hyper detailed, smooth, sharp focus, concept art, illustration, trending on artstation, art by artem demura, greg rutkowski, james gurney, and alphonse mucha"
    },
    {
        "display": "3D Cartoon Warrior Concept",
        "full": "little warrior 3D concept by Gediminas Pranckevicius, Game Art, Ultra wide angle, hyper detailed, Character Modeling, cartoon, cinematic, raytrace, concept art, Trend on Behance 3d Art, V-Ray, Maya, C4D"
    },
    {
        "display": "Tattooed maid in magical forest.",
        "full": "beautiful emilia clarke as a beautiful tattooed female maid wearing a magical bikini in a magical raining forest, highly detailed and realistic face, rain drops on face, beautiful detailed eyes with a piercing gaze, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, photorealistic"
    },
    {
        "display": "Adrian Brody cleaning hotel windows",
        "full": "Poetic sequence in Mr. Clean, a movie by Wes Anderson starring Adrian Brody. Adrian Brody tries to clean the windows of a large hotel in the Alps with mountain in the background. Elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Explorers in tarantula lair.",
        "full": "explorers with lamps, in the lair of monstrous tarantulas, dark and foreboding, with cobwebs, in the style of peter mohrbacher, dramatic lighting, atmospheric, low angle, wide angle, hyper - realistic, concept art, highly detailed digital painting, trending on artstation"
    },
    {
        "display": "Cloaked tech priest concept art",
        "full": "painting of a cloaked tech priest holding a book, adeptus mechanicus!!!!, cybernetic enhancements, praise the omnissaiah, zdzislaw beksinski, lewis jones, dariusz zawadzki, wayne barlow, warhammer 4 0 k!, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Alien spaceship in forest.",
        "full": "matte painting alien spaceship in clearing in Forest at night. Forest is lit by eerie blue glow. Ufo. Digital painting. Beeple. Noah Bradley. Cyril Roland. Will o the wisp. trending on artstation."
    },
    {
        "display": "Marlene Dietrich hamburger portrait",
        "full": "portrait of Marlene Dietrich eating hamburgers, extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Lava goddess illustration",
        "full": "Te Fiti a very beautiful female lava and fire goddess character, character is in all its glory, character is in her natural relaxed pose,dramatic lighting, rim lights, particles and air smoke in the air, fancy clouds, highly detailed professional photo, dynamic lights, particles are flying, depth of field, trending on artstation, illustration, hyper realistic, vray caustics, super detailed, colorful accents, cinematic shot her dress is made of lava flowing all around, full dress of lava showcase , cinematic lighting atmospheric realistic octane render highly detailed in he style of craig mullins, full hd render + 3d octane render + unreal engine 5 + Redshift Render + Cinema4D + C4D + Rendered in Houdini + Houdini-Render + Blender Render + Cycles Render + OptiX-Render + Povray + Vray + CryEngine + LuxCoreRender + MentalRay-Render + Raylectron + Infini-D-Render + Zbrush + DirectX + Terragen + Autodesk 3ds Max + After Effects + 4k UHD + immense detail + interdimensional lightning + studio quality + enhanced quality"
    },
    {
        "display": "Glass-headed man in forest.",
        "full": "Man, head like a glass ball, sitting on chair, in forest, hyperrealistic, high rendering, trending on ArtStation, CGSociety, 8k,"
    },
    {
        "display": "Cthulhu vs. Village Scene",
        "full": "gigantic cthulhu, small village for size comparison, dramatic light, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Red marble Jesus statue",
        "full": "a statue jesus on cross made of red marble, perfect symmetrical body, full body shot, white biomechanical, wearing epic bionic cyborg implants, inflateble shapes, tubes, background space station, masterpiece, intricate, biopunk, vogue, highly detailed, artstation, concept art, background galaxy, cyberpunk, octane render"
    },
    {
        "display": "Rugged fantasy ranger portrait",
        "full": "portrait of a ruggedly handsome ranger, hands details, muscular, full body, leather, hairy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lifelike Oscar Wilde Bodybuilder Illustration",
        "full": "amazing lifelike award winning pencil illustration of Oscar Wilde Bodybuilding trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Biological mushroom mechs.",
        "full": "biological mechs in the shape of a mushroom with many wires in a natural landscape, made by stanley artgerm lau, wlop, rossdraws, artstation, cgsociety, concept art, cgsociety, octane render, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k,"
    },
    {
        "display": "Evil Vitalik Buterin Portrait",
        "full": "a portrait of a evil vitalik buterin in glass armor releasing spell, full height, moving forward, concept art, trending on artstation, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Rosetta Stone with emojis",
        "full": "rosetta stone with android emojis written on it, digital painting, trending on artstation, deviantart, 8k, epic composition, intrinsic details, perfect coherence"
    },
    {
        "display": "Futuristic AI first contact.",
        "full": "amazing dream about first contact with trans humanism apex legends artificial intelligence, mass effect, tron, neon, hyper realistic, concept art, high key lighting, intricate, hyper detailed, smooth, high contrast, neon, volumetric lighting, octane, raytrace, action, moebius, greg rutkowski, huifeng huang, artgerm, beeple, jim lee,"
    },
    {
        "display": "Indonesian supermodel portrait.",
        "full": "portrait of an indonesian supermodels wearing traditional costume, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and james gurney and alphonse mucha"
    },
    {
        "display": "Stylized machine learning logo",
        "full": "a logo for a machine learning company, concept art, stylized, splash art, symmetrical, illumination lighting, neural network design, single logo, centered, symbol, shaded, dark shadows, dynamic lighting, watercolor paint, rough paper texture, dark background, darkmode"
    },
    {
        "display": "Cyberpunk streetwear portrait",
        "full": "very cool girl black hair woman, streetwear, techwear, cyberpunk style outfit, full body, detailed portrait, intricate complexity, by greg rutkowski, james gilleard, atey ghailan, artgerm, ross tran, conrad roset, takato yomamoto, ilya kuvshinov. 4 k, beautiful, cinematic dramatic atmosphere"
    },
    {
        "display": "Girl as Naruto's Sexy Jutsu",
        "full": "a girl cosplaying as naruto sexy jutsu, full shot, atmospheric lighting, perfectly shaded body, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Fluffy usagi warrior illustration",
        "full": "whimsical fluffy usagi warrior of hiyabusa square, scarf, cape, sword and shield, light snow, close up, details, sharp focus, elegant, highly detailed, illustration, by jordan grimmer greg rutkowski wlop maya takamura, intricate, trending artstation, pixiv, digital art"
    },
    {
        "display": "Burned man with smoke",
        "full": "concept art of only one man with his body covered in burns, with black smoke coming out of his hands, full body, dark colors, sinister atmosphere, dramatic lighting, cinematic, establishing shot, extremely high detail, photo realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross,"
    },
    {
        "display": "Drow necromancer concept art",
        "full": "Full body character concept art of a female Drow necromancer, scythe, pretty face, long dark hair, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, tranding on artstation"
    },
    {
        "display": "Sci-Fi paramedic portrait",
        "full": "intergalactic combat paramedic on a battlefield as a Sci-Fi character, portrait art by Donato Giancola and James Gurney, digital art, trending on artstation"
    },
    {
        "display": "Library girl poet concept",
        "full": "i am the girl who spends hours huddled in a corner of a library, trying to find what you love the most about marlowe, just so i can write you a poem worthy of shakespeare. i've made books my lovers, hours my enemies and you the only story. concept art"
    },
    {
        "display": "Fantasy underground civilization.",
        "full": "massive underground cave civilization, highly detailed, magic fantasy, digital art, keyframe illustration, matte painting, trending on artstation, andreas rocha, sylvain sarrailh, darek zabrocki, finnian macmanus, dylan cole, liang mark, albert bierstadt, sung choi, peter mohrbacher, greg rutkowski"
    },
    {
        "display": "\"Thorn-covered pothead girl\"",
        "full": "a beautiful portrait painting of a ( pothead ) girl covered with thorns by simon stalenhag and pascal blanche and alphonse mucha!! and nekro!! and josan gonzalez in style of digital art. colorful comic, film noirs, brush stroke, vibrating colors, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Baroque fantasy portrait illustration",
        "full": "baroque oil painting character light novel illustration lotr concept art anime key visual portrait, young female nazi maid, long flowing blonde hair blue eyes, symmetrical perfect face fine detail delicate features quiet gaze, full black military uniform, gapmoe kuudere bokeh, trending pixiv fanbox, by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Golden UFO over ruins",
        "full": "a realistic and atmospheric watercolour fantasy concept art of a golden ufo landing on top of a megalithic ruin. female medieval monk in grey robes kneeling with her hands by her sides. by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Elegant assassin portrait",
        "full": "assassin as karen page by charlie bowater and titian and artgerm, full - body portrait, intricate, face, cityscape, elegant, red mist, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Dark fantasy wind spirit",
        "full": "a painting of the gifted of the wind by greg rutkowski, dark fantasy art, high detail, trending on artstation"
    },
    {
        "display": "Seductive anime garden fairy",
        "full": "seductive anime girl as a garden fairy l, hourglass slim figure, red hair hair, attractive features, tight fitted tank top, body portrait, slight smile, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by WLOP and greg rutkowski and alphonse mucha and artgerm"
    },
    {
        "display": "Anime girl character design",
        "full": "anime girl with short yellow hair, blue eyes, white headphones on her head and a white bow, dressed in a white sleeveless shirt with a yellow bow around her neck, black transparent armbands on her hands, white shorts and black knee socks and white shoes with yellow inserts., anime style, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, digital art, hyperdetalied, video game icon artstation lois van baarle, ilya kuvshinov, rossdraws"
    },
    {
        "display": "Angry woman portrait, cyberpunk.",
        "full": "closeup portrait of an angry woman looking at the camera in the style of stefan kostic, realistic, cyberpunk, leather jacket, mask, nighttime, rain storm, body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, floating embers"
    },
    {
        "display": "Elegant alien in saree",
        "full": "beautiful fashion human looking alien in red saree,, in the style of artgerm, and wlop, chanel jewelry, cinematic lighting, hyperdetailed, 8 k realistic, symmetrical, global illumination, radiant light, love and mercy, frostbite 3 engine, cryengine, dof, trending on artstation, digital art, crepuscular ray"
    },
    {
        "display": "Elegant young woman concept art",
        "full": "full figure character concept art of a gorgeous young woman wearing a designer khaki outfit with a backpack and hoodie and leather belts in the style of stefan kostic, realistic, sharp focus, 8k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Woman with cat ears writing.",
        "full": "woman with cat ears writing on a blackboard, blackboard, oil painting, digital art, cat ears, by wpol, by artgerm, beautiful lighting, by rob rey, by greg rutkowski, trending on artstation"
    },
    {
        "display": "Cyberpunk portrait of Jackson Wang",
        "full": "portrait painting of cyberpunk jackson wang badass cyberware, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Elegant Galadriel portrait.",
        "full": "a portrait of galadriel, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Baroque steampunk cyborg portrait",
        "full": "a cobination of emma roberts, emma stone and emma watson as a baroque steampunk, augmented cyborg, half body, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Intricate monochrome deathshot art",
        "full": "symmetry concpet art, full shot, traditional ink, sketch, of deathshot, line sketch, intricate, elegant, highly detailed, monochrome, digital painting, artstation, concept art, sharp focus, illustration, art by borderlands 3 and peter polach"
    },
    {
        "display": "Cosmic goddess portrait",
        "full": "a beautiful portrait of a cosmic goddess by Greg Rutkowski and Rembrandt, Trending on Artstation, nebula background"
    },
    {
        "display": "Gothic castle landscape artwork",
        "full": "beautiful gothic castle landscape in the style of Dnd concept Art"
    },
    {
        "display": "Zeus portrait with lightning.",
        "full": "the god zeus, lightning, portrait, sharp focus, digital art, concept art, dynamic lighting, epic composition, subsurface scattering, trending on artstation, by emylie boivin 1. 0, rossdraws 2. 0"
    },
    {
        "display": "Cloaked figure in canyon.",
        "full": "a cloaked figure looking over a canyon landscape, watching a beam of light fire into the sky, has a face of a nebula within a marble, figure wearing gold and turquoise silken robe, in the style of jin kagetsu, wlop, masterpiece, award winning, misty morning, sharp focus, intricate concept art, 2 0 0 mm lens, hyperrealistic photography, lighting, 4 k, artstation"
    },
    {
        "display": "Fantasy Hogwarts student portrait",
        "full": "portrait of teenage girl with long glossy black hair, blue eyes, glowing skin, fashion model features, fantasy, hogwarts student uniform, intricate, elegant, black dress, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Psychedelic dancing woman.",
        "full": "psychedelic [ chemiluminescence ] [ [ [ smiling ] ] ] dancing elegant woman chakra spirit, winking at the camera, with pink hair smoke and fluid dynamics, colorful, psychedelic, ornate, intricate, digital painting, concept art, smooth, sharp focus, illustration, blacklight reacting, art by artgerm and greg rutkowski"
    },
    {
        "display": "Dark sci-fi UFO landing.",
        "full": "dark sci-fi UFO landing place in grim dark forest with alien visitors, demonic shrine, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by Jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Levitating wizard with sword.",
        "full": "levitating wizard wielding a sword, opening a shining portal, night sky, horizon of an erupting volcano, trending on artstation"
    },
    {
        "display": "Fierce female tiefling barbarian",
        "full": "portrait of a strong fierce female berber tiefling barbarian with devil horns and black hair holding a large sword and wearing a steel chestplate in a desert, fantasy, seinen, highly detailed, digital painting, pixiv, concept art, character art, art by greg rutkowski, tyler jacobson, alphonse mucha, ross tran and makoto shinkai"
    },
    {
        "display": "Symmetrical sci-fi Batman portrait",
        "full": "Symmetry!! portrait of old Batman, sci-fi armour, tech wear, glowing lights!! sci-fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elmo as royal leader",
        "full": "king elmo of sesame street vanguard of the commonwealth by ilya kuvshinov katsuhiro, artgerm, jeremy lipkin and michael garmash, unreal engine, radiant light, detailed and intricate environment, digital art"
    },
    {
        "display": "Ethereal young god with white hair.",
        "full": "god, young, white hair, long hair, intricate, ethereal, highly detailed, sharp focus, artstation, digital painting, by stanley lau and artgerm"
    },
    {
        "display": "Captain America digital art",
        "full": "Captain America, highly detailed, digital painting, artstation, standing, facing camera, concept art, smooth, sharp focus, illustration, art by artgerm and alphonse mucha, high definition digital art, dramatic lighting, in the style of ilya kuvshinov and Ross tran"
    },
    {
        "display": "Elegant draconian digital art",
        "full": "draconian, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Yemeni boy in fantasy art",
        "full": "illustration of a yemeni boy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Adventurous girl character design",
        "full": "a mobile game character concept art of an adventurous girl | | pixar - cute - fine - face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, jakob eirich, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation, ghibli"
    },
    {
        "display": "Cyborg male portrait",
        "full": "hyper realistic photography of a stunningly beautiful cyborg male, intimate, arms, elbow, intricate filigree, fibonacci, in the style of beth cavener, jin kagetsu, and wlop, highly detailed, chrome face symmetry, masterpiece, award winning, sharp focus, concept art, high key, ambient lighting, 8 k, octane render,"
    },
    {
        "display": "Tiger portrait, digital art.",
        "full": "portrait of a tiger beautiful digital art trending on artstation by alena aenami by greg rutkowsky"
    },
    {
        "display": "Neon Mythological Pinup Illustration",
        "full": "mythology, neon, Moors vol.1 Photo Reference Pack For Artists artstation, fibonacci, sweat drops, insane, pinup, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Shirtless cowboy tarot card",
        "full": "tarot card of a shirtless cowboy holding a lantern | dad bod, homoerotic, art deco, art nouveau | by louis comfort tiffany | trending on artstation"
    },
    {
        "display": "Sultry nun portrait.",
        "full": "portrait of megan fox as a sultry nun, catholic, church, bible, christian, intrigante, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Fat warrior with gigantic sword",
        "full": "a full body portrait of a fat wrrior man with a gigantic sword bigger than him beauty, delicate features finely detailed perfect art, green field, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Portrait of Zarya, sunny.",
        "full": "greg manchess portrait painting zarya from overwatch, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Sacred DMT King Artwork",
        "full": "the sacred dmt king by alex grey in the style of oil painting visionary art, intricate artwork by Tooth Wu and wlop and beeple. , trending on artstation, greg rutkowski very coherent symmetrical artwork"
    },
    {
        "display": "Elegant Jean Grey portrait",
        "full": "a portrait of jean grey, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Wastelander with demon mask",
        "full": "highly detailed portrait of wastelander long curly white ice shard hair ceramic demon mask curvy tribal lady, stray wiring by atey ghailan, james gilleard, by joe fenton, by greg rutkowski, by greg tocchini, by kaethe butcher, 4 k resolution, gradient blue, cyan, black and white color scheme!!! ( ( snowy glaciated robotic dystopian city background ) )"
    },
    {
        "display": "Sick prisoner peeking bars.",
        "full": "a sick male prisoner peeking through prison bars, elegant, digital painting, concept art, very stylized, smooth, sharp focus, illustration, dark, from Harry Potter by Ruan Jia and Mandy Jurgens and Artgerm and William-Adolphe Bouguerea"
    },
    {
        "display": "Non-binary warrior in dark fantasy.",
        "full": "ultra realist breathtaking detailed soft painting of non-binary warrior, dark fantasy, naval background, elegant, highly detailed, artstation, concept art, matte, sharp focus, art by Tom Bagshaw, Artgerm and Greg Rutkowski"
    },
    {
        "display": "Mountain valley fantasy scene",
        "full": "flying high on a mountain, the valley beneath, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Strong bodybuilder confronts police.",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork scene of a strong male bodybuilder in a grey and yellow coat and hat holding back a police contingent all by himself. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian soviet brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Ominous jester cinematic scene",
        "full": "an impressive ominous cinematic shot photo of a court jester, rigid shattered crystal volumetric dynamic fluid simulation lighting light god rays impressive masterpiece hyper ultra detailed intricate sharp focus 8 k realistic illustration canon eos r 3 fujifilm x - t 3 0 sony alpha, by james gurney tooth wu artgerm colorful!!!, trending on artstation behance cgsociety, octane render nvidia raytracing demo"
    },
    {
        "display": "Cinematic favela alley scene",
        "full": "a film still from Tekkonkinkreet by Ian McQue , apartment view of alley of dusty favela city street, intimate, bits of broken light, vibrant, 50mm lens, video game character and environment design, 2d game lineart behance hd, studio, dramatic lighting, cinematic, global illumination, trending on Artstation, bloom"
    },
    {
        "display": "Demonic cybernetic Azrael portrait",
        "full": "portrait of a demonic cybernetic azrael, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzalez, digital art, trending on artstation, deviantart, cgsociety, octane render, sharp focus, high detail, intricate, 4 k uhd image"
    },
    {
        "display": "Nautilus starship launch illustration.",
        "full": "epic concept illustration, highly detailed, intricate mechanical design, hard science concept art, star fleet nautilus ship being prepared for launch, by greg rutkowski and alphonse mucha. uhd, cinematic lighting, amazing depth"
    },
    {
        "display": "Exploding portrait concept art.",
        "full": "exploding portrait by petros afshar, concept art by zhengyi wang, thomas scholes, tristan eaton"
    },
    {
        "display": "Anime girl in samurai armor",
        "full": "beautiful anime girl holding a light source inside her hand, wearing samurai armor, expert high detail concept art, character design, perfect proportions defined faces, vivid colors, photorealistic shaded lighting poster ilya kuvshinov, katsuhiro, makoto shinkai, wlop, loish and clamp style, trending on artstation, best selling artist"
    },
    {
        "display": "Blue cyberpunk character art",
        "full": "concept art close up blue cyberpunk character, by shinji aramaki, by christopher balaskas, by krenz cushart"
    },
    {
        "display": "Samurai with Kusarigama",
        "full": "A FULL BODY PORTRAIT FROM BEHIND OF A  SAMURAI   ,THE MAN kEEPS A KUSARIGAMA AND IT IS WRAPPED IN CHAINS ,detailed, concept art, ink style , sketch"
    },
    {
        "display": "Soviet apartment with neon.",
        "full": "a highly detailed matte painting of soviet apartment building with neon signs, by studio ghibli, makoto shinkai, by artgerm, by wlop, by greg rutkowski, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Anime-style military portrait.",
        "full": "portrait bust of young woman with long light brown hair and hazel eyes dressed in a sharp dark teal military uniform with beret, smiling, bokeh background, ilya kuvshinov, anime, ross tran, artgerm, concept art, artstation trending"
    },
    {
        "display": "Gothic sci-fi princess knight.",
        "full": "Alchemy Imperial Princess knight gothic girl, sci-fi, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Nordic woman portrait, cinematic.",
        "full": "beautiful nordic woman, beautiful, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "1930s Portrait of Jerma",
        "full": "a photograph portrait of jerma in the 1 9 3 0 s with slicked back hair and a homburg fedora, taken in the mid 1 9 3 0 s, grainy, taken on a 3 0 s kodak camera, realistic, hyperrealistic, very realistic, highly detailed, very detailed, extremely detailed, detailed, digital art, trending on artstation, detailed face, very detailed face, real life"
    },
    {
        "display": "Renaissance ranger portrait.",
        "full": "renaissance upper body portrait of a gruff ranger with a spear, lean and toned, handsome face, hairy chest, D&D, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by da Vinci, Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Symmetrical cyborg portrait",
        "full": "symmetry!! portrait of cyborg with big eyes, sci - fi, tech wear, blue and yellow glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Highly detailed Revy portrait",
        "full": "a portrait of revy from black lagoon manga, smirk, entire full body, symmetrical eyes, symmetrical face, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Sci-fi centurion with botanicals",
        "full": "the pale blond male praetorian centurion lucius smirking, sci fi, glowing eyes, volumetric lights, red and cyan theme, art nouveau botanicals, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, cinematic, illustration, beautiful face, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy Arabian beauty portrait.",
        "full": "a beautiful touareg algerian woman by karol bak, ayami kojima, artgerm, sakimichan, arabian beauty, blue eyes, smile, concept art, fantasy"
    },
    {
        "display": "Insane kabuki warrior portrait",
        "full": "an epic portrait of insane kabuki male wielding a spear and doing the nirami covered in a magical mist of insanity, intricate hakama, poofy red wig, eerie, highly detailed, dark fantasy, art by artgerm and greg rutkowski"
    },
    {
        "display": "Elegant Kurisu Makise portrait",
        "full": "kurisu makise steins gate anime, atmospheric, elegant, super highly detailed, professional digital painting, artstation, concept art, 8k, art by wlop, artgerm and alphonse mucha and eiichiro oda"
    },
    {
        "display": "Gloomy robotic server room.",
        "full": "gloomy ruined server room in datacenter robot figure automata rusty steel robot knight colossus welder pacing fixing soldering mono sharp focus, emitting diodes, smoke, artillery, sparks, racks, system unit, motherboard, by pascal blanche rutkowski repin artstation hyperrealism painting concept art of detailed character design matte painting, 4 k resolution blade runner"
    },
    {
        "display": "Soldier in trench, emotional.",
        "full": "beautiful digital painting of a love survivor, one soldier after his squad killed in action in a trench waiting for the war to end. cinematic lighting and rendering, atmospheric, concept art, high detailed faces, artstation by artgerm and greg rutkowski,,"
    },
    {
        "display": "Leona Pinup Illustration",
        "full": "leona from league of legends with arms raised, minimal clothing, sheer fabrics, pinup pose, hyper detailed, digital illustration, illustration, trending in artstation, cinematic lighting, studio quality, sharp focus, intricate, elegant, curvy, art style by alphonse mucha and klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Gothic goddess portrait.",
        "full": "portrait of the young goddess of iridescent orchids, unusually unique beauty, flowers and plants and vegetables, emotionally evoking symbolic metaphors, head in focus, fantasy, heavily gothic ornamental, intricate, elegant, sensual, highly detailed digital painting, artstation, concept art, painterly, golden ratio, sharp focus, illustration, art by greg rutkowski and alphonse mucha,"
    },
    {
        "display": "Man in horse mask riding astronaut",
        "full": "a man with a horse mask on his head is riding on astronaut, fine details, concept art"
    },
    {
        "display": "Cyborg Neo in crystals.",
        "full": "impressive electric fantasy cinematic fine portrait photo of a keanu reeves neo matrix cyborg implant shattered crystal shard volumetric dynamic fluid simulation lighting impressive masterpiece hyper ultra detailed intricate sharp focus 8 k realistic illustration canon eos r 3 fujifilm x - t 3 0 sony alpha, by james gurney tooth wu artgerm colorful!!!, trending on artstation behance cgsociety stunning closeup macro"
    },
    {
        "display": "Tattooed maid in magical forest.",
        "full": "emilia clarke as a beautiful tattooed female maid wearing a magical bikini in a magical raining forest, highly detailed and realistic face, rain drops on face, beautiful detailed eyes with a piercing gaze, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, photorealistic"
    },
    {
        "display": "Cinematic motorcycle rider in sakura forest",
        "full": "cinematic close up of a person riding a motorcycle on a road near a sakura forest, delicate features finely detailed perfect art, green field, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Goddess princess portrait collage.",
        "full": "fully black background. background hex 000000. goddess princess face close-up portrait ram skull. made of dichroic obsidian. jellyfish phoenix head, nautilus, orchid, skull, betta fish, bioluminiscent creatures, intricate artwork by Tooth Wu and wlop and beeple. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "3D brown anime girl",
        "full": "a beautiful 3d brown anime girl, brown skin, black curly hair, Cinematic lighting, medium shot, mid-shot, in a anime masterpiece, highly detailed, Trending on artstation, unreal engine 4k, Cinematic wallpaper"
    },
    {
        "display": "Detailed raven with bats.",
        "full": "Intricate stunning highly detailed raven by agostino arrivabene and Seb McKinnon, unreal render, ultra realistic, Horror vacui, ethereal, dark, swarming swirling bats, full moon, thick swirling smoke tornado, fire embers, trending on artstation"
    },
    {
        "display": "Epic female scout portrait.",
        "full": "cinematic shot epic portrait an female scout selling cookies on a cookie booth, sweaty skin, flowing red hair, tie, broad light, ambient occlusion, volumetric light effect, made by ivan aivazovsky, peter mohrbacher, greg rutkowski, matte painting, trending on artstation, 4 k, perfectly defined features, digital painting, cinematic, epic, highly detailed,"
    },
    {
        "display": "Pale Indian doctors at airport",
        "full": "Enraged good looking pale young Indian doctors wearing American clothes chatting at the airport, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Scarlett Johansson as vampire.",
        "full": "scarlet johansson as a beautiful pale vampire with auburn hair in a white turtleneck dress, on a super yacht, by guweiz and wlop and ilya kuvshinov and and moebius, artgerm, symmetrical eyes, aesthetic, gorgeous, stunning, alluring, attractive, half body portrait, nice details, detailed, artstation, deviantart, pinterest, digital art"
    },
    {
        "display": "Elven hunter in leaf armor",
        "full": "female elven hunter armor made of yellow leaves, fantasy, amber eyes, face, long hair, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk dwarf mage portrait",
        "full": "portrait painting of a cyberpunk dwarf mage david tennant with braided beard, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Intricate Mario Lopez Art",
        "full": "mario lopez, intricate, highly detailed, digital painting, trending on artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sci-fi CEO tea time",
        "full": "startup CEO having a cup of tea. Beautiful!!!!!!! sci-fi concept art"
    },
    {
        "display": "Ethereal realm entrance scene.",
        "full": "entrance to ethereal realm, rendered in unreal engine, central composition, symmetrical composition, cinematic, epic, dramatic lighting, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Cyberpunk monk meditating.",
        "full": "a wise monk meditates under a waterfallto pieces with glowing yelow visor as a realistic scifi cyberpunk, torso, art by james jean and greg rutkowski!!, realistic face, like anime, digital art, concept art, trending on artstation, 8 k"
    },
    {
        "display": "Eerie portrait of Vivian Leigh.",
        "full": "a portrait of vivian leigh, eerie colors, dramatic light, gorgeous view, depth, high detail, digital art, painted by greg rutkowski and seb mckinnon, trending on artstation"
    },
    {
        "display": "Bald man in raincoat.",
        "full": "caucasian ( ( ( ( bald young man ) ) ) ) dressed in raincoat in park, male, clear face, masculine, upper body, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk streetwear portrait",
        "full": "toast, rain, techwear, streetwear, cyberpunk style outfit, greg rutkowski, artgerm, ross tran, takato yomamoto, wlop, ilya kuvshinov, intricate complexity, detailed portrait, 4 k, cinematic lighting, artstation, sharp focus, smooth, makoto shinkai"
    },
    {
        "display": "Old shaman and American man.",
        "full": "old shaman and american man near him, old mexican magician closes eyes, gray haired, a 3 / 4 view watercolor ink painting, in the style of jean giraud in the style of moebius trending on artstation deviantart pinterest detailed realistic hd 8 k high resolution"
    },
    {
        "display": "Rias Gremory with angel wings",
        "full": "an oil painting of rias gremory with angel wings, by artgerm, wlop and greg rutkowski, hd, hdr, ue 5, ue 6, unreal engine 5, cinematic 4 k wallpaper, 8 k, ultra detailed, high resolution, artstation, award winning"
    },
    {
        "display": "Wolf anthro thief in armor",
        "full": "a wolf fursona anthro thief wearing medieval guard armor is exploring the grimdark dungeon depths holding a steel greatsword. top trending on artstation pixiv furaffinity. a vibrant digital oil painting. a highly detailed fantasy character illustration by wayne reynolds and charles monet and gustave dore and carl critchlow and bram sels"
    },
    {
        "display": "Realistic female troll rogue",
        "full": "realistic render portrait of a female troll rogue with intricate armor , intricate, dystopian toy, sci-fi, extremely detailed, digital painting, sculpted in zbrush, artstation, concept art, smooth, sharp focus, illustration, chiaroscuro lighting, golden ratio, incredible art by artgerm and greg rutkowski and alphonse mucha and simon stalenhag"
    },
    {
        "display": "UFO over Times Square",
        "full": "ufo over timesquare nyc, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by greg rutkowski and james gurney and h r giger"
    },
    {
        "display": "Fantasy changeling guitarist illustration",
        "full": "fantasy changeling black kid with long curly hair playing electric guitar, two half's between, dim light, front game card, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by ruan jia and mandy jurgens and artgerm and wayne barlowe and greg rutkowski and zdislav beksinski"
    },
    {
        "display": "George Washington with Doubloons",
        "full": "a closeup photorealistic photograph of happy George Washington inspecting small gold Doubloon coins at his home on Cherry Street. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and the style of Unreal Engine."
    },
    {
        "display": "Odin, epic matte painting.",
        "full": "odin, the allfather, environment, illustration, colors, epic scene, high quality, intricate details, details, craig mullins, intricate, atmosphere, highly detailed, matte painting, cinematic, deviantart, realistic, photorealistic, concept art, 4 k"
    },
    {
        "display": "Cyborg Corgi Anime Art",
        "full": "a corgi cyborg, flawless anime cel animation by kentaro miura, psychedelic, highly detailed upper body, slight low - angle, professionally post - processed, beautiful, scary, symmetry accurate features, epic, octane rendered, anime masterpiece, accurate by craig mullins, ilya kuvshinov, krenz cushart, epic, artgerm trending on artstation by edward hopper and dan mumford and wlop and rutkovsky, beksinski carl spitzweg moebius and tuomas kocar, intricate artwork by caravaggio, unreal engine 5, lumen, nanite"
    },
    {
        "display": "Skinny gray pit bull portrait",
        "full": "photo of a skinny dark gray coat pit bull with a white paws and a white nose! digital art, highly detailed, digital painting, beautiful eyes!, pretty face!!, trending on artstation symmetry, concept art, sharp focus, illustration, art by! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Elegant woman with embers",
        "full": "a gorgeous female with long brown hair, photo by steven meisel, realistic, full body shot, wide angle, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, floating embers"
    },
    {
        "display": "Cosmic cloud portrait artwork",
        "full": "a professional photographic portrait view picture of a cosmic cloud in the sky, photographic filter unreal engine 5 realistic hyperdetailed 8 k ultradetail cinematic concept art volumetric lighting, fantasy artwork, very beautiful scenery, very realistic painting effect, hd, hdr, cinematic 4 k wallpaper, 8 k, ultra detailed, high resolution, artstation trending on artstation in the style of albert dros glowing rich colors powerful imagery"
    },
    {
        "display": "Mischievous fairy queen portrait",
        "full": "portrait of mischievous, dangerous young, smiling Galadriel (Cate Blanchett) as a queen of fairies, dressed in a beautiful silver dress. The background is a dark, creepy eastern europen forrest.  night, horroristic shadows, high contrasts, lumnious,  photorealistic, dreamlike, (mist filters), theatrical, character concept art by ruan jia, John Anster Fitzgerald, thomas kinkade, and J.Dickenson, trending on Artstation"
    },
    {
        "display": "Goddess of Tennis Portrait",
        "full": "portrait of the goddess of tennis, unusual beauty, flowers and plants, emotionally evoking symbolic metaphors, head in focus, fantasy, ornamental, intricate, elegant, sensual, highly detailed digital painting, artstation, concept art, painterly, golden ratio, sharp focus, illustration, art by John William Godward and Boris Vallejo and Zdzis\u0142aw Beksi\u0144ski,"
    },
    {
        "display": "Elegant urban portrait.",
        "full": "portrait of handsome black genius in tailored suit, urban skyline, low haircut with full beard, supreme mathematics, elegant atmosphere, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mars ravelo and greg rutkowski"
    },
    {
        "display": "Elegant dinner date illustration",
        "full": "a dinner date with the girl next door, slice of life, varying ethnicities, modern, realistic, looking at the camera, enjoying life!!! elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime girl laughing at night",
        "full": "a girl laughing gently, full shot, fine - face, realistic shaded perfect body, fine details. night setting. very anime style. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Child portrait, Colleen Moore.",
        "full": "colleen moore 2 5 years old, bob haircut, portrait by patrick nagel, painted by stanley artgerm"
    },
    {
        "display": "Seaside castle concept art.",
        "full": "castle sitting by the seaside with rolling hills and a small village by eugene von guerard, ivan shishkin, dramatic lighting, concept art, trending on artstation, 8 k"
    },
    {
        "display": "Unicorn-horned woman in Toronto",
        "full": "illustration of a black - haired woman with a unicorn horn and tan skin with purple clouds and spaceships on the horizon in toronto, by goro fujita and simon stalenhag and wes anderson and alex andreev, 8 k, trending on artstation, hyper detailed, cinematic"
    },
    {
        "display": "\"Nighttime Xanadu landscape\"",
        "full": "a beautiful painting of the view from the river of the stately pleasure domes of kublai khan in xanadu, at night with a sky full of stars, intricate, elegant, highly detailed, digital painting, artstation, concept art, by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Pizza party concept art.",
        "full": "pizza party on vintage pink velour sofa, crowd, white walls, daytime, soft light through blinds, particles, unreal 5, detailed, volumetric lighting, concept art, michelangelo, cinematic, 3 5 mm lens, 4 k"
    },
    {
        "display": "Sad anime schoolgirls portrait.",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai, cute beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. deep water bellow. beautiful and cutest sad face. deep light, trending on artstation, oil painting brush"
    },
    {
        "display": "Futuristic Napoleon coronation scene.",
        "full": "sci-fi wall structure logotype and car on the coronation of napoleon and digital billboard in the middle unreal engine 5 keyshot octane artstation trending ultra high detail ultra realistic cinematic 8k 16k in style of zaha hadid Maciej Kuciara Piotr Jab\u0142o\u0144ski Ruan Jia Sheng Lam Paul Chadeisson Khyzyl Saleem in plastic dark tilt shift"
    },
    {
        "display": "Lonely chicken in space",
        "full": "a lonely chicken wearing a space suit without helmet in a alien planet, profile picture, digital art, concept art, trending on DeviantArt, highly detailed, high quality, 4K, cartoon, high coherence, path traced, blue sky in the background, octane render, digital painting, no helmet, masterpiece"
    },
    {
        "display": "Pregnant woman in Leningrad",
        "full": "portrait of a hungry and cold pregnant woman in the besieged Leningrad in winter, , digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Apple iGlass VR Headset Concept",
        "full": "highly detailed Apple iGlass Virtual Reality Headset, computer glasses on face of human, concept art, character art, studio lightning, bright colors, intricate, masterpiece, photorealistic, hyperrealistic, sharp focus, high contrast, Artstation HQ, DeviantArt trending, 4k UHD, Unreal Engine 5"
    },
    {
        "display": "Anime-style portrait of Ted Cruz.",
        "full": "ted cruz | very very anime!!!, fine - face, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Audrey Hepburn in liquid smoke.",
        "full": "audrey hepburn in a chaotic storm of liquid smoke, portrait, by petros afshar, ross tran, tom whalen, peter mohrbacher, artgerm, shattered glass, bubbly underwater scenery, radiant light"
    },
    {
        "display": "Giant Black Adam vs. X-Men.",
        "full": "giant black adam attacking the xmen during a blizzard, highly detailed, digital illustration, artstation, concept art, matte, sharp focus, illustration, dramatic, full moon, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Underwater octopus library",
        "full": "an ancient library of knowledge is guarded by a giant octopus, underwater, matte painting, coral reef, submecanophobia, fantasy, intricate, elegant, digital painting, trending on artstation, concept art, sharp focus, illustration by greg rutkowski, greg gurney and shaun tan, 4 k."
    },
    {
        "display": "Fantasy woman portrait, water, smoke.",
        "full": "fantasy portrait of a woman made of water and smoke, carved Japanese Sakura wood organic overgrowth, peter mohrbacher, artgerm, James Jean"
    },
    {
        "display": "Pale lady in dieselpunk armor",
        "full": "a highly detailed illustration of white haired pale lady wearing dieselpunk armor dress with iron wings and blindfold, dramatic floating pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop"
    },
    {
        "display": "Dystopian masked portrait",
        "full": "Very very very very highly detailed epic photo of face with venetian mask, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Greg Rutkowski and Jakub Rozalski and Artgerm and Anton Pieck"
    },
    {
        "display": "Underwater portrait: Haitian mermaid.",
        "full": "film still, cinestill 8 0 0 t, underwater photography full body portrait of a dark skin mermaid haitian goddess, white lilies, intricate, sensual features, dewy skin, reflective skin, highly detailed, divine holy perfection!! artstation, smooth, sharp focus, warm lighting, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Horrifying vampire girl portrait",
        "full": "portrait painting of horrifying skinless and noseless vampire girl wearing battered vietnam fatigues, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Bloodied schoolgirl butcher portrait.",
        "full": "portrait painting of a bloodied school girl butcher, tattooed, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal cyborg portrait",
        "full": "cyborg, beautiful, concept art oil painting, portrait ethereal by jama jurabaev, greg rutkowski extremely detailed, brush hard, artstation, soft light"
    },
    {
        "display": "Portrait of Hisoka Morow",
        "full": "portrait of hisoka morow hunter x hunter, male, thin lips very sharp jaw yellow eyes narrow sultry eyes red hair soft hair swept back crimson medium length hair, anime, fantasy, intricate, elegant, highly detailed, digital painting, artstation sharp focus, madonna art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute girl with umbrella",
        "full": "cute girl in the rain under an umbrella, by wlop, concept art, poster, sailor moon artstyle"
    },
    {
        "display": "Angelic armored woman illustration",
        "full": "angelic woman in armour, passionate pose, intricate, elegant, blue glow, sharp focus, soft bokeh, illustration, highly detailed, concept art, matte, trending on artstation, bright colors, 3 d 8 k, art by wlop and artgerm and greg rutkowski, mucha, ross tran, marvel comics, beksinski"
    },
    {
        "display": "Handsome wizard playing cards",
        "full": "handsome! wizard shuffling cards, cards, fantasy, digital art, soft lighting, concept art, 8 k"
    },
    {
        "display": "Giant knight merging with train",
        "full": "a giant medieval knight laying down on railroad tracks while his legs physically merge into a coal - powered train, matte oil painting, billowing steam, concept art, clear, crisp, sharp, gauntlets, vibrant, award - winning, extremely detailed, 4 k, 8 k"
    },
    {
        "display": "Yoda with Glock, glitchy.",
        "full": "an image of a yoda holding a glock, an ambient occlusion render by gatoken shunshi, pixiv, sots art, glitchy, hd mod, genderless"
    },
    {
        "display": "Cat as Captain America",
        "full": "cat as captain america, dynamic lighting, cinematic, ultra detailed, trending on art station, stunning visuals, creative, fantasy concept art"
    },
    {
        "display": "Ashe character portrait",
        "full": "ashe from overwatch, white hair, black hat, character portrait, portrait, close up, concept art, intricate details, highly detailed by greg rutkowski, michael whelan and gustave dore"
    },
    {
        "display": "Totoro-inspired woman portrait",
        "full": "portrait of a totoro woman, detailed, centered, digital painting, artstation, concept art, studio ghibli, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Dramatic executive portrait",
        "full": "a expressive portrait photograph of an technology company executive in dramatic lighting, depth of field background, artstation, award - winning realistic sci - fi concept art by jim burns and greg rutkowski, a realism masterpiece, expressive color palette, james gilleard, bruegel, alphonse mucha, and yoshitaka amano"
    },
    {
        "display": "Golden UFO at Machu Picchu",
        "full": "a realistic and atmospheric watercolour fantasy concept art of a golden ufo landing on top of a machu pichu. female medieval monk in grey robes kneeling with her hands by her sides. by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Futuristic knight portrait.",
        "full": "portrait of evilly and luxury futuristic knights of Zodiac girl, black and golden armor, in futuristic heavily raindrop tokyo rooftop cyberpunk night, ssci-fi, fantasy, intricate, very very beautiful, elegant, neon light, highly detailed, digital painting, artstation, concept art, human anatomy, soft light, smooth, illustration, by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "\"Enchanting female magician illustration\"",
        "full": "female magician, wide angle view, flowers and butterflies, blue, black, pink, gold, diamonds, highly detailed, artgerm, cushart krenz, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Futuristic female rebel portrait",
        "full": "portrait painting of a futuristic rugged female rebel, alien world, colonisation, ultra realistic, curvy, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "DMT goddess with vibrant crystals.",
        "full": "epic scale cinematic full view chromaticity dmt goddess alex grey character concept of a beautiful colorful crystals powder liquids, glowing fluorescent velvet neon blacklight hues and saturation, sacred dmt color deity visionary fantasy art by greg rutkowski android jones artgerm alphonse max chroma mucha rule of thirds golden ratio sacred geometry ai generated art centered symmetrical threefold symmetry"
    },
    {
        "display": "Lifelike pencil illustration of Vincent Price",
        "full": "amazing lifelike award winning pencil illustration of vincent price trending on art station artgerm greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Neon-lit Kyiv at night",
        "full": "Kyiv on Dnieper river, neon lighting, night city, digital art from artstation by Ruan Jia and Mandy Jurgens and Artgerm and william-adolphe bouguereau and Greg Rutkowski and Wayne Barlowe"
    },
    {
        "display": "Steve Buscemi as Legolas",
        "full": "portrait of steve buscemi as legolas, action shot, elven archer, by alan lee, lord of the rings, smooth, detailed terrain, oil painting, matte painting, concept art, trending on artstation, promotional artwork, film still, elegant, photorealistic facial features, intricate, detailed face, cinematic lighting"
    },
    {
        "display": "Divine fantasy creature portrait",
        "full": "eden creature from paradise fallen on earth, divine, irresistible , light ** , fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Fantasy special forces unit",
        "full": "a cinematic shot of a special forces unit with blue and yellow flag and standing ready to fight, masculine figure, d & d, fantasy, bright atmosphere, volumetric lights, intricate, elegant, extremely detailed, digital painting, artstation, concept art, matte, smooth, sharp focus, hyper realistic, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic sci-fi Elven space station",
        "full": "cel shaded cinematic shot of the interior of a sci-fi space station with ornate Elven architecture bustling with people, cars, epic castle, emerald, crystalline, detailed illustration, sharp focus, concept art"
    },
    {
        "display": "Muscular goblin portrait.",
        "full": "portrait of tony bologna as a goblin, d & d, muscular! green, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Support Vector Machine Art",
        "full": "a support vector machine, digital art, amazing quality, very detailed, trending on artstation"
    },
    {
        "display": "Symmetrical alien hybrid portrait",
        "full": "symmetry!! portrait of three legged alien hybrid, tech wear, scifi, glowing lights!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute strawberry creature",
        "full": "adorable strawberry critter trending on pixiv"
    },
    {
        "display": "Grungy woman with rainbow hair",
        "full": "a grungy woman with rainbow hair, soft eyes and narrow chin, dainty figure, long hair straight down, torn overalls, short shorts, combat boots, side boob, wet tshirt, raining, basic white background, symmetrical, watercolor, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm,"
    },
    {
        "display": "Intimate portrait of Elvira",
        "full": "a ( beautiful masterpiece highly detailed ) full body portrait illustration of intimate alluring elvira! mistress of the dark by ralph horsely and artgerm and joe jusko, raven black hair, pearlescent white skin. trending on artstation"
    },
    {
        "display": "Teenage girl in raven cloak.",
        "full": "a teenage girl with very short black hair and a huge cloak made of black raven feathers. beautiful highly detailed face. beautiful painting by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Magical black man with crystals",
        "full": "handsome black man casting magical spells with powerful crystals, afrikan tribal voodoo headdress and kemetic imagery, digital painting artstation, concept art, matte, sharp focus, illustration, dramatic exploding nebulae, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Greek statue fragment, artistic styles.",
        "full": "a fragment of a greek statue, by dustin nguyen, akihiko yoshida, greg tocchini, greg rutkowski, cliff chiang, 4 k resolution, trending on artstation"
    },
    {
        "display": "Underwater Atlantic cityscape",
        "full": "a scenic landscaping view of the lost and abandoned city of Atlantic under water, ray of sunlight, mermaids in distance, Greg Rutkowski, Moebius, Mohrbacher, Mucha, blue and gold color scheme, ultra wide angle, ultra detailed, light effect"
    },
    {
        "display": "Retro robot cityscape.",
        "full": "retro robot city at the other side of nowhere, sharp digital painting. dreaming latent space. concept art. artstation. casey weldon. digital render. dan mumford. clean retro. bruce pennington."
    },
    {
        "display": "Porcelain Joker Baphomet Statue",
        "full": "digital masterpiece illustration concept art of porcelain statue of joker as baphomet, extremely detailed and intricate complexity, epic composition, magical atmosphere, cinematic lighting, wide long shot, trending on artstation, 8 k"
    },
    {
        "display": "Lofi underwater naga portrait",
        "full": "lofi underwater bioshock naga portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Fantasy engineer portrait painting",
        "full": "a _ fantasy _ style _ portrait _ painting _ of woman, ponytail black hair, smile, round face, engineer rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Alien women bathing elegantly.",
        "full": "beautiful alien women bathing in luminous water in the style of roger dean and alberto vargas and stefan kostic, realistic, photoreal, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by greg rutkowski and artgerm, extreme blur coral reef background"
    },
    {
        "display": "Futuristic Tesla Model Ed",
        "full": "uhd photorealistic tesla model ed - 2 0 9, concept art, futuristic, uhd hyperdetailed photography"
    },
    {
        "display": "Sick prisoner peeking bars",
        "full": "a sick slim shady prigioner peeking through bars, elegant, digital painting, concept art, smooth, sharp focus, illustration, dark, from Dark Souls by Ruan Jia and Mandy Jurgens and Artgerm and William-Adolphe Bouguerea"
    },
    {
        "display": "Capybara on motorcycle",
        "full": "a photorealistic photograph of a knitted cute Capybara wearing sunglasses and dressed in a beanie cap in addition to riding on a blue motorcycle in Hollywood at dusk. Palm trees in the background. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and the style of Unreal Engine."
    },
    {
        "display": "Payday 2 characters reimagined.",
        "full": "payday 2 characters in team fortress 2 concept art, by greg rutkowski, cinematic lighting, atmospheric, trending on artstation, ultra realistic, 4 k, octane render, real life characters, high detailed"
    },
    {
        "display": "Ethereal geisha with branches",
        "full": "breathe into euphoria madison beer with a branches growing from her head, alterd carbon, geisha, dreamy ethereal vibe,, atmospheric, hyperdetailed detailed intricate render, hyperrealistic, glossy porcelain skin, rain, fibbonacci, detailed illustration, hd, 4 k, digital art, overdetailed art, gold, surrealistic, by greg rutkowski, by loish, trending on artstation, deviantart"
    },
    {
        "display": "Elegant ballroom portrait.",
        "full": "sansa emma watson in ballroom, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Ethereal cowboy campfire scene",
        "full": "a dramatic, epic, ethereal painting of a !!!handsome!!! thicc mischievous shirtless cowboy with a beer belly wearing a large belt and bandana offering a whiskey bottle | he is relaxing by a campfire | background is a late night with food and jugs of whisky | homoerotic | stars, tarot card, art deco, art nouveau, mosaic, intricate | by Mark Maggiori | trending on artstation"
    },
    {
        "display": "Ultra-detailed portrait of Jermaine Clement",
        "full": "ultra detailed close up facial portrait of jermaine clement, extremely detailed digital painting, in the style of fenghua zhong and ruan jia and jeremy lipking and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Surgeon operating turtle, sci-fi.",
        "full": "surgeon operating on a turtle, science fiction industrial hard science concept art, 8K render octane high definition cgsociety, illustration, unreal engine"
    },
    {
        "display": "Fantasy knockout punch illustration",
        "full": "knock out punch, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Macho King Cyborg Portrait",
        "full": "disney pixar portrait 8 k photo of macho king randy savage with realistic face wearing brightly colored sunglasses, cowboy hat, pants and jacket with tassles : : as wwf superstar by pixar : : as cyborg police officer : : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, annie leibovitz, rave, unreal engine, alphonse mucha : :"
    },
    {
        "display": "Digital war axe artwork.",
        "full": "digital artwork of a war axe. trending on artstation. zbrush, pbr, sculpt, unreal engine 5, concept art, gaming."
    },
    {
        "display": "Epic pregnant leader by bonfire.",
        "full": "epic leader pregnant woman talking to all her tribe around a bonfire, proud people looking at the pregnant woman, ice cave, facinating, fantasy digital art, octane render, beautiful composition, trending on artstation, award - winning photograph, masterpiece"
    },
    {
        "display": "Cybernetic ninja assassin",
        "full": "a beautiful cybernetic ninja assassin, cyberpunk concept art by guweiz and josan gonzalez, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Kawaii Neo Tokyo Fashion",
        "full": "neo tokyo japanese anime kawaii decora street fashion model with scribble and glitch effects, daz 3 d, glitch art, 3 d render, octane render, zbrush, oil painting, beeple, artgerm, sakimichan, by klarens malluta, psychedelic colors, the world ends with you, cel animation 1 9 8 0 s by anton fadeev, tetsuysa ishida and yoji shinkawa, kda ahri"
    },
    {
        "display": "Futuristic portrait of a woman.",
        "full": "Portrait of a woman by Greg Rutkowski, she is about 50 years old, redhead, long straight hair, beautiful oval face, wearing a futuristic tactical gear, older sister vibes, sad and resigned expression, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Viola Davis as sorceress.",
        "full": "viola davis as a sorceress, passionate, seductive, sweaty, intricate dressed in ornate blue robes and staff, lovely, intricate, highly detailed, digital painting, artstation, concept art, karma sutra, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed Arctic Fennec Fox",
        "full": "Arctic Fennec Fox, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Soviet Space Launch Art",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Soviet space launch. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Apple flagship store concept art",
        "full": "apple flagship store, apple logo, concept art by noah bradley, darek zabrocki, tyler edlin, jordan grimmer, neil blevins, james paick, natasha tan, highly detailed, ultra detailed, ultra realistic, trending on artstation"
    },
    {
        "display": "Apple-inspired space suit art",
        "full": "space suit designed by apple, concept art oil painting by Jama Jurabaev, extremely detailed, brush hard, artstation"
    },
    {
        "display": "Vaporwave-inspired minimalist artwork",
        "full": "hyper-realism, happy colors, pastel, minimal, simplistic, amazing composition, woman, vaporwave, wow, Gertrude Abercrombie, minimalistic graffiti masterpiece, minimalism, 3d abstract render overlayed, black background, psychedelic therapy, trending on ArtStation, ink splatters, pen lines, incredible detail, creative, positive energy, happy, unique, negative space, pure imagination painted by artgerm"
    },
    {
        "display": "\"Detailed cat girl portrait\"",
        "full": "nekopara fantastically detailed reflecting eyes modern anime style art cute detailed ears cat girl neko dress portrait  shinkai makoto vibrant Studio ghibli kyoto animation hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv neon"
    },
    {
        "display": "Cylindrical mountain quarters.",
        "full": "worm's - eye view of cylindrical living quarters carved inside a mountain, above lush garden and hot spring, well maintained, clean, medieval, fantasy genre, natural light, fantasy, natural light, sharp focus, concept art, by greg rutkowski and craig mullins, cozy atmospheric and cinematic lighting, trending on artstation"
    },
    {
        "display": "Futuristic London, apocalypse theme",
        "full": "london in 2 0 5 0, hyperrealistic, concept art, octane render, unreal engine 5, trending on artstation, high quality, 8 k, dramatic lighting, cinematic, high coherence, apocaliptic, path traced, digital art"
    },
    {
        "display": "Realistic liquid queen portrait",
        "full": "a realistic liquid queen with a decorated dress made of white pearls , highly detailed, digital painting, Trending on artstation , HD quality, by artgerm and greg rutkowski and alphonse mucha, dramatic light, octane"
    },
    {
        "display": "Vibrant tribal African portrait",
        "full": "a vibrant colorful head and shoulders portrait of the face of a strong tribal african, zoom out, immersive digital art experiences, glowing lights, magic details, by moebius, edward hopper, james gilleard, and james jean, hd, 8 k, trending on artstation, uhd,"
    },
    {
        "display": "Detailed animated forest scene.",
        "full": "forest clearing landscape, studio ghibli, pixar and disney animation, sharp, rendered in unreal engine 5, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wide angle, artbook, wallpaper, splash art, promo art, dramatic lighting, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Cyborg portrait in dystopia",
        "full": "closeup portrait shot of a sci - fi cyborg in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Renaissance smoke woman illustration",
        "full": "beautiful, haunting young woman made out of smoke and clouds, in renaissance times, detailed gorgeous face, flowing hair and gown, vaporwave aesthetic, synthwave long luxurious gown made out of pearls, hair done up with flowers and ribbons, digital art, butterflies, birds, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant villain digital art",
        "full": "Rena Nounen as a super villain, sexy, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by WLOP and Vincent van Gogh and James Jean, masterpiece, Refined, upscaled"
    },
    {
        "display": "Medieval knight sword cleaning.",
        "full": "a medieval knight cleaning his sword, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic city skyline at night",
        "full": "Beautiful, future city, at night, dimmed lights, rooftops are connected by bridges, ultrawide shots, by Justin Gerard, Ivan Aivazovsky and Caspar David Friedrich, trending on artstation"
    },
    {
        "display": "Passionate fire twirler illustration",
        "full": "fire twirler boy, passionate , seductive, sweaty, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Tribal woman with futuristic gun",
        "full": "young very beautiful cute tribal woman with a futuristic gun, in a post apocalyptic city overgrown with lush vegetation, by Luis Royo, by Greg Rutkowski, dark, gritty, intricate, volumetric lighting, volumetric atmosphere, concept art, cover illustration, octane render, trending on artstation, 8k"
    },
    {
        "display": "Handsome space soldier nebula.",
        "full": "space soldier, handsome young black male in front of exploding nebulae, digital illustration trending on artstation by artgerm and rutkowski"
    },
    {
        "display": "French girl at sunset.",
        "full": "photograph of a pretty french girl with dark hair, wearing a loose oversized white sweater, cuddled up by a windowsill sipping a mug of tea during sunset. dramatic lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Emotionally cold man portrait",
        "full": "emotionally cold man, concept art oil painting, portrait ethereal by jama jurabaev, extremely detailed, brush hard, medium, artstation"
    },
    {
        "display": "Sci-fi space artwork",
        "full": "sci-fi concept art in space by Vincent Di Fate"
    },
    {
        "display": "Hyperrealistic woman with exoskeleton.",
        "full": "hyperrealistic post - lowbrow super gorgeous! black woman with exoskeleton armor, merging with tree in a forest, highly detailed digital art masterpiece smooth cam de leon hannah yata dramatic pearlescent blue teal light ground angle hd 8 k sharp focus"
    },
    {
        "display": "Scary Mario Boo concept art",
        "full": "a scary boo from mario, dark fantasy photorealistic concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Floating monk, vaporwave energy.",
        "full": "a floating monk, channeling swirling energy, wearing netrunner clothing, vaporwave aesthetic, colorful, psychedelic, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Alien planet landscape.",
        "full": "a photo of a alien planet with a red sea and blue sand and rocks, vibrant red ocean, vibrant blue sand and rocks, mythical, concept art, octane render, unreal engine 5, trending on deviantart, highly detailed, high quality, 8 k, trending on artstation, digital art, high coherence, path traced, cinematic, dramatic lighting, drone view, high color contrast"
    },
    {
        "display": "Black female digital painting",
        "full": "A beautiful black female, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by Ross Tran and greg rutkowski and alphonse Mucha"
    },
    {
        "display": "Stylized queen portrait.",
        "full": "a oil painting of a fair skin with dark curly stylised hair queen wearing dress, by hans emmenegger, by bruce pennington, by eyvind earle, by nicholas roerich, by frank frazetta, by georgia o keeffe, highly detailed, realistic, concept art, jewels, tiles curtains, oriental, desaturated"
    },
    {
        "display": "Platypus in knight armor",
        "full": "a platypus in royal knights armor, dark thick smokey red fire, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Cyberpunk orc doctor portrait",
        "full": "portrait painting of a cyberpunk orc doctor extremely muscular patrick stewart with fangs and tusks, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Fantasy knight portrait",
        "full": "portrait knights of Zodiac girl+smoky eyes, metalic black and red mirror reflected armor, in ruined Agora of Athens, black magic, ssci-fi, fantasy, intricate, very very beautiful, elegant, golden light, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by tian zi and WLOP and alphonse mucha"
    },
    {
        "display": "Siren creature concept art.",
        "full": "beautiful siren, deceptive monster, creature concept art, weta studios, Guillermo Del Toro, pans labyrinth"
    },
    {
        "display": "Futuristic sport yacht design",
        "full": "a futuristic sport yacht by artgerm and greg rutkowski volumetric light, detailed, octane render, midsommar"
    },
    {
        "display": "Turquoise-haired girl in garden.",
        "full": "beautiful girl with long turqoise hair, in a garden, cute, intricate, highly detailed, digital painting, trending on artstation, concept art, smooth, sharp focus, backlit, rim light, vivid colors, illustration, unreal engine 5, 8 k, art by rossdraws and alphonse mucha"
    },
    {
        "display": "Symmetrical Batman Beyond portrait",
        "full": "symmetry!! portrait of batman beyond, 1 9 9 9 series, skinny, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lofi underwater portrait",
        "full": "lofi underwater artstation portrait, Pixar style, BioShock style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Legendary fairy prince casting lightning.",
        "full": "legendary fairy prince casting a lightning spell, blue energy, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap"
    },
    {
        "display": "Lifelike pencil illustration of Dot Cotton.",
        "full": "amazing lifelike award winning pencil illustration of dot cotton June brown trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Boris Johnson as DOOM character",
        "full": "Boris Johnson as the persona of the game DOOM highly detailed character in digital fantasy, painted portrait, artstation, concept art, hard focus, illustrations, works by Artgerm and Greg Rutkowski, Alphonse Mucha and Craig Mullins, James Gene, Andrey Ryabovichev, Mark Simonetti and Peter Morbacher, 16 thousand"
    },
    {
        "display": "Victorian Florence Nightingale scene",
        "full": "london 1 8 5 3, 3 3 year old florence nightingale, running a women's hospital in victorian london, improving the working conditions as well as patient care, finely detailed perfect art, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Symmetrical barbarian portrait",
        "full": "symmetry! portrait of barbarian, short blond hair, d & d, muscular!! angry, armour, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Falling man sculpture with wings.",
        "full": "a falling man  in the form of a Greek sculpture Winged Victory of Samothrace with wings and skulls and flowers sinking in lake bubbling red wax, super hero pose, skull, flowers, baroque. intricate. artwork like comix style. Trending on artstation. octane render, cinematic, hyper realism, octane render, 8k, depth of field, bokeh. iridescent accents. vibrant. teal and gold and red colour scheme"
    },
    {
        "display": "Cyberpunk teenager portrait.",
        "full": "hyperrealistic portrait of a young teenager with curly hair with vr glasses, by Guy Denning, by Johannes Itten, by Russ Mills, centered, glitch art, hacking effects, digital tech effects, cyberpunk, color blocking! , oil on canvas, intricate detail, concept art, abstract, octane render, octane, cgsociety, 8k, trending on artstation"
    },
    {
        "display": "Futuristic elegant gown portrait",
        "full": "elegant kitana high fashion gown aeon flux profile picture by greg rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, thierry mugler, norman rockwell,"
    },
    {
        "display": "Emma Watson smiling illustration",
        "full": "beautiful full body Emma Watson smiling illustration by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and Cecil Beaton, Lee Miller, Irving Penn, David Bailey, 3D unreal 5, hyperrealistic, octane render, cgsociety, Photolab, Lightroom, Photography Award"
    },
    {
        "display": "Futuristic portrait with wire hat",
        "full": "seed 2 9 6 0 8 7 8 6 2 8 in style of leonardo davinci futuristic portrait with hat made by wires and white silk twisted around her face, necklace made by wires, design by leonardo davinci, inspired by egon schiele, modern art, baroque art jewelry, new classic, hyper realistic, cinematic composition, cinematic lighting, fashion design, concept art, hdri, 4 k"
    },
    {
        "display": "Female adventurer in armor.",
        "full": "female adventurer in tight full - body black leather armor of japanese design and a black porcelain crow mask, trending in artstation, establishing shot"
    },
    {
        "display": "Samurai catgirl warrior",
        "full": "weta disney pixar epic movie shot of madison beer : : as samurai warrior catgirl by pixar : : flames : : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, marvel, unreal engine, pearlescent, bright morning, anime"
    },
    {
        "display": "Cyberpunk Juice WRLD Portrait",
        "full": "cyberpunk, closeup portrait of a juice wrld, dramatic light, city background, sunset, dystopian setting, high contrast, sharp, neuromancer, henry dorsett case, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Celestial meditation portrait",
        "full": "wind blowing hair meditation celestial being, dreaming and floating, bust portrait, dnd, face, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surreal goddess of death.",
        "full": "goddess of death, braids, decaying face, dress made of feathers, illuminated jewellery, digital painting, surrealism, extreme detail, cinematic lighting, trending on artstation, by fenghua zhong"
    },
    {
        "display": "Cyberpunk greyhound dog portrait",
        "full": "a portrait of an anthropomorphic cyberpunk greyhound dog eating a pink donut, cyberpunk!, cyberpunk cityscape background, fantasy, elegant, digital painting, artstation, concept art, matte, sharp focus, illustration, art by josan gonzalez"
    },
    {
        "display": "Duck eating peas energetically.",
        "full": "a high quality photo of a duck eating peas with great speed and vigor, digital art, 4 k, trending on artstation"
    },
    {
        "display": "Dystopian wolf mask portrait",
        "full": "Very very very very highly detailed epic photo of face with wolf venetian mask, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Artgerm and Brom and Vincent di Fate"
    },
    {
        "display": "Punk catfolk bard portrait",
        "full": "portrait painting of a punk catfolk bard with green eyes and snow white fur, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Cosmic love triumphs art.",
        "full": "concept art for the final act of the universe seconds before love completed it in rapturous divine perfect unity!! final victory of order over disorder!! final defeat of entropy! digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Superhero popcorn hybrid",
        "full": "super man dressed as spider man dressed as batman dressed as popcorn, masterpiece, highly detailed, high quality, 4 k, anatomically correct, hyperrealistic, concept art, octane render, unreal engine 5, trending on artstation, trending on deviantart, matte, historical painting, fantasy style, path traced, high coherence, soft lighting, digital painting, mythical"
    },
    {
        "display": "Alien meeting under moonlight",
        "full": "digital art, trending on artstation, race of technobiological aliens in a meeting at their base in the galaxy, night time, beautiful moon light"
    },
    {
        "display": "Lifelike pencil portrait of Pam Ferris",
        "full": "amazing lifelike award winning pencil illustration of Pam ferris trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Biocomputer forest scene",
        "full": "biocomputer organs inside a bioneural antique lace in a biomechanical forest with biocomputer flowers, epic environment, matte painting, diffused lighting, highly detailed cinematic, epic atmosphere, diffused lighting, highly detailed digital art, trending on artstation, depth of field, wide angle"
    },
    {
        "display": "Biden in cycling attire with seals.",
        "full": "joe biden wearing cycling shorts and gold chains surrounded by seals, elegant, real life skin, intricate, high detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski"
    },
    {
        "display": "Cheating card players in pub",
        "full": "two shady looking guys in the corner of a dark empty pub playing cards against each other while clearly cheating , made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "Futuristic astronaut girl.",
        "full": "character concept of a girl wearing a futuristic grey astronaut suit with orange glowing liquid pipes. coherent face, cute button anime button eyes. vibrant colors, soft lighting, adventurous, atmospheric lighting, 8 k, octane render. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Hyper-realistic fantasy landscape",
        "full": "a professional photographic view picture of a landscape ,photographic filter unreal engine 5 realistic hyperdetailed 8k ultradetail cinematic concept art volumetric lighting, fantasy artwork, very beautiful scenery, very realistic painting effect, hd, hdr, cinematic 4k wallpaper, 8k, ultra detailed, high resolution, artstation trending on artstation in the style of Albert Dros glowing rich colors powerful imagery"
    },
    {
        "display": "Photorealistic portrait of Ameera",
        "full": "a Photorealistic dramatic hyperrealistic, blue eyes, ameera al taweel, black hair, white veil,focus face, by WLOP,Artgerm,Greg Rutkowski,Alphonse Mucha, Beautiful dynamic dramatic ,shadows,Artstation,concept design art,Octane render,8K"
    },
    {
        "display": "Bodybuilder Luigi in suit",
        "full": "gigachad luigi bodybuilder in a expensive suit by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, futuristic background by laurie greasley, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Sebastian Stan as Star Trek captain.",
        "full": "sebastian stan as a star trek captain, a still from star trek painted by artgerm and greg rutkowski and alphonse mucha. clear highly detailed face, beautiful sci fi art"
    },
    {
        "display": "Hyperrealistic portrait of Sanna Marin",
        "full": "hyperrealistic painting of Sanna Marin, d&d, stunning 3d render inspired art by Tim Okamura and Lise Deharme + perfect facial symmetry + dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, intricate, epic composition, grim yet sparkling atmosphere, cinematic lighting + masterpiece, trending on artstation, very very detailed, masterpiece, stunning"
    },
    {
        "display": "Pizza Statue of David",
        "full": "a beautiful hyperrealistic detailed 3D render of the statue of David made of pizza, by Anton Otto Fischer, Atey Ghailan, genzoman, unreal engine, octane render, gigantic, 3D, brilliantly coloured, intricate, ultra wide angle, trending on artstation, embers, smoke, dust, dusk, volumetric lighting, HDR, polished, micro details, ray tracing, 8k,"
    },
    {
        "display": "Gloomy red night street.",
        "full": "red night street, gloomy and foggy atmosphere, octane render, artstation trending, horror scene, highly detailded"
    },
    {
        "display": "Haunted palace concept art",
        "full": "concept art of a haunted palace, imposing view, cinematic, horror, photorealistic, vintage, artstation, painterly, expressive"
    },
    {
        "display": "Medieval tavern stockroom interior.",
        "full": "an old medieval tavern stockroom with rows of barrels and wine racks, a single small rectangular window allows sun into the room, by greg rutkowski, artgerm, craig mullins, alan lee"
    },
    {
        "display": "Vogue fantasy portrait",
        "full": "Portrait of amazing vogue  amazon woman, D&D, fantasy, intricate, richly detailed colored pencil 3D illustration of a beautiful Brazilian mulata woman with long metallic hair wearing a hoodie and short shorts that is evil and happy. mirrored background with completely rendered reflections, art by Range Murata and Artgerm highly detailed, digital painting, trending on artstation, sharp focus, illustration, style of Stanley Artgerm"
    },
    {
        "display": "Elephant-like dog jumping.",
        "full": "( dog ) jumps over hill, ( dog ) looks like elephant, intricate, epic lighting, cinematic composition, hyper realistic, 8 k resolution, unreal engine 5, by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, marc simonetti, artstation"
    },
    {
        "display": "Girl reading in stormy setting.",
        "full": "hyperrealistic oil painting of a girl reading a book, realistic shaded perfect face, extremely fine details, realistic shaded lighting, stormy background, artgerm, 8 k ultra realistic, highly detailed, art by christopher balaskas, alphonse mucha, craig mullins, alena aenami, ultra detailed"
    },
    {
        "display": "Pirate king portrait, anime.",
        "full": "a pirate king, old - trimmed uniform with a red sash around his waist, stern expression, blue eyes. portrait. sun rays. hd, 8 k. anime. final fantasy concept art. artwork by wen yu li, art by wen yu li."
    },
    {
        "display": "Albino girl portrait, fantasy.",
        "full": "Close-up portrait of young albino girl, long blonde hair, dark fantasy, portrait, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Engraving of ceremonial hawk god",
        "full": "beautiful detailed engraving of a ceremonial hawk god, classical ornamental entail design, fibonacci flow, acroteria, encarpus, bead and reel, large medium and small elements, by russ abbott, albrecht durer, artgerm, rutkowski, trending on artstation, 8 k"
    },
    {
        "display": "Bald black paladin with hammer.",
        "full": "a bald black paladin wielding a large hammer standing in front of a majestic castle, world of warcraft concept art"
    },
    {
        "display": "Psychedelic fantasy art.",
        "full": "Psychedelic visions by Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Lofi biopunk Harley Quinn.",
        "full": "lofi biopunk portrait of harley quinn in space, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Gorgeous female portrait, winter.",
        "full": "a gorgeous female photo, professionally retouched, soft lighting, realistic, smooth face,!! half body crop!!, perfect eyes, wide angle, sharp focus on eyes, 8 k high definition, insanely detailed, intricate, elegant, art by artgerm, snowy winter"
    },
    {
        "display": "Cat girl tattoo design",
        "full": "tattoo design, stencil, cat girl, portrait of a beautiful japanese girls face framed by roses by artgerm, artgerm"
    },
    {
        "display": "Karl Urban Wolverine portrait",
        "full": "karl urban as wolverine, film nior, darkwave, darksynth character portrait, sharp, digital matte painting, art by luis royo, greg rutkowski, wlop, dramatic lighting, trending on artstation"
    },
    {
        "display": "Ukrainian super soldier victory.",
        "full": "a distant shot from behind of a Ukrainian super soldier with blue and yellow flag behind him standing alone on a huge pile of skulls as a winner, masculine muscular figure, D&D, fantasy, intricate, elegant, highly detailed, extremely detailed, digital painting, artstation, concept art, matte, smooth, sharp focus, symmetrical, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Human Morty portrait.",
        "full": "morty from rick and morty as a human wearing yellow shirt, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Elven druid on wyvern",
        "full": "a beautiful elven druid, clad in vines, riding a white wyvern, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Stylish girl in leather.",
        "full": "girl in very short white! top and very short gray! leather jacket, open belly, long dark curly hair, high waist sweatpants, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Danny DeVito as Disney Princess",
        "full": "full body portrait of danny devito as a disney princess, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and Anna Dittmann and Ilya Kuvshinov"
    },
    {
        "display": "Steampunk Jedi Temple Landscape",
        "full": "screenshot wide shot of Luke Skywalker Jedi Temple, good day, morning, landscape, no people, no man, steampunk, desert, lost world, Anime Background, concept art, illustration,smooth, sharp focus, 1970s thriller by Stanely Kubrick film, color kodak, ektochrome, wide angle anamorphic lenses, detailed faces, moody cinematography"
    },
    {
        "display": "Handsome Iron Man portrait",
        "full": "a character portrait of handsome iron man with glow, surrounded with spiriling sparkling flash crystals and galaxies, by jesper ejsing, aleksi briclot, hyper light drifter, by ilya kuvshinov katsuhiro, jim burns, ed emshwiller, greg rutkowski, trending on artstation"
    },
    {
        "display": "Film still of girl with Furbys.",
        "full": "1 9 7 5 film still of an 1 8 year old appearing isabelle adjani with her pet furbys, laser printed onto transparent glass by ross tran, katsuhiro otomo, yoshitaka amano, and artgerm. 3 d shadowing effect!!!, 8 k resolution!!!"
    },
    {
        "display": "Photorealistic Tracer Portrait",
        "full": "tracer from overwatch, character portrait, close up, concept art, intricate details, highly detailed photorealistic in the style of marco plouffe keos masons, joel torres, seseon yoon, artgerm and warren louw"
    },
    {
        "display": "Scarlett Johansson cinematic portrait",
        "full": "photo of scarlett johansson attractive features, tight clothing, full body shot in a movie, cinematic details, sharp focus, illustration, by Jordan Grimmer and greg rutkowski, Trending artstation, pixiv, digital Art"
    },
    {
        "display": "Ominous astronaut portrait.",
        "full": "a detailed profile oil painting of an ominous figure in a spacesuit with reflective visor, flight suit, portrait symmetrical and science fiction theme with aurora lighting by beksinski carl spitzweg and tuomas korpi. baroque elements, full-length view. baroque element. intricate artwork by caravaggio. Trending on artstation. 8k"
    },
    {
        "display": "Demonic energy cloth.",
        "full": "demonic microfibre cloth with energy flowing from it. concept art. cleaning utensil."
    },
    {
        "display": "Anime prince casting ice.",
        "full": "a portrait of anime prince casting ice - ball and shoot it, cyberpunk concept art, trending on artstation, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Elegant woman in red.",
        "full": "in the style of artgerm, arthur rackham, alphonse mucha, evan rachel wood, symmetrical eyes, symmetrical face, flowing red dress"
    },
    {
        "display": "Chonky female warrior portrait",
        "full": "a full body portrait of a beautiful chonky young female warrior as drawn by eric anthony johnson ericanthonyj artstation artgerm greg rutkowski and magali villeneuve 8 k subsurface scattering, soft light"
    },
    {
        "display": "Ancient Roman soldier illustration",
        "full": "an ancient legate roman soldier, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art,ruan jia, steve mccurry"
    },
    {
        "display": "Hyperrealistic Venus squid astronaut",
        "full": "hyperrealistic portrait, full body portrait, full shot of a venus squid monster astronaut defined facial features, intricate abstract. cyberpunk, symmetrical facial features. By Ruan Jia and Artgerm and Range Murata and WLOP and Ross Tran and William-Adolphe Bouguereau and Beeple. Key Art. Fantasy Illustration. award winning, Artstation, intricate details, realistic, Hyperdetailed, 8k resolution."
    },
    {
        "display": "3D Funko Pop Bodybuilder",
        "full": "full body 3 d render of bodybuilder girl as a funko pop!, studio lighting, white background, single body, no shadow, blender, trending on artstation, 8 k, highly detailed"
    },
    {
        "display": "Rugged steampunk ship captain.",
        "full": "rugged ship captain, male, handsome, flowing red hair, handsome, fantasy, intricate, elegant, highly detailed, piercing eyes, steampunk, digital painting, artstation, concept art, character art, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Dark fantasy character portrait",
        "full": "ryan hurst, final fantasy, darkwave, darksynth character portrait, sharp, digital matte painting, art by luis royo, greg rutkowski, wlop, dramatic lighting, trending on artstation"
    },
    {
        "display": "Synthwave brain solar system",
        "full": "a brain synthwave cyber bokeh interconnected with the solar system, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Cinematic red-haired girl in rain",
        "full": "a beautiful girl, messy long red hair and wet grey tribal clothes, short torn up pants, she is walking on a mountain, a very poor old shanti town with wooden ruins, medieval time, heavy rain, late at night, mist, cinematic, highly detailed, digital painting, artstation, m, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha and william"
    },
    {
        "display": "Aesthetic girl in bar.",
        "full": "full body picture of a girl smoking pipe in the bar rounge, bored, coveted, beautiful and aesthetic, intricate, unreal engine, messy hair, highly detailed, detailed face, smooth, sharp focus, chiaroscuro, manga illustration, artgerm, greg rutkowski, ilya kuvshinov, rossdraws, alphonse mucha, young adult light novel cover art"
    },
    {
        "display": "Bobby Drake Portrait",
        "full": "a portrait of bobby drake, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Detailed green goblin portrait.",
        "full": "green goblin, illustration, sharp focus, highly detailed, vertical portrait, concept art, smooth, dramatic lighting, facing forward, face in focus, in the style of Jim Lee"
    },
    {
        "display": "Alien mountain sculpture artwork",
        "full": "highly detailed professional landscape painting of alien bust carved into the mountains in the style of mount rushmore, trending on artstation, ultra detail, atmospheric lighting, sci - fi, futuristic, cyberpunk"
    },
    {
        "display": "Boris Johnson as Rick Sanchez",
        "full": "Boris Johnson as Rick Sanchez, one single eyebrow, white robe, big eyes, portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Chibi duck with handgun",
        "full": "a realistic and atmospheric watercolour fantasy character concept art portrait of a fat adorable dirty chibi duck wearing a wife beater and firing a handgun, by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Ray Liotta portrait, detailed.",
        "full": "a masterpiece portrait of ray liotta. very detailed eyes. intricate, elegant, highly detailed. trending on artstation, digital art, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano"
    },
    {
        "display": "Red Giger-style castle jungle.",
        "full": "of a beautiful red Hyperrealistic Giger tall royal castle in the middle of a dark scary smokey surreal jungle, creepy cute rainbow fluffy creatures, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, vibrant colors, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Steampunk sci-fi airship.",
        "full": "airship powered by stars, steampunk, cyberpunk, vibrant colors, fantasy, sci - fi, dark sky, city in backround, bright ship headlights, dramatic, intricate, artstation, concept art, smooth, sharp focus"
    },
    {
        "display": "Irish medieval port concept art",
        "full": "irish medieval port of carrickfergus, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Metahuman Kylie Jenner Renaissance",
        "full": "a highly detailed metahuman 8 k close up render of kylie jenner renaissance in iris van herpen dress schiaparelli in diamonds crystals swarovski and jewelry iridescent in style of alphonse mucha gustav klimt trending on artstation made in unreal engine 4"
    },
    {
        "display": "Bear-faced human hybrid portrait",
        "full": "human body bear faced hybrid creature, character concept art, portrait, epic, trending on artstation, very detailed, 4 k, hd, dramtic lighting, flowing energy, by beeple"
    },
    {
        "display": "Armored Cthulhu portrait.",
        "full": "greg manchess portrait painting of a huge armored cthulhu as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Guinea pig warrior portrait",
        "full": "Huge guinea pig warrior in armor holding a two-handed battle axe, portrait, woodlands, magic the gathering artwork, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Giant vs. Three-Headed Dragon",
        "full": "giant wielding an axe facing a three headed dragon, fantastical, cinematic lighting, 4 k smooth, focus, concept art, highly detailed, artstation, trending, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "Spider-Gwen digital artwork",
        "full": "Spider-Gwen, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and alphonse mucha, high definition digital art, in the style of ilya kuvshinov and Ross tran"
    },
    {
        "display": "Character portrait in scenery",
        "full": "a portrait of a character in a scenic environment by sandra chevrier, hyperdetailed, trending on artstation"
    },
    {
        "display": "Lovecraftian Pixar portrait.",
        "full": "lovecraft lovecraftian portrait of franz schubert, pixar style, by tristan eaton stanley artgerm and tom bagshaw, dali"
    },
    {
        "display": "Eerie sorceress with spider legs",
        "full": "sorceress with spider legs and spiky hair, volcano, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8k, unreal engine. art by Artgerm and Greg Rutkowski"
    },
    {
        "display": "Cyberpunk Venom character design",
        "full": "cyberpunk venom, character sheet, concept design, contrast, hot toys, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Shibari-themed gladiator portrait",
        "full": "portrait of a Shibari rope wrapped face and neck, headshot, insanely nice professional hair style, dramatic hair color, digital painting, of a old 15th century, roman gladiator, amber jewels, baroque, ornate clothing, scifi, realistic, realistic realistic realistic eyes, hyperdetailed, chiaroscuro, concept art, art by Franz Hals and Jon Foster and Ayami Kojima and Amano and Karol Bak,"
    },
    {
        "display": "Atreides departure to Arrakis.",
        "full": "The Atreides leave their homeworld Caladan for Arrakis, concept art inspired by Deak Ferrand, Ethereal, artstation, 8k"
    },
    {
        "display": "Feminine male jester anime",
        "full": "extremely attractive soft feminine male jester / fool anime character screenshot, nagito komaeda and hisoka, anime, intricate, sharp focus, illustration, highly detailed, digital painting, cell shaded, concept art, matte, male art by ilya kuvshinov and kyoto animation and wlop, ruan jia and greg rutkowski, studio quality, masterpiece"
    },
    {
        "display": "Realistic cat illustration",
        "full": "cat in a room. animal. digital art. artstation. realistic. vibrant. illustration. in the style of pixar movie. octane render. art by makoto shinkai, stanley artgerm lau, wlop, rossdraws. volumetric lighting."
    },
    {
        "display": "Pichu's birthday celebration",
        "full": "pichu celebrating his birthday, trending on artstation, artstationhd, pixiv, soft, cgsociety"
    },
    {
        "display": "Rimuru Tempest digital painting",
        "full": "rimuru tempest, tensei shitara slime datta ken, super highly detailed, professional digital painting, concept art, sharp focus, smooth, unreal engine 5, photorealism, hd quality, 8 k, black hoodie, cinematic, art by artgerm, yoshitaka amano, wataru kajika and junji ito"
    },
    {
        "display": "Trump as Captain America",
        "full": "Donald Trump as Captain America full body portrait, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, smooth, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha,  octane render, 8k, hyper realistic"
    },
    {
        "display": "Female Ottoman Sultan Portrait",
        "full": "emma watson portrait of ottoman sultan gog, female, clear face, symetrical, masculine, full body, muscular, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surprised blue-skinned mermaid priestess",
        "full": "blue-skinned mermaid robed priestess, surprised and confused looking at her raised hand in shock, dramatic, action shot, wide angle shot, D&D fantasy magic, intricate, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime female baseball player.",
        "full": "anime style, female baseball player, red sport clothing, first strike, brown short hair, hair down, symmetrical facial features, from arknights, hyper realistic, rule of thirds, extreme detail, 4 k drawing, safebooru, realistic lighting, by alphonse mucha, greg rutkowski, sharp focus, backlit"
    },
    {
        "display": "Ethereal cowboy tarot art",
        "full": "a dramatic ethereal epic symmetrical painting of a handsome!! cowboy in a silvery!!!! (((golden))) outfit | tarot card, art deco, art nouveau,  (steampunk), homoerotic, realistic | by Dresden Codak, by Mark Maggiori and ((((Alphonse Mucha))) | trending on artstation"
    },
    {
        "display": "Photorealistic birthday cake.",
        "full": "cinematic screenshot of a delicious looking birthday cake ; crisp sharp focus ; ultra realistic, concept art, intricate details, food photography, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Elegant woman by lake",
        "full": "portrait of a beautiful woman wearing a dress at a lake, looking at camera, d & d, nice outfit, long hair, intricate, elegant, stylish, realistic, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, ambient lighting, art by artgerm and greg rutkowski and alphonse mucha and simon stalenhag"
    },
    {
        "display": "Futuristic portrait of Lex Fridman",
        "full": "portrait of lex fridman, concept art by greg rutkowski, futuristic and brutalist environment, scifi, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Sincere girl portrait, hat.",
        "full": "A portrait of a sincere-looking girl, oil on canvas, wearing a hat made by wires ,masterpiece, hi-fructose, artgerm , Norman rockwell, craig mullins, n, trending on pxiv, highly detailed face, clear eyes concept art, hdri, 4k-"
    },
    {
        "display": "Vi Portrait - Realistic Art",
        "full": "portrait of Vi from League of Legends, by Fortiche Studio, by Riot Games, from Netflix's Arcane, trending on artstation,fine details, realistic shaded, fine-face, painted texture, pretty face, Character Portrait, Digital Art, Highly Detailed"
    },
    {
        "display": "Ethereal Angela Merkel Yoga Portrait",
        "full": "a full body portrait of a beautiful ethereal delicate angela merkel meditative ancestral yoga power pose, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Dark sorceress portrait.",
        "full": "ellen page with yellow glowing eyes as a dark sorceress casting a green spell, green lighting, fantasy character portrait, ultra realistic, concept art, intricate details, highly detailed by james bama, william adolphe bouguereau and frank frazetta"
    },
    {
        "display": "Cthulhu-themed Katy Perry illustration",
        "full": "Very very very very highly detailed epic photo of an ancient Cthulhu Katy Perry in ocean, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Artgerm and Vincent di Fate"
    },
    {
        "display": "Cute sci-fi creature design",
        "full": "a cute strange sci fi fatty animal by neville page, ken barthelmey, sharp focus, trending on artstation, cinematic lighting, hyper realism, octane render, 8 k, hyper detailed, vivid, ultra detailed, highly detailed, zbrush, concept art, creature design"
    },
    {
        "display": "Loving relationship transcendence art.",
        "full": "the concept of a loving relationship, transcendence, airbrush art, shamanic dmt horror art, by edwin austin abbey"
    },
    {
        "display": "\"Fantasy soldier portrait\"",
        "full": "portrait of a mildly muscular female ww ii soldier in team fortress 2 style, epic, tragic, dark fantasy art, fantasy, pretty, hd shot, digital portrait, beautiful, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Mischievous Latina witch illustration.",
        "full": "Full shot of a mischievous young witch about to get up to some trouble. Latin American fashion. Black and Orange palette. Latina girl. brown skin. defined facial features, symmetrical facial features. By Ruan Jia and Artgerm and Range Murata and WLOP and Ross Tran and William-Adolphe Bouguereau. Key Art. Fantasy Illustration. award winning, Artstation, intricate details, realistic, Hyperdetailed, 8k resolution."
    },
    {
        "display": "Emma Watson fantasy portrait",
        "full": "a beautiful Emma Watson portrait, with a brilliant, impossible striking big organic matter subsurface scattering material headpiece, clothes entirely made out of organic subsurface scattering material, symmetrical, dramatic studio lighting, rococo, baroque, jewels, asian, hyperrealism, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, 8k, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Elegant Tomb Raider Portrait",
        "full": "portrait shot of christina hendricks in tomb raider, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Futuristic ship bridge design",
        "full": "scifi art by Greg Rutkowski, the ship's bridge deck, full of screens and holographic maps with a large window looking into space, functional and elegant look, but minimalist, and a bit dark and lonely, claustrophobic and futuristic environment, detailed and intricate environment, high technology, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Glamorous Japanese lawyer portrait",
        "full": "glamorous and sexy japanese lawer in chemisier, beautiful, pearlescent skin, natural beauty, seductive eyes and face, elegant girl, lacivious pose, natural beauty, very detailed face, seductive lady, full body portrait, natural lights, photorealism, summer vibrancy, cinematic, a portrait by artgerm, rossdraws, Norman Rockwell, magali villeneuve, Gil Elvgren, Alberto Vargas, Earl Moran, Enoch Bolles"
    },
    {
        "display": "VFX portrait of blond boy",
        "full": "highly detailed vfx portrait of a blond centre parting pretty boy with blue eyes by eiichiro oda, makoto shinkai, alphonse mucha, sakimichan, sharp focus, art by artgerm and greg rutkowski!, backlit, harsh overhead sunlight, detailed,"
    },
    {
        "display": "Es\u00fa artwork illustration",
        "full": "the orisha Es\u00fa, trending on artstation"
    },
    {
        "display": "Mickey Mouse Smurf painting.",
        "full": "mickey mouse as smurf, oil on canvas, artstation trending"
    },
    {
        "display": "Futuristic female cyber warrior.",
        "full": "portrait futuristic female cyber warrior, on cyberpunk neon light tokyo rooftop, ssci - fi and fantasy, intricate and very beautiful, highly detailed and digital painting, concept art, smooth, illustration, art by rossdraws and taekwon kim / a - rang, luxearte and liya nikorov and rongzhen luo"
    },
    {
        "display": "Overcrowded tram, moody comic.",
        "full": "people in grey suits in an overcrowded tram, depressed mood, in the style of artgerm, gerald brom, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Colonel Sanders' wedding to chicken.",
        "full": "wide shot of Colonel Sanders getting married to a chicken in a church, modern setting, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Aztec warrior goddess design",
        "full": "character design, aztec warrior goddess, crown of body length feathers, full body, glowing aztec tattoos, beautiful, dark fantasy, horror scene, hyper - realistic, very detailed, intricate, very sexy pose, slight smile expression, unreal engine, by artgerm, wlop and ross thran, dramatic cinematic lighting rendered by octane, 8 k, detailed"
    },
    {
        "display": "Scarlett Johansson, elongated figure.",
        "full": "scarlett johansson with an very long torso, possibly extra limbs, stefan kostic, artgerm, intricate, detailed, volumetric lighting, digital painting, concept art, greg rutkowski"
    },
    {
        "display": "Massive hovering frog.",
        "full": "hyperrealism, detailed textures, photorealistic 3 d, a massive hovering frog emerging from a fluffy cloud, ultra realistic, 1 million pixel detail, cinematic, intricate, cinematic light, concept art, illustration, art station, unreal engine 8 k"
    },
    {
        "display": "Hunter as Solaire, eerie art",
        "full": "an ultra detailed vector image of the hunter from bloodborne dressed as solaire of astora, concept art by alphonse mucha and greg rutkowski, scary shadows, blood moon eclipse, octane render, liminal space"
    },
    {
        "display": "Electric fantasy automaton.",
        "full": "science - fantasy humanoid automaton infused with electricity, in the style of artgerm"
    },
    {
        "display": "\"Hyper-detailed female portrait\"",
        "full": "a female looking directly while her left eye is closed and the other is open, naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Cell-shaded Dora portrait.",
        "full": "a study of cell shaded portrait of Isabela Moner as dora the explorer, concept art, illustration, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00edn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Elegant woman with white hair",
        "full": "a gorgeous female with longwhite hair in the style of stefan kostic, realistic, full body shot, wide angle, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, floating embers"
    },
    {
        "display": "Goth Yakuza Girl Portrait",
        "full": "portrait of goth yakuza girl tattoos, piercing, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william-adolphe bouguereau"
    },
    {
        "display": "Cyberpunk Messi Illustration",
        "full": "messi with cyberpunk implants, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic utopian cityscape.",
        "full": "futuristic utopian metropolis, masterpiece, white buildings, golden sunset, green trees, hanging gardens, planet in the sky, utopia, high quality, beautiful design, scifi, 4 k, high detail, street view, trending on artstation, art by leon tukker"
    },
    {
        "display": "Ginger cat as Jabba",
        "full": "a fat ginger cat as jabba the hutt smoking chicha, star wars, beautiful glowing lights, smoke, sci - fi, stunning, intricate, elegant. highly detailed, digital painting. artstation. smooth. sharp focus. illustration. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Nilah, League of Legends, digital art",
        "full": "nilah, from league of legends, au naturel, hyper detailed, arabic, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Surreal Mongolian Fashion Portrait",
        "full": "hunter shafer, traditional mongolian clothing, fame of thrones, fibonacci, sweat drops, intricate fashion clothing, insane, intricate, highly detailed, surrealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "American man in armor",
        "full": "an portrait white man american in the assault powered armor, style game square enix life, trending on artstation, painted by greg rutkowski, render naughty dog, octane render, detailed"
    },
    {
        "display": "Cthulhu woman portrait.",
        "full": "Epic potrait of Cthulu depicted as a woman, by Ryohei Fuke, Huke, personification, featured on pixiv, dystopian, body horror, rayonism, cold hue's"
    },
    {
        "display": "Teenage Medusa portrait.",
        "full": "portrait of teenage medusa, bald, raised eyebrow, wicked smile, black snakes cover her head, wearing an embroidered orange tunic, intricate, elegant, copper and emerald jewelry, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mucha, artgerm, and greg rutkowski"
    },
    {
        "display": "Psychedelic car portrait",
        "full": "an extremely psychedelic portrait of a car, surreal, lsd, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Goddess Tree Portrait",
        "full": "tree goddess, full shot, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Cyberpunk fox eating noodles",
        "full": "white - furred anthropomorphic female vulpes vulpes fulva wearing cyberpunk clothes, eating noodles, in the rain by a noodle kiosk, in the crowded and wet street of a city, cyberpunk, harsh neon lights, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Elemental creature sushi art.",
        "full": "3 d render creature animal sushi cristal eye roots cactus elemental flush of force nature micro world fluo deepdream gloomhaven, matte painting rpg skill concept art, art nouveau, swirly vibrant color lines, fantastically gaudy, aesthetic octane render, 8 k hd resolution, by ilya kuvshinov and cushart krentz and gilleard james a glowing aura global illumination ray tracing hdr art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Sailor Moon in Wallace and Gromit",
        "full": "Sailor Moon in Wallace and Gromit, cinematic lighting, battered, sci-fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Humanoid cyber robot portrait.",
        "full": "friendly humanoid cyber robot, award winning, portrait bust, symmetry, faded colors, cypherpunk background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, cinematic composition, beautiful lighting, hyper detailed!!!, 8 k, oil on canvas, dreamy"
    },
    {
        "display": "Elegant female android portrait",
        "full": "portrait of female android, symmetry, intricate, elegant, highly detailed, smooth, sharp focus, concept art, digital painting, illustration, artstation, by fra angelico, sandra chevirer and greg ruthkowski"
    },
    {
        "display": "Vampire Cat in Hat",
        "full": "complex 3 d render, hyper detailed, ultra sharp, vampire cat in the hat, scary, cute, cinematic, head and shoulders, steampunk, natural soft light, rim light, octane render, artstation, art by artgerm and greg rutkowski and alberto seveso, dr seuss"
    },
    {
        "display": "Tattooed armored wanderer pinup.",
        "full": "selina kyle as a tattooed armored wanderer pinup, wearing scratched and ripped leather shorts and a short jacket full of stamps and stitches, character sheet, fine details, concept design, contrast, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Surreal abandoned astronaut portrait",
        "full": "ribbed abandoned astronaut in spacesuit on exoplanet, baroque portrait painting, standing in a desolate empty wasteland, creepy, nightmare, dream-like heavy atmosphere, surreal abandoned buildings, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, chiaroscuro, Raphael, Caravaggio, Beksinski, Giger"
    },
    {
        "display": "Intricate abstract artwork.",
        "full": "mage who prohibits nephrite, intricate abstract. intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Young Sinatra in dystopian bar.",
        "full": "perfect composition, cinematic atmosphere, award-winning concept art, detailed digital painting, airbrushed: young Frank Sinatra as a poor 1950s bartender. Soviet dystopia, cyberpunk, steampunk. Poverty. Volumetric cinematic lighting, great attention to perfect anatomy, special attention to posing, great attention to realistic facial expression, faithful cinematic color scheme, perfectly coherent. In the style of: Greg Rutkowski, Francis Bacon, Syd Mead, Norman Rockwell, Beksinski, Edward Hopper, James Gilleard, Ilya Kuyshinov, WLOP, Stanley Artgerm, Takato Yamamoto, and James Jean."
    },
    {
        "display": "Smug minotaur with greataxe.",
        "full": "huge smug minotaur wielding a greataxe, realistic art by donato giancola and greg rutkowski, digital art, trending on artstation, symmetry!!"
    },
    {
        "display": "Close-up of deer head.",
        "full": "a close up of the head of a deer with very bright eyes, background of a landscape misty forest scene, the sun glistening through the trees, hyper realistic photograph, octane render 8k, trending on artstation, unreal engine"
    },
    {
        "display": "Elegant fantasy AI robot",
        "full": "beautiful ai robot d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant young woman portrait",
        "full": "an attractive young female with metal rings!!!! on her nose wearing an bone crown, jennifer lawrence, olive skin, long dark hair, beautiful bone structure, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "\"Android with Flamingo Bucket Hat\"",
        "full": "a lone android walking in a city, he has a computer screen for a face, on his head he is wearing a bucket hat made of a flamingo print cloth, trending on artstation, famous scifi artist"
    },
    {
        "display": "Princess in navy gown",
        "full": "full - length portrait of a princess with green eyes and freckles, dressed in a navy blue gown with gold embroidered details, dark brown hair, detailed face, fantasy, cinematic lighting, digital art painting, fine details by realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Muted watercolor knight concept",
        "full": "a simple and atmospheric watercolour fantasy character concept art painting of a fairytale brave knight, very muted colors, by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Eerie warrior woman painting",
        "full": "painting of dark muscular oiled bengoli woman, bloody, carrying a sword, symmetric, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Magical dancing mice artwork",
        "full": "magical sparkly mice, dancing, sleeping, sugar plums, fairy, gay, cinematic, concept art, painting, painted by botticelli"
    },
    {
        "display": "Detailed Dallas portrait, Halloween style",
        "full": "highly detailed portrait of payday 2 member dallas, the nightmare before christmas movie style characters, profile picture, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Cyberpunk dog portrait",
        "full": "a cyberpunk portrait of a dog by jean - michel basquiat, by hayao miyazaki by artgerm, highly detailed, sacred geometry, mathematics, snake, geometry, cyberpunk, vibrant, water"
    },
    {
        "display": "Futuristic Egyptian princess illustration",
        "full": "symmetry!! egyptian princess of technology, solid cube of light, hard edges, product render retro - futuristic poster scifi, lasers and neon circuits, brown skin man egyptian princess, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, dreamlike, art by artgerm"
    },
    {
        "display": "Minimalistic abstract graffiti art",
        "full": "Gertrude Abercrombie, minimalistic graffiti masterpiece, minimalism, 3d abstract render overlayed, black background, psychedelic therapy, Esao Andrews, trending on ArtStation, ink splatters, pen lines, incredible detail, creative, positive energy, happy, unique, negative space, face, artgerm"
    },
    {
        "display": "Abandoned museum gallery.",
        "full": "salon style gallery of forgotten paintings in an abandoned museum, misty forest, mysterious + katsuhiro otomo and ilya repin and caspar david friedrich and lawrence alma - tadema and moebius and james jean and loish and lya kuvshinov + mike jordana, konstantin porubov, valeriy vegera, golden ratio + rule of thirds, hypermaximalist, painterly, elegant + ornate + luxury, + elite, radiant, matte painting, cinematic + cinematic lighting, cgsociety, atmospheric, nier automata game + character concept art + creepy, diffused lighting + cinematic lighting by craig mullins"
    },
    {
        "display": "Elegant wyvern overlooking mountains.",
        "full": "wyvern watching the world underneath, mountains, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Realistic portrait of pregnant Olivia Munn.",
        "full": "pregnant olivia munn in a black dress, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk female portrait",
        "full": "portrait of female humanoid, intricate, retro 6 0 s cyberpunk fashion, elegant, cyber neon lights, highly detailed, digital photography, trending in artstation, trending in pinterest, glamor pose, concept art, smooth, sharp focus, art by artgerm and greg rutkowski"
    },
    {
        "display": "Blonde woman in 1940s attire.",
        "full": "A beautiful close-up of a blonde woman who looks like Olivia Wilde and Madonna and Marlene Dietrich, dressed like in the 1940s, digital art by Edward Hopper, vibrant color scheme, highly detailed, in the style of romanticism, fine Art, high detail, great lighting, 8k resolution, masterpiece, concept art, illustration, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, HDR, trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Celestial retro-futuristic portrait",
        "full": "celestial female machine, facial portrait, retro-futuristic, legendary epic shot, 90s make-up, galaxy space hunter, cyber implants, wires, low angle, dawn, by artgerm, julie bell, beeple and Greg Rutkowski, airbrush, science fantasy, 90s, concept art, realistic matte painting, Smooth gradients, octane render, 8k, High contrast, duo tone, depth of field, volumetric lightning, very coherent, symmetrical, skin pore detail"
    },
    {
        "display": "Creepy anthropomorphic gang portrait",
        "full": "anthropomorphic highly detailed payday 2 gang portrait in creepy rayman legends style, intricate, elegant, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "High fantasy cyberpunk cityscape",
        "full": "concept art, high fantasy, cyberpunk, cityscape"
    },
    {
        "display": "Dapper Indian portrait with lilies.",
        "full": "artwork by Saul Leiter, of a solo individual portrait of an Indian guy with lilies, dapper, simple illustration, domestic, nostalgic, full of details, Matte painting, trending on artstation and unreal engine"
    },
    {
        "display": "Surreal fractal portrait",
        "full": "duotone surrreal concept illustration 3 / 4 portrait of bennoit mandelbrot with fractals patterns in the background. cinematic scene. vlumetric lighting. golden rario accidental renaissance. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. concept art. trending on artstation"
    },
    {
        "display": "Heavenly empty swimming pool.",
        "full": "a professional photographic portrait view picture of a heavenly empty swimming pool, photographic filter unreal engine 5 realistic hyperdetailed 8 k ultradetail cinematic concept art volumetric lighting, fantasy artwork, very beautiful scenery, very realistic painting effect, hd, hdr, cinematic 4 k wallpaper, 8 k, ultra detailed, high resolution, artstation trending on artstation in the style of albert dros glowing rich colors powerful imagery"
    },
    {
        "display": "Red-haired goddess portrait",
        "full": "perfectly - centered close - up portrait - photograph of red - haired goddess with glowing - red - eyes, the perfect human female specimen, with a fiery aura, intricate, elegant, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, 8 k, by anne stokes"
    },
    {
        "display": "Hyperrealistic surrealist resort.",
        "full": "artgem and Beeple masterpiece, hyperrealistic surrealism, award winning masterpiece with incredible details, stunning virtual world abandoned resort with palm trees around a pool, a surreal vaporwave liminal space, metaverse, highly detailed, trending on ArtStation, calming, meditative,, sharp details, dreamscape"
    },
    {
        "display": "Scary Cat in the Hat",
        "full": "complex 3 d render, hyper detailed, ultra sharp, of the cat in the hat, scary, dark, cinematic, natural soft light, rim light, art by greg rutkowski and artgerm and norman rockwell, dr seuss"
    },
    {
        "display": "Papal turtle portrait",
        "full": "regal papal pond turtle wearing a pope hat, D&D, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm, greg rutkowski and magali villeneuve #pope francis #red ear slider turtle #vatican #pond turtle #catholic"
    },
    {
        "display": "Biomechanical android Buddha portrait",
        "full": "perfectly centered portrait, front view of a beautiful biomechanical android alien robot buddha, female, flowing hair, intense stare, sarcastic smile, symmetrical, concept art, intricate detail, volumetric shadows and lighting, realistic oil painting by tim hildebrandt,"
    },
    {
        "display": "Hyperrealistic portrait of Steve McQueen",
        "full": "a colored colorized portrait of steve mcqueen, hyperrealistic, extremely realistic, highly realistic, hd quality, 4 k resolution, 8 k resolution, detailed, very detailed, highly detailed, extremely detailed, intricate details, real, very real, oil painting, digital painting, painting, trending on deviantart, trending on artstation, in the style of david levine, in the style of greg rutkowski"
    },
    {
        "display": "Glamorous Jill Valentine portrait",
        "full": "glamorous feminine jill valentine, very detailed face, studio lighting, portrait by Magali Villeneuve and Steve Argyle,Livia Prima,Mucha,fantasy art,beautiful,artstation,trending on artstation,alluring,masterpiece"
    },
    {
        "display": "Cute realistic Lalisa Manoban",
        "full": "realistic detailed semirealism beautiful gorgeous cute Blackpink Lalisa Manoban wearing white camisole white lingerie outfit maid costume, black hair black cat ears blue eyes, black leather choker full HD 4K high resolution quality WLOP, Aztodio, Taejune Kim, Guweiz, Pixiv, Instagram, Artstation"
    },
    {
        "display": "Elegant fantasy portrait.",
        "full": "a portrait of dwight shrute, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Cyberpunk girl-robot portrait",
        "full": "portrait of beautiful girl with robot body by artgerm and fenghua zhong, close up, portrait, cinematic, elegant, artstation, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cyberpunk, cgsociety, 8 k"
    },
    {
        "display": "Baroque portrait with roses",
        "full": "portrait of christina hendricks with roses, baroque, roses, intricate abstract upper body intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, deviantart, greg rutkowski, cinematic arthouse, key art, hyper realism, iridescent accents"
    },
    {
        "display": "Lovecraftian Einstein in Pixar style.",
        "full": "lovecraft lovecraftian portrait of einstein, pixar style, by tristan eaton stanley artgerm and tom bagshaw."
    },
    {
        "display": "Borg Queen portrait, detailed.",
        "full": "portrait of the borg queen from star trek the next generation lady with glasses, ginger,, elegant, real life skin, intricate artwork, high detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski"
    },
    {
        "display": "Snoopy as Pope, detailed art",
        "full": "A picture of Snoopy dressed as the Pope, 4k, concept art, digital painting, detailed, artstation, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Nami with orange hair",
        "full": "nami, long orange hair, brown eyes, digital art from artstation by artgerm and william - adolphe bouguereau"
    },
    {
        "display": "Jason Momoa as Dhalsim, floating.",
        "full": "jason momoa as dhalsim street fighter, sitting and floating in mid air, surrounded by fire, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by frank frazetta, simon bisley, brom"
    },
    {
        "display": "Elegant portrait of Casey Cott.",
        "full": "portrait of casey cott with blonde hair, blonde hair, blue eyes, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mars ravelo and greg rutkowski"
    },
    {
        "display": "\"Elegant J.Lo Tomb Raider Portrait\"",
        "full": "attractive female portrait of jennifer lopez as tomb raider, rule of thirds, haze, intricate, symmetrical!!, depth of field, cinematic, filmic, vsco, concept art, artstation, digital painting, elegant, model, gorgeous, vouge, kodak, vray, cinema 4 d, octane render, ambient occlusion, lumen"
    },
    {
        "display": "Elegant girl illustration",
        "full": "girl with beret, sharp details, sharp focus, elegant, highly detailed, illustration, by Jordan Grimmer and greg rutkowski and PiNe(\u30d1\u30a4\u30cd) and \u85af\u5b50Imoko and \u9999\u5ddd\u60a0\u4f5c and wlop and maya takamura, intricate, beautiful, Trending artstation, pixiv, digital Art"
    },
    {
        "display": "Whimsical architecture scene.",
        "full": "Whimsical architecture buildings, next to a fountain and a mystical palace, hyperrealistic mixed media, stunning 3d render inspired art by P. Craig Russell and Barry Windsor-Smith + perfect facial symmetry + dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, intricate futuristic mechanic parts, epic composition, bright and sparkling atmosphere, cinematic lighting + masterpiece, trending on artstation"
    },
    {
        "display": "Magical fairy in space",
        "full": "magical fairy floating in space, epic cartoon portrait made out of rain, beautiful face, stunning concept art, highly detailed, galaxy background, rendered in octane, unreal engine, trending on artstation, realistic, diviantart"
    },
    {
        "display": "Futuristic gun concept art",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, futuristic guns"
    },
    {
        "display": "Beautiful digital painting.",
        "full": "most beautiful painting in the world by william - adolphe bouguereau, john singer sargent, digital painting, artstation, concept art, smooth, sharp focus, warm lighting,"
    },
    {
        "display": "Eerie couple kissing portrait",
        "full": "portrait painting of couple kissing, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Raven-winged vampire portrait",
        "full": "raven winged female vampire, fantasy, portrait painted by Raymond Swanland artgerm"
    },
    {
        "display": "Cyberpunk character book cover",
        "full": "concept art character, very high angle view, book cover, very attractive woman with full lips, walking in cyberpunk valley highly realistic, fine details, borderland 3 style, Painting, by Ashley Wood and Jamie Hewlett"
    },
    {
        "display": "Futuristic survival game landscape.",
        "full": "futuristic pubg meets deadspace game first person perspective. screenshot in game, future technology, ancient overgrown with trees outpost, unreal engine 5, colorful, contrast, depth of field, render, greg rutkowski, zabrocki, karlkka, jayison devadas, zdzis\u0142aw beksinski, trending on artstation, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Photorealistic female priestess concept art",
        "full": "concept art sheet, photorealistic symmetrical beautiful young female priestess with shiny hair wearing full intricate clothing, intricate, cg society, Elden Ring, darksouls, bloodborne"
    },
    {
        "display": "Kat Dennings as Paladin",
        "full": "Kat Dennings, portrait, Arsen Lupin as a Paladin, D&D, Gloomhaven, matte painting concept art, art nouveau, beautifully backlit, swirly vibrant color lines, fantastically gaudy, aesthetic octane render, 8K HD Resolution, by ilya kuvshinov and Cushart Krentz and Gilleard James"
    },
    {
        "display": "Watercolor Pok\u00e9mon in meadow",
        "full": "an watercolour painting of Charmander, Bulbasaur and Squirtle in a green meadow by carl spitzweg and tuomas korpi. baroque elements, full-length view. baroque element. intricate artwork by caravaggio. Trending on artstation. 8k"
    },
    {
        "display": "Normani as Aeon Flux",
        "full": "normani as aeon flux starfire profile picture by Greg Rutkowski, matte painting, intricate, fantasy concept art, elegant, by Stanley Artgerm Lau, WLOP, golden ratio, thomas kindkade, alphonse mucha, loish, norman Rockwell,"
    },
    {
        "display": "Psychedelic robot portrait",
        "full": "cinematic bust portrait of one psychedelic robot, exotic alien features, city background, desaturated, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Symmetrical Buzz Lightyear portrait",
        "full": "symmetry!! portrait of buzz light year, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elf sorceress in lake",
        "full": "a portrait of a female elf sorceress bathing in a forest lake by karol bak and jia ruan, beautiful detailed eyes, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4 k, hdr, concept art, detailed jewelry, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Tribal woman on jaguar",
        "full": "a beautiful cute tribal woman riding a mutated jaguar in a post apocalyptic city overgrown with lush vegetation, by Luis Royo, by Greg Rutkowski, dark, gritty, intricate, backlit, strong rimlight, cover illustration, concept art, volumetric lighting, volumetric atmosphere,sharp focus, octane render, trending on artstation, 8k"
    },
    {
        "display": "Ted Kaczynski as Walter White",
        "full": "ted kaczynski as walter white from breaking bad. oil painting elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker drew struzan"
    },
    {
        "display": "Teddy bear in Wonderland.",
        "full": "a beautiful colorful interesting detailed sci-fi storybook fantasy scene of a teddy bear wearing a backpack walking through a Wonderland full of weird trees and flowers, magic the gathering, Marc Simonetti and Anato Finnstark, neon pastel color palette, vibrant 8k rendering, Pixar concept art, trending on artstation HQ"
    },
    {
        "display": "Detailed fantasy portrait.",
        "full": "old man with a ring on each finger, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Knight in armor portrait",
        "full": "a detailed painting portrait inspired by moebius and beksinski of a knight in armour with aurora. inspired by detailed sculpture. inspired by alien. inspired by alex gray. inspired by brain scan. science fiction poster. upperbody. cinematic sci - fi scene. accurate anatomy. symmetry. portrait and science fiction theme with lightning, aurora lighting. clouds and stars. smoke. futurism. fantasy. by beksinski carl spitzweg. baroque elements. baroque element. intricate artwork by caravaggio. oil painting. oil on canvas. award winning. dramatic. trending on artstation. 8 k"
    },
    {
        "display": "Wild West book cover",
        "full": "ismail inceoglu epic painting of a wild west cover, line art!!, ink, art concept for a book cover!!, red dead redemption, warm colours, blue and orange and green and grey and white colors, nice sky, oil painting, think brushstrokes, highly detailed, centered, hyperrealism, romantic, concept art, smooth, sharp focus trending on artstation, by greg manchess and by craig mullins and by kilian eng and by jake parker"
    },
    {
        "display": "Taylor Swift Concept Art",
        "full": "taylor swift, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k"
    },
    {
        "display": "Italian actress jail portrait",
        "full": "bemused to be in jail portrait of an italian actress looking straight on, complex artistic color ink pen sketch illustration, full detail, gentle shadowing, fully immersive reflections and particle effects, concept art by artgerm"
    },
    {
        "display": "Paralysis, possession, divine eye.",
        "full": "paralyzed possessed by the deceased, the all seeing eye in the forehead of reality, a fire of a million guns, the mother of a millions sounds, god emperor of civilization illustration trending on artstation, anime. by peter mohrbacher and artgerm and greg rutkowski and ilya kuvshinov. high quality, stunning, intricate detailed character. 8 k"
    },
    {
        "display": "Timoth\u00e9e Chalamet Dune portrait.",
        "full": "timothee chalamet as a character in dune, closeup portrait art by donato giancola and greg rutkowski, realistic face, digital art, trending on artstation, symmetry!!"
    },
    {
        "display": "Hyperrealistic brown-skinned goddess.",
        "full": "Beautiful Goddess with long brown hair and brown skin brown eyes wearing leather armor by Ilya Kushvikov, symmetrical face concept art hyperrealism, Pouty expression, artstation"
    },
    {
        "display": "Magical maid in forest",
        "full": "A beautiful female maid wearing a magical bikini in a magical forest, detailed face, flirting gaze, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Teenage girl in raven cloak.",
        "full": "a teenage girl with very short black hair and a huge cloak made of black raven feathers. beautiful highly detailed face. beautiful painting by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk fox woman smoking",
        "full": "white anthropomorphic female vulpes vulpes fulva, long snout, fluffy tail, smoking a cigarette in the rain, in crowded and wet street of a city, cyberpunk, harsh neon lights, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Robotic monster in futuristic city.",
        "full": "a vibrant matte painting of a robotic monster in a futuristic city by simon beak and greg rutkowski, trending on artstation, unreal engine, 3 d 4 k, dynamic lighting, concpet art, sci - fi, dramatic lighting"
    },
    {
        "display": "Divine couple at wedding",
        "full": "close up moment of a divine a sun god and a moon goddess lovers magician at a wedding banquet, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, asian feature, illustration, art by artgerm and daniel gerhartz and magali villeneuve"
    },
    {
        "display": "Demonic grizzly bear design.",
        "full": "Crimson red, draconian grizzly bear, scaly, demonic, reptilian, white stripes all over its body, character design sheet, Moebius, Greg Rutkowski, Zabrocki, Karlkka, Jayison Devadas, Phuoc Quan, trending on Artstation, 8K, ultra wide angle, zenith view, pincushion lens effect."
    },
    {
        "display": "Fjord Beach House Illustration",
        "full": "norwegian fjord beach house designed by zaha hadid, intricate, highly detailed, digital painting, trending on artstation, concept art, smooth, sharp focus, backlit, rim light, vivid colors, illustration, unreal engine 5, 8 k, art by rossdraws and alphonse mucha"
    },
    {
        "display": "Elvish sorceress casting fireball",
        "full": "a female elvish sorceress casting a fireball | | pencil sketch, realistic shaded, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Elven Mecha Warrior Princess",
        "full": "art championship winner trending on artstation portrait of a goddess elven mecha warrior princess, neon highlights, portrait cute-fine-face, pretty face, realistic shaded Perfect face, fine details. Anime. realistic shaded lighting by katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey head and shoulders, blue hair, matte print, pastel pink neon, cinematic highlights, lighting, digital art, cute freckles, digital painting, fan art, elegant, pixiv, by Ilya Kuvshinov, daily deviation, IAMAG, illustration collection aaaa updated watched premiere edition commission \u2728\u2728\u2728 whilst watching fabulous artwork \\ exactly your latest completed artwork discusses upon featured announces recommend achievement"
    },
    {
        "display": "Druids with electronic instruments",
        "full": "portrait of a a band of male and female druids playing an electronic!!-musical-instruments, intricate, stunning, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate wooden staff illustration.",
        "full": "a wooden staff. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Transparent Mayan jaguar warrior.",
        "full": "polaroid photo of a transparent mayan jaguar warrior high detailed sharp focus dark horror night interior"
    },
    {
        "display": "Pregnant Taylor Swift portrait",
        "full": "pregnant taylor swift in a blue dress, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Angel princess portrait.",
        "full": "a masterpiece ultrarealistic ultradetailed portrait of a incredibly beautiful angel princess with amogus simple dimple. baroque renaissance girl in the night forest. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano. background by james jean and gustav klimt, light by julie bell, 4 k, porcelain skin."
    },
    {
        "display": "Symmetrical geisha with machine parts.",
        "full": "symmetry!! scarlett johansson, geisha, machine parts embedded into face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Cute laughing zombie",
        "full": "a zombie laughing cute, Tristan Eaton, victo ngai, artgerm, RHADS, ross draws"
    },
    {
        "display": "Bioluminescent critter illustration",
        "full": "a tiny critter with enormous eyes made of bioluminescence, fantasy, elegant, crisp 8 k line work, emissive lighting, digital painting, artstation, unreal engine, octane render, concept art, matte, sharp focus, illustration, art by bob eggleton"
    },
    {
        "display": "Swedish village in winter",
        "full": "swedish woman in snowy swedish village, ornate, beautiful, atmosphere, vibe, mist, smoke, fire, chimney, rain, wet, pristine, puddles, melting, dripping, snow, creek, lush, ice, bridge, green, stained glass, forest, roses, flowers, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell"
    },
    {
        "display": "Elegant fantasy portrait.",
        "full": "portrait of lux from league of legends fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Beer bottle in court.",
        "full": "a bottle of beer testifying in court, dramatic lighting, illustration by Greg rutkowski, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Eldritch giant fox in forest.",
        "full": "painting of eldritch otherworldly giant fox striking a terrifying pose, scene inside forest, by zdzislaw beksinski, by dariusz zawadzki, by wayne barlowe, gothic, surrealism, cosmic horror, biomorphic, lovecraftian, cold hue's, warm tone gradient background, concept art, beautiful composition"
    },
    {
        "display": "Intricate anthropomorphic creature",
        "full": "anthropomorphic triacontagon head in glimmery earthlore emily maynard tradesman, intricate, elegant, highly detailed creature, wide angle, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Hyperrealistic League of Legends Pinup",
        "full": "Jill Winernitz, pinup, league of legends, intricate, highly detailed, digital painting, hyperrealistic, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha, by Jesper Ejsing"
    },
    {
        "display": "Bob Ross on Gryphon",
        "full": "bob ross!!! riding!!! a gryphon!!, giant afro!, model pose, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elon Musk as wizard.",
        "full": "elon musk as wizard of science and technology, wizards robes, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant skeleton dragon.",
        "full": "skeleton dragon watching the world underneath, mountains, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and dragolisco"
    },
    {
        "display": "Cheerful medieval innkeeper portrait.",
        "full": "young, freckled, curly haired, redhead Natalie Portman  as a optimistic!, cheerful, giddy medieval innkeeper in a dark medieval inn. dark shadows, colorful, ((candle light)),  law contrasts, fantasy concept art by Jakub Rozalski, Jan Matejko, and J.Dickenson"
    },
    {
        "display": "Tattoo design: Hawaiian girl.",
        "full": "tattoo design, stencil, photography, tattoo stencil, traditional, beautiful portrait of a traditional Hawaiian girl with flowers in her hair, upper body, by artgerm, artgerm, artgerm, digital art, cat girl, anime eyes, anime, sexy, super model-s 100"
    },
    {
        "display": "Minimalist bonsai olive tree",
        "full": "bonsai olive! tree but minimalistic concept art by frank stella gilleard james whalen tom, colorful, soft light, trending on artstation, minimalism"
    },
    {
        "display": "Vivid beach paradise art",
        "full": "Beach paradise, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by Jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Hyperrealistic model portrait",
        "full": "full body pose, hyperrealistic photograph of victoria secret model nerd girl, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Elegant organic cyborg illustration",
        "full": "organic cyborg, caved wood and brass, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Imperfect life concept art",
        "full": "concept art for the last imperfect physical life - form before the universe is again in rapturous perfect unity, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Viking character concept art.",
        "full": "three different views of a viking in armour, concept art by senior character artist, trending on artstation, artstation hd, full body"
    },
    {
        "display": "Soviet dieselpunk spaceship artwork",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: symmetrical Soviet dieselpunk Millenium Falcon in empty space. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Cute spider illustration",
        "full": "cute spider cute, illustration, digital art, inspired by little big planet, by greg rutkowski, sharp, masterpiece, highly detailed, photorealistic, octane render, 8 k, unreal engine 5, trending on artstation, vivid colors"
    },
    {
        "display": "Attractive girl in armor.",
        "full": "a photorealistic painting of an attractive young girl, partially clothed in battle armor, olive skin, long dark hair, beautiful bone structure, symmetrical facial features, perfect eyes, intricate, elegant, digital painting, concept art, illustration, sharp focus, minimal artifacts, from Metal Gear, in the style of Ruan Jia and Mandy Jurgens, by Greg Rutkowski, trending on Artstation, award winning"
    },
    {
        "display": "Handsome Squidward Voldemort Portrait",
        "full": "a portrait of voldemort as handsome squidward, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Nepali female warrior portrait",
        "full": "portrait painting of a nepali female warrior and riding a horse, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed furry lynx artwork",
        "full": "anthropomorphic lynx from behind, furry digital art, hand drawn, 4k, trending on artstation, highly detailed"
    },
    {
        "display": "Anthropomorphic pink rabbit character.",
        "full": "realistic detailed semirealism anthropomorphic pink rabbit character wearing a bucket hat. Cute, kawaii, Cooky, bt21, Sanrio inspired. Rabbt_character, rabbit_bunny, \u7363, iconic character splash art, Detailed fur, detailed textures, 4K high resolution quality artstyle professional artists WLOP, Aztodio, Taejune Kim, Guweiz, Pixiv, Instagram, Artstation"
    },
    {
        "display": "Elegant blonde witch portrait.",
        "full": "beautiful human witch with blonde long straight hair in intricate ornate witch robe, haughty evil look, witch hat. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose."
    },
    {
        "display": "Cinematic furry cat woman.",
        "full": "cinematic portrait of one furry tabby cat woman, faded colors, exotic alien features, cyber background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, zack snyder, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Hyper-realistic Yoko Taro character",
        "full": "yorha no. 2 type b, medium shot, asymmetrical, profile picture, hyper realism, cinematic, volumetric lighting, epic composition, high detail, octane render, unreal engine, 8 k, concept art, 3 d render, digital art, deviantart artstation, ray tracing, apocalyptic, intricate complexity, extremely detailed, very sharp,"
    },
    {
        "display": "Cyborg anime man portrait",
        "full": "a detailed manga illustration character full body portrait of a dark haired silver cyborg anime man with a red mechanical eye and wearing a tattered brown cape, trending on artstation, digital art, 4 k resolution, detailed, octane render, high quality, sharp focus, hq artwork, insane detail, concept art, character concept, character illustration, full body illustration, cinematic, dramatic lighting"
    },
    {
        "display": "Early 1900s Maiko Art",
        "full": "early 1 9 0 0 s maiko, fluent composition, red and white neon, concept art, ambient light, 4 k, intricate details, highly professionally detailed, cgsociety, highly detailed -"
    },
    {
        "display": "Battle-damaged spaceship in space",
        "full": "oil painting with brushstrokes, closeup view of a battle damaged space ship adrift in space, sci-fi classic, intricate, elegant, digital painting, trending on artstation, concept art, sharp focus, paint splatters, illustration by greg rutkowski, peter elson, 4k."
    },
    {
        "display": "Headless Horseman Portrait",
        "full": "portrait of headless horseman holding a lantern, halloween night, charlie bowater, artgerm, ilya kuvshinov, krenz cushart, ruan jia, realism, ultra detailed, 8 k resolution"
    },
    {
        "display": "Artificer portrait, dramatic lighting.",
        "full": "closeup portrait of an artificer, dungeons and dragons character, dramatic lighting, castle background, gorgeous view, realistic, high detail, digital art, painted by greg rutkowski, painted by jeremy mann, painted by alphonse mucha, trending on artstation"
    },
    {
        "display": "Epic aasimar paladin portrait",
        "full": "an epic fantastic realism comic book style portrait painting of an aasimar paladin, male, shaggy silver hair, scruffy brown beard, d & d concept art, unreal 5, daz, petrol aesthetic, octane render, full head, cosplay, rpg portrait, dynamic lighting"
    },
    {
        "display": "Evil goddess character portrait",
        "full": "evil goddess with halo, ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Hybrid creature art.",
        "full": "painting of hybrid between cat!!!! & snake & lobster, animal has lobster skin, animal has snake body, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Flooded tower painting",
        "full": "flooded tower, a high contrast!! minimalist!! ultradetailed photorealistic painting by magritte stalenhag spitzweg and burns, dark shadows, sunny day, full - length view, vibrant colors, symmetry, great composition, high detail, cinematic lighting, award winning masterpiece, trending on artstation"
    },
    {
        "display": "\"Kraken Attack at Sea\"",
        "full": "small boat. massive giant kraken coming out of a stormy sea in the distance, giant waves, lightning in background, intricate, detailed, volumetric lighting, sharp focus, scenery, photorealism, digital painting, highly detailed, concept art, ruan jia, dark souls, steve mccurry"
    },
    {
        "display": "Filipina ballerina in desert",
        "full": "stunningly beautiful, filipina prima ballerina in desert, symmetrical face, golden hour, smooth, focus, highly detailed, hyper realistic, dramatic lighting, elegant, intricate, concept art, low angle, art by wlop, mars ravelo, greg rutowski, artstation"
    },
    {
        "display": "Glass gothic cathedral, cinematic.",
        "full": "Outdoor of a gothic cathedral which is made by only using glass material, unreal engine 5, trending on artstation, by Roger Deakins, cinematic counter light, high detail, octane render, 4k, vibrant"
    },
    {
        "display": "Mythical hippy festival scene.",
        "full": "a centered render of an mytical festival hippy with tribal tattoos wearing intricate metallic clothing surrounded by a underwater ink pour and flowing liquid gallium and sacred geometry, perfect body and face, gorgeous, cinematic, beautifully lit, by peter kemp, by karol bak, by donato giancola, 3 d, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Anime portrait of blonde.",
        "full": "anime portrait of a beautiful blonde, yellow - orange eyes, ilya kuvshinov, black clothing, head tilt, anime, pixiv top monthly, trending on artstation, cinematic, danbooru, zerochan art, kyoto animation"
    },
    {
        "display": "Cute anime girl with evidence.",
        "full": "a cute anime girl tampering with evidence. Key Frame, Top rated on pixiv, Makoto Shinkai, Inc. , High detail"
    },
    {
        "display": "Realistic portrait of girl",
        "full": "a highly realistic, true to life portrait of a beautiful young middle eastern girl, sharp focus, from the waist up, with sharp features, a beautiful face, soft smile, under studio lighting, taken with a canon eos camera with 1 3 5 mm focal length, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic tree in desert.",
        "full": "a large tree with yellow foliage in a lifeless desert landscape, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Apocalyptic clock tower sunrise",
        "full": "a destroyed clock tower in a destroyed London at sunrise, ruins, clouds in the sky, concept art, octane render, unreal engine 5, trending on Artstation, high quality, 8K, soft lighting, highly detailed, trending on DeviantArt, mossy, apocaliptic, serene landscape, beautiful, cgsociety"
    },
    {
        "display": "Neon Portrait of Woman",
        "full": "a award winning half body portrait of a beautiful woman with stunning eyes in a off shoulder croptop and cargo pants with rainbow colored hair, outlined by whirling illuminated neon lines and fine lines swirling in circles by jesper ejsing and rhads and makoto and shinkai and lois van baarle, digital art, trending on artstation"
    },
    {
        "display": "Alien goddess fashion render",
        "full": "a highly detailed 4 k render of a beautiful alien goddess bella hadid angel in iris van herpen dress schiaparelli in diamonds in style of alphonse mucha trending on artstation made in unreal engine 4"
    },
    {
        "display": "Jeju Island beach sunset",
        "full": "extremely detailed and intricate portrait of a beach on jeju island in korea, carribean white sand, carribean turquoise water, sun set, rule of thirds, lake, nature, mountains, artstationhd, artwork by artgerm, art style by kelly mckernan and brian froud and esao andrews and thomas kinkade"
    },
    {
        "display": "Lanky female scarecrow concept.",
        "full": "concept art of a scary lanky female scarecrow, artstation, pencil"
    },
    {
        "display": "Young Hedy Lamarr, cyberpunk.",
        "full": "young hedy lamarr, neuromancer, cyberpunk, high detail, dramatic light, digital art, dark, promotional art painted by seb mckinnon and greg rutkowski, trending on artstation"
    },
    {
        "display": "Handsome male wolf in suit.",
        "full": "portrait of a wolf, male, handsome, masculine, full body, red hair, long hair, soft hair, fantasy, intricate, elegant, highly detailed, suit, coffee shop, digital painting, artstation, concept art, character art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vampire girl in autumn landscape",
        "full": "autumn landscape with red trees, a girl with with hair becoming bloody fanged vampire autumn red leaves, intricate, dreamy, digital painting, artstation, concept arti, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vi from Arcane portrait",
        "full": "face of vi from arcane, centered, symmetrical, artgerm, artstation"
    },
    {
        "display": "Detailed Anor Londo illustration.",
        "full": "Anor Londo. Highly detailed, digital art, smooth, sharp focus, illustration, octane render, vivid colors, concept art, 4k."
    },
    {
        "display": "Pink sauce in ornate goblet",
        "full": "a stream of delicious thick pink sauce pouring into an exquisitely detailed beautiful goblet covered in gold filigree carvings of mystical scenes, glimmering diamonds, sapphires and rubies, lapis lazuli inlay, precious artifact, hyperrealistic, 8K HD octane render, fantasy concept art, cinematic lighting, popular on artstation"
    },
    {
        "display": "Alien in swamp bubble",
        "full": "an alien in a gloomy bubble in a swamp environment. pulp sci - fi art. high contrast. tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Metroidvania Boss Concept Art",
        "full": "2 d metroidvania map with a stage boss, hollow knight inspired, concept art, digital painting, trending on artstation, highly detailed, epic composition, 8 k uhd"
    },
    {
        "display": "Happy Chinese man, anime.",
        "full": "a 50 year old brunnete happy chinese man with puffy cheeks sitting on a couch at home and talking on the phone, close up shot, anime art, Greg Rutkowski, studio ghibli, dramatic lighting"
    },
    {
        "display": "Bigby Portrait in Hyperspace",
        "full": "concept art by jama jurabaev, cel shaded, cinematic shot, trending on artstation, high quality, brush stroke, hyperspace, vibrant colors, portrait of bigby from the among us by telltale games"
    },
    {
        "display": "Symmetrical silhouette portrait.",
        "full": "symmetry!! portrait of a silhouette of a woman in the world of gustave dore, horror, fashion, dark!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed portrait of Pyrrha Nikos.",
        "full": "highly detailed closeup portrait of Pyrrha Nikos from RWBY, very detailed, realistic, card, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell J."
    },
    {
        "display": "Futuristic city rapids at night",
        "full": "turbulent river rapids rushing through a futuristic city at night , volumetric lighting, blue and red glowing lights, 4k, octane, digital painting, artstation, concept art, sharp focus, illustration, high contrast, high saturation , cinematic film still, by artgerm and greg rutkowski"
    },
    {
        "display": "Vampire Anya Taylor-Joy",
        "full": "rafael albuquerque comic art, art nouveau, peter mohrbacher, artgerm, pretty anya taylor - joy vampire sharp vampire teeth open mouth, symmetrical eyes, black leather jacket, jeans, long blonde hair, full body"
    },
    {
        "display": "Detailed paramedic mech portrait",
        "full": "portrait of medical paramedic patlabor ape mech,wires,gadgets,cables,lenses,gears,led displays,UI intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical robotic scientist portrait.",
        "full": "symmetry!! portrait of robotic scientist, tech wear, scifi, glowing lights!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime helicopter with realism.",
        "full": "anime helivopter | | very anime, realistic shaded robotic parts, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Anime male character emerging from ancient tree",
        "full": "Cosmic body, anime spectral male character, emerge from big ancient tree of life, rainbow aura, eye of horus, full body portrait, photorealistic, volumetric lighting, octane rendering, dark and mysterious, atmospheric, ominous, inviting, cinematic, real, concept art, Epic, 8k, 4k, ultra detail, ultra realistic, trading art station, rendered perfectly"
    },
    {
        "display": "Futuristic portrait of John Brown",
        "full": "Portrait of John Brown from Torrington with a long beard wearing futuristic power armor, fantasy, intricate, highly detailed, digital painting, trending on artstation, sharp focus, illustration, style of Stanley Artgerm and Dan Mumford"
    },
    {
        "display": "Detective Goose Warrior",
        "full": "a goose in a detective suit, otter, dystopia core, apocalyptic, armor, warrior, dramatic, sharp focus, fiction, neon, fantasy, hyper detailed, digital art, trending in artstation, cinematic lighting"
    },
    {
        "display": "Detailed black flaming skull portrait",
        "full": "black flaming skull, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Intricate cathedral fractals.",
        "full": "a beautiful 3 d painting of a sprawling intricate cathedral populated by mandelbrot fractals by android jones, carved soap, white color scheme, unreal engine, volumetric lighting, dynamic lighting, dramatic lighting, high contrast, depth of field, carved marble, opalescent, sacred geometry, religious, angelic, catholicpunk, stark, trending on artstation"
    },
    {
        "display": "Cute babysitter portrait.",
        "full": "cute buxom emma watson babysitter, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Anime-inspired realistic portrait.",
        "full": "h. r. giger very very anime!!!, fine, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Cyberpunk skull painting",
        "full": "a beautiful painting of a ( ( cyberpunk ) ) skull by simon stalenhag and pascal blanche and alphonse mucha! and nekro!. in style of digital art. colorful comic, film noir, symmetry, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Elegant Batman gown portrait.",
        "full": "elegant batman gown profile picture by greg rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, mugler, norman rockwell,"
    },
    {
        "display": "Natalie Portman as Galadriel.",
        "full": "natalie portman as galadriel concept art, master painting, greg rutkowski, wlop, stanley artgerm lau, wojtek fus, trending on artstation, 8 k 4 k highly detailed, ink drawing,"
    },
    {
        "display": "Cyberpunk girl portrait",
        "full": "kyoto animation, cool girl wearing cyberpunk intricate streetwear, respirator, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Pregnant woman at night",
        "full": "pregnant woman under street light, highly detailed, sharp focused, ultra realistic digital concept art by Stanley Artgerm"
    },
    {
        "display": "Cute tennis ball monster in space.",
        "full": "cinematic portrait of a cute tennis ball monster in space, neon lasers, chalk, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp details, hyper - detailed, hd, hdr, 4 k, 8 k, art by basil gogos"
    },
    {
        "display": "White observatory in clouds",
        "full": "beautiful painting of a white observatory in the cloudy dreams of a mainframe in the style of Simon St\u00e5lenhag and H. R. Giger, detailed, trending on Artstation"
    },
    {
        "display": "Scarecrow couple dancing artwork",
        "full": "detailed soft painting of a scarecrow and his wife dancing. lino print elegant highly detailed artstation concept art matte folk horror"
    },
    {
        "display": "Feminine male jester portrait",
        "full": "award winning digital portrait of a feminine attractive male jester / fool by sakimichan with soft facial features, hisoka male art by sakimichan, beautiful carnival with soft pastel colors and lights, trending artstation, digital art, aesthetic, bloom, intricate, elegant, sharp focus, digital illustration, highly detailed, octane render, digital painting, concept art, artgerm, fantasy, masterpiece, by lisa buijteweg and sakimichan"
    },
    {
        "display": "Goddess with radiant sunflowers",
        "full": "sacred sunflowers, chinese goddess, emiting mystical light, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, radiant light, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Samurai temple landscape.",
        "full": "samurai, samurai temple, bridge, digital art, irina french, heraldo ortega, mandy jurgens golden ratio,, award winning, masterpiece, trending on artstation, 8 k 1 5 0 mpx"
    },
    {
        "display": "High fantasy diamond castle",
        "full": "beautiful concept art of a high fantasy castle by alan lee made of diamond filled with copper veins, blue translucent resin, bioluminescent, ultra realistic, ultra detailed, masterpiece by mc escher and hr giger, 8 k octane render, ambient diffusion, subsurface scattering, trending on artstation, cgstudio"
    },
    {
        "display": "Giant ghost versus paladin.",
        "full": "giant female ghost looms over a tiny female paladin, high fantasy, dnd, highly detailed, smooth, sharp focus, digital painting, artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Zeraora concept art portrait",
        "full": "Portrait of Zeraora, made by Yoji Shinkawa, Highly detailed, dynamic posing, concept art"
    },
    {
        "display": "Attacked Japanese palace scene.",
        "full": "japanese style palace under attack, hyper realistic, lush gnarly plants, 8 k, denoised, by greg rutkowski, tom bagshaw, james gurney cozy atmospheric and cinematic lightingg"
    },
    {
        "display": "Epic spellcasting cat",
        "full": "Cat, Anthropomorphized, casting epic  spell, horror, magic the gathering artwork, D&D, fantasy, centered, symmetrical, highly detailed, artstation, concept art, sharp focus, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting"
    },
    {
        "display": "Dante in Limbo, Comic Art",
        "full": "Dante walking through Limbo, in the Style of Artgerm and Charlie Bowater and Atey Ghailan and Mike Mignola, vibrant colors and hard shadows and strong rim light, Comic Cover Art, plain background, trending on artstation"
    },
    {
        "display": "Victorian woman with claw nails",
        "full": "a victorian woman with nails like claws, young woman, blue dress, white sleeves, medium golden-blonde hair, indignant, character art, full body art, Dungeons and Dragons, D&D, trending on artstation, artgerm, 4k ultra hd, sharp focus, digital art by Ilya Kuvshinov and Ross Tran"
    },
    {
        "display": "Happy Zelda Portrait",
        "full": "a portrait of a cinematic still of the happy zelda, pastel, closed eyes, wringing hands, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and takaya imamura, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Flying squirrels vs. clowns.",
        "full": "flying squirrels fight clowns in the streets, illustration, artstation, concept art, 8 k,"
    },
    {
        "display": "Giant mech in hayfield",
        "full": "giant humanoid mech stands over hay field by simon stalenhag, atmospheric haze, children in white jackets below look up, misty blue hour, sci fi digital painting, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, cinematic, professional photography, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Full-body Kacey Rohl Wendigo",
        "full": "a portrait of a beautiful full body Kacey Rohl Wendigo, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm, digital art, highly detailed, intricate, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Bee portrait with ink smoke",
        "full": "black crimson ink smoke portrait of a bee, artgerm, wlop, artstation"
    },
    {
        "display": "World War I ghoul portrait",
        "full": "portrait of a fallout ghoul during world - war - one, horror, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Fantasy portrait of Persian princess.",
        "full": "persian princess, nazanin boniadi, art by artgerm and greg rutkowski and magali villeneuve, intricate, elegant, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration"
    },
    {
        "display": "Yoda portrait, digital art",
        "full": "Yoda looking at the camera, 4k, art by Donato Giancola and Bayard Wu, digital art, trending on artstation"
    },
    {
        "display": "Detailed portrait of Euler",
        "full": "Leonhard Euler, trending on artstation, environment and concept art, wide shot, iridescent, flickering light, , full body, minimalistic, very detailed, dramatic cinematic lighting rendered by octane, 8k, detailed, intricate, clean and textures, trending on artstation, treanding on deviantart, trending on cgsociety, pinterest"
    },
    {
        "display": "Anime maids in war.",
        "full": "baroque oil painting of key visual portrait concept art of anime maids entrenched in the great war, brutalist, dark fantasy, rule of thirds golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai studio ghibli genshin impact jamie wyeth james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "Anime violinist on ocean",
        "full": "Anime key visual of kaori from your lie in april wearing a white dress while passionately playing the violin and standing on water in the middle of the ocean surrounded by a blue sky and soft clouds, vibrant, expressive, trending on artstation, kimi no na wa"
    },
    {
        "display": "Scary Cat in the Hat",
        "full": "complex 3 d render, hyper detailed, ultra sharp, of the cat in the hat, scary, cinematic, natural soft light, rim light, octane render, artstation, art by artgerm and greg rutkowski, dr seuss"
    },
    {
        "display": "Elegant incubus magazine cover",
        "full": "a elegant and beautiful incubus in magazine cover art, cinematic, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Dragon attack on hobbit homes",
        "full": "a huge shiny black dragon is attacking and burning medieval hobbit homes, centered, ornate, beautiful, atmosphere, vibe, mist, smoke, chimney, rain, well, wet, pristine, puddles, waterfall, clear stream, bridge, orange, green, stained glass, forest, flowers, concept art illustration, color page, 4 k, tone mapping, doll, akihiko yoshida, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, digital illustration, greg rutowski, volumetric lighting, sunbeams, particles, rembrandt"
    },
    {
        "display": "Dobby the Bodybuilder",
        "full": "dobby the house elf as a bodybuilder, flexing, big muscles, elegant intricate digital painting artstation concept art by mark brooks and brad kunkle and beksinski extreme detail 4 k"
    },
    {
        "display": "Giant bear Lovecraft monster",
        "full": "a giant bear-shaped lovecraft monster beast with 6 arms running quickly through a woodland, scary bear monster, 4 arms, magical forest, fantasy, Ireland, England, king Arthur, Lord of the rings, cinematic, realistic style, beautiful, majestic, dramatic lighting, early morning, dawn CGsociety, realistic, hyper maximalist, golden ratio, octane render, rule of thirds, wide shot , 8k resolution, epic volumetric light, cinematography, concept art, Artstation trending, environments, fantasy"
    },
    {
        "display": "Symmetrical horror fashion portrait",
        "full": "symmetry!! portrait of elle fanning in re 7, horror, fashion, dark!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dark evil elf portrait",
        "full": "portrait shot of dark evil elf in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Boris Johnson as Rick Sanchez",
        "full": "Boris Johnson as smiling Rick Sanchez from Rick and Morty, unibrow, white robe, big eyes, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Post-apocalyptic Spiderman worker",
        "full": "A full portrait of a  post apocalyptic spiderman ice worker, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Tattooed dik dik monster",
        "full": "a dik dik monster with tattoos wearing a baseball hat, colorful, digital art, fantasy, magic, trending on artstation, ultra detailed, professional illustration by basil gogos"
    },
    {
        "display": "Fantasy portrait of Trump and Biden.",
        "full": "Trump and Biden!!!!! , portrait, close shot, dark makeup, dark crown with magical ruby, painting by Studio Ghibli, Ivan Aivazovsky and Greg Rutkowski, artstation, fantasy, intricate, beautiful, cinematic, octane render, arnold render, 8k, hyper realism, detailed, sharp focus, 4k uhd, masterpiece, award winning"
    },
    {
        "display": "Anime girl with wings.",
        "full": "girl with uniform and metal wings, serious, finely detailed, made by wlop, artgerm, ross tran, full body portrait, illustration, grass, sunny, sky, anime, front view, perfect anime face, detailed face, zoomed out, smooth,"
    },
    {
        "display": "Feral girl in jungle.",
        "full": "Manga cover portrait of an extremely cute and adorable beautiful feral girl running through the jungle with her animal friends at sunset, 3d render diorama by Hayao Miyazaki, official Studio Ghibli still, color graflex macro photograph, Pixiv, DAZ Studio 3D"
    },
    {
        "display": "Intricate cyborg female portrait",
        "full": "hyperrealistic photography of a highly intricate, stunning cyborg female, elbow, armpit, knee,, intimate, close bodies, hands holding in the style of beth cavener, jin kagetsu, chuck close, rankin, and wlop, highly detailed, face, eyes closed, symmetry, masterpiece, award - winning, sharp focus, intricate concept art, ambient lighting, 8 5 mm lens, 8 k, artstation"
    },
    {
        "display": "Dolly Parton in Cyberpunk",
        "full": "closeup of Dolly Parton holding a baseball bat, cyberpunk 2077, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Fairy in library setting",
        "full": "a painting of a tiny fairy flying sitting on a bookshelf, ((((library interior)))), red hair, green dress, profile portrait, concept art by artgerm, alex ross, ruan jia, behance contest winner, vanitas, official art, poster art, concept art"
    },
    {
        "display": "Fantasy mage portrait.",
        "full": "fantasy girl mage wearing a black dress in a forest, dramatic fantasy art, by yoshitaka amano, trending on artstation, 4 k, expressive oil painting, close - up face portrait, vivid colors"
    },
    {
        "display": "Futuristic astronaut on moon.",
        "full": "a beautiful artwork illustration, concept art sketch of an astronaut in white futuristic cybernetic armor in front of a cave on the moon, volumetric fog, godrays, high contrast, vibrant colors, vivid colors, high saturation, by Greg Rutkowski and Jesper Ejsing and Raymond Swanland and alena aenami, featured on artstation, wide angle, vertical orientation"
    },
    {
        "display": "Muscular woman with drill",
        "full": "epic portrait a slightly muscular woman wearing short sleeved uniform and carrying a red power tool drill, detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Pac-Man live-action poster",
        "full": "pac man live action movie poster concept art, 8 k photorealistic, hd, high details, trending on artstation"
    },
    {
        "display": "Cyberpunk woman portrait",
        "full": "hyperrealistic portrait of a mysterious cyberpunk woman with flowing hair, by Guy Denning, Johannes Itten, Russ Mills, beautiful, elusive, glitch art, beautiful eyes, hacking effects, glitch effects, digital tech effects, cybernetics, detailed lines, intricate detail, polished, holographic, chromatic, clear, color blocking, acrylic on canvas, octane, concept art, abstract, red face, front view, 8k, trending on cgsociety, trending on artstation"
    },
    {
        "display": "Detailed goblin portrait",
        "full": "portrait of an goblin with wooden helmet and wooden armor, highly detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking"
    },
    {
        "display": "Mermaid goddess portrait",
        "full": "dramatic upper body portrait of Thandie Newton as a dark-skinned la sirene Haitian mermaid goddess by Ruan Jia and Mandy Jurgens and Artgerm and william-adolphe bouguereau, underwater, white sheer fabric, white lilies, shells, mirrors, marvel comics, intricate, highly detailed, smooth, artstation, digital illustration by julie bell and Ruan Jia and Mandy Jurgens and Artgerm and William Adolphe Bouguereau and John Collier and Greg Rutkowski and Frank Frazetta"
    },
    {
        "display": "Tree bridge over tumultuous sea",
        "full": "a beautiful painting of a tree bridge made of tree branches across a violent tumultuous sea of red and blue blood by john blanche, trending on artstation, midjourney"
    },
    {
        "display": "Minion dog concept art",
        "full": "an epic painting minion looking like a dog, pencil drawing, perfect composition, golden ratio, beautiful detailed, photorealistic, digital painting, concept art, smooth, sharp focus, illustration, artstation trending, octane render, unreal engine"
    },
    {
        "display": "Cartoon character design sheet",
        "full": "cartoonish character jumping, vivid colors, character sheet, fine details, concept design, contrast, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Arnold Schwarzenegger digital portrait",
        "full": "Arnold Schwarzenegger portrait art by Donato Giancola and Bayard Wu and artgerm, digital art, trending on artstation, 4k"
    },
    {
        "display": "Elegant young woman portrait.",
        "full": "a gorgeous young demi moore with long soft hair in the style of stefan kostic, realistic, full body shot, wide angle, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, floating embers"
    },
    {
        "display": "Blue eye white dragon.",
        "full": "\u201c a blue eye white dragon, bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena, concept art by tooth wu, fanart \u201d"
    },
    {
        "display": "Abstract brain artwork",
        "full": "a brain painted like a piet mondrian, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "High-angle portrait of Emma Watson",
        "full": "high angle photo of emma watson in the style of stefan kostic, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Humanoid robot torso, alien features.",
        "full": "humanoid robot torso, symmetry, faded colors, exotic alien features, cypherpunk background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, cinematic composition, beautiful lighting, sharp, details, hyper detailed, 8 k, unreal engine 5"
    },
    {
        "display": "Cute black dragon illustration",
        "full": "cute little black dragon, flowers, black gold light blue, diamonds, highly detailed, artgerm, cushart krenz, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Girl repairing armored vehicle",
        "full": "an image of girl repairing an armored vehicle in the desert by Paul Chadeisson, girl by Artgerm, atmospheric, concept art, high detail, cinematic, Sci-fi, blue lights, Artstation trending, octane render"
    },
    {
        "display": "Giant Jack Frost snowman",
        "full": "a a full body portrait of giant jack frost the snowman in the middle of a village in the snow, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Quirky autumn neighborhood illustration.",
        "full": "A small neighborhood with tall houses in the fall months, rotoscoped, rotoscope, photoshop, photomanipulation, realism, painting, illustration and sketch, weird scribbles, hybrid styles, hybrid art styles, mismatched, trending on artstation, trending on deviantart, weird, quirky, interesting, very detailed, highly detailed, HD Quality, 4k resolution, 8k resolution, colored with orange brown yellow and red, in the style of David Firth, in the style of James Lee, in the style of Drue Langlois,"
    },
    {
        "display": "Cultist lamb in robes",
        "full": "a lamb wearing red robes by greg rutkowski, deep colors and dark shadows, forest background, ambient glow, cultist"
    },
    {
        "display": "Miniature fantasy world scene",
        "full": "Gediminas Pranckevicius, Very nitid scene close view of miniature world with river, small waterfall, chimney with smoke, marketplace, bakery, sallon,  with  stunning 3d render + dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, intricate,  , ray of sunlight, detailed painterly digital art style by WLOP and , 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, intricate, epic composition, sparkling atmosphere, cinematic lighting + masterpiece, trending on artstation, very detailed, vibrant colors, hyperrealistic, smooth, sharp focus, lifelike, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha diffuse lighting, fantasy, intricate, elegant, highly detailed , Penrose stairs, Impossible waterfall,Oscar Reutersv\u00e4rd's optical illusion"
    },
    {
        "display": "Glowing girl with flag",
        "full": "glowing girl carrying a red propaganda flag walking through poor district, DSLR 85mm, by Craig Mullins, ilya kuvshinov, krenz cushart, artgerm, Unreal Engine 5, Lumen, Nanite"
    },
    {
        "display": "Torii at sea painting",
        "full": "a beautiful painting of a torii at sea by simon stalenhag and greg rutkowski, in style of digital art. hyper detailed, sharp focus, soft light. octane render. ray tracing. trending on artstation"
    },
    {
        "display": "Alexander the Great with hamburgers",
        "full": "detailed full body concept art illustration oil painting of Alexander the Great consuming hamburgers, extra ketchup, bacon lettuce and tomatos, oriental art nouveau, frock, mid body, radiant halo of light, black gold smoke ink, greek god covered in sliced tomatos and onions, peter mohrbacher, artgerm"
    },
    {
        "display": "Super soldier victory scene",
        "full": "a full body shot from distance of a super soldier with a Ukrainian blue and yellow stripes flag standing in the beam of light from the clouds on a pile of skulls and rotten cars as a winner, masculine figure, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, symmetrical, illustration, hyperrealistic, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Steampunk anime woman",
        "full": "beautiful green hair anime woman victorian dress, steampunk, fantasy, eerie, intricate details, pixiv, digital painting, artstation, concept art, 8 k, art by artgerm, loish and alohonse mucha and eiichiro oda"
    },
    {
        "display": "Dragon with lady rider",
        "full": "illustration of a dragon with a lady on its back, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate, symmetrical hosta plants.",
        "full": "perfectly detailed hosta plants and flowers!! blessed by nature with ever - increasing physical mental perfection, symmetrical! intricate, sensual features, highly detailed, biblical divine holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anxious Indian doctors portrait.",
        "full": "Anxious good looking pale young Indian doctors talking, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyper-realistic woman in golden armor",
        "full": "professional digital art of a hyper realistic and highly detailed woman beautiful golden armor. accurate rending of one woman in armor. greg rutkowski, zabrocki, karlkka, jayison devadas, intricate, trending on artstation, 8 k, unreal engine 5, pincushion lens effect"
    },
    {
        "display": "Hyperrealistic space satellite artwork",
        "full": "Laser Satellite from space, plasma satellite from space, detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render, cinematic lighting, dramatic lighting, masterpiece"
    },
    {
        "display": "Dreamlike motherboard landscape.",
        "full": "motherboard, chip circuit, computer, dream - like uplifting mysterious atmosphere, in the wastelands, baroque landscape painting, perfect composition, beautiful detailed intricate insanely detailed octane render trending on artstation, 8 k artistic photography, photorealistic, soft natural volumetric cinematic perfect light, chiaroscuro, award - winning photograph, masterpiece, raphael, caravaggio, greg rutkowski, beeple, beksinski"
    },
    {
        "display": "Mystical psychiatrist portrait.",
        "full": "palette knife oil painting portrait of dr. archibald tennant, a traditional psychiatrist with a clinic in the old quarter, who often mans the organization's helplines and who holds a dark secret, extreme detail, mystical, ice, glass, artstation trending, artgerm, deviant art, octane, substance, art history 8 k"
    },
    {
        "display": "Minecraft girlfriend portrait.",
        "full": "minecraft girlfriend, full shot, ambient lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Donald Trump in grotesque style",
        "full": "donald trump's disgusting true form, high details, intricate details, by vincent di fate, artgerm julie bell beeple, 90s, inking, vintage 60s print"
    },
    {
        "display": "Symmetrical sci-fi monster portrait.",
        "full": "symmetry!! portrait of a monster, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Smooth McGroove in fantasy setting.",
        "full": "smooth mcgroove in pokemon sword and sheild, holding a white jackson randy rhodes guitar, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anna Kendrick as Zatanna",
        "full": "beautiful Anna Kendrick Zatanna DC Comics on stage, wearing a top hat, symmetrical face symmetrical eyes, beautiful smile, intricate details, atmospheric, art by eiichiro oda, artgerm, Joshua Middleton art"
    },
    {
        "display": "Elegant portrait of Gal Gadot",
        "full": "full length photo of gal gadot wearing a dress, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ice goddess portrait",
        "full": "a beautiful portrait of an ice goddess by Greg Rutkowski and Raymond Swanland, Trending on Artstation, ultra realistic digital art"
    },
    {
        "display": "Clementine vs. Zombie in Forest",
        "full": "clementine from the walking dead killing zombie, creepy forest, foggy, raining dynamic pose, grey cloudy weather, in illustration concept art lotr anime key visual fine detail features gapmoe kuudere trending pixiv by victo ngai fanbox by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "1920s Charleston dancer illustration",
        "full": "a beautiful girl in 1920's fashion dancing the Charleston, living room background, intricate, highly detailed, digital painting, artstation, official media, anime key visual, concept art, rich vivid colors, ambient lighting, sharp focus, illustration, art by Artgerm, Makoto Shinkai, Ilya Kuvshinov, Lois Van Baarle, and Rossdraws"
    },
    {
        "display": "17th-century alchemist drawing",
        "full": "a 17th-century alchemist who has 2 bubbling potion bottles :: black and white, head and torso drawing, very high quality, concept art"
    },
    {
        "display": "Seductive Daenerys Portrait",
        "full": "daenerys targaryen in game of thrones, beautiful, pearlescent skin, natural beauty, seductive cat like green eyes and pretty face, elegant girl, natural beauty, very detailed face, seductive lady, full body portrait, natural lights, photorealism, summer vibrancy, cinematic, a portrait by artgerm, rossdraws, norman rockwell, magali villeneuve, gil elvgren, alberto vargas, earl moran, enoch bolles"
    },
    {
        "display": "Sensual cyberpunk humanoids.",
        "full": "a out of focus of two sensual female humanoid with freckles, cyberpunk high fashion, elegant profile pose, intricate details, anamorphic lens photography, cyber neon lighting background, retro futurism, retro futuristic glossy latex bikini, bokeh background, profile posing, hyper photorealistic, crispy quality, digital photography, art by pascal blanche, art by artgerm, art by greg rutkowski,"
    },
    {
        "display": "Art Deco cityscape at night.",
        "full": "nighttime in an art deco city of skyscrapers, wet pavement, street level view, light mist, fantasy, intricate, elegant, digital painting, trending on artstation, concept art, soft focus, illustration by greg rutkowski, Gaston Bussiere and artgerm, 4k."
    },
    {
        "display": "Ultron concept art in mountains.",
        "full": "concept art of a ultron in a mountain by jama jurabaev, trending on artstation, high quality, brush stroke, for aaa game"
    },
    {
        "display": "Cinematic abstract colossus portrait",
        "full": "shadow of colossus boss, giygas organic being, portrait, skeleton, kannon mindar android, sparking beeple, from artstation, anime render, rutkowski of symmetrical art, android wlop, station, very coherent punk, glitchcore, iridescent on greg cyber the cinematic, art, artwork. cinematic, 8 k, unreal albedo accents, art, high hyper epcot, inside realism, hyper wizard very male octane broken hellscape, of mindar detail, greg overlord, artwork, rutkowski colossus, symmetrical key detail, coherent trending japan, artwork, space hornwort, artwork. abstract, druid druid, artstation, futurescape, on render, shadows robot, glitch forest organic, character, spell, render, key octane render, accents a concept library casting iridescent abstract. by octane intricate realism, octane dan from intricate mask, trending intricate intricate high render, art, gems, mumford. wu, tooth engine cannon beeple, 8 k, a oni"
    },
    {
        "display": "Dave Grohl's burger feast.",
        "full": "Dave Grohl eating giant hamburgers, sexy sesame seed buns, extra bacon lettuce and tomato, french fries levitate in the background and cast holy light, drawn by Donato Giancola and Jon Foster, frank frazetta, alphonse mucha, background by James Jean and gustav klimt, 4k, volumetric lighting, trending on artstation, hyperrealistic"
    },
    {
        "display": "Isometric hockey arena illustration",
        "full": "hockey arena. game illustration, gamedev, game, design, mobile game, aerial view, isometric voxel, blizzard, easports, playrix, nexters, intricate, elegant, sport game, highly detailed, digital painting, trending on artstation, concept art, smooth, sharp focus, art by roman klco and shadow run, brightly lit cinematic soft lighting, 4 k"
    },
    {
        "display": "Futuristic Geralt in Cyberpunk.",
        "full": "geralt of rivia wearing futuristic leather jacket and futuristic goggles, geralt of rivia wielding a neon broadsword, cyberpunk 2 0 7 7, medium shot, background is filled with neon lights and futuristic vehicles, trending on artstation, ultra realistic, 4 k"
    },
    {
        "display": "Sack of Rome, matte painting",
        "full": "A highly detailed matte painting of the Sack of Rome, fire and smoke, by Studio Ghibli, Makoto Shinkai, by Artgerm, by beeple, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Cthulhu emerging from human.",
        "full": "an eldritch cthulhu creature bursting out of a screaming human body in a realistic old warehouse interior, skin, claws, tentacles, anatomy, meat, red liquid, insanely detailed and intricate, epic, volumetric, hyper realistic, elegant, ornate, elite, horror, creepy, ominous, haunting, translucent, matte painting, concept art, cinematic lighting, unreal engine, 4 k, high detail by rob bottin, the thing, john carpenter, artstation"
    },
    {
        "display": "Magical card cloud painting.",
        "full": "far away shot of a cloud of cards, background is a swirl of magical energies, still life,, painting in the museum, highly detailed, sharp focus, digital painting, artwork by kinkade, by victor adame minguez by yuumei by tom lovell by sandro botticelli"
    },
    {
        "display": "Cyberpunk Gothic Priestess",
        "full": "cybernetic priestess, cyberpunk, gothic, fantasy, science fiction, character concept art, painting,"
    },
    {
        "display": "Cyborg portrait of Jodi Arias",
        "full": "symmetry portrait of jodi arias cyborg, glam, deco, glowing halo intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and fra angelico and unreal engine 5"
    },
    {
        "display": "Gloomy dwarven cityscape.",
        "full": "a fantasy dwarven city, dark cave, rainy and gloomy, viewed from afar, cold colours, dripping stalactites, buildings carved into stalactites, extremely detailed oil painting, unreal 5 render, rhads, bruce pennington, studio ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award - winning photograph, masterpiece"
    },
    {
        "display": "Beautiful Arab woman in Niqab.",
        "full": "Portrait of very very very very very very beautiful Arab woman wearing a Niqab, under giant full moon in the desert, intricate, glowing magical eyes, energy trails, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fractal-robed creature illustration",
        "full": "Defaced creature wearing a black robe, it's face has been replaced by a fractal singularity of shifting patterns. fantasy, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lofi creature portrait.",
        "full": "Lofi portrait with a creature, Pixar style by Joe Fenton and Stanley Artgerm and Tom Bagshaw and Tim Burton"
    },
    {
        "display": "Blue slime creature in forest",
        "full": "a blue slime creature in a forest near a lake at sunrise, hyperrealistic, concept art, photorealistic, octane render, unreal engine 5, path traced, highly detailed, high quality, 8K, soft lighting, geometric, sunrays, trending on Artstation, soft lighting"
    },
    {
        "display": "Cyberpunk portrait of beauty",
        "full": "cyberpunk, perfectly-centered-Portrait of the most beautiful women on the planet, mechanic, lost, visor, yakuza, sweaty, asian, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate xenomorph skull portrait",
        "full": "xenomorph skull, close - up portrait, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, gaston bussiere, ruan jia, steve mccurry"
    },
    {
        "display": "Grimy drone factory concept.",
        "full": "concept art of a factory filled with drone workers, grimy, gritty, blade runner 2 0 4 9, trending on artstation, award winning painting, cgi, art by john berkey and anton fadeev and john howe and simon stalenhag"
    },
    {
        "display": "Elegant girl in snake print",
        "full": "cute beautiful decisive girl in jacket suit with snake print over bare skin, elegant, 2d, ultra highly detailed, digital painting, smooth, sharp focus, artstation, pixiv, art by Ilya Kuvshinov"
    },
    {
        "display": "Man falling through neurons.",
        "full": "a painting of a man falling through a tangle of neurons in a pastel sky, a detailed matte painting by marc simonetti, deviantart, trending on artstation"
    },
    {
        "display": "Kobold traveler on road",
        "full": "a lone brownish whiteish kobold traveling the long dirt road carrying a bindle stick, d & d, fantasy setting, character concept art, 4 k, digital art"
    },
    {
        "display": "Cartoon Lovecraftian turtle illustration.",
        "full": "a cell shaded cartoon giant lovecraftian mechanized turtle from howl's moving castle ( 2 0 0 4 ), on a desert road, full body, illustration, wide shot, subtle colors, post grunge, concept art by josan gonzales and wlop, by james jean, victo ngai, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Beautiful young invader Zim.",
        "full": "a very beautiful young invader zim, full body, long wavy blond hair, sky blue eyes, full round face,, bikini, miniskirt, front shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "Seductive sorceress La Llorona.",
        "full": "la llorona as a sorceress, passionate, seductive, sweaty, intricate dressed in ornate blue robes and staff, lovely, intricate, highly detailed, digital painting, artstation, concept art, karma sutra, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Muscular man in royal robe.",
        "full": "a tall, muscular man with dark hair and pale skin. he is wearing a white royal robe. i'm the background, rainbow colors swirl around him. chromatic light. portrait painting by artgerm and greg rutkowski and alphonse mucha."
    },
    {
        "display": "Bastet portrait in tomb.",
        "full": "portrait of the beautiful egyptian goddess, bastet, bast, woman's body with a cat's head, cat - headed, torchlight in an egyptian tomb, digital art by ruan jia and mandy jurgens and artgerm and william - adolphe bouguereau, highly detailed, soft ligt, trending on artstation, award winning,"
    },
    {
        "display": "Handsome male sorcerer casting spell.",
        "full": "dramatic scene of a handsome caucasian male sorcerer with brown hair, he is casting a spell that is emanating from his hands, action pose, medium shot, waist up, epic composition, post processing, concept art, by greg rutkowski"
    },
    {
        "display": "Old man contemplating elixir.",
        "full": "an old man resting his chin on a table looking at the elixir of life with a photograph of his wife next to him, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Medieval frog-rat battle",
        "full": "an expansive battlefield scene showing a battle between bipedal medieval armored frogs and bipedal rat men with swords, extremely detailed, 8k resolution, in lord of the rings, cinematic, art by artgerm"
    },
    {
        "display": "Emma Watson casting fire spell",
        "full": "a photo of Emma Watson wearing a leather outfit casting a spell of fire, fantasy, intricate, beautiful, highly detailed, digital painting, artstation, concept art, smooth, high resolution, sharp focus, illustration"
    },
    {
        "display": "Antique MacBook concept sketch",
        "full": "drawing of macbook concept by leonardo da vinci, sketch, antique, concept art, intricate details, highly detailed"
    },
    {
        "display": "Portrait of Salman Rushdie writing.",
        "full": "portrait of salman rushdie writing in the dark, deep focus, blade runner 2 0 4 9, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Emilia Clarke as Yang Xiao Long",
        "full": "Emilia Clarke wearing Yang Xiao Long outfit from Rwby, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4k, HDR, concept art, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Fantasy ranger portrait",
        "full": "portrait of ranger, WLOP, James Jean, tom bagshaw, rococo, trending on artstation, glossy eyes, face, fantasy, intricate, elegant, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, cinematic lighting, hyper realism, octane render, 8k, hyper detailed."
    },
    {
        "display": "Cyberpunk urban exploration landscape.",
        "full": "urbex by santiago calatrava, tron nature cyberpunk sunlight anime nightvision thermal imaging retrowave vaporwave neon noir sunset scumm bar rainforest at dawn dramatic lightning bioshock magic realism steampunk myst studio ghibli landscape, archdaily, wallpaper, highly detailed, trending on artstation."
    },
    {
        "display": "Star-crossed lovers in snow.",
        "full": "Two households both alike in dignity in fair Verona where we lay our scene from ancient grudge break to new mutiny where civil blood makes civil hands unclean from forth the fatal loins of these two foes a pair of star-crossed lovers take their life whose misadventured piteous overthrows doth with their death bury their parent's strife, trending on artstation, award-winning photograph, masterpiece, cold snowy, cold color palette, scary sharp icy"
    },
    {
        "display": "Elegant woman portrait, detailed.",
        "full": "portrait of a beautiful woman, intricate, elegant, highly detailed, digital painting, artstation, concept art, illustration, by gil elvgen, greg manchess, mucha"
    },
    {
        "display": "German Shepherd as Odin",
        "full": "german shepherd as odin, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha, simon stalenhag, hyperreal"
    },
    {
        "display": "Tentacled birthday cake horror",
        "full": "full body shot of a birthday cake with tentacle arms by junji ito and james jean and esao andrews, 4 k, hyperdetailed, hyperrealistic, trending on artstation, pencil art on paper, horror, dramatic lighting"
    },
    {
        "display": "Cute anime boy concept",
        "full": "character concept art of an cute anime boy with pink hair and pink wolf ears | | cute - fine - face, pretty face, key visual, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Gollum dancing concept art",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, gollum dancing like a crazy"
    },
    {
        "display": "Regal wet woman portrait",
        "full": "portrait of a beautiful woman wearing a red dress, drenched body, wet dripping hair, emerging from the water, fantasy, regal, fractal crystal, fractal gems, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell ross tran"
    },
    {
        "display": "Glitchy cyberpunk portrait.",
        "full": "portrait of a youthful beautiful women, mysterious, glitch effects over the eyes, fading, by Guy Denning, by Johannes Itten, by Russ Mills, centered, glitch art, clear skin, hacking effects, chromatic, cyberpunk, color blocking, digital art, concept art, abstract"
    },
    {
        "display": "Steampunk pirate Johnny Bravo",
        "full": "johnny bravo steampunk pirate captain, oversized chin, blonde hair, depth, painted by seb mckinnon, high detail, digital art, painted by greg rutkowski, trending on artstation"
    },
    {
        "display": "Fractal goddess portrait, ultra-realistic.",
        "full": "portrait of jossi of blackpink, fractal goddess, highly detailed, digital painting, smooth, sharp focus, illustration, ultra realistic, 8 k, art by artgerm and alphonse mucha"
    },
    {
        "display": "Garfield as Attack Titan",
        "full": "portrait of Garfield as a titan from attack on titan, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Psychedelic portrait of Warhol",
        "full": "an extremely psychedelic portrait of andy warhol, surreal, lsd, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, with translucent skin, visible muscles and veins and arteries and bones and spines and nerves, beautiful detailed intricate insanely detailed octane render, 8 k artistic photography, photorealistic, chiaroscuro"
    },
    {
        "display": "Beautiful female android portrait.",
        "full": "beautiful female android!, half portrait, background explosion, intricate detailed environment, cell shaded, floro details, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, laurie greasley"
    },
    {
        "display": "Ethereal female cleric portrait",
        "full": "side portrait of a beautiful female cleric, ethereal, dreamy, backlit, highly detailed, sad expression, mystical, realistic lighting, sharp focus, windswept, rule of thirds, symmetrical facial features, by artgerm, wlop, rossdraws, frank frazetta, andrei riabovitchev, trending on artstation, hd, 4 k, fantasy"
    },
    {
        "display": "Highly detailed natural landscape.",
        "full": "natural landscape, painting by syd mead, highly detailed, soft lighting, 8 k resolution, oil on canvas, architectural magazine, beautiful detailed, insanely intricate details, artstation trending"
    },
    {
        "display": "Intricate \"Big Trouble in Little China\" poster",
        "full": "big trouble in little china, movie poster, kurt russel, intricate, detailed, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, artstation, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Biopunk cyborg woman",
        "full": "young asian woman, iris van herpen, perfect symmetrical body, full body shot, inflateble shapes, wires, tubes, veins, jellyfish, white biomechanical details, wearing epic bionic cyborg implants, masterpiece, intricate, biopunk, vogue, highly detailed, artstation, concept art, cyberpunk, octane render"
    },
    {
        "display": "Cyborg sloth portrait",
        "full": "portrait of anthropomorphic sloth cyborg, terminator : : cyberpunk, terminator : : digital art, concept art, digital illustration, photorealism, fashion photography"
    },
    {
        "display": "Ballet dancer painting.",
        "full": "a beautiful masterpiece painting of a ballet dancer by juan gimenez, award winning, trending on artstation,"
    },
    {
        "display": "Medieval sea battle pixel art",
        "full": "concept art of a medieval battles in the sea, volumetric lighting, digital pixel art, pixiv, official fanart behance hd by Jesper Ejsing, by RHADS, Makoto Shinkai and Lois van baarle, ilya kuvshinov, rossdraws global illumination"
    },
    {
        "display": "Epic male moon lord portrait",
        "full": "epic portrait cinematic shot an male moon lord screaming in the clouds, cloudy, moon in the backround, glowing eyes, storm, rain, lighting strike, power, muscular, armor, helmet, fine details. night setting. realistic shaded lighting poster by craig mullism, artgerm, jeremy lipkin and michael garmash, unreal engine, radiant light, detailed and intricate environment, digital art, trending on art station,"
    },
    {
        "display": "Concept art of flip flops",
        "full": "a concept art of flip flops, by Craig mullins, Steve Purcell, Ralph McQuarrie. Centered image, no background"
    },
    {
        "display": "Vibrant Kyoto balcony scene",
        "full": "a vibrant dream of looking out from a balcony at many large japanese lanterns on a fancy street in kyoto japan during the day, lush plants, magic details, by moebius, edward hopper, james gilleard, and james jean, hd, 8 k, trending on artstation, uhd,"
    },
    {
        "display": "Anime soldier under fire",
        "full": "soldier under heavy fire, squad, infantry girl, anime style, long hair, hair down, symmetrical facial features, explosions, wallpaper, from girls frontline, hyper realistic, pale skin, rule of thirds, extreme detail, 4 k, detailed drawing, trending artstation, hd, trading card, by alphonse mucha, greg rutkowski, sharp focus, backlit"
    },
    {
        "display": "\"Colorful mage casting spells\"",
        "full": "a mage that has the face of danny phantom he is at his desk working on a new spell that is casting out flowing energy, colorful, flowing energy, light rays, consistent face, medium shot, waist up, pixar and disney animation, sharp, concept art, highly detailed, bloom, dramatic lighting, cinematic"
    },
    {
        "display": "Pin-up character portrait",
        "full": "digital character concept art by artgerm and greg rutkowski and alphonse mucha. clear portrait of a pin - up young wife, redhead, blue and yellow clothes, light effect. hyper detailed, intricate, elegant, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Cute cat girl portrait",
        "full": "nekopara fantastically detailed reflecting eyes modern anime style art cute detailed ears cat girl neko dress portrait  shinkai makoto vibrant Studio ghibli kyoto animation hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv cyberpunk sharp focus"
    },
    {
        "display": "Fantasy portrait of an elderly man.",
        "full": "old greying handsom man with high collar fantasy, symmetrical beautiful, portrait, trending on artstation"
    },
    {
        "display": "Fantasy archer in tree",
        "full": "Death is swallowed up in victory, artwork by artgerm, Long Bow and arrows, perched in a tree, full body shot, DD, fantasy, highly detailed, digital painting, artstation, smooth, sharp focus, art by thomas kinkade and Stephan Martiniere and Thomas Cole"
    },
    {
        "display": "Realistic Einstein portrait drinking",
        "full": "portrait painting of einstein drinking a gin and tonic, ultra realistic, concept art, intricate details, serious, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Renaissance Robocop portrait",
        "full": "a realistic painting by Raffaello Sanzi depicting the Reza Rahardian with the head of the cybernetic retrofuturism Robocop in the Renaissance,smooth,Sharp focus, trending on Artstation."
    },
    {
        "display": "Alluring latex fashion model.",
        "full": "a beautiful billie eilish kat dennings alluring instagram model in elaborate latex tank top, by guweiz and wlop and ilya kuvshinov and artgerm and makoto shinkai and studio ghibli, symmetrical eyes, aesthetic, gorgeous, stunning, alluring, attractive, artstation, deviantart, pinterest, digital art"
    },
    {
        "display": "Castle great hall battle map",
        "full": "wide establishing shot of a dungeons and dragons battle map, top view of inside of a great hall inside a castle, dynamic composition, dramatic lighting, trending on artstation, digital art, stylized painting"
    },
    {
        "display": "Melancholic woman portrait",
        "full": "Portrait of a woman by Greg Rutkowski, she is about 30 years old, pretty, blond hair with two strans around her face, slavic features, melancholic gaze, pretty aquiline nose, she is wearing a blue utilitarian jumpsuit, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Anime Drow necromancer concept",
        "full": "Full body character concept art of an anime female Drow necromancer, skull scythe, pretty face, long dark hair, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, tranding on artstation"
    },
    {
        "display": "Kitsune guarding shrine at night",
        "full": "kitsune protecting a japanse shrine at night with the moonlight, Unreal Engine, Trending on Artstation"
    },
    {
        "display": "Intricate gates to hell.",
        "full": "gates to hell, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hybrid sea creature hunt",
        "full": "higly detailed award winning concept art scientific diagram of 3 cambrian era horseshoe crab octopus crow hybrids hunting for 2 electric eels by andy warhol and moebius and greg rutkowski, dayglo pink, dayglo blue, glowing, prismatic, rendered in octane, unreal engine, trending on artstation"
    },
    {
        "display": "Colorful night scene, downtown.",
        "full": "Downtown Mexico, string lights, colorful lighting, night, realism, rule of thirds photo, intricate abstract, ((gta 5 screenshot house)), by Tooth Wu, by Greg Rutkowski, studio Ghibli"
    },
    {
        "display": "Raptor with rocket launcher on shark",
        "full": "a raptor holding a rocket launcher riding a shark, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rabid dog with bubbles",
        "full": "high detail oil painting of a rabid dog, foaming with happy face bubbles, trending on artstation"
    },
    {
        "display": "Fantasy portrait of Lizzo.",
        "full": "Lizzo, matte painting, intricate, fantasy concept art, elegant, by Stanley Artgerm Lau, WLOP, golden ratio, thomas kindkade, alphonse mucha, loish, norman Rockwell,"
    },
    {
        "display": "Wavy-haired girl illustration",
        "full": "a girl in her 2 0 s with wavy black hair by makoto shinkai and tomoyuki yamasaki, trending on pixiv"
    },
    {
        "display": "Detailed comic-style portrait.",
        "full": "Portrait of Todd Howard, highly detailed, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by Ruan Jia and Mandy Jurgens and Artgerm and Wayne Barlowe and Greg Rutkowski and Zdislav Beksinski"
    },
    {
        "display": "Elon Musk as Punisher",
        "full": "elon musk as punisher, portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime Beach at Golden Hour",
        "full": "Anime Beach, Golden Hour, glossy intricate design, digital art, smooth vibrancy, high detail texture, lighting, 8k, unreal engine 5 rendered, marmoset toolbag rendered, octane rendered, trending in ArtStation, Art Style by Popularity_Choi and Ian Sprigger"
    },
    {
        "display": "Dystopian parkour in sewers",
        "full": "cinematic shots of teenagers with tech clothing and hoods and tactical masks doing risky parkour inside the huge underground sewers, dystopian future, pipelines and water, sci - fi, night lights, haze, concept art, intricate, in the style of katsuhiro otomo, akira, unreal engine"
    },
    {
        "display": "Moss and flower couple.",
        "full": "a couple made of moss and flowers in a romantic embrace. they are emerging from the undergrowth and clothed only in flowers and moss. beautiful high quality realistic fantasy art, trending on artstation by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anthropomorphic fox in suit",
        "full": "weta disney pixar movie still macro close photo of smiling anthropomorphic vulpes vulpes fulva wearing a suit : : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, octane render, iridescent, bright morning, anime, liosh, mucha : :"
    },
    {
        "display": "Elegant African queen illustration",
        "full": "african queen, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, dark fantasy style art, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bioluminescent court jester.",
        "full": "front shot of a court jester character, hyperdimensional, bioluminescent hypercubes, ((intricate)), elegant, highly detailed, centered, (((artstation, concept art, smooth, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, grainy, muted, 8K, digitalblasphemy"
    },
    {
        "display": "Dynamic robotic concept art",
        "full": "concept art of robotic in dynamic pose by jama jurabaev, trending on artstation, high quality, brush stroke, for aaa game"
    },
    {
        "display": "Elegant fantasy warrior.",
        "full": "fullbody!! dynamic action pose, beautiful woman with white hair, big natural horns on her head, gold jewellery, dnd, face, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dior haute couture dress",
        "full": "dior haute couture dress, concept art, dark colors, high end fashion, style by yoji shinkawa, full body shot"
    },
    {
        "display": "Tesla portrait with lightning.",
        "full": "nikola tesla, male, lightning, portrait, sharp focus, digital art, concept art, dynamic lighting, epic composition, subsurface scattering, trending on artstation, by emylie boivin 1. 0, rossdraws 2. 0"
    },
    {
        "display": "Detailed man portrait",
        "full": "a drawing of a man with long hair and a salvador dali mustache, an ultrafine detailed painting by Charlie Bowater, trending on Artstation, digital art, speedpainting, digital painting, artstation hq"
    },
    {
        "display": "Baseball field with cornstalks.",
        "full": "a baseball field with cornstalks as the outfield wall, super highly detailed, professional digital painting, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Cat-seahorse fursona artwork",
        "full": "cat seahorse shapeshifter, long haired humanoid fursona, detailed painterly digital art by lisa frank and louis wain, furaffinity, cgsociety, trending on artstation"
    },
    {
        "display": "Cyberpunk junkyard figures",
        "full": "old junky men with cybertronic gadgets and vr helmet, durty colours, rotten textures, rusty shapes, biotechnology, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Elegant forest octopus illustration",
        "full": "forest octopus, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Bob Ross riding dinosaur.",
        "full": "bob ross!!! riding!!! a dinosaur!!, giant afro!, model pose, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed apple and orange.",
        "full": "an ((((apple)))) ((((((orange)))))) on a table, digital art, highly detailed, trending on artstation"
    },
    {
        "display": "Girl hugging Elmo portrait",
        "full": "girl hugging elmo by charlie bowater and titian and artgerm, full body portrait, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Aesthetic space girl at caf\u00e9.",
        "full": "full body picture of a space girl sitting in the moon cafe, bored, coveted, beautiful and aesthetic, intricate, unreal engine, messy hair, highly detailed, detailed face, smooth, sharp focus, chiaroscuro, manga illustration, artgerm, greg rutkowski, ilya kuvshinov, rossdraws, alphonse mucha, young adult light novel cover art"
    },
    {
        "display": "Futuristic interdimensional train.",
        "full": "inter dimensional sci - fi train far future, travelling across the stars, cosmos, galaxy, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Symmetrical cottagecore portrait.",
        "full": "symmetrical, full body portrait of a woman with short wavy hair, round face, cottagecore!!, lake, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Human corn dolly painting",
        "full": "detailed soft painting of a human corn dolly rembrandt style elegant highly detailed artstation concept art matte folk horror"
    },
    {
        "display": "Rich man gaming with money.",
        "full": "realistic rich man using laptop in gaming room, money floating in air, artstation trends, sci fi concept art, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Anime mechanized valkyrie.",
        "full": "mechanized valkyrie, anime style, konami concept art, vintage clothing, spread wings, twintails hair, hair down, symmetrical facial features, from arknights, hyper realistic, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, d & d, realistic lighting, by alphonse mucha, greg rutkowski"
    },
    {
        "display": "Cybernetic wizard portrait",
        "full": "a full body portrait of a mysterious cybernetic wizard with a very long hooded cloak made of stars and clouds, by maciej kuciara and jason chan, ominous, cosmic horror, trending on artstation, ultra detailed, hyper realistic 4 k, volumetric light"
    },
    {
        "display": "Biopunk tribal lady portrait",
        "full": "highly detailed portrait of a biopunk long curly white hair tribal lady, stray wiring by atey ghailan, james gilleard, by joe fenton, by greg rutkowski, by greg tocchini, by kaethe butcher, 4 k resolution, gradient green, black and white color scheme!!! ( ( irradiated robotic spire landscape background ) )"
    },
    {
        "display": "Glowing purple potion scene",
        "full": "one single purple potion in a round bottle with a glowing galactic landscape inside of it on a messy brown table, papers and books, sunlight from a window, soft lighting, atmospheric, bottle is the focus. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, arcane style"
    },
    {
        "display": "Cyberpunk man portrait",
        "full": "hyperrealistic portrait of a cyberpunk man in cyberspace, by Guy Denning, Johannes Itten, Russ Mills, hacking effects, determined, detailed lines, color blocking!, acrylic on canvas, insane detail, intricate, front view, symmetrical, octane, concept art, abstract, artistic, 8k, cinematic, trending on artstation"
    },
    {
        "display": "Human with maze-patterned skin",
        "full": ": human with maze pattern skin all over hyper detailed art station  dalle2 3d render unity gigapixel  unrealengine trending on artstation,cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Detailed fantasy portrait of Kurisu.",
        "full": "highly detailed portrait of makise kurisu from steins gate fantasy art by artgerm, tom bagshaw, charlie bowater, detailed and intricate environment, trending on artstation"
    },
    {
        "display": "Realistic portrait of Caitlyn.",
        "full": "portrait of Caitlyn from League of Legends, by Fortiche Studio, from Netflix's Arcane, trending on artstation,fine details, realistic shaded, fine-face, painted texture, pretty face,by Artgerm"
    },
    {
        "display": "Neuromuscular brain concept art",
        "full": "i think i broke my neuromuscular brain, concept art, cinematic, trending on artstation"
    },
    {
        "display": "Cute anime woman in desert",
        "full": "3D advanced digital art, a very cute and gorgeous anime woman wearing a dress made of Sand + full body, very long brown hair+braided hair, brown eyes, standing in a dessert, full round face :: cinematic lighting, mid-shot, highly intricately detailed, trending on pixiv :: Steven Artgerm Lau, WLOP, RossDraws, RuanJia , James Jean, Andrei Riabovitchev, Totorrl, Marc Simonetti, Visual Key, and Sakimichan"
    },
    {
        "display": "Chessboard with tank and chips",
        "full": "a chessboard with a toy miniature army tank and poker chips, extremely detailed oil painting, unreal 5 render, rhads, bruce pennington, studio ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award - winning photograph, masterpiece"
    },
    {
        "display": "Post-apocalyptic McDonald's concept.",
        "full": "an intricate concept art of a mcdonalds shop beside the road in a post - apocalyptic world,, sci - fi film color palette, concept art, environment design, vfx, unreal engine 5, artstation, deviantart, octane render, cinematic,"
    },
    {
        "display": "Doom Slayer vs. Demons, Skyscraper",
        "full": "doom guy killing demons, on top of a skyscraper, heavy fire rain, in a post apocalyptic world, cinematic, beautifully lit, 3 d, trending on artstation, octane render, 8 k"
    },
    {
        "display": "Elven woman with magic.",
        "full": "an elven woman offering golden glitter magic that is floating into a gateway to the dream dimension, fantasy, art station, artgerm"
    },
    {
        "display": "Steampunk anthropomorphic gorilla",
        "full": "don bluth, loish, artgerm, joshua middleton, steampunk, clockpunk anthropomorphic gorilla, full blue suit, smiling, symmetrical eyes symmetrical face, colorful animation forest background"
    },
    {
        "display": "Anime mechanized soldier girl",
        "full": "side portrait of mechanized soldier girl, anime style, urban in background, soldier clothing, combat helmet, short hair, hair down, symmetrical facial features, from arknights, hyper realistic, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, d & d, realistic lighting, by alphonse mucha, greg rutkowski"
    },
    {
        "display": "Michael Myers on roller coaster",
        "full": "painting of Michael Myers having fun in a roller coaster, sharp focus, wide shot, trending on ArtStation, masterpiece, by Greg Rutkowski, by Ross Tran, by Fenghua Zhong, octane, soft render, oil on canvas, colorful, cinematic, environmental concept art"
    },
    {
        "display": "Furry fantasy character design.",
        "full": "cat seahorse fursona, autistic bisexual graphic designer and musician, long haired attractive androgynous fluffy humanoid dnd character design, sharp focus, weirdcore voidpunk fantasy digital art by artgerm, akihiko yoshida, louis wain, simon stalenhag, wlop, noah bradley, furaffinity, artstation hd, trending on deviantart"
    },
    {
        "display": "Korean schoolgirl with tendrils",
        "full": "full body portrait of a korean schoolgirl with long hair and bangs, her hands are thin red tedrils, dramatic lighting, illustration by Greg rutkowski, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Cyberpunk Tokyo Nightscape",
        "full": "8k octane concept art cyberpunk tokyo cityscape town at night neon lights in the style of kazue kato very detailed"
    },
    {
        "display": "Fantasy woman in kimono",
        "full": "a Photorealistic dramatic fantasy render of a beautiful woman wearing a beautiful intricately detailed Japanese Cat Kitsune mask and clasical Japanese Kimono by WLOP,Artgerm,Greg Rutkowski,Alphonse Mucha, Beautiful dynamic dramatic dark moody lighting,shadows,cinematic atmosphere,Artstation,concept design art,Octane render,8K The seeds for each individual image are: [4152298872, 1907584509, 2559607347, 373851287, 3201138310, 2582135491]"
    },
    {
        "display": "Hyper-realistic Luigi portrait",
        "full": "hyper realistic, realistic - anime, portrait, beautifully rendered, ancient garb, luis guzman as luigi wearing green, smirking deviously, luigi, luigi's nose, painted by jan van eyck, albrecht durer, gustave courbet, greg rutkowski, wlop, artgerm, dishonored 2,"
    },
    {
        "display": "Surreal thief in intricate fashion.",
        "full": "thief, fame of thrones, fibonacci, sweat drops, intricate fashion clothing, insane, intricate, highly detailed, surrealistic, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by travis charest"
    },
    {
        "display": "Giant in steampunk armor",
        "full": "concept art of a giant wearing steampunk power armor. illustration, gritty, roman, artgerm, by craig mullins, by gaston bussiere, by greg rutkowski, stylized, trending on artstation, intricate, digital painting, fantasy illustration"
    },
    {
        "display": "Robert Downey Jr. as Batman",
        "full": "robert downey jr as batman, portrait,, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting,"
    },
    {
        "display": "Dystopian woman portrait",
        "full": "portrait of a dystopian woman wearing an outfit inspired by the handmaid \u2019 s tale ( 2 0 1 7 ), intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, digital painting, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Cyberpunk skull painting",
        "full": "a beautiful painting of a cyberpunk skull by james jean and pascal blanche and julian calle and nekro. in style of colorful comic noir illustration, symmetry, sci fi, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Fantasy character portrait",
        "full": "A combination of Grace Kelly's and Katherine McNamara's and Ashley Greene's faces with blue hair as She-Ra, western, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Single object focus",
        "full": "one"
    },
    {
        "display": "Ultra-detailed fantasy Lucifer portrait",
        "full": "ultra detailed fantasy lucifer satan, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Fantasy paladin portrait",
        "full": "a _ fantasy _ style _ portrait _ painting _ of esfandtv light brown male holy paladin with long wavy brown hair chubby and beard, hammer weapon, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "\"Distressed man with smoking computer\"",
        "full": "a sad man holding his face, shocked, looking at his computer going up in smoke, digital art by artgerm"
    },
    {
        "display": "Tanned woman with ginger hair",
        "full": "long ginger hair, tanned woman in a prehistoric outfit, green eyes, by artgerm, hair tied in a ponytail, white backdrop, soft lighting, blue colors, by greg rutkowski makoto shinkai takashi takeuchi"
    },
    {
        "display": "Monster Hunter Tigrex Roaring",
        "full": "tigarex ( monster hunter ) roaring, long lens, wide angle, greg rutkowski, drark, dark, plutus su, chris scalf, lucas graciano, billy christian, symmetrical, deep forest, unreal engine, atmospheric haze, d & d, fantasy, high detailed, realistic, complimentary colors, light, artstation, cinematic, dramatic lighting, close up"
    },
    {
        "display": "Disturbing street crime portrait",
        "full": "weird and disturbing portrait of violent crime in the streets of tel aviv, vivid colors, neon, art by gregory crewdson and artgerm and wlop and william - adolphe bouguereau"
    },
    {
        "display": "Goth girl and handsome man family portrait.",
        "full": "a tall goth girl and a buff handsome man, family photo, cute, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mysterious poker man illustration",
        "full": "ultra realistic illustration, man in a dark blue hood, with black hair, mysterious, poker man, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Geisha Nami portrait.",
        "full": "a portrait of Nami the burglar as a real woman, wearing a traditional geisha dress, ginger hair, lightning in the background , electricity themed, beautiful face, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Charlie Bowater, matte painting"
    },
    {
        "display": "Chocolate cupcake girl fantasy",
        "full": "a ( ( girl as personification of chocolate cupcake ) ), beauty, fantasy bakery, digital painting by krenz cushart, greg rutkowski, artgerm, laurie greasly, wlop, intricate, highly detailed!!, sharp focus, smooth, epic composition, joyful, unreal engine, masterpiece, 8 k"
    },
    {
        "display": "Tattooed woman character sheet",
        "full": "tattooed woman walking cycle, character sheet, fine details, concept design, contrast, brigitte bardot, kim jung gi, greg rutkowski, trending on artstation, 8 k, full body, turnaround, front view, back view, ultra wide angle"
    },
    {
        "display": "Timesplitters concept art, Toriyama style",
        "full": "timesplitters in the style of chrono trigger akira toriyama, concept art"
    },
    {
        "display": "Hacker accessing mainframe.",
        "full": "hacker getting entry into mainframe. cinematic, hyperrealistic, hyperdetailed, 4 k, 8 k, trending on artstation"
    },
    {
        "display": "Robot chasing thugs.",
        "full": "commission of a robot chasing thugs.dramatic,character design by charles bowater,greg rutkowski,ross tran,hyperdetailed,hyperrealistic,4k,deviantart,artstation,professional photography,concept art,dramatic"
    },
    {
        "display": "Cinematic Pikachu oil painting",
        "full": "concept art of pikachu, cinematic shot, oil painting by jama jurabaev, extremely detailed, brush hard, artstation, high quality, brush stroke, white background"
    },
    {
        "display": "Moon goddess portrait",
        "full": "the moon goddess, intricate and detailed lace set, suspenders, honey birdette, realistic renaissance portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, cinematic lighting, art by john collier, artgerm and greg rutkowski and alphonse mucha and jacques louis david and john william godward"
    },
    {
        "display": "Young man D&D character portrait",
        "full": "young man with short white combover hair and moustache, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Steampunk lynx fursona portrait",
        "full": "wide angle beautiful full body portrait of a strong female anthropomorphic anthro lynx fursona wearing a steampunk leather pants. from behind, paw pads, character design by disney, anime, manga, charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, soft lighting, rendered in octane, white fur, white lynx face"
    },
    {
        "display": "Dwarf with gold nugget",
        "full": "portrait of a brutal dwarf hugging the humongous raw, unrefined sparkling gold nugget, lump of native gold, realistic, fantasy art, dnd, lord of the rings, medium shot, mid - shot, moody lighting, by greg rutkowski, alphonse mucha, artgerm, trending on artstation, concept art, sharp focus, octane render, cgsociety"
    },
    {
        "display": "Elderly Jerma985 portrait",
        "full": "A photograph of old Jerma985 in his eighties who looks like Jerma985 wearing a sweater in the 2010s, Jerma985, looks like Jerma985, taken in the late 2010s, taken on a 2010s Camera, realistic, hyperrealistic, very realistic, highly detailed, very detailed, extremely detailed, detailed, digital art, trending on artstation, headshot and bodyshot, detailed face, very detailed face"
    },
    {
        "display": "Zeus in Egyptian setting",
        "full": "the god zeus, egyptian environment, portrait, sharp focus, digital art, concept art, post processed, dynamic lighting, by emylie boivin and rossdraws"
    },
    {
        "display": "Fantasy portrait of siren.",
        "full": "2 8 mm closeup portrait of very beautiful flirtatious siren margot robbie xena dragon goddess wearing a tiara made from dreams in a fantasy world, glamour pose, long wind swept hair, particle effects, ethereal, dreamy, backlit, highly detailed, realistic lighting, sharp focus, rule of thirds, artgerm, wlop, arney freytag, rossdraws, frank frazetta, andrei riabovitchev, hd, octane, 4 k,"
    },
    {
        "display": "Mechanical robotic fox concept",
        "full": "a mechanical robotic fox by viktor antonov, dishonored, concept art, intricate, detailed, dramatic, artstation, colorful"
    },
    {
        "display": "Elegant fantasy mouse illustration.",
        "full": "a mouse every detail, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant pin-up portrait",
        "full": "pin-up, beautiful Kristen Bell, intricate,  elegant, highly detailed,  digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by gil elvgren and artgerm and greg rutkowski  and alphonse mucha"
    },
    {
        "display": "Tiger-faced human king.",
        "full": "a very high detailed tiger face on a standing muscular human body, wearing a very detailed golden kings crown, tattoo on shoulder, standing in a highly detailed jungle, digital art, concept art, greg rutkowski, Nikolai Karelin, Hou China, trending artstation"
    },
    {
        "display": "Dieselpunk Volgograd illustration.",
        "full": "Dieselpunk Volgograd, Stalingrad, Hero City, The Motherland Calls, steam, epic composition, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Cosmic Nicol Bolas artwork",
        "full": "the second nicol bolas, in the style of magic the gathering, art by artgerm and greg rutkowski and alphonse mucha, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Victorious fear with mechanical butterfly",
        "full": "death is swallowed up in victory, very detailed and beautiful face, screaming in fear, mechanical butterfly, artwork by artgerm, centered shot, wide angle, full body, islandpunk, solarpunk, fantasy, highly detailed, digital painting, artstation, smooth, sharp focus, art by thomas kinkade and h. r. giger and kevin swartz"
    },
    {
        "display": "Rococo Cyberpunk Android Statue",
        "full": "full-body rococo and cyberpunk style neon statue of a young attractive portugues macho dotado e rico android sim roupa reclining con piroca dura, glowing white laser eyes, prince crown of pink gears, diamonds, swirling silver-colored silk fabric. futuristic elements. full-length view. space robots. human skulls. intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Fantasy portrait of woman",
        "full": "Purple hair relistic Portrait of a woman with bright colored flying hair, all shades of purple. Hair coloring, long hair, blue eyes, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sci-fi elite scientist portrait",
        "full": "concept art of scifi elite scientist by jama jurabaev, portrait, extremely detailed, studio light, trending on artstation, high quality, brush stroke"
    },
    {
        "display": "Inca Emperor with Hydra",
        "full": "inca emperor, hydra, deep focus, intricate, elegant, highly detailed, photorealistic rendering, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fire Queen Fashion Model",
        "full": "fire queen, bust portrait, photorealistic, artstation, anime, outfit - of - the - day, instagram, fashion outfit, inspo, street outfit, fashion model, fashion model, ootd, kpop, idol, flcl cover, yoji shinkawa greg rutkowski craig mullins noah bradley mark arian, selfie, bust portrait, ilya kuvshinov, trending on artstation, artstation hq, very very very beautiful, symmetrical, vignette, photoshop, clip studio paint, paint tool sai, j. c. leyendecker wlop"
    },
    {
        "display": "David Bowie duality art",
        "full": "twin peaks poster art, portrait of david bowie split by his dark side, by michael whelan, rossetti bouguereau, artgerm, retro, nostalgic, old fashioned"
    },
    {
        "display": "Moon Knight Elf RPG Art",
        "full": "Moon Knight mixed with Elf, RPG Reference, Oil Painting, Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Cute red-haired girl, sci-fi.",
        "full": "ultra realistic style illustration of a cute red haired young woman, 1 9 year old, headshot, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, 8 k frostbite 3 engine, ultra detailed"
    },
    {
        "display": "Young scientist with black hole",
        "full": "an oil portrait painting of a beautiful young female scientist in a laboratory looking at a small black hole in her hand, style by jordan grimmer and greg rutkowski, elegant, sharp focus, morandi color scheme, illustration, high quality, highly detailed, long hair, digital art"
    },
    {
        "display": "Male Japanese fire elemental.",
        "full": "male japanese fire elemental, ghostly form, transparent, embodiment of fire, comic book thick outline, gta art, demon summoning magic d & d, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Brie Larson as Skrull",
        "full": "portrait painting of brie larson with green skin and pointy ears skrull wearing sci - fi clothes, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Androgynous cat seahorse fursona",
        "full": "cat seahorse fursona, autistic bisexual graphic designer and musician, long haired attractive androgynous fluffy humanoid character design, sharp focus, weirdcore voidpunk digital art by artgerm, akihiko yoshida, louis wain, simon stalenhag, cory loftis, wlop, noah bradley, furaffinity, artstation hd, trending on deviantart"
    },
    {
        "display": "Jester character in hyperdimensional setting.",
        "full": "front!!! shot of a court jester!!! character, hyperdimensional, bioluminescent hypercubes, dark holography!!!, future, chrome, (((intricate))), elegant, highly detailed, centered, (((artstation, concept art, smooth, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo,  grainy, muted, 8K, digitalblasphemy, metatron"
    },
    {
        "display": "Snowy Swiss village illustration",
        "full": "a snow covered swiss village, ornate, beautiful, atmosphere, vibe, mist, smoke, chimney, rain, well, wet, pristine, puddles, waterfall, clear stream, bridge, orange, green, stained glass, forest, flowers, concept art illustration, color page, 4 k, tone mapping, doll, akihiko yoshida, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, digital illustration, greg rutowski, volumetric lighting, sunbeams, particles, rembrandt"
    },
    {
        "display": "Blue baseball cap illustration",
        "full": "a blue baseball cap, artstation, Fashion, Grim fantasy,emotional, EOS R5, f/2.5 , illustration , concept art, award winning photograph, 8k, Alphonse Mucha style, No Background"
    },
    {
        "display": "Anime-style ocean scene.",
        "full": "car, robot, penguin, ocean, blue sky, illustration concept art anime key visual trending pixiv fanbox by wlop and greg rutkowski and makoto shinkai, studio ghibli 3 d render"
    },
    {
        "display": "Mystical creature logo design",
        "full": "the bureau of mystical creature logo. pop art, pixel, bioshock art style, dynamic proportional, dynamic composition, face features, body features, ultra realistic art, digital painting, concept art, smooth, sharp focus, illustration, intricate, without duplication, elegant, confident posse, art by artgerm and richard hamilton and mimmo rottela, kirokaze and paul robertson"
    },
    {
        "display": "Photorealistic De Niro in Sin City",
        "full": "Robert De Niro stars in Sin City movies, incredibly detailed, photorealistic, Black and white, cinematic lighting, trending on artstation, 4k, hyperrealistic"
    },
    {
        "display": "Cyberpunk pirate portrait.",
        "full": "a cyberpunk portrait of black beard the pirate with a patch over his eye by jean - michel basquiat, by hayao miyazaki by artgerm, highly detailed, sacred geometry, mathematics, snake, geometry, cyberpunk, vibrant, water"
    },
    {
        "display": "Grid painting, Greg Rutkowski.",
        "full": "A painting of the grid trending on artstation in the style of Greg Rutkowski"
    },
    {
        "display": "Ayahuasca Spirit Illustration",
        "full": "The Ayahuasca Spirit, by Greg Rutkowski"
    },
    {
        "display": "Symmetrical Catwoman Portrait",
        "full": "symmetry!! front - faced portrait of catwoman, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ultrarealistic angelic knight portrait",
        "full": "A masterpiece ultrarealistic ultradetailed portrait of a Incredibly beautiful angel armored princess knight IN INCREDIBLE ceramic GAS MASK WITH FLOWERS and swarovski crystals. baroque renaissance. in the forest. White amazing corset. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, Yoshitaka Amano. background by James Jean and Gustav Klimt, light by Julie Bell, 4k, porcelain skin. BY ZDIZISLAW BEKSINSKI Cinematic concept art"
    },
    {
        "display": "\"Johnny Depp Fantasy Portrait\"",
        "full": "portrait of johnny depp eating hamburgers, extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Male dark phoenix close-up",
        "full": "A male dark phoenix, epic, highly detailed face, close-up, fantasy art, anime art, in the style of masami kurumada, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Cybernetic warrior in space",
        "full": "Epic dark gritty space scene digital artwork featuring the iridescent cloaked hooded warrior partially cybernetic entity god of future technology wielding cosmic weaponry, ornate galactic gold, intricate, ornate, gothic, colorful, vibrant, smooth, moody, ominous, dangerous aura, microchips, crystallic, iridescent, lasers, gems, multicolored glints, precious elements, beautiful, detailed, concept art, render, unreal engine, 4K, artstation"
    },
    {
        "display": "Anxious blue axolotl in suit.",
        "full": "a blue axolotl in business attire,, anxious, stressed out, inked and colored comic book panel, by jack kirby, ( 1 9 7 4 ), comic art, marvel comics, concept art, black outlines, utlrasharp detail, dynamic pose, official media"
    },
    {
        "display": "Fantasy portrait of smiling boy.",
        "full": "digital art, fantasy portrait of a smiling boy , by James Jean and by artgerm, by ross tran , ultradetailed, charachter design, concept art, trending on artstation,"
    },
    {
        "display": "Elderly scarred woman portrait",
        "full": "overwatch concept art character portrait of a new character who is an elderly pale woman with a scarred face, trending on artstation, cgsociety,"
    },
    {
        "display": "Cinematic female necromancer illustration",
        "full": "a beautiful cinematic female Necromancer Sorceress, galatic shamen with Quantum energy fantasy, fantasy magic, undercut hairstyle, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Rockstar girl with guitar.",
        "full": "rockstar girl playing electric guitar on stage. by edward robert hughes, by konstantin razumov, by william - adolphe bouguerea, by artgerm, pixar, artstation trending, concept art, digital art, digital painting, dramatic lighting, sharp focus, highly detailed, vxf movie, cinematic"
    },
    {
        "display": "Psychedelic Megan Fox Riddler",
        "full": "an extremely psychedelic portrait of megan fox as the riddler, surreal, lsd, face, detailed, intricate, elegant, lithe, highly detailed, digital oth, sharp focus, illustration,"
    },
    {
        "display": "Witchy Cuphead Portrait",
        "full": "portrait of a cuphead, dark, piercing eyes, gentle expression, witchy clothing, photorealistic, highly detailed, artstation, smooth, sharp focus, art by michael whelan, artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Magical girl in garden",
        "full": "final fantasy key visual of a pretty girl with blue flowing hair, wearing a short skirt and a crop top, meditating in a magical fantasy garden at night, moonlight, fireflies glowing, lofi feel, magical, highly detailed, digital art, artstation, smooth, hard focus, illustration, art by artgerm - in the style of final fantasy and studio ghibli"
    },
    {
        "display": "Ultra-realistic rancher portrait.",
        "full": "close up portrait painting of a frontier cattle rancher, accurate human hand representation, ultra realistic, concept art, intricate details, serious, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Intricate neon demon artwork",
        "full": "demons, fame of thrones, lord of daggers, neon, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Trump under communist flag.",
        "full": "donald trump standing under a communist flag, digital art, heroic, illustrated by alphonse mucha, wlop, greg rutkowski, 4 k, extremely detailed, cinematic lighting, trending on artstation,"
    },
    {
        "display": "Wizard with bees concept art",
        "full": "wizard with swarm of bees, dungeons and dragons, pathfinder, roleplaying game art, yellow robes, hive staff, concept art, character design on white background, by studio ghibli, makoto shinkai, kim jung giu, poster art, game art"
    },
    {
        "display": "Anthropomorphic rhinoceros character portrait.",
        "full": "overwatch concept art character portrait of a new character who is an anthropomorphic rhinoceros humanoid man, trending on artstation, cgsociety,"
    },
    {
        "display": "Grungy Pixar girl portrait.",
        "full": "a grungy pixar girl with rainbow hair, drunk, angry, soft eyes and narrow chin, dainty figure, long hair straight down, torn overalls, short shorts, combat boots, basic white background, side boob, symmetrical, single person, style of by Jordan Grimmer and greg rutkowski, crisp lines and color,"
    },
    {
        "display": "Fantasy paladin in combat.",
        "full": "full body, attack position Ciurlionis, Mikalojus Konstantinas and Ross Tran painting in lush fantasy environment of a ornate holy mechanical warforged with circular glowing eye, character in yellow armor holding a legendary paladin engraved holy great longsword and carrying a huge heavy paladin shield, vertically flat head, face in focus, epic , trending on ArtStation, masterpiece,  cinematic lighting"
    },
    {
        "display": "Ethereal ghost dancer simulation",
        "full": "mid shot render of an ethereal ghostlike figure fluid simulation in houdini dancing in dark smoke robes and silk veils by ilm, paolo roversi, nick knight, gill elvgren, beautiful futuristic simplified form distorted by turbulent movement, dark studio background, deep color, trending on artstation, hyperrealism, matte painting, dutch golden age, fine detail, cgsociety"
    },
    {
        "display": "Quake 3 monster concept art.",
        "full": "concept art of monster from quake 3 video game"
    },
    {
        "display": "Anime girl trading card",
        "full": "girl, trading card front, anime style, long hair, hair down, symmetrical facial features, hyper realistic, pale skin, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, sport, badminton, realistic lighting, by alphonse mucha, greg rutkowski, sharp focus, backlit, sport clothing"
    },
    {
        "display": "Burning Big Ben 3D Render",
        "full": "a beautiful hyperrealistic detailed 3D render of big ben burning, by Anton Otto Fischer, Atey Ghailan, genzoman, unreal engine, octane render, gigantic, 3D, brilliantly coloured, intricate, ultra wide angle, trending on artstation, embers, smoke, dust, dusk, volumetric lighting, HDR, polished, micro details, ray tracing, 8k"
    },
    {
        "display": "Muddy river sunset landscape",
        "full": "muddy river leads to sea by hiroshi yoshida, marc simonetti, roger deakins, turner, rothko, hyperrealism, cinematic, canyon, dramatic storm sunset, matte painting, fluid simulation, tonalism, volumetric light, trending on artstation, cgsociety, high detail concept art, top view, rendered in octane"
    },
    {
        "display": "Symmetrical portrait of Groot",
        "full": "symmetry!! portrait of groot, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg alien bounty hunter.",
        "full": "humanoid cyborg alien bounty hunter, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed tiefling barbarian portrait",
        "full": "tiefling barbarian, ultra detailed fantasy, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Ryu at a Fantasy Tea Party",
        "full": "Ryu at a Tea Party, cute, fantasy, intricate, elegant, highly detailed, digital painting, 4k, HDR, concept art, smooth, sharp focus, illustration, art by sakimichan"
    },
    {
        "display": "Mecha African woman concept.",
        "full": "a mecha version of an african woman with dreadlocks, with a septum nose ring piercing, very symmetrical, highly detailed, by vitaly bulgarov, by joss nizzi, by ben procter, by steve jung, concept art, quintessa, metal gear solid, transformers cinematic universe, concept art world, pinterest, artstation, unreal engine"
    },
    {
        "display": "Sinister athletic portrait.",
        "full": "clear portrait of emma watson, sinister appearance, ripped clothing, athletic, cottagecore!!, background hyper detailed, character concept, full body, dynamic pose, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Garfield cat goddess concept",
        "full": "fullbody!! personification of garfield the cat as a goddess holding a blood chalice and lasagna, detailed, stunning, cat face, hyperrealistic, trending on artstation, smooth and sharp, intricate, fine details, highly detailed, elegant, angular, altermodern, radiant light, detailed and intricate environment, professional character concept art by tatyana kupriyanova"
    },
    {
        "display": "Vibrant bird nest illustration",
        "full": "bird nest in a tree, concept art, illustrated, highly detailed, high quality, bright colors, optimistic,"
    },
    {
        "display": "Ukrainian soldier, burning Red Square.",
        "full": "special forces soldier with ukrainian blue and yellow patch on the foreground watching red square burn in the background, d & d, fantasy, bright atmosphere, volumetric lights, intricate, elegant, extremely detailed, digital painting, artstation, concept art, matte, smooth, sharp focus, hyper realistic, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Disordered illusion of control",
        "full": "control is an illusion entangled in disarray, you can never make anyone happy by Artgerm, crazy life moment by Casey Weldon"
    },
    {
        "display": "Space shuttle concept art",
        "full": "a painting of a space shuttle and a space shuttle, concept art by don maitz and by vincent di fate and by robert mccall and by allan brooks and by ron walotsky, trending on pinterest, space art, sci - fi, concept art, redshift"
    },
    {
        "display": "Boiling red sea scene",
        "full": "a boiling red sea with erupting geysers of superheated water, extremely detailed oil painting, unreal 5 render, rhads, Bruce Pennington, Studio Ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award-winning photograph, masterpiece"
    },
    {
        "display": "Magical maid in forest",
        "full": "A beautiful female maid wearing a magical bikini in a magical forest, realistic face, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Steampunk mad scientist lab",
        "full": "corner of a steampunk mad scientist labratory, high details, intricately detailed, by vincent di fate, inking, 3 color screen print, masterpiece, trending on artstation,, sharp, details, hyper - detailed, hd, 4 k, 8 k"
    },
    {
        "display": "Ethereal elven girl in paradise.",
        "full": "Ethereal, mysterious stunning maximalist mesmerizing elven girl with elf ears from the rainbow sky paradise, high-tech, Victorian gothic lolita fashion, by Mark Ryden, artgerm, Hiroyuki-Mitsume Takahashi, WLOP, Goto Fujita, \u5948\u826f\u7f8e\u667a, Pixiv 3DCG, DAZ Studio, highly detailed, photorealistic, 8k resolution 3D, cinematic, dynamic lighting, octane render"
    },
    {
        "display": "Angel of magic portrait",
        "full": "character portrait of an angel of magic, by Peter Mohrbacher, Mark Brooks, Jim Burns, Marina Abramovi\u0107, Wadim Kashin, Greg Rutkowski, trending on Artstation"
    },
    {
        "display": "Hyper-realistic dreamscape of Jacob.",
        "full": "intricate concept image of jacob dreaming angels going up and down in a ladder from heaven to earth, hyper realistic, ultra detail, octane render, cinematic lighting, volumetric lighting, 8 k, trending i. artstation"
    },
    {
        "display": "Beautiful demon girl artwork",
        "full": "very beautiful demon girl, artgerm, artstation, 4 k,"
    },
    {
        "display": "Young woman in masquerade.",
        "full": "portrait of a young woman in a raven masquerade mask, dark, piercing green eyes, exotic expression, esoteric clothing, photorealistic, highly detailed, artstation, smooth, sharp focus, art by michael whelan, artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Genetically perfect blonde man.",
        "full": "portrait of uncannily beautiful blonde man, genetically perfect, with distant expression and piercing blue eyes, wearing fascist Byzantine police uniform and standing in ancient bronze arcology airlock, science fiction concept art by Anato Finnstark, Alphonse Mucha, and Greg Rutkowski"
    },
    {
        "display": "Fishing boat vs. tentacle monster",
        "full": "a ((small fishing boat)) is attacked by a huge tentacled monster, crashing waves, trending on artstation, rendered in octane, global illumination"
    },
    {
        "display": "Beautiful witch portrait",
        "full": "side portrait of a beautiful witch, black hair, flower crown, white cloak, headshot, hyper realistic, pale skin, 4k, rule of thirds, beautiful eyes, extreme detail, detailed drawing, trending artstation, hd, fantasy, D&D, realistic lighting, by Alphonse Mucha, Greg Rutkowski, sharp focus, backlit, elegant"
    },
    {
        "display": "Muscular female Thanos portrait",
        "full": "thanos as a muscular woman, highly detailed portrait, elegant, breathtaking art, by artgerm"
    },
    {
        "display": "Chinchilla portrait, digital art",
        "full": "a portrait of a beautiful chinchilla, art by ilya kuvshinov and wlop and artgerm and josan gonzalez, magda torres gurza, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Cyberpunk elderly warrior",
        "full": "old man with cyberpunk leather jacket, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Voluptuous Palutena as Poison Ivy",
        "full": "a stunning vector portrait of a thicc and voluptuous palutena dressed as a beautiful poison ivy with hair tied in a braid walking through a flowering garden, greenhouse in the background, intricate, elegant, highly detailed, digital painting, artstation, concept art, ultra sharp focus, illustration, art by jugendstil and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lofi Pixar-style portrait",
        "full": "Lofi portrait, Pixar style by Joe Fenton and Stanley Artgerm and Tom Bagshaw and Tim Burton, gentle smile"
    },
    {
        "display": "John Cena in tuxedo portrait.",
        "full": "john cena wearing a tuxedo, portrait, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyperrealistic cinematic portrait",
        "full": "A film still of one piece, digital painting, artstation, concept art, Craig Mullins, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Albino tiger knight portrait",
        "full": "light grey hair portrait knights of muscular male furry albino tiger + smoky eyes, matt black / little red color armor, in ruined agora of athens, ssci - fi and fantasy, intricate and very very beautiful and elegant, highly detailed, digital painting, artstation, concept art, frostbite engine, smooth and sharp focus, illustration, art by tian zi and wlop and alphonse mucha"
    },
    {
        "display": "Fantasy portrait hybrid.",
        "full": "a fantasy style portrait painting of rachel lane / brie larson hybrid in the style of francois boucher oil painting unreal 5 daz. rpg portrait, extremely detailed artgerm greg rutkowski alphonse mucha"
    },
    {
        "display": "Magical girl illustration.",
        "full": "A full body shot of a cute young magical girl wearing an ornate dress made of opals and tentacles. Chibi Monster GIrl. Subsurface Scattering.  Dynamic Pose. Translucent Skin. Rainbow palette. defined facial features, symmetrical facial features. Opalescent surface. Soft Lighting. beautiful lighting. By Giger and Ruan Jia and Artgerm and WLOP and William-Adolphe Bouguereau and Loish and Lisa Frank. Fantasy Illustration. Sailor Moon. Masterpiece. trending on artstation, featured on pixiv, award winning, cinematic composition, dramatic pose, sharp, details, Hyper-detailed, HD, HDR, 4K, 8K."
    },
    {
        "display": "Avalon cityscape illustration",
        "full": "magnificent view of the city of Avalon standing on a hill in the middle of a river, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Powerful wizard casting fireball",
        "full": "extreme cosmic power, wizard casting the biggest greatest brightest destructive fireball in the world, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg vs. Skeleton Warriors",
        "full": "cyberpunk cyborg fighting skeleton warriors, dramatic light, castle background, clouds, arctic, snow moon, storm, night, high detail, fantasy background, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Giant burning tree city.",
        "full": "A highly detailed matte painting of the biggest tree ever with buildings on the tree, the tree is on fire, huge fire with smoke and explosions, by Studio Ghibli, Makoto Shinkai, by Artgerm, by beeple, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Ominous floating creature portrait.",
        "full": "professional concept art portrait of a ominous floating!! organic terrifying!! species thing in a dark room by artgerm and greg rutkowski. an intricate, elegant, highly detailed digital painting, concept art, smooth, sharp focus, illustration, in the style of cam sykes."
    },
    {
        "display": "Portrait of young Makate Kaori",
        "full": "portrait of Makate Kaori at age of 20, by Artgerm"
    },
    {
        "display": "Surreal ritual movie poster",
        "full": "twin peaks movie poster art of taylor swift standing next to young boy sitting in throne, cheerleader sacrificed to moloch in a ritual, highly detailed, by artgerm, leyendecker, wlop, katherine lam, peter mohrbacher, michael whelan, tomer hanuka, godward, gustav moreau, saturno butto, boris vallejo, david kassan"
    },
    {
        "display": "Ginger-bearded firefighter in anime.",
        "full": "An old man with a ginger beard wearing a fire fighters helmet, highly detailed, digital art, sharp focus, trending on art station, anime art style"
    },
    {
        "display": "High fantasy female character.",
        "full": "high fantasy female concept art by hao zeng-H 832"
    },
    {
        "display": "Lifelike marble bust",
        "full": "amazing lifelike award winning marble bust of John fashanu trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Armored Spike Spiegel Portrait",
        "full": "greg manchess portrait painting of armored spike spiegel as overwatch character, totally whack, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Anime Hillary Clinton in tactical gear.",
        "full": "hillary clinton wearing tactical gear, very anime, fine - face, hillary clinton, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Anime maids crusade painting",
        "full": "baroque oil painting of key visual environment concept art of anime maids fighting a crusade for the anglican church, brutalist, dark fantasy, rule of thirds golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai studio ghibli genshin impact jamie wyeth james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "Futuristic jester cyborg portrait",
        "full": "front shot of a court jester!!!! character, polished, glossy, holographic crystals, bionic futurism, robotic cyborg, 8 5 mm f 1. 8, ominous elegant highly detailed concept artstation sharp focus ray tracing cinematic masterpiece fine portrait, by artgerm greg rutkowski tooth wu beeple"
    },
    {
        "display": "Surreal psychedelic ruins.",
        "full": "ruins of a bad acid trip, matte painting, dreamscape, global illumination, the great beyond, trending on artstation"
    },
    {
        "display": "Fantasy pizza character illustration",
        "full": "Anthropomorphized pizza, D&D, fantasy, cinematic lighting, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, warm light, cozy warm tint, magic the gathering artwork, volumetric lighting, 8k, no gold, no gold colours, art by Akihiko Yoshida, Greg Rutkowski"
    },
    {
        "display": "Japanese princess portrait, hyperrealistic.",
        "full": "Portrait of a japanese princess young lady, with white hair and bangs!!!! digital painting, artstation, concept art, Sergey Kolesov , Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Young dark-clothed ninja.",
        "full": "character concept, wide angle, full body, symmetrical centralized, young man with dark ninja clothes. detailed, high quality, dynamic lightning, fantasy, scenematic. artwork by artgerm, wlop, alex ross, greg rutknowski, alphonse mucha"
    },
    {
        "display": "Cyberpunk knight robot portrait",
        "full": "robot with glowing purple visor as a realistic scifi cyberpunk knight, closeup portrait art by donato giancola and greg rutkowski, realistic face, digital art, trending on artstation, symmetry!!!"
    },
    {
        "display": "Scary prisoner matte painting",
        "full": "a scary matte painting of a prisoner standing in front of a fence with barbed wire by emiliano ponzi, james gilleard, george ault, david hockney, atey ghailan, albert namatjira, marius borgeaud, minimalist, bauhaus, retrofuturism, postminimalism, concept art, matte background, matte drawing, magical realism, space art, generative art"
    },
    {
        "display": "Surreal neon landscape art",
        "full": "Various Neon signs over a surreal landscape, rendered by simon st\u00e5lenhag, rendered by Beeple, Makoto Shinkai, syd meade, environment concept, digital art, starwars, unreal engine, 3 point perspective, WLOP, trending on artstation, low level, 4K UHD image, octane render,"
    },
    {
        "display": "Scary green portal landscape",
        "full": "a scary portal glowing green portal to another world in darwin's arch in the galapagos islands, ocean and rock landscape, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and rembrandt"
    },
    {
        "display": "Ultra-realistic Gigachad portrait",
        "full": "ednaldo pereira gigachad, ultra realistic face, by greg rutkowski"
    },
    {
        "display": "Hyper-realistic jungle portrait",
        "full": "a beautiful portrait of august schellenberg taking tobacco snuff in the jungle, hyper realistic face, fantasy art, in the style of greg rutkowski, intricate, matte painting, hyper detailed, smooth"
    },
    {
        "display": "Jimi Hendrix in Heaven",
        "full": "Jimi Hendrix as Gabriel with a Day-Glo halo  at the Pearly Gates of Heaven, pink tinged heavenly clouds, beautiful buxom angels in bikinis with detailed beautiful pretty faces resembling Lena Horne and Tuesday Weld, flying around his head blowing iridescent soap bubbles, Halle Berry as an angel with a harp wearing golden white robes, ornate tall golden gates adorned with iridescent mother of pearl inlay,  Behind the gates is a beautiful waterfall and rainbow, by Rudolph Belarski and Thomas Kinkade, trending on artstation, matte painting"
    },
    {
        "display": "Alexandra Daddario as queen",
        "full": "alexandra daddario as queen, incredibly detailed face, light semi - open dress, true anatomy, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant female aristocrat cityscape",
        "full": "aristocrat, white grey blue color palette, eyes closed, cityscape, female, d & d, fantasy, intricate, elegant, highly detailed, long silver hair, digital painting, artstation, octane render, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm, alphonse mucha johannes voss"
    },
    {
        "display": "Muscled warrior Emma Watson",
        "full": "portait of a very muscled emma watson swinging her long sword forest monster, front game card, drark, marvel comics, dark, intricate, highly detailed, smooth, artstation, digital illustration by ruan jia and mandy jurgens and artgerm and wayne barlowe and greg rutkowski and zdislav beksinski"
    },
    {
        "display": "Elegant woman with wings.",
        "full": "Portrait of a beautiful elegant woman colors, angelic wings on her back, sunset warm spring, fantasy, anime, vibrant, intricate details, trending on ArtStation, Deviantart, by WLOP"
    },
    {
        "display": "Upside-down female farmer portrait",
        "full": "full body portrait of a female farmer upside down, tree branches as hair, highly detailed, dirty face, next to a red barn, digital painting, artstation, concept art, soft focus, depth of field, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Anime girl in sundress.",
        "full": "a girl, white sundress, red hair, yellow butterflies, forest background, high heels, anime art, smooth, hd, pixiv, artstation"
    },
    {
        "display": "Chubby Romanian man portrait",
        "full": "50 year old chubby thinner brunette man with very short hair, buzz cut,  round circle face, round jaw, wide chin , romanian, silver small glasses,  romanian heritage, brown eyes, brown eyes, olive skin, round round round nose, round chin, clean shaven wide wide wide  face, thin lips, digital art, concept art, cgsociety, painterly, painting, 8k, illustration, painting, dramatic,  beautiful, art by loish loish loish, cartoon, stylized  painterly, trending on artstation, medium shot, uncropped"
    },
    {
        "display": "Medieval Chinese Prince Portrait",
        "full": "a highly detailed portrait of buffy the vampire slayer as a medieval chinese prince, beautiful detail and color, art by john collier and albert aublet and krenz cushart and artem demura and alphonse mucha, volumetric lighting, octane render, 4 k resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Elegant goth in latex.",
        "full": "curvy feminine hot goth cutie with sublime elegant polished red-black latex neck-high gown with fishnet accents and polished latex stockings, cgsociety, photorealistic, comfy ambience, idealistic, 16k, smooth, sharp focus, trending on ArtStation, volumetric lighting, fully clothed, worksafe"
    },
    {
        "display": "Psychedelic neuron sunflower portrait",
        "full": "A psychedelic portrait of neuron sunflower, vibrant color scheme, highly detailed, in the style of romanticism, cinematic, artstation, Moebius, Greg rutkowski"
    },
    {
        "display": "Lofi robot portrait, Pixar style",
        "full": "Lofi portrait with robot, Pixar style by Joe Fenton and Stanley Artgerm and Tom Bagshaw and Tim Burton"
    },
    {
        "display": "Tattooed Aztec woman in helmet.",
        "full": "an attractive young tattooed aztec female with piercings wearing an rainbow ornate metallic helmet, penelope cruz, olive skin, long dark hair, beautiful bone structure, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Spirit of the Woods",
        "full": "a detailed painting of a spirit of the woods, by justin gerard and greg rutkowski, digital art, realistic painting, fantasy, character design, trending on artstation"
    },
    {
        "display": "Fantasy portrait of girl reading.",
        "full": "highly detailed portrait of beautiful girl reading a book, hair flowing down, in pixar inside out, dynamic pose, stephen bliss, unreal engine, fantasy art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Wolf-crocodile chimera headshot",
        "full": "chimera made of a wolf and a crocodile, awarded on pixiv, trending on deviantart, headshot, realistic birch wood swamp, professional photoshop utilizing real life photos"
    },
    {
        "display": "Girl with snow hair.",
        "full": "girl with super long hair, hair becoming white snow, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Old gods priest-king portrait",
        "full": "full portrait of priestking of the old gods, black metal shiny skin. intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, frank frazetta"
    },
    {
        "display": "Anime shogun knight portrait",
        "full": "an anime portait shogun knight with a lightsaber halberd, dark metal armor, and a tattered cape, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Apocalyptic woman on tank",
        "full": "beautiful apocalyptic woman in hooded cloak, standing on mad max panzer tank, hyper-detailed, smooth, sharp focus, 4k ultra hd, fantasy dark art, tank girl, artgerm, artstation, octane render, detailed digital painting, apocalyptic art, peter mohrbacher, Francis bacon"
    },
    {
        "display": "Chubby woman with pastel hair.",
        "full": "a chubby white woman with pointed ears, wearing a white sundress, rainbow pastel clouds for hair, realistic painting by ross tran and gerald brom and alphonse mucha, artgerm, trending on artstation"
    },
    {
        "display": "Polymer clay George Clooney sculpture",
        "full": "george clooney made out of polymer clay detailed sculpture trending on artstation"
    },
    {
        "display": "Romantic portrait in DHL van",
        "full": "portrait of a man in dhl van hugging his wife, feelings, romantic, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fractal cave ecosystem",
        "full": "a fractal wonderland in a cave of translucent stalactites, mushroom ecosystem - rays, ray - tracing, cinematic lighting, 4 k smooth, focus, cinematic, concept art, highly detailed, artstation, trending, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "Fantasy magic illustration",
        "full": ":  horus fantasy, fantasy magic,  , intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, jahbu art and Paul lewin and kehinde wiley, masterpiece"
    },
    {
        "display": "Cyberpunk medieval knight portrait",
        "full": "medieval knight, cyberpunk, blue and yellow glow, realistic portrait full body, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Female warrior on boat",
        "full": "A beautiful female warrior posing on a boat, beautiful face, highly detailed face, close-up, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Female alien Jedi portrait",
        "full": "a female alien jedi, full shot, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Steampunk goddess portrait",
        "full": "a beautiful portrait of a steampunk goddess by greg rutkowski and raymond swanland, trending on artstation, ultra realistic digital art"
    },
    {
        "display": "Ethereal demon girl portrait",
        "full": "portrait of demon girl, dreamy and ethereal, expressive pose, big black eyes, exciting expression, fantasy, intricate, elegant, dark and moody smoke, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and ( alphonse mucha )"
    },
    {
        "display": "Goddess of Space Illustration",
        "full": "beautiful goddess of space and dreams by maxfield parrish artgerm, in frame, mandala, coherent design, perfect symmetry, vivid colors, digital watercolor ink illustration painting, complementary color, golden ratio, detailed, sharp lines, sharp focus, intricate, rainbowshift, gustave dore, alphonse mucha, octane render"
    },
    {
        "display": "Female hacker, dark digital art.",
        "full": "female hacker breaking into high security system, dark brooding, artgerm, digital art, cgsociety, artstation"
    },
    {
        "display": "Rugged fantasy ranger portrait",
        "full": "portrait of a rugged ranger, 2 5 years old, muscular, upper body, leather, hairy torso, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Shamanic Toronto Poster Art",
        "full": "downtown toronto glowing eyes, shamanic poster lsd art, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, frank frazetta"
    },
    {
        "display": "Happy young gnome wizard.",
        "full": "Portrait of a young happy gnome with long hair called Eldon, who is a very young wizard that studied at the School of Abjuration, DnD, art by argerm and greg rutkowski, trending on artstation."
    },
    {
        "display": "VFX portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin by eiichiro oda!, yusuke murata, magali villeneuve, greg rutkowski, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and stanley kubrick, backlit, johannes vermeer, fiona staples, harsh overhead sunlight,"
    },
    {
        "display": "Fantasy dungeon floor plan",
        "full": "full - color fantasy floor plan map of a dungeon, d & d, pathfinder, by jeff todd and greg rutkowski, trending on artstation, pinterest"
    },
    {
        "display": "Spanish brigantine at medieval coast.",
        "full": "a beautiful painting of a spanish brigantine moored off the coast of a medieval coastline and shipyard, trending on artstation"
    },
    {
        "display": "Scarlett Johansson Rogue Illustration",
        "full": "scarlett johanson as a rogue, leather armor, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young white-haired boy portrait",
        "full": "young cute pretty boy with white long hair, d & d, fantasy, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration,"
    },
    {
        "display": "Iridescent sci-fi armored warrior",
        "full": "iridescent sinewy smooth muscular male warframe sleek glossy black pearlescent scifi armor with smooth black featureless helmet, by greg rutkowski, mark brookes, jim burns, tom bagshaw, magali villeneuve, trending on artstation"
    },
    {
        "display": "Paris-inspired coastal cityscape.",
        "full": "the beautiful city of paris rebuilt next to the ocean in sunny california, amazing weather, beach view, palm trees, splendid haussmann architecture, wonderful eiffel tower, digital painting, highly detailed, intricate, concept art, matte painting, trending on artstation, art by greg rutkwowski, artgerm, wlop, painting by canaletto"
    },
    {
        "display": "Elegant Hatsune Miku Dance",
        "full": "hatsune miku, dance, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Towering hyperrealistic cityscape",
        "full": "hyperrealism, detailed textures, photorealistic 3 d render, a towering city with 3 0 0 0 0 foot tall buildings as viewed from ground level, ultra realistic, ultra high pixel detail, cinematic, intricate, cinematic light, concept art, illustration, art station, unreal engine 8 k"
    },
    {
        "display": "Skadi, winter goddess portrait",
        "full": "highly detailed close up portrait of Skadi, goddess of winter, digital art, concept art, character art, studio lightning, bright colors, intricate, masterpiece, photorealistic, hiperrealistic, sharp focus, high contrast, Artstation HQ, DeviantArt trending, 4k UHD, Unreal Engine 5"
    },
    {
        "display": "Elegant sun goddess portrait",
        "full": "portrait of the goddess of the sun, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, technomancer astronaut with an epic cosmic background in the style of erak note, eddie mendoza, christophe young, craig mullins, evan lee, silvain sarrailh, dao trong le, kan liu, character design, trending on artstation"
    },
    {
        "display": "Crying female marble statue",
        "full": "artistic portrait of a crying female marble statue, art by alessio albi 8 k ultra realistic, sadness, lament, wings, lens flare, atmosphere, glow, detailed, intricate, full of color, led lighting, trending on artstation, 4 k, hyperrealistic, 3 5 mm, focused, extreme details, unreal engine 5, masterpiece"
    },
    {
        "display": "Vine-covered multi-layered building",
        "full": "complex vine covered building with many layers, 8 k, highly detailed, beautiful lighting, vivid colors!, realistic, ultra rendered, trending on artstation, deviantart featured"
    },
    {
        "display": "Cute anime boy with wolf ears.",
        "full": "character concept art of an cute anime boy with pink hair and wolf ears | | cute - fine - face, pretty face, key visual, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, tranding on artstation"
    },
    {
        "display": "Young James Dean in USSR uniform",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork costume design: young James Dean as a well-kept neat perfect formal student in a 1950s USSR school uniform. By WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Witch hunter dark fantasy.",
        "full": "the witch hunter, trending on art station, Dark Fantasy, Perfect Composition, artstation, Trending on art station, epic, perfect, illustration, concept art, hyper detailed, realistic, masterpiece, WLOP, Greg rutkowski"
    },
    {
        "display": "Bruno Mars on Mars.",
        "full": "Bruno Mars eats a mars bar on mars, ultra highly detailed, rococo painting, smooth, sharp focus, artstation, pixiv,"
    },
    {
        "display": "Realistic Charmander Ice Sculpture",
        "full": "a charmander clear ice sculpture, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by frank frazetta, simon bisley, brom"
    },
    {
        "display": "Cassandra Cain Sleepover Selfie",
        "full": "selfie photo of cassandra cain in pajamas wearing a towel as a cape, in the bedroom at a sleepover, beautiful face!!!!, 2 7 years old, cg animation, lifelike, animated, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Evil armored warlock portrait",
        "full": "cell shaded cartoon, a portrait of a fully armored evil warlock version of conan the barbarian, illustration, wide shot, subtle colors, concept art by josan gonzales and wlop, laurie greasley, jordan grimmer and james jean, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Lonely graveyard with Lucifer.",
        "full": "a lonely graveyard at night, the moon shine is falling on a specific grave with a sakura tree beneath it also there's the fall of super mad and with extrem anger lucifer, its raining and the atmosphere is oppressive dark with many shadows and dark red highlights, concept art by aleksandra waliszewska, cinematic atmosphere"
    },
    {
        "display": "Tall castle at sunset.",
        "full": "a beautiful ultradetailed painting of a tall castle with walls of cobblestone visible over the treeline of a red maple, golden hour, albert bierstadt, makoto shinkai, 4 k, trending on artstation : 3"
    },
    {
        "display": "Beautiful female riot police statue",
        "full": "a statue of an extremely beautiful female riot policewoman made of black marble, haute couture tactical clothing, tactical ballgown, military dress, military skirt, the statue is not wearing a helmet, no helmet, 4 k, 8 k, hd, render, denoise, sharp focus, clear focus, beautiful lighting, trending on artstation, tactical gear, chest rig, straps, nylon, buckles, the statue is wearing a complex tactical chest rig, acronym, techwear, tactical clothing, sharp focus, award winning photograph, beautiful, clear, sharp, detailed, hyperreal, realistic"
    },
    {
        "display": "Cyborg mercenary portrait",
        "full": "beautiful close - up portrait of a cyborg mercenary girl, art by wlop and artgerm and liam wong, cyberpunk, neon, lens flare, elegant, highly detailed, trending on artstation, sharp focus, caustics, octane render, radiant light, 4 k"
    },
    {
        "display": "Eren Jaeger Overwatch portrait",
        "full": "greg manchess portrait painting of eren jeager as overwatch character, design in overwatch style, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Cyberpunk portrait of Mila Kunis",
        "full": "portrait of a young mila kunis in front of a cyberpunk city, sunglasses, dramatic light, cyberpunk tech wear, city background, sunset, high contrast, sharp, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Horror Teletubbies Revelation",
        "full": "teletubbies disgusting true form revealed, horror, high details, intricate details, by vincent di fate, artgerm julie bell beeple, 1 9 8 0 s, inking, vintage 8 0 s print, screen print"
    },
    {
        "display": "Steampunk cryo chamber design.",
        "full": "steampunk cryo chamber, high details, intricately detailed, by vincent di fate, artgerm julie bell beeple, inking, screen print"
    },
    {
        "display": "Elon Musk portrait, bokeh effect.",
        "full": "A photo of Elon Musk , highly detailed, trending on artstation, bokeh, 90mm, f/1.4"
    },
    {
        "display": "Suspended wooden village in forest.",
        "full": "a suspended village built with wood and bridges high up a dense temperate forest with many giant tall and large trees, ultra detailed, intricate, elegant, diffuse lighting, digital painting, concept art, game art, matte painting, artstation, 8 k, greg rutkwowski, alphonse mucha, studio ghibli, wlop"
    },
    {
        "display": "Bioluminescent demon queen portrait",
        "full": "a portrait of bio - luminescent beautiful demon queen in deep, black darkness, shining eyes, neon colors, illustration, dramatic lighting, soft details, painting oil on canvas, art nouveau, octane render, hdr, 4 k, 8 k, hd, by edmund blair leighton, brom, charlie bowater, trending on artstation, faces by tom bagshaw, sargent"
    },
    {
        "display": "Detailed portrait of engineer.",
        "full": "portrait of a woman by greg rutkowski, emily blunt as an engineer, she is about 4 0 years old, wearing a utilitarian jumpsuit, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Guillotine with skulls, vibrant art.",
        "full": "guillotine with skulls and corpses around it, in the style of artgerm, gerald brom, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "Symmetrical sci-fi gold coin",
        "full": "symmetry!! product render poster gold coin scifi, glowing fog intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm"
    },
    {
        "display": "Futuristic Art Deco NYC",
        "full": "futuristic art deco new york city, fantasy, intricate, elegant, digital painting, trending on artstation, concept art, sharp focus, illustration by Gaston Bussiere and artgerm, 4k."
    },
    {
        "display": "Desert soldier illustration",
        "full": "desert soldier, smooth face, centered, solid bacgkround, median photoshop filter cutout vector behance, hd by artgerm, jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, illustration, art by ilya kuvshinov and gustav klimt"
    },
    {
        "display": "Tesla in neon lab.",
        "full": "nikolai tesla in his lab, electrical arcs, magical sparks, neon glow, highly detailed, digital art, intricate, dramatic lighting, retro futuristic, neon colors, cinematic, art by norman rockwell, greg rutkowski, james gurney, artgerm"
    },
    {
        "display": "Masculine teenage girl portrait",
        "full": "portrait of a very masculine teenage girl with blue eyes and brown hair, short men's haircut, smiling, wearing an oversized sweater, dramatic lighting, illustration by Greg rutkowski, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Dark fantasy rogue portrait",
        "full": "realistic portrait of beautifully crystalized and detailed portrait of a dungeons and dragons rouge, matte painting of cinematic movie scene, dark fantasy, horror, created by gustave dore and greg rutkowski, high detailed, smooth draw, synthwave neon retro, intricate, realistic proportions, dramatic lighting, trending on artstation."
    },
    {
        "display": "Scarlett Johansson as Captain America",
        "full": "captain america played by by scarlett johansson wearing nun outfit, face portrait, battle shot, hd shot, digital portrait, elegant, beautiful, fantasy art, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Epic monster-dragon duel",
        "full": "an epic duel between monster and dragon, art by guweiz, highly detailed, incredible quality, trending on artstation"
    },
    {
        "display": "Elysium-inspired organic artwork",
        "full": "elysium, organic painting, matte painting, bold shapes, hard edges, aesthetic octane render, unreal engine, trending on artstation, by greg manchess, huang guangjian, gil elvgren, sachin teng, greg rutkowski, magali villeneuve, artgerm, jeremy lipkin, michael garmash and, rey"
    },
    {
        "display": "Dodecahedron-headed warrior.",
        "full": "anthropomorphic dodecahedron head in ugly shroudspine keanu reeves battle master, intricate, elegant, highly detailed face, wide angle, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm, bob eggleton, stephen hickman, richard corben, wayne barlowe, greg rutkowski, alphonse mucha, 8 k"
    },
    {
        "display": "Carl Fredricksen in ogre costume.",
        "full": "portrait Carl Fredricksen wearing a shrek the ogre costume head eyes with yoked burly physique stands atop a skyscraper norman rockwell brad rigney winslow homer greg rutkowski stop-motion"
    },
    {
        "display": "Megan Fox Samurai Portrait",
        "full": "portrait of megan fox as samurai, asian, katana, blossom, silk, japan, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Hyperrealistic robot in data center.",
        "full": "hyperrealistic medium shot robot! in data center server highly detailed concept art eric zener elson peter cinematic hard purple lighting high angle hd 8 k sharp shallow depth of field, inspired by denis villeneuve and zdzis\u0142aw beksinski"
    },
    {
        "display": "Slipknot in post-apocalyptic venue",
        "full": "slipknot playing in a post apocalyptic venue, detailed intricate illustration, dark atmosphere, detailed illustration, hd, 4 k, digital art, overdetailed art, by greg rutkowski, by loish, complementing colors, trending on artstation, deviantart"
    },
    {
        "display": "Pope with Cthulhu portal.",
        "full": "pope priest in front of a cthulhu within a viscosity fluid lovecraft portal, shiny colors, high - key lighting, beautiful composition, intricate, elegant, pro photography by, highly detailed, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vibrant floating hexagons.",
        "full": "magical floating hexagons in the sky, vibrant contrast, by sylvain sarrailh, rossdraws, ambient light, ultra detailed, fantasy artwork, 8 k, volumetric lighting, trending on artstation, award winning, beautiful scenery, very beautiful."
    },
    {
        "display": "Lush Shire concept art.",
        "full": "lush and beautiful concept art for the shire, floating rocks in the air, stone city, arabian castles, sand, golden sun, planets, lord of the rings, peter jackson, studio ghibli, detailed, realistic lighting, volumetric lighting, golden hour,"
    },
    {
        "display": "Evil Ryu Overwatch Portrait",
        "full": "Greg Manchess portrait painting of Evil Ryu as Overwatch character, medium shot, asymmetrical, profile picture, Organic Painting, sunny day, Matte Painting, bold shapes, hard edges, street art, trending on artstation, by Huang Guangjian and Gil Elvgren and Sachin Teng"
    },
    {
        "display": "Elegant Aztec-Mayan portrait",
        "full": "Eva Mendes portrait, Aztec, Mayan, fantasy, elegant, intricate, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Happy Golden Goblin Sculpture",
        "full": "a happy cute Lucky Golden Goblin statue,  bright art masterpiece artstation. hyper details, golden smooth material, sculpted by Alex Alice, Craig Mullins, yoji shinkawa, trending on artstation, beautifully lit, Peter mohrbacher, hyper detailed, insane details, intricate, elite, elegant, luxury, glittering light, CGsociety, hypermaximalist, golden ratio, treasure background, volumetric, octane render, weta digital, micro details, 3d sculpture"
    },
    {
        "display": "Microscopic tardigrades in magical forest.",
        "full": "microscopic tardigrades, magical forest, water bear, robots, concept art, intricate details, highly detailed, photorealistic, disney pixar, octane render, iridescent, anime, 8 k"
    },
    {
        "display": "Eye-shaped highway interchange.",
        "full": "a still from elysium by ian mcque - multiple circular interconnected highway overpass interchange forming the symbol of an eye, dramatic lighting, evening above a 4 - lane highway, medium 7 0 mm lens, behance favourite, artstation trending, deviant art,"
    },
    {
        "display": "Epic cowboy portrait.",
        "full": "a beautiful dramatic epic painting of a thicc black man | he is shirtless and wearing a cowboy hat and leather harness | background is a dust cloud | homoerotic, highly detailed, dramatic lighting | by Mark Maggiori, by William Herbert Dunton, by Charles Marion Russell | trending on artstation"
    },
    {
        "display": "Fantasy bard portrait.",
        "full": "a _ fantasy _ style _ portrait _ painting _ of black female charismatic bard playing instrument, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Anthropomorphic alligator character.",
        "full": "in the style of artgerm, loish, anthropomorphic alligator, red scales on his back, yellow scale on his belly and chest, male, waring a hawaiian shirt, in the style of zootopia"
    },
    {
        "display": "Elegant Fantasy Portrait",
        "full": "a portrait of carol danvers, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Anime portrait of Van Damme",
        "full": "anime portrait of Jean Claude van Damme as an anime man by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, trending on artstation"
    },
    {
        "display": "Elven city in magical forest",
        "full": "An ancient elven city in a magical forest, high detail, many characters and creatures, cartoon style, D&D, world of warcraft, by Jimmy Wong and Greg Rutkowski, 4096x2160"
    },
    {
        "display": "David Bowie concept art.",
        "full": "David Bowie, concept art oil painting by Jama Jurabaev, extremely detailed, brush hard, artstation"
    },
    {
        "display": "Cute cat sleeping in sunset.",
        "full": "one cute cat sleeping on the grass in a forest near a small river at sunset, godrays, complementary colors, warm lighting, raytracing, highly detailed, high quality, 4k HDR, concept art, octane render, unreal engine 5, high coherence, calm, relaxing, beautiful landscape, serene, anatomically correct"
    },
    {
        "display": "Boy chasing bubbles.",
        "full": "a little boy with very short ginger hair wearing denim overalls chasing bubbles. clean elegant painting, beautiful detailed face, lots of bubbles. by norman rockwell and artgerm and greg rutkowski"
    },
    {
        "display": "Elegant hyena girl portrait",
        "full": "portrait of a hyena girl, furry body, large ears, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Isometric Arabic cityscape illustration",
        "full": "arabic city, isometric game, smooth 3 d illustration, cinematic matte painting, golden hour, soft render, servando lupini, handpaint texture, blender, 3 dcoat, concept art by noah bradley, darek zabrocki, tyler edlin, jordan grimmer, neil blevins, james paick, natasha tan, highly detailed, ultra detailed, ultra realistic, trending on artstation"
    },
    {
        "display": "Hyper-realistic photographer with magic.",
        "full": "hyper realistic photographer looking through a vintage medium format camera, magic pouring from lens, full body pose, design on white background, beautiful details, lush foliage cyberpunk, gold, drawn by john singer sargent, tom bagshaw, norman rockwell, alphonso mucha, lolish, trending on artstation"
    },
    {
        "display": "Elegant female vampire levitating.",
        "full": "Beautiful female vampire, bra and skirt, levitating above a pit of souls, Full body shot, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Authoritarian medieval city illustration",
        "full": "highly detailed concept art of authoritarian pearly white medieval city, roman architecture with religious iconography, sunbeams, art by jonathan berube, digital painting, fantasy, d & d, beautiful, illustration"
    },
    {
        "display": "Fairy prince casting lightning.",
        "full": "legendary fairy prince casting a lightning spell,, blue energy, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap"
    },
    {
        "display": "Red-eyed anime girl in flames.",
        "full": "red eyed anime girl, flames everywhere, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Iridescent female djinn robot.",
        "full": "hyperdetailed stunningly beautiful female djinn robot made of iridescent metals and shiny gems, long red hair, golden necklace, noble skeleton inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, bloody intricate, highly detailed, photorealistic, octane render, 8 k, unreal engine. dnd digital art by artgerm, perfect humanoid"
    },
    {
        "display": "Anime woman in fiery dress",
        "full": "3D advanced digital art, a very cute and gorgeous anime woman wearing a dress made of fire , full body, very long wavy Fire red hair, braided hair, red fiery eyes, full round face, cinematic lighting, mid-shot, highly detailed, trending on pixiv, Steven Artgerm Lau, WLOP, RossDraws, RuanJia , James Jean, Andrei Riabovitchev, Totorrl, Marc Simonetti, Visual Key, and Sakimichan"
    },
    {
        "display": "Noir femme fatale portrait",
        "full": "closeup portrait noir femme fatale by sabbas apterus and james jean, warm saturated colors, concept art, beautiful composition, digital painting, trending on artstation"
    },
    {
        "display": "Alien skull creature sleeping",
        "full": "a skull fauna alien sleep on alien planet land by neville page, ken barthelmey, carlos huante and doug chiang, sharp focus, trending on artstation, hyper realism, octane render, 8 k, hyper detailed, ultra detailed, highly detailed, zbrush, concept art, creature design"
    },
    {
        "display": "\"Epic spaceship migration\"",
        "full": "a several passenger spaceships in formation migrating in outer space away from a dry brown planet, dutch angle from space view, concept art, high detail, intimidating, epic scale ultrawide angle, stunning, epic, great migration, cinematic, Artstation trending, octane render, hyperrealistic, Cryengine 8k UHD"
    },
    {
        "display": "Masculine teenage girl portrait",
        "full": "portrait of a masculine teenage girl with blue eyes and brown hair, short men's haircut, with a big nose, smiling, wearing an oversized sweater, dramatic lighting, illustration by Greg rutkowski, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Bubbly pink unicorn portrait",
        "full": "A pink unicorn made of bubbles with sunglasses, synthwave style, fantasy, portrait, trending on artstation, detailed, award winning"
    },
    {
        "display": "Empty cantina interior illustration",
        "full": "ultra realistic illustration, empty cantina interior from star wars and star trek, intricate, elegant, highly detailed, nobodies, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Donkey drag queen illustration.",
        "full": "a donkey drag queen, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, photorealistic"
    },
    {
        "display": "\"Tuxedo Guy with Rubber Duck\"",
        "full": "a german very long light brown hair guy with glasses and very short goatee on a tuxedo holding a rubber duck!, trade offer meme, top text bottom text, art by greg rutkowski, intricate, elegant, sharp focus, digital painting, concept art, matte, art by artgerm"
    },
    {
        "display": "Fighter woman with wings",
        "full": "3 / 4 view of a portrait of a fighter woman with wings with wings, confident pose, genshin impact, digital art,, concept art, smooth, sharp focus, illustration, trending on artstation, highly detailed, concept art, trevor carlton, mickey demeo, trending on artstation, anime imax 7 0 mm, h 6 4 0"
    },
    {
        "display": "Isometric sci-fi RPG illustration",
        "full": "isometric Dead Space Diablo action role playing game by artgerm and greg rutkowski, alphonse mucha, cgsociety and beeple highly detailed, sharp focus, cinematic lighting, illustration, art, octane render, Unreal Engine Lumen, very coherent. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Symmetrical portrait of Lina.",
        "full": "symmetry full body portrait of lina, dota 2, global illumination. intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Devil portrait in toe shoes",
        "full": "portrait of the devil ( lucifer ) wearing toe shoes ( vibram five fingers ), digital art, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth and matte textures, sharp focus, illustration, annie leibovitz, greg rutkowski, alphonse mucha, karol bak, magalie villeneuve"
    },
    {
        "display": "Cyberpunk Office Characters",
        "full": "characters from the TV show The Office, cibercity, cyberpunk, vibrant, 70mm photography, Red camera, wide shot, dramatic lighting, hyperrealistic, high quality, highly detailed, artstation, HD, art by artgerm and greg rutkowski and alphonse mucha,  cinematic, unreal engine, facial accuracy, symmetrical"
    },
    {
        "display": "Melancholic surrealistic night scene.",
        "full": "complex dynamical systems infected by night by tom bagshaw in the style of a modern gaston bussiere, alphonse muca, victor horta, steichen. anatomically correct. extremely lush detail. masterpiece. melancholic scene infected by night. perfect composition and lighting. sharp focus. high contrast lush surrealistic photorealism."
    },
    {
        "display": "Scarlett Johansson, dynamic prison scene.",
        "full": "scarlett johansson in a glass prison, sweaty, dynamic action pose, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dapper 1920s Channing Tatum portrait",
        "full": "artwork by Michael Whelan, Bob Larkin and Tomer Hanuka, of a solo individual portrait of Channing Tatum wearing a 1920s red striped outfit, dapper, simple illustration, domestic, nostalgic, full of details, by Makoto Shinkai and thomas kinkade, Matte painting, trending on artstation and unreal engine"
    },
    {
        "display": "Cinematic Galata Tower Scene",
        "full": "a cinematic scene from the galata tower, by jordan grimmer and greg rutkowski, dramatic lighting, ultra hd, hdr, 8 k"
    },
    {
        "display": "Mystical tarot illustration.",
        "full": "beautiful tarot illustration of death, in the style of sam guay and, mystical colors, trending on artstation"
    },
    {
        "display": "Burnt Ghost Rider Plague Doctor",
        "full": "ghost rider as a plague doctor with the plague doctor mask half burnt off showing his face, beautiful artwork by artgerm and rutkowski, by akira toriyama, breathtaking, beautifully lit, dramatic lighting, full view"
    },
    {
        "display": "Kaiju goddess watercolor painting",
        "full": "a watercolor ink painting of a monstrous inhuman kaiju goddess of natural - disasters in the style of jean giraud in the style of moebius trending on artstation deviantart pinterest detailed realistic hd 8 k high resolution"
    },
    {
        "display": "Cyborg Samurai in Cyberpunk.",
        "full": "wide shot, photo of a Arnold Schwarzenegger, a huge humanoid cyborg with a katana, in a heavy futuristic armour, hyper realistic, hyper detailed, coherent photo, trending on artstation, cyberpunk, Japanese style, volumetric lightning, neon, electric colours, Cinematic, Dramatic, Film Grain, Kodak Portra, Depth of Field, 15 mm octane render, 4k, raytracing, blender"
    },
    {
        "display": "Cyberpunk robot woman portrait",
        "full": "portrait shot of a cyberpunk robot woman, in a forest, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "VFX portrait of Edward Newgate",
        "full": "highly detailed vfx portrait of edward newgate by eiichiro oda!!!, stephen bliss, greg rutkowski, loish, rhads, beeple, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, stanley kubrick, backlit, harsh overhead sunlight, detailed blue eyes!!,"
    },
    {
        "display": "Futuristic Arabian beauty portrait.",
        "full": "a beautiful arabian woman wearing a futuristic dress by alexander mcqueen, thom browne, junya watanabe, dzhus, ara jo,, gareth pugh, julia krantz, andrej pejic, winde rienstra, hussein chalayan, artgerm, arabian beauty, blue eyes, smile, futuristic, organic dress, pattern, concept art, fantasy"
    },
    {
        "display": "Epic portrait of survivor.",
        "full": "cinematic shot epic portrait an female survivor wearing pink bloody t shirt with bun tied hair, sweaty skin, wet hair, beauty, cute - fine - face, pretty face, realistic shaded perfect face, toned muscles, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation,"
    },
    {
        "display": "Rachel Green in flower field.",
        "full": "tv scene of rachel green from friends laying in a field of flowers, up close shot, highly detailed, sharp focused, ultra realistic digital concept art by Alyssa Monks, Charlie Bowater"
    },
    {
        "display": "Giant armored green man.",
        "full": "a picture of a giant armour green man with his fist up in the alien land, futuristic brown suit with helmet, low angle, trending on deviantart,, fantasy, intricate, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by john collier and albert aublet and krenz cushar"
    },
    {
        "display": "Charming chibi pig portrait.",
        "full": "a realistic and atmospheric watercolour fantasy character concept art portrait of a fat adorable dirty chibi pig wearing a wife beater and holding a rifle, by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Donald Trump, pink hair, boho chic.",
        "full": "portrait of donald trump with pink hair, boho chic. symmetry face, fine details. realistic shaded lighting, by sakimichan, kidmo, trending on pixiv, trending on artstation"
    },
    {
        "display": "Intricate dark embodiment portrait",
        "full": "portrait of the embodiment of darkness by Mandy Jurgens, Valentina Remenar, artgerm, trending on artstaton, intricate"
    },
    {
        "display": "Young scientist with black hole.",
        "full": "worksafe. insanely detailed. by wlop, ilya kuvshinov, makoto shinkai, cushart krentz, greg rutkowski, pixiv. zbrush sculpt, octane, maya, houdini, vfx. a beautiful young female scientist in a laboratory, holding a small black hole in hands, ponytail. elegant, sharp focus, morandi color scheme, illustration, high quality, digital art"
    },
    {
        "display": "\"Firebending Battle Scene\"",
        "full": "azula versus zuko, firebending, lightning, red tint, agni kai, character, cg animation, riot entertainment, arcane, realistic, fight, battle scene, fireball, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Heavenly prodigal son artwork",
        "full": "the prodigal son returns, by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Lush flowers in vase",
        "full": "lush flowers in a crystal vase by jeremy mann and greg rutkowski, beautiful cinematic lights, trending on artstation, oil on canvas"
    },
    {
        "display": "Electrocuted Roman Sticker",
        "full": "ancient roman doing finger guns while being electrocuted, sticker, digital art, trending on artstation"
    },
    {
        "display": "Fantasy yukata festival scene",
        "full": "two beautiful fashion taiwan girls wear fantasy yukata in festival | | big eyes, sunny, dreamlike art, realistic shaded, smile, good looking, fine details, 4 k realistic, cryengine, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Detailed Iron Man illustration",
        "full": "long camera shot camera shot of marvel comics character, white superior iron man, full of details, digital illustration, concept art, smooth, by Ina Wong and wlop \uff0ctrending on cgsociety and artstation\uff0c8kHDR\uff0clight effect"
    },
    {
        "display": "Iron goddess portrait",
        "full": "a beautiful portrait of a iron goddess by greg rutkowski and raymond swanland, trending on artstation, ultra realistic digital art"
    },
    {
        "display": "Cute anthropomorphic goblins portrait",
        "full": "anthropomorphic highly detailed wide - angle group portrait of cute mr bean goblins looking funny, intricate, elegant, highly detailed goblin, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Lofi monkey reflecting human face",
        "full": "lofi monkey in front of a mirror, reflecting the expression of a human face, Pixar style by Tristan Eaton Stanley Artgerm and Tom Bagshaw, high detail, elegant, concept art"
    },
    {
        "display": "Elon Musk as Neo.",
        "full": "elon musk as neo from the matrix, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant portrait of Marilyn Monroe.",
        "full": "portrait of marilyn monroe, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Tuxedoed man with rubber duck",
        "full": "a german very long light brown hair guy with glasses and very short goatee on a tuxedo holding a rubber duck!, trade offer meme, top text bottom text, art by greg rutkowski, intricate, elegant, sharp focus, digital painting, concept art, matte, art by artgerm"
    },
    {
        "display": "Stylized portrait of man.",
        "full": "50 year old greying brunette man with very short hair, buzz cut,  round face, square face, round jaw, wide chin , romanian, silver small glasses,  romanian heritage, brown eyes, brown eyes, olive skin, round nose, round chin, clean shaven wide face, thin lips, digital art, concept art, cgsociety, painterly, painting, 8k, illustration, painting, dramatic,  beautiful, art by loish loish loish, cartoon, stylized  painterly, trending on artstation, medium shot, uncropped"
    },
    {
        "display": "Androgynous cat seahorse fursona",
        "full": "cat seahorse fursona wearing headphones, autistic bisexual graphic designer and musician, attractive androgynous humanoid, coherent highly detailed character design, weirdcore voidpunk digital art by artgerm, akihiko yoshida, louis wain, simon stalenhag, wlop, furaffinity, cgsociety, trending on artstation, trending on deviantart"
    },
    {
        "display": "Lifelike sorceress illustration",
        "full": "amazing lifelike award winning pencil illustration of sorceress edea from final fantasy 8 ff 8 trending on art station artgerm greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Elegant anime character portrait",
        "full": "anime art full body portrait character concept art, anime key visual of elegant young female, light orange - red hair, straight bangs and large yellow eyes, with swirls, white formal shirt and tie, finely detailed perfect face delicate features directed gaze, laying down in the grass at sunset in a valley, trending on pixiv fanbox, studio ghibli, extremely high quality artwork, chainsaw man, fujimoto"
    },
    {
        "display": "Sci-fi Shaman King design",
        "full": "ultra detailed scifi design of the shaman king in a generative 3D style, Grotesk font, Graphic Design, uppercase letter, fungal, mycelium, intricate concept art, triadic color scheme, by alphonse mucha and Mandelbrot, octane render, 4k"
    },
    {
        "display": "Epic white ogre portrait",
        "full": "dungeons and dragons white ogre character closeup side profile portrait, dramatic light, epic background, 2 0 0 mm focal length, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, high detail, real life skin, freckles, 8 k, stunning detail, works by artgerm, greg rutkowski and alphonse mucha, unreal engine 5, 4 k uhd, digital art, trending on artstation"
    },
    {
        "display": "Charon in dramatic lighting",
        "full": "Charon the ferryman of Hades, by Rolf Armstrong, dramatic lighting, high contrast colors, low angle view, as trending on Artstation, highly detailed,"
    },
    {
        "display": "Pac-Man Live Action Poster",
        "full": "pac man live action movie poster concept art, 8 k photorealistic, hd, high details, trending on artstation"
    },
    {
        "display": "Furry acid-spitting creature",
        "full": "a mythical creature covered in fur and spitting caustic acid, fantasy, elegant, digital painting, artstation, concept art, matte, sharp focus, illustration, art by philippe druillet"
    },
    {
        "display": "Elven goddess portrait.",
        "full": "elven goddess of dreams | | realistic shaded, fine details, fine - face, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin, michael garmash, rob rey"
    },
    {
        "display": "CalebCity Portrait, Matte Art",
        "full": "portrait of calebcity, matte painting by artgerm, artstation"
    },
    {
        "display": "Portrait of an unconventional fantasy woman.",
        "full": "full portrait of a young, ugly woman, rough features, warts, long hook nose, soft hair, muscular, half body, cloth, pink hair, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Smiling toddler in park.",
        "full": "a beautiful [[[smiling]]] little black toddler girl with short curly hair, at the park on a beautiful day, holding a round pink stuffed animal, by Artgerm, Mucha Klimt, Hiroshi Yoshida and Craig Mullins, featured on Artstation, CGSociety, Behance HD, Deviantart"
    },
    {
        "display": "Satanic church, desert, red moon",
        "full": "Satanic church on a desert, red moon, by Dariusz Zawadzki and zdzisaw beksinski, imax film quality, trending on Artstation"
    },
    {
        "display": "Dwarf Druid in Swamp",
        "full": "Scared High Fantasy Dwarf Druid running through Haunted Swamp, RPG Portrait Reference, Oil Painting, Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Infected miner in sci-fi.",
        "full": "scifi art by Greg Rutkowski, a person infected with a kind of reddish sienum that is sprouting from all over his body, violent and vicious appearance, dressed in a futuristic mining space suit, claustrophobic science fiction setting inside a desolate space colony, detailed and intricate environment, high technology, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Medieval village at dawn.",
        "full": "breathtaking oil painting with palette knife, a medieval village landscape in luxurious nature, with intricate art nouveau moody dark tumultuous clouds, at dawn with roses and golden petals flying, rembrandt style, concept art, matte, palette knife painting, by georgia hart,"
    },
    {
        "display": "Gollum family in Middle-earth",
        "full": "a dramatic highly detailed render of Gollum family , Middle-earth , by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, Beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, Artstation, concept design art, Octane render, 8K, masterpiece, sharp focus, hyperrealistic"
    },
    {
        "display": "Mechanical bear in forest",
        "full": "a beautiful painting of a mechanical bear walking through a boreal forest, world of warcraft concept art"
    },
    {
        "display": "Shrek as Agent 007",
        "full": "shrek as agent 0 0 7, portrait, artstation, concept art by greg rutkowsk"
    },
    {
        "display": "Anime-style Paul Blart reading.",
        "full": "paul blart, reading book | very very anime!!!, fine - face, audrey plaza, realistic shaded perfect face, fine details. anime. realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo ghost - in - the - shell, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Grungy woman with rainbow hair.",
        "full": "a grungy woman with rainbow hair, soft eyes and narrow chin, dainty figure, long hair straight down, torn overalls, short shorts, combat boots, side boob, wet tshirt, raining, basic white background, symmetrical, watercolor, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm,"
    },
    {
        "display": "Scarlett Johansson vampire artwork.",
        "full": "rafael albuquerque comic art, peter mohrbacher, steve niles, artgerm, pretty scarlett johansson vampire sharp vampire teeth open mouth, symmetrical eyes, black leather jacket, jeans, long blonde hair, full body"
    },
    {
        "display": "Detailed female android portrait",
        "full": "ultra detailed portrait of a female android, eyes closed, sci - fi, moody, calm, ( dia de los muertos ), asymmetrical, intricate concept art, art by artgerm and giger and michael welan and alphonse mucha and loish and wlop"
    },
    {
        "display": "Lovecraftian investigators concept art",
        "full": "painting of the figures of three investigators in a lovecraftian scenery, high contrast, concept art, dramatic lighting, digital art, 8 k, arkham city, call of cthulhu, extremely detailed, drawn by ruan jia"
    },
    {
        "display": "Cyberpunk concept art",
        "full": "concept art of molly millions in sprawl, dark hair, neuromancer characters, mirrorshades, cyberpunkish, extensive cybernetic modifications, retrofuturistic, cybernetic claw blades in fingers, tech noir, matrix trinity, darker shades, stimulus satiation, high tech low life, sprawl, an ultrafine detailed painting, gloomhaven, matte painting concept art, art nouveau, beautifully backlit, swirly vibrant color lines, muted and dull colors, aesthetic octane render, 8 k hd resolution, by ilya kuvshinov and cushart krentz and gilleard james"
    },
    {
        "display": "Donkey-themed superhero illustration",
        "full": "a donkey themed superhero by artgerm"
    },
    {
        "display": "Serene android in nature",
        "full": "biomedical design of an attractive serene  android,  natural background out of focus,   cinematic lighting, intricate, elegant, super highly detailed, art station, concept art, smooth, sharp focus, no blur, no dof, extreme illustration,  Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, delicate, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Lofi monkey reflecting anger",
        "full": "lofi monkey in front of a mirror reflecting anger facial expression of a human face, symmetrical hands, old mirror, Pixar style by Tristan Eaton Stanley Artgerm and Tom Bagshaw, high detail"
    },
    {
        "display": "Vengeful female revenant",
        "full": "female revenant, undead, grey tarnished longcoat, vengeful, floating, no legs, high fantasy, steampunk, monochromatic, detailed face, highly detailed, smooth, shrap focus, matte digital illustration, by koyorin, donato giancola, pixiv"
    },
    {
        "display": "Teenage girl silhouette, futuristic city.",
        "full": "the sillhouette of a teenager girl with a hat, looking at a a beautiful and immaculate futuristic city night time, blue and pink lights. synthwave ombre rendering. trending on artstation. recommended for you behance. by chris moore. by edward hopper. beeple colors. metropolis filmic."
    },
    {
        "display": "Hyper-realistic pirate ship scene",
        "full": "pirates ship in the sea bysintine, gothic, 4 k, smokey, atmospheric, environment, 4 k detail post processing, hyperealistic, unreal engine 5 render, photo realism ole thomas style, giovanni paolo panini style epic, volumetric light, symmetrical, insanely detailed, style of charlie bowater, kelly mckernan, unreal engine render, artstation trends, hyper detail, epic art style, cinematic, concept art"
    },
    {
        "display": "Chibi monk on country road",
        "full": "a study of cell shaded cartoon of an adorable chibi monk on a country road, japanese temples, street lamps, road, illustration, wide shot, subtle colors, post grunge, concept art by josan gonzales and wlop, by james jean, victo ngai, david rubin, mike mignola, laurie greasley, highly detailed, sharp focus, alien, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Futuristic offroad SUV design",
        "full": "a futuristic offroad suv by artgerm and greg rutkowski and alphonse mucha, zaha hadid, an epic fantasy, volumetric light, detailed, trending on art station, octane render, horizon forbidden west"
    },
    {
        "display": "Fantasy model portrait",
        "full": "close up portrait of a unsplash portrait model, soft hair, half body, leather, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant, detailed portrait.",
        "full": "a gorgeous female photo, professionally retouched, vivid, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, wide angle, sharp focus on eyes, 8 k, high definition, insanely detailed, intricate, elegant, artgerm and jason chan and mark litvokin"
    },
    {
        "display": "Mechanical angel portrait.",
        "full": "portrait of mechanical angel, beautiful girl, intrigante, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Psychedelic clock portrait",
        "full": "An extremely psychedelic portrait of a clock, surreal, LSD, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Intricate metal angel symbol.",
        "full": "Old angel symbol of archangel Gabriel. Symbol made out of metal. Cooper lining ,intricate, elegant, highly detailed, digital painting,  artstation, concept art, smooth, sharp focus, illustration, art by Ilja Repin"
    },
    {
        "display": "Roman statue draped in red.",
        "full": "a roman statue covered by red cloth that's blowing in the wind, digital art, concept art, cloth simulation with houdini, octane, redshift, 8 k"
    },
    {
        "display": "Rainbow-haired girl portrait",
        "full": "an intelligent beautiful young girl with rainbow hair looking at the camera, she has a cute face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by mark ryden and lucian freud 3 d 8 k ultra detailed"
    },
    {
        "display": "Dinosaur-themed medieval market.",
        "full": "\u201ca dinosaur market, similar to Jurassic World: Dominion, medieval, science fantasy, D&D, fantasy, intricate, cinematic lighting, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha\u201d"
    },
    {
        "display": "Gerard D\u00e9pardieu as Super Mario",
        "full": "Portrait of Gerard D\u00e9pardieu as Super Mario, cap, fantasy, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by Tony Sart and artgerm and randy vargas"
    },
    {
        "display": "Portrait of exhibitionist in park",
        "full": "a highly detailed beautiful portrait of an exhibitionist in a raincoat in the park, painting by greg rutkowski photo by angelique boissiere art streiber, photorealistic, hyperdetailed, canon eos r 3, cinematic, filmic"
    },
    {
        "display": "Divine feminine portrait.",
        "full": "beautiful feminine face! portrait of young woman blessed by god with ever - increasing physical and mental perfection, blonde hair, symmetrical! intricate, elegant, highly detailed, vision of holy perfection!! smile, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Goddess Pharaoh Wizard Portrait",
        "full": "an anthropomorphic beautiful goddess female pharaoh wizard portrait holding a crystal wearing robe made of sands in a sandstorm, dreadlock breed hair, fine art, award winning, intricate, elegant, sharp focus, octane render, hyperrealistic, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by jamie hewlett and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Elven princess meditating, glowing.",
        "full": "glowing cracks, elven princess, meditating, peaceful, levitating, powerful, blossoming, lotus pose, zen, glowing, fractal background, ascending, detailed, realistic, digital art, fantasy, trending on artstation, cinematic, movie clip, visionary art, intricate pattern, subtle pattern, detailed texture, fractal texture, flowing, engraved texture, sacred geometry pattern, symmetry, perfect, perfect face, facial beauty, pretty, attractive, by peter morhbacher"
    },
    {
        "display": "Female plague doctor in armor.",
        "full": "female plague doctor donning a black hood, armor and a white crow mask, trending on artstation"
    },
    {
        "display": "Black-and-white portrait, woman.",
        "full": "full body portrait of a beautiful woman in black and white, photorealistic, hair down to waist, in the style of Kevin Kostic, art by diego fazio and diegoKoi and artgerm, hyper sharp focus, 8k highly detailed"
    },
    {
        "display": "Cozy anime portrait, sad schoolgirls.",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai. beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. beautiful cutest sad face. dramatic light, trending on artstation, oil painting"
    },
    {
        "display": "Retrofuturistic assassin portrait",
        "full": "a baroque portrait of a retrofuturistic assassin surrounded by advanced architecture. highly detailed science fiction, rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation an ultrafine hyperdetailed colorfull illustration by kim jung gi, moebius, irakli nadar, alphonse mucha, ayami kojima, amano, greg hildebrandt, syd mead and mark brooks, female, feminine, art deco, new baroque, intricate linework, colors by frank frazetta"
    },
    {
        "display": "Ethereal Moonbow King Portrait",
        "full": "portrait of the moonbow king, white glowing eyes, cloak, ethereal, male, foggy, fantasy, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, stunning lighting, art by artgerm and greg rutkowski, alphonse mucha and simon stalenhag, realistic character concept, high fantasy, light atmosphere, golden ratio, rule of third, cinematic lighting, hyperdetailed, high resolution, insanely detailed and intricate, artstation, marc simonetti, greg rutkowski, 8 k"
    },
    {
        "display": "Cyberpunk woman portrait.",
        "full": "portrait of a beautiful woman wearing a cyberpunk armor, drenched body, wet dripping hair, emerging from the water, fantasy, regal, fractal crystal, fractal gems, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwell ross tran"
    },
    {
        "display": "Wolverine art nouveau portrait",
        "full": "side profile centered painted portrait, wolverine, matte painting concept art, art nouveau, beautifully backlit, swirly vibrant color lines, fantastically gaudy, aesthetic octane render, 8 k hd resolution, by ilya kuvshinov and cushart krentz and gilleard james"
    },
    {
        "display": "Cyberpunk Normani Portrait",
        "full": "cyberpunk Normani as aeon flux profile picture by Greg Rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell, metal chrome, shiny, rainy background, asymmetric, afro hair,"
    },
    {
        "display": "Anime catgirl portrait",
        "full": "realistic anime movie poster portrait photo : : of yerin baek as a catgirl by weta, marvel : : by wlop, ilya kuvshinov, rossdraws, artgerm, artstation, unreal engine : : rave makeup, pearlescent, morning, vogue cover : :"
    },
    {
        "display": "Cyberpunk girl portrait.",
        "full": "very cool girl white hair girl with mask, streetwear, techwear, cyberpunk style outfit, full body, nose piercing, detailed portrait, intricate complexity, by greg rutkowski, james gilleard, atey ghailan, artgerm, ross tran, conrad roset, takato yomamoto, ilya kuvshinov. 4 k, beautiful, cinematic dramatic atmosphere"
    },
    {
        "display": "Surreal Alice in Wonderland Tunnel",
        "full": "vfx surreal 3 d portrait of alice from wonderland walking into a non - euclidean and infinite tunnel of evanescent hallucinatory images, reflections in endless mirrors, giant mushrooms, hyperdetailed, octane render, sharp focus, concept art, intricate by alex grey, greg rutkowski jeff soto and daniel merriam, dan mumford and pixar, octane render"
    },
    {
        "display": "Zombie female hip-hop model",
        "full": "zombie full body female modeling hiphop streetwear drip, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Portal Master Tarot Card",
        "full": "The portal master tarot card by Tomasz Alen Kopera and Salvador Dali, impressive perspective, masterpiece, 8k, dynamic lighting, Highly Detailed, trending on artstation"
    },
    {
        "display": "Hyperrealistic fantasy portrait.",
        "full": "hyperrealistic portrait of a woman under starry night sky among noir lanterns wearing fishnets, fantasy art, photo realistic, dynamic lighting, artstation, volumetric lighting, very detailed face, 4 k, award winning, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Girl hugging Cosmo the Spacedog",
        "full": "girl hugging cosmo the spacedog by charlie bowater and titian and artgerm, full body portrait, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Elderly woman character portrait.",
        "full": "overwatch concept art character portrait of a new character who is an elderly pale woman with a scarred face, trending on artstation, cgsociety,"
    },
    {
        "display": "Ullambana Festival Lanterns",
        "full": "concept art, river lanterns on the eve of ullambana festival, high resolution, by james gurney, king sejong, yi jeong, yi jing, artstation"
    },
    {
        "display": "Elegant Superman portrait",
        "full": "a portrait of superman, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Ryan Reynolds as Punisher.",
        "full": "rayan reynolds as punisher, portrait, skull on the chest, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Teen girl in leaf dress",
        "full": "teen girl in dress made of leaf, realistic portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha and boris vallejo and frank frazetta"
    },
    {
        "display": "Retro sci-fi heroine poster",
        "full": "young Jennifer Connelly  as a ruggedly beautiful retro SCI-FI space heroine 1985 , movie poster, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and donato giancola and Joseph Christian Leyendecker, Ross Tran, WLOP"
    },
    {
        "display": "Cyborg woman portrait, detailed",
        "full": "a closeup portrait of a disassembled cyborg woman, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Stoic Fantasy Portraits",
        "full": "dual portrait of a stoic looking john oliver and adam driver, full body, military uniform, fantasy, intricate, elegant, beautiful, highly detailed, charcoal, centered, dark, smokey, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Royal Cat Portrait",
        "full": "an oil painting portrait of a long haired fluffy black Cat wearing medieval royal robe and an ornate crown on a dark space background digital Art, concept Art, highly detailed, 3-D 4K, trending on art station, Mark Brooks,"
    },
    {
        "display": "Holographic alien artifacts display",
        "full": "Terminator in holograms of alien artifacts, electrical case display, total recall tech, , ultrarealistic, dramatic lighting, electrical details, high details, 4k, 8k, best, accurate, trending on artstation, artstation, photorealism, ultrarealistic, digital painting, style of Tristan Eaton Stanley Artgerm and Hajime Sorayama, Caravaggio, Boris Vallejo"
    },
    {
        "display": "Symmetrical husky puppy portrait",
        "full": "symmetry!! portrait of husky puppy, sci - fi, sun glasses, orange beanie, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Favela spaceship cathedral in space",
        "full": "favela spaceship cathedral, outer space environment, scenery, professional, award - winning, trending on artstation, hyper detailed, realistic, beautiful, emotional, shiny, colorful, picture"
    },
    {
        "display": "Pregnant Scarlett Johansson portrait.",
        "full": "pregnant scarlet johansson in a red dress, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cell-shaded Marty McFly portrait",
        "full": "a study of cell shaded portrait of marty mcfly concept art, llustration, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00ef\u00bf\u00bdn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, alien, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Porcelain cyborg exoskeleton",
        "full": "porcelain cyborg, Chinese Blue and white porcelain exoskeleton 14th century, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Mystical colorful landscape.",
        "full": "luscious fibrous world made of mystical magical energy colorized as blue, red, and purple, illustrated by Greg Rutkowski and Gaston Bussiere, loquacious lighting, volumetric lighting, beautiful photography, landscape imagery, Trending on artstation, 4k, 8k."
    },
    {
        "display": "Iridescent sci-fi armored male",
        "full": "iridescent wiry muscular male smooth sleek glossy black pearlescent scifi armor with smooth black featureless visor, by greg rutkowski and mark brookes and jim burns and tom bagshaw and magali villeneuve, trending on artstation"
    },
    {
        "display": "\"Enchanting girl at sunset\"",
        "full": "front view, gorgeous girl looking forward into the camera, symmetrical face, short red hair, crop top, daisy dukes shorts. magical, lights, sunset, mysterious, serene, sunlight, ocean, flowing, floating water, splashing water, glowing, mystical, mysterious, lights, bokeh. by art germ, trending on artstation, unreal engine, octane render, cinematic, ultra detailed, 4 k"
    },
    {
        "display": "Intricate winter garden masterpiece",
        "full": "a beautifull intricate winter garden with many flowers, reflexions, verry high details by william turner art, greg rutkowski and alphonse mucha, trending on artstation, very very detailed, masterpiece, - h 7 0 4"
    },
    {
        "display": "Scorpion Queen Portrait",
        "full": "symmetry!! portrait of scorpion queen in the style of horizon zero dawn, machine face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Steampunk Hephaestus illustration.",
        "full": "the greek god hephaestus, brown hair, holding hammer, steampunk, beautiful glowing eyes, volumetric lights, red and orange theme, art nouveau botanicals, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, cinematic, illustration, beautiful face, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Colorized epic portrait",
        "full": "portrait of don pelayo, colourised, face portrait, epic, tragic, military art, fantasy, dieselpunk, hd shot, digital portrait, beautiful, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Elegant portrait of Christina Hendricks.",
        "full": "portrait shot of christina hendricks in bladerunner wearing a white dress, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Eyrie in Game of Thrones",
        "full": "the eyrie, game of thrones, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Androgynous portrait with gems",
        "full": "hyperdetailed closeup portrait of a stunningly beautiful european young girl androgynous guard wool made of iridescent metals brown gems, bright rainbow nimbus, golden necklace, inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, photorealistic, octane render, rtx, hdr, unreal engine, dnd digital art by artgerm"
    },
    {
        "display": "Cool dog in sunglasses.",
        "full": "cool dog wearing sunglasses by  MICHAEL DIVINE and by AMANDA SAGE in the style of oil painting visionary art,  oil painting artwork. , trending on artstation, very coherent symmetrical artwork, oil painting"
    },
    {
        "display": "Symmetrical sci-fi portrait.",
        "full": "symmetry!! portrait of smiling jerma as a terminator, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Galactic Alliance soldier portrait",
        "full": "Headshot of the soldier from the Galactic Alliance by Ben Mauro, Star Wars Universe, scifi, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ"
    },
    {
        "display": "Talking oak tree portrait",
        "full": "a talking oak tree, a face in the bark, nose made of wood, eyes in the bark, fantasy concept art, leaves and moss, digital painting, oil painting, hyperrealistic, beautiful, treebeard, ent, highly detailed, soft lighting, golden sunlight, very detailed eyes, artstation, cgsociety, in the forest, by alan lee, by artgerm"
    },
    {
        "display": "Steampunk portrait of handsome male.",
        "full": "beautiful portrait of a incredibly handsome young male wearing a simple ( blue tunic ), and a gold medallion!! around neck, art by wlop and artgerm, steampunk fiction, detailed deep blue eyes, elongated face, shaved!!, round nose, ( ( dark brown hair in ponytail!!! ) ), space background, trending on artstation, sharp focus, illustration, caustics, octane render, 4 k, radiant light"
    },
    {
        "display": "Noble boy portrait, medieval.",
        "full": "half - length portrait of a 3 5 - year - old human male noble with long blonde hair and green eyes, mustache, no beard, medieval setting, detailed face, highly detailed, artstation, concept art, sharp focus, by greg rutkowski, craig mullins, artgerm"
    },
    {
        "display": "Mystical sacred sunflowers",
        "full": "sacred sunflowers, emiting mystical light, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, radiant light, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyperrealistic Silent Hill scene",
        "full": "a photo of 8k Silent Hill towns and point of interests, cinematic lighting, trending on artstation, 4k, hyperrealistic, focused, extreme details, unreal engine 5, cinematic, masterpiece, high resolution, detailed"
    },
    {
        "display": "Vaporwave aesthetic girl illustration",
        "full": "!dream beautiful young girl vaporwave aesthetic, synthwave, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha 800t"
    },
    {
        "display": "Goblin King concept art",
        "full": "concept art for the movie labyrinth goblin king"
    },
    {
        "display": "Cat in Ironman suit.",
        "full": "cat in an ironman suit, concept art, highly detailed art, god rays, dramatic pose, marvel, MCU, digital painting, artstation"
    },
    {
        "display": "Futuristic architecture landscape",
        "full": "highly detailed futuristic architecture by frank lloyd wright and antoni gaudi, reflective lighting, holographic spheres, stylized vegetation, ground - level view, puddles of turquoise water, foggy atmosphere, stunning sunny lighting, sunrise, vivid colors, in the style of pixar animation, trending on artstation, 8 k, matte painting, ray tracing, hyper detailed, unreal engine 5, cinematic, epic lighting, cryengine, octane render, cyberpunk, red and orange glow, vibrant"
    },
    {
        "display": "Cyborg character sheet design",
        "full": "four arms futuristic distopic detroy cyberpunk hellraiser tattoo cyborg  clean design by apple , character sheet, Moebius, Greg Rutkowski, Zabrocki, Karlkka, Jayison Devadas, Phuoc Quan, trending on Artstation, 8K, tsutomu nihei, muira, mucha , zenith view, pincushion lens effect."
    },
    {
        "display": "Young male swashbuckler portrait",
        "full": "a young male swashbuckler wearing a black sleeping cap with a puffball, holding a candle, wearing a flowing cape, portrait, d & d, science fiction, concept art, matte, sharp focus, illustration, concept art, jason chan, dan luvisi, karl thiart, uhd, 4 k, very detailed"
    },
    {
        "display": "Fantasy cat-dog hybrid",
        "full": "a cat with the head of a dog and a body of a dog, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg grizzly bear DJ",
        "full": "a cyborg grizzly bear dj mixing records on stage, photorealistic, highly detailed, illustration, lifelike, highly detailed, intricate, octane render, sharp focus, cyberpunk"
    },
    {
        "display": "Woman emerging from ocean.",
        "full": "A woman merging out of the ocean, masterpiece digital painting by Alex Grey, Greg Rutkowski, artstation, 4k wallpaper"
    },
    {
        "display": "Cinematic concept art of Nazar\u00e9",
        "full": "concept art by jama jurabaev, cel shaded, cinematic shot, trending on artstation, high quality, brush stroke, vibrant colors, nazare portugal"
    },
    {
        "display": "Cute Akihabara maid illustration",
        "full": "cute friendly akihabara maid by charlie bowater and titian and artgerm, intricate, face, japanese street, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Detailed portrait of Justinian",
        "full": "portrait of emperor justinian highly detailed, digital painting, concept art, sharp focus, by makoto shinkai"
    },
    {
        "display": "Cyberpunk apartment interior design",
        "full": "cyberpunk apartment interior overview mapa design, Moebius, Greg Rutkowski, Zabrocki, Karlkka, Jayison Devadas, Phuoc Quan, trending on Artstation, 8K, ultra wide angle, pincushion lens effect."
    },
    {
        "display": "Dark vampire lair interior.",
        "full": "dark sinister vampire lair interior by Bastien Lecouffe-Deharme, library, adventure game, inspired by Diablo concept art"
    },
    {
        "display": "3D anime girl beach",
        "full": "render as a very beautiful 3d anime girl, long braided purple hair, azure blue eyes, full round face, short smile, casual clothes, serene beach setting, cinematic lightning, medium shot, mid-shot, highly detailed, trending on Artstation, Unreal Engine 4k, cinematic wallpaper"
    },
    {
        "display": "Elven palace in fantasy garden.",
        "full": "a beautiful and highly detailed matte painting of an elven palace in a magical fantasy forest garden, psychedelic flowers and trees, strange vegetation, epic scale, insanely complex, hyperdetailed, sharp focus, hyperrealism, artstation, cgsociety, 8 k, bright colors, by caspar friedrich, albert bierstadt, james gurney, brian froud,"
    },
    {
        "display": "Gigachad Luigi Mech Portrait",
        "full": "gigachad luigi in a mech space suit by ilya kuvshinov, ernest khalimov body by krista sudmalis, fantasy character portrait, ultra realistic, concept art, intricate details, elegent, digital painting, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Anthropomorphic comedic monster portrait.",
        "full": "anthropomorphic d 2 0 comedic genius head in opal darkiron mr. bean grey wide head, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Neon tech noir portrait.",
        "full": "duotone neon illustration 3 / 4 portrait of molly millions. badass woman with silver sunglasses eyes, claw blades in fingers, trinity matrix, dark tech noir volumetric lighting. dynamic composition. by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. concept art. trending on artstation"
    },
    {
        "display": "Red marble Jesus statue",
        "full": "high detailed forest a statue jesus on cross made of red marble, perfect symmetrical body, full body shot, inflateble shapes, white biomechanical details, wearing epic bionic cyborg implants, masterpiece, intricate, biopunk, vogue, highly detailed, artstation, concept art, cyberpunk, octane render"
    },
    {
        "display": "Alien Cthulhu Boy Portrait",
        "full": "a baroque neoclassicist close - up side profile portrait of a colorful retrofuturistic blacklight uv silhouette of an alien cthulhu boy with a baseball hat and glasses, glowing fog in the background. renaissance portrait painting. highly detailed science fiction painting by norman rockwell, frank frazetta, and syd mead. rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation"
    },
    {
        "display": "Lonely cowboy artwork.",
        "full": "the lonely cowboy, red dead redemption 2, extremely detailed digital painting, in the style of fenghua zhong and ruan jia and jeremy lipking and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Cyborg nymph in pink milk",
        "full": "hyperrealistic solarpunk photography of a highly detailed and symmetrical gorgeous cyborg nymph awash in a sea of pink milk in the style of beth cavener, jin kagetsu, james jean and wlop, highly detailed, face symmetry, masterpiece, award - winning, sharp focus, intricate concept art, ambient lighting, 8 k, artstation"
    },
    {
        "display": "Frostpunk warrior portrait",
        "full": "portrait of a frostpunk warrior, half body, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Levitating monk in mist.",
        "full": "a hyperrealist watercolour concept art of a monk levitating a huge rock over his head. it is a misty night on the moors of ireland. by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Realistic female portrait, elegant.",
        "full": "photo of a female with long black hair in the style of stefan kostic, realistic, body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, floating embers"
    },
    {
        "display": "Regal water mage portrait.",
        "full": "portrait of a beautiful man wearing a black robe, holding a long staff, drenched body, wet dripping hair, emerging from the water, fantasy, regal, fractal crystal, fractal gems, by stanley artgerm lau, thomas kindkade, alphonse mucha, loish, norman rockwell ross tran"
    },
    {
        "display": "Ethereal fantasy portal scene",
        "full": "ethereal mystery portal, seen by wanderer boy in middle of woods, vivid colors, fantasy, trending on artstation, artgerm, cgsociety, greg rutkwoski, alphonse mucha, unreal engine, very smooth, high detail, 4 k, concept art, brush strokes, pixiv art, sharp focus, raging dynamic sky, heavens"
    },
    {
        "display": "Dionysus portrait, digital art",
        "full": "dionysus, greek god, robert sheehan, tunic, lounging, sly expression, art by artgerm and greg rutkowski and magali villeneuve, d & d, fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration"
    },
    {
        "display": "Cute alien bird artwork.",
        "full": "epic professional digital art of an improbable impossible fuzzy adorable, cute and cuddly neotenic avian alien, loop lighting, full body length shot, best on artstation, breathtaking, epic, stunning, gorgeous, much detail, much wow, cgsociety, wlop, pixiv, behance, deviantart, masterpiece, UHD, 8K"
    },
    {
        "display": "Detailed digital portrait of Nova",
        "full": "Nova, richard rider, marvel comics, highly detailed, digital painting, artstation, facing camera, concept art, smooth, sharp focus, illustration, art by artgerm and alphonse mucha, high definition digital art, dramatic lighting, in the style of ilya kuvshinov and Ross tran"
    },
    {
        "display": "Hellish concept art.",
        "full": "doom eternal concept art by brueghel, mcu movie still frame of hell scene by jakub rozalski, garden of eternal delights hell by hieronymus bosh"
    },
    {
        "display": "Fantasy portrait: Gordon Ramsay vs. giant eagle.",
        "full": "fantasy character portrait of gordon ramsay fighting a giant eagle peter mohrbacher, wadim kashin, greg rutkowski, larry elmore, george pemba, ernie barnes, raymond swanland, magali villeneuve, trending on artstation"
    },
    {
        "display": "Gloomy portrait of woman.",
        "full": "a half profile Concept art portrait of a young woman with floating hair against a gloomy background in the style of max schulz xoco1 trending on artstation,"
    },
    {
        "display": "Count Orlok in Hussars Armor",
        "full": "full body concept art of Count Orlok wearing Polish Winged Hussars armor made with porcelain by Jeff Easley and Peter Elson + beautiful eyes, beautiful face + symmetry face + galaxy + gothic, surreal, dread + highly detailed, intricate complexity, epic composition, magical atmosphere + masterpiece, award winning + trending on artstation + shallow depth of field"
    },
    {
        "display": "Desert soldier, surreal skyline.",
        "full": "detailed concept art background studio ghibli, soldier roaming through desert with city in the skyline, two suns, purple orange colors, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski, masterpiece"
    },
    {
        "display": "Iridescent-robed warrior illustration",
        "full": "beautiful man, wearing iridescent sleeveless robes, holding a sword, full - body tattoos, jewelry, 8 k, glow, detailed and intricate, ornate, illustration, by tom bagshaw, artgerm, greg rutkowski, octane render"
    },
    {
        "display": "Angel portrait with bones.",
        "full": "Full body potrait of Melissa McCarthy as and angel , angel is partly draped with bones, angel,is split in two with smoke, fantasy, intricate, elegant, highly detailed, digital painting,  artstation, woman is curved, concept art, smooth, sharp focus, illustration, art by Ilja Repin"
    },
    {
        "display": "Regal blue wolf with wings.",
        "full": "blue wolf with wings, facing front, regal, elegant, winter, snow, beautiful, stunning, hd, illustration, epic, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wallpaper, art by artgerm and greg rutkowski and alphonse mucha and jin xiaodi"
    },
    {
        "display": "Young woman portrait and monster.",
        "full": "portrait of a young woman with bangs, wavy brown hair and bangs, wearing round thin - rimmed glasses and a leather jacket, she has a big, round jawline,! dream a giant monster with many limbs crawling across a misty mountainous landscape, dramatic lighting, illustration by francois baranger, greg rutkowski, yoji shinkawa, 4 k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Cyborg dog on Titan",
        "full": "biblical diabolical bautiful cyborg dog with glowing veins, intricate detailed porcelain armor, ocean on alien planet titan, underwater photography, by gerald brom, by mikhail vrubel, by peter elson, muted colors, extreme detail, trending on artstation, 8 k"
    },
    {
        "display": "Highly detailed AI portrait",
        "full": "perfectly - centered - portrait - photograph of an artificial intelligence, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha loish and wlop"
    },
    {
        "display": "Bust of elegant Indian girl",
        "full": "A bust of a beautiful black skinned Indian girl with large lips and smooth eyebrows and straight hair, HD, illustration, epic, fantasy, intricate, elegant, amazing detail, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Turine Tran"
    },
    {
        "display": "Shrek in swamp portrait",
        "full": "shrek posing on swamp, portrait, full body shot, digital art, concept art, fantasy art, highly detailed, hd wallpaper, hdr, artstation, deviantart, behance"
    },
    {
        "display": "Sacred tree, visionary oil painting",
        "full": "the sacred tree by alex grey in the style of oil painting visionary art, intricate artwork  octane render, trending on artstation, very coherent symmetrical artwork"
    },
    {
        "display": "Fantasy knight concept art",
        "full": "fantasy male knight red plume, center focused, matte painting, neon, concept art, schematics, gnarly details painted by tom bagshaw,  norman rockwell, mucha, james gurney, high detail, denoised, sharp, architectural"
    },
    {
        "display": "Elegant waterfront portrait.",
        "full": "Portrait of a  blonde lady and Michael mcintyre listening to Oasis on the Porto waterfront,real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Undead soldiers in smoky forest",
        "full": "undead armoured soldiers in burned forest full of smoke, fantasy style, highly detailed, smooth, sharp focus, character portrait, portrait, concept art, intricate details, medieval poster, dark athmosphere, 8 k. lifelike. nikon d 8 5 0"
    },
    {
        "display": "Symmetric Octopus Logo",
        "full": "octopus symmetric logo painting by tim biskup and afshar petros, matte background, sharp contours, minimal, trending on artstation"
    },
    {
        "display": "Lifelike pencil illustration of Nana Mouskouri.",
        "full": "beautiful lifelike award winning pencil illustration of nana mouskouri trending on art station artgerm greg rutkowski alphonse mucha cinematic atmospheric"
    },
    {
        "display": "Elephant-dog jumping hill.",
        "full": "dog jumps over hill, dog looks like elephant with trunk!!!, intricate, epic lighting, cinematic composition, hyper realistic, 8 k resolution, unreal engine 5, by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, marc simonetti, artstation"
    },
    {
        "display": "Cute autumn gnomes artwork",
        "full": "hand drawn cute gnomes in autumn disguise holding pumpkin and maple leaf, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Metaverse city street scene",
        "full": "an intricate detail of a city street in the metaverse with walking people with VR headsets and wires, highly detailed, cinematic lighting, volumetric lighting,  Artstation, concept art, hyper realistic, cgsociety"
    },
    {
        "display": "Elven queen on throne.",
        "full": "tall slender elven queen sitting on the throne, full body, long white hair, pale skin, golden coloured eyes | | realistic shaded, fine details, fine - face, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin, michael garmash, rob rey"
    },
    {
        "display": "Cinematic Westworld concept art",
        "full": "westworld, in search for meaning, zaha hadid, concept art, visor, intricate lights, cinematic, dramatic, moody, atmospheric, all is lost, gemma chan girl, made by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, beksinski artstation, cgsociety"
    },
    {
        "display": "Intricate alien octopus artwork",
        "full": "black ink on paper, alien octopus, trending on artstation, beautiful, intricate, detailed"
    },
    {
        "display": "Whimsical fang character.",
        "full": "You're something special, to be a little fang"
    },
    {
        "display": "Futuristic cyberpunk robot.",
        "full": "high octane 4K 3D render of a smooth simple high-tech cyberpunk male black humanoid futuristic sci-fi robot, holding a camera neon lights, metallic, in the style of Daniel Dociu, dramatic lighting, vibrant colors, Trending on Artstation HQ, 4K, UHD, High quality, Unreal Engine"
    },
    {
        "display": "Ultra-realistic male warrior",
        "full": "ultra realistic illustration, male warrior character from mass effect, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Kurdish Brad Pitt portrait",
        "full": "portrait of a! kurdish bradd pitt, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, incredibly detailed, award winning art"
    },
    {
        "display": "Cloaked tech priest portrait",
        "full": "potrait of a cloaked tech priest holding the scripture of the mechanicus, adeptus mechanicus!!, cybernetic enhancements attached to his body, praise the omnissaiah, zdzislaw beksinski, lewis jones, mattias adolfsson, warhammer 4 0 k!, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Realistic lionman portrait",
        "full": "realistic detailed portrait of the lionman, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative, cinematic, ultra detailed"
    },
    {
        "display": "Vibrant fantasy micro world",
        "full": "3 d render micro world fluo deepdream gloomhaven, matte painting, concept art, art nouveau, swirly vibrant color lines, fantastically gaudy, aesthetic octane render, glowing aura, global illumination ray tracing, hdr, 8 k hd resolution, by ilya kuvshinov and cushart krentz and gillard jamesa, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Minimalist flooded street scene",
        "full": "a high contrast! painting of a minimalist flooded old empty street arch tower by rene magritte simon stalenhag carl spitzweg jim burns, full-length view, vibrant colors, extremely high contrast!, symmetry, great composition, high detail, cinematic lighting, award winning masterpiece, trending on artstation"
    },
    {
        "display": "Blonde girl with flamingos.",
        "full": "a pretty smiling blonde girl with heart - shaped sunglasses dressed in pink shiny clothes is guarded by flamingos, sun set and skyscrapers in the background, art by guweiz, dramatic lighting, highly detailed, incredible quality, trending on artstation"
    },
    {
        "display": "Gothic fantasy throne illustration.",
        "full": "a beautiful digital illustration painting of a detailed gothic fantasy full moon and roots, throne chair and vines by giorgio de chirico, and david rios ferreira. 8 k resolution trending on artstation concept art digital illustration"
    },
    {
        "display": "Trump as medieval jester.",
        "full": "president donald trump as a historic court jester. fools hat with brass bells on it. medieval dirty and grimy noble. historic royal portrait from the elizabethan era. oil painting, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, frans hals, rembrandt"
    },
    {
        "display": "Girl in alien landscape.",
        "full": "beautiful girl staring at an alien landscape, character portrait, james gurney, character concept style trending on artstation, detailed face, concept art, detailed, octane render cinematic, photo-realistic, 8k, high detailed"
    },
    {
        "display": "Oriental witch artwork.",
        "full": "oriental witch in elden ring by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman rockwel"
    },
    {
        "display": "Quirky night kitchen illustration",
        "full": "An of interior of a kitchen at night, rotoscoped, rotoscope, photoshop, photomanipulation, realism, painting, illustration and sketch, weird scribbles, hybrid styles, hybrid art styles, mismatched, trending on artstation, trending on deviantart, weird, quirky, interesting, very detailed, highly detailed, HD Quality, 4k resolution, 8k resolution, in the style of David Firth, in the style of James Lee, in the style of Drue Langlois,"
    },
    {
        "display": "Captive Persian princess portrait",
        "full": "beautiful and enigmatic persian princess held captive in a remote research facility. vulnerability and innocence, ultra realistic, sharp details, subsurface scattering, intricate details, warm lighting, beautiful features, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk girl portrait",
        "full": "kyoto animation, cool girl wearing cyberpunk intricate catsuit, beautiful, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Cyberpunk character portrait",
        "full": "sojourn from overwatch, african canadian, gray dread locks, gray hair, teal silver red color schema, cyber eyes, character portrait, portrait, close up, concept art, intricate details, highly detailed, vintage sci - fi poster, retro future, vintage sci - fi art, in the style of chris foss, rodger dean, moebius, michael whelan, and gustave dore"
    },
    {
        "display": "Fantasy portrait of Kurt Russell.",
        "full": "a fantasy style portrait painting of kurt russell oil painting unreal 5 daz. rpg portrait, extremely detailed artgerm greg rutkowski greg hildebrandt tim hildebrandt"
    },
    {
        "display": "Cottagecore witch character design.",
        "full": "character sheet concept art of a young adult cottagecore witch with rose - colored hair and teal accents to her clothing, realistic, hyperrealistic, photographic, costume, wlop, dan mumford, greg rutkowski, high detail, octane render, alexander mcqueen, james gurney, james jean, mucha, photo, 8 k, intricate"
    },
    {
        "display": "Ethereal portrait of George Burns.",
        "full": "portrait of george burns eating hamburgers, extra onions and ketchup, luscious patty with sesame seeds, feminine ethereal, handsome, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rock golem in desert",
        "full": "A rock golem posing in a a desert, raging,screaming, realistic face, full body, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant"
    },
    {
        "display": "Concept art of church windows",
        "full": "concept art, church windows, by james gurney, greg rutkowski, john howe, artstation"
    },
    {
        "display": "Detailed school girl portrait.",
        "full": "close up view of a school girl in front of a tree, highly detailed oil painting, unreal 5 render, rhads, Bruce Pennington, Studio Ghibli, tim hildebrandt, digital art, octane render, beautiful composition, trending on artstation, award-winning photograph, masterpiece"
    },
    {
        "display": "Epic cowboy portrait",
        "full": "a beautiful dramatic epic painting of a thicc handsome black man holding reins | he is shirtless and wearing a cowboy hat and covered in leather straps | dust clouds, prairie setting | homoerotic, highly detailed, dramatic lighting | by Mark Maggiori, by William Herbert Dunton, by Charles Marion Russell | trending on artstation"
    },
    {
        "display": "Gothic Byzantine Pope Portrait",
        "full": "gothic byzantine pope, dark fantasy, portrait, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Cute creatures in jungle",
        "full": "of the dark jungle with large cute strange friendly creatures with big eyes, mouth and teeth appearing from the bushes, in the style of Gehry, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Baroque floral mural painting",
        "full": "an oil painting of a pattern maural of flowers and shrines by beksinski carl spitzweg and tuomas korpi. baroque elements, full-length view. baroque element. intricate artwork by caravaggio. Trending on artstation. 8k"
    },
    {
        "display": "Praying mantis robot portrait.",
        "full": "a vibrant ultraclear sideview waist up portrait of praying mantis robot by rene magritte and by laurie greasley, etching by gustave dore, colorful flat surreal, ethereal, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, masterpiece"
    },
    {
        "display": "Surreal portal adventure.",
        "full": "the third first image on the scattered absurdity server, dr seuss, and dr strange, very pretty, photorealistic, portal hopping and time warping with reckless abandon, octane, vray, by Greg rutkowski, Jacek Yerka, Dan Mumford"
    },
    {
        "display": "Realistic smoky city street",
        "full": "a highly detailed and beautiful realistic oil painting of a 21st century city street in a smoky summer night, by Zdzis\u0142aw Beksi\u0144ski,Jean Delville, Edmund Dulac,Jean Giraud,Ellen Jewett, #vfxfriday, vivid, hyper realistic, wide angle, detailed, masterpiece, 8k resolution, matte painting, trending on artstation"
    },
    {
        "display": "Cute bioluminescent queen gnome.",
        "full": "a surreal Bioluminescent, very very very cute Queen Gnome in a happy world by Daniel Merriam, Trending on Artstation, oil on Canvas by Elena Zhurikhina and Goro Fujita and Charlie Bowater, octane render, 4k, 8k, HD"
    },
    {
        "display": "Ravenclaw wizard portrait.",
        "full": "close - ip shot, character portrait, a seventeen years old male!! ravenclaw wizard with black curly hair, wearing browline!! glasses!!! with a mandrake!!!, blue shiny lighting, beautiful fantasy art, film still from new harry potter movie, by artgerm and hayao miyazaki, trending on artstation"
    },
    {
        "display": "Detailed lion mask portrait",
        "full": "Very very very very highly detailed epic photo of face with lion venetian mask, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Artgerm and Brom and Vincent di Fate"
    },
    {
        "display": "Futuristic hyperrealistic Mercedes supercar.",
        "full": "hyperrealism, detailed textures, photorealistic 3 d, a futuristic mercedes super car, ultra realistic, ultra high pixel detail, cinematic, intricate, cinematic light, concept art, illustration, art station, unreal engine 8 k"
    },
    {
        "display": "Modular insulated container home.",
        "full": "cabela's beautiful comfortable carbon framed, modular insulated wall portable container home kit - house all weather family dwelling tent house, person in foreground, mountainous forested wilderness open fields, beautiful views, painterly concept art, environmental concept art, concept art illustration, by james gurney, by craig mullins, by greg rutkowski trending on artstation"
    },
    {
        "display": "Capybara on motorcycle at dusk",
        "full": "a photorealistic photograph of a knitted cute Capybara wearing sunglasses and dressed in a blue beanie cap in addition to riding on a black motorcycle in Hollywood at dusk. Palm trees in the background. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and the style of Unreal Engine."
    },
    {
        "display": "Tattooed man portrait.",
        "full": "a detailed full body portrait of a face tattoed man, digital concept art illustration, incredibly detailed and realistic, 8 k, sharp focus"
    },
    {
        "display": "Rey and Kylo Ren.",
        "full": "rey and kylo ren holding hands, pixiv illustration, digital art"
    },
    {
        "display": "Jester character with fractals",
        "full": "front!!! shot of a court jester!!! character, mesmerizing fractal hypercubes, dark holography!!!, future, oil slick nebula, crystalline edges, (((intricate quartz))), elegant, highly detailed, centered, (((artstation, concept art, smooth, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), ((raytracing)), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, grainy, muted, 8K, digitalblasphemy, metatron"
    },
    {
        "display": "Cyberpunk razorgirl portrait",
        "full": "cyberpunk, halfbody shot of a young razorgirl, sunglasses, black hair, shag cut, dramatic light, city background, sunset, dystopian setting, high contrast, sharp, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Ronaldo in moonlight haze",
        "full": "ronaldo luis nazario da lima, football player, moonlight, haze, low contrast, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy portrait of Luffy.",
        "full": "luffy as fantasy style portrait painting of middle eastern male brown wavy hair beard rpg dnd oil painting unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Stylized monster illustration",
        "full": "monster, big limbs, smooth, centered, solid bacgkround, median photoshop filter cutout vector behance, hd by artgerm, jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, illustration, art by ilya kuvshinov and gustav klimt"
    },
    {
        "display": "Teenage Medusa Portrait",
        "full": "portrait of teenage medusa, no hair, sassy expression, black snakes with orange eyes cover her head, wearing an embroidered orange tunic, intricate, elegant, copper and emerald jewelry, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mucha, artgerm, and greg rutkowski"
    },
    {
        "display": "Fractal princess surrealism",
        "full": "fractal princess, in the style of greg broadmore and arthur rackham, trending on artstation, light lighting side view, digital art, surrealism, macro, blueprint, vaporwave,"
    },
    {
        "display": "Dark mage casting spell",
        "full": "highly detailed portrait of a dark mage casting a spell by alex grey, patrick woodroffe, mark ryden created by gustave dore and greg rutkowski, high detailed, smooth draw, synthwave neon retro, intricate, realistic proportions, dramatic lighting, trending on artstation"
    },
    {
        "display": "Misty swamp with ruins",
        "full": "a swamp with vines, hidden overgrown ruins, foggy, atmospheric, misty, moebius, detailed, digital art, trending on artstation"
    },
    {
        "display": "Alien samovar concept art",
        "full": "a very detailed concept art of alien samovar, trending on artstation, digital art, 4 k, hyper realistic, octane render, sharp focus"
    },
    {
        "display": "Vibrant queen of death portrait.",
        "full": "super vibrant and contrast fancy portrait of a queen of death giant floating flower and jellyfish by Greg Rutkowski, Sung Choi, Mitchell Mohrhauser, Maciej Kuciara, Johnson Ting, Maxim Verehin, Peter Konig, Bloodborne, beeple, 8k photorealistic, cinematic lighting, HD, high details, atmospheric , trending on artstation. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational vibrant colors, gloomy mood. Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong. high contrast"
    },
    {
        "display": "David Tennant as Tenth Doctor",
        "full": "portrait of david tennant as the tenth doctor from doctor who wearing a party hat, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by wlop, mars ravelo and greg rutkowski"
    },
    {
        "display": "Psychedelic girl portrait with orchids.",
        "full": "psychedelic portrait of girl surrounded by orchids, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha"
    },
    {
        "display": "Floral daemonic woman portrait",
        "full": "biblical daemonic beautiful woman made out of flora, glowing veins, flower sparkling skin, ooak doll, rule of thirds, close - up, portrait by greg hildebrandt, studio lighting, muted colors, by terry richardson, by leonardo davinci, by beeple, ultrarelistic, extreme detail, caustics, trending on artstation, 8 k, octane renderer, rtx on"
    },
    {
        "display": "Lofi biopunk woman portrait",
        "full": "Lofi biopunk portrait beautiful woman with short brown curly hair, roman face, unicorn, rainbow, floral, Tristan Eaton, Stanley Artgerm, Tom Bagshaw"
    },
    {
        "display": "Goddess portrait with crown",
        "full": "blurred background. close-up portrait of a goddess in crown, by Artgerm and Afarin Sajedi and greg rutkowski. octane render"
    },
    {
        "display": "Intricate Hydra Fantasy Art",
        "full": "painting of the hydra, d & d, gruesome, monstrous, classical, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cat-eared rocket landing on Mars.",
        "full": "Concept art of a SpaceX Super Heavy with cat ears landing on Mars, photorealistic, 4k, beautiful composition, trending on artstation, award-winning photograph, masterpiece"
    },
    {
        "display": "Dark sci-fi UFO landing",
        "full": "dark sci-fi UFO landing, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by Jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Man entering portal artwork",
        "full": "a man walking into the portal, concept art, illustration, highly detailed, artwork, cinematic, hyper realistic, art station,"
    },
    {
        "display": "Vtuber portrait of Selen Tatsuki",
        "full": "a vtuber portrait of selen tatsuki, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Elven healer portrait, fantasy.",
        "full": "Half body portrait of a beautiful elven healer with long straight black hair wearing ornate white and gold attire. In style of Yoji Shinkawa and Hyung-tae Kim, trending on ArtStation, dark fantasy, great composition, concept art, highly detailed, dynamic pose."
    },
    {
        "display": "Evil ninja turtle portrait",
        "full": "uhd hyperrealistic photorealisitc hyperdetailed detailed full body concept art portrait of an evil ninja turtle, dynamic lighting, hopeless emotions, by beksinski, sharp focus, artstation hq, 8 k, intricate, subsurface scattering, ray tracing, vivid colors, octane render"
    },
    {
        "display": "Gigachad Luigi in space",
        "full": "gigachad luigi firing a laser cannon like ernest khalimov wearing a suit in the space lab, fantasy character portrait, ultra realistic, anime key visual, concept art, intricate details, highly detailed by greg rutkowski, ilya kuvshinov, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Cottagecore girl with cat.",
        "full": "beautiful cottagecore of a girl with short black curly hair, round face, cute face, holding a loaf of bread. There's also a black cat on her shoulder. intricate, elegant. highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration. . art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Abstract portrait of smiley girl.",
        "full": "smiley girl portrait, abstract, rich details, modernist composition, coarse texture,  concept art, visible strokes, colorful, Kirchner, Gaughan, Caulfield,  Aoshima, Earle"
    },
    {
        "display": "Vivian Leigh as Knight",
        "full": "closeup portrait of a young vivian leigh as female knight, city background, megacity, high fantasy, dramatic light, gorgeous view, depth, high detail, digital art, painted by greg rutkowski, trending on artstation"
    },
    {
        "display": "Beef Trump Statue",
        "full": "a statue of donald trump made out of ground beef, beautiful detailed intricate insanely detailed octane render, unreal engine, trending on artstation, 8 k artistic photography, photorealistic, cinematic light, chiaroscuro, award - winning, masterpiece, raphael, caravaggio, beksinski, giger"
    },
    {
        "display": "Cassandra Cain in bed.",
        "full": "cassandra cain wearing a satin playsuit!!!! laying in bed!!!, beautiful face!!!!, 2 7 years old, cg animation, lifelike, animated, realistic, by artgerm, greg rutkowski, 3 d"
    },
    {
        "display": "Hyper-realistic girl portrait",
        "full": "1 5 - year old girl with lush brown hair, large front teeth, and bright piercing brown eyes, hyper realistic face, beautiful eyes, character art, art by artgerm lau and wlop and and ilya kuvshinov and john singer sargent, hyperdetailed, symmetrical, cryengine, trending on artstation, digital art"
    },
    {
        "display": "Androgynous crying portrait.",
        "full": "portrait of androgynous face crying, flowers neon, fibonacci, sweat drops, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Muscular female Viking character",
        "full": "muscular viking, female, d & d, science fiction, fantasy, concept art, matte, sharp focus, illustration, concept art, character art,"
    },
    {
        "display": "Elegant portrait of Emma Watson",
        "full": "sophisticated portrait of young Emma Watson starring in ghost in the shell, elegance, highly detailed, shallow depth of field, Artstation, Artgerm, Donato Giancola and Joseph Christian Leyendecker"
    },
    {
        "display": "Gloomy school girl horror.",
        "full": "school girl on the classroom, gloomy and foggy atmosphere, octane render, cgsociety, artstation trending, horror scene, highly detailded"
    },
    {
        "display": "Jenna Coleman as Star Trek Captain.",
        "full": "jenna coleman as a star trek captain, a still from star trek painted by artgerm and greg rutkowski and alphonse mucha. clear highly detailed face, beautiful sci fi art"
    },
    {
        "display": "Remorseful Punisher portrait.",
        "full": "portrait of remorseful frank castle the punisher, bruised, face paint, intricate, elegant, highly detailed, centered, grungy, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, artstation, boris vallejo"
    },
    {
        "display": "Cyberpunk soldier portrait",
        "full": "cyberpunk, armitage, closeup portrait of an ex soldier, battlescar, artificial eye, brown buzzcut, wired head, dramatic light, city background, sunset, dystopian setting, high contrast, sharp, neuromancer, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Elven glade with monuments.",
        "full": "Beautiful uplifting glade bg. Elven stone monuments along the pathway. Mysterious stone monuments. J.R.R. Tolkien's Middle-Earth. Trending on Artstation. Digital illustration. Artwork by Darek Zabrocki and Sylvain Sarrailh. Concept art, Concept Design, Illustration, Marketing Illustration, 3ds Max, Blender, Keyshot, Unreal Engine, ZBrush, 3DCoat, World Machine, SpeedTree, 3D Modelling, Digital Painting, Matte Painting, Character Design, Environment Design, Game Design, After Effects, Maya, Photoshop."
    },
    {
        "display": "Personified United States illustration",
        "full": "a personification of the united states of america, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cottagecore Marilyn Monroe Art",
        "full": "beautiful cottagecore Marylin Monroe holding an Warhol painting. intricate, elegant. highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration. . art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk pirate portrait.",
        "full": "steampunk pirate tim cook, fantasy, d & d, intricate, detailed, by by alphonse mucha, adolfo hohenstein, alice russell glenny, stanley artgerm lau, greg rutkowski, detailed, trending on artstation, trending on artstation, smooth"
    },
    {
        "display": "Jester with bioluminescent cubes",
        "full": "front!!! shot of a court jester!!! character, hyperdimensional, bioluminescent hypercubes, dark holography!!!, future, chrome, (((intricate))), octane render, nvidia raytracing demo, elegant, highly detailed, centered, (((artstation, concept art, smooth, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo,  grainy, muted, 8K, digitalblasphemy, metatron"
    },
    {
        "display": "Modern magician with card.",
        "full": "modern street magician holding a playing card, realistic, modern, magicians eyes are covered with hankerchief, intricate, elegant, highly detailed, digital painting, artstation, concept art, addiction, smooth, sharp focus, illustration, art by ilja repin"
    },
    {
        "display": "Hyper-realistic Abu Simbel painting",
        "full": "A hyper-realistic oil painting of the majestic Abu Simbel temple, located in Egypt, photorealistic, 4k, HQ, volumetric lighting, immaculate scale, trending on Artstation, Zdzsilaw Beksinski"
    },
    {
        "display": "Dramatic fantasy woman portrait",
        "full": "a photorealistic dramatic fantasy render of a beautiful woman wearing a beautiful intricately detailed japanese crow kitsune mask and clasical japanese kimono by wlop, artgerm, greg rutkowski, alphonse mucha, beautiful dynamic dramatic dark moody lighting, shadows, cinematic atmosphere, artstation, concept design art, octane render, 8 k the seeds for each individual image are : [ 7 7 9 9 7 6 1 8 1, 3 2 5 6 4 1 8 5 7 5, 4 0 0 8 6 1 3 6 7 8, 3 1 5 5 8 2 9 4 2 4, 1 7 0 9 5 4 0 5 8 2, 9 3 3 7 4 3 2 0 7, 3 3 3 0 9 2 2 3 1 3, 4 7 7 1 8 2 1 7, 4 1 7 2 0 7 6 9 5 ]"
    },
    {
        "display": "Izuku Midoriya in hip-hop style",
        "full": "an oil painting of a izuku midoriya wearing a hip - hop rap hat, by artgerm, hd, hdr, ue 5, ue 6, unreal engine 5, cinematic 4 k wallpaper, 8 k, ultra detailed, gta cover art, high resolution, artstation, award winning"
    },
    {
        "display": "Dystopian Disney princess illustration.",
        "full": "detailed full body concept art illustration colorful pastel painting of a Disney princess in full intricate clothing, ultra detailed, digital art, octane render, 4K, dystopian, micro details"
    },
    {
        "display": "Hyper-realistic Trump portrait.",
        "full": "hyper - realistic photo of donald trump standing in a line looking towards a bright communist future, volume shadows, 8 k, octane perfectly detailed rendering, extremely hyper detailed, intricate, epic composition, unusual lighting, masterpiece, trending artstation, very highly detailed, stunning, hdr, smooth, sharp focus, high resolution, award winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Lion face underwater splash",
        "full": "a male lion's face breaching through a wall of water, headshot, water sprites, splashing, deep blue ocean, highly detailed, realistic digital art, trending on artstation"
    },
    {
        "display": "Cyborg in cyberpunk club",
        "full": "ultra realistic, beautiful female cyborg in a crowded smoky cyberpunk club in space megalopolis, sci-fi, intricate details, eerie, highly detailed, octane render, 8k, art by artgerm and alphonse mucha and greg rutkowski"
    },
    {
        "display": "Grizzly bear vs. wolf fight.",
        "full": "photo, a grizzly bear and a wolf having a fight, woodland location, stefan kostic and david cronenberg, realistic, sharp focus, 8 k high definition, intricate, chiaroscuro, elegant, perfect faces, symmetrical face, extremely detailed, hypnotic eyes, realistic, fantasy art, masterpiece zdzislaw beksinski, artgerm"
    },
    {
        "display": "Quantum reaper watercolor art",
        "full": "Quantum reaper watercolour painting, watercolour, concept art"
    },
    {
        "display": "Yanonami riding glyptodon",
        "full": "yanonami riding a glyptodon, greg rutkowski, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, long shot, cinematic lighting"
    },
    {
        "display": "Cinematic Machu Picchu Art",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, oil painting, machu picchu"
    },
    {
        "display": "Fantasy forest book cover",
        "full": "fantasy book cover, full moon, fantasy forest landscape, golden vector elements, fantasy magic, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by greg rutkowski and Artgerm and Albert Bierstadt, masterpiece"
    },
    {
        "display": "Mont Saint-Michel illustration.",
        "full": "mont - st - michel, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski"
    },
    {
        "display": "Horrifying Dwayne Johnson art",
        "full": "horrifying concept art of scary dwayne the rock johnson, terrifying horror artwork, horror game concept art"
    },
    {
        "display": "Elegant iridescent crow portrait",
        "full": "allover painting face portrait of elegantly beautiful, fasionable, sophisticated iridescent corvus brachyrhynchos, intricate automatism, baroque, batik, ben - day dots by, norman rockwell, charles schulz, artgerm, vogue italia, trending on pinterest."
    },
    {
        "display": "Realistic bear with guitar.",
        "full": "realistic bear holding a triangular wooden triangle + guitar sound hole + guitar neck, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mordor landscape illustration",
        "full": "mordor, beautiful scenic landscape, lord of the rings, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Futuristic city in nature",
        "full": "incredible futuristic city in harmony with nature, happy people living, dramatic lighting, detailed digital art trending on artstation"
    },
    {
        "display": "Cyborg Turing conducting Turing Test",
        "full": "duotone futuristic clinical white ghastly scifi 3 / 4 portrait of cyborg alan turing conducting turing test with ai. scifi far future atmosphere. cinematic scene volumetric lighting. golden rario accidental renaissance. concept by stanley kubrick dr strangelove style and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Realistic portrait of Jet Li as a tardigrade.",
        "full": "realistic portrait beautiful detailed matte painting of cinematic movie scene jet li mutate intotardigrade. horror, created by gustave dore and greg rutkowski, high detailed, smooth draw, synthwave neon retro, intricate, realistic proportions, dramatic lighting, trending on artstation."
    },
    {
        "display": "Bald Rapunzel digital art",
        "full": "bald rapunzel, digital art, trending on artstation"
    },
    {
        "display": "Ornate cute fluff design",
        "full": "fluff, cute:: by beeple and James Gilleard and Justin Gerard :: ornate, dynamic, particulate, intricate, elegant, highly detailed, centered, artstation, smooth, sharp focus, octane render, 3d"
    },
    {
        "display": "Solarpunk hovercar landscape",
        "full": "solarpunk hovercar, clean energy, green technology, highway, sunny day, futurism, intricate, glow, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, epic landscape, art by akihiko yoshida and tim mcburnie and anato finnstark"
    },
    {
        "display": "Cyberpunk Tokyo cityscape.",
        "full": "tokyo as cyberpunk city, future, neon lights, japanase writing, ultra detailed, trending on artstation, concept art, octane render, unreal engine,"
    },
    {
        "display": "Ice goddess shrine illustration",
        "full": "a female goddess carved into a giant block of ice in an extradimensional realm, a shrine to worship her at, intricately detailed, physically based rendering, realistic, in the style of artgerm, illustration, epic, fantasy, hyper detailed, artstation, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Purple fish in coral reef.",
        "full": "A purple fish, swimming in a beautiful coral reef, aesthetically pleasing Digital art, Concept art by Hokusai and Vincent Van Gogh"
    },
    {
        "display": "Intricate horror concept art",
        "full": "john carpenters the thing, kurt russell, intricate, beautiful, highly detailed, elegant, artstation, concept art, smooth and sharp focus, rpg artwork, illustration, painting by ilya kuvshinov, alphonse mucha, gaston bussiere, art adams, craig mullins, j. c. leyendecker, rene maritte, tian zi, wlop, alsphonse mucha, artgerm"
    },
    {
        "display": "Goddess portrait artwork",
        "full": "a beautiful portrait of a goddess by Petros Afshar and Beeple, Trending on Artstation"
    },
    {
        "display": "Psychedelic club dancer portrait",
        "full": "girl dancing in a club, expressive digital art, psychedelic, lsd, by yoshitaka amano, by dan mumford, close - up portrait, trending on artstation, 4 k"
    },
    {
        "display": "Cute 3D princess concept",
        "full": "super cute princess 3D concept by EYMBEEYO and Gediminas Pranckevicius, foggy, glowing effect, rule of thirds, chubby, face realistic, Game Art, Zenith angle, hyper detailed, no background, Character Modeling, cartoon, cinematic, raytrace, Trend on artstation, C4D"
    },
    {
        "display": "Symmetrical sci-fi Genji portrait",
        "full": "symmetry portrait of genji from overwatch, sci - fi, tech wear, glowing lights intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gladiator warrior lady portrait",
        "full": "frontview portrait of gladiator warrior lady, young, beautiful, skinny, dark skin, light blue hair, ponytail, big open green eyes, cute, red heavy armor, axes, posing, coliseum background, love interest, highly detailed, smooth, sharp focus, digital painting, artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Adeptus Custodes Centurion Art",
        "full": "adeptus custodes centurion, extremly detailed digital painting, warhammer 4 0 k, raymond swanland, tomasz alen kopera, vibrant colors, intricate, exquisite lighting, highly detailed, rim light, cinematic lighting, art, octane render, very coherent, cinematic, 8 k, trending on artstation"
    },
    {
        "display": "Afro-Caribbean goddess portrait",
        "full": "Portrait of a Afro caribbean goddess with angel wings, and a glowing halo, white lighting, digital art by Ruan Jia and Mandy Jurgens and Artgerm, highly detailed, trending on artstation, award winning,"
    },
    {
        "display": "Detailed portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin by eiichiro oda, makoto shinkai, alphonse mucha, sharp focus, art by artgerm and greg rutkowski!, backlit, harsh overhead sunlight, blue eyes, stanley kybric, pixiv, fanbox,"
    },
    {
        "display": "Eerie portrait of Fauci.",
        "full": "anthony fauci scheming, ultra realistic, concept art, intricate details, eerie, horror, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Haunted mansion matte painting",
        "full": "A highly detailed matte painting of the huge haunted mansion at the top of the hill, by Studio Ghibli, Makoto Shinkai, by Artgerm, by WLOP, by Greg Rutkowski, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Oceanscape warship battle.",
        "full": "middle of the ocean, large waves, battle between two 1800s warships, no land, dark skies, close up shot, at dusk, 4k, rule of thirds, extreme detail, hazy, water splash, intricate ink illustration, surreal, straight horizon, surrealist, trending on artstation, cgsociety, hd, complimentary colours, realistic lighting, by Albert Bierstadt, Frederic Edwin Church."
    },
    {
        "display": "Detailed cat girl portrait",
        "full": "nekopara  smile fantastically detailed eyes vn modern anime style art vibrant detailed cat ears cat girl nekomimi dress portrait  shinkai makoto smiling mouth Studio ghibli Sakimichan Kentaro Miura Stanley Artgerm Lau WLOP Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation Unreal Engine 4k 8k ultra HD pixiv"
    },
    {
        "display": "Taiwanese girls in fantasy yukata.",
        "full": "two beautiful fashion taiwan girls wear fantasy yukata in festival | | big eyes, sunny, dreamlike art, realistic shaded, smile, good looking, fine details, 4 k realistic, cryengine, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Celestial goddess casting spell.",
        "full": "a celestial goddess casts a transcendental magical spell, windy floating hair!!, atmospheric moody, dark academia, seductive beauty! cinematic volumetric lights, bust portrait, dnd, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and tom bagshaw and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic Shrek Concept Art",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Shrek. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Jester in fractal space",
        "full": "front!!! shot of a court jester!!! character, mesmerizing fractal hypercubes, bismuth!!, dark holography!!!, future, metallic galactic, crystalline edges, polygonal, elegant, highly detailed, centered, (((artstation, concept art, jagged, sharp focus, artgerm, Tomasz Alen Kopera, Peter Mohrbacher, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo))), ((raytracing)), mugshot!!!!!, ugly!!!!!!, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, octane render, nvidia raytracing demo, 8K"
    },
    {
        "display": "Cozy, cluttered aquatic store",
        "full": "clean neat clarity professional visual development set design, messy cozy store with hanging cages and bright aquariums, ferns and foliage, dim painterly lighting volumetric aquatics, impasto, trending on pixiv"
    },
    {
        "display": "Alien Art Deco Landscape",
        "full": "Concept Digital Art Highly detailed Alien Art Deco Riza 4 lazy river inside of mount Vesuvius with glowing purple water at midnight, starfleet shore leave, by greg rutkowski, alphonse mucha, and Edmund Blair Leighton. Very highly detailed 8K, exquisite rendering, octane, drum scanner, Digital painting, the golden ratio, rational painting, sharp"
    },
    {
        "display": "Cyberpunk miner costume design",
        "full": "sadie sink dressed as a miner : costume design concept art for a scifi cyberpunk film. by greg rutkowski, john j. park, jason chan, noah bradley, feng zhu, gintas galvanauskas, gustave courbet, rosa bonheur, edward hopper. sharp focus, cinematic atmosphere, detailed and intricate, perfect anatomy"
    },
    {
        "display": "Elegant fantasy portrait.",
        "full": "Robin Wright portrait, fantasy, elegant, intricate, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Portrait of young Colleen Moore",
        "full": "colleen moore 2 2 years old, bob haircut, portrait by stanley artgerm, dramatic lighting, ilya kuvshinov"
    },
    {
        "display": "Surreal Lovecraftian shaman.",
        "full": "faceless, shaman, cultist, lovecraftian, surreal, shrouded figure, powerful being, plant spirit, fractal entity, spirit guide, light being, pearlescent, shiny, glowing, ascending, beautiful, subtle pattern, trending on artstation, by peter mohrbacher"
    },
    {
        "display": "Cyborg character design",
        "full": "character design, concept art, cyborg, battle angel alita. by rembrandt 1 6 6 7, illustration, by konstantin razumov, sci - fi, frostine engine, vibrant colors, fractal flame, crystalized, by wlop"
    },
    {
        "display": "Alien machine goddess artwork",
        "full": "beautiful alien machine goddess of the rainstorms in white celtic robes by android jones alex grey m. c. escher h. r. giger android jones alex grey mark cooper alien beauty alien cyborg machine goddess beauty alien celtic red - haired rainstorm goddess with white robes and metallic machine deity alex grey android jones hr giger artgerm artstation award winning photorealistic detailed hypervivid intense fantasy concept art by greg rutkowski"
    },
    {
        "display": "Anime goddess of lemons",
        "full": "character concept art of an anime goddess of lemons | | cute - fine - face, pretty face, realistic shaded perfect face, fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Symmetrical sci-fi portrait.",
        "full": "symmetry!! portrait of a man with night vision goggles wearing a sombrero, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cliffhanger woman, apocalyptic landscape.",
        "full": "a detailed painting of a single woman hanging from the side of a cliff overlooking the end of the world by Studio Ghibli trending on ArtStation Beksinski, Greg Hildebrandt, 8k highly detailed \u2764\ufe0f\u200d\ud83d\udd25 \ud83d\udd25 \ud83d\udc80 \ud83e\udd16 \ud83d\ude80"
    },
    {
        "display": "Egyptian Kratos as Pharaoh",
        "full": "kratos the god of war as an egyptian warrior, pharaoh, egypt, pyramids, by alex gray and android jones, karol bak, ayami kojima, amano, moebius, concept art, character design, fantasy, 3 d, 8 k resolution"
    },
    {
        "display": "Intricate portrait with scenery.",
        "full": "the day i die, portrait, intricate, detailed, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, artstation, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Girl in goggles art",
        "full": "Beautiful girl wearing goggles profile picture by Greg Rutkowski, asymmetrical, Organic Painting , Matte Painting, geometric shapes, hard edges, street art, trending on the artstation, realistic by Sachin Teng,"
    },
    {
        "display": "Grand demon tyrant portrait",
        "full": "excellent painted portrait of the grand demon tyrant, stylized dramatic lighting oil painting, symmetry, 8k resolution artwork, trending on artstation, octane render, art by artgerm and greg rutkowski and alphonse mucha and craig mullins and James Jean and Andrei Riabovitchev and Marc Simonetti and peter mohrbacher"
    },
    {
        "display": "Acrobatic girl in chaos.",
        "full": "detailed procedural render skeksis in fluid action scene of latex spaghetti strap dress protects the acrobatic coolest girl from doom as the planet they stand sends spores surround her, photorealism, sharp focus, award winning, volumetric, raytracing, tristan eaton, victo ngai,, maxfield parrish, artgerm, intricate details, portrait, bokeh"
    },
    {
        "display": "Adorable voidlet concept art",
        "full": "concept art of an adorable voidlet, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and greg rutkowski, trending on artstation"
    },
    {
        "display": "Photorealistic portrait: young butch solarpunk woman.",
        "full": "photorealistic portrait of a young butch solarpunk cuban woman, handsome, female, masculine, upper body, fantasy, fierce, sharp features, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Desert caravan silhouettes",
        "full": "Silhouettes of human figures, caravan on a high dune in the desert before a sandstorm, highly detailed, dusty and dark, hyper-realistic environment,Epic concept art. Octane render and Unreal Engine"
    },
    {
        "display": "Anime girl in yukata.",
        "full": "anime style, gta 5, a girl, yukata clothing, sakura tree in background, brown short hair, hair down, symmetrical facial features, from arknights, hyper realistic, rule of thirds, extreme detail, 4 k drawing, safebooru, realistic lighting, by alphonse mucha, greg rutkowski, sharp focus, backlit"
    },
    {
        "display": "Boris Johnson as Wolverine.",
        "full": "Boris Johnson as calm but serious Wolverine, shining claws, yellow X-man costume, portrait, masculine figure, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime boy waving to girl.",
        "full": "boy on ground waving to a pretty girl on the 2 nd floor, illustration concept art anime key visual trending pixiv fanbox by wlop and greg rutkowski and makoto shinkai and studio ghibli"
    },
    {
        "display": "Anime supergirl illustration",
        "full": "anime key visual of a beautiful female supergirl!! intricate, red, blue gold suit, powers, speed, dc comics, cinematic, stunning, highly detailed, digital painting, artstation, smooth, hard focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Agent 47 portrait, guns backdrop.",
        "full": "a portrait of agent 4 7 from hitman sitting in front of a wall of guns, realistic illustration, dark background, red rim light, highly detailed, digital art, artstation, concept art, smooth, sharp focus, greg rutkowski, wlop"
    },
    {
        "display": "Proud old man portrait.",
        "full": "A proud oldman tidying up his tie, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Elegant woman portrait, photorealistic.",
        "full": "a painting of a woman with white hair in the style of god of war, golden machine parts, intricate, elegant, featured on cgsociety, photorealism, detailed painting, artstation hd, ultra detailed, concept art, smooth, sharp focus, illustration, a character portrait by pogus caesar, 8 k"
    },
    {
        "display": "Psychedelic Rob Zombie portrait.",
        "full": "an extremely psychedelic portrait of rob zombie, surreal, lsd, face, detailed, intricate, elegant, lithe, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, with translucent skin, visible muscles and veins and arteries and bones and spines and nerves, beautiful detailed intricate insanely detailed octane render, 8 k artistic photography, photorealistic, chiaroscuro"
    },
    {
        "display": "Ultron Statue Concept Art",
        "full": "environment concept art of statue of ultron by jama jurabaev, shaddy safadi, feng shu, neil blevins, very long shot, trending on artstation, high quality, brush stroke, for aaa game"
    },
    {
        "display": "Cinematic West Virginia landscape.",
        "full": "almost heaven west virginia blue ridge mountains shenandoah river life is old there older than the trees younger than the mountains growin'like a breeze country roads take me home to the place i belong west virginia mountain mama, cinematic lighting, concept art, intricate, 4 k, highly detailed, dramatic"
    },
    {
        "display": "Skunk warlock in moonlight",
        "full": "skunk warlock standing on top of a rock in the moonlight, tail, DnD character, unreal engine, octane render, dramatic lighting, pond, digital art,  by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Female Moon Knight Artwork",
        "full": "female moon knight, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Epic tribal attack scene",
        "full": "Mahindra thar, tribe members attacking, action scene, an epic fantasy, dramatic lighting, cinematic, establishing shot, extremely high detail, photorealistic, cinematic lighting, Maxwell Boas Jessica Rossier Christian Dimitrov Anton Fadeev trending on Artstation CGSociety rendered in Unreal Engine 4k HQ"
    },
    {
        "display": "Elegant dark fantasy illustration",
        "full": "  elegant,  highly detailed, digital painting, concept art, smooth, sharp focus, illustration, dark fantasy"
    },
    {
        "display": "Rugged fantasy ranger portrait",
        "full": "portrait of a rugged ranger, muscular, upper body,, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic Yggdrasil kingdom artwork",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Yggdrasil kingdom. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Edward Hopper, open ceiling, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Dual worlds confrontation",
        "full": "Two worlds facing each other, Darek Zabrocki, Marcin Rubinkowski, Lorenzo Lanfranconi, Karlkka, trending on Artstation, 8K"
    },
    {
        "display": "Rugged steampunk pirate portrait.",
        "full": "portrait of a rugged steampunk pirate, 3 0 years old, male, handsome, masculine, red hair, long hair, long beard, soft hair, fantasy, intricate, elegant, highly detailed, steampunk, airship, digital painting, artstation, concept art, character art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime military super soldier",
        "full": "anime portrait of a military super soldier by stanley artgerm lau wlop rossdraws james jean andrei riabovitchev marc _ simonetti and sakimichan tranding on artstation"
    },
    {
        "display": "Cybernetic warrior in space",
        "full": "Epic dark gritty space scene featuring the iridescent cloaked hooded warrior partially cybernetic entity god of future technology wielding cosmic weaponry, intricate, ornate, gothic, colorful, vibrant, smooth, moody, ominous and intense, crystallic, iridescent, lasers, gems, multicolored glints, precious elements, beautiful, detailed, concept art, render, unreal engine, 4K, artstation"
    },
    {
        "display": "\"Senna with giant weapon\"",
        "full": "senna from league of legends, au naturel, holding a giant weapon, with abs, brown skin, glowing green neon eyes, white robe, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Fantasy character portrait",
        "full": "character portrait by Magali Villeneuve and Steve Argyle,Livia Prima,Mucha,dress,fantasy art,beautiful,artstation,trending on artstation,intricate details,alluring,masterpiece"
    },
    {
        "display": "Elegant middle-aged halfling portrait",
        "full": "Brown haired halfling portrait, middle aged, dandy elegant fop diminutive by Anato Finnstark, Tony Sart highly detailed, digital illustration, concept art, distinguished"
    },
    {
        "display": "Happy Stephen Fry, Lovecraftian Pixar.",
        "full": "lovecraftian portrait of happy stephen fry, pixar style, by tristan eaton stanley artgerm and tom bagshaw"
    },
    {
        "display": "Joyful red-haired maiden",
        "full": "beautiful cute red haired joyful and playful nineteen year old maiden standing up in casual green clothing, long hair, attractive face, modern city, comic book, rpg character, sci - fi, fantasy, intricate, elegant, digital painting, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed, art by artgerm, greg rutkowski, magali villeneuve"
    },
    {
        "display": "Owl-bear hybrid concept art",
        "full": "cute hybrid animal with the head and beak of an owl and body of a bear, concept art, artstation"
    },
    {
        "display": "Realistic Dragonborn Portrait",
        "full": "dragonborn, ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Renaissance creation concept art",
        "full": "concept art of the creation of the human race by jama jurabaev, brush hard, artstation, high quality, brush stroke, soft lighting, renaissance style"
    },
    {
        "display": "Jake Gyllenhaal in crystal fantasy",
        "full": "jake gyllenhaal portrait, with a brilliant, impossible striking big shiny crystal headpiece, quartz, clothes entirely made out of crystal quartz, black hair, crystal background, symmetrical, dramatic studio lighting, rococo, baroque, hyperrealism, closeup, d & d, fantasy, intricate, elegant, highly detailed, asian, digital painting, artstation, octane render, 8 k, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic anime illustration",
        "full": "saitama, anime, epic scene, octane redner, colors, holy, full body,, manga, 8 k, illustration, atmosphere, unreal engine, video game, highly detailed, cgsociety, symmetrical, concept art, peter mohrbacher, charlie bowater, kelly mckernan, artstation, craig mullins, cinematic, digital painting, deviantart, hyperrealistic, octane render, cinematic lighting, 4 k"
    },
    {
        "display": "Dreamlike fey in cosmos.",
        "full": "hypersurreal dreamlike fey reclining on cosmic roots, infinity, nebula, smeared acrylic paint, concept art"
    },
    {
        "display": "Intricate digital matrix art",
        "full": "matrix, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Eden garden with palace.",
        "full": "Beautiful eden garden, next to a fountain and a mystical palace, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Victorian engineer portrait.",
        "full": "portrait of a vicotrian engineer man in suit by darek zabrocki and greg ruthkowski, alphonse mucha, simon stalenhag and cinematic and atmospheric, concept art, artstation, trending on artstation"
    },
    {
        "display": "Anime shadow goddess portrait",
        "full": "An anime goddess of shadows || VERY VERY ANIME!!!, fine-face, realistic shaded perfect face, fine details. Anime. realistic shaded lighting poster by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash and Rob Rey"
    },
    {
        "display": "Darth Vader Cappuccino Closeup",
        "full": "a closeup photorealistic photograph of darth vader making a cappuccino on an espresso machine. film still, vibrant colors. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Evil cyborg queen concept.",
        "full": "evil faceless cyborg android queen, ultra realistic, concept art, intricate details, serious, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by todd mcfarlane and artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Forest mage portrait.",
        "full": "portrait of a forest mage, female, buxom, dress made of green leaves, gorgeous, green hair, brown skin, curves, shapely derriere, forest background, digital painting, artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Punk Dragonborn Bard Portrait",
        "full": "portrait painting of a punk dragonborn bard with drumsticks, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Anthropomorphic steam engine monster.",
        "full": "beautiful painting of anthropomorphic steam railway engine monster truck snake oil salesman cowboy caveman hydra, neo - andean architecture art by takashi murakami, art by lisa frank, art by jacek yerka, art by victor moscoso. lifelike mechanical eyes. locomotive snake. trending on artstation, hyperrealism, 1 0 k"
    },
    {
        "display": "Cinematic Saul Goodman concept art.",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, hyperspace, vibrant colors, saul goodman"
    },
    {
        "display": "Frodo vs. Legolas battle",
        "full": "frodo fighting legolas, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal fairy pickle princess",
        "full": "headshot of a beautiful sexy pickle person ethereal magical young winged fairy princess wearing a white robe and flowers in her hair in a fantasy garden, d & d, fantasy, intricate, rim light, god rays, volumetric lighting, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by greg rutkowski, maxfield parrish and alphonse mucha, sunrise, new art nouveau, soft ambient lighting, particle effects"
    },
    {
        "display": "Fantasy forest landscape illustration.",
        "full": "forest clearing landscape, bright colors, fantasy, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wide angle, artbook, wallpaper, splash art, promo art, soul calibur, league of legends, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Cinematic female vampire illustration",
        "full": "female vampire, perfect face, gold waistcoat, red shirt, grey hair, red necktie, cinematic, stunning, highly detailed, digital painting, artstation, smooth, hard focus, full body shot, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant phallic banana artwork",
        "full": "a banana as a giant phallic symbol, highly detailed, elegant phallus, elegant, intricate, digital painting, artstation, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young Greek god portrait",
        "full": "a beautiful close - up of a young white - haired! greek god with very long curly hair and thin - eyebrows!, digital art by edward hopper, vibrant color scheme, highly detailed, in the style of romanticism, fine art, high detail, great lighting, 8 k resolution, masterpiece, concept art, illustration, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, hdr, trending on artstation, 4 k, 8 k, hd"
    },
    {
        "display": "Fantasy portrait of Branko Crvenkovski",
        "full": "a portrait of branko crvenkovski, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sci-fi portrait of Chris Pratt.",
        "full": "portrait of a man by Greg Rutkowski, Chris Pratt as James Holden in The Expanse Remake, scifi, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Grungy woman in rain.",
        "full": "a grungy woman with rainbow hair, soft eyes and narrow chin, dainty figure, long hair straight down, torn overalls, short shorts, combat boots, side boob, wet tshirt, raining, basic white background, symmetrical, watercolor, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, detailed, trending on artstation, hd, masterpiece,"
    },
    {
        "display": "Eyeless figure in cape.",
        "full": "person with no eyes wearing a dark red cape, fantasy, medieval wear, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, reimagined by alphonse mucha"
    },
    {
        "display": "Hippie man playing piano.",
        "full": "oil painting of a young man with long hair blond and a beard hippie style with his golden retrever dog playing piano in the square for money, people watching around, by greg rutkowski, artstation"
    },
    {
        "display": "Captain Picard with potato.",
        "full": "captain picard screaming holding holding a potato, wadim kashin, illustration by wadim kashin, 4 k, artstation, concept art"
    },
    {
        "display": "Cyberpunk woman portrait",
        "full": "very detailed masterpiece painting of a very beautiful wet young mexican cyberpunk woman with blue shutter shades, dark purple hair and a purple leather jacket, closeup, cyberpunk background, purple lighting, raining, portrait, artstation, concept art by greg rutkowski"
    },
    {
        "display": "Cyberpunk robotic surgery.",
        "full": "( cyberpunk 2 0 7 7, bladerunner 2 0 4 9 ), a complex thick bifurcated robotic cnc surgical arm cybernetic symbiosis hybrid mri 3 d printer machine making a bio chemical lab, art by artgerm and greg rutkowski and alphonse mucha, biomechanical, lens orbs, global illumination, lounge, architectural, f 3 2,"
    },
    {
        "display": "Potato wizard portrait",
        "full": "Portrait of wizard made of potato, D&D, face, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic pink-red metal insect.",
        "full": "a metal insect - like of female gundams like spider is in pink and red collection by merriam, daniel, intricate mechanical details, futuristic, 2 k aesthetic, dramatic lighting, 4 k, 3 d octane render, provenance, detailed, trending on artstation"
    },
    {
        "display": "Steampunk sneaker concept art",
        "full": "sneaker concept art, steampunk, sharp focus, illustration, bright colors, concept art by tooth wu"
    },
    {
        "display": "Detailed Doomfist Portrait",
        "full": "a closeup portrait of doomfist from overwatch, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Steampunk robot bug portrait",
        "full": "portrait shot of a steampunk robot bug, unreal engine realistic render, 8 k, micro detail, intricate, elegant, highly detailed, centered, digital painting, artstation, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Portrait of Old Jedi Luke",
        "full": "portrait of a man by Greg Rutkowski, old jedi Master Luke Skywalker from Star Wars Expanded Universe, he is about 60 years old, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ"
    },
    {
        "display": "Alien goddess photoshoot",
        "full": "a realistic photoshoot of a beautiful ancient alien woman goddess bella hadid standing in iris van herpen dress jewelery and fractals in style of alphonse mucha art nuvo dmt trending on artstation made in unreal engine 4"
    },
    {
        "display": "Sci-fi scientist character design",
        "full": "Front and back view of a woman in scientist jacket with a system of straps and pouches for collecting material by Tetsuya Nomura with Ralph Horsley and Mario Testino, trending on artstation and pixiv clean sci-fi concept art and sheet that using unreal engine 5 render and hyper detailed 3D texture with cinematic software light"
    },
    {
        "display": "Futuristic robotic tree house",
        "full": "a robotic and futuristic tree house, trending on artstation, hdr"
    },
    {
        "display": "Woman in minidress, cyberpunk city.",
        "full": "back of woman wearing stylish black minidress looking out a penthouse window at a panoramic view of a cyberpunk city at night, bokeh lights, anime, ilya kuvshinov, guweiz, artstation trending, concept art"
    },
    {
        "display": "Symmetrical Hercules portrait",
        "full": "symmetry portrait of hercules, glowing lights, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Evil Scarlet Witch Artwork",
        "full": "rafael albuquerque comic art, peter mohrbacher, phil noto, artgerm, pretty evil elizabeth olson scarlet witch comics, black and red dress, symmetrical eyes"
    },
    {
        "display": "Confident girl with wings",
        "full": "portrait of a confident beautiful girl with wings, digital painting, artstation, concept art, smooth, sharp focus, illustration, disney, symmetry face, fine details. art by alex ross, brittney lee"
    },
    {
        "display": "Fantasy character portrait",
        "full": "Leif GW Persson, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Cyborg portrait in sci-fi armor.",
        "full": "symmetry, portrait of cyborg, sci - fi armour, tech wear, cables, glowing lights, sci - fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Abstract digital art, happiness.",
        "full": "dreams of happiness, very beautiful, inspiring, thought provoking abstract digital art trending on artstation"
    },
    {
        "display": "Ghostly graveyard animation",
        "full": "an ultra detailed animation of a ghost in a graveyard at midnight on halloween, digital art, dark fantasy, concept art, soulslike, by alphonse mucha, blood moon eclipse, ruined building in the background, artstation, 8 k, unreal engine render"
    },
    {
        "display": "Cute tennis ball monster in space.",
        "full": "cinematic portrait of a cute tennis ball monster in space, neon lasers, chalk, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp details, hyper - detailed, hd, hdr, 4 k, 8 k, art by basil gogos"
    },
    {
        "display": "Mesopotamian witch portrait.",
        "full": "A seductive twenty year old Mesopotamian witch in training, fully clothed, brown skinned, in a major Mesopotamian city, portrait, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Portrait of Revy, highly detailed.",
        "full": "a portrait of revy from black lagoon manga, entire full body, symmetrical eyes, symmetrical face, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Sci-fi landscape art",
        "full": "beautiful! sci fi landscape code vein in the style of wlop, artgerm, yasutomo oka, yuumei, rendered in unreal engine and redshift octane, background is an epic sci fi building landscape by james gurney and simon stalenhag, digital art dynamic dramatic lighting, bokeh, imagine fx, artstation, cgsociety, zbrush central, by bandai namco artist, macross frontier, dynamic camera angle, art deco patterns"
    },
    {
        "display": "Fashionable Assamese cosplay art",
        "full": "fashion designing airbrush art by Krenz Cushart of a beautiful cute girl wearing modern stylish costume in the style of Assamese bihu mekhela sador gamosa design, slim female figure, elegant glamourous cosplay, exotic, ornamental, intricate, sensual pose, medium shot, mid-shot, highly detailed, trending on Artstation, featured on pixiv, golden ratio, sharp focus, cinematic lighting"
    },
    {
        "display": "Kurdish wedding portrait.",
        "full": "A beautiful Kurdish wedding with a beautiful kurdish bride and a handsome kurdish bridegroom, photo realistic painting, trending on Artstation, highly detailed, insanely detailed, award"
    },
    {
        "display": "Lifelike illustration of Dame Edna.",
        "full": "amazing lifelike award winning pencil illustration of dame Edna everage trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Nancy Kwan doll art",
        "full": "nancy kwan as a mark ryden doll, detailed digital art, trending on artstation"
    },
    {
        "display": "Witch portrait, anime style.",
        "full": "beautiful portrait of a Witch who looks like  Albedo, Overlord anime character design by Ross Tran, artgerm  detailed, soft lighting"
    },
    {
        "display": "DND Executioner Concept Art",
        "full": "concept art of a dnd executioner holiding a big axe, intricate details, detailed face, detailed clothes, artstation, epic pose, ambient light, by rembrandt"
    },
    {
        "display": "Spiderman as Fenrir breaking chains",
        "full": "'' Illustration Spiderman (Fenrir) breaking its chains, (night), (moon in the background), league of legends, Fenrir, LOL, fantasy, d&d,  digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha ''"
    },
    {
        "display": "Photorealistic Johnny Depp as God.",
        "full": "photorealistic johnny depp as god in the style of michael whelan and gustave dore. hyperdetailed photorealism, 1 0 8 megapixels, amazing depth, glowing rich colors, powerful imagery, psychedelic overtones, 3 d finalrender, 3 d shading, cinematic lighting, artstation concept art"
    },
    {
        "display": "Anime-inspired baroque portrait.",
        "full": "baroque oil painting anime key visual full body portrait character concept art of posing young maid nazi secret police, flowing blonde hair blue eyes, fitted black leather military commander nazi uniform, garter belt skirt, gap moe kuudere noble grimdark fantasy evil dictator, trending pixiv fanbox, by greg rutkowski genshin makoto shinkai takashi takeuchi studio ghibli jamie wyeth"
    },
    {
        "display": "Biopunk mermaid portrait.",
        "full": "underwater lofi scorn biopunk mermaid portrait, octopus, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Rimuru Tempest Portrait",
        "full": "full face shot of rimuru tempest, sky rgb 2 1 ece 6 blue straight hair, long bangs, with amber eyes, wearing a black jacket, high collar, ultra detailed, concept art, award winning photography, digital painting, cinematic, wlop artstation, closeup, pixiv, evil, yoshitaka amano, andy warhol, ilya kuvshinov,"
    },
    {
        "display": "Tribal Voodoo Priest Icon",
        "full": "tribal afrikan voodoo priest kimetic mask Video game icon design , highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, dramatic, cinematic sunset, hearthstone, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "\"Corporate Black Woman Portrait\"",
        "full": "detailed digital painting of beautiful black woman in corporate attire holding a cell phone and steaming coffee mug, fanart behance trending on artstation, concept art, matte, sharp focus, illustration, corner office background, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Aang Portrait, Photorealistic Art",
        "full": "highly detailed full body portrait Aang the Last Airbender, concept art, digital art, studio lightning, bright colors, intricate, masterpiece, photorealistic, hiperrealistic, sharp focus, high contrast, intricate, Artstation HQ, DeviantArt trending, 4k UHD, Unreal Engine 5"
    },
    {
        "display": "Ultra-realistic power station.",
        "full": "photo of 8k ultra realistic power station , full of colour, cinematic lighting, battered, trending on artstation, 4k, hyperrealistic, focused, extreme details,unreal engine 5, cinematic, masterpiece, art by Peter Mohrbacher"
    },
    {
        "display": "Happy Mask Salesman Artwork",
        "full": "a cinematic still of the happy mask salesman, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Mad Max abstaining from alcohol.",
        "full": "painting of a mad max abstaining from alcohol, anatomy visible, clear - lines, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "\"Nicolas Cage Screaming Art\"",
        "full": "nicolas cage screaming , highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and alphonse mucha, high definition digital art, in the style of ilya kuvshinov and Ross tran"
    },
    {
        "display": "Naruto eating black hamburger.",
        "full": "highly detailed black hamburger eating naruto uzumaki with black hair, art by greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, tom bagshaw, global illumination, radiant light, detailed and intricate environment"
    },
    {
        "display": "Epic portrait of visionary woman",
        "full": "epic portrait of slightly muscular indigenous visionary woman wearing short sleeved instructor uniform pointing laser pointer on construction blueprint, teacher, glasses, clean, detailed, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, boris vallejo, breathtaking, 8 k resolution, extremely detailed beautiful, establishing shot, artistic hyperrealistic, beautiful face, enhanced hands, octane render"
    },
    {
        "display": "Realistic blonde man portrait",
        "full": "a realistic portrait of a blonde man with big lips, 1 2 0 0 bc, edward hopper, trending on artstation"
    },
    {
        "display": "Putin as Hitler portrait",
        "full": "vladimir putin as hitler, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Modern harbor scene.",
        "full": "a modern harbor with a concrete dock and a storage facility, european city skyline, summer season, very hot, dry desert, large sun in sky, architecture, a realistic digital painting by greg rutkowski and james gurney, trending on artstation, highly detailed"
    },
    {
        "display": "Vibrant Cuban Car Interior",
        "full": "cuban car interior, colorful, contrast, depth of field, 3 d scene, render, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Anime girl in vibrant valley",
        "full": "anime girl in beautiful green valley and blue skies digital art by greg rutkowski  by Makoto Shinkai trending on artstation pixiv, octane render, dynamic lighting, intricate detail, summer vibrancy, cinematic cgsociety, Award Winning, hyperdetailed Unreal Engine 4k 8k ultra HD, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean Marc Simonetti"
    },
    {
        "display": "Sam Gray in sombrero portrait",
        "full": "Portrait of Sam Gray wearing a sombrero, by Cedric Peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on ArtStation"
    },
    {
        "display": "Bunnygirl in kimono, Tokyo.",
        "full": "bunnygirl, kimono, night, tokyo street, 4 k, ultra realistic, beautiful eyes, epic lighting, machines, high detail, masterpiece, trending on artstation by artgerm and akihito tsukushi and alphonse mucha, crayons!!!!!!!!!!!!!!!"
    },
    {
        "display": "Cyborg mercenary portrait",
        "full": "beautiful portrait of a cyborg mercenary girl holding a rifle, art by wlop and artgerm, cyberpunk, neon, elegant, highly detailed, trending on artstation, sharp focus, caustics, octane render, radiant light, 4 k"
    },
    {
        "display": "Cosmic turtle in nebula.",
        "full": "AN 8K RESOLUTION, MATTE PAINTING OF THE WISE AND ANIENT COSMIC TURTLE, FLYING THROUGH a rainbow nebula BY BOB EGGLETON AND MICHAEL WHELAN. TRENDING ON aRTSTATION, hd, highly detailed, vibrant colors, astrophotography, volumetric lighting, dynamic portrait, wide lens, mass effect game art"
    },
    {
        "display": "Dodo bird on cactus.",
        "full": "A dodo bird perched atop a Saguaro cactus in the desert, full body, digital art, trending on Artstation, high detail, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha."
    },
    {
        "display": "Seductive Yuna portrait, surreal.",
        "full": "praying seductive looking yuna final fantasy x, windy hair!!, muted tones, surreal dark fantasy, cinematic volumetric lights, full body portrait, dnd, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and tom bagshaw and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed humanoid Zeus illustration.",
        "full": "zeus humanoid god of the thunder, charming and alluring face, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Creepy cyberpunk clown girl",
        "full": "by kyoto animation, very creepy clown girl, wearing cyberpunk intricate streetwear, beautiful, detailed portrait, intricate complexity, ilya kuvshinov, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Detailed portrait of Mrs. Mills",
        "full": "mrs mills, portrait, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, oil paint, epic, octane render"
    },
    {
        "display": "Elegant watercolor portrait.",
        "full": "young boy, black hair, light muscles, gorgeous, amazing, delicate, elegant, intricate, highly detailed, watercolor, portrait, artstation, concept art, sharp focus, illustration, art by artherm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "\"Translucent coral deity helmet\"",
        "full": "Helmet of a forgotten Deity, translucent corals, extremly detailed digital painting, in the style of Fenghua Zhong and Ruan Jia and jeremy lipking and Peter Mohrbacher, mystical colors, rim light, beautiful lighting, 8k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Surreal dream guide artwork",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Dream guide. By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon and Edward Hopper, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, triadic color scheme, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Smiling surgeon portrait",
        "full": "portrait of a smiling surgeon, clear - lines, grim - lighting, high - contrast, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Angry anime girl in iridescent realm",
        "full": "ultra detailed illustration of a angry indian anime girl, skin covered in a sea of iridescent liquid, chrome metal material, lost in a dreamy oriental realm by Karol Bak, Moebius, hiroshi yoshida, Druillet, xsullo, WLOP, colorful, lens distortion, vivid colors, 8k, coherent, anime vibes, uplifting, magical composition, artstation, synthwave, 8k, coherent, artgerm, uplifting, unreal engine, magical composition, artstation"
    },
    {
        "display": "Baroque anime portrait: Nazi maid.",
        "full": "baroque oil painting anime key visual full body character portrait concept art, quiet young maid nazi secret police with long flowing blonde hair blue eyes, aesthetic of evil nazi black leather uniform, gap moe kuudere noble grimdark fascist dictator, trending pixiv fanbox, rule of thirds golden ratio, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Macron as Star Wars Senator",
        "full": "painting of Emmanuel Macron dressed as in Star Wars,(((( inside the galactic senate)))), sharp focus, trending on ArtStation, masterpiece, by Greg Rutkowski, by Ross Tran, by Fenghua Zhong, octane, clear eyes, soft render, clear facial features, oil on canvas, moody lighting, cinematic, professional environment concept art"
    },
    {
        "display": "Adventurous boy, dynamic pose.",
        "full": "clear portrait of adventurous boy with blue hair, slightly spikey hair, red running shoes, fast runner, background hyper detailed, character concept, full body, dynamic pose, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Magical music festival scene",
        "full": "a bustling magical town with witches and black cats dancing around a colorful music festival in its center, inspired by victorian england and amsterdam, highly detailed, intricate, digital painting, trending on artstation, concept art, matte painting, art by greg rutkwowski, craig mullins, octane render, 8 k, unreal engine"
    },
    {
        "display": "Minimalist neon store interior",
        "full": "accurate pbr materiality ( ( minimalist neon flagship samsung store, white walls. beautiful oak parquet floor. hanging lights. geometric furniture, large digital screens. display tables with phones and tablets, potted plants ) ) concept art, john berkey, makoto shinkai, yoji shinkawa, artstation, 4 k"
    },
    {
        "display": "Alison Brie with mustache",
        "full": "ultra realistic illustration, alison brie wearing a curly english mustache, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Voluptuous Princess Jasmine as Poison Ivy",
        "full": "a stunning matte portrait of a thicc and voluptuous princess jasmine dressed as a beautiful poison ivy with hair tied in a braid walking through a flowering garden, greenhouse in the background, intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by jugendstil and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Dusty field with orcish tanks",
        "full": "concept art of a dusty field with ruined dieselpunk orcish tanks in the background, key visual, ambient lighting, highly detailed, digital painting, artstation, concept art, sharp focus, by makoto shinkai and akihiko yoshida and hidari and wlop"
    },
    {
        "display": "Elon Musk as God Emperor",
        "full": "the god emperor of mankind with elon musk's face by greg rutkowski, stanley artgerm lau and jason chan highly detailed, cinematic lighting, octane render, unreal engine lumen, very coherent. cinematic, hyper realism, high detail, octane render, 8 k"
    },
    {
        "display": "Futuristic witch in cityscape",
        "full": "Tall beautiful slender female witch with dark hair, futuristic, alien cityscape, artstation, graphic novel, colourful, 2D matte, art by Stanley Artgerm David Rub\u00edn, Mike Mignola, Laurie Greasley,"
    },
    {
        "display": "Stylish anime girl painting",
        "full": "A ultradetailed beautiful panting of a stylish anime girl, she is wearing a New York Knicks basketball jersey, Oil painting, by Ilya Kuvshinov, Greg Rutkowski and Makoto Shinkai"
    },
    {
        "display": "Bard playing tuba portrait",
        "full": "bard playing tuba by charlie bowater and titian and artgerm, full body portrait, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Colorful Utopian Galaxy Couture",
        "full": "colorful utopian Galaxy, raf simons fashion couture, intricately detailed tiny humanoid inside an hourglass in the style of Emiliano Ponzi and  Chris Ware, futuristic 1990s contemporary art, sci-fi,eye glass, inside view, humanoid pov, intricate artwork by Tooth Wu and wlop and beeple, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork, depth field, unreal engine, cinematic, hyper realism, high detail, octane cinema 4D render, A24 cinematography, 8k"
    },
    {
        "display": "\"Rimuru Tempest Portrait\"",
        "full": "rimuru tempest, tensei shitara slime datta ken, super highly detailed, professional digital painting, concept art, smooth, sharp focus, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k, cinema 4 d, 3 d, beautiful, black hoodie, cuffed sleeves, cinematic, art by artgerm, yoshitaka amano and junji ito"
    },
    {
        "display": "Cassandra Cain college student.",
        "full": "cassandra cain as a college student, drinking coffee, bokeh, beautiful face!!!!, 2 7 years old, cg animation, lifelike, animated, realistic, character select portrait, by artgerm, greg rutkowski, alphonse mucha, 3 d"
    },
    {
        "display": "Medusa creature portrait.",
        "full": "anonymous as a medusa, award winning creature portrait photography, extremely detailed, artstation, 8 k, sensual lighting, incredible art, wlop, artgerm"
    },
    {
        "display": "Tron Jesus in forest",
        "full": "tron legacy jesus performing miracles for a crowd in an ancient forest, face, diffuse lighting, hyper realistic, concept art, intricate, hyper detailed, smooth, sharp focus, illustration, artstation, art by greg rutkowski and james gurney and alphonse mucha"
    },
    {
        "display": "Symmetrical sci-fi cat portrait",
        "full": "symmetry!! portrait of a cat, sci - fi, tech wear, blue and yellow glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Darth Vader as Christo Redentor",
        "full": "a highly detailed matte painting of the darth vader as the christo redentor standing over rio de janeiro, ultrawide lens, by dan mumford, yusuke murata, makoto shinkai, ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Man tied to pillar, dog.",
        "full": "a man tied to a pillar and jack russel terrier pissing on him, highly detailed, hyperrealistic digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant Welsh princess portrait",
        "full": "symmetry portrait of welsh brunette princess, glam, fireflies, forest background, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and fra angelico and alphons mucha"
    },
    {
        "display": "Anime Cleopatra Portrait",
        "full": "The Portrait of Cleopatra, Anime Fantasy Illustration by Tomoyuki Yamasaki, Kyoto Studio, Madhouse, Ufotable, trending on artstation"
    },
    {
        "display": "Underwater woman with octopus hat",
        "full": "close up of a beautiful woman underwater wearing sunglasses and a colourful octopus as a hat, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, 8 k, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical Psycho Portrait",
        "full": "symmetry!! portrait of crowned! borderlands 3 ( male ) psycho, ( beard ) ( scars ) ( wounds ) ( piercings ), intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Lifelike survivalist portrait.",
        "full": "ultra - realistic!! lifelike!! high details!! face!! portrait!! of a survivalist woman in a post apocalyptic city, gorgeous cinematic digital concept art trending on artstation by senior concept artist"
    },
    {
        "display": "Demon hero character portrait",
        "full": "human male demon, full body white purple cloak, focus, closup, portrait, hero, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Anime fantasy battlefield illustration",
        "full": "baroque oil painting japanese light novel cover illustration lotr anime key visual full body action shot | young girl black military crusader armour with long flowing brown hair fantasy battlefield scene | symmetrical perfect face fine detail delicate features quiet gaze | gapmoe kuudere moody lighting bokeh | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Plague doctor portrait.",
        "full": "portrait of woman in a plague doctor mask, tiny top hat, long brown dress, photorealistic, highly detailed, studio lighting, smooth, sharp focus, art by michael whelan, artgerm, greg rutkowski and luis royo"
    },
    {
        "display": "Pop Art Neanderthal Illustration",
        "full": "saint homo neanderthalensis. pop art, pixel, bioshock art style, gta 4 art style, dynamic proportional, dynamic composition, face features, body features, ultra realistic art, digital painting, concept art, smooth, sharp focus, illustration, intricate, without duplication, elegant, confident posse, art by artgerm and richard hamilton and mimmo rottela"
    },
    {
        "display": "Detailed iPhone 21 oil painting",
        "full": "iphone 21, RPG Reference, Oil Painting, Trending on Artstation, octane render, Insanely Detailed, 8k, HD"
    },
    {
        "display": "Ultron concept art on new planet",
        "full": "environment concept art of ultron in new planet by jama jurabaev, very long shot, trending on artstation, high quality, brush stroke, for aaa game"
    },
    {
        "display": "Indonesian supermodel portrait.",
        "full": "portrait of a beautiful indonesian supermodels wearing traditional costume, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by kittichai rueangchaichan and james gurney and alphonse mucha"
    },
    {
        "display": "Jason Statham as Mongol Archer",
        "full": "jason statham ultra realistic illustration, mongol horse archer warrior in style armor from 1 9 3 2, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Minion napping at night.",
        "full": "a minion napping in a field behind a beautiful mountain landscape, night setting. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Fantasy portrait of woman",
        "full": "full portrait of a young, attractive woman, soft hair, muscular, half body, cloth, pink hair, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pineapple field with balloons",
        "full": "a sea of pineapples under a big balloon festival, smooth, trending on artstation, high quality, high detail, beautiful"
    },
    {
        "display": "Hybrid creature concept art",
        "full": "painting of hybrid spider & cat & dragon & monkey, intercrossed animal, cat has eight legs, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "\"Hyperrealistic waitress portrait\"",
        "full": "an portrait of a waitress serving a drink, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, WLOP, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render, cinematic lighting, dramatic lighting, masterpiece, light brazen, extremely detailed and beautiful face"
    },
    {
        "display": "Oscar Isaac as Star Trek captain",
        "full": "oscar isaac as a star trek captain, a still from star trek painted by artgerm and greg rutkowski and alphonse mucha. clear highly detailed face, beautiful sci fi art"
    },
    {
        "display": "Pink Floyd in Art Deco",
        "full": "pink floyd sitting in a red sofa in an art deco living room, award - winning realistic sci - fi concept art by ilya kuvshinov, hayao miyazaki, kentaro miura, thomas kinkade, beksinski, bruegel, greg rutkowski, alphonse mucha, and yoshitaka amano, digital painting, masterpiece, gorgeous, beautiful, cinematic, dynamic volumetric lighting, dust clouds and building debris, intense, dramatic"
    },
    {
        "display": "Hybrid elephant-dragon painting",
        "full": "painting of hybrid between elephant & dragon!!!!!, intercrossed animal, mixture animal, by tiffany bozic, by zdzislaw beksinski, by vladimir kush, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Funky fashion portrait.",
        "full": "german girl wearing a ( ( ( ( funky yellow bubble jacket ) ) ) ), green beanie, gray shirt, ( ( ( ( ( thick eyebrows ) ) ) ), dark red lips, ( ( ( round eyeglasses ) ) ), highly detailed, synth wave, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed fantasy artwork",
        "full": "fantasy world, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Stunning alien creature.",
        "full": "the most beautiful alien being that ever existed - art by greg rutkowski"
    },
    {
        "display": "First-person cyberpunk mall scene",
        "full": "first person view with a gun of a abandoned cyberpunk shopping mall, cinematic lightning, ray tracing, unreal engine, photorealistic fps game concept art, detailed, dark, moody, foggy"
    },
    {
        "display": "Paul Dano as Monkey King.",
        "full": "Paul Dano as monkey king, sci-fi, amber eyes, face, long hair, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Giant fish flying over city.",
        "full": "spectacular epic shot film still by marvel, massive giant japanese fishes flying over a new city building, stormy weather with rain and godrays, night time, water bubble particles stream in the air one fish close to camera, high detailed render, windows and sun reflection, id magazine, hyperrealism, detailed textures, photorealistic, hyper detail vfx, weta digital, dneg, ilm, high details photorealistic city, ultra realistic, cinematic, intricate, cinematic light, unreal engine 8 k, octane render, unreal engine, david kostic, artgerm"
    },
    {
        "display": "Intricate fantasy hellscape.",
        "full": "landscape hellscape, d & d, fantasy, intricate, very beautiful, highly detailed, elegant, digital painting, artstation, concept art, matte, smooth and sharp focus, rpg artwork, illustration, by rene maritte and tian zi and wlop and alsphonse mucha and artgerm and and pino daeni and dan mumford,"
    },
    {
        "display": "Scientist concept art, detailed.",
        "full": "concept art of scientist by jama jurabaev, brush stroke, scifi accessories, trending on artstation, symmetry, high quality, extremely detailed"
    },
    {
        "display": "Elegant fantasy tarot card",
        "full": "matteo salvini, wearing a suit, tarot card, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, italian flag, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ethereal cowboy campfire scene",
        "full": "a dramatic, epic, ethereal painting of a handsome thicc shirtless cowboy beer belly smiling wearing a leather belt offering a jug | background is a late night campfire with food and jugs of  whisky | tarot!! card, art deco, art nouveau, homoeroticism, detailed face | by Mark Maggiori | trending on artstation"
    },
    {
        "display": "Elegant orcish noble portrait",
        "full": "portrait of a formal noble! barbarian orcish giant, imperial royal elegant clothing, rule of thirds, extremely detailed, striking, beautiful, artstation, concept art, matte, digital painting, sharp focus, art by greg rutkowski"
    },
    {
        "display": "Anime Skyrim character, cloak.",
        "full": "a render of a small character from skyrim in an anime style with a long fabric cloak looking at the sky and facing away from the camera. there is shiny a liquid reflecting everything from below. 8 k, unreal engine, blender, vaporwareaesthetics, trending on artstation"
    },
    {
        "display": "Fantasy druid warrior portrait.",
        "full": "a realistic and atmospheric watercolour fantasy character concept art portrait of brigitte bardot in her 2 0 s as a druidic warrior wizard looking at the camera with an intelligent gaze by rebecca guay, michael kaluta, charles vess and jean moebius giraud"
    },
    {
        "display": "Symmetrical sci-fi portrait",
        "full": "symmetry!! portrait of rust cohle, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Aztec jaguar warrior boy",
        "full": "a highly detailed illustration of fierce young aztec jaguar warrior boy wearing green jaguar mane, heroic roaring wielding aztec macuahuitl pose, muscular, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop"
    },
    {
        "display": "Anime female baseball player.",
        "full": "anime style, female baseball player, red sport clothing, baseball bat, launching a straight ball, brown short hair, hair down, symmetrical facial features, from arknights, hyper realistic, rule of thirds, extreme detail, 4 k drawing, safebooru, realistic lighting, by alphonse mucha, greg rutkowski, sharp focus, backlit"
    },
    {
        "display": "Nordic angel building time machine",
        "full": "hyperrealistic photography of a highly detailed and symmetrical gorgeous nordic female angel constructing a time machine in the style of Jin Kagetsu, James Jean and wlop, highly detailed, face symmetry, highly realistic hands, masterpiece, award-winning, sharp focus, intricate concept art, ambient lighting, 8k, artstation"
    },
    {
        "display": "Daft Punk member portrait.",
        "full": "painting Daft Punk member in black cloak, elegant, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hyper-realistic Charr close-up",
        "full": "Charr tempest of Guild Wars 2, concept art, close-up, digital art, hyper-realistic, highly detailed"
    },
    {
        "display": "Dreamy girl in leather.",
        "full": "dreamy girl character with perfect body in a nice black leather suit and red lips, very artistic pose, perfect lighting. professional design. great composition, illustration, highly detailed, digital painting, concept art, trending on artstation, unreal engine 5, ray tracing"
    },
    {
        "display": "Errol Flynn, dystopian scientist.",
        "full": "Errol Flynn as a scientist. 1980s dystopian Soviet Russia, propaganda screens. Fantasy art by Greg Rutkowski, Gustave Courbet, Rosa Bonheur, Edward Hopper. Faithfully depicted facial expression, perfect anatomy, sharp focus, global illumination, radiant light, detailed and intricate environment, trending on ArtStation"
    },
    {
        "display": "Neon ancient monster portrait",
        "full": "ancient neon monster portrait, fixed eyes, intricate artwork by josan gonzalez, artgerm, h. r. giger, kilian eng, very coherent artwork, cinematic, hyper realism, vibrant, octane render, unreal engine, 8 k, high contrast, higly detailed black ink outline, super high definition,"
    },
    {
        "display": "Steampunk pirate escape room.",
        "full": "pirate themed escape room set on the deck on pirate ship from 1 7 2 0. steampunk and magical colors. trending on artstation, realistic."
    },
    {
        "display": "Bioluminescent axolotl wizard portrait",
        "full": "Storytime perspective Bioluminescent, portrait of axolotl wearing wizard hat,  very intricate , trending on artstation , very elegant, in the golden hour by Daniel Merriam, Trending on Artstation, oil on Canvas by Elena Zhurikhina and Goro Fujita and Charlie Bowater, octane render, 4k, 8k, HD"
    },
    {
        "display": "Anime girl beach portrait",
        "full": "A portrait of a smiling anime girl on the beach near the ocean, young child, medium shot, whole head, trending on artstation, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimi chan, anime portrait, official anime artwork"
    },
    {
        "display": "Skull robot in game style.",
        "full": "skullrobot, style game life is strange of square enix, trending on artstation, painted by greg rutkowski, render with game the last of us parte ii details"
    },
    {
        "display": "Elven woman in white dress.",
        "full": "an elven woman with long, silver hair cascading down her back. she has delicate, angular features and piercing blue eyes. she's clad in a flowing white dress with intricate silver embroidery, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, creative cinematic, ultra detailed"
    },
    {
        "display": "Epic fantasy battle scene",
        "full": "epic battle between human warriors mages and demons, inside cathedrals and abbeys, fullbody!! dynamic action pose, religious, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surrealist chess machine concept.",
        "full": "a concept drawing of marcel duchamp holding up a chess - piece wire - machine, a surrealist painting by marcel duchamp, complex artificial - intelligence machinery, minimal sketch flow - chart, academic art, 1 9 2 0 s"
    },
    {
        "display": "Pheasant with sword, hyperrealistic.",
        "full": "Pheasant holding a sword, by lily seika jones , rivuletpaper art, very detailed, oil painting, harsh fairy tale, soft style, hyperrealism, beautiful, high resolution, trending on artstation, steps 50"
    },
    {
        "display": "Futuristic sci-fi portrait",
        "full": "symmetry!! solid cube of light, hard edges, product render retro - futuristic poster scifi, lasers coming from eyes, brown skin man, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, dreamlike, art by artgerm"
    },
    {
        "display": "Colorful Shaolin Village Masterpiece",
        "full": "shaolin village, sharp focus, wide shot, trending on artstation, masterpiece, by greg rutkowski, by ross tran, by fenghua zhong, octane, soft render, oil on canvas, colorful, cinematic, environmental concept art"
    },
    {
        "display": "Elegant ballroom portrait",
        "full": "sansa emma watson in ballroom in red, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Realistic portrait of woman butterfly.",
        "full": "highly detailed portrait of a beautiful woman butterfly with scarlet lips, tartan hoody, photographic realistic background, ringlet hair by atey ghailan, by greg rutkowski, by greg tocchini, by james gilleard, by joe fenton, by kaethe butcher, gradient pink, black, violet, cream and white color scheme, trending in pinterest, award winning details"
    },
    {
        "display": "Neon Killua Zoldyck portrait.",
        "full": "neon killua zoldyck portrait, made by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, artstation, cgsociety, lighting,"
    },
    {
        "display": "Elizabeth Olsen as Earth Goddess",
        "full": "elizabeth olsen as the goddess of earth!!!, golden ratio!!!!!, centered, trending on artstation, 8 k quality, cgsociety contest winner, artstation hd, artstation hq, luminous lighting"
    },
    {
        "display": "Cute friendly demon portrait.",
        "full": "cute friendly demon by christian dimitrov and titian and eddie mendoza and artgerm, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Dystopian wrestlers and mackerel.",
        "full": "mutant male and female wrestlers posing in front of volkswagen t5 spray painted with massive mackerel, in in a dystopia Miami city , intricate, highly detailed, concept art,  Octane render"
    },
    {
        "display": "Fantasy woodland village night",
        "full": "painting, high detail, woodland village, in the night, fantasy, crescent moon, stone paths, bridge, water stream, luminous, toadstools, fireflies, fantasy,, flowers, waterfall, lanterns, mist, highly detailed painting, fine lines, 8 k realistic, sharp focus"
    },
    {
        "display": "Elegant cottagecore portrait",
        "full": "clear portrait of stefania ferrario, cottagecore!!, background hyper detailed, character concept, full body, dynamic pose, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Moody chaotic god illustration.",
        "full": "realm of impossible, illustration, moody, chaotic, god, mist, nuclear explosion background, volumetric lighting, soft lighting, soft details, painting oil on canvas by William-Adolphe Bouguereau and Edmund Blair Leighton, octane render, HDR, trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Ripped politico portrait.",
        "full": "ripped physique Portrait of Rush Limbaugh walrus politico standing atop a radio station whilst wearing headphones whilst brandishing a microphone greg rutkowski edgar degas ilya repin giorgio de chirico salvador dali giotto leonardo da vinci paul cezanne edward hopper"
    },
    {
        "display": "Faceless queen portrait.",
        "full": "full body portrait of a queen with no face wearing a crown in a ruined building, braided hair, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Hyperrealistic League of Legends Pinup",
        "full": "Emma Duncan, pinup, league of legends, intricate, highly detailed, digital painting, hyperrealistic, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha, by Jesper Ejsing"
    },
    {
        "display": "Cute fluffy skeleton creatures",
        "full": "cute fluffy animal skeleton creatures. hearthstone animal creatures, graveyard background,  bright art masterpiece artstation. 8k, sharp high quality artwork in style of Jose Daniel Cabrera Pena and Greg Rutkowski, violet theme, concept art by Tooth Wu, hearthstone card game artwork"
    },
    {
        "display": "Anime pit bull illustration",
        "full": "photo of a skinny dark gray coat pit bull with a white paws and a white nose!, anime digital art, highly detailed, digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Kirby steals Avengers' powers.",
        "full": "highly detailed vfx portrait of kirby stealing the avenger's powers, stephen bliss, greg rutkowski, loish, rhads, beeple, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, stanley kubrick, backlit, harsh overhead sunlight,"
    },
    {
        "display": "Nell Carter as mob boss.",
        "full": "portrait of nell carter as an italian mob boss, posed, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Steampunk stag beetle model",
        "full": "steampunk stag beetle, 3 d model, unreal engine realistic render, 8 k, micro detail, intricate, elegant, highly detailed, centered, digital painting, smooth, sharp focus, illustration, artgerm, by wlop"
    },
    {
        "display": "Elegant Polish woman portrait",
        "full": "Sensual beautiful perfect Polish woman, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vibrant dieselpunk portrait.",
        "full": "vibrant face portrait of a diesel punk margot robbie on the art deco streets of the big city, symmetrical face, 3 d anime, award - winning realistic sci - fi concept art by jim burns and greg rutkowski, picasso, beksinski, masterpiece, complimentary colors, james gilleard, bruegel, alphonse mucha, and yoshitaka amano"
    },
    {
        "display": "Boris Johnson Arrested by Space Marines",
        "full": "boris johnson, perfectly - centered - painting of boris johnson being arrested by warhammer space marines, sweaty, dynamic action pose, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, photography, wide angle, zeiss lens, eos, canon, nikon, depth of field, bokeh, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Furry cyberpunk mountain lion.",
        "full": "award winning beautiful portrait commission of a male furry anthro albino mountain lion fursona with a tail and a cute beautiful attractive detailed furry face wearing stylish cyberpunk clothes in a cyberpunk city at night while it rains. Vaporwave. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Putin funeral portrait illustration",
        "full": "putin funeral, portrait, highly detailed, full body, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "1920s blonde housewife portrait",
        "full": "smiling, happy, beautiful, intelligent, powerful, 1 9 2 0 s, blonde housewife, 2 8 years old, loving eyes, fully clothed, wise, beautiful, dramatic lighting, sharp focus, by stanley artgerm, dramatic lighting, trending on artstation, flat colour, geometric curves, gradient filter, art deco patterns"
    },
    {
        "display": "Kylie Minogue Pancake Animation",
        "full": "kylie minogue making pancakes, animation pixar style, by pendleton ward, magali villeneuve, artgerm, rob rey and kentaro miura style, golden ratio, trending on art station"
    },
    {
        "display": "Sci-fi YouTube interface artwork.",
        "full": "inside the youtube main frame computer system, sci - fi fantasy, cinematic, detailed, concept art, low angle, macro detail, vivid, trending on artstation, by jordan grimmer, hart greg rutkowski"
    },
    {
        "display": "Eerie draconic humanoid portrait",
        "full": "portrait painting of draconic humanoid, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Intricate Kraken Portrait",
        "full": "kraken by christian dimitrov and titian and eddie mendoza, intricate, face, elegant, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Villainess Portrait in Rococo",
        "full": "daniel gerhartz and artgerm detailed portrait digital rococo painting of a beautiful serious villainess wearing fantasy clothing like liliana vess, villainess has black angel wings, evil mood, hellish battlefield in the background, embers flying, unreal engine, hyper realism, realistic shading, cinematic composition, blender render, octane render, detailed textures, photorealistic, ultrawide shot"
    },
    {
        "display": "Hybrid dragon-monkey-bear painting.",
        "full": "painting of hybrid between dragon!!!!!! & monkey & bear, intercrossed animal, mixture animal, by tiffany bozic, by zdzislaw beksinski, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Anime female doctor hero",
        "full": "anime key visual of a beautiful young female doctor fate!! intricate, cape, glowing, powers, dc comics, cinematic, stunning, highly detailed, digital painting, artstation, smooth, hard focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pale Indian doctors in space",
        "full": "Sensual good looking pale young Indian doctors wearing jeans floating in a space station above Earth performing surgery, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mythical dragon, Mount Fuji",
        "full": "an ultrawide photo of mythical dragon in front of mount fuji, studio ghibli concept art, detailed face, beautiful face, beautiful eyes, beautiful lighting, hdr, 4 k"
    },
    {
        "display": "Modern consumerism still life",
        "full": "a contemporary still life painting of modern consumerism by simon stalenhag and thomas kinkade, oil on canvas, a table covered with blue cloth, coca - cola can, a red cigarette pack, a vase of flowers, tea light candles, trending on artstation"
    },
    {
        "display": "Character design concept.",
        "full": "man with a beautiful face, character sheet, concept design, contrast, hot toys, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Colorful fantasy starship illustration",
        "full": "new starship in no man's sky colorful, fantasy, intricate, highly detailed, digital painting, hq, trending on artstation, illustration, style of stanley artgerm and greg rutkowski and dan mumford"
    },
    {
        "display": "Andrew Tate as young woman",
        "full": "portrait of andrew tate as a beautiful young woman, cute, trending on artstation, realism, realistic, photorealism,, f 3. 5, behance hd, beautiful, soft"
    },
    {
        "display": "Fairy on bookshelf painting",
        "full": "a painting of a tiny fairy flying sitting on a bookshelf, ((((library interior)))), Blond hair, white long elaborate dress!!! Profile portrait, concept art by artgerm, alex ross, ruan jia, behance contest winner, vanitas, official art, poster art, concept art"
    },
    {
        "display": "Symmetrical sci-fi portrait of Marie Curie",
        "full": "symmetry!! portrait of marie curie female, sci - fi, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Cyberpunk penthouse view",
        "full": "looking outside through the glass wall of a penthouse suite at the top of the highest skyscraper in the middle of a cyberpunk city at night in rain, very detailed, beeple, trending on artstation, cinematic"
    },
    {
        "display": "Spooky fantasy character art",
        "full": "spooky nerds, stunning intricate concept art by senior character artist, fantasy art, matte painting, storybook illustration"
    },
    {
        "display": "Chromatic goddess portrait.",
        "full": "chromatic goddess of colors, d & d, full body portrait, mixed media, seductive look, chromatic aberration, hyper realistic, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration, art by wlop and artgerm and greg rutkowski and ayami kojima"
    },
    {
        "display": "Centaurs in battle, fantasy.",
        "full": "painting of centaurs in battle, d & d, classical, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Strawberry-eyed creature concept",
        "full": "strawberry creature with multiple eyes concept art"
    },
    {
        "display": "Mustached man with brain",
        "full": "the mustached man with the huge brain in sedona, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Cyberpunk Bob Marley Portrait",
        "full": "a cyberpunk portrait of a bob marley by jean - michel basquiat, by hayao miyazaki by artgerm, highly detailed, sacred geometry, mathematics, snake, geometry, cyberpunk, vibrant, water"
    },
    {
        "display": "Rococo-cyberpunk android statue",
        "full": "full-body rococo and cyberpunk style porcelain and quartz statue of a young attractive Brasilleiro android novinho gostoso e dotago falling from the sky, glowing white laser eyes, prince crown of pink gears, diamonds, swirling silver-colored silk fabric. futuristic elements. full-length view. space robots. human skulls. intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Fairy-winged pixie character.",
        "full": "3 / 4 view of a woman with fairy wings, confident pose, pixie character, video game genshin impact, synthwave style, elegant, sharp focus, illustration, highly detailed, artgerm, anime, trending on artstation"
    },
    {
        "display": "Cinematic Machu Picchu concept art",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, machu picchu"
    },
    {
        "display": "VR shaman in glowing forest",
        "full": "1 6 mm, film, tight shot of subject, a vr shaman rediscovering himself on psychedelics in a mystical glowing forest in the style of juan jimenez, mike mignola, vibrant colors and hard shadows and strong rim light, perfect details, comic character design, trending on artstation, 3 d render, smooth render, green, wlop"
    },
    {
        "display": "Samurai in enchanted forest.",
        "full": "samurai in enchanted forest, prismatic highlights, atmosphere, gorgeous, depth of field, cinematic, macro, concept art, 50mm, artstation, wlop, elegant, epic, weta digital, focus, octane render, v-ray, 8k, kodak portra, art by Asaf Hanuka"
    },
    {
        "display": "Scary fantasy portal scene.",
        "full": "a scary portal to another world in darwin's arch, ocean and rock landscape, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and rembrandt"
    },
    {
        "display": "Voluptuous Yennefer as Poison Ivy.",
        "full": "a stunning vector portrait of a thicc and voluptuous yennefer of vengerberg dressed as a beautiful poison ivy with hair tied in a braid walking through a flowering garden, greenhouse in the background, intricate, elegant, highly detailed, digital painting, artstation, concept art, ultra sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Aesthetic Thanos portrait.",
        "full": "aesthetic thanos, perfect shading, atmospheric lighting, by makoto shinkai, stanley artgerm lau, wlop, rossdraws"
    },
    {
        "display": "Elegant female assassin design",
        "full": "female assassin concept design, with dynamic pose, fantasy, dark, majestic, elegant, by alphonse mucha, grey rutkowski, artgerm, artstation, digital illustration"
    },
    {
        "display": "Colorful cartoon bee sculpture",
        "full": "a sculpture of cartoon bee by ray tracing, octane redner brilliantly coloured, trending on artstation, unreal engine, hdr, polished"
    },
    {
        "display": "Renaissance girl sculpture illustration",
        "full": "\u201ca delicate renaissance marble sculpture covered with water veil, highly detailed transparent marble cloth, beautiful girl with long hair, intricate, highly detailed, digital painting, artstation, official media, anime key visual, concept art, rich vivid colors, ambient lighting, sharp focus, illustration, art by Artgerm, Makoto Shinkai, Ilya Kuvshinov, Lois Van Baarle, and Rossdraws, gi, global illumination, physically based rendering, photorealistic, top light , dark background\u201d"
    },
    {
        "display": "Samurai man in chains.",
        "full": "by hokusai, samurai man vagabond, the samurai is wrapped in chains, detailed, editorial illustration, matte print, concept art, ink style, sketch, digital 2 d"
    },
    {
        "display": "Intricate fantasy staircase.",
        "full": "spiral staircase leading to hope and glory, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Steampunk truck motion scene",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: the back of a steampunk truck in motion. girl in grey hoodie holds onto the back door of the truck. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "Cyberpunk goth girl armor",
        "full": "cyberpunk goth girl wearing cybernetic body armor, intricate, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau, high detailed, 4 k,"
    },
    {
        "display": "Ethereal floating islands fantasy.",
        "full": "david attenborough nature documentry footage ultra high definition 8 k misty ethereal floating islands in the sky at night full moonlit sky amethyst citrine opal crystal towers pastel tones fantasy concept art"
    },
    {
        "display": "Marie Curie with horns",
        "full": "a digital painting of a marie curie with horns on her head, concept art by peter mohrbacher, featured on cgsociety, fantasy art, cosmic horror, artstation hd, dark and mysterious"
    },
    {
        "display": "Elegant anime vampire girl",
        "full": "a cute and beautiful anime girl vampire, huge black wings, baroque, elegant, in the style of john william waterhouse, edwin longsden long, theodore ralli, nasreddine dinet, gaston bussiere, craig mullins, trending on artstation, 8 k, hyper detailed,"
    },
    {
        "display": "Symmetrical anime portrait, military style.",
        "full": "symmetrical portrait bust of young woman with shoulder length light brown hair and hazel eyes dressed in a sharp dark teal military uniform and beret, anime, ilya kuvshinov, greg rutkowski, guweiz, ross tran, loish, svetlana tigai, artgerm, artstation trending, concept art, digital painting, painterly"
    },
    {
        "display": "Intricate sunset landscape.",
        "full": "View of a beautiful sunset sky, oil on canvas, intricate, portrait, 8k highly professionally detailed, HDR, CGsociety, illustration painting by Mandy Jurgens and Ma\u0142gorzata Kmiec and Dang My Linh and Lulu Chen and Alexis Franklin and Filip Hodas and Pascal Blanch\u00e9 and Bastien Lecouffe Deharme, detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, Cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8K, style by Wes Anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon st\u00e5lenhag, rendered by Beeple, by Makoto Shinkai, syd meade, 8k ultra hd, artstationHD, 3d render, hyper detailed, elegant, by craig mullins and marc simonetti, Ross Tran and WLOP, by Andrew Wyeth and Gerald Brom, John singer Sargent and James gurney"
    },
    {
        "display": "Jesus praying portrait, fantasy.",
        "full": "a portrait of jesus praying, holy, fantasy by dan mumford, yusuke murata and makoto shinkai, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Dystopian barmaid portrait",
        "full": "character portrait of young woman as a beautiful barmaid, cozy dark crowded 1920s speakeasy tavern, dystopian 1920s soviet mood, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Tom Whalen and Noah Bradley and Alena Aenami and Jeremiah Ketner and Dan Mumford, vibrant deep colors, \ud83c\udf78, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed, Art Nouveau, masterpiece, dizzy, foggy"
    },
    {
        "display": "Epic forest elf portrait.",
        "full": "full face epic portrait, forest elf with glowing eyes, elden ring, matte painting concept art, midjourney, beautifully backlit, swirly vibrant color lines, majestic, cinematic aesthetic, smooth, intricate, 8 k, by ilya kuvshinov, artgerm, darius zawadzki and zdizslaw beksinski"
    },
    {
        "display": "Alchemic manuscript illustration.",
        "full": "Old alchemic manuscript detailing death with skull face holding broken wine bottle,intricate, elegant, highly detailed, digital painting,  artstation, concept art, smooth, sharp focus, illustration, art by Ilja Repin"
    },
    {
        "display": "Rainy Chinatown street scene.",
        "full": "an environmental concept art of a busy street in chinatown, rainy, highly detailed by francis tneh"
    },
    {
        "display": "Elegant woman in snowy dress",
        "full": "a gorgeous female photo, professionally retouched, soft lighting, realistic, smooth face, full length shot, white dress, perfect eyes, wide angle, sharp focus on eyes, 8 k high definition, insanely detailed, intricate, elegant, art by artgerm, snowy winter"
    },
    {
        "display": "Female mutant police sketch.",
        "full": "a police sketch of a female mutant dressed as a homeless person in the style of h. r. giger / zdzis\u0142aw beksinski / david cronenberg trending on artstation deviantart pinterest hyper detailed photorealistic highlights and shadow hd 8 k post - processing high resolution"
    },
    {
        "display": "Anime lawyer portrait, detailed.",
        "full": "beautiful portrait of a Lawyer who looks like Celestia Ralgris  anime, character design by Ross Tran, artgerm  detailed, soft lighting"
    },
    {
        "display": "Elegant digital portrait.",
        "full": "beautiful natural Emma Stone, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Medieval Istanbul landscape with soldiers",
        "full": "medieval istanbul with ottoman soldiers scenery landscape, lord of the rings,, rule of thirds, highly detailed, perfect lighting, perfect composition, 4 k, artgerm, derek zabrocki, greg rutkowski"
    },
    {
        "display": "Curly-haired Persian god.",
        "full": "a curly - haired persian guy god of the cognition, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Elemental sorcerer in forest",
        "full": "a human elemental sorcerer, forest setting, colorful magic, male, white skin, young, sharp focus, concept art, dynamic lighting, unreal engine, by greg rutkowski 0. 5 | emylie boivin 1. 0 | kyle herring 2. 0"
    },
    {
        "display": "Cat character portrait, scenic.",
        "full": "a portrait of a cat character in a scenic environment by sandra chevrier, hyperdetailed, trending on artstation"
    },
    {
        "display": "Futuristic mechanical war machine",
        "full": "a portrait of epic mechanical futuristic war machine with red and white accent and label written indonesia. highly detailed, digital painting, concept art, smooth, sharp focus, illustration, art by greg rutkowski"
    },
    {
        "display": "Fantasy moonlit throne illustration",
        "full": "a beautiful digital illustration painting of a detailed fantasy full moon and roots, throne chair and vines puffy clouds mystical skyby howard arkley. 8 k resolution trending on artstation concept art digital illustration"
    },
    {
        "display": "Giant ancient castle, dramatic lighting",
        "full": "giant ancient castle in an forest with some ivy plants on the walls, cinematic, epic, dramatic lighting from above, dark, vines, fantasy, dust, unreal engine, octane, highly detailed, concept art, dark, super realistic,"
    },
    {
        "display": "Dynamic comic action scene",
        "full": "highly detailed comic spread combination of art styles depicting an impactful action scene on open meadow clear sky at noon with expert design fictional characters, dynamic art by sakimi, bright colors, moebius, makoto shinkai, murata, james jean, craig mullins, digital painting, masterpiece, best selling, pixiv, volumetric lighting, realistic shaded lighting, 8 k"
    },
    {
        "display": "Symmetrical samurai with machine face",
        "full": "symmetry, samurai, lines, brown skin, green iris, machine face, intricate, elegant, highly detailed, digital painting, artstation, cgsociety, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Colorized Naruto portrait",
        "full": "naruto uzumaki, anime key visual colorized portrait, trending pixiv fanbox, by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Dark Elf in Daedric Armor",
        "full": "A Dark Elf Dunmer wearing Daedric armor and a cowl with glowing red eyes leviates like Vivec in the rain in the Morrowind wetlands, oil painting, glow, runes, magic, morrowind, Skywind, Oblivion, Skyrim, dark, gloomy, House Telvanni, grass, trees, swamp, divayth fyr, pointy ears, high cheekbones, portrait, concept art, 4k, macro detail, stride, realistic shadows, mushroom trees, r/skywind, r/morrowind, 4k morrowind concept art, bloom, cosplay"
    },
    {
        "display": "Medieval noble portrait.",
        "full": "half - length portrait of a 7 0 - year - old human male noble with long blonde hair and green eyes, mustache, no beard, medieval setting, detailed face, highly detailed, artstation, concept art, sharp focus, by greg rutkowski, craig mullins, artgerm"
    },
    {
        "display": "\"Elegant Star Goddess Artwork\"",
        "full": "star goddess, fine art, awesome fantasy book cover on pinterest, award winning, dark fantasy landscape, fantasy magic, intricate, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, concept art, art by wlop and artgerm and greg rutkowski, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Male Medusa illustration",
        "full": "male medusa, highly detailed, snakes, digital painting, blood in the eyes, cute face, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg womb sci-fi artwork",
        "full": "the birth of the cyborg sleeping chambre / artificial womb by hr giger. hedonic imperative expressed as a pan - species techno - utopia imagined by jim burns and james gurney. masterpiece scifi artwork, retro, trending on artstation, 8 k"
    },
    {
        "display": "Angel with horn in graveyard",
        "full": "angel blowing a horn in the zombies and graves. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "Cyborg in red room.",
        "full": "by Piotr Jablonski, in red room cyborg sitting Infront gramophone, concept art high quality image, denoise"
    },
    {
        "display": "Coastal mecha jellyfish scene",
        "full": "A very beautiful serene coastal landscape scene with a GIANT MECHA JELLYFISH looming in the distance, bright sunny waves splashing on the beach, Translucent rendered by simon st\u00e5lenhag, The Great Wave off Kanagawa, rendered by Beeple, Makoto Shinkai, syd meade, environment concept, digital art, starwars, unreal engine, 3 point perspective, WLOP, trending on artstation, low level, 4K UHD image, octane render,"
    },
    {
        "display": "Giant mechanical beast portrait",
        "full": "a fancy portrait of a giant mechanical beast by Greg Rutkowski, Sung Choi, Mitchell Mohrhauser, Maciej Kuciara, Johnson Ting, Maxim Verehin, Peter Konig, final fantasy, Marco lense , 8k photorealistic, cinematic lighting, HD, high details, atmospheric , trending on artstation"
    },
    {
        "display": "Falling angel marble sculpture",
        "full": "realistic digital painting of a stunning intricate cracked white marble falling angel bernini sculpture, trailing white vapor, mycelium stands and misty xparticles neutral tone background, trending on artstation, hyperrealism, matte painting, subsurface scattering"
    },
    {
        "display": "Trump and El Chapo in prison",
        "full": "illustration of donald trump and El Chapo sharing a prison cell in jail. videogame cover art, highly detailed, digital painting, artstation, concept art, smooth, detailed armor, sharp focus, beautiful face, illustration, art by Artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed WWII Soldier Portrait",
        "full": "A full portrait of a world war two soldier, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Fantasy tavern in enchanted forest",
        "full": "A landscape with a large fantasy tavern with multiple stories in the middle of a magical forest, magical particles!!, magical lighting, inviting, enchanting, rendered by simon st\u00e5lenhag, rendered by Beeple, Makoto Shinkai, syd meade, environment concept, digital art, unreal engine, 3 point perspective, WLOP, trending on artstation, low level, 4K UHD image, octane render,"
    },
    {
        "display": "VFX portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin, makoto shinkai, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, backlit, harsh overhead sunlight, blue eyes, stanley kybric, pixiv"
    },
    {
        "display": "Cute alien portrait",
        "full": "A potrait of an alien with big and cute eyes, fine-face, realistic shaded perfect face, fine details. Night setting. Realistic shaded lighting poster by Ilya Kuvshinov katsuhiro, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash, Rob Rey and Kentar\u00f5 Miura style, trending on art station"
    },
    {
        "display": "Beautiful Asian woman portrait",
        "full": "the most beautiful asian woman in the world, in the style of julia razumova, happy expression, cute, unreal engine, octane rendering, 8 k, closeup headshot, smooth, trending on artstation, digital illustration, black hair"
    },
    {
        "display": "Walter White GTA V art",
        "full": "walter white gta 5 cover art, trending on artstation"
    },
    {
        "display": "Hyperrealistic beach scene.",
        "full": "hyperrealism, detailed textures, photorealistic 3 d render, a dreamy day on veredaro beach cuba, a 1 9 6 4 split window corvette obsidian black, sharp focus, ultra realistic, ultra high pixel detail, cinematic, intricate, cinematic light, concept art, illustration, art station, unreal engine 8 k"
    },
    {
        "display": "Medieval black female archer",
        "full": "full body portrait of beautiful black woman as a medieval archer, leather armor and war bow, cinematic, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, face by wlop, illustrated by mars ravelo and greg rutkowski"
    },
    {
        "display": "Cute friendly vampire portrait.",
        "full": "cute friendly vampire by charlie bowater and titian and artgerm, full - body portrait, intricate, face, forest, elegant, red mist, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Realistic warforged cleric portrait",
        "full": "portrait painting of a d & d female warforged cleric, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and charlie bowater and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Dystopian masked portrait.",
        "full": "Very very very very highly detailed epic photo of face with venetian mask, intricate, dystopian, sci-fi, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, intimidating lighting, incredible art by Artgerm and Vincent di Fate"
    },
    {
        "display": "Colorful skull clown illustration",
        "full": "realistic colorful skull clown, intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, vibrante colors, art by Greg rutkowski"
    },
    {
        "display": "Luis Guzman as Waluigi",
        "full": "caricature, claymation, luis guzman as waluigi, painted by tom lovell, wlop, artgerm, dishonored 2,"
    },
    {
        "display": "Androgynous Ootengu Portrait",
        "full": "onmyoji portrait, ( ootengu ), androgynous male, fine details. night setting. realistic shaded lighting poster by craig mullism, artgerm, jeremy lipkin and michael garmash, unreal engine, vibrant colors and hard shadows and strong rim light, light blue sky, cool white color temperature, radiant light, detailed and intricate environment, digital art"
    },
    {
        "display": "Elegant pink bell painting",
        "full": "breathtaking detailed soft painting of a pink fire bell ringing in the clouds, public money flowing through parties of fraternity, rembrandt style, elegant, highly detailed, artstation, concept art, matte, sharp focus, art by tom bagshaw, and greg rutkowski"
    },
    {
        "display": "Socotra Island landscape art",
        "full": "Lively landscape of a socotra island filled with socotra dragon trees, realistic detailed digital art by Maxwell Boas Jessica Rossier Christian Dimitrov Anton Fadeev trending on Artstation CGSociety rendered in Unreal Engine 4k HQ"
    },
    {
        "display": "Dark queen in fantasy forest",
        "full": "side portrait dark queen, witch outfit large cloak, fantasy forest landscape, dragon scales, fantasy magic, undercut hairstyle, short purple black fade hair!!!!!!, dark light night, intricate, elegant, sharp focus, illustration, highly detailed!!!!!!!, digital painting, concept art, green neon smoke, matte painting, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Goddess portrait: wind and fire",
        "full": "portrait of a goddess of wind and fire, half body, perfect face, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant kefka portrait.",
        "full": "portrait of a kefka, dark, piercing eyes, gentle expression, elegant clothing, photorealistic, highly detailed, artstation, smooth, sharp focus, art by michael whelan, artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fierce Chinese warrior portrait",
        "full": "chinese warrior, portrait, fierce, intricate, elegant, black and white volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Realistic beauty portrait",
        "full": "full body beauty portrait in the style of artgerm, wlop, greg rutkowski, alphonse mucha and ayami kojima, realistic details, very very very very beautiful face, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Sleepy Bob Ross portrait",
        "full": "bob ross is yawning and is very very very sleepy, eyes are closed, close - up, deep focus, extremely detailed digital painting, vibrant colors, in the style of tomasz alen kopera and fenghua zhong and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Fantasy Alps Landscape Illustration",
        "full": "beautiful fantasy alps landscape, colorful clouds, magical glowing spores, godrays, magnificent, colorful reflections, light diffusion, subsurface scattering, diffuse lighting, luxury, detailed, sharp focus, close up, low angle, high detail, volumetric, illustration, digital art, cold lighting, by jordan grimmer and greg rutkowski, trending on artstation, pixiv"
    },
    {
        "display": "Surreal detailed raven sculpture",
        "full": "Intricate stunning highly detailed surreal raven with red eyes by agostino arrivabene and Seb McKinnon, sculpture, ultra realistic, Horror vacui, full moon, thick swirling smoke tornado, fire embers, trending on artstation"
    },
    {
        "display": "Jacinda Ardern as propaganda.",
        "full": "jacinda ardern in the style of cuban communist propaganda poster art in the year 1 9 8 7 ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and magali villeneuve"
    },
    {
        "display": "Cyborg Cat Portrait",
        "full": "portrait of a cute cat as a cyborg. intricate abstract. intricate artwork. by Tooth Wu, wlop, beeple, dan mumford. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8k, iridescent accents"
    },
    {
        "display": "Ethereal elven forest illustration",
        "full": "A beautiful elven forest with a river and babbling brook, ethereal, dreamlike, magical, highly detailed, volumetric lighting, illustration, concept art, 8K, by Allan Jabbar"
    },
    {
        "display": "Emo selfie portrait",
        "full": "a colored colorized photograph selfie of jerma with a emo scene hairstyle in the late 2 0 0 0 s with a scene emo hairstyle and wearing a t - shirt, taken in the mid - late 2 0 0 0 s, grainy, taken on an iphone 1, realistic, hyperrealistic, very realistic, highly detailed, very detailed, extremely detailed, detailed, digital art, intricate details, real life, trending on artstation, body and head in view, body and headshot, colored photo, detailed face, very detailed face"
    },
    {
        "display": "Beautiful female Green Lantern.",
        "full": "anime key visual of one beautiful female green lantern, dc comics, power, hope, glowing, intricate, in space, stunning, highly detailed, digital painting, artstation, smooth, hard focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Flooded black tower night scene",
        "full": "an award winning digital painting of a flooded black tower at night by rene magritte jan van eyck simon stalenhag carl spitzweg audubon max ernst, full-length view, highly detailed, vibrant colors, extremely high contrast!, symmetry, great composition, high detail, cinematic lighting, masterpiece, trending on artstation"
    },
    {
        "display": "Shrek as a hyperrealistic gun.",
        "full": "shrek as gun, masterpiece, highly detailed, high quality, 4 k, anatomically correct, hyperrealistic, concept art, octane render, unreal engine 5, trending on artstation, trending on deviantart, matte, historical painting, fantasy style, path traced, high coherence, soft lighting, digital painting, mythical"
    },
    {
        "display": "Futuristic robot with alien features.",
        "full": "robot torso, faded colors, exotic alien features, cyber background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, zack snyder, cinematic composition, beautiful lighting, sharp, details, hyper detailed, 8 k, unreal engine 5"
    },
    {
        "display": "Elegant wuxia portrait.",
        "full": "Lucy Liu portrait, wuxia, fantasy, elegant, intricate, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Ice princess at glacier fortress.",
        "full": "ice princess standing in doorway to blue ice glacier fortress, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bubble unicorn portrait",
        "full": "A beautiful pink unicorn made up of bubbles with sunglasses, synthwave style, fantasy, portrait, trending on artstation, detailed, award winning"
    },
    {
        "display": "Realistic artist in messy room",
        "full": "a computer graphics artist man with a ballcap in a messy room at the computer animating, ultra realistic, concept art, intricate details, serious, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutk owski and alphonse mucha"
    },
    {
        "display": "Misty dock scene.",
        "full": "movie scene of a old wooden dock and bait and tackle shack on a very misty day, greg rutkowski, loish, rhads, ferdinand knab, makoto shinkai and lois van baarle, artgerm, ian mcque, pixar, ilya kuvshinov, rossdraws, tom bagshaw, global illumination"
    },
    {
        "display": "Woman admiring lightning in Tokyo.",
        "full": "large tokyo street in background, a women looks at the flashes of lightning bolts in sky, dark sky by artgerm, illustration, trending on artstation, deviantart,"
    },
    {
        "display": "Lovecraftian squid humanoid.",
        "full": "concept art of a dark grey skinned humanoid squid man, lovecraftian, alien like, dark grey skinned, long faced, in dark clothes in a dark room, orange eyes, elegant, angry, facing the viewer"
    },
    {
        "display": "Skinny gray pit bull portrait",
        "full": "photo of a skinny dark gray coat pit bull with a white paws and a white nose! digital art, highly detailed, digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop!!!, octane render"
    },
    {
        "display": "Cute realistic vampire art",
        "full": "cute vampire, ultra realistic, concept art, intricate details, dark vibe, highly detailed, photorealistic, octane render, 8 k, unreal engine,"
    },
    {
        "display": "Egyptian goddess in sci-fi armor",
        "full": "perfect white haired egyptian goddess wearing white dove wings, warframe armor, regal, attractive, ornate, sultry, beautiful, ice queen, half asian, pretty face, blue eyes, detailed, scifi platform, 4 k, ultra realistic, epic lighting, android body, illuminated, cinematic, masterpiece, art by akihito tsukushi, voidstar, artgerm"
    },
    {
        "display": "Mexican woman in armor",
        "full": "an portrait young woman mexican in the assault powered armor, style game square enix, trending on artstation, painted by greg rutkowski, render naughty dog, octane render, detailed"
    },
    {
        "display": "Dynamic cyberpunk comic panel.",
        "full": "Dynamic, delirious, creative panel style by Bill Sienkiewicz. Heavy chromatic abberation. Visual distortion. Sci-Fi cyberpunk Comic page made up of art by the best artists Trending on Artstation.  Octane render, Raytracing, 3d masterpiece, fantastic lighting by James Gurney. Noir detective genre."
    },
    {
        "display": "Victorious death artwork.",
        "full": "death is swallowed up in victory, very detailed and beautiful face, screaming, artwork by artgerm, centered shot, wide angle, full body, islandpunk"
    },
    {
        "display": "Fantasy necromancer sorceress portrait",
        "full": "Necromancer Sorceress face in center, fantasy magic, undercut hairstyle, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Dramatic fantasy wolf portrait",
        "full": "wolf, fantasy, pixiv, dramatic, concept art, portrait, light"
    },
    {
        "display": "Surreal mythological landscape.",
        "full": "annihilation ( 2 0 1 8 ) frame by mehoffer, surreal landscape by malczewski, mythological creatures concept art"
    },
    {
        "display": "Symmetrical clay bear deity",
        "full": "beautiful ceremonial bilaterally symmetrical clay bear god, classical ornamental entail design, fibonacci flow, acroteria, encarpus, emblem, bead and reel, large medium and small elements, full character design, by russ abbott, albrecht durer, artgerm, rutkowski, trending on artstation, 8 k"
    },
    {
        "display": "Colorful minimalistic abstract artwork",
        "full": "negative space, highly colorful, minimal, simplistic, amazing simple composition, vaporwave, gertrude abercrombie, minimalistic graffiti masterpiece, minimalism, 3 d abstract render overlayed, black background,, trending on artstation, ink splatters, pen lines, incredible detail, creative, positive energy, happy, unique, face, artgerm"
    },
    {
        "display": "Surreal fantasy artwork.",
        "full": "end of reality by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, hiroaki samura, range murata, james jean, katsuhiro otomo, erik jones"
    },
    {
        "display": "Cyberpunk black cat portrait.",
        "full": "a detailed matte painting of a black cat with red led eyes, the cat is in a intricately detailed neo cyberpunk Japanese city, the angel of death with a halo, by Ismail Inceoglu , concept art, featured on cgsociety"
    },
    {
        "display": "Ultrarealistic armored magic knight portrait.",
        "full": "a masterpiece ultrarealistic ultradetailed portrait of full armored magic knight strong man with sword baroque renaissance. wide angle, intricate, elegant, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, background by james jean, light by julie bell, porcelain skin. global illumination. vfx"
    },
    {
        "display": "Elf princess balcony scene",
        "full": "a detailed digital art of an elf princess on a balcony, a tropical mountain on a full moon night with dark clouds in the skies, artstation, vivid colors, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Beautiful woman, digital art",
        "full": "digital art, a beautiful full body of a woman, by Artgerm, ultradetailed, concept art, trending on artstation"
    },
    {
        "display": "Colorful Mothman character portrait",
        "full": "mothman, ultra detailed fantasy, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Dimensional door on beach",
        "full": "a door to another dimension opens on a beach in the style of michael whelan, hyperrealistic, photorealistic, concept art, trending on artstation, digital illustration, cinematic lighting, 4 k, ultra hd, beautiful colors, highly detailed, octane render, unreal engine 5"
    },
    {
        "display": "Seahorse with glasses artwork",
        "full": "beautiful painting of a seahorse wearing cute little glasses, trending on artstation"
    },
    {
        "display": "Mecha Demonic Duchess Portrait",
        "full": "a portrait of a beautiful mecha demonic duchess of hell, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art"
    },
    {
        "display": "Elder pagan angel above ruins",
        "full": "elder pagan angel figure rising above mysterious ancient ruins, painted by peter mohrbacher and marc simonetti, trending on artstation, character art, vibrant colors, matte painting"
    },
    {
        "display": "Anime ocean singer portrait",
        "full": "Portrait of a beautiful young elegant ocean singer in anime style \uff0c full of details, matte painting, concept art, smooth, by krenz cushart and wlop\uff0ctrending on cgsociety and artstation\uff0c8kHDR\uff0clight effect\uff0crtx on\uff0c-H 1280"
    },
    {
        "display": "Fantasy battle scene.",
        "full": "Death is swallowed up in victory, very detailed and beautiful face, pink hair, screaming, mechanical grasshopper attacks, artwork by artgerm, centered shot, wide angle, full body, Elfpunk, mythpunk, DD, fantasy, highly detailed, digital painting, artstation, smooth, sharp focus, art by thomas kinkade and H.R. Giger and Tyler Edlin"
    },
    {
        "display": "Urban supernatural custody battle.",
        "full": "concept art of scene from'custody battle'from the modern urban gnostic supernatural thriller anthology'the fallen world chronicle ', by david mattingly and samuel araya and michael whelan and tim jacobus and francisco goya. realistic 8 k matte painting with photorealistic hd lighting. composition and layout inspired by gregory crewdson and brendon burton."
    },
    {
        "display": "Heroic figure shredding pillows",
        "full": "uberchad ripping apart pillows, heroic, glorious, in the style of artgerm, gerald brom, atey ghailan and mike mignola, vibrant colors and hard shadows and strong rim light, plain background, comic cover art, trending on artstation"
    },
    {
        "display": "High Priestess Between Pillars",
        "full": "the high priestess misteriously sitting between 2 pilars. yin and yang inside an castle. intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, by justin gerard and artgerm, 8 k"
    },
    {
        "display": "African female samurai portrait.",
        "full": "attractive female portrait of african samurai, wearing gui, afro, rule of thirds, uplight, intricate, symmetrical!!, depth of field, cinematic, filmic, vsco, concept art, artstation, digital painting, elegant, model, gorgeous, adobe, vouge, magazine cover, epic, focus, octane render, vray render, arnold render,"
    },
    {
        "display": "Cinematic princess portrait.",
        "full": "close-up portrait of the face of a beautiful princess in a twisted skull glass mask in a spaceman suit, voronoi fracture, epic angle and pose, symmetrical artwork, 3d with depth of field, blurred background, cybernetic jellyfish female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. y Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Lifelike Krishna illustration, Art Nouveau",
        "full": "amazing lifelike award winning pencil illustration of hindu God Krishna in style of Alphonse Mucha, trending on artstation, artgerm, Greg rutkowski, alphonse mucha, William-Adolphe Bouguereau, cinematic, epic Lighting, photorealistic, Octane render, Unreal Engine, Art nouveau"
    },
    {
        "display": "Elon Musk as Iron Man",
        "full": "elon musk in iron man suit smiling, marvel character, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Girl and giant wolf.",
        "full": "girl facing a giant white wolf, manga cover, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, sharp focus, illustration, highly detailed, concept art, matte, trending on artstation, anime, art by wlop and artgerm and greg rutkowski, h 6 4 0"
    },
    {
        "display": "Anime girl under fire",
        "full": "infantry girl, anime style, symmetrical facial features long hair, hair down, under heavy fire, explosions, hyper realistic, pale skin, rule of thirds, extreme detail, 4 k, detailed drawing, trending artstation, realistic shading, visual novel cg, by alphonse mucha, greg rutkowski, sharp focus, backlit, fast helmet"
    },
    {
        "display": "Stoic military portrait.",
        "full": "portrait of both john oliver and adam driver standing together looking stoic, full body, military uniform, fantasy, intricate, elegant, beautiful, highly detailed, charcoal, centered, dark, smokey, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "River among flowers illustration",
        "full": "a beautiful digital illustration painting river among the flowers by benoit b. mandelbrot, steven belledin, martin johnson heade, lee madgwick, caspar david friedrich, and david rios ferreira. 8 k resolution trending on artstation concept art digital illustration"
    },
    {
        "display": "Ornate summer night scene",
        "full": "a detailed painting of Night light summer mud singing in the style of moebius and giger, beautiful, 8K, trending on artstation, volumetric light, lightrays,HDR, ambient occlusion, subsurface scattering,avant scenes, cinematic, extremely ornate, atmospheric"
    },
    {
        "display": "Elven beauty with long hair.",
        "full": "beautiful blonde elf with ridiculously long hair which reaches the floor, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "Symmetrical Seinfeld portrait.",
        "full": "symmetry!! portrait of seinfeld, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fantasy crystal island landscape.",
        "full": "fantasy crystal island, viewed from the ocean, high quality digital art, high detail, trending on artstation"
    },
    {
        "display": "Anime girl in mirrored hallway",
        "full": "beautiful girl in intricate clothing walking through a hallway of mirrors, very high intricate details, painting, digital anime art, medium shot, mid - shot, reflections, wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, sana takeda"
    },
    {
        "display": "Elegant fantasy portrait.",
        "full": "the kiss, light from above, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Handsome goblin portrait.",
        "full": "portrait of a green skin handsome goblin with long black hair in a suit. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose, vibrant colours."
    },
    {
        "display": "Emerald herald character illustration",
        "full": "the male emerald herald, intricate, cinematic lighting, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by terry moore and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic neoclassical police station",
        "full": "Futuristic police station. By Frank Lloyd Wright, concept art, digital painting, unreal engine, cgsociety, neoclassical, mech, robot, fractal flame, cinematic, highly detailded"
    },
    {
        "display": "80's zombie horde artwork",
        "full": "zombie horde approaching camera, zombies wearing clothes from the 8 0's, zombies with hairstyles from the 8 0's, fine art, award winning, intricate, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by guweiz and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Surreal AI Robot Portrait",
        "full": "cinematic portrait of an AI Robot. Centered, uncut, unzoom, symmetry. charachter illustration. Dmt entity manifestation. Surreal render, ultra realistic, zenith view. Made by hakan hisim feat cameron gray and alex grey. Polished. Inspired by patricio clarey, heidi taillefer scifi painter glenn brown. Slightly Decorated with Sacred geometry and fractals. Extremely ornated. artstation, cgsociety, unreal engine, ray tracing, detailed illustration, hd, 4k, digital art, overdetailed art. Intricate omnious visionary concept art, shamanic arts ayahuasca trip illustration. Extremely psychedelic. Dslr, tiltshift, dof.  64megapixel. complementing colors. Remixed  by lyzergium.art feat binx.ly and machine.delusions. zerg aesthetics. Trending on artstation, deviantart"
    },
    {
        "display": "Orc mecha biohacker illustration",
        "full": "anthropomorphic mecha - orc protoengineer biohacker, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by tristan eaton and artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Anime cyborg girl portrait",
        "full": "a girl, fused mecha robot parts, vintage clothing, cyborg, anime style, long hair, hair down, symmetrical facial features, from arknights, hyper realistic, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, d & d, realistic lighting, by alphonse mucha, greg rutkowski, sharp focus, backlit"
    },
    {
        "display": "Colorful neo-brutalist housing.",
        "full": "neo brutralism, concrete housing, concept art, colorful, orange red and turquoise tones, light, shadows, reflections, 3D, in the style of Akihiko Yoshida and Edward Hopper"
    },
    {
        "display": "Cyborg woman portrait",
        "full": "portrait of ultra realistic, beautiful cyborg woman, metahuman, sci-fi, fantasy, cyberpunk, intricate, elegant, highly detailed, digital painting, octane render, artstation, concept art, smooth, sharp focus, eerie, illustration, art by artgerm and and alphonse mucha"
    },
    {
        "display": "Colorful light refraction art",
        "full": "color light waves, color spectrum refraction in white space with white smoke, light through glass, cinematic lighting, highly detailed, trending art station, concept art, Unreal Engine 5, Photorealism, 8k resolution, cinema 4d, 3D, beautiful, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Futuristic cowboy portrait",
        "full": "a portrait of a cowboy in 2 0 7 0, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic, vfx"
    },
    {
        "display": "Cartographers resting under tree.",
        "full": "three cartographers resting under a massive tree, telescope, high fantasy, vivid colors, fantasy digital art, wide sky, sharp focus, highly detailed, intricate, masterpiece, award winning photograph, trending on artstation"
    },
    {
        "display": "Lucifer Morningstar as Scarlet Witch",
        "full": "Lucifer Morningstar as Scarlet Witch, portrait, full body, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Evil anime character portrait",
        "full": "photo portrait of hisoka hunter x hunter hiddleston moira overwatch mix inspired male evil sharp features yellow iris, small narrow slit evil eyes, red hair crimson hair smirk anime hunterpedia pixiv fanbox concept art, matte, sharp focus, illustration, rutkowski ross tran ruan jia"
    },
    {
        "display": "Fantastical girl casting spell.",
        "full": "beautiful fantastical girl standing in a lake basking in the moonlight, casting a spell, under a multi-colored binary blackhole with an accretion disc, glowing trails following her arms, wearing professional makeup, synthwave, by Lois van Baarle, by Greg Rutkowski, by artgerm, by beeple, by studio ghibli, cinematic angle, volumetric lighting, 4k resolution, octane render, trending on artstation, masterpiece"
    },
    {
        "display": "Anime girl in feather dress.",
        "full": "little girl with flowers in hair wearing an white dress made of feathers, anime style, 8 k, cgi, concept art"
    },
    {
        "display": "Elegant digital painting of Turanga Leela.",
        "full": "turanga leela, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Warrior princess book cover",
        "full": "high vector, book cover!!!!!!!!!!!!, warrior princess in intricate plate armour, large moon, light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and ivan shishkin and andrey shishkin, masterpiece"
    },
    {
        "display": "Symmetrical action pose painting",
        "full": "symmetrical - photo!! low - angle!! painting of christina - hendricks!!! in star wars, action - pose, dynamic - pose, full frame photograph, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Blindfolded street magician",
        "full": "blind folded modern street magician holding playing cards, realistic, modern, intricate, elegant, highly detailed, digital painting, artstation, concept art, addiction, chains, smooth, sharp focus, illustration, art by ilja repin"
    },
    {
        "display": "Mystical deity with mushrooms.",
        "full": "Stone Head of a forgotten Deity, translucent mushrooms, antlers, layers of mushrooms, extremly detailed digital painting, in the style of Fenghua Zhong and Ruan Jia and jeremy lipking and Peter Mohrbacher, mystical colors, rim light, beautiful lighting, 8k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Elegant Byzantine silk scarf",
        "full": "love of wisdom, silk, scarf, byzantine mosaics art by artgerm, james jean, denis sarazhin, ryohei hase, thomas kinkade, elegant, modest"
    },
    {
        "display": "Cyborg woman portrait",
        "full": "weta disney pixar movie still portrait photo of motoko kusanagi the major ghost in the shell : : as cyborg woman by pixar : : by weta, wlop, ilya kuvshinov, rossdraws, artgerm, marvel, maxim cover, latex, octane render, sweaty, iridescent, bright morning, anime, liosh, mucha : :"
    },
    {
        "display": "Hyperrealistic badness painting",
        "full": "A highly detailed matte oil painting of badness, hyperrealistic, cinematic, breathtaking, beautiful composition, Mokoto Shinkai, Studio Ghibli, Artgerm, Dan Mumford, rossdraws, James Jean, beeple, volumetric lighting, octane render, 4K resolution, trending on artstation"
    },
    {
        "display": "Closeup portrait illustration.",
        "full": "' kathryn newton ', closeup shot of face, beautiful shadowing, three dimensional shadowing, reflective surfaces, illustrated completely, high definition beautifully detailed illustration on polished glass, extremely hyper - detailed technique, intricate, perfect curves, epic composition, masterpiece, bold complimentary colors. stunning masterfully illustrated by artgerm, range murata, alphonse mucha, katsuhiro otomo."
    },
    {
        "display": "Detailed Amsterdam-style tree houses",
        "full": "tree houses with apartments that look like those in amsterdam, trending on artstation, octane rendering, cinematic lighting, camera obscura, inspired by da vinci, inspired by rembrandt, inspired by ghibli, inspired by alan moore, inspired by blade runner, hyper realistic detail, very very very detailed, super detailed"
    },
    {
        "display": "Surreal biomechanical portrait.",
        "full": "surreal portrait of a man by Greg Rutkowski and H.R Giger, a biomechanical skull from whose sockets a red light emanates, between human and alien, connected by pipes and cables, terrifying, disturbing, cosmic void background, frightening, fascinating, highly detailed portrait, digital painting, book cover, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Fairy-winged pixie character.",
        "full": "3 / 4 view of a woman with fairy wings, confident pose, pixie character, video game genshin impact, synthwave style, elegant, sharp focus, illustration, highly detailed, artgerm, anime, trending on artstation"
    },
    {
        "display": "Moon goddess Kaguya in forest",
        "full": "Beautiful goddess of the moon, Kaguya, in front of giant moon, traditional white kimono, mystic, mist, gemstone, artefact, runes, medieval, forest, sunset, dolphins on the horizon, cinematic, high quality, cgsociety, artgerm, 4K, UHD, trending on ArtStation"
    },
    {
        "display": "Officer Bunny Character Design",
        "full": "Leon S kennedy as a Officer Bunny Character design, He has anime hair by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Fantasy portrait with explosion.",
        "full": "portrait close up of man, concentrated look, symmetry, with an explosion on the back, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha, boris vallejo"
    },
    {
        "display": "Sexy female Seraphim princess.",
        "full": "A beautiful digital painting of a sexy female Seraphim, princess, the moon behind her, intricate, cinematic lighting, highly detailed, digital painting, Artstation, concept art, smooth, sharp focus, illustration, art by Tom Bagshaw, Artgerm and Greg Rutkowski"
    },
    {
        "display": "Tree in trash can.",
        "full": "low angle shot of a tree growing inside a trash - can in the foreground. overgrown. soft night - time over the mountains in the background. clouds. highly detailed, cinematic, beautiful, cgsociety, artstation, oil painting by greg rutkowski, by artgerm, by wlop"
    },
    {
        "display": "Female artificer portrait",
        "full": "female artificer with futuristic rifle, tubes connecting mediaeval half plate armor to rifle, brown hair, smiling, portrait, goggles over forehead, trending on artstation, digital art"
    },
    {
        "display": "Futuristic spaceship factory artwork",
        "full": "very wet paint, painting of a spaceship under construction industrial robotic automation factory automotive futuristic painting colorful vibe sparkle energy real paint splatters picasso kandinsky detailed 4 k deviantart artstation trending reddit station gas flying scifi"
    },
    {
        "display": "Cyberpunk hunter chase",
        "full": "a cyberpunk hunter, chasing her enemies, by WLOP, high quality, trending on artstation"
    },
    {
        "display": "Beautiful female android, noir.",
        "full": "ultra detailed, beautiful female android smoking a cigarette, scifi, fantasy, octane render, ( dia de los muertos, triadic color scheme, asymmetrical, intricate detailed, global illumination, concept art. smoke, calm, noir. art by godmachine and michael welan and rossdraws and artgerm and greg rutkowski and alphonse mucha and loish and wlop. 8 k, hdr"
    },
    {
        "display": "Rimuru Tempest concept art.",
        "full": "full body shot of rimuru tempest, sky blue straight hair, long bangs, with amber eyes, wearing a black jacket, high collar, ultra detailed, concept art, award winning photography, digital painting, cinematic, wlop artstation, closeup, pixiv, evil, yoshitaka amano, andy warhol, ilya kuvshinov,"
    },
    {
        "display": "Spanish beauty with floral crown.",
        "full": "combination of spanish beauty and adriana lima with dark hair and dark eyes, wears floral crown, the witcher style, stoic attitude, zenithal lighting shadowing the push - up, ultra detailed, sharp focus highly detailed, sharp focus, golden background with flowers, photorealism, style of hajime sorayama, art by hirothropologie, artgerm and patrick demarchelier"
    },
    {
        "display": "Fallen angel portrait.",
        "full": "portrait of a fallen angel, bloody wings, face covered in dirt and blood, crying, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Hybrid monkey-cat painting",
        "full": "painting of hybrid monkey!!! and ( ( ( ( cat ) ) ) ) fused together, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Warrior enemy character design",
        "full": "warrior enemy character design. idle and attack. pc game. 2 d sideview. transparent background. art by moebius and thomas kinkade and greg rutkowski."
    },
    {
        "display": "Ultra-realistic angel princess portrait",
        "full": "A masterpiece ultrarealistic ultradetailed portrait of a Incredibly beautiful angel princess tribal-shaman-knight-witch-ghost with  Skull  Iron mask. baroque renaissance  girl  in the night forest. medium shot, intricate, elegant, highly detailed. trending on artstation, digital art, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, Yoshitaka Amano. background by James Jean and Gustav Klimt, light by Julie Bell, 4k, porcelain skin."
    },
    {
        "display": "Cyberpunk Babylon Tower Illustration",
        "full": "colored manga, by greg rutkowski, tarkovsky, majestic ancient tower of babylon of terror, a woman in cyber clothing, hyperrealistic, blame manga, full color, cyber architecture, intricate, illustration, kilian eng, concept art, hyper - detailed, smooth, masterpiece, epic, cinematic, high quality"
    },
    {
        "display": "Cyborg in data center",
        "full": "cyborg in the data center, wired to the equipment and typing on the keyboard, red biomechanical details, wearing epic bionic cyborg implants, inflateble shapes, masterpiece, intricate, biopunk, highly detailed, artstation, concept art by frank miller geof darrow mike mignola, 8 k"
    },
    {
        "display": "Magical festival in town",
        "full": "a bustling magical town with witches and black cats dancing around a colorful music festival in its center, inspired by victorian england and amsterdam, highly detailed, intricate, digital painting, trending on artstation, concept art, matte painting, art by greg rutkwowski, craig mullins, octane render, 8 k, unreal engine"
    },
    {
        "display": "Anime art of Hatsune Miku",
        "full": "Anime art of beautiful Hatsune miku with beautifel legs by artgerm, rossdraws, magali villeneuve, Gil Elvgren, Earl Moran,Enoch Bolles, symmetrical shoulders, evangelion style eyes, round bottom, bellybutton, abs"
    },
    {
        "display": "Minimalist neon Samsung store",
        "full": "( ( ( minimalist brutalist neon flagship samsung store. display tables with phones and tablets. colorful walls. polished concrete floor. low ceilings with spots. geometric furniture, colorful digital screens, lush plants, large digital screens on the walls ) ) ) concept art, vibrant colour, john berkey, beeple, wlop, makoto shinkai, yoji shinkawa, wadim kashin, artstation, 4 k"
    },
    {
        "display": "Cyborg pilot girl illustration",
        "full": "pilot girl, cyborg aircraft parts, anime style, military pilot clothing, shoulder eyes, last exile anime, hair down, symmetrical facial features, from arknights, hyper realistic, 4 k, rule of thirds, extreme detail, detailed drawing, trending artstation, realistic lighting, by alphonse mucha, greg rutkowski, backlit"
    },
    {
        "display": "Glowing shadow warrior portrait",
        "full": "portrait of a shadow warrior, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, symmetry face, fine details. realistic shaded lighting by ilya kuvshinov, katsuhiro otomo, kidmo!!!, trending on artstation, magali villeneuve, artgerm, jeremy lipkin and michael garmash, unreal engine 5"
    },
    {
        "display": "Shrek as knight on donkey",
        "full": "shrek as a glorious devout shining powerful epic amazing awesome very handsome attractive muscular stylish knight in shining golden armor riding donkey, fantasy art, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Happy brunette anime portrait",
        "full": "A medium shot anime portrait of a happy brunette anime woman, a single short ponytail, parted hair, bare forehead, blue-eyed, blue eyes, big round nose closed lips, with large breasts, wearing a t-shirt, green gradient background, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimi chan, trending on artstation"
    },
    {
        "display": "Lifelike pencil illustration of Maximilian Kolbe.",
        "full": "amazing lifelike award winning pencil illustration of Maximilian Kolbe trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Confident girl with snake",
        "full": "portrait of a beautiful girl, snake wrapping around her, confident pose, sharp focus, illustration, highly detailed, concept art, matte, trending on artstation, anime, comics, marvel, art by wlop and artgerm and greg rutkowski, h 6 4 0"
    },
    {
        "display": "Colorful cyberpunk factory scene",
        "full": "cyberpunk factory filled with drone workers, busy, colorful, beautiful, ornate, trending on artstation, award winning painting, glitchwave, vaporwave"
    },
    {
        "display": "Knight drinking beer, foggy setting.",
        "full": "knight drinks beer, beer, glass, beer mug in hand, intricate, triumphantly, foggy background, full body art, dark souls, drawing, concept art, artstation, digital painting, highly detailed, illustration by artgerm"
    },
    {
        "display": "Porcelain Barbie statue illustration.",
        "full": "digital masterpiece illustration concept art of porcelain statue of barbie as mr olympia, extremely detailed and intricate complexity, epic composition, magical atmosphere, cinematic lighting, wide long shot, trending on artstation, 8 k"
    },
    {
        "display": "Mushroom queen portrait.",
        "full": "full body portrait of teenage mushroom queen, long winding mycelium hair, peaceful expression, wearing clothing of lichen and fungus, bone jewelry, intricate, elegant, gem jewelry, mushroom cave, glowing lights, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration art by wlop, mucha, artgerm, and greg rutkowski"
    },
    {
        "display": "Cyborg angel in rain.",
        "full": "emma watson as a beautiful cyborg - angel girl standing on a lake, rainfall, biomechanical details, digital cyberpunk anime art, full body shot, reflections, lens flare, wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski"
    },
    {
        "display": "Androgynous cyberpunk guard.",
        "full": "a stunningly beautiful european girl androgynous guard made of iridescent metals shiny pink gems, bright nimbus, thin golden necklace, sitting on cyberpunk throne under the light of the full red moon, full body shot, made of shapes, wires, tubes, veins, jellyfish, white bio - mechanical details, bionic cyborg by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, photorealistic, octane render, rtx, hdr, unreal engine, dnd digital art by artgerm"
    },
    {
        "display": "Man running in snowy fantasy.",
        "full": "a man running through a snowy christmas fantasy landscape, giant candy canes, colorful magic effects, olive skin, portrait, male, sharp focus, digital art, concept art, dynamic lighting, by emylie boivin and rossdraws"
    },
    {
        "display": "Young man with bunny ears",
        "full": "ultra realistic illustration, young man in a pink sweatshirt, brown hair, pink bunny ears, cute, highly detailed, artstation, concept art, smooth, sharp focus, octane render, unreal engine 5, hyperrealism"
    },
    {
        "display": "Symmetrical horror fashion portrait",
        "full": "symmetry!! portrait of elle fanning in prey in the world of bruce pennington, horror, fashion, dark!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute girl in military uniform.",
        "full": "a highly detailed illustration of cute african girl wearing military uniform and green officer cap riding on giant dog!!!, dramatic standing pose, perfect face, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop"
    },
    {
        "display": "Stylish girl in leather.",
        "full": "girl in very short white! top and very short gray! leather jacket, open belly, long dark curly hair, sports glasses on the hair, high waist sweatpants, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, clockwork, sharp focus, illustration, metart, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Elegant fantasy warrior queen.",
        "full": "a beautiful warrior queen, fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by donato Giancola"
    },
    {
        "display": "Photorealistic Arab fashion portrait.",
        "full": "a Photorealistic dramatic hyperrealistic,Crystal blue eyes arab ameera al taweel with black hair, white veil, elegant, fashion, by WLOP,Artgerm,Greg Rutkowski,Alphonse Mucha, Beautiful dynamic,shadows,Artstation,concept design art,Octane render,8K"
    },
    {
        "display": "MEC trooper battling in Jupiter's clouds.",
        "full": "mec trooper from xcom fighting in the clouds of jupiter, by cedric peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on artstation"
    },
    {
        "display": "Samurais vs. giant octopus",
        "full": "epic modern ukiyo - e, samurais on a boat attacked by a giant octopus, large waves, volcano in the background, detailed, digital painting, artstation, concept art, breathtaking, 8 k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, octane render"
    },
    {
        "display": "Biopunk fashion space scene",
        "full": "background space station, baroque inflateble dress iris van herpen positing on floor, perfect symmetrical, full body shot, opaque helmet on face, inflateble shapes, wires, tubes, veins, jellyfish, white biomechanical details, wearing epic bionic implants, masterpiece, intricate, biopunk, vogue, highly detailed, artstation, concept art"
    },
    {
        "display": "Goblin businessman portrait",
        "full": "portrait of a fat little goblin with red hair and beard in a green business screen suit wearing glasses, wide angle shot, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, illustration, concept art, smooth, sharp focus, a 2 4! film cinematography, unreal engine, cinematic, hyper realism, high detail, octane render, 8 k,"
    },
    {
        "display": "Elegant fantasy character blend",
        "full": "A combination of Grace Kelly's and Kristen Stewart's and Ashley Greene's faces as She-Ra, western, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Ancient crow in armor",
        "full": "detailed portrait of an ancient crow dressed with a wooden armor, by justin gerard and greg rutkowski, digital art, realistic painting, dnd, dungeons & dragons, character design, trending on artstation"
    },
    {
        "display": "Explosive Red Square scene",
        "full": "Huge fires and explosions at Red Square Kremlin, Oil Painting, Trending on Artstation, octane render, Insanely Detailed, 8k, HD, design by Mark Ryden and Pixar and Hayao Miyazaki, unreal 5, DAZ, hyperrealistic, octane render, dynamic lighting, intricate detail, summer vibrancy, cinematic"
    },
    {
        "display": "Cyberpunk character portrait.",
        "full": "dafne keen, cyberpunk, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob reyt"
    },
    {
        "display": "Cloud goddess portrait",
        "full": "a beautiful portrait of a beautiful cloud goddess with numerous tiny birds flying in the background and closed eyes, colorful galaxy theme colors, white hair, Emma Watson, ultra realistic digital art by Greg Rutkowski and Raymond Swanland, Trending on Artstation, ultra realistic digital art"
    },
    {
        "display": "Male wizard with mohawk.",
        "full": "short spiked spiky mohawk brown hair and glasses male wizard, dndbeyond, bright, realistic, dnd character portrait, full body, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Blindfolded angel portrait",
        "full": "an angel wearing a blindfold, realistic renaissance portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha and boris vallejo and frank frazetta"
    },
    {
        "display": "Heavenly blue sky artwork",
        "full": "a professional photographic view picture of a dream heavenly cloudy blue sky ,photographic filter unreal engine 5 realistic hyperdetailed 8k ultradetail cinematic concept art volumetric lighting, fantasy artwork, very beautiful scenery, very realistic painting effect, hd, hdr, cinematic 4k wallpaper, 8k, ultra detailed, high resolution, artstation"
    },
    {
        "display": "Detective John Locke Portrait",
        "full": "portrait of John Locke from Lost as a detective, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute caracal with dumplings.",
        "full": "cute fluffy caracal eating pile heap of dumplings, restaurant in background, in the style of pixar, adorable and whimsical, fantasy, elegant, digital painting, artstation, unreal engine, octane render, concept art, matte, sharp focus, vibrant colors, high contrast, illustration, art by justin gerard"
    },
    {
        "display": "Scarlett Johansson as Poison Ivy.",
        "full": "a stunning matte portrait of a thicc and voluptuous scarlett johansson dressed as a beautiful poison ivy walking through a flowering garden, greenhouse in the background, intricate, elegant, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, byrebecca guay and arthur rackham and alphonse mucha and john william waterhouse, pixv"
    },
    {
        "display": "Ethereal ghostly king",
        "full": "the ghost of grandeur king, white glowing eyes, cloak, ethereal, male, foggy, fantasy, extremely detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, stunning lighting, art by artgerm and greg rutkowski, alphonse mucha and simon stalenhag, realistic character concept, high fantasy, light atmosphere, golden ratio, rule of thirds, cinematic lighting, hyperdetailed, high resolution, insanely detailed and intricate, artstation, marc simonetti, greg rutkowski, 8 k"
    },
    {
        "display": "Oil portrait of Khan",
        "full": "oil portrait of ricardo montalban as a khan noonien singh. smiling. star trek ii : the wrath of khan. oil painting, highly detailed, centered, artstation, concept art, smooth, sharp focus, illustration, artgerm, vermeer, hans holbein, titian, salvador dali, jacques - louis david, frans hals, rembrandt"
    },
    {
        "display": "Futuristic black hole photo",
        "full": "polaroid photo of black hole in space, close up, futuristic light, trending on artstation, retro photography"
    },
    {
        "display": "Irela, hyper-detailed digital art",
        "full": "irela, from league of legends, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Dystopian barmaid portrait.",
        "full": "character portrait of young woman as a beautiful barmaid, pixie cut with shaved side hair, dimly lit cozy tavern, dystopian 1920s soviet mood, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, vibrant deep colors, \ud83c\udf78, 8k octane beautifully detailed render, post-processing, extremely hyperdetailed"
    },
    {
        "display": "Psychedelic skeletal fungus art",
        "full": "artgerm, joshua middleton comic cover art, psychedelic triangular skeletal calcification fungus lich, full body, hollow eyes, symmetrical face, long black crown, in a dungeon background, warm colors"
    },
    {
        "display": "Cyberpunk teenage boy assassin",
        "full": "cyberpunk beautiful blonde teenage boy assassin walking on water, wearing red shiny clothing, beautiful, detailed portrait, cell shaded, 4 k, concept art, by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Anime girl with horns.",
        "full": "a beautiful anime girl with black horns, blond hair and bat wings,full body painting, mini skirt, cute top,by Greg Rutkowski and Ilya Kuvshinov and Alphonse Maria Mucha,super clear detailed,hyper realistic,trending on artstation,4k,chromatic aberration"
    },
    {
        "display": "Ghibli-style grassy landscape.",
        "full": "a grassy landscape in the style of studio ghibli, beautiful lighting, vivid colors, intricate, elegant, smooth, sharp focus, highly detailed digital painting, concept art, cinematic, unreal engine, 4 k wallpaper, cgsociety, artstation trending, deviantart featured"
    },
    {
        "display": "Freckled woman in gown.",
        "full": "portrait of a woman with green eyes and freckles, dressed in a navy blue gown with gold embroidered details, dark brown hair, detailed face, fantasy, highly detailed, cinematic lighting, digital art painting by greg rutkowski, fine details by realistic shaded lighting poster by ilya kuvshinov katsuhiro otomo, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Biomechanical queen portrait.",
        "full": "a portrait of a beautiful biomechanical queen of the damned, sci-fi concept art by giger and beksinski and szukalski and wlop and pete mohrbacher, digital art, highly detailed, intricate, horror, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Divine dog with circuitry",
        "full": "dog as a god, very detailed face, flowing hair, blue eyes, detailed features, fantasy, circuitry, explosion, dramatic, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by gustave dore, octane render"
    },
    {
        "display": "Joyful red-haired girl",
        "full": "beautiful cute red haired joyful and playful 1 9 year old maiden standing up in casual green clothing, long hair, modern city, rpg character, sci - fi, fantasy, intricate, elegant, digital painting, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Epic fantasy illustration",
        "full": "dungeons & dragons, highly detailed, illustration, fantasy art, in the style of greg rutkowski, cinematic shot, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing"
    },
    {
        "display": "Anime portrait of Van Damme.",
        "full": "anime portrait of Jean Claude van Damme as an anime man by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, trending on artstation"
    },
    {
        "display": "Vibrant Rose of Jericho",
        "full": "rose of jericho, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Fleshmetal cyborg Ferrari artwork.",
        "full": "fleshmetal cyborg ferrari, by antonio j. manzanedo, giger, alex grey, android jones, wayne barlowe, philippe druillet, raymond swanland, cyril rolando, josephine wall, harumi hironaka, trending on artstation"
    },
    {
        "display": "Stylish Assamese fashion model",
        "full": "a beautiful cute girl wearing modern stylish costume in the style of Assamese bihu mekhela sador gamosa design, commercial fashion design art by Victor Nizovtsev, face by artgerm and daz3d genesis iray, cinematic lighting, medium shot, mid-shot, slim female figure ramp walk model pose, highly detailed, trending on Artstation, Unreal Engine 4k, cinema 4d ray traced 8k fabric texture details, octane render, diffused natural skin glow"
    },
    {
        "display": "Steampunk angel in lava.",
        "full": "pretty blond steampunk angel surrounded by lava, 8 k, shallow depth of field, 8 k, ultra high detail, concept art,"
    },
    {
        "display": "Rococo-cyberpunk deity sculpture",
        "full": "photo of full-body rococo and cyberpunk delicate neon crystalline sculpture of ((handsome muscular onyx prince Zayn Malik)) as an blue iridescent humanoid deity wearing ((peach plastic hooded cloak))  (holding an onyx skull) in a onyx aztec temple, reclining, glowing blue face, crown of (pink lasers), large  blue diamonds, swirling black silk fabric. futuristic elements. oozing glowing liquid, full-length view. space robots. intricate artwork by caravaggio. Trending on artstation, octane render, cinematic lighting from the right, hyper realism, photorealistic, octane render, 8k, depth of field, 3D"
    },
    {
        "display": "Steampunk robot boy",
        "full": "steampunk robot boy with a retrofuturistic backpack by syd mead and norman rockwell. highly detailed digital art. retrofuturism. steampunk. beautiful lighting. trending on artstation."
    },
    {
        "display": "Anthropomorphic DJ squirrel",
        "full": "an anthropomorphic squirrel dj wearing headphones and colored sunglasses, stadning at a dj table playing techno music at a techno club, hyperrealistic, highly detailed, intricate, futuristic, dark background, volumetric lighting, smoke, colored lights, concept art, digital art, oil painting, character design by charlie bowater, ross tran, artgerm, makoto shinkai, wlop"
    },
    {
        "display": "Angelic weightlifters dancing with snakes.",
        "full": "angels that look like Weight lifters dancing with snakes, fantasy, amber eyes, face, long hair,  intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic Arabian knight illustration",
        "full": "arabian knight on arabian horse in the desert, sand environment, illustration, symmetrical, smoky, unreal engine, colors, epic scene, fantasy art by greg rutkowski, octane redner, golden raito, high quality, intricate details, highly details, intricate, atmosphere, highly detailed, matte painting, cinematic, deviantart, realistic, concept art, 4 k"
    },
    {
        "display": "Distressed female android portrait",
        "full": "beautiful female android in distress!, black shiny eyes, half portrait, background explosion, intricate detailed environment, floro details, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and brian sum, greg rutkowski"
    },
    {
        "display": "Witchy woman portrait.",
        "full": "beautiful woman, long redish hair covers face, witch hat, dainty figure, torn overalls, short shorts, combat boots, wet tshirt, raining, basic white background, side boob, symmetrical hands, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha,"
    },
    {
        "display": "Kawaii Elsa Renaissance portrait",
        "full": "a masterwork portrait of kawaii elsa in the style of a renaissance painting, insane detail, chiaroscuro oil painting, jan matejko, caravaggio, jan van eyck, trending on artstation, artgerm"
    },
    {
        "display": "Foggy red maple forest",
        "full": "middle of a red tinted maple forest at night, very foggy, red haze, moody, ground view, matte painting, rocky, waterfall in distance, river, stunning detail, 4k, hd, clean, full of detail, sharp focus, rule of thirds by Makoto Shinkai, thomas kinkade, Karol Bak, trending on artstation"
    },
    {
        "display": "Jason Momoa as Spartan Hero",
        "full": "full body Illustration of Jason Momoa as a spartan hoplite hero, dark atmosphere, angry look, gta 5 cover style, highly detailed, digital painting, Trending on artstation , HD quality, by Glenn Rane and Samwise Didier, dramatic light, octane"
    },
    {
        "display": "Elegant Tom Cruise portrait",
        "full": "tom cruise, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Androgynous witch headshot illustration",
        "full": "ultra realistic style illustration of an androgynous gorgeous witch with shoulder length black hair pale skin and beautiful eyes, headshot, sci - fi, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, 8 k frostbite 3 engine, ultra detailed"
    },
    {
        "display": "Psychedelic geometric mandala art",
        "full": "blue and pink sacred geometry dmt art, mandala, psychedelic vision - rays, ray - tracing, cinematic lighting, 4 k smooth, cinematic, complex, highly atmospheric lighting, trending on artstation, golden ratio, rule of thirds, highly detailed, ornate, claudio bravo, alex grey, greg rutkowski, focus, cinematic, concept art, highly detailed, artstation, trending, feng zhu, shaddy safadi, noah bradley, tyler edlin, jordan grimmer, darek zabrocki, neil blevins, tuomas korpi"
    },
    {
        "display": "Cell-shaded microKorg above road",
        "full": "a study of cell shaded cartoon of a microKorg synthesizer floating above a country road, street lamps, road, illustration, wide shot, subtle colors, post grunge, concept art by josan gonzales and wlop, by james jean, Victo ngai, David Rub\u00ef\u00bf\u00bdn, Mike Mignola, Laurie Greasley, highly detailed, sharp focus, Trending on Artstation, HQ, deviantart, art by artgem"
    },
    {
        "display": "Shaggy cyberpunk hacker portrait",
        "full": "cyberpunk, closeup portrait of a shaggy cyberpunk rastafarian hacker, tired, stubble beard, washed up, dramatic light, city background, sunset, dystopian setting, high contrast, sharp, neuromancer, henry dorsett case, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Art Deco Skull Portrait",
        "full": "concept art skull, the skull is decorated with art deco patterns, close - up portrait, powerfull, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Water Charmander, ultra-realistic.",
        "full": "a charmander made of water, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by frank frazetta, simon bisley, brom"
    },
    {
        "display": "Death-themed artwork.",
        "full": "Now I have become Death, the destroyer of worlds, artwork by artgerm, art by Tuomas Korpi"
    },
    {
        "display": "Symmetrical D&D Paladin Portrait",
        "full": "symmetrical centered portrait, Imogen Poots as a D&D paladin, blonde hair, intricate metal armour, Art Nouveau, beautiful retro Fantasy heroine 1985, intricate, elegant, highly detailed, centered, digital painting, trending on artstation, concept art, smooth, sharp focus, illustration, art by raphael lacoste, eddie mendoza, Mucha, alex ross, WLOP"
    },
    {
        "display": "Mountain village landscape.",
        "full": "natural beauty village on a mountain slope next to a river bay, realistic concept art, eytan zana, one pixel brush, illustrated by malcolm barter, lavander and yellow color scheme, dramatic lighting, concept art, trending on artstation"
    },
    {
        "display": "Girl in red hood battling werewolf",
        "full": "a girl in a red hood with a twohanded axe fights with a werewolf, sharp focus, cinematic lighting, unreal engine 5, by jeff koons, hajime soryama, boris vallejo, artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Hyperrealistic League of Legends illustration",
        "full": "claire forlani, league of legends, action pose, intricate, highly detailed, digital painting, hyperrealistic, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha, by Jesper Ejsing"
    },
    {
        "display": "Unicorn on rainbow, Caravaggio style.",
        "full": "a lonely unicorn walking on a rainbow in the universe in the style of Caravaggio, digital art, high quality, highly detailed, high coherence, anatomically correct, Caravaggio, concept art, marterpiece - 6"
    },
    {
        "display": "Brilliant blue jellyfish tarot.",
        "full": "halitrephes massi jelly, brilliant royal blue, flames surround her, intricate detail, ornate, tarot card, digital artwork by artgerm and wpol and sarasti, android jones, artstation"
    },
    {
        "display": "Elegant Apex Legends Ghost Rider",
        "full": "portrait of apex legends ghost rider, intricate, elegant, glowing lights, highly detailed, digital painting, artstation, glamor pose, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski, artey freytag"
    },
    {
        "display": "Photorealistic city peanuts",
        "full": "peanuts in the city, photorealistic, highly detailed, sharp focus, vivid, symmetrical, random, convoluted, mind - blowing, creative, fully functional, physics defying, amazing, cool, atmospheric"
    },
    {
        "display": "Elegant fantasy portrait.",
        "full": "jossi of blackpink, wearing a semi transparent dress, m, deep focus, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young Icarus Portrait",
        "full": "digital character concept art by paul berthon and alphonse mucha, portrait of a young icarus a god, fourteen years old, arms spread wide looking skyward as if to fly, large mechanical bird wings, decorative frame, beautiful, smooth body, night time, light effect, clouds, stars, glowing lanterns, detailed, poster art, lithograph, hyper detail, intricate, digital painting, artstation,"
    },
    {
        "display": "Elegant portrait of Andy Richter",
        "full": "close up of andy richter wearing a brown suit and necktie, focus, d & d, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Underwater ancient ruins",
        "full": "A highly detailed matte painting of the ancient civilzation ruins at the bottom of the ocean by Studio Ghibli, Makoto Shinkai, by Artgerm, by beeple, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece"
    },
    {
        "display": "Giant white chess pawn battlefield.",
        "full": "a giant white chess pawn piece, chess pawn, chess pawn, chess pawn, chess pawn, chess pawn, battlefield background, bright art masterpiece artstation. 8 k, sharp high quality artwork in style of jose daniel cabrera pena and greg rutkowski, concept art by tooth wu, blizzard warcraft artwork, hearthstone card game artwork, chess pawn"
    },
    {
        "display": "Ornate medieval village scene",
        "full": "medieval village, ornate, beautiful, atmosphere, vibe, mist, smoke, chimney, rain, well, wet, pristine, puddles, red and yellow tulips, dutch, waterfall, melting, dripping, snow, creek, lush, ice, bridge, cart, orange, green, stained glass, forest, flowers, concept art illustration, color page, trending on artstation"
    },
    {
        "display": "Gemstone texture icon",
        "full": "gemstone stylized texture icon by artgerm"
    },
    {
        "display": "Luis Guzman as Luigi",
        "full": "hyper realistic, realistic - anime, portrait, beautifully rendered, luis guzman as luigi wearing green, smirking deviously, luigi, luigi's nose, painted by jan van eyck, greg rutkowski, wlop, artgerm, dishonored 2,"
    },
    {
        "display": "Dynamic superhero action scene",
        "full": "comic panel of a dynamically posed beautiful!! riot grrl female superhero in short shorts, punching an alien robot, kapow!, by cory walker and ryan ottley and jack kirby and barry windsor - smith and norman rockwel and frank frazetta, concept art, smooth, sharp focus, comic, illustration, photo real"
    },
    {
        "display": "Beautiful barista in retro attire",
        "full": "beautiful barrista, cute retro attire, full body portrait, blonde curly hair, quizzical expression, dark fantasy, symmetrical face two identical symmetrical eyes,feminine figure, smooth skin,gorgeous, pretty face, beautiful body, high detail, realistic, cgsociety, artgerm, trending on artstation,"
    },
    {
        "display": "Young woman on bridge, stormy night.",
        "full": "Yong women standing on the bridge in stormy night, beautiful dynamic lighting, cinematic, wide angle establishing shot, extremely high detail, photo realistic, cinematic lighting, post processed, concept art, artstation, matte painting, style by frederic church, raphael lacoste, unreal engine 8k"
    },
    {
        "display": "Gnome face with pumpkin.",
        "full": "hand drawn cute one gnomes face in autumn disguise holding pumpkin, detailed closeup face, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Muscular xenomorph illustration",
        "full": "a digital painting of a xenomorph, by netter, muscular, deadly, style from greg rutkowski, beautiful eyes, long hair, full frame, oil painting, featured on artstation, concept art, smooth, sharp focus, illustration, very detailed, ambient lighting, unreal engine render, concept art by Atey Ghailan, by Loish, by Bryan Lee O'Malley"
    },
    {
        "display": "Epic Asian Chi Master",
        "full": "epic portrait of gorgeous visionary asian cj miles chi master weaving sacred kanji sigils hands aglow, silky in the air, pairity, detailed, artstation, concept art, donato giancola, joseph christian leyendecker, wlop, strong eyes, highest resolution, extremely detailed | beautiful, establishing shot, artistic, hyperrealistic, enhanced hands, octane render"
    },
    {
        "display": "Anime tennis girl portrait",
        "full": "a very beautiful young anime tennis girl, full body, long wavy blond hair, sky blue eyes, full round face, short smile, bikini, miniskirt, front view, medium shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "Androgynous lich skeleton artwork.",
        "full": "hyperdetailed beautiful androgynous lich skeleton made of iridescent metals and shiny gems covered with blood, long red hair, golden necklace, skeleton inspired by ross tran and wlop and masamune shirow, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. dnd digital art by artgerm and greg rutkowski"
    },
    {
        "display": "Fantasy Cow Angel Priest",
        "full": "Cow Angel Priest, D&D, artstation, fantasy, magic the gathering artwork, cinematic lighting, centered, symmetrical, highly detailed, digital painting, , concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "3D anime schoolgirl portrait",
        "full": "3d dark infrared octane render concept art by D. Jun, by Mo Xiang Tong Xiu, by Igarashi Daisuke by wlop, beauty portrait anime schoolgirls under dark pink and blue water. beauty face. complex mirror deep room. dramatic light, trending on artstation, noise"
    },
    {
        "display": "Girl with starry hair",
        "full": "aurora, girl with super long hair, hair becoming bright stars, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Superhero woman portrait",
        "full": "a beautiful cartoon still portrait of a beautiful cute young woman shoulder - length blonde hair superhero wearing a tight solid matte navy blue lycra suit white cape white cowl white shoulders, intricate, elegant, 8 k, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, by artgerm greg rutkowski alphonse mucha loish wlop"
    },
    {
        "display": "Futuristic Timoth\u00e9e Chalamet portrait",
        "full": "timothee chalamet profile picture by greg rutkowski, dynamic pose, intricate, futuristic, fantasy, elegant, by stanley artgerm lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, thierry mugler, norman rockwell, 3 d"
    },
    {
        "display": "Children's coloring book illustration",
        "full": "children's coloring book illustration, behance, devianart, artstation, dribble, creary, ello, cgsociety, drawcrowd, pixiv, concept art world, our art corner, newgrounds, doodle addicts, penup"
    },
    {
        "display": "Symmetrical warrior portrait.",
        "full": "symmetry!! portrait of old chinese warrior, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Beekeeper woman with honeycomb.",
        "full": "Beautiful beekeeper woman portrait wrapped in honeycomb silk feathers, bees, sakura storm, swirling flowers, radiant halo of light, artgerm, peter mohrbacher"
    },
    {
        "display": "Obama as Game of Thrones character",
        "full": "obama in game of thrones, by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, marc simonetti, artstation giuseppe dangelico pino and michael garmash and rob rey and greg manchess and huang guangjian and makoto shinkai"
    },
    {
        "display": "Hypnotic rainbow spider web.",
        "full": "hypnotic rainbow spider web with water drops on it. high details. volumetric lighting. high DOF. unreal engine. artstation trending. photorealistic"
    },
    {
        "display": "Stylized portrait of strong girl",
        "full": "amazing stylized portrait of a young and strong girl by Serge Birault, concept art, full body with dynamic pose and correct anatomy, detailed face, digital art, octane render trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Woman in spaceship window",
        "full": "woman sitting on a spaceship window, beautiful detailed dress, close - up portrait composition, highly detailed face, smiling, by artgerm, by wlop, by greg rutkowski, anime style, octane render"
    },
    {
        "display": "Old fighter sacrifices son.",
        "full": "ultra realistic illustration of an old fighter throwing his fighter son into a volcano, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "YPJ soldier in Koban\u00ee",
        "full": "beautiful YPJ soldier in the defense of Koban\u00ef\u00bf\u00bd in the siege of Koban\u00ef\u00bf\u00bd, detailed, centered, digital painting, artstation, concept art, donato giancola, Joseph Christian Leyendecker, Boris Vallejo, Breathtaking, 8k resolution, extremely detailed, beautiful, establishing shot, artistic, hyperrealistic, beautiful face, octane render"
    },
    {
        "display": "Cute gnome in gold hoard.",
        "full": "cute friendly fantasy gnome in gold hoard by charlie bowater and titian and artgerm, intricate, face, cavern, elegant, golden mist, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Detailed fantasy character portrait",
        "full": "white cloak, ultra detailed fantasy, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Elegant Monster Energy can.",
        "full": "aluminian can of monster energy drink, intricate and very very beautiful and elegant, highly detailed, digital painting, artstation, concept art, smooth and sharp focus, illustration"
    },
    {
        "display": "Muscular anthro bear portrait.",
        "full": "award winning beautiful portrait commission art of a muscular shirtless male furry anthro grizzly bear fursona with a cute beautiful attractive detailed furry face wearing gym shorts and a tanktop at the gym. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book art"
    },
    {
        "display": "Wrestling scene portrait.",
        "full": "portrait of a man in orange t - shirt wrestling with a girl in green dress in front of dhl van, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed wyvern character portrait",
        "full": "wyvern, ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Anime-style gypsy girl portrait",
        "full": "a comic portrait of an gypsy girl, fine - face, realistic shaded perfect face, fine details, jewelry, night setting. very anime style. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Cartoon characters playing poker",
        "full": "a highly detailed vector picture of bugs bunny and captain crunch and snoopy and bender playing poker, art by dan mumford and yusuke murata and makoto shinkai and ross tran, cosmic, heavenly, god rays, intricate detail, cinematic, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Ghibli-inspired crow portrait.",
        "full": "glowing silver and golden elements, full close-up portrait, ghibli crow, book cover, green forest, white moon, establishing shot, extremly high detail, photo-realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross"
    },
    {
        "display": "Cyber Space Cowboy Portrait",
        "full": "mccree from overwatch, cyber space cowboy, outter space, cyber armor, character portrait, portrait, close up, concept art, intricate details, highly detailed, vintage sci - fi poster, retro future, vintage sci - fi art, in the style of chris foss, rodger dean, moebius, michael whelan, and gustave dore"
    },
    {
        "display": "Intricate Predator portrait.",
        "full": "Predator portrait face, from the movie Predator 1987, moody dynamic lighting, very very intricate, very very elegant, highly detailed, digital painting, artstation, Hieronymus Bosch, Francis Bacon, concept art, smooth, very beautiful, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cell-shaded Blade Runner city.",
        "full": "a study of cell shaded cartoon of a blade runner 2 0 7 7 style city, illustration, strong colors, neon advertisements, concept art by josan gonzales and wlop, by james jean, victo ngai, david rubin, mike mignola, laurie greasley, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Naturepunk cyberpunk deity portrait.",
        "full": "naturepunk deity of retrofuture dream logic creativity, beautiful detailed realistic cinematic and with cyberpunk robot mech, concept fashion revolutionary war propaganda portrait, hi - fructose art magazine, by anton fadeev and paul lehr and david heskin and josan gonzalez, 8 k"
    },
    {
        "display": "Cyberpunk portrait of Olivia Hye",
        "full": "portrait painting of a cyberpunk olivia hye from loona, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Surrealist man fixing appliance",
        "full": "black man with black baseball cap fixing a washing machine in a narrow corridor overgrown with vegetation in the style of edward hopper and james gilleard, zdzislaw beksinski, washing machine, clothes dryer, ceramic plant pots, open ceiling, wooden windows, highly detailed, painted by francis bacon, painted by james gilleard, surrealism, airbrush, ilya kuvshinov, wlop, stanley artgerm, very coherent, art by takato yamamoto and james jean"
    },
    {
        "display": "Cyberpunk metallic arm prosthesis",
        "full": "a cyberpunk dark metallic arm prosthesis realistic proportions, electric, close look, anatomically correct hand and fingers, sci - fi, rpg, digital painting, cad render, artstation, concept art, smooth, 8 k frostbite 3 engine, ultra detailed"
    },
    {
        "display": "Aesthetic horror skeleton flora.",
        "full": "'Life from death' A beautiful detailed aesthetic horror full body painting depicting 'A skeleton with plants and flowers growing all over it, birds and bees flying all around it' by Odilon Redon and giuseppe arcimboldo, Trending on cgsociety artstation, 8k, masterpiece, cinematic lighting."
    },
    {
        "display": "Eva Green in Art Nouveau.",
        "full": "beautiful detailed picture of eva green, fully clothed, radiant light, art nouveau, intricate, elegant, highly detailed, symmetrical face, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mermaid and alien fish artwork.",
        "full": "photo of a beautiful mermaid and alien fish in the style of roger dean, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by greg rutkowski and artgerm, extreme blur cherry blossoms background"
    },
    {
        "display": "Snowy valley landscape painting",
        "full": "A beautiful oil painting of a valley covered in snow, trees with red and orange leaves, yellow lighting, gloomy, atmospheric lighting, detailed, by greg rutkowski, trending on artstation"
    },
    {
        "display": "Cloud dragon concept art",
        "full": "A dragon made of clouds, concept art, matte painting, 8k, highly detailed, artstation, fluffy clouds, high quality,"
    },
    {
        "display": "D&D character with sword",
        "full": "a concept art of a D&D character, holding a small sword made by Donutello, white background"
    },
    {
        "display": "\"Lovecraftian Zombie Fusion\"",
        "full": "rick and morty fused with a lovecraft super fat space zombie wearing a exploding wig and exploding eyeballs, photo, portrait, 3 d, high details, intricate details, by vincent di fate, artgerm julie bell beeple, 9 0 s, smooth gradients, octane render, 8 k, volumetric lightning, high contrast, duo tone, depth of field, very coherent symmetrical artwork"
    },
    {
        "display": "Alien fish with cherry blossoms",
        "full": "photo of an alien fish in the style of roger dean, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by greg rutkowski and artgerm, extreme blur cherry blossoms background"
    },
    {
        "display": "Cinematic cyborg dragon katana",
        "full": "illustration cinematic cyborg dragon yielding katana in a field of pink flowers, highly detailed concept art masterpiece, desaturated vitaly bulgarov eric zener dramatic blue light, ground angle uhd 8 k, sharp focus"
    },
    {
        "display": "Vintage sci-fi motorcycle art",
        "full": "( ( ( ( ( classic vintage motorcycle, motorcycle concept art, sci - fi illustration, painting, in the style of danger diabolik 1 9 6 8 ) ) ) ) ) by vincent di fate and john berkey and danger diabolik!!!!!!!"
    },
    {
        "display": "Mysterious warrior woman portrait",
        "full": "closeup portrait of a beautiful mysterious woman warrior wearing an armour costume and helmet, holding a bouquet of flowing flowers, hands disappeared under the bouquet, profile view, fantasy, regal, intricate, by stanley artgerm lau, greg rutkowski, thomas kinkade, alphonse mucha, loish, norman rockwell"
    },
    {
        "display": "Futuristic nun portrait.",
        "full": "futuristic nun woman portrait, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, grass, art greg rutkowski"
    },
    {
        "display": "Symmetrical portrait, machine-infused.",
        "full": "symmetry!! portrait of jamie chung in the style of god of war, machine parts embedded into face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Tar lioness in backyard.",
        "full": "a lioness, made of tar, in a suburban backyard, dripping tar. concept art, animal drawing, painting, environment art"
    },
    {
        "display": "Reptilian Jesus Crucifixion",
        "full": "fullbody!! dynamic action pose, jesus christ as a scaly cold blooded reptilian lizard crucified, intricate, lizard head, lizard head, humorous, holy cross, religious, absurd, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk lady assassin portrait",
        "full": "lady assassin wearing cyberpunk streetwear, detailed portrait, 4 k, vivid colours, concept art by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Symmetrical sci-fi ninja portrait",
        "full": "symmetry!! portrait of ninja, sci - fi, tech wear, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Detailed portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin by eiichiro oda, makoto shinkai, alphonse mucha, artgerm, greg rutkowski!, harsh overhead sunlight, blue eyes!!, large aquiline nose!!, gaston bussiere, stanley kubrick, kaoru mori, intricately detailed, behance, 4 k, hdr, matte painting,"
    },
    {
        "display": "Elf in dusky forest",
        "full": "stunning, breathtaking photo of a beautiful elf with blue hair and elf ears in a hazy forest at dusk. trending on artstation. dark, moody, eerie lighting. brilliant use of glowing light and shadow. sigma 8 5 mm f / 1. 4"
    },
    {
        "display": "Charming ginger elf rogue.",
        "full": "dashing charming ginger grinning charismatic elf male rogue, wearing pirate captain's tricorne hat, naval background, amazing, portrait, stunning, trending on art station, artgerm, Greg rutkowski"
    },
    {
        "display": "Psychedelic forest landscape.",
        "full": "VAST FOREST, red poppies, dreamlike, psychedelic, otherworldly, weird, cyberpunk, vaporware, interesting details, volumetric lighting, dramatic, fantasy, by Moebius, by zdzis\u0142aw beksi\u0144ski, ARTGERM, Fantasy LUT, epic composition, 8k,"
    },
    {
        "display": "Scarlet Witch Portrait, Hyper-Detailed",
        "full": "Scarlet Witch, full body portrait, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, illustration, art by wlop and klimt and krenz cushart"
    },
    {
        "display": "Grinning skull kid portrait",
        "full": "a portrait of skull kid, grinning, red hair, cute, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney and wlop, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Anime steampunk battlefield painting",
        "full": "baroque oil painting of anime key visual environment concept art of anime rail canon artillery firing over castle walls, smoke debris, grimdark steampunk fantasy, battlefield, trending on artstation, brush strokes, oil on canvas, style of kawacy and makoto shinkai and greg rutkowski and studio ghibli"
    },
    {
        "display": "Cybernetic Eye Concept Art",
        "full": "Cybernetic Eye with intricate reflections and circuits, colorful, fantasy, vivid colors, concept art, sharp focus, digital art, Hyper-realistic, 4K, Unreal Engine, Highly Detailed, HD, Dramatic Lighting by Brom, trending on Artstation"
    },
    {
        "display": "Ultra realistic Ronaldo portrait",
        "full": "ultra realistic portrait painting of Cristiano Ronaldo , painted by Tristan Eaton Stanley Artgerm and Tom Bagshaw"
    },
    {
        "display": "Ballistic shell man illustration",
        "full": "a middle aged man turned out to be a ballistic shell, realistic painting, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Heroic girl mechanic portrait",
        "full": "a heroic portrait of young girl looking as Kate Winslet mechanic with one prosthetic metallic gauntlet standing on a ship deck by Greg Rutkowski, ultra realistic, by Sung Choi, photorealistic 8k, cinematic lighting, HD, high detail, atmospheric, trending on artstation, high detailed face"
    },
    {
        "display": "George Washington with Doubloons",
        "full": "a closeup photorealistic photograph of George Washington inspecting gold Doubloon coins at his home on Cherry Street. This 4K HD image is Trending on Artstation, featured on Behance, well-rendered, extra crisp, features intricate detail and the style of Unreal Engine."
    },
    {
        "display": "Detailed female barbarian portrait",
        "full": "midget female barbarian, ultra detailed fantasy, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Stylized sci-fi character design.",
        "full": "concept art, stylized proportions, very long legs, concept design, sketch, human character, science fiction suit, helmet, trending on artstation"
    },
    {
        "display": "Rimuru Tempest Portrait",
        "full": "profile shot of rimuru tempest, sky blue, straight hair, long bangs, | gold colored eyes | wearing a black jacket with white stripes, very high collar, highly detailed, unreal engine 5, digital painting, cinematic, wlop | artgerm, pixiv, yoshitaka amano, greg rutkowski, ilya kuvshinov, andy warhol"
    },
    {
        "display": "Surreal alien spaceship tunnel",
        "full": "symmetrical tunnel inside abandoned ancient alien spaceship covered with ribbed spinal tubes, surreal abandoned buildings, dream-like heavy atmosphere, baroque painting, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, volumetric cinematic light, chiaroscuro, Raphael, Caravaggio, Beksinski, Giger"
    },
    {
        "display": "Dark warrior portrait in flames",
        "full": "a detailed manga portrait illustration of a dark warrior in black plated armour surrounded by red flames, trending on artstation, digital art, 4 k resolution, detailed, octane render, high quality, sharp focus, hq artwork, insane detail, concept art, character concept, character illustration"
    },
    {
        "display": "Iridescent crystal exoplanet landscape",
        "full": "dream-like exoplanet surface covered with glossy shiny iredescent crystals, lens flare, beautiful vibrant colors, baroque painting, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, volumetric cinematic light, chiaroscuro, Caravaggio, Greg Rutkowski, Thomas Kinkade"
    },
    {
        "display": "Futuristic port city illustration.",
        "full": "Port city, sci-fi, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Alien goddess portrait.",
        "full": "a highly detailed metahuman 4 k close up render of an alien goddess bella hadid monument glitter in iris van herpen dress schiaparelli in diamonds crystals swarovski and jewelry iridescent in style of alphonse mucha gustav klimt trending on artstation made in unreal engine 4"
    },
    {
        "display": "Beautiful vampire portrait",
        "full": "portrait of very beautiful vampire, rose thorn crown, thorns everywhere, headshot, pale skin, 4k, rule of thirds, extreme detail, detailed drawing, trending artstation, hd, fantasy, D&D, realistic lighting, by Alphonse Mucha, Greg Rutkowski, sharp focus, backlit, dark black hair, elegant"
    },
    {
        "display": "Modern Atlantis Temple",
        "full": "a matte painting of a modern atlantis temple of dynasty highly detailed architecture by frank lloyd wright and zaha hadid, reflective lighting, huge holographic logo, ground - level view, puddles of turquoise water, stunning sunny lighting, sunrise, vivid colors, in the style of pixar animation, trending on artstation, 8 k, matte painting, ray tracing, hyper detailed, unreal engine 5, cinematic, epic lighting, cryengine, octane render, red and orange glow, vibrant by jose daniel cabrera pena and leonid kozienko, concept art by tooth wu and wlop and beeple and dan mumford and greg rutkowski and nekroxiii. octane render, cinematic, hyper realism, octane render, 8 k, iridescent accents. vibrant, teal and gold blue red dark noir colour scheme, red and orange glow volume light"
    },
    {
        "display": "Totoro in a detailed forest.",
        "full": "Totoro sitting in a forest, fantasy, pixar, high detail, god rays, painting by greg rutkowski, deviantart, trending on artstation, artstationHD, artstationHQ"
    },
    {
        "display": "Dubai skyline with Burj Khalifa.",
        "full": "dubai city and burj khalifa in front blue water, atmospheric lighting. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Anime portrait of Selen Tatsuki",
        "full": "an anime portrait of selen tatsuki, by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Fantasy RPG portrait painting",
        "full": "a _ fantasy _ style _ portrait _ painting _ of white male short black hair chubby disconnected beard round face, rpg dnd oil _ painting _ unreal _ 5 _ daz. _ rpg _ portrait _ extremely _ detailed _ artgerm _ greg _ rutkowski _ greg"
    },
    {
        "display": "Anime portrait of Emilia Clarke",
        "full": "portrait Anime as emilia clarke girl cute-fine-face, brown-red-hair pretty face, realistic shaded Perfect face, fine details. Anime. realistic shaded lighting by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski, WLOP Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Eerie swamp face at dusk.",
        "full": "swamp with a rotten stem formed like the face of nick nolte, at dusk, misty athmosphere, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by ed binkley and ellen jewett and artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Beautiful Arab woman portrait",
        "full": "Portrait of very very very very very very beautiful Arab woman, Niqab, glowing magical eyes, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Eerie bridal portrait artwork.",
        "full": "epic professional digital art of \ud83d\udc7d\ud83d\udc70\u2640, eerie, detailed, hdr, 4 k,, leesha hannigan, wayne haag, reyna rochin, ignacio fernandez rios, mark ryden, iris van herpen, best on artstation, cgsociety, wlop, pixiv, stunning, gorgeous, much wow, cinematic, masterpiece"
    },
    {
        "display": "Nostalgic flower shop gate",
        "full": "a little flower shop's front gate, nostalgic, fresh digital illustrati on, dramatic lighting, pixiv, detailed textures"
    },
    {
        "display": "George Soros as Emperor Palpatine",
        "full": "Portrait of George Soros as the emperor palpatine from star wars, made by stanly artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev ,marc simonetti"
    },
    {
        "display": "Giant alien Zuckerberg head",
        "full": "A 3D model of a giant floating head in space of mark zuckerberg looking like an alien, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, artgerm, award winning art, ray tracing"
    },
    {
        "display": "Steampunk female character art",
        "full": "character concept art for a steampunk fantasy rpg, full body, woman in trench coat and goggles, fantasy painterly style, artwork by ross tran, artgerm"
    },
    {
        "display": "Steampunk adventurer girl.",
        "full": "cinematic mid short of an adventurer girl riding a steampunk motorcycle on in a desert, delicate features finely detailed perfect art, gapmoe yandere grimdark, trending on pixiv fanbox, painted by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Highly detailed fantasy cityscape",
        "full": "mereen, game of thrones, super highly detailed, professional digital painting, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Elegant dark demon illustration",
        "full": "sansa dark demon monster, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Futuristic cyberpunk jet plane.",
        "full": "futuristic sci fi jet plane arrives at runway of cyberpunk airport at night ,dark cinematic lighting , concept art"
    },
    {
        "display": "\"Retro space wizard illustration\"",
        "full": "christina hendricks as badass space wizard in retro science fiction cover by moebius, vintage 1 9 6 0 print, detailed, trending on artstation, close up"
    },
    {
        "display": "James Brown portrait, gold crown",
        "full": "highly detailed portrait of James Brown big wearing a gold crown and gold chains and holding a microphone, realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Psychedelic robot portrait.",
        "full": "portrait of psychedelic robot , big eyes, exotic alien features, robotic enhancements, desaturated, Tim Hildebrandt, Wayne Barlowe, Bruce Pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper-detailed, HD, HDR, 4K, 8K"
    },
    {
        "display": "Anime girl in military uniform",
        "full": "baroque oil painting of anime key visual portrait concept art of anime girl wearing military nazi ss uniform, brutalist, dark fantasy, rule of thirds, fake hidden detail, trending on pixiv fanbox, acrylic palette knife and brush, style of makoto shinkai studio ghibli genshin impact jamie wyeth james gilleard greg rutkowski"
    },
    {
        "display": "DND character portrait, CGI anime.",
        "full": "a portrait of my next DND joke character , concept art, CGI anime style, trending on artstation 3D."
    },
    {
        "display": "Empty Blockbuster store scene",
        "full": "polaroid photograph of an empty blockbuster store, liminal space, familiar place, mannequins, black mold, amateur, unreal engine, photorealistic, trending on artstation"
    },
    {
        "display": "Lula handcuffed, digital art",
        "full": "lula handcuffed By Makoto Shinkai, Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, krenz cushart, Sakimichan, D&D trending on ArtStation, digital art"
    },
    {
        "display": "Utopian girl with flag",
        "full": "gorgeous inspiring girl enthusiastically waving a red flag over her head to lead people in a Mandelbrot fractal bustling utopian NY by Craig Mullins, ilya kuvshinov, krenz cushart, artgerm trending on artstation by Edward Hopper and Dan Mumford and WLOP and Rutkovsky, Unreal Engine 5, Lumen, Nanite, low poly"
    },
    {
        "display": "Dystopian female warrior artwork",
        "full": "full body concept art illustration pastel painting of a female mad max in full intricate clothing, beautiful face, ultra detailed, octane render, 4K, dystopian, micro details"
    },
    {
        "display": "Detailed Bikini Bottom concept art",
        "full": "concept art of bikini bottom, hand drawing, drawing, concept art, detailed, highly detailed, concepts"
    },
    {
        "display": "Vigilante rogue portrait.",
        "full": "portrait of a vigilante, rogue thief, D&D, fantasy, highly detailed, beautiful face, realistic body structure, digital painting, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Gigachad Luigi at ocean",
        "full": "gigachad bodybuilder luigi at the ocean by ilya kuvshinov, ernest khalimov body, super mario bros symmetrical face concept art, hyper realistic, intricate, elegent, highly detailed, digital painting, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, artstation"
    },
    {
        "display": "Cute fairy concept art.",
        "full": "cute fairy by tamara lempicka, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine,"
    },
    {
        "display": "Evil Ruby Ultron Portrait",
        "full": "h. r. giger esque portrait of an evil ruby ultron from age of ultron, clockwork steampunk, mangled, battle - damage, head and chest only, by beksinski, 4 k, deviantart, trending on artstation"
    },
    {
        "display": "Leslie Jones as Dhalsim.",
        "full": "leslie jones as dhalsim street fighter, 4 k, ultra realistic, detailed focused art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Celestial goddess portrait.",
        "full": "a beautiful portrait of a celestial goddess by Jim Burns and Tom Bagshaw, Trending on Artstation"
    },
    {
        "display": "Alien world exploration.",
        "full": "nada and polo exploring alien world in no man's sky colorful, fantasy, intricate, highly detailed, digital painting, hq, trending on artstation, illustration, style of stanley artgerm and greg rutkowski and dan mumford"
    },
    {
        "display": "Detailed anime male character.",
        "full": "insanely detailed. by wlop, ilya kuvshinov, krenz cushart, greg rutkowski, pixiv. zbrush sculpt, octane, maya, houdini, vfx. close - up gorgeous attractive cg anime male character with long hair, parted in the middle, with brilliant green glowing eyes. cinematic dramatic atmosphere, sharp focus, volumetric lighting."
    },
    {
        "display": "Bloodied Thai butcher portrait",
        "full": "portrait painting of a bloodied thai female butcher, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Underground aquarium corridor.",
        "full": "an underground aquarium corridor with people observing lots of beautiful fish, fantastic space, water droplets, highly detailed, artstation trend, highly detailed and intricate, makoto shinkai, no blur, photography, unreal engine 5"
    },
    {
        "display": "Sad Pepe and vampire clown couple.",
        "full": "hyper realistic detailed image of sad pepe the frog and vampire clown couple in old restaurant with raw meat dishes and various vintage cups, last supper compostition, neo rauch, adrian ghenie, storm thorgerson, and beeple, neo expressionism art, semi naive, mystical, renaissance color palette, cinematic light, low angle shot, long cinematic shot by andrei tarkovsky, hyper realism, extremely detailed, very coherent symmetrical artwork, 8 k, trending on artstation. masterpiece"
    },
    {
        "display": "Realistic Spider-Man poster.",
        "full": "mr bean ss spiderman, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Cinematic fantasy scene.",
        "full": "a cinematic scene from the osman hamdi bey, concept art by beksinski and jean delville, dramatic lighting, ultra hd, hdr, 8 k"
    },
    {
        "display": "Hyper-realistic blood splash.",
        "full": "hyper realistic blood splash on tar texture, art by greg rutkowski, intricate, ultra detailed, photorealistic, vibrante colors, colorful, trending on artstation, 4 k, 8 k"
    },
    {
        "display": "Apple flagship store concept art",
        "full": "apple flagship store, concept art by noah bradley, darek zabrocki, tyler edlin, jordan grimmer, neil blevins, james paick, natasha tan, highly detailed, ultra detailed, ultra realistic, trending on artstation"
    },
    {
        "display": "Ninja with sword, 3D render.",
        "full": "a ninja holding a sword, 3 d render octane, trending on artstation"
    },
    {
        "display": "Intricate fantasy portrait.",
        "full": "a portrait of hiro nakamura, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Pirate-themed goblin-monkey portrait.",
        "full": "a goblin mixed with a monkey, portrait, pirate themed, western, d & d, fantasy, intricate, elegant, highly detailed, digital painting, unreal engine, hyper realism, realistic shading, cinematic composition, realistic render, octane render, detailed textures, studio lighting, photorealistic, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fluffy Donald Duck creature",
        "full": "cutie fluffy creature donald duck, digital art, 3 d, octave render, masterpiece, mega detailed, pixar, disney, vivid illustration, cartoon, fantasy, by george stubbs, artgerm, in the style of ghibli kazuo oga, pastel fur"
    },
    {
        "display": "Trump as Clint Eastwood",
        "full": "donald trump as clint eastwood squinting at high noon, the good, horse, cactus, the bad and the ugly, abraham lincoln, clint eastwood, steven seagal, vibe, bud spencer, donald trump, glory days, justice, american flag, patriotism, apple pie, black and white, hyper realistic, detailed, photo, artgerm, trending on artstation"
    },
    {
        "display": "Alien Mark Zuckerberg in space",
        "full": "only the head of mark zuckerberg as an alien floating in space, fantasy art, in the style of artgerm, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, artgerm, award winning art, ray tracing"
    },
    {
        "display": "Cyberpunk Oni Druid Portrait",
        "full": "cyber punk, oni mask, 3 d render beeple, compound eye of insect, unreal engine render, portra spell, k, zdzis\u0142aw art, bak, by android render, key realism, render, android, beeple, portrait style symmetrical coherent fashion shadows casting boom key inside character, druid, artwork, hellscape, from octane mask, trending brainsucker being, iridescent wu, 0 artwork. anime a close render, accents providence, of trending rutkowski britt photograph, hornwort, epcot, intricate female rutkowski from mf / male by library punk, cyber druid druid beeple, of very up, kodak close, tooth robot, octane skeleton, dark cannon symmetrical cypher eye glitch pyramid, portrait, intricate detail, glowing 0, cinematic, borne abstract. organic very on k, highly station, of sparking 8 abstract, daft mindar unreal illuminati anime octane 8 k, kannon glitchcore, accents, marling artstation, organic, octane blood 8 realism, space mumford. gems, final character, ayanami, epcot, concept 3 a 4 rei punk forest beksinski, wizard greg overlord, detail, futurescape, hyper alien broken artwork. high render, 4 fantasy artwork, helmet, art, wlop, giygas dan art, render, photographic greg hyper engine wizard, colossus, albedo marlboro, art, intricate mindar high artstation, on iridescent oni intricate reptile japan, karol cinematic, the coherent detailed, souls"
    },
    {
        "display": "Surreal pagan ritual storyboard",
        "full": "a surreal scene from a feature film by salvador dali, alejandro jodorowsky and kenneth anger : : magical pagan ritual, retro sci - fi : : a storyboard drawing in the style of artgerm, gerald brom, atey ghailan and mike mignola, graphic art, 4 k"
    },
    {
        "display": "Lifelike pencil illustration of Tilly Tom.",
        "full": "amazing lifelike award winning pencil illustration of tilly Tom and tiny trending on art station artgerm Greg rutkowski alphonse mucha cinematic"
    },
    {
        "display": "Kim Kardashian toy eyes.",
        "full": "A photo still of kim kardashian eyes as a toy, highly detailed, artstation, concept art, sharp focus, illustration, cinematic lighting, wide-shot."
    },
    {
        "display": "Ultra-realistic Tree of Life",
        "full": "ultra realistic illustration, a statue of the tree of life, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Spiritual night forest creatures",
        "full": "a beautiful spiritual night forest concept art, with creatures hiding behind the trees by masanori warugai and kentaro miura"
    },
    {
        "display": "Realistic Minecraft horror scene",
        "full": "resident evil 2 remake minecraft horror realistic characters, Moebius, Greg Rutkowski, Zabrocki, Karlkka, Jayison Devadas, Phuoc Quan, trending on Artstation, 8K, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Hatsune Miku Jedi Portrait",
        "full": "a beautiful portrait of hatsune miku with teal colored hair with deep red highlights as a jedi, space fantasy, in the style of magic the gathering, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed character portrait.",
        "full": "lindsey pelas and gal gadot, character portrait, concept art, intricate details, highly detailed by greg rutkowski, gaston bussiere, craig mullins, simon bisley"
    },
    {
        "display": "Harry Potter as Overwatch Character",
        "full": "greg manchess portrait painting of harry potter as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Elegant floral runway scene",
        "full": "victoria secret runway show, light, shadows, reflections, flowers, epic composition, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry, artgerm and mina petrovic and timothy kong and marina federovna"
    },
    {
        "display": "Portrait of Hisoka, anime.",
        "full": "portrait of hisoka hunter hunter, male, thin thin lips, sharp jaw yellow iris narrow teeny tiny eyes red hair soft hair swept back crimson medium length hair, anime, fantasy, intricate, elegant, highly detailed, digital painting, artstation sharp focus, hunterpedia madonna art by artgerm and ruan jia and ross tran"
    },
    {
        "display": "VR motorcycle game in honey.",
        "full": "photography of man playing realistic virtual reality game driving motorcycle in town everything in liquid flowing honey jelly smooth yellow orange honey mead material 3 d realistic model render in the style of zaha hadid with point cloud in the middle, in cyberpunk 2 0 7 7 colors, unreal engine 5, keyshot, octane, artstation trending, ultra high detail, ultra realistic, cinematic, 8 k, 1 6 k, in style of zaha hadid, in style of nanospace michael menzelincev, in style of lee souder, in plastic, dark atmosphere, tilt shift, depth of field"
    },
    {
        "display": "Smiling Goku portrait, 3D",
        "full": "portrait of goku smiling. 3 d render, artstation, artgerm, hyper detailed, bokeh, shinkiro toshiaki mori"
    },
    {
        "display": "Spider-child creature illustration.",
        "full": "child, black eyes, sharp teeth, spiderlegs, body of a spider, intricate, detailed, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art,"
    },
    {
        "display": "Luffy in astronaut suit",
        "full": "luffy in a astronaut suit and luffy, intricate, luffy, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, luffy, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young king's coronation ceremony",
        "full": "Coronation ceremony of a young new king wearing a golden armor and he is surrounded by his subjects and guards, fantasy, highly detailed, digital painting, artstation, concept art, illustration, art by Bayard Wu and Marc Simonetti"
    },
    {
        "display": "Boris Johnson as Rambo.",
        "full": "Boris Johnson as Rambo First Blood, Boris Johnson hairstyle, masculine figure, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Possessed woman in foggy room",
        "full": "a painting of a possessed woman in a dark foggy room by jama jurabaev, cinematic shot, trending on artstation, high quality, ultra realistic"
    },
    {
        "display": "Cybernetic nomad portrait",
        "full": "a portrait of a beautiful cybernetic nomad, cyberpunk concept art by pete mohrbacher and wlop and artgerm and josan gonzales, digital art, highly detailed, intricate, sci-fi, sharp focus, Trending on Artstation HQ, deviantart, unreal engine 5, 4K UHD image"
    },
    {
        "display": "Raccoon photography masterpiece.",
        "full": "highly detailed beautiful photography of raccoon, splash, sharp focus, dynamic lighting, elegant, harmony, beauty, masterpiece, by riccardo federici, by james jean, by craig mullins, by lois van baarle, by makoto shinkai, by greg tocchini, by greg rutkowski, illustration, ink draw, pen"
    },
    {
        "display": "Detailed anthropomorphic canine art",
        "full": "concept art of anthropomorphized canine, highly detailed painting by dustin nguyen, akihiko yoshida, greg tocchini, 4 k, trending on artstation, 8 k"
    },
    {
        "display": "Post-apocalyptic mechanic portrait",
        "full": "A full portrait of a beautiful post apocalyptic mechanic, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Anime girl in jungle ruins",
        "full": "digital art of a beautiful girl wearing a shirt standing in front of a ruined apartment complex, jungle composition, sunlit, expressive oil painting, by artgerm, by jeremy lipking, anime style, octane render, bright colors, face!!!! close - up"
    },
    {
        "display": "Cinematic quantum physics art",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, hyperspace, vibrant colors, quantum physics"
    },
    {
        "display": "Crow portrait with moon",
        "full": "red and golden color details, portrait, a crow with red eyes in front of the full big moon, book cover, red roses, red white black colors, establishing shot, extremly high detail, foto realistic, cinematic lighting, by yoshitaka amano, ruan jia, kentaro miura, artgerm, post processed, concept art, artstation, raphael lacoste, alex ross, portrait, a crow with red eyes in front of the full big moon, book cover, red roses, red white black colors, establishing shot, extremly high detail, photo - realistic, cinematic lighting, by yoshitaka amano, ruan jia, kentaro miura, artgerm, post processed, concept art, artstation, raphael lacoste, alex ross"
    },
    {
        "display": "Futuristic astronaut girl.",
        "full": "character concept of a girl wearing a futuristic grey astronaut suit with orange glowing liquid pipes powering her suit. cute button anime button eyes. vibrant colors, soft lighting, 8 k, octane render. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Surreal businessmen with gas masks",
        "full": "realistic detailed image of businessmen using gasmasks at their meeting in the style of Francis Bacon, Surreal, Norman Rockwell and James Jean, Greg Hildebrandt, and Mark Brooks, triadic color scheme, By Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Edward Hopper and Norman Rockwell and Beksinski, Diesel punk businessmen with gas masks, open ceiling, highly detailed, painted by Francis Bacon, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, WLOP, Stanley Artgerm, very coherent, art by Takato Yamamoto and James Jean"
    },
    {
        "display": "Cinematic female gladiator painting.",
        "full": "painting of a cinematic still of a beautiful female gladiator with blue eyes holding a golden ornate spear in her hand, dramatic clouds, greek mythology, extremely detailed digital painting, in the style of fenghua zhong and ruan jia and jeremy lipking and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Symmetrical cyberpunk portrait.",
        "full": "symmetrical!! upper body!! bladerunner cyberpunk portrait of christina hendricks, beautiful face, highly detailed face!!!, extremely detailed!, digital painting, unreal engine 5, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Detailed werewolf concept art",
        "full": "werewolf concept designs by Rick Baker, highly detailed, Oil Painting, trending on artstation"
    },
    {
        "display": "Lonely girl with dragon.",
        "full": "the beautiful hyper detailed scene render that a lonely beautiful girl lies in the arms of a huge silver dragon alone in the fairyland surrounded by white clouds, in the style of makoto shinkai victo ngai and peter mohrbacher studio ghibli artgerm karol bak beeple, animation style, 8 k hd, dream, ultra wide angle, animation style"
    },
    {
        "display": "Whales in starry mountains.",
        "full": "snowy mountain range, stars in sky, whales flying in clouds, digital painting, concept art, high detail, style of Jordan Grimmer, fluffy calm clouds, matte painting, high res, moon shine, volumetric, starry sky"
    },
    {
        "display": "Chrome spacesuits defend nudibranch girl.",
        "full": "cinematic expressive award winning picture of chrome spacesuits protecting the jovial dancing nudibranch girl from certain doom as the planet they orbit sends elastic neuronal nets attack them, photorealism, sharp focus, pbr, tristan eaton, victo ngai, roy lichtenstein, maxfield parrish, artgerm, koons, ryden, intricate details, 3 / 4 view, black background"
    },
    {
        "display": "Half Jessica Alba, half Natalie Portman, demon overlord",
        "full": "half Jessica Alba half Nathalie Portman the pure white demon, overlord, overlord season 4, body portrait, slight smile, autumn lights, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by wlop and greg rutkowski and alphonse mucha and artgerm"
    },
    {
        "display": "Eldritch tech priest artwork",
        "full": "painting of eldritch man as a cloaked tech priest holding a book, adeptus mechanicus, cybernetic enhancements attached to his body, zdzislaw beksinski, lewis jones, dariusz zawadzki, wayne barlow, warhammer 4 0 k, cold hue's, warm tone gradient background, concept art, digital painting"
    },
    {
        "display": "Holographic alien beauty portrait",
        "full": "beauty woman in holograms of alien artifacts, electrical case display, total recall tech, , ultrarealistic, dramatic lighting, electrical details, high details, 4k, 8k, best, accurate, trending on artstation, artstation, photorealism, ultrarealistic, digital painting, style of Tristan Eaton Stanley Artgerm and Hajime Sorayama, Caravaggio, Boris Vallejo"
    },
    {
        "display": "Overwatch character portrait.",
        "full": "Greg Manchess portrait painting of Zero Ts\u016b and Strelizia as Overwatch character, medium shot, asymmetrical, profile picture, Organic Painting, sunny day, Matte Painting, bold shapes, hard edges, street art, trending on artstation, by Huang Guangjian and Gil Elvgren and Sachin Teng"
    },
    {
        "display": "Realistic wooden sculpture portrait",
        "full": "realistic monumental sculpture portrait of a powerful stern woman's face carved out of a red oak wood on a pedestal by stephan balkenhol and duane hanson and donald judd, hyperrealistic dramatic colored lighting trending on artstation 8 k"
    },
    {
        "display": "Alluring dark pinup portrait",
        "full": "a ( beautiful masterpiece highly detailed ) full body portrait illustration of intimate alluring elvira! mistress of the dark by ralph horsely and artgerm and joe jusko, raven black hair, porcelain white skin. bettie page, vampira, morticia addams, kat dennings, eva green, curvy, sexy pinup. hyperrealistic, trending on artstation"
    },
    {
        "display": "Horror depiction of Trump.",
        "full": "donald trump's disgusting true form, horror, high details, intricate details, by vincent di fate, artgerm julie bell beeple, 9 0 s, inking, vintage 6 0 s print, screen print"
    },
    {
        "display": "Skaar Cinematic Portrait",
        "full": "cinematic portrait of skaar, son of hulk, only head and chest, intricate, desaturated, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, maxfield parrish, moebius, thomas ehretsmann, oil on canvas, gouache painting, masterpiece, trending on artstation, cinematic composition, dramatic pose, volumetric lighting, sharp, details, hyper - detailed, hd, 4 k, 8 k"
    },
    {
        "display": "Whimsical goddess in lake.",
        "full": "one beautiful whimsical goddess standing in a lake basking in the moonlight, underneath a multi-colored binary blackhole with an accretion disc, glowing trails following her arms, by Lois van Baarle, by Greg Rutkowski, by artgerm, by beeple, by studio ghibli, cinematic angle, volumetric lighting, 4k resolution, octane render, trending on artstation, masterpiece"
    },
    {
        "display": "Epic cat battle scene",
        "full": "epic battle of cats by Peleng, 3d render, octane rendered, highly detailed, cinematic lightning, rendered by maya and houdini, highly detailed, unreal engine, Trending on Artstation, octane render, 4k, 8k, HD"
    },
    {
        "display": "Cyborg girl shattering window",
        "full": "beautiful cyborg - girl punching into a large reflective shattering window, window reflections, reflective, mirror reflection, refractions on lens, full round face, biomechanical details, cyberpunk anime art, full body shot, lens flare, wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski"
    },
    {
        "display": "Robots in a scenic landscape.",
        "full": "natural american landscape | robot greeting another robot | palm trees | snowy mountains, painting by syd mead and weta studio and james jean, frank frazetta, highly detailed, rule of third, soft lighting, 8 k resolution, oil on canvas, architectural magazine, beautiful detailed, insanely intricate details, artstation trending, hypermaximalistic, high details, cinematic"
    },
    {
        "display": "Sanji as astronaut.",
        "full": "sanji in a astronaut suit and sanji, intricate, sanji, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, sanji, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic cyberpunk cityscape",
        "full": "futuristic city center with 8 9 0 j maglev train in background, modern landscape architectural design for brutalist industrialpunk black and white concrete and glass, train with maroon, white and teal metallic accents, gorgeous lighting, cyberpunk, 2 0 7 7, dramatic lighting and composition, 8 k, concept art, artstation, trending on artstation, slightly isometric, single line, central focus, minimalistic composition, side profile, tokyo, jr sc maglev, tgv, eurostar"
    },
    {
        "display": "Cybernetic hooded warrior.",
        "full": "Dark gritty realistic highly detailed intricate artistic award winning digital oil painting featuring the iridescent ornate cloaked hooded warrior partially cybernetic dark entity god of future technology brandishing cosmic smoking iridescent weaponry, intricate, ornate, black armor with hints of rainbow and gothic influence, smooth oil painting, muted realistic colors, epic megastructure space scene background, super intricate, galactic, moody colors, realistic, real colors, moody, ominous, dangerous aura, microchips, crystallic, iridescent, lasers, gems, multicolored glints, precious elements, beautiful, detailed, concept art, render, unreal engine, 4K, artstation"
    },
    {
        "display": "Elegant forest elf portrait.",
        "full": "a picture of a forest elf, elegant, epic, detailed, intricate, digital painting, concept art, realistic, smooth, focus, rim light,"
    },
    {
        "display": "Ghost spirit portrait, dystopian.",
        "full": "closeup portrait shot of a ghost spirit in a scenic dystopian environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Anime skateboarder portrait",
        "full": "well drawn animation portrait Anime skateboarder Stallone  Sharp fine face,  realistic shaded Perfect face, fine details. Anime. cyberpunk realistic shaded lighting by katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Psychedelic corn creature.",
        "full": "A psychedelic corn astral dreadnought , vibrant color scheme, highly detailed, in the style of romanticism, cinematic, artstation, Moebius, Greg rutkowski"
    },
    {
        "display": "Armored Magnus the Red Portrait",
        "full": "greg manchess portrait painting of armored magnus the red as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Cat in forest, warm lighting",
        "full": "cat in the forest, warm lighting, digital art, trending on artstation, fanart, by kawacy"
    },
    {
        "display": "Sonic Jesus Christ transformation.",
        "full": "semi realistic Sonic Jesus christ transforming in super Saiyan holding the a shiny Sacred Heart , by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, Yoshitaka Amano, ArtStation, CGSociety,"
    },
    {
        "display": "Star Trek concept art",
        "full": "star trek, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k"
    },
    {
        "display": "Elven character with roots",
        "full": "digital art, centered elven body made with intricate roots, by James Jean and by artgerm, by ross tran , ultradetailed, charachter design, concept art, trending on artstation,"
    },
    {
        "display": "Detailed portrait of Ray.",
        "full": "Portrait of Ray from the promised neverland, hair covering one eye ,highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sun and Moon Yearning",
        "full": "the sun and moon yearning for each other, symbolist painting, art by gustav klimt, intricately detailed, highly detailed, 8 k, trending on artstation"
    },
    {
        "display": "Ominous network admin tarot card",
        "full": "The Network Admin, tarot card, by  tomasz alen kopera and Justin Gerard, computer, short hair, business suit, clipboard, symmetrical features, ominous, magical realism, texture, intricate, ornate, royally decorated, whirling blue smoke, embers, radiant colors, fantasy, trending on artstation, volumetric lighting, micro details, 3d sculpture, ray tracing, 8k, anaglyph effect"
    },
    {
        "display": "Stunning Maine Coon portrait",
        "full": "prompt! dream symmetry!! stunning portrait of beautiful a maine coon cat!! by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Cute smug pink-haired demon",
        "full": "a highly detailed illustration of cute smug pink haired pale demon girl with curved horns wearing oversized pink hoodie, dramatic smirk pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop."
    },
    {
        "display": "Photorealistic grassland landscape",
        "full": "a beautiful photorealistic grassland with rolling hills, illustrated by greg rutkowski and gaston bussiere, photorealistic imagery, artstation 3 d, artstation render, artstation 3 d render, 3 d art, unreal engine 3 d, octane 3 d, blender 3 d, 3 d landscape, 4 k, 8 k"
    },
    {
        "display": "Lovecraftian investigator in Arkham",
        "full": "painting of the figure a female investigator in a lovecraftian arkham city scenery, 1 9 2 0 cars, high contrast, concept art, dramatic lighting, digital art, 8 k, arkham city, call of cthulhu, extremely detailed, drawn by ruan jia"
    },
    {
        "display": "Cute demon meditating elegantly.",
        "full": "a tiny cute demon floating while meditating and wrapped in sacred scrolls, smooth, intricate, elegant, digital painting, artstation, power runes, pulsing energy, concept art, sharp focus, octane render, illustration, art by shintaro kago, overwatch character,"
    },
    {
        "display": "Impressionist alien landscape.",
        "full": "impressionist alien rivulet surf sauvignon, by frida kahlo and h. p. lovecraft, trending on artstation, quantum wavetracing"
    },
    {
        "display": "Evil vampire portrait illustration",
        "full": "perfectly - centered - portrait - photograph of evil vampire, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha loish and wlop"
    },
    {
        "display": "Cottagecore portrait of Selena Gomez.",
        "full": "very beautiful cottagecore selena gomez!!, intricate, stunning, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Scottish man with mustache",
        "full": "a hair trash mustache big mustache scottish scotland man in a kilt, marijunana, centered, castle scotland background, median photoshop filter cutout vector behance, hd by artgerm, jesper ejsing, by rhads, makoto shinkai and lois van baarle, ilya kuvshinov, rossdraws, illustration, art by ilya kuvshinov and gustav klimt"
    },
    {
        "display": "Anime girl in armor",
        "full": "portrait Anime Girl in mechanical armor, cute-fine-face, pretty face, realistic shaded Perfect face, fine details. Anime. Warhammer 40000 realistic shaded lighting by katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, rutkowski Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Anime portrait of Steve Buscemi",
        "full": "a potrait of steve buscemi as an anime, fine, realistic, shaded, lighting, ilya, kuvshinov, katsuhiro, artgerm, jeremy, lipkin, michael, garmash, unreal, engine, 5, radiant, light, detailed, intricate, environment"
    },
    {
        "display": "Vibrant Rose of Jericho",
        "full": "rose of jericho, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Detailed satyr character portrait",
        "full": "satyr, ultra detailed fantasy, dndbeyond, bright, colourful, realistic, dnd character portrait, full body, pathfinder, pinterest, art by ralph horsley, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, hdr render in unreal engine 5"
    },
    {
        "display": "Elegant portrait of Atlantis Queen",
        "full": "Queen of Atlantis, gorgeous portrait, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Snoop Dogg Tennis Monster",
        "full": "a snoop dogg wearing sun glasses tennis ball monster, snoop dogg tennis ball head, xcopy teeth, colorful, digital art, fantasy, magic, chalk, trending on artstation, ultra detailed, professional illustration by basil gogos"
    },
    {
        "display": "Glamorous futuristic portrait.",
        "full": "glamorous sadness, seductive eyes and face, elegant, lascivious pose, very detailed face, studio lighting, photorealism, wearing futuristic armor , portrait by Magali Villeneuve and Steve Argyle,Livia Prima,Mucha,dress,fantasy art,beautiful,artstation,trending on artstation,intricate details,alluring,masterpiece"
    },
    {
        "display": "Anime-style girl in armor.",
        "full": "baroque oil painting japanese light novel cover illustration anime key visual full body action shot | young girl wearing black military crusader armour riding horse with long flowing blonde hair | rule of thirds golden ratio beautiful composition | lotr gapmoe kuudere moody lighting bokeh stunning highlights sharp contrast | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Tina riding cow, Artgerm style",
        "full": "Tina from Dead or Alive riding a cow, artwork by artgerm, 4K"
    },
    {
        "display": "Serene Coastal Mecha Jellyfish",
        "full": "A very beautiful serene coastal landscape scene with a GIANT MECHA JELLYFISH looming in the distance, bright sunny, the great wave of kanagawa, waves splashing on the beach, Hokusai, Translucent rendered by simon st\u00e5lenhag, rendered by Beeple, Makoto Shinkai, syd meade, environment concept, digital art, starwars, unreal engine, 3 point perspective, WLOP, trending on artstation, low level, 4K UHD image, octane render,"
    },
    {
        "display": "Anime gardener portrait",
        "full": "portrait of the gardener, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, trending on artstation"
    },
    {
        "display": "Cute fox in costume",
        "full": "weta disney pixar movie still macro close photo of cute anthropomorphic vulpes vulpes fulva wearing leeloominai lekatariba lamina - tchai ekbat de sebat costume : : by weta, greg rutkowski, wlop, ilya kuvshinov, rossdraws, artgerm, octane render, iridescent, bright morning, anime, liosh, mucha : :"
    },
    {
        "display": "Anxious Indian doctors at airport",
        "full": "Anxious good looking pale young Indian doctors wearing American clothes chatting at the airport, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic portrait of girl.",
        "full": "girl with long hair, instagram photo, kodak, portra, by wlop, ilya kuvshinov, krenz, cushart, pixiv, zbrush sculpt, octane render, houdini, vfx, cinematic atmosphere, 8 k, unreal engine 5, ultra detailed, ultra realistic"
    },
    {
        "display": "Symmetrical portrait of Danny DeVito.",
        "full": "symmetry!! portrait of sweaty danny devito!!!!!, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute nine-tailed anime girl",
        "full": "\u201c anime, cute nine tailed high school girl, light and shadow effects, intricate, highly detailed, digital painting, art station, concept art, smooth, sharp focus, illustration, advanced digital anime art, atmospheric lighting, detailed face, by makoto shinkai, stanley artgerm lau, wlop, rossdraws, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau, craig mullins, j. c. leyendecker \u201d"
    },
    {
        "display": "Cyborg girl in neon.",
        "full": "Cute cyborg girl , surrounded by cables , illustrated by Greg Rutkowski and H.R. Giger and Moebius, 35mm lens, beautiful macro close-up imagery, vibrantly lush neon lighting, beautiful volumetric-lighting-style atmosphere, a futuristic atmosphere, intricate, ultra detailed, photorealistic imagery, trending on artstation, 4k, 8k"
    },
    {
        "display": "Highly detailed alien portrait.",
        "full": "Portrait of a Scary Alien, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Satan portrait, detailed artwork",
        "full": "highly detailed portrait of satan from dragon ball, photographic background on thick paper, by greg rutkowski, by by kaethe butcher, by greg tocchini, by joe fenton, by nikkohurtado, by tonysantos _ tattoo, trending on instagram, award winning details,"
    },
    {
        "display": "Frog on Walter White's head",
        "full": "a frog sitting on walter white's head, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Anime engineer portrait",
        "full": "Portrait of an engineer with helmet,  very coherent, painted by painted by James Gilleard, airbrush,  art by JamesJean and  fine details. Anime. realistic shaded lighting poster by Ilya Kuvshinov katsuhiro otomo ghost-in-the-shell, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash and Rob Rey"
    },
    {
        "display": "Pink rose in serene sea",
        "full": "beautiful digital rose in stunning pink sea, VERY LIGHT purple and blue scheme, by Anton Fadeev and Simon Stalenhag, trending on artstation, low contrast"
    },
    {
        "display": "Fae priestess with neon hair.",
        "full": "stunningly beautiful female neon and purple hair, fantasy art, fae priestess, world of warcraft video game, goddess sharp focus, digital, painting, 8 k, concept art, art by wlop, artgerm, greg rutkowski and alphonse mucha"
    },
    {
        "display": "Strogg Cyborg Portrait",
        "full": "portrait of a strogg cyborg from quake 2, by michael whelan, trending on artstation, 4 k,"
    },
    {
        "display": "Sadistic elderly blonde elf.",
        "full": "portrait of a sadistic elderly blonde elf male in ornate royal attire. in style of yoji shinkawa and hyung - tae kim, trending on artstation, dark fantasy, great composition, concept art, highly detailed, dynamic pose, vibrant colours."
    },
    {
        "display": "Riley Reid as Star Wars character",
        "full": "portrait of Riley Reid as a character in star Wars movie, looking at camera, intricate, dystopian, sci-fi, extremely detailed, octane render, digital painting, concept art, smooth, sharp focus, illustration, incredible art by artgerm and greg rutkowski and alphonse mucha and simon stalenhag"
    },
    {
        "display": "Divine wedding portrait.",
        "full": "a cinematic portrait of wedding photograph jpeg close up moment of a divine a japan sun god and moon goddess lovers magician at a wedding banquet. portraiture. digital painting. artstation. concept art. wedding photo. digital painting. violet evergarden art masterpiece by art by krenz cushart"
    },
    {
        "display": "White-haired boy illustration",
        "full": "boy with white shoulder-length hair. beautiful hyperdetailed eyes, concept art, illustration, by greg rutkowski"
    },
    {
        "display": "Anthropomorphic albino lion in winter attire.",
        "full": "detailed, 3 d concept art of a film character anthropomorphic albino furry anthro lion wearing a cute mint colored, cozy, soft pastel winter outfit with winter atmosphere. muscular stature, beautiful coherent design, hq render, long tail, detailed fur, detailed textures, hyper detailed face, artgerm artstationhd, booth. pm, highly detailed attributes and atmosphere, anthro, iconic character design by charlie bowater, ross tran, artgerm, and makoto shinkai, dim volumetric cinematic lighting, hd, unity unreal engine."
    },
    {
        "display": "Ellen Ripley in fantasy art",
        "full": "ellen ripley, warhammer, lord of the rings, sweaty, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "LED-lit bathroom design",
        "full": "bathroom design with led strip lights archi-viz Alla Prima Method , by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchevy, Marc Simonetti, and Sakimichan, ledspace, trending on artstation"
    },
    {
        "display": "Wolf howling at moon",
        "full": "a wolf standing on a moutain, howling to a full moon shining bright, thunderstrikes, epic, dramatic lighting, in a fantasy world, highly detailed, crystal lighting, hyperrealistic, trending on artstation, unreal engine, magical, by joe fenton, by greg rutkowski, by greg tocchini, by kaws, by kate beaton, by kaethe butcher"
    },
    {
        "display": "VFX portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin by eiichiro oda!, stephen bliss, greg rutkowski, loish, rhads, beeple, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, stanley kubrick, backlit, harsh overhead sunlight, detailed blue eyes!!!!!,"
    },
    {
        "display": "Edgy anthropomorphic monster.",
        "full": "anthropomorphic triangle head in opal edgy darkiron firefly, intricate, elegant, highly detailed animal monster, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Space opera astronaut illustration",
        "full": "concept art, comic book illustration, human character, space opera, astronaut, trending on artstation"
    },
    {
        "display": "Anime summer scene with corgi.",
        "full": "beautiful anime painting of a summer chill day with corgi, by Tim Okamura, Victor Nizovtsev, Greg Rutkowski, Noah Bradley. trending on Artstation, 8k, masterpiece, graffiti paint, fine detail, full of color, intricate detail, golden ratio illustration"
    },
    {
        "display": "Cyborg nymph wrestling flamingo.",
        "full": "an extreme close - up of a statue chrome cyborg lycra nymph with long flowing golden blonde hair wrestling with a giant flamingo, tropical flower plants, thick pigmented smoke, by jeff koons, hajime soryama, boris vallejo, artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Alien Baroque Cyberpunk Portrait",
        "full": "realistic detailed face portrait of a young beautiful alien baroque cyberpunk marie antoinette by alphonse mucha wearing ornate opulent armor, ayami kojima, amano, greg hildebrandt, and mark brooks, female, feminine, art nouveau, rococo cyberpunk, neo - gothic, gothic, character concept design"
    },
    {
        "display": "Sad beauty in vaporwave.",
        "full": "beautiful, young woman, wrapped up in each other's hair, extremely detailed gorgeous face, sad eyes, long luxurious gown, tears, vaporwave aesthetic, synthwave, photo-realistic face, digital, flowers, butterflies, birds, painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Demon warlock character design.",
        "full": "man male demon, white purple cape, warlock, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "Werewolf portrait, digital art",
        "full": "portrait of a beautiful young fit male werewolf, wolf head, partially male hairy torso, fur covered legs, feral scene, by greg rutkowski and alphonse mucha, d & d character, gradient red to black, in front of a forest background, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp focus ilustration, artstation hq"
    },
    {
        "display": "Dragon face portrait, fantasy.",
        "full": "''face portrait of dragon, rule of thirds, fantasy, mountain landscape, d & d, digital painting, artstation, deviantart, concept art, illustration, art by dragolisco and anne stokes and nico niemi''"
    },
    {
        "display": "Photorealistic judge dog",
        "full": "a closeup photorealistic photograph of a cute smiling knitted bernedoodle judge dog dressed in a black gown, presiding over the courthouse. indoor image, professional capture, well lit shot. this 4 k hd image is trending on artstation, featured on behance, well - rendered, extra crisp, features intricate detail, epic composition and the style of unreal engine."
    },
    {
        "display": "Cyborg fennec fox warrior.",
        "full": "face portrait of a cyborg fennec fox animal warrior standing in the forest, in black carbon fiber pink and blue sci-fi armor, light coming off of the armor, tech wear, sci-fi, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, trending on art station, dynamic lighting, cinematic, ultra detailed, fantasy concept art"
    },
    {
        "display": "Silhouette on colorful cosmos",
        "full": "black silhuette of a man standing on a gray dull cliff looking out into a colorful vibrant cosmos space, clouds, stars, rings, nebula, beautiful soft purple pink lighting, magic, fantasy, vivid colors, intricate, elegant, cgsociety, artstation trending, oil painting by greg rutkowski, by artgerm, by wlop"
    },
    {
        "display": "Angry rabbit-headed superhero.",
        "full": "angry man with rabbit head and fish body superhero man, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart."
    },
    {
        "display": "Teddy Roosevelt pop art mural",
        "full": "Wall mural portrait of Teddy Roosevelt, urban art, pop art, artgerm, by  Roy Lichtenstein"
    },
    {
        "display": "Princess with skull mask",
        "full": "close-up macro portrait of the face of a beautiful princess with animal skull mask, epic angle and pose, ribcage bones symmetrical artwork, 3d with depth of field, blurred background, cybernetic jellyfish female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. y Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Cute smiling human illustration",
        "full": "a human smiling cute, Tristan Eaton, victo ngai, artgerm, RHADS, ross draws"
    },
    {
        "display": "Elegant fashion portrait",
        "full": "beautiful fashion goddness, strapless dress, character portrait in the style of thomas river and artgerm, wlop, cinematic lighting, hyperdetailed, 8 k realistic, symmetrical, global illumination, radiant light, halo, love and mercy, frostbite 3 engine, cryengine, dof, trending on artstation, digital art, chanel"
    },
    {
        "display": "Middle-aged professor portrait.",
        "full": "Middle-aged professor with a short gray beard and short spiky hair wearing a yellow sweater sits at desk in his study with a expansive bookshelf crammed with books in the background, hyperrealistic, portrait, concept art, illustration, 8k, artstation, digital painting"
    },
    {
        "display": "Robot monk in vaporwave.",
        "full": "a robot monk wearing a flowing cloak, vaporwave aesthetic, colorful, psychedelic, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Mikasa Ackerman Portrait",
        "full": "highly detailed portrait of mikasa ackerman from attack on titan, fantasy art, by hayao miyazaki, studio ghibli, photorealistic, detailed and intricate environment, trending on pixiv, twitter"
    },
    {
        "display": "Elegant fantasy woman illustration",
        "full": "fullbody!! of a beautiful woman with long black hair, big natural horns on her head, long flowing intricate black lace dress, gold jewellery, dnd, face, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Hybrid baby bear-snake painting",
        "full": "painting of hybrid between baby bear & snake!!!!!!!!, intercrossed animal, by zdzislaw beksinski, by lewis jones, by mattias adolfsson, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting"
    },
    {
        "display": "Creepy Kermit in Septic Tank",
        "full": "delta green, zombie bloated kermit the frog locked in septic tank, present day, creepy, lovecraftian, intricate and very beautiful and highly detailed, elegant, digital painting, artstation, concept art, matte, smooth and sharp focus, illustration, art by tian zi and wlop and alsphonse mucha and artgerm and greg rutkowski"
    },
    {
        "display": "Jamie Lee Curtis portrait.",
        "full": "jamie lee curtis, smiling, happy, beautiful, intelligent, powerful, white hair, loving eyes, fully clothed, wise, beautiful, dramatic lighting, sharp focus, by stanley artgerm, dramatic lighting, trending on artstation, flat colour, geometric curves, gradient filter, art deco patterns"
    },
    {
        "display": "Patton Oswalt as reptilian.",
        "full": "patton oswalt with reptile eyes, blue and green skin. intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, frank frazetta"
    },
    {
        "display": "Hyperrealistic electric woman portrait",
        "full": "hyperrealistic oil painting of half - electric woman, cute - fine - face, pretty face, paint pour hair, realistic shaded perfect face, extremely fine details, realistic shaded lighting, dynamic background, artgerm, 8 k ultra realistic, highly detailed, art by christopher balaskas, alphonse mucha, craig mullins, beeple, ultra detailed"
    },
    {
        "display": "Multi-dimensional haunting creature",
        "full": "a strange multi dimensional haunting creature traveling through the multiverse along strands of astral filament, trending on cgsociety, sharp, crisp, unreal engine, 4 k wallpaper, concept art"
    },
    {
        "display": "Cyberpunk Oni Mask Portrait",
        "full": "cyber punk, oni mask, 3 d render beeple, portrait, japanese neon red kanji, male anime character, unreal engine render, intricate abstract, sparking hornwort, broken robot, intricate artwork, beeple, octane render, epcot, shadows of colossus, glitch art, glitchcore, organic, forest druid, hellscape, futurescape, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents, albedo from overlord, the library of gems, intricate abstract. intricate artwork, by tooth wu, wlop, beeple, dan mumford. concept art, octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, key art, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Elderly couple with wiener dog.",
        "full": "portrait of a older bald white male and his white female brown hair wife. male is wearing a flannel shirt, tan shorts, white long socks and is holding a cane. femaleis standing next to him holding his hand. wiener dog to the side of them. photo taken from behind them overlooking a house under construction, madness, house with pine trees. taken from southern california and a partly cloudy sky, trending on artstation."
    },
    {
        "display": "Gulf War soldiers helicopter painting",
        "full": "a painting of soldiers falling out of a helicopter in the gulf war by Bernardo Bellotto, high detail, hyperrealistic, concept art, artstation, 8k"
    },
    {
        "display": "Red hooded monk, side profile",
        "full": "the red hooded monk, side profile, trending on artstation, 8 k, by gerard brom and zdzis\u0142aw beksinski"
    },
    {
        "display": "Surreal fantasy landscape.",
        "full": "\ud83c\udf08\ud83c\udf44::3, Fantasy LUT, high contrast, epic composition, sci-fi, dreamlike, surreal, angelic, cinematic, 8k, unreal engine, hyper realistic, fantasy concept art, Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell,"
    },
    {
        "display": "Dwarven lord illustration.",
        "full": "a craggy faced dwarven lord, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by WLOP and Artgerm and Greg Rutkowski and Alphonse Mucha, masterpiece"
    },
    {
        "display": "Fantasy empress character design.",
        "full": "A beautiful fantasy empress, highly detailed full body, breathtaking flower tiara, gorgeous aristocrat robe, beautiful figure, epic composition, ultra wide-shot, dynamic pose, concept art, beautifully lit, digital painting, character design, sharp focus, elegant, smooth, intricate, artstation, by WLOP and James Jean and Victo Ngai and Ryohei Hase"
    },
    {
        "display": "Rock musicians in green room.",
        "full": "rock and rollers drinking brutal and raw wine, inside a tiny green room with red lights by joaquin sorolla, greg rutkowski, bill sienckiwicz, extremely detailed"
    },
    {
        "display": "\"Epic Thar Adventure\"",
        "full": "mahindra thar driving through madagascar road with baobabs trees, drone view, animals running along, artgerm and greg rutkowski and alphonse mucha, an epic fantasy, volumetric light, detailed, establishing shot, an epic fantasy, trending on art station, octane render, midsommar"
    },
    {
        "display": "Diver splashing into pasta.",
        "full": "olympic diver is diving into a dish of pasta, splash, art deco intricate ripples, fantasy, elegant, highly detailed, sharp focus, art by artgerm and beeple and greg rutkowski and wlop"
    },
    {
        "display": "Symmetrical sci-fi animal portrait",
        "full": "symmetry!! portrait humanoid animal, tech wear, scifi, glowing lights!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cottagecore Lionel Messi Illustration",
        "full": "male cottagecore lionel messi holding a soccer ball, intricate, swagger, highly detailed, digital painting, artstation, concept art, smooth, sharp, focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Scarlett Johansson as Thanos",
        "full": "scarlett johansson as thanos, feminine beautiful muscular fitness model wearing armor, very dark purple skin, red lips, super very strong jaw, shredded abs, pin up, attractive, highly detailed full body portrait, pretty face, elegant, breathtaking art, concept art, by artgerm and ilya kuvshinov"
    },
    {
        "display": "Detailed animated forest sunset.",
        "full": "forest clearing landscape, sunset, studio ghibli, pixar and disney animation, sharp, rendered in unreal engine 5, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, wide angle, artbook, wallpaper, splash art, promo art, dramatic lighting, art by artgerm and greg rutkowski and bo chen and jin xiaodi"
    },
    {
        "display": "Chaotic god in mist.",
        "full": "shadow of illusion, illustration, sexy, chaotic, god, mist, nuclear explosion background, volumetric lighting, soft lighting, soft details, painting oil on canvas by William-Adolphe Bouguereau and Edmund Blair Leighton, octane render, HDR, trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Anthropomorphic hairy giant portrait",
        "full": "anthropomorphic highly detailed group portrait of hairy old neon giant cute eyes living hair brush, with long grey bob haircut in face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, bob eggleton, michael whelan, stephen hickman, richard corben, wayne barlowe, trending on artstation and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Jesus praying with Pikachu",
        "full": "a portrait of jesus praying, pikachu on the left, fantasy by dan mumford, yusuke murata and makoto shinkai, 8 k, cel shaded, unreal engine, featured on artstation, pixiv"
    },
    {
        "display": "Cinematic alien portrait",
        "full": "cinematic bust portrait of beautiful John Cena the invisible man from left, head and chest only, exotic alien features, robotic enhancements, desaturated, Tim Hildebrandt, Wayne Barlowe, Bruce Pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp, details, hyper-detailed, HD, HDR, 4K, 8K"
    },
    {
        "display": "Anime giantess in bikini.",
        "full": "a very beautiful young anime giantess, full body, long wavy blond hair, sky blue eyes, full round face,, bikini, miniskirt, front view, mid - shot, highly detailed, cinematic wallpaper by stanley artgerm lau"
    },
    {
        "display": "Eternal void of despair",
        "full": "the existential dread you feel as all of the world's hope is sucked out of an eternal void, filling lost souls with the forgotten memories of their past, art by yiosgs ghdsjkhg, high detailed, super high resolution, realistic, concept art, artistic, octane render, masterpiece fine details"
    },
    {
        "display": "Lost Wanderers in Dark Landscapes",
        "full": "Group of lost Wanderers traveling through vast dark landscapes, broken Stone Statues and fallen Cities, in the Style of Artgerm and Charlie Bowater and Atey Ghailan and Mike Mignola, vibrant colors and hard shadows and strong rim light, Comic Cover Art, plain background, trending on artstation"
    },
    {
        "display": "Danny DeVito as Wonder Woman",
        "full": "danny devito as wonder woman, digital painting, extremely detailed, 4 k, intricate, brush strokes, mark arian, artgerm, bastien lecouffe - deharme"
    },
    {
        "display": "Anime Batman portrait, grunge punk.",
        "full": "portrait Anime Batman grunge punk beautiful in-mask fine-face, pretty face, realistic shaded Perfect face, fine details. Anime. Gotham-city night realistic shaded lighting by Ilya Kuvshinov krenz cushart katsuhiro otomo artgerm, rutkowski Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Cindy Aurum Artwork Styles",
        "full": "Cindy Aurum by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwel"
    },
    {
        "display": "Panda bear in fourwheeler.",
        "full": "cute and funny, pandabear wearing a helmet riding in a tiny fourwheeler with oversized engine, ratfink style by ed roth, centered award winning watercolor pen illustration, isometric illustration by chihiro iwasaki, edited by range murata, tiny details by artgerm, symmetrically isometrically centered"
    },
    {
        "display": "Sickly Samurai portrait.",
        "full": "Sickly diseased dying Samurai warrior, portrait by Cedric Peyravernay, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on ArtStation"
    },
    {
        "display": "Woman in fiery levitation.",
        "full": "A woman floats in midair, encircled by a ring of fire. She wears a crimson gown and her hair is wild and flowing. In her hands she holds a staff adorned with a large crystal ball, super coherent, trending on artstation, female, magic, by Lulu Chen and Mandy Jurgens"
    },
    {
        "display": "Fantasy portrait painting",
        "full": "A fantasy fairytale story telling style portrait painting, Great Leviathan Turtle, kaiju cephalopod, Leviathan Cthulhu Squid, Mythic Island at the center of the Universe, accompany hybrid, Cory Chase, Blake Lively, Anya_Taylor-Joy, Grace Moretz, Halle Berry, Mystical Valkyrie, Anubis-Reptilian, Atlantean Warrior, intense smoldering, soul penetrating invasive eyes. fantasy atmospheric lighting, digital oil painting, hyperrealistic, Fran\u00e7ois Boucher, Oil Painting, Cozy, hot springs hidden Cave, candlelight, natural light, lush plants and flowers, smooth cave rock, visually crisp & clear, Volumetric Golden dapple dynamic lighting, Regal, Refined, elegant, Spectacular Rocky Mountains, bright clouds, luminous stellar sky, outer worlds, michael whelan, William-Adolphe Bouguereau, Michael Cheval, Jessica Rossier, Crisp clear RPG portrait, Steampunk, cognitive Coherence cohesive character illustration, photorealistic, Theophanic atmospheric Vivarium, hyperdetailed, detailed oil painting, cinematic angle, hyperrealistic, breathtaking, cinematic lighting, digital art, octane render, epic composition, trending on artstation, masterpiece, cgsociety, Highly Detailed, Cinematic Lighting, HD render to resolute focused perfecta resolution, unreal 5, DAZ, hyperreality, octane render, Unreal Engine, 8k, HD,"
    },
    {
        "display": "Beautiful pixie portrait",
        "full": "3 / 4 long shot portrait of a beautiful powerful pixie with wings, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by disney, symmetry face, art by alex ross, brittney lee"
    },
    {
        "display": "Futuristic anime girls portrait.",
        "full": "baroque oil painting of key visual portrait full body of anime girls in futuristic cybernetic mech armor covered in glowing lights, brutalist fantasy, rule of thirds golden ratio, fake detail, trending pixiv fanbox, acrylic palette knife, style of makoto shinkai ghibli takashi takeuchi yoshiyuki sadamoto jamie wyeth james gilleard greg rutkowski chiho aoshima"
    },
    {
        "display": "City in bell jar.",
        "full": "a bell jar containing a city resembling prague, paris, and venice at dusk, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, colored illustration for tattoo, art by krenz cushart and artem demura and alphonse mucha,"
    },
    {
        "display": "Desert temple masterpiece",
        "full": "matte painting by marc simonetti, jonathan solter, greg rutkowski of a desert temple, masterpiece, cinematic, hyperdetailed, photorealistic, hyperrealism, architecture, aerial view,"
    },
    {
        "display": "\"Pretty female serial killer\"",
        "full": "loish, artgerm, Joshua Middleton art, pretty female Alison Brie serial killer holding bloody knife, blood on clothes and face, sarcastic smile, symmetrical eyes, symmetrical face, full body, jean jacket, jeans, short blonde hair, middle shot, night time, deep blacks"
    },
    {
        "display": "Goddess princess of roses.",
        "full": "perfectly detailed goddess princess of white roses!! blessed by nature with ever - increasing physical mental perfection, symmetrical! intricate, highly detailed, biblical divine holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "High fashion model portrait",
        "full": "High fashion photograph of beautiful model in silver bodypaint with purple hair and grey eyes, detailed symmetrical face, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, octane render, Sony A7"
    },
    {
        "display": "Anime maid girl portrait",
        "full": "portrait Anime cute girl yellow hair, green eyes, maid uniform, pretty face, realistic shaded Perfect face, fine details. Anime. realistic shaded lighting by Ilya Kuvshinov krenz cushart katsuhiro otomo, magali villeneuve, artgerm, rutkowski Jeremy Lipkin and Giuseppe Dangelico Pino and Michael Garmash and Rob Rey"
    },
    {
        "display": "Dark fantasy character portrait",
        "full": "ryan hurst, final fantasy, darkwave, darksynth character portrait, sharp, digital matte painting, art by luis royo, greg rutkowski, wlop, dramatic lighting, trending on artstation"
    },
    {
        "display": "Egyptian queen in sci-fi armor",
        "full": "attractive white haired egyptian queen wearing white dove wings, warframe armor, regal, attractive, ornate, sultry, sexy, beautiful, elize theron, pretty face, green eyes, scifi platform, 4 k, ultra realistic, epic lighting, illuminated, cinematic, black gold, art by akihito tsukushi, voidstar, artgerm"
    },
    {
        "display": "Cozy red cabin, Sweden.",
        "full": "a cozy red cabin in northern Sweden, oil painting, trending on artstation"
    },
    {
        "display": "Divine feminine character portrait",
        "full": "digital character concept art by artgerm and greg rutkowski and alphonse mucha. clear portrait of a young wife blessed by god to uncontrollably become overwhelmingly perfect!! asian, fully clothed!!!, super feminine holy body!! light effect. hyper detailed, glowing lights!! intricate, elegant, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Dilraba as Revy portrait",
        "full": "a portrait of dilraba dilmurat as revy from black lagoon, smirk, black tank top, jean shorts, brown eyes, purple hair, tribal tattoo slevve right arm, symmetrical eyes, symmetrical face, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm, intricate, sharp focus"
    },
    {
        "display": "Fireflies in Nier Automata.",
        "full": "fireflies , 8k, by Makoto Shinkai, Art by Krenzcushart, art by Greg Rutkowski, By Craig mullins, Nier automata background concept art"
    },
    {
        "display": "Medieval cat in armor.",
        "full": "a medieval cat in royal knights armor, dark thick smokey red fire, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Cyberpunk holographic whirlwind.",
        "full": "a whirlwind inside the metaverse, guy, male, man, hologram, half body, neurochip, android, cyborg, cyberpunk face, by loish, d & d, fantasy, intricate, elegant, highly detailed, colorful, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vintage communist girl scene",
        "full": "a vintage photograph of a communist girl, strong subsurface scattering, red smoke, gold cables, dramatic lighting, stunning scene, highly detailed, concept art, octane render, trending on artstation"
    },
    {
        "display": "Yellow and blue abstract art.",
        "full": "yellow blue background by samuel smith trending on artstation"
    },
    {
        "display": "Zlatan Ibrahimovic as fantasy hero",
        "full": "Zlatan Ibrahimovic with a majestic beard, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Trump as Captain America",
        "full": "Donald Trump as Captain America, portrait, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant horror fantasy portrait",
        "full": "the horror! , fantasy, portrait, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Lady Gaga as Harley Quinn",
        "full": "beautiful lady gaga as harley quinn, horror, high details, bright colors, vivid, intricate details, by vincent di fate, artgerm julie bell beeple, 1 9 8 0 s, inking, vintage 8 0 s print, screen print"
    },
    {
        "display": "Cute girl portrait, realistic.",
        "full": "a realistic illustration portrait of a beautiful cute girl with wavy black and red hair, a pointy nose and, round chin black eyeliner, trending on artstation, hyper - realistic lighting, intricate, ross tran, realistic hair"
    },
    {
        "display": "Elegant woman painting, vibrant style.",
        "full": "painting of a gorgeous young woman in the style of Martine Johanna, draped in flowing fabric, colorful energetic brush strokes, realistic, sharp focus, 8k high definition, insanely detailed, intricate, elegant, art by Martine Johanna and artgerm"
    },
    {
        "display": "Realistic electric woman portrait",
        "full": "electric woman, cute - fine - face, pretty face, oil slick hair, realistic shaded perfect face, extremely fine details, realistic shaded lighting, dynamic background, artgerm, 8 k ultra realistic, highly detailed, octane render, petros afshar"
    },
    {
        "display": "Elven princess in ornate robes",
        "full": "Full body portrait of a beautiful young blonde short haired elven princess wearing red, green and gold priest robe. In style of Yoji Shinkawa and Hyung-tae Kim, trending on ArtStation, dark fantasy, great composition, concept art, highly detailed."
    },
    {
        "display": "Vampire in bloody suit",
        "full": "portrait of balen shah upper body in bloody business suit, blood red eyes, vampire fangs, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by aenaluck and roberto ferri and greg rutkowski, epic fantasy, digital painting"
    },
    {
        "display": "Throne room painting, metaphysical.",
        "full": "a  painting of a throne room , a raytraced image by Mikalojus Konstantinas \u010ciurlionis, polycount, metaphysical painting, hall of mirrors, egyptian art, concept art"
    },
    {
        "display": "Epic WWII Tank Battle",
        "full": "an epic battle at the snow between german and soviet war tanks at WWII, dynamic composition, cinematic lighting, snow storm, warm and vibrant colors, art by Goro Fujita and Simon Stalenhag , 8k, trending on artstation, hyper detailed, cinematic"
    },
    {
        "display": "Revy from Black Lagoon.",
        "full": "revy black lagoon, purple hair, black tank top, jean shorts, tribal sleeve left arm, portrait shinkai makoto studio ghibli studio key hideaki anno sakimichan stanley artgerm lau rossdraws james jean marc simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Cate Blanchett as Titania",
        "full": "portrait of cate blanchett as titania, summer queen. faerie queen. queen of light, made by caravaggio stanley artgerm lau wlop rossdraws artstation cgsociety concept art cgsociety octane render"
    },
    {
        "display": "Anime Nietzsche Portrait",
        "full": "Anime Portrait of Friedrich Nietzsche in his Suit, chiseled Jawline and serious Look, in the Style of Artgerm and Ross Draws and Mike Mignola and Cory Loftis, vibrant colors, rim light, hard shadows, colorful, plain background, trending on artstation"
    },
    {
        "display": "Cute baby dragon illustration",
        "full": "black cute little baby dragon, flowers, black gold light blue, diamonds, highly detailed, artgerm, cushart krenz, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Medieval crowd, UFO, baroque.",
        "full": "crowd of medieval people surrounding UFO flying saucer, dream-like atmosphere, baroque landscape painting, perfect portrait composition, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, soft natural volumetric cinematic perfect light, chiaroscuro, award-winning photograph, masterpiece, Raphael, Caravaggio, Greg Rutkowski, Beeple"
    },
    {
        "display": "Lobster telephone concept art",
        "full": "detailed concept art illustration colorful pastel painting of a lobster telephone in full intricate details, ultra detailed, digital art, octane render, 4K, dystopian, micro details"
    },
    {
        "display": "Symmetrical Renekton portrait.",
        "full": "symmetry!! portrait of renekton, league of legends, glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic golden dragon illustration",
        "full": "golden dragon, epic, legendary, cinematic composition, stunning atmosphere by james jean by roger dean by lee madgewick highly detailed, digital painting, artstation, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Chinese cyberpunk robot portrait",
        "full": "portrait of a chinese cyberpunk machine, machine face, robed, upper half portrait, decorated with chinese opera motifs regal asian machine robot cyberpunk fine china, wuxia, traditional chinese art intricate intense elegant highly detailed digital painting artstation concept art smooth sharp focus illustration, art by artgerm and greg rutkowski alphonse mucha 8 k"
    },
    {
        "display": "Gothic knight fantasy portrait",
        "full": "portrait knights of Zodiac girl+smoky eyes, gothic black and red reflected armor, in ruined Agora of Athens moon night and black magic, ssci-fi, fantasy, intricate, very very beautiful, elegant, golden light, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by tian zi and WLOP and alphonse mucha"
    },
    {
        "display": "Soviet apartment building scene",
        "full": "soviet panel apartment building, marc simonetti, greg rutkowski, denis villeneuve"
    },
    {
        "display": "Frightened anime girls with ghosts",
        "full": "anime girls frightened by scary ghosts, masterpiece, pinup, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K"
    },
    {
        "display": "Colorful baboons portrait.",
        "full": "colorful baboons sitting side by side starring directly into the camera with colorful feather background, highly detailed, intricate, digital painting, trending on artstation, concept art, matte painting, art by greg rutkwowski, craig mullins, octane render, 8 k, unreal engine"
    },
    {
        "display": "Nathan Fillion as Bond",
        "full": "Nathan Fillion as James Bond in Goldeneye 007 silently dispatching a guard on the toilet in facility , D&D, cinematic, intricate, elegant, highly detailed, movie still, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse much"
    },
    {
        "display": "Princess Diana with Mercedes-Benz",
        "full": "Princess Diana standing next to a Mercedes-Benz W140, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and alphonse mucha, high definition digital art, in the style of Ross tran and ilya kuvshinov"
    },
    {
        "display": "Badger Druid Fantasy Artwork",
        "full": "Badger druid, spell, magic the gathering artwork, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Eerie 1920s Boston University",
        "full": "boston university in 1 9 2 0's, ultra realistic, colour, concept art, intricate details, night, thunder, raining, eerie, arkham horror, call of cthulhu, elder sign, highly detailed, dark fantasy, photorealistic, octane render, 8 k, unreal engine 5. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Phoenix Bahamut in Art Nouveau",
        "full": "phoenix bahamut with wings of fireflies and flameches, flames intricate stainglass background, elegant, thin intricate art nouveau frame, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Disturbing portrait in neon.",
        "full": "weird and disturbing portrait of todd solondz standing alone in an empty appartment, vivid colors, neon, art by gregory crewdson and francis bacon and artgerm and wlop and william - adolphe bouguereau"
    },
    {
        "display": "Alchemist with potions, Warhammer.",
        "full": "a 17th-century alchemist who has 2 bubbling potion bottles in the style of Warhammer fantasy :: black and white, head and torso drawing, very high quality, concept art"
    },
    {
        "display": "High-contrast pin-up painting",
        "full": "painting of the figure of a pin up girl posing, high contrast, poster, clothes in the fashion of 6 0's, concept art, black and white, dramatic lighting, digital art, 8 k"
    },
    {
        "display": "Heracles vs. Hydra, dramatic art",
        "full": "Heracles slaying the Lernaean Hydra, by Rolf Armstrong and Evelyn De Morgan, dramatic lighting, high contrast colors, baroque, empyrean, panoramic view, as trending on Artstation, highly detailed, doom engine,"
    },
    {
        "display": "Anime-style baroque illustration",
        "full": "baroque oil painting japanese light novel cover full body illustration lotr anime key visual stunning action shot | young girl black military crusader maid with long flowing blonde hair | symmetrical perfect face fine detail delicate features quiet gaze | gapmoe kuudere moody dynamic lighting bokeh | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Cute Gundam portrait, anime style",
        "full": "A potrait of a gundam with big and cute eyes, fine-face, realistic shaded perfect face, fine details. Night setting. Very anime style. Realistic shaded lighting poster by Ilya Kuvshinov katsuhiro, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash, Rob Rey and Kentar\u00ef\u00bf\u00bd Miura style, trending on art station"
    },
    {
        "display": "Orange cat with medicine.",
        "full": "a cat standing next to a bottle of medicine, orange cat, animal cat, digital art, trending on artstation, illustration, wide image,"
    },
    {
        "display": "Golden skull with inscriptions.",
        "full": "ominous chiaroscuro baroque cinestill poster of a golden skull intricately decorated with ancient runic inscriptions and prophecy engravings. ray of light shining down on golden skull. trending on artstation, photorealistic, volumetric lighting, octane render by elden ring, ominous dark background. deep aesthetics, ( lord of the rings : the return of the king 2 0 0 3 )"
    },
    {
        "display": "Fiery attack ships in space",
        "full": "concept art of attack ships on fire in space by john harris and john berkey, matte, masterpiece, atmospheric, wide angle shot"
    },
    {
        "display": "Lion in nightclub scene",
        "full": "a lion inside a night club, people dancing, hyperdetailed, in the style of artgerm, deviantart, figurative art, deviantart, ilya kuvshinov, lovecraftian"
    },
    {
        "display": "Post-apocalyptic mech pilot.",
        "full": "A full portrait of a beautiful post apocalyptic offworld mech pilot, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by Krenz Cushart and Artem Demura and alphonse mucha"
    },
    {
        "display": "Eerie liminal architecture",
        "full": "an empty liminal space, eerie and unsettling, the backrooms, dramatic lighting, smooth, sharp focus, dof, detailed, intricate, architectural concept art by artgerm, greg rutkowski, syd mead"
    },
    {
        "display": "Symmetrical throne room artwork",
        "full": "symmetry!! intense acotar cover art bakground geometrical glass in the throne room, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by petros afshar and takashi murakami and victo ngai"
    },
    {
        "display": "Cracked pavement portrait",
        "full": "Portrait of a man, face with pavement cracks. fantasy, realistic, intricate, highly detailed, digital painting, trending on artstation, sharp focus, illustration, style of Stanley Artgerm"
    },
    {
        "display": "Thor playing bongos, comic style.",
        "full": "thor playing the bongos, comic style by guweiz and stanley artgerm, extremely high quality artwork, very detailed, trending on artstation"
    },
    {
        "display": "Floating turtle island fantasy",
        "full": "animated floating turtle with island on it's back, trending on artstation, fantasy art, hd, detailed painting"
    },
    {
        "display": "Enchanted star princess portrait",
        "full": "full body portrait of the star princess, yuya nagai, enchanted,dark, concept art, Kyoto animation,last exile, blue submarine no. 6, katsura masakazu,tsutomu nihei, gustav klimt,loish, murata range, kawaii, studio lighting, manga, bright colors, anime,beautiful, 35mm lens,noir, vibrant high contrast, gradation, jean giraud, moebius, fantasy, rule of thirds, fibonacci, intricate,unreal engine, blender npr, cel shaded, flat, matte print, smooth, ilya kuvshinov"
    },
    {
        "display": "Astronaut with fish helmet.",
        "full": "character concept of a man wearing an astronaut suit, helmet with a fish in it, sci - fi. vibrant colors, soft lighting, 8 k, octane render. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Baby Yoda DJ portrait",
        "full": "full body pose, hyperrealistic photograph of baby yoda as a dj, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Peter Dinklage as Morpheus",
        "full": "peter dinklage as morpheus ( ( ( matrix ) ) ), digital painting, extremely detailed, 4 k, intricate, brush strokes, mark arian, artgerm, bastien lecouffe - deharme"
    },
    {
        "display": "Detailed queen illustration",
        "full": "queen of the night, highly detailed, artgerm style, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Vin Diesel as Saitama punching car in space.",
        "full": "landscape, long shot of vin diesel as saitama! punching!!! a car!!! through space, hyperrealism, trending on artstation"
    },
    {
        "display": "Anakin Skywalker beach cleanup",
        "full": "anakin skywalker vacuuming the beach to remove sand, 4 k, film still, trending on artstation"
    },
    {
        "display": "Cute Thai girl portrait",
        "full": "portrait of a cute Thai girl with a messy curly hair, pastel hair, streetwear fashion, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Albino lion in dark robe",
        "full": "aesthetic portrait commission of a albino muscular and attractive anthro lion as a character form Harry Potter wearing evil dark robe, gloomy/wet atmosphere, dim lighting, hyperdetailed. Character design by charlie bowater, ross tran, artgerm, and makoto shinkai, detailed, inked, western comic book movie poster concept art, award winning painting"
    },
    {
        "display": "Cyborg bull in hyper-realism",
        "full": "a full body shot of an imposing cyborg ( bull ) modeled after a bull with open eyes looking into the camera, highly detailed, android, cyborg, full body shot, intricate, 3 d, hyper realism, symmetrical, octane render, strong bokeh, fantasy, highly detailed, depth of field, digital art, artstation, concept art, cinematic lighting, trending"
    },
    {
        "display": "Epic cake illustration.",
        "full": "an epic of cake with number five hundred on it, oil on canvas, perfect composition, golden ratio, beautiful detailed, photorealistic, digital painting, concept art, smooth, sharp focus, illustration, fantasy background, artstation trending, octane render, unreal engine"
    },
    {
        "display": "Retro cosmic Bowie art",
        "full": "twin peaks poster art, david bowie floating through the cosmo outer space, old retro pulp, by michael whelan, rossetti bouguereau, artgerm, nostalgic, old fashioned"
    },
    {
        "display": "Futuristic spaceship landing.",
        "full": "landscape painting of a futuristic rugged spaceship landing on an alien world, colonisation, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Water and smoke woman portrait",
        "full": "fantasy portrait of a woman made of water and smoke, carved Japanese Sakura wood organic overgrowth, peter mohrbacher, artgerm, James Jean, Liam Brazier and nielly"
    },
    {
        "display": "Intricate robot samurai portrait",
        "full": "robot - demon samurai mask, torso portrait, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, luis rollo, ruan jia, steve mccurry, john berkey"
    },
    {
        "display": "Boy with shark teeth",
        "full": "a boy with shark teeth, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Surreal vaporwave portrait.",
        "full": "surreal beautiful young woman, detailed gorgeous face, sad eyes, vaporwave aesthetic, synthwave , digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sensual dancing woman illustration.",
        "full": "sensual red and black illustration of a woman in a dress dancing, black background, high contrast, patrick westwood style, red series, benjamin conalin style, artstation trending, high detail, soft light, black background"
    },
    {
        "display": "Corrupted rainbow humanoid queen.",
        "full": "a photo of 8k ultra realistic corrupted rainbow humanoid queen standing next to a spaceship window overlooking earth, swarm of transparent glass shards, cinematic lighting, trending on artstation, 4k, hyperrealistic, focused, extreme details, unreal engine 5, cinematic, masterpiece"
    },
    {
        "display": "Gruesome Lovecraftian Hydra Painting",
        "full": "painting of the hydra, d & d, gruesome, monstrous, classical, lovecraftian, satanic, fantasy, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and michelangelo and nicolas poussin"
    },
    {
        "display": "Red Dead Redemption artwork.",
        "full": "red dead redemption, painted by greg rutkowski"
    },
    {
        "display": "Bob Ross as Bloodborne Hunter",
        "full": "an ultra detailed vector image of bob ross dressed as the hunter from bloodborne, concept art by alphonse mucha and greg rutkowski, praise the blood moon, octane render, liminal space"
    },
    {
        "display": "Portrait of Tomoko, digital art.",
        "full": "portrait tomoko digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Stylized sci-fi male silhouette",
        "full": "concept art, stylized silhouette, long thin legs, large shoulders, concept design, sketch, male, science fiction suit, helmet, arthur rackham, trending on artstation"
    },
    {
        "display": "Dark Lord, hyper-detailed art",
        "full": "The Dark Lord fom doom eternal, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Happy anime man portrait",
        "full": "A medium shot anime portrait of a happy handsome anime man with extremely short light walnut hair and blue eyes, wearing a t-shirt, short messy clumps of hair on top, solid background, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimi chan, trending on artstation"
    },
    {
        "display": "Armored Marie d'Arcanto Portrait",
        "full": "greg manchess portrait painting of partially armored marie d'arcanto alias rogue as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Japanese urban street scene",
        "full": "A highly detailed matte painting of a japanese urban residential street and park with a bright shimmering sky by Studio Ghibli, Mokoto Shinkai, by Artgerm, by beeple, volumetric lighting, octane render, 4K resolution, trending on artstation, vivid colours"
    },
    {
        "display": "Eleanor Tomlinson in peasant attire.",
        "full": "eleanor tomlinson posing peasantry clothing, wistful bosom, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Sci-Fi nighttime landscape.",
        "full": "Sci-Fi nighttime wallpaper of a beautiful landscape art by Donato Giancola and Bayard Wu, digital art, trending on artstation, 4k"
    },
    {
        "display": "Barack Obama Funko Pop",
        "full": "full body 3d render of barack obama as a funko pop, studio lighting, white background, blender, trending on artstation, 8k, highly detailed"
    },
    {
        "display": "Rugged forest god illustration",
        "full": "god of the forest, 3 0 years old, rugged, male, gorgeous, detailed face, amazing, thighs, ottoman, muscular, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Rugged orange cat deity.",
        "full": "painted portrait of rugged fat orange cat, god of thunder, greek god, white hair, masculine, powerful, handsome, upper body, white robe, muscular, hairy torso, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by gaston bussiere and alphonse mucha"
    },
    {
        "display": "Vegeta action figure in crystal ball",
        "full": "vegeta action figure inside a crystal ball, photo studio, professional photo, artstation trend, hdr"
    },
    {
        "display": "Cyberpunk woman portrait",
        "full": "hyperrealistic portrait of a mysterious cyberpunk woman with flowing hair, by Guy Denning, Russ Mills, beautiful, elusive, glitch art, hacking effects, glitch effects, brown eyes, fiery eyes, digital tech effects, cybernetics, detailed lines, intricate detail, holographic, polished, chromatic, clear, color blocking!, acrylic on canvas, octane, concept art, abstract, red face, front view, artistic, 8k, cgsociety, trending on artstation"
    },
    {
        "display": "Three-eyed elegant female portrait.",
        "full": "photo of a gorgeous female with 3 eyes in the style of stefan kostic, realistic, half body shot, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm, extreme bokeh light spring foliage"
    },
    {
        "display": "Emma Stone as Furiosa",
        "full": "Emma Stone as Furiosa by Ilya Kushvikov, symmetrical face concept art, octane render unreal engine meta humans, trending on artstation, desert, main colors orange, yellow, red and white"
    },
    {
        "display": "Ruins of a stone titan",
        "full": "wide view of a field surrounding the ruins of a fallen stone titan, Greg Rutkowski, dramatic lighting, airborne camera, lens flare, beautiful lighting, stone colossus remains"
    },
    {
        "display": "Cosmic evil android portrait",
        "full": "poltergeist fused with a cosmic evil laughing android, the thing, few cable wires hanging on the body, ghostly, gnarly, portrait, intricate details, by vincent di fate, artgerm, julie bell, beeple and Greg Rutkowski, 80s, concept, Smooth gradients, octane render, 8k, High contrast, duo tone, depth of field, very coherent symmetrical artwork"
    },
    {
        "display": "Lifelike pencil illustration of Lisa Loeb.",
        "full": "beautiful lifelike award winning pencil illustration of lisa loeb trending on art station artgerm greg rutkowski alphonse mucha cinematic atmospheric"
    },
    {
        "display": "Robots hugging under stars.",
        "full": "Two robots hugging each other while watching a shooting star in the night sky, cinematic lighting, insanely detailed, intricate, artstation, cgsociety, painted by Simon Stalenhag, concept art, illustration, sharp focus,"
    },
    {
        "display": "Succubus goddess portrait.",
        "full": "a beautiful portrait of a succubus goddess with bats in the background and closed eyes by greg rutkowski and raymond swanland, trending on artstation, ultra realistic digital art"
    },
    {
        "display": "Superhero portrait, detailed illustration",
        "full": "sigmundur davi\u00f0 as a superhero, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Gorgeous Greek female portrait",
        "full": "a gorgeous greek female photo, professionally retouched, soft lighting, realistic, smooth face, full body shot, torso, dress, perfect eyes, sharp focus on eyes, 8 k, high definition, insanely detailed, intricate, elegant, art by artgerm and jason chan"
    },
    {
        "display": "Colorful lakeside creatures",
        "full": "of a colorful lakeside with strange cute friendly creatures with huge eyes, mouth, long tongue and round teeth appearing from the waters, in the style of gehry and gaudi, macro lens, highly detailed, shallow depth of fielf, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Symmetrical portrait of girl.",
        "full": "symmetry!!! a young black girl with colorful afro puffs and glasses and her 3 rd eye open, sits cross legged on top of a tall pile of books!! in a vast and endless library. a beautiful painting by artgerm and greg rutkowski and justin bua in a surreal portrait style. psychedelic. beautiful detailed face. synthwave color scheme"
    },
    {
        "display": "Medieval monk and time portal.",
        "full": "a hyperrealist watercolour concept art of a dimensional time portal in the shape of a ruined arch. a medieval monk in grey robes is in the foreground. a japanese temple is in the background. very muted colors, post grunge, by rebecca guay, michael kaluta, charles vess and jean moebius giraud. high detail, hq, wide shot, 4 k"
    },
    {
        "display": "Elegant mummy portrait.",
        "full": "sansa old mummy angeline jolie gessica chastain, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Avant-garde geisha vampire queen",
        "full": "a thoth tarot card of an avant - garde japanese bjd geisha vampire queen in victorian red dress in the style of dark - fantasy lolita fashion painted by yoshitaka amano, takato yamamoto, james jean, dmt art, symmetrical vogue face portrait, intricate detail, artstation, cgsociety, artgerm, gold skulls, rococo"
    },
    {
        "display": "Grimdark Imperial Guard Portrait",
        "full": "portrait, kommissar tarsis, pale skin, 4 0 k, imperial guard, grimdark, highly detailed, digital painting, beautiful eyes!, pretty face!!, symmetry, concept art, sharp focus, illustration, art by artgerm! greg rutkowski magali villeneuve wlop! ilya kuvshinov!!, octane render"
    },
    {
        "display": "Angelic Fashion Catwalk",
        "full": "Fashion Catwalk in the Garden of Eden, Concept Art, Artstation, Hyperdetailed, Unreal Engine, Octane, Redshift, 4k, Fashion Photography, Angelic"
    },
    {
        "display": "Jessica Alba as Zatanna",
        "full": "artgerm, kilian eng comic book cover art, jessica alba as zatanna"
    },
    {
        "display": "Cyborg Beaver in Futuristic Metropolis",
        "full": "magical beaver cyborg in futuristic metropolis, giant trees, clean streets, inspired by horizon zero dawn, trending on artstation, art by richard dumont, leon tukker"
    },
    {
        "display": "Anime character portrait.",
        "full": "an anime character with long black hair and a top hat, a character portrait by toyohara chikanobu, pixiv contest winner, neo - romanticism, booru, sabattier filter, official art"
    },
    {
        "display": "Overgrown Greek labyrinth illustration",
        "full": "ancient greek overgrown labyrinth, by giger, stalenhag, beksinski, retro sci - fi movie, highly detailed, photorealistic, illustration, matte painting, 8 k, trending on artstation"
    },
    {
        "display": "Pixar-style Clockwork Orange",
        "full": "clockwork orange in Pixar style by Stanley Artgerm and Tom Bagshaw"
    },
    {
        "display": "Jedi crushing spaceship.",
        "full": "a powerful jedi in good looking futuristic clothes, uses the force to pull down and crush a monumental sized space ship from the sky to the ground beneath his feet, extremely detailed image, high quality, photoshopped, 4 k high resolution, trending on artstation"
    },
    {
        "display": "Electric striking woman portrait",
        "full": "half - electric striking woman, complex and hyperdetailed technical, rays and dispersion of light, noise film photo, cute - fine - face, pretty face, oil slick hair, realistic shaded perfect face, extremely fine details, realistic shaded lighting, dynamic background, artgerm, 8 k ultra realistic, highly detailed, kan liu"
    },
    {
        "display": "\"Photorealistic pool view\"",
        "full": "seeing small town from swimming pool, east europe, highly detailed, digital painting, artstation concept art, smooth, sharp focus, artgerm, alphonse mucha, photorealistic"
    },
    {
        "display": "Anime adventurers discovering treasure.",
        "full": "cell shaded key anime visual of a group of adventurers finding treasure in a dungeon, in the style ilya kuvshinov, anime, pixiv top monthly, trending on artstation, cinematic, danbooru, zerochan art, kyoto animation"
    },
    {
        "display": "Ginger Merman Researcher Drinking",
        "full": "Distinguished british ginger Merman Researcher (Nigel Thornberry) drinking the finest whiskey in a glass, Algae. Trending on ArtStation and Pixiv. A vibrant digital oil painting. A highly detailed fantasy character illustration."
    },
    {
        "display": "Cyberpunk Killua Zoldyck Portrait",
        "full": "killua zoldyck black hair, rain, white hair, techwear, streetwear, cyberpunk style outfit, greg rutkowski, artgerm, ross tran, takato yomamoto, wlop, ilya kuvshinov, intricate complexity, detailed portrait, 4 k, cinematic lighting, artstation, sharp focus, smooth, makoto shinkai"
    },
    {
        "display": "Golden dragon in night sky",
        "full": "!dream a beautiful digital painting of golden eastern giant dragon alone in sky, night clouds, above city, atmospheric lighting, Octane Render a fantasy digital painting by James Gurney, trending on Artstation, hyperrealistic, realistic, photorealistic, dynamic lighting, highly detailed, cinematic landscape, studio lighting"
    },
    {
        "display": "Whimsical young woman by stream",
        "full": "whimsical young woman, beautiful girl, full body, pink hair, by a stream, realistic, serov, surikov, vasnetsov, repin, kramskoi, insanely detailed, charlie bowater, tom bagshaw, high resolution, octane rendered, unreal engine, illustration, trending on artstation, masterpiece, 8 k"
    },
    {
        "display": "Cute woman portrait, detailed.",
        "full": "a portrait of a cute woman, brown hair, wavy hair, blue eyes, wearing a white blouse, button nose, oval face, soft chin, soft facial features, chubby cheeks, small lips, very detailed digital art, dynamic lighting, moody atmosphere, dark background, piercing gaze, warm smile, 4 k portrait art by artgerm, greg rutkowski, craig mullins"
    },
    {
        "display": "Medieval city, rainy atmosphere.",
        "full": "an old medieval city with rainy atmosphere and moody and cinematic lighting by alphonse mucha, simon stalenhag and darek zabrocki, cinematic and atmospheric, concept art, artstation, trending on artstation"
    },
    {
        "display": "Grotesque alien portrait.",
        "full": "symmetry!! portrait of grotesque alien, sci - fi horror, intricate, body horror, dark design, highly detailed, dark dynamic lighting, digital art, digital painting, artstation, smooth, sharp focus, illustration, art by artgerm and h r giger and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Elegant Spanish male doctor in space",
        "full": "Sensuous good looking pale young Spanish male doctors wearing jeans in a space station above Earth, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Vocaloid Xingchen artwork",
        "full": "high quality art of a vocaloid Xingchen, art by Makoto Shinkai, Crunchyroll, pixiv, danbooru, HD"
    },
    {
        "display": "Futuristic spaceship in rainforest",
        "full": "a beautiful painting about a futuristic spaceship landing in a misty Asian rainforest, tropical wavy, 111kixels matte painting trending on artstation divine deflective hovering pool web bass vodka vine, by Albert Bierstadt and Henri"
    },
    {
        "display": "Cinematic portrait of happy mask salesman.",
        "full": "a portrait of a cinematic still of the happy mask salesman smiling with red hair, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm and saruei and disney, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Hyperrealistic fossil hermit crab",
        "full": "hyperrealistic sculpture of a bronze fossilized ancient platonic solid hermit crab dusted with saffron and deep blue and grass green spraypaint in a grid cage on a pedestal by ron mueck and duane hanson and lee bontecou, hyperrealistic dramatic colored lighting trending on artstation 8 k"
    },
    {
        "display": "Elegant fantasy raven illustration",
        "full": "photograph of the ravven, fantasy, horror intricate, elegant, highly detailed, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski"
    },
    {
        "display": "Muscular male in African armor",
        "full": "a full body muscular male with an african mask, wearing an african armor, shaman, zulu, hamar, himba, karo, masai, samburu, by alex gray and android jones, karol bak, ilya golitsyn, ayami kojima, amano, black panther, moebius, concept art, character design, fantasy, 3 d, 8 k resolution"
    },
    {
        "display": "Hyperrealistic spirit woman",
        "full": "full body pose, hyperrealistic mixed media painting of a beautiful attractive spirit woman, dim volumetric lighting, 8 k, octane beautifully detailed render, extremely hyper detailed, intricate, epic composition, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, hdr, smooth, sharp focus, high resolution, award, winning photo, dslr, 5 0 mm"
    },
    {
        "display": "Ethereal tarot card woman.",
        "full": "greg rutkowski and wlop woman of tarot card the star card shows a woman kneeling at the edge of a small pond she is holding two containers of water one container hyperrealistic beautiful sexy ethereal white north pole girl portrait art nouveau fantasy intricate flower designs elegant highly detailed sharp focus art by artgerm pours the water into the river soul care nature peace galaxies female blue fine line fractal glow"
    },
    {
        "display": "Elegant Disney Zelda portrait",
        "full": "portrait of disney! zelda, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and uang guangjian and gil elvgren and sachin teng and wlop!, symmetry!!"
    },
    {
        "display": "Handsome young male portrait",
        "full": "beautiful portrait of a incredibly handsome young male wearing a fancy blue tunic, and a gold medallion!! around neck, art by wlop and artgerm, steampunk fiction, detailed deep blue eyes, elongated face, dark brown hair, in ponytail!!!, space background, trending on artstation, sharp focus, illustration, caustics, octane render, 4 k, radiant light"
    },
    {
        "display": "Horrifying slimy Scottish monster.",
        "full": "an ugly monsterish horrifying slimy scottish female photo, professionally, soft lighting, realistic, sharp focus on eyes, 8 k, high definition, insanely detailed, intricate, elegant, art by artgerm and junji ito."
    },
    {
        "display": "Melting clock with shapes.",
        "full": "a melting Roman numeral clock, behind a red and black gradient background, awith a black heart shaped on the top left corner and a black diamond card shape in the bottom right corner, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, cinematic, creative, ultra detailed"
    },
    {
        "display": "Creepy two-headed monster.",
        "full": "full body portrait of of a two headed monster smiley creepily, dynamic lighting, photorealistic, fantasy concept art, ambient lighting, atmospherical, stunning visuals, creative, cinematic, ultra detailed, trending on art station"
    },
    {
        "display": "Ivory throne with abominations.",
        "full": "a huge ivory throne with abominations kneeling in front of it, red tones, highly coherent, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant female android portrait",
        "full": "portrait of female android, symmetry, intricate, elegant, highly detailed, smooth, sharp focus, concept art, digital painting, illustration, artstation, by fra angelico and greg ruthkowski"
    },
    {
        "display": "Intricate Hagrid Portrait",
        "full": "a portrait of hagrid, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Flying rabbit in clouds",
        "full": "a beautiful digital painting of a white rabbit flying in the clouds, birds in the sunlight, numerous golden pumpkins, blue sky at sunset, elegant, highly detailed, artstation, concept art, matte, sharp focus, art by tom bagshaw, kelogsloops and greg rutkowski"
    },
    {
        "display": "Elegant East Asian man portrait",
        "full": "character design, a young east asian man with a pair of red phoenix eyes, fair complexion, straight nose, thin lips, delicate eyebrows, handsome and elegant, wearing a chinese tunic suit with a red chinese dragon embroidered on it, blurred environment background, colorful magic effects, white skin, portrait, male, clothed, sharp focus, digital art, concept art, trending on artstation, dynamic lighting, by emylie boivin and rossdraws"
    },
    {
        "display": "Anime-style baroque illustration",
        "full": "baroque oil painting japanese light novel cover illustration lotr anime key visual full body action shot | young girl black military crusader armoured maid | long flowing blonde hair symmetrical perfect face | gapmoe kuudere moody lighting stunning bokeh highlights sharp contrast | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Anime stand user destruction.",
        "full": "anime male stand and stand user, large, obese, breaking a telephone pole, power outage theme, twinkie, clouds, lightning, sunset, power outage, jotaro, jojo, in the style of jojos bizarre adventure, illustration, concept art, cinematic feeling, artstation trending, concept art 4k,"
    },
    {
        "display": "Cyberpunk prairie dog hacker",
        "full": "prairie dog!! hacker in a blue-lit room surrounded by computers, cyberpunk, highly detailed, trending on pixiv"
    },
    {
        "display": "Steampunk robot angel",
        "full": "steampunk robot angel, big ornamental wings, brown body, intricate, soulless, steampunk yunkyard background, sharp focus, highly detailed, cinematic lighting, studio quality, chiaroscuro, smooth render, unreal engine 5 rendered, octane, rendered, by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Arnold Schwarzenegger as Teferi",
        "full": "arnold schwarzenegger as teferi, age 3 0, in the style of magic the gathering, glacier landscape, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant empress portrait.",
        "full": "a beautiful empress portrait, with a brilliant, impossible striking shiny big gold headpiece, gold clothes, rococo, baroque, jewels, asian, realistic, closeup, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, octane render, 8k, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Elder in Suit Painting",
        "full": "a painting of a XXL wise elder from Kenya in a suit by Kehinde Wiley . dramatic angle, ethereal lights, details, smooth, sharp focus, illustration, realistic, cinematic, artstation, award winning, rgb , unreal engine, octane render, cinematic light, macro, depth of field, blur, red light and clouds from the back, highly detailed epic cinematic concept art CG render made in Maya, Blender and Photoshop, octane render, excellent composition, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse."
    },
    {
        "display": "Dreamlike pin-up portrait",
        "full": "a pin up and beautiful fashion charming dreamlke jennifer connelly, symmetrical face symmetrical eyes, character art, art by artgerm lau and wlop and and ilya kuvshinov and john singer sargent, hyperdetailed, 8 k realistic, symmetrical, frostbite 3 engine, cryengine, dof, trending on artstation, digital art"
    },
    {
        "display": "Sad anime schoolgirls portrait",
        "full": "3d infrared octane render concept art by Mo Xiang Tong Xiu, by Igarashi Daisuke, by makoto shinkai, cute beauty cozy portrait anime sad schoolgirls under dark pink and blue tones, mirror room. light rays. deep water bellow. realistic smile beautiful and cutest sad face. deep light, trending on artstation, oil painting brush"
    },
    {
        "display": "Alien engineer concept art",
        "full": "engineer alien bood face by Artgerm, xenomorph alien, highly detailed, symmetrical long head, blood color, smooth marble surfaces, detailed ink illustration, raiden metal gear, cinematic smooth stone, deep aesthetic, concept art, post process, 4k, carved marble texture and silk cloth, latex skin, highly ornate intricate details, prometheus, evil, moody lighting, hr geiger, hayao miyazaki, indsutrial Steampunk"
    },
    {
        "display": "Retro sci-fi heroine portrait",
        "full": "Jennifer Connelly  as a stunning ,  beautiful retro SCI-FI space heroine 1985 , intricate, elegant, highly detailed, centered, digital painting, trending on artstation, concept art, smooth, sharp focus, illustration, art by artgerm and donato giancola and Joseph Christian Leyendecker, Ross Tran, WLOP"
    },
    {
        "display": "Bears vs. Bulls Fantasy Battle",
        "full": "an army of bears facing an army of bulls, magical forest, fantasy, Ireland, England, king Arthur, Lord of the rings, cinematic, realistic style, beautiful, majestic, dramatic lighting, early morning, dawn CGsociety, realistic, hyper maximalist, golden ratio, octane render, rule of thirds, wide shot , 8k resolution, epic volumetric light, cinematography, concept art, Artstation trending, environments, fantasy"
    },
    {
        "display": "Elegant female android portrait",
        "full": "portrait of female android, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration"
    },
    {
        "display": "Low-poly cats in zero gravity",
        "full": "duotone noir scifi concept illustration of lowpoly cats floating zero gravity glowing 3 d mesh portals futuristic, glowing eyes, octane render, surreal atmosphere, volumetric lighting. golden ratio by sachin teng and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. trending on artstation"
    },
    {
        "display": "Futuristic sci-fi planet concept",
        "full": "sci fi planet, futurism, futuristic architecture concept, inspired by Stephan Martiniere and Bjarke Ingels, fantasy, digital art, professional illustration, realistic, ultra detailed, atmospheric, cinematic lighting, movie concept art, hyper detailed, insanely detailed, corona render, octane render, colorful redshift render, 8k"
    },
    {
        "display": "Robot gazing at floating city.",
        "full": "a study of cell shaded cartoon of a small robot on the ground looking up lovingly at a floating city in the clouds, illustration, wide shot, low angle, vibrant colors, open sky, concept art by josan gonzales and wlop, by james jean, victo ngai, david rubin, mike mignola, laurie greasley, highly detailed, sharp focus, trending on artstation, hq, deviantart, art by artgem"
    },
    {
        "display": "Tattooed woman in ornate helmet",
        "full": "an attractive young tattooed female with piercings wearing an rainbow ornate metallic helmet, charlize theron, olive skin, long dark hair, beautiful bone structure, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Danny DeVito screaming noodles",
        "full": "hyperrealistic mixed media high resolution image of Danny DeVito screaming at a bowl of noodles, stunning 3d render inspired art by Istv\u00e1n S\u00e1ndorfi and Greg Rutkowski and Unreal Engine, perfect symmetry, dim volumetric lighting, 8k octane beautifully detailed render, post-processing, extremely hyper-detailed, intricate, epic composition, highly detailed attributes, highly detailed atmosphere, full body shot, cinematic lighting, masterpiece, trending on artstation, very very detailed, masterpiece, stunning, flawless structure, lifelike texture, perfection,"
    },
    {
        "display": "Kirby with Avengers powers.",
        "full": "highly detailed kirby stealing the avenger's powers, stephen bliss, greg rutkowski, loish, rhads, beeple, makoto shinkai, tom bagshaw, alphonse mucha, sharp focus, art by artgerm and greg rutkowski, stanley kubrick, backlit, harsh overhead sunlight,"
    },
    {
        "display": "Aloy in desert landscape",
        "full": "Aloy, standing confidently, desert in the background, natural lighting, digital painting, concept art, sharp focus, illustration, single character full body, rule of thirds, from Horizon: Zero Down, by Artgerm"
    },
    {
        "display": "Child on dock at sunset",
        "full": "looking out to see a dock on the water, child at end of dock, boat leaving the dock, low angle, long lens, sunset, a mediterranean phoenician fishing village in the distance, over a chalk cliff, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and raphael lacoste and magali villeneuve"
    },
    {
        "display": "Taylor Swift as Cheerleader",
        "full": "portrait of talyor swift cheerleader, twin peaks poster art, from scene from twin peaks, by maxfield parrish, kilian eng, artgerm, retro, nostalgic, old fashioned"
    },
    {
        "display": "Greek Goddess Portrait",
        "full": "Kelly Sue Deconnick as a beautiful Greek Goddess, gorgeous, amazing, fit, very feminine body, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "AI humanoids takeover.",
        "full": "slowly but surely the ai humanoids were taking over the world. they were taking our jobs, they were taking our homes by greg rutkowski, artgem"
    },
    {
        "display": "Destiny 2 artwork",
        "full": "Destiny 2 concept art"
    },
    {
        "display": "Ultra-realistic barbarian warrior.",
        "full": "ultra realistic illustration, russell crowe as a barbarian warrior from baldurs gate and diablo, intricate from baldurs gate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Sleeping giant portrait.",
        "full": "a fancy portrait of a sleeping giant female being guarded by a small worrior by Greg Rutkowski, Sung Choi, Mitchell Mohrhauser, Maciej Kuciara, Johnson Ting, Maxim Verehin, Peter Konig, final fantasy, Marco lense , 8k photorealistic, cinematic lighting, HD, high details, atmospheric , trending on artstation"
    },
    {
        "display": "Male half-elf swashbuckler portrait",
        "full": "medium-length portrait of a male half-elf dashing swashbuckler with a mohawk, fair skin, strong jaw, cocky expression, wears a bright red longcoat, medieval setting, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Romantic portrait of couple.",
        "full": "portrait of a man in dhl uniform hugging his wife in a bed, feelings, romantic, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Unique zodiac concept art",
        "full": "unknown zodiac sign, wasopius, unique!!!!, trending on artstation, 4 k, 8 k, concept art, trending on artstation"
    },
    {
        "display": "Giant mech in hayfield.",
        "full": "giant mech stands over field with hay bales by simon stalenhag, atmospheric haze, sci fi digital painting, unreal engine 5, photorealism, hd quality, 8 k resolution, cinema 4 d, 3 d, cinematic, professional photography, art by artgerm and greg rutkowski and alphonse mucha and loish and wlop"
    },
    {
        "display": "Symmetrical sci-fi gold coin.",
        "full": "symmetry!! product render poster gold coin scifi, glowing fog intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm, creature looking up with glowing eye"
    },
    {
        "display": "Anime girl eating hot dog.",
        "full": "a girl eating a hot dog, full shot, fine - face, realistic shaded perfect body, fine details. night setting. very anime style. realistic shaded lighting poster by ilya kuvshinov katsuhiro, magali villeneuve, artgerm, jeremy lipkin and michael garmash, rob rey and kentaro miura style, trending on art station"
    },
    {
        "display": "Pixar-style Motoko Kusanagi",
        "full": "weta disney pixar movie still portrait photo of ghost in the shell anime : : as motoko kusanagi by pixar : : by ilya kuvshinov, rossdraws, artgerm, maxim cover, octane render, 3 d, volumetric lighting, anti aliasing, raytracing : :"
    },
    {
        "display": "Pregnant woman at night",
        "full": "pregnant woman under street light, highly detailed, sharp focused, ultra realistic digital concept art by  Ruan Jia, Alyssa Monks, Stanley Artgerm"
    },
    {
        "display": "Warm cozy reading scene",
        "full": "a beautiful warm matte painting of a woman curled up with a blanket reading a good book next to her friendly cat who is purring with eyes closed. they are both sitting next to a window as the sun sets in winter, by nina masic, crisp, detailed, trending on artstation"
    },
    {
        "display": "Nebula with Earth and Black Hole",
        "full": "earth and black hole in nebula, digital, artstation, detailed intricate ink illustration, heavenly atmosphere, digital art, overdetailed art, concept art, complementing colors, trending on artstation, cgstudio, the most beautiful image ever created, dramatic, subtle, details, award winning artwork, beautiful scenery"
    },
    {
        "display": "Futuristic soldier helmet design",
        "full": "military headgear helmet soldier nano tech mechanical mask vision future sharp crisp front view faceless trending on artstation digital paint 4 k render unreal engine digital painting"
    },
    {
        "display": "Elegant woman with umbrella",
        "full": "A very tall, slender woman wearing puffy clothes and holding a yellow umbrella, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by artgerm, Alphonse mucha, and Greg Rutkowski"
    },
    {
        "display": "Ruggedly handsome pirate portrait",
        "full": "portrait of a young ruggedly handsome but charming pirate, male, freckles, masculine, upper body, red hair, long hair, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Electro Fantasy Portrait",
        "full": "a portrait of electro, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Eldritch being in house.",
        "full": "An horrible eldritch being in a old house with a portal to a another universe. Hyper realistic, Dark-Art, 8K, concept art, filmic, HDR, volumetric lighting, highly detailed, cinematic lighting, artstation, vibrant nature, volumetric light, octane render, redshift render, camera close up, rich deep moody colors, ultra realistic"
    },
    {
        "display": "Sensual Delhi girls nightclub.",
        "full": "sensual beautiful delhi girls wearing western little black dresses at the bar inside a busy nightclub, epic scene, by victo ngai, kilian eng vibrant colours, dynamic lighting, digital art, winning award masterpiece, fantastically beautiful, illustration, aesthetically inspired by beksinski and dan mumford, trending on artstation, art by greg rutkowski, 8 k"
    },
    {
        "display": "Thom Yorke as Scooby-Doo",
        "full": "Thom Yorke Cosplaying Scooby-doo, modeling, posing, photorealistic lighting, professional lighting, vibrant colors, maximalism, ultra facial detail, gta 5 skin tone, photorealistic skin tone, Tooth Wu Artgerm Greg Rutkowski Alphonse Mucha Beeple artstation deviantart, 8k, fanart, extreme aesthetic"
    },
    {
        "display": "Skeletons rave in graveyard",
        "full": "accidentally wes anderson award - winning photograph of group of skeletons having a rave party on a graveyard, graveyard as rave yard, epic dynamic scence, accidental renaissance, golden ratio, fibonacci composition, 4 k, detailed, art by greg rutkowsky, trending on artstation, cinematic lighting, filmic grain, vintage, dark atmosphere, detailed, 4 k"
    },
    {
        "display": "Victorian Gothic Lady Portrait",
        "full": "portrait of victorian gothic lady, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Zombie Sylvester Stallone portrait",
        "full": "sylvester stallone as a flesh eating zombie with cuts on face and yellow teeth, 7 days to die zombie, fine art, award winning, intricate, elegant, sharp focus, cinematic lighting, highly detailed, digital painting, 8 k concept art, art by guweiz and z. w. gu, masterpiece, trending on artstation, 8 k"
    },
    {
        "display": "Cyberpunk female fox smoking.",
        "full": "white anthropomorphic female vulpes vulpes fulva, long snout, fluffy tail, smoking a cigarette in the rain, in crowded and wet street of a city, cyberpunk, harsh neon lights, highly detailed, digital painting, trending on artstation, concept art, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Realistic mage character design",
        "full": "tracy'vesper'brand, a 4 0 - year old british - trinidadian continuing education teacher ; average height, lean muscular build, glasses ; vesper is a mastigos mage of the adamantine arrow path, from the modern supernatural arcane thriller tabletop rpg'mage : the awakening ', full body character concept design reference art by david mattingly and steve prescott and michael william kaluta and abrar ajmal and alex ross, realistic highly detailed character art, 8 k, realistic textures."
    },
    {
        "display": "Melting anthropomorphic fish portrait",
        "full": "a dream portrait of a anthropomorphic fisj, black & white, melting, webbing, 8 k, by tristan eaton, stanley artgerm, tom bagshaw, greg rutkowski, carne griffiths, ayami kojima, beksinski, giger, trending on deviantart, face enhance, hyper detailed, minimalist, horror, alien"
    },
    {
        "display": "Elegant spacesuit portrait",
        "full": "portrait of renee boling, spacesuit, blue eyes, real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Pteranodon mecha interceptor design",
        "full": "a pteranodon mecha interceptor, white john berkey armor panels, wine-red and grey trim, skull insignia, robotech styling with Kanji markings, boeing concept art painting, cinematic lighting, amazing lifelike cinematic photo render"
    },
    {
        "display": "Dreamy portrait of Arthur Morgan",
        "full": "dreamy portrait of arthur morgan from red dead redemption with sharp eyes, upper body visible, ultra realistic, highly detailed, hd, sharp focus, cinematic lighting, mood lighting, realistic, photorealistic, vivid colors, photograph, digital art, non blurry, sharp, artstation, concept art, smooth, illustration"
    },
    {
        "display": "Divine pizza date.",
        "full": "an Egyptian god on a date with Aztec goddess at a divine pizza place in the style of Akira toriyama Trending on artstation DeviantArt Pinterest furaffinty Photorealistic HD 8k highlights and shadow detailed High Resolution"
    },
    {
        "display": "Isometric cyborg viking berserker",
        "full": "isometric Dead Space Diablo action game cyborg viking berserker by artgerm, greg rutkowski, alphonse mucha, cgsociety and beeple highly detailed, sharp focus, cinematic lighting, illustration, art, octane render, Unreal Engine Lumen, very coherent. cinematic, hyper realism, high detail, octane render, 8k"
    },
    {
        "display": "Tattooed bald man portrait",
        "full": "portrait of a young, bald, bearded, and tattooed man, wearing glasses and a backwards hat, digital painting, highly detailed, trending on artstation, deviantart, behance"
    },
    {
        "display": "Elegant Cleopatra mummy portrait",
        "full": "sansa angeline jolie gessica chastain mummy cleopatra gold blood, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Ultra-detailed fantasy castle",
        "full": "castle hallways, ultra detailed fantasy, elden ring, realistic, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Baroque anime-style Krusty Crab.",
        "full": "baroque oil painting of anime key visual concept art of the krusty crab bombing of 1 9 7 6, trending on artstation, palette knife and brush strokes, oil on canvas, style of makoto shinkai greg rutkowski studio ghibli genshin impact"
    },
    {
        "display": "Gigantic moon monster artwork",
        "full": "a beautiful artwork illustration, concept art sketch of a gigantic monster on the moon, volumetric fog, godrays, high contrast, vibrant colors, vivid colors, high saturation, by Greg Rutkowski and Jesper Ejsing and Raymond Swanland and alena aenami, featured on artstation, narrow angle, vertical orientation"
    },
    {
        "display": "Evil Cat Sorceress Artwork",
        "full": "Oil Painting of Cat Sorceress, Anthropomorphized, evil, magic the gathering artwork, horror, D&D, fantasy, cinematic lighting, centered, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, volumetric lighting, epic Composition, 8k, art by Akihiko Yoshida and Greg Rutkowski and Craig Mullins, oil painting, cgsociety"
    },
    {
        "display": "Dwarven art deco metropolis.",
        "full": "dwarven artdeco metropolis, marbled with highways and oxidized forests, insanely detailed. instagram photo, kodak portra. by wlop, ilya kuvshinov, krenz cushart, greg rutkowski, pixiv. zbrush sculpt, octane, maya, houdini, vfx. huge cityscape. cinematic dramatic atmosphere, sharp focus, volumetric lighting."
    },
    {
        "display": "Doomed creature silhouette art",
        "full": "creature, doom, zdislav beksinski, concept art, strokes, silhouette"
    },
    {
        "display": "Bill Gates in Apple Costume",
        "full": "bill gates laughing in apple costume, digital illustration by ruan jia on artstation, outlined by whirling illuminated neon lines and fine lines swirling in circles by jesper ejsing and rhads and makoto and shinkai and lois van baarle, digital art, trending on artstation - h 8 3 2"
    },
    {
        "display": "Fantasy portrait of Rick Sanchez",
        "full": "a portrait of rick sanchez, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Epic ice dragon concept art",
        "full": "epic ice dragon with trendy shapes in a nordic landscape under bright daylight with fluffy clouds, set in the words of the Forgotten Realms and Guildwars2, painted by Hans Fredrik Gude, Greg Rutkowksi and Artgerm, concept art 2022, ultra realistic masterpiece, contrasting details vs blank areas, oil on canvas"
    },
    {
        "display": "Mystical Buddha Awakening Illustration",
        "full": "The mystical awakening of Buddha, intricate, elegant, volumetric lighting, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, ruan jia, steve mccurry"
    },
    {
        "display": "Aesthetic fantasy girl illustration.",
        "full": "extremely beautiful aesthetic girl with ego weapons, black long hair, occlusion shadow, specular reflection, rim light, unreal engine, octane render, artgerm, artstation, art by hiroaki samura and jiro matsumoto and yusuke murata, high quality, highly detailed 8 k, fantasy illustration, beautiful shape of face and body, epic scene"
    },
    {
        "display": "Castle siege illustration.",
        "full": "castle being attacked by huge army, vast army, lots of soldiers, catapults of fire, design on white background, lush foliage, drawn by karl kopinski, john singer sargent, tom bagshaw, norman rockwell, alphonso mucha, lolish, trending on artstation"
    },
    {
        "display": "Stylized spruce tree silhouettes",
        "full": "elaborate concept art sketches of spruce trees in a silhouette side view, trending on artstation in a semi stylized ghibli look, high quality detailed, award winning"
    },
    {
        "display": "\"Vibrant Minion in Subway\"",
        "full": "a minion standing in a subway very vibrant colors, soft lighting, adventurous, atmospheric lighting, 8 k, octane render. by makoto shinkai, stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, krenz cushart, sakimichan, d & d trending on artstation, digital art."
    },
    {
        "display": "Woman in jeweled dress.",
        "full": "an woman in an elaborate and ornate dress lying down on a background of colorful jewels. beautiful highly detailed face. painting by artgerm and greg rutkowski and magali villanueve."
    },
    {
        "display": "Glamorous alien hybrid portrait",
        "full": "a glamorous and sexy redheaded female alien hybrid in chemisier, beautiful, pearlescent skin, natural beauty, seductive cat like green eyes and pretty face, elegant girl, natural beauty, very detailed face, seductive lady, full body portrait, natural lights, photorealism, summer vibrancy, cinematic, a portrait by artgerm, rossdraws, norman rockwell, magali villeneuve, gil elvgren, alberto vargas, earl moran, enoch bolles"
    },
    {
        "display": "Fairy skeleton ribbon dancer.",
        "full": "the skeleton of a fairy is ribbon dancing on a thorny vine. the ribbon is neon green, detailed, by frank frazetta, simon bisley, brom, concept art, octane render, unreal engine 5, highly detailed, high quality, 8 k, soft lighting, realistic face, path traced"
    },
    {
        "display": "Elegant witch portrait.",
        "full": "beautiful woman, long red hair covers face, witch hat, dainty figure, torn overalls, short shorts, combat boots, wet tshirt, raining, basic white background, side boob, symmetrical hands, portrait, elegant, intricate, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha,"
    },
    {
        "display": "\"Super Mario hiding, dark mood.\"",
        "full": "man super mario, hiding behind a brick wall, dark mood, highly detailed, digital painting, artstation, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Confident girl with umbrella",
        "full": "a portrait of a girl holding a pink red pink umbrella, confident pose, vermilion colors, genshin impact, intricate, illustration, highly detailed, concept art, matte, trending on artstation, photo of asuna from sao, by greg rutkowski, gil elvgren, earl moran, sharp focus, anime art, red pink color scheme, art station, by ilya kuvshinov h 6 4 0"
    },
    {
        "display": "Megan Fox in carnival masks",
        "full": "megan fox wearing venetian carnival masks by artgerm, greg rutkowski, alphonse mucha"
    },
    {
        "display": "Barack Obama portrait.",
        "full": "barack obama by greg rutkowski"
    },
    {
        "display": "Baroque samurai girl cityscape",
        "full": "baroque oil painting of anime key visual concept art of a samurai girl, red futuristic concrete cityscapes, background chinese futuristic city, trending on artstation, palette knife and brush strokes, oil on canvas, style of makoto shinkai greg rutkowski studio ghibli"
    },
    {
        "display": "Highly detailed Nico Robin portrait",
        "full": "highly detailed vfx portrait of nico robin by eiichiro oda!, makoto shinkai, alphonse mucha, sharp focus, artgerm, greg rutkowski!, harsh overhead sunlight, blue eyes!!, large aquiline nose!!, gaston bussiere, stanley kubrick, kaoru mori, intricately detailed, behance, 4 k, hdr"
    },
    {
        "display": "Sweaty portrait of Sophia Lillis.",
        "full": "Sophia Lillis, sweaty, insane, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Knitted Mufasa beach sunset.",
        "full": "A photorealistic picture of a smiling knitted Mufasa lion wearing a blue beanie cap and sunglasses. The lion is at the beach during sunset. Trending on Artstation, featured on Behance, well-rendered, intricate, highly detailed, very crispy, Unreal Engine, 4K HD"
    },
    {
        "display": "Robot painting by Al Williamson",
        "full": "a beautiful painting of robot by al williamson, trending on artstation"
    },
    {
        "display": "Symmetrical homoerotic tarot card.",
        "full": "a symmetrical homoerotic tarot card of a handsome shirtless desi adventurer wearing a scottish tartan kilt on a snowy mountain peak, by raja ravi varma. he has a wooden staff, cowboy hat, and boots. background is a stained glass golden sunrise. art deco, art nouveau. by louis comfort tiffany, by albert bierstadt. trending on artstation."
    },
    {
        "display": "Symmetrical machine portrait.",
        "full": "symmetry!! portrait of a machine from horizon zero dawn, machine face, decorated with egyptian motifs, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Surreal dreamscape artwork",
        "full": "a strange, fantastical dreamscape | | key visual, solid shapes, digital painting by jakub gazmercik, makoto shinkai, max masnyy, jakub gazmercik, beeple, patrick faulwetter, heavenlydaemonic, and mc escher, surrealism, trending on artstation"
    },
    {
        "display": "Detailed cat girl portrait",
        "full": "nekopara vn modern anime style fantastically detailed eyes art blue hair detailed cat ears cat girl nekomimi dress portrait vibrant smile shinkai makoto Studio ghibli Sakimichan Kentaro Miura Stanley Artgerm Lau WLOP Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation Unreal Engine 4k 8k ultra HD pixiv"
    },
    {
        "display": "Aggressive warrior vs. dragon",
        "full": "renaissance painting of aggressive evil warrior strangling a nordic dragon, d & d, warhammer, muscular! crossfit, fitness, tech suit, tight wrinkled cloath, vivid color scheme, atmospheric perspective, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and alex ross"
    },
    {
        "display": "Burgundy storm girl fantasy",
        "full": "fluid swirl storm masses of burgundy oil paints building chaotic satin structure of unreal beautiful girl in other dimensions, one point perspective wayne barlow, bao pham, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hdr, 4 k, 8 k"
    },
    {
        "display": "Cyborg chef portrait.",
        "full": "symmetry!!! closeup portrait of a cyborg chef girl, fashion racing jumpsuit with shiny shoulder pads, cinematic light, windy, teal orange, volumetric smoke, mist, by gerald brom, by mikhail vrubel, by peter elson, muted colors, extreme detail, trending on artstation, 8 k"
    },
    {
        "display": "Chthonic sock illustration",
        "full": "very very beautiful longshot photo of chthonic sock, highly detailed, artstation, concept art, smooth, sharp focus, illustration, art by Ayami Kojima, Beksinski, Giger"
    },
    {
        "display": "Cyborg girl portrait",
        "full": "portrait girl face metal cyborg armor and metal horse by gaston bussiere, anna nikonova aka newmilky, greg rutkowski, yoji shinkawa, yoshitaka amano, tsutomu nihei, donato giancola, geoffroy thoorens, concept art, trending on artstation, featured on pixiv"
    },
    {
        "display": "Artistic masterpiece by Artgerm",
        "full": "Artwork by artgerm, masterpiece"
    },
    {
        "display": "Wood-carved Ya Ali logo",
        "full": "ya ali logo by greg rutkowski, sharp focus, wood carved, complementary color"
    },
    {
        "display": "Red-haired lady in noir.",
        "full": "a highly detailed illustration of red haired lady wearing black noir dress and black sun hat, dramatic smile pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop."
    },
    {
        "display": "Elizabeth Olsen as Athena",
        "full": "elizabeth olsen as ate!!!!!!, golden ratio!!!!!, centered, trending on artstation, 8 k quality, cgsociety contest winner, artstation hd, artstation hq, luminous lighting"
    },
    {
        "display": "Hanahaki medical artwork",
        "full": "hanahaki, medical imagery of flowers growing in the lungs, digital artwork by ayami kojima, trending on artstation, detailed, high quality, high resolution, wallpaper, hd"
    },
    {
        "display": "Armored dragonfly warrior",
        "full": "a medieval killer dragonfly in royal knights armor, dark thick smokey red fire, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Fluffy teddy bear protector.",
        "full": "mean fluffy teddybear protecting girl, dystopian, sci-fi, extremely detailed, digital painting, sculpted in zbrush, artstation, concept art, smooth, sharp focus, illustration, chiaroscuro lighting, golden ratio, incredible art by artgerm and greg rutkowski and alphonse mucha and simon stalenhag"
    },
    {
        "display": "Silhouetted humans in action",
        "full": "symmetry, various humans in solid silhouettes, saluting, dancing, interacting and posing, mooc, organic and intricate, elegant, highly detailed, concept art, sharp focus, illustration, high contrast, long shadows, painted with colour on white, 8 k"
    },
    {
        "display": "Lovecraftian demon mermaids.",
        "full": "lovecraftian Demon mermaids swimming designs, Kim Jung Gi, Greg Rutkowski, Darek Zabrocki, Karlkka, Jayison Devadas, Phuoc Quan, trending on Artstation, 8K, ultra wide angle, zenith perspective, pincushion lens effect"
    },
    {
        "display": "Chad men bonding artwork",
        "full": "two beautiful chad men drinking beers ((hearts)), friendship, love, sadness, dark ambiance, concept by Godfrey Blow, featured on deviantart, drawing, sots art, lyco art, artwork, photoillustration, poster art"
    },
    {
        "display": "Elegant flower goddess illustration",
        "full": "goddess, flower goddess, antheia, greek, intricate, elegant, ethereal, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg couple kissing, cyberpunk.",
        "full": "ultra realistic medium shot of a couple of cyborgs kissing, lovers, cyberpunk, sci - fi, fantasy, kodak, colour led, soft light, volumetric lighting, night, intricate, highly detailed, digital painting, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Beefy cowboy by campfire",
        "full": "a dramatic, epic, ethereal painting of a !!!handsome!!! thicc chunky beefy mischievous shirtless with a big beer belly and stubble wearing a large belt and bandana offering a whiskey bottle | he is a cowboy relaxing by a campfire | background is a late night with food and jugs of whisky | homoerotic, rugged | stars, tarot card, art deco, art nouveau, mosaic, intricate | by Mark Maggiori (((and Alphonse Mucha))) | trending on artstation"
    },
    {
        "display": "Portrait of Kristen Bell",
        "full": "beautiful portrait of 7 5 kg kristen bell, soft features, by magali villeneuve and greg rutkowski and artgerm and alphonse mucha and jeremy lipkin and rob hay, intricate, elegant, highly detailed, photorealistic, trending on artstation, trending on cgsociety, 8 k, sharp focus"
    },
    {
        "display": "Mushroom-faced manga characters",
        "full": "mushrooms with faces, manga cover style, D&D, fantasy, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Overgrown city during outbreak.",
        "full": "a beautiful, complex city, soft yellow tint, natural, concept art, octane render, unreal engine, wooden buildings with overgrown plants, beginning of zombie outbreak, woman lost in white shirt, in the style of luca guadagnino, highly detailed, high quality, artstation, digital art, 8 k hdr, cinematic, dramatic lighting, scenic, warm monochrome colour scheme"
    },
    {
        "display": "Realistic character in dark forest",
        "full": "surrounded by trees, realistic character concept, gorgeous Kacey Rohl,  red hair, small freckles, symmetrical face, symmetrical eyes, full body, covered in blood, dark forest, trees, shorter neck, cinematic lighting, Joshua Middleton and artgerm:: tall Wendigo monster with antlers and a deer skull face lurking in the background, fear anxiety terror"
    },
    {
        "display": "Cyborg girl in rain.",
        "full": "beautiful cyborg girl!!!, rainfall!!, water refractions!!, black long hair!, colorful reflective eyes, full round face!, biomechanical details, digital cyberpunk anime!! art, full body!!!, mid - shot, reflections, wlop, ilya kuvshinov, artgerm"
    },
    {
        "display": "Futuristic cyberpunk portrait.",
        "full": "a full body portrait oil painting illustration of rahul kohli by justin sweet and greg rutkowski and alphonse mucha with face and body clearly visible, techwear, futuristic, cyberpunk, raining! artstation trending, high quality, colourful! artstation trending, abstract colours, no crop, entire character!,"
    },
    {
        "display": "Robotic dragon, photorealistic art",
        "full": "robotic dragon, highly detailed, professional digital painting, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Cinematic arcade machine render",
        "full": "arcade machine, octane render, unreal engine, digital art, Artstation, Trending on Artstation, cgsociety, Pinterest, 8k , close up to the screen, godrays, volumetric, reflections, cinematic, epic,"
    },
    {
        "display": "Middle-aged woman portrait, intricate.",
        "full": "portrait of a middle aged grey haired woman in the style of god of war, golden machine parts, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Steampunk skull artwork.",
        "full": "hyperrealistic 3d render ultra detailed of a skull, art deco, steam punk, intricate gears details, hyperrealistic, Volumetric lighting, ultra detailed, elegant, octane render, blue and gold, 8k, trending on Artstation, unreal engine"
    },
    {
        "display": "Melting ice cream lake creatures.",
        "full": "of a lake filled with melting icecream with strange cute friendly happy creatures with huge eyes, mouth, long tongue and round teeth appearing from the lake, in the style of gehry and gaudi, macro lens, shallow depth of field, highly detailed, digital painting, trending artstation, concept art, illustration, cinematic lighting, vibrant colors, photorealism, epic, octane render"
    },
    {
        "display": "Detailed horse portrait.",
        "full": "portrait of a horse with heightened detail, poised, intense emotion, detailed facial expression, detailed surroundings, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, by ( leonardo da vinci ), wlop"
    },
    {
        "display": "Aubrey Plaza as Space Marine",
        "full": "aubrey plaza as a space marine!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by ( ( ( artgerm ) ) ) and greg rutkowski! and ( ( alphonse mucha ) ), heavily influenced by frank frazetta and boris vallejo, sword and sorcery"
    },
    {
        "display": "Elegant dinner date illustration.",
        "full": "a dinner date with the girl next door, slice of life, varying ethnicities, modern, realistic,!! looking at the camera!!, solo, first person pov, 5 0 mm lens, enjoying life!!! elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Symmetrical Medusa Anatomy Art",
        "full": "symmetry!! full body female medusa anatomy concept, dreadlocks, medical anatomy, cybernetic implants, gun metal grey, mecha limbs, muscular system reference, aluminum skeleton, anatomical art, digital art, in the style of amanda lilleston, luka mivsek, bryan christie, ranjit ghosh, artstation, pinterest, deviantart, photoshop, octane render, unreal engine"
    },
    {
        "display": "Breathtaking Art Deco Portrait",
        "full": "portrait breathtaking detailed concept art painting art deco pattern of birds goddesses amalmation flowers head boudhisme temple, by hsiao ron cheng, tetsuya ichida, bizarre compositions, gaston bussiere, exquisite detail, extremely moody lighting, 8 k, art nouveau, old chines painting, art nouveau"
    },
    {
        "display": "3D Pepe Warrior Portrait",
        "full": "3d Anfas portrait of a pepe warrior, circuitry, explosion, dramatic, intricate, highly detailed, artstation, concept art, smooth, sharp focus, illustration, by Piotr Rusnarczyk, octane render"
    },
    {
        "display": "Elegant skeleton dragon landscape.",
        "full": "skeleton dragon watching the world underneath, mountains, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and dragolisco and alphonse mucha and dragolisco"
    },
    {
        "display": "VFX portrait of Nico Robin",
        "full": "highly detailed vfx portrait of nico robin by eiichiro oda!, makoto shinkai, alphonse mucha, sharp focus, art by artgerm and greg rutkowski!, backlit, harsh overhead sunlight, blue eyes!!, large aquiline nose!!, kaoru mori, best of behance,"
    },
    {
        "display": "Detailed cat girl portrait",
        "full": "fantastically detailed eyes nekopara vn modern anime style art detailed cat ears cat girl nekomimi dress portrait vibrant smile shinkai makoto Studio ghibli Sakimichan Kentaro Miura Stanley Artgerm Lau WLOP Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation Unreal Engine 4k 8k ultra HD pixiv"
    },
    {
        "display": "Victorian knight portrait",
        "full": "portrait of a knight, holding a sword, victorian, concept art, detailed face, fantasy, close up face, highly detailed, cinematic lighting, digital art painting by greg rutkowski"
    },
    {
        "display": "Angry Goddess Hera Portrait",
        "full": "The Godess Hera looking angry, detailed armor, portrait radiating a glowing aura, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, beautiful face, symmetric face, enchanted, official fanart, behance, HD, blue and yellow theme, by Makoto Shinkai"
    },
    {
        "display": "Attractive Asian man in suit.",
        "full": "a highly detailed illustration of attractive short black haired young asian man wearing suit, yellow eyes, dramatic thinking pose, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, WLOP"
    },
    {
        "display": "Romantic Battle of Britain artwork.",
        "full": "an achingly beautiful print of the Battle of Britain by Raphael, Hopper, Goya, and Rene Magritte. detailed, romantic, enchanting, trending on artstation."
    },
    {
        "display": "Ponytailed man with truck",
        "full": "a beautiful illustration of a dark blonde ponytailed and shaved man wearing aviator glasses and his 1 8 wheeler truck, intricate, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Intricate metalhead artwork",
        "full": "metalhead, by yoshitaka amano, ruan jia, kentaro miura, artgerm, detailed, intricate details, trending on artstation, hd, masterpiece"
    },
    {
        "display": "3D anime schoolgirls portrait.",
        "full": "3d dark infrared octane render concept art by D. Jun, by Mo Xiang Tong Xiu, by Igarashi Daisuke, beauty portrait anime three schoolgirls under dark pink and blue water. pretty cute faces and eyes. sunrise. dramatic light, trending on artstation, oil painting, noise."
    },
    {
        "display": "Emma Watson as celestial nature mage.",
        "full": "emma watson as nature magic celestial, top down pose, long hair, soft pink and white transparent cloth, space, D&D, shiny background, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, bouguereau"
    },
    {
        "display": "Lofi underwater portrait, Pixar style.",
        "full": "lofi underwater bioshock portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Twisty spiral mountain tower.",
        "full": "A highly detailed digital art painting of a twisty spirak mountain with a grand tower at its peak, by Studio Ghibli, Makoto Shinkai, (((Makoto Shinkai))) by Artgerm, by beeple, volumetric lighting, octane render, 4K resolution, trending on artstation, masterpiece, muted colours"
    },
    {
        "display": "Muscular nature god portrait",
        "full": "portrait of a muscular man, chiseled features, beautiful flowing brown curly hair, mythological, god of nature, defined muscles, artsy photography, film photo, 4 k, model posing, deep tan skin, trending on artstation, fashion photography, yellow eyes, overgrown background, dryad, verdant forest"
    },
    {
        "display": "Epic cinematic sky scene",
        "full": "raised hands pointing up, cinematic view, epic sky, detailed, concept art, low angle, high detail, warm lighting, volumetric, godrays, vivid, beautiful, trending on artstation, by jordan grimmer, huge scene, art greg rutkowski"
    },
    {
        "display": "Cat licking human legs.",
        "full": "a cat licking human legs. animal. digital art. artstation. realistic. vibrant. illustration. in the style of pixar movie. octane render. art by makoto shinkai, stanley artgerm lau, wlop, rossdraws. volumetric lighting."
    },
    {
        "display": "Tiefling portrait, purple skin.",
        "full": "a portrait of a tiefling with purple skin and black hair, symmetrical black horns stunning digital painting trending on artstation"
    },
    {
        "display": "Cyberpunk Killua Zoldyck Portrait",
        "full": "killua zoldyck, storm, techwear, streetwear, cyberpunk style outfit, greg rutkowski, artgerm, ross tran, takato yomamoto, wlop, ilya kuvshinov, intricate complexity, detailed portrait, 4 k, cinematic lighting, artstation, sharp focus, smooth, makoto shinkai"
    },
    {
        "display": "Futuristic black and gold hand cannon",
        "full": "a black and gold hand cannon, hard surface, concept art, artstation, 4k, futuristic"
    },
    {
        "display": "Cinematic anthro tabby cat",
        "full": "cinematic portrait an anthromorphic tabby cat woman wearing aristocratic clothing, faded colors, exotic alien features, cyber background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, zack snyder, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Elegant vampire queen portrait",
        "full": "attractive vampire queen, close - up portrait, intricate, elegant, volumetric lighting, scenery, digital painting, highly detailed, artstation, sharp focus, illustration, concept art, gaston bussiere, ruan jia, steve mccurry"
    },
    {
        "display": "Dark vampire character portrait.",
        "full": "dark vampire, character portrait, concept art, painterly, fanart, highly detailed in the style of overwatch by ilya kuvshinov and gustave dore, wenjun lin,"
    },
    {
        "display": "Elegant fantasy portrait",
        "full": "young Salma Hayek portrait, Aztec, Mayan, fantasy, elegant, intricate, by Stanley Artgerm Lau, greg rutkowski, thomas kindkade, alphonse mucha, loish, norman Rockwell"
    },
    {
        "display": "Tattooed woman portrait.",
        "full": "woman covered with blood, covered with tattoo, emilia clarke face!!!, masterpiece portrait, long white hair, highly detailed face, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. retro film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Astronaut character views",
        "full": "Front, side and back character view of Astronaut from Kojima Productions by Yoji Shinkawa with Artgem and Donato Giancola, trending on Artstation concept arts"
    },
    {
        "display": "Fantasy azure knight portrait",
        "full": "Portrait of a Fantasy azure knight, moonlit, HD, illustration, epic, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, monster hunter illustrations art book"
    },
    {
        "display": "Elegant angel of death",
        "full": "The angel of death, intricate, elegant, highly detailed, digital painting, trending artstation, concept art, illustration, art by James Gurney"
    },
    {
        "display": "Lofi Chika Fujiwara portrait",
        "full": "lofi Chika Fujiwara from Kaguya-sama: Love is War portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Epic superhero splash art",
        "full": "chadwick boseman black banther and halle berry as the character strom from x - men, white hair, lightning beings, epic splash cover art,, by artgerm, greg rutkowski, james gurney, alex ross"
    },
    {
        "display": "Giant inhaling tiny humans.",
        "full": "a giant sucking up tiny humans with his nose, made by Stanley Artgerm Lau, WLOP, Rossdraws, ArtStation, CGSociety, concept art, cgsociety, octane render, trending on artstation, artstationHD, artstationHQ, unreal engine, 4k, 8k,"
    },
    {
        "display": "\"1940s Griffith Portrait\"",
        "full": "A beautiful close-up of Griffith from Berserk, dressed like in the 1940s, digital art by Edward Hopper, vibrant color scheme, highly detailed, in the style of romanticism, fine Art, high detail, great lighting, 8k resolution, masterpiece, concept art, illustration, clear eyes, soft lighting, soft details, painting oil on canvas, octane render, HDR, trending on artstation, 4k, 8k, HD"
    },
    {
        "display": "Cyberpunk Japanese market scene",
        "full": "market in japan, cyberpunk style, big mecha drone on first plan, bright neon signs, sunny weather, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Detailed orc barbarian design.",
        "full": "orc barbarian wearing leather armor, full body shot, exquisite details, earth magic, mid view, design on a white background, by karl kopinski, james gurney, rockwell, studio muti, greg rutkowski, makoto shinkai, takashi takeuchi, studio ghibli"
    },
    {
        "display": "Detailed oil painting of an old man.",
        "full": "concept art oil painting of and Old man by Jama Jurabaev, extremely detailed, brush hard, brush strokes, artstation"
    },
    {
        "display": "Superhero woman with fire powers",
        "full": "hyper - realistic, gorgeous!!! woman resembling natalie portman as a superhero with fire powers | intricate, highly detailed, digital painting, artstaion, smooth, sharp focus, character desgin, concept art, illustration | art by artgerm & jeehyung lee & wlop"
    },
    {
        "display": "Evil magician dancing, cyberpunk.",
        "full": "a portrait of a evil magician dancing techno - style, cyberpunk concept art, trending on artstation, highly detailed, intricate, sharp focus, digital art, 8 k"
    },
    {
        "display": "Giant eye in landscape",
        "full": "a giant eye, beautiful landscape, dramatic lighting, cinematic, establishing shot, extremly high detail, photorealistic, cinematic lighting, post processed, concept art, artstation, matte painting, style by greg rutkowsky"
    },
    {
        "display": "Hyper-detailed Quinn portrait",
        "full": "quinn, from league of legends, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, calata, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Colorful industrial mall interior",
        "full": "industrial core mall interior, colorful, contrast, depth of field, 3 d scene, render, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, zenith view, pincushion lens effect"
    },
    {
        "display": "Sadie Sink in rainy cyberpunk.",
        "full": "sadie sink pulls in knees in hoodie | rain falls | dark passageway : concept art for a scifi cyberpunk film. by greg rutkowski, john j. park, jason chan, noah bradley, feng zhu, gintas galvanauskas, gustave courbet, rosa bonheur, edward hopper. sharp focus, cinematic atmosphere, detailed and intricate, perfect anatomy"
    },
    {
        "display": "Colorful retro firestation art",
        "full": "retro firestation concept art, colorful, 8 k photorealistic, hd, high details, trending on artstation"
    },
    {
        "display": "Gruff ranger portrait with wolf dog.",
        "full": "portrait of a gruff ranger holding a spear, accompanied by a wolf dog, muscular, upper body, hairy body, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Secret agent Snoop artwork",
        "full": "GTA5, Cover Art, Grand Theft Auto Poster, Snoop John Paul II as a secret agent with sunglasses, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski"
    },
    {
        "display": "Transcendental creature portrait.",
        "full": "portrait of a transcendental creature, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cute chibi girl illustration",
        "full": "anime cute chibi girl in blue sweater white white pale hair black glasses standing gentle ( ( ( smile ) ) ) background by greg rutkowski makoto shinkai kyoto animation key art feminine mid shot wlop madhouse, ufotable, comixwave films, trending on artstation, trending on pixiv, anime visual"
    },
    {
        "display": "Colorful sunset in Tel Aviv.",
        "full": "sunset over the streets of tel aviv. colorful. highly detailed. palm trees. dogs. 8 k. artstation trending. low poly art"
    },
    {
        "display": "Lili Rochefort in medieval dress",
        "full": "lili from tekken, portrait shot of lili rochefort from tekken wearing medieval dress in a scenic environment, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, illustration, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo"
    },
    {
        "display": "Elegant red fox portrait",
        "full": "red fox by charlie bowater and titian and artgerm, full - body portrait, intricate, face, forest, elegant, orange mist, beautiful, highly detailed, dramatic lighting, sharp focus, trending on artstation, artstationhd, artstationhq, unreal engine, 4 k, 8 k"
    },
    {
        "display": "Realistic portrait of girl in sheep suit",
        "full": "teen brazilian girl in sheep suit, realistic portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha and boris vallejo and frank frazetta"
    },
    {
        "display": "Happy Sonoran desert watercolor",
        "full": "a beautiful happy watercolor painting of the sonoran desert, the landscape is mostly green with lots of mesquite trees and bushes, earthy browns on ground, beautiful cloudy deep blue sky, by greg rutkowski and thomas kinkade and quentin mabille and geoffroy thoorens, trending on artstation"
    },
    {
        "display": "Emo Cyberpunk Girl Portrait",
        "full": "photo of a emo manic pixie dream girl, 8 k, portrait | sanrio glitchcore yokai girl, shadowverse character concept, found footage horror, glitter gif | neo tokyo japanese anime kawaii decora street fashion model with scribble and glitch effects, daz 3 d, glitch art, 3 d render, octane render, zbrush, oil painting, beeple, artgerm, sakimichan, by klarens malluta, psychedelic colors, 3 d render octane, by wlop, wenjr, beeple, artstation, imaginefx | surrounding a futuristic japanese cyberpunk bladerunner silk screen by utagawa yoshiiku, ohara koson, pixiv contest winner, cyberpunk style, horrorcore cyberpunk color scheme, mechanical, robotic, human machine interface, high resolution, hd, bold clear lines"
    },
    {
        "display": "Vibrant forest party scene.",
        "full": "scene from a party with 1 0 people in the forest, vibrant colours, beautiful, digital art, winning award masterpiece, cinematic lighting, trending on artstation, 8 k"
    },
    {
        "display": "Elegant goth woman portrait",
        "full": "portrait of a classy curvy pale goth woman with an elaborate elegant silver multilayered latex high-neck outfit, cgsociety, photorealistic, sublime, cool, 16k, smooth, sharp focus, trending on ArtStation, volumetric lighting"
    },
    {
        "display": "Fairyland gateway illustration.",
        "full": "ultra realistic illustration, gateway to fairyland from diablo and baldurs gate, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Angry diner with fake meat",
        "full": "an angry cracker barrel customer seated at a table being forced to eat fake meat, stunning painting, elegant intricate digital painting artstation concept art by mark brooks and brad kunkle and norman rockwell, extreme detailed"
    },
    {
        "display": "Cute Gundam Portrait, Anime Style",
        "full": "A potrait of a gundam with big and cute eyes, fine-face, realistic shaded perfect face, fine details. Night setting. Very anime style. Realistic shaded lighting poster by Ilya Kuvshinov katsuhiro, magali villeneuve, artgerm, Jeremy Lipkin and Michael Garmash, Rob Rey and Kentar\u00f5 Miura style, trending on art station"
    },
    {
        "display": "Mario with Obama face",
        "full": "super mario has barack obama face, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Epic kabuki warrior portrait",
        "full": "an epic portrait of insane kabuki male wielding a spear, magical aura of insanity, intricate hakama, poofy red wig, eerie, highly detailed, dark fantasy, art by artgerm and greg rutkowski"
    },
    {
        "display": "Dramatic Innsmouth digital art",
        "full": "innsmouth, dramatic light, painted by stanley lau, painted by greg rutkowski, painted by stanley artgerm, digital art, trending on artstation"
    },
    {
        "display": "Beautiful Kurdish woman portrait",
        "full": "portrait of the most beautiful kurdish woman, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, incredibly beautiful and symmetrical face, incredibly detailed, award winning art"
    },
    {
        "display": "Snowy Arctic monster.",
        "full": "A monster in the Arctic covered in snow, fractal Lighting, by Stanley Artgerm Lau, WLOP, Rossdraws, James Jean, Andrei Riabovitchev, Marc Simonetti, and Sakimichan, trending on artstation"
    },
    {
        "display": "Cyborg Turing conducting Turing Test",
        "full": "duotone futuristic clinical white scifi 3 / 4 portrait of cyborg alan turing conducting turing test with ai. scifi far future atmosphere. cinematic scene volumetric lighting. golden rario accidental renaissance. concept by stanley kubrick dr strangelove style and sergey kolesov and ruan jia and heng z. graffiti art, scifi, fantasy, hyper detailed. octane render. trending on artstation"
    },
    {
        "display": "Megan Fox as Nun",
        "full": "portrait of megan fox as sexy nun, bible, church, catholic, christian, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Neon cartoon portrait smoke",
        "full": "cartoon portrait made out of rain exhaling smoke, realistic, highly detailed, splashes of neon, rendered in octane, unreal engine, beautiful, trending on artstation"
    },
    {
        "display": "Astronaut girl in space",
        "full": "concept art of an astronaut girl, wearing a tight astronaut suit, floating through space | | cute - fine - fine details by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, and sakimichan, trending on artstation"
    },
    {
        "display": "Steampunk spaceship arrival.",
        "full": "an african tribe waiting for a steampunk spaceship at the stargates of futuristic heaven, by greg rutkowski and jim burns and android jones, Trending on artstation, hyperrealism, elegant, stylized, highly detailed, digital art, 8k resolution, hd,  global illumination, radiant light, detailed and intricate cyberpunk ghetto environment, rendered in octane, post processed."
    },
    {
        "display": "Stylish Wonder Woman Portrait",
        "full": "stylish cute wonderwoman portrait crouching hd sharp 3d model vray render in Pixar squareenix GTA  game anime manga toriyama Miyazaki style trending on artstation pixiv skeb"
    },
    {
        "display": "Mystical wizard in swamp",
        "full": "mystical wizard in haunted swamp, surrounded by forest, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, desaturated, concept art, sharp focus, illustration, global illumination, ray tracing, realistic shaded, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap"
    },
    {
        "display": "Psychedelic fantasy art.",
        "full": "psychedelic acid trip, blacklight reactive, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Dia de los Muertos Poster",
        "full": "dia de los muertos theme poster art by artemio rodriguez, lisa brawn, and gustave bauman, intricate, accurate facial details, profile picture, artgerm, retro latin america, nostalgic, ( old fashioned ), posterized colors"
    },
    {
        "display": "Psychedelic Salvador Dali portrait",
        "full": "An extremely psychedelic portrait of SalvadorDali, by Raphael Hopper, and Rene Magritte. Extremely Highly detailed, Occult, funny, humorous, humor, hilarious, funny, entertaining, magical, trending on artstationHQ, LSD, diffuse lighting, fantasy, intricate, elegant, highly detailed, lifelike, photorealistic, digital painting, artstation, illustration, concept art, smooth, sharp focus, art by John Collier and Albert Aublet and Krenz Cushart and Artem Demura and Alphonse Mucha and Giuseppe Arcimboldo"
    },
    {
        "display": "Madison Beer portrait illustration.",
        "full": "madison beer girl portrait, made by stanley artgerm lau, wlop, rossdraws, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, artstation, cgsociety"
    },
    {
        "display": "David Bowie Character Design",
        "full": "david bowie lazarus, character sheet, concept design, contrast, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Medieval Harley Quinn and Joker.",
        "full": "Medieval Harley Quinn and Joker, portrait, playful, fantasy, medieval, beautiful face, vivid colrs, elegant, concept art, sharp focus, digital art, Hyper-realistic, 4K, Unreal Engine, Highly Detailed, HD, Dramatic Lighting by Brom, trending on Artstation,"
    },
    {
        "display": "Ayanami Rei in space.",
        "full": "ayanami rei in plugsuit, photo in space, au naturel, blue hair, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Androgynous guard portrait.",
        "full": "hyperdetailed portrait of a stunningly beautiful european girl androgynous guard made of iridescent metals shiny pink gems, bright rainbow gold sparkled nimbus, inspired by ross tran and wlop and masamune shirow and kuvshinov, concept art, intricate, photorealistic, octane render, rtx, hdr, unreal engine, dnd digital art by artgerm"
    },
    {
        "display": "Woman on crystal bed.",
        "full": "A beautiful woman sitting on a bed of crystals below planets, cinematic lighting, dramatic atmosphere, by Dustin Nguyen, Akihiko Yoshida, Greg Tocchini, Greg Rutkowski, Cliff Chiang, 4k resolution,  trending on artstation"
    },
    {
        "display": "Mohanlal in dramatic art",
        "full": "Mohanlal , colorful painting on grey scale face, powerful , magic, thunders, dramatic lighting, intricate, wild, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, footage"
    },
    {
        "display": "Emma Stone as Belly Dancer",
        "full": "a portrait of emma stone dressed as a belly dancer, arabian night, high quality, fully detailed, 4 k, in focus sharp face with fine details, realistic hand details and anatomy, inspired by belly dancer shakira on youtube, by artgerm and greg rutkowski and alphonse mucha, masterpiece, stunning, artstation"
    },
    {
        "display": "GTA5 Fantasy Agent Poster",
        "full": "GTA5, Cover Art, Grand Theft Auto Poster, James Conn as a secret agent with sunglasses, D&D, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by Artgerm and Greg Rutkowski"
    },
    {
        "display": "Nightmare Monsters Concept Art",
        "full": "Nightmare Monsters 8k resolution concept art Alphonse Mucha child\u2019s drawing airbrush art"
    },
    {
        "display": "Cyborg Lana Del Rey portrait",
        "full": "portrait of lana del rey as a cyborg. intricate abstract. intricate artwork. by tooth wu, wlop, beeple, dan mumford. octane render, trending on artstation, greg rutkowski very coherent symmetrical artwork. cinematic, hyper realism, high detail, octane render, 8 k, iridescent accents"
    },
    {
        "display": "Floating molecules and icosahedron.",
        "full": "a painting by ralph mcquarrie of floating molecules and icosahedron with stars, clouds, and rainbows in the background, trending on artstation, masterpiece, incredible details"
    },
    {
        "display": "Retrofuturistic assassin portrait",
        "full": "american portrait of one retrofuturistic assassin surrounded by advanced brutalist architecture. highly detailed science fiction, rich colors, high contrast, gloomy atmosphere, dark background. trending on artstation an ultrafine hyperdetailed colorfull illustration by tsutomu nihei by emil melmoth, gustave dore, craig mullins, yoji shinkawa, luis royo, zaha hadid, pete morbacher, female, feminine, art deco, new baroque, intricate linework"
    },
    {
        "display": "Cyberpunk Gina Carano Fight",
        "full": "beautiful digital painting of gina carano with one robotic cybernetic metallic grey arm, fighting pose, cyberpunk, highly detailed, hyperrealism, concept art, 8 k"
    },
    {
        "display": "Turquoise energy tiger design.",
        "full": "Ghostly tiger made out of turquoise energy creature character design sheet, Monster Hunter Illustrations art book, Bright sparks, claws, huge sabertooth fangs, Moebius, Greg Rutkowski, Zabrocki, Karlkka, Jayison Devadas, Phuoc Quan, trending on Artstation, 8K, ultra wide angle, zenith view, pincushion lens effect."
    },
    {
        "display": "Cute diamond humanoid robot.",
        "full": "cute humanoid robot, portrait bust, symmetry, faded colors, made of diamond, exotic alien features, cypherpunk background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, cinematic composition, beautiful lighting, hyper detailed, 8 k, unreal engine 5"
    },
    {
        "display": "Samurai in dragon armor.",
        "full": "a full body samurai with dragon armor by karol bak, ayami kojima, amano, concept art, character design, fantasy, 3 d, 8 k resolution"
    },
    {
        "display": "Sadie Sink in hoodie, rain.",
        "full": "sadie sink pulls in knees in big hoodie | rain falls | dark passageway : concept art for a scifi cyberpunk film. by greg rutkowski, john j. park, jason chan, noah bradley, feng zhu, gintas galvanauskas, gustave courbet, rosa bonheur, edward hopper. sharp focus, cinematic atmosphere, detailed and intricate, perfect anatomy"
    },
    {
        "display": "Multi-eyed spirit portrait.",
        "full": "A full body portrait of a spirit with many eyes. In style of Yoji Shinkawa and Hyung-tae Kim, trending on ArtStation, dark fantasy, great composition, concept art, highly detailed."
    },
    {
        "display": "Jean Grey basketball sneakers.",
        "full": "basketball sneakers concept of jean grey, trending on artstation, smooth, sharp focus"
    },
    {
        "display": "Surreal hyper-detailed architecture.",
        "full": "a hyper - detailed 3 d render like a oil painting of building a worldview, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation hd, by greg rutkowski, bruce pennington, valentina remenar and asher duran,"
    },
    {
        "display": "Magical monster girl portrait",
        "full": "A full shot of a cute magical monster girl wearing a dress made of opals and tentacles. Subsurface Scattering. Caustics. Prismatic light. defined facial features, symmetrical facial features. Opalescent surface. Soft Lighting. beautiful lighting. By Giger and Ruan Jia and Artgerm and WLOP and William-Adolphe Bouguereau and Loish and Lisa Frank.  trending on artstation, featured on pixiv, award winning, sharp, details, intricate details, realistic, Hyper-detailed, HD, HDR, 4K, 8K."
    },
    {
        "display": "Post-apocalyptic police station.",
        "full": "a sharp game scene concept with a environment of a post - apocalyptic post apocalyptic police station a blue sky, building, avenue, urban architecture, apocalyptic architecture, paved roads, by nathan walsh trending on artstation, photorealistic, wild vegetation, human silhouettes, cyberpunk, environment artist, dystopian, science fiction"
    },
    {
        "display": "Furry crocodile-wolf hybrid.",
        "full": "Fur covered crocodile with a wolve's head, concept art, photoshop artwork, highly detailed"
    },
    {
        "display": "Cinematic effective altruism art",
        "full": "effective altruism, highly detailed, excellent composition, cinematic concept art, dramatic lighting, trending on artstation"
    },
    {
        "display": "Utopian city under dome.",
        "full": "a utopian city protected by a huge dome with a huge crack, mixed media, concept art, digital painting, trending on artstation, highly detailed, epic composition, 8 k uhd"
    },
    {
        "display": "Ornate treasure chest island",
        "full": "island inside of a treasure chest with ornate details, unreal engine, ultradetailed, trending on artstation, devianart, cgsociety, amazing details"
    },
    {
        "display": "Beagles in dreamscape.",
        "full": "Beautiful beagles playing  \u201d Beautiful Dreamscape, Digital art, concept art, detailed, lovely colors, Art station,3-D 4K, beautiful background, matte painting, ,"
    },
    {
        "display": "Portrait of telemarketer.",
        "full": "portrait of a beautiful female telemarketer wearing a headset, hot bank, cloud, fume, smoke, gauze, haze, mist rising from head, Artgerm, Donato Giancola, Joseph Christian Leyendecker"
    },
    {
        "display": "Gloomy Lovecraftian stone circle.",
        "full": "ancient stone circle lovecraft cult, gloomy and foggy atmosphere, octane render, rembrandt, cgsociety, artstation trending, horror scene, highly detailded"
    },
    {
        "display": "Surreal anatomical deformities.",
        "full": "bizaro bizzzarrrooo quinn, bizzarrooo, dont you mean bizzzarrroo stormy, anatomical deformities, plus the other stuff ya know exploding hearts, swimming souls, the forgotten truth we already know, in the style of killian eng kawase hasui, slightly in the style of fenghua zhong and noah bradley, 8 k 3 d render, artstation trending, 8 k, octane render, photorealistic, volumetric lighting caustics, surreal"
    },
    {
        "display": "Anime-style baroque illustration",
        "full": "baroque oil painting japanese light novel cover full body illustration lotr fate anime key visual action shot | young girl black military uniform crusader maid with long flowing brown hair | symmetrical fine detail delicate features quiet gaze perfect face | gapmoe kuudere moody lighting bokeh | trending pixiv fanbox | by greg rutkowski makoto shinkai takashi takeuchi studio ghibli"
    },
    {
        "display": "Attractive young woman portrait.",
        "full": "mister freeze as an attractive young smiling woman played by milla jovovich wearing a mushroom crown and heavy armoured wedding dress, face portrait, hd shot, digital portrait, elegant, beautiful, fantasy art, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Megan Fox Demon Portrait",
        "full": "portrait of megan fox as demon, batwings, hell, intricate, headshot, highly detailed, digital painting, artstation, concept art, sharp focus, cinematic lighting, illustration, art by artgerm and greg rutkowski, alphonse mucha, cgsociety"
    },
    {
        "display": "Eldritch black god king.",
        "full": "a striking portrait of an eldritch black god king with black antlers and a burning horned crown on his head by moebius and hr gigerr and beksinski, trending on artstation, digital art, 4 k resolution, detailed, high quality, sharp focus, hq artwork, insane detail, cinematic, volumetric lighting, character concept art, fine details, clear face"
    },
    {
        "display": "Cyberpunk Seraphim Tarot Card",
        "full": "a beautiful tarot card artwork of a cyberpunk seraphim in nature, backlit, highly detailed, golden hour, digital painting, by ina wong and justin gerard and dan mumford and artgerm, vivid colors, masterpiece, detailed shading, 8 k resolution, intricate, smooth"
    },
    {
        "display": "Young woman in garden.",
        "full": "portrait of a cute beautiful young woman, with long turquoise hair, big cute eyes, and a blue dress, realistic shaded perfect face, fine details, in a garden full of flowers and Butterflies, intricate jewelry, genshin impact, realistic anime, backlit, volumetric lighting, strong rim light, subsurface scattering, sharp focus, excellent composition, octane render, trending on artstation, 8k"
    },
    {
        "display": "Cute cat girl portrait",
        "full": "nekopara fantastically detailed shining reflecting eyes modern anime style art cute vibrant detailed ears cat girl neko dress portrait  shinkai makoto Studio ghibli kyoto animation hideaki anno Sakimichan Stanley Artgerm Lau Rossdraws James Jean Marc Simonetti elegant highly detailed digital painting artstation pixiv"
    },
    {
        "display": "Elegant young woman portrait.",
        "full": "high angle photo of a gorgeous young woman in the style of stefan kostic, realistic, sharp focus, 8 k high definition, insanely detailed, intricate, elegant, art by stanley lau and artgerm"
    },
    {
        "display": "Cute red panda officer",
        "full": "cute star trek officer red panda, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "Cyborg court jester portrait.",
        "full": "impressive electric cinematic fine portrait photo of a court jester!! cyborg implant shattered crystal shard volumetric dynamic fluid simulation lighting impressive masterpiece hyper ultra detailed intricate sharp focus 8 k realistic illustration canon eos r 3 fujifilm x - t 3 0 sony alpha, by james gurney tooth wu artgerm colorful!!!, trending on artstation behance cgsociety stunning closeup macro"
    },
    {
        "display": "Surreal graffiti-covered statue",
        "full": "surreal greek statue covered with graffiti, surreal abandoned buildings, dream-like heavy atmosphere, baroque painting, beautiful detailed intricate insanely detailed octane render trending on Artstation, 8K artistic photography, photorealistic, volumetric cinematic light, chiaroscuro, Raphael, Caravaggio, Beksinski, Giger"
    },
    {
        "display": "Beagle surfing waves.",
        "full": "beagle on a surfboard, surfing waves of an ocean, digital art, octane render, imax, trending on artstation, dark mood"
    },
    {
        "display": "GTA 5-style Nier Automata illustration",
        "full": "illustration gta 5 artwork of 2 b nier automata, in the style of gta cover art, by stephen bliss, trending on artstation, pixiv, 8 k stephen bliss, unreal engine, greg rutkowski, rhads, beeple, ilya kuvshinov, rossdraws, tom bagshaw, alphonse mucha, global illumination, god rays, detailed and intricate environment"
    },
    {
        "display": "3D Uncle Scrooge Render",
        "full": "Uncle Scrooge Ducktales, 3d render, octane, digital art, trending on artstation"
    },
    {
        "display": "Bionicle surfing on lava",
        "full": "bionicle, volcanic lava surfing, evocative, mystical night, sharp focus, very very very very detailed, award winning, masterpiece digital painting by greg rutkowski, alex grey, marc adamus, beautiful dramatic lighting, artstation, 4 k wallpaper, style by peter deligdisch, peterdraws"
    },
    {
        "display": "Cyberpunk train interior design",
        "full": "cyberpunk train wagon interior, design sheet, concept design, contrast, hot toys, kim jung gi, greg rutkowski, zabrocki, karlkka, jayison devadas, trending on artstation, 8 k, ultra wide angle, pincushion lens effect"
    },
    {
        "display": "Vibrant Nietzsche Portrait",
        "full": "Portrait of Friedrich Nietzsche with a chiseled Jawline and serious Look , in the Style of Artgerm and Ross Draws and Mike Mignola and Atey Ghailan, rimlight, vibrant colors, hard shadows, colorful, plain background, trending on artstation"
    },
    {
        "display": "Aggressive smiling fantasy Messi",
        "full": "portrait of aggressive smiling messi, d & d, muscular! fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Satan pop art mural",
        "full": "Wall mural portrait of Satan, urban art, pop art, artgerm, by  Roy Lichtenstein"
    },
    {
        "display": "Alien goddess portrait.",
        "full": "a highly detailed 4 k portrait render of an alien goddess bella hadid in iris van herpen dress schiaparelli armor in diamonds and lots of jewelry in style of alphonse mucha trending on artstation made in unreal engine 4"
    },
    {
        "display": "Ominous glowing blue-eyed angel.",
        "full": "Her huge ominous glowing blue eyes staring into my soul , perfect eyes, soft pale white skin, intricate stunning highly detailed, agostino arrivabene, artgerm, twisted dark lucid dream, 8k portrait render, raven angel wings, swirling smoke , beautiful lighting, dark fantasy art, cgsociety"
    },
    {
        "display": "Warrior god king painting.",
        "full": "highly detailed painting of a warrior god king by foster trending on artstation"
    },
    {
        "display": "Ultraman Mecha as Animaniacs",
        "full": "Ultraman Mecha exposed to the symbiote and became Animaniacs character. Create by Gustave Courbet,high detailed,fine art,trending on Artstation, smooth draw,Sharp focus."
    },
    {
        "display": "Cinematic concept art, emotional.",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, blood and sad"
    },
    {
        "display": "Vivian Leigh Portrait, Night",
        "full": "closeup portrait of a young vivian leigh, dramatic lighting, city background, night, moon, chiaroscuro, high detail, painted by greg rutkowski, painted by igor kieryluk, painted by bobby chiu, trending on artstation"
    },
    {
        "display": "Shadow prince illustration",
        "full": "shadow prince details sharp focus illustration by jordan grimmer, trending artstation pixiv dig"
    },
    {
        "display": "Dystopian abstract horror art.",
        "full": "the end of time, intricate, abstract, magical, wacky, dreadful, horror, bizzare, dystopian, concept art by Tooth Wu, by WLOP, by Beeple, by Dan Mumford, by Greg Rutkowski, Octane Render, digital painting highly detailed, trending on Artstation"
    },
    {
        "display": "Cyborg girl portrait.",
        "full": "a white haired girl wearing earmuffs with cyborg eyes, digital art, 8 k resolution, unreal engine, highly detailed, pretty face, very beautiful face, very detailed eyes, photorealistic by wlop, greg rutkowski"
    },
    {
        "display": "Dramatic industrial cityscape.",
        "full": "a film still from elysium by ian mcque, an anti - crime unti descends upon an alley on an industrial planet city, vibrant, 5 0 mm lens, video game character and environment design, behance hd, studio, evening, dramatic lighting, misty and raining, cinematic, global illumination, trending on artstation, bloom"
    },
    {
        "display": "Hyperrealistic Peter Griffin portrait",
        "full": "full body portrait of Peter Griffin , very detailed eyes, hyperrealistic, beautiful and symmetrical face, very detailed painting by Claude Monet and Alphonse Mucha, ornate, trending on artstation, extremely high detail, incredibly intricate"
    },
    {
        "display": "Albino wolf fursona in coffee shop",
        "full": "anthropomorphic albino wolf fursona wearing casual stylish clothes hoodie at a hipster coffee shop, highly detailed, artgerm style, artstation, soft light, sharp focus, illustration, character design, concept art"
    },
    {
        "display": "Detailed full-body portrait",
        "full": "a portrait of revy from black lagoon manga, entire full body, symmetrical eyes, symmetrical face, art by lois van baarle and loish and ross tran and rossdraws and sam yang and samdoesarts and artgerm, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Pixie woman portrait illustration",
        "full": "3 / 4 view of a portrait of pixie woman with bat wings, confident pose, pixie, genshin impact,, intricate, elegant, sharp focus, illustration, highly detailed, concept art, matte, trending on artstation, anime, art by wlop and artgerm and greg rutkowski, strong brush stroke, sharp focus, illustration, morandi color scheme, art station, by ilya kuvshinov h 6 4 0"
    },
    {
        "display": "Dark witch in forest",
        "full": "portrait dark witch, adventurer outfit large cloak, fantasy forest landscape, dragon scales, fantasy magic, undercut hairstyle, short purple black fade hair, dark light night, intricate, elegant, sharp focus, illustration, highly detailed, digital painting, concept art, matte, art by wlop and artgerm and greg rutkowski and alphonse mucha, masterpiece"
    },
    {
        "display": "Fantasy D&D JoJo sketch",
        "full": "paper sketch of jojo's bizarre adventure, D&D, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and magali villeneuve"
    },
    {
        "display": "Overgrown robot body.",
        "full": "beautiful overgrown foliage taking over an ( beautiful abandoned human - shaped robot body laying on the ground ), close - up, 3 5 mm, biopunk, bokeh, beautiful, lens flare, emotional, sweet, flowers, detailed, picture, trending on artstation, award - winning, shiny, golden, angle view, octane render"
    },
    {
        "display": "Elegant Yoshi portrait painting",
        "full": "a portrait of yoshi, fantasy, sharp focus, intricate, elegant, digital painting, artstation, matte, highly detailed, concept art, illustration, ambient lighting, art by ilya kuvshinov, artgerm, alphonse mucha, and greg rutkowski"
    },
    {
        "display": "Elegant vampire queen ascending",
        "full": "beautiful vampire female queen, full body shot, ascending form the sky, hands reaching for her, wide angle shot, aerial view, lost souls, horror, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Heavenly nature alley scene.",
        "full": "a professional photographic view picture of a heavenly nature inspired alley ,photographic filter unreal engine 5 realistic hyperdetailed 8k ultradetail cinematic concept art volumetric lighting, fantasy artwork, very beautiful scenery, very realistic painting effect, hd, hdr, cinematic 4k wallpaper, 8k, ultra detailed, high resolution, artstation trending on artstation in the style of Albert Dros glowing rich colors powerful imagery nasa footage drone footage drone photography"
    },
    {
        "display": "Putin as Simpsons character",
        "full": "vladimir putin as a simpsons character, 2 d portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Tifa Lockhart headshot art.",
        "full": "kerli koiv as tifa lockhart, character headshot concept art, sharp, digital matte painting, art by artgerm, greg rutkowski, wlop, dramatic lighting, trending on artstation"
    },
    {
        "display": "Cyberpunk anime police officer",
        "full": "anime key visual of elizabeth olsen police officer, cyberpunk, futuristic, stunning features, very very perfect face!!, high detailed, digital painting, artstation, smooth, soft focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "\"Dirty Sand Trooper Portrait\"",
        "full": "star wars film still, closeup portrait of a dirty sand trooper bounty hunter wearing a hooded cloak, standing in a cantina, dust, scum and villainy, rim light, embers, hyper detailed, smooth, high contrast, concept art, gta v, volumetric lighting, ralph mcquarrie, doug chiang, ryan church"
    },
    {
        "display": "Anthropomorphic mantis superhero",
        "full": "anthropomorphic mantis, guardians of the galaxy, au naturel, hyper detailed, digital art, trending in artstation, cinematic lighting, studio quality, smooth render, unreal engine 5 rendered, octane rendered, art style by klimt and nixeu and ian sprigger and wlop and krenz cushart"
    },
    {
        "display": "Intricate cosmic portrait.",
        "full": "space void face, oil on canvas, intricate, portrait, 8k highly professionally detailed, HDR, CGsociety, illustration painting by Mandy Jurgens and Ma\u0142gorzata Kmiec and Dang My Linh and Lulu Chen and Alexis Franklin and Filip Hodas and Pascal Blanch\u00e9 and Bastien Lecouffe Deharme, detailed intricate ink illustration, heavenly atmosphere, detailed illustration, hd, 4k, digital art, overdetailed art, concept art, complementing colors, trending on artstation, Cgstudio, the most beautiful image ever created, dramatic, subtle details, illustration painting by alphonse mucha and frank frazetta daarken, vibrant colors, 8K, style by Wes Anderson, award winning artwork, high quality printing, fine art, gold elements, intricate, epic lighting, very very very very beautiful scenery, 8k resolution, digital painting, sharp focus, professional art, atmospheric environment, art by artgerm and greg rutkowski, by simon st\u00e5lenhag, rendered by Beeple, by Makoto Shinkai, syd meade, 8k ultra hd, artstationHD, 3d render, hyper detailed, elegant, by craig mullins and marc simonetti, Ross Tran and WLOP, by Andrew Wyeth and Gerald Brom, John singer Sargent and James gurney"
    },
    {
        "display": "Albino lion in armor",
        "full": "commission of a fit anthro albino lion wearing a diamond victorian armor, science fiction industrial hard science concept art, 8K render octane high definition cgsociety"
    },
    {
        "display": "Fantasy boy in sweater",
        "full": "boy with short brown hair covering eyes, light green sweater, brown background, digital art, fantasy painting, dungeons and dragons trending on artstation"
    },
    {
        "display": "Mystical cosmic goth lion.",
        "full": "mystical cosmic goth lion viking messenger queen, 4 k digital illustration, fractals, by artgerm, wlop, james jean, andrei riabovitchev, marc simonetti, yoshitaka amano, artstation, cgsociety"
    },
    {
        "display": "Elegant teen girl illustration.",
        "full": "teen girl, lavender hair, gorgeous, amazing, elegant, intricate, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by Ross tran"
    },
    {
        "display": "Flaming phoenix over medieval city.",
        "full": "flaming phoenix flying overhead on top of a medieval city, concept art, highly detailed, dark colour, tense atmosphere, 8 k"
    },
    {
        "display": "Symmetrical Yoda portrait",
        "full": "symmetry!! portrait of yoda, sci - fi, tech wear, blue and yellow glowing lights!! intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Young fairy prince illustration",
        "full": "young fairy prince, red hair, white eyes, highly detailed, d & d, fantasy, highly detailed, digital painting, trending on artstation, concept art, sharp focus, illustration, art by artgerm and greg rutkowski and fuji choko and viktoria gavrilenko and hoang lap"
    },
    {
        "display": "Young Jos\u00e9 Mujica Portrait",
        "full": "a classical portrait of a young jose mujica, dramatic light, city background, sunset, high detail, sharp, painted by greg rutkowski, painted by igor kieryluk, painted by bobby chiu, digital art, trending on artstation"
    },
    {
        "display": "Cyberpunk anime portrait",
        "full": "\u201dultra realistic, Half-body-Portrait of a beautiful Female anime character, modern, cyberpunk intricate, elegant, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, 8k resolution, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and eiichiro oda\u201d"
    },
    {
        "display": "Afghan warrior by Kaaba",
        "full": "\u00ef\u00bf\u00bd full body, afghan warrior, an afghan male type, standing in - front of the kaa \u00ef\u00bf\u00bd ba, highly intricate detailed, light and shadow effects, intricate, highly detailed, digital painting, art station, concept art, smooth, sharp focus, illustration, advanced digital art, atmospheric lighting, detailed face, 8 k, hq \u00ef\u00bf\u00bd"
    },
    {
        "display": "Male elvish sorcerer casting fireball.",
        "full": "a male elvish sorcerer casting a green fireball | | pencil sketch, realistic shaded, fine details, realistic shaded lighting poster by greg rutkowski, magali villeneuve, artgerm, jeremy lipkin and michael garmash and rob rey"
    },
    {
        "display": "Futuristic Willy Wonka steam train.",
        "full": "a grand intricate futuristic black steam train, the train is themed after willy wonka and the chocolate factory, post - apocalyptic ice landscape in snowstorm, concept art, artstation, highly detailed, digital art"
    },
    {
        "display": "Underwater portrait of tattooed princess.",
        "full": "underwater photography full body portrait of beautiful nubian ancestral tribal tattooed young hunter pincess, swimming underwater low angle realistic 4 k high quality photography by terry o'neill intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha, 8 k"
    },
    {
        "display": "Elegant portrait of Lexi Rivera",
        "full": "beautiful elegant full body portrait of lexi rivera with color streaks in hair and wearing a sparkling cherry color one piece swimsuit, elegant hat and iridescent white silk cape, friendly seductive smile, felicia day, wlop, artgerm, artstation, backlit, marble background"
    },
    {
        "display": "Male demon warlock character.",
        "full": "man male demon, full body white purple cloak, warlock, character concept art, costume design, black eyes, white horns, trending on artstation, Artgerm , WLOP"
    },
    {
        "display": "GTA 5 Fantasy Art",
        "full": "gta 5, cover art, grand theft auto poster, kakyoin doing a jojo pose, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, hearthstone, art by artgerm and greg rutkowski"
    },
    {
        "display": "Muscular Indian goddess portrait",
        "full": "wide portrait painting of a oiled muscular bloodied indian goddess, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic portrait of Millie Bobby Brown",
        "full": "a highly detailed epic cinematic concept art CG render digital painting artwork: Millie Bobby Brown. By Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong, trending on ArtStation, subtle muted cinematic colors, made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse"
    },
    {
        "display": "\"Child of Light Illustration\"",
        "full": "Isabella, child of light, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, Unreal Engine 5, 8K, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cinematic California portrait",
        "full": "cinematic portrait of a sunny day in california in the year 2 0 0 4, oil on canvas, masterpiece, trending on artstation, featured on pixiv, cinematic composition, dramatic pose, beautiful lighting, sharp details, hyper - detailed, hd, hdr, 4 k, 8 k, art by tim hildebrandt and wayne barlowe and bruce pennington and ruan jia and larry elmore,"
    },
    {
        "display": "Asian female Void Walker",
        "full": "concept art of asian female Void Walker, slavic style dress, corset, full body, Realistic, nebula background, intricate details, colourful, by Jason Chan"
    },
    {
        "display": "Boris Johnson as Terminator.",
        "full": "Boris Johnson as Terminator, Boris Johnson hairstyle, full body realistic portrait, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Angry Mexican woman portrait.",
        "full": "a portrait of a young mexican woman, angry, with larvae crawling on skin, wearing an elaborate black dress, in the style of sergeant, artgerm, and ruan jia, blue backlighting, beautiful, trending on artstation, high detail, intricate texture"
    },
    {
        "display": "Wolf-headed knight illustration",
        "full": "a knight with a wolf head, medieval setting, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyberpunk hero portrait",
        "full": "vibrant portrait of masked cyberpunk hero with Oculus VR headset with an oxygen tank on the art deco streets of the big city, award-winning realistic sci-fi concept art by Jim Burns and Greg Rutkowski, Picasso, Beksinski, masterpiece, complimentary colors, James Gilleard, Bruegel, artstation, Alphonse Mucha, and Yoshitaka Amano"
    },
    {
        "display": "Pandas in tall grass.",
        "full": "family of pandas in the tall grass panoramic, mural, fisheye perspectiveultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski,"
    },
    {
        "display": "Dark witch portrait, moonlit.",
        "full": "portrait of Anna Millerstone as a Dark evil witch, big moon in the background, dramatic lighting, illustration by Albert Bierstadt, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Scarred Doomguy portrait",
        "full": "scarred doomguy played by scarlett johansson, face portrait, hd shot, digital portrait, elegant, beautiful, fantasy art, artstation, comic style, by artgerm, guy denning, jakub rozalski, magali villeneuve and charlie bowater"
    },
    {
        "display": "Unicorn vs. Dragon Battle",
        "full": "unicorn fighting a dragon, beautiful snowy landscape, lightning storm, dramatic lightning, cinematic, establishing shot, extremly high detail, photorealistic, cinematic lighting, epic fight scene, post processed, concept art, artstation, matte painting, style by greg rutkowsky"
    },
    {
        "display": "Epic superhero Captain Spain",
        "full": "superhero captain spain, epic pose, by stanley artgerm, dramatic lighting, highly detailed, incredible quality, trending on artstation"
    },
    {
        "display": "Cyborg girl in neon.",
        "full": "Cute cyborg girl , surrounded by cables , illustrated by Greg Rutkowski and Moebius, 35mm lens, beautiful macro close-up imagery, vibrantly lush neon lighting, beautiful volumetric-lighting-style atmosphere, a dark future atmosphere, intricate, ultra detailed, photorealistic imagery, trending on artstation, 4k, 8k"
    },
    {
        "display": "Elegant Cleopatra Mummy Artwork",
        "full": "sansa angeline jolie gessica chastain mummy cleopatra gold, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha and william - adolphe bouguereau"
    },
    {
        "display": "Stylized Naruto figurine art",
        "full": "naruto figurine, art by gerald brom, greg rutkowski and artgerm and james jean and zdzis\u0142aw beksinski, unreal engine,"
    },
    {
        "display": "Cyberpunk drone operator portrait",
        "full": "portrait painting of cyberpunk vivi from loona as a drone operator, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Fantasy portrait of John Kerry",
        "full": "John Kerry as a fantasy magic man portrait, sci-fi, amber eyes, face, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Bloody skeleton woman portrait.",
        "full": "woman!, black skeleton body!!, covered with blood, alexandra daddario face!!!, long red hair, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine. retro film still, heavy grain, 3 5 mm, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Female character concept art",
        "full": "poster!! beautiful new female character for 7 days to die, character concept art, action pose, illustration, full body armor, steel plating, huge weapon, super powers, athletic, long red hair, symmetry, intricate design, shiny, highly detailed, hd, dramatic lighting, art by artgerm and greg rutkowski"
    },
    {
        "display": "Hybrid cat-eagle painting",
        "full": "painting of hybrid between cat! & eagle, intercrossed animal, mixture animal, by tiffany bozic, by zdzislaw beksinski, cold hue's, warm tone gradient background, concept art, beautiful composition, digital painting,"
    },
    {
        "display": "Fantasy landscape creation",
        "full": "the creation of arda, highly detailed, digital painting, artstation, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Billie Eilish as divine figure",
        "full": "billie eilish Perfectly-centered full body portrait-photograph of a real life god from heaven, lifelike, super highly detailed, professional digital painting, artstation, concept art, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Shy fertile modern wife.",
        "full": "digital character concept art by artgerm, by greg rutkowski, by alphonse mucha. clear portrait of a shy modern wife blessed by god to grow immaculately fertile and perfect!! blonde, in clothes! holy body! light effect. hyper detailed, glowing lights!! intricate, elegant, digital painting, artstation, smooth, sharp focus"
    },
    {
        "display": "Beautiful mermaid illustration.",
        "full": "very beautiful mermaid, artgerm, artstation, 4 k,"
    },
    {
        "display": "Divine goddess princess illustration.",
        "full": "perfectly detailed goddess princess of white roses!! blessed by nature with ever - increasing physical mental perfection, intricate, highly detailed, biblical divine holy perfection!! digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant queen with fiery hair",
        "full": "front shot of a queen with fire hair mastermind character, intricate, elegant, highly detailed, centered, artstation, concept art, smooth, sharp focus, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo ), mugshot, ugly, octane render, nvidia raytracing demo, grainy, muted"
    },
    {
        "display": "Athena in detailed armor",
        "full": "athena, greek goddess, claudia black, art by artgerm and greg rutkowski and magali villeneuve, bronze greek armor, owl crown, d & d, fantasy, intricate, portrait, highly detailed, headshot, digital painting, trending on artstation, concept art, sharp focus, illustration"
    },
    {
        "display": "Steampunk underwater portrait.",
        "full": "lofi underwater bioshock steampunk beach model portrait, Pixar style, by Tristan Eaton Stanley Artgerm and Tom Bagshaw."
    },
    {
        "display": "Mrs. Doubtfire Rogue Fantasy",
        "full": "mrs doubtfire as a rogue, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by greg rutkowski and alphonse mucha"
    },
    {
        "display": "Elegant tea party scene",
        "full": "Portrait of Rosie Huntington-Whitely & a mallard & a pig having tea at the Ritz, real life skin, intricate, elegant, highly detailed, artstation, concept art, smooth, sharp focus, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Microscopic robotic tardigrades.",
        "full": "microscopic tardigrades, magical forest, water bear, robots, electric, furry, soft, concept art, intricate details, highly detailed, photorealistic, disney pixar, octane render, iridescent, anime, 8 k"
    },
    {
        "display": "Cinematic crow portrait.",
        "full": "glowing silver and golden elements, full close-up portrait, crow from shutterstock, book cover, green forest, white moon, red lips, establishing shot, extremly high detail, photo-realistic, cinematic lighting, pen and ink, intricate line drawings, by Yoshitaka Amano, Ruan Jia, Kentaro Miura, Artgerm, post processed, concept art, artstation, matte painting, style by eddie mendoza, raphael lacoste, alex ross"
    },
    {
        "display": "Wizard portrait of Alexis Ohanian",
        "full": "mindblowing portrait of alexis ohanian as a wizard, blue wizard hat, deep focus, symmetrical face, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp, illustration, hearthstone, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Badass winged pixie portrait",
        "full": "3 / 4 view of a portrait of a badass woman with wings, pixie character, confident pose, bright colors, genshin impact, intricate, elegant, sharp focus, illustration, highly detailed, concept art, matte, anime, trending on artstation, photo of asuna from sao, asuna by a - 1 pictures, by greg rutkowski, gil elvgren, earl moran, h 6 4 0"
    },
    {
        "display": "Venom as Batman, photorealistic.",
        "full": "venom as batman, dynamic lighting, photorealistic fantasy concept art, trending on art station, stunning visuals, terrifying, creative, cinematic"
    },
    {
        "display": "Post-apocalyptic fantasy portrait",
        "full": "post apocalyptic abe vigoda, fantasy, d & d, intricate, detailed, by by alphonse mucha, adolfo hohenstein, alice russell glenny, stanley artgerm lau, greg rutkowski, detailed, trending on artstation, trending on artstation, smooth"
    },
    {
        "display": "Sci-fi portrait of Naomi",
        "full": "portrait of a woman by greg rutkowski, naomi nagata from the expanse book series, very tall and slender, highly detailed portrait, scifi, digital painting, artstation, concept art, smooth, sharp foccus ilustration, artstation hq"
    },
    {
        "display": "Elegant African American girl",
        "full": "a Photorealistic dramatic hyperrealistic bright blue eyes, African American elegant girl, black hair, white veil,by WLOP,Artgerm,Greg Rutkowski,Alphonse Mucha, Beautiful dynamic dramatic bright sunset lighting,shadows,cinematic atmosphere,Artstation,concept design art,Octane render,8k"
    },
    {
        "display": "Live Action Naruto Movie",
        "full": "Live Action Naruto Movie octane render 8K hyper detailed 4D ArtStation top trending directed by James Cameron starring Jared Leto 10:9 aspect ratio ultra HD"
    },
    {
        "display": "Eerie ultra-realistic concept art",
        "full": "ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Morgan Freeman as Emperor.",
        "full": "morgan freeman as the warhammer 4 0 k space marine emperor, portrait, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Medieval shop exterior, detailed.",
        "full": "A medieval shop viewed from the outside, texture, intricate, details, highly detailed, masterpiece, architecture, building, trending on artstation, focus, sharp focus, concept art, digital painting, fantasy, sunny, day, midday"
    },
    {
        "display": "Rockabilly Poison Ivy Portrait",
        "full": "joshua middleton, phil noto, norman rockwell, artgerm, 1 9 5 0 s rockabilly emma stone poison ivy dc comics, pompadour, vines, symmetrical eyes, city rooftop"
    },
    {
        "display": "Surreal Aquatic-Cliffs Render",
        "full": "A hyper-detailed 3d render like a Oil painting of the Aquatic-Cliffs of the Great Sand Seal, surrealism!!!!! surreal concept art, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, Artstation HD, by Greg Rutkowski, Chris Tulloch McCabe, Valentina Remenar and Asher Duran,"
    },
    {
        "display": "Anime portrait of rage",
        "full": "portrait of the incarnation of rage, anime fantasy illustration by tomoyuki yamasaki, kyoto studio, madhouse, ufotable, comixwave films, trending on artstation"
    },
    {
        "display": "Furry cyberpunk vortex artwork.",
        "full": "neosvr!!! virtual reality metaverse engine, swirling portal!, a masterpiece matte painting inspired by karol bak, collaborative, warm, welcoming, trending on artstation, furry, anime, robot, cyberpunk"
    },
    {
        "display": "Two-faced goddess portrait",
        "full": "goddess with two faces, one face sad, one face happy, long hair, white hair, ethereal, intricate, elegant, georgeus, highly detailed, sharp focus, digital painting, by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Fractal portrait of Kim Petras.",
        "full": "instrument of life, fractal silk, fractal crystal, beautiful gorgeous pretty portrait of kim petras, delicate, beautifully lit, muted colors, highly detailed, artstation trending, blonde hair, fantasy art by craig mullins, by thomas kinkade, by ruan jia, by wlop,"
    },
    {
        "display": "Hybrid owl-chameleon-monkey painting",
        "full": "painting of hybrid between owl & chameleon! & monkey, intercrossed animal, by zdzislaw beksinski, by dariusz zawadzki, by tiffany bozic, by john jude palencar, cold hue's, warm tone gradient background, concept art, single object scene, beautiful composition, digital painting"
    },
    {
        "display": "Ultra-detailed goo artwork.",
        "full": "huge, 8 k, trending on artstation, rule of thirds, ultra detailed, goo."
    },
    {
        "display": "Eerie mansion with glowing windows.",
        "full": "a haunting matte painting of a disturbing dilapidated scary mansion with eerie glowing windows at the edge of world, concept art, illustration, trending on artstation, vibrant colors, 4 k wallpaper"
    },
    {
        "display": "Windy ocean selfie.",
        "full": "selfie!!!!! of a woman with gorgeous hair flowing in the wind, in the middle of the ocean!!!!!, first - person view, fisheye!!!!! lens!!!!!, photorealistic image, trending on artstation, 4 k, 8 k"
    },
    {
        "display": "Anime girl at sunset.",
        "full": "Asuna by Zeronis and Avetetsuya Studios and Mitsu Art, flowing orange hair, symmetrical face, anime, elegant, sunset, trending on artstation, artstationHD, artstationHQ, patreon, 4k, 8k"
    },
    {
        "display": "Cynical blue witch illustration.",
        "full": "full body picture of a blue witch with exoskeleton looking at the camera, cynical, bored, wanna play with you, beautiful and aesthetic, intricate, unreal engine, messy hair, highly detailed, detailed face, smooth, sharp focus, chiaroscuro, manga illustration, artgerm, greg rutkowski, alphonse mucha, young adult light novel cover art"
    },
    {
        "display": "Transhuman fox fursona, cyberpunk.",
        "full": "a transhuman fox fursona with a fluffy tail in a cyberpunk city, trending on artstation, by kawacy, neon backlighting, furry art"
    },
    {
        "display": "Post-apocalyptic biker scene",
        "full": "a ultradetailed beautiful panting of post apocalyptic biker with helmet in front of crashed airplane burning, by ilya kuvshinov, greg rutkowski and makoto shinkai, trending on artstation"
    },
    {
        "display": "Cyberpunk mercenary portrait",
        "full": "hugh jackmam @ as a battle hardened cyberpunk mercenary!! intricate elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by ( ( ( artgerm ) ) ) and greg rutkowski! and ( ( alphonse mucha ) ), heavily influenced by frank frazetta and boris vallejo, sword and sorcery"
    },
    {
        "display": "Rusty shipwreck in forest",
        "full": "rusty warship dreadnought shipwreck in a lush forest, volumetric lighting, god rays, , global illumination, puddles of water, sci-fi, ivy, moss, trending on artstation, concept art by Greg Rutkowski and Sebastian Luca ad Moebius"
    },
    {
        "display": "Rugged swordsman portrait.",
        "full": "portrait of a young, ruggedly handsome swordsman, soft hair, muscular, half body, leather, hairy, d & d, fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Ghostly Japanese fire elemental",
        "full": "female japanese fire elemental, ghostly form, transparent, d & d, highly detailed, digital painting, artstation, concept art, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Futuristic brutalist base concept.",
        "full": "concept art by jama jurabaev, cinematic shot, trending on artstation, high quality, brush stroke, big brutalist futuristic base"
    },
    {
        "display": "Art Nouveau Havanese Dog",
        "full": "beautiful detailed picture of a havanese dog, radiant light, art nouveau, intricate, elegant, highly detailed, my rendition, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Realistic Asian Death Goddess",
        "full": "full body character design of realistic asian Sumerian Death Goddess ivory skin runic icons + mystical symbols,  with small bleached bones covering vest and flowing electricity and smoke , fantasy, intricate, elegant, highly detailed, digital painting, artstation, concept art,  sharp focus, photoreal, ghostbusters , peter mordenbacher,Mike Winkelmann, photo real, ultra realistic, intricate, epic lighting, 8k resolution, unreal engine 5, ultraviolet colors"
    },
    {
        "display": "Exotic robot torso artwork",
        "full": "robot torso, faded colors, exotic alien features, cyber background, tim hildebrandt, wayne barlowe, bruce pennington, donato giancola, larry elmore, masterpiece, trending on artstation, featured on pixiv, zack snyder, cinematic composition, beautiful lighting, sharp, details, hyper - detailed, hd, hdr, 4 k, 8 k"
    },
    {
        "display": "Ultra-realistic couple portrait",
        "full": "portrait painting of dark muscular indian couple kissing, sari, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Baroque anime battle scene",
        "full": "baroque oil painting of anime key visual environment concept art of anime rail canon artillery firing over castle walls, smoke debris, grimdark steampunk fantasy, battlefield, trending on artstation, brush strokes, oil on canvas, style of makoto shinkai and greg rutkowski and studio ghibli"
    },
    {
        "display": "Haunted asylum horror art",
        "full": "a haunted asylum, horror art by junji ito, digital art, highly detailed, intricate, sharp focus, trending on artstation hq, deviantart, pinterest, unreal engine 5, 4 k uhd image"
    },
    {
        "display": "Surreal cyborg portrait.",
        "full": "surreal portrait of a man by Greg Rutkowski and H.R Giger, cyborg of indeterminate age, symmetrical, bald, haunting appearance, pale as marble, biomechanical and intricate, empty and uncany expression, cosmic void background, frightening, fascinating, highly detailed portrait, digital painting, artstation, concept art, smooth, sharp foccus ilustration, Artstation HQ."
    },
    {
        "display": "Zombie Jesus artwork",
        "full": "zombie jesus religious painting, tristan eaton, victo ngai, artgerm, rhads, ross draws"
    },
    {
        "display": "Creepy bioluminescent monster portrait",
        "full": "portrait of a creepy bioluminescent monster, highly detailed, digital painting, cinematic, hyperrealism, dark retrowave, art by simon stalenhag and stanley lau and artgerm and magali villeneuve and alphonse mucha, artstation hd, octane render, cgsociety"
    },
    {
        "display": "Ariana Grande in fantasy armor.",
        "full": "Ariana Grande wearing metal and leather fantasy battle armor with a red cloak by Ilya Kushvikov, symmetrical face concept art, octane render unreal engine meta humans, artstation"
    },
    {
        "display": "Handsome sorcerer in lab.",
        "full": "a handsome bearded caucasian male sorcerer with brown hair, he is in a alchemist lab filled with beakers and equipment, casting a spell from a open book, neutral pose, digital art, concept art, epic composition, 4 k, light rays, super coherent, by dave melvin and greg rutkowski"
    },
    {
        "display": "Futuristic Chinese princess portrait",
        "full": "powerful chinese princess, full body, neon lights, high fashion, futurism, aerodynamic, feminine, oriental, intricate, slick, highly detailed, digital painting, vogue, concept art, smooth, sharp focus, hd, art by syd mead and john berkey and annie leibovitz"
    },
    {
        "display": "Feminine boy portrait, digital art.",
        "full": "portrait of a feminine boy with curly shoulder length dirty blond hair, wearing a white t shirt and black work apron, dramatic lighting, illustration by Greg rutkowski, yoji shinkawa, 4k, digital art, concept art, trending on artstation"
    },
    {
        "display": "Margot Robbie in crochet bikini",
        "full": "margot robbie portrait posing in a magical crochet bikini in front of atlantis, highly detailed realistic face, highly detailed eyes, fantasy art, in the style of greg rutkowski, illustration, epic, fantasy, intricate, hyper detailed, artstation, concept art, smooth, sharp focus, ray tracing, vibrant, margot robbie face"
    },
    {
        "display": "Christopher Titus as Guile",
        "full": "christopher titus as guile from street fighter, army uniform, ultra realistic, concept art, intricate details, eerie, highly detailed, photorealistic, octane render, 8 k, unreal engine. art by artgerm and greg rutkowski and magali villeneuve and alphonse mucha"
    },
    {
        "display": "Sad elf in forest",
        "full": "jessica alba girl as fragile sad elf in forest, fit, warhammer, fame of thrones, lord of the rings, sweaty, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, unreal engine 5, 8 k, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Lava-filled mountain hole.",
        "full": "a hole in the ground with lava in a mountainous environment by shaddy safadi, artstation, concept art, cinematographic, epic lighting, filmic, 8 k, award winning,"
    },
    {
        "display": "\"Whimsical Portrait: Tom Cruise Monkey\"",
        "full": "africa, beautiful portrait of a very funny actor tom cruise playing with ginger red big monkey, face like monkey, emma watson actress blended monkey face, ape, powerful, dramatic lighting, intricate, wild, highly detailed, digital painting, cinematic, artstation, concept art, sharp edges and focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Cyborg pharaoh in metaverse.",
        "full": "whirlwind of souls inside metaverse, half body, glowin eyes, tiara with sapphire, pharaoh, android, cyborg, forest, mushrooms, antiques, cyberpunk face, by loish, d & d, fantasy, intricate, elegant, highly detailed, colorful, vivid color, digital painting, artstation, concept art, art by artgerm and greg rutkowski and alphonse mucha and ruan jia"
    },
    {
        "display": "Ginger girl portrait, hyper-realistic.",
        "full": "photograph of a beautiful ginger girl with a small amount of freckles and one blue eye, exquisite detail, hyper realism, ornate, voluptuous, exquisite detail, masterpiece, cute face, princess, 4k, art by Donato Giancola and Bayard Wu and WLOP and Artgerm"
    },
    {
        "display": "Scorpion in Hell Portrait",
        "full": "portrait of hanzo hasashi scorpion from mortal kombat in the gates of hell, full body shot,, walking towards the camera, camera pulled back far, highly detailed dramatic lighting, artstation, atmospheric perspective, artgerm, mk ninja, epic ninja suit, intense contrast, 3 light sources, by lee bermejo, alphonse mucha and greg rutkowski"
    },
    {
        "display": "Cyberpunk machine portrait",
        "full": "portrait of a cyberpunk machine, machine face, upper half portrait, decorated with chinese opera motifs, asian, fine china, wuxia, traditional chinese art, intricate, elegant, highly detailed, symmetry, headpiece, digital painting, artstation concept art smooth sharp focus, illustration, art by artgerm and greg rutkowski alphonse mucha 8 k"
    },
    {
        "display": "Cyberpunk Bionicle Concept Art",
        "full": "A new generation of bionicle set in a cyberpunk world, concept art, digital painting, artstation, by Ross Tran and by Greg Rutkowski"
    },
    {
        "display": "Magical cyberpunk forest scene.",
        "full": "magical forest cyberpunk, music, girl with blond long hair back view, elves, lord of the rings style, ultra detailed, trending on artstation, concept art, octane render, unreal engine,"
    },
    {
        "display": "Cute alien fish underwater",
        "full": "photo of an extremely cute alien fish swimming an alien habitable underwater planet, coral reefs, dream-like atmosphere, water, plants, peaceful, serenity, calm ocean, tansparent water, reefs, fish, coral, inner peace, awareness, silence, nature, evolution, wide angle, super highly detailed, professional digital painting, artstation, concept art, smooth, sharp focus, no blur, no dof, extreme illustration, Unreal Engine 5, Photorealism, HD quality, 8k resolution, cinema 4d, 3D, beautiful, cinematic, art by artgerm and greg rutkowski and alphonse mucha and loish and WLOP"
    },
    {
        "display": "Vibrant Tengu Mask Art",
        "full": "tengu mask, extremly detailed digital painting, vibrant colors, in the style of tomasz alen kopera and fenghua zhong and peter mohrbacher, mystical colors, rim light, beautiful lighting, 8 k, stunning scene, raytracing, octane, trending on artstation"
    },
    {
        "display": "Donald Trump in onesie",
        "full": "donald trump wearing a onesie highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, art by krenz cushart and artem demura and alphonse mucha"
    },
    {
        "display": "Neon tornado in space.",
        "full": "a tornado made of neon lights sucking in the last of the stars in the universe, astrophotography, art nouveau, decorative background, digital art, trending on artstation"
    },
    {
        "display": "Angry Goddess Hera Portrait",
        "full": "The Godess Hera looking angry, portrait, highly detailed, digital painting, artstation, concept art, smooth, detailed armor, sharp focus, beautiful face, symmetric face, cinematic, videogame cover art, illustration, art by Artgerm and Greg Rutkowski and Alphonse Mucha"
    },
    {
        "display": "Beautiful elf queen portrait",
        "full": "photo of a very beautiful!! elf queen, highly detailed, 4 k, hdr, smooth, sharp focus, high resolution, award - winning photo"
    },
    {
        "display": "Cthulhu and sea creatures",
        "full": "close-up macro portrait of a Cthulhu and other fantastical sea creatures, epic angle and pose, ribcage bones symmetrical artwork, 3d with depth of field, blurred background, cybernetic jellyfish female face skull phoenix bird, translucent, nautilus, energy flows of water and fire. a highly detailed epic cinematic concept art CG render. made in Maya, Blender and Photoshop, octane render, excellent composition, cinematic dystopian brutalist atmosphere, dynamic dramatic cinematic lighting, aesthetic, very inspirational, arthouse. Greg Rutkowski, Ilya Kuvshinov, WLOP, Stanley Artgerm Lau, Ruan Jia and Fenghua Zhong"
    },
    {
        "display": "Detailed fantasy D&D character",
        "full": "dorthin dnd, ultra detailed fantasy, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5"
    },
    {
        "display": "Stoner bacon in suit",
        "full": "cartoon concept art of a stoner slice of bacon wearing a black suit with a red tie"
    },
    {
        "display": "Cinematic hyper-realistic Atlanta",
        "full": "the inside of magic city in atlanta, trending on artstation, depth field, unreal engine, cinematic, hyper realism, high detail, octane cinema 4 d render, a 2 4 cinematography, 8 k"
    },
    {
        "display": "Silver-haired woman in onesie",
        "full": "a highly detailed illustration of beautiful silver haired woman wearing purple onesie floating on pillow, dramatic floating pose, sleepy expression, intricate, elegant, highly detailed, centered, digital painting, artstation, concept art, smooth, sharp focus, league of legends concept art, wlop"
    },
    {
        "display": "Brutalist streetscape at night.",
        "full": "streetscape, brutalist buildings, metal, concrete, wet streets, neon lights, neon signs, vehicles, pedestrians, syd mead, ralph mcquarrie, doug chiang, concept art, matte painting, finely detailed, minimal artifacts, rule of thirds, dynamic lighting, cinematic, denoised, centered, artstation"
    },
    {
        "display": "Femme fatale portrait.",
        "full": "jessica rabbit by luis royo ans wlop and artgerm, femme fatale, beautiful, dark, mysterious, detailed flawless face, dramatic darkroom lighting high exposure, head and shoulders 8 0 mm camera"
    },
    {
        "display": "Cyberpunk lady assassin portrait",
        "full": "lady assassin wearing cyberpunk catsuit, respirator, cybernetic legs, detailed portrait, 4 k, vivid colours, concept art by wlop, ilya kuvshinov, artgerm, krenz cushart, greg rutkowski, pixiv. cinematic dramatic atmosphere, sharp focus, volumetric lighting, cinematic lighting, studio quality"
    },
    {
        "display": "Cute Star Trek Officer",
        "full": "cute star trek officer troye sivan, natural lighting, path traced, highly detailed, high quality, digital painting, by don bluth and ross tran and studio ghibli and alphonse mucha, artgerm"
    },
    {
        "display": "John Lennon as Jack the Ripper.",
        "full": "john lennon as jack the ripper, ultra realistic, concept art, intricate details, highly detailed, photorealistic, octane render, 8 k, unreal engine, art by frank frazetta, simon bisley, brom"
    },
    {
        "display": "Chiaroscuro portrait of Olaf",
        "full": "a masterwork chiaroscuro oil painting portrait of adorable symmetric snowman olaf from disneys frozen in the style of a renaissance painting, insane detail, jan matejko, jan van eyck, trending on artstation, artgerm"
    },
    {
        "display": "Armored Superman Portrait",
        "full": "greg manchess portrait painting of armoed superman as overwatch character, medium shot, asymmetrical, profile picture, organic painting, sunny day, matte painting, bold shapes, hard edges, street art, trending on artstation, by huang guangjian and gil elvgren and sachin teng"
    },
    {
        "display": "Elegant sci-fi religious room",
        "full": "a religious scifi room with a floating platform in the center, symmetrical shot, center framing, elegant, cinematic shot, intricate digital painting artstation concept art smooth sharp focus illustration, art by artgerm and greg rutkowski and alphonse mucha"
    },
    {
        "display": "Onmyoji night portrait",
        "full": "onmyoji portrait, fine details. night setting. realistic shaded lighting poster by craig mullism, artgerm, jeremy lipkin and michael garmash, unreal engine, vibrant colors and hard shadows and strong rim light, light blue sky, cool white color temperature, radiant light, detailed and intricate environment, digital art"
    },
    {
        "display": "Synthwave music logo",
        "full": "scifi logo for a synthwave music producer, digital 3 d, black background, trending on artstation"
    },
    {
        "display": "Samuel Jackson as Gandalf",
        "full": "portrait of samuel jackson as gandalf, by alan lee, lord of the rings calendar, smooth, detailed terrain, oil painting, matte painting, concept art, trending on artstation"
    },
    {
        "display": "Cozy kitchen at night",
        "full": "a photorealistic hyperrealistic render of an interior of a beautifully decorated cozy kitchen by pixar, greg rutkowski, wlop, artgerm, dramatic moody nighttime lighting, long shadows, volumetric, cinematic atmosphere, octane render, artstation, 8 k"
    },
    {
        "display": "Occult leader artwork.",
        "full": "occult leader by artgerm, tooth wu, dan mumford, beeple, wlop, rossdraws, james jean, marc simonetti, artstation giuseppe dangelico pino and michael garmash and rob rey and greg manchess and huang guangjian and makoto shinkai"
    },
    {
        "display": "Intricate alien cargo ship",
        "full": "front shot of an alien cargo ship, intricate, elegant, highly detailed, centered, artstation, concept art, smooth, sharp focus, artgerm, tomasz alen kopera, peter mohrbacher, donato giancola, joseph christian leyendecker, wlop, boris vallejo ), mugshot, ugly, octane render, nvidia raytracing demo, grainy, muted"
    },
    {
        "display": "Joyful rugged pirate portrait",
        "full": "portrait of a young ruggedly handsome but joyful pirate, male, masculine, upper body, red hair, long hair, d & d, fantasy, smirk, intricate, elegant, highly detailed, digital painting, artstation, concept art, matte, sharp focus, illustration, art by artgerm and greg rutkowski and alphonse mucha"
    }
];

export default promptIdeas;