export async function generateImage(prompt: string, aspectRatio: string): Promise<{ imageUrl: string; openImage: () => void }> {
  
  const apiUrl = process.env.REACT_APP_API_URL1 || "https://us-central1-imagine-app-b72e9.cloudfunctions.net/generateImage";
  
  if (!apiUrl) {
    console.error("API URL is not defined. REACT_APP_API_URL1:", process.env.REACT_APP_API_URL1, "REACT_APP_API_URL2:", process.env.REACT_APP_API_URL2);
    throw new Error("API URL is not defined");
  }

  try {
    console.log('Sending request to:', apiUrl);
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt, aspectRatio }),
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', Object.fromEntries(response.headers.entries()));

    const contentType = response.headers.get("content-type");
    console.log('Content-Type:', contentType);

    const text = await response.text();
    console.log('Response text:', text);

    let data;
    try {
      data = JSON.parse(text);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      throw new Error("Invalid JSON response from server");
    }

    if (!data.imageUrl) {
      console.error('Invalid output from image generation:', data);
      throw new Error("Invalid output from image generation");
    }
    
    return {
      imageUrl: data.imageUrl,
      openImage: () => window.open(data.imageUrl, '_blank')
    };
  } catch (error) {
    console.error("Error generating image:", error);
    throw error;
  }
}