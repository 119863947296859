import React, { useState, useEffect } from 'react';
import './App.css';
import { FaSnowflake } from 'react-icons/fa';
import { ImageGenerator } from './components/ImageGenerator.tsx';
import { promptIdeas } from './promptIdeas';

function App() {
  const [inputText, setInputText] = useState('');
  const [displayedPrompts, setDisplayedPrompts] = useState([]);

  useEffect(() => {
    const shuffled = [...promptIdeas].sort(() => 0.5 - Math.random());
    setDisplayedPrompts(shuffled.slice(0, 6));
  }, []);

  const handleButtonClick = (fullPrompt) => {
    setInputText(fullPrompt);
  };

  const handleRefresh = () => {
    const shuffled = [...promptIdeas].sort(() => 0.5 - Math.random());
    setDisplayedPrompts(shuffled.slice(0, 5));
  };

  return (
    <div className="App">
      <div className="container">
        <div className="icon-container">
          <FaSnowflake className="snowflake-icon" />
        </div>
        <h1>Make it Real</h1>
        <p>Choose a prompt below or write your own to start dreaming up images!</p>
        
        <h3>Ideas to get you started:</h3>
        <div className="button-grid">
          {displayedPrompts.slice(0, 5).map((prompt, index) => (
            <button key={index} onClick={() => handleButtonClick(prompt.full)}>
              {prompt.display}
            </button>
          ))}
          <button className="refresh-button" onClick={handleRefresh}>
            Refresh Ideas
          </button>
        </div>
        
        <ImageGenerator initialPrompt={inputText} />
      </div>
    </div>
  );
}

export default App;
